/*
 * @Author: swxy
 * @Date: 2022-08-15 14:32:34
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
    'project.projcet': '项目',
    'project.no_task': '无任务',
    'project.back_project_list': '返回项目列表',
    'project.task': '任务',
    'project.last_update_time': '最后更新时间',
    'project.create_by': '创建于',
    'project.pending': '待办',
    'project.in_progress': '进行中',
    'project.completed': '完成',
    'project.open': '打开',
    'project.back_project': '返回项目',
    'project.back_task': '返回任务',

    'project.job': '题包',
    'project.jobs': '题包列表',
    'project.frames': '帧数',
    'project.job_status': '题包状态',
    'project.start_date': '开始时间',
    'project.duration': '期间',
    'project.copy': '复制',
    'project.copy_success': '已复制到剪贴板！',

    'project.overlap_size': '重叠尺寸',
    'project.segment_size': '总数量',
    'project.image_quality': '画面质量',

    'task.this': '这里',

    'task.lastFrame': '你最近查看的是第{{parsedFrame}}帧',
    'task.lastFrameHelp_pre': '你可以点击',
    'task.lastFrameHelp_last': '跳转到第{{parsedFrame}}帧继续',
    'task.noLabelHelp': '暂无标签信息',
    'task.noLabel': '请询问项目管理员，是否有在项目中加入标签。',

    'task.nofoundJob': '抱歉，这个题包没有找到',
    'task.pleaseClick': '请点击',
    'task.backHome': '返回首页',

    'task.uploadImages': '上传图片',
    'task.noListData': '当前没有数据，您可以',
    'task.add': '新增',
    'task.one': '一个',
    'task.updateTime': '更新时间',

    'task.selectAutoTypeHelp': '请至少选择一个自动化类型!',
    'task.seletImageHelp': '请先选择图片!',
    'task.uploading': '上传中，请耐心等待...',
    'task.autoType': '自动化类型：',
    'task.uploadTip': '拖拽图片或者点击这里',
    'task.uploadSupTypeHelp': '体验版本目前仅支持jpg、jpeg、png、bmp',


    'job.desInfo': '图片分辨率：{{width}} X {{height}}，总帧数：{{total}}',
    'job.backPrevPage': '返回上一层',
    'job.backHome': '返回首页',
    'job.updateTime': '更新时间',

    'project.myProject': '我的项目',

    //#region 2023-05-04 待校验
    'task.checkResolutionTip': '检查图片分辨率...',
    'task.uploadImagesTip': '上传中，请耐心等待...',
    'task.autoAnnotationTip': '自动化标注执行中，请耐心等待...',
    'task.sizeOver.title': '图片数量超限',
    'task.sizeOver.content': `您好，体验版仅支持10帧以内的数据计算，如有数据处理需求，请联系我们。

邮箱：sales@idatalab.cc`,
    'task.countOver.title': '次数超限',
    'task.countOver.content': `您好，当日体验次数已超限，如有额外需求，请联系我们。

邮箱：sales@idatalab.cc`,
    'job.deleteSuccess': '您已删除{{name}}, 项目名称将重新排列！',
    //#endregion
};
