/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2023-02-03 17:12:36
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from '@ant-design/icons';

import { MoveIcon } from 'icons';
import { ActiveControl } from 'reducers/interfaces';
import { Canvas } from 'cvat-canvas-wrapper';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useTranslation } from 'react-i18next';

export interface Props {
    canvasInstance: Canvas;
    activeControl: ActiveControl;
    cursorShortkey?: string;
    onClick?: (callback: () => void) => void;
}

function MoveControl(props: Props): JSX.Element {
    const { canvasInstance, activeControl, cursorShortkey, onClick } = props;
    const { t } = useTranslation();

    const moveEvent = (): void => {
        if (activeControl === ActiveControl.DRAG_CANVAS) {
            canvasInstance.dragCanvas(false);
        } else {
            canvasInstance.cancel();
            canvasInstance.dragCanvas(true);
        }
    };

    onClick?.(moveEvent);

    return (
        // <CVATTooltip title='Move the image' placement='right'>
        <CVATTooltip title={`${cursorShortkey || ''}${t('workspace.side.controls.move_tip')}`} placement='right'>
            <Icon
                component={MoveIcon}
                className={
                    activeControl === ActiveControl.DRAG_CANVAS
                        ? 'cvat-move-control cvat-active-canvas-control'
                        : 'cvat-move-control'
                }
                onClick={moveEvent}
            />
        </CVATTooltip>
    );
}

export default React.memo(MoveControl);
