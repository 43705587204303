/*
 * @Author: swxy
 * @Date: 2022-08-15 14:22:13
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
    language: '中文',
    setting: '设置',
    logout: '登出',
    loginfo_timeout: '登录信息已过期，请重新登录！',

    ok: '确定',
    cancel: '取消',
    close: '关闭',
    collapsed: '展开',
    uncollapsed: '折叠',

    'base.deleteConfirm': '是否确认删除该条',
    'base.delete': '删除',
    'base.close': '关闭',
    'base.upload': '上传',

    'base.prevStep': '上一步',
    'base.nextStep': '下一步',
    'base.send': '发送',

    'base.export': '导出',
    'base.save': '保存',
    'base.reset': '重置',

    'base.C': '保存',
    'layouts.copyright': '版权声明@上海智目科技有限公司',
    'layouts.copyright_number': '沪ICP备19010544号-2',
};
