/*
 * @Author: swxy
 * @Date: 2022-08-15 14:32:34
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'project.projcet': 'プロジェクト',
    'project.no_task': 'タスクなし',
    'project.back_project_list': 'プロジェクトリストに戻る',
    'project.task': 'タスク',
    'project.last_update_time': '最終更新時間',
    'project.create_by': '作成者',
    'project.pending': '保留中',
    'project.in_progress': '進行中',
    'project.completed': '完了',
    'project.open': '開く',
    'project.back_project': 'プロジェクトに戻る',
    'project.back_task': 'タスクに戻る',

    'project.job': 'ジョブ',
    'project.jobs': 'ジョブリスト',
    'project.frames': 'フレーム数',
    'project.job_status': 'ジョブステータス',
    'project.start_date': '開始日',
    'project.duration': '期間',
    'project.copy': 'コピー',
    'project.copy_success': 'クリップボードにコピーしました！',

    'project.overlap_size': 'オーバーラップサイズ',
    'project.segment_size': '総数',
    'project.image_quality': '画像品質',

    'task.this': 'ここ',

    'task.lastFrame': 'あなたが最近見たのはフレーム目{{parsedFrame}',
    'task.lastFrameHelp_pre': 'クリックすることができます',
    'task.lastFrameHelp_last': '{parsedFrame}}フレームにジャンプして続行します',
    'task.noLabelHelp': 'ラベル情報はありません',
    'task.noLabel': 'プロジェクトにラベルを追加した場合は、プロジェクト管理者に尋ねてください。',

    'task.nofoundJob': '申し訳ありませんが、この質問バッグが見つかりませんでした',
    'task.pleaseClick': 'クリックしてください',
    'task.backHome': 'ホームページに戻ります',

    'task.uploadImages': '画像をアップロードします',
    'task.noListData': '現在、データはありません',
    'task.add': '増加',
    'task.one': '',
    'task.updateTime': '時間を更新します',

    'task.selectAutoTypeHelp': '少なくとも1つの自動化タイプを選択してください！',
    'task.seletImageHelp': '最初に写真を選択してください！',
    'task.uploading': 'アップロードするには、辛抱強く待ってください...',
    'task.autoType': '自動化タイプ：',
    'task.uploadTip': '写真をドラッグするか、ここをクリックしてください',
    'task.uploadSupTypeHelp': '体験版バージョンはJPG、JPEG、PNG、BMPのみをサポートしています',


    'job.desInfo': '画像解像度：{{width}} x {{height}}、合計フレーム番号：{{total}}',
    'job.backPrevPage': '前のレイヤーに戻ります',
    'job.backHome': 'ホームページに戻ります',
    'job.updateTime': '時間を更新します',

    'project.myProject': 'マイプロジェクト',

    //#region 2023-05-04 待校验
    'task.checkResolutionTip': '画像解像度のチェック...',
    'task.uploadImagesTip': 'アップロード中、お待ちください…...',
    'task.autoAnnotationTip': '自動寸法実行中、お待ちください......',
    'task.sizeOver.title': 'ピクチャ数が制限を超えています',
    'task.sizeOver.content': `こんにちは、体験版は10トン以内のデータ計算しかサポートしていません。データ処理のニーズがあれば、お問い合わせください。

メールボックス：sales@idatalab.cc`,
    'task.countOver.title': '回数超過',
    'task.countOver.content': `こんにちは、当日の体験回数は制限を超えています。追加の必要があれば、お問い合わせください。

メールアドレス：sales@idatalab.cc`,
    'job.deleteSuccess': 'プロジェクト名が再配置される{{name}を削除しました！',
    //#endregion
};
