/*
 * @Author: swxy
 * @Date: 2023-08-25 18:38:18
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { ServerError } from 'errors/exception';
import { AmygoResponse, RequestSetting } from 'reducers/interfaces';
import { saveErrorLogs } from 'service/api/api';
import { post } from 'utils/request';
import { getUserInfo } from 'utils/storage';
import defaultSingeton from 'utils/defaultSingeton';
import { AnnotateType, BackfillStatus, ProjectType } from 'utils/ConstType';

export const queryJobsInfo = async (...data: any) => {
    const result = await post('/api/engineJob/selectRelative', ...data);
    return result;
};

export const queryJobInfo = async (...data: any) => {
    const result = await post('/api/engineJob/selectByPrimary', ...data);
    return result;
};

export const api_queryJobInfo = async (...data: any) => {
    const result = await post('/api/engineJob/selectByPrimary', ...data);
    return result;
};

export const queryJobInfoDetail = async (...data: any) => {
    const result = await post('/api/engineJob/selectByPrimary', ...data);
    return result;
};

export const queryLabels = async (...data: any) => {
    const result = await post('/api/engineProjectLabel/selectNest', ...data);
    return result;
};

export const queryPresetsIssues = async (...data: any) => {
    const result = await post('/api/engineProjectNote/select', ...data);
    return result;
};

export const queryIssues = async (...data: any) => {
    const result = await post('/api/engineLabeledshapenote/select', ...data);
    return result;
};

export const createIssue = async (...data: any) => {
    const result = await post('/api/engineLabeledshapenote/insert', ...data);
    return result;
};

export const api_updateIssue = async (data: any, config: RequestSetting = {}) => {
    const result = await post('/api/engineLabeledshapenote/update', data, config);
    return result;
};

// export const updateIssue = async (...data: any) => {
//     const result = await post('/api/engineLabeledshapenote/update', ...data);
//     return result;
// };

export const deleteIssue = async (...data: any) => {
    const result = await post('/api/engineLabeledshapenote/delete', ...data);
    return result;
};

export const api_getTagsTotal = async (...data: any) => {
    const result = await post('/api/engineLabeledimage/selectTotalNest', ...data);
    return result;
};

export const api_getShapesTotal = async (...data: any) => {
    const result = await post('/api/engineLabeledshape/selectTotalNest', ...data);
    return result;
};

export const api_getTrackFullTotal = async (...data: any) => {
    const result = await post('/api/engineLabeledtrack/selectTotalNest', ...data);
    return result;
};

export const api_getTags = async (...data: any) => {
    const result = await post('/api/engineLabeledimage/selectNest', ...data);
    return result;
};

export const api_getShapes = async (...data: any) => {
    const result = await post('/api/engineLabeledshape/selectNest', ...data);
    return result;
};

export const api_getTrackFull = async (...data: any) => {
    // const result = await post('/api/engineLabeledtrack/selectNest', ...data);
    const result = await post('/api/engineLabeledtrack/selectNestShape', ...data);
    return result;
};

export const api_getTracksWithoutShapes = async (...data: any) => {
    // const result = await post('/api/engineLabeledtrack/selectNest', ...data);
    const result = await post('/api/engineLabeledtrack/selectNestAttr', ...data);
    return result;
};

export const api_getTrackShapes = async (...data: any) => {
    const result = await post('/api/engineTrackedshape/selectNest', ...data);
    return result;
};

export const getTracks = async (...data: any) => {
    const result = await post('/api/engineLabeledtrack/select', ...data);
    return result;
};

export const api_exportTaskAnnotations = async (data: any, config: RequestSetting = { responseOriginalData: true }) => {
    const result = await post('/api/aatp/job/dumpJobResult', data, config);
    return result;
};

export const saveImageAnnotations = async (data: any, config: RequestSetting = {}) => {
    let url = '/api/aatp/shape/batchImage';
    let response: any = undefined;
    // const saveStartTime = new Date().valueOf();
    // let progross = '接口开始调用';
    try {
        // defaultSingeton.saveTimesLastThree = new Date().toISOString();
        response = await post(url, data, {
            responseOriginalData: true,
            ...config,
        });
        // defaultSingeton.saveTimesLastThree = new Date().toISOString();
        if (response.success) {
            return response.data;
        }
        throw new ServerError(`接口请求错误：${response.message}`, response.code);
    } catch (error) {
        // const dur = new Date().valueOf() - saveStartTime;
        // eslint-disable-next-line no-use-before-define
        saveErrorLogs({
            jobId: data.jobId,
            kind: 'error',
            urlAddress: url,
            paramInput: JSON.stringify(data),
            paramOutput: typeof response !== 'string' ? JSON.stringify(response) : response,
            detail: (error as Error).stack,
            // msg: `最近三次保存时间：${defaultSingeton.saveTimesLastThree.toString()}-保存耗时：${
            //     dur / 1000
            // }秒-进度：${progross}`,
        });
        throw new ServerError(response?.message);
    }
};

export const saveAnnotationsToServer = async (data: any, config: RequestSetting = {}) => {
    let response = undefined;
    const url = `/api/aatp/shape/batchShape`;
    const name = Math.floor(Math.random() * 10000);
    let progross = {
        title: `接口开始调用${name}`,
        detail: '接口即将调用，但还没有调用',
    };
    // let progross = '接口开始调用';
    const saveStartTime = new Date().valueOf();
    try {
        // defaultSingeton.saveStartTimesLastThree = new Date().toISOString();
        const userInfo = getUserInfo();
        response = await post(
            url,
            {
                jobId: data.jobId,
                userId: userInfo.id,
                ...data,
            },
            {
                responseOriginalData: true,
                ...config,
            },
            progross,
        );
        progross.title = `接口调用完成${name}`;
        progross.detail = '接口已经调用完成';
        // defaultSingeton.saveEndTimesLastThree = new Date().toISOString();
        if (response.success) {
            return response.data;
        }
        if (response.success) {
            const update = response.data;
            const { newTracks, newShapes } = update;
            return {
                newShapes: newShapes || {},
                newTracks: newTracks || {},
            };
        }
        throw new ServerError(response.message);
    } catch (error) {
        const dur = new Date().valueOf() - saveStartTime;
        // eslint-disable-next-line no-use-before-define
        saveErrorLogs({
            jobId: data.jobId,
            kind: 'error',
            urlAddress: url,
            paramInput: JSON.stringify(data),
            paramOutput: typeof response !== 'string' ? JSON.stringify(response) : response,
            detail: (error as Error).stack,
            msg: `接口请求：${name}报错-保存耗时：${dur / 1000}秒-进度：${JSON.stringify(progross)}`,
        });
        throw new ServerError(response?.message);
    }

    // return response.data;
};

export const submitJob = async (data: any, config: RequestSetting = {}) => {
    const response = await post('/api/engineReview/insert', data, {
        ...config,
        responseOriginalData: true,
    });
    return response;
};

export const api_createRelation = async (data: any, config: RequestSetting = {}) => {
    const result = await post('/api/tShapeRelation/insert', data, config);
    return result;
};
export const api_updateRelation = async (data: any, config: RequestSetting = {}) => {
    const result = await post('/api/tShapeRelation/update', data, config);
    return result;
};
export const api_deleteRelation = async (data: any, config: RequestSetting = {}) => {
    const result = await post('/api/tShapeRelation/delete', data, config);
    return result;
};
export const api_getRelation = async (data: any, config: RequestSetting = {}) => {
    const result = await post('/api/tShapeRelation/select', data, config);
    return result;
};

export const api_getTrackKeyFrames = async (data: any, config: RequestSetting = {}) => {
    const result = await post('/api/aatp/job/getTrackedShapeFrame', data, config);
    return result;
};

export const api_multUpdateAttrByShapeIndex = async (
    data: any,
    config: RequestSetting = { responseOriginalData: true },
) => {
    try {
        const result = await post('/api/aatp/shape/updateLaneAttr', data, config);
        return !!result?.success;
    } catch (error) {
        throw error;
    }
};

/**
 * 重新汇总目标物的个数统计
 * @param data
 * @param config
 * @returns
 */
export const api_countTargetTotal = async (
    data: {
        jobId: number;
    },
    config: RequestSetting = { responseOriginalData: true },
): Promise<boolean> => {
    const result = await post('/api/aatp/task/countShape', data, config);
    return !!result?.success;
};

export const api_checkJobAnnotationResult = async (
    data: { jobId: number; data: string },
    // config: RequestSetting = {},
): Promise<any> => {
    const result = await post('/business/company/dazhuo/checkJob', data, { responseOriginalData: true });
    return result;
};

export const api_queryLabels = async (data: { projectId: number }, config: RequestSetting = {}) => {
    const result: any[] = await post('/api/engineProjectLabel/selectNest', data, config);
    return result?.length
        ? result.map((label) => {
              label.attributes = label.engineProjectSpecs;
              delete label.engineProjectSpecs;
              return label;
          })
        : [];
};

// 属性自动标注
export const api_sendExportJobAutoAttr = async (
    data: { jobId: number; annotateType: AnnotateType; frames: any[] },
    config: RequestSetting = {},
) => {
    try {
        const result: any = await post('/api/aatp/job/sendAutolabel', data, {
            responseOriginalData: true,
        });
        return result;
    } catch (error) {}
    return undefined;
};

// 属性自动标注
export const api_queryExportJobAutoAttr = async (
    data: { jobId: number; annotateType: AnnotateType },
    config: RequestSetting = {},
) => {
    try {
        const result: any = await post('/api/dumpJobAutolabel/selectOne', data, config);
        return result;
    } catch (error) {}
    return undefined;
};

// 更新属性的回填状态
export const api_updateJobAutoAttrStatus = async (
    data: { jobId: number; annotateType: AnnotateType; backfillStatus: BackfillStatus },
    config: RequestSetting = {},
) => {
    try {
        const result: any = await post('/api/dumpJobAutolabel/update', data, config);
        return result;
    } catch (error) {}
    return undefined;
};
