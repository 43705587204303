/*
 * @Author: swxy
 * @Date: 2022-08-15 14:22:13
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
    language: 'English',
    setting: 'setting',
    logout: 'logout',
    loginfo_timeout: 'Login information has expired，Please logoin again！',

    ok: 'OK',
    cancel: 'Cancel',
    close: 'Close',

    collapsed: 'collapsed',
    uncollapsed: 'uncollapsed',

    'base.deleteConfirm': 'Do you confirm to delete this item?',
    'base.delete': 'Delete',
    'base.close': 'Close',
    'base.upload': 'Upload',

    'base.prevStep': 'Previous',
    'base.nextStep': 'Next',
    'base.send': 'Send',

    'layouts.copyright': 'copyright @Shanghai Zhimu Technology Co., Ltd.',
    'layouts.copyright_number': '沪ICP备19010544号-2',
};
