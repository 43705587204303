/*
 * @Author: swxy
 * @Date: 2022-03-18 10:04:08
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/actions/jobs-actions.ts
 * Copyright (C) Amygo
 */
// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
// import getCore from 'cvat-core-wrapper';
import { JobsQuery } from 'reducers/interfaces';
import AnnotationHubHelper from 'business/annotationHubHelper';
import {
    api_applyJob,
    // api_queryTeamTaskByUser,
    api_queryTeamTaskList,
    api_queryTeamUserList,
    api_recoveryJob,
} from 'service/team/team';
import { JobStatus } from 'utils/ConstType';

const helper = AnnotationHubHelper.getInstance();
const cvat = helper.old;
// const cvat = getCore();

export enum JobsActionTypes {
    GET_JOBS = 'GET_JOBS',
    GET_JOBS_SUCCESS = 'GET_JOBS_SUCCESS',
    GET_JOBS_FAILED = 'GET_JOBS_FAILED',
    get_teamUsers_failed = 'get_teamUsers_failed',
    get_teamTask_failed = 'get_teamTask_failed',
    apply_job_failed = 'apply_job_failed',
    recovery_job_failed = 'recovery_job_failed',
}

interface JobsList extends Array<any> {
    count: number;
}

/**
 * 根据题包状态重新排序
 * 打回的优先
 * @param jobs
 */
export const sortJobByJobStatus = (jobs: any[] = []) => {
    jobs.sort((a: any, b: any) => {
        if ([JobStatus.backOne, JobStatus.backTwo, JobStatus.backReceive].includes(b.jobStatus)) {
            return 1;
        }
        if ([JobStatus.backOne, JobStatus.backTwo, JobStatus.backReceive].includes(a.jobStatus)) {
            return -1;
        }
        return 0;
    });
};

const jobsActions = {
    getJobs: (query: Partial<JobsQuery>) => createAction(JobsActionTypes.GET_JOBS, { query }),
    getJobsSuccess: (jobs: JobsList, count: number, previews: string[]) =>
        createAction(JobsActionTypes.GET_JOBS_SUCCESS, { jobs, count, previews }),
    getJobsFailed: (error: any) => createAction(JobsActionTypes.GET_JOBS_FAILED, { error }),
    getTeamUsersFailed: (error: any) => createAction(JobsActionTypes.get_teamUsers_failed, { error }),
    getTeamTaskFailed: (error: any) => createAction(JobsActionTypes.get_teamTask_failed, { error }),
    applyJobFailed: (error: any) => createAction(JobsActionTypes.apply_job_failed, { error }),
    recoveryJobFailed: (error: any) => createAction(JobsActionTypes.recovery_job_failed, { error }),
};

export type JobsActions = ActionUnion<typeof jobsActions>;

export const getJobsAsync =
    (query: JobsQuery): ThunkAction =>
    async (dispatch): Promise<any> => {
        try {
            // Remove all keys with null values from the query
            const filteredQuery: Partial<JobsQuery> = { ...query };
            if (filteredQuery.page === null) delete filteredQuery.page;
            if (filteredQuery.filter === null) delete filteredQuery.filter;
            if (filteredQuery.sort === null) delete filteredQuery.sort;
            if (filteredQuery.search === null) delete filteredQuery.search;
            if (filteredQuery.pageSize === null) {
                filteredQuery.pageSize = 10;
            }
            if (filteredQuery.pageIndex === null) {
                filteredQuery.pageIndex = 10;
            }

            dispatch(jobsActions.getJobs(filteredQuery));
            const { rows, total } = await helper.jobs.get(filteredQuery);
            const previewPromises = rows.map(() => '');
            dispatch(jobsActions.getJobsSuccess(rows, total, await Promise.all(previewPromises)));
            return rows;
        } catch (error) {
            dispatch(jobsActions.getJobsFailed(error));
            return [];
        }
    };

export const getJobsInfoAsync =
    (query: any): ThunkAction =>
    async (dispatch): Promise<any> => {
        try {
            // Remove all keys with null values from the query
            const filteredQuery: Partial<JobsQuery> = { ...query };
            if (filteredQuery.page === null) delete filteredQuery.page;
            if (filteredQuery.filter === null) delete filteredQuery.filter;
            if (filteredQuery.sort === null) delete filteredQuery.sort;
            if (filteredQuery.search === null) delete filteredQuery.search;
            if (filteredQuery.pageSize === null) {
                filteredQuery.pageSize = 10;
            }
            if (filteredQuery.pageIndex === null) {
                filteredQuery.pageIndex = 10;
            }

            const jobs = await cvat.jobs.getInfoList(filteredQuery);

            sortJobByJobStatus(jobs);
            // const previewPromises = jobs.map(() => '');
            // dispatch(jobsActions.getJobsSuccess(jobs, await Promise.all(previewPromises)));
            return jobs;
        } catch (error) {
            dispatch(jobsActions.getJobsFailed(error));
            return [];
        }
    };

export const getTeamUserListAsync =
    (query: any): ThunkAction =>
    async (dispatch): Promise<any> => {
        try {
            const jobs = await api_queryTeamUserList(query);
            // const previewPromises = jobs.map(() => '');
            // dispatch(jobsActions.getJobsSuccess(jobs, await Promise.all(previewPromises)));
            return jobs;
        } catch (error) {
            dispatch(jobsActions.getTeamUsersFailed(error));
            return [];
        }
    };

export const getTeamTaskListAsync =
    (query: any): ThunkAction =>
    async (dispatch): Promise<any> => {
        try {
            const jobs = await api_queryTeamTaskList(query);
            // const previewPromises = jobs.map(() => '');
            // dispatch(jobsActions.getJobsSuccess(jobs, await Promise.all(previewPromises)));
            return jobs;
        } catch (error) {
            dispatch(jobsActions.getTeamTaskFailed(error));
            return [];
        }
    };

export const applyJobAsync =
    (query: any): ThunkAction =>
    async (dispatch): Promise<any> => {
        try {
            const success = await api_applyJob(query);
            // const previewPromises = jobs.map(() => '');
            // dispatch(jobsActions.getJobsSuccess(jobs, await Promise.all(previewPromises)));
            return success;
        } catch (error) {
            dispatch(jobsActions.applyJobFailed(error));
            return false;
        }
    };

export const recoveryJobAsync =
    (query: any): ThunkAction =>
    async (dispatch): Promise<any> => {
        try {
            const success = await api_recoveryJob(query);
            // const previewPromises = jobs.map(() => '');
            // dispatch(jobsActions.getJobsSuccess(jobs, await Promise.all(previewPromises)));
            return success;
        } catch (error) {
            dispatch(jobsActions.recoveryJobFailed(error));
            return false;
        }
    };

export const deleteTaskByIdAsync =
    (query: any): ThunkAction =>
    async (dispatch): Promise<any> => {
        try {
            if (query.jobId) {
                await cvat.jobs.delete(query.jobId);
            }
            if (query.taskId) {
                await cvat.tasks.delete(query.taskId);
            }
        } catch (error) {
            // dispatch(jobsActions.getJobsFailed(error));
            // return [];
        }
    };

export const clearJobAnnotation =
    (jobId: number): ThunkAction =>
    async (dispatch): Promise<any> => {
        try {
            await cvat.server.clearAnnotation(jobId);
        } catch (error) {
            // dispatch(jobsActions.getJobsFailed(error));
            // return [];
        }
    };
