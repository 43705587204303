/*
 * @Author: swxy
 * @Date: 2023-09-15 17:05:07
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import CameraControls from 'camera-controls';
import * as THREE from 'three';

CameraControls.install({ THREE: THREE });

class CameraControl extends CameraControls {
    private clock = new THREE.Clock();
    constructor(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera, domElement?: HTMLElement) {
        super(camera, domElement);
        // this.infinityDolly = true;
    }

    /**
     * 是否需要更新场景
     * @returns
     */
    public isNeedUpdateRender(): boolean {
        const delta = this.clock.getDelta();
        const hasControlsUpdated = this.update(delta);
        return hasControlsUpdated;
    }
}

export default CameraControl;
