/*
 * @Author: swxy
 * @Date: 2023-08-25 19:11:44
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { ServerError, ArgumentError } from 'errors/exception';
import { queryProjectsInfo, queryProjectInfo } from 'service/project/project';
import Job, { Project, Task } from './objects/job';
import { queryTasksInfo, queryTaskInfo } from 'service/task/task';
import {
    queryJobsInfo,
    queryJobInfo,
    queryJobInfoDetail,
    queryLabels,
    queryPresetsIssues,
    queryIssues,
    createIssue,
    // updateIssue,
    // deleteI?ssue,
    api_createRelation,
    api_updateRelation,
    api_deleteRelation,
    api_getRelation,
} from 'service/job/job';
import { Label } from './objects/label';
import { ProjectCompanyType } from 'utils/ConstType';
import Issue from './objects/issue';
import IssuePreset from './objects/issuePreset';
import { getAnnotationTags, getAnnotations } from './annotationHelper';
import { checkObjectType } from 'common/common';
import { api_heartbeat } from 'service/api/api';

const total = {
    project: 0,
    task: 0,
    job: 0,
};

// const jobCache = new WeakMap();

const checkFrameRange = (frame: number, startFrame: number, stopFrame: number) => {
    if (!Number.isInteger(frame)) {
        throw new ArgumentError(`无效的帧号: ${frame}`);
    }
    if (frame < startFrame && frame > stopFrame) {
        throw new ArgumentError(`帧号：${frame}，不在有效范围：${startFrame}和${stopFrame}之间！`);
    }
    return true;
};

class AnnotationHubHelper {
    private static instance: AnnotationHubHelper;
    public test = 1;
    public old: any;
    public jobInstance?: Job;

    private constructor() {}

    public static getInstance() {
        if (!AnnotationHubHelper.instance) {
            AnnotationHubHelper.instance = new AnnotationHubHelper();
        }
        return AnnotationHubHelper.instance;
    }

    private get label() {
        return {
            get: async (projectId: number) => {
                try {
                    if (projectId) {
                        const result: any[] = await queryLabels({ projectId });

                        return result.map((label) => {
                            label.attributes = label.engineProjectSpecs;
                            delete label.engineProjectSpecs;
                            return new Label(label);
                        });
                    }
                    throw new ArgumentError('获取题包参数错误');
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
        };
    }

    public get issue() {
        return {
            get: async (jobId: number) => {
                try {
                    if (jobId) {
                        const result: any[] = await queryIssues({ jobId });

                        return result.map((issue) => {
                            return new Issue(issue, this.jobInstance?.supplement);
                        });
                    }
                    throw new ArgumentError('获取题包参数错误');
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
            getPresetsIssues: async (projectId: number) => {
                try {
                    if (projectId) {
                        const result: any[] = await queryPresetsIssues({ projectId });

                        return result.map((issue) => new IssuePreset(issue));
                    }
                    throw new ArgumentError('获取题包参数错误');
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
            createIssue: async (issue: Issue, message: string) => {
                try {
                    if (issue) {
                        checkObjectType('jobId', issue.jobId, 'number');
                        checkObjectType('message', message, 'string');
                        checkObjectType('frame', issue.frame, 'number');
                        const result: any = await createIssue({
                            ...issue.serialize(),
                            // message,
                            content: message,
                        });

                        return new Issue(result);
                    }
                    throw new ArgumentError('获取题包参数错误');
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
            // updateIssue: async (points: number[]) => {
            //     try {
            //         if (points) {
            //             if (!Array.isArray(points)) {
            //                 throw new ArgumentError(`"points"必须是数字数组，现在是"${points}"`);
            //             }

            //             // const result: any = await updateIssue({ ...data });

            //             return result;
            //         }
            //         throw new ArgumentError('获取题包参数错误');
            //     } catch (error) {
            //         console.error('接口错误:', error);
            //         throw new ServerError((error as Error).message);
            //     }
            // },
            // deleteIssue: async (id: number) => {
            //     try {
            //         if (id) {
            //             const result: any = await deleteIssue({ id }, {});

            //             return result;
            //         }
            //         throw new ArgumentError('获取题包参数错误');
            //     } catch (error) {
            //         console.error('接口错误:', error);
            //         throw new ServerError((error as Error).message);
            //     }
            // },
        };
    }

    public get projects() {
        return {
            get: async (filter: any = {}) => {
                try {
                    if (filter.id) {
                        const result: any = await queryProjectInfo(filter);
                        // 由于当前逻辑，查询项目后，任务自动置空
                        total.task = 0;
                        total.project = 0;
                        return {
                            rows: [new Project(result)],
                            total: total.project,
                        };
                    }
                    const result: any = await queryProjectsInfo(filter);
                    const { rows, total: count } = result;
                    total.project = Math.max(total.project, count);
                    return {
                        rows: rows.map((initProject: any) => {
                            return new Project(initProject);
                        }),
                        total: total.project,
                    };
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
        };
    }

    public get tasks() {
        return {
            get: async (filter: any = {}) => {
                try {
                    if (filter.id) {
                        const result: any = await queryTaskInfo(filter);
                        // 由于当前逻辑，查询项目后，任务自动置空
                        total.job = 0;
                        total.task = 0;
                        return {
                            rows: [new Task(result)],
                            total: total.task,
                        };
                    }
                    const result: any = await queryTasksInfo(filter);
                    const { rows, total: count } = result;
                    total.task = Math.max(total.task, count);
                    return {
                        rows: rows.map((initTask: any) => {
                            return new Task(initTask);
                        }),
                        total: total.task,
                    };
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
        };
    }

    public get jobs() {
        return {
            get: async (filter: any = {}) => {
                try {
                    if (filter.id) {
                        const result: any = await queryJobInfoDetail(filter);
                        // 由于当前逻辑，查询项目后，任务自动置空
                        total.job = 0;
                        total.task = 0;
                        return {
                            rows: [Job.createJob(result)],
                            total: total.job,
                        };
                    }
                    const result: any = await queryJobsInfo(filter);
                    const { rows, total: count } = result;
                    total.job = Math.max(total.job, count);
                    return {
                        rows: rows,
                        total: total.job,
                    };
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
        };
    }

    public get job() {
        return {
            get: async (jobId: number) => {
                try {
                    if (jobId) {
                        let result: any = await queryJobInfo({ id: jobId });
                        // 获取项目和任务
                        const {
                            rows: [project],
                        } = await this.projects.get({ id: result.projectId });

                        let frameMeta = await Job.getFrameMeta(jobId);

                        if (
                            project.projectCompanyType !== ProjectCompanyType.dazhuo ||
                            !Job.isNotOldTask(result.taskId)
                        ) {
                            [result] = await this.old.jobs.get({ id: jobId });
                            return this.jobInstance;
                        } else {
                            const {
                                rows: [task],
                            } = await this.tasks.get({ id: result.taskId });

                            result.project = project;
                            result.task = task;

                            result.labels = await this.label.get(result.projectId);

                            result.presetsIssues = await this.issue.getPresetsIssues(result.projectId);
                            result.issues = await this.issue.get(result.projectId);
                        }

                        this.jobInstance = new Job(result, frameMeta);

                        return this.jobInstance;
                    }
                    throw new ArgumentError('获取题包参数错误');
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
            close: () => {
                // if (this.jobInstance && jobCache.has(this.jobInstance)) {
                //     jobCache.delete(this.jobInstance);
                //     this.jobInstance.close();
                //     this.jobInstance = undefined;
                // }
            },
            saveRelation: async (relation: any, action: string) => {
                let response = null;
                try {
                    if (action === 'create') {
                        response = await api_createRelation(relation);
                    }
                    if (action === 'update') {
                        response = await api_updateRelation(relation);
                    }
                    if (action === 'delete') {
                        response = await api_deleteRelation(relation);
                    }
                    return response;
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
            getRelation: async (relation: any) => {
                try {
                    const response = await api_getRelation(relation);
                    return response;
                } catch (error) {
                    console.error('接口错误:', error);
                    throw new ServerError((error as Error).message);
                }
            },
        };
    }

    public get annotations() {
        if (!this.jobInstance) {
            throw new ArgumentError('题包暂时还不存在！');
        }
        return {
            get: async (
                frameNumber: number,
                showAllInterpolationTracks: boolean,
                filters: any = {},
                cameraName?: string,
            ) => {
                try {
                    if (
                        this.jobInstance &&
                        checkFrameRange(frameNumber, this.jobInstance.startFrame, this.jobInstance!.stopFrame)
                    ) {
                        return await getAnnotations(
                            this.jobInstance,
                            frameNumber,
                            showAllInterpolationTracks,
                            filters,
                            cameraName,
                        );
                        this.jobInstance?.getAnnotations(frameNumber, showAllInterpolationTracks, filters, cameraName);
                    }
                    throw new ArgumentError('题包暂时还不存在！');
                } catch (error) {
                    throw error;
                }
                return [];
            },
            getTags: async (frameNumber: number, filters: any = {}, cameraName?: string) => {
                try {
                    if (
                        this.jobInstance &&
                        checkFrameRange(frameNumber, this.jobInstance.startFrame, this.jobInstance!.stopFrame)
                    ) {
                        return await getAnnotationTags(this.jobInstance, frameNumber, cameraName);
                        this.jobInstance?.getAnnotations(frameNumber, filters, cameraName);
                    }
                    throw new ArgumentError('题包暂时还不存在！');
                } catch (error) {
                    throw error;
                }
            },
        };
    }

    public get logs() {
        return {
            save: (data: any[]) => {
                // 保存日志
                console.log('日志暂时不加：', data);
            },
        };
    }

    public get exception() {
        return {
            log: (data: any[]) => {
                console.log('错误信息暂时不加：', data);
            },
        };
    }

    public get server() {
        return {
            heartbeat: async () => {
                api_heartbeat();
            },
            about: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            share: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            formats: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            userAgreements: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            register: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            login: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            logout: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            changePassword: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            requestPasswordReset: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            resetPassword: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            authorized: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            request: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            installedApps: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            registerCheckUserRepeat: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            getEmailLoginCode: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            loginByEmailCode: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            clearAnnotation: async () => {
                console.log('待实现！！！！！');
                return {};
            },
        };
    }

    public get users() {
        return {
            get: async () => {
                console.log('待实现！！！！！');
                return {};
            },
        };
    }

    public get teams() {
        return {
            queryTeamUserList: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            queryTeamTaskList: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            applyJob: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            recoveryJob: async () => {
                console.log('待实现！！！！！');
                return {};
            },
        };
    }
    public get plugins() {
        return {
            list: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            register: async () => {
                console.log('待实现！！！！！');
                return {};
            },
        };
    }
    public get lambda() {
        return {
            list: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            run: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            call: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            cancel: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            listen: async () => {
                console.log('待实现！！！！！');
                return {};
            },
            requests: async () => {
                console.log('待实现！！！！！');
                return {};
            },
        };
    }

    public get logger() {
        return;
    }

    public get config() {
        return {};
    }

    public get classes() {
        return {};
    }
}

export default AnnotationHubHelper;
