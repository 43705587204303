/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React from 'react';
import Select, { SelectValue } from 'antd/lib/select';
import { Label } from 'business/objects/label';

interface Props {
    currentLabel: string;
    labels: any[];
    changeLabel(value: SelectValue): void;
}

function ObjectBasicsEditor(props: Props): JSX.Element {
    const { currentLabel, labels, changeLabel } = props;

    return (
        <div className='cvat-attribute-annotation-sidebar-basics-editor'>
            <Select value={currentLabel} onChange={changeLabel} style={{ width: '50%' }}>
                {labels.map(
                    (label: Label): JSX.Element => (
                        <Select.Option value={label.name} key={label.name}>
                            {/* {label.name} */}
                            {label.title}
                        </Select.Option>
                    ),
                )}
            </Select>
        </div>
    );
}

export default React.memo(ObjectBasicsEditor);
