/*
 * @Author: swxy
 * @Date: 2023-05-04 15:02:10
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
        //#region 2023-05-04 待校验
        'userNotice': `
        一、总则

        1、请务必认真阅读和理解本《用户须知》(以下简称《须知》)中规定的所有权利和限制。

        2、除非另有明确规定，idatalab平台所推出的新功能、新服务，均无条件的使用本须知。

        3、idatalab平台保留在任何时候修改本须知条款的权利，且无需另行通知；须知条款一经变更立即生效，用户在使用服务时应关注并遵守。若不同意本协议的修改，应放弃使用或访问本平台；须知变更后，若用选择继续使用本平台，则视为用户已经接受所有新增或修改内容。



        二、用户注册及隐私说明

        1、用户在进行注册程序过程中点击"登录"按钮即表示用户完全接受本须知项下的全部条款。

        2、用户在使用本平台服务过程中应保证上传数据的合法和合规性, 如果因上传的数据引起的问题，并对问题发生所带来的后果，全权由用户本人承担，idatalab平台不负任何责任。

        3、在您注册和使用idatalab平台时，本平台会提示、收集、存储您的个人信息以及数据，本平台承诺不对外公开或向第三方提供用户的信息资料和数据资料。



        三、服务内容

        1、idatalab平台免费为用户提供服务，用户使用网络上传数据或使用本软件的过程中产生的流量费用由用户自行承担。

        2、除非本服务须知另有其它明示规定，本平台所推出的新产品、新功能、新服务，均受到本服务须知之规范。



        四、服务变更、中断或终止

        1、idatalab平台有权在任何时候对系统进行检修或维护，无论是否通知。

        2、鉴于网络服务的特殊性（包括但不限于服务器的稳定性问题、恶意的网络攻击等行为的存在及idatalab平台无法控制的情形），idatalab平台有权随时中断或终止部分或全部的服务，而无需另行通知。



        五、用户使用规则

        1、用户应遵守本平台服务须知的各项规定。

        2、用户对以其账号发生的或通过其账号发生的一切活动和事件负全部法律责任。

        3、用户不得为任何非法目的或利用本平台系统服务进行不正当活动。

        4、用户不得对本平台服务任何部分或本平台服务中使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。

        5、用户上传的数据及其自动化结果，idatalab有权到期自动删除，无需征求个人用户的同意。



        六、免则声明

        1、鉴于本软件使用网络服务的特殊性, 不担保网络服务一定满足用户的要求,也不担保网络服务不会中断,对网络服务的及时性、安全性和准确性也不作担保。

        2、对于因电信系统或互联网网络故障、计算机故障、计算机系统问题或其它任何不可抗力原因而产生损失, 本平台系统不承担任何责任, 但将尽力减少因此给用户造成的损失和影响。



        七、本须知最终解释权归idatalab平台所有。
`
        //#endregion
}