// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, {
    Dispatch,
    // TransitionEvent
} from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
// import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Tabs from 'antd/lib/tabs';
import Layout from 'antd/lib/layout';

import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'canvas3d-wrapper';
import { CombinedState } from 'reducers/interfaces';
import LabelsList from 'components/annotation-page/standard-workspace/objects-side-bar/labels-list';
// import { adjustContextImagePosition } from
// 'components/annotation-page/standard-workspace/context-image/context-image';
import { collapseSidebar as collapseSidebarAction } from 'actions/annotation-actions';
import AppearanceBlock from 'components/annotation-page/appearance-block';
import IssuesListComponent from 'components/annotation-page/standard-workspace/objects-side-bar/issues-list';
import { useTranslation } from 'react-i18next';

interface OwnProps {
    objectsList: JSX.Element;
}

interface StateToProps {
    sidebarCollapsed: boolean;
    sidebarPositionLeft?: boolean;
    canvasInstance: Canvas | Canvas3d;
    jobInstance: any;
}

interface DispatchToProps {
    collapseSidebar(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            sidebarCollapsed,
            canvas: { instance: canvasInstance },
            job: { instance: jobInstance },
        },
    } = state;

    return {
        sidebarCollapsed,
        canvasInstance,
        jobInstance,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchToProps {
    return {
        collapseSidebar(): void {
            dispatch(collapseSidebarAction());
        },
    };
}

function ObjectsSideBar(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {
        sidebarCollapsed,
        // canvasInstance, collapseSidebar,
        objectsList,
        // jobInstance,
    } = props;

    const { t } = useTranslation();

    // const collapse = (): void => {
    //     const [collapser] = window.document.getElementsByClassName('cvat-objects-sidebar');
    //     const listener = (event: TransitionEvent): void => {
    //         if (event.target && event.propertyName === 'width' && event.target === collapser) {
    //             canvasInstance.fitCanvas();
    //             canvasInstance.fit();
    //             (collapser as HTMLElement).removeEventListener('transitionend', listener as any);
    //         }
    //     };

    //     if (collapser) {
    //         (collapser as HTMLElement).addEventListener('transitionend', listener as any);
    //     }

    //     adjustContextImagePosition(!sidebarCollapsed);
    //     collapseSidebar();
    // };

    // const is2D = jobInstance ? jobInstance.dimension === DimensionType.DIM_2D : true;
    return (
        <Layout.Sider
            className='cvat-objects-sidebar'
            theme='light'
            width={300}
            collapsedWidth={0}
            reverseArrow
            collapsible
            trigger={null}
            collapsed={sidebarCollapsed}
        >
            {/* eslint-disable-next-line */}
            {/* <span
                className={`cvat-objects-sidebar-sider
                    ant-layout-sider-zero-width-trigger
                    ant-layout-sider-zero-width-trigger-left`}
                onClick={collapse}
            >
                {sidebarCollapsed ? <MenuFoldOutlined title='Show' /> : <MenuUnfoldOutlined title='Hide' />}
            </span> */}

            <Tabs type='card' tabPosition='bottom' defaultActiveKey='objects' className='cvat-objects-sidebar-tabs'>
                {/* <Tabs.TabPane tab={<Text strong>Objects</Text>} key='objects'> */}
                <Tabs.TabPane tab={<Text strong>{t('workspace.side.tab.object')}</Text>} key='objects'>
                    {objectsList}
                </Tabs.TabPane>
                {/* <Tabs.TabPane forceRender tab={<Text strong>Labels</Text>} key='labels'> */}
                <Tabs.TabPane forceRender tab={<Text strong>{t('workspace.side.tab.labels')}</Text>} key='labels'>
                    <LabelsList />
                </Tabs.TabPane>

                {/* {is2D ? (
                    // <Tabs.TabPane tab={<Text strong>Issues</Text>} key='issues'></Tabs.TabPane>
                     */}
                <Tabs.TabPane tab={<Text strong>{t('workspace.side.tab.issues')}</Text>} key='issues'>
                    <IssuesListComponent />
                </Tabs.TabPane>
                {/* ) : null} */}
                <Tabs.TabPane tab={<Text strong>{t('workspace.side.tab.appear')}</Text>} key='Appearance'>
                    <AppearanceBlock />
                </Tabs.TabPane>
            </Tabs>

            {/* {!sidebarCollapsed && <AppearanceBlock />} */}
        </Layout.Sider>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ObjectsSideBar));
