/*
 * @Author: swxy
 * @Date: 2023-04-23 17:24:46
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
  
    'setting.settingTip': 'Tastenkombination {{setting}}',
    'setting.changePassword': 'Passwort ändern',

    'setting.changePasswordSuccess': 'Passwort erfolgreich geändert!',
    'setting.changePasswordConfirm': 'ändern',
    
}