// Copyright (C) 2019-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

(() => {
    const serverProxy = require('./server-proxy');
    const { Task } = require('./session');
    const { ScriptingError, ServerError } = require('./exceptions');

    function getDirectionClientIDKey(direction, clientID) {
        return direction ? `${clientID}-${direction}` : clientID;
    }

    class AnnotationsSaver {
        constructor(version, collection, session) {
            this.sessionType = session instanceof Task ? 'task' : 'job';
            this.id = session.id;
            this.version = version;
            this.collection = collection;
            this.initialObjects = {};
            // this.addClientId = { shapes: [], tracks: [], tags: [] };
            this.hash = this._getHash();

            this.deleteTrackShape = {};
            this.deleteClientIDs = [];

            // 等待保存promise， 保存池
            this.waitPromises = [];
            this.saving = false;

            // We need use data from export instead of initialData
            // Otherwise we have differ keys order and JSON comparison code incorrect
            const exported = this.collection.export();

            this._resetState();
            for (const shape of exported.shapes) {
                if (shape.id) {
                    this.initialObjects.shapes[shape.id] = shape;
                }
            }

            for (const track of exported.tracks) {
                if (track.id) {
                    this.initialObjects.tracks[track.id] = track;
                }
            }

            for (const tag of exported.tags) {
                if (tag.id) {
                    this.initialObjects.tags[tag.id] = tag;
                }
            }
        }

        _resetState() {
            this.initialObjects = {
                shapes: {},
                tracks: {},
                tags: {},
            };
        }

        _getHash() {
            const exported = this.collection.export();
            return JSON.stringify(exported);
        }

        async _request(data, action) {
            const result = await serverProxy.annotations.updateAnnotations(this.sessionType, this.id, data, action);

            return result;
        }

        async _put(data) {
            const result = await this._request(data, 'put');
            return result;
        }

        async _create(created) {
            const result = await this._request(created, 'create');
            return result;
        }

        async _updateShape(updated) {
            const result = await this._request(updated, 'shape');
            return result;
        }

        async _updateTrack(updated) {
            const result = await this._request(updated, 'track');
            return result;
        }

        async _update(updated) {
            const result = await serverProxy.annotations.saveAnnotations(updated);
            return result;
        }

        async _updateImages(updated) {
            const result = await serverProxy.annotations.saveImageAnnotations(updated);
            return result;
        }

        async _delete(deleted) {
            const result = await this._request(deleted, 'delete');
            return result;
        }

        _split(exported) {
            const splitted = {
                created: {
                    shapes: [],
                    tracks: [],
                    tags: [],
                },
                updated: {
                    shapes: [],
                    tracks: [],
                    tags: [],
                },
                deleted: {
                    shapes: [],
                    tracks: [],
                    tags: [],
                },
            };

            const keys = [
                'id',
                'label_id',
                'group',
                'frame',
                'occluded',
                'z_order',
                'points',
                'rotation',
                'type',
                'shapes',
                'attributes',
                'value',
                'specId',
                'source',
                'outside',
            ];

            // Find created and updated objects
            for (const type of Object.keys(exported)) {
                for (const object of exported[type]) {
                    if (object.id in this.initialObjects[type]) {
                        const exportedHash = JSON.stringify(object, keys);
                        const initialHash = JSON.stringify(this.initialObjects[type][object.id], keys);
                        if (exportedHash !== initialHash) {
                            splitted.updated[type].push(object);
                        }
                    } else if (typeof object.id === 'undefined') {
                        splitted.created[type].push(object);
                    } else {
                        throw new ScriptingError(
                            `Id of object is defined "${object.id}" but it absents in initial state`,
                        );
                    }
                }
            }

            // Now find deleted objects
            const indexes = {
                shapes: exported.shapes.map((object) => +object.id),
                tracks: exported.tracks.map((object) => +object.id),
                tags: exported.tags.map((object) => +object.id),
            };

            for (const type of Object.keys(this.initialObjects)) {
                for (const id of Object.keys(this.initialObjects[type])) {
                    if (!indexes[type].includes(+id)) {
                        const object = this.initialObjects[type][id];
                        splitted.deleted[type].push(object);
                    }
                }
            }

            return splitted;
        }

        // _updateCreatedObjects(saved, indexes) {
        //     const savedLength = saved.tracks.length + saved.shapes.length + saved.tags.length;

        //     const indexesLength = indexes.tracks.length + indexes.shapes.length + indexes.tags.length;

        //     if (indexesLength !== savedLength) {
        //         throw new ScriptingError(
        //             `Number of indexes is differed by number of saved objects ${indexesLength} vs ${savedLength}`,
        //         );
        //     }

        //     // Updated IDs of created objects
        //     for (const type of Object.keys(indexes)) {
        //         for (let i = 0; i < indexes[type].length; i++) {
        //             const clientID = indexes[type][i];
        //             this.collection.objects[clientID].serverID = saved[type][i].id;
        //         }
        //     }
        // }

        _updateCreatedObjects(beforSaved, saved) {
            const savedLength = saved.tracks.length + saved.shapes.length + saved.tags.length;

            const beforSavedLength = beforSaved.tracks.merges.length +
                beforSaved.shapes.merges.length +
                beforSaved.tags.merges.length;

            if (beforSavedLength !== savedLength) {
                throw new ScriptingError(
                    `保存前和保存后的数量不一致，保存前${beforSavedLength}, 保存后:${savedLength}`,
                );
            }

            // 更新
            for (const type of Object.keys(saved)) {
                for (let i = 0; i < saved[type].length; i++) {
                    const savedObject = saved[type][i];
                    const beforSavedObject = beforSaved[type].merges[i];
                    this.collection.objects[beforSavedObject.clientID].serverID = savedObject.id;
                    this.collection.objects[beforSavedObject.clientID].attributesID =
                        savedObject.attributes.reduce((attributeAccumulator, attr) => {
                            attributeAccumulator[attr.specId] = attr;
                            return attributeAccumulator;
                        }, {});
                    if (type === 'tracks') {
                        // 连续帧， 更新shapes里的id和shapes里的属性id

                        if (this.collection.objects[beforSavedObject.clientID].shapes) {
                            Object.entries(this.collection.objects[beforSavedObject.clientID].shapes)
                                .forEach(([key], index) => {
                                    // shape.id = savedObject.shapes[index].id;

                                    this.collection.objects[beforSavedObject.clientID]
                                        .shapes[+key].id = savedObject.shapes[index].id;
                                    this.collection.objects[beforSavedObject.clientID]
                                        .shapes[+key].serverID = savedObject.shapes[index].id;

                                    this.collection.objects[beforSavedObject.clientID]
                                        .attributesID.shapes = this.collection.objects[beforSavedObject.clientID]
                                            .attributesID.shapes || {};
                                    savedObject.shapes[index].attributes.forEach((attr) => {
                                        this.collection.objects[beforSavedObject.clientID]
                                            .attributesID.shapes[+key] = attr;
                                    });
                                });
                        }
                    }
                }
            }
        }

        _receiveIndexes(exported) {
            // Receive client indexes before saving
            const indexes = {
                tracks: exported.tracks.map((track) => track.clientID),
                shapes: exported.shapes.map((shape) => shape.clientID),
                tags: exported.tags.map((tag) => tag.clientID),
            };

            // Remove them from the request body
            exported.tracks
                .concat(exported.shapes)
                .concat(exported.tags)
                .map((value) => {
                    delete value.clientID;
                    return value;
                });

            return indexes;
        }

        _tParams(exported) {
            const splitted = {
                created: {
                    shapes: {},
                    tracks: {},
                    tags: {},
                },
                updated: {
                    shapes: {},
                    tracks: {},
                    tags: {},
                },
                deleted: {
                    shapes: [],
                    tracks: [],
                    trackShapes: [],
                    tags: [],
                },
            };
            let jobId;

            const keys = [
                // 'idVals',
                'id',
                'shape',
                'track',
                'labelId',
                'group',
                'frame',
                'occluded',
                'zOrder',
                'points',
                'occludedParts',
                'pointsLine',
                'rotation',
                'type',
                'value',
                'source',
                'outside',
                'trackShape',
                'shapeId',
                'trackId',
                'direction',
                'subPoints',
            ];

            // Find created and updated objects
            for (const type of Object.keys(exported)) {
                for (const object of exported[type]) {
                    if (object.id in this.initialObjects[type]) {
                        try {
                            let isUpdateObject = false;
                            const updateObject = {
                                id: object.id,
                                jobId: object.jobId,
                                clientID: object.clientID,
                                shape: {
                                    id: object.id,
                                    jobId: object.jobId,
                                    clientID: object.clientID,
                                },
                            };

                            updateObject.idVals = {};

                            jobId = object.jobId;

                            // 先对比是否有更改对象本身。
                            const exportedHash = JSON.stringify(object, keys);
                            const initialHash = JSON.stringify(this.initialObjects[type][object.id], keys);
                            if (exportedHash !== initialHash) {
                                updateObject.shape = type === 'tracks' ? object.track : object.shape;
                                isUpdateObject = true;
                            }

                            // 比较属性是否更改
                            Object.entries(object.idVals).forEach(([attrId, value]) => {
                                const { idVals } = this.initialObjects[type][object.id];
                                if (idVals[attrId] !== value) {
                                    updateObject.idVals[attrId] = value;
                                    isUpdateObject = true;
                                }
                            });

                            if (type === 'tags') {
                                updateObject.image = updateObject.shape;
                                delete updateObject.shape;
                            }
                            if (type === 'tracks') {
                                updateObject.track = updateObject.shape;
                                delete updateObject.shape;
                                updateObject.mergeTrackShapes = {};

                                // 连续帧的关键帧
                                const { mergeTrackShapes } = object;
                                // updateTrack.mergeTrackShapes = {};
                                Object.entries(mergeTrackShapes).forEach(([frame, shape]) => {
                                    const initialShape = this.initialObjects[type][object.id].mergeTrackShapes[+frame];
                                    if (initialShape) {
                                    // 是否更新了关键帧
                                        let isUpdateShape = false;
                                        // 是否更新了关键帧的属性
                                        // let isUpdateAttr = false;
                                        const exportedShapeHash = JSON.stringify(shape, keys);
                                        const initialShapeHash = JSON.stringify(initialShape, keys);
                                        const updateShape = {
                                            id: shape.id,
                                            clientID: shape.frame,
                                            frame: shape.frame,
                                            idVals: {},
                                            trackShape: {
                                                id: shape.id,
                                                trackId: object.id,
                                                frame: shape.frame,
                                            },
                                        };

                                        // const newTrackShapeIdVals = {};

                                        // 比较关键帧属性是否修改
                                        Object.entries(shape.idVals).forEach(([attrId, value]) => {
                                            const { idVals = {} } = initialShape;
                                            if (idVals[attrId] !== value) {
                                                updateShape.idVals[attrId] = value;
                                                isUpdateObject = true;
                                                isUpdateShape = true;
                                                // isUpdateAttr = true;
                                            }
                                        });

                                        // if (isUpdateAttr) {
                                        //     updateShape.idVals = newTrackShapeIdVals;
                                        // }
                                        // 比较关键帧信息是否更改
                                        if (exportedShapeHash !== initialShapeHash) {
                                            updateShape.trackShape = shape.trackShape;
                                            isUpdateObject = true;
                                            isUpdateShape = true;
                                        }
                                        if (isUpdateShape) {
                                            updateObject.mergeTrackShapes[shape.frame] = updateShape;
                                        }
                                    } else {
                                    // 新增关键帧
                                        isUpdateObject = true;
                                        updateObject.mergeTrackShapes[shape.frame] = shape;
                                    }
                                });
                            }
                            if (isUpdateObject) {
                                const key = getDirectionClientIDKey(object.direction, object.clientID);
                                splitted.updated[type][key] = updateObject;
                            }
                        } catch (error) {
                            console.error('转换属性状态错误！');
                            throw new ScriptingError(
                                `检查修改内容错误-待保存数据:${JSON.stringify(object)}。 错误详情:-${error}`,
                            );
                        }
                    } else if (typeof object.id === 'undefined') {
                        const key = getDirectionClientIDKey(object.direction, object.clientID);
                        splitted.created[type][key] = (object);
                    } else {
                        throw new ScriptingError(
                            `对象的 ID “${object.id}”，在初始状态下不存在`,
                        );
                    }
                }
            }

            // Now find deleted objects
            const indexes = {
                shapes: exported.shapes.map((object) => +object.id),
                tracks: exported.tracks.map((object) => +object.id),
                tags: exported.tags.map((object) => +object.id),
            };

            const trackObj = exported.tracks.reduce((previous, current) => {
                previous[current.id] = current;
                return previous;
            }, {});

            for (const type of Object.keys(this.initialObjects)) {
                for (const id of Object.keys(this.initialObjects[type])) {
                    const object = this.initialObjects[type][id];
                    if (!indexes[type].includes(+id) && id !== 'null') {
                        // 找到初始里有，但当前导出的对象里没有的id，当前对象需要删除
                        splitted.deleted[type].push(+id);
                        this.deleteClientIDs.push(object.clientID);
                    } else if (type === 'shapes') {
                        //
                    } else if (type === 'tracks') {
                        // 当id包含时，找到需要删除和多余的的属性、连续帧的关键帧、关键帧的属性里，是否有多余及需要删除的。

                        const track = trackObj[object.id];
                        const trackedshapesFrames = Object.keys(track.mergeTrackShapes);
                        Object.entries(object.mergeTrackShapes).forEach(([frame, current]) => {
                            // 以往的shapeid里，如果现有的没有包含，则需要删除该对象
                            if (!trackedshapesFrames.includes(frame)) {
                                splitted.deleted.trackShapes.push(current.id);
                                this.deleteTrackShape[current.id] = {
                                    trackId: object.id,
                                    frame,
                                };
                            }
                        });
                    }
                }
            }

            return {
                jobId,
                delShapes: [...splitted.deleted.shapes],
                delTracks: [...splitted.deleted.tracks],
                delTrackShapes: [...splitted.deleted.trackShapes],
                delImages: [...splitted.deleted.tags],
                mergeShapes: { ...splitted.created.shapes, ...splitted.updated.shapes },
                mergeTracks: { ...splitted.created.tracks, ...splitted.updated.tracks },
                mergeImages: { ...splitted.created.tags, ...splitted.updated.tags },
            };
        }

        async saveImages(data) {
            try {
                const { mergeImages } = data;
                const { newImages = {} } = await this._updateImages(data);

                // 更改则直接用参数替换
                Object.entries(mergeImages).forEach(([clientID, tag]) => {
                    if (!tag.id) {
                        tag.id = newImages[clientID];
                        tag.image.id = tag.id;
                        // this.collection.objects[clientID].serverID = tag.id;
                        this.collection.updateServerID({
                            clientID,
                            serverID: tag.id,
                            objectType: 'tag',
                            direction: tag.image.direction, // 有方向，代表是投影
                        });

                        this.initialObjects.tags[tag.id] = tag;
                    } else {
                        // 回填属性
                        if (this.initialObjects.tags[tag.id]) {
                            const { idVals } = this.initialObjects.tags[tag.id];
                            // const updateIdVals = shape.idVals;
                            tag.idVals = { ...idVals, ...tag.idVals };
                        }

                        this.initialObjects.tags[tag.id] = tag;
                    }
                });
            } catch (error) {
                // console.log('保存图像标注时错误：', error);
                throw new ServerError(`保存图像标注时错误：${error}`);
            }
        }

        async saveData(data) {
            // console.log('需要保存的数据：', data);
            // const { newShapes, newTracks } = await this._update(data);
            // mergeImages = {}
            const { mergeImages, ...shapes } = data;

            const { newShapes = {}, newTracks = {} } = await this._update(shapes);
            // console.log('新增的普通框：', newShapes);
            // console.log('更新的连续帧框：', newTracks);
            // console.log('更新的图像框：', data);

            const {
                delShapes = [],
                delTrackShapes = [],
                delTracks = [],
                mergeShapes = {},
                mergeTracks = {},
            } = shapes;
            for (const type of Object.keys(this.initialObjects)) {
                if (type === 'shapes') {
                    // 删除普通帧
                    delShapes.forEach((shapeServerID) => {
                        delete this.initialObjects[type][shapeServerID];
                    });

                    // 更改则直接用参数替换
                    Object.entries(mergeShapes).forEach(([clientID, shape]) => {
                        try {
                            if (!shape.id) {
                                shape.id = newShapes[clientID];
                                shape.shape.id = shape.id;

                                this.initialObjects[type][shape.id] = shape;
                                // this.collection.objects[clientID].serverID = shape.id;
                                this.collection.updateServerID({
                                    clientID,
                                    serverID: shape.id,
                                    objectType: 'shape',
                                    direction: shape.shape.direction, // 有方向，代表是投影
                                });
                            } else {
                                // 回填属性
                                if (this.initialObjects[type][shape.id]) {
                                    const { idVals } = this.initialObjects[type][shape.id];
                                    // const updateIdVals = shape.idVals;
                                    shape.idVals = { ...idVals, ...shape.idVals };
                                }

                                this.initialObjects[type][shape.id] = shape;
                            }
                        } catch (error) {
                            throw new ServerError(`回填普通帧报错:${error}`);
                        }
                    });
                } else if (type === 'tracks') {
                    try {
                    // 删除连续帧
                        delTracks.forEach((shapeServerID) => {
                            delete this.initialObjects[type][shapeServerID];
                        // delete this.collection.objects[clientID];
                        });
                        // 删除连续帧下的关键帧
                        delTrackShapes.forEach((trackShapeId) => {
                            const { trackId, frame } = this.deleteTrackShape[trackShapeId];
                            delete this.initialObjects[type][trackId].mergeTrackShapes[frame];
                        });
                    } catch (error) {
                        throw new ServerError(`删除连续帧报错:${error}`);
                    }

                    // 更改则直接用入参替换
                    Object.entries(mergeTracks).forEach(([clientID, track]) => {
                        // const addTrack = newTracks[clientID];

                        const { id, newTrackShapes = {} } = newTracks[clientID] || {};

                        if (!track.id) {
                            try {
                            // 新增的关键帧
                                track.id = id;
                                track.track.id = track.id;
                                // this.collection.objects[clientID].serverID = track.id;
                                const trackShapeServerIDByFrame = {};
                                // this.initialObjects[type][track.id] = track;
                                if (track.mergeTrackShapes && Object.keys(track.mergeTrackShapes).length) {
                                    Object.entries(track.mergeTrackShapes).forEach(([frame, shape]) => {
                                    // 新增的关键帧回填id
                                        if (!shape.id) {
                                            shape.id = newTrackShapes[frame];
                                            shape.trackShape.id = shape.id;

                                            // if (this.collection.objects[clientID].shapes[frame]) {
                                            //     this.collection.objects[clientID].shapes[frame].serverID = shape.id;
                                            // }
                                            trackShapeServerIDByFrame[frame] = shape.id;
                                        }
                                    });
                                }

                                this.collection.updateServerID({
                                    clientID,
                                    serverID: track.id,
                                    objectType: 'track',
                                    direction: track.track.direction, // 有方向，代表是投影
                                    trackShapeServerIDByFrame,
                                });
                            } catch (error) {
                                throw new ServerError(`新增连续帧报错:${error}`);
                            }
                        } else {
                            try {
                            // 只修改关键帧时，连续帧信息中只有id。为了兼容更改连续帧信息，需要合并初始化和入参的连续帧信息。
                                const {
                                    track: oldTrack = {},
                                    idVals: oldIdVals = {},
                                    mergeTrackShapes: oldMergeTrackShapes = {},
                                } = this.initialObjects[type][track.id] || {};
                                // 更新连续帧信息
                                track.track = { ...oldTrack, ...track.track };
                                // 更新连续帧属性信息
                                track.idVals = { ...oldIdVals, ...track.idVals };

                                // 更新关键帧信息
                                // 初始的关键帧列表,上面已经进行了删除。
                                // 由于入参只有修改/新增的关键帧，没有无变化的，因此以初始关键帧为准。
                                const trackShapeServerIDByFrame = {};
                                // 有更新/新增关键帧。
                                if (track.mergeTrackShapes && Object.keys(track.mergeTrackShapes).length) {
                                    Object.entries(track.mergeTrackShapes).forEach(([frame, shape]) => {
                                        // 新增的关键帧回填id
                                        if (!shape.id) {
                                            shape.id = newTrackShapes[frame];
                                            shape.trackShape.id = shape.id;

                                            trackShapeServerIDByFrame[frame] = shape.id;
                                            // if (track.direction) {
                                            //     if (this.collection.objects[clientID].shapes[frame]) {
                                            //         this.collection.objects[clientID].shapes
                                            // [frame].serverID = shape.id;
                                            //     }
                                            // } else {
                                            //     if (this.collection.objects[clientID].shapes[frame]) {
                                            //         this.collection.objects[clientID].shapes[frame].
                                            // serverID = shape.id;

                                            //     }
                                            // }
                                        } else {
                                            try {
                                                // 有id时，存在一种特殊情况，在initialObjects中不存在该帧，oldMergeTrackShapes[frame]可能为空
                                                // 取消关键帧保存后，initialObjects已经删除该帧，但撤销取消关键帧后，该帧id仍然存在。
                                                const {
                                                    idVals: oldTrackShapeIdVals = {},
                                                    trackShape: oldTrackShape = {},
                                                } = (oldMergeTrackShapes[frame] || {});
                                                shape.idVals = { ...oldTrackShapeIdVals, ...shape.idVals };
                                                shape.trackShape = { ...oldTrackShape, ...shape.trackShape };
                                            } catch (error) {
                                                throw new ServerError(`修改连续帧框报错:${error},
                                                旧帧： ${JSON.stringify(oldMergeTrackShapes[frame])}。
                                                新帧：${JSON.stringify(shape)}`);
                                            }
                                        }
                                    });
                                }
                                this.collection.updateTrackServerID({
                                    clientID,
                                    objectType: 'track',
                                    direction: track.track.direction, // 有方向，代表是投影
                                    trackShapeServerIDByFrame,
                                });
                                track.mergeTrackShapes = { ...oldMergeTrackShapes, ...track.mergeTrackShapes };
                            } catch (error) {
                                throw new ServerError(`修改连续帧报错:${error}`);
                            }
                        }
                        this.initialObjects[type][track.id] = track;
                    });
                }
            }

            // 不操作删除collection中的内容
            // this.deleteClientIDs.forEach((id) => {
            //     delete this.collection.objects[id];
            // });
            // this.deleteTrackShape = {};

            // 新增或修改
        }

        // 正式的保存
        async saveAction(onUpdateArg) {
            console.time('保存耗时');
            this.saving = true;
            this.deleteTrackShape = {};
            this.deleteClientIDs = [];
            // // 更新和新增两种id

            const onUpdate = typeof onUpdateArg === 'function' ? onUpdateArg : (message) => {
                console.log(message);
            };
            onUpdate('正在将修改保存到服务器...');
            const exported = this.collection.export();

            let param;
            try {
                param = this._tParams(exported);
            } catch (error) {
                this.saving = false;
                throw new ScriptingError(
                    `保存时转换对象错误:${error}`,
                );
            }
            try {
                // const { shapes, tracks } = param;
                const savePromises = [];

                // if (shapes && (shapes.deletes.length || param.shapes.merges.length)) {
                //     savePromises.push(this.saveShape(shapes));
                // }

                // if (tracks && (tracks.deletes.length || tracks.merges.length)) {
                //     savePromises.push(this.saveTrack(tracks));
                // }

                const {
                    delShapes,
                    delTracks,
                    delTrackShapes,
                    mergeShapes,
                    mergeTracks,
                    jobId,
                } = param;
                if (delShapes.length ||
                    delTracks.length ||
                    delTrackShapes.length ||
                    Object.keys(mergeShapes).length ||
                    Object.keys(mergeTracks).length) {
                    savePromises.push(this.saveData({
                        delShapes,
                        delTracks,
                        delTrackShapes,
                        mergeShapes,
                        mergeTracks,
                        jobId,
                    }));
                }
                const {
                    delImages,
                    mergeImages,
                } = param;
                if (delImages.length || Object.keys(mergeImages).length) {
                    savePromises.push(this.saveImages({
                        delImages,
                        mergeImages,
                    }));
                }

                await Promise.all(savePromises).catch((e) => {
                    this.waitPromises = [];
                    this.saving = false;
                    throw new ServerError(e.message, 0);
                });

                this.hash = this._getHash();

                this.saving = false;
                // 执行完保存结束后，查看是否有等待保存的。有的话，按顺序一个个保存。
                // 静默保存没有阻塞操作，防止本身返回有可能的时间慢导致的后发先至以及和保存冲突。
                if (this.waitPromises && this.waitPromises.length) {
                    const data = this.waitPromises.shift();
                    await this.saveAction(data.param);
                }
                console.timeEnd('保存耗时');
            } catch (error) {
                this.saving = false;
                console.error('保存时错误：', error);
                throw new ScriptingError(
                    `保存时错误:${error}`,
                );
            }
        }

        async save(onUpdateArg) {
            // 是否正在保存
            if (this.saving || (this.waitPromises && this.waitPromises.length)) {
                this.waitPromises.push({ param: onUpdateArg });
            } else {
                try {
                    await this.saveAction(onUpdateArg);
                    this.saving = false;
                } catch (error) {
                    this.saving = false;
                    throw new ScriptingError(error);
                }
            }
        }

        // async saveSub(onUpdateArg) {
        //     console.log('保存视角对象的方法：');
        //     const onUpdate = typeof onUpdateArg === 'function' ? onUpdateArg : (message) => {
        //         console.log(message);
        //     };
        //     onUpdate('正在将修改保存到服务器...');
        // }

        hasUnsavedChanges() {
            return this._getHash() !== this.hash;
        }
    }

    module.exports = AnnotationsSaver;
})();
