/*
 * @Author: swxy
 * @Date: 2022-03-30 18:40:57
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/components/annotation-page/canvas/canvas-wrapper3D.tsx
 * Copyright (C) Amygo
 */

import React, { ReactElement, SyntheticEvent, useEffect, useReducer, useRef, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import notification from 'antd/lib/notification';
// import { ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined, ArrowUpOutlined } from '@ant-design/icons';
// import { ResizableBox } from 'react-resizable';
import {
    ActiveView,
    ColorBy,
    CombinedState,
    ContextMenuType,
    DimensionType,
    ObjectType,
    ShapeType,
    Workspace,
} from 'reducers/interfaces';
import { CameraAction, Canvas3d, ViewType, ViewsDOM } from 'canvas3d-wrapper';
import type { CameraDistortionParameter, PcdParameter } from 'canvas3d-wrapper';
// import { Canvas } from 'cvat-canvas-wrapper';
import ContextImages, {
    BitImageData,
} from 'components/annotation-page/standard3D2D-workspace/context-image/context-images';
import CVATTooltip from 'components/common/cvat-tooltip';
import { LogType } from 'cvat-logger';
import getCore from 'cvat-core-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Mode } from 'canvas3d/src/interface';
import AttributeAnnotationImage from 'components/annotation-page/attribute-annotation-image';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { createSubAnnotationsAsync, projectionAnnotation } from 'actions/annotationSub-actions';
import { defaultCameraName } from 'consts';
import { Spin, message } from 'antd';
import Job from 'business/objects/job';
import PcdObject from 'business/objects/pcd';
import ImageObject from 'business/objects/image';
import FrameData from 'business/frame';
import { changeMoveObjectActiveControl } from 'actions/annotation-actions';
import ThreeCanvasWrapper from './three_canvas_wrapper';
import { defaultRightViewWidth } from 'consts';
import ObjectState from 'business/objects/objectState';
import CanvasFooterComponent from './canvas_footer';
import FrameMeta from 'business/amygo/AmygoFrameMeta';

// const cvat = getCore();

interface Props {
    opacity: number;
    selectedOpacity: number;
    outlined: boolean;
    outlineColor: string;
    colorBy: ColorBy;
    frameFetching: boolean;
    canvasInstance: Canvas3d;
    jobInstance: any;
    frameData: any;
    curZLayer: number;
    annotations: any[];
    contextMenuVisibility: boolean;
    activeLabelID: number;
    activatedStateID: number | null;
    activeObjectType: ObjectType;
    onSetupCanvas: () => void;
    onGroupObjects: (enabled: boolean) => void;
    onResetCanvas(): void;
    onCreateAnnotations(sessionInstance: any, frame: number, states: any[]): void;
    onActivateObject(activatedStateID: number | null, activeAttrID?: number | null, issueID?: number): void;
    onUpdateAnnotations(states: any[]): void;
    onUpdateContextMenu(visible: boolean, left: number, top: number, type: ContextMenuType, pointID?: number): void;
    onGroupAnnotations(sessionInstance: any, frame: number, states: any[]): void;
    onEditShape: (enabled: boolean) => void;
    onDragCanvas: (enabled: boolean) => void;
    onReviewCanvas: (enabled: boolean) => void;
    onStartIssue(position: number[], type: DimensionType): void;
    onSelectIssue(id: number | null): void;
    onShapeDrawn: () => void;
    onUpdateIssue: (id: number, points: number[]) => void;
    onCollapseStates(states: any[], collapsed: boolean): void;
    onBirdEyeMode: (enabled: boolean) => void;
    onChangeActiveView(activeView: ActiveView): void;
    workspace: Workspace;
    automaticBordering: boolean;
    showObjectsTextAlways: boolean;
    frame: number;
    disableEdit: boolean;
    selectIssueID: number | null;
    canvasIsReady: boolean;
    acitveView: ActiveView;
    keyMap: KeyMap;

    perspective?: string;
    active2DLabelID: number;
    subAnnotations: any[];
}

interface ViewSize {
    fullHeight: number;
    fullWidth: number;
    vertical: number;
    horizontal: number;
    top: number;
    side: number;
    front: number;
}

interface SourceData {
    data?: Blob | PcdObject[];
    images: BitImageData[] | ImageObject[];
    number: number;
}

interface Projection {
    clientID: number;

    length?: number;
    width?: number;
    height?: number;

    count?: number; // 内含点数量

    shapeType: ShapeType;
}

// let minWidth = '400px';

function viewSizeReducer(
    state: ViewSize,
    action: { type: ViewType | 'set' | 'resize'; e?: SyntheticEvent; data?: ViewSize },
): ViewSize {
    const event = action.e as unknown as MouseEvent;
    const canvas3dContainer = document.getElementById('canvas3d-container');
    if (canvas3dContainer) {
        switch (action.type) {
            case ViewType.TOP: {
                const width = event.clientX - canvas3dContainer.getBoundingClientRect().left;
                const topWidth = state.top;
                if (topWidth < width) {
                    const top = state.top + (width - topWidth);
                    const side = state.side - (width - topWidth);
                    return {
                        ...state,
                        top,
                        side,
                    };
                }
                const top = state.top - (topWidth - width);
                const side = state.side + (topWidth - width);
                return {
                    ...state,
                    top,
                    side,
                };
            }
            case ViewType.SIDE: {
                const width = event.clientX - canvas3dContainer.getBoundingClientRect().left;
                const topSideWidth = state.top + state.side;
                if (topSideWidth < width) {
                    const side = state.side + (width - topSideWidth);
                    const front = state.front - (width - topSideWidth);
                    return {
                        ...state,
                        side,
                        front,
                    };
                }
                const side = state.side - (topSideWidth - width);
                const front = state.front + (topSideWidth - width);
                return {
                    ...state,
                    side,
                    front,
                };
            }
            case ViewType.PERSPECTIVE:
                return {
                    ...state,
                    vertical: event.clientY - canvas3dContainer.getBoundingClientRect().top,
                };
            case 'set':
                return action.data as ViewSize;
            case 'resize': {
                const canvasPerspectiveContainer = document.getElementById('cvat-canvas3d-perspective');
                let midState = { ...state };
                if (canvasPerspectiveContainer) {
                    if (state.fullHeight !== canvas3dContainer.clientHeight) {
                        const diff = canvas3dContainer.clientHeight - state.fullHeight;
                        midState = {
                            ...midState,
                            fullHeight: canvas3dContainer.clientHeight,
                            vertical: state.vertical + diff,
                        };
                    }
                    if (state.fullWidth !== canvasPerspectiveContainer.clientWidth) {
                        const oldWidth = state.fullWidth;
                        const width = canvasPerspectiveContainer.clientWidth;
                        midState = {
                            ...midState,
                            fullWidth: width,
                            top: (state.top / oldWidth) * width,
                            side: (state.side / oldWidth) * width,
                            front: (state.front / oldWidth) * width,
                        };
                    }
                    return midState;
                }
                return state;
            }
            default:
                throw new Error();
        }
    }
    return state;
}

const CanvasWrapperComponent = (props: Props): ReactElement => {

    const dispatch = useDispatch();

    const animateId = useRef(0);
    const [viewSize, setViewSize] = useReducer(viewSizeReducer, {
        fullHeight: 0,
        fullWidth: 0,
        vertical: 0,
        horizontal: 0,
        top: 0,
        side: 0,
        front: 0,
    });
    const perspectiveView = useRef<HTMLDivElement | null>(null);
    const topView = useRef<HTMLDivElement | null>(null);
    const sideView = useRef<HTMLDivElement | null>(null);
    const frontView = useRef<HTMLDivElement | null>(null);
    // const [cuboidsByImagesInfos, setCuboidsByImagesInfos] = useState<any>([]);
    const { activeModel, workspace } = useSelector((state: CombinedState) => ({
        activeModel: state.annotation.annotations.activeModel,
        workspace: state.annotation.workspace,
    }));

    const { t } = useTranslation();
    // const [sourceData, setSourceData] = useState<SourceData>({ data: undefined, images: [], number: 0 });
    // const [disableEdit, setDisableEdit] = useState(true);
    const { disableEdit } = props;
    // const [activeView, setActiveView] = useState<'top' | 'side' | 'front' | undefined>();

    // useEffect(() => {
    //     setDisableEdit(disableEditing3D(jobStatus, roleType));
    // }, [jobStatus, roleType]);

    const contextImageHidden = useSelector((state: CombinedState) => state.annotation.player.contextImage.hidden);
    // const [pointBy3D, setPointBy3D] = useState<any>();
    // const [cloudPoints, setCloudPoints] = useState<number>(0);
    const [projectInfo, setProjectInfo] = useState<Projection | undefined>(undefined);

    const [rightWidth, setRightWidth] = useState(defaultRightViewWidth);

    const {
        keyMap,
        activatedStateID,
        opacity,
        outlined,
        outlineColor,
        selectedOpacity,
        colorBy,
        contextMenuVisibility,
        frameData,
        activeObjectType,
        annotations,
        frame,
        jobInstance,
        activeLabelID,
        frameFetching,
        // onCollapseStates,
        selectIssueID,
        canvasIsReady,
        acitveView,

        onResetCanvas,
        onSetupCanvas,
        onShapeDrawn,
        onCreateAnnotations,
        onChangeActiveView,

        // perspective,
        // active2DLabelID,
        subAnnotations,
    } = props;

    // const [oldJson, setOldJson] = useState<string>('');
    // const [oldObjects, setOldObjects] = useState<any[]>([]);
    const activeObjectTypeRef = useRef<ObjectType>(activeObjectType);
    activeObjectTypeRef.current = activeObjectType;

    const { canvasInstance } = props as { canvasInstance: Canvas3d };

    const onCanvasSetup = (): void => {
        onSetupCanvas();
        // canvasInstance.mode()
    };

    // const onCanvasDragStart = (): void => {
    //     const { onDragCanvas } = props;
    //     onDragCanvas(true);
    // };

    // const onCanvasDragDone = (): void => {
    //     const { onDragCanvas } = props;
    //     onDragCanvas(false);
    // };

    // console.log('当前类型：', activeObjectType);

    const onChangeMoveObject = (): void => {
        dispatch(changeMoveObjectActiveControl());
    }


    const onBridEyeModeStart = (): void => {
        const { onBirdEyeMode } = props;
        // onActivateObject(null);
        // onSelectIssue(null);
        onBirdEyeMode(true);
    };

    const onBridEyeModeDone = (): void => {
        const { onBirdEyeMode } = props;
        onBirdEyeMode(false);
    };

    const onCanvasReviewStart = (): void => {
        const { onReviewCanvas } = props;
        onReviewCanvas(true);
    };

    const onCanvasReviewDone = (): void => {
        const { onReviewCanvas } = props;
        onReviewCanvas(false);
    };

    const onCanvasDrawnIssue = (event: any): void => {
        const { onStartIssue } = props;
        const {
            state: {
                points: [x, y, z],
            },
            isContinue,
        } = event.detail;

        // 前两个是鼠标点击的位置，后三个是范围，最后一个是？半径
        onStartIssue([x, y, z], DimensionType.DIM_3D);
        if (!isContinue) {
            onResetCanvas();
        }
    };

    const animateCanvas = (): void => {
        canvasInstance.render();
        animateId.current = requestAnimationFrame(animateCanvas);
    };

    // const updateCanvasPointCloud = async (): Promise<void> => {
    //     if (frameData !== null) {
    //         frameData
    //             .data((): void => { })
    //             .then((data: Resource): void => {
    //                 const { datas, images } = data;
    //                 // console.log('结果：', data);
    //                 // setSourceData({
    //                 //     data: data?.imageData?.data.data,
    //                 //     images: data.imageData.images || [],
    //                 //     number: frameData.number,
    //                 // });
    //                 // 新的加载形式
    //                 setSourceData({
    //                     data: datas?.[0]?.data,
    //                     images: images,
    //                     number: frameData.number,
    //                 });
    //             })
    //             .catch((exception: any): void => {
    //                 // this.data.exception = exception;
    //                 // this.notify(UpdateReasons.DATA_FAILED);
    //                 throw exception;
    //             });

    //     }
    // };

    // const updateCanvasObjects = (): void => { };
    // const updateCanvas = (): void => {
    //     // if (frameData !== null) {
    //     //     canvasInstance.setup(
    //     //         frameData,
    //     //         annotations.filter((e) => e.objectType !== ObjectType.TAG),
    //     //     );
    //     //     // setOldJson([...annotations]);
    //     //     // canvasInstance.setupIssueRegions(frameIssues);
    //     // }
    // };

    const onCanvasCancel = (): void => {
        onResetCanvas();
    };

    const isSafePoints = (points: number[]): boolean => {
        if (points && points.length) {
            const length = points[6];
            const width = points[7];
            const height = points[8];
            if (length > 0.1 && width > 0.1 && height > 0.1) {
                return true;
            }
        }
        return false;
    }

    const onCanvasShapeDrawn = (event: any): void => {
        if (!event.detail.continue) {
            onShapeDrawn();
        }

        const { state, duration } = event.detail;
        const isDrawnFromScratch = !state.label;
        if (isDrawnFromScratch) {
            jobInstance.logger.log(LogType.drawObject, { count: 1, duration });
        } else {
            jobInstance.logger.log(LogType.pasteObject, { count: 1, duration });
        }

        if (state.shapeType === ShapeType.CUBOID && !isSafePoints(state.points)) {
            message.error('目标物生成过小，如果需要，请换用另一种画框方式');
            return;
        }

        // if (type === 'issue') {
        //     onCanvasDrawnIssue(event);
        //     return;
        // }

        // const { activeObjectType } = props;
        // console.log('创建类型：', state);

        // 车道线暂时只支持Shape
        if (state.shapeType === ShapeType.laneline) {
            state.objectType = ObjectType.SHAPE;
        }

        state.objectType = state.objectType || activeObjectTypeRef.current;
        state.label = state.label || jobInstance.labels.filter((label: any) => label.id === activeLabelID)[0];
        state.occluded = state.occluded || false;
        state.frame = frame;
        state.zOrder = 0;
        if (state.label.parentLabelId) {
            message.error('标签不匹配--使用了2D标签，请重新选择标签!');
            return;
        }
        const objectState = new ObjectState(state);
        onCreateAnnotations(jobInstance, frame, [objectState]);
    };

    // 投影到视角
    const onCanvasProjection = (): void => {
        // if (activatedStateID) {
        //     const state = annotations.find((ann) => ann.clientID === activatedStateID);
        //     if (state) {

        //         if (subAnnotations.find(ann => ann.clientID === activatedStateID)) {
        //             message.info(t('workspace.canvas.canvas3D2D.projectionExists'));
        //             return;
        //         }


        //         jobInstance.logger.log(LogType.drawObject, { count: 1 });
        //         // 获取相机列表，有方向字段时，以方向为相机列表。
        //         // 没有方向时，取相机内参列表顺序。
        //         const directions: string[] = Array.isArray(jobInstance.direction) && jobInstance.direction.length
        //             ? jobInstance.direction
        //             : (jobInstance.innerParam?.map((_: any, index: number) => `${defaultCameraName}${index}`) || []);


        //         // console.log('相机列表：', directions);

        //         dispatch(projectionAnnotation([state.clientID], directions))
        //     }
        // }
    }

    const onCanvasClick = (e: MouseEvent): void => {
        const { onUpdateContextMenu } = props;
        if (contextMenuVisibility) {
            onUpdateContextMenu(false, e.clientX, e.clientY, ContextMenuType.CANVAS_SHAPE);
        }
    };

    const changeCanvasConfigure = (): void => {
        canvasInstance.configure({
            forceDisableEditing: disableEdit || workspace === Workspace.REVIEW_WORKSPACE,
            activeModel,
            hasAnimation: true,
        });
    };

    // 投影
    const onProjection = (event: any): void => {
        const { clientID, points, count, shapeType, length } = event.detail;
        if (clientID && shapeType === ShapeType.CUBOID && points) {
            const [length, width, height] = points;
            setProjectInfo({
                clientID,

                length,
                width,
                height,

                count,
                shapeType,
            });
        } else if (clientID && shapeType === ShapeType.laneline && length) {
            setProjectInfo({
                clientID,
                length,
                shapeType,
            });
        } else {
            setProjectInfo(undefined);
        }
    };

    const initialSetup = (): void => {
        const canvasInstanceDOM = canvasInstance.html() as ViewsDOM;
        // changeCanvasConfigure();
        canvasInstanceDOM.perspective.addEventListener('canvas.setup', onCanvasSetup);
        canvasInstanceDOM.perspective.addEventListener('canvas.canceled', onCanvasCancel);
        canvasInstanceDOM.perspective.addEventListener('canvas.birdEyeModeStart', onBridEyeModeStart);
        canvasInstanceDOM.perspective.addEventListener('canvas.birdEyeModeStop', onBridEyeModeDone);
        canvasInstanceDOM.perspective.addEventListener('canvas.reviewstart', onCanvasReviewStart);
        canvasInstanceDOM.perspective.addEventListener('canvas.reviewstop', onCanvasReviewDone);
        canvasInstanceDOM.perspective.addEventListener('canvas.drawnissue', onCanvasDrawnIssue);
        canvasInstanceDOM.perspective.addEventListener('canvas.activeComplete', onProjection);
        canvasInstanceDOM.perspective.addEventListener('canvas.moveObjectStart', onChangeMoveObject);
        canvasInstanceDOM.perspective.addEventListener('canvas.moveObjectEnd', onChangeMoveObject);
    };

    const keyControlsKeyDown = (key: KeyboardEvent): void => {
        canvasInstance.keyControls(key);
    };

    const keyControlsKeyUp = (key: KeyboardEvent): void => {
        if (key.code === 'ControlLeft') {
            canvasInstance.keyControls(key);
        }
    };

    const onCanvasShapeSelected = (event: any): void => {
        const { clientID, issueID } = event.detail;
        const { onActivateObject, onSelectIssue, onCollapseStates } = props;
        console.time('选中总耗时');

        onActivateObject(clientID, undefined, issueID);

        // setTimeout(() => {
        //     if (issueID) {
        //         // canvasInstance.activateIssue(issueID);

        //         onSelectIssue(issueID);
        //         // canvasInstance.activateIssue(issueID);
        //     } else {
        //         onSelectIssue(null);
        //         // canvasInstance.activate(clientID);
        //     }

        if (clientID) {
            const selectStates = annotations.filter((state) => state.clientID === clientID);
            onCollapseStates(selectStates || [], false);
        }
        // })
        console.timeEnd('选中总耗时');
    };

    const onCanvasEditDone = (event: any): void => {
        const { onEditShape, onUpdateAnnotations } = props;
        onEditShape(false);
        const { state, points } = event.detail;
        state.points = points;
        onUpdateAnnotations([state]);
    };

    const onCanvasIssueEditDone = (event: any): void => {
        // const { onEditShape, onUpdateAnnotations } = props;
        // onEditShape(false);
        // const { state, points } = event.detail;
        // state.points = points;
        // onUpdateAnnotations([state]);

        const { onUpdateIssue } = props;
        const { issue, points } = event.detail;
        issue.points = points;
        onUpdateIssue(issue.id, points);
    };

    const getCameraParamsOther = () => {
        // const cameraCalibs = [...jobInstance.innerParam];
        // const cameraToBumpers = [...jobInstance.outerParam];
        const cameraParams: CameraDistortionParameter[] = jobInstance?.distortionParam.map(
            (item: number[], index: number) => ({
                cameraType: jobInstance?.cameraTypes[index],
                k1: item?.[0],
                k2: item?.[1],
                k3: item?.[2],
                p1: item?.[3],
                p2: item?.[4],
                k4: item?.[5],
                k5: item?.[6],
                k6: item?.[7],
                s1: item?.[8],
                s2: item?.[9],
            }),
        );

        const needInvert = [...jobInstance.outerReverse];

        return {
            intrinsics: jobInstance.innerParam,
            extrinsics: jobInstance.outerParam,
            distcoeffs: cameraParams,
            needInvert: needInvert,
        }

    };

    const getCameraParams = (): void => {
        const {
            intrinsics,
            extrinsics,
            distcoeffs,
            needInvert,
        } = jobInstance instanceof Job ? jobInstance.getCameraParams(frame) : getCameraParamsOther();


        //  多视角时，则认为只需要设置标注范围；
        //  单视角时，才会读取标注角度等参数。
        const pcdParameter: PcdParameter = {
            cameraRadius: jobInstance?.labelRange,

            viewStartMiddleAngle: jobInstance?.startRadian,
            viewAngle: jobInstance?.labelEngle,
        };


        if (intrinsics.length !== extrinsics.length) {
            notification.error({
                message: '相机参数错误',
                description: '相机内参与外参数量不一致，可能无法正确投影至图片',
            });
        }

        canvasInstance.getCameraParams(intrinsics, extrinsics, distcoeffs, pcdParameter, needInvert);
    };

    useEffect(() => {
        getCameraParams();
    }, [frame]);

    useEffect(() => {
        const canvasInstanceDOM = canvasInstance.html();

        // if (
        //     perspectiveView &&
        //     perspectiveView.current &&
        //     topView &&
        //     topView.current &&
        //     sideView &&
        //     sideView.current &&
        //     frontView &&
        //     frontView.current
        // ) {
        //     perspectiveView.current.appendChild(canvasInstanceDOM.perspective);
        //     topView.current.appendChild(canvasInstanceDOM.top);
        //     sideView.current.appendChild(canvasInstanceDOM.side);
        //     frontView.current.appendChild(canvasInstanceDOM.front);
        //     const canvas3dContainer = document.getElementById('canvas3d-container');
        //     if (canvas3dContainer) {
        //         const width = canvas3dContainer.clientWidth / 3;
        //         setViewSize({
        //             type: 'set',
        //             data: {
        //                 fullHeight: canvas3dContainer.clientHeight,
        //                 fullWidth: canvas3dContainer.clientWidth,
        //                 vertical: canvas3dContainer.clientHeight / 1.5,
        //                 horizontal: canvas3dContainer.clientHeight / 3,
        //                 top: width,
        //                 side: width,
        //                 front: width,
        //             },
        //         });
        //     }
        // }
        if (
            perspectiveView &&
            perspectiveView.current
        ) {
            perspectiveView.current.appendChild(canvasInstanceDOM.perspective);
        }

        document.addEventListener('keydown', keyControlsKeyDown);
        document.addEventListener('keyup', keyControlsKeyUp);

        initialSetup();
        // updateCanvas();
        animateCanvas();

        return () => {
            canvasInstanceDOM.perspective.removeEventListener('canvas.setup', onCanvasSetup);
            canvasInstanceDOM.perspective.removeEventListener('canvas.canceled', onCanvasCancel);
            canvasInstanceDOM.perspective.removeEventListener('canvas.birdEyeModeStart', onBridEyeModeStart);
            canvasInstanceDOM.perspective.removeEventListener('canvas.birdEyeModeStop', onBridEyeModeDone);
            canvasInstanceDOM.perspective.removeEventListener('canvas.reviewstart', onCanvasReviewStart);
            canvasInstanceDOM.perspective.removeEventListener('canvas.reviewstop', onCanvasReviewDone);
            canvasInstanceDOM.perspective.removeEventListener('canvas.drawnissue', onCanvasDrawnIssue);
            canvasInstanceDOM.perspective.removeEventListener('canvas.positionIn2D', onProjection);
            canvasInstanceDOM.perspective.removeEventListener('canvas.moveObjectStart', onChangeMoveObject);
            canvasInstanceDOM.perspective.removeEventListener('canvas.moveObjectEnd', onChangeMoveObject);
            document.removeEventListener('keydown', keyControlsKeyDown);
            document.removeEventListener('keyup', keyControlsKeyUp);
            cancelAnimationFrame(animateId.current);
        };
    }, []);

    useEffect(() => {
        changeCanvasConfigure();
    }, [disableEdit, activeModel]);

    useEffect(() => {
        if ((canvasInstance as Canvas3d).mode() === Mode.IDLE || (canvasInstance as Canvas3d).mode() === Mode.birdEye) {
            (canvasInstance as Canvas3d).activate(activatedStateID, selectIssueID);
        }
    }, [activatedStateID, selectIssueID]);

    // useEffect(() => {
    //     if ((canvasInstance as Canvas3d).mode() === 'idle') {
    //         (canvasInstance as Canvas3d).activate(null, selectIssueID);
    //     }
    // }, [selectIssueID]);

    const updateShapesView = (): void => {
        (canvasInstance as Canvas3d).configureShapes({
            opacity,
            outlined,
            outlineColor,
            selectedOpacity,
            colorBy,
        });
    };

    const onContextMenu = (event: any): void => {
        const { onUpdateContextMenu, onActivateObject } = props;
        onActivateObject(event.detail.clientID);
        onUpdateContextMenu(
            event.detail.clientID !== null,
            event.detail.clientX,
            event.detail.clientY,
            ContextMenuType.CANVAS_SHAPE,
        );
    };

    const onResize = (): void => {
        setViewSize({
            type: 'resize',
        });
    };

    const onCanvasObjectsGroupped = (event: any): void => {
        const { onGroupAnnotations, onGroupObjects } = props;

        onGroupObjects(false);

        const { states } = event.detail;
        onGroupAnnotations(jobInstance, frame, states);
    };

    const unRemoveOther = () => {
        const canvasInstanceDOM = canvasInstance.html() as ViewsDOM;
        canvasInstanceDOM.perspective.removeEventListener('canvas.drawn', onCanvasShapeDrawn);
        canvasInstanceDOM.perspective.removeEventListener('canvas.selected', onCanvasShapeSelected);
        canvasInstanceDOM.perspective.removeEventListener('canvas.edited', onCanvasEditDone);
        canvasInstanceDOM.perspective.removeEventListener('canvas.contextmenu', onContextMenu);
        canvasInstanceDOM.perspective.removeEventListener('click', onCanvasClick);
        canvasInstanceDOM.perspective.removeEventListener('canvas.fit', onResize);
        canvasInstanceDOM.perspective.removeEventListener('canvas.groupped', onCanvasObjectsGroupped);
        canvasInstanceDOM.perspective.removeEventListener('canvas.issueedited', onCanvasIssueEditDone);
        window.removeEventListener('resize', onResize);
    };

    const updateOther = () => {
        const canvasInstanceDOM = canvasInstance.html() as ViewsDOM;
        canvasInstanceDOM.perspective.addEventListener('canvas.drawn', onCanvasShapeDrawn);
        canvasInstanceDOM.perspective.addEventListener('canvas.selected', onCanvasShapeSelected);
        canvasInstanceDOM.perspective.addEventListener('canvas.edited', onCanvasEditDone);
        canvasInstanceDOM.perspective.addEventListener('canvas.contextmenu', onContextMenu);
        canvasInstanceDOM.perspective.addEventListener('click', onCanvasClick);
        canvasInstanceDOM.perspective.addEventListener('canvas.fit', onResize);
        canvasInstanceDOM.perspective.addEventListener('canvas.groupped', onCanvasObjectsGroupped);
        canvasInstanceDOM.perspective.addEventListener('canvas.issueedited', onCanvasIssueEditDone);
        window.addEventListener('resize', onResize);
    };

    const screenKeyControl = (code: CameraAction, altKey: boolean, shiftKey: boolean): void => {
        canvasInstance.keyControls(new KeyboardEvent('keydown', { code, altKey, shiftKey }));
    };

    const onFocus = (view: ActiveView): void => {
        if (activatedStateID) {
            onChangeActiveView(view);
        }
    };

    const onBlur = (view: ActiveView): void => {
        onChangeActiveView(ActiveView.default);
    };

    const updatePointCloudOrObjectsOrIssues = async () => {
        // await (frameData as FrameData).data();
        const data = await (frameData as FrameMeta).data();
        // console.log('当前页面：', data);
        try {
            if (data) {
                const pcd = data.pcds[0];
                canvasInstance.setupPointCloudOrObjectsOrIssues(frame, pcd, annotations)
            }
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        updateShapesView();
    }, [opacity, outlined, outlineColor, selectedOpacity, colorBy]);

    useEffect(() => {
        updateOther();
        return () => {
            unRemoveOther();
        };
    }, [frameData, activeLabelID, annotations, contextMenuVisibility]);

    // useEffect(() => {
    //     updateCanvas();
    // }, [frameData, activeLabelID, contextMenuVisibility]);

    // useEffect(() => {
    //     // 属性没有变更时，跳过更新点云视图

    //     const keys = [
    //         'id',
    //         'label',
    //         'frame',
    //         'objectType',
    //         'shapeType',
    //         'source',
    //         'clientID',
    //         'serverID',
    //         'color',
    //         'hidden',
    //         'points',
    //         'rotation',
    //         'group',
    //         'zOrder',
    //         'outside',
    //         'keyframe',
    //         'occluded',
    //         'lock',
    //         'pinned',
    //     ];
    //     const newJson = JSON.stringify(annotations, keys);
    //     if (oldJson !== newJson) {
    //         updateCanvas();
    //     }
    //     setOldJson(newJson);
    // }, [annotations]);

    useEffect(() => {
        // 新的
        if (jobInstance?.isNotOldTask && jobInstance?.isNotOldTask()) {
            updatePointCloudOrObjectsOrIssues();
        }
        // return () => {
        //     frameData?.clear();
        // }
    }, [frame, frameData, annotations]);

    // // 点云及图片数据变更, 本页面储存并传递要发送的数据
    // useEffect(() => {
    //     if (!jobInstance?.isNotOldTask || !jobInstance?.isNotOldTask()) {
    //         updateCanvasPointCloud();
    //     }
    // }, [frameData]);

    // useEffect(() => {
    //     if (!jobInstance?.isNotOldTask || !jobInstance?.isNotOldTask()) {
    //         // 本页面储存的数据变更
    //         if (sourceData && sourceData.data) {
    //             const objectURL = URL.createObjectURL(sourceData.data as Blob);
    //             canvasInstance.init(objectURL, sourceData.number).then();
    //             URL.revokeObjectURL(objectURL);
    //         }
    //     }
    // }, [sourceData]);

    useEffect(() => {
        if (!jobInstance?.isNotOldTask || !jobInstance?.isNotOldTask()) {
            if (canvasIsReady) {
                // 对象列表变更
                const att = annotations.filter(item => item.objectType !== ObjectType.TAG);
                canvasInstance.setup(att, true);
            }
            // console.log('更新对象列表：', annotations);
        }
    }, [annotations, canvasIsReady]);

    useEffect(() => {
        onResize();
    }, [projectInfo]);

    // console.log('视图：', perspective);

    // const ArrowGroup = (): ReactElement => (
    //     <span className='cvat-canvas3d-perspective-arrow-directions'>
    //         <CVATTooltip title='Shift+Arrow Up' placement='topRight'>
    //             <button
    //                 data-cy='arrow-up'
    //                 onClick={() => screenKeyControl(CameraAction.TILT_UP, false, true)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-arrow-directions-icons-up'
    //             >
    //                 <ArrowUpOutlined className='cvat-canvas3d-perspective-arrow-directions-icons-color' />
    //             </button>
    //         </CVATTooltip>
    //         <br />
    //         <CVATTooltip title='Shift+Arrow Left' placement='topRight'>
    //             <button
    //                 onClick={() => screenKeyControl(CameraAction.ROTATE_LEFT, false, true)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-arrow-directions-icons-bottom'
    //             >
    //                 <ArrowLeftOutlined className='cvat-canvas3d-perspective-arrow-directions-icons-color' />
    //             </button>
    //         </CVATTooltip>
    //         <CVATTooltip title='Shift+Arrow Bottom' placement='topRight'>
    //             <button
    //                 onClick={() => screenKeyControl(CameraAction.TILT_DOWN, false, true)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-arrow-directions-icons-bottom'
    //             >
    //                 <ArrowDownOutlined className='cvat-canvas3d-perspective-arrow-directions-icons-color' />
    //             </button>
    //         </CVATTooltip>
    //         <CVATTooltip title='Shift+Arrow Right' placement='topRight'>
    //             <button
    //                 onClick={() => screenKeyControl(CameraAction.ROTATE_RIGHT, false, true)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-arrow-directions-icons-bottom'
    //             >
    //                 <ArrowRightOutlined className='cvat-canvas3d-perspective-arrow-directions-icons-color' />
    //             </button>
    //         </CVATTooltip>
    //     </span>
    // );

    // const ControlGroup = (): ReactElement => (
    //     <span className='cvat-canvas3d-perspective-directions'>
    //         <CVATTooltip title='Alt+U' placement='topLeft'>
    //             <button
    //                 onClick={() => screenKeyControl(CameraAction.MOVE_UP, true, false)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-directions-icon'
    //             >
    //                 U
    //             </button>
    //         </CVATTooltip>
    //         <CVATTooltip title='Alt+I' placement='topLeft'>
    //             <button
    //                 onClick={() => screenKeyControl(CameraAction.ZOOM_IN, true, false)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-directions-icon'
    //             >
    //                 I
    //             </button>
    //         </CVATTooltip>
    //         <CVATTooltip title='Alt+O' placement='topLeft'>
    //             <button
    //                 onClick={() => screenKeyControl(CameraAction.MOVE_DOWN, true, false)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-directions-icon'
    //             >
    //                 O
    //             </button>
    //         </CVATTooltip>
    //         <br />
    //         <CVATTooltip title='Alt+J' placement='topLeft'>
    //             <button
    //                 onClick={() => screenKeyControl(CameraAction.MOVE_LEFT, true, false)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-directions-icon'
    //             >
    //                 J
    //             </button>
    //         </CVATTooltip>
    //         <CVATTooltip title='Alt+K' placement='topLeft'>
    //             <button
    //                 onClick={() => screenKeyControl(CameraAction.ZOOM_OUT, true, false)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-directions-icon'
    //             >
    //                 K
    //             </button>
    //         </CVATTooltip>
    //         <CVATTooltip title='Alt+L' placement='topLeft'>
    //             <button
    //                 onClick={() => screenKeyControl(CameraAction.MOVE_RIGHT, true, false)}
    //                 type='button'
    //                 className='cvat-canvas3d-perspective-directions-icon'
    //             >
    //                 L
    //             </button>
    //         </CVATTooltip>
    //     </span>
    // );

    // let points: number[] = [];
    // if (pointBy3D && activatedStateID) {
    //     points = pointBy3D[activatedStateID] as number[];
    // }

    const getShow = (type?: string): JSX.Element => {
        // if (projectInfo) {
        //     if (type === 'side') {
        //         return (
        //             <div className={acitveView === ActiveView.Side ? 'canvas3d-active' : ''}>
        //                 {/* {t('workspace.content.view.object_lenght', {
        //                     length: projectInfo?.length?.toFixed(2),
        //                     width: projectInfo?.width?.toFixed(2),
        //                     height: projectInfo?.height?.toFixed(2),
        //                 })}
        //                 &nbsp;&nbsp;
        //                 {t('workspace.content.view.object_cloud_point')}
        //                 {projectInfo?.count || '--'} */}
        //                 {t('workspace.content.view.object_lenght')}
        //                 {projectInfo?.length?.toFixed(2)}
        //                 &nbsp;
        //                 {t('workspace.content.view.object_height')}
        //                 {projectInfo?.height?.toFixed(2)}
        //                 &nbsp;&nbsp;
        //                 {t('workspace.content.view.object_cloud_point')}
        //                 {projectInfo?.count || '--'}
        //             </div>
        //         );
        //     }
        //     if (type === 'front') {
        //         return (
        //             <div className={acitveView === ActiveView.Front ? 'canvas3d-active' : ''}>
        //                 {t('workspace.content.view.object_width')}
        //                 {projectInfo?.width?.toFixed(2)}
        //                 &nbsp;
        //                 {t('workspace.content.view.object_height')}
        //                 {projectInfo?.height?.toFixed(2)}
        //             </div>
        //         );
        //     }
        //     return (
        //         <div className={acitveView === ActiveView.Top ? 'canvas3d-active' : ''}>
        //             {t('workspace.content.view.object_lenght')}
        //             {projectInfo?.length?.toFixed(2)}
        //             &nbsp;
        //             {t('workspace.content.view.object_width')}
        //             {projectInfo?.width?.toFixed(2)}
        //         </div>
        //     );
        // }
        if (projectInfo && projectInfo.shapeType === ShapeType.CUBOID) {
            return <div className={acitveView === ActiveView.Side ? 'canvas3d-active' : ''}>
                {t('workspace.content.view.object_lenght')}
                {projectInfo?.length?.toFixed(2)}
                &nbsp;
                {t('workspace.content.view.object_width')}
                {projectInfo?.width?.toFixed(2)}
                &nbsp;
                {t('workspace.content.view.object_height')}
                {projectInfo?.height?.toFixed(2)}
                &nbsp;&nbsp;
                {t('workspace.content.view.object_cloud_point')}
                {projectInfo?.count || '--'}
            </div>;
        }
        if (projectInfo && projectInfo.shapeType === ShapeType.laneline) {
            return <div className={acitveView === ActiveView.Side ? 'canvas3d-active' : ''}>
                {t('workspace.content.view.object_lenght')}
                {projectInfo?.length?.toFixed(2)}
            </div>;
        }
        return <></>
    };

    // console.log('重置尺寸：', viewSize);

    // const rightWidth = '30%';
    // const rightHeight = contextImageHidden ? '100%' : 'calc(100% - 280px)';
    // const viewMarginTop = contextImageHidden ? '0' : "280px";


    const subKeyMap = {
        laneline_add_a_point: keyMap.laneline_add_a_point,
        laneline_delete_a_point: keyMap.laneline_delete_a_point,
        projection: keyMap.projection,
    };

    const handlers = {
        laneline_add_a_point: (event: KeyboardEvent | undefined) => {
            canvasInstance.updateLaneline({
                type: 'add'
            });
        },
        laneline_delete_a_point: (event: KeyboardEvent | undefined) => {
            canvasInstance.updateLaneline({
                type: 'delete'
            });
        },
        projection: (event: KeyboardEvent | undefined) => {
            event?.preventDefault();

            onCanvasProjection();
            // console.log('投影到对象中：', activatedStateID);
        },
    };

    const objectState = annotations.find(obj => obj.clientID === activatedStateID);

    return (
        <Content className='aatp_canvas' id='canvas3d-container'>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <div className='aatp-canvas3d'>
                <ContextImages />
                {/* <ResizableBox
                className='cvat-resizable'
                width={viewSize.fullWidth - viewSize.top}
                height={Infinity}
                axis='x'
                handle={<span className='cvat-resizable-handle-horizontal' />}
            // onResize={(e: SyntheticEvent) => setViewSize({ type: ViewType.PERSPECTIVE, e })}
            > */}
                <div
                    className='cvat-canvas3d-perspective'
                    id='cvat-canvas3d-perspective'
                    style={{ width: `calc( 100% - ${rightWidth}px )` }}
                >
                    {frameFetching ? (
                        <svg id='cvat_canvas_loading_animation'>
                            <circle id='cvat_canvas_loading_circle' r='30' cx='50%' cy='50%' />
                        </svg>
                    ) : null}
                    <div className='cvat-canvas-container cvat-canvas-container-overflow' ref={perspectiveView} />
                    {/* <ArrowGroup />
                    <ControlGroup /> */}
                </div>
                {/* </ResizableBox> */}

                <ThreeCanvasWrapper
                    canvasInstance={canvasInstance}
                    activatedStateID={activatedStateID}
                    acitveView={acitveView}
                    onChangeActiveView={onChangeActiveView}
                    defaultWdith={rightWidth}
                    contextImageHidden={contextImageHidden}
                    onChangeWidth={setRightWidth}
                />

            </div>
            <div className='aatp_image_annotation'>
                <AttributeAnnotationImage />
                <div>
                    <CanvasFooterComponent
                        objectState={objectState}
                        frame={frame}
                        frameData={frameData}
                        canvasInstance={canvasInstance}
                    />
                </div>
            </div>
            {/* {(!canvasIsReady) && <Spin className='canvas_loading' size='large' spinning={true} tip='点云加载中...' />} */}
        </Content>
    );
};

export default React.memo(CanvasWrapperComponent);
