/*
 * @Author: swxy
 * @Date: 2023-08-05 09:47:01
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import React, { FC, useEffect, useState } from "react";
import ReactDom from "react-dom";
import "../styles.scss";

export const ElementSizeMinStandard: FC<{
    width: number,
    height: number,
    strokeWidth?: number,
    isCircle?: boolean
}> = ({
    width = 25,
    height = 25,
    strokeWidth = 1,
    isCircle = false
}) => {

        const [position, setPosition] = useState({ top: -200, left: -200 });

        const mousemove = (e: MouseEvent) => {
            // console.log('移动事件：', e);
            setPosition({
                top: e.offsetX - (height / 2),
                left: e.offsetY - (width / 2),
            })
        }

        useEffect(() => {
            const parent = document.getElementById('cvat_canvas_content')
            parent?.addEventListener('mousemove', mousemove);
            return () => {
                parent?.removeEventListener('mousemove', mousemove);
            }
        }, [])

        const parent = document.getElementById('cvat_canvas_content')

        if (parent && isCircle) {
            return ReactDom.createPortal(
                <circle
                    style={{
                        pointerEvents: 'none',
                    }}
                    r={width}
                    cx={position.top}
                    cy={position.left}
                    strokeOpacity={1}
                    strokeWidth={strokeWidth}
                    // color="#00ff37a6"
                    fillOpacity={0}
                    stroke="#00ff37a6"
                />,
                parent);
        }

        if (parent) {
            return ReactDom.createPortal(
                // <div className="review_min_standard" style={{
                //     top: position.top,
                //     left: position.left,
                //     width: width,
                //     height: height,
                // }} />,
                <rect
                    style={{
                        pointerEvents: 'none',
                    }}
                    width={width}
                    height={height}
                    x={position.top}
                    y={position.left}
                    strokeOpacity={1}
                    strokeWidth={strokeWidth}
                    // color="#00ff37a6"
                    fillOpacity={0}
                    stroke="#00ff37a6"
                />,
                parent);
        }

        return <></>
    }
