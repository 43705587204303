/*
 * @Author: swxy
 * @Date: 2022-12-13 10:58:10
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
export default {
    'error.initialized': 'The current point cloud has been initialized',
    'error.pathError': 'The current point cloud path does not exist',
    'error.drawAlready': 'Drawing has been already started',
    'error.busyError': 'Canvas is busy. Action: {{mode}}',
};
