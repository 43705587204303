/*
 * @Author: swxy
 * @Date: 2023-06-07 11:36:32
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { Object3D } from 'three';
class BaseObject {
    public clientID?: number;
    public type = 'BaseObject';

    public perspective: Object3D;
    public top: Object3D;
    public side: Object3D;
    public front: Object3D;

    public set userData(userData: any) {
        this.perspective.userData = userData;
        this.top.userData = userData;
        this.side.userData = userData;
        this.front.userData = userData;
    }

    // public object: THREE.Object3D = new Object3D;
}

export default BaseObject;