/*
 * @Author: swxy
 * @Date: 2022-07-28 15:12:01
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import * as THREE from 'three';
import CONST from './consts';

export const resizeRendererToDisplaySize = (
    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
    renderer: THREE.WebGLRenderer,
): void => {
    const canvas = renderer.domElement;
    if (!canvas.parentElement) return;
    const width = canvas.parentElement.clientWidth;
    const height = canvas.parentElement.clientHeight;
    const needResize = canvas.clientWidth !== width || canvas.clientHeight !== height;
    if (needResize && camera) {
        if (camera instanceof THREE.PerspectiveCamera) {
            camera.aspect = width / height;
        } else {
            const topViewFactor = 0;
            const viewSize = CONST.ZOOM_FACTOR;
            const aspectRatio = width / height;
            if (!(camera instanceof THREE.PerspectiveCamera)) {
                camera.left = (-aspectRatio * viewSize) / 2 - topViewFactor;
                camera.right = (aspectRatio * viewSize) / 2 + topViewFactor;
                camera.top = viewSize / 2 + topViewFactor;
                camera.bottom = -viewSize / 2 - topViewFactor;
            }
            camera.near = -50;
            camera.far = 50;
        }
        renderer.setSize(width, height);
        camera.updateProjectionMatrix();
    }
};

export const isLeft = (a: any, b: any, c: any): boolean => {
    // For reference
    // A
    // |\                // A = Rotation Center
    // | \               // B = Previous Frame Position
    // |  C              // C = Current Frame Position
    // B
    return (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x) > 0;
};
