/*
 * @Author: swxy
 * @Date: 2023-10-28 17:38:58
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { api_queryFramesInfo } from './job/frame';
import { api_queryJobInfo, api_queryLabels } from './job/job';
import { api_queryProjectInfo } from './project/project';
import { api_queryTaskInfo } from './task/task';

/**
 * Service - 接口服务
 * 用于一些可能的，需要接口组合/再写的功能。
 *
 * 后续考虑将service层独立
 */
class Service {
    /**
     * 项目内所需要用到的接口
     */
    public project = {
        /**
         * 获取当前项目信息
         * @param id
         */
        get: async (id: number) => {
            return await api_queryProjectInfo({ id });
        },
    };
    /**
     * 任务内所需要用到的接口
     */
    public task = {
        /**
         * 获取当前任务信息
         * @param id
         */
        get: async (id: number) => {
            return await api_queryTaskInfo({ id });
        },
    };
    /**
     * 题包内所需要用到的接口
     */
    public job = {
        /**
         * 获取当前题包信息，包含帧信息列表
         * @param id
         */
        get: async (id: number) => {
            return await api_queryJobInfo({ id });
        },
    };

    public frames = {
        get: async (jobId: number) => {
            return await api_queryFramesInfo({ jobId });
        },
    };

    public label = {
        get: async (projectId: number) => {
            return await api_queryLabels({ projectId });
        },
    };
}

const service = new Service();
export default service;

export const service_getJob = service.job.get;
export const service_getFrames = service.frames.get;
