/*
 * @Author: swxy
 * @Date: 2023-08-25 21:02:41
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { post } from 'utils/request';

export const queryProjectsInfo = async (...data: any) => {
    const result = await post('/api/engineProject/selectRelative', ...data);
    return result;
};

export const queryProjectInfo = async (...data: any) => {
    const result = await post('/api/engineProject/selectDetailByPrimary', ...data);
    return result;
};

export const api_queryProjectInfo = async (...data: any) => {
    const result = await post('/api/engineProject/selectDetailByPrimary', ...data);
    return result;
};
