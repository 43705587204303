/*
 * @Author: swxy
 * @Date: 2023-01-06 09:32:10
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
    //#region 2023-05-04 待校验
    'error.baseError': 'There were some errors with the service, you can try again later!',
    //#endregion

    'annotation.save.title': 'Saving Job Error!',
    'annotation.save.detail': 'You can record the recent operations and report them to the administrator. \r\nAfter clicking [OK], the page will be refreshed.',


    'register.username_length': 'The username must be more than 5 characters.',
    'register.username_char': 'Usernames can only use: numbers, uppercase and lowercase letters in English, “-” and “_”.',
    'error.register.username_invalid': 'Invalid username',

    'error.register.error': 'Registration failed, please try again later!',

    'error.register.password_length': 'The password must be more than 8 characters.',
    'error.register.password_number': 'The password must contain at least one number.',
    'error.register.password_uppercaseChar': 'The password must contain at least one uppercase letter.',
    'error.register.password_lowercaseChar': 'The password must contain at least one lowercase letter.',

    'error.task.uploadImage': ' Image information is abnormal, or the width and height of the image are inconsistent!',
    'error.job.taskInfo': 'Failed to retrieve information!',
};
