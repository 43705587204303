// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { memo } from 'react';
import Menu from 'antd/lib/menu';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Icon, { LinkOutlined, CopyOutlined, BlockOutlined, RetweetOutlined, DeleteOutlined } from '@ant-design/icons';

import { BackgroundIcon, ForegroundIcon, ResetPerspectiveIcon, ColorizeIcon } from 'icons';
import CVATTooltip from 'components/common/cvat-tooltip';
import { ObjectType, ShapeType, ColorBy, DimensionType } from 'reducers/interfaces';
import ColorPicker from './color-picker';
import { useTranslation } from 'react-i18next';

interface Props {
    readonly: boolean;
    serverID: number | undefined;
    locked: boolean;
    shapeType: ShapeType;
    objectType: ObjectType;
    color: string;
    colorBy: ColorBy;
    colorPickerVisible: boolean;
    changeColorShortcut: string;
    copyShortcut: string;
    pasteShortcut: string;
    propagateShortcut: string;
    changeStartFrameShortcut: string;
    toBackgroundShortcut: string;
    toForegroundShortcut: string;
    removeShortcut: string;
    changeColor(value: string): void;
    copy(): void;
    remove(): void;
    propagate(): void;
    changeStartFrame(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    resetCuboidPerspective(): void;
    changeColorPickerVisible(visible: boolean): void;
    changeMenuVisible(visible: boolean): void;
    shouContextMenuItem(): void;
    jobInstance: any;
}

interface ItemProps {
    toolProps: Props;
    content: string;
}

function CreateURLItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { serverID, createURL } = toolProps;
    return (
        <Menu.Item {...rest}>
            <Button disabled={serverID === undefined} type='link' icon={<LinkOutlined />} onClick={createURL}>
                {/* Create object URL */}
                {content}
                {/* 创建当前对象链接 */}
            </Button>
        </Menu.Item>
    );
}

function ShouContextMenuItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { serverID, shouContextMenuItem } = toolProps;
    return (
        <Menu.Item {...rest}>
            <Button disabled={serverID === undefined} type='link' icon={<LinkOutlined />} onClick={shouContextMenuItem}>
                {content}
                {/* Create object URL */}
                {/* 展示右键属性面板 */}
            </Button>
        </Menu.Item>
    );
}

function MakeCopyItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { copyShortcut, pasteShortcut, copy } = toolProps;
    return (
        <Menu.Item {...rest}>
            {/* <CVATTooltip title={`${copyShortcut} and ${pasteShortcut}`}> */}
            <CVATTooltip title={`复制${copyShortcut} 、粘贴 ${pasteShortcut}`}>
                <Button type='link' icon={<CopyOutlined />} onClick={copy}>
                    {/* Make a copy */}
                    {content}
                    {/* 复制 */}
                </Button>
            </CVATTooltip>
        </Menu.Item>
    );
}

function PropagateItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { propagateShortcut, propagate } = toolProps;
    return (
        <Menu.Item {...rest}>
            <CVATTooltip title={`${propagateShortcut}`}>
                <Button type='link' icon={<BlockOutlined />} onClick={propagate}>
                    {/* Propagate */}
                    {/* 多帧复制 */}
                    {content}
                </Button>
            </CVATTooltip>
        </Menu.Item>
    );
}

function StartFrameItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { changeStartFrameShortcut, changeStartFrame } = toolProps;
    return (
        <Menu.Item {...rest}>
            <CVATTooltip title={`${changeStartFrameShortcut}`}>
                <Button type='link' icon={<BlockOutlined />} onClick={changeStartFrame}>
                    {/* 更改起始帧 */}
                    {content}
                </Button>
            </CVATTooltip>
        </Menu.Item>
    );
}

function SwitchOrientationItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { switchOrientation } = toolProps;
    return (
        <Menu.Item {...rest}>
            <Button type='link' icon={<RetweetOutlined />} onClick={switchOrientation}>
                {/* Switch orientation */}
                {/* 切换方向 */}
                {content}
            </Button>
        </Menu.Item>
    );
}

function ResetPerspectiveItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { resetCuboidPerspective } = toolProps;
    return (
        <Menu.Item {...rest}>
            <Button type='link' onClick={resetCuboidPerspective}>
                <Icon component={ResetPerspectiveIcon} />
                {/* Reset perspective */}
                {/* 重置透视 */}
                {content}
            </Button>
        </Menu.Item>
    );
}

function ToBackgroundItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { toBackgroundShortcut, toBackground } = toolProps;
    return (
        <Menu.Item {...rest}>
            <CVATTooltip title={`${toBackgroundShortcut}`}>
                <Button type='link' onClick={toBackground}>
                    <Icon component={BackgroundIcon} />
                    {/* To background */}
                    {/* 背景 */}
                    {content}
                </Button>
            </CVATTooltip>
        </Menu.Item>
    );
}

function ToForegroundItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { toForegroundShortcut, toForeground } = toolProps;
    return (
        <Menu.Item {...rest}>
            <CVATTooltip title={`${toForegroundShortcut}`}>
                <Button type='link' onClick={toForeground}>
                    <Icon component={ForegroundIcon} />
                    {/* To foreground */}
                    {/* 前景 */}
                    {content}
                </Button>
            </CVATTooltip>
        </Menu.Item>
    );
}

function SwitchColorItem(props: ItemProps): JSX.Element {
    const { toolProps, content, ...rest } = props;
    const { color, colorPickerVisible, changeColorShortcut, colorBy, changeColor, changeColorPickerVisible } =
        toolProps;
    return (
        <Menu.Item
            {...rest}
            onClick={(menuInfo) => {
                if (menuInfo && menuInfo.domEvent) {
                    menuInfo.domEvent.stopPropagation();
                }
            }}
        >
            <ColorPicker
                value={color}
                onChange={changeColor}
                visible={colorPickerVisible}
                onVisibleChange={changeColorPickerVisible}
                resetVisible={false}
            >
                <CVATTooltip title={`${changeColorShortcut}`}>
                    <Button type='link'>
                        <Icon component={ColorizeIcon} />
                        {/* {`Change ${colorBy.toLowerCase()} color`} */}
                        {content}
                        {/* {`更改 ${colorBy.toLowerCase()} 的颜色`} */}
                    </Button>
                </CVATTooltip>
            </ColorPicker>
        </Menu.Item>
    );
}

function RemoveItem(props: ItemProps & { deleteTip: string; deletestr: string }): JSX.Element {
    const { toolProps, content, deleteTip, deletestr, ...rest } = props;
    const { removeShortcut, locked, remove, colorBy } = toolProps;
    return (
        <Menu.Item {...rest}>
            <CVATTooltip title={`${removeShortcut}`}>
                <Button
                    type='link'
                    icon={<DeleteOutlined />}
                    onClick={(): void => {
                        if (locked) {
                            Modal.confirm({
                                className: 'cvat-modal-confirm',
                                // title: 'Object is locked',
                                // content: 'Are you sure you want to remove it?',
                                title: content,
                                content: deleteTip,
                                onOk() {
                                    remove();
                                },
                            });
                        } else {
                            remove();
                        }
                    }}
                >
                    {/* Remove */}
                    {deletestr}
                </Button>
            </CVATTooltip>
        </Menu.Item>
    );
}

enum MenuKeys {
    CREATE_URL = 'create_url',
    COPY = 'copy',
    PROPAGATE = 'propagate',
    CHANGE_START_FRAME = 'change_start_frame',
    SWITCH_ORIENTATION = 'switch_orientation',
    RESET_PERSPECIVE = 'reset_perspective',
    TO_BACKGROUND = 'to_background',
    TO_FOREGROUND = 'to_foreground',
    SWITCH_COLOR = 'switch_color',
    REMOVE_ITEM = 'remove_item',
}

const ItemMenu = (props: Props): JSX.Element => {
    const { readonly, shapeType, objectType, colorBy, jobInstance, changeMenuVisible } = props;


    const is2D = jobInstance.dimension === DimensionType.DIM_2D;

    const { t } = useTranslation();

    return (
        <Menu
            className='cvat-object-item-menu'
            selectable={false}
            theme='dark'
            onClick={() => {
                changeMenuVisible(false);
            }}
        >
            <CreateURLItem
                content={t('workspace.side.object.context.create_url')}
                key={MenuKeys.CREATE_URL}
                toolProps={props}
            />
            <ShouContextMenuItem content={t('workspace.side.object.context.right_context_panel')} toolProps={props} />
            {!readonly && (
                <MakeCopyItem key={MenuKeys.COPY} content={t('workspace.side.object.context.copy')} toolProps={props} />
            )}
            {!readonly && (
                <PropagateItem
                    key={MenuKeys.PROPAGATE}
                    content={t('workspace.side.object.context.propagate')}
                    toolProps={props}
                />
            )}
            {objectType === ObjectType.TRACK && !readonly && (
                <StartFrameItem
                    key={MenuKeys.CHANGE_START_FRAME}
                    content={t('workspace.side.object.context.changeStartFrame')}
                    toolProps={props}
                />
            )}
            {is2D && !readonly && [ShapeType.POLYGON, ShapeType.POLYLINE, ShapeType.CUBOID].includes(shapeType) && (
                <SwitchOrientationItem
                    key={MenuKeys.SWITCH_ORIENTATION}
                    content={t('workspace.side.object.context.switch')}
                    toolProps={props}
                />
            )}
            {is2D && !readonly && shapeType === ShapeType.CUBOID && (
                <ResetPerspectiveItem
                    key={MenuKeys.RESET_PERSPECIVE}
                    content={t('workspace.side.object.context.reset')}
                    toolProps={props}
                />
            )}
            {is2D && objectType !== ObjectType.TAG && (
                <ToBackgroundItem
                    key={MenuKeys.TO_BACKGROUND}
                    content={t('workspace.side.object.context.background')}
                    toolProps={props}
                />
            )}
            {is2D && !readonly && objectType !== ObjectType.TAG && (
                <ToForegroundItem
                    key={MenuKeys.TO_FOREGROUND}
                    content={t('workspace.side.object.context.foreground')}
                    toolProps={props}
                />
            )}
            {[ColorBy.INSTANCE, ColorBy.GROUP].includes(colorBy) && (
                <SwitchColorItem
                    key={MenuKeys.SWITCH_COLOR}
                    content={t('workspace.side.object.context.changeColor', { name: colorBy })}
                    toolProps={props}
                />
            )}
            {!readonly && (
                <RemoveItem
                    key={MenuKeys.REMOVE_ITEM}
                    content={t('workspace.side.object.context.create_url')}
                    deleteTip={t('workspace.side.object.context.delete_object_lock_tip')}
                    deletestr={t('workspace.side.object.context.delete')}
                    toolProps={props}
                />
            )}
        </Menu>
    );
}

export default ItemMenu;