/*
 * @Author: swxy
 * @Date: 2022-10-19 18:06:21
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
    //#region 2023-05-04 待校验
    'error.baseError': '服务出现一些错误，您可以稍后重试！',
    //#endregion

    'annotation.save.title': '保存题包出错',
    'annotation.save.detail': '您可以记录下最近几次的操作，报告给管理员。\r\n点击[确定]后，会重新刷新页面。',

    'error.register.username_length': '用户名必须超过5个字符',
    'error.register.username_char': '用户名只能使用：数字、英文大小写字母、"-"、"_"',
    'error.register.username_invalid': '不可用名称',

    'error.register.error': '注册失败，请稍后再试！',

    'error.register.password_length': '密码必须超过8个字符',
    'error.register.password_number': '密码必须包含至少一个数字',
    'error.register.password_uppercaseChar': '密码必须包含至少一个大写字母',
    'error.register.password_lowercaseChar': '密码必须包含至少一个小写字母',

    'error.task.uploadImage': '图片信息异常，或者图片宽高不一致！',
    'error.job.taskInfo': '获取信息出错！',

    //#region 2023-07-15 待校验
    'error.2D3D.sub.createAnnotationsFailed': '创建标注对象错误',
    'error.2D3D.sub.updateAnnotationsFailed': '更新标注对象错误',
    'error.2D3D.sub.removeAnnotationsFailed': '删除标注对象错误',

    'error.2D3D.sub.projectionAnnotationFailed': '投影标注对象错误',
    'error.2D3D.sub.associatedFailed': '关联失败',
    'error.issue.update': '更新批注失败',

    'error.shape.mult_update_attr': '根据序号批量修改属性失败',

    'error.autoAttrAnntation.noData': '自动标注失败，没有自动标注结果',
    //#endregion
};
