/*
 * @Author: swxy
 * @Date: 2023-05-23 14:56:39
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { AmygoResponse as Response, RequestSetting } from 'reducers/interfaces';
import { clearAllSave, getToken, removeToken } from './storage';
import { getVersion } from './utils';
import { message } from 'antd';
import { ServerError } from 'errors/exception';

enum ErrorCode {
    loginOverTime = 1005,
}

/**
 * 重大错误---无法被跳过
 */
const errorMsgByCodeImportant: Record<number, string> = {
    [ErrorCode.loginOverTime]: '登录失效，请重新登录',
};

/**
 * 普通错误---可以被跳过
 */
const errorMsgByCode: Record<number, string> = {
    // [ErrorCode.loginOverTime]: '登录失效，请重新登录',
};

const errorMessage = '服务器正在开小差...';

const analyzingToResponse = async (response: globalThis.Response, skipErrorHandle = false): Promise<any> => {
    const headers = response.headers;
    if (response.status === 401) {
        message.error('登录失效');
        setTimeout(() => {
            // 登录失效
            removeToken();
            location.reload();
        }, 1500);
    } else if (headers.get('Content-Type')?.includes('json')) {
        return await response.json();
    } else if (headers.get('Content-Type')?.includes('html')) {
        return await response.text();
    } else if (headers.get('Content-Type')?.includes('application/octet-stream')) {
        const result = await response.arrayBuffer();
        return new Blob([result]);
    } else if (!response.ok && !skipErrorHandle) {
        return await response.json();
    } else if (
        headers.has('Content-Disposition') ||
        headers.get('Content-Type')?.includes('zip') ||
        headers.get('Content-Type')?.includes('image')
    ) {
        return await response.arrayBuffer();
    }
    return await response.json();
};

const errorHandle = async (
    response: {
        status: number;
        ok: string;
        url: string;
        headers: Record<string, string>;
        data: Response;
    },
    skipErrorHandle = false,
): Promise<void> => {
    let errorMsg = errorMessage;
    const { url, data } = response;
    // 独立验证登录失效，跳过验证无法跳过这个
    if (!data?.success && data.code) {
        switch (data.code) {
            case ErrorCode.loginOverTime:
                errorMsg = errorMsgByCodeImportant[data.code] || errorMsg;
                void message.error(errorMsg);
                setTimeout(() => {
                    // 登录失效
                    removeToken();
                    location.reload();
                }, 1500);
                return;

            default:
                break;
        }
    }

    if (!skipErrorHandle && !data?.success) {
        if (url.includes('login') && data.message?.includes('verifyCode not match!')) {
            errorMsg = '验证码不匹配，请检查！';
            // void message.error(errorMsg);
            // throw new Error(data?.message);
        } else if (data.code) {
            errorMsg = errorMsgByCode[data.code] || data.message || errorMsg;
        } else {
            errorMsg = data.message || errorMsg;
        }
        void message.error(errorMsg);
        throw new ServerError(data?.message, data.code);
    }
};

// const useHeader: Record<string = {
//     'project': 'aatp',
//     'version': getVersion(),
//     'Content-Type': 'application/json',
// }
/**
 * 获取接口header
 * @param header HeadersInit 暂时只考虑Record<string, string>一种情况
 * @returns
 */
const getResquestHeader = (header?: Record<string, string>): HeadersInit => {
    let token = getToken() ?? '';
    // const token = store.get('token');
    token = token.replace(/"/g, '');
    if (header) {
        if (header instanceof Headers) {
            header.set('Authorization', token);
            header.set('project', 'aatp');
            header.set('version', getVersion());
        } else if (Array.isArray(header)) {
            const changeArrayKey = (key: string, value: string): void => {
                const index = header.findIndex(([key1]) => key1 === key);
                if (index >= 0) {
                    header[index] = [key, value];
                } else {
                    header.push([key, value]);
                }
            };
            changeArrayKey('Authorization', token);
            changeArrayKey('project', 'aatp');
            changeArrayKey('version', getVersion());
        } else {
            header.Authorization = token;
            header.project = 'aatp';
            header.version = getVersion();
            header['Content-Type'] = header['Content-Type'] ?? 'application/json';
        }
        return header;
    }

    return {
        Authorization: token,
        project: 'aatp',
        version: getVersion(),
        'Content-Type': 'application/json',
    };
};

export const get = async (
    url: string,
    skipErrorHandle: boolean = false,
    data?: any,
    option?: RequestInit,
): Promise<Response> => {
    const param = encodeURIComponent(data);

    // eslint-disable-next-line @typescript-eslint/promise-function-async
    const result = await fetch(`${url}${param}`, {
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        ...option,
        headers: getResquestHeader(option?.headers as Record<string, string>),
    }).then(async (response) => {
        return await analyzingToResponse(response, skipErrorHandle);
    });
    return result as unknown as Response;
};

export const post = async (
    url: string,
    data?: any,
    config: RequestSetting = {
        responseOriginalData: false,
        skipErrorHandle: false,
    },
    progross?: { title: string; detail: any },
): Promise<any> => {
    const { option, header, skipErrorHandle, responseOriginalData } = config;
    const result = await fetch(url, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        ...option,
        body: data instanceof FormData ? data : JSON.stringify(data), // body data type must match "Content-Type" header
        headers: getResquestHeader(header ?? (option?.headers as Record<string, string>)),
    })
        .then(async (response) => {
            if (progross) {
                progross.title = '接口请求已发出';
                progross.detail = response;
            }
            const result = await analyzingToResponse(response, skipErrorHandle);

            if (progross) {
                progross.title = '接口数据已解析';
                progross.detail = {
                    status: response.status,
                    ok: response.ok,
                    url: response.url,
                    headers: response.headers,
                    data: result,
                };
            }
            // return await response.json();
            return {
                status: response.status,
                ok: response.ok,
                url: response.url,
                headers: response.headers,
                data: result,
            };
        })
        .then((res: any) => {
            const { data } = res;
            // if (!responseOriginalData) {
            void errorHandle(res, skipErrorHandle);
            // }
            return responseOriginalData ? data : data.data;
        })
        .catch((error) => {
            if (progross) {
                progross.title = '接口出错';
                progross.detail = (error as Error).stack;
            }
            void message.error(errorMessage, 3);
            throw new Error(error);
        });
    return result as unknown as Response;
};
