/*
 * @Author: swxy
 * @Date: 2023-08-26 17:27:29
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { DimensionType, ProjectType } from 'utils/ConstType';
import AnnotationHistory, { HistoryActions } from './annotationsHistory';
import { Attribute, Label } from './label';
import FrameData from 'business/frame';
import Job from './job';
import Tag, { ServerTagData } from './tag';
import Shape, {
    CuboidShape,
    EllipseShape,
    ImageShape,
    LanelineShape,
    PointsShape,
    PolygonShape,
    PolylineShape,
    RectangleShape,
    ServerShapeData,
    SplitRectangleShape,
    Bezier2Shape,
} from './shape';
import Track, {
    CuboidTrack,
    EllipseTrack,
    PointsTrack,
    PolygonTrack,
    PolylineTrack,
    RectangleTrack,
    ServerTrackData,
    ServerTrackShapeData,
    SplitRectangleTrack,
    Bezier2Track,
} from './track';
import { colors } from 'utils/enums';
import { ArgumentError, DataError, ScriptingError } from 'errors/exception';
import { ObjectType, ShapeType, Source, SubElement } from 'reducers/interfaces';
import { Annotation } from './annotation';
import ObjectState, { SerializedData } from './objectState';
import Statistics from './Statistics';
import { checkObjectType } from 'common/common';
import { number } from 'prop-types';
import AnnotationsFilter from './annotationFilter';
import { message } from 'antd';

export interface InjectionProps {
    labels: Label[];
    groups: { max: number };
    frameMeta: Record<Frame, FrameData>;
    history: AnnotationHistory;
    groupColors: any;
    serverIDByClientID: Record<number, any>;
    startFrame: number;
    stopFrame: number;
    supplement: number;
}

function shapeFactory(shapeData: ServerShapeData, clientID: number, injection: InjectionProps) {
    const { type } = shapeData;
    const color = colors[clientID % colors.length];

    let shapeModel = null;
    switch (type) {
        case ShapeType.RECTANGLE:
            shapeModel = new RectangleShape(shapeData, clientID, color, injection);
            break;
        case ShapeType.splitRectangle:
            shapeModel = new SplitRectangleShape(shapeData, clientID, color, injection);
            break;
        case ShapeType.POLYGON:
            shapeModel = new PolygonShape(shapeData, clientID, color, injection);
            break;
        case ShapeType.POLYLINE:
            shapeModel = new PolylineShape(shapeData, clientID, color, injection);
            break;
        case ShapeType.POINTS:
            shapeModel = new PointsShape(shapeData, clientID, color, injection);
            break;
        case ShapeType.ELLIPSE:
            shapeModel = new EllipseShape(shapeData, clientID, color, injection);
            break;
        case ShapeType.CUBOID:
            shapeModel = new CuboidShape(shapeData, clientID, color, injection);
            break;
        case ShapeType.IMAGE:
            shapeModel = new ImageShape(shapeData, clientID, color, injection);
            break;
        case ShapeType.laneline:
            shapeModel = new LanelineShape(shapeData, clientID, color, injection);
            break;
        case ShapeType.bezier2:
            shapeModel = new Bezier2Shape(shapeData, clientID, color, injection);
            break;
        default:
            throw new DataError(`An unexpected type of shape "${type}"`);
    }

    return shapeModel;
}

function trackFactory(trackData: ServerTrackData, clientID: number, injection: InjectionProps) {
    if (trackData.shapes.length) {
        const { type } = trackData.shapes[0];
        const color = colors[clientID % colors.length];

        let trackModel = null;
        switch (type) {
            case ShapeType.RECTANGLE:
                trackModel = new RectangleTrack(trackData, clientID, color, injection);
                break;
            case ShapeType.splitRectangle:
                trackModel = new SplitRectangleTrack(trackData, clientID, color, injection);
                break;
            case ShapeType.POLYGON:
                trackModel = new PolygonTrack(trackData, clientID, color, injection);
                break;
            case ShapeType.POLYLINE:
                trackModel = new PolylineTrack(trackData, clientID, color, injection);
                break;
            case ShapeType.POINTS:
                trackModel = new PointsTrack(trackData, clientID, color, injection);
                break;
            case ShapeType.ELLIPSE:
                trackModel = new EllipseTrack(trackData, clientID, color, injection);
                break;
            case ShapeType.CUBOID:
                trackModel = new CuboidTrack(trackData, clientID, color, injection);
                break;
            case ShapeType.laneline:
                throw new DataError(`车道线暂时不支持连续帧 "${type}"`);
            case ShapeType.bezier2:
                trackModel = new Bezier2Track(trackData, clientID, color, injection);
                break;
            default:
                throw new DataError(`连续帧暂不支持该类型： "${type}"`);
        }

        return trackModel;
    }

    console.warn('The track without any shapes had been found. It was ignored.');
    return null;
}

function getDirectionClientIDKey(clientID: number, direction?: string) {
    return direction ? `${clientID}-${direction}` : clientID;
}

interface CollectionProps {
    id: number;
    history: AnnotationHistory;
    job: Job;
    frameMeta: Record<Frame, FrameData>;
    // labels: Label[];
    // startFrame: number;
    // stopFrame: number;
    // frameMeta: FormData;
    // projectType: ProjectType;
}

type Frame = number;

class Collection {
    private job: Job;
    // private id: number;
    // private startFrame: number;
    // private stopFrame: number;
    private frameMeta: Record<Frame, FrameData>;
    // private projectType: ProjectType;
    // private labels: Label[];
    private history: AnnotationHistory;

    private tags: Record<Frame, Tag[]>;
    private shapes: Record<Frame, Shape[]>;
    private tracks: Track[];

    private subTags: Record<Frame, Tag[]>;
    private subShapes: Record<Frame, Shape[]>;
    private subTracks: Track[];

    private objects: Record<Frame, Annotation>;
    private directionObjects: Record<number | string, Tag | Shape | Track>;

    private flush: boolean;

    private groups: { max: number };

    private clientIDByServerID: {
        tag: Record<number, number>;
        shape: Record<number, number>;
        track: Record<number, number>;
    };
    private serverIDByClientID: Record<number, number>;
    private showCamName?: string;
    private annotationsFilter: any; // 筛选

    private injection: InjectionProps;

    private count = 0;

    constructor(data: CollectionProps) {
        // this.id = data.id;
        this.job = data.job;

        this.history = data.history;

        this.tags = {};
        this.shapes = {};
        this.tracks = [];

        this.subTags = {};
        this.subShapes = {};
        this.subTracks = [];

        this.objects = {};
        this.directionObjects = {};
        this.flush = false;

        this.groups = { max: 0 };

        this.clientIDByServerID = {
            tag: {},
            shape: {},
            track: {},
        };
        this.serverIDByClientID = {};

        this.showCamName = undefined;
        this.annotationsFilter = new AnnotationsFilter();
        this.frameMeta = data.frameMeta;

        // this.cacheFrameFull = {};
        // for (let index = this.job.startFrame; index <= this.job.stopFrame; index++) {
        //     this.cacheFrameFull[index] = false;
        // }

        this.injection = {
            labels: this.job.labels,
            frameMeta: this.frameMeta,
            history: this.history,
            groups: this.groups,
            groupColors: {},
            serverIDByClientID: this.serverIDByClientID,
            startFrame: this.job.startFrame,
            stopFrame: this.job.stopFrame,
            supplement: this.job.supplement,
        };
    }

    public import({
        tags,
        shapes,
        tracks,
    }: // trackShapes,
    {
        tags: ServerTagData[];
        shapes: ServerShapeData[];
        tracks: ServerTrackData[];
        // trackShapes: ServerTrackShapeData[];
    }) {
        const result: {
            tags: Tag[];
            shapes: Shape[];
            tracks: Track[];
        } = {
            tags: [],
            shapes: [],
            tracks: [],
        };

        for (const tag of tags) {
            const clientID = ++this.count;
            const color = colors[clientID % colors.length];
            const tagModel = new Tag(tag, clientID, color, this.injection);
            this.tags[tagModel.frame] = this.tags[tagModel.frame] || [];
            this.tags[tagModel.frame].push(tagModel);
            this.objects[clientID] = tagModel;

            if (tag.id) {
                this.clientIDByServerID.tag[tag.id] = clientID;
                this.serverIDByClientID[clientID] = tag.id;
            }

            result.tags.push(tagModel);
        }

        for (const shape of shapes) {
            const clientID = ++this.count;
            const shapeModel = shapeFactory(shape, clientID, this.injection);
            this.shapes[shapeModel.frame] = this.shapes[shapeModel.frame] || [];
            this.shapes[shapeModel.frame].push(shapeModel);
            this.objects[clientID] = shapeModel;

            if (shape.id) {
                this.clientIDByServerID.shape[shape.id] = clientID;
                this.serverIDByClientID[clientID] = shape.id;
            }

            result.shapes.push(shapeModel);
        }

        for (const track of tracks) {
            const clientID = ++this.count;
            const trackModel = trackFactory(track, clientID, this.injection);
            // The function can return null if track doesn't have any shapes.
            // In this case a corresponded message will be sent to the console
            if (trackModel) {
                this.tracks.push(trackModel);
                this.objects[clientID] = trackModel;

                result.tracks.push(trackModel);
            }

            if (track.id) {
                this.clientIDByServerID.track[track.id] = clientID;
                this.serverIDByClientID[clientID] = track.id;
            }
        }

        [...result.tags, ...result.shapes, ...result.tracks].forEach((ann: Annotation) => {
            if (ann.parentID) {
                if (ann.isTag) {
                    ann.updateParentID(this.clientIDByServerID.tag[ann.parentID]);
                } else if (ann.isShape) {
                    ann.updateParentID(this.clientIDByServerID.shape[ann.parentID]);
                } else if (ann.isTrack) {
                    ann.updateParentID(this.clientIDByServerID.track[ann.parentID]);
                }
            }
        });

        return result;
    }

    public importTrackShape(trackShapesByTrackId: Record<number, ServerTrackShapeData[]>) {
        const jsonTrackShapes: Record<number, any> = {};
        [...this.tracks, ...this.subTracks].forEach((track) => {
            if (track.serverID && trackShapesByTrackId[track.serverID]?.length) {
                const addTrackShapes = track.updateShapes(trackShapesByTrackId[track.serverID]);
                jsonTrackShapes[track.serverID] = track.toShapeJson(addTrackShapes);
            }
        });

        return jsonTrackShapes;
    }

    // 回填id
    updateServerID({
        clientID,
        serverID,
        objectType,
        direction = undefined, // 有方向，代表是投影
        trackShapeServerIDByFrame = {},
    }: {
        clientID: number;
        serverID: number;
        objectType: ObjectType;
        direction?: string; // 有方向，代表是投影
        trackShapeServerIDByFrame?: Record<number, number>;
    }) {
        if (direction) {
            const key = getDirectionClientIDKey(clientID, direction);
            this.directionObjects[key].serverID = serverID;
            if (objectType === 'track') {
                for (const frame in trackShapeServerIDByFrame) {
                    if (
                        Object.hasOwnProperty.call(trackShapeServerIDByFrame, frame) &&
                        (this.directionObjects[key] as Track).shapes[frame]
                    ) {
                        // eslint-disable-next-line max-len
                        (this.directionObjects[key] as Track).shapes[frame].serverID = trackShapeServerIDByFrame[frame];
                    }
                }
            }
        } else {
            this.clientIDByServerID[objectType][serverID] = clientID;
            this.serverIDByClientID[clientID] = serverID;
            this.objects[clientID].serverID = serverID;
            if (objectType === 'track') {
                for (const frame in trackShapeServerIDByFrame) {
                    if (
                        Object.hasOwnProperty.call(trackShapeServerIDByFrame, frame) &&
                        (this.objects[clientID] as Track).shapes[frame]
                    ) {
                        (this.objects[clientID] as Track).shapes[frame].serverID = trackShapeServerIDByFrame[frame];
                    }
                }
            }
        }
    }

    // 回填连续帧关键帧id
    updateTrackServerID({
        clientID,
        objectType,
        direction = undefined, // 有方向，代表是投影
        trackShapeServerIDByFrame = {},
    }: {
        clientID: number;
        // serverID: number;
        objectType: ObjectType;
        direction?: string; // 有方向，代表是投影
        trackShapeServerIDByFrame: Record<number, number>;
    }) {
        if (direction) {
            const key = getDirectionClientIDKey(clientID, direction);
            if (objectType === 'track') {
                for (const frame in trackShapeServerIDByFrame) {
                    if (
                        Object.hasOwnProperty.call(trackShapeServerIDByFrame, frame) &&
                        (this.directionObjects[key] as Track).shapes[frame]
                    ) {
                        // eslint-disable-next-line max-len
                        (this.directionObjects[key] as Track).shapes[frame].serverID = trackShapeServerIDByFrame[frame];
                    }
                }
            }
        } else if (objectType === 'track') {
            for (const frame in trackShapeServerIDByFrame) {
                if (
                    Object.hasOwnProperty.call(trackShapeServerIDByFrame, frame) &&
                    (this.objects[clientID] as Track).shapes[frame]
                ) {
                    (this.objects[clientID] as Track).shapes[frame].serverID = trackShapeServerIDByFrame[frame];
                }
            }
        }
    }

    exportSub() {
        const data = {
            tracks: this.subTracks
                .filter((track) => !track.removed)
                .map((track) => {
                    const newTrack = track.toJSON();
                    // newTrack.trackId = this.serverIDByClientID[track.parentID];
                    // if (!newTrack.trackId) {
                    //     delete newTrack.trackId;
                    // }
                    return newTrack;
                }),
            shapes: Object.values(this.subShapes)
                .reduce((accumulator, value) => {
                    accumulator.push(...value);
                    return accumulator;
                }, [])
                .filter((shape) => !shape.removed)
                .map((shape) => {
                    const newShape = shape.toJSON();
                    // newShape.shapeId = this.serverIDByClientID[shape.parentID];
                    // if (!newShape.shapeId) {
                    //     delete newShape.shapeId;
                    // }
                    return newShape;
                }),
            tags: Object.values(this.subTags)
                .reduce((accumulator, value) => {
                    accumulator.push(...value);
                    return accumulator;
                }, [])
                .filter((tag) => !tag.removed)
                .map((tag) => tag.toJSON()),
        };

        return {
            tracks: data.tracks || [],
            shapes: data.shapes || [],
            tags: data.tags || [],
        };
    }

    export() {
        const data = {
            tracks: this.tracks.filter((track) => !track.removed).map((track) => track.toJSON()),
            shapes: Object.values(this.shapes)
                .reduce((accumulator, value) => {
                    accumulator.push(...value);
                    return accumulator;
                }, [])
                .filter((shape) => !shape.removed)
                .map((shape) => shape.toJSON()),
            tags: Object.values(this.tags)
                .reduce((accumulator, value) => {
                    accumulator.push(...value);
                    return accumulator;
                }, [])
                .filter((tag) => !tag.removed)
                .map((tag) => tag.toJSON()),
        };

        const sub = this.exportSub();

        // 从小到大排序
        data.tracks = data.tracks.concat(sub.tracks).sort((a, b) => (a.id || -1) - (b.id || 0));
        data.shapes = data.shapes.concat(sub.shapes).sort((a, b) => (a.id || -1) - (b.id || 0));
        data.tags = data.tags.concat(sub.tags).sort((a, b) => (a.id || -1) - (b.id || 0));

        return data;
        // return {
        //     tracks: data.tracks.concat(sub.tracks),
        //     shapes: data.shapes.concat(sub.shapes),
        //     tags: data.tags.concat(sub.tags),
        // };
    }

    get(frame: number, allTracks: boolean, filters: any[], cameraName?: string) {
        this.showCamName = cameraName;
        // console.log('获取的相机视角：', cameraName || this.showCamName);
        const { tracks } = this;
        const shapes = this.shapes[frame] || [];
        const tags = this.tags[frame] || [];

        const objects = ([] as Annotation[]).concat(tracks, shapes, tags);
        // const objects = [].concat(tracks, shapes);
        const visible: {
            models: Annotation[];
            data: SerializedData[];
        } = {
            models: [],
            data: [],
        };

        for (const object of objects) {
            if (object.removed) {
                continue;
            }

            // 视角有值，代表是2D车道线。
            if (object.cameraName !== (cameraName || this.showCamName)) {
                continue;
            }

            const stateData = object.get(frame);
            if (!allTracks && stateData.outside && !stateData.keyframe) {
                continue;
            }

            visible.models.push(object);
            visible.data.push(stateData);
        }

        const objectStates: ObjectState[] = [];
        const filtered = this.annotationsFilter.filter(visible.data, filters);

        visible.data.forEach((stateData, idx) => {
            if (!filters.length || filtered.includes(stateData.clientID)) {
                const model = visible.models[idx];
                const objectState = Annotation.objectStateFactory.call(model, frame, stateData);
                objectStates.push(objectState);
            }
        });

        return objectStates;
    }

    getTags(frame: number) {
        const createTag = (label: Label): ServerTagData => ({
            attributes: label.attributes.reduce((previous: Record<number, string>, attr) => {
                previous[attr.id] = attr.defaultValue;
                return previous;
            }, {}),
            frame,
            labelId: label.id,
            group: 0,
            jobId: this.job.id,
        });

        const constructed: {
            shapes: ServerShapeData[];
            tracks: ServerTrackData[];
            tags: ServerTagData[];
            trackShapes: ServerTrackShapeData[];
        } = {
            shapes: [],
            tracks: [],
            tags: [],
            trackShapes: [],
        };

        const tags = this.tags[frame] || [];
        const tagsByLabelId = tags.reduce((previous: Record<number, Tag>, current) => {
            previous[current.labelId] = current;
            return previous;
        }, {});
        const tagLabels: Record<number, Label> = {};
        Object.entries(this.job.labels).forEach(([labelId, label]) => {
            // console.log(`测试标签：labeleId=${labelId}, 标签=${label}`);
            // 1-标注框，2-图像，3-语义分割
            if (label.labelObjectType === 2) {
                tagLabels[+labelId] = label;
            }
        });

        for (const [labelId, tagLabel] of Object.entries(tagLabels)) {
            if (!tagsByLabelId[+labelId]) {
                constructed.tags.push(createTag(tagLabel));
            }
        }

        this.import(constructed);

        const objects = this.tags[frame];
        const visible: {
            models: Annotation[];
            data: SerializedData[];
        } = {
            models: [],
            data: [],
        };

        for (const object of objects) {
            if (object.removed || object.cameraName !== this.showCamName) {
                continue;
            }

            const stateData = object.get(frame);

            visible.models.push(object);
            visible.data.push(stateData);
        }

        const objectStates: ObjectState[] = [];
        // const filtered = this.annotationsFilter.filter(visible.data, filters);

        visible.data.forEach((stateData, idx) => {
            // if (!filters.length || filtered.includes(stateData.clientID)) {
            const model = visible.models[idx];
            const objectState = Annotation.objectStateFactory.call(model, frame, stateData);
            objectStates.push(objectState);
            // }
        });

        // console.log('标签：', tagLabels);
        return objectStates;
    }

    merge(objectStates: ObjectState[]) {
        throw new DataError(`待实现！`);
        return [];
        // checkObjectType('shapes for merge', objectStates, null, Array);
        // if (!objectStates.length) return;
        // const objectsForMerge = objectStates.map((state) => {
        //     checkObjectType('object state', state, null, ObjectState);
        //     const object = this.objects[state.clientID];
        //     if (typeof object === 'undefined') {
        //         throw new ArgumentError(
        //             'The object is not in collection yet. Call ObjectState.put([state]) before you can merge it',
        //         );
        //     }
        //     return object;
        // });

        // const keyframes = {}; // frame: position
        // const { label, shapeType } = objectStates[0];
        // if (!(label.id in this.labels)) {
        //     throw new ArgumentError(`Unknown label for the task: ${label.id}`);
        // }

        // if (!Object.values(ObjectShape).includes(shapeType)) {
        //     throw new ArgumentError(`Got unknown shapeType "${shapeType}"`);
        // }

        // const labelAttributes = label.attributes.reduce((accumulator, attribute) => {
        //     accumulator[attribute.id] = attribute;
        //     return accumulator;
        // }, {});

        // for (let i = 0; i < objectsForMerge.length; i++) {
        //     // For each state get corresponding object
        //     const object = objectsForMerge[i];
        //     const state = objectStates[i];
        //     if (state.label.id !== label.id) {
        //         throw new ArgumentError(
        //             `All shape labels are expected to be ${label.name}, but got ${state.label.name}`,
        //         );
        //     }

        //     if (state.shapeType !== shapeType) {
        //         throw new ArgumentError(`All shapes are expected to be ${shapeType}, but got ${state.shapeType}`);
        //     }

        //     // If this object is shape, get it position and save as a keyframe
        //     if (object instanceof Shape) {
        //         // Frame already saved and it is not outside
        //         if (object.frame in keyframes && !keyframes[object.frame].outside) {
        //             throw new ArgumentError('Expected only one visible shape per frame');
        //         }

        //         keyframes[object.frame] = {
        //             type: shapeType,
        //             frame: object.frame,
        //             points: [...object.points],
        //             occluded: object.occluded,
        //             rotation: object.rotation,
        //             zOrder: object.zOrder,
        //             pointsLine: object.pointsLine,
        //             outside: false,
        //             attributes: Object.keys(object.attributes).reduce((accumulator, attrID) => {
        //                 // We save only mutable attributes inside a keyframe
        //                 if (attrID in labelAttributes && labelAttributes[attrID].mutable) {
        //                     accumulator.push({
        //                         specId: +attrID,
        //                         value: object.attributes[attrID],
        //                     });
        //                 }
        //                 return accumulator;
        //             }, []),
        //         };

        //         // Push outside shape after each annotation shape
        //         // Any not outside shape rewrites it
        //         if (!(object.frame + 1 in keyframes) && object.frame + 1 <= this.stopFrame) {
        //             keyframes[object.frame + 1] = JSON.parse(JSON.stringify(keyframes[object.frame]));
        //             keyframes[object.frame + 1].outside = true;
        //             keyframes[object.frame + 1].frame++;
        //         }
        //     } else if (object instanceof Track) {
        //         // If this object is track, iterate through all its
        //         // keyframes and push copies to new keyframes
        //         const attributes = {}; // id:value
        //         for (const keyframe of Object.keys(object.shapes)) {
        //             const shape = object.shapes[keyframe];
        //             // Frame already saved and it is not outside
        //             if (keyframe in keyframes && !keyframes[keyframe].outside) {
        //                 // This shape is outside and non-outside shape already exists
        //                 if (shape.outside) {
        //                     continue;
        //                 }

        //                 throw new ArgumentError('Expected only one visible shape per frame');
        //             }

        //             // We do not save an attribute if it has the same value
        //             // We save only updates
        //             let updatedAttributes = false;
        //             for (const attrID in shape.attributes) {
        //                 if (!(attrID in attributes) || attributes[attrID] !== shape.attributes[attrID]) {
        //                     updatedAttributes = true;
        //                     attributes[attrID] = shape.attributes[attrID];
        //                 }
        //             }

        //             keyframes[keyframe] = {
        //                 type: shapeType,
        //                 frame: +keyframe,
        //                 points: [...shape.points],
        //                 pointsLine: shape.pointsLine,
        //                 rotation: shape.rotation,
        //                 occluded: shape.occluded,
        //                 outside: shape.outside,
        //                 zOrder: shape.zOrder,
        //                 attributes: updatedAttributes
        //                     ? Object.keys(attributes).reduce((accumulator, attrID) => {
        //                           accumulator.push({
        //                               specId: +attrID,
        //                               value: attributes[attrID],
        //                           });

        //                           return accumulator;
        //                       }, [])
        //                     : [],
        //             };
        //         }
        //     } else {
        //         throw new ArgumentError(
        //             `Trying to merge unknown object type: ${object.constructor.name}. ` +
        //                 'Only shapes and tracks are expected.',
        //         );
        //     }
        // }

        // let firstNonOutside = false;
        // for (const frame of Object.keys(keyframes).sort((a, b) => +a - +b)) {
        //     // Remove all outside frames at the begin
        //     firstNonOutside = firstNonOutside || keyframes[frame].outside;
        //     if (!firstNonOutside && keyframes[frame].outside) {
        //         delete keyframes[frame];
        //     } else {
        //         break;
        //     }
        // }

        // const clientID = ++this.count;
        // const track = {
        //     frame: Math.min.apply(
        //         null,
        //         Object.keys(keyframes).map((frame) => +frame),
        //     ),
        //     shapes: Object.values(keyframes),
        //     group: 0,
        //     source: objectStates[0].source,
        //     labelId: label.id,
        //     attributes: Object.keys(objectStates[0].attributes).reduce((accumulator, attrID) => {
        //         if (!labelAttributes[attrID].mutable) {
        //             accumulator.push({
        //                 specId: +attrID,
        //                 value: objectStates[0].attributes[attrID],
        //             });
        //         }

        //         return accumulator;
        //     }, []),
        // };

        // const trackModel = trackFactory(track, clientID, this.injection);
        // this.tracks.push(trackModel);
        // this.objects[clientID] = trackModel;

        // // Remove other shapes
        // for (const object of objectsForMerge) {
        //     object.removed = true;
        // }

        // this.history.do(
        //     HistoryActions.MERGED_OBJECTS,
        //     () => {
        //         trackModel.removed = true;
        //         for (const object of objectsForMerge) {
        //             object.removed = false;
        //         }
        //     },
        //     () => {
        //         trackModel.removed = false;
        //         for (const object of objectsForMerge) {
        //             object.removed = true;
        //         }
        //     },
        //     [...objectsForMerge.map((object) => object.clientID), trackModel.clientID],
        //     objectStates[0].frame,
        // );
    }

    split(objectState: ObjectState[], frame: number) {
        throw new DataError(`待实现！`);
        return [];
        // checkObjectType('object state', objectState, null, ObjectState);
        // checkObjectType('frame', frame, 'integer', null);

        // const object = this.objects[objectState.clientID];
        // if (typeof object === 'undefined') {
        //     throw new ArgumentError('The object has not been saved yet. Call annotations.put([state]) before');
        // }

        // if (objectState.objectType !== ObjectType.TRACK) {
        //     return;
        // }

        // const keyframes = Object.keys(object.shapes).sort((a, b) => +a - +b);
        // if (frame <= +keyframes[0]) {
        //     return;
        // }

        // const labelAttributes = object.label.attributes.reduce((accumulator, attribute) => {
        //     accumulator[attribute.id] = attribute;
        //     return accumulator;
        // }, {});

        // const exported = object.toJSON();
        // const position = {
        //     type: objectState.shapeType,
        //     points: [...objectState.points],
        //     rotation: objectState.rotation,
        //     occluded: objectState.occluded,
        //     outside: objectState.outside,
        //     pointsLine: objectState.pointsLine,
        //     zOrder: objectState.zOrder,
        //     attributes: Object.keys(objectState.attributes).reduce((accumulator, attrID) => {
        //         if (!labelAttributes[attrID].mutable) {
        //             accumulator.push({
        //                 specId: +attrID,
        //                 value: objectState.attributes[attrID],
        //             });
        //         }

        //         return accumulator;
        //     }, []),
        //     frame,
        // };

        // const prev = {
        //     frame: exported.frame,
        //     group: 0,
        //     labelId: exported.labelId,
        //     attributes: exported.attributes,
        //     shapes: [],
        // };

        // const next = JSON.parse(JSON.stringify(prev));
        // next.frame = frame;

        // next.shapes.push(JSON.parse(JSON.stringify(position)));
        // exported.shapes.map((shape) => {
        //     delete shape.id;
        //     if (shape.frame < frame) {
        //         prev.shapes.push(JSON.parse(JSON.stringify(shape)));
        //     } else if (shape.frame > frame) {
        //         next.shapes.push(JSON.parse(JSON.stringify(shape)));
        //     }

        //     return shape;
        // });
        // prev.shapes.push(position);

        // // add extra keyframe if no other keyframes before outside
        // if (!prev.shapes.some((shape) => shape.frame === frame - 1)) {
        //     prev.shapes.push(JSON.parse(JSON.stringify(position)));
        //     prev.shapes[prev.shapes.length - 2].frame -= 1;
        // }
        // prev.shapes[prev.shapes.length - 1].outside = true;

        // let clientID = ++this.count;
        // const prevTrack = trackFactory(prev, clientID, this.injection);
        // this.tracks.push(prevTrack);
        // this.objects[clientID] = prevTrack;

        // clientID = ++this.count;
        // const nextTrack = trackFactory(next, clientID, this.injection);
        // this.tracks.push(nextTrack);
        // this.objects[clientID] = nextTrack;

        // // Remove source object
        // object.removed = true;

        // this.history.do(
        //     HistoryActions.SPLITTED_TRACK,
        //     () => {
        //         object.removed = false;
        //         prevTrack.removed = true;
        //         nextTrack.removed = true;
        //     },
        //     () => {
        //         object.removed = true;
        //         prevTrack.removed = false;
        //         nextTrack.removed = false;
        //     },
        //     [object.clientID, prevTrack.clientID, nextTrack.clientID],
        //     frame,
        // );
    }

    group(objectStates: ObjectState[], reset: boolean) {
        throw new DataError(`待实现！`);
        return [];
        // checkObjectType('shapes for group', objectStates, null, Array);

        // const objectsForGroup = objectStates.map((state) => {
        //     checkObjectType('object state', state, null, ObjectState);
        //     const object = this.objects[state.clientID];
        //     if (typeof object === 'undefined') {
        //         throw new ArgumentError('The object has not been saved yet. Call annotations.put([state]) before');
        //     }
        //     return object;
        // });

        // const groupIdx = reset ? 0 : ++this.groups.max;
        // const undoGroups = objectsForGroup.map((object) => object.group);
        // for (const object of objectsForGroup) {
        //     object.group = groupIdx;
        // }
        // const redoGroups = objectsForGroup.map((object) => object.group);

        // this.history.do(
        //     HistoryActions.GROUPED_OBJECTS,
        //     () => {
        //         objectsForGroup.forEach((object, idx) => {
        //             object.group = undoGroups[idx];
        //         });
        //     },
        //     () => {
        //         objectsForGroup.forEach((object, idx) => {
        //             object.group = redoGroups[idx];
        //         });
        //     },
        //     objectsForGroup.map((object) => object.clientID),
        //     objectStates[0].frame,
        // );

        // return groupIdx;
    }

    clear(startframe: number, endframe: number, delTrackKeyframesOnly: boolean) {
        if (startframe !== undefined && endframe !== undefined) {
            // If only a range of annotations need to be cleared
            for (let frame = startframe; frame <= endframe; frame++) {
                this.shapes[frame] = [];
                this.tags[frame] = [];
            }
            const { tracks } = this;
            tracks.forEach((track) => {
                if (track.frame <= endframe) {
                    if (delTrackKeyframesOnly) {
                        for (const keyframe in track.shapes) {
                            if (+keyframe >= startframe && +keyframe <= endframe) {
                                delete track.shapes[+keyframe];
                            }
                        }
                    } else if (track.frame >= startframe) {
                        const index = tracks.indexOf(track);
                        if (index > -1) {
                            tracks.splice(index, 1);
                        }
                    }
                }
            });
        } else if (startframe === undefined && endframe === undefined) {
            // If all annotations need to be cleared
            this.shapes = {};
            this.tags = {};
            this.tracks = [];
            this.objects = {}; // by id
            this.count = 0;

            this.flush = true;
        } else {
            // If inputs provided were wrong
            throw Error(
                'Could not remove the annotations, please provide both inputs or' +
                    ' leave the inputs below empty to remove all the annotations from this job',
            );
        }
    }

    statistics() {
        const { startFrame, stopFrame, projectType, labels: jobLabels } = this.job;
        const labels: any = {};
        const skeleton = {
            rectangle: {
                shape: 0,
                track: 0,
            },
            splitRectangle: {
                shape: 0,
                track: 0,
            },
            polygon: {
                shape: 0,
                track: 0,
            },
            polyline: {
                shape: 0,
                track: 0,
            },
            points: {
                shape: 0,
                track: 0,
            },
            ellipse: {
                shape: 0,
                track: 0,
            },
            cuboid: {
                shape: 0,
                track: 0,
            },
            image: {
                shape: 0,
                track: 0,
            },
            laneline: {
                shape: 0,
                track: 0,
            },
            tags: 0,
            auto: 0,
            manually: 0,
            modify: 0,
            interpolated: 0,
            total: 0,
        };
        // const total = {
        //     tag: JSON.parse(JSON.stringify(skeleton)),
        //     tag: JSON.parse(JSON.stringify(skeleton)),
        //     tag: JSON.parse(JSON.stringify(skeleton)),
        //     issue: JSON.parse(JSON.stringify(skeleton)),
        // };

        const data: any = {
            data2D: {},
            data3D: {},
            total2D: {},
            total3D: {},
            data: {},
            total: {},
        };

        const saveData = (label: number, key: string, object: Annotation, value = 1, key2?: string) => {
            if ([ProjectType.two].includes(projectType)) {
                // 2D
                if (key2) {
                    data.data2D[label][key][key2] += value;
                } else {
                    data.data2D[label][key] += value;
                }
            } else if ([ProjectType.three].includes(projectType)) {
                // 3D
                if (key2) {
                    data.data3D[label][key][key2] += value;
                } else {
                    data.data3D[label][key] += value;
                }
            } else if ([ProjectType.mix].includes(projectType)) {
                // 2D3D
                if (object.direction) {
                    if (key2) {
                        data.data2D[label][key][key2] += value;
                    } else {
                        data.data2D[label][key] += value;
                    }
                } else if (key2) {
                    data.data3D[label][key][key2] += value;
                } else {
                    data.data3D[label][key] += value;
                }
            } else if (key2) {
                data.data[label][key][key2] += value;
            } else {
                data.data[label][key] += value;
            }
        };

        const total = JSON.parse(JSON.stringify(skeleton));
        data.total2D = JSON.parse(JSON.stringify(skeleton));
        data.total3D = JSON.parse(JSON.stringify(skeleton));
        data.total = JSON.parse(JSON.stringify(skeleton));
        for (const label of Object.values(jobLabels)) {
            const { id } = label;
            labels[id] = JSON.parse(JSON.stringify(skeleton));
            data.data2D[id] = JSON.parse(JSON.stringify(skeleton));
            data.data3D[id] = JSON.parse(JSON.stringify(skeleton));
            data.data[id] = JSON.parse(JSON.stringify(skeleton));
        }

        for (const object of [...Object.values(this.objects), ...Object.values(this.directionObjects)]) {
            if (object.removed) {
                continue;
            }

            let objectType = null;
            if (object instanceof Shape) {
                objectType = 'shape';
            } else if (object instanceof Track) {
                objectType = 'track';
            } else if (object instanceof Tag) {
                objectType = 'tag';
            } else {
                throw new ScriptingError(`统计时-未定义的对象类型: "${objectType}"`);
            }

            // const label = object.label.name;
            const label = object.label.id;
            if (objectType === 'tag') {
                labels[label].tags++;
                labels[label].manually++;
                // labels[label].total++;
            } else {
                const { shapeType } = object;
                labels[label][shapeType][objectType]++;

                // if ([1].includes(this.projectType)) {
                //     // 2D
                //     data.data2D[label][shapeType][objectType]++;
                // } else if ([2].includes(this.projectType)) {
                //     // 3D
                //     data.data3D[label][shapeType][objectType]++;
                // } else if ([3].includes(this.projectType)) {
                //     // 2D3D
                //     if (object.direction) {
                //         data.data2D[label][shapeType][objectType]++;
                //     } else {
                //         data.data3D[label][shapeType][objectType]++;
                //     }
                // } else {
                //     data.data2D[label][shapeType][objectType]++;
                // }

                saveData(label, shapeType, object, 1, objectType);

                if (objectType === 'track') {
                    const keyframes = Object.keys((object as Track).shapes)
                        .sort((a, b) => +a - +b)
                        .map((el) => +el);

                    let prevKeyframe = keyframes[0];
                    let visible = false;

                    for (const keyframe of keyframes) {
                        if (visible) {
                            const interpolated = keyframe - prevKeyframe - 1;
                            labels[label].interpolated += interpolated;
                            labels[label].total += interpolated;

                            if (object.source === Source.AUTO) {
                                labels[label].auto += interpolated;
                                saveData(label, 'auto', object, interpolated);
                            } else {
                                labels[label].manually += interpolated;
                                saveData(label, 'manually', object, interpolated);
                            }

                            saveData(label, 'interpolated', object, interpolated);
                            saveData(label, 'total', object, interpolated);
                        }

                        if (object.source === Source.AUTO) {
                            labels[label].auto++;
                            saveData(label, 'auto', object, 1);
                        } else {
                            labels[label].manually++;
                            saveData(label, 'manually', object, 1);
                        }

                        labels[label].modify++;

                        saveData(label, 'modify', object);
                        // labels[label].manually++;
                        // saveData(label, 'manually', object);
                        labels[label].total++;
                        saveData(label, 'total', object);

                        visible = !(object as Track).shapes[keyframe].outside;
                        prevKeyframe = keyframe;
                        // if (visible) {
                        // }
                    }

                    const lastKey = keyframes[keyframes.length - 1];
                    if (lastKey !== stopFrame && !(object as Track).shapes[lastKey].outside) {
                        const interpolated = stopFrame - lastKey;
                        labels[label].interpolated += interpolated;
                        labels[label].total += interpolated;
                        if (object.source === Source.AUTO) {
                            labels[label].auto += interpolated;
                            saveData(label, 'auto', object, interpolated);
                        } else {
                            labels[label].manually += interpolated;
                            saveData(label, 'manually', object, interpolated);
                        }
                        saveData(label, 'interpolated', object, interpolated);
                        saveData(label, 'total', object, interpolated);
                    }
                } else {
                    if (object.source === Source.AUTO) {
                        labels[label].auto++;
                        saveData(label, 'auto', object);
                    } else {
                        labels[label].manually++;
                        saveData(label, 'manually', object);
                    }
                    labels[label].modify++;
                    labels[label].total++;
                    saveData(label, 'modify', object);
                    saveData(label, 'total', object);
                }
            }
        }

        for (const label of Object.keys(labels)) {
            for (const key of Object.keys(labels[label])) {
                if (typeof labels[label][key] === 'object') {
                    for (const objectType of Object.keys(labels[label][key])) {
                        total[key][objectType] += labels[label][key][objectType];
                        data.total2D[key][objectType] += data.data2D[label][key][objectType];
                        data.total3D[key][objectType] += data.data3D[label][key][objectType];
                        data.total[key][objectType] += data.data[label][key][objectType];
                    }
                } else {
                    total[key] += labels[label][key];
                    data.total2D[key] += data.data2D[label][key];
                    data.total3D[key] += data.data3D[label][key];
                    data.total[key] += data.data[label][key];
                }
            }
        }

        return new Statistics(labels, data, total);
    }

    put(objectStates: ObjectState[], id: number) {
        // console.log('创建的相机视角：', this.showCamName);
        checkObjectType('shapes for put', objectStates, undefined, Array);
        const constructed: {
            shapes: ServerShapeData[];
            tracks: ServerTrackData[];
            tags: ServerTagData[];
            trackShapes: ServerTrackShapeData[];
        } = {
            shapes: [],
            tracks: [],
            tags: [],
            trackShapes: [],
        };

        // function convertAttributes(accumulator: Record<number, string>, attrID: number) {
        //     const specID = +attrID;
        //     const value = this.attributes[attrID];

        //     checkObjectType('attribute id', specID, 'integer', null);
        //     checkObjectType('attribute value', value, 'string', null);
        //     // checkObjectType('attribute value', value, null, Object);

        //     // accumulator.push({
        //     //     specId: specID,
        //     //     value,
        //     // });
        //     // accumulator.push({
        //     //     specId: specID,
        //     //     value: value.value,
        //     // });
        //     accumulator.push({
        //         specId: specID,
        //         value,
        //     });

        //     return accumulator;
        // }

        for (const state of objectStates) {
            checkObjectType('object state', state, undefined, ObjectState);
            checkObjectType('state client ID', state.clientID, 'undefined', null);
            checkObjectType('state frame', state.frame, 'integer', null);
            checkObjectType('state rotation', state.rotation || 0, 'number', null);
            checkObjectType('state pointsLine', state.pointsLine || 0, 'number', null);
            checkObjectType('state attributes', state.attributes, undefined, Object);
            checkObjectType('state label', state.label, undefined, Label);

            // const attributes = Object.keys(state.attributes).reduce(convertAttributes.bind(state), []);

            // const trackAttrKes: Attribute[] = [];
            // const trackShapeAttrKes: number[] = [];
            // const labelAttributes = state.label.attributes.reduce(
            //     (accumulator: Record<number, Attribute>, attribute) => {
            //         if (!attribute.mutable) {
            //             trackAttrKes.push(attribute);
            //         } else {

            //         }
            //         accumulator[attribute.id] = attribute;
            //         return accumulator;
            //     },
            //     {},
            // );

            // Construct whole objects from states
            if (state.objectType === 'tag') {
                constructed.tags.push({
                    attributes: state.attributes,
                    frame: state.frame,
                    labelId: state.label.id,
                    group: 0,
                    jobId: id,
                    cameraName: this.showCamName,
                });
            } else {
                checkObjectType('state occluded', state.occluded, 'boolean', null);
                checkObjectType('state points', state.points, undefined, Array);
                checkObjectType('state zOrder', state.zOrder, 'integer', undefined);
                checkObjectType('state descriptions', state.descriptions, undefined, Array);
                state.descriptions.forEach((desc) => checkObjectType('state description', desc, 'string'));

                for (const coord of state.points) {
                    checkObjectType('point coordinate', coord, 'number', null);
                }

                if (!Object.values(ShapeType).includes(state.shapeType)) {
                    throw new ArgumentError(`Object shape must be one of: ${JSON.stringify(Object.values(ShapeType))}`);
                }

                if (state.objectType === 'shape') {
                    constructed.shapes.push({
                        // id: 0,
                        attributes: state.attributes,
                        // descriptions: state.descriptions,
                        frame: state.frame,
                        group: 0,
                        labelId: state.label.id,
                        occluded: state.occluded || false,
                        points: [...state.points],
                        // pointOccludeds: pointOccludedLabelIds.includes(state.label.id)
                        //     ? state.points.map(() => false)
                        //     : undefined,
                        // pointOccludeds: undefined,
                        pointsLine: state.pointsLine || 0,
                        rotation: state.rotation || 0,
                        type: state.shapeType,
                        zOrder: state.zOrder || 0,
                        source: state.source,
                        // relation: { ...state.relation },
                        jobId: id,
                        elements: [],
                        cameraName: this.showCamName,
                        // shapeType: state.shapeType,
                    });
                } else if (state.objectType === 'track') {
                    const trackAttr: Record<number, string> = {};
                    const trackShapeAttr: Record<number, string> = {};
                    state.label.attributes.forEach((attr) => {
                        if (!attr.mutable) {
                            trackAttr[attr.id] = state.attributes[attr.id] || attr.defaultValue;
                        } else {
                            trackShapeAttr[attr.id] = state.attributes[attr.id] || attr.defaultValue;
                        }
                    });
                    constructed.tracks.push({
                        attributes: trackAttr,
                        // descriptions: state.descriptions || [],
                        frame: state.frame,
                        group: 0,
                        source: state.source,
                        labelId: state.label.id,
                        jobId: id,
                        shapes: [
                            {
                                attributes: trackShapeAttr,
                                frame: state.frame,
                                occluded: state.occluded || false,
                                outside: false,
                                points: [...state.points],
                                // pointOccludeds: pointOccludedLabelIds.includes(state.label.id)
                                //     ? state.points.map(() => false)
                                //     : undefined,
                                pointsLine: state.pointsLine || 0,
                                rotation: state.rotation || 0,
                                type: state.shapeType,
                                zOrder: state.zOrder || 0,
                                jobId: id,
                                elements: [],
                            },
                        ],
                        relation: { ...state.relation },
                        cameraName: this.showCamName,
                    });
                } else {
                    throw new ArgumentError(`Object type must be one of: ${JSON.stringify(Object.values(ObjectType))}`);
                }
            }
        }

        // Add constructed objects to a collection
        // eslint-disable-next-line no-unsanitized/method
        const imported = this.import(constructed);
        const importedArray: Annotation[] = ([] as Annotation[])
            .concat(imported.tags)
            .concat(imported.tracks)
            .concat(imported.shapes);

        if (objectStates.length) {
            this.history.do(
                HistoryActions.CREATED_OBJECTS,
                () => {
                    importedArray.forEach((object) => {
                        object.removed = true;
                    });
                },
                () => {
                    importedArray.forEach((object) => {
                        object.removed = false;
                        object.serverID = undefined;
                    });
                },
                importedArray.map((object) => object.clientID),
                objectStates[0].frame,
            );
        }

        return importedArray.map((value) => value.clientID);
    }

    select(objectStates: ObjectState[], x: number, y: number) {
        checkObjectType('shapes for select', objectStates, undefined, Array);
        checkObjectType('x coordinate', x, 'number', undefined);
        checkObjectType('y coordinate', y, 'number', undefined);

        let minimumDistance = null;
        let minimumState = null;
        for (const state of objectStates) {
            checkObjectType('object state', state, undefined, ObjectState);
            if (
                // state.outside ||
                state.hidden ||
                state.objectType === ObjectType.TAG
            ) {
                continue;
            }

            const key = getDirectionClientIDKey(state.clientID, state.direction);
            const object = this.directionObjects[key] || this.objects[state.clientID];

            // const object = this.objects[state.clientID] ||
            // this.directionObjects[state.direction][state.clientID];
            if (typeof object === 'undefined') {
                throw new ArgumentError('The object has not been saved yet. Call annotations.put([state]) before');
            }

            // const { relation = {} } = state;
            // const point = [...state.points];
            // 解决分割线，垂直于矩形父框时。永远无法选中的问题。
            // 竖分割线
            // if (relation.relationType === 1 && state.shapeType === 'polyline') {
            //     point[0] -= 2;
            //     point[2] += 2;
            // } else if (relation.relationType === 2 && state.shapeType === 'polyline') {
            //     // 横分割线
            //     point[1] -= 2;
            //     point[3] += 2;
            // }

            // @ts-expect-error
            const distance = object.constructor.distance(state.points, x, y, state.rotation);
            if (distance !== null && (minimumDistance === null || distance < minimumDistance)) {
                minimumDistance = distance;
                minimumState = state;
            }
        }

        return {
            state: minimumState,
            distance: minimumDistance,
        };
    }

    searchEmpty(frameFrom: number, frameTo: number) {
        const sign = Math.sign(frameTo - frameFrom);
        const predicate = sign > 0 ? (frame: number) => frame <= frameTo : (frame: number) => frame >= frameTo;
        const update = sign > 0 ? (frame: number) => frame + 1 : (frame: number) => frame - 1;
        for (let frame = frameFrom; predicate(frame); frame = update(frame)) {
            if (frame in this.shapes && this.shapes[frame].some((shape) => !shape.removed)) {
                continue;
            }
            if (frame in this.tags && this.tags[frame].some((tag) => !tag.removed)) {
                continue;
            }
            const filteredTracks = this.tracks.filter((track) => !track.removed);
            let found = false;
            for (const track of filteredTracks) {
                const keyframes = track.boundedKeyframes(frame);
                const { prev, first } = keyframes;
                const last: number = !prev ? first : prev;
                const lastShape = track.shapes[last];
                const isKeyfame = frame in track.shapes;
                if (first <= frame && (!lastShape.outside || isKeyfame)) {
                    found = true;
                    break;
                }
            }

            if (found) continue;

            return frame;
        }

        return null;
    }

    search(filters: any[], frameFrom: number, frameTo: number) {
        // const sign = Math.sign(frameTo - frameFrom);
        // const filtersStr = JSON.stringify(filters);
        // const linearSearch = filtersStr.match(/"var":"width"/) || filtersStr.match(/"var":"height"/);

        // const predicate = sign > 0 ? (frame) => frame <= frameTo : (frame) => frame >= frameTo;
        // const update = sign > 0 ? (frame) => frame + 1 : (frame) => frame - 1;
        // for (let frame = frameFrom; predicate(frame); frame = update(frame)) {
        //     // First prepare all data for the frame
        //     // Consider all shapes, tags, and not outside tracks that have keyframe here
        //     // In particular consider first and last frame as keyframes for all tracks
        //     const statesData = [].concat(
        //         (frame in this.shapes ? this.shapes[frame] : [])
        //             .filter((shape) => !shape.removed)
        //             .map((shape) => shape.get(frame)),
        //         (frame in this.tags ? this.tags[frame] : []).filter((tag) => !tag.removed).map((tag) => tag.get(frame)),
        //     );
        //     const tracks = Object.values(this.tracks)
        //         .filter((track) => frame in track.shapes || frame === frameFrom || frame === frameTo || linearSearch)
        //         .filter((track) => !track.removed);
        //     statesData.push(...tracks.map((track) => track.get(frame)).filter((state) => !state.outside));

        //     // Nothing to filtering, go to the next iteration
        //     if (!statesData.length) {
        //         continue;
        //     }

        //     // Filtering
        //     const filtered = this.annotationsFilter.filter(statesData, filters);
        //     if (filtered.length) {
        //         return frame;
        //     }
        // }

        return null;
    }

    importSub(
        directionConstructed: Record<
            string,
            {
                tags: ServerTagData[];
                shapes: ServerShapeData[];
                tracks: ServerTrackData[];
                // trackShapes: ServerTrackShapeData[];
            }
        >,
    ) {
        const result: {
            tags: Tag[];
            shapes: Shape[];
            tracks: Track[];
        } = {
            tags: [],
            shapes: [],
            tracks: [],
        };

        for (const direction in directionConstructed) {
            if (Object.hasOwnProperty.call(directionConstructed, direction)) {
                const data = directionConstructed[direction];
                // this.directionObjects[direction] = this.directionObjects[direction] || {};
                for (const tag of data.tags) {
                    let { clientID } = tag; // 自己的对象id
                    // 如果有父对象，则强行使用父对象id
                    if (tag.parentId && this.clientIDByServerID.tag[tag.parentId]) {
                        clientID = +this.clientIDByServerID.tag[tag.parentId];
                    }
                    // 两个都没有，则新创建一个id
                    if (!clientID) {
                        clientID = ++this.count;
                    }

                    // 如果有父对象id，但现有的后台对象id中，没有发现该父对象id（父对象已被删除）。
                    // 则记录下来，让其他视角保持一致的对象id（以该视角）。
                    if (tag.parentId && !this.clientIDByServerID.tag[tag.parentId]) {
                        this.clientIDByServerID.tag[tag.parentId] = clientID;
                        this.serverIDByClientID[clientID] = tag.parentId;
                    }

                    // 将parentId置为clientID
                    if (tag.parentId) {
                        tag.parentId = this.clientIDByServerID.tag[tag.parentId] || tag.parentId;
                    }

                    const color = colors[clientID % colors.length];
                    const tagModel = new Tag(tag, clientID, color, this.injection);

                    this.subTags[tagModel.frame] = this.subTags[tagModel.frame] || [];
                    this.subTags[tagModel.frame].push(tagModel);

                    const key = getDirectionClientIDKey(clientID, direction);
                    this.directionObjects[key] = tagModel;

                    result.tags.push(tagModel);
                }

                for (const shape of data.shapes) {
                    // const { clientID } = shape;
                    let { clientID } = shape; // 自己的对象id
                    // 如果有父对象，则强行使用父对象id
                    if (shape.parentId && this.clientIDByServerID.shape[shape.parentId]) {
                        clientID = +this.clientIDByServerID.shape[shape.parentId];
                    }
                    // 两个都没有，则新创建一个id
                    if (!clientID) {
                        clientID = ++this.count;
                    }

                    // 如果有父对象id，但现有的后台对象id中，没有发现该父对象id（父对象已被删除）。
                    // 则记录下来，让其他视角保持一致的对象id（以该视角）。
                    if (shape.parentId && !this.clientIDByServerID.shape[shape.parentId]) {
                        // parentId 3D目标物已被删除，2D视角目标物clientID保持一致
                        this.clientIDByServerID.shape[shape.parentId] = clientID;
                        this.serverIDByClientID[clientID] = shape.parentId;
                    }

                    // 将parentId置为clientID
                    if (shape.parentId) {
                        shape.parentId = this.clientIDByServerID.shape[shape.parentId] || shape.parentId;
                    }

                    const shapeModel = shapeFactory(shape, clientID, this.injection);

                    this.subShapes[shapeModel.frame] = this.subShapes[shapeModel.frame] || [];
                    this.subShapes[shapeModel.frame].push(shapeModel);

                    const key = getDirectionClientIDKey(clientID, direction);
                    this.directionObjects[key] = shapeModel;

                    result.shapes.push(shapeModel);
                }

                for (const track of data.tracks) {
                    let { clientID } = track; // 自己的对象id
                    // 如果有父对象，则强行使用父对象id
                    if (track.parentId && this.clientIDByServerID.track[track.parentId]) {
                        clientID = +this.clientIDByServerID.track[track.parentId];
                    }
                    // 两个都没有，则新创建一个id
                    if (!clientID) {
                        clientID = ++this.count;
                    }

                    // 如果有父对象id，但现有的后台对象id中，没有发现该父对象id（父对象已被删除）。
                    // 则记录下来，让其他视角保持一致的对象id（以该视角）。
                    if (track.parentId && !this.clientIDByServerID.track[track.parentId]) {
                        this.clientIDByServerID.track[track.parentId] = clientID;
                        this.serverIDByClientID[clientID] = track.parentId;
                    }

                    // 将parentId置为clientID
                    if (track.parentId) {
                        track.parentId = this.clientIDByServerID.track[track.parentId] || track.parentId;
                    }

                    const trackModel = trackFactory(track, clientID, this.injection);
                    if (trackModel) {
                        this.subTracks.push(trackModel);
                        const key = getDirectionClientIDKey(clientID, direction);
                        this.directionObjects[key] = trackModel;

                        result.tracks.push(trackModel);
                    }
                }
            }
        }

        return result;
    }

    getSub(frame: number, allTracks: boolean, filters: any[]) {
        if (frame >= 0) {
            try {
                const tracks = this.subTracks;
                const shapes = this.subShapes[frame] || [];
                const tags = this.subTags[frame] || [];

                const objects = ([] as Annotation[]).concat(tracks, shapes, tags);
                // const objects = [].concat(tracks, shapes);
                const visible: {
                    models: Annotation[];
                    data: SerializedData[];
                } = {
                    models: [],
                    data: [],
                };

                for (const object of objects) {
                    if (object.removed) {
                        continue;
                    }

                    const stateData = object.get(frame);
                    if (!allTracks && stateData.outside && !stateData.keyframe) {
                        continue;
                    }

                    visible.models.push(object);
                    visible.data.push(stateData);
                }

                const objectStates: ObjectState[] = [];
                const filtered = this.annotationsFilter.filter(visible.data, filters);

                visible.data.forEach((stateData, idx) => {
                    if (!filters.length || filtered.includes(stateData.clientID)) {
                        const model = visible.models[idx];
                        const objectState = Annotation.objectStateFactory.call(model, frame, stateData);
                        objectStates.push(objectState);
                    }
                });

                // console.log('获取视角数据：', objectStates);
                return objectStates;
            } catch (error) {
                console.error('获取视角对象列表错误', error);
            }
        }
        return [];
    }

    // 投影，只需要
    projection(clientIDs: number[] = [], directions: string[] = [], jobId: number, get2DPoints: Function) {
        checkObjectType('shapes for put', clientIDs, undefined, Array);
        // console.log('创建对象的视角对象：', clientIDs);

        const directionConstructed: Record<
            string,
            {
                shapes: ServerShapeData[];
                tracks: ServerTrackData[];
                tags: ServerTagData[];
                trackShapes: ServerTrackShapeData[];
            }
        > = {};

        const shapeType = ShapeType.RECTANGLE;

        // 转换属性
        // function convertAttributes(accumulator, attrID) {
        //     const specID = +attrID;
        //     const value = this.attributes[attrID];

        //     checkObjectType('attribute id', specID, 'integer', null);
        //     checkObjectType('attribute value', value, null, Object);

        //     accumulator.push({
        //         specId: specID,
        //         value: value.value,
        //     });

        //     return accumulator;
        // }

        const state3DTo2Ds = (state3D: Shape | Track | Tag) => {
            // 获取对应的子labelID
            const labels = Object.values(this.job.labels).filter((label) => label.isSub);
            const label = labels.find((lab) => lab.parentLabelId === state3D.label.id) || labels[0];

            const labelId = label?.id;
            checkObjectType('labelId for projection', labelId, 'number', null);

            // 生成视角对象
            for (const direction of directions) {
                if (get2DPoints) {
                    const constructed = directionConstructed[direction] || {
                        shapes: [],
                        tracks: [],
                        tags: [],
                    };

                    const {
                        clientID,
                        frame,
                        group = 0,
                        // shapeType,
                        source,
                    } = state3D;

                    // const attributes = Object.keys(state.attributes)
                    // .reduce(convertAttributes.bind(state), []);
                    // const labelAttributes = state.label.attributes.reduce((accumulator, attribute) => {
                    //     accumulator[attribute.id] = attribute;
                    //     return accumulator;
                    // }, {});

                    if (state3D instanceof Tag) {
                        constructed.tags.push({
                            clientID,
                            attributes: [],
                            frame,
                            labelId,
                            group,
                            jobId,
                            direction,
                            parentId: clientID,

                            // zOrder,
                            // rotation,
                        });
                    } else {
                        // descriptions.forEach((desc) => checkObjectType('state description', desc, 'string'));

                        const {
                            occluded = false,
                            pointsLine = 0,
                            rotation = 0,
                            zOrder = 0,
                            relation = {},
                        } = state3D as Shape;
                        if (!Object.values(ShapeType).includes(shapeType)) {
                            throw new ArgumentError(
                                `框类型必须是其中的一个: ${JSON.stringify(Object.values(ShapeType))}`,
                            );
                        }

                        if (state3D instanceof Shape) {
                            const points2Ds = get2DPoints(state3D.points, direction);
                            if (points2Ds) {
                                checkObjectType('state points', points2Ds, undefined, Array);
                                for (const coord of points2Ds) {
                                    checkObjectType('point coordinate', coord, 'number', null);
                                }

                                constructed.shapes.push({
                                    clientID,
                                    attributes: [],
                                    // descriptions,
                                    frame,
                                    group,
                                    labelId,
                                    occluded,
                                    points: points2Ds,
                                    pointsLine,
                                    rotation,
                                    type: shapeType,
                                    zOrder,
                                    source,
                                    relation: { ...relation },
                                    jobId,
                                    direction,
                                    parentId: clientID,
                                    elements: [],
                                });
                            }
                        } else if (state3D instanceof Track) {
                            const {
                                // occluded = false,
                                // pointsLine = 0,
                                // rotation = 0,
                                // zOrder = 0,
                                relation = {},

                                // descriptions = [],

                                shapes = {},
                            } = state3D as Track;
                            // @ts-expect-error
                            const trackShapes: ServerTrackShapeData[] = Object.entries(shapes)
                                .map(([frameStr, shape]) => {
                                    const points2Ds = get2DPoints(shape.points, direction);
                                    if (points2Ds) {
                                        return {
                                            ...shape,
                                            attributes: {} as Record<number, string>,
                                            points: points2Ds,
                                            frame: +frameStr,
                                            zOrder: shape.zOrder || 0,
                                            pointsLine: shape.pointsLine || 0,
                                            jobId,
                                            type: shapeType,
                                            elements: [] as SubElement[],
                                        };
                                    }
                                    return undefined;
                                })
                                .filter((item) => item);

                            if (trackShapes && trackShapes.length) {
                                const newTrack = {
                                    clientID,
                                    attributes: {} as Record<number, string>,
                                    // descriptions,
                                    frame,
                                    group,
                                    source,
                                    labelId,
                                    jobId,
                                    relation: { ...relation },
                                    direction,
                                    parentId: clientID,
                                    shapes: trackShapes,
                                };
                                constructed.tracks.push(newTrack);
                            }
                        } else {
                            throw new ArgumentError(
                                `Object type must be one of: ${JSON.stringify(Object.values(ObjectType))}`,
                            );
                        }
                    }

                    directionConstructed[direction] = constructed;
                }
            }
        };

        for (const clientID of clientIDs) {
            const state3D = this.objects[clientID] as Tag | Shape | Track;
            state3DTo2Ds(state3D);
        }

        // console.log('转化：', directionConstructed);

        const imported = this.importSub(directionConstructed);
        const importedArray = ([] as Annotation[])
            .concat(imported.tags)
            .concat(imported.tracks)
            .concat(imported.shapes);

        if (clientIDs.length && importedArray.length) {
            const { frame } = importedArray[0];
            this.history.do(
                HistoryActions.CREATED_OBJECTS,
                () => {
                    importedArray.forEach((object) => {
                        object.removed = true;
                    });
                },
                () => {
                    importedArray.forEach((object) => {
                        object.removed = false;
                        object.serverID = undefined;
                    });
                },
                importedArray.map((object) => object.clientID),
                frame,
            );
        }

        return importedArray.map((value) => value.clientID);
    }

    public associated(objectStates: ObjectState[], clientID: number) {
        objectStates.forEach((objectState) => {
            const { objectType, direction } = objectState;
            if (objectType === ObjectType.TAG) {
            } else if (objectType === ObjectType.SHAPE) {
                if (direction) {
                    const key = getDirectionClientIDKey(objectState.clientID, direction);
                    const newKey = getDirectionClientIDKey(clientID, direction);
                    const shapeModel = this.directionObjects[key] as Shape;
                    const newShapeModel = this.directionObjects[newKey] as Shape;
                    // 新旧两个id会同时指向一个对象。不需要理会。由于机制，旧的id不会再被使用。（防止接口调用过程中，请求使用的clientID是旧id，而关联后旧id不存在了的问题）
                    if (newShapeModel && newShapeModel !== shapeModel && !newShapeModel.removed) {
                        // 当前已存在相同的对象
                        message.error('当前视角已经存在该对象！');
                    } else if (shapeModel) {
                        shapeModel.associated(clientID);
                        this.directionObjects[newKey] = shapeModel;
                        // delete this.directionObjects[key];
                    } else {
                        message.error('关联时的其他错误！');
                    }
                }
            } else if (objectType === ObjectType.TRACK) {
                if (direction) {
                    const key = getDirectionClientIDKey(objectState.clientID, direction);
                    const newKey = getDirectionClientIDKey(clientID, direction);
                    const trackModel = this.directionObjects[key] as Track;
                    const newTrackModel: Track = this.directionObjects[newKey] as Track;
                    if (newTrackModel && newTrackModel !== trackModel && !newTrackModel.removed) {
                        // 当前已存在相同的对象
                        message.error('连续帧当前视角已经存在该对象！');
                    } else if (trackModel) {
                        trackModel.associated(clientID);
                        this.directionObjects[newKey] = trackModel;
                    } else {
                        message.error('连续帧关联时的其他错误！');
                    }
                }
            }
        });
    }

    putSub(objectStates: ObjectState[] = [], id: number) {
        checkObjectType('shapes for put', objectStates, undefined, Array);
        // console.log('创建视角的一个对象：', objectStates);

        const directionConstructed: Record<
            string,
            {
                shapes: ServerShapeData[];
                tracks: ServerTrackData[];
                tags: ServerTagData[];
                trackShapes: ServerTrackShapeData[];
            }
        > = {};

        // 转换属性
        // function convertAttributes(accumulator, attrID) {
        //     const specID = +attrID;
        //     const value = this.attributes[attrID];

        //     checkObjectType('attribute id', specID, 'integer', null);
        //     checkObjectType('attribute value', value, 'string', null);

        //     accumulator.push({
        //         specId: specID,
        //         value,
        //     });

        //     return accumulator;
        // }

        for (const state of objectStates) {
            if (state.direction) {
                const constructed = directionConstructed[state.direction] || {
                    shapes: [],
                    tracks: [],
                    tags: [],
                };
                // const attributes = Object.keys(state.attributes).reduce(convertAttributes.bind(state), []);
                const attributes = { ...state.attributes };

                // const labelAttributes = state.label.attributes.reduce((accumulator, attribute) => {
                //     accumulator[attribute.id] = attribute;
                //     return accumulator;
                // }, {});
                if (state.objectType === 'tag') {
                    constructed.tags.push({
                        clientID: state.clientID,
                        attributes,
                        frame: state.frame,
                        labelId: state.label.id,
                        group: 0,
                        jobId: id,
                        direction: state.direction,
                        parentId: state.parentID,
                        cameraName: state.cameraName,
                        // elements: state.elements,
                    });
                } else {
                    checkObjectType('state points', state.points, undefined, Array);
                    state.descriptions.forEach((desc) => checkObjectType('state description', desc, 'string'));

                    for (const coord of state.points) {
                        checkObjectType('point coordinate', coord, 'number', null);
                    }

                    if (!Object.values(ShapeType).includes(state.shapeType)) {
                        throw new ArgumentError(
                            `标注对象必须是以下中的一个: ${JSON.stringify(Object.values(ShapeType))}`,
                        );
                    }

                    if (state.objectType === 'shape') {
                        constructed.shapes.push({
                            clientID: state.clientID,
                            attributes,
                            // descriptions: state.descriptions,
                            frame: state.frame,
                            group: 0,
                            labelId: state.label.id,
                            occluded: state.occluded || false,
                            points: [...state.points],
                            pointsLine: state.pointsLine || 0,
                            rotation: state.rotation || 0,
                            type: state.shapeType,
                            zOrder: state.zOrder,
                            source: state.source,
                            relation: { ...state.relation },
                            jobId: id,
                            direction: state.direction,
                            parentId: state.parentID,
                            elements: state.elements,
                        });
                    } else if (state.objectType === 'track') {
                        const trackAttr: Record<number, string> = {};
                        const trackShapeAttr: Record<number, string> = {};
                        state.label.attributes.forEach((attr) => {
                            if (!attr.mutable) {
                                trackAttr[attr.id] = state.attributes[attr.id] || attr.defaultValue;
                            } else {
                                trackShapeAttr[attr.id] = state.attributes[attr.id] || attr.defaultValue;
                            }
                        });
                        constructed.tracks.push({
                            clientID: state.clientID,
                            // attributes: attributes.filter((attr) => !labelAttributes[attr.specId].mutable),
                            attributes: trackAttr,
                            // descriptions: state.descriptions,
                            frame: state.frame,
                            group: 0,
                            source: state.source,
                            labelId: state.label.id,
                            jobId: id,
                            shapes: [
                                {
                                    // attributes: attributes.filter((attr) => labelAttributes[attr.specId].mutable),
                                    attributes: trackShapeAttr,
                                    frame: state.frame,
                                    occluded: state.occluded || false,
                                    outside: false,
                                    points: [...state.points],
                                    pointsLine: state.pointsLine || 0,
                                    rotation: state.rotation || 0,
                                    type: state.shapeType,
                                    zOrder: state.zOrder,
                                    jobId: id,
                                    elements: state.elements,
                                },
                            ],
                            relation: { ...state.relation },
                            direction: state.direction,
                            parentId: state.parentID,
                        });
                    } else {
                        throw new ArgumentError(
                            `标注对象必须是以下中的一个： ${JSON.stringify(Object.values(ObjectType))}`,
                        );
                    }
                }

                directionConstructed[state.direction] = constructed;
            }
        }

        const imported = this.importSub(directionConstructed);
        const importedArray = ([] as Annotation[])
            .concat(imported.tags)
            .concat(imported.tracks)
            .concat(imported.shapes);

        if (objectStates.length) {
            this.history.do(
                HistoryActions.CREATED_OBJECTS,
                () => {
                    importedArray.forEach((object) => {
                        object.removed = true;
                    });
                },
                () => {
                    importedArray.forEach((object) => {
                        object.removed = false;
                        object.serverID = undefined;
                    });
                },
                importedArray.map((object) => object.clientID),
                objectStates[0].frame,
            );
        }

        return importedArray.map((value) => value.clientID);
    }

    public saveMultAttr(
        data: Record<number, Record<number, string>>,
        frames: { startFrame: number; stopFrame: number },
    ) {
        Object.values(this.shapes).forEach((shapes) => {
            shapes.forEach((shape) => {
                if (
                    typeof shape.shapeIndex === 'number' &&
                    data[shape.shapeIndex] &&
                    shape.frame >= frames.startFrame &&
                    shape.frame <= frames.stopFrame
                ) {
                    shape.updateAttributes(data[shape.shapeIndex]);
                    // console.log('已保存：', shape);
                }
            });
        });
    }

    public changeCamera(cameraName?: string) {
        if (this.showCamName !== cameraName) {
            this.showCamName = cameraName;
        }
    }

    private checkTrackIsFull(trackId: number, keyFrames?: number[], direction?: string) {
        const clientID = this.clientIDByServerID.track[trackId];
        if (clientID) {
            const key = getDirectionClientIDKey(clientID, direction);

            const trackModel = (direction ? this.directionObjects[key] : this.objects[key as number]) as Track;

            if (trackModel) {
                const keys = Object.keys(trackModel.shapes);
                keys.sort((a, b) => +a - +b);
                return keyFrames?.toString() === keys.toString();
            }
        }
        return false;
    }

    // public importByTrack({
    //     frame,
    //     trackShapes,
    //     tracks,
    // }: {
    //     tracks: ServerTrackData[];
    //     trackShapes: ServerTrackShapeData[];
    // }) {
    //     const result: {
    //         tracks: Track[];
    //     } = {
    //         tracks: [],
    //     };

    //     for (const track of tracks) {
    //         const clientID = ++this.count;
    //         const trackModel = trackFactory(track, clientID, this.injection);
    //         // The function can return null if track doesn't have any shapes.
    //         // In this case a corresponded message will be sent to the console
    //         if (trackModel) {
    //             this.tracks.push(trackModel);
    //             this.objects[clientID] = trackModel;

    //             result.tracks.push(trackModel);
    //         }

    //         if (track.id) {
    //             this.clientIDByServerID.track[track.id] = clientID;
    //             this.serverIDByClientID[clientID] = track.id;
    //         }
    //     }

    //     return result;
    // }
}

export default Collection;
