/*
 * @Author: swxy
 * @Date: 2022-08-15 14:21:02
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import userNotice from './other/userNotice';

export default {
    'login.login': '登陆',
    'login.register': '注册',

    'login.username': '用户名',
    'login.password': '密码',
    'login.empty_username': '请输入用户名',
    'login.empty_password': '请输入密码',

    'login.confirm_password': '确认密码',
    'login.confirm_empty_password': '请再次输入密码',
    'login.valid_password': '两次输入密码不一致！',

    'login.title': '数据标注工具',
    'login.demoTitle': '自动化标注',
    'login.demoSubTitle': '—— 体验版',
    'login.resetPassword': '忘记密码',

    'login.nickName': '昵称',
    'login.email': '邮箱',
    'login.mobile': '手机',
    'login.affiliation': '公司/学校',
    'login.info': '其他',
    'login.verifyCode': '验证码',
    'login.placeholder_nickName': '请输入昵称',
    'login.placeholder_email': '请输入邮箱',
    'login.placeholder_mobile': '请输入手机',
    'login.placeholder_affiliation': '请输入公司/学校',
    'login.placeholder_info': '请输入其他',
    'login.placeholder_verifyCode': '请输入验证码',

    'login.valid_email': '邮箱格式错误！',
    'login.valid_mobile': '手机格式错误！',

    'login.hasAccount': '已有账号？您可以',

    'login.registerSuccess': '注册成功！',

    'login.newPassword': '新密码',
    'login.empty_newPassword': '请输入新密码',
    'login.confirm_empty_newPassword': '请再次输入新密码',

    'login.forgetPassword': '忘记密码',
    'login.email.change': '更换',
    'login.validcode.get': '获取',
    'login.validcode.placeholder': '请获取验证码，并输入',

    'login.imageVerifyCode': '图片验证码',
    'login.imageVerifyCode_placeholder': '请获取图片验证码，并输入',
    'login.emailVerifyCode': '邮件验证码',
    'login.emailVerifyCode_placeholder': '请获取邮件验证码，并输入',

    'login.imageCodeNeed': '请输入图片验证码',
    'login.sendEmailValidCodeSuccess': '验证码已发送至邮箱，请查收',
    'login.sendEmailValidCodeError': '验证码发送失败！',

    //#region 2023-05-04 待校验
    'register.userNotice': '《用户须知》',
    'register.confirmUserNotice': '确认并同意签署',
    'register.userNoticeTip': '请查看并同意《用户须知》',
    'register.userNotice.title': '用户须知',
    ...userNotice,
    //#endregion
};
