// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import '../../styles.scss';
import React, {
    Dispatch,
    // TransitionEvent
} from 'react';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
// import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import Text from 'antd/lib/typography/Text';
import Tabs from 'antd/lib/tabs';
import Layout from 'antd/lib/layout';

import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'canvas3d-wrapper';
import { CombinedState } from 'reducers/interfaces';
// import LabelsList from 'components/annotation-page/standard-workspace/objects-side-bar/labels-list';
import LabelsList from './labels-list';
// import LabelsList from './labels-list';
import ObjectsListComponent from './objectsListContainer';
// import { adjustContextImagePosition } from
// 'components/annotation-page/standard-workspace/context-image/context-image';
import { collapseSidebar as collapseSidebarAction } from 'actions/annotation-actions';
import AppearanceBlock from 'components/annotation-page/appearance-block';
// import IssuesListComponent from 'components/annotation-page/standard-workspace/objects-side-bar/issues-list';
import IssuesListComponent from './issues-list';
import { useTranslation } from 'react-i18next';

interface OwnProps {
    // objectsList: JSX.Element;
    readonly?: boolean;
}

interface StateToProps {
    sidebarCollapsed: boolean;
    sidebarPositionLeft?: boolean;
    canvasInstance: Canvas;
    jobInstance: any;
}

interface DispatchToProps {
    collapseSidebar(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            sidebarCollapsed,
            canvas: { instance: canvasInstance },
            job: { instance: jobInstance },
        },
    } = state;

    return {
        sidebarCollapsed,
        canvasInstance: canvasInstance as Canvas,
        jobInstance,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchToProps {
    return {
        collapseSidebar(): void {
            dispatch(collapseSidebarAction());
        },
    };
}

function ObjectsSideBar(props: StateToProps & DispatchToProps & OwnProps): JSX.Element {
    const {
        sidebarCollapsed,
        // objectsList,
        readonly = false,
    } = props;

    const { t } = useTranslation();

    return (
        <Layout.Sider
            className='cvat-objects-sidebar'
            theme='light'
            width={300}
            collapsedWidth={0}
            reverseArrow
            collapsible
            trigger={null}
            collapsed={sidebarCollapsed}
        >
            <Tabs type='card' tabPosition='bottom' defaultActiveKey='objects' className='cvat-objects-sidebar-tabs'>
                <Tabs.TabPane tab={<Text strong>{t('workspace.side.tab.object')}</Text>} key='objects'>
                    <ObjectsListComponent readonly={readonly} />
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={<Text strong>{t('workspace.side.tab.labels')}</Text>} key='labels'>
                    <LabelsList />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<Text strong>{t('workspace.side.tab.issues')}</Text>} key='issues'>
                    <IssuesListComponent />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<Text strong>{t('workspace.side.tab.appear')}</Text>} key='Appearance'>
                    <AppearanceBlock />
                </Tabs.TabPane>
            </Tabs>
        </Layout.Sider>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ObjectsSideBar));
