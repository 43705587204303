/*
 * @Author: swxy
 * @Date: 2023-11-13 16:37:24
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import Resource from './AmygoResource';
import CacheResource from './AmygoCacheResource';
import AmygoPoseModel from './AmygoPoseModel';

export enum CameraType {
    normal = 'normal',
    buttonhole = 'buttonhole',
    fisheye = 'fisheye',
}

interface CameraParam {
    extrinsic: number[][];
    intrinsic: number[][];
    distcoeff: number[][];
    type: CameraType;
    width: number;
    height: number;
}

class FrameMeta {
    // jobId	题包编号	integer(int32)	integer(int32)
    // id	编号	integer(int32)	integer(int32)
    // frameIndex	帧索引号	integer(int32)	integer(int32)
    // frameName	帧名称	string
    // fileImg	图片文件	string
    // filePcd	点云文件	string
    // fileSemantic	语义分割文件	string
    // nameImg	图片名称	string
    // namePcd	点云名称	string
    // objNum	目标物个数	integer(int32)	integer(int32)
    // param	参数	string
    // sceneArea	场景区域	string
    // sceneName	场景名称	string
    // sceneTime	场景时间	string
    // thirdParam	第三方参数	string
    // updateTime	更新时间	string(date-time)	string(date-time)
    // addTime	输入时间	string(date-time)	string(date-time)

    public readonly id: number;
    public readonly frameIndex: number;
    public readonly frameName: string;
    public readonly frameTime?: number;
    public readonly fileImg: string;
    public readonly filePcd: string;
    public readonly fileSemantic: string;
    public readonly nameImg: string;
    public readonly namePcd: string;
    public readonly objNum: number;
    public readonly addTime: string;
    public readonly updateTime: string;

    public readonly param: Record<string, any> = {};
    public readonly imageNames: string[] = [];
    public readonly pcdNames: string[] = [];

    public pose?: AmygoPoseModel;

    private readonly _cache: CacheResource;

    private width: number = 0;
    private height: number = 0;

    constructor(frameInfo: any, cache: CacheResource, orders: string[] = []) {
        this.id = frameInfo.id;
        this.frameIndex = frameInfo.frameIndex;
        this.frameName = frameInfo.frameName;
        this.frameTime = frameInfo.frameTime;
        this.fileImg = frameInfo.fileImg;
        this.filePcd = frameInfo.filePcd;
        this.fileSemantic = frameInfo.fileSemantic;
        this.nameImg = frameInfo.nameImg;
        this.namePcd = frameInfo.namePcd;
        this.objNum = frameInfo.objNum;
        // this.param = frameInfo.param;
        this.addTime = frameInfo.addTime;
        this.updateTime = frameInfo.updateTime;

        this._cache = cache;

        try {
            this.imageNames = frameInfo.fileImg ? frameInfo.fileImg?.split(',') || [] : [];
            this.pcdNames = frameInfo.filePcd ? frameInfo.filePcd?.split(',') || [] : [];
            this.param = JSON.parse(frameInfo.param || JSON.stringify({}));

            if (orders.length && this.imageNames.length) {
                // 有图片和排序，则重新排列图片
                let imageNames = [...this.imageNames];
                orders.forEach((order: string, num: number) => {
                    const index = imageNames.findIndex((name: string) => name.includes(order));
                    if (index >= 0) {
                        const imageName = imageNames.splice(index, 1);
                        this.imageNames[num] = imageName[0];
                    }
                });
            }
        } catch (error) {
            console.error('题包帧信息错误：', error);
        }
    }

    public static import(frameInfo: any, cache: CacheResource, order: string[] = []) {
        return new FrameMeta(frameInfo, cache, order);
    }

    public get hasRelatedContext() {
        if (this.pcdNames.length && this.imageNames.length) {
            return true;
        }

        return false;
    }

    public getWidth() {
        try {
            // if (this.job.imgWidth) {
            //     width = this.job.imgWidth;
            // } else if (this.job.dimension === DimensionType.DIM_2D) {
            // }
        } catch (error) {}
        return this.width;
    }

    public getHeight() {
        try {
            // if (this.job.imgHeight) {
            //     height = this.job.imgHeight;
            // } else if (this.job.dimension === DimensionType.DIM_2D) {
            // }
        } catch (error) {}
        return this.height;
    }

    public async data() {
        try {
            const data = await this._cache.get(this.frameIndex);
            if (data.pcds?.length) {
                data.pcds.forEach((pcd) => {
                    pcd.pose = this.pose;
                });
            }
            if (data.images.length) {
                this.width = data.images[0].width;
                this.height = data.images[0].height;
            }
            return data;
            // return await this._cache.get(this.frameIndex);
        } catch (error) {
            throw new Error(`获取第${this.frameIndex}帧资源失败！-${(error as Error).stack}`);
        }
    }
}

export default FrameMeta;
