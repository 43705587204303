/*
 * @Author: swxy
 * @Date: 2022-08-15 14:22:13
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
    language: '日本語',
    setting: '設定',
    logout: 'ログアウト',
    loginfo_timeout: 'ログイン情報が期限切れになりました。もう一度ログインしてください！',

    ok: '確認',
    cancel: 'キャンセル',
    close: '閉鎖',
    collapsed: '拡大',
    uncollapsed: '折り畳み',

    'base.deleteConfirm': 'このバーを削除することを確認するかどうか',
    'base.delete': '消去',
    'base.close': '閉鎖',
    'base.upload': 'アップロード',
	
    'base.prevStep': '前へ',
    'base.nextStep': '次へ',
    'base.send': '送信',
   
};
