// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    LeftOutlined,
    RightOutlined,
    EyeInvisibleFilled,
    EyeOutlined,
    CheckCircleFilled,
    CheckCircleOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import Alert from 'antd/lib/alert';
import { Row, Col } from 'antd/lib/grid';

import { changeFrameAsync } from 'actions/annotation-actions';
import { reviewActions, deleteIssueAsync } from 'actions/review-actions';
import CVATTooltip from 'components/common/cvat-tooltip';
import { CombinedState, Issue, IssueStaus } from 'reducers/interfaces';
import { reviewerAndMore } from 'utils/constant';
import { Popconfirm, Space } from 'antd';
import { useTranslation } from 'react-i18next';

export default function LabelsListComponent(): JSX.Element {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const frame = useSelector((state: CombinedState): number => state.annotation.player.frame.number);
    // const allframeIssues = useSelector((state: CombinedState): any[] => state.review.frameIssues);
    const allissues = useSelector((state: CombinedState): any[] => state.review.issues);
    // const frameIssues = allframeIssues.filter((issue: any) => !issue.direction) || [];
    // const issues = allissues.filter((issue: any) => !issue.direction) || [];;
    const issuesHidden = useSelector((state: CombinedState): any => state.review.issuesHidden);
    const user = useSelector((state: CombinedState): any => state.auth.user);
    const issuesResolvedHidden = useSelector((state: CombinedState): any => state.review.issuesResolvedHidden);
    const frames = allissues.map((issue: any): number => issue.frame).sort((a: number, b: number) => +a - +b);
    const nearestLeft = frames.filter((_frame: number): boolean => _frame < frame).reverse()[0];
    const dinamicLeftProps: any = Number.isInteger(nearestLeft)
        ? {
            onClick: () => dispatch(changeFrameAsync(nearestLeft)),
        }
        : {
            style: {
                pointerEvents: 'none',
                opacity: 0.5,
            },
        };

    const nearestRight = frames.filter((_frame: number): boolean => _frame > frame)[0];
    const dinamicRightProps: any = Number.isInteger(nearestRight)
        ? {
            onClick: () => dispatch(changeFrameAsync(nearestRight)),
        }
        : {
            style: {
                pointerEvents: 'none',
                opacity: 0.5,
            },
        };

    const isReviewAndMore = reviewerAndMore(user.roleType);

    const resolved = (noteStatus: IssueStaus): boolean =>
        isReviewAndMore ? noteStatus === IssueStaus.finish : noteStatus !== IssueStaus.await;

    const onDeleteIssue = (deleteIssue: Issue): void => {
        dispatch(deleteIssueAsync(deleteIssue.id));
    };

    const deleteRender = (issue: Issue): JSX.Element | null =>
        isReviewAndMore ? (
            <Popconfirm
                title={t('workspace.side.issue.delete_issue_pop')}
                onConfirm={() => onDeleteIssue(issue)}
                okText={t('workspace.side.issue.delete_delete')}
                okButtonProps={{ danger: true }}
                cancelText={t('workspace.side.issue.delete_cancel')}
            >
                <CloseOutlined />
            </Popconfirm>
        ) : null;


    const issueRender = (frameIssue: Issue) => {
        if (frameIssue.readonly) {
            return <Alert
                // message='Resolved'
                message={resolved(frameIssue.noteStatus) ? t('workspace.side.issue.resolved') : t('workspace.side.issue.open')}
                type='info'
                showIcon
            // action={deleteRender(frameIssue)}
            />
        }

        return resolved(frameIssue.noteStatus) ? (
            <Alert
                // message='Resolved'
                // message={t('workspace.side.issue.resolved')}
                message={<Row justify={'space-between'}>
                    <div>
                        {t('workspace.side.issue.resolved')}
                    </div>
                    <Space>
                        <div>第{frameIssue.frame}帧</div>
                        <div>视角:{frameIssue.direction || '3D'}</div>
                    </Space>
                </Row>}
                type='success'
                showIcon
                action={deleteRender(frameIssue)}
            />
        ) : (
            <Alert
                // message='Opened'
                message={<Row justify={'space-between'}>
                    <div>
                        {t('workspace.side.issue.open')}
                    </div>
                    <Space>
                        <div>第{frameIssue.frame}帧</div>
                        <div>视角:{frameIssue.direction || '3D'}</div>
                    </Space>
                </Row>}
                type='warning'
                showIcon
                action={deleteRender(frameIssue)}
            // closable={isReviewAndMore}
            // onClose={() => onDeleteIssue(frameIssue)}
            >
            </Alert>
        )
    }

    return (
        <>
            <div className='cvat-objects-sidebar-issues-list-header'>
                <Row justify='start' align='middle'>
                    <Col>
                        {/* <CVATTooltip title='Find the previous frame with issues'> */}
                        <CVATTooltip title={t('workspace.side.issue.find_previous_frame_issues')}>
                            <LeftOutlined className='cvat-issues-sidebar-previous-frame' {...dinamicLeftProps} />
                        </CVATTooltip>
                    </Col>
                    <Col offset={1}>
                        {/* <CVATTooltip title='Find the next frame with issues'> */}
                        <CVATTooltip title={t('workspace.side.issue.find_next_frame_issues')}>
                            <RightOutlined className='cvat-issues-sidebar-next-frame' {...dinamicRightProps} />
                        </CVATTooltip>
                    </Col>
                    <Col offset={2}>
                        {/* <CVATTooltip title='Show/hide all issues'> */}
                        <CVATTooltip title={t('workspace.side.issue.show/hide_all_issue')}>
                            {issuesHidden ? (
                                <EyeInvisibleFilled
                                    className='cvat-issues-sidebar-hidden-issues'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenFlag(false))}
                                />
                            ) : (
                                <EyeOutlined
                                    className='cvat-issues-sidebar-shown-issues'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenFlag(true))}
                                />
                            )}
                        </CVATTooltip>
                    </Col>
                    <Col offset={2}>
                        {/* <CVATTooltip title='Show/hide resolved issues'> */}
                        <CVATTooltip title={t('workspace.side.issue.hide_all_resolved_issue')}>
                            {issuesResolvedHidden ? (
                                <CheckCircleFilled
                                    className='cvat-issues-sidebar-hidden-resolved-status'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenResolvedFlag(false))}
                                />
                            ) : (
                                <CheckCircleOutlined
                                    className='cvat-issues-sidebar-hidden-resolved-status'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenResolvedFlag(true))}
                                />
                            )}
                        </CVATTooltip>
                    </Col>
                </Row>
            </div>
            <div className='cvat-objects-sidebar-issues-list'>
                {allissues.map(
                    (frameIssue: Issue): JSX.Element => (
                        <div
                            key={frameIssue.id}
                            id={`cvat-objects-sidebar-issue-item-${frameIssue.id}`}
                            className='cvat-objects-sidebar-issue-item'
                            onMouseEnter={() => {
                                const element = window.document.getElementById(
                                    `cvat_canvas_issue_region_${frameIssue.id}`,
                                );
                                if (element) {
                                    element.setAttribute('fill', 'url(#cvat_issue_region_pattern_2)');
                                }
                            }}
                            onMouseLeave={() => {
                                const element = window.document.getElementById(
                                    `cvat_canvas_issue_region_${frameIssue.id}`,
                                );
                                if (element) {
                                    element.setAttribute('fill', 'url(#cvat_issue_region_pattern_1)');
                                }
                            }}
                        // onClick={() => {
                        //     dispatch({

                        //     })
                        // }}
                        >
                            {issueRender(frameIssue)}
                            {/* {resolved(frameIssue.noteStatus) ? (
                                <Alert
                                    // message='Resolved'
                                    // message={t('workspace.side.issue.resolved')}
                                    message={<Row justify={'space-between'}>
                                        <div>
                                            {t('workspace.side.issue.resolved')}
                                        </div>
                                        <Space>
                                            <div>第{frameIssue.frame}帧</div>
                                            <div>视角:{frameIssue.direction || '3D'}</div>
                                        </Space>
                                    </Row>}
                                    type='success'
                                    showIcon
                                    action={deleteRender(frameIssue)}
                                />
                            ) : (
                                <Alert
                                    // message='Opened'
                                    message={<Row justify={'space-between'}>
                                        <div>
                                            {t('workspace.side.issue.open')}
                                        </div>
                                        <Space>
                                            <div>第{frameIssue.frame}帧</div>
                                            <div>视角:{frameIssue.direction || '3D'}</div>
                                        </Space>
                                    </Row>}
                                    type='warning'
                                    showIcon
                                    action={deleteRender(frameIssue)}
                                // closable={isReviewAndMore}
                                // onClose={() => onDeleteIssue(frameIssue)}
                                >
                                </Alert>
                            )} */}


                        </div>
                    ),
                )}
            </div>
        </>
    );
}
