/*
 * @Author: swxy
 * @Date: 2024-03-15 14:10:34
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
/*
 * @Author: swxy
 * @Date: 2023-10-07 14:50:50
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
// 贝塞尔模型

import { ObjectState, ShapeType, SubElement, SubElementType, SubElementVersion } from '../../interface';
import { pointToVector3, pointsInNearLines } from '../../utils';
import { Group, Vector3, ColorRepresentation, Intersection, Object3D, Object3DEventMap, Raycaster } from 'three';
import LanePoint, { PointType } from '../point';
import LaneSegment from './segment';

// interface BezierProps {
//     start: Vector3;
//     stop: Vector3;

//     locus?: Vector3[];
// }

const DashedStartPointColor = 'pink';
const DashedStopPointColor = '#c0fff9';

class Lane extends Group {
    /**
     * 起止点
     */
    public readonly positions: Vector3[] = [];

    /**
     * 虚线点
     */
    public readonly dashedDots: Vector3[] = [];

    /**
     * 贝塞尔曲线的线段对象
     */
    private readonly segments: LaneSegment[] = [];

    private _isActive: boolean = false;
    private _radius: number = 0.2;
    private _opacity: number = 100;
    private _controlPointColor: ColorRepresentation = 'red';

    public clientID: number;
    // public locusVisible: boolean = false;

    public color: ColorRepresentation = 'red';

    public readonly lookPos: Vector3 = new Vector3();

    public readonly perspective: Lane;
    public top?: Lane;
    public side?: Lane;
    public front?: Lane;

    public activeMesh?: LanePoint;

    /**
     * 起止点数量
     */
    public length: number = 0;
    public dashedLength: number = 0;
    // public get length(): number {
    //     return this.positions.length;
    // }

    // public object?: ObjectState;
    public readonly isLane: boolean = true;

    constructor(
        clientID: number,
        // posistions: number[] = [],
        color: ColorRepresentation = 'red',
        // dashedDots: number[] = [],
    ) {
        super();

        this.clientID = clientID;
        this.name = `${clientID}`;
        // this.positions.splice(0, Infinity, ...pointToVector3(posistions));
        this.color = color;
        // this.dashedDots.splice(0, Infinity, ...pointToVector3(dashedDots));

        // this.length = this.positions.length;
        // this.dashedLength = this.dashedDots.length;

        this.perspective = this;
        this.frustumCulled = false;

        // this.fixDashedDot();
        // this.create();
    }

    public init() {
        this.clear();
        this.positions.splice(0, Infinity);
        this.segments.splice(0, Infinity);
        this.dashedDots.splice(0, Infinity);

        // this.create();
    }

    private createPointMesh(pos: Vector3, index: number) {
        const point = new LanePoint(
            this.clientID,
            'LanePoint',
            PointType.Point,
            pos,
            this.color,
            index,
            this._opacity,
            this._radius,
        );

        point.frustumCulled = false;

        return point;
    }

    private createSegment(index: number, startMesh: LanePoint, stopMesh: LanePoint) {
        const segment = new LaneSegment(
            this.clientID,
            this.positions[index],
            this.positions[index + 1],
            index,
            this.color,
            this._controlPointColor,
            this._opacity,
        );
        segment.startMesh = startMesh;
        segment.stopMesh = stopMesh;
        return segment;
    }

    // 自行实现碰撞检测
    raycast(raycaster: Raycaster, intersects: Intersection<Object3D<Object3DEventMap>>[]) {
        const data: Intersection<Object3D>[] = [];
        this.children.forEach((child) => {
            child.raycast(raycaster, data);
        });

        if (data.length) {
            const point = data[0].point.clone();
            this.lookPos.set(point.x, point.y, point.z);
            intersects.push({
                object: this,
                distance: -1, // 最优先
                point,
            });
            intersects.push(...data);
            this.activeMesh = undefined;
            const inter = data.find(
                (intersect) => intersect.object.parent === this && intersect.object instanceof LanePoint,
            );
            this.activeMesh = inter?.object as LanePoint | undefined;
        }
    }

    private reCreateDashedPoint() {
        this.fixDashedDot();
        if (this.dashedLength !== this.dashedDots.length) {
            const old = this.children.filter((node) => node.type === PointType.DashedDots);
            if (old?.length) {
                // 删除旧的
                old.forEach((o) => o.removeFromParent());
            }

            const points = this.dashedDots.map((dashedDot, index) => {
                return new LanePoint(
                    this.clientID,
                    'dashedDot',
                    PointType.DashedDots,
                    dashedDot,
                    index % 2 ? DashedStartPointColor : DashedStopPointColor,
                    index,
                );
            });

            this.add(...points);
            this.dashedLength = this.dashedDots.length;
        } else {
            const olds = this.children.filter((node) => node.type === PointType.DashedDots);
            this.dashedDots.forEach((dot, index) => {
                (olds?.[index] as LanePoint)?.update({
                    pos: dot,
                    color: index % 2 ? DashedStartPointColor : DashedStopPointColor,
                });
            });
        }
    }

    private create() {
        this.clear();
        this.segments.splice(0, Infinity);

        const pointMeshs = this.positions.map((pos, index) => {
            return this.createPointMesh(pos, index);
        });

        const length = this.positions.length;

        if (length) {
            this.add(...pointMeshs);
        }

        // 有两个点以上，足以构成线段后
        if (length >= 2) {
            // 遍历线段，线段数量为，起止点数-1
            for (let index = 0; index < length - 1; index++) {
                const segment = this.createSegment(index, pointMeshs[index], pointMeshs[index + 1]);
                this.segments.push(segment);
            }

            // const locusMeshs = this.segments.flatMap((segment) => {
            //     // segment.locusMeshs.forEach((mesh) => {
            //     //     // mesh.visible = this.locusVisible;
            //     // });
            //     return segment.locusMeshs;
            // });
            // this.add(...locusMeshs);

            this.reCreateDashedPoint();

            // 创建曲线
            const lines = this.segments.map((segment) => segment);
            this.add(...lines);
        }
        this.length = this.positions.length;
    }

    private updateViews() {
        if (this.top) {
            this.top.copyByLane(this);
        }
        if (this.side) {
            this.side.copyByLane(this);
        }
        if (this.front) {
            this.front.copyByLane(this);
        }
    }

    private update() {
        /**
         * 更新最新的位置信息
         * 1、循环(起止点数量 - 1)
         * 2、获取线段类
         * 3、根据最新的position更新线段
         */

        if (this.length !== this.positions.length) {
            this.create();
            return;
        }

        if (this.positions.length === 1) {
            // 没有线段时
            const [pos] = this.positions;
            this.children[0].position.copy(pos);
        }

        const { length } = this.positions;
        for (let index = 0; index < length - 1; index++) {
            const start = this.positions[index]; // 最新的起始点位置
            const stop = this.positions[index + 1]; // 最新的终止点位置
            const segment = this.segments[index];

            if (segment) {
                segment.update(
                    {
                        start,
                        stop,
                    },
                    {
                        color: this.color,
                        controlPointColor: this._controlPointColor,
                    },
                );
            }
        }

        this.reCreateDashedPoint();

        this.updateViews();
    }

    private updatePositions(pos: Vector3, index: number) {
        this.positions[index].set(pos.x, pos.y, pos.z);
    }

    public updatePosition(pos: Vector3, index?: number) {
        let num = index || this.positions.length;
        const newPos = pos.clone();
        if (typeof index === 'number' && index >= 1) {
            newPos.setZ(this.positions[index - 1].z);
        } else if (this.positions.length >= 2) {
            newPos.setZ(this.positions[this.positions.length - 2].z);
        }
        this.positions[num] = newPos;
        this.update();
    }

    public updateByMeshs() {
        this.segments.forEach((segment, index) => {
            if (index === 0) {
                // 更新第一个点
                this.updatePositions(segment.startMesh.position, index);
            }
            this.updatePositions(segment.stopMesh.position, index + 1);
        });

        const old = this.children.filter((node) => node.type === PointType.DashedDots);
        if (old && old.length) {
            // console.log('数量：', old);
            old.forEach((mesh: LanePoint) => {
                const newPos = mesh.position.clone();
                this.dashedDots[mesh.pointIndex].copy(newPos);
            });
        }

        this.update();
    }

    public deletePosition(index?: number, deleteCount: number = 0) {
        if (typeof index === 'number') {
            this.positions.splice(index, deleteCount);
        } else {
            this.positions.pop();
        }
        this.create();
    }

    public updateLastPosition(pos: Vector3) {
        const stop = pos.clone();
        if (this.positions.length >= 2) {
            // 有2个点以上，更改z值
            stop.setZ(this.positions[this.positions.length - 2].z);
        }
        const index = Math.max(0, this.positions.length - 1);
        this.positions[index] = stop;
        // const segment = this.segments[this.segments.length - 1];
        // segment.update({
        //     stop,
        // });

        this.update();
    }

    // 移动
    public move(pos: Vector3, offset?: number) {
        // const index = offset >= 0 ? offset : this.positions.length - 1;
        if (this.positions.length) {
            // 默认移动最后一个
            this.update();
        }
    }

    // 在最后一个点增加一个点
    public addPoint(points: Vector3) {
        if (points) {
            const newPos = points.clone();

            const { length } = this.positions;
            if (length >= 1) {
                // 和前一个点一样的高
                newPos.setZ(this.positions[length - 1].z);
            }

            // 由于最后一个点实时跟随鼠标，因此将最后一个点删除
            this.positions.push(newPos.clone());
            this.update();
        }
    }

    // 增加点
    public insertPoint(offset: number, ...points: Vector3[]) {
        if (points && points.length) {
            this.positions.splice(offset, 0, ...points.map((vec) => vec.clone()));
            this.update();
        }
    }

    public deletePoint(pointIndex: number) {
        this.positions.splice(pointIndex, 1);
        // 删除/新增一个点，会影响到前后两个控制点
        // this.resetControlPoint(pointIndex, 'delete');

        const index = Math.min(this.positions.length - 1, Math.max(0, pointIndex));
        this.lookPos.set(this.positions[index].x, this.positions[index].y, this.positions[index].z);
    }

    public active() {
        // 选中
        this._isActive = true;

        // 生成
        this.genViews();
    }

    public unActive() {
        this._isActive = false;

        this.top = undefined;
        this.side = undefined;
        this.front = undefined;
    }

    // private resetControlPoint(pointIndex: number, type: 'delete') {
    //     if (type === 'delete') {
    //         if (pointIndex === 0) {
    //             // 删除第一个点
    //             this.locus.splice(pointIndex, 1);
    //         } else if (pointIndex >= this.locus.length) {
    //             // 删除最后一个点
    //             this.locus.splice(Math.min(pointIndex, this.locus.length - 1), 1);
    //         } else {
    //             const start = this.positions[pointIndex - 1];
    //             const end = this.positions[pointIndex];
    //             this.locus.splice(pointIndex - 1, 2, LaneSegment.getBezierControlPoints(start, end));
    //         }
    //     }
    // }

    public prev(current: LanePoint) {}

    public next(current: LanePoint) {}

    public fixDashedDot() {
        // console.log(
        //     '计算前：',
        //     this.children.filter((obj) => (obj as LanePoint).type === PointType.DashedDots).map((obj) => obj.position),
        // );
        pointsInNearLines(this.dashedDots, this.positions);
        // console.log('计算后：', this.dashedDots);
    }

    public updateDashedDot(addPos?: Vector3, deleteIndex = -1, deleteCount: number = 1) {
        if (addPos) {
            // 增加一个点，z值取线的第一个点
            this.dashedDots.push(addPos.clone().setZ(this.positions[0].z));
        }
        if (deleteIndex >= 0) {
            this.dashedDots.splice(deleteIndex, deleteCount);
        }
        this.fixDashedDot();
        this.update();
    }

    public clearAll() {
        this.clear();
        this.positions.splice(0, Infinity);
        this.segments.splice(0, Infinity);
        this.dashedDots.splice(0, Infinity);
    }

    public destroy(): void {
        this.clearAll();
    }

    public import(
        object: ObjectState,
        {
            color,
            radius,
            opacity,
            controlPointColor,
        }: {
            color: ColorRepresentation;
            radius: number;
            opacity: number;
            controlPointColor: ColorRepresentation;
        },
    ) {
        // this.clearAll();
        const { points, elements } = object;
        const oldLength = this.positions.length;

        this.color = color;
        this._opacity = opacity;
        this._radius = radius;
        this._controlPointColor = controlPointColor;

        // this.length = this.positions.length;
        // this.dashedLength = this.dashedDots.length;

        if (points.length !== oldLength * 3) {
            const positions = pointToVector3(object.points);
            this.positions.splice(0, Infinity, ...positions);
        } else {
            for (let index = 0; index < oldLength; index++) {
                this.positions[index].fromArray(points, index * 3);
            }
        }

        if (elements?.length) {
            const element = elements[0];
            this.dashedDots.splice(0, Infinity, ...element.points.map((sub) => new Vector3(sub.x, sub.y, sub.z)));
        } else {
            this.dashedDots.splice(0, Infinity);
        }

        this.update();
    }

    public genViews = (): {
        top: Lane;
        front: Lane;
        side: Lane;
    } => {
        this.top = this.top || new Lane(this.clientID);
        this.side = this.side || new Lane(this.clientID);
        this.front = this.front || new Lane(this.clientID);

        this.top.copyByLane(this);
        this.side.copyByLane(this);
        this.front.copyByLane(this);

        return {
            top: this.top,
            front: this.front,
            side: this.side,
        };
    };

    public copyByLane(lane: Lane) {
        this.color = lane.color;
        // this.locusVisible = true;

        this.positions.splice(0, Infinity, ...lane.positions.map((vec) => vec.clone()));
        this.dashedDots.splice(0, Infinity, ...lane.dashedDots.map((vec) => vec.clone()));

        this.update();
    }

    // 将对象转化为对象
    public toJsonData() {
        const points = this.positions.flatMap((vec) => [vec.x, vec.y, vec.z]);
        // const controlPoints = this.dashedDots.map((pos) => [pos.x, pos.y, pos.z]);
        if (!this.userData) {
            // 新增
            return {
                points,
                // controlPoints,
                shapeType: ShapeType.laneline,
            };
        }

        return {
            clientID: this.userData.clientID,
            points,
            // controlPoints,
            shapeType: ShapeType.laneline,
            elements: [
                {
                    shapeType: SubElementType.dashedLine,
                    numOfPoints: this.dashedDots.length,
                    points: this.dashedDots.map((dot, index) => {
                        return {
                            x: dot.x,
                            y: dot.y,
                            z: dot.z,
                            pointType: index % 2 ? 'start' : 'end',
                        };
                    }),
                    version: SubElementVersion.version1_0_0,
                },
            ] as SubElement[],
        };
    }
}

export default Lane;
