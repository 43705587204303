/*
 * @Author: swxy
 * @Date: 2023-08-24 19:58:51
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import FrameData from 'business/frame';
import { ObjectType, ShapeType, Source, SubElement } from 'reducers/interfaces';
import {
    DataResourceType,
    DimensionType,
    ShapeResourceType,
    ShapeStatus,
    ShapeType as ShapeTypeNum,
} from 'utils/ConstType';
import AnnotationHistory, { HistoryActions } from './annotationsHistory';
import { Annotation, DrawnAnnotation } from './annotation';
import { Label } from './label';
import { ScriptingError } from 'errors/exception';
import { InjectionProps } from './annotationCollection';
import ObjectState from './objectState';

interface Point {
    x: number;
    y: number;
}

export interface ServerShapeData {
    clientID?: number;
    id?: number; //	编号	integer(int64)	integer(int64)
    jobId: number; //	题包编号	integer(int32)	integer(int32)
    labelId: number; //	标签编号	integer(int32)	integer(int32)
    type: ShapeType; //	类型	string
    cameraName?: string; //	相机名称	string
    direction?: string; //	方向	string
    dimensionType?: DimensionType; //	维度类型	integer(int32)	integer(int32)
    attributes: Record<number, string>; //	标注框属性(框编号)	array	标注框属性(框编号)
    frame: number; //	帧号	integer(int32)	integer(int32)
    group?: number; //	组	integer(int32)	integer(int32)
    occluded: boolean; //	是否遮挡	boolean
    occludedParts?: boolean[]; //	遮挡部分	string
    points: number[]; //	点	string
    pointsLine?: number; //	线坐标	string
    reviewInfo?: string; //	审核信息	string
    rotation?: number; //	旋转	number(double)	number(double)
    shapeId?: number; //	主框编号	integer(int64)	integer(int64)
    shapeIndex?: number; //	框序号	integer(int32)	integer(int32)
    dataResourceType?: DataResourceType; //	数据来源类型	integer(int32)	integer(int32)
    shapeResourceType?: ShapeResourceType; //	标注框来源类型	integer(int32)	integer(int32)
    shapeStatus?: ShapeStatus; //	标注框状态	integer(int32)	integer(int32)
    shapeType?: ShapeTypeNum; //	标注框类型	integer(int32)	integer(int32)
    source?: Source; //	源	string
    elements: SubElement[]; //	子点	string
    zOrder?: number; //		integer(int32)	integer(int32)
    parentId?: number;
    backTimes?: number; //	打回次数	integer(int32)	integer(int32)
    addTime?: string; //	输入时间	string(date-time)	string(date-time)
    updateTime?: string; //	更新时间	string(date-time)	string(date-time)

    relation?: any; // 补
    descriptions?: string[];

    pointsControl?: number[][];
}

type Frame = number;

export interface ToServerShapeData {
    id?: number;
    clientID: number;
    direction?: string;
    // jobId: number;
    idVals: Record<number, string>;
    shape: {
        jobId: number;
        id?: number;
        clientID: number;
        type: ShapeType;
        occluded: boolean;
        zOrder: number;
        occludedParts?: string;
        points: string;
        pointsLine: number;
        rotation: number;
        frame: number;
        labelId: number;
        group?: number;
        source: Source;
        shapeId?: number;
        shapeType: ShapeTypeNum;
        direction?: string;
        // shapeId: this.parentID,
        // shapeStatus: 0,
        subPoints: string;
        cameraName?: string;
        pointsControl?: string;
    };
}

class Shape extends DrawnAnnotation {
    public points: number[];
    public rotation: number;
    public zOrder: number;
    public occluded: boolean;
    public pointsLine: number;
    public pointOccludeds?: boolean[];
    public elements: SubElement[];
    public shapeIndex?: number;

    protected controls?: number[][];

    public descriptions: string[];
    public readonly isShape: boolean = true;

    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);

        this.points = data.points || [];
        this.rotation = data.rotation || 0;
        this.zOrder = data.zOrder || 0;
        this.occluded = data.occluded || false;

        this.pointsLine = data.pointsLine || 0;
        this.pointOccludeds = data.occludedParts;

        this.shapeIndex = data.shapeIndex;

        this.elements = data.elements;

        this.descriptions = [];

        this.controls = data.pointsControl;
    }

    // 转换成服务器使用的字段
    public toJSON(): ToServerShapeData {
        return {
            // _insert: !this.serverID,
            id: this.serverID,
            clientID: this.clientID,
            // jobId: this.jobId,
            direction: this.direction,
            idVals: { ...this.attributes },
            shape: {
                jobId: this.jobId,
                id: this.serverID,
                clientID: this.clientID,
                type: this.shapeType,
                occluded: this.occluded,
                zOrder: this.zOrder,
                occludedParts: Array.isArray(this.pointOccludeds) ? JSON.stringify(this.pointOccludeds) : undefined,
                points: typeof this.points !== 'string' ? JSON.stringify([...this.points]) : this.points,
                pointsLine: this.pointsLine || 0,
                rotation: this.rotation || 0,
                frame: this.frame,
                labelId: this.label.id,
                group: this.group,
                source: this.source,
                shapeId:
                    this.relation && this.relation.parentSeventID
                        ? this.relation.parentSeventID
                        : this.getParentServerID(),
                shapeType: 0,
                direction: this.direction,
                // shapeId: this.parentID,
                // shapeStatus: 0,
                subPoints: this.elements && this.elements.length ? JSON.stringify(this.elements) : JSON.stringify([]),
                cameraName: this.cameraName,
                pointsControl: this.controls ? JSON.stringify(this.controls) : '',
            },
        };
    }

    /**
     * 获取满足创建ObjectState的值
     * @param frame
     * @returns
     */
    public get(frame: number) {
        if (frame !== this.frame) {
            throw new ScriptingError('Got frame is not equal to the frame of the shape');
        }

        return {
            pointsLine: this.pointsLine,
            relation: this.relation,
            objectType: ObjectType.SHAPE,
            shapeType: this.shapeType,
            clientID: this.clientID,
            serverID: this.serverID,
            // occluded: this.occluded,
            occluded: false,
            lock: this.lock || this.readonly,
            zOrder: this.zOrder,
            points: [...this.points],
            pointOccludeds: this.pointOccludeds ? [...this.pointOccludeds] : undefined,
            rotation: this.rotation,
            attributes: { ...this.attributes },
            descriptions: [...this.descriptions],
            label: this.label,
            group: this.groupObject,
            color: this.color,
            hidden: this.hidden,
            updated: this.updated,
            pinned: this.pinned,
            frame,
            source: this.source,
            direction: this.direction,
            parentID: this.parentID,
            cameraName: this.cameraName,

            elements: this.elements,
            shapeIndex: this.shapeIndex,
            controls: this.controls,

            readonly: this.readonly,
        };
    }

    public _saveLabel(label: Label, frame: number) {
        const undoLabel = this.label;
        const redoLabel = label;
        const undoAttributes = { ...this.attributes };
        const undoElements = [...this.elements];
        this.label = label;
        this.attributes = {};
        this.elements = [];
        this.appendDefaultAttributes(label);

        // Try to keep old attributes if name matches and old value is still valid
        for (const attribute of redoLabel.attributes) {
            for (const oldAttribute of undoLabel.attributes) {
                if (
                    attribute.name === oldAttribute.name &&
                    Annotation.validateAttributeValue(undoAttributes[oldAttribute.id], attribute)
                ) {
                    this.attributes[attribute.id] = undoAttributes[oldAttribute.id];
                }
            }
        }
        const redoAttributes = { ...this.attributes };
        const redoElements = [...this.elements];

        this.history.do(
            HistoryActions.CHANGED_LABEL,
            () => {
                this.label = undoLabel;
                this.attributes = undoAttributes;
                this.elements = undoElements;
                this.updated = Date.now();
            },
            () => {
                this.label = redoLabel;
                this.attributes = redoAttributes;
                this.elements = redoElements;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );
    }

    public _savePointsLine(pointsLine: number, frame: number) {
        const undoPointsLine = this.pointsLine;
        const redoPointsLine = pointsLine;

        this.history.do(
            HistoryActions.CHANGED_POINTSLINE,
            () => {
                this.pointsLine = undoPointsLine;
                this.updated = Date.now();
            },
            () => {
                this.pointsLine = redoPointsLine;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.pointsLine = pointsLine;
    }
    _savePoints(points: number[], rotation: number, frame: number) {
        const undoPoints = this.points;
        const undoRotation = this.rotation;
        const redoPoints = points;
        const redoRotation = rotation;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;

        this.history.do(
            HistoryActions.CHANGED_POINTS,
            () => {
                this.points = undoPoints;
                this.source = undoSource;
                this.rotation = undoRotation;
                this.updated = Date.now();
            },
            () => {
                this.points = redoPoints;
                this.source = redoSource;
                this.rotation = redoRotation;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.source = Source.MANUAL;
        this.points = points;
        this.rotation = rotation;
    }

    _savePointOccludeds(pointOccludeds: boolean[], frame: number) {
        const undoPointOccludeds = this.pointOccludeds;
        const redoPointOccludeds = pointOccludeds;

        this.history.do(
            HistoryActions.CHANGED_PINNED,
            () => {
                this.pointOccludeds = undoPointOccludeds;
                this.updated = Date.now();
            },
            () => {
                this.pointOccludeds = redoPointOccludeds;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.pointOccludeds = pointOccludeds;
    }

    _saveOccluded(occluded: boolean, frame: number) {
        const undoOccluded = this.occluded;
        const redoOccluded = occluded;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;

        this.history.do(
            HistoryActions.CHANGED_OCCLUDED,
            () => {
                this.occluded = undoOccluded;
                this.source = undoSource;
                this.updated = Date.now();
            },
            () => {
                this.occluded = redoOccluded;
                this.source = redoSource;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.source = Source.MANUAL;
        this.occluded = occluded;
    }

    _saveZOrder(zOrder: number, frame: number) {
        const undoZOrder = this.zOrder;
        const redoZOrder = zOrder;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;

        this.history.do(
            HistoryActions.CHANGED_ZORDER,
            () => {
                this.zOrder = undoZOrder;
                this.source = undoSource;
                this.updated = Date.now();
            },
            () => {
                this.zOrder = redoZOrder;
                this.source = redoSource;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.source = Source.MANUAL;
        this.zOrder = zOrder;
    }

    _saveElements(elements: SubElement[], frame: number) {
        const undoelements = this.elements;
        const redoelements = elements;

        this.history.do(
            HistoryActions.change_elements,
            () => {
                this.elements = undoelements;
                this.updated = Date.now();
            },
            () => {
                this.elements = redoelements;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.elements = elements;
    }

    // 贝赛尔曲线控制点
    protected _saveControls(controls: number[][] | undefined, frame: number) {
        if (!controls) {
            return;
        }
        const undoControls = this.controls;
        const redoControls = controls.map((points) => [...points]);
        const undoSource = this.source;
        const redoSource = Source.MANUAL;

        this.history.do(
            HistoryActions.change_controls,
            () => {
                this.controls = undoControls;
                this.source = undoSource;
                this.updated = Date.now();
            },
            () => {
                this.controls = redoControls;
                this.source = redoSource;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.source = Source.MANUAL;
        this.controls = redoControls;
    }

    async save(frame: number, data: ObjectState): Promise<ObjectState> {
        if (frame !== this.frame) {
            throw new ScriptingError('Got frame is not equal to the frame of the shape');
        }

        if (this.readonly) {
            throw new ScriptingError('该对象目前为只读，不可修改！');
        }

        const updated = data.updateFlags;

        if (updated.parentID && data.parentID) {
            this._saveParentID(data.parentID);
            this.updateTimestamp(updated as unknown as Record<string, boolean>);
        }

        if (updated.relation) {
            this._saveRelation(data.relation);
            this.updateTimestamp(updated as unknown as Record<string, boolean>);
        }

        if (this.lock && data.lock) {
            // 关系的更新与锁定无关
            // if (updated.relation) {
            //     this._saveRelation(data.relation);
            //     this.updateTimestamp(updated as unknown as Record<string, boolean>);
            // }
            updated.reset();
            return Annotation.objectStateFactory.call(this, frame, this.get(frame));
        }

        if (updated.pointsLine) {
            // 与锁定无关
            this._savePointsLine(data.pointsLine, frame);
        }

        // const updated = data.updateFlags;
        const fittedPoints = this._validateStateBeforeSave(frame, data, updated as unknown as Record<string, boolean>);
        const { rotation } = data;

        // Now when all fields are validated, we can apply them
        if (updated.label) {
            this._saveLabel(data.label, frame);
        }

        if (updated.attributes) {
            this._saveAttributes(data.attributes, frame);
        }

        if (updated.descriptions) {
            this._saveDescriptions(data.descriptions);
        }

        if (updated.points && fittedPoints.length) {
            this._savePoints(fittedPoints, rotation, frame);
        }

        if (updated.occluded) {
            this._saveOccluded(data.occluded, frame);
        }

        if (updated.zOrder) {
            this._saveZOrder(data.zOrder, frame);
        }

        if (updated.lock) {
            this._saveLock(data.lock, frame);
        }

        if (updated.pinned) {
            this._savePinned(data.pinned, frame);
        }

        if (updated.color) {
            this._saveColor(data.color, frame);
        }

        if (updated.hidden) {
            this._saveHidden(data.hidden, frame);
        }

        if (updated.elements) {
            this._saveElements(data.elements, frame);
        }

        if (updated.pointOccludeds) {
            this._savePointOccludeds(data.pointOccludeds || [], frame);
        }

        if (updated.controls) {
            this._saveControls(data.controls, frame);
        }

        this.updateTimestamp(updated as unknown as Record<string, boolean>);
        updated.reset();

        return Annotation.objectStateFactory.call(this, frame, this.get(frame));
    }

    public updateAttributes = (attr: Record<number, string>) => {
        this._saveAttributes(attr, this.frame);
    };
}

export class ImageShape extends Shape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.occluded = data.occluded;
        this.shapeType = ShapeType.IMAGE;
        this.pinned = false;
    }
}

export class RectangleShape extends Shape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.RECTANGLE;
        // this.shapeType = data.type;
        this.pinned = false;
        Annotation.checkNumberOfPoints(this.shapeType, this.points);
    }

    static distance(points: number[], x: number, y: number, angle: number) {
        const [xtl, ytl, xbr, ybr] = points;
        const cx = xtl + (xbr - xtl) / 2;
        const cy = ytl + (ybr - ytl) / 2;
        const [rotX, rotY] = Annotation.rotatePoint(x, y, -angle, cx, cy);

        if (!(rotX >= xtl && rotX <= xbr && rotY >= ytl && rotY <= ybr)) {
            // Cursor is outside of a box
            return null;
        }

        // The shortest distance from point to an edge
        return Math.min.apply(null, [rotX - xtl, rotY - ytl, xbr - rotX, ybr - rotY]);
    }
}

export class SplitRectangleShape extends RectangleShape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.splitRectangle;

        this.pointsLine = data.pointsLine || 0;
        // this.shapeType = data.type;
        // this.pinned = false;
        // checkNumberOfPoints(this.shapeType, this.points);
    }

    static distance(points: number[], x: number, y: number, angle: number) {
        const [xtl, ytl, xbr, ybr] = points;
        const cx = xtl + (xbr - xtl) / 2;
        const cy = ytl + (ybr - ytl) / 2;
        const [rotX, rotY] = Annotation.rotatePoint(x, y, -angle, cx, cy);

        if (!(rotX >= xtl && rotX <= xbr && rotY >= ytl && rotY <= ybr)) {
            // Cursor is outside of a box
            return null;
        }

        // The shortest distance from point to an edge
        return Math.min.apply(null, [rotX - xtl, rotY - ytl, xbr - rotX, ybr - rotY]);
    }
}

export class EllipseShape extends Shape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.ELLIPSE;
        this.pinned = false;
        Annotation.checkNumberOfPoints(this.shapeType, this.points);
    }

    static distance(points: number[], x: number, y: number, angle: number) {
        const [cx, cy, rightX, topY] = points;
        const [rx, ry] = [rightX - cx, cy - topY];
        const [rotX, rotY] = Annotation.rotatePoint(x, y, -angle, cx, cy);
        // https://math.stackexchange.com/questions/76457/check-if-a-point-is-within-an-ellipse
        const pointWithinEllipse = (_x: number, _y: number) => (_x - cx) ** 2 / rx ** 2 + (_y - cy) ** 2 / ry ** 2 <= 1;

        if (!pointWithinEllipse(rotX, rotY)) {
            // Cursor is outside of an ellipse
            return null;
        }

        if (Math.abs(x - cx) < Number.EPSILON && Math.abs(y - cy) < Number.EPSILON) {
            // cursor is near to the center, just return minimum of height, width
            return Math.min(rx, ry);
        }

        // ellipse equation is x^2/rx^2 + y^2/ry^2 = 1
        // from this equation:
        // x^2 = ((rx * ry)^2 - (y * rx)^2) / ry^2
        // y^2 = ((rx * ry)^2 - (x * ry)^2) / rx^2

        // we have one point inside the ellipse, let's build two lines (horizontal and vertical) through the point
        // and find their interception with ellipse
        const x2Equation = (_y: number) => ((rx * ry) ** 2 - (_y * rx) ** 2) / ry ** 2;
        const y2Equation = (_x: number) => ((rx * ry) ** 2 - (_x * ry) ** 2) / rx ** 2;

        // shift x,y to the ellipse coordinate system to compute equation correctly
        // y axis is inverted
        const [shiftedX, shiftedY] = [x - cx, cy - y];
        const [x1, x2] = [Math.sqrt(x2Equation(shiftedY)), -Math.sqrt(x2Equation(shiftedY))];
        const [y1, y2] = [Math.sqrt(y2Equation(shiftedX)), -Math.sqrt(y2Equation(shiftedX))];

        // found two points on ellipse edge
        const ellipseP1X = shiftedX >= 0 ? x1 : x2; // ellipseP1Y is shiftedY
        const ellipseP2Y = shiftedY >= 0 ? y1 : y2; // ellipseP1X is shiftedX

        // found diffs between two points on edges and target point
        const diff1X = ellipseP1X - shiftedX;
        const diff2Y = ellipseP2Y - shiftedY;

        // return minimum, get absolute value because we need distance, not diff
        return Math.min(Math.abs(diff1X), Math.abs(diff2Y));
    }
}

export class PolyShape extends Shape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.rotation = 0; // is not supported
    }
}

export class PolygonShape extends PolyShape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.POLYGON;
        Annotation.checkNumberOfPoints(this.shapeType, this.points);
    }

    static distance(points: number[], x: number, y: number) {
        function position(x1: number, y1: number, x2: number, y2: number) {
            return (x2 - x1) * (y - y1) - (x - x1) * (y2 - y1);
        }

        let wn = 0;
        const distances = [];

        for (let i = 0, j = points.length - 2; i < points.length - 1; j = i, i += 2) {
            // Current point
            const x1 = points[j];
            const y1 = points[j + 1];

            // Next point
            const x2 = points[i];
            const y2 = points[i + 1];

            // Check if a point is inside a polygon
            // with a winding numbers algorithm
            // https://en.wikipedia.org/wiki/Point_in_polygon#Winding_number_algorithm
            if (y1 <= y) {
                if (y2 > y) {
                    if (position(x1, y1, x2, y2) > 0) {
                        wn++;
                    }
                }
            } else if (y2 <= y) {
                if (position(x1, y1, x2, y2) < 0) {
                    wn--;
                }
            }

            // Find the shortest distance from point to an edge
            // Get an equation of a line in general
            const aCoef = y1 - y2;
            const bCoef = x2 - x1;

            // Vector (aCoef, bCoef) is a perpendicular to line
            // Now find the point where two lines
            // (edge and its perpendicular through the point (x,y)) are cross
            const xCross = x - aCoef;
            const yCross = y - bCoef;

            if ((xCross - x1) * (x2 - xCross) >= 0 && (yCross - y1) * (y2 - yCross) >= 0) {
                // Cross point is on segment between p1(x1,y1) and p2(x2,y2)
                distances.push(Math.sqrt((x - xCross) ** 2 + (y - yCross) ** 2));
            } else {
                distances.push(
                    Math.min(Math.sqrt((x1 - x) ** 2 + (y1 - y) ** 2), Math.sqrt((x2 - x) ** 2 + (y2 - y) ** 2)),
                );
            }
        }

        if (wn !== 0) {
            return Math.min.apply(null, distances);
        }

        return null;
    }
}

export class PolylineShape extends PolyShape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.POLYLINE;
        Annotation.checkNumberOfPoints(this.shapeType, this.points);
    }

    static distance(points: number[], x: number, y: number) {
        const distances = [];
        for (let i = 0; i < points.length - 2; i += 2) {
            // Current point
            const x1 = points[i];
            const y1 = points[i + 1];

            // Next point
            const x2 = points[i + 2];
            const y2 = points[i + 3];

            // Find the shortest distance from point to an edge
            if ((x - x1) * (x2 - x) >= 0 && (y - y1) * (y2 - y) >= 0) {
                // Find the length of a perpendicular
                // https://en.wikipedia.org/wiki/Distance_from_a_point_to_a_line
                distances.push(
                    Math.abs((y2 - y1) * x - (x2 - x1) * y + x2 * y1 - y2 * x1) /
                        Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2),
                );
            } else {
                // The link below works for lines (which have infinite length)
                // There is a case when perpendicular doesn't cross the edge
                // In this case we don't use the computed distance
                // Instead we use just distance to the nearest point
                distances.push(
                    Math.min(Math.sqrt((x1 - x) ** 2 + (y1 - y) ** 2), Math.sqrt((x2 - x) ** 2 + (y2 - y) ** 2)),
                );
            }
        }

        return Math.min.apply(null, distances);
    }
}

export class PointsShape extends PolyShape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.POINTS;
        Annotation.checkNumberOfPoints(this.shapeType, this.points);
    }

    static distance(points: number[], x: number, y: number) {
        const distances = [];
        for (let i = 0; i < points.length; i += 2) {
            const x1 = points[i];
            const y1 = points[i + 1];

            distances.push(Math.sqrt((x1 - x) ** 2 + (y1 - y) ** 2));
        }

        return Math.min.apply(null, distances);
    }
}

export class CuboidShape extends Shape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.rotation = 0;
        this.shapeType = ShapeType.CUBOID;
        this.pinned = false;
        Annotation.checkNumberOfPoints(this.shapeType, this.points);
    }

    static makeHull(geoPoints: Point[]) {
        // Returns the convex hull, assuming that each points[i] <= points[i + 1].
        function makeHullPresorted(points: Point[]) {
            if (points.length <= 1) return points.slice();

            // Andrew's monotone chain algorithm. Positive y coordinates correspond to 'up'
            // as per the mathematical convention, instead of 'down' as per the computer
            // graphics convention. This doesn't affect the correctness of the result.

            const upperHull: Point[] = [];
            for (let i = 0; i < points.length; i += 1) {
                const p = points[`${i}`];
                while (upperHull.length >= 2) {
                    const q = upperHull[upperHull.length - 1];
                    const r = upperHull[upperHull.length - 2];
                    if ((q.x - r.x) * (p.y - r.y) >= (q.y - r.y) * (p.x - r.x)) upperHull.pop();
                    else break;
                }
                upperHull.push(p);
            }
            upperHull.pop();

            const lowerHull = [];
            for (let i = points.length - 1; i >= 0; i -= 1) {
                const p = points[`${i}`];
                while (lowerHull.length >= 2) {
                    const q = lowerHull[lowerHull.length - 1];
                    const r = lowerHull[lowerHull.length - 2];
                    if ((q.x - r.x) * (p.y - r.y) >= (q.y - r.y) * (p.x - r.x)) lowerHull.pop();
                    else break;
                }
                lowerHull.push(p);
            }
            lowerHull.pop();

            if (
                upperHull.length === 1 &&
                lowerHull.length === 1 &&
                upperHull[0].x === lowerHull[0].x &&
                upperHull[0].y === lowerHull[0].y
            )
                return upperHull;
            return upperHull.concat(lowerHull);
        }

        function POINT_COMPARATOR(a: Point, b: Point) {
            if (a.x < b.x) return -1;
            if (a.x > b.x) return +1;
            if (a.y < b.y) return -1;
            if (a.y > b.y) return +1;
            return 0;
        }

        const newPoints = geoPoints.slice();
        newPoints.sort(POINT_COMPARATOR);
        return makeHullPresorted(newPoints);
    }

    static contain(shapePoints: Point[], x: number, y: number) {
        function isLeft(P0: Point, P1: Point, P2: Point) {
            return (P1.x - P0.x) * (P2.y - P0.y) - (P2.x - P0.x) * (P1.y - P0.y);
        }
        const points = CuboidShape.makeHull(shapePoints);
        let wn = 0;
        for (let i = 0; i < points.length; i += 1) {
            const p1 = points[`${i}`];
            const p2 = points[i + 1] || points[0];

            if (p1.y <= y) {
                if (p2.y > y) {
                    if (isLeft(p1, p2, { x, y }) > 0) {
                        wn += 1;
                    }
                }
            } else if (p2.y < y) {
                if (isLeft(p1, p2, { x, y }) < 0) {
                    wn -= 1;
                }
            }
        }

        return wn !== 0;
    }

    static distance(actualPoints: number[], x: number, y: number) {
        const points = [];

        for (let i = 0; i < 16; i += 2) {
            points.push({ x: actualPoints[i], y: actualPoints[i + 1] });
        }

        if (!CuboidShape.contain(points, x, y)) return null;

        let minDistance = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < points.length; i += 1) {
            const p1 = points[`${i}`];
            const p2 = points[i + 1] || points[0];

            // perpendicular from point to straight length
            const distance =
                Math.abs((p2.y - p1.y) * x - (p2.x - p1.x) * y + p2.x * p1.y - p2.y * p1.x) /
                Math.sqrt((p2.y - p1.y) ** 2 + (p2.x - p1.x) ** 2);

            // check if perpendicular belongs to the straight segment
            const a = (p1.x - x) ** 2 + (p1.y - y) ** 2;
            const b = (p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2;
            const c = (p2.x - x) ** 2 + (p2.y - y) ** 2;
            if (distance < minDistance && a + b - c >= 0 && c + b - a >= 0) {
                minDistance = distance;
            }
        }
        return minDistance;
    }
}

export class LanelineShape extends Shape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.rotation = 0;
        this.shapeType = ShapeType.laneline;
        this.pinned = false;
        Annotation.checkNumberOfPoints(this.shapeType, this.points);
    }
}

export class Bezier2Shape extends Shape {
    constructor(data: ServerShapeData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.rotation = 0;
        this.shapeType = ShapeType.bezier2;
        this.pinned = false;
        Annotation.checkNumberOfPoints(this.shapeType, this.points);
    }

    static distance(actualPoints: number[], x: number, y: number) {
        // const points = [];

        // for (let i = 0; i < 16; i += 2) {
        //     points.push({ x: actualPoints[i], y: actualPoints[i + 1] });
        // }

        // if (!CuboidShape.contain(points, x, y)) return null;

        // let minDistance = Number.MAX_SAFE_INTEGER;
        // for (let i = 0; i < points.length; i += 1) {
        //     const p1 = points[`${i}`];
        //     const p2 = points[i + 1] || points[0];

        //     // perpendicular from point to straight length
        //     const distance =
        //         Math.abs((p2.y - p1.y) * x - (p2.x - p1.x) * y + p2.x * p1.y - p2.y * p1.x) /
        //         Math.sqrt((p2.y - p1.y) ** 2 + (p2.x - p1.x) ** 2);

        //     // check if perpendicular belongs to the straight segment
        //     const a = (p1.x - x) ** 2 + (p1.y - y) ** 2;
        //     const b = (p2.x - p1.x) ** 2 + (p2.y - p1.y) ** 2;
        //     const c = (p2.x - x) ** 2 + (p2.y - y) ** 2;
        //     if (distance < minDistance && a + b - c >= 0 && c + b - a >= 0) {
        //         minDistance = distance;
        //     }
        // }
        return 0;
    }
}

export default Shape;
