/*
 * @Author: swxy
 * @Date: 2023-08-26 14:04:03
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { InputType } from 'reducers/interfaces';
import { ArgumentError } from 'errors/exception';
import { ControlType, DeleteStatus, FrameType, LabelObjectType, LabelProjectType, LabelType } from 'utils/ConstType';

interface SerializedLabel {
    projectId: number; //	项目	integer(int32)	integer(int32)
    id: number; //	编号	integer(int32)	integer(int32)
    labelId: number; //	全局标签编号	integer(int32)	integer(int32)
    labelObjectType: LabelObjectType; //	标签对象类型	integer(int32)	integer(int32)
    labelProjectType: LabelProjectType; //	标签项目类型	integer(int32)	integer(int32)
    labelType: LabelType; //	标签类型	integer(int32)	integer(int32)
    name: string; //	名字	string
    title: string; //	显示标题	string
    color: string; //	颜色	string
    dumpName: string; //	导出名称	string
    mainLabelId: number; //	父标签	integer(int32)	integer(int32)
    deleteStatus: DeleteStatus; //	删除状态	integer(int32)	integer(int32)
    enName: string; //	英文名称	string
    updateTime: string; //	更新时间	string(date-time)	string(date-time)
    addTime: string; //	输入时间	string(date-time)	string(date-time)
    attributes: SerializedAttribute[];
}
interface SerializedAttribute {
    projectId: number; //	项目	integer(int32)
    projectLabelId: number; //	标签编号	integer(int32)
    id: number; //	编号	integer(int32)
    labelId: number; //	标签编号	integer(int32)
    name: string; //	名字	string
    colors: string; //	颜色	string
    ranges: string; //	范围值	string
    titles: string; //	显示标题	string
    values: string; //	值	string
    controlType: ControlType; //	控件类型	integer(int32)
    defaultValue: string; //	默认值	string
    dumpName: string; //	导出名称	string
    mutable: boolean; //	可以多选	boolean
    // frameType: FrameType	帧类型	integer(int32)	----无用
    deleteStatus: DeleteStatus; //	删除状态	array	integer
    enName: string; //	英文名称	string
    updateTime: string; //	更新时间	string(date-time)
    addTime: string; //	输入时间	string(date-time)
}

const controlTypes = ['normal', 'serialid', 'select', 'radio', 'checkbox', 'number', 'text'];

export class Attribute {
    public readonly id: number;
    public readonly labelId: number;

    public readonly title: string; // 显示标题
    public readonly name: string;
    public readonly inputType: InputType;

    public readonly colors: string[];
    /**
     * 范围值列表
     */
    public readonly values: string[]; // values
    /**
     * @readonly
     * 范围值对应的显示名称
     */
    public readonly ranges: string[];

    public readonly defaultValue: string;
    public readonly mutable: boolean;

    public readonly controlType: ControlType;
    public readonly enName: string;
    public readonly dumpName: string;

    constructor(initialData: SerializedAttribute) {
        this.id = initialData.id;
        this.labelId = initialData.labelId;

        this.name = initialData.name;
        this.title = initialData.titles || initialData.name;

        this.values = JSON.parse(initialData.values || '[]');
        this.ranges = initialData.ranges ? JSON.parse(initialData.ranges) || [] : this.values;

        this.defaultValue = initialData.defaultValue;
        this.mutable = initialData.mutable;
        this.controlType = initialData.controlType;
        this.enName = initialData.enName;
        this.dumpName = initialData.dumpName;
        this.inputType = controlTypes[this.controlType] as InputType;

        this.colors = initialData.colors ? JSON.parse(initialData.colors) || [] : [];

        // for (const key in data) {
        //     if (Object.prototype.hasOwnProperty.call(data, key)) {
        //         if (Object.prototype.hasOwnProperty.call(initialData, key)) {
        //             if (Array.isArray(initialData[key])) {
        //                 data[key] = [...initialData[key]];
        //             } else {
        //                 data[key] = initialData[key];
        //             }
        //         }
        //     }
        // }

        if (!Object.values(InputType).includes(this.inputType)) {
            throw new ArgumentError(`Got invalid attribute type ${this.inputType}`);
        }
    }

    public get titles() {
        return this.title;
    }

    // getDefaultValue() {
    //     if (this.values && this.values.length) {
    //         return this.values[0];
    //     }
    //     return '';
    // }

    //     toJSON(): SerializedAttribute {
    //         const object: SerializedAttribute = {
    //             name: this.name,
    //             titles: this.titles,
    //             mutable: this.mutable,
    //             // defaultValue: this.defaultValue,
    //             // inputType: this.inputType,
    //             defaultValue: this.getDefaultValue(),
    //             values: JSON.stringify(this.values || []),
    //             ranges: JSON.stringify(this.ranges || []),
    //             controlType: this.controlType,
    //             // labelObjectType: this.labelObjectType,
    //             dumpName: this.dumpName,
    //         };

    //         if (typeof this.id !== 'undefined') {
    //             object.id = this.id;
    //         }

    //         return object;
    //     }
}

export class Label {
    public readonly id: number;
    public readonly labelId: number;
    public readonly labelType: LabelType;
    public readonly projectId: number;
    public readonly name: string;
    public readonly dumpName: string;
    public readonly title: string;
    public readonly color: string;
    public readonly labelObjectType: LabelObjectType;
    public readonly labelProjectType: LabelProjectType;
    public readonly mainLabelId: number;
    public readonly attributes: Attribute[];
    public isUniformSize: boolean = true;

    constructor(initialData: SerializedLabel) {
        this.id = initialData.labelId;
        this.labelId = initialData.labelId;
        this.labelType = initialData.labelType;
        this.projectId = initialData.projectId;
        this.name = initialData.name;
        this.title = initialData.title || this.name;
        this.dumpName = initialData.dumpName;
        this.color = initialData.color;
        this.labelObjectType = initialData.labelObjectType;
        this.labelProjectType = initialData.labelProjectType;
        this.mainLabelId = initialData.mainLabelId;
        this.attributes = initialData.attributes.map((attr) => new Attribute(attr));
    }

    public get parentLabelId() {
        return this.mainLabelId;
    }

    public get isSub() {
        return this.labelProjectType === LabelProjectType.two;
    }
}
