/*
 * @Author: swxy
 * @Date: 2022-08-17 18:35:05
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
     'workspace.track': '連続フレーム',
     'workspace.shape': '通常フレーム',

     'workspace.top.back': '前のページに戻る',
     'workspace.top.save': '現在の変更を保存する',

     'workspace.top.undo': '前の操作を取り消す',
     'workspace.top.redo': '次の操作に進む',

     'workspace.top.go_back': '前へ',
     'workspace.top.go_back_filter': '前の条件フレーム',
     'workspace.top.go_back_empty': '前の空白フレーム',
     'workspace.top.go_next': '次へ',
     'workspace.top.go_next_filter': '次の条件フレーム',
     'workspace.top.go_next_empty': '次の空白フレーム',

     'workspace.top.frist_frame': '最初のフレーム',
     'workspace.top.prve_step': '前のセクション',
     'workspace.top.play': '再生',
     'workspace.top.pause': '一時停止',

     "workspace.top.next_step": "次のセクション",
     "workspace.top.last_frame": "最後のフレーム",

     "workspace.top.change_frame_input_tip": "{{hot}}を押してフォーカスを合わせます",
     "workspace.top.panle_m": "パネルを表示/非表示にする",
     "workspace.top.attribute_m": "注釈画面で属性を表示/非表示にする",
     "workspace.top.active_move": "マウス移動でオブジェクトをアクティブ化する",
     "workspace.top.active_click": "マウスクリックでオブジェクトをアクティブ化する",
     "workspace.top.fullscreen_exit": "全画面表示を終了する",
     "workspace.top.fullscreen": "全画面表示",
     "workspace.top.annotation_info": "注釈情報",
     "workspace.top.annotation_object_filter": "注釈オブジェクトをフィルタリングする",

     "workspace.top.drop.unsavedata_tip": "現在の作業には保存されていない操作があります",
     "workspace.top.drop.save_tip": "続行する前に保存しますか？",
     "workspace.top.drop.save": "保存する",
     "workspace.top.drop.unsave": "保存しない",

     'workspace.top.drop.change_workspace_tip': '現在の作業状態を変更する',
     'workspace.top.drop.change_workspace_content_tip': '現在の作業状態は「完了済み」に変更されます。続行しますか？',
     'workspace.top.drop.change_workspace_continue': '続行',
     'workspace.top.drop.change_workspace_cancle': 'キャンセル',

     'workspace.top.drop.showAllProjection': '完全な投影ディスプレイ',
     'workspace.top.drop.showAllProjection_tip': '画像上のディスプレイターゲットの投影',
     'workspace.top.drop.panel_model': 'パネル表示モード',
     'workspace.top.drop.change_panel_model': 'パネルリスト表示モードを切り替える',
     'workspace.top.drop.panel_left': '左側パネル',
     'workspace.top.drop.panel_show_on_left': 'パネルリストを左側に表示する',
     'workspace.top.drop.change_serialid': '識別子を変更する',
     'workspace.top.drop.panel_sort': 'パネルリストの並べ替え',
     'workspace.top.drop.change_workspace': '作業モードを変更する',
     'workspace.top.drop.submit': '提出する',
     'workspace.top.drop.submit_title': '提出する',

     'workspace.top.drop.workspace_standard3d': '標準 (3D)',
     'workspace.top.drop.workspace_standard': 'スタンダード',
     'workspace.top.drop.workspace_attribute_annotation': '属性',
     'workspace.top.drop.workspace_tag_annotation': 'タグ <Tag>',
     'workspace.top.drop.workspace_review': 'レビュー',

     'workspace.top.modal.sort': '並べ替え',
     'workspace.top.modal.sort_ID_descent': 'オブジェクト ID - 降順',
     'workspace.top.modal.sort_ID_ascent': 'オブジェクト ID - Ascent',
     'workspace.top.modal.sort_update_time': '更新時間',

     'workspace.top.modal.submit_permission': 'パーミッション',
     'workspace.top.modal.submit_permission_sub': 'この操作を実行する権限がない可能性があります。質問がある場合は、プロジェクト管理者に尋ねることができます! ',
     'workspace.top.modal.submit_permission_close': '閉じる',

     'workspace.top.modal.submit_review_success': 'レビューが成功しました! ',
     'workspace.top.modal.submit_review_back_reason_w': '返品理由を入力してください',
     'workspace.top.modal.submit_status_submit': '送信',
     'workspace.top.modal.submit_status_frist_review': '最初のトライアルに合格しました',
     'workspace.top.modal.submit_status_second_review': '2 回目の審査に合格しました',
     'workspace.top.modal.submit_status_acceptance': '承認に合格しました',
     'workspace.top.modal.submit_status_review': 'レビュー',

     'workspace.top.modal.submit_loading_tip': 'データを生成しています。ページを閉じないでください...',
     'workspace.top.modal.submit_content': 'この質問パッケージ? ',

     'workspace.top.modal.submit_sorce_very_bad': '非常に悪い',
     'workspace.top.modal.submit_sorce_bad': '悪い',
     'workspace.top.modal.submit_sorce_authorized': '修飾済み',
     'workspace.top.modal.submit_sorce_good': '非常に良い',
     'workspace.top.modal.submit_sorce_very_good': '非常に良い',

     'workspace.top.modal.submit_submit': '送信',
     'workspace.top.modal.submit_pass': '合格',
     'workspace.top.modal.submit_back_reason': '返品理由を入力してください! ',
     'workspace.top.modal.submit_back': '戻る',
     'workspace.top.modal.submit_close': '閉じる',

     'workspace.content.view.top': 'トップ ビュー',
     'workspace.content.view.side': '側面図',
     'workspace.content.view.front': '正面からの視点',

     'workspace.content.view.object_length': '長さ:',
     'workspace.content.view.object_width': '幅:',
     'workspace.content.view.object_height': '高:',

     'workspace.content.view.object_cloud_point': '点群番号:',

     'workspace.content.image.only_show_projection_tip': '選択したオブジェクトの投影のみを表示します',
     'workspace.content.image.only_show_select': '選択する',

     'workspace.side.controls.cursor': 'カーソル',
     'workspace.side.controls.parent_sub_delete': '選択後、親子に関連付けられたオブジェクトがあります。親オブジェクトが削除されると、子要素も一緒に削除されます。 ',

     'workspace.side.controls.max_points': '最大ポイント',
     'workspace.side.controls.draw_cuboid_model_tip': '直方体生成モード',
     'workspace.side.controls.draw_cuboid_model_from_rectangle': '長方形拡張',
     'workspace.side.controls.draw_cuboid_model_4_points': '4 ポイント',

     'workspace.side.controls.draw_continue_tip': 'メモを完了したら、現在のモードのままでメモを続けます',
     'workspace.side.controls.draw_continue': '連続注釈',

     'workspace.side.controls.cuboid_tip': '押すと直方体描画モードに入ります。 繰り返し操作キャンセル',
     'workspace.side.controls.cuboid_3d_tip': '3D ボックスを描画',

     'workspace.side.controls.ellipse_tip': '押すと円描画モードに入ります',
     'workspace.side.controls.points_tip': 'を押して点描画モードに入ります。 数量が設定されていない場合は、再度 [OK] をクリックします。 ',
     'workspace.side.controls.polygon_tip': '押すとポリゴン描画モードに入ります。 数量が設定されていない場合は、再度 [OK] をクリックします。 ',
     'workspace.side.controls.polyline_tip': '押すと線画モードに入ります。 数量が設定されていない場合は、再度 [OK] をクリックします。 ',
     'workspace.side.controls.rectangle_tip': "を押して長方形描画モードに入り、'a' ショートカット キーを押して直接長方形描画モードに入ります。 繰り返し操作キャンセル",
     'workspace.side.controls.fit_tip': '表示画像をウィンドウサイズに合わせる [マウスをダブルクリック]',
     'workspace.side.controls.group_tip': '共通フレーム {{objectType}} をグループ化します。 グループを選択した後、{{キー}}を押してグループをキャンセルします',
     'workspace.side.controls.merge_tip': '通常フレーム/連続フレームを 1 つの連続フレームに結合',
     'workspace.side.controls.split_tip': '選択した連続フレームとその後の新しい連続フレームへのセグメンテーション',
     'workspace.side.controls.add_v_line': '現在のデフォルトラベルを使用して、セカンダリオブジェクト換算ラインを追加します',
     'workspace.side.controls.add_h_line': '現在のデフォルトラベルを使用して、セカンダリオブジェクトホリゾントセグメンテーションラインを追加します',
     'workspace.side.controls.move_tip': '画像を移動',
     'workspace.side.controls.birdEye': '鳥瞰図のビューの下で、CTRLを押してそれを置かないで、マウスの左ボタンを押し、フレームをヘッドの長い正方形に引っ張ります',
     'workspace.side.controls.resize_interest_tip': 'サイズ変更する範囲を選択',
     'workspace.side.controls.rotate_anticlockwise_tip': '画像を反時計回りに回転',
     'workspace.side.controls.rotate_clockwise_tip': '画像を時計回りに回転',
     'workspace.side.controls.fine_tuning': '微調整',

     'workspace.side.controls.create_issue': '注釈を作成します',

     'workspace.side.controls.track_model_tip': '<通常の注釈オブジェクト> と <連続フレーム注釈オブジェクト> の作成モードを切り替えます',
     'workspace.side.controls.track_model': '連続フレーム',
     'workspace.side.controls.autoFit_tip': '回転を自動的に計算し、ボックスをポイントクラウドに閉じます',
     'workspace.side.controls.autoFit': '自動フィット',

     'workspace.side.issue.defualt_label_msg': 'デフォルトのラベルが次のように変更されました:',
     'workspace.side.issue.delete_issue_pop': 'コメントを削除するかどうか',
     'workspace.side.issue.delete_delete': '削除',
     'workspace.side.issue.delete_cancel': 'キャンセル',
     'workspace.side.issue.find_previous_frame_issues': 'コメント付きの前のフレームを検索',
     'workspace.side.issue.find_next_frame_issues': 'コメント付きの次のフレームを検索',
     'workspace.side.issue.show/hide_all_issue': 'すべての問題を表示/非表示',
     'workspace.side.issue.hide_all_resolved_issue': '解決済みの問題をすべて非表示',
     'workspace.side.issue.resolved': '解決済み',
     'workspace.side.issue.open': '開く',

     'workspace.side.object.show_detail_att': '現在のオブジェクトの属性を表示',
     'workspace.side.object.track_simple': '接続',
     'workspace.side.object.lock': '現在のオブジェクトをロック',
     'workspace.side.object.occluded': '現在のオブジェクトが遮られているかどうか',
     'workspace.side.object.pinned': '現在のオブジェクトの位置を固定',
     'workspace.side.object.hidden': '現在のオブジェクトを非表示',
     'workspace.side.object.outside': '現在のオブジェクトは画面の外にあります',
     'workspace.side.object.keyframe': '現在のオブジェクトのキーフレームを設定/解除',

     'workspace.side.object.label_type': 'ラベルの種類',
     'workspace.side.object.parent_id': '親オブジェクト ID',
     'workspace.side.object.cancel_relation': '関連付けをキャンセル',
     'workspace.side.object.cancel_relation_tip': '現在の関係をキャンセル',

     'workspace.side.object.context.create_url': '現在のオブジェクト リンクを作成',
     'workspace.side.object.context.right_context_panel': '右クリック プロパティ パネルを表示',
     'workspace.side.object.context.copy': 'コピー',
     'workspace.side.object.context.propagate': 'マルチフレーム レプリケーション',
     'workspace.side.object.context.changeStartFrame': '開始フレームを変更',
     'workspace.side.object.context.switch': '方向を切り替える',
     'workspace.side.object.context.reset': '視点をリセット',
     'workspace.side.object.context.background': '背景',
     'workspace.side.object.context.foreground': 'フォアグラウンド',
     'workspace.side.object.context.changeColor': '{{name}} の色を変更',
     'workspace.side.object.context.object_lock_tip': 'オブジェクトは既にロックされています',
     'workspace.side.object.context.delete_object_lock_tip': '削除を続けますか?',
     'workspace.side.object.context.delete': '削除',

     'workspace.side.top.lock_all': '現在のすべての注釈オブジェクトをロック',
     'workspace.side.top.hide_all_unlock': '現在ロック解除されているすべての注釈オブジェクトを非表示にする',
     'workspace.side.top.expand_collapse_all': 'リスト内のすべての注釈オブジェクトのプロパティを展開/折りたたむ',
     'workspace.side.top.force_active': '現在のみ表示',

     'workspace.side.appearance.color': '色',

     'workspace.side.appearance.instance': 'オブジェクト',
     'workspace.side.appearance.group': 'グループ',
     'workspace.side.appearance.label': 'ラベル',

     'workspace.side.appearance.opacity': '不透明度',
     'workspace.side.appearance.selected_opacity': '選択した不透明度',
     'workspace.side.appearance.outlined_borders': '外枠',
     'workspace.side.appearance.show_bitmap': 'ビットマップを表示',
     'workspace.side.appearance.show_projections': '投影を表示',

     'workspace.side.tab.object': 'オブジェクト',
     'workspace.side.tab.labels': 'ラベル',
     'workspace.side.tab.issues': '承認',
     'workspace.side.tab.appear': '外観',

     'workspace.side.export.dumpOrderType': 'エクスポートファイルタイプ',

     'workspace.side.object.context.trackFrame.title': '連続フレーム',
     'workspace.side.object.context.trackFrame.detail': '現在の連続フレームの開始フレームを変更するには（現在のオブジェクトを前に延長）：第',
     'workspace.side.object.context.trackFrame.track': 'フレーム',

     'workspace.canvas.rectangleHelp': '最低寸法条件に達していません：幅-{minWidth}、高さ-{minHeight}',
};
