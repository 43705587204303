// Copyright (C) 2019-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

const config = require('./config');

(() => {
    const PluginRegistry = require('./plugins');
    const serverProxy = require('./server-proxy');
    const lambdaManager = require('./lambda-manager');
    const {
        isBoolean,
        isInteger,
        isString,
        checkFilter,
        checkExclusiveFields,
        camelToSnake,
        checkObjectType,
    } = require('./common');

    const User = require('./user');
    const { AnnotationFormats } = require('./annotation-formats');
    // const { ArgumentError } = require('./exceptions');
    const { Task, Job } = require('./session');
    const { Project } = require('./project');
    const { CloudStorage } = require('./cloud-storage');
    const Organization = require('./organization');

    function implementAPI(cvat) {
        cvat.plugins.list.implementation = PluginRegistry.list;
        cvat.plugins.register.implementation = PluginRegistry.register.bind(cvat);

        cvat.lambda.list.implementation = lambdaManager.list.bind(lambdaManager);
        cvat.lambda.run.implementation = lambdaManager.run.bind(lambdaManager);
        cvat.lambda.call.implementation = lambdaManager.call.bind(lambdaManager);
        cvat.lambda.cancel.implementation = lambdaManager.cancel.bind(lambdaManager);
        cvat.lambda.listen.implementation = lambdaManager.listen.bind(lambdaManager);
        cvat.lambda.requests.implementation = lambdaManager.requests.bind(lambdaManager);
        serverProxy.server.heartbeat();
        cvat.server.heartbeat.implementation = async () => {
            const result = await serverProxy.server.heartbeat();
            return result;
        };

        cvat.server.about.implementation = async () => {
            const result = await serverProxy.server.about();
            return result;
        };

        cvat.server.share.implementation = async (directory) => {
            const result = await serverProxy.server.share(directory);
            return result;
        };

        cvat.server.formats.implementation = async () => {
            const result = await serverProxy.server.formats();
            return new AnnotationFormats(result);
        };

        cvat.server.userAgreements.implementation = async () => {
            const result = await serverProxy.server.userAgreements();
            return result;
        };

        cvat.server.registerCheckUserRepeat.implementation = async (params) => {
            const isRepeat = await serverProxy.server.registerCheckUserRepeat(params);
            return isRepeat;
        };

        cvat.server.getRegisterToken.implementation = async (params) => {
            const isRepeat = await serverProxy.server.getRegisterToken(params);
            return isRepeat;
        };

        cvat.server.register.implementation = async (
            username,
            firstName,
            lastName,
            email,
            password1,
            password2,
            userConfirmations,
        ) => {
            const result = await serverProxy.server.register(
                username,
                firstName,
                lastName,
                email,
                password1,
                password2,
                userConfirmations,
            );

            return result;
        };

        cvat.server.login.implementation = async (...data) => {
            await serverProxy.server.login(...data);
        };

        cvat.server.logout.implementation = async () => {
            await serverProxy.server.logout();
        };

        cvat.server.changePassword.implementation = async (oldPassword, newPassword1, newPassword2) => {
            await serverProxy.server.changePassword(oldPassword, newPassword1, newPassword2);
        };

        cvat.server.requestPasswordReset.implementation = async (email) => {
            await serverProxy.server.requestPasswordReset(email);
        };

        cvat.server.resetPassword.implementation = async (newPassword1, uid) => {
            await serverProxy.server.resetPassword(newPassword1, uid);
        };

        cvat.server.authorized.implementation = async (callback) => {
            const result = await serverProxy.server.authorized(callback);
            return result;
        };

        cvat.server.request.implementation = async (url, data) => {
            const result = await serverProxy.server.request(url, data);
            return result;
        };

        cvat.server.installedApps.implementation = async () => {
            const result = await serverProxy.server.installedApps();
            return result;
        };

        cvat.server.getEmailLoginCode.implementation = async (...data) => {
            const result = await serverProxy.server.getEmailLoginCode(...data);
            return result;
        };

        cvat.server.loginByEmailCode.implementation = async (...data) => {
            const result = await serverProxy.server.loginByEmailCode(...data);
            return result;
        };

        cvat.server.clearAnnotation.implementation = async (...data) => {
            const result = await serverProxy.server.clearAnnotation(...data);
            return result;
        };

        cvat.users.get.implementation = async (filter) => {
            checkFilter(filter, {
                id: isInteger,
                is_active: isBoolean,
                self: isBoolean,
                search: isString,
                limit: isInteger,
            });

            let users = null;
            if ('self' in filter && filter.self) {
                users = await serverProxy.users.self();
                users = [users];
            } else {
                const searchParams = {};
                for (const key in filter) {
                    if (filter[key] && key !== 'self') {
                        searchParams[key] = filter[key];
                    }
                }
                users = await serverProxy.users.get(searchParams);
            }

            users = users.map((user) => new User(user));
            return users;
        };

        cvat.jobs.get.implementation = async (filter) => {
            // checkFilter(filter, {
            //     page: isInteger,
            //     filter: isString,
            //     sort: isString,
            //     search: isString,
            //     taskID: isInteger,
            //     jobID: isInteger,
            // });
            checkFilter(filter, {
                id: isInteger,
                page: isInteger,
                pageIndex: isInteger,
                pageSize: isInteger,
                filter: isString,
                sort: isString,
                search: isString,
                taskId: isInteger,
                jobId: isInteger,
            });

            // if ('taskId' in filter && 'jobId' in filter) {
            //     throw new
            //  ArgumentError('Filter fields "taskID" and "jobID" are not permitted to be used at the same time');
            // }

            // if ('taskId' in filter) {
            //     const jobs = await serverProxy.jobs.get({ taskId: filter.taskId });
            //     if (jobs) {
            //         return jobs.map((job) => new Job(job));
            //     }

            //     return [];
            // }

            if ('jobId' in filter || 'id' in filter) {
                const job = await serverProxy.jobs.get({ id: filter.jobId || filter.id });
                if (job) {
                    return [new Job(job)];
                }
            }

            const jobsData = await serverProxy.jobs.get(filter);

            const jobs = jobsData.map((jobData) => new Job(jobData));
            jobs.count = jobsData.count;
            return jobs;
        };
        cvat.jobs.getInfoList.implementation = async (filter) => {
            // checkFilter(filter, {
            //     id: isInteger,
            //     page: isInteger,
            //     pageIndex: isInteger,
            //     pageSize: isInteger,
            //     filter: isString,
            //     sort: isString,
            //     search: isString,
            //     taskId: isInteger,
            //     jobId: isInteger,
            // });

            if ('jobId' in filter || 'id' in filter) {
                const job = await serverProxy.jobs.get({ id: filter.jobId || filter.id });
                if (job) {
                    return job;
                }
            }

            const jobsData = await serverProxy.jobs.get(filter);

            // const jobs = jobsData.map((jobData) => new Job(jobData));
            // jobs.count = jobsData.count;
            return jobsData;
        };

        cvat.jobs.delete.implementation = async (...params) => {
            const result = await serverProxy.jobs.delete(...params);
            return result;
        };

        // cvat.jobs.submit.implementation = async (data) => {
        //     const jobsData = await serverProxy.jobs.submit(data);
        //     return jobsData;
        // };

        cvat.jobs.saveRelation.implementation = async (relation, action) => {
            const jobsData = await serverProxy.jobs.saveRelation(relation, action);
            return jobsData;
        };
        cvat.jobs.getRelation.implementation = async (filter) => {
            const jobsData = await serverProxy.jobs.getRelation(filter);
            return jobsData;
        };

        cvat.tasks.get.implementation = async (filter) => {
            // checkFilter(filter, {
            //     page: isInteger,
            //     pageSize: isInteger,
            //     projectId: isInteger,
            //     id: isInteger,
            //     search: isString,
            //     filter: isString,
            //     ordering: isString,
            // });

            // checkExclusiveFields(filter, ['id', 'projectId'], ['page']);

            // const searchParams = {};
            // for (const field of [
            //     'filter',
            //     'search',
            //     'ordering',
            //     'id',
            //     'page',
            //     'projectId',
            // ]) {
            //     if (Object.prototype.hasOwnProperty.call(filter, field)) {
            //         if (field === 'page') {
            //             searchParams[camelToSnake('pageIndex')] = filter[field];
            //         } else {
            //             searchParams[camelToSnake(field)] = filter[field];
            //         }
            //     }
            // }

            const tasksData = await serverProxy.tasks.get(filter);
            const tasks = tasksData.map((task) => new Task(task));

            tasks.count = tasksData.count;

            return tasks;
        };

        cvat.tasks.create.implementation = async (data) => {
            const tasksData = await serverProxy.tasks.create(data);
            return tasksData;
        };

        cvat.teams.queryTeamUserList.implementation = async (data) => {
            const tasksData = await serverProxy.teams.queryTeamUserList(data);
            return tasksData;
        };

        cvat.teams.queryTeamTaskList.implementation = async (data) => {
            const tasksData = await serverProxy.teams.queryTeamTaskList(data);
            return tasksData;
        };

        cvat.teams.applyJob.implementation = async (data) => {
            const tasksData = await serverProxy.teams.applyJob(data);
            return tasksData;
        };

        cvat.teams.recoveryJob.implementation = async (data) => {
            const tasksData = await serverProxy.teams.recoveryJob(data);
            return tasksData;
        };
        cvat.tasks.delete.implementation = async (...params) => {
            const result = await serverProxy.tasks.delete(...params);
            return result;
        };

        cvat.tasks.uploadImagesToCreateTask.implementation = async (...params) => {
            const result = await serverProxy.tasks.uploadImagesToCreateTask(...params);
            return result;
        };

        cvat.tasks.queryAlgoTask.implementation = async (...params) => {
            const result = await serverProxy.tasks.queryAlgoTask(...params);
            return result;
        };

        cvat.projects.get.implementation = async (filter) => {
            checkFilter(filter, {
                id: isInteger,
                page: isInteger,
                pageSize: isInteger,
                search: isString,
                filter: isString,
            });

            checkExclusiveFields(filter, ['id'], ['page']);

            const searchParams = {};
            for (const field of ['filter', 'search', 'status', 'id', 'page']) {
                if (Object.prototype.hasOwnProperty.call(filter, field)) {
                    if (field === 'page') {
                        searchParams[camelToSnake('pageIndex')] = filter[field] - 1;
                    } else {
                        searchParams[camelToSnake(field)] = filter[field];
                    }
                }
            }

            const projectsData = await serverProxy.projects.get(searchParams);
            const projects = projectsData.map((project) => {
                project.task_ids = project.tasks;
                return project;
            }).map((project) => new Project(project));

            projects.count = projectsData.count;

            return projects;
        };

        cvat.projects.searchNames
            .implementation = async (search, limit) => serverProxy.projects.searchNames(search, limit);

        cvat.cloudStorages.get.implementation = async (filter) => {
            checkFilter(filter, {
                page: isInteger,
                filter: isString,
                id: isInteger,
                search: isString,
            });

            checkExclusiveFields(filter, ['id', 'search'], ['page']);

            const searchParams = new URLSearchParams();
            for (const field of [
                'filter',
                'search',
                'id',
                'page',
            ]) {
                if (Object.prototype.hasOwnProperty.call(filter, field)) {
                    searchParams.set(camelToSnake(field), filter[field]);
                }
            }

            const cloudStoragesData = await serverProxy.cloudStorages.get(searchParams.toString());
            const cloudStorages = cloudStoragesData.map((cloudStorage) => new CloudStorage(cloudStorage));
            cloudStorages.count = cloudStoragesData.count;
            return cloudStorages;
        };

        cvat.organizations.get.implementation = async () => {
            const organizationsData = await serverProxy.organizations.get();
            const organizations = organizationsData.map((organizationData) => new Organization(organizationData));
            return organizations;
        };

        cvat.organizations.activate.implementation = (organization) => {
            checkObjectType('organization', organization, null, Organization);
            config.organizationID = organization.slug;
        };

        cvat.organizations.deactivate.implementation = async () => {
            config.organizationID = null;
        };

        return cvat;
    }

    module.exports = implementAPI;
})();
