/*
 * @Author: swxy
 * @Date: 2022-12-24 11:19:09
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT
import * as THREE from 'three';
import constants from '../consts';
import Object3DManager, { setLineColor } from './object3DManager';

export interface Indexable {
    [key: string]: any;
}

export class SphereModel extends Object3DManager {
    // public perspective: THREE.Mesh;
    // public top: THREE.Mesh;
    // public side: THREE.Mesh;
    // public front: THREE.Mesh;
    public disabled: boolean;
    // public lineGeo: THREE.EdgesGeometry;

    public constructor(name: string, color?: string) {
        super(name, color);
        const geometry = new THREE.SphereGeometry(1, 16, 8);

        const material = new THREE.MeshBasicMaterial({
            color: color || '#00ffff',
            wireframe: false,
            transparent: true,
            // opacity: 0.4,
        });
        this.perspective = new THREE.Mesh(geometry, material);
        // const geo = new THREE.EdgesGeometry(this.perspective.geometry);
        // // this.setLineColor(geo, outlineColor);
        // const wireframe = new THREE.LineSegments(
        //     geo,
        //     new THREE.LineBasicMaterial({
        //         linewidth: 4, // color: outlineColor,
        //         // vertexColors: true,
        //         color: '#fff',
        //     }),
        // );
        // wireframe.computeLineDistances();
        // wireframe.renderOrder = 1;
        // this.perspective.add(wireframe);

        this.top = new THREE.Mesh(geometry, material);
        this.side = new THREE.Mesh(geometry, material);
        this.front = new THREE.Mesh(geometry, material);

        this.top.name = name;
        this.side.name = name;
        this.front.name = name;
        this.perspective.name = name;

        // const camRotateHelper = new THREE.Object3D();
        // camRotateHelper.translateX(-2);
        // camRotateHelper.name = 'camRefRot';
        // camRotateHelper.up = new THREE.Vector3(0, 0, 1);
        // camRotateHelper.lookAt(new THREE.Vector3(0, 0, 0));
        // this.front.add(camRotateHelper.clone());
        // this.initText();
    }
}

export function setEdges(instance: THREE.Mesh): THREE.LineSegments {
    const edges = new THREE.EdgesGeometry(instance.geometry);
    setLineColor(edges, '#ffffff');
    const line = new THREE.LineSegments(
        edges,
        new THREE.LineBasicMaterial({
            // color: '#ffffff',
            linewidth: 3,
            vertexColors: true,
        }),
    );
    line.name = constants.CUBOID_EDGE_NAME;
    instance.add(line);
    return line;
}

export default SphereModel;
