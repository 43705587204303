export enum SubElementType {
    wheelPoint = 'wheelPoint', // 车轮点
    dashedLine = 'dashedLine', // 车道线虚线点
}

export interface SubElement {
    shapeType: SubElementType;
    numOfPoints: number;
    points: SubElementInfo[];
}

export interface SubElementInfo {
    x: number;
    y: number;
    z?: number;
    occluded?: boolean;
    order?: number;
    pointType?: 'start' | 'end'; // 不再使用
}
