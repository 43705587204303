/*
 * @Author: swxy
 * @Date: 2022-07-08 15:41:32
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import './styles.scss';
import React, {
    useEffect,
    useRef,
    useState,
    // TransitionEvent
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Form, List, Card, Spin, FormInstance } from 'antd';
import Layout from 'antd/lib/layout';

import { LogType } from 'cvat-logger';
import {
    CombinedState,
    // ImageLabel,
    ImageLabelAttribute,
    InputType,
    Label,
    // LabelAttribute,
    // ObjectState,
    ObjectType,
    ShapeType,
} from 'reducers/interfaces';
import { createAnnotationsAsync, removeObjectAsync, updateAnnotationsAsync } from 'actions/annotation-actions';
// import { adjustContextImagePosition } from
// 'components/annotation-page/standard-workspace/context-image/context-image';
// import { collapseSidebar as collapseSidebarAction } from 'actions/annotation-actions';
// import getCore from 'cvat-core-wrapper';
import LabelControlTypeComponent from './labelControlTypeComponent';
import ObjectState from 'business/objects/objectState';

// const cvat = getCore();

function ControlsFooterBar(): JSX.Element {
    // const {
    //     sidebarCollapsed,
    //     // canvasInstance, collapseSidebar,
    //     // jobInstance,
    //     labels,

    //     states,
    // } = props;
    const dispatch = useDispatch();
    const fromRef = useRef<FormInstance>(null);

    const {
        labels,
        states,
        playing,
        frame,
        jobInstance,
    }: { labels: Label[]; states: ObjectState[]; playing: boolean; frame: number; jobInstance: any } = useSelector(
        (state: CombinedState) => ({
            jobInstance: state.annotation.job.instance,
            labels: state.annotation.job.labels,
            states: state.annotation.annotations.states,
            playing: state.annotation.player.playing,
            frame: state.annotation.player.frame.number,
        }),
    );

    const anns = states.filter((item: any) => item.frame === frame);

    // const [imageLabel, setImageLabel] = useState<Label>(labels?.[0]);
    const [atts, setAtts] = useState<ImageLabelAttribute[]>(
        labels?.[0]?.attributes?.map((att) => ({
            id: att.id,
            name: att.name,
            inputType: att.inputType,
            values: att.values,
            defaultValue: att.defaultValue,
            mutable: att.mutable,
            labelSpecType: att.labelSpecType,
            value: att.defaultValue,
        })) || [],
    );

    // 根据ObjectState对象，将Label更改为ImageLabel。 主要是标签属性的赋值。
    const getImageLabelValue = (): void => {
        // console.log('更新后的states:', states);
        if (anns.length) {
            const objectState = anns[0];
            const { attributes } = objectState;
            // const { attributes: imageAttributes } = imageLabel;

            const newAttributes = labels?.[0]?.attributes?.map((item: ImageLabelAttribute): ImageLabelAttribute => {
                // const newItem = item?.toObject ? item?.toObject() : item;
                let value = attributes[item.id];
                if (item.inputType === InputType.checkbox) {
                    value = attributes[item.id] === 'true';
                }
                return {
                    // ...item,
                    id: item.id,
                    name: item.name,
                    inputType: item.inputType,
                    values: item.values,
                    defaultValue: item.defaultValue,
                    mutable: item.mutable,
                    labelSpecType: item.labelSpecType,
                    value,
                } as ImageLabelAttribute;
            });
            setAtts(newAttributes);

            // setImageLabel({
            //     ...imageLabel,
            //     clientID: objectState.clientID,
            //     serverID: objectState.serverID,

            //     attributes: [...newAttributes],
            // });

            fromRef.current?.resetFields();
        }
    };

    useEffect(() => {
        getImageLabelValue();
        if (anns.length >= 2) {
            const [, ...deletes] = anns;
            dispatch(removeObjectAsync(jobInstance, deletes, true));
        }
    }, [states, labels]);

    useEffect(() => {
        if (!playing && !anns.length && labels?.[0]) {
            // 不是正在播放时，如果当前页面标注框为空，则创建一个标注框。
            const newState = {
                frame,
                label: labels?.[0],
                objectType: ObjectType.SHAPE,
                shapeType: ShapeType.IMAGE,
                points: [],
                occluded: false,
                outside: false,
                zOrder: 0,
                attributes: labels?.[0].attributes.reduce((previous: Record<number, string | boolean>, current) => {
                    previous[current.id] = current.defaultValue;
                    previous[current.id] = current.defaultValue;
                    return previous;
                }, {}),
            };

            const objectState = new ObjectState(newState);

            // console.log('objectState:', objectState);
            dispatch(createAnnotationsAsync(jobInstance, frame, [objectState]));
        }
    }, [jobInstance, frame, states, playing, labels]);

    // console.log('states:', states);

    const attributeChange = (changedValues: any): void => {
        // fromRef.current?.setFieldsValue(changedValues);
        // console.log('现有：', imageLabel.attributes);

        const objectState = anns[0];
        const attr: Record<number, string> = {};
        Object.entries(changedValues).forEach(([id, value]) => {
            jobInstance.logger.log(LogType.changeAttribute, {
                id,
                value,
                object_id: objectState.clientID,
            });
            attr[Number(id)] = `${value}`;
            const attribute = atts.find((att) => att.id === Number(id));
            if (attribute) {
                attribute.value = `${value}`;
                // imageLabel.attributes = [...imageLabel.attributes];
                setAtts([...atts]);
            }
            fromRef.current?.resetFields();
        });
        // console.log('更改属性：', changedValues);
        objectState.attributes = attr;
        dispatch(updateAnnotationsAsync([objectState]));
    };

    // const collapse = (): void => {
    //     const [collapser] = window.document.getElementsByClassName('cvat-objects-sidebar');
    //     const listener = (event: TransitionEvent): void => {
    //         if (event.target && event.propertyName === 'width' && event.target === collapser) {
    //             canvasInstance.fitCanvas();
    //             canvasInstance.fit();
    //             (collapser as HTMLElement).removeEventListener('transitionend', listener as any);
    //         }
    //     };

    //     if (collapser) {
    //         (collapser as HTMLElement).addEventListener('transitionend', listener as any);
    //     }

    //     adjustContextImagePosition(!sidebarCollapsed);
    //     collapseSidebar();
    // };

    // const is2D = jobInstance ? jobInstance.dimension === DimensionType.DIM_2D : true;

    if (playing && !anns.length) {
        return <></>;
    }

    if (!playing && !anns.length) {
        return <Spin spinning tip='创建中，请稍后...' />;
    }

    // const initValues = useMemo(
    //     () =>
    //         imageLabel.attributes.reduce((previous: Record<string, string | undefined>, current) => {
    //             previous[`${current.id}`] = current.value;
    //             return previous;
    //         }, {}),
    //     [imageLabel, imageLabel.attributes],
    // );
    return (
        <Layout.Footer className='aatp-controls-side-bar'>
            <Form
                ref={fromRef}
                onValuesChange={attributeChange}
                wrapperCol={{ span: 24 }}
                labelCol={{ span: 24 }}
                initialValues={atts.reduce((previous: Record<string, string | undefined>, current) => {
                    previous[`${current.id}`] = current.value;
                    return previous;
                }, {})}
            >
                <List
                    rowKey='id'
                    grid={{ column: 6 }}
                    dataSource={atts.filter((att) => att.inputType !== InputType.serialid)}
                    renderItem={(attribute) => (
                        <List.Item>
                            <Card title={attribute.name}>
                                <LabelControlTypeComponent
                                    // labelID={label.id}
                                    attribute={attribute}
                                />
                            </Card>
                        </List.Item>
                    )}
                />
            </Form>
            {/* 这是列表 */}
            {/* <span
                className={`cvat-objects-sidebar-sider
                    ant-layout-sider-zero-width-trigger
                    ant-layout-sider-zero-width-trigger-left`}
                onClick={collapse}
            >
                {sidebarCollapsed ? <MenuFoldOutlined title='Show' /> : <MenuUnfoldOutlined title='Hide' />}
            </span> */}
            {/* <Tabs type='card' tabPosition='bottom' defaultActiveKey='objects' className='cvat-objects-sidebar-tabs'>
                <Tabs.TabPane tab={<Text strong>对象</Text>} key='objects'>
                    {objectsList}
                </Tabs.TabPane>
                <Tabs.TabPane forceRender tab={<Text strong>标签</Text>} key='labels'>
                    <LabelsList />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<Text strong>审批</Text>} key='issues'>
                    <IssuesListComponent />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<Text strong>外观</Text>} key='Appearance'>
                    <AppearanceBlock />
                </Tabs.TabPane>
            </Tabs> */}
            {/* {!sidebarCollapsed && <AppearanceBlock />} */}
        </Layout.Footer>
    );
}

export default React.memo(ControlsFooterBar);
