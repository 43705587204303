// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useRef, useState } from 'react';
import Layout from 'antd/lib/layout';
import { ActiveControl, ObjectType, ShapeType, CombinedState } from 'reducers/interfaces';
// import { Canvas3d as Canvas, Canvas3d } from 'canvas3d-wrapper';
import { Canvas3d as Canvas, Canvas3d } from 'canvas3d-wrapper';
// import MoveControl, {
//     Props as MoveControlProps,
// } from 'components/annotation-page/standard-workspace/controls-side-bar/move-control';
import BirdEyeControl, {
    Props as BirdEyeControlProps,
} from 'components/annotation-page/standard3D-workspace/controls-side-bar/birdEye-control';
import CursorControl, {
    Props as CursorControlProps,
} from 'components/annotation-page/standard-workspace/controls-side-bar/cursor-control';
import DrawCuboidControl, {
    Props as DrawCuboidControlProps,
} from 'components/annotation-page/standard-workspace/controls-side-bar/draw-cuboid-control';
import LanelineControl, {
    Props as LanelineControlProps,
} from 'components/annotation-page/standard3D-workspace/controls-side-bar/lanelineControl';
// import GroupControl, {
//     Props as GroupControlProps,
// } from 'components/annotation-page/standard-workspace/controls-side-bar/group-control';

// import SetupTagControl from 'components/annotation-page/standard-workspace/controls-side-bar/setup-tag-control';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import ControlVisibilityObserver from 'components/annotation-page/standard-workspace/controls-side-bar/control-visibility-observer';
import LowControl from 'components/annotation-page/standard3D-workspace/controls-side-bar/low-control';
import { Tooltip, Checkbox, Divider } from 'antd';
import { activateObject, changeMoveObjectActiveControl, rememberObject } from 'actions/annotation-actions';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ObjectMoveControl from 'components/annotation-page/standard3D-workspace/controls-side-bar/object-move-control';

const enum BottomCheckType {
    ContinueLabel = 'continueLabel',
    ConsecutiveFrames = 'ConsecutiveFrames',
    AutoFit = 'autoFit',
}

interface Props {
    keyMap: KeyMap;
    canvasInstance: Canvas | Canvas3d;
    activeControl: ActiveControl;
    normalizedKeyMap: Record<string, string>;
    labels: any[];
    activeObjectType: ObjectType;
    readonly?: boolean;
    repeatDrawShape(): void;
    redrawShape(): void;
    pasteShape(): void;
    groupObjects(enabled: boolean): void;
    resetGroup(): void;
}

const ObservedCursorControl = ControlVisibilityObserver<CursorControlProps>(CursorControl);
const ObservedBirdEyeControl = ControlVisibilityObserver<BirdEyeControlProps>(BirdEyeControl);
const ObservedDrawCuboidControl = ControlVisibilityObserver<DrawCuboidControlProps>(DrawCuboidControl);
const ObservedLanelineControl = ControlVisibilityObserver<LanelineControlProps>(LanelineControl);
// const ObservedGroupControl = ControlVisibilityObserver<GroupControlProps>(GroupControl);
// const ObservedSetupTagControl = ControlVisibilityObserver(SetupTagControl);

export default function ControlsSideBarComponent(props: Props): JSX.Element {
    const {
        canvasInstance,
        pasteShape,
        activeControl,
        normalizedKeyMap,
        keyMap,
        labels,
        redrawShape,
        repeatDrawShape,
        groupObjects,
        resetGroup,
        activeObjectType,
        readonly,
    } = props;

    const [drawTrack, setDrawTrack] = useState(activeObjectType === ObjectType.TRACK);
    const { t } = useTranslation();

    const { activeLabelID, activeNumOfPoints, activeRectDrawingMethod, activeCuboidDrawingMethod } = useSelector(
        (state: CombinedState) => state.annotation.drawing,
    );
    const perspective = useSelector(
        (state: CombinedState) => state.subAnnotation.perspective,
    );

    const changeCameraViewEvent = useRef<any>();
    const addShapeEvent = useRef<any>();

    const dispatch = useDispatch();

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    let subKeyMap: any = {
        CANCEL: keyMap.CANCEL,
    };

    let handlers: any = {
        CANCEL: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (activeControl !== ActiveControl.CURSOR) {
                canvasInstance.cancel();
            }
        },
    };

    if (labels.length && !readonly) {
        handlers = {
            ...handlers,
            PASTE_SHAPE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                canvasInstance.cancel();
                pasteShape();
            },
            SWITCH_DRAW_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const drawing = [ActiveControl.DRAW_CUBOID].includes(activeControl);

                if (!drawing) {
                    canvasInstance.cancel();
                    if (event && event.shiftKey) {
                        redrawShape();
                    } else {
                        repeatDrawShape();
                    }
                } else {
                    canvasInstance.draw({ enabled: false });
                }
            },
            SWITCH_GROUP_MODE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const grouping = activeControl === ActiveControl.GROUP;
                if (!grouping) {
                    canvasInstance.cancel();
                }
                canvasInstance.group({ enabled: !grouping });
                groupObjects(!grouping);
            },
            RESET_GROUP: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const grouping = activeControl === ActiveControl.GROUP;
                if (!grouping) {
                    return;
                }
                resetGroup();
                canvasInstance.group({ enabled: false });
                groupObjects(false);
            },
            ENTER_MOVE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                changeCameraViewEvent.current?.();
            },
            ADD_THREE_SHAPE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                addShapeEvent.current?.();
            },
            rotate_object_90_clockwise: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                // 由3D内部做，这里只是为了展示在快捷键列表里。objectUpdateRotate方法
            },
            rotate_object_90_anti_clockwise: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                // 由3D内部做，这里只是为了展示在快捷键列表里。objectUpdateRotate方法
            },
            move_model: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                // 由3D内部做，这里只是为了展示在快捷键列表里。objectUpdateRotate方法
                dispatch(activateObject(null, null));
                canvasInstance.moveObject({ enabled: activeControl !== ActiveControl.move_object });
            },
        };
        subKeyMap = {
            ...subKeyMap,
            PASTE_SHAPE: keyMap.PASTE_SHAPE,
            SWITCH_DRAW_MODE: keyMap.SWITCH_DRAW_MODE,
            SWITCH_GROUP_MODE: keyMap.SWITCH_GROUP_MODE,
            RESET_GROUP: keyMap.RESET_GROUP,
            ENTER_MOVE: keyMap.ENTER_MOVE,
            ADD_THREE_SHAPE: keyMap.ADD_THREE_SHAPE,
            rotate_object_90_clockwise: keyMap.rotate_object_90_clockwise,
            rotate_object_90_anti_clockwise: keyMap.rotate_object_90_anti_clockwise,
            move_model: keyMap.move_model,
        };
    }

    const onDraw = (shapeType: ShapeType): void => {
        canvasInstance.cancel();
        canvasInstance.draw({
            enabled: true,
            shapeType,
        });

        dispatch(
            rememberObject({
                activeObjectType: activeObjectType,
                activeShapeType: shapeType,
                activeLabelID,
                activeNumOfPoints,
                activeRectDrawingMethod,
                activeCuboidDrawingMethod,
            }),
        );
    };

    const switchChange = (e: CheckboxChangeEvent, checkType: BottomCheckType): void => {
        // canvasInstance.cancel();
        if (checkType === BottomCheckType.ConsecutiveFrames) {
            // setObjectType(e.target.checked ? ObjectType.TRACK : ObjectType.SHAPE);
            if (activeControl === ActiveControl.DRAW_CUBOID) {
                onDraw(ShapeType.CUBOID);
            } else if (activeControl === ActiveControl.laneline) {
                onDraw(ShapeType.laneline);
            } else if (activeControl === ActiveControl.birdEyeMode) {
                dispatch(
                    rememberObject({
                        activeObjectType: e.target.checked ? ObjectType.TRACK : ObjectType.SHAPE,
                        activeShapeType: ShapeType.CUBOID,
                        activeLabelID,
                        activeNumOfPoints,
                        activeRectDrawingMethod,
                        activeCuboidDrawingMethod,
                    }),
                );
            } else {
                dispatch(
                    rememberObject({
                        activeObjectType: e.target.checked ? ObjectType.TRACK : ObjectType.SHAPE,
                    }),
                );
            }
        } else if (checkType === BottomCheckType.AutoFit) {
            canvasInstance.configure({ birdEyeAutoFit: !!e.target.checked });
        }
    };

    // 帧标注
    // const onTagSetup = () => {
    //     console.log('增加一个tag类型标签：');
    // }

    const disabled = !labels.length || readonly;

    return (
        <Layout.Sider
            // className='cvat-canvas-controls-sidebar'
            className={`${activeObjectType === ObjectType.TRACK ? 'aatp_canvas_controls_sidebar_track ' : ''
                }cvat-canvas-controls-sidebar`}
            theme='light'
            width={90}
        >
            {!perspective && <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />}
            <ObservedCursorControl
                cursorShortkey={normalizedKeyMap.CANCEL}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
            />
            <ObservedBirdEyeControl
                cursorShortkey={normalizedKeyMap.ENTER_MOVE}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
                onClick={(callback: () => void) => {
                    changeCameraViewEvent.current = callback;
                }}
            />

            <ObjectMoveControl
                cursorShortkey={normalizedKeyMap.move_model}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
            />

            <ObservedDrawCuboidControl
                cursorShortkey={normalizedKeyMap.ADD_THREE_SHAPE}
                canvasInstance={canvasInstance}
                isDrawing={activeControl === ActiveControl.DRAW_CUBOID}
                disabled={disabled}
                onClickEvent={(callback: (objectType: ObjectType) => void) => {
                    addShapeEvent.current = callback;
                }}
                onClick={() => onDraw(ShapeType.CUBOID)}
            />

            <ObservedLanelineControl
                cursorShortkey={normalizedKeyMap.ADD_THREE_SHAPE}
                canvasInstance={canvasInstance}
                isDrawing={activeControl === ActiveControl.laneline}
                disabled={disabled}
                onClickEvent={(callback: (objectType: ObjectType) => void) => {
                    addShapeEvent.current = callback;
                }}
                onClick={() => onDraw(ShapeType.laneline)}
            />

            <Divider />

            <div className='aatp_controls_side_bar_bottom'>
                <LowControl />
                {/* <Tooltip title='完成一个标注后，停留在当前模式下，继续标注' placement='right'>
                    <div>
                        <Checkbox
                            defaultChecked={false}
                            onChange={(e: CheckboxChangeEvent) => {
                                switchChange(e, BottomCheckType.ContinueLabel);
                            }}
                        >
                            连续标注
                        </Checkbox>
                    </div>
                </Tooltip> */}
                {/* <Tooltip title='在创建<普通标注对象>与<连续帧标注对象>的模式之间切换' placement='right'> */}
                {
                    activeControl !== ActiveControl.laneline && <Tooltip title={t('workspace.side.controls.track_model_tip')} placement='right'>
                        <div>
                            <Checkbox
                                checked={activeObjectType === ObjectType.TRACK}
                                onChange={(e) => {
                                    switchChange(e, BottomCheckType.ConsecutiveFrames);
                                }}
                            >
                                {t('workspace.side.controls.track_model')}
                            </Checkbox>
                        </div>
                    </Tooltip>
                }


                {activeControl === ActiveControl.birdEyeMode && (
                    <Tooltip title={t('workspace.side.controls.autoFit_tip')} placement='right'>
                        <div>
                            <Checkbox
                                defaultChecked={true}
                                onChange={(e) => {
                                    switchChange(e, BottomCheckType.AutoFit);
                                }}
                            >
                                {t('workspace.side.controls.autoFit')}
                            </Checkbox>
                        </div>
                    </Tooltip>
                )}
            </div>
        </Layout.Sider>
    );
}
