/*
 * @Author: swxy
 * @Date: 2023-08-24 19:58:51
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { ObjectType, Source } from 'reducers/interfaces';
import { Annotation } from './annotation';
import { Label } from './label';
import FrameData from 'business/frame';
import AnnotationHistory from './annotationsHistory';
import ObjectState, { SerializedData } from './objectState';
import { ScriptingError } from 'errors/exception';
import { InjectionProps } from './annotationCollection';

export interface ServerTagData {
    clientID?: number;
    id?: number; //	编号	integer(int64)	integer(int64)
    jobId: number; //	题包编号	integer(int32)	integer(int32)
    labelId: number; //	标签编号	integer(int32)	integer(int32)
    attributes: Record<number, string>; // 属性
    frame: number; //帧号	integer(int32)	integer(int32)
    group: number; //组	integer(int32)	integer(int32)
    source?: Source; //源	string
    cameraName?: string; //	相机名称	string
    parentId?: number; // 多余
    direction?: string; // 多余
    updateTime?: string; //	更新时间	string(date-time)	string(date-time)
    addTime?: string; //	输入时间	string(date-time)	string(date-time)
}

type Frame = number;

export interface ToServerTagData {
    id?: number;
    // jobId: number;
    clientID: number;
    idVals: Record<number, string>;
    image: {
        jobId: number;
        clientID: number;
        id?: number;
        frame: number;
        labelId: number;
        group?: number;
        source: Source;
        direction?: string;
        cameraName?: string;
    };
    direction?: string;
}

class Tag extends Annotation {
    public readonly isTag: boolean = true;
    constructor(data: ServerTagData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
    }

    public toJSON(): ToServerTagData {
        return {
            id: this.serverID,
            // jobId: this.jobId,
            clientID: this.clientID,
            direction: this.direction,
            idVals: { ...this.attributes },
            image: {
                jobId: this.jobId,
                clientID: this.clientID,
                id: this.serverID,
                frame: this.frame,
                labelId: this.label.id,
                group: this.group,
                source: this.source,
                direction: this.direction,
                cameraName: this.cameraName,
            },
        };
    }

    /**
     * 获取满足创建ObjectState的值
     * @param frame
     * @returns
     */
    public get(frame: number): SerializedData {
        if (frame !== this.frame) {
            throw new ScriptingError('获取的帧数与当前帧数不符');
        }

        return {
            objectType: ObjectType.TAG,
            clientID: this.clientID,
            serverID: this.serverID,
            lock: this.lock || this.readonly,
            attributes: { ...this.attributes },
            label: this.label,
            group: this.groupObject,
            color: this.color,
            updated: this.updated,
            frame,
            source: this.source,
            parentID: this.parentID,
            cameraName: this.cameraName,
            readonly: this.readonly,
        };
    }

    public async save(frame: number, data: ObjectState): Promise<ObjectState> {
        if (frame !== this.frame) {
            throw new ScriptingError('Got frame is not equal to the frame of the tag');
        }

        if (this.readonly) {
            throw new ScriptingError('该对象目前为只读，不可修改！');
        }

        const updated = data.updateFlags;
        if (this.lock && data.lock) {
            return Annotation.objectStateFactory.call(this, frame, this.get(frame));
        }

        // const updated = data.updateFlags;
        this._validateStateBeforeSave(frame, data, updated as unknown as Record<string, boolean>);

        // Now when all fields are validated, we can apply them
        if (updated.label) {
            this._saveLabel(data.label, frame);
        }

        if (updated.attributes) {
            this._saveAttributes(data.attributes, frame);
        }

        if (updated.lock) {
            this._saveLock(data.lock, frame);
        }

        if (updated.color) {
            this._saveColor(data.color, frame);
        }

        this.updateTimestamp(updated as unknown as Record<string, boolean>);
        updated.reset();

        return Annotation.objectStateFactory.call(this, frame, this.get(frame));
    }
}

export default Tag;
