// Copyright (C) 2019-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

(() => {
    const { AttributeType } = require('./enums');
    const { ArgumentError } = require('./exceptions');
    // const { labelSpecType } = require('const-field');

    /**
     * Class representing an attribute
     * @memberof module:API.cvat.classes
     * @hideconstructor
     */

    const attributeTransformation = {
        id: 'id',
        default_value: 'defaultValue',
        input_type: 'inputType',
        mutable: 'mutable',
        name: 'name',
        values: 'values',
    };

    // CHECKBOX: 'checkbox',
    // RADIO: 'radio',
    // SELECT: 'select',
    // NUMBER: 'number',
    // TEXT: 'text',
    // SERIALID: 'serialid',
    const labelSpecTypes = {
        normal: 0,
        serialid: 1,
        select: 2,
        radio: 3,
        checkbox: 4,
        number: 5,
        text: 6,
    };

    function getRandomColor() {
        return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    }

    class Attribute {
        constructor(initialData) {
            const data = {
                id: undefined,
                default_value: undefined,
                input_type: undefined,
                mutable: undefined,
                name: undefined,
                titles: undefined,
                values: undefined,
                ranges: undefined,
                labelSpecType: undefined,
                dumpName: undefined,
            };

            // const trans = initialData.input_type ? data : this.toTransformation();

            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    // const value = initialData.input_type ? key : trans[key];
                    if (Object.prototype.hasOwnProperty.call(initialData, key)) {
                        if (Array.isArray(initialData[key])) {
                            data[key] = [...initialData[key]];
                        } else {
                            data[key] = initialData[key];
                            // if (key === 'values') {
                            //     data[key] = JSON.parse(initialData[key]);
                            // }
                        }
                    }
                }
            }

            if (!Object.values(AttributeType).includes(data.input_type)) {
                throw new ArgumentError(`Got invalid attribute type ${data.input_type}`);
            }

            Object.defineProperties(
                this,
                Object.freeze({
                    /**
                     * @name id
                     * @type {integer}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    id: {
                        get: () => data.id,
                    },
                    /**
                     * @name defaultValue
                     * @type {(string|integer|boolean)}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    defaultValue: {
                        get: () => data.default_value,
                    },
                    /**
                     * @name inputType
                     * @type {module:API.cvat.enums.AttributeType}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    inputType: {
                        get: () => data.input_type,
                    },
                    /**
                     * @name dumpName
                     * @type {module:API.cvat.enums.AttributeType}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    dumpName: {
                        get: () => data.dumpName,
                    },
                    /**
                     * @name mutable
                     * @type {boolean}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    mutable: {
                        get: () => data.mutable,
                    },
                    /**
                     * @name name
                     * @type {string}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    name: {
                        get: () => data.name,
                    },
                    /**
                     * @name titles
                     * @type {string}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    titles: {
                        get: () => data.titles || data.name,
                    },
                    /**
                     * @name values
                     * @type {(string[]|integer[]|boolean[])}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    values: {
                        get: () => [...data.values],
                        set: (values) => {
                            if (typeof values === 'string') {
                                data.values = JSON.parse(values);
                                return;
                            }
                            if (Array.isArray(values)) {
                                data.values = JSON.parse(values);
                                return;
                            }

                            throw new ArgumentError(`values必须是json字符串或者数组 但当前是${typeof values}`);
                        },
                    },
                    /**
                     * @name ranges
                     * @type {(string[]|integer[]|boolean[])}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    ranges: {
                        get: () => (data.ranges && data.ranges.length ? [...data.ranges] : [...data.values]),
                        set: (ranges) => {
                            if (typeof ranges === 'string') {
                                data.ranges = JSON.parse(ranges);
                                return;
                            }
                            if (Array.isArray(ranges)) {
                                data.ranges = JSON.parse(ranges);
                                return;
                            }

                            throw new ArgumentError(`ranges必须是json字符串或者数组 但当前是${typeof ranges}`);
                        },
                    },
                    /**
                     * @name labelSpecType
                     * @type {string}
                     * @memberof module:API.cvat.classes.Attribute
                     * @readonly
                     * @instance
                     */
                    labelSpecType: {
                        get: () => data.labelSpecType,
                    },
                }),
            );
        }

        toTransformation() {
            return attributeTransformation;
        }

        getDefaultValue() {
            if (this.values && this.values.length) {
                return this.values[0];
            }
            return '0';
        }

        toJSON() {
            const object = {
                name: this.name,
                titles: this.titles,
                mutable: this.mutable,
                input_type: this.inputType,
                default_value: this.defaultValue,
                inputType: this.inputType,
                defaultValue: this.getDefaultValue(),
                values: this.values || [],
                ranges: this.ranges || [],
                labelSpecType: labelSpecTypes[this.inputType] || 0,
                labelObjectType: this.labelObjectType,
                dumpName: this.dumpName,
            };

            if (typeof this.id !== 'undefined') {
                object.id = this.id;
            }

            return object;
        }

        toJSON2() {
            const object = {
                name: this.name,
                titles: this.titles,
                mutable: this.mutable,
                inputType: this.inputType,
                defaultValue: this.getDefaultValue(),
                values: JSON.stringify(this.values || []),
                ranges: JSON.stringify(this.ranges || []),
                labelSpecType: labelSpecTypes[this.inputType] || 0,
                _insert: true,
            };

            if (typeof this.id !== 'undefined') {
                object.id = this.id;
                // eslint-disable-next-line no-underscore-dangle
                object._insert = false;
            }

            return object;
        }

        toObject() {
            const object = {
                name: this.name,
                titles: this.titles,
                mutable: this.mutable,
                inputType: this.inputType,
                defaultValue: this.defaultValue,
                values: this.values,
                ranges: this.ranges,
                labelSpecType: this.labelSpecTypes,
            };

            if (typeof this.id !== 'undefined') {
                object.id = this.id;
            }

            return object;
        }
    }

    /**
     * Class representing a label
     * @memberof module:API.cvat.classes
     * @hideconstructor
     */
    class Label {
        constructor(initialData) {
            const data = {
                id: undefined,
                ownId: undefined,
                labelId: undefined,
                name: undefined,
                title: undefined,
                color: undefined,
                deleted: false,
                projectId: undefined,
                taskId: undefined,
                labelType: undefined,
                labelObjectType: undefined,
                labelProjectType: undefined,

                mainLabelId: undefined,
                dumpName: undefined,
                // parentLabelId: undefined,
                // subLabelId: undefined,
            };

            for (const key in data) {
                if (Object.prototype.hasOwnProperty.call(data, key)) {
                    if (Object.prototype.hasOwnProperty.call(initialData, key)) {
                        data[key] = initialData[key];
                    }
                }
            }

            data.attributes = [];

            if (
                Object.prototype.hasOwnProperty.call(initialData, 'attributes') &&
                Array.isArray(initialData.attributes)
            ) {
                initialData.attributes.sort((a, b) => a.id - b.id);
                for (const attrData of initialData.attributes) {
                    data.attributes.push(new Attribute(attrData));
                }
            }

            Object.defineProperties(
                this,
                Object.freeze({
                    /**
                     * @name id
                     * @type {integer}
                     * @memberof module:API.cvat.classes.Label
                     * @readonly
                     * @instance
                     */
                    id: {
                        get: () => data.id,
                    },
                    /**
                     * @name ownId
                     * @type {integer}
                     * @memberof module:API.cvat.classes.Label
                     * @readonly
                     * @instance
                     */
                    ownId: {
                        get: () => data.ownId,
                    },
                    /**
                     * @name labelId
                     * @type {integer}
                     * @memberof module:API.cvat.classes.Label
                     * @readonly
                     * @instance
                     */
                    labelId: {
                        get: () => data.labelId,
                    },
                    /**
                     * @name labelType
                     * @type {integer}
                     */
                    labelType: {
                        get: () => data.labelType,
                    },
                    /**
                     * @name projectId
                     * @type {integer}
                     * @memberof module:API.cvat.classes.Label
                     * @readonly
                     * @instance
                     */
                    projectId: {
                        get: () => data.projectId,
                    },
                    /**
                     * @name taskId
                     * @type {integer}
                     * @memberof module:API.cvat.classes.Label
                     * @readonly
                     * @instance
                     */
                    taskId: {
                        get: () => data.taskId,
                    },
                    /**
                     * @name name
                     * @type {string}
                     * @memberof module:API.cvat.classes.Label
                     * @instance
                     */
                    name: {
                        get: () => data.name,
                        set: (name) => {
                            if (typeof name !== 'string') {
                                throw new ArgumentError(`Name must be a string, but ${typeof name} was given`);
                            }
                            data.name = name;
                        },
                    },
                    /**
                     * @name dumpName
                     * @type {string}
                     * @memberof module:API.cvat.classes.Label
                     * @instance
                     */
                    dumpName: {
                        get: () => data.dumpName,
                        // set: (dumpName) => {
                        //     if (typeof dumpName !== 'string') {
                        //         throw new ArgumentError(`Name must be a string, but ${typeof name} was given`);
                        //     }
                        //     data.dumpName = dumpName;
                        // },
                    },
                    /**
                     * @name title
                     * @type {string}
                     * @memberof module:API.cvat.classes.Label
                     * @instance
                     */
                    title: {
                        get: () => data.title || data.name,
                        set: (title) => {
                            if (typeof title !== 'string') {
                                throw new ArgumentError(`Name must be a string, but ${typeof title} was given`);
                            }
                            data.title = title;
                        },
                    },
                    /**
                     * @name color
                     * @type {string}
                     * @memberof module:API.cvat.classes.Label
                     * @instance
                     */
                    color: {
                        get: () => data.color,
                        set: (color) => {
                            if (typeof color === 'string' && color.match(/^#[0-9a-f]{6}$|^$/)) {
                                data.color = color;
                            } else {
                                throw new ArgumentError('Trying to set wrong color format');
                            }
                        },
                    },
                    /**
                     * @name attributes
                     * @type {module:API.cvat.classes.Attribute[]}
                     * @memberof module:API.cvat.classes.Label
                     * @readonly
                     * @instance
                     */
                    attributes: {
                        get: () => [...data.attributes],
                    },
                    labelObjectType: {
                        get: () => data.labelObjectType,
                    },
                    deleted: {
                        get: () => data.deleted,
                        set: (value) => {
                            data.deleted = value;
                        },
                    },
                    isSub: {
                        get: () => data.labelProjectType === 1,
                        // set: (value) => {
                        //     data.deleted = value;
                        // },
                    },
                    parentLabelId: {
                        get: () => data.mainLabelId,
                    },
                    labelProjectType: {
                        get: () => data.labelProjectType,
                    },
                    mainLabelId: {
                        get: () => data.mainLabelId,
                    },
                }),
            );
        }

        toJSON() {
            const object = {
                name: this.name,
                title: this.title || this.name,
                attributes: [...this.attributes.map((el) => el.toJSON())],
                color: this.color,
            };

            if (typeof this.id !== 'undefined') {
                object.id = this.id;
            }

            if (this.deleted) {
                object.deleted = this.deleted;
            }

            return object;
        }

        toJSON2(result = { deletes: [], merges: [] }) {
            const object = {};
            if (typeof this.id !== 'undefined') {
                object.id = this.id;
            }
            if (this.deleted) {
                result.deletes.push(object);
                return result;
                // object.deleted = this.deleted;
            }

            object.name = this.name;
            object.title = this.title || this.name;
            object.engineAttributespecs = [...this.attributes.map((el) => ({ ...el.toJSON2(), labelId: this.id }))];
            if (!object.engineAttributespecs.length) {
                delete object.engineAttributespecs;
            }
            object.color = this.color || getRandomColor();
            object.projectId = this.projectId;
            object.taskId = this.taskId;

            if (typeof object.id !== 'number') {
                // eslint-disable-next-line no-underscore-dangle
                object._insert = true;
            }
            result.merges.push(object);
            return result;

            // const object = {
            //     name: this.name,
            //     engineAttributespecs: [...this.attributes.map((el) => el.toJSON2())],
            //     color: this.color,
            // };

            // if (typeof this.id !== 'undefined') {
            //     object.id = this.id;
            // }

            // if (this.deleted) {
            //     object.deleted = this.deleted;
            // }
            // return object;
        }
    }

    module.exports = {
        Attribute,
        Label,
    };
})();
