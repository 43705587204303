/*
 * @Author: swxy
 * @Date: 2023-11-13 16:19:07
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { Modal, message } from 'antd';
import Resource from './AmygoResource';
// @ts-expect-error
import DownloadWorker from './workers/download.worker';
// @ts-expect-error
import AnalyzeWorker from './workers/analyze.worker';
import { getToken } from './utils/storage';
import FrameMeta from './AmygoFrameMeta';
import { getSetting } from 'utils/storage';
import { getAmygoStore } from 'amygo-store';
import { CombinedState } from 'reducers/interfaces';
import Job from 'business/objects/job';
import { ProjectType } from 'utils/ConstType';

type Frame = number;

const timeDur = 100; // 检查下载成功与否的频率
const preCacheSize = 1; // 题包的缓存范围
const preAnalyzingSize = 1; // 题包的解析范围

// 最大缓存1G
const maxCacheMemory = 1 * 1024 * 1024 * 1024;

class AmygoCacheResource {
    private readonly _frameData: Map<Frame, Resource> = new Map(); // 缓存的资源文件
    private readonly _waitQueue: Set<number>; // 等待处理的帧
    private readonly _workingQueue: Set<number>; // 正在处理的帧

    private readonly _waitAnalyzeQueue: Set<number>; // 等待解析的帧
    private readonly _analyzingQueue: Set<number>; // 正在解析的帧

    private readonly _maxWorkerSize = 3; // 最大并行数  每个任务单独计数

    // private _waitframe: number; // 等待获取的帧
    private _frameMeta: Map<Frame, FrameMeta> = new Map(); // 缓存的frameinfo
    private _frame: number; // 当前帧
    private _workerSize = 0; // 当前并行数

    private _startFrame: number;
    private _stopFrame: number;

    private _isError = false;

    private memory = 0;

    constructor() {
        this._waitQueue = new Set();
        this._workingQueue = new Set();

        this._waitAnalyzeQueue = new Set();
        this._analyzingQueue = new Set();

        this._frame = 0;
        this._startFrame = 0;
        this._stopFrame = 0;

        setInterval(() => {
            this.check();
        }, timeDur);
    }

    private check() {
        // console.log('当前缓存大小：', this.memory);
        if (this._waitQueue.size && this._workerSize < this._maxWorkerSize) {
            this.download();
        }
        if (this._waitAnalyzeQueue.size && this._workerSize < this._maxWorkerSize) {
            this.analyze();
        }
    }

    private isNoCache = (frame: number) => {
        return !this._frameData.has(frame) && !this._workingQueue.has(frame) && !this._waitQueue.has(frame);
    };

    private clearMemory = () => {
        let farFrame = this._frame;
        this.memory = 0;

        for (const key of [...this._frameData.keys()]) {
            if (Math.abs(farFrame - this._frame) < Math.abs(key - this._frame)) {
                farFrame = key;
            }
            const resource = this._frameData.get(key);
            if (resource) {
                // 有资源,且已解析，但不在解析范围外，清除。
                if (resource.isAnalyze && Math.abs(key - this._frame) > preAnalyzingSize) {
                    resource.clear();
                }
                this.memory += resource.memory;
            }
        }

        // console.log(`当前缓存资源估算大小：${(this.memory / 1024 / 1024).toFixed(2)}M`);

        if (this.memory > maxCacheMemory && farFrame !== this._frame) {
            const resource = this._frameData.get(farFrame);
            if (resource) {
                resource.destroy();
            }
            this._frameData.delete(farFrame);
            this.clearMemory();
            // console.log('清除缓存：', farFrame);
        }
    };

    private computeCache = () => {
        const start = Math.max(this._startFrame, this._frame - preCacheSize);
        const end = Math.min(this._stopFrame, this._frame + preCacheSize);
        // 清除等待缓存的帧
        this._waitQueue.clear();
        for (let preFrame = start; preFrame <= end; preFrame++) {
            // 如果当前需要缓存的帧不满足：已有缓存文件、在工作队列中、在等待队列中，则加入等待队列
            if (this.isNoCache(preFrame)) {
                this._waitQueue.add(preFrame);
            }
        }

        const analyzeStart = Math.max(this._startFrame, this._frame - preCacheSize);
        const analyzeEnd = Math.min(this._stopFrame, this._frame + preCacheSize);

        // 清除等待解析的帧
        this._waitAnalyzeQueue.clear();
        for (let preFrame = analyzeStart; preFrame <= analyzeEnd; preFrame++) {
            // 如果当前预解析帧，没有正在解析或者已解析完成，则加入等待解析列表
            if (!this._analyzingQueue.has(preFrame) && !this._frameData.get(preFrame)?.isAnalyze) {
                this._waitAnalyzeQueue.add(preFrame);
            }
        }

        this.clearMemory();
    };

    private getAnalyzeFrame = (frame?: number) => {
        // 如果没有缓存，则开始下载当前帧资源
        if (typeof frame === 'number' && !this._frameData.has(frame)) {
            return frame;
        } else if (this._waitAnalyzeQueue.size && this._workerSize < this._maxWorkerSize) {
            // 按离frame最大的排序。
            const _waitList = [...this._waitAnalyzeQueue].sort(
                (a, b) => Math.abs(a - this._frame) - Math.abs(b - this._frame),
            );
            // 从等待队列中选择一个，工作队列中不包含的。
            return _waitList.find((waitFrame) => !this._analyzingQueue.has(waitFrame));
        }
    };

    private getCacheFrame = (frame?: number) => {
        // 如果没有缓存，则开始下载当前帧资源
        if (typeof frame === 'number' && !this._frameData.has(frame)) {
            return frame;
        } else if (this._waitQueue.size && this._workerSize < this._maxWorkerSize) {
            // 按离frame最大的排序。
            const _waitList = [...this._waitQueue].sort(
                (a, b) => Math.abs(a - this._frame) - Math.abs(b - this._frame),
            );
            // 从等待队列中选择一个，工作队列中不包含的。
            return _waitList.find((waitFrame) => !this._workingQueue.has(waitFrame));
        }
    };

    // 下载完成后的处置
    private dispose(frame: number, pcds: any[], images: any[]) {
        this._waitQueue.delete(frame);
        this._workingQueue.delete(frame);
        this._workerSize--;

        // console.log('下载完成：', frame);
        this._frameData.set(frame, new Resource(pcds, images));
    }

    /**
     * 开始下载并解析资源
     * @description 当前frame，会无视worker的数量限制，直接开始任务。
     * @description 其他则会按照等待列表，依离frame由近到远开始获取资源。
     */
    private async download() {
        // 获取需要缓存的帧
        const cacheFrame = this.getCacheFrame();
        // 需要缓存的帧是否正在缓存
        if (typeof cacheFrame === 'number' && !this._workingQueue.has(cacheFrame)) {
            // console.log('开始下载：', cacheFrame);

            const worker: Worker = new DownloadWorker();

            worker.onmessage = (e) => {
                const {
                    success,
                    frame,
                    error,
                    pcds,
                    images,
                }: {
                    success: boolean;
                    frame: number;
                    pcds: {
                        data: Blob;
                        name: string;
                    }[];
                    images: {
                        data: Blob;
                        name: string;
                    }[];
                    error: Error;
                } = e.data;

                if (success) {
                    // this.dispose(frame, pcds, images);
                    // 等待队列删除
                    // 正在工作队列删除
                    // 工作中的线程数量-1
                    this._waitQueue.delete(frame);
                    this._workingQueue.delete(frame);
                    this._workerSize--;

                    // 资源结果
                    this._frameData.set(frame, new Resource(pcds, images));
                } else {
                    if (!this._isError) {
                        this._frame = -1;
                        Modal.error({
                            title: '下载资源资源失败',
                            content: error.message,
                            bodyStyle: { color: 'red' },
                            okText: '刷新页面',
                            onOk: () => {
                                location.reload();
                            },
                        });
                    }
                    this._isError = true;
                }

                worker.terminate();
            };

            worker.onerror = (e) => {
                worker.terminate();
                message.error('解析工作线程出现问题，您可以刷新一次页面解决！');
            };

            worker.onmessageerror = () => {
                worker.terminate();
                message.error('解析工作线程出现问题！');
            };

            const token = getToken();
            const frameMeta = this._frameMeta.get(cacheFrame);
            if (frameMeta) {
                const state: CombinedState = getAmygoStore().getState();
                this._workingQueue.add(cacheFrame);
                this._workerSize++;
                worker.postMessage({
                    frame: cacheFrame,
                    token,
                    frameId: frameMeta.id,
                    pcds:
                        (state.annotation.job.instance as Job).projectType === ProjectType.two
                            ? []
                            : frameMeta.pcdNames,
                    images: frameMeta.imageNames,
                });
            }
        }
    }

    /**
     * 解析返回当前帧
     */
    private async analyze() {
        // 解析之前先检查并清理缓存
        // this.computeCache();

        const analyzeFrame = this.getAnalyzeFrame();
        if (
            typeof analyzeFrame === 'number' &&
            this._frameData.has(analyzeFrame) &&
            !this._analyzingQueue.has(analyzeFrame) &&
            !this._frameData.get(analyzeFrame)?.isAnalyze
        ) {
            // console.log('+++++++++++++++++++++++++++++++++++++++++++++++++解析帧:', analyzeFrame);
            const worker: Worker = new AnalyzeWorker();
            worker.onmessage = (e) => {
                const {
                    success,
                    frame,
                    error,
                    pcds,
                    images,
                }: {
                    success: boolean;
                    frame: number;
                    pcds: {
                        positions: number[][];
                        colors?: number[][];
                    }[];
                    images: {
                        data: ImageBitmap;
                    }[];
                    error: Error;
                } = e.data;

                if (success) {
                    // this.dispose(frame, pcds, images);
                    // 等待队列删除
                    // 正在工作队列删除
                    // 工作中的线程数量-1
                    this._waitAnalyzeQueue.delete(frame);
                    this._analyzingQueue.delete(frame);
                    this._workerSize--;

                    // 资源结果
                    const frameData = this._frameData.get(frame);
                    if (frameData) {
                        frameData.import(pcds, images);
                    }
                } else {
                    if (!this._isError) {
                        this._frame = -1;
                        Modal.error({
                            title: '下载资源资源失败',
                            content: error.message,
                            bodyStyle: { color: 'red' },
                            okText: '刷新页面',
                            onOk: () => {
                                location.reload();
                            },
                        });
                    }
                    this._isError = true;
                }

                worker.terminate();
            };

            worker.onerror = (e) => {
                worker.terminate();
                message.error('解析工作线程出现问题，您可以刷新一次页面解决！');
            };

            worker.onmessageerror = () => {
                worker.terminate();
                message.error('解析工作线程出现问题！');
            };

            const frameData = this._frameData.get(analyzeFrame);
            const setting = getSetting();
            if (frameData) {
                this._analyzingQueue.add(analyzeFrame);
                this._workerSize++;
                worker.postMessage({
                    frame: analyzeFrame,
                    pcds: frameData.pcds.map((pcd) => pcd.original),
                    images: frameData.images.map((image) => image.original),
                    pointCloudColor: setting?.canvas3D.pointCloudColor,
                });
            }
        }
    }

    public update({
        startFrame,
        stopFrame,
        frameMeta,
    }: {
        startFrame: number;
        stopFrame: number;
        frameMeta: Map<Frame, FrameMeta>;
    }) {
        this._startFrame = startFrame;
        this._stopFrame = stopFrame;
        this._frameMeta = frameMeta;
    }

    /**
     * 获取资源数据
     * @description 异步执行，同时只允许获取同一帧的数据。在上一帧还没结束之前，获取下一帧数据，会使上一帧的获取异常结束。
     * @param frame 帧
     * @returns 资源对象
     */
    public async get(frame: number): Promise<Resource> {
        try {
            if (this._isError) {
                throw new Error('获取资源文件发生错误，请刷新页面重试！');
            }

            this._frame = frame; // 最新需要获取的帧

            this.computeCache();

            while (!this._frameData.get(frame)?.isAnalyze) {
                if (this._frame !== frame) {
                    throw new Error(`当前页面已经没有停留在${frame}帧`);
                }
                // 等待timeDur时间，
                await new Promise((resolve) => setTimeout(resolve, timeDur));
            }

            if (this._frame === frame) {
                const data = this._frameData.get(frame)!;
                // this._frameData.delete(frame);
                return data;
            }
        } catch (error) {
            throw error;
        }

        throw new Error(`当前页面已经没有停留在${frame}帧`);
    }

    public destory() {
        Object.entries(this._frameData).forEach(([frame, data]) => {
            if (data) {
                // (data as Resource).pcds.forEach((pcd) => {
                //     pcd.destroy();
                // });
                (data as Resource).images.forEach((image) => {
                    image.destroy();
                });
            }
        });
        this._frameMeta.clear();
        this._frameData.clear();
        this._workingQueue.clear();
        this._waitQueue.clear();

        this._workerSize = 0;
        this._frame = 0;
        this._startFrame = 0;
        this._stopFrame = 0;
    }
}

export default AmygoCacheResource;
