/*
 * @Author: swxy
 * @Date: 2023-03-30 15:14:08
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { Point, BBox } from './shared';

export enum Orientation {
    LEFT = 'left',
    RIGHT = 'right',
}

// 分割框对象
export class SplitRectModel {
    public splitLine: Point; // 分割线位置
    public box: BBox;
    // public points: Point[]; // 分割框位置
    public orientation: Orientation; // 方向

    public constructor(points?: Point[], lineX?: number) {
        // this.points = points;
        if (points && points.length >= 2) {
            this.box = {
                x: points[0].x,
                y: points[0].y,
                width: points[1].x - points[0].x,
                height: points[1].y - points[0].y,
            }
            this.splitLine = typeof lineX === 'number'
                ? { x: lineX, y: points[0].y }
                : { x: points[0].x + (points[1].x - points[0].x) / 3, y: points[0].y };
        }
    }

    public getPoints(): Point[] {
        return [
            {
                x: this.box.x,
                y: this.box.y,
            },
            {
                x: this.box.x + this.box.width,
                y: this.box.y + this.box.height,
            }
        ];
    }

    public getPointsStr(): string {
        const points = this.getPoints();
        return points
            .reduce((acc: string, point: Point): string => `${acc} ${point.x},${point.y}`, '')
            .trim();
    }

    public getPointsLineStr(): string {
        const points = this.getPoints();
        return `${this.splitLine.x}`;
    }

    // public setPoints(points: (Point | null)[]): void {
    //     points.forEach((point: Point | null, i: number): void => {
    //         if (point !== null) {
    //             this.points[i].x = point.x;
    //             this.points[i].y = point.y;
    //         }
    //     });
    // }

    public updateBox(box: BBox): void {
        if (typeof box.x === 'number') {
            this.box.x = box.x
        }
        if (typeof box.y === 'number') {
            this.box.y = box.y
        }
        if (typeof box.width === 'number') {
            this.box.width = box.width
        }
        if (typeof box.height === 'number') {
            this.box.height = box.height
        }
        this.updateLinePosition(box);
    }

    public updateLinePosition(box: BBox, x?: number): void {
        const lineX = typeof x === 'number' ? x : this.splitLine.x
        this.splitLine.x = Math.min(Math.max(lineX, this.box.x), this.box.x + this.box.width);
        if (box && typeof box.y === 'number') {
            this.splitLine.y = box.y;
        }
    }

    public getSplitLine(): Point {
        return this.splitLine;
    }
}
