/*
 * @Author: swxy
 * @Date: 2023-06-07 15:54:56
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import * as THREE from "three";
import BaseObject from "./baseObject";

export const pointName = 'lanelinePointName-'

class PointMesh extends BaseObject {
    public type = 'BaseObject';

    public position: THREE.Vector3;
    public radius: number;
    public color: THREE.Color;

    // public object: THREE.Mesh;

    constructor(
        name: string,
        position: THREE.Vector3 = new THREE.Vector3(0, 0, 0),
        color: THREE.ColorRepresentation = 0xffff00,
        opacity: number = 20,
        radius: number = 0.2,
    ) {
        super()
        this.color = new THREE.Color(color);
        this.position = position;
        this.radius = radius

        const geometry = new THREE.BoxGeometry(radius, radius, radius);
        const material = new THREE.MeshBasicMaterial({
            color,
            transparent: true,
            opacity: opacity / 100,
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(position.x, position.y, position.z);

        this.perspective = mesh;
        this.top = mesh.clone();
        this.side = mesh.clone();
        this.front = mesh.clone();
        this.clientID = 0;

        this.perspective.name = name;
        this.top.name = name;
        this.side.name = name;
        this.front.name = name;
    }

    public updatePosition(pos: THREE.Vector3) {
        this.perspective.position.set(pos.x, pos.y, pos.z);
        this.top.position.set(pos.x, pos.y, pos.z);
        this.side.position.set(pos.x, pos.y, pos.z);
        this.front.position.set(pos.x, pos.y, pos.z);
    }

    public setColor(color: THREE.Color) {
        ((this.perspective as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(color);
    }
}

export default PointMesh;