/*
 * @Author: swxy
 * @Date: 2022-04-11 15:28:37
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/utils/constant.ts
 * Copyright (C) Amygo
 */

// import constT from 'const-field';
import { JobStage, RoleType } from 'reducers/interfaces';
import zh_CN from '../../../public/jsonField_zh_CN.json';
import en_US from '../../../public/jsonField_en_US.json';
import { getI18n } from 'react-i18next';
import { isDev } from './enviroment';

export interface ConstType {
    label: string;
    value: number;
}
const constField = {
    zh_CN,
    en_US,
} as Record<string, Record<string, ConstType[]>>;

const getLocale = (): string => {
    const { language } = getI18n();
    return language;
};
// const constant = constT as Record<string, ConstType[]>;

export const getConstObject = (type: string): ConstType[] => {
    const language = getLocale();
    if (constField[language] && constField[language]?.[type]) {
        return constField[language][type];
    }
    return [];
};

export const recordByConstObject = (type: string): Record<number, string> => {
    const language = getLocale();
    if (constField[language] && constField[language]?.[type]) {
        return constField[language][type].reduce(
            (previous: Record<number, string>, current: { value: number; label: string }) => {
                previous[current.value] = current.label;
                return previous;
            },
            {},
        );
    }
    return {};
};

export const getValueByLabel = (type: string, label: string): number | undefined => {
    try {
        const list = getConstObject(type);
        const obj = list.find((item) => item.label === label);
        return obj?.value;
        // return (constField?.[type] as ConstType[]).find((item) => item.label === label)?.value;
    } catch (error) {}
    return undefined;
};

export const getLabelByValue = (type: string, value: number): string | undefined => {
    try {
        const language = getLocale();
        return (constField[language]?.[type] as ConstType[]).find((item) => item.value === value)?.label;
    } catch (error) {}
    return undefined;
};

/**
 * @delete 待删除，更换为getConstObject。适配语言等
 * @param key json中的key
 * @returns 数组
 */
export const getConst = (key: string): ConstType[] => getConstObject(key);

/**
 * 获取码表相应的value的对象。
 * @param key 字段名
 * @param num value值
 * @returns {value: value, label: label};
 */
export const getConstItem = (key: string, num: number): ConstType | undefined => {
    try {
        const constKey = getConst(key);
        const item = constKey.find((constItem) => constItem.value === num);
        return item;
    } catch (err) {
        console.error('取常数项错误！！');
    }
    return undefined;
};

/**
 * 获取码表相应key下的，相应value，对应的label值
 * @param key 字段名
 * @param num value值
 * @returns label-value对应的名称
 */
export const getValueName = (key: string, num: number): string | undefined => {
    try {
        const constKey = getConst(key);
        const item = constKey.find((constItem) => constItem.value === num) || { label: undefined };
        return item.label;
    } catch (err) {
        console.error('取常数项值错误！！');
    }
    return undefined;
};

// 项目管理员之上才算管理员
export const isManager = (roleType: number): boolean => roleType >= 15;

// 是否可以切换工作空间
export const changeWorkspaceAble = (status: number, roleType: number): boolean => {
    let jobStatusStr = false;
    // 可标注时，且人员是标注员 或者项目管理员及以上
    if ((status === 1 || status === 2 || status === 4) && (roleType === 10 || isManager(roleType))) {
        jobStatusStr = true;
    }
    // 可一审时，且人员是初级审批员 或者项目管理员及以上
    if ((status === 3 || status === 6) && (roleType === 11 || isManager(roleType))) {
        jobStatusStr = true;
    }

    // 可二审时，且人员是高级审批员  或者项目管理员及以上
    if ((status === 5 || status === 8) && (roleType === 12 || isManager(roleType))) {
        jobStatusStr = true;
    }

    // 可验收时，且人员是验收员  或者项目管理员及以上
    if (status === 7 && (roleType === 13 || isManager(roleType))) {
        jobStatusStr = true;
    }
    // if (status === 7 || status === 9) {
    //     jobStatusStr = JobStage.ACCEPTANCE;
    // }
    // 验收 结束 这两种情况暂时不允许修改工作空间。
    return jobStatusStr;
};

// 当前状态，所需展示的页面模式
export const checkAnnotationStatus = (status: number): JobStage => {
    // let jobStatusStr = JobStage.FINISH;
    // 目前只需要2种，有标注权限的默认标注页面。 有审批权限的默认审核页面
    let jobStatusStr = JobStage.REVIEW;
    if (status === 1 || status === 2 || status === 4) {
        jobStatusStr = JobStage.ANNOTATION;
    }
    // if (status === 3 || status === 5 || status === 6 || status === 8) {
    //     jobStatusStr = JobStage.REVIEW;
    // }
    // if (status === 7 || status === 9) {
    //     jobStatusStr = JobStage.ACCEPTANCE;
    // }
    // if (status === 8) {
    //     // jobStatusStr = JobStage.ACCEPTANCE;
    // }
    return jobStatusStr;
};

// 当前状态，所需展示的页面模式
export const disableEditing3D = (status: number, roleType: number): boolean => {
    let disabledEdit = true;
    // 可标注时，且人员是标注员 或者项目管理员及以上
    if ((status === 1 || status === 2 || status === 4) && (roleType === 10 || isManager(roleType))) {
        disabledEdit = false;
    }
    // 可一审时，且人员是初级审批员 或者项目管理员及以上
    if ((status === 3 || status === 6) && (roleType === 11 || isManager(roleType))) {
        disabledEdit = false;
    }
    // 可二审时，且人员是高级审批员  或者项目管理员及以上
    if ((status === 5 || status === 8) && (roleType === 12 || isManager(roleType))) {
        disabledEdit = false;
    }

    // 可验收时，且人员是验收员  或者项目管理员及以上
    if (status === 7 && (roleType === 13 || isManager(roleType))) {
        disabledEdit = false;
    }
    return disabledEdit;
};

// 当前登录人是否不能审核。
export const disabledReview3D = (status: number, roleType: number): boolean => {
    let disabledEdit = true;
    // // 可标注时，且人员是标注员 或者项目管理员及以上
    // if ((status === 1 || status === 2 || status === 4) && (roleType === 10 || isManager(roleType))) {
    //     disabledEdit = false;
    // }
    // 可一审时，且人员是初级审批员 或者项目管理员及以上
    if ((status === 3 || status === 6) && (roleType === 11 || isManager(roleType))) {
        disabledEdit = false;
    }
    // 可二审时，且人员是高级审批员  或者项目管理员及以上
    if ((status === 5 || status === 8) && (roleType === 12 || isManager(roleType))) {
        disabledEdit = false;
    }

    // 可验收时，且人员是验收员  或者项目管理员及以上
    if (status === 7 && (roleType === 13 || isManager(roleType))) {
        disabledEdit = false;
    }

    return disabledEdit;
};

// 是否可以切换工作空间
export const saveDisable = (status: number, roleType: number): boolean => {
    let canSave = false;
    // 可标注时，且人员是标注员 或者管理员
    if ((status === 1 || status === 2 || status === 4) && (roleType === 10 || isManager(roleType))) {
        canSave = true;
    }
    // 可一审时，且人员是初级审批员 或者管理员以上
    if ((status === 3 || status === 6) && (roleType === 11 || isManager(roleType))) {
        canSave = true;
    }

    // 可二审时，且人员是高级审批员  或者管理员以上
    if ((status === 5 || status === 8) && (roleType === 12 || isManager(roleType))) {
        canSave = true;
    }

    // 可验收时，且人员是验收员  或者项目管理员及以上
    if (status === 7 && (roleType === 13 || isManager(roleType))) {
        canSave = true;
    }
    // if (status === 7 || status === 9) {
    //     jobStatusStr = JobStage.ACCEPTANCE;
    // }
    // 验收 结束 这两种情况暂时不允许修改工作空间。
    return canSave;
};

// 是否可以切换工作空间
export const disableLabel = (roleType?: number): boolean => {
    let disable = true;
    if (roleType) {
        // 可标注时，且人员是标注员 或者管理员
        if (isManager(roleType)) {
            disable = false;
        }
    }
    return disable;
};

// 检查批注权限
// 标注员点击“解决”，状态更改为已提交。
// 审核员点击“解决”，状态改为已结束
// 审核员点击“重开”，状态改为待解决
export const issueChangeStaus = (roleType?: number): number => {
    // 0-待解决, 1-已提交 2-已结束
    // 标注员
    if (roleType && roleType === 10) {
        return 1;
    }

    // 审核员及以上，则可以直接结束。
    if (roleType && roleType >= 11) {
        return 2;
    }
    return 0;
};

// 当前人是审核员以上
export const reviewerAndMore = (roleType?: number): boolean => {
    // 0-待解决, 1-已提交 2-已结束
    if (roleType && roleType >= 11) {
        // 可标注时，且人员是审核员 或者管理员
        return true;
    }
    return false;
};

/**
 * 运营管理员或者是系统管理员
 * @param roleType 角色权限
 * @returns 是/否
 */
export const isAdmin = (roleType: RoleType): boolean => roleType >= RoleType.operation_admin;

/** --------------------- 标注员及以上 ----------------------- */
export const annotatorAndMore = (roleType: RoleType, hasMore = true): boolean =>
    hasMore ? roleType >= RoleType.annotator : roleType === RoleType.annotator || isAdmin(roleType);

/** --------------------- 一审人员及以上 ----------------------- */
export const reviewFristAndMore = (roleType: RoleType, hasMore = true): boolean =>
    hasMore ? roleType >= RoleType.first_reviewer : roleType === RoleType.first_reviewer || isAdmin(roleType);

/** --------------------- 二审人员及以上 ----------------------- */
export const reviewSecondAndMore = (roleType: RoleType, hasMore = true): boolean =>
    hasMore ? roleType >= RoleType.second_reviewer : roleType === RoleType.second_reviewer || isAdmin(roleType);

/** --------------------- 验收员及以上 ----------------------- */
export const acceptorAndMore = (roleType: RoleType, hasMore = true): boolean =>
    hasMore ? roleType >= RoleType.acceptor : roleType === RoleType.acceptor || isAdmin(roleType);

export const isNormalUser = (roleType: RoleType): boolean => roleType <= RoleType.normal;
/** --------------------- 是否是团队管理员及以上 ----------------------- */
export const isLeaderAndMore = (roleType: RoleType, hasMore = true): boolean =>
    hasMore ? roleType >= RoleType.team_leader : roleType === RoleType.team_leader || isManager(roleType);

/**
 * 生产上，需要pose信息的项目id
 */
export const proPoseProjectIds = isDev() ? [60] : [100];
