/*
 * @Author: swxy
 * @Date: 2023-04-20 10:35:03
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { FC, useEffect, useMemo, useState } from 'react';
import { Space, Button, Card, Progress, Upload, UploadProps, Checkbox, Row, Col, message, Divider, Spin, Input, Modal } from 'antd';
import type { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { RcFile } from 'antd/lib/upload/interface';
import { LabelType } from 'utils/ConstType';
import { getValueName } from 'utils/constant';

import { InboxOutlined } from '@ant-design/icons';
import { loadImageSize } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImagesToCreateTask } from 'actions/tasks-actions';
import { CombinedState } from 'reducers/interfaces';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;


interface Props {
    onClose?: () => void;
}
const autoAnnObjectList = [{
    key: LabelType.car,
    value: 'outCar',
    label: getValueName('labelType', LabelType.car),
}, {
    key: LabelType.trafficSign,
    value: 'outTrafficSign',
    label: getValueName('labelType', LabelType.trafficSign),
}, {
    key: LabelType.bicycle,
    value: 'outBicycle',
    label: getValueName('labelType', LabelType.bicycle),
}, {
    key: LabelType.pedestrian,
    value: 'outPedestrian',
    label: getValueName('labelType', LabelType.pedestrian),
}, {
    key: LabelType.trafficLight,
    value: 'outTrafficLight',
    label: getValueName('labelType', LabelType.trafficLight),
    // }, {
    //     key: LabelType.curb,
    //     value: 'outCurb',
    //     label: getValueName('labelType', LabelType.curb),
    // }, {
    //     key: LabelType.other,
    //     value: 'outOther',
    //     label: getValueName('labelType', LabelType.other),
    // }, {
    //     key: 'outUnknown',
    //     value: LabelType.unknown,
    //     label: getValueName('labelType', LabelType.unknown),
},]


const autoChecked = {
    outCar: false,
    outTrafficSign: false,
    outBicycle: false,
    outPedestrian: false,
    outTrafficLight: false,
    outCurb: false,
    outOther: false,
    outUnknown: false,
};


const data = {
    width: 0,
    height: 0,
}

let isInfoShow = false;

const UploadImages: FC<Props> = ({ onClose }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [name, setName] = useState<string>('');
    const [fileList, setFileList] = useState<Set<RcFile>>(new Set());

    const options = useMemo(() => autoAnnObjectList, [autoAnnObjectList])

    const { updating, progress } = useSelector((state: CombinedState) => ({
        updating: state.tasks.updating,
        progress: state.tasks.progress,
    }))

    const [loading, setLoading] = useState(false);


    const changeAutoAnnotation = (checkedValue: CheckboxValueType[]) => {
        type TypeKey = keyof typeof autoChecked;
        Object.keys(autoChecked).forEach((key: string) => {
            if (checkedValue.includes(key)) {
                autoChecked[key as TypeKey] = true;
            } else {
                autoChecked[key as TypeKey] = false;
            }
        })
    }

    const changeTaskName = (e: ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value || '')
    }

    const checkImages = async (files: RcFile[]): Promise<boolean> => {
        let isSameWidthHeight = true;
        data.width = 0;
        data.height = 0;
        try {

            if (!files.length) {
                message.error(t('task.seletImageHelp'));
                return false;
            }

            const imageSizePromises = files.map(file => loadImageSize(file));
            const imageSizes = await Promise.all(imageSizePromises);

            imageSizes.forEach(({ width, height, error }) => {
                if (!data.width) {
                    data.width = width;
                    data.height = height;
                } else if (data.width && (data.width !== width || data.height !== height)) {
                    isSameWidthHeight = false;
                }
            })
        } catch (error) {
            isSameWidthHeight = false
        }

        if (!data.width || !isSameWidthHeight) {
            message.error(t('error.task.uploadImage'));
            return false;
        }
        return isSameWidthHeight;
    }

    const overSize = (type: 'sizeOver' | 'countOver' = 'sizeOver') => {

        const title = type === 'sizeOver' ? t('task.sizeOver.title') : t('task.countOver.title')
        const content = type === 'sizeOver' ? t('task.sizeOver.content') : t('task.countOver.content')

        if (!isInfoShow) {
            isInfoShow = true;
            Modal.info({
                title,
                content,
                bodyStyle: { whiteSpace: 'pre-wrap' },
                onOk: () => {
                    isInfoShow = false;
                },
                onCancel: () => {
                    isInfoShow = false;
                },
            });
        }
    }

    const onSubmit = async () => {
        // console.log('参数1-data: ', { ...data, ...autoChecked });
        // console.log('参数2-files: ', fileList);

        setLoading(true);
        const files = [...fileList]
        const isImageSizeOK = await checkImages(files);

        const isSelectAutoType = Object.entries(autoChecked).some((([key, value]) => value))

        if (!isSelectAutoType) {
            message.error(t('task.selectAutoTypeHelp'));
            setLoading(false);
            return;
        }

        if (isImageSizeOK) {
            const result = (await dispatch(uploadImagesToCreateTask({ data: { ...data, ...autoChecked, taskName: name }, files })) as any);
            if (result.isError) {
                overSize('countOver')
            } else {
                onClose?.();
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        setLoading(updating);
    }, [updating])

    // useEffect(() => {
    //     console.log("进度条：", progress);
    // }, [progress])

    const uploadProps: UploadProps = {
        accept: '.png,.jpeg,.jpg,.bmp',
        multiple: true,
        // directory: true,
        onRemove: (file) => {
            //   const index = fileList.indexOf(file);
            //   const newFileList = fileList.slice();
            //   newFileList.splice(index, 1);
            setFileList(oldList => {
                oldList.delete(file as RcFile);
                return new Set([...oldList])
            });
        },
        beforeUpload: (file, list) => {
            if (list.length > 10) {
                overSize();
                return false;
            }
            setFileList((oldList) => {
                if (oldList.size >= 10) {
                    overSize();
                    return oldList;
                }
                return new Set([...oldList, file])
            });
            return false;
        },
        fileList: [...fileList],
    }

    const getTip = (pro: number) => {
        let tip = t('task.checkResolutionTip');
        if (pro >= 5) {
            tip = t('task.uploadImagesTip');
        } else if (pro >= 20) {
            tip = t('task.autoAnnotationTip');
        }

        return tip;
    }

    return <>
        <Spin spinning={loading} size='large' tip={getTip(progress)}>
            <Card>

                {/* <Row gutter={[24, 24]}>
                    <Col span={24}>
                        名称：
                        <Input style={{ maxWidth: '256px' }} value={name} onChange={changeTaskName} />
                    </Col>
                    <Col span={24}>
                    </Col>
                </Row> */}
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        {t('task.autoType')}
                        <Checkbox.Group options={options} onChange={changeAutoAnnotation} />
                    </Col>
                    <Col span={24}>
                    </Col>
                </Row>
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('task.uploadTip')}</p>
                    <p className="ant-upload-hint">
                        {t('task.uploadSupTypeHelp')}
                    </p>
                </Dragger>
                <Divider />
                <Row justify='center' align='middle'>
                    <Space>
                        <Button onClick={onClose}>{t('base.close')}</Button>
                        <Button type='primary' disabled={!fileList.size} onClick={onSubmit}>{t('base.upload')}</Button>
                    </Space>
                </Row>
            </Card>
        </Spin>
        {
            loading && <div className='aatp_upload_progress'>
                <Progress percent={progress} width={300} strokeWidth={20} />
            </div>
        }
    </>
}

export default UploadImages;