import { Points, BufferGeometry, PointsMaterial, Float32BufferAttribute, Color } from 'three';

/**
 * 有内存泄漏问题，待优化
 */

type DataType = 'ascii' | 'binary' | 'binary_compressed';

interface PCDHeader {
    data: DataType;
    headerLen: number;
    str: string;
    type: string[];
    version: number;
    fields: string[];
    size: number[];
    count: number[];
    width: number;
    height: number;
    viewpoint: string;
    points: number;
    offset: Record<string, number>;
    rowSize: number;
}

interface PointDataInfo {
    position: number[];
    normal: number[];
    color: number[];

    [k: string]: number[];
}

interface PointMultDataInfo {
    position: number[][];
    normal: number[][];
    color: number[][];
    intensity: number[][];

    length: number;

    max: { x: number; y: number; z: number };
    min: { x: number; y: number; z: number };

    [k: string]: any;
}

// interface PointMultData extends Omit<PointMultDataInfo, 'min' | 'max'> {
// }

// 10万个点为一个数组
const readLengthOnce = 100000;
const enterLength = '\r\n'.length;
const frames = 200;

const getValue = (dataview: DataView, type: 'U' | 'F', value: any, littleEndian: boolean, field?: string): number => {
    let result = 0;
    try {
        if (type === 'U') {
            result = dataview.getUint32(value, littleEndian);
        }
        if (type === 'F') {
            result = dataview.getFloat32(value, littleEndian);
        }
    } catch (error) {
        console.error(`在${value}处读取点云值${field}数据错误：`, error);
    }
    return result;
};

/**
 * 从流中获取头部信息
 * @param headerBuffer 包含头部信息的部分流
 * @returns 返回一个头部对象
 */
const getHeader = (headerBuffer: ArrayBuffer): PCDHeader => {
    const data = new TextDecoder().decode(headerBuffer);

    const result1 = data.search(/[\r\n]DATA\s(\S*)\s/i);
    const result2 = /[\r\n]DATA\s(\S*)\s/i.exec(data.slice(result1 - 1));

    const bodyDataType = result2?.[1] as DataType;
    // 多加上最后的换行符\r\n 2个字节
    const headerLen = (result2?.[0].length || 0) + result1;
    // 去除注释
    const str = data.slice(0, headerLen).replace(/#.*/gi, '');

    const versionArr = /VERSION (.*)/i.exec(str);
    const fieldsArr = /FIELDS (.*)/i.exec(str);
    const sizeArr = /SIZE (.*)/i.exec(str);
    const typeArr = /TYPE (.*)/i.exec(str);
    const countArr = /COUNT (.*)/i.exec(str);
    const widthArr = /WIDTH (.*)/i.exec(str);
    const heightArr = /HEIGHT (.*)/i.exec(str);
    const viewpointArr = /VIEWPOINT (.*)/i.exec(str);
    const pointsArr = /POINTS (.*)/i.exec(str);

    // evaluate
    let version = 0;
    let type: string[] = [];
    let width = 0;
    let height = 0;
    let points = 0;
    let viewpoint = '';
    let size: number[] = [];
    let count: number[] = [];
    const fields = fieldsArr !== null ? fieldsArr[1].split(' ') : [];
    if (versionArr) {
        version = parseFloat(versionArr[1]);
    }

    if (typeArr) {
        type = typeArr[1].split(' ');
    }

    if (widthArr) {
        width = parseInt(widthArr[1]);
    }

    if (heightArr) {
        height = parseInt(heightArr[1]);
    }

    if (viewpointArr) viewpoint = viewpointArr[1];

    if (pointsArr) points = parseInt(pointsArr[1], 10);

    if (!points) points = width * height;

    if (sizeArr) {
        size = sizeArr[1].split(' ').map(function (x: any) {
            return parseInt(x, 10);
        });
    }

    if (countArr) {
        count = countArr[1].split(' ').map(function (x: any) {
            return parseInt(x, 10);
        });
    } else {
        count = [];

        for (let i = 0, l = fields.length; i < l; i++) {
            count.push(1);
        }
    }

    const offset: Record<string, number> = {};

    let sizeSum = 0;

    for (let i = 0, l = fields.length; i < l; i++) {
        if (bodyDataType === 'ascii') {
            offset[fields[i]] = i;
        } else {
            offset[fields[i]] = sizeSum;
            sizeSum += size[i] * count[i];
        }
    }

    // for binary only

    const rowSize = sizeSum;

    const header: PCDHeader = {
        headerLen,
        str,
        fields,
        version,
        data: bodyDataType,
        type,
        width,
        height,
        points,
        size,
        count,
        offset,
        viewpoint,
        rowSize,
    };
    // parse
    return header;
};

const getDataMultByAscii = (
    dataBuffer: ArrayBuffer,
    header: PCDHeader,
    segmentLength: number,
    singleMaxLength: number,
): PointMultDataInfo => {
    const { points, offset, fields, type } = header;

    let isSegment = points > segmentLength;

    const predictFields = ['x', 'y', 'z', 'normal_x', 'normal_y', 'normal_z', 'rgb', 'intensity', 'min', 'max'];
    const objectData: PointMultDataInfo = Object.keys(offset).reduce(
        (previous: PointMultDataInfo, currentKey: string) => {
            if (!predictFields.includes(currentKey)) {
                previous[currentKey] = [[]];
            }
            return previous;
        },
        {
            position: [],
            color: [],
            normal: [],
            intensity: [],

            min: {
                x: Number.MAX_SAFE_INTEGER,
                y: Number.MAX_SAFE_INTEGER,
                z: Number.MAX_SAFE_INTEGER,
            },
            max: {
                x: Number.MIN_SAFE_INTEGER,
                y: Number.MIN_SAFE_INTEGER,
                z: Number.MIN_SAFE_INTEGER,
            },

            length: points,
        },
    );

    try {
        const textData = new TextDecoder().decode(dataBuffer);
        textData.trim();
        const lines = textData.split('\n');
        const { min, max } = objectData;

        for (let i = 0, l = lines.length; i < l; i++) {
            if (lines[i] === '') continue;

            const line = lines[i].split(' ');

            for (const field in objectData) {
                if (field === 'position' && offset.x !== undefined) {
                    const x = parseFloat(line[offset.x]);
                    const y = parseFloat(line[offset.y]);
                    const z = parseFloat(line[offset.z]);

                    // if (isSegment) {
                    const index = isSegment ? Math.trunc(i / singleMaxLength) : 0;
                    const position = objectData.position[index] || [];
                    position.push(x, y, z);
                    objectData.position[index] = position;
                    // } else {
                    //     objectData.position[0].push(x);
                    //     objectData.position[0].push(y);
                    //     objectData.position[0].push(z);
                    // }

                    min.x = Math.min(min.x, x);
                    min.y = Math.min(min.y, y);
                    min.z = Math.min(min.z, z);

                    max.x = Math.max(max.x, x);
                    max.y = Math.max(max.y, y);
                    max.z = Math.max(max.z, z);
                } else if (field === 'color' && offset.rgb !== undefined) {
                    const rgb_field_index = fields.findIndex((field: any) => field === 'rgb');
                    const rgb_type = type[rgb_field_index];

                    const float = parseFloat(line[offset.rgb]);
                    let rgb = float;

                    if (rgb_type === 'F') {
                        const farr = new Float32Array(1);
                        farr[0] = float;
                        rgb = new Int32Array(farr.buffer)[0];
                    }

                    const r = (rgb >> 16) & 0x0000ff;
                    const g = (rgb >> 8) & 0x0000ff;
                    const b = (rgb >> 0) & 0x0000ff;
                    // color.push(r / 255, g / 255, b / 255);
                    // if (isSegment) {
                    const index = isSegment ? Math.trunc(i / singleMaxLength) : 0;
                    const color = objectData.color[index] || [];
                    color.push(r / 255, g / 255, b / 255);
                    objectData.color[index] = color;
                    // } else {
                    //     objectData.color[0].push(r / 255, g / 255, b / 255);
                    // }
                } else if (field === 'normal' && offset.normal_x !== undefined) {
                    // if (isSegment) {
                    const index = isSegment ? Math.trunc(i / singleMaxLength) : 0;
                    const normal = objectData.normal[index] || [];
                    normal.push(parseFloat(line[offset.normal_x]));
                    normal.push(parseFloat(line[offset.normal_y]));
                    normal.push(parseFloat(line[offset.normal_z]));
                    objectData.normal[index] = normal;
                    // } else {
                    //     objectData.normal[0].push(parseFloat(line[offset.normal_x]));
                    //     objectData.normal[0].push(parseFloat(line[offset.normal_y]));
                    //     objectData.normal[0].push(parseFloat(line[offset.normal_z]));
                    // }
                } else if (offset[field] !== undefined) {
                    const field_index = fields.findIndex((name: any) => name === field);
                    const typeType = type[field_index];

                    // if (isSegment) {
                    const index = isSegment ? Math.trunc(i / singleMaxLength) : 0;
                    const other = objectData[field][index] || [];
                    if (typeType === 'U') {
                        other.push(parseInt(line[offset[field]]));
                    } else if (typeType === 'F') {
                        other.push(parseFloat(line[offset[field]]));
                    }
                    objectData[field][index] = other;
                    // } else {
                    //     if (typeType === 'U') {
                    //         objectData[field][0].push(parseInt(line[offset[field]]));
                    //     } else if (typeType === 'F') {
                    //         objectData[field][0].push(parseFloat(line[offset[field]]));
                    //     }
                    // }
                }
            }
        }
    } catch (error) {
        console.error('读取点云异常：', error);
        throw new Error(`读取点云异常：${(error as Error).message}`);
    }

    return objectData;
};

const getDataMultByBinary = (
    dataBuffer: ArrayBuffer,
    header: PCDHeader,
    littleEndian: boolean,
    segmentLength: number,
    singleMaxLength: number,
): PointMultDataInfo => {
    const { rowSize, points, offset, fields, type: types } = header;
    let isSegment = false;
    if (points > segmentLength) {
        isSegment = true;
    }
    const dataview = new DataView(dataBuffer);

    const predictFields = ['x', 'y', 'z', 'normal_x', 'normal_y', 'normal_z', 'rgb', 'intensity', 'min', 'max'];
    const objectData: PointMultDataInfo = Object.keys(offset).reduce(
        (previous: PointMultDataInfo, currentKey: string) => {
            if (!predictFields.includes(currentKey)) {
                previous[currentKey] = [];
            }
            return previous;
        },
        {
            position: [],
            color: [],
            normal: [],
            intensity: [],

            min: {
                x: Number.MAX_SAFE_INTEGER,
                y: Number.MAX_SAFE_INTEGER,
                z: Number.MAX_SAFE_INTEGER,
            },
            max: {
                x: Number.MIN_SAFE_INTEGER,
                y: Number.MIN_SAFE_INTEGER,
                z: Number.MIN_SAFE_INTEGER,
            },

            length: points,
        },
    );

    try {
        let readCount = 0;
        const c = new Color();

        let isPageOver = false;

        for (let i = 0, row = 0; i < points; i++, row += rowSize) {
            const { min, max } = objectData;

            for (const field in objectData) {
                if (['min', 'max', 'length'].includes(field)) {
                    continue;
                }
                const array = objectData[field][readCount] || [];
                if (field === 'position' && offset.x !== undefined) {
                    const x = dataview.getFloat32(row + offset.x, littleEndian);
                    const y = dataview.getFloat32(row + offset.y, littleEndian);
                    const z = dataview.getFloat32(row + offset.z, littleEndian);
                    min.x = Math.min(min.x, x);
                    min.y = Math.min(min.y, y);
                    min.z = Math.min(min.z, z);

                    max.x = Math.max(max.x, x);
                    max.y = Math.max(max.y, y);
                    max.z = Math.max(max.z, z);

                    array.push(x);
                    array.push(y);
                    array.push(z);
                    if (array.length >= singleMaxLength * 3) {
                        isPageOver = true;
                    }
                } else if (field === 'color' && offset.rgb !== undefined) {
                    // array.push(dataview.getUint8(row + offset.rgb + 2) / 255.0);
                    // array.push(dataview.getUint8(row + offset.rgb + 1) / 255.0);
                    // array.push(dataview.getUint8(row + offset.rgb + 0) / 255.0);

                    const r = dataview.getUint8(row + offset.rgb + 2) / 255.0;
                    const g = dataview.getUint8(row + offset.rgb + 1) / 255.0;
                    const b = dataview.getUint8(row + offset.rgb + 0) / 255.0;

                    c.set(r, g, b).convertSRGBToLinear();

                    array.push(c.r, c.g, c.b);
                    if (array.length >= readLengthOnce * 3) {
                        isPageOver = true;
                    }
                } else if (field === 'normal' && offset.normal_x !== undefined) {
                    array.push(dataview.getFloat32(row + offset.normal_x, littleEndian));
                    array.push(dataview.getFloat32(row + offset.normal_y, littleEndian));
                    array.push(dataview.getFloat32(row + offset.normal_z, littleEndian));
                    if (array.length >= readLengthOnce * 3) {
                        isPageOver = true;
                    }
                } else if (field === 'intensity' && offset.intensity !== undefined) {
                    const fieldIndex = fields.findIndex((name: any) => name === field);
                    const type = types[fieldIndex] as 'U' | 'F';
                    const value = getValue(dataview, type, row + offset[field], littleEndian, field);

                    array.push(value);
                    if (array.length >= readLengthOnce) {
                        isPageOver = true;
                    }
                } else {
                    continue;
                }
                objectData[field][readCount] = array;
            }

            if (isSegment && isPageOver) {
                isPageOver = false;
                readCount++;
            }
        }
    } catch (error) {
        console.error('读取点云异常：', error);
        throw new Error(`读取点云异常：${(error as Error).message}`);
    }

    return objectData;
};

export class PCDLoader {
    multFrame: boolean; // 融合点云
    littleEndian: boolean;

    constructor() {
        this.multFrame = false;
        this.littleEndian = true;
    }

    /**
     * 从流中获取头部信息
     * @param headerBuffer 包含头部信息的部分流
     * @returns 返回一个头部对象
     */
    private readonly getHeader = (headerBuffer: ArrayBuffer): PCDHeader => {
        const data = new TextDecoder().decode(headerBuffer);

        const result1 = data.search(/[\r\n]DATA\s(\S*)\s/i);
        const result2 = /[\r\n]DATA\s(\S*)\s/i.exec(data.slice(result1 - 1));

        const bodyDataType = result2?.[1] as DataType;
        // 多加上最后的换行符\r\n 2个字节
        const headerLen = (result2?.[0].length || 0) + result1;
        // 去除注释
        const str = data.slice(0, headerLen).replace(/#.*/gi, '');

        const versionArr = /VERSION (.*)/i.exec(str);
        const fieldsArr = /FIELDS (.*)/i.exec(str);
        const sizeArr = /SIZE (.*)/i.exec(str);
        const typeArr = /TYPE (.*)/i.exec(str);
        const countArr = /COUNT (.*)/i.exec(str);
        const widthArr = /WIDTH (.*)/i.exec(str);
        const heightArr = /HEIGHT (.*)/i.exec(str);
        const viewpointArr = /VIEWPOINT (.*)/i.exec(str);
        const pointsArr = /POINTS (.*)/i.exec(str);

        // evaluate
        let version = 0;
        let type: string[] = [];
        let width = 0;
        let height = 0;
        let points = 0;
        let viewpoint = '';
        let size: number[] = [];
        let count: number[] = [];
        const fields = fieldsArr !== null ? fieldsArr[1].split(' ') : [];
        if (versionArr) {
            version = parseFloat(versionArr[1]);
        }

        if (typeArr) {
            type = typeArr[1].split(' ');
        }

        if (widthArr) {
            width = parseInt(widthArr[1]);
        }

        if (heightArr) {
            height = parseInt(heightArr[1]);
        }

        if (viewpointArr) viewpoint = viewpointArr[1];

        if (pointsArr) points = parseInt(pointsArr[1], 10);

        if (!points) points = width * height;

        if (sizeArr) {
            size = sizeArr[1].split(' ').map(function (x: any) {
                return parseInt(x, 10);
            });
        }

        if (countArr) {
            count = countArr[1].split(' ').map(function (x: any) {
                return parseInt(x, 10);
            });
        } else {
            count = [];

            for (let i = 0, l = fields.length; i < l; i++) {
                count.push(1);
            }
        }

        const offset: Record<string, number> = {};

        let sizeSum = 0;

        for (let i = 0, l = fields.length; i < l; i++) {
            if (bodyDataType === 'ascii') {
                offset[fields[i]] = i;
            } else {
                offset[fields[i]] = sizeSum;
                sizeSum += size[i] * count[i];
            }
        }

        // for binary only

        const rowSize = sizeSum;

        const header: PCDHeader = {
            headerLen,
            str,
            fields,
            version,
            data: bodyDataType,
            type,
            width,
            height,
            points,
            size,
            count,
            offset,
            viewpoint,
            rowSize,
        };
        // parse
        return header;
    };

    private readonly fileLoader = async (
        url: string,
        onProgress?: (event: ProgressEvent) => void,
        onError?: (event: any) => void,
    ): Promise<ArrayBuffer> => {
        const req = new Request(url, {
            headers: new Headers(),
            credentials: 'same-origin',
        });

        const file = await fetch(req).then(async (response) => {
            return await response.arrayBuffer();
        });

        return await new Promise((resolve, reject) => {
            resolve(file);
        });
    };

    // private getValue()

    private getDataByAscii(dataBuffer: ArrayBuffer, header: PCDHeader): Record<number, PointDataInfo> {
        const dataByFrame: Record<number, PointDataInfo> = {};

        // 为防止文件太大，分批进行解析；
        let linesNum = 0;
        let readLen = 0;
        let frame = 0; // 当前读取位置

        const range = dataBuffer.byteLength / frames;

        // const keys = ['position', 'normal', 'color'];
        // let objectData: PointDataInfo = {
        //     position: [],
        //     color: [],
        //     normal: [],
        //     intensity: [],
        // };
        const { fields, offset, type } = header;
        const predictFields = ['x', 'y', 'z', 'normal_x', 'normal_y', 'normal_z', 'rgb'];

        let objectData: PointDataInfo = Object.keys(offset).reduce(
            (previous: PointDataInfo, currentKey: string) => {
                if (!predictFields.includes(currentKey)) {
                    previous[currentKey] = [];
                }
                return previous;
            },
            {
                position: [],
                color: [],
                normal: [],
            },
        );
        const textDecoder = new TextDecoder();
        // 初始化
        fields.forEach((field: string) => {
            if (!predictFields.includes(field)) {
                objectData[field] = [];
                // keys.push(field);
            }
        });

        // const position: number[] = [];
        let data: undefined | ArrayBuffer;
        let pcdData: undefined | string;
        // let color = [];
        // let normal = [];
        // let other: Record<string, number[]> = {};

        const readData = (): void => {
            if (readLen >= dataBuffer.byteLength) {
                return;
            }
            data = dataBuffer.slice(readLen, Math.min(readLen + range, dataBuffer.byteLength));
            pcdData = textDecoder.decode(data);
            data = undefined;
            readLen +=
                readLen + range > dataBuffer.byteLength
                    ? dataBuffer.byteLength
                    : pcdData.lastIndexOf('\n') + enterLength - 1;

            // 舍弃最后一行
            const lines = pcdData.split('\n');
            lines.pop();

            for (let i = 0, l = lines.length; i < l; i++) {
                if (lines[i] === '') {
                    continue;
                }

                const line = lines[i].split(' ');

                for (const field in objectData) {
                    if (field === 'position' && offset.x !== undefined) {
                        objectData.position.push(
                            parseFloat(line[offset.x]),
                            parseFloat(line[offset.y]),
                            parseFloat(line[offset.z]),
                        );
                    } else if (field === 'color' && offset.rgb !== undefined) {
                        const rgbFieldIndex = fields.findIndex((field: any) => field === 'rgb');
                        const rgbType = type[rgbFieldIndex];

                        const float = parseFloat(line[offset.rgb]);
                        let rgb = float;

                        if (rgbType === 'F') {
                            const farr = new Float32Array(1);
                            farr[0] = float;
                            rgb = new Int32Array(farr.buffer)[0];
                        }

                        const r = (rgb >> 16) & 0x0000ff;
                        const g = (rgb >> 8) & 0x0000ff;
                        const b = (rgb >> 0) & 0x0000ff;
                        // color.push(r / 255, g / 255, b / 255);
                        objectData.color.push(r / 255, g / 255, b / 255);
                    } else if (field === 'normal' && offset.normal_x !== undefined) {
                        objectData.normal.push(
                            parseFloat(line[offset.normal_x]),
                            parseFloat(line[offset.normal_y]),
                            parseFloat(line[offset.normal_z]),
                        );
                    } else if (offset[field] !== undefined) {
                        objectData[field] = objectData[field] || [];
                        const fieldIndex = fields.findIndex((name: any) => name === field);
                        const typeValue = type[fieldIndex];
                        if (typeValue === 'U') {
                            objectData[field].push(parseInt(line[offset[field]]));
                        } else if (typeValue === 'F') {
                            objectData[field].push(parseFloat(line[offset[field]]));
                        }
                    }
                }

                dataByFrame[frame] = objectData;

                linesNum++;

                if (this.multFrame && linesNum > (header.points / frames) * (frame + 1)) {
                    // dataByFrame[frame] = {
                    //     position,
                    //     color,
                    //     normal,
                    //     ...other,
                    // }
                    objectData = Object.keys(offset).reduce(
                        (previous: PointDataInfo, currentKey: string) => {
                            if (!predictFields.includes(currentKey)) {
                                previous[currentKey] = [];
                            }
                            return previous;
                        },
                        {
                            position: [],
                            color: [],
                            normal: [],
                        },
                    );
                    // position = [];
                    // color = [];
                    // normal = [];
                    // other = {};
                    frame++;
                }
            }

            // console.log('计算中：帧数-', Object.keys(dataByFrame).length);
            // console.log(`读取范围：`, dataByFrame)
            readData();
            // if (pcdData.lastIndexOf('\n') !== -1) {
            //     readData();
            // }
        };

        readData();

        return dataByFrame;
    }

    private checkLength = (objectData: any): boolean => {
        const length = objectData.position.length;

        for (const key in objectData) {
            if (objectData[key].length && objectData[key].length !== length) {
                return false;
            }
        }

        return true;
    };

    public getDataByBinary(dataBuffer: ArrayBuffer, header: PCDHeader): Record<number, PointDataInfo> {
        const dataByFrame: Record<number, PointDataInfo> = {};

        const { rowSize, points, offset, fields, type: types } = header;
        const dataview = new DataView(dataBuffer);

        const predictFields = ['x', 'y', 'z', 'normal_x', 'normal_y', 'normal_z', 'rgb', 'intensity'];
        let frame = 0;
        let objectData: PointDataInfo = Object.keys(offset).reduce(
            (previous: PointDataInfo, currentKey: string) => {
                if (!predictFields.includes(currentKey)) {
                    previous[currentKey] = [];
                }
                return previous;
            },
            {
                position: [],
                color: [],
                normal: [],
                intensity: [],
            },
        );
        try {
            for (let i = 0, row = 0; i < points; i++, row += rowSize) {
                for (const field in objectData) {
                    if (field === 'position' && offset.x !== undefined) {
                        objectData.position.push(dataview.getFloat32(row + offset.x, this.littleEndian));
                        objectData.position.push(dataview.getFloat32(row + offset.y, this.littleEndian));
                        objectData.position.push(dataview.getFloat32(row + offset.z, this.littleEndian));
                    } else if (field === 'color' && offset.rgb !== undefined) {
                        objectData.color.push(dataview.getUint8(row + offset.rgb + 2) / 255.0);
                        objectData.color.push(dataview.getUint8(row + offset.rgb + 1) / 255.0);
                        objectData.color.push(dataview.getUint8(row + offset.rgb + 0) / 255.0);
                    } else if (field === 'normal' && offset.normal_x !== undefined) {
                        objectData.normal.push(dataview.getFloat32(row + offset.normal_x, this.littleEndian));
                        objectData.normal.push(dataview.getFloat32(row + offset.normal_y, this.littleEndian));
                        objectData.normal.push(dataview.getFloat32(row + offset.normal_z, this.littleEndian));
                    } else if (field === 'intensity' && offset.intensity !== undefined) {
                        // objectData.intensity.push(dataview.getFloat32(row + offset.intensity, this.littleEndian));
                        const fieldIndex = fields.findIndex((name: any) => name === field);
                        const type = types[fieldIndex] as 'U' | 'F';
                        const value = getValue(dataview, type, row + offset[field], this.littleEndian, field);

                        objectData[field].push(value);
                        // } else if (offset[field] !== undefined && objectData[field]) {
                        //     const fieldIndex = fields.findIndex((name: any) => name === field);
                        //     const type = types[fieldIndex] as 'U' | 'F';
                        //     const value = getValue(dataview, type, row + offset[field], this.littleEndian, field);

                        //     objectData[field].push(value);
                    }
                }
                dataByFrame[frame] = objectData;
                if (this.multFrame && i > (points / frames) * (frame + 1)) {
                    objectData = Object.keys(offset).reduce(
                        (previous: PointDataInfo, currentKey: string) => {
                            if (!predictFields.includes(currentKey)) {
                                previous[currentKey] = [];
                            }
                            return previous;
                        },
                        {
                            position: [],
                            color: [],
                            normal: [],
                        },
                    );
                    frame++;
                }
            }
        } catch (error) {
            console.error('读取点云异常：', error);
        }

        console.log('读取结果：', dataByFrame);
        return dataByFrame;
    }

    public async load(
        url: string,
        onLoad?: (points: Points | Record<number, Points>) => void,
        onProgress?: (event: ProgressEvent) => void,
        onError?: (event: any) => void,
    ): Promise<Points | Record<number, Points>> {
        let objectDataByFrame: Record<number, PointDataInfo> = {};

        try {
            // 读取文件
            const arrayBuffer = await this.fileLoader(url, onProgress);
            // console.log('读取：', arrayBuffer);
            // 读取头部信息
            const headerBuffer = arrayBuffer.slice(0, 300);
            const header = this.getHeader(headerBuffer);
            // console.log('读取头部信息：', header);

            const dataBuffer = arrayBuffer.slice(header.headerLen);

            const { data: dataType } = header;
            switch (dataType) {
                case 'ascii':
                    objectDataByFrame = this.getDataByAscii(dataBuffer, header);
                    break;

                case 'binary':
                    objectDataByFrame = this.getDataByBinary(dataBuffer, header);
                    break;

                case 'binary_compressed':
                    break;

                default:
                    break;
            }
        } catch (e: any) {
            onError?.(e);
            this.onError?.(e);
            // console.error('错误了！', e)
        }
        return await new Promise((resolve, reject) => {
            const points: Points[] = [];
            for (const [frame, objectData] of Object.entries(objectDataByFrame)) {
                const geometry = new BufferGeometry();

                for (const [field, value] of Object.entries(objectData)) {
                    if ((value as any)?.length > 0)
                        geometry.setAttribute(
                            field,
                            new Float32BufferAttribute(
                                [...value] as any,
                                ['position', 'normal', 'color'].includes(field) ? 3 : 1,
                            ),
                        );
                }

                const material = new PointsMaterial({ size: 0.005 });
                if (objectData?.color?.length > 0) {
                    material.vertexColors = true;
                }
                geometry.computeBoundingSphere();
                const point = new Points(geometry, material);
                point.name = 'point_cloud';
                point.userData = { frame };

                points.push(point);
            }

            objectDataByFrame = {};
            // onLoad?.(points[0]);
            // resolve(points[0]);
            // onLoad?.(points.length === 1 ? points[0] : points);
            // resolve(points.length === 1 ? points[0] : points);
            onLoad?.(points.length === 1 ? points[0] : points);
            resolve(points.length === 1 ? points[0] : points);
        });
    }

    public getDataMultByAscii(dataBuffer: ArrayBuffer, header: PCDHeader): PointMultDataInfo {
        const { rowSize, points, offset, fields, type } = header;

        // const range = dataBuffer.byteLength / frames;

        const predictFields = ['x', 'y', 'z', 'normal_x', 'normal_y', 'normal_z', 'rgb', 'intensity', 'min', 'max'];
        const objectData: PointMultDataInfo = Object.keys(offset).reduce(
            (previous: PointMultDataInfo, currentKey: string) => {
                if (!predictFields.includes(currentKey)) {
                    previous[currentKey] = [[]];
                }
                return previous;
            },
            {
                position: [[]],
                color: [[]],
                normal: [[]],
                intensity: [[]],

                min: {
                    x: Number.MAX_SAFE_INTEGER,
                    y: Number.MAX_SAFE_INTEGER,
                    z: Number.MAX_SAFE_INTEGER,
                },
                max: {
                    x: Number.MIN_SAFE_INTEGER,
                    y: Number.MIN_SAFE_INTEGER,
                    z: Number.MIN_SAFE_INTEGER,
                },

                length: points,
            },
        );

        try {
            const textData = new TextDecoder().decode(dataBuffer);
            textData.trim();
            const lines = textData.split('\n');
            const { min, max } = objectData;

            for (let i = 0, l = lines.length; i < l; i++) {
                if (lines[i] === '') continue;

                const line = lines[i].split(' ');

                for (const field in objectData) {
                    if (field === 'position' && offset.x !== undefined) {
                        const x = parseFloat(line[offset.x]);
                        const y = parseFloat(line[offset.y]);
                        const z = parseFloat(line[offset.z]);
                        objectData.position[0].push(x);
                        objectData.position[0].push(y);
                        objectData.position[0].push(z);

                        min.x = Math.min(min.x, x);
                        min.y = Math.min(min.y, y);
                        min.z = Math.min(min.z, z);

                        max.x = Math.max(max.x, x);
                        max.y = Math.max(max.y, y);
                        max.z = Math.max(max.z, z);
                    } else if (field === 'color' && offset.rgb !== undefined) {
                        const rgb_field_index = fields.findIndex((field: any) => field === 'rgb');
                        const rgb_type = type[rgb_field_index];

                        const float = parseFloat(line[offset.rgb]);
                        let rgb = float;

                        if (rgb_type === 'F') {
                            const farr = new Float32Array(1);
                            farr[0] = float;
                            rgb = new Int32Array(farr.buffer)[0];
                        }

                        const r = (rgb >> 16) & 0x0000ff;
                        const g = (rgb >> 8) & 0x0000ff;
                        const b = (rgb >> 0) & 0x0000ff;
                        // color.push(r / 255, g / 255, b / 255);
                        objectData.color[0].push(r / 255, g / 255, b / 255);
                    } else if (field === 'normal' && offset.normal_x !== undefined) {
                        objectData.normal[0].push(parseFloat(line[offset.normal_x]));
                        objectData.normal[0].push(parseFloat(line[offset.normal_y]));
                        objectData.normal[0].push(parseFloat(line[offset.normal_z]));
                    } else if (offset[field] !== undefined) {
                        const field_index = fields.findIndex((name: any) => name === field);
                        const typeType = type[field_index];
                        if (typeType === 'U') {
                            objectData[field][0].push(parseInt(line[offset[field]]));
                        } else if (typeType === 'F') {
                            objectData[field][0].push(parseFloat(line[offset[field]]));
                        }
                    }
                }
            }
        } catch (error) {
            console.error('读取点云异常：', error);
            throw new Error(`读取点云异常：${(error as Error).message}`);
        }

        return objectData;
    }

    public getDataMultByBinary(dataBuffer: ArrayBuffer, header: PCDHeader): PointMultDataInfo {
        const { rowSize, points, offset, fields, type: types } = header;
        const dataview = new DataView(dataBuffer);

        const predictFields = ['x', 'y', 'z', 'normal_x', 'normal_y', 'normal_z', 'rgb', 'intensity', 'min', 'max'];
        const objectData: PointMultDataInfo = Object.keys(offset).reduce(
            (previous: PointMultDataInfo, currentKey: string) => {
                if (!predictFields.includes(currentKey)) {
                    previous[currentKey] = [];
                }
                return previous;
            },
            {
                position: [],
                color: [],
                normal: [],
                intensity: [],

                min: {
                    x: Number.MAX_SAFE_INTEGER,
                    y: Number.MAX_SAFE_INTEGER,
                    z: Number.MAX_SAFE_INTEGER,
                },
                max: {
                    x: Number.MIN_SAFE_INTEGER,
                    y: Number.MIN_SAFE_INTEGER,
                    z: Number.MIN_SAFE_INTEGER,
                },

                length: points,
            },
        );

        try {
            let readCount = 0;
            const c = new Color();

            let isPageOver = false;

            for (let i = 0, row = 0; i < points; i++, row += rowSize) {
                const { min, max } = objectData;

                for (const field in objectData) {
                    if (['min', 'max', 'length'].includes(field)) {
                        continue;
                    }
                    const array = objectData[field][readCount] || [];
                    if (field === 'position' && offset.x !== undefined) {
                        const x = dataview.getFloat32(row + offset.x, this.littleEndian);
                        const y = dataview.getFloat32(row + offset.y, this.littleEndian);
                        const z = dataview.getFloat32(row + offset.z, this.littleEndian);
                        min.x = Math.min(min.x, x);
                        min.y = Math.min(min.y, y);
                        min.z = Math.min(min.z, z);

                        max.x = Math.max(max.x, x);
                        max.y = Math.max(max.y, y);
                        max.z = Math.max(max.z, z);

                        array.push(x);
                        array.push(y);
                        array.push(z);
                        if (array.length >= readLengthOnce * 3) {
                            isPageOver = true;
                        }
                    } else if (field === 'color' && offset.rgb !== undefined) {
                        // array.push(dataview.getUint8(row + offset.rgb + 2) / 255.0);
                        // array.push(dataview.getUint8(row + offset.rgb + 1) / 255.0);
                        // array.push(dataview.getUint8(row + offset.rgb + 0) / 255.0);

                        const r = dataview.getUint8(row + offset.rgb + 2) / 255.0;
                        const g = dataview.getUint8(row + offset.rgb + 1) / 255.0;
                        const b = dataview.getUint8(row + offset.rgb + 0) / 255.0;

                        c.set(r, g, b).convertSRGBToLinear();

                        array.push(c.r, c.g, c.b);
                        if (array.length >= readLengthOnce * 3) {
                            isPageOver = true;
                        }
                    } else if (field === 'normal' && offset.normal_x !== undefined) {
                        array.push(dataview.getFloat32(row + offset.normal_x, this.littleEndian));
                        array.push(dataview.getFloat32(row + offset.normal_y, this.littleEndian));
                        array.push(dataview.getFloat32(row + offset.normal_z, this.littleEndian));
                        if (array.length >= readLengthOnce * 3) {
                            isPageOver = true;
                        }
                    } else if (field === 'intensity' && offset.intensity !== undefined) {
                        const fieldIndex = fields.findIndex((name: any) => name === field);
                        const type = types[fieldIndex] as 'U' | 'F';
                        const value = getValue(dataview, type, row + offset[field], this.littleEndian, field);

                        array.push(value);
                        if (array.length >= readLengthOnce) {
                            isPageOver = true;
                        }
                    } else {
                        continue;
                    }
                    objectData[field][readCount] = array;
                }

                if (isPageOver) {
                    isPageOver = false;
                    readCount++;
                }
            }
        } catch (error) {
            console.error('读取点云异常：', error);
            throw new Error(`读取点云异常：${(error as Error).message}`);
        }

        return objectData;
    }

    public async loadMult(
        url: string,
        onLoad?: (points: PointMultDataInfo) => void,
        onProgress?: (event: ProgressEvent) => void,
        onError?: (event: any) => void,
    ): Promise<PointMultDataInfo> {
        let objectData: PointMultDataInfo;

        try {
            // 读取文件
            const arrayBuffer = await this.fileLoader(url, onProgress);
            // console.log('读取：', arrayBuffer);
            // 读取头部信息
            const headerBuffer = arrayBuffer.slice(0, 300);
            const header = getHeader(headerBuffer);
            // console.log('读取头部信息：', header);

            const dataBuffer = arrayBuffer.slice(header.headerLen);

            const { data: dataType } = header;

            switch (dataType) {
                case 'ascii':
                    // objectData = this.getDataMultByAscii(dataBuffer, header);
                    objectData = getDataMultByAscii(dataBuffer, header, 1000000, 1000000);
                    break;

                case 'binary':
                    objectData = getDataMultByBinary(dataBuffer, header, this.littleEndian, 1000000, 300000);
                    // objectData = this.getDataMultByBinary(dataBuffer, header);
                    break;

                case 'binary_compressed':
                    break;

                default:
                    break;
            }
        } catch (e: any) {
            onError?.(e);
            this.onError?.(e);
        }
        return await new Promise((resolve, reject) => {
            onLoad?.(objectData);
            resolve(objectData);
        });
    }

    /**
     * 读取失败的错误事件
     * @param event
     */
    public onError(event: ErrorEvent): any {}

    /**
     *
     * @param url 文件url
     * @param segmentLength 需要进行分割的最大点数
     * @param singleMaxLength 分割时，单个文件的最大点数
     * @param littleEndian 将低序字节存储在起始地址（低位编址）
     * @returns PointMultDataInfo 返回点云信息
     */
    public static async load(
        url: string,
        segmentLength: number = 1000000,
        singleMaxLength: number = 1000000,
        littleEndian: boolean = true,
    ): Promise<PointMultDataInfo> {
        let objectData: PointMultDataInfo;

        try {
            // const req = new Request(url, {
            //     headers: new Headers(),
            //     credentials: 'same-origin',
            // });

            // 读取文件
            const arrayBuffer = await fetch(url).then(async (response) => {
                return await response.arrayBuffer();
            });
            // console.log('读取：', arrayBuffer);
            // 读取头部信息
            const headerBuffer = arrayBuffer.slice(0, 300);
            const header = getHeader(headerBuffer);
            // console.log('读取头部信息：', header);

            const dataBuffer = arrayBuffer.slice(header.headerLen);

            const { data: dataType } = header;

            switch (dataType) {
                case 'ascii':
                    objectData = getDataMultByAscii(dataBuffer, header, segmentLength, singleMaxLength);
                    break;

                case 'binary':
                    objectData = getDataMultByBinary(dataBuffer, header, littleEndian, segmentLength, singleMaxLength);
                    break;

                case 'binary_compressed':
                    break;

                default:
                    break;
            }
        } catch (e: any) {
            PCDLoader.onError?.(e);
        }
        return await new Promise((resolve, reject) => {
            resolve(objectData);
        });
    }

    /**
     * 读取失败的错误事件
     * @param event
     */
    public static onError(event: ErrorEvent): any {}

    public static intensityToColor(intensities: number[][]): number[][] {
        const isRenderColor = true; // 是否渲染颜色
        const isRenderColorByintensity = true; // 是否按照强度值渲染颜色
        // const isRenderColorByHeight = false; // 是否按照高度值渲染颜色

        const white = new Color('white');
        const red = new Color('red');
        const fuchsia = new Color('fuchsia');
        const yellow = new Color('yellow');
        const green = new Color('green');
        const aqua = new Color('aqua');
        const blue = new Color('blue');

        // const { r: wr, g: wg, b: wb } = white;
        // const { r: rr, g: rg, b: rb } = red;
        // const { r: fr, g: fg, b: fb } = fuchsia;
        // const { r: yr, g: yg, b: yb } = yellow;
        // const { r: gr, g: gg, b: gb } = green;
        // const { r: ar, g: ag, b: ab } = aqua;
        // const { r: br, g: bg, b: bb } = blue;
        const color: number[][] = [];
        if (intensities?.length && isRenderColor && isRenderColorByintensity) {
            intensities.forEach((intensitys, index) => {
                color[index] = color?.[index] || [];
                intensitys.forEach((intensity, num) => {
                    let r = blue.r,
                        g = blue.g,
                        b = blue.b; // 默认蓝色
                    if (intensity < 1) {
                        r = white.r;
                        g = white.g;
                        b = white.b;
                    } else if (intensity < 3) {
                        r = green.r;
                        g = green.g;
                        b = green.b;
                    } else if (intensity < 5) {
                        r = yellow.r;
                        g = yellow.g;
                        b = yellow.b;
                    } else if (intensity < 7) {
                        r = aqua.r;
                        g = aqua.g;
                        b = aqua.b;
                    } else if (intensity < 8) {
                        r = red.r;
                        g = red.g;
                        b = red.b;
                    } else if (intensity < 10) {
                        r = fuchsia.r;
                        g = fuchsia.g;
                        b = fuchsia.b;
                    }
                    color[index].push(r, g, b);
                });
            });
            return color;
        }
        return [];
    }

    public static axisZToColor(positions: number[][]): number[][] {
        const isRenderColor = true; // 是否渲染颜色
        // const isRenderColorByintensity = true; // 是否按照强度值渲染颜色
        const isRenderColorByHeight = true; // 是否按照高度值渲染颜色

        const heightObj: Record<string, number> = {};

        const white = new Color('white');
        const red = new Color('red');
        const fuchsia = new Color('fuchsia');
        const yellow = new Color('yellow');
        const green = new Color('green');
        const aqua = new Color('aqua');
        const blue = new Color('blue');

        // const { r: wr, g: wg, b: wb } = white;
        // const { r: rr, g: rg, b: rb } = red;
        // const { r: fr, g: fg, b: fb } = fuchsia;
        // const { r: yr, g: yg, b: yb } = yellow;
        // const { r: gr, g: gg, b: gb } = green;
        // const { r: ar, g: ag, b: ab } = aqua;
        // const { r: br, g: bg, b: bb } = blue;
        const color: number[][] = [];
        let floorHeight = 0; // 地面z轴
        if (positions?.length && isRenderColor && isRenderColorByHeight) {
            //
            positions.forEach((poses, number) => {
                poses.forEach((num, index) => {
                    if (index % 3 === 2) {
                        const key = num.toFixed(2);
                        heightObj[key] = heightObj[key] ? heightObj[key] + 1 : 1;
                    }
                });
            });

            const array = Object.entries(heightObj).sort((a, b) => a[1] - b[1]);
            floorHeight = +array[array.length - 1][0];
            // console.log('查看排序：', JSON.stringify(array.flat()));
            // console.log('基本高度：', floorHeight);

            // 获取所有Z
            positions.forEach((poses, number) => {
                color[number] = color?.[number] || [];
                poses.forEach((num, index) => {
                    if (index % 3 === 2) {
                        let r = white.r,
                            g = white.g,
                            b = white.b; // 默认蓝色
                        if (num < floorHeight + 0.2) {
                            // 地面下两公分内都认为是地面
                            r = blue.r;
                            g = blue.g;
                            b = blue.b;
                        } else if (num < floorHeight + 0.5) {
                            r = aqua.r;
                            g = aqua.g;
                            b = aqua.b;
                        } else if (num < floorHeight + 1) {
                            r = green.r;
                            g = green.g;
                            b = green.b;
                        } else if (num < floorHeight + 1.5) {
                            r = yellow.r;
                            g = yellow.g;
                            b = yellow.b;
                        } else if (num < floorHeight + 2) {
                            r = red.r;
                            g = red.g;
                            b = red.b;
                        } else if (num < floorHeight + 2.5) {
                            r = fuchsia.r;
                            g = fuchsia.g;
                            b = fuchsia.b;
                        }
                        color[number].push(r, g, b);
                    }
                });
            });

            return color;
        }
        return [];
    }
}

export default PCDLoader;
