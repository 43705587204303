import { AnnotationSettingState, LocalSettingState } from 'reducers/interfaces';

/*
 * @Author: swxy
 * @Date: 2022-08-15 12:10:23
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
const language = 'Language';

const storageKey = {
    token: 'token',
    loginInfo: 'loginInfo',
    userInfo: 'userInfo',
    setting: 'setting_local',
};

export const removeLanguage = (): void => {
    localStorage.removeItem(language);
};
export const setLanguage = (value: string): void => {
    if (value) {
        localStorage.setItem(language, value);
    }
};
export const getLanguage = (): string | null => localStorage.getItem(language);

export const saveToken = (token: string): void => {
    if (typeof token === 'string') {
        localStorage.setItem(storageKey.token, token);
    } else {
        console.error('储存token信息失败！传入的值不是string类型');
    }
};

export const getToken = (): string | null => {
    let token = localStorage.getItem(storageKey.token);
    token = (token || '').replace(/"/g, '');
    return token;
};

export const getUserInfo = (): any => {
    try {
        let userInfo = localStorage.getItem(storageKey.userInfo);
        userInfo = JSON.parse(userInfo || '{}');
        return userInfo;
    } catch (error) {}
    return {};
};

// export const getSetting = (): LocalSettingState | undefined => {
export const getSetting = (defaultSetting?: AnnotationSettingState): AnnotationSettingState | undefined => {
    try {
        const settingStr = localStorage.getItem(storageKey.setting);
        if (settingStr) {
            let setting = JSON.parse(settingStr);
            return setting;
        }
        return defaultSetting;
    } catch (error) {
        localStorage.removeItem(storageKey.setting);
        return defaultSetting;
    }
};

export const saveSetting = (setting?: any) => {
    try {
        if (setting) {
            localStorage.setItem(storageKey.setting, JSON.stringify(setting));
        }
    } catch (error) {}
};

export const clearAllSave = () => {
    const setting = getSetting();
    localStorage.clear();
    sessionStorage.clear();
    saveSetting(setting);
};

export const clearAllCache = () => {
    localStorage.clear();
    sessionStorage.clear();
};

export const removeToken = () => {
    localStorage.removeItem(storageKey.token);
    localStorage.removeItem(storageKey.loginInfo);
    localStorage.removeItem(storageKey.userInfo);
};
