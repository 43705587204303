/*
 * @Author: swxy
 * @Date: 2022-08-19 11:50:48
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'shape.rectangle': '矩',
    'shape.polygon': '多',
    'shape.polyline': '线',
    'shape.points': '点',
    'shape.cuboid': '体',
    'shape.image': '图',
    'shape.ellipse': '圆',
    'shape.other': '他',

    'shape.full.rectangle': '矩形',
    'shape.full.polygon': '多边形',
    'shape.full.polyline': '线段',
    'shape.full.points': '点',
    'shape.full.cuboid': '長方形',
    'shape.full.image': '画像',
    'shape.full.ellipse': '楕円形',
    'shape.full.other': '不明なタイプ',

};
