

import React from 'react';
import {
    Modal, Form, Button, Row, Space, Checkbox, Collapse, Divider, Col, Popconfirm, message, CheckboxOptionType, Select,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
// import { changeAnnotationsSettingStatus } from 'actions/annotation-actions';
import { CombinedState, PointCloudColorBy } from 'reducers/interfaces';
import { showSetting as showSettingAction, updateAnnotationSetting } from 'actions/settings-actions';
import { useTranslation } from 'react-i18next';
import { defaultAnnotationSetting, defaultSetting } from 'utils/setting';
import { RoleType } from 'utils/ConstType';

interface Props {
    visible?: boolean; // 强制控制展示与否
}

interface SettingProps {
    onCancel?: () => void;
}

interface CommonData {
    key: string;
    title: string;
    description: string;
    options?: CheckboxOptionType[],
}
interface SettingData {
    key: string;
    title: string;
    header: string;
    description: string;
    detail: string;
    common: CommonData[];
}

const SettingComponent = (props: SettingProps): JSX.Element => {

    const {
        setting,
        user,
    } = useSelector((state: CombinedState) => ({
        setting: {
            'isForce': state.settings.annotationSetting.camera.isForce,
            'isFollow': state.settings.annotationSetting.camera.isFollow,
            'pointCloudColor': state.settings.annotationSetting.canvas3D.pointCloudColor
        },
        user: state.auth.user,
    }));

    const [fromInstance] = Form.useForm();

    const { t } = useTranslation();


    const dispatch = useDispatch();
    // const cancel = (): void => {
    //     if (dispatch) {
    //         dispatch(showSettingAction());
    //     }
    // };

    const onOk = (value: any = {}): void => {
        // console.log('选择了：', value)
        dispatch(updateAnnotationSetting({
            camera: {
                isFollow: value['isFollow'],
                isForce: value['isForce'],
            },
            canvas3D: {
                pointCloudColor: value['pointCloudColor']
            }
        }))

        message.success('保存成功！');
    };

    const onInitialize = () => {
        let setting = defaultSetting;
        if (user && user.roleType === RoleType.annotator) {
            setting = defaultAnnotationSetting;
        }
        // dispatch(updateAnnotationSetting(setting));
        // fromInstance.resetFields();
        // message.success('初始化成功！');
        fromInstance.setFieldsValue({
            isForce: setting.camera.isForce,
            isFollow: setting.camera.isFollow,
            pointCloudColor: PointCloudColorBy.normal,
        });
    }

    // console.log('当前的值：', setting)

    const optionsCanvasColor = [{
        label: t('setting.workspace.canvas.pointcloudcolor.normal'),
        value: PointCloudColorBy.normal,
    }, {
        label: t('setting.workspace.canvas.pointcloudcolor.color'),
        value: PointCloudColorBy.color,
        // disabled: true,
    }, {
        label: t('setting.workspace.canvas.pointcloudcolor.intensity'),
        value: PointCloudColorBy.intensity,
    }, {
        label: t('setting.workspace.canvas.pointcloudcolor.axisZ'),
        value: PointCloudColorBy.axisZ,
        // disabled: true,
    },]

    const data: SettingData[] = [
        {
            key: 'camera',
            title: t('setting.workspace.3d.camera'),
            header: t('setting.workspace.3dTarget'),
            description: t('setting.workspace.3d.cameraTip'),
            detail: t('setting.workspace.3d.cameraHelp'),
            common: [{
                key: 'isForce',
                title: t('setting.workspace.3d.camera.force'),
                description: t('setting.workspace.3d.camera.forceTip'),
                options: [],
            }, {
                key: 'isFollow',
                title: t('setting.workspace.3d.camera.follow'),
                description: t('setting.workspace.3d.camera.followTip'),
                options: [],
            }],
        },
        {
            key: 'canvas',
            title: t('setting.workspace.canvas.title'),
            header: t('setting.workspace.canvas.header'),
            description: t('setting.workspace.canvas.description'),
            detail: t('setting.workspace.canvas.detail'),
            common: [{
                key: 'pointCloudColor',
                title: t('setting.workspace.canvas.pointCloudColor.title'),
                description: t('setting.workspace.canvas.pointCloudColor.description'),
                options: optionsCanvasColor,
            },]
        }
    ]


    // console.log('初始值：', setting)
    return <Form
        onFinish={onOk}
        style={{ padding: '24px' }}
        initialValues={setting}
        form={fromInstance}
    >
        <Collapse style={{ maxHeight: '80vh' }} defaultActiveKey={'object'}>
            {/* <Collapse.Panel key={'object'} header={t('setting.workspace.3dTarget')}>
                <Row>{data.camera.title}</Row>
                <Row gutter={[24, 24]}>
                    <Col>{data.camera.detail}</Col>
                    {data.camera.common.map(item => {
                        return <Col>
                            <Form.Item name={item.key} tooltip={item.description} valuePropName='checked'>
                                <Checkbox >{item.title}</Checkbox>
                            </Form.Item>
                        </Col>
                    })}
                </Row>
            </Collapse.Panel> */}

            {data.map(item => {
                return <Collapse.Panel key={item.key} header={item.header}>
                    <Row>{item.detail}</Row>
                    <Row gutter={[24, 24]}>
                        {/* <Col>{item.detail}</Col> */}
                        {item.common.map(item => {
                            if (item?.options?.length) {
                                return <Col>
                                    <Form.Item name={item.key} tooltip={item.description} label={item.title}>
                                        <Select style={{ minWidth: '300px' }} options={item.options} />
                                    </Form.Item>
                                </Col>
                            }
                            return <Col>
                                <Form.Item name={item.key} tooltip={item.description} valuePropName='checked'>
                                    <Checkbox >{item.title}</Checkbox>
                                </Form.Item>
                            </Col>
                        })}
                    </Row>
                </Collapse.Panel>
            })}

        </Collapse>

        <Divider />

        <Row justify={'center'}>
            <Space>
                <Popconfirm title={t('setting.initialize.sure')} onConfirm={onInitialize} okButtonProps={{ danger: true }}>
                    <Button danger>{t('setting.initialize')}</Button>
                </Popconfirm>
                <Button htmlType='submit'>{t('base.save')}</Button>
            </Space>
        </Row>
    </Form>
}


export default ({ visible: enforceVisible }: Props): JSX.Element => {
    const {
        visible,
    } = useSelector((state: CombinedState) => ({
        visible: state.settings.showSetting,
    }));

    const dispatch = useDispatch();
    const cancel = (): void => {
        if (dispatch) {
            dispatch(showSettingAction());
        }
    };

    return (
        <Modal
            open={enforceVisible !== undefined ? !!enforceVisible : visible}
            destroyOnClose
            onCancel={cancel}
            width={'70%'}
            style={{ maxWidth: '600px', minWidth: '400px' }}
            footer={null}
        >
            <SettingComponent onCancel={cancel} />
        </Modal>
    );
};
