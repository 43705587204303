// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// import { Col, Row } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';
// import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Spin from 'antd/lib/spin';
// import Text from 'antd/lib/typography/Text';
import 'antd/dist/antd.css';
import ConfigProvider from 'antd/lib/config-provider';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';

import LoginPageContainer from 'containers/login-page/login-page';
import LoginWithTokenComponent from 'components/login-with-token/login-with-token';
import RegisterPageContainer from 'containers/register-page/register-page';
import ResetPasswordPageConfirmComponent from 'components/reset-password-confirm-page/reset-password-confirm-page';
import ResetPasswordPageComponent from 'components/reset-password-page/reset-password-page';

import Header from 'components/header/header';
import GlobalErrorBoundary from 'components/global-error-boundary/global-error-boundary';

import ShortcutsDialog from 'components/shortcuts-dialog/shortcuts-dialog';
// import ExportDatasetModal from 'components/export-dataset/export-dataset-modal';
import ModelsPageContainer from 'containers/models-page/models-page';

import JobsPageComponent from 'components/jobs-page/jobs-page';

// import TasksPageContainer from 'containers/tasks-page/tasks-page';
import CreateTaskPageContainer from 'containers/create-task-page/create-task-page';
import TaskPageContainer from 'containers/task-page/task-page';

import ProjectsPageComponent from 'components/projects-page/projects-page';
import CreateProjectPageComponent from 'components/create-project-page/create-project-page';
import ProjectPageComponent from 'components/project-page/project-page';

import CloudStoragesPageComponent from 'components/cloud-storages-page/cloud-storages-page';
import CreateCloudStoragePageComponent from 'components/create-cloud-storage-page/create-cloud-storage-page';
import UpdateCloudStoragePageComponent from 'components/update-cloud-storage-page/update-cloud-storage-page';

import OrganizationPage from 'components/organization-page/organization-page';
import CreateOrganizationComponent from 'components/create-organization-page/create-organization-page';

import AnnotationPageContainer from 'containers/annotation-page/annotation-page';
import getCore from 'cvat-core-wrapper';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { AnnotationSettingState, Language, NotificationsState, RoleType } from 'reducers/interfaces';
import { customWaViewHit } from 'utils/enviroment';
// import showPlatformNotification, {
//     platformInfo,
//     stopNotifications,
//     showUnsupportedNotification,
// } from 'utils/platform-checker';
import '../styles.scss';
import { message, Modal } from 'antd';
import { Translation } from 'react-i18next';
import EmailConfirmationPage from './email-confirmation-page/email-confirmed';
// import MainComponent from 'components/main/main';
import SettingsModal from './header/settings-modal/settings-modal';
import moment from 'moment';
import WelcomeComponent from 'components/normalUser';
// import JobListPageComponent from 'components/normalUser/jobs';
import ReadOnlyPageComponent from 'components/normalUser/job';
import ReadOnlyHeaderComponent from 'components/normalUser/header';
import { isNormalUser } from 'utils/constant';
import JobListComponent from './salesmanPages/jobs';
import { isLeaderAndMore } from 'utils/constant';
import { CustomEventDefinition } from 'utils/CustomEvent';
import { api_heartbeat } from 'service/api/api';
import { getSetting } from 'utils/storage';
import { defaultAnnotationSetting, defaultSetting } from 'utils/setting';

ConfigProvider.config({
    theme: {
        primaryColor: '#ffffff',
        errorColor: '#ff4d4f',
        warningColor: '#faad14',
        successColor: '#52c41a',
        infoColor: '#1890ff',
    }
});

const headerStyle: React.CSSProperties = {
    color: 'white',
    backgroundColor: '#7e7e7e',
};

interface CVATAppProps {
    loadFormats: () => void;
    loadAbout: () => void;
    verifyAuthorized: (callback?: () => void) => void;
    loadUserAgreements: () => void;
    initPlugins: () => void;
    initModels: () => void;
    resetErrors: () => void;
    resetMessages: () => void;
    switchShortcutsDialog: () => void;
    switchSettingsDialog: (show?: boolean) => void;
    loadAuthActions: () => void;
    getDataFailed: (error: any) => void;
    changeLastMouseMoveTime: (date: Date) => void;
    updateAnnotationSetting: (setting: AnnotationSettingState) => void;

    // loadOrganizations: () => void;
    keyMap: KeyMap;
    userInitialized: boolean;
    userFetching: boolean;
    organizationsFetching: boolean;
    organizationsInitialized: boolean;
    pluginsInitialized: boolean;
    pluginsFetching: boolean;
    modelsInitialized: boolean;
    modelsFetching: boolean;
    formatsInitialized: boolean;
    formatsFetching: boolean;
    aboutInitialized: boolean;
    aboutFetching: boolean;
    userAgreementsFetching: boolean;
    userAgreementsInitialized: boolean;
    authActionsFetching: boolean;
    authActionsInitialized: boolean;
    notifications: NotificationsState;
    user: any;
    isModelPluginActive: boolean;
    settingsDialogShown: boolean;
    language: Language;

    heartInterval: number;
    lastMouseMoveTime: Date;

}

let lastActiveDate = new Date().valueOf();
let needUpdateDate = false;

class CVATApplication extends React.PureComponent<CVATAppProps & RouteComponentProps> {
    public constructor(props: CVATAppProps & RouteComponentProps) {
        super(props);
        const { language } = props;
        moment.locale(language);

        const { history, location } = this.props;

        if (location.search.includes('loginfo=')) {
            localStorage.clear();
            const loginIndex = location.search.indexOf('loginfo=');
            const index = location.search.indexOf('{');
            let loginfoSrc: any = location.search.substring(index);
            loginfoSrc = decodeURI(loginfoSrc);
            localStorage.setItem('loginInfo', loginfoSrc);
            loginfoSrc = JSON.parse(loginfoSrc);
            localStorage.setItem('token', loginfoSrc.token);
            history.replace({
                ...location,
                search: location.search.substring(0, loginIndex),
            });
            window.location.reload();
            return;
        }
    }

    public componentDidMount(): void {
        const {
            verifyAuthorized,
            history,
            location,
            // heartInterval,
            // lastMouseMoveTime,
            // changeLastMouseMoveTime,

            getDataFailed,
        } = this.props;

        // if (location.search.includes('loginfo=')) {
        //     localStorage.clear();
        //     const loginIndex = location.search.indexOf('loginfo=');
        //     const index = location.search.indexOf('{');
        //     let loginfoSrc: any = location.search.substring(index);
        //     loginfoSrc = decodeURI(loginfoSrc);
        //     localStorage.setItem('loginInfo', loginfoSrc);
        //     loginfoSrc = JSON.parse(loginfoSrc);
        //     localStorage.setItem('token', loginfoSrc.token);
        //     history.replace({
        //         ...location,
        //         search: location.search.substring(0, loginIndex),
        //     });
        //     window.location.reload();
        //     return;
        // }
        const core = getCore();
        // configure({ ignoreRepeatedEventsWhenKeyHeldDown: false });

        this.getAnnotationSetting();

        // Logger configuration
        const userActivityCallback: (() => void)[] = [];
        window.addEventListener('click', () => {
            userActivityCallback.forEach((handler) => handler());
        });
        core.logger.configure(() => window.document.hasFocus, userActivityCallback);

        customWaViewHit(location.pathname, location.search, location.hash);
        history.listen((_location) => {
            customWaViewHit(_location.pathname, _location.search, _location.hash);
        });

        verifyAuthorized(() => {
            history.push(location.pathname.length > 1 ? `/login/?next=${location.pathname}` : '/login');
            message.error(<Translation>{(t) => t('loginfo_timeout')}</Translation>);
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        });

        addEventListener(CustomEventDefinition.customError, (e) => {
            const { data } = (e as CustomEvent).detail;
            console.log('errror', data)
            getDataFailed(data);
            throw data;
        })

        const updateDate = () => {
            if (!location.pathname.startsWith('/login') && !location.pathname.startsWith('/register')) {
                // 不是登录页面和注册页面
                // changeLastMouseMoveTime(new Date())

                needUpdateDate = true;
            }
        }

        addEventListener('mousemove', () => {
            updateDate();
        });

        addEventListener('keypress', () => {
            updateDate();
        })

        this.heartBeat();
        // const { name, version, engine, os } = platformInfo();

        // if (showPlatformNotification()) {
        //     stopNotifications(false);
        //     Modal.warning({
        //         title: 'Unsupported platform detected',
        //         className: 'cvat-modal-unsupported-platform-warning',
        //         content: (
        //             <>
        //                 <Row>
        //                     <Col>
        //                         <Text>
        //                             {`The browser you are using is ${name} ${version} based on ${engine}.` +
        //                                 ' idatalab was tested in the latest versions of Chrome and Firefox.' +
        //                                 ' We recommend to use Chrome (or another Chromium based browser)'}
        //                         </Text>
        //                     </Col>
        //                 </Row>
        //                 <Row>
        //                     <Col>
        //                         <Text type='secondary'>{`The operating system is ${os}`}</Text>
        //                     </Col>
        //                 </Row>
        //             </>
        //         ),
        //         onOk: () => stopNotifications(true),
        //     });
        // } else if (showUnsupportedNotification()) {
        //     stopNotifications(false);
        //     Modal.warning({
        //         title: 'Unsupported features detected',
        //         className: 'cvat-modal-unsupported-features-warning',
        //         content: (
        //             <Text>
        //                 {`${name} v${version} does not support API, which is used by idatalab. `}
        //                 It is strongly recommended to update your browser.
        //             </Text>
        //         ),
        //         onOk: () => stopNotifications(true),
        //     });
        // }
    }

    public componentDidUpdate(): void {
        const {
            verifyAuthorized,
            // loadFormats,
            // loadAbout,
            // loadUserAgreements,
            // initPlugins,
            // initModels,
            // loadOrganizations,
            // loadAuthActions,
            userInitialized,
            userFetching,
            // organizationsFetching,
            // organizationsInitialized,
            // formatsInitialized,
            // formatsFetching,
            // aboutInitialized,
            // aboutFetching,
            // pluginsInitialized,
            // pluginsFetching,
            // modelsInitialized,
            // modelsFetching,
            // user,
            // userAgreementsFetching,
            // userAgreementsInitialized,
            // authActionsFetching,
            // authActionsInitialized,
            // isModelPluginActive,
        } = this.props;

        this.showErrors();
        this.showMessages();

        if (!userInitialized && !userFetching) {
            verifyAuthorized();
            // return;
        }

        // if (!userAgreementsInitialized && !userAgreementsFetching) {
        //     loadUserAgreements();
        //     return;
        // }

        // if (!authActionsInitialized && !authActionsFetching) {
        //     loadAuthActions();
        // }

        // if (user == null || !user.isVerified) {

        // }

        // if (!organizationsInitialized && !organizationsFetching) {
        //     loadOrganizations();
        // }

        // if (!formatsInitialized && !formatsFetching) {
        //     loadFormats();
        // }

        // if (!aboutInitialized && !aboutFetching) {
        //     loadAbout();
        // }

        // if (isModelPluginActive && !modelsInitialized && !modelsFetching) {
        //     initModels();
        // }

        // if (!pluginsInitialized && !pluginsFetching) {
        //     initPlugins();
        // }
    }

    private getAnnotationSetting = () => {
        try {
            let setting = getSetting();
            const { user, updateAnnotationSetting } = this.props;
            if (!setting && user && user.roleType === RoleType.annotator) {
                setting = defaultAnnotationSetting;
            } else if (!setting) {
                // 没有值，设置默认
                setting = defaultSetting;
            }
            updateAnnotationSetting(setting);
        } catch (error) {
            console.error('error:', error)
        }
    }

    //
    private heartBeat = () => {
        let { heartInterval, lastMouseMoveTime, location } = this.props;
        if (!location.pathname.startsWith('/login') && !location.pathname.startsWith('/register')) {
            // const last = lastMouseMoveTime.valueOf();
            // const now = new Date().valueOf();

            // if (now - last <= heartInterval * 1000) {
            //     api_heartbeat();
            // }
            if (needUpdateDate) {
                needUpdateDate = false;
                api_heartbeat();
            }
        }

        setTimeout(() => {
            this.heartBeat();
        }, heartInterval * 1000);
    }

    private showMessages(): void {
        function showMessage(title: string): void {
            notification.info({
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: null,
            });
        }

        const { notifications, resetMessages } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.messages)) {
            for (const what of Object.keys((notifications as any).messages[where])) {
                const message2 = (notifications as any).messages[where][what];
                shown = shown || !!message2;
                if (message2) {
                    showMessage(message2);
                }
            }
        }

        if (shown) {
            resetMessages();
        }
    }

    private showErrors(): void {
        function showError(title: string, _error: any, className?: string): void {
            const error = _error.toString();
            const dynamicProps = typeof className === 'undefined' ? {} : { className };
            notification.error({
                ...dynamicProps,
                message: (
                    <div
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                ),
                duration: null,
                description: error.length > 200 ? 'Open the Browser Console to get details' : error,
            });

            // eslint-disable-next-line no-console
            console.error(error);
        }

        const { notifications, resetErrors } = this.props;

        let shown = false;
        for (const where of Object.keys(notifications.errors)) {
            for (const what of Object.keys((notifications as any).errors[where])) {
                const error = (notifications as any).errors[where][what];
                shown = shown || !!error;

                if (error?.type === 'Reload') {
                    // 只在标注页面需要弹出强行刷新的提示
                    if (location.pathname.includes('jobs')) {
                        Modal.error({
                            title: error.message,
                            style: { whiteSpace: 'pre-wrap' },
                            content: `${error.reason} \r\n - ${error.detail.stack}`,
                            onOk: () => {
                                window.location.reload();
                            },
                            className: error.className,
                            okText: <Translation>{(t) => t('ok')}</Translation>,
                        });
                    }
                } else if (error) {
                    showError(error.message, error.reason, error.className);
                }
            }
        }

        if (shown) {
            resetErrors();
        }
    }

    // Where you go depends on your URL
    public render(): JSX.Element {
        const {
            userInitialized,
            // aboutInitialized,
            // pluginsInitialized,
            // formatsInitialized,
            // modelsInitialized,
            // organizationsInitialized,
            switchShortcutsDialog,
            switchSettingsDialog,
            user,
            keyMap,
            location,
            isModelPluginActive,
            settingsDialogShown,
            language,
        } = this.props;
        const readyForRender = userInitialized;
        // const readyForRender =
        //     (userInitialized && (user == null || !user.isVerified)) ||
        //     (userInitialized &&
        //         formatsInitialized &&
        //         pluginsInitialized &&
        //         aboutInitialized &&
        //         organizationsInitialized &&
        //         (!isModelPluginActive || modelsInitialized));

        const subKeyMap = {
            SWITCH_SHORTCUTS: keyMap.SWITCH_SHORTCUTS,
            SWITCH_SETTINGS: keyMap.SWITCH_SETTINGS,
        };

        const handlers = {
            SWITCH_SHORTCUTS: (event: KeyboardEvent) => {
                if (event) event.preventDefault();

                switchShortcutsDialog();
            },
            SWITCH_SETTINGS: (event: KeyboardEvent) => {
                if (event) event.preventDefault();

                switchSettingsDialog();
            },
        };
        const locale = language === 'en_US' ? enUS : zhCN;
        // console.log('语言：', language);
        // console.log('测试：', locale);

        if (readyForRender) {
            if (user && user.isVerified) {
                const searchParams = new URLSearchParams(location.search)
                let next = searchParams.get('next');
                if (next) {
                    searchParams.delete('next');
                    next = `${next}?${searchParams.toString()}`;
                }
                if (isNormalUser(user.roleType)) {
                    return <ConfigProvider locale={locale}>
                        <GlobalErrorBoundary>
                            <Layout>
                                {/* <Header /> */}
                                {location.pathname && location.pathname.endsWith('/project') ? null : <ReadOnlyHeaderComponent />}
                                <Layout.Content style={{ height: '100%' }}>
                                    {/* <ShortcutsDialog />
                                    <SettingsModal
                                        visible={settingsDialogShown}
                                        onClose={() => switchSettingsDialog(false)}
                                    /> */}
                                    {/* <GlobalHotKeys keyMap={subKeyMap} handlers={handlers}> */}
                                    <Switch>
                                        {/* <Route exact path='/welcome' component={WelcomeComponent} /> */}
                                        <Route exact path='/projects' component={WelcomeComponent} />
                                        <Route exact path='/project' component={ReadOnlyPageComponent} />
                                        <Redirect
                                            push
                                            to={next || '/projects'}
                                        />
                                    </Switch>
                                    {/* </GlobalHotKeys> */}
                                    {/* eslint-disable-next-line */}
                                    {/* <ExportDatasetModal /> */}
                                    {/* eslint-disable-next-line */}
                                    <a id='downloadAnchor' target='_blank' style={{ display: 'none' }} download />
                                </Layout.Content>
                                {/* <Layout.Footer style={{ textAlign: 'center' }}>
                                    <Translation>{(t) => `${t('layouts.copyright')} © ${t('layouts.copyright_number')}`}</Translation>
                                </Layout.Footer> */}
                                {location.pathname && location.pathname.includes('jobs') ? null : (
                                    <Layout.Footer style={{ textAlign: 'center' }}>
                                        <Translation>{(t) => `${t('layouts.copyright')} © ${t('layouts.copyright_number')}`}</Translation>
                                    </Layout.Footer>
                                )}
                            </Layout>
                        </GlobalErrorBoundary>

                    </ConfigProvider >
                }
                // return (
                //     <ConfigProvider locale={locale}>
                //         <GlobalErrorBoundary>
                //             <Layout>
                //                 {/* <Header /> */}
                //                 {location.pathname && location.pathname.includes('jobs') ? null : <Header />}
                //                 <Layout.Content style={{ height: '100%' }}>
                //                     <ShortcutsDialog />
                //                     <SettingsModal
                //                         visible={settingsDialogShown}
                //                         onClose={() => switchSettingsDialog(false)}
                //                     />
                //                     <GlobalHotKeys keyMap={subKeyMap} handlers={handlers}>
                //                         <Switch>
                //                             {/* <Route exact path='/main' component={MainComponent} /> */}
                //                             <Route exact path='/projects' component={ProjectsPageComponent} />
                //                             <Route
                //                                 exact
                //                                 path='/projects/create'
                //                                 component={CreateProjectPageComponent}
                //                             />
                //                             <Route exact path='/projects/:id' component={ProjectPageComponent} />
                //                             {/* <Route exact path='/tasks' component={TasksPageContainer} /> */}
                //                             <Route exact path='/tasks/create' component={CreateTaskPageContainer} />
                //                             <Route exact path='/tasks/:id' component={TaskPageContainer} />
                //                             <Route
                //                                 exact
                //                                 path='/tasks/:tid/jobs/:jid'
                //                                 component={AnnotationPageContainer}
                //                             />
                //                             <Route exact path='/jobs' component={JobsPageComponent} />
                //                             <Route exact path='/cloudstorages' component={CloudStoragesPageComponent} />
                //                             <Route
                //                                 exact
                //                                 path='/cloudstorages/create'
                //                                 component={CreateCloudStoragePageComponent}
                //                             />
                //                             <Route
                //                                 exact
                //                                 path='/cloudstorages/update/:id'
                //                                 component={UpdateCloudStoragePageComponent}
                //                             />
                //                             <Route
                //                                 exact
                //                                 path='/organizations/create'
                //                                 component={CreateOrganizationComponent}
                //                             />
                //                             <Route exact path='/organization' component={OrganizationPage} />
                //                             {isModelPluginActive && (
                //                                 <Route exact path='/models' component={ModelsPageContainer} />
                //                             )}
                //                             <Redirect
                //                                 push
                //                                 to={new URLSearchParams(location.search).get('next') || '/projects'}
                //                             />
                //                         </Switch>
                //                     </GlobalHotKeys>
                //                     {/* eslint-disable-next-line */}
                //                     {/* <ExportDatasetModal /> */}
                //                     {/* eslint-disable-next-line */}
                //                     <a id='downloadAnchor' target='_blank' style={{ display: 'none' }} download />
                //                 </Layout.Content>
                //             </Layout>
                //         </GlobalErrorBoundary>
                //     </ConfigProvider>
                // );
                const isManager = isLeaderAndMore(user.roleType);
                const job = <Route exact path='/tasks/:tid/jobs/:jid' component={AnnotationPageContainer} />;
                if (isManager) {
                    return (
                        <ConfigProvider locale={locale}>
                            <GlobalErrorBoundary>
                                <Layout>
                                    {/* <Header /> */}
                                    {location.pathname && location.pathname.includes('jobs') ? null : <Header />}
                                    <Layout.Content style={{ height: '100%' }}>
                                        <ShortcutsDialog />
                                        <SettingsModal
                                            visible={settingsDialogShown}
                                            onClose={() => switchSettingsDialog(false)}
                                        />
                                        <GlobalHotKeys keyMap={subKeyMap} handlers={handlers}>
                                            <Switch>
                                                {/* <Route exact path='/main' component={MainComponent} /> */}
                                                <Route exact path='/projects' component={ProjectsPageComponent} />
                                                <Route
                                                    exact
                                                    path='/projects/create'
                                                    component={CreateProjectPageComponent}
                                                />
                                                <Route exact path='/projects/:id' component={ProjectPageComponent} />
                                                {/* <Route exact path='/tasks' component={TasksPageContainer} /> */}
                                                <Route exact path='/tasks/create' component={CreateTaskPageContainer} />
                                                <Route exact path='/tasks/:id' component={TaskPageContainer} />
                                                {/* <Route
                                                    exact
                                                    path='/tasks/:tid/jobs/:jid'
                                                    component={AnnotationPageContainer}
                                                /> */}
                                                {job}
                                                <Route exact path='/jobs' component={JobsPageComponent} />
                                                <Route
                                                    exact
                                                    path='/cloudstorages'
                                                    component={CloudStoragesPageComponent}
                                                />
                                                <Route
                                                    exact
                                                    path='/cloudstorages/create'
                                                    component={CreateCloudStoragePageComponent}
                                                />
                                                <Route
                                                    exact
                                                    path='/cloudstorages/update/:id'
                                                    component={UpdateCloudStoragePageComponent}
                                                />
                                                <Route
                                                    exact
                                                    path='/organizations/create'
                                                    component={CreateOrganizationComponent}
                                                />
                                                <Route exact path='/organization' component={OrganizationPage} />
                                                {isModelPluginActive && (
                                                    <Route exact path='/models' component={ModelsPageContainer} />
                                                )}
                                                <Redirect
                                                    push
                                                    to={new URLSearchParams(location.search).get('next') || '/projects'}
                                                />
                                            </Switch>
                                        </GlobalHotKeys>
                                        {/* eslint-disable-next-line */}
                                        {/* <ExportDatasetModal /> */}
                                        {/* eslint-disable-next-line */}
                                        <a id='downloadAnchor' target='_blank' style={{ display: 'none' }} download />
                                    </Layout.Content>
                                    {/* <Layout.Footer style={{ textAlign: 'center' }}>
                                        <Translation>{(t) => `${t('layouts.copyright')} © ${t('layouts.copyright_number')}`}</Translation>
                                    </Layout.Footer> */}
                                    {location.pathname && location.pathname.includes('jobs') ? null : (
                                        <Layout.Footer style={{ textAlign: 'center' }}>
                                            <Translation>{(t) => `${t('layouts.copyright')} © ${t('layouts.copyright_number')}`}</Translation>
                                        </Layout.Footer>
                                    )}
                                </Layout>
                            </GlobalErrorBoundary>
                        </ConfigProvider>
                    );
                } else {
                    return (
                        <ConfigProvider locale={locale}>
                            <GlobalErrorBoundary>
                                <Layout style={{ backgroundColor: '#747474' }}>
                                    {/* <Header /> */}
                                    {location.pathname && location.pathname.includes('jobs') ? null : (
                                        <Header style={headerStyle} />
                                    )}
                                    <Layout.Content>
                                        <ShortcutsDialog />
                                        <SettingsModal
                                            visible={settingsDialogShown}
                                            onClose={() => switchSettingsDialog(false)}
                                        />
                                        <GlobalHotKeys keyMap={subKeyMap} handlers={handlers}>
                                            <Switch>
                                                <Route exact path='/salesman' component={JobListComponent} />
                                                {job}
                                                <Redirect
                                                    push
                                                    to={new URLSearchParams(location.search).get('next') || '/salesman'}
                                                />
                                            </Switch>
                                        </GlobalHotKeys>
                                    </Layout.Content>
                                    {location.pathname && location.pathname.includes('jobs') ? null : (
                                        <Layout.Footer style={{ textAlign: 'center', backgroundColor: 'transparent', color: '#74f35599' }}>
                                            <Translation>{(t) => `${t('layouts.copyright')} © ${t('layouts.copyright_number')}`}</Translation>
                                        </Layout.Footer>
                                    )}
                                </Layout>
                            </GlobalErrorBoundary>
                        </ConfigProvider>
                    );
                }
            }

            return (
                <ConfigProvider locale={locale}>
                    <GlobalErrorBoundary>
                        <Switch>
                            {/* <Route exact path='/auth/register' component={RegisterPageContainer} /> */}
                            {/* <Route exact path='/auth/login' component={LoginPageContainer} /> */}
                            <Route exact path='/register' component={RegisterPageContainer} />
                            <Route exact path='/login' component={LoginPageContainer} />
                            <Route
                                exact
                                path='/auth/login-with-token/:sessionId/:token'
                                component={LoginWithTokenComponent}
                            />
                            <Route exact path='/auth/password/reset' component={ResetPasswordPageComponent} />
                            <Route
                                exact
                                path='/auth/password/reset/confirm'
                                component={ResetPasswordPageConfirmComponent}
                            />

                            <Route exact path='/auth/email-confirmation' component={EmailConfirmationPage} />

                            <Redirect
                                // to={location.pathname.length > 1 ?
                                // `/auth/login/?next=${location.pathname}` : '/auth/login'}
                                to={location.pathname.length > 1 ? `/login?next=${location.pathname}` : '/login'}
                            />
                        </Switch>
                    </GlobalErrorBoundary>
                </ConfigProvider>
            );
        }

        return <Spin size='large' className='cvat-spinner' />;
    }
}

export default withRouter(CVATApplication);
