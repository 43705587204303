/*
 * @Author: swxy
 * @Date: 2022-10-21 16:57:40
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { FC, useMemo } from 'react';
import { Modal, InputNumber, Typography, Divider, Space, Button, Row } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { trackChangeStartFrame, updateAnnotationsAsync } from 'actions/annotation-actions';

const { Text } = Typography;

interface Props {
    // onOK?: () => void;
    // onCancel?: () => void;
}

const TrackFrame: FC<Props> = ({ }) => {
    const { t } = useTranslation();
    const { perspective } = useSelector((state: CombinedState) => ({
        perspective: state.subAnnotation.perspective,
    }));
    const { clientID, frame, states, jobInstance } = useSelector((state: CombinedState) => ({
        clientID: state.annotation.trackFrame.clientID,
        frame: state.annotation.player.frame.number,
        states: perspective ? state.subAnnotation.states : state.annotation.annotations.states,
        jobInstance: state.annotation.job.instance,
    }));
    const [loading, setLoading] = useState<boolean>(false);
    const [changeFrame, setChangeFrame] = useState<number>(Math.max(jobInstance.startFrame, frame - 1));
    const dispatch = useDispatch();

    const object = useMemo(() => states.find((item) => {
        if (item.clientID === clientID && perspective) {
            return item.direction === perspective;
        } else if (item.clientID === clientID && !perspective) {
            return !item.direction;
        }
        return false
    }), [clientID, perspective]);

    const onCancel = () => {
        dispatch(trackChangeStartFrame());
    };

    const onOK = async () => {
        setLoading(true);
        object.frame = changeFrame;
        await dispatch(updateAnnotationsAsync([object]));
        onCancel();
        setLoading(false);
    };

    return (
        <>
            <Text>{t('workspace.side.object.context.trackFrame.detail')}</Text>
            <InputNumber min={Math.min(jobInstance.startFrame, frame)} max={frame} value={changeFrame} onChange={(changeFrame) => {
                setChangeFrame(changeFrame ?? frame)
            }} />
            <Text>{t('workspace.side.object.context.trackFrame.track')}</Text>
            <Divider />
            <Row justify='center'>
                <Space>
                    <Button onClick={onCancel}>{t('close')}</Button>
                    <Button type='primary' onClick={onOK} loading={loading}>
                        {t('ok')}
                    </Button>
                </Space>
            </Row>
        </>
    );
};

const TrackFrameConfirm = () => {
    const { clientID } = useSelector((state: CombinedState) => ({
        clientID: state.annotation.trackFrame.clientID,
    }));
    const { t } = useTranslation();

    return (
        <Modal
            title={t('workspace.side.object.context.trackFrame.title')}
            open={!!clientID}
            destroyOnClose
            footer={null}
        >
            <TrackFrame />
        </Modal>
    );
};

export default TrackFrameConfirm;
