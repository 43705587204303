/*
 * @Author: swxy
 * @Date: 2024-08-24 11:58:26
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

// import common from '../../aatp-web-common/dist/index.js';
import newFoo from '../../aatp-web-common/dist/Foo/index.js';
import newImageVerifyCode from '../../aatp-web-common/dist/ImageVerifyCode/index';
import newErrorImage from '../../aatp-web-common/dist/ErrorImage/index';
import newProjectionThumbnatilImages from '../../aatp-web-common/dist/ProjectionThumbnatilImages/index.js';

export const Foo = newFoo;
export const ImageVerifyCode = newImageVerifyCode;
export const ErrorImage = newErrorImage;
export const ProjectionThumbnatilImages = newProjectionThumbnatilImages;