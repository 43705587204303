// Copyright (C) 2019-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

const { Source, ObjectType } = require('./enums');

(() => {
    const PluginRegistry = require('./plugins');
    const { ArgumentError } = require('./exceptions');

    /**
     * Class representing a state of an object on a specific frame
     * @memberof module:API.cvat.classes
     */
    class ObjectState {
        /**
         * @param {Object} serialized - is an dictionary which contains
         * initial information about an ObjectState;
         * </br> Necessary fields: objectType, shapeType, frame, updated, group
         * </br> Optional fields: keyframes, clientID, serverID
         * </br> Optional fields which can be set later: points, zOrder, outside,
         * occluded, hidden, attributes, lock, label, color, keyframe, source
         */
        constructor(serialized) {
            const data = {
                label: null,
                attributes: {},
                descriptions: [],

                points: null,
                pointOccludeds: null,
                rotation: 0,
                outside: null,
                occluded: null,
                keyframe: null,

                zOrder: null,
                lock: null,
                color: null,
                hidden: null,
                pinned: null,
                source: Source.MANUAL,
                keyframes: serialized.keyframes,
                group: serialized.group,
                updated: serialized.updated,

                clientID: serialized.clientID,
                serverID: serialized.serverID,

                frame: serialized.frame,
                objectType: serialized.objectType,
                shapeType: serialized.shapeType,
                updateFlags: {},
                pointsLine: null,
                relation: serialized.relation || {},
                direction: '',
                parentID: undefined, // clientID
                cameraName: undefined,

                elements: [],
            };

            // Shows whether any properties updated since last reset() or interpolation
            Object.defineProperty(data.updateFlags, 'reset', {
                value: function reset() {
                    this.label = false;
                    this.attributes = false;
                    this.descriptions = false;

                    this.points = false;
                    this.pointOccludeds = false;
                    this.outside = false;
                    this.occluded = false;
                    this.keyframe = false;

                    this.zOrder = false;
                    this.pinned = false;
                    this.lock = false;
                    this.color = false;
                    this.hidden = false;
                    this.frame = false;

                    this.pointsLine = false;
                    this.relation = false;

                    this.parentID = false;

                    this.elements = false;

                    return reset;
                },
                writable: false,
                enumerable: false,
            });

            Object.defineProperties(
                this,
                Object.freeze({
                    // Internal property. We don't need document it.
                    updateFlags: {
                        get: () => data.updateFlags,
                    },
                    frame: {
                        /**
                         * @name frame
                         * @type {integer}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @readonly
                         * @instance
                         */
                        get: () => data.frame,
                        set: (frame) => {
                            if (data.objectType === ObjectType.TRACK) {
                                // 连续帧可以更改起始帧
                                data.updateFlags.frame = true;
                                data.frame = frame;
                            }
                        },
                    },
                    objectType: {
                        /**
                         * @name objectType
                         * @type {module:API.cvat.enums.ObjectType}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @readonly
                         * @instance
                         */
                        get: () => data.objectType,
                    },
                    shapeType: {
                        /**
                         * @name shapeType
                         * @type {module:API.cvat.enums.ObjectShape}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @readonly
                         * @instance
                         */
                        get: () => data.shapeType,
                    },
                    source: {
                        /**
                         * @name source
                         * @type {module:API.cvat.enums.Source}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @readonly
                         * @instance
                         */
                        get: () => data.source,
                    },
                    clientID: {
                        /**
                         * @name clientID
                         * @type {integer}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @readonly
                         * @instance
                         */
                        get: () => data.clientID,
                    },
                    serverID: {
                        /**
                         * @name serverID
                         * @type {integer}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @readonly
                         * @instance
                         */
                        get: () => data.serverID,
                    },
                    label: {
                        /**
                         * @name shape
                         * @type {module:API.cvat.classes.Label}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => data.label,
                        set: (labelInstance) => {
                            data.updateFlags.label = true;
                            data.label = labelInstance;
                        },
                    },
                    color: {
                        /**
                         * @name color
                         * @type {string}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => data.color,
                        set: (color) => {
                            data.updateFlags.color = true;
                            data.color = color;
                        },
                    },
                    hidden: {
                        /**
                         * @name hidden
                         * @type {boolean}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => data.hidden,
                        set: (hidden) => {
                            data.updateFlags.hidden = true;
                            data.hidden = hidden;
                        },
                    },
                    points: {
                        /**
                         * @name points
                         * @type {number[]}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @throws {module:API.cvat.exceptions.ArgumentError}
                         * @instance
                         */
                        get: () => data.points,
                        set: (points) => {
                            if (Array.isArray(points)) {
                                data.updateFlags.points = true;
                                data.points = [...points];
                            } else {
                                throw new ArgumentError(
                                    'Points are expected to be an array ' +
                                        `but got ${
                                            typeof points === 'object' ? points.constructor.name : typeof points
                                        }`,
                                );
                            }
                        },
                    },
                    pointOccludeds: {
                        /**
                         * @name pointOccludeds
                         * @type {number[]}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @throws {module:API.cvat.exceptions.ArgumentError}
                         * @instance
                         */
                        get: () => data.pointOccludeds,
                        set: (pointOccludeds) => {
                            if (Array.isArray(pointOccludeds)) {
                                data.updateFlags.pointOccludeds = true;
                                data.pointOccludeds = [...pointOccludeds];
                            } else {
                                throw new ArgumentError(
                                    'Points are expected to be an array ' +
                                        `but got ${
                                            typeof pointOccludeds === 'object' ? pointOccludeds.constructor.name : typeof pointOccludeds
                                        }`,
                                );
                            }
                        },
                    },
                    rotation: {
                        /**
                         * @name rotation
                         * @type {number} angle measured by degrees
                         * @memberof module:API.cvat.classes.ObjectState
                         * @throws {module:API.cvat.exceptions.ArgumentError}
                         * @instance
                         */
                        get: () => data.rotation,
                        set: (rotation) => {
                            if (typeof rotation === 'number') {
                                data.updateFlags.points = true;
                                data.rotation = rotation;
                            } else {
                                throw new ArgumentError(
                                    `Rotation is expected to be a number, but got ${
                                        typeof rotation === 'object' ? rotation.constructor.name : typeof points
                                    }`,
                                );
                            }
                        },
                    },
                    group: {
                        /**
                         * Object with short group info { color, id }
                         * @name group
                         * @type {object}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         * @readonly
                         */
                        get: () => data.group,
                    },
                    zOrder: {
                        /**
                         * @name zOrder
                         * @type {integer | null}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => data.zOrder,
                        set: (zOrder) => {
                            data.updateFlags.zOrder = true;
                            data.zOrder = zOrder;
                        },
                    },
                    outside: {
                        /**
                         * @name outside
                         * @type {boolean}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => data.outside,
                        set: (outside) => {
                            data.updateFlags.outside = true;
                            data.outside = outside;
                        },
                    },
                    keyframe: {
                        /**
                         * @name keyframe
                         * @type {boolean}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => data.keyframe,
                        set: (keyframe) => {
                            data.updateFlags.keyframe = true;
                            data.keyframe = keyframe;
                        },
                    },
                    keyframes: {
                        /**
                         * Object of keyframes { first, prev, next, last }
                         * @name keyframes
                         * @type {object | null}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @readonly
                         * @instance
                         */
                        get: () => {
                            if (typeof data.keyframes === 'object') {
                                return { ...data.keyframes };
                            }

                            return null;
                        },
                    },
                    occluded: {
                        /**
                         * @name occluded
                         * @type {boolean}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => data.occluded,
                        set: (occluded) => {
                            data.updateFlags.occluded = true;
                            data.occluded = occluded;
                        },
                    },
                    lock: {
                        /**
                         * @name lock
                         * @type {boolean}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => data.lock,
                        set: (lock) => {
                            data.updateFlags.lock = true;
                            data.lock = lock;
                        },
                    },
                    pinned: {
                        /**
                         * @name pinned
                         * @type {boolean | null}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => {
                            if (typeof data.pinned === 'boolean') {
                                return data.pinned;
                            }

                            return null;
                        },
                        set: (pinned) => {
                            data.updateFlags.pinned = true;
                            data.pinned = pinned;
                        },
                    },
                    updated: {
                        /**
                         * Timestamp of the latest updated of the object
                         * @name updated
                         * @type {number}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         * @readonly
                         */
                        get: () => data.updated,
                    },
                    attributes: {
                        /**
                         * Object is id:value pairs where "id" is an integer
                         * attribute identifier and "value" is an attribute value
                         * @name attributes
                         * @type {Object}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @throws {module:API.cvat.exceptions.ArgumentError}
                         * @instance
                         */
                        get: () => data.attributes,
                        set: (attributes) => {
                            if (typeof attributes !== 'object') {
                                // throw new ArgumentError(
                                //     'Attributes are expected to be an object ' +
                                //         `but got ${
                                //             typeof attributes === 'object' ?
                                //                 attributes.constructor.name :
                                //                 typeof attributes
                                //         }`,
                                // );
                                throw new ArgumentError(
                                    '属性需要是一个对象，' +
                                        `但是现在的类型是 ${
                                            typeof attributes === 'object' ?
                                                attributes.constructor.name :
                                                typeof attributes
                                        }`,
                                );
                            }

                            for (const attrID of Object.keys(attributes)) {
                                data.updateFlags.attributes = true;
                                data.attributes[attrID] = attributes[attrID];
                            }

                            // if (Object.values(attributes).every((att) => typeof att === 'object')) {
                            //     // 传入的是对象
                            //     for (const attrID of Object.keys(attributes)) {
                            //         data.updateFlags.attributes = true;
                            //         data.attributes[attrID] = attributes[attrID];
                            //     }
                            // } else if (Object.values(attributes).every((att) => typeof att === 'string')) {
                            //     // 传入的是字符串
                            //     for (const attrID of Object.keys(attributes)) {
                            //         data.updateFlags.attributes = true;
                            //         const old = data.attributes[attrID] || {};
                            //         data.attributes[attrID] = {
                            //             ...old,
                            //             specId: +attrID,
                            //             value: attributes[attrID],
                            //         };
                            //     }
                            // }
                        },
                    },
                    descriptions: {
                        /**
                         * Additional text information displayed on canvas
                         * @name descripttions
                         * @type {string[]}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @throws {module:API.cvat.exceptions.ArgumentError}
                         * @instance
                         */
                        get: () => [...data.descriptions],
                        set: (descriptions) => {
                            if (
                                !Array.isArray(descriptions) ||
                                descriptions.some((description) => typeof description !== 'string')
                            ) {
                                throw new ArgumentError(
                                    `Descriptions are expected to be an array of strings but got ${data.descriptions}`,
                                );
                            }

                            data.updateFlags.descriptions = true;
                            data.descriptions = [...descriptions];
                        },
                    },
                    pointsLine: {
                        /**
                         * @name pointsLine
                         * @type {number}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @instance
                         */
                        get: () => +data.pointsLine,
                        set: (pointsLine) => {
                            data.updateFlags.pointsLine = true;
                            data.pointsLine = +pointsLine;
                        },
                    },
                    relation: {
                        /**
                         * 关系对象
                         * 目前只有父子关系
                         * @name relation
                         * @type {Object}
                         * @memberof module:API.cvat.classes.ObjectState
                         * @throws {module:API.cvat.exceptions.ArgumentError}
                         * @example { parent: ObjectState, children:[ObjectState,...], id: 1234 }
                         * @instance
                         */
                        get: () => data.relation,
                        set: (relation) => {
                            if (typeof relation !== 'object') {
                                throw new ArgumentError(
                                    'relation需要一个对象 ' +
                                         `但是目前是 ${
                                             typeof relation === 'object' ?
                                                 relation.constructor.name :
                                                 typeof relation
                                         }`,
                                );
                            }
                            data.relation = { ...relation };
                            data.updateFlags.relation = true;
                        },
                    },
                    direction: {
                        get: () => data.direction,
                        set: (direction) => {
                            if (typeof direction !== 'string') {
                                throw new ArgumentError(
                                    'direction需要一个字符串 ' +
                                         `但是目前是 ${
                                             typeof direction === 'string' ?
                                                 direction.constructor.name :
                                                 typeof direction
                                         }`,
                                );
                            }
                            data.direction = direction;
                        },
                    },
                    parentID: {
                        get: () => data.parentID,
                        set: (parentID) => {
                            if (typeof parentID !== 'number') {
                                throw new ArgumentError(
                                    'direction需要一个数字 ' +
                                         `但是目前是 ${
                                             typeof parentID === 'number' ?
                                                 parentID.constructor.name :
                                                 typeof parentID
                                         }`,
                                );
                            }
                            data.parentID = parentID;
                            data.updateFlags.parentID = true;
                        },
                    },
                    cameraName: {
                        get: () => data.cameraName,
                        set: (cameraName) => {
                            if (typeof cameraName !== 'string') {
                                throw new ArgumentError(
                                    'direction需要一个字符串 ' +
                                         `但是目前是 ${
                                             typeof cameraName === 'string' ?
                                                 cameraName.constructor.name :
                                                 typeof cameraName
                                         }`,
                                );
                            }
                            data.cameraName = cameraName;
                            data.updateFlags.cameraName = true;
                        },
                    },
                    elements: {
                        get: () => (typeof data.elements === 'string' ? JSON.parse(data.elements) : data.elements),
                        set: (elements) => {
                            if (Array.isArray(elements) && elements.some((sub) => typeof sub !== 'object')) {
                                throw new ArgumentError(
                                    'elements需要一个对象数组 ' +
                                         `但是目前是 ${
                                             typeof elements === 'object' ?
                                                 elements.constructor.name :
                                                 typeof elements
                                         }`,
                                );
                            }
                            data.elements = elements;
                            data.updateFlags.elements = true;
                        },
                    },
                }),
            );

            this.label = serialized.label;
            this.lock = serialized.lock;

            if ([Source.MANUAL, Source.AUTO].includes(serialized.source)) {
                data.source = serialized.source;
            }
            if (typeof serialized.zOrder === 'number') {
                this.zOrder = serialized.zOrder;
            }
            if (typeof serialized.occluded === 'boolean') {
                this.occluded = serialized.occluded;
            }
            if (typeof serialized.outside === 'boolean') {
                this.outside = serialized.outside;
            }
            if (typeof serialized.keyframe === 'boolean') {
                this.keyframe = serialized.keyframe;
            }
            if (typeof serialized.pinned === 'boolean') {
                this.pinned = serialized.pinned;
            }
            if (typeof serialized.hidden === 'boolean') {
                this.hidden = serialized.hidden;
            }
            if (typeof serialized.color === 'string') {
                this.color = serialized.color;
            }
            if (typeof serialized.rotation === 'number') {
                this.rotation = serialized.rotation;
            }
            if (Array.isArray(serialized.points)) {
                this.points = serialized.points;
            }
            if (
                Array.isArray(serialized.descriptions) &&
                serialized.descriptions.every((desc) => typeof desc === 'string')
            ) {
                this.descriptions = serialized.descriptions;
            }
            if (typeof serialized.attributes === 'object') {
                this.attributes = serialized.attributes;
            }
            if (typeof serialized.pointsLine === 'number') {
                this.pointsLine = serialized.pointsLine;
            }
            if (typeof serialized.relation === 'object') {
                this.relation = serialized.relation;
            }
            if (typeof serialized.frame === 'number') {
                this.frame = serialized.frame;
            }
            if (typeof serialized.direction === 'string') {
                this.direction = serialized.direction;
            }
            if (typeof serialized.parentID === 'number') {
                this.parentID = serialized.parentID;
            }
            if (typeof serialized.cameraName === 'string') {
                this.cameraName = serialized.cameraName;
            }
            if (Array.isArray(serialized.elements) && serialized.elements.every((sub) => typeof sub === 'object')) {
                this.elements = serialized.elements;
            }
            if (Array.isArray(serialized.pointOccludeds)) {
                this.pointOccludeds = serialized.pointOccludeds;
            }

            data.updateFlags.reset();
        }

        toJSON() {
            return {
                clientID: this.clientID,

                label: this.label,
                // attributes: Object.entries(this.attributes).map(([key, value]) => ({ specId: +key, value })),
                // attributes: this.attributes,
                // elements: this.elements,
                // points: this.points,
                frame: this.frame,
                pointsLine: this.pointsLine,
                objectType: this.objectType,
                shapeType: this.shapeType,

                source: this.source,
                zOrder: this.zOrder,
                lock: this.lock,
                occluded: this.occluded,
                outside: this.outside,
                keyframe: this.keyframe,
                pinned: this.pinned,
                hidden: this.hidden,
                color: this.color,
                rotation: this.rotation,
                descriptions: this.descriptions,
                relation: this.relation,
                direction: this.direction,
                parentID: this.parentID,
                elements: JSON.parse(JSON.stringify(this.elements || [])),
                attributes: JSON.parse(JSON.stringify(this.attributes || {})),
                points: this.points ? [...this.points] : undefined,
            };
        }

        /**
         * Method saves/updates an object state in a collection
         * @method save
         * @memberof module:API.cvat.classes.ObjectState
         * @readonly
         * @instance
         * @async
         * @throws {module:API.cvat.exceptions.PluginError}
         * @throws {module:API.cvat.exceptions.ArgumentError}
         * @returns {module:API.cvat.classes.ObjectState} updated state of an object
         */
        async save(dimension) {
            const result = await PluginRegistry.apiWrapper.call(this, ObjectState.prototype.save, dimension);
            return result;
        }

        /**
         * Method deletes an object from a collection
         * @method delete
         * @memberof module:API.cvat.classes.ObjectState
         * @readonly
         * @instance
         * @param {integer} frame current frame number
         * @param {boolean} [force=false] delete object even if it is locked
         * @async
         * @returns {boolean} true if object has been deleted
         * @throws {module:API.cvat.exceptions.PluginError}
         * @throws {module:API.cvat.exceptions.ArgumentError}
         */
        async delete(frame, force = false) {
            const result = await PluginRegistry.apiWrapper.call(this, ObjectState.prototype.delete, frame, force);
            return result;
        }

        // /**
        //  * Method deletes an object from a collection
        //  * @method delete
        //  * @memberof module:API.cvat.classes.ObjectState
        //  * @readonly
        //  * @instance
        //  * @param {integer} frame current frame number
        //  * @param {boolean} [force=false] delete object even if it is locked
        //  * @async
        //  * @returns {boolean} true if object has been deleted
        //  * @throws {module:API.cvat.exceptions.PluginError}
        //  * @throws {module:API.cvat.exceptions.ArgumentError}
        //  */
        // async export(frame, type = 'CX') {
        //     const result = await PluginRegistry.apiWrapper.call(this, ObjectState.prototype.export, frame, type);
        //     return result;
        // }
    }

    // Updates element in collection which contains it
    ObjectState.prototype.save.implementation = function (dimension) {
        if (this.__internal && this.__internal.save) {
            return this.__internal.save(dimension);
        }

        return this;
    };

    // Delete element from a collection which contains it
    ObjectState.prototype.delete.implementation = function (frame, force) {
        if (this.__internal && this.__internal.delete) {
            if (!Number.isInteger(+frame) || +frame < 0) {
                throw new ArgumentError('Frame argument must be a non negative integer');
            }

            return this.__internal.delete(frame, force);
        }

        return false;
    };

    // Delete element from a collection which contains it
    // ObjectState.prototype.export.implementation = function (frame, type) {
    //     return this.__internal.export(frame, type);
    // };

    module.exports = ObjectState;
})();
