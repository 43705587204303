/*
 * @Author: swxy
 * @Date: 2022-09-14 15:03:21
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
     'hotKey.rotateUp.name': 'カメラアップ',
     'hotKey.rotateUp.description': 'カメラの画角の中心をドットとして、カメラを上に回転させます',
     'hotKey.rotateDown.name': 'カメラダウン',
     'hotKey.rotateDown.description': 'カメラの画角の中心をドットとして、カメラを下に回転させます',
     'hotKey.rotateLeft.name': 'カメラを左に向ける',
     'hotKey.rotateLeft.description': 'カメラの画角の中心をドットとして、カメラを左に回転させます',
     'hotKey.rotateRight.name': 'カメラを右に向ける',
     'hotKey.rotateRight.description': 'カメラの画角の中心をドットとして、カメラを右に回転させます',
     'hotKey.moveDown.name': 'カメラダウン',
     'hotKey.moveDown.description': 'カメラを下に移動',
     'hotKey.moveUp.name': 'カメラを上に移動',
     'hotKey.moveUp.description': 'カメラを上に移動',
     'hotKey.moveLeft.name': 'カメラ左',
     'hotKey.moveLeft.description': 'カメラを左に移動',
     'hotKey.moveRight.name': 'カメラを右に移動',
     'hotKey.moveRight.description': 'カメラを右に移動',
     'hotKey.zoomIn.name': 'カメラのズームイン',
     'hotKey.zoomIn.description': '拡大を開始',
     'hotKey.zoomOut.name': 'カメラのズームアウト',
     'hotKey.zoomOut.description': 'ズームアウトを開始',
     'hotKey.moveCam.name': '3D がカメラ移動モードに入ります',
     'hotKey.moveCam.description': '現在の 3D 視点を移動',
     'hotKey.3dShape.name': '3Dボックスを追加',
     'hotKey.3dShape.description': '3Dボックスを追加',
     'hotKey.selectAll.name': '現在のフレーム内のすべてのオブジェクト',
     'hotKey.selectAll.description': '現在のフレーム内のすべてのオブジェクトを選択',
     'hotKey.panelModel.name': 'パネル リスト モード',
     'hotKey.panelModel.description': 'スイッチ リストが単一ディスプレイか複数ディスプレイか',
     'hotKey.lineV.name': '垂直分割線',
     'hotKey.lineV.description': '選択した長方形オブジェクトに、縦の分割線を追加します。 ',
     'hotKey.lineH.name': '水平分割線',
     'hotKey.lineH.description': '選択した長方形オブジェクトに水平分割線を追加します。 ',
     'hotKey.deletePoints/Lines.name': '現在のポイント/ラインを削除',
     'hotKey.deletePoints/Lines.description': 'ポイント/ライン オブジェクトが選択されている場合、現在のオブジェクト上でマウスがクリックしているポイントを削除します。オブジェクトは変更されません',
     'hotKey.cancelRelation.name': '関係をキャンセル',
     'hotKey.cancelRelation.description': '選択したオブジェクトの関連付けを解除します。親オブジェクトを選択すると、すべての子オブジェクトとの関連付けが解除されます。 ',
     'hotKey.nextObject.name': '次のオブジェクトを選択',
     'hotKey.nextObject.description': '現在のフレーム、現在アクティブなオブジェクトの次のオブジェクトを選択',
     'hotKey.prevObject.name': '前のオブジェクトを選択',
     'hotKey.prevObject.description': '現在のフレーム、現在アクティブなオブジェクトの前のオブジェクトを選択',

     'hotKey.rotateClockwise.name': '時計回りに 90°回転',
     'hotKey.rotateAntiClockwise.name': '反時計回りに 90 度回転',
     'hotKey.rotateClockwise.description': '現在選択されているオブジェクトは、上部の視点に基づいて時計回りに 90 度回転します',
     'hotKey.rotateAntiClockwise.description': '現在選択されているオブジェクトは、上部の視点に基づいて、反時計回りに 90° 回転します',

     'hotKey.updateObjectPosition.allowLeft': '現在選択されているオブジェクトを左に移動します',
     'hotKey.updateObjectPosition.allowLeftDetail': '現在フォーカスされている 3 つのビューで、現在のビューの方向に従ってオブジェクトを左に移動させます',
     'hotKey.updateObjectPosition.allowRight': '現在選択されているオブジェクトを右に移動します',
     'hotKey.updateObjectPosition.allowRightDetail': '現在フォーカスされている 3 つのビューで、オブジェクトを現在のビューの方向に右に移動させます',
     'hotKey.updateObjectPosition.allowUp': '現在選択されているオブジェクトが上に移動します',
     'hotKey.updateObjectPosition.allowUpDetail': '現在フォーカスされている 3 つのビューで、オブジェクトを現在のビューの方向に上に移動させます',
     'hotKey.updateObjectPosition.allowDown': '現在選択されているオブジェクトを下に移動',
     'hotKey.updateObjectPosition.allowDownDetail': '現在フォーカスされている 3 つのビューで、オブジェクトを現在のビューの方向に下に移動させます',
};
