/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2023-05-11 18:07:28
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
// import Popover from 'antd/lib/popover';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';

import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'canvas3d-wrapper';
import { TagIcon } from 'icons';

// import SetupTagPopoverContainer from
// 'containers/annotation-page/standard-workspace/controls-side-bar/setup-tag-popover';
// import withVisibilityHandling from './handle-popover-visibility';

export interface Props {
    canvasInstance?: Canvas | Canvas3d;
    isDrawing: boolean;
    disabled?: boolean;
    onClick(): void;
}

// const CustomPopover = withVisibilityHandling(Popover, 'setup-tag');
function SetupTagControl(props: Props): JSX.Element {
    const {
        // isDrawing,
        disabled, onClick,
    } = props;
    // const dynamicPopoverProps = isDrawing ?
    //     {
    //         overlayStyle: {
    //             display: 'none',
    //         },
    //     } :
    //     {};

    const click = (): void => {
        onClick();
    };
    return disabled ? (
        <Icon className='cvat-setup-tag-control cvat-disabled-canvas-control' component={TagIcon} />
    ) : (
        // <CustomPopover {...dynamicPopoverProps} placement='right' content={<SetupTagPopoverContainer />}>
        //     <Icon className='cvat-setup-tag-control' component={TagIcon} />
        // </CustomPopover>
        <Tooltip title='图片信息标注' placement='rightTop'>
            <Icon onClick={click} className='cvat-setup-tag-control' component={TagIcon} />
        </Tooltip>
    );
}

export default React.memo(SetupTagControl);
