/*
 * @Author: swxy
 * @Date: 2022-12-13 10:59:28
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
export default {
    test: 'Internationalization test',
    language: '中文',
};
