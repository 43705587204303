/*
 * @Author: swxy
 * @Date: 2022-07-30 16:08:55
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { FC, useEffect, useState } from 'react';
import { Row, Typography, Divider, Spin, Result, Popconfirm, Input, message, Radio, Space, Button, Modal, ModalProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { CX_autoAnntationAttr, toAttrs } from 'business/customs/CX';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import Job from 'business/objects/job';
import { AnnotateType, AutolabelStatus, BackfillStatus, ProjectType } from 'utils/ConstType';
import { api_sendExportJobAutoAttr, api_updateJobAutoAttrStatus } from 'service/job/job';
import { Label } from 'business/objects/label';
import ObjectState from 'business/objects/objectState';
import { updateAnnotationsAsync } from 'actions/annotation-actions';


interface Props {
    onCancel?: () => void;
    onSubmit?: () => void;
}

const { Text } = Typography;

const AutoAttrAntationComponent = ({ onCancel }: Props): JSX.Element => {
    const jobInstance: Job = useSelector((state: CombinedState) => state.annotation.job.instance);
    const [loading, setLoading] = useState<boolean>(false);
    const { t } = useTranslation();

    const onSubmit = async () => {
        setLoading(true);
        const result = await CX_autoAnntationAttr(true);

        const json = {
            jobId: jobInstance.id,
            annotateType: AnnotateType.box3d,
            frames: (result as any[]).map((data) => {
                return {
                    frame_id: data.frame_id,
                    annotated_info: {
                        only_3d_city_object_detection_annotated_info: {
                            ...data.annotated_info,
                        }
                    }
                }
            }),
        };

        const data = await api_sendExportJobAutoAttr(json)
        setLoading(false);
        if (data.success) {
            Modal.destroyAll();
            Modal.success({
                title: t('workspace.side.attr.auto.submitToCustom'),
                content: t('workspace.side.attr.auto.submitToCustom.help'),
                onOk: () => {
                    location.reload();
                }
            });
        }
    }

    const disabled = typeof jobInstance.autoAttrInfo?.autolabelStatus === 'number' && jobInstance.autoAttrInfo?.autolabelStatus < 5;

    const render = (
        <Spin spinning={loading} tip={t('workspace.top.modal.submit_loading_tip')}>
            <Row justify='center'>
                {typeof jobInstance.autoAttrInfo?.autolabelStatus === 'number' ? t('workspace.side.attr.auto.result') : t('workspace.side.attr.auto.help')}
                {jobInstance.autoAttrInfo?.autolabelStatus_}
            </Row>
            <Divider />
            <Row justify='center'>
                <Space>
                    <Popconfirm title={<Text>{t('workspace.side.attr.auto.submit_tip')}</Text>} onConfirm={onSubmit} disabled={disabled}>
                        <Button type='primary' disabled={disabled}>{t('workspace.side.attr.auto.submit')}</Button>
                    </Popconfirm>
                    <Button onClick={onCancel} type='primary' danger>{t('close')}</Button>
                </Space>
            </Row>
        </Spin>
    );

    return <>{render}</>;
};


interface Props extends ModalProps {

}
const AutoAttrAntationModal: FC<Props> = (props) => {
    const { t } = useTranslation();

    const [] = useState();
    const jobInstance: Job = useSelector((state: CombinedState) => state.annotation.job.instance);
    const labels: Label[] = useSelector((state: CombinedState) => state.annotation.job.labels);
    const dispatch = useDispatch();

    const saveAnnotation = async (frame: number, objectAttrs: Record<number, Record<number, string>>): Promise<ObjectState[]> => {
        const states: ObjectState[] = await jobInstance.frames.get(frame);
        const updateStates = states.filter(state => {
            if (objectAttrs[state.serverID!] && Object.keys(objectAttrs[state.serverID!])?.length) {
                const attrs = objectAttrs[state.serverID!];
                if (attrs['0']) {
                    const newLabel = labels.find(l => l.labelId === +attrs['0']);
                    if (newLabel) {
                        state.label = newLabel;
                    }
                    delete attrs['0'];
                }
                state.attributes = attrs
                return true;
            }
            return false;
        }) || [];

        return updateStates;
    }

    const saveAnnotationAttrs = async () => {

        // 已成功，且回填状态不为已完毕
        // 开始回填
        try {
            const { jsonLabel, annotateType } = jobInstance?.autoAttrInfo;
            if (!jsonLabel) {
                throw new Error('error.autoAttrAnntation.noData');
            }
            const json = JSON.parse(jsonLabel);

            const attrsByTrackIdByFrame = toAttrs(json);

            const promises: Promise<ObjectState[]>[] = [];
            Object.keys(attrsByTrackIdByFrame).forEach((frame) => {
                promises.push(saveAnnotation(+frame, attrsByTrackIdByFrame[+frame]));
            })

            const updateObjects: ObjectState[] = (await Promise.all(promises)).flat();
            dispatch(updateAnnotationsAsync(updateObjects));

            await api_updateJobAutoAttrStatus({ jobId: jobInstance.id, annotateType, backfillStatus: BackfillStatus.finish });
            Modal.destroyAll();
            // 还没有成功
            Modal.success({
                title: t('workspace.side.attr.auto.reback'),
                content: t('workspace.side.attr.auto.reback.help'),
                onOk: () => {
                }
            });
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        if (typeof jobInstance?.autoAttrInfo?.autolabelStatus === 'number') {
            if (jobInstance?.autoAttrInfo?.autolabelStatus < AutolabelStatus.result) {
                // 还没有成功
                Modal.info({
                    title: t('workspace.side.attr.auto.title'),
                    content: t('workspace.side.attr.auto.content'),
                    onOk: () => {
                    }
                });
            } else if (
                jobInstance?.autoAttrInfo?.autolabelStatus >= AutolabelStatus.result
                && jobInstance?.autoAttrInfo?.autolabelStatus !== AutolabelStatus.fail &&
                jobInstance?.autoAttrInfo?.backfillStatus !== BackfillStatus.finish) {
                Modal.info({
                    title: t('workspace.side.attr.auto.start'),
                    content: t('workspace.side.attr.auto.start.help'),
                    okButtonProps: { disabled: true }
                });
                saveAnnotationAttrs();
            }
        }


    }, []);

    if (!jobInstance) {
        return <></>
    }

    return <Modal {...props}>
        <AutoAttrAntationComponent onCancel={props.onCancel} />
    </Modal>
}

export default AutoAttrAntationModal;