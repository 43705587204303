/*
 * @Author: swxy
 * @Date: 2023-04-25 17:17:12
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Button, ButtonProps, Col, Input, InputProps, Row } from 'antd';


export interface ValidCodeProps extends InputProps {
    // value: string;
    // onChange?(value: string):void;
    text?: React.ReactNode;
    maxCountdown?: number;

    buttonProps?: ButtonProps;

    onLoadCode?(): Promise<any> | any;
}

const maxBufferTime = 60

const ValidCode: FC<ValidCodeProps> = ({
    text,
    maxCountdown = maxBufferTime,
    onLoadCode,
    buttonProps = {},
    ...props
}) => {
    const [count, setCount] = useState<number>(0);
    const [loading, setLoading] = useState(false);
    const timer = useRef<NodeJS.Timer>();

    const clearTimer = () => {
        if (timer.current) {
            clearInterval(timer.current)
        }
    }

    const onClick = async () => {
        setLoading(true);
        try {
            await onLoadCode?.();
            clearTimer();
            setCount(1);
            timer.current = setInterval(() => {
                setCount(old => old + 1)
            }, 1000);
        } catch (error) {
            setCount(maxCountdown);
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        if (count >= maxCountdown) {
            setCount(0);
            clearTimer();
        }
    }, [count]);

    const countdown = count > 0;

    return <Row>
        <Input style={{ width: 'calc(100% - 100px)' }} {...props} />
        {text && <Button
            type='primary'
            style={{ width: '100px' }}
            onClick={onClick}
            loading={loading}
            disabled={loading || countdown}
            {...buttonProps}
        >

            {countdown ? maxCountdown - count : text}
        </Button>}
    </Row>
}

export default ValidCode;