// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import {
    CaretDownOutlined,
    CaretUpFilled,
    EyeInvisibleFilled,
    EyeOutlined,
    LockFilled,
    UnlockOutlined,
    AimOutlined,
    DiffOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd/lib/grid';

// import StatesOrderingSelector from
// 'components/annotation-page/standard-workspace/objects-side-bar/states-ordering-selector';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useTranslation } from 'react-i18next';
import Job from 'business/objects/job';
import { ProjectDumpType, ProjectType } from 'utils/ConstType';
// import { StatesOrdering } from 'reducers/interfaces';

interface Props {
    jobInstance: Job;
    readonly: boolean;
    statesHidden: boolean;
    statesLocked: boolean;
    statesCollapsed: boolean;
    // statesOrdering: StatesOrdering;
    switchLockAllShortcut: string;
    switchHiddenAllShortcut: string;
    isOnlyShowSelectStates: boolean;
    // changeStatesOrdering(value: StatesOrdering): void;
    lockAllStates(): void;
    unlockAllStates(): void;
    collapseAllStates(): void;
    expandAllStates(): void;
    hideAllStates(): void;
    showAllStates(): void;
    onlyShowSelectStates(): void;

    onChangeShowMultChangeAttr(): void;
}

interface sProps extends Props {
    title: string;
}

function LockAllSwitcher(props: sProps): JSX.Element {
    const { title, statesLocked, switchLockAllShortcut, unlockAllStates, lockAllStates } = props;
    return (
        <Col span={2}>
            {/* <CVATTooltip title={`Switch lock property for all ${switchLockAllShortcut}`}> */}
            <CVATTooltip title={`${title} ${switchLockAllShortcut}`}>
                {statesLocked ? <LockFilled onClick={unlockAllStates} /> : <UnlockOutlined onClick={lockAllStates} />}
            </CVATTooltip>
        </Col>
    );
}

function HideAllSwitcher(props: sProps): JSX.Element {
    const { title, statesHidden, switchHiddenAllShortcut, showAllStates, hideAllStates } = props;
    return (
        <Col span={2}>
            {/* <CVATTooltip title={`Switch hidden property for all ${switchHiddenAllShortcut}`}> */}
            <CVATTooltip title={`${title} ${switchHiddenAllShortcut}`}>
                {statesHidden ? (
                    <EyeInvisibleFilled onClick={showAllStates} />
                ) : (
                    <EyeOutlined onClick={hideAllStates} />
                )}
            </CVATTooltip>
        </Col>
    );
}

function CollapseAllSwitcher(props: sProps): JSX.Element {
    const { title, statesCollapsed, expandAllStates, collapseAllStates } = props;
    return (
        <Col span={2}>
            {/* <CVATTooltip title='Expand/collapse all'> */}
            <CVATTooltip title={title}>
                {statesCollapsed ? (
                    <CaretDownOutlined onClick={expandAllStates} />
                ) : (
                    <CaretUpFilled onClick={collapseAllStates} />
                )}
            </CVATTooltip>
        </Col>
    );
}

function OnlyShowActivitySwitcher(props: sProps): JSX.Element {
    const { title, onlyShowSelectStates, isOnlyShowSelectStates } = props;
    return (
        <Col span={2} className={isOnlyShowSelectStates ? 'cvat-objects-sidebar-states-header-icon-rows-select' : ''}>
            {/* <CVATTooltip title='only show activity'> */}
            <CVATTooltip title={title}>
                <AimOutlined onClick={onlyShowSelectStates} />
            </CVATTooltip>
        </Col>
    );
}

const ChangeMultAttributeSwitcher = ({ jobInstance, title, onChangeShowMultChangeAttr }: { jobInstance: Job; title: string; onChangeShowMultChangeAttr(): void; }) => {
    if (jobInstance && jobInstance.projectType === ProjectType.two && jobInstance.project.projectDumpType === ProjectDumpType.lane) {
        return <Col span={2}>
            {/* <CVATTooltip title='only show activity'> */}
            <CVATTooltip title={title}>
                <DiffOutlined onClick={onChangeShowMultChangeAttr} />
            </CVATTooltip>
        </Col>
    }

    return <></>
}

function ObjectListHeader(props: Props): JSX.Element {
    const {
        jobInstance,
        readonly,
        // statesOrdering, changeStatesOrdering
        onChangeShowMultChangeAttr,
    } = props;

    const { t } = useTranslation();

    const multChangeAttributeHelpMessage = t('workspace.side.object.multChangeAttr');

    return (
        <div className='cvat-objects-sidebar-states-header'>
            <Row justify='space-around' align='middle' className='cvat-objects-sidebar-states-header-icon-rows'>
                {!readonly && (
                    <>
                        <LockAllSwitcher title={t('workspace.side.top.lock_all')} {...props} />
                        <HideAllSwitcher title={t('workspace.side.top.hide_all_unlock')} {...props} />
                    </>
                )}
                <OnlyShowActivitySwitcher title={t('workspace.side.top.force_active')} {...props} />
                <ChangeMultAttributeSwitcher title={multChangeAttributeHelpMessage} jobInstance={jobInstance} onChangeShowMultChangeAttr={onChangeShowMultChangeAttr} />
                <CollapseAllSwitcher title={t('workspace.side.top.expand_collapse_all')} {...props} />
                {/* <StatesOrderingSelector statesOrdering={statesOrdering}
                 changeStatesOrdering={changeStatesOrdering} /> */}
            </Row>
        </div>
    );
}

export default React.memo(ObjectListHeader);
