/*
 * @Author: swxy
 * @Date: 2022-03-18 10:04:08
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/cvat-core-wrapper.ts
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import AnnotationHubHelper from 'business/annotationHubHelper';
import _oldCore from 'cvat-core/src/api';

// const cvat: any = _cvat;

// cvat.config.backendAPI = '/api';
// cvat.config.backendAPI = '/aatp/api';
// (globalThis as any).cvat = cvat;

const hubHelper = AnnotationHubHelper.getInstance();
hubHelper.old = _oldCore;
hubHelper.old.config.origin = window.location.origin;
hubHelper.old.config.uploadChunkSize = 100;

export default function getCore(): any {
    return hubHelper.old;
}
