/*
 * @Author: swxy
 * @Date: 2023-08-21 16:42:08
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import Job from 'business/objects/job';

interface TagRawData {}

class Statistics {
    public label: any;
    public data: any;
    public total: any;

    constructor(label: any, data: any, total: any) {
        this.label = label;
        this.data = data;
        this.total = total;
    }

    // async getStatistics(job: Job) {
    //     const promises = [];
    //     for (let frame = job.startFrame; frame < job.stopFrame; frame++) {
    //         promises.push(job.annotations.get());
    //     }
    // }
}

export default Statistics;
