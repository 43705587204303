import { changeMultAttrByShapeIndex, changeShowMultChangeAttr } from 'actions/annotation-actions';
import { message, Modal, Divider, Row, Space, Button, Popconfirm, Spin, InputNumber, Col, Tooltip, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { useTranslation } from 'react-i18next';
import ChangeAttributeComponent from './changeAttributeComponent';

import './styles.scss';
import Job from 'business/objects/job';
import { number } from 'yargs';
import { Attribute } from 'business/objects/label';


const ChangeMultAttribute = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { states, label, jobInstance, loading, frameNumber } = useSelector((state: CombinedState) => ({
        states: state.annotation.annotations.states,
        label: state.annotation.job.labels[0],
        jobInstance: state.annotation.job.instance,
        loading: state.annotation.annotations.saving.multAttrSaving,
        frameNumber: state.annotation.player.frame.number,
    }));

    const [shapeIndexs, setShapeIndexs] = useState<number[]>([]);
    const [shapeIndex, setShapeIndex] = useState<number>();
    const [changeData, setChangeData] = useState<Record<number, Record<number, string>>>({})
    const [frames, setFrames] = useState<{
        startFrame: number,
        stopFrame: number,
    }>({
        startFrame: frameNumber,
        stopFrame: frameNumber,
    })

    const getShapeIndexs = () => {
        const indexs = new Set<number>();
        const list = states.map(state => state.shapeIndex);
        list.forEach((index) => {
            if (typeof index === 'number') {
                indexs.add(index);
            }
        })

        setShapeIndexs([...indexs].sort((a, b) => a - b));
    }


    const onClose = () => {
        dispatch(changeShowMultChangeAttr(false));
    }

    const onChangeShapeIndexData = (value: number) => {
        setShapeIndex(value);
        setChangeData({})
    }

    const onChange = (value: Record<number, string>, shapeIndex: number) => {
        setChangeData((old) => {
            const data = old[shapeIndex] || {};

            return {
                ...old,
                [shapeIndex]: {
                    ...data,
                    ...value,
                }
            }
        })
    }

    const saveData = async () => {
        await dispatch(changeMultAttrByShapeIndex(changeData, frames));
        onClose();
    }


    const save = () => {
        saveData();
    }

    useEffect(() => {
        getShapeIndexs();
    }, [states]);

    const { startFrame = frameNumber, stopFrame = frameNumber } = jobInstance;
    const shapeIndexOptions = shapeIndexs.map((shapeIndex) => {
        return {
            value: shapeIndex,
            label: shapeIndex,
        }
    })

    const attributeById: Record<number, Attribute> = ((label.attributes || []) as Attribute[])
        .reduce((previous: Record<number, Attribute>, current) => {
            previous[current.id] = current;
            return previous;
        }, {});

    const hasChange = !!Object.keys(changeData).length;

    return <Spin spinning={loading} tip={t('workspace.side.object.saving_tip')}>
        {/* 当前序号：{shapeIndexs.toString()} */}
        <div className='aatp_workspace_mult_object_attr_wrapper'>
            <Row gutter={[24, 48]}>
                <Col>
                    <Tooltip title="起始帧-从第几帧开始更改属性（包含）">
                        <InputNumber
                            min={startFrame}
                            max={stopFrame}
                            placeholder={'起始帧'}
                            value={frames.startFrame}
                            onChange={(frame) => {
                                if (typeof frame === 'number') {
                                    setFrames(old => {
                                        return {
                                            ...old,
                                            startFrame: frame,
                                        }
                                    })
                                }
                            }}
                        />
                    </Tooltip>
                </Col>
                <Col >
                    <Tooltip title="截止帧-到第几帧结束更改属性（包含）">
                        <InputNumber
                            min={startFrame}
                            max={stopFrame}
                            placeholder={'截止帧'}
                            value={frames.stopFrame}
                            onChange={(frame) => {
                                if (typeof frame === 'number') {
                                    setFrames(old => {
                                        return {
                                            ...old,
                                            stopFrame: frame,
                                        }
                                    })
                                }
                            }}
                        />
                    </Tooltip>
                </Col>
            </Row>

            <Select
                style={{ minWidth: '200px' }}
                options={shapeIndexOptions}
                placeholder="请选择要修改的序号"
                value={shapeIndex}
                onChange={onChangeShapeIndexData}
            />

            {/* <div className=".aatp_workspace_object_attr_site_card_wrapper">
            <Row gutter={[24, 48]}>
                {
                    shapeIndexs.map((shapeIndex) => {
                        return <ChangeAttributeComponent key={shapeIndex} shapeIndex={shapeIndex} label={label} onChange={onChange} />
                    })
                }
            </Row>
        </div> */}

            {typeof shapeIndex === 'number' && <ChangeAttributeComponent key={shapeIndex} shapeIndex={shapeIndex} label={label} onChange={onChange} />}

            {hasChange && <div className=".aatp_workspace_object_attr_site_card_wrapper">
                <Row gutter={[24, 48]}>
                    即将更改：
                </Row>
                {
                    Object.entries(changeData).map(([num, data]) => {
                        return <>
                            <Row>序号：{num}</Row>
                            {Object.entries(data).map(([attrId, value]) => {
                                const attr = attributeById[+attrId];
                                const index = attr.values.findIndex(item => item === value);
                                return <>
                                    <Row>{attr.title} 一 {attr.ranges[index]} </Row>
                                </>
                            })}
                        </>
                    })
                }
            </div>}
        </div>

        <Divider />

        <Row justify={'center'} gutter={16}>
            <Space>
                <Button danger onClick={onClose}>{t('base.close')}</Button>
                <Popconfirm title={t('workspace.side.object.save_tip')} onConfirm={save}>
                    <Button type='primary' disabled={!hasChange}>{t('base.save')}</Button>
                </Popconfirm>
            </Space>
        </Row>
    </Spin>
}

const ChangeMultAttributeComponent = () => {

    const showMultChangeAttr = useSelector((state: CombinedState) => state.annotation.annotations.showMultChangeAttr)


    return <Modal
        open={showMultChangeAttr}
        width={"80%"}
        destroyOnClose
        title="批量更改属性"
        footer={null}
        // closeIcon={<></>}
        closable={false}
        maskClosable={false}
    >
        <ChangeMultAttribute />
    </Modal>
}

export default ChangeMultAttributeComponent;