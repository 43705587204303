/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { AnyAction } from 'redux';
import { AnnotationActionTypes } from 'actions/annotation-actions';
import { AuthActionTypes } from 'actions/auth-actions';
import { BoundariesActionTypes } from 'actions/boundaries-actions';
import { Canvas, CanvasMode, RectDrawingMethod } from 'cvat-canvas-wrapper';
// import { Canvas3d } from 'canvas3d-wrapper';
import { Canvas3d } from 'canvas3d-wrapper';

import {
    ActiveControl,
    AnnotationState,
    CameraType,
    ContextMenuType,
    DimensionType,
    JobStage,
    ObjectType,
    ShapeType,
    Workspace,
    StatesOrdering,
    ActivationModel,
    ActiveView,
} from './interfaces';
import { getDiff } from '../utils/math';
import { JobStatus, LabelObjectType, ProjectType } from 'utils/ConstType';

function updateActivatedStateID(newStates: any[], prevActivatedStateID: number | null): number | null {
    return prevActivatedStateID === null || newStates.some((_state: any) => _state.clientID === prevActivatedStateID)
        ? prevActivatedStateID
        : null;
}

function updateActivatedStatesID(newStates: any[], prevSelectedStatesID: number[]): number[] {
    const clientIDs = newStates.map((_state: any) => _state.clientID);

    const selectedStatesID = clientIDs.filter((clientID: number) => prevSelectedStatesID.includes(clientID));

    return selectedStatesID;
}
const defaultState: AnnotationState = {
    activities: {
        loads: {},
    },
    canvas: {
        contextMenu: {
            visible: false,
            left: 0,
            top: 0,
            type: ContextMenuType.CANVAS_SHAPE,
            pointID: null,
            clientID: null,
        },
        instance: null,
        ready: false,
        activeControl: ActiveControl.CURSOR,
        zoomView: ActiveView.default,
    },
    job: {
        openTime: null,
        labels: [],
        requestedId: null,
        instance: null,
        attributes: {},
        fetching: false,
        saving: false,
        serialidItemBylabelId: {}, // 每个labelid下，所对应的唯一serialid。
    },
    player: {
        frame: {
            number: 0,
            filename: '',
            data: null,
            hasRelatedContext: false,
            fetching: false,
            delay: 0,
            changeTime: null,
        },
        playing: false,
        frameAngles: [],
        navigationBlocked: false,
        contextImage: {
            fetching: false,
            data: null,
            hidden: false,
            imagesData: [],
        },
    },
    drawing: {
        activeShapeType: ShapeType.RECTANGLE,
        activeLabelID: 0,
        activeObjectType: ObjectType.TRACK,
        activeNumOfPoints: 2,
        activeRectDrawingMethod: RectDrawingMethod.CLASSIC,
    },
    annotations: {
        annotationUpdating: false,
        activatedStateID: null,
        selectedStatesID: [],
        activatedAttributeID: null,
        saving: {
            forceExit: false,
            uploading: false,
            savingSilent: false,
            statuses: [],

            multAttrSaving: false,
        },
        collapsed: {},
        collapsedAll: true,
        states: [],
        filters: [],
        resetGroupFlag: false,
        history: {
            undo: [],
            redo: [],
        },
        zLayer: {
            min: 0,
            max: 0,
            cur: 0,
        },
        annotationsSetting: {
            visible: false,
            cameraType: CameraType.normalCamera,
            projectionType: '0',
            cameraIntrinsics: null,
            cameraExtrinsics: null,
            distortion: {
                k1: '',
                k2: '',
                k3: '',
                k4: '',
                k5: '',
                k6: '',
                s1: '',
                s2: '',
            }, // 畸变参数
        },

        serialID: {
            minID: 0,
            maxID: 0,
        },
        activeModel: ActivationModel.clickActive,
        isOnlyShowSelectStates: false,
        isPanelListModel: true,
        statesOrdering: StatesOrdering.ID_ASCENT,
        // isDrawShape: true, // 是否只是画框
        acitveView: ActiveView.default,
        isOnlyShowSelectStates3D: true,
        tags: [],
        drawSubPoints: undefined,
        cameraName: undefined,

        showMultChangeAttr: false,
    },
    propagate: {
        objectState: null,
        frames: 50,
    },
    trackFrame: {
        clientID: null,
        frame: 0,
    },
    statistics: {
        visible: false,
        collecting: false,
        data: null,
    },
    relationOperate: false,
    colors: [],
    sidebarCollapsed: false,
    sidebarPositionLeft: false,
    appearanceCollapsed: true,
    filtersPanelVisible: false,
    predictor: {
        enabled: false,
        error: null,
        message: '',
        projectScore: 0,
        fetching: false,
        annotatedFrames: [],
        timeRemaining: 0,
        progress: 0,
        annotationAmount: 0,
        mediaAmount: 0,
    },
    workspace: Workspace.STANDARD,
    // perspective: undefined,
};

export default (state = defaultState, action: AnyAction): AnnotationState => {
    switch (action.type) {
        case AnnotationActionTypes.GET_JOB: {
            return {
                ...state,
                job: {
                    ...state.job,
                    instance: null,
                    requestedId: action.payload.requestedId,
                    fetching: true,
                },
            };
        }
        case BoundariesActionTypes.RESET_AFTER_ERROR:
        case AnnotationActionTypes.GET_JOB_SUCCESS: {
            const {
                job,
                states,
                tags,
                openTime,
                frameNumber: number,
                frameFilename: filename,
                frameHasRelatedContext,
                colors,
                filters,
                frameData: data,
                minZ,
                maxZ,
                cameraName,
            } = action.payload;

            const isReview = job.jobStatus >= JobStatus.submitOne && job.jobStatus !== JobStatus.backOne;
            //  && job.dimension !== DimensionType.DIM_3D;
            let workspaceSelected = Workspace.STANDARD;
            let activeShapeType = ShapeType.RECTANGLE;

            if (job.dimension === DimensionType.DIM_3D) {
                workspaceSelected = Workspace.STANDARD3D;
                activeShapeType = ShapeType.CUBOID;
            }

            if (state.canvas.instance) {
                state.canvas.instance.destroy();
            }

            const idItemBylabelId: any = {};

            // const lockIDs: string[] = []; // 记录锁定对象的id
            // const lockStates: any[] = []; // 记录锁定的对象
            const labels =
                job.projectType === ProjectType.mix
                    ? (job.labels || []).filter((label: any) => !label.isSub)
                    : job.labels || [];

            const attributes = job.labels.reduce((acc: Record<number, any[]>, label: any): Record<number, any[]> => {
                acc[label.id] = label.attributes.sort((attr1: any, attr2: any) => {
                    if (attr1.dumpName && attr2.dumpName) {
                        return attr1.dumpName.localeCompare(attr2.dumpName);
                    }
                    return attr1.name.localeCompare(attr2.name);
                });
                return acc;
            }, {});
            // 获取id
            for (const item of labels || []) {
                const itemAttributes = attributes[item.id];
                const changeItem = itemAttributes.find((ele: any) => ele.inputType === 'serialid') || {};
                idItemBylabelId[item.id] = changeItem.id;
            }
            const workspace = isReview ? Workspace.REVIEW_WORKSPACE : workspaceSelected;
            let activeModel = ActivationModel.clickActive;
            if (workspace === Workspace.REVIEW_WORKSPACE && job.dimension === DimensionType.DIM_2D) {
                activeModel = ActivationModel.moveInActive;
            }

            return {
                ...state,
                job: {
                    ...state.job,
                    openTime,
                    fetching: false,
                    instance: job,
                    labels: labels,
                    // attributes: job.labels
                    //     .reduce((acc: Record<number, any[]>, label: any): Record<number, any[]> => {
                    //         acc[label.id] = label.attributes;
                    //         return acc;
                    //     }, {}),
                    attributes,
                    serialidItemBylabelId: idItemBylabelId,
                },
                annotations: {
                    ...state.annotations,
                    states,
                    tags,
                    filters,
                    zLayer: {
                        min: minZ,
                        max: maxZ,
                        cur: maxZ,
                    },
                    cameraName,
                    activeModel,
                },
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        filename,
                        hasRelatedContext: frameHasRelatedContext,
                        number,
                        data,
                    },
                    frameAngles: Array(job.stopFrame - job.startFrame + 1).fill(0),
                    // frameAngles: Array(20 - 0 + 1).fill(0),
                },
                drawing: {
                    ...state.drawing,
                    // activeLabelID: job.labels.length ? job.labels[0].id : null,
                    activeLabelID: labels.length
                        ? labels.find((label: any) =>
                              [LabelObjectType.def, LabelObjectType.shape].includes(label.labelObjectType),
                          ).id
                        : null,
                    // activeObjectType: job.mode === 'interpolation' ? ObjectType.TRACK : ObjectType.SHAPE,
                    activeObjectType: ObjectType.TRACK,
                    activeShapeType,
                },
                canvas: {
                    ...state.canvas,
                    instance: job.dimension === DimensionType.DIM_2D ? new Canvas() : new Canvas3d(),
                },
                colors,
                workspace: isReview ? Workspace.REVIEW_WORKSPACE : workspaceSelected,
            };
        }
        case AnnotationActionTypes.GET_JOB_FAILED: {
            return {
                ...state,
                job: {
                    ...state.job,
                    instance: undefined,
                    fetching: false,
                },
            };
        }
        case AnnotationActionTypes.GET_DATA_FAILED: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        fetching: false,
                    },
                },
            };
        }
        case AnnotationActionTypes.CHANGE_FRAME: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        fetching: true,
                    },
                },
                canvas: {
                    ...state.canvas,
                    ready: false,
                },
            };
        }
        case AnnotationActionTypes.CHANGE_FRAME_SUCCESS: {
            const { activatedStateID, selectedStatesID } = state.annotations;
            const { number, data, filename, hasRelatedContext, states, minZ, maxZ, curZ, delay, changeTime, tags } =
                action.payload;

            // const clientIDs = states.map((_state: any) => _state.clientID);
            // const activatedStateID = clientIDs
            //     .includes(state.annotations.activatedStateID) ?
            //     state.annotations.activatedStateID :
            //     null;
            // let { selectedStatesID } = state.annotations;
            // selectedStatesID = clientIDs.filter((clientID:number) => selectedStatesID.includes(clientID));

            return {
                ...state,
                player: {
                    ...state.player,
                    frame: {
                        data,
                        filename,
                        hasRelatedContext,
                        number,
                        fetching: false,
                        changeTime,
                        delay,
                    },
                    contextImage: {
                        ...state.player.contextImage,
                        ...(state.player.frame.number === number ? {} : { data: null }),
                    },
                },
                annotations: {
                    ...state.annotations,
                    activatedStateID: updateActivatedStateID(states, activatedStateID),
                    selectedStatesID: updateActivatedStatesID(states, selectedStatesID),
                    states,
                    tags,
                    zLayer: {
                        min: minZ,
                        max: maxZ,
                        cur: curZ,
                    },
                },
            };
        }
        case AnnotationActionTypes.CHANGE_FRAME_FAILED: {
            return {
                ...state,
                player: {
                    ...state.player,
                    frame: {
                        ...state.player.frame,
                        fetching: false,
                    },
                },
            };
        }
        case AnnotationActionTypes.ROTATE_FRAME: {
            const { offset, angle, rotateAll } = action.payload;
            return {
                ...state,
                player: {
                    ...state.player,
                    frameAngles: state.player.frameAngles.map((_angle: number, idx: number) => {
                        if (rotateAll || offset === idx) {
                            return angle;
                        }
                        return _angle;
                    }),
                },
            };
        }
        case AnnotationActionTypes.SAVE_ANNOTATIONS: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        uploading: true,
                        statuses: [],
                    },
                },
            };
        }
        case AnnotationActionTypes.SAVE_ANNOTATIONS_SUCCESS: {
            const { states } = action.payload;
            const { activatedStateID, selectedStatesID } = state.annotations;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    activatedStateID: updateActivatedStateID(states, activatedStateID),
                    selectedStatesID: updateActivatedStatesID(states, selectedStatesID),
                    saving: {
                        ...state.annotations.saving,
                        uploading: false,
                    },
                },
            };
        }
        case AnnotationActionTypes.SAVE_ANNOTATIONS_FAILED: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        uploading: false,
                    },
                },
            };
        }
        case AnnotationActionTypes.SAVE_ANNOTATIONS_SILENT: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        savingSilent: true,
                        statuses: [],
                    },
                },
            };
        }
        case AnnotationActionTypes.SAVE_ANNOTATIONS_SILENT_SUCCESS: {
            const { states } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    saving: {
                        ...state.annotations.saving,
                        savingSilent: false,
                    },
                },
            };
        }
        case AnnotationActionTypes.SAVE_ANNOTATIONS_SILENT_FAILED: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        savingSilent: false,
                    },
                },
            };
        }
        case AnnotationActionTypes.SAVE_UPDATE_ANNOTATIONS_STATUS: {
            const { status } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        statuses: [...state.annotations.saving.statuses, status],
                    },
                },
            };
        }
        case AnnotationActionTypes.SWITCH_PLAY: {
            const { playing } = action.payload;

            return {
                ...state,
                player: {
                    ...state.player,
                    playing,
                },
            };
        }
        case AnnotationActionTypes.COLLAPSE_SIDEBAR: {
            return {
                ...state,
                sidebarCollapsed: !state.sidebarCollapsed,
            };
        }
        case AnnotationActionTypes.COLLAPSE_APPEARANCE: {
            return {
                ...state,
                appearanceCollapsed: !state.appearanceCollapsed,
            };
        }
        case AnnotationActionTypes.COLLAPSE_OBJECT_ITEMS: {
            const { states, collapsed } = action.payload;
            // const updatedCollapsedStates = { ...state.annotations.collapsed };
            // const totalStatesCount = state.annotations.states.length;
            // for (const objectState of states) {
            //     updatedCollapsedStates[objectState.clientID] = collapsed;
            // }

            const {
                annotations: { states: allStates },
            } = state;

            // 2021-10-14 只能同时展示一个详情列表
            const updatedCollapsedStates: Record<number, boolean> = {};
            const totalStatesCount = state.annotations.states.length;
            for (const objectState of allStates) {
                updatedCollapsedStates[objectState.clientID] = true;
            }

            for (const objectState of states) {
                updatedCollapsedStates[objectState.clientID] = collapsed;
                // 默认展开父对象
                const { relation = {} } = objectState;
                const { parent } = relation;
                if (parent) {
                    updatedCollapsedStates[parent.clientID] = false;
                }
            }

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    collapsed: updatedCollapsedStates,
                    collapsedAll: states.length === totalStatesCount ? collapsed : state.annotations.collapsedAll,
                },
            };
        }
        case AnnotationActionTypes.CONFIRM_CANVAS_READY: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    ready: true,
                },
            };
        }
        case AnnotationActionTypes.DRAG_CANVAS: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.DRAG_CANVAS : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    // activatedStateID: null,
                    // selectedStatesID: [],
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationActionTypes.birdEyeMode: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.birdEyeMode : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    // activatedStateID: null,
                    // selectedStatesID: [],
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationActionTypes.drawSubPoints: {
            const { drawSubPoints } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                    drawSubPoints,
                },
                canvas: {
                    ...state.canvas,
                    activeControl: drawSubPoints?.clientID ? ActiveControl.DRAW_POINTS : ActiveControl.CURSOR,
                },
            };
        }
        case AnnotationActionTypes.ZOOM_CANVAS: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.ZOOM_CANVAS : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationActionTypes.REMEMBER_CREATED_OBJECT: {
            const { payload } = action;

            let { activeControl } = state.canvas;
            if (payload.activeShapeType === ShapeType.RECTANGLE) {
                activeControl = ActiveControl.DRAW_RECTANGLE;
            } else if (payload.activeShapeType === ShapeType.splitRectangle) {
                activeControl = ActiveControl.draw_split_rectangle;
            } else if (payload.activeShapeType === ShapeType.POLYGON) {
                activeControl = ActiveControl.DRAW_POLYGON;
            } else if (payload.activeShapeType === ShapeType.POLYLINE) {
                activeControl = ActiveControl.DRAW_POLYLINE;
            } else if (payload.activeShapeType === ShapeType.POINTS) {
                activeControl = ActiveControl.DRAW_POINTS;
            } else if (payload.activeShapeType === ShapeType.ELLIPSE) {
                activeControl = ActiveControl.DRAW_ELLIPSE;
            } else if (payload.activeShapeType === ShapeType.CUBOID && activeControl !== ActiveControl.birdEyeMode) {
                activeControl = ActiveControl.DRAW_CUBOID;
            } else if (payload.activeShapeType === ShapeType.laneline) {
                activeControl = ActiveControl.laneline;
            } else if (payload.activeObjectType === ObjectType.TAG) {
                activeControl = ActiveControl.CURSOR;
            } else if (payload.activeShapeType === ShapeType.bezier2) {
                activeControl = ActiveControl.draw_bezier2;
            }

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
                drawing: {
                    ...state.drawing,
                    ...payload,
                    activeInteractor: undefined,
                },
            };
        }
        case AnnotationActionTypes.REPEAT_DRAW_SHAPE: {
            const { activeControl } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationActionTypes.SELECT_ISSUE_POSITION: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.OPEN_ISSUE : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationActionTypes.MERGE_OBJECTS: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.MERGE : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationActionTypes.GROUP_OBJECTS: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.GROUP : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationActionTypes.SPLIT_TRACK: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.SPLIT : ActiveControl.CURSOR;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                },
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationActionTypes.SHAPE_DRAWN: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl: ActiveControl.CURSOR,
                },
            };
        }
        case AnnotationActionTypes.UPDATE_ANNOTATIONS: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    annotationUpdating: true,
                },
            };
        }
        case AnnotationActionTypes.UPDATE_ANNOTATIONS_SUCCESS: {
            const { history, states: updatedStates, minZ, maxZ } = action.payload;
            const { states: prevStates } = state.annotations;
            const nextStates = [...prevStates];

            const clientIDs = prevStates.map((prevState: any): number => prevState.clientID);
            for (const updatedState of updatedStates) {
                const index = clientIDs.indexOf(updatedState.clientID);
                if (index !== -1) {
                    nextStates[index] = updatedState;
                }
            }

            const maxZLayer = Math.max(state.annotations.zLayer.max, maxZ);
            const minZLayer = Math.min(state.annotations.zLayer.min, minZ);

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    zLayer: {
                        min: minZLayer,
                        max: maxZLayer,
                        cur: maxZLayer,
                    },
                    states: nextStates,
                    history,
                    annotationUpdating: false,
                },
            };
        }
        case AnnotationActionTypes.UPDATE_ANNOTATIONS_FAILED: {
            const { states } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    annotationUpdating: false,
                },
            };
        }
        case AnnotationActionTypes.CREATE_ANNOTATIONS_SUCCESS: {
            const { states, history } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                },
            };
        }
        case AnnotationActionTypes.MERGE_ANNOTATIONS_SUCCESS: {
            const { states, history } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                },
            };
        }
        case AnnotationActionTypes.RESET_ANNOTATIONS_GROUP: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    resetGroupFlag: true,
                },
            };
        }
        case AnnotationActionTypes.GROUP_ANNOTATIONS: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    resetGroupFlag: false,
                },
            };
        }
        case AnnotationActionTypes.GROUP_ANNOTATIONS_SUCCESS: {
            const { states, history } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                },
            };
        }
        case AnnotationActionTypes.SPLIT_ANNOTATIONS_SUCCESS: {
            const { states, history } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    history,
                },
            };
        }
        case AnnotationActionTypes.ACTIVATE_OBJECT: {
            const { activatedStateID, activatedAttributeID } = action.payload;

            const {
                canvas: { activeControl, instance },
                annotations: { states },
            } = state;

            if (
                ![ActiveControl.CURSOR, ActiveControl.birdEyeMode].includes(activeControl) ||
                ![CanvasMode.IDLE, CanvasMode.birdEye].includes(
                    (instance as Canvas | Canvas3d).mode() as any as CanvasMode,
                )
            ) {
                return state;
            }
            // let { collapsed } = state.annotations;
            // if (activatedStateID && !collapsed[activatedStateID]) {
            //     // collapsed[activatedStateID] = true;
            //     collapsed = {
            //         [activatedStateID]: false,
            //     };
            // }

            // const {
            //     annotations: { states: allStates },
            // } = state;

            // // 2021-10-14 只能同时展示一个详情列表
            // const updatedCollapsedStates: Record<number, boolean> = {};
            // const totalStatesCount = state.annotations.states.length;
            // for (const objectState of allStates) {
            //     updatedCollapsedStates[objectState.clientID] = true;
            // }

            // for (const objectState of states) {
            //     updatedCollapsedStates[objectState.clientID] = false;
            //     // 默认展开父对象
            //     const { relation = {} } = objectState;
            //     const { parent } = relation;
            //     if (parent) {
            //         updatedCollapsedStates[parent.clientID] = false;
            //     }
            // }

            // return {
            //     ...state,
            //     annotations: {
            //         ...state.annotations,
            //         collapsed: updatedCollapsedStates,
            //         collapsedAll: states.length === totalStatesCount ? collapsed : state.annotations.collapsedAll,
            //     },
            // };

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID,
                    activatedAttributeID,
                    selectedStatesID: [],
                    // collapsed: updatedCollapsedStates,
                    // collapsedAll: states.length === totalStatesCount ? false : state.annotations.collapsedAll,
                },
            };
        }
        case AnnotationActionTypes.SELECT_OBJECTS: {
            const {
                canvas: { activeControl, instance },
                annotations: { states },
                player: {
                    frame: { number },
                },
            } = state;

            if (activeControl !== ActiveControl.CURSOR || instance?.mode() !== CanvasMode.IDLE) {
                return state;
            }

            const {
                annotations: { activatedStateID, selectedStatesID },
            } = state;
            const { selectsID = [] } = action.payload;

            let statesID = [];
            if (selectsID && selectsID.length) {
                if (activatedStateID && !selectsID.includes(activatedStateID)) {
                    selectsID.unshift(activatedStateID);
                }
                // 单个选择
                statesID = getDiff(selectedStatesID, selectsID);
                // } else if (sele) { // 全取消不需要
            } else {
                // 全选快捷键
                statesID = states.reduce((previousValue, currentItem) => {
                    if (currentItem.frame === number) {
                        previousValue.push(currentItem.clientID);
                    }
                    return previousValue;
                }, []);
            }
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: statesID,
                },
            };
        }
        case AnnotationActionTypes.REMOVE_OBJECT_SUCCESS: {
            const { objectStates, history } = action.payload;
            const contextMenuClientID = state.canvas.contextMenu.clientID;
            const contextMenuVisible = state.canvas.contextMenu.visible;
            const objectStatesID: number[] = objectStates.map((objectState: any): number => objectState.clientID);

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    history,
                    activatedStateID: null,
                    selectedStatesID: [],
                    states: state.annotations.states.filter(
                        (_objectState: any) => !objectStatesID.includes(_objectState.clientID),
                    ),
                },
                canvas: {
                    ...state.canvas,
                    contextMenu: {
                        ...state.canvas.contextMenu,
                        clientID: objectStatesID.includes(contextMenuClientID as number) ? null : contextMenuClientID,
                        visible: objectStatesID.includes(contextMenuClientID as number) ? false : contextMenuVisible,
                    },
                },
            };
        }
        case AnnotationActionTypes.PASTE_SHAPE: {
            const { activeControl } = action.payload;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                },
            };
        }
        case AnnotationActionTypes.COPY_SHAPE: {
            const { objectState } = action.payload;

            return {
                ...state,
                drawing: {
                    ...state.drawing,
                    activeInitialState: objectState,
                },
            };
        }
        case AnnotationActionTypes.EDIT_SHAPE: {
            // const { enabled } = action.payload;
            // const oldControl = state.canvas.activeControl
            // const activeControl = enabled ? ActiveControl.EDIT : state.canvas.activeControl;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    // activeControl,
                },
            };
        }
        case AnnotationActionTypes.PROPAGATE_OBJECT: {
            const { objectState } = action.payload;
            return {
                ...state,
                propagate: {
                    ...state.propagate,
                    objectState,
                },
            };
        }
        case AnnotationActionTypes.track_change_start_frame: {
            const { changeClientID } = action.payload;
            return {
                ...state,
                trackFrame: {
                    ...state.trackFrame,
                    clientID: changeClientID || null,
                },
            };
        }
        case AnnotationActionTypes.PROPAGATE_OBJECT_SUCCESS: {
            const { history } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    history,
                },
                propagate: {
                    ...state.propagate,
                    objectState: null,
                },
            };
        }
        case AnnotationActionTypes.CHANGE_PROPAGATE_FRAMES: {
            const { frames } = action.payload;

            return {
                ...state,
                propagate: {
                    ...state.propagate,
                    frames,
                },
            };
        }
        case AnnotationActionTypes.SWITCH_SHOWING_STATISTICS: {
            const { visible } = action.payload;

            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    visible,
                },
            };
        }
        case AnnotationActionTypes.SWITCH_SHOWING_FILTERS: {
            const { visible } = action.payload;

            return {
                ...state,
                filtersPanelVisible: visible,
            };
        }
        case AnnotationActionTypes.COLLECT_STATISTICS: {
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    collecting: true,
                },
            };
        }
        case AnnotationActionTypes.COLLECT_STATISTICS_SUCCESS: {
            const { data } = action.payload;
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    collecting: false,
                    data,
                },
            };
        }
        case AnnotationActionTypes.COLLECT_STATISTICS_FAILED: {
            return {
                ...state,
                statistics: {
                    ...state.statistics,
                    collecting: false,
                    data: null,
                },
            };
        }
        case AnnotationActionTypes.UPLOAD_JOB_ANNOTATIONS: {
            const { job, loader } = action.payload;
            const { loads } = state.activities;
            loads[job.id] = job.id in loads ? loads[job.id] : loader.name;

            return {
                ...state,
                activities: {
                    ...state.activities,
                    loads: {
                        ...loads,
                    },
                },
            };
        }
        case AnnotationActionTypes.UPLOAD_JOB_ANNOTATIONS_FAILED: {
            const { job } = action.payload;
            const { loads } = state.activities;

            delete loads[job.id];

            return {
                ...state,
                activities: {
                    ...state.activities,
                    loads: {
                        ...loads,
                    },
                },
            };
        }
        case AnnotationActionTypes.UPLOAD_JOB_ANNOTATIONS_SUCCESS: {
            const { states, job, history } = action.payload;
            const { loads } = state.activities;

            delete loads[job.id];

            return {
                ...state,
                activities: {
                    ...state.activities,
                    loads: {
                        ...loads,
                    },
                },
                annotations: {
                    ...state.annotations,
                    history,
                    states,
                    activatedStateID: null,
                    collapsed: {},
                },
            };
        }
        // Added Remove Annotations
        case AnnotationActionTypes.REMOVE_JOB_ANNOTATIONS_SUCCESS: {
            const { history } = action.payload;
            const { states } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    history,
                    states,
                    activatedStateID: null,
                    collapsed: {},
                },
            };
        }
        case AnnotationActionTypes.UPDATE_CANVAS_CONTEXT_MENU: {
            const { visible, left, top, type, pointID } = action.payload;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    contextMenu: {
                        ...state.canvas.contextMenu,
                        visible,
                        left,
                        top,
                        type,
                        pointID,
                        clientID: state.annotations.activatedStateID,
                    },
                },
            };
        }
        case AnnotationActionTypes.REDO_ACTION_SUCCESS:
        case AnnotationActionTypes.UNDO_ACTION_SUCCESS: {
            const { activatedStateID, selectedStatesID } = state.annotations;
            const { history, states, minZ, maxZ } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: updateActivatedStateID(states, activatedStateID),
                    selectedStatesID: updateActivatedStatesID(states, selectedStatesID),
                    states,
                    history,
                    zLayer: {
                        min: minZ,
                        max: maxZ,
                        cur: maxZ,
                    },
                },
            };
        }
        case AnnotationActionTypes.FETCH_ANNOTATIONS_SUCCESS: {
            const { activatedStateID, selectedStatesID } = state.annotations;
            const { states, history, minZ, maxZ } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: updateActivatedStateID(states, activatedStateID),
                    selectedStatesID: updateActivatedStatesID(states, selectedStatesID),
                    states,
                    history,
                    zLayer: {
                        min: minZ,
                        max: maxZ,
                        cur: maxZ,
                    },
                },
            };
        }
        case AnnotationActionTypes.CHANGE_ANNOTATIONS_FILTERS: {
            const { filters } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    filters,
                },
            };
        }
        case AnnotationActionTypes.SWITCH_Z_LAYER: {
            const { cur } = action.payload;
            const { max, min } = state.annotations.zLayer;

            let { activatedStateID, selectedStatesID } = state.annotations;
            if (activatedStateID !== null) {
                const idx = state.annotations.states.map((_state: any) => _state.clientID).indexOf(activatedStateID);
                if (idx !== -1) {
                    if (state.annotations.states[idx].zOrder > cur) {
                        activatedStateID = null;
                        selectedStatesID = [];
                    }
                } else {
                    activatedStateID = null;
                    selectedStatesID = [];
                }
            }

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID,
                    selectedStatesID,
                    zLayer: {
                        ...state.annotations.zLayer,
                        cur: Math.max(Math.min(cur, max), min),
                    },
                },
            };
        }
        case AnnotationActionTypes.ADD_Z_LAYER: {
            const { max } = state.annotations.zLayer;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    zLayer: {
                        ...state.annotations.zLayer,
                        max: max + 1,
                        cur: max + 1,
                    },
                },
            };
        }
        case AnnotationActionTypes.INTERACT_WITH_CANVAS: {
            const { activeInteractor, activeLabelID } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activatedStateID: null,
                    selectedStatesID: [],
                },
                drawing: {
                    ...state.drawing,
                    activeInteractor,
                    activeLabelID,
                },
                canvas: {
                    ...state.canvas,
                    activeControl: activeInteractor.type.startsWith('opencv')
                        ? ActiveControl.OPENCV_TOOLS
                        : ActiveControl.AI_TOOLS,
                },
            };
        }
        case AnnotationActionTypes.SET_FORCE_EXIT_ANNOTATION_PAGE_FLAG: {
            const { forceExit } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        forceExit,
                    },
                },
            };
        }
        case AnnotationActionTypes.CHANGE_WORKSPACE: {
            const { workspace } = action.payload;
            if (state.canvas.activeControl !== ActiveControl.CURSOR) {
                return state;
            }

            let activeModel = ActivationModel.clickActive;
            if (workspace === Workspace.REVIEW_WORKSPACE && state.job.instance.dimension === DimensionType.DIM_2D) {
                activeModel = ActivationModel.moveInActive;
            }

            return {
                ...state,
                workspace,
                annotations: {
                    ...state.annotations,
                    activeModel,
                },
            };
        }
        case AnnotationActionTypes.UPDATE_PREDICTOR_STATE: {
            const { payload } = action;
            return {
                ...state,
                predictor: {
                    ...state.predictor,
                    ...payload,
                },
            };
        }
        case AnnotationActionTypes.GET_PREDICTIONS: {
            return {
                ...state,
                predictor: {
                    ...state.predictor,
                    fetching: true,
                },
            };
        }
        case AnnotationActionTypes.GET_PREDICTIONS_SUCCESS: {
            const { frame } = action.payload;
            const annotatedFrames = [...state.predictor.annotatedFrames, frame];

            return {
                ...state,
                predictor: {
                    ...state.predictor,
                    fetching: false,
                    annotatedFrames,
                },
            };
        }
        case AnnotationActionTypes.GET_PREDICTIONS_FAILED: {
            return {
                ...state,
                predictor: {
                    ...state.predictor,
                    fetching: false,
                },
            };
        }
        case AnnotationActionTypes.RESET_CANVAS: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl: ActiveControl.CURSOR,
                },
            };
        }
        case AnnotationActionTypes.HIDE_SHOW_CONTEXT_IMAGE: {
            const { hidden } = action.payload;
            return {
                ...state,
                player: {
                    ...state.player,
                    contextImage: {
                        ...state.player.contextImage,
                        hidden,
                    },
                },
            };
        }
        case AnnotationActionTypes.GET_CONTEXT_IMAGE: {
            return {
                ...state,
                player: {
                    ...state.player,
                    contextImage: {
                        ...state.player.contextImage,
                        fetching: true,
                    },
                },
            };
        }
        case AnnotationActionTypes.GET_CONTEXT_IMAGE_SUCCESS: {
            const {
                // contextImageData,
                contextImageDataJson,
            } = action.payload;

            return {
                ...state,
                player: {
                    ...state.player,
                    contextImage: {
                        ...state.player.contextImage,
                        fetching: false,
                        // data: contextImageData,
                        imagesData: contextImageDataJson,
                    },
                },
            };
        }
        case AnnotationActionTypes.GET_CONTEXT_IMAGE_FAILED: {
            return {
                ...state,
                player: {
                    ...state.player,
                    contextImage: {
                        ...state.player.contextImage,
                        fetching: false,
                    },
                },
            };
        }
        case AnnotationActionTypes.SWITCH_NAVIGATION_BLOCKED: {
            return {
                ...state,
                player: {
                    ...state.player,
                    navigationBlocked: action.payload.navigationBlocked,
                },
            };
        }
        case AnnotationActionTypes.CHANGE_ANNOTATIONS_SETTING_STATUS: {
            const { visible } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    annotationsSetting: {
                        ...state.annotations.annotationsSetting,
                        visible: visible === undefined ? !state.annotations.annotationsSetting.visible : !!visible,
                    },
                },
            };
        }
        case AnnotationActionTypes.CHANGE_SIDEBAR_POSITION: {
            // const { sidebarPositionLeft } = action.payload;
            const { sidebarPositionLeft } = state;
            return {
                ...state,
                sidebarPositionLeft: !sidebarPositionLeft,
            };
        }
        case AnnotationActionTypes.ONLY_SHOW_SELECT_STATES: {
            const {
                annotations: { isOnlyShowSelectStates },
            } = state;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    isOnlyShowSelectStates: !isOnlyShowSelectStates,
                    // states,
                },
            };
        }
        case AnnotationActionTypes.onlyShowSelectStates3D: {
            const {
                annotations: { isOnlyShowSelectStates3D },
            } = state;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    isOnlyShowSelectStates3D: !isOnlyShowSelectStates3D,
                    // states,
                },
            };
        }
        case AnnotationActionTypes.CHANGE_PANEL_MODEL: {
            const {
                annotations: { isPanelListModel },
            } = state;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    isPanelListModel: !isPanelListModel,
                },
            };
        }
        case AnnotationActionTypes.UPDATE_SERIALID: {
            const { minID, maxID } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    serialID: {
                        minID,
                        maxID,
                    },
                },
            };
        }
        case AnnotationActionTypes.CHANGE_PANEL_SORT: {
            const { statesOrdering } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    statesOrdering,
                },
            };
        }
        // case AnnotationActionTypes.CHANGE_DRAW_TYPE: {
        //     const { isDrawShape } = action.payload;
        //     return {
        //         ...state,
        //         annotations: {
        //             ...state.annotations,
        //             isDrawShape,
        //         },
        //     };
        // }
        case AnnotationActionTypes.CHANGE_ACTIVE_MODEL: {
            const { activeModel } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    activeModel: activeModel || ActivationModel.clickActive,
                },
            };
        }
        case AnnotationActionTypes.UPDATE_OBJECT_RELATION_SUCCESS: {
            const { states } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                },
            };
        }
        case AnnotationActionTypes.UPDATE_OBJECT_RELATION_FAILED: {
            const { states } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states: states || state.annotations.states,
                },
            };
        }
        case AnnotationActionTypes.CHANGE_RELATION_OPERATE: {
            const { relationOperate } = action.payload;
            return {
                ...state,
                relationOperate,
            };
        }
        case AnnotationActionTypes.changeActive3DView: {
            const { activeView } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    acitveView: activeView,
                },
            };
        }
        case AnnotationActionTypes.getTagsSuccess: {
            const { tags } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    tags,
                },
            };
        }
        case AnnotationActionTypes.CLOSE_JOB:
        case AuthActionTypes.LOGOUT_SUCCESS: {
            if (state.canvas.instance) {
                state.canvas.instance.destroy();
            }
            if (state.job.instance) {
                state.job.instance?.close();
            }
            return { ...defaultState };
        }
        case AnnotationActionTypes.updateHistory: {
            const { history } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    history,
                },
            };
        }
        case AnnotationActionTypes.changeCamera2D: {
            const { cameraName, states } = action.payload;
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    cameraName,
                    states,
                },
            };
        }
        case AnnotationActionTypes.move_object: {
            const { activeControl } = state.canvas;
            const newActiveControl =
                activeControl === ActiveControl.move_object ? ActiveControl.CURSOR : ActiveControl.move_object;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl: newActiveControl,
                },
            };
        }
        case AnnotationActionTypes.changeZoomView: {
            const { zoomView } = action.payload;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    zoomView: zoomView,
                },
            };
        }
        case AnnotationActionTypes.showMultChangeAttr: {
            const { showMultChangeAttr } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    showMultChangeAttr,
                },
            };
        }
        case AnnotationActionTypes.changeMultAttr: {
            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        multAttrSaving: true,
                    },
                },
            };
        }
        case AnnotationActionTypes.changeMultAttrSuccess: {
            const { states } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    states,
                    saving: {
                        ...state.annotations.saving,
                        multAttrSaving: false,
                    },
                },
            };
        }
        case AnnotationActionTypes.changeMultAttrFailed: {
            // const {  } = action.payload;

            return {
                ...state,
                annotations: {
                    ...state.annotations,
                    saving: {
                        ...state.annotations.saving,
                        multAttrSaving: false,
                    },
                },
            };
        }
        default: {
            return state;
        }
    }
};
