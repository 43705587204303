/*
 * @Author: swxy
 * @Date: 2023-04-18 15:53:50
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { useState } from 'react';
import { Row, Button, Modal } from 'antd';
import TaskListComponent from './tasks/taskList';
import UploadImages from './tasks/uploadImages';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const WelcomeComponent = () => {
    const { t } = useTranslation();

    const [visible, setVisible] = useState(false);

    const onClose = () => {
        setVisible(false);
    }

    const onVisible = () => {
        setVisible(true);
    }


    return <main className='aatp_normal_welcome_background'>
        <div className='aatp_normal_welcome'>
            <Row align='middle' className='aatp_task_upload_row'>
                <Button type='primary' onClick={onVisible}>{t('task.uploadImages')}</Button>
            </Row>
            <TaskListComponent onClickAdd={onVisible} />
            <Modal
                destroyOnClose
                open={visible}
                width='80%'
                onCancel={onClose}
                footer={null}
                closable={false}
                maskClosable={false}
            >
                <UploadImages onClose={onClose} />
            </Modal>
        </div>
    </main>;
}

export default WelcomeComponent;