export enum MainType {
  def = 0,  //ef
  base = 1,  //基础
  socket = 2,  //socket
  api = 3,  //接口程序
}

export enum DatabaseType {
  def = 0,  //ef
  mysql = 1,  //mysql
  postgre = 2,  //postgre
  greenplum = 3,  //greenplum
  sqlserver = 4,  //sqlserver
  oracle = 5,  //oracle
  hbase = 6,  //hbase
  h2 = 7,  //h2
}

export enum WebType {
  def = 0,  //ef
  vue = 1,  //vue
  react = 2,  //react
}

export enum ActType {
  def = 0,  //缺省
  base = 1,  //基础
  business = 2,  //业务
  extend = 3,  //扩展
  other = 9,  //其它
}

export enum OpResult {
  success = 0,  //成功
  fail = 1,  //失败
}

export enum LogRankType {
  def = 0,  //缺省
  debug = 1,  //调试
  info = 2,  //信息
  warn = 3,  //警告
  error = 4,  //错误
  fatal = 5,  //致命
}

export enum RoleType {
  def = 0,  //缺省
  normal = 1,  //普通
  annotator = 10,  //标注员
  juniorChecker = 11,  //初级审核员
  seniorChecker = 12,  //高级审核员
  receiver = 13,  //验收员
  groupLeader = 14,  //团队管理员
  projectLeader = 15,  //项目管理员
  companyLeader = 16,  //企业管理员
  operateLeader = 20,  //运营管理员
  admin = 99,  //系统管理员
}

export enum UserStatus {
  def = 0,  //缺省
  normal = 1,  //正常
  pause = 2,  //暂停
  stop = 3,  //停止
  invalId = 4,  //无效
}

export enum ProjectRoleType {
  def = 0,  //缺省
  annotator = 1,  //标注员
  reviewer = 2,  //审核员
  pm = 3,  //项目经理
}

export enum StaffInsideType {
  def = 0,  //缺省
  inner = 1,  //内部员工
  send = 2,  //派遣员工
}

export enum StaffDutyType {
  def = 0,  //缺省
  all = 1,  //全职
  part = 2,  //兼职
}

export enum SexType {
  other = 0,  //保密
  man = 1,  //男
  female = 2,  //女
}

export enum UseableStatus {
  yes = 0,  //有效
  no = 1,  //无效
}

export enum CompanyType {
  def = 0,  //缺省
  customer = 1,  //客户
  supplier = 2,  //供应商
}

export enum ValidStatus {
  def = 0,  //缺省
  normal = 1,  //正常
  stop = 2,  //停止
}

export enum EnableStatus {
  on = 0,  //启用
  off = 1,  //禁止
}

export enum TeamJobType {
  def = 0,  //缺省
  annotate = 1,  //标注
  checkOne = 2,  //一审
  checkTwo = 3,  //二审
  accept = 4,  //验收
}

export enum AssignTaskType {
  def = 0,  //缺省
  apply = 1,  //可申请
  assign = 2,  //需指派
}

export enum DataSourceType {
  def = 0,  //缺省
  normal = 1,  //正常
  raw = 2,  //原始数据
  user = 3,  //用户数据
}

export enum ProjectType {
  def = 0,  //缺省
  two = 1,  //2D
  three = 2,  //3D
  mix = 3,  //2D-3D
  twoDImg = 4,  //2D图像标注
  video = 5,  //视频
  D23OD = 6,  //23D目标物检测
  D3City = 7,  //3D城市
  D3Highway = 8,  //3D高速
}

export enum VisualType {
  def = 0,  //缺省
  single = 1,  //单视角
  much = 2,  //多视角
}

export enum MultiVisualFileType {
  def = 0,  //缺省
  direction = 1,  //方向文件夹
  frame = 2,  //帧文件夹
}

export enum FrameType {
  def = 0,  //缺省
  normal = 1,  //普通帧
  track = 2,  //连续帧
  mix = 3,  //混合
}

export enum ProjectionType {
  def = 0,  //缺省
  four = 1,  //4点框
  eight = 2,  //8点框
  both = 3,  //都有
}

export enum ResultOutputType {
  def = 0,  //缺省
  json = 1,  //json
  xml = 2,  //xml
}

export enum ProjectCompanyType {
  def = 0,  //缺省
  zhijia = 1,  //ZJ
  fr = 2,  //FR
  dazhuo = 3,  //dazhuo
}

export enum ProjectDumpType {
  def = 0,  //缺省
  zhijia = 1,  //ZJ
  ago = 2,  //FR原版
  od = 3,  //2D目标物
  lane = 4,  //车道线
  lidar = 5,  //雷达
  semanteme = 6,  //语义分割
}

export enum ProjectStatus {
  normal = 0,  //正常
  checked = 1,  //审核完成
  received = 2,  //验收完成
  finish = 9,  //已结束
}

export enum TaskType {
  def = 0,  //缺省
  annotation = 1,  //标注任务
  pick = 2,  //挑选任务
  semanteme = 3,  //语义分割
  user = 4,  //用户任务
}

export enum FileType {
  def = 0,  //缺省
  video = 1,  //视频
  audio = 2,  //音频
  image = 3,  //图片
}

export enum HandleType {
  def = 0,  //缺省
  task = 1,  //添加任务
  algo = 2,  //算法计算
  load = 3,  //导入标注
  blur = 4,  //模糊化
}

export enum BuildStatus {
  def = 0,  //缺省
  startup = 1,  //开始
  build = 2,  //创建中
  handle = 3,  //处理中
  handled = 4,  //处理完毕
  pack = 5,  //打包中
  packed = 6,  //打包完毕
  error = 8,  //出现错误
  finish = 9,  //已结束
}

export enum AlgoStatus {
  def = 0,  //缺省
  prepare = 1,  //准备
  start = 2,  //算法开始
  end = 3,  //算法结束
}

export enum TaskStatus {
  def = 0,  //创建中
  normal = 1,  //正常
  checked = 2,  //审核完成
  received = 3,  //验收完成
  forbid = 8,  //禁止
  finish = 9,  //已结束
}

export enum AssignAnnotationStatus {
  no = 0,  //未分配
  yes = 1,  //已分配
}

export enum AssignCheckoneStatus {
  no = 0,  //未分配
  yes = 1,  //已分配
}

export enum AssignChecktwoStatus {
  no = 0,  //未分配
  yes = 1,  //已分配
}

export enum AssignAcceptStatus {
  no = 0,  //未分配
  yes = 1,  //已分配
}

export enum JobType {
  def = 0,  //缺省
  raw = 1,  //原始题包
  annotation = 2,  //标注题包
}

export enum JobStatus {
  def = 0,  //创建中
  normal = 1,  //正常
  annotate = 2,  //标注中
  submitOne = 3,  //提交一审
  backOne = 4,  //一审打回
  submitTwo = 5,  //提交二审
  backTwo = 6,  //二审打回
  submitReceive = 7,  //提交验收
  backReceive = 8,  //验收打回
  pass = 9,  //初验通过
  passed = 10,  //终验通过
  finish = 20,  //已结束
}

export enum DumpStatus {
  def = 0,  //缺省
  on = 1,  //正在导出
  dumpOk = 2,  //导出完毕
  fail = 9,  //导出失败
}

export enum PullStatus {
  no = 0,  //不能拉取
  yes = 1,  //可以拉取
}

export enum JobAlgoStatus {
  def = 0,  //缺省
  load = 1,  //已载入
  ready = 2,  //已准备
  store = 3,  //正在入库
  stored = 4,  //入库完毕
  fail = 9,  //失败
}

export enum CheckStatus {
  def = 0,  //缺省
  send = 1,  //发送请求
  check = 2,  //检查中
  noError = 3,  //已结束无错误
  hasError = 4,  //已结束有错误
  cancel = 5,  //取消检查
  other = 9,  //其它错误
}

export enum CompressType {
  def = 0,  //缺省
  zip = 1,  //zip
  rar = 2,  //rar
}

export enum FrameFileType {
  def = 0,  //缺省
  lidar = 1,  //雷达
  camera = 2,  //摄像头
  pose = 3,  //位置
}

export enum DownloadStatus {
  def = 0,  //缺省
  ready = 1,  //准备下载
  down = 2,  //正在下载
  success = 3,  //下载成功
  fail = 4,  //下载失败
  finish = 9,  //完成
}

export enum UseType {
  def = 0,  //缺省
  platform = 1,  //平台
  user = 2,  //用户
}

export enum LabelType {
  def = 0,  //缺省
  car = 1,  //汽车
  trafficSign = 2,  //交通牌
  bicycle = 3,  //二轮车
  pedestrian = 4,  //行人
  trafficLight = 5,  //信号灯
  curb = 6,  //路缘
  other = 7,  //其它对象
  unknown = 8,  //不识别
}

export enum LabelObjectType {
  def = 0,  //缺省
  shape = 1,  //标注框
  image = 2,  //图像
  semantic = 3,  //语义分割
}

export enum DeleteStatus {
  normal = 0,  //正常
  del = 1,  //删除
  clear = 2,  //清除
}

export enum ControlType {
  def = 0,  //缺省
  serialid = 1,  //自动编号
  select = 2,  //下拉框
  radio = 3,  //单选按钮
  checkbox = 4,  //判断框
  number = 5,  //数字框
  text = 6,  //文本框
}

export enum LabelProjectType {
  def = 0,  //缺省
  two = 1,  //2D
  three = 2,  //3D
}

export enum UploadDataType {
  def = 0,  //缺省
  D2 = 1,  //2D标注
  D3 = 2,  //3D标注
  D23 = 3,  //23D融合
  D3Box = 4,  //3DBox
  D23OD = 10,  //23D目标物检测
  D3City = 15,  //3D城市
  D3Highway = 16,  //3D高速
}

export enum UploadTaskStatus {
  def = 0,  //缺省
  upload = 1,  //已上传
  down = 2,  //已下载
}

export enum DownStartupStatus {
  def = 0,  //缺省
  success = 1,  //成功
  fail = 2,  //失败
  finish = 9,  //已结束
}

export enum NoteType {
  def = 0,  //缺省
  low = 1,  //一般
  middle = 2,  //严重
  top = 3,  //最高
}

export enum NoteStatus {
  def = 0,  //待解决
  submit = 1,  //已提交
  finish = 2,  //已结束
}

export enum DimensionType {
  def = 0,  //缺省
  two = 1,  //2D
  three = 2,  //3D
}

export enum ShapeType {
  def = 0,  //缺省
  rectangle = 1,  //2D框
  polygon = 2,  //多边形
  polyline = 3,  //多线段
  points = 4,  //点
  ellipse = 5,  //圆弧
  cuboid = 6,  //立方体
  image = 7,  //图像
  splitRectangle = 8,  //分割框
}

export enum DataResourceType {
  def = 0,  //缺省
  algo = 1,  //算法
}

export enum ShapeResourceType {
  def = 0,  //缺省
  origin = 1,  //原始项目
  map = 2,  //映射
}

export enum ShapeStatus {
  normal = 0,  //正常
  back = 1,  //打回
}

export enum RelationType {
  def = 0,  //缺省
  vertical = 1,  //垂直分割线
  horizontal = 2,  //水平分割线
}

export enum ParentFrameType {
  single = 0,  //单帧
  track = 1,  //连续帧
}

export enum ChildFrameType {
  single = 0,  //单帧
  track = 1,  //连续帧
}

export enum DumpContentType {
  def = 0,  //缺省
  normal = 1,  //普通
  compose = 2,  //综合
}

export enum DumpOrderType {
  def = 0,  //缺省
  first = 1,  //第一次
  second = 2,  //第二次
}

export enum DumpCycleType {
  def = 0,  //缺省
  first = 1,  //第一轮
  second = 2,  //第二轮
}

export enum AnnotateType {
  def = 0,  //缺省
  lane2d = 1,  //2D车道线
  lane3d = 2,  //3D车道线
  object23d = 3,  //23DObject
  box3d = 4,  //3DBox
}

export enum AutolabelStatus {
  def = 0,  //缺省
  prepary = 1,  //已准备
  send = 2,  //已发送
  run = 3,  //运行中
  end = 4,  //已完成
  result = 5,  //已获取结果
  fail = 6,  //任务失败
  finish = 9,  //已完成
}

export enum BackfillStatus {
  def = 0,  //缺省
  already = 1,  //准备
  doing = 2,  //回填中
  finish = 3,  //完毕
}

export enum ShapeObjType {
  def = 0,  //缺省
  vehicle = 1,  //车辆
  rider = 2,  //骑车人
  person = 3,  //行人
  three = 4,  //三轮车
  cone = 5,  //锥桶
  unknown = 6,  //其它障碍物
  unsure = 7,  //不确定障碍物
}

export enum ShapeObjFrType {
  def = 0,  //缺省
  car = 1,  //汽车
  person = 2,  //行人
  truck = 3,  //卡车
  bus = 4,  //公交车
  bicycle = 5,  //自行车
  motorcycle = 6,  //摩托车
  rollingContainers = 7,  //滚筒
  wheeledPedestrian = 8,  //坐轮椅人
  trailers = 9,  //拖车
  vehicleOther = 10,  //其它车
  animal = 11,  //动物
  trafficCones = 12,  //交通锥标
  targetOther = 13,  //其它目标物
  pickTruck = 14,  //皮卡
  engineVehicle = 15,  //工程车
  tricycle = 16,  //三轮车
  lane = 17,  //车道线
  barrier = 18,  //障碍物
  trafficWarning = 19,  //三角警示牌
  hourseCar = 20,  //房车
  unknow = 99,  //未知
}

export enum SemanticObjFrType {
  def = 0,  //缺省
  unlabelled = 1,  //未标记的
  road = 2,  //道路
  sidewalk = 3,  //人行道
  building = 4,  //建筑物
  wall = 5,  //围墙
  fence = 6,  //围栏
  pole = 7,  //杆子
  traffic_light = 8,  //交通灯
  traffic_sign = 9,  //指示牌
  vegetation = 10,  //植被
  terrain = 11,  //地形
  sky = 12,  //天空
  person = 13,  //人
  rider = 14,  //骑手
  car = 15,  //汽车
  truck = 16,  //卡车
  bus = 17,  //公交车
  trailer = 18,  //拖车
  train = 19,  //火车
  motorcycle = 20,  //摩托车
  bicycle = 21,  //自行车
  other_vehicle = 22,  //其它车辆
  zebra_crossing = 23,  //斑马线
}

export enum LaneFrType {
  def = 0,  //缺省
  lanes_and_boundaries = 1,  //车道边界
  stop_and_yield = 2,  //停车让行
  pedestrian_crossing = 3,  //人行横道
}

export enum UploadWorkType {
  def = 0,  //缺省
  algo = 1,  //算法
  semantic = 2,  //语义分割
}

export enum UploadStatus {
  def = 0,  //缺省
  on = 1,  //上传中
  merge = 2,  //合并中
  end = 3,  //已完成
  fail = 9,  //失败
}

export enum ResourceType {
  file = 0,  //文件
  index = 1,  //索引
  task = 2,  //任务
}

export enum AlgoOutputType {
  def = 0,  //缺省
  base = 1,  //基础
  extend = 2,  //扩展
  threeD = 3,  //3D
  velocity = 4,  //速度
  boundary = 5,  //边界
  lane = 6,  //车道线
  all = 99,  //全部
}

export enum HandleStatus {
  def = 0,  //缺省
  ready = 1,  //准备
  extract = 2,  //抽帧中
  extractEnd = 3,  //抽帧完成
  convert = 4,  //转换中
  convertEnd = 5,  //转换完成
  algo = 6,  //运算中
  algoEnd = 7,  //运算完成
  store = 8,  //入库中
  finish = 9,  //已结束
  fail = 10,  //失败
}

export enum AlgoLimitType {
  def = 0,  //缺省
  normal = 1,  //普通用户
  company = 2,  //企业用户
  inner = 3,  //内部用户
}

export enum UserAlgoStatus {
  def = 0,  //缺省
  start = 1,  //启动
  success = 2,  //成功
  fail = 3,  //失败
}

export enum CustomerType {
  def = 0,  //缺省
  old = 1,  //老客户
  newly = 2,  //新客户
  other = 9,  //其它
}

export enum AnnotationType {
  def = 0,  //缺省
  two = 1,  //2d
  three = 2,  //3d
  other = 99,  //其它
}

export enum UsePlatformType {
  def = 0,  //自有平台
  customer = 1,  //客户平台
  third = 2,  //第三方平台
}

export enum SettleType {
  def = 0,  //缺省
  customer = 1,  //客户结算
  inner = 2,  //内部结算
  supplier = 3,  //供应商结算
}

export enum FilePatternType {
  def = 0,  //缺省
  plusai = 1,  //PlusAI
  momenta = 2,  //MomenTa
}

export enum CompleteStatus {
  no = 0,  //未完结
  yes = 1,  //已完结
}

export enum StatRoleType {
  def = 0,  //缺省
  annotator = 1,  //标注员
  reviewer = 2,  //审核员
}

export enum InquiryType {
  my = 0,  //我的报价
  he = 1,  //对方报价
}

export enum ApproveType {
  def = 0,  //未确认
  back = 1,  //退回
  ok = 2,  //同意
}