// Copyright (C) 2021-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Layout from 'antd/lib/layout';
import Result from 'antd/lib/result';
import Spin from 'antd/lib/spin';
import notification from 'antd/lib/notification';

import Standard2DImageWorkspaceComponent from 'components/annotation-page/standard2DImage-workspace/standard2DImage-workspace';
import AttributeAnnotationWorkspace from 'components/annotation-page/attribute-annotation-workspace/attribute-annotation-workspace';
import ReviewAnnotationsWorkspace from 'components/annotation-page/review-workspace/review-workspace';
import ReviewAnnotations3DWorkspace from 'components/annotation-page/review3D-workspace/review3D-workspace';
import ReviewAnnotations3D2DWorkspace from 'components/annotation-page/review3D2D-workspace/review3D2D-workspace';
import StandardWorkspaceComponent from 'components/annotation-page/standard-workspace/standard-workspace';
import StandardWorkspace3DComponent from 'components/annotation-page/standard3D-workspace/standard3D-workspace';
import StandardWorkspace3D2DComponent from 'components/annotation-page/standard3D2D-workspace/standard3D2D-workspace';
import TagAnnotationWorkspace from 'components/annotation-page/tag-annotation-workspace/tag-annotation-workspace';
import FiltersModalComponent from 'components/annotation-page/top-bar/filters-modal';
import StatisticsModalComponent from 'components/annotation-page/top-bar/statistics-modal';
import AnnotationTopBarContainer from 'containers/annotation-page/top-bar/top-bar';
import { Workspace } from 'reducers/interfaces';
import { usePrevious } from 'utils/hooks';
import './styles.scss';
import Button from 'antd/lib/button';
import { ProjectCompanyType, ProjectDumpType, ProjectType } from 'utils/ConstType';
import LoadingComponent from 'components/common/Loading';
import SettingComponent from 'components/setting/setting';

interface Props {
    job: any | null | undefined;
    fetching: boolean;
    frameNumber: number;
    workspace: Workspace;
    sidebarPositionLeft: boolean;
    annotationUpdating: boolean;
    canvasIsReady: boolean;

    getJob(): void;
    saveLogs(): void;
    closeJob(): void;
    changeFrame(frame: number): void;
}

export default function AnnotationPageComponent(props: Props): JSX.Element {
    const {
        job,
        fetching,
        workspace,
        frameNumber,
        sidebarPositionLeft,

        getJob,
        closeJob,
        // saveLogs,
        changeFrame,
        annotationUpdating,
        canvasIsReady,
    } = props;
    const prevJob = usePrevious(job);
    const prevFetching = usePrevious(fetching);

    // const { annotation, review } = useSelector((state: CombinedState) => state.permission);

    const history = useHistory();
    // if (!annotation && !review) {
    //     message.error('您并不是这个任务的工作人员！');
    //     history.goBack();
    //     return <></>;
    // }
    useEffect(() => {
        // 日志暂时去掉
        // saveLogs();
        const root = window.document.getElementById('root');
        if (root) {
            root.style.minHeight = '720px';
        }

        return () => {
            // saveLogs();
            if (root) {
                root.style.minHeight = '';
            }

            if (!history.location.pathname.includes('/jobs')) {
                closeJob();
            }
        };
    }, []);

    useEffect(() => {
        if (job === null && !fetching) {
            getJob();
        }
    }, [job, fetching]);

    useEffect(() => {
        if (prevFetching && !fetching && !prevJob && job) {
            const latestFrame = localStorage.getItem(`Job_${job.id}_frame`);
            if (latestFrame && Number.isInteger(+latestFrame)) {
                const parsedFrame = +latestFrame;
                if (parsedFrame !== frameNumber && parsedFrame >= job.startFrame && parsedFrame <= job.stopFrame) {
                    const notificationKey = `cvat-notification-continue-job-${job.id}`;
                    notification.info({
                        key: notificationKey,
                        // message: `You finished working on frame ${parsedFrame}`,
                        message: `你最近查看的是第${parsedFrame}帧`,
                        description: (
                            <span>
                                你可以点击
                                <Button
                                    className='cvat-notification-continue-job-button'
                                    type='link'
                                    onClick={() => {
                                        changeFrame(parsedFrame);
                                        notification.close(notificationKey);
                                    }}
                                >
                                    这里
                                </Button>
                                跳转到第
                                {parsedFrame}
                                帧继续
                                {/* if you would like to continue */}
                            </span>
                        ),
                        placement: 'topRight',
                        className: 'cvat-notification-continue-job',
                    });
                }
            }

            if (!job.labels.length) {
                // notification.warning({
                //     message: 'No labels',
                //     description: (
                //         <span>
                //             {`${job.projectId ? 'Project' : 'Task'} ${
                //                 job.projectId || job.taskId
                //             } does not contain any label. `}
                //             <a href={`/${job.projectId ? 'projects' : 'tasks'}/${job.projectId || job.id}/`}>
                //                 Add
                //             </a>
                //             {' the first one for editing annotation.'}
                //         </span>
                //     ),
                //     placement: 'topRight',
                //     className: 'cvat-notification-no-labels',
                // });
                notification.warning({
                    message: '暂无标签信息',
                    description: (
                        <span>
                            请询问项目管理员，是否有在项目中加入标签。
                            {/* {`${job.projectId ? 'Project' : 'Task'} ${
                                job.projectId || job.taskId
                            } does not contain any label. `}
                            <a href={`/${job.projectId ? 'projects' : 'tasks'}/${job.projectId || job.id}/`}>
                                Add
                            </a>
                            {' the first one for editing annotation.'} */}
                        </span>
                    ),
                    placement: 'topRight',
                    className: 'cvat-notification-no-labels',
                });
            }
        }
    }, [job, fetching, prevJob, prevFetching]);

    if (job === null) {
        return <Spin size='large' className='cvat-spinner' />;
    }

    if (typeof job === 'undefined') {
        return (
            <Result
                className='cvat-not-found'
                status='404'
                // title='Sorry, but this job was not found'
                // subTitle='Please, be sure information you tried to get exist and you have access'
                title='抱歉，这个题包没有找到'
                subTitle={
                    <div>
                        请点击
                        <Button
                            type='link'
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            这里
                        </Button>
                        返回首页
                    </div>
                }
            />
        );
    }

    // if (job?.project?.projectCompanyType !== ProjectCompanyType.dazhuo) {
    //     return (
    //         <Result
    //             className='cvat-not-found'
    //             status='500'
    //             // title='Sorry, but this job was not found'
    //             // subTitle='Please, be sure information you tried to get exist and you have access'
    //             title='抱歉，不再支持该版本的题包'
    //             subTitle={
    //                 <div>
    //                     请点击
    //                     <Button
    //                         type='link'
    //                         onClick={() => {
    //                             history.push('/');
    //                         }}
    //                     >
    //                         这里
    //                     </Button>
    //                     返回首页
    //                 </div>
    //             }
    //         />
    //     );
    // }

    // useEffect(() => {

    //     // 审核状态
    // }, job)

    if (job.projectType === ProjectType.twoDImg) {
        return (
            <Layout className='cvat-annotation-page'>
                <Layout.Header className='cvat-annotation-header'>
                    <AnnotationTopBarContainer />
                </Layout.Header>
                {workspace === Workspace.STANDARD && (
                    <Layout.Content className='cvat-annotation-layout-content'>
                        <Standard2DImageWorkspaceComponent
                            labelsLength={job.labels.length}
                            sidebarPositionLeft={sidebarPositionLeft}
                        />
                    </Layout.Content>
                )}
                {/* <FiltersModalComponent />
                <StatisticsModalComponent /> */}
                <LoadingComponent annotationUpdating={annotationUpdating} />
                <SettingComponent />
            </Layout>
        );
    }

    if (job.projectType === ProjectType.mix) {
        return (
            <Layout className='cvat-annotation-page'>
                <Layout.Header className='cvat-annotation-header'>
                    <AnnotationTopBarContainer />
                </Layout.Header>
                {workspace === Workspace.STANDARD3D && (
                    <Layout.Content className='cvat-annotation-layout-content'>
                        <StandardWorkspace3D2DComponent />
                    </Layout.Content>
                )}
                {job.dimension === '3d' && workspace === Workspace.REVIEW_WORKSPACE && (
                    <Layout.Content className='cvat-annotation-layout-content'>
                        <ReviewAnnotations3D2DWorkspace />
                    </Layout.Content>
                )}
                <StatisticsModalComponent />
                <LoadingComponent annotationUpdating={annotationUpdating} />
                {(!canvasIsReady) && <Spin className='canvas_loading' size='large' spinning={true} tip='点云加载中...' />}

                <SettingComponent />
            </Layout>
        );
    }

    return (
        <Layout className='cvat-annotation-page'>
            <Layout.Header className='cvat-annotation-header'>
                <AnnotationTopBarContainer />
            </Layout.Header>
            {workspace === Workspace.STANDARD3D && (
                <Layout.Content className='cvat-annotation-layout-content'>
                    <StandardWorkspace3DComponent />
                </Layout.Content>
            )}
            {/* {workspace === Workspace.STANDARD3D && (
                <Layout.Content className='cvat-annotation-layout-content'>
                    <StandardWorkspace3D2DComponent />
                </Layout.Content>
            )} */}
            {workspace === Workspace.STANDARD && (
                <Layout.Content className='cvat-annotation-layout-content'>
                    <StandardWorkspaceComponent sidebarPositionLeft={sidebarPositionLeft} />
                </Layout.Content>
            )}
            {workspace === Workspace.ATTRIBUTE_ANNOTATION && (
                <Layout.Content className='cvat-annotation-layout-content'>
                    <AttributeAnnotationWorkspace sidebarPositionLeft={sidebarPositionLeft} />
                </Layout.Content>
            )}
            {workspace === Workspace.TAG_ANNOTATION && (
                <Layout.Content className='cvat-annotation-layout-content'>
                    <TagAnnotationWorkspace sidebarPositionLeft={sidebarPositionLeft} />
                </Layout.Content>
            )}
            {/* {job.dimension === '3d' && workspace === Workspace.REVIEW_WORKSPACE && (
                <Layout.Content className='cvat-annotation-layout-content'>
                    <ReviewAnnotations3DWorkspace />
                </Layout.Content>
            )} */}
            {job.dimension === '3d' && workspace === Workspace.REVIEW_WORKSPACE && (
                <Layout.Content className='cvat-annotation-layout-content'>
                    <ReviewAnnotations3DWorkspace />
                </Layout.Content>
            )}
            {job.dimension !== '3d' && workspace === Workspace.REVIEW_WORKSPACE && (
                <Layout.Content className='cvat-annotation-layout-content'>
                    <ReviewAnnotationsWorkspace sidebarPositionLeft={sidebarPositionLeft} />
                </Layout.Content>
            )}
            <FiltersModalComponent />
            <StatisticsModalComponent />
            <LoadingComponent annotationUpdating={annotationUpdating} />
            <SettingComponent />
        </Layout>
    );
}
