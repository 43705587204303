// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';

// import ObjectButtonsContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-buttons';
// import { ObjectType, ShapeType, ColorBy } from 'reducers/interfaces';
import { ObjectType, ShapeType, ColorBy, ActivationModel } from 'reducers/interfaces';
import ObjectItemContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-item';
import ItemDetails, { attrValuesAreEqual } from './object-item-details';
import ItemBasics from './object-item-basics';
import ItemContextMenu from './object-item-contextmenu';
// import { useTranslation } from 'react-i18next';

interface Props {
    normalizedKeyMap: Record<string, string>;
    readonly: boolean;
    activated: boolean;
    objectType: ObjectType;
    shapeType: ShapeType;
    clientID: number;
    serverID: number | undefined;
    labelID: number;
    locked: boolean;
    attrValues: Record<number, any>;
    color: string;
    colorBy: ColorBy;
    labels: any[];
    attributes: any[];
    collapsed: boolean;
    jobInstance: any;

    shapeIndex?: number;
    subStates: any[];
    contextMenued?: boolean;
    parentIDs: number[];
    parentID: number;
    activeModel: ActivationModel;

    disabled: boolean;

    changeRelegation(parentID: number | undefined): void;

    activate(e: React.MouseEvent): void;
    copy(): void;
    propagate(): void;
    changeStartFrame(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    remove(): void;
    changeLabel(label: any): void;
    changeAttribute(attrID: number, value: string): void;
    changeColor(color: string): void;
    collapse(): void;
    resetCuboidPerspective(): void;
    shouContextMenuItem(): void;
}

function objectItemsAreEqual(prevProps: Props, nextProps: Props): boolean {
    return (
        nextProps.activated === prevProps.activated &&
        nextProps.readonly === prevProps.readonly &&
        nextProps.locked === prevProps.locked &&
        nextProps.labelID === prevProps.labelID &&
        nextProps.color === prevProps.color &&
        nextProps.clientID === prevProps.clientID &&
        nextProps.serverID === prevProps.serverID &&
        nextProps.objectType === prevProps.objectType &&
        nextProps.shapeType === prevProps.shapeType &&
        nextProps.collapsed === prevProps.collapsed &&
        nextProps.labels === prevProps.labels &&
        nextProps.attributes === prevProps.attributes &&
        nextProps.normalizedKeyMap === prevProps.normalizedKeyMap &&
        nextProps.colorBy === prevProps.colorBy &&
        nextProps.subStates === prevProps.subStates &&
        nextProps.subStates.length === prevProps.subStates.length &&
        attrValuesAreEqual(nextProps.attrValues, prevProps.attrValues)
    );
}

function ObjectItemComponent(props: Props): JSX.Element {
    const {
        activated,
        readonly,
        objectType,
        shapeType,
        clientID,
        serverID,
        locked,
        attrValues,
        labelID,
        color,
        colorBy,
        attributes,
        labels,
        collapsed,
        normalizedKeyMap,
        subStates,
        contextMenued,
        parentIDs,
        parentID,
        activeModel,

        changeRelegation,
        activate,
        copy,
        propagate,
        changeStartFrame,
        createURL,
        switchOrientation,
        toBackground,
        toForeground,
        remove,
        changeLabel,
        changeAttribute,
        changeColor,
        collapse,
        resetCuboidPerspective,
        shouContextMenuItem,
        jobInstance,

        shapeIndex,
        disabled,
    } = props;
    // const { t } = useTranslation();

    // const type =
    //     objectType === ObjectType.TAG ?
    //         ObjectType.TAG.toUpperCase() :
    //         `${shapeType.toUpperCase()} ${objectType.toUpperCase()}`;
    // let type = {
    //     name: t('shape.other'),
    //     fullName: t('shape.full.other'),
    // };
    // try {
    //     const name =
    //         objectType === ObjectType.TAG ? ObjectType.TAG.toUpperCase() : `${t(`shape.${shapeType}`).toUpperCase()}`;
    //     type = {
    //         name,
    //         fullName: t(`shape.${shapeType}`),
    //     };
    // } catch (error) {
    //     type = {
    //         name: t('shape.other'),
    //         fullName: t('shape.full.other'),
    //     };
    // }

    let className = 'cvat-objects-sidebar-state-item';
    if (activated) {
        className = className + ' cvat-objects-sidebar-state-active-item';
    }
    if (disabled) {
        className = className + ' cvat-objects-sidebar-state-item-disabled';
    }
    const basicsItemJXP = (
        <ItemBasics
            jobInstance={jobInstance}
            readonly={readonly}
            serverID={serverID}
            clientID={clientID}
            // labelID={labelID}
            // labels={labels}
            shapeType={shapeType}
            objectType={objectType}
            color={color}
            colorBy={colorBy}
            // type={type}
            locked={locked}
            copyShortcut={normalizedKeyMap.COPY_SHAPE}
            pasteShortcut={normalizedKeyMap.PASTE_SHAPE}
            propagateShortcut={normalizedKeyMap.PROPAGATE_OBJECT}
            changeStartFrameShortcut={normalizedKeyMap.track_change_start_frame}
            toBackgroundShortcut={normalizedKeyMap.TO_BACKGROUND}
            toForegroundShortcut={normalizedKeyMap.TO_FOREGROUND}
            // removeShortcut={normalizedKeyMap.DELETE_OBJECT}
            changeColorShortcut={normalizedKeyMap.CHANGE_OBJECT_COLOR}
            removeShortcut={normalizedKeyMap.delete_object}
            collapsed={collapsed}
            // changeLabel={changeLabel}
            changeColor={changeColor}
            copy={copy}
            remove={remove}
            propagate={propagate}
            changeStartFrame={changeStartFrame}
            createURL={createURL}
            switchOrientation={switchOrientation}
            toBackground={toBackground}
            toForeground={toForeground}
            resetCuboidPerspective={resetCuboidPerspective}
            collapse={collapse}
            shouContextMenuItem={shouContextMenuItem}
            shapeIndex={shapeIndex}
        />
    );

    if (contextMenued) {
        // 如果是右键展示
        return (
            <div style={{ display: 'flex', marginBottom: '2px' }}>
                {/* <div className='cvat-objects-sidebar-state-item-color' style={{ background: `${color}` }} /> */}
                <div
                    onMouseEnter={activate}
                    id={`cvat-objects-sidebar-state-item-${clientID}`}
                    className={className}
                // style={{ backgroundColor: `${color}88` }}
                >
                    {basicsItemJXP}

                    <ItemContextMenu
                        readonly={readonly}
                        collapsed={collapsed}
                        labelID={labelID}
                        labels={labels}
                        parentIDs={parentIDs}
                        parentID={parentID}
                        serverID={serverID}
                        changeRelegation={changeRelegation}
                        collapse={collapse}
                        changeLabel={changeLabel}
                    />
                </div>
            </div>
        );
    }

    return (
        <div style={{ display: 'flex', marginBottom: '2px' }}>
            {/* <div className='cvat-objects-sidebar-state-item-color' style={{ background: `${color}` }} /> */}
            <div
                onMouseEnter={activeModel === ActivationModel.moveInActive ? activate : () => { }}
                onMouseDown={activeModel === ActivationModel.clickActive ? activate : () => { }}
                id={`cvat-objects-sidebar-state-item-${clientID}`}
                className={className}
                // style={{ backgroundColor: disabled ? `${color}88` : '' }}
                role='button'
                tabIndex={0}
            >
                {basicsItemJXP}
                {!!attributes.length && (
                    <ItemDetails
                        readonly={readonly}
                        collapsed={collapsed}
                        attributes={attributes}
                        values={attrValues}
                        labelID={labelID}
                        labels={labels}
                        collapse={collapse}
                        changeAttribute={changeAttribute}
                        changeLabel={changeLabel}
                    />
                )}
                {!collapsed && subStates && subStates.length ? (
                    <div style={{ paddingLeft: '20px', backgroundColor: '#6b6767' }}>
                        {subStates.map(
                            (id: number): JSX.Element => (
                                <ObjectItemContainer
                                    readonly={readonly}
                                    objectStates={[]}
                                    subStates={subStates}
                                    key={id}
                                    clientID={id}
                                    initialCollapsed={false}
                                />
                            ),
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default React.memo(ObjectItemComponent, objectItemsAreEqual);
