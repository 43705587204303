/*
 * @Author: swxy
 * @Date: 2023-07-21 18:22:51
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { DimensionType, LogType, WebLogInfo } from 'reducers/interfaces';
import { downFrameFile, queryFrameFilesInfo, queryFramesInfo } from 'service/job/frame';
import { AnnotateType, JobStatus, JobType, ProjectCompanyType, ProjectDumpType, ProjectType } from 'utils/ConstType';
// import CacheManager from './cache';
import FrameData from '../frame';
import CacheFiles from '../cacheFiles';
import AnnotationHubHelper from 'business/annotationHubHelper';
import {
    annotationsStatistics,
    associatedAnnotations,
    changeCamera,
    clearActions,
    closeSession,
    exportAnnotations,
    freezeHistory,
    getActions,
    getAnnotations,
    getSubAnnotations,
    hasUnsavedChanges,
    importAnnotations,
    projectionSubAnnotations,
    putAnnotations,
    putSubAnnotations,
    redoActions,
    saveAnnotations,
    saveMultAttribute,
    selectObject,
    undoActions,
} from 'business/annotationHelper';
import loggerStorage, { LoggerStorage } from './log';
import { Label } from './label';
import { saveWebLogs } from 'service/api/api';
import ObjectState from './objectState';
import { ArgumentError } from 'errors/exception';
import { message } from 'antd';
import { api_exportTaskAnnotations, api_queryExportJobAutoAttr, queryLabels, submitJob } from 'service/job/job';

import service from 'service/service';
import CacheResource from 'business/amygo/AmygoCacheResource';
import FrameMeta from 'business/amygo/AmygoFrameMeta';
import moment from 'moment';
import { getLabelByValue, proPoseProjectIds } from 'utils/constant';
import AmygoPoseModel from 'business/amygo/AmygoPoseModel';
import { isDev } from 'utils/enviroment';
// 由于相互引用问题,使用时，再获取

// const annotationHubHelper = AnnotationHubHelper.getInstance();

// const getHelper = () => {
//     return AnnotationHubHelper.getInstance();
// };
// const helper = getHelper();

const cacheInstance = new CacheResource();

export enum CameraType {
    normal = 'normal',
    buttonhole = 'buttonhole',
    fisheye = 'fisheye',
}

export interface Distcoeff {
    cameraType: CameraType;
    k1?: number;
    k2?: number;
    k3?: number;
    k4?: number;
    k5?: number;
    k6?: number;
    s1?: number;
    s2?: number;
    s3?: number;
    s4?: number;
    p1?: number;
    p2?: number;
    // cameraViewAngle?: number;
    // viewAngleAble?: boolean;// 是否展示视场角参考线
    // viewRadius?: number;
    // viewStartMiddleAngle?: number; // 开始位置的中间角度
}

interface CameraParam {
    extrinsic: number[][];
    intrinsic: number[][];
    distcoeff: number[][];
    type: CameraType;
    width: number;
    height: number;
}

export interface FrameInfoProps {
    id: number;
    jobId: number;
    frameIndex: number;
    frameName: string;
    filePcds: string[];
    fileImgs: string[];
    param: Record<string, CameraParam>;
}

interface ProjectProps {
    [k: string]: any;
}

// 暂时没有项目的单独操作,因此暂时放置
export class Project implements ProjectProps {
    public direction: string[] = [];
    [k: string]: any;

    constructor(initialData: any) {
        for (const property in initialData) {
            if (property in initialData) {
                this[property] = initialData[property];
            }
        }

        try {
            this.direction = JSON.parse(initialData.direction || JSON.stringify([]));
        } catch (error) {
            console.error('错误！', (error as Error).stack);
        }
    }

    public static async createProject(projectId: number) {
        // 后续
        const projectInfo = await service.project.get(projectId);
        return new Project(projectInfo);
    }
}

// 暂时没有任务的单独操作,因此暂时放置
export class Task {
    [k: string]: any;
    constructor(initialData: any) {
        for (const property in initialData) {
            if (property in initialData) {
                this[property] = initialData[property];
            }
        }
    }

    public static async createTask(taskId: number) {
        // 后续
        const taskInfo = await service.task.get(taskId);
        return new Task(taskInfo);
    }
}

// const cache = CacheManager.getInstance();

const loadImage = async (imageName: string, frameId: number) => {
    const image = (await downFrameFile({
        frameId: frameId,
        name: imageName,
    })) as Blob;

    return {
        data: image,
        fullName: imageName,
        name: imageName,
    };
};

const loadImages = async (imageNames: string[], frameId: number) => {
    const promises = imageNames.map((name: string) => {
        return loadImage(name, frameId);
    });

    const result = await Promise.all(promises);

    return result;
    // return {
    //     data: image,
    //     fullName: imageName,
    //     name: imageName,
    // };
};

// async function onServerRequest(frameInfo: FrameInfoProps, dimension: DimensionType, cameraName?: string) {
//     if (dimension === DimensionType.DIM_3D) {
//         // frameData.hasRelatedContext = true;
//         const pcdName = frameInfo.filePcds[0];

//         const pcdFile = await downFrameFile({
//             frameId: frameInfo.id,
//             name: pcdName,
//         });

//         const promise = frameInfo.fileImgs.map((imageName: string) => loadImage(imageName, frameInfo.id));
//         const images = await Promise.all(promise);

//         return {
//             data: {
//                 name: pcdName,
//                 data: {
//                     data: pcdFile as Blob,
//                 },
//                 images,
//             },
//             fileName: pcdName,
//         };
//     }

//     const imageName = cameraName || frameInfo.fileImgs[0];

//     // const blobResults = await loadImages(frameInfo.fileImgs, frameInfo.id);
//     const imageFile = await downFrameFile({
//         frameId: frameInfo.id,
//         name: imageName,
//     });
//     // const imageFiles = await blobResults()

//     const imageBitMap = await createImageBitmap(imageFile);

//     return {
//         data: imageBitMap,
//         fileName: imageName,
//     };
// }

class Job {
    public readonly id: number; // 题包id integer(int32) integer(int32)
    public readonly name: number; // 题包名称 string
    public readonly jobId: number; // 题包id

    public readonly taskId: number; //  任务编号 integer(int32) integer(int32)
    public readonly projectId: number; //  项目编号 integer(int32) integer(int32)
    // public readonly jobIndex: number; //  题包序号 integer(int32) integer(int32)

    // 主要内容
    public readonly labels: Label[]; // 标签
    public readonly dimension: DimensionType; //  维度 string
    public readonly jobStatus: JobStatus; //  题包状态 integer(int32) integer(int32)
    public readonly startFrame: number; //  起始帧 integer(int32) integer(int32)
    public readonly stopFrame: number; //  停止帧 integer(int32) integer(int32)
    public readonly totalBlock: number; //  总共块数 integer(int32) integer(int32)
    public readonly totalFrame: number; //  总共帧数 integer(int32) integer(int32)
    public readonly jobType: JobType; //  题包类型 integer(int32) integer(int32)
    public readonly status: string; //  状态 string
    public readonly checkNo: string; // 验收序号/批次

    // 场景信息，
    public readonly sceneArea: string;
    public readonly sceneName: string;
    public readonly sceneTime: string;
    public readonly sequenceId: number;

    public readonly minWidth: number; //  标注连续帧(作业编号) array 标注连续帧(作业编号)
    public readonly minHeight: number; //  任务信息(任务编号) 任务信息(任务编号) 任务信息(任务编号)

    public readonly task: Task;
    public readonly project: Project;
    public readonly projectType: ProjectType;

    public readonly info: string; //  题包说明 string
    public readonly segmentId: number; //  片段编号 integer(int32) integer(int32)
    public readonly imgHeight: number; //  图片高度 integer(int32) integer(int32)
    public readonly imgWidth: number; //  图片宽度 integer(int32) integer(int32)
    public readonly pcdHeight: number; //  点云高度 integer(int32) integer(int32)
    public readonly pcdWidth: number; //  点云宽度 integer(int32) integer(int32)
    public readonly enableReview: boolean; //  是否可以审核 boolean

    public readonly blocks: any; //  题包分块信息(题包编号) array 题包分块信息(题包编号)
    public readonly tags: any; //  标注图像(作业编号) array 标注图像(作业编号)
    public readonly shapes: any; //  标注框(作业编号) array 标注框(作业编号)
    public readonly tracks: any; //  标注连续帧(作业编号) array 标注连续帧(作业编号)

    public readonly pickRegions: string; //  挑选区间 string

    public readonly score: number; //  审核打分 integer(int32) integer(int32)
    public readonly reviewInfo: string; //  审核信息 string
    public readonly backOneTimes: number; //  一审打回次数 integer(int32) integer(int32)
    public readonly backTwoTimes: number; //  二审打回次数 integer(int32) integer(int32)
    public readonly backReceiveTimes: number; //  验收打回次数 integer(int32) integer(int32)
    public readonly checkerOneId: number; //  一审人 integer(int32) integer(int32)
    public readonly checkerTwoId: number; //  二审人 integer(int32) integer(int32)
    public readonly assigneeId: number; //  指定人 integer(int32) integer(int32)
    public readonly receiverId: number; //  验收人 integer(int32) integer(int32)

    public readonly shapeNum: number; // 框数

    public readonly statusUpdateTime: string; //  状态更新时间 string(date-time) string(date-time)
    public readonly updatedDate: string; //  更新日期 string(date-time) string(date-time)
    public readonly createdDate: string; //  创建日期 string(date-time) string(date-time)
    public readonly outClipId: string; // 客户id

    public readonly supplement: number = 0;

    public readonly jobStatus_: string;

    public cams: string[] = []; // 相机数量

    public cameraName?: string = undefined; // 当前选中的相机

    private jobInstance: any; // 旧的数据

    public readonly frameInfos: Record<number, FrameInfoProps> = {};
    public readonly frameMeta: Map<number, FrameMeta> = new Map();

    public readonly checkResult?: string;

    public readonly autoAttrInfo?: any;

    private _cache: CacheResource = cacheInstance;

    public readonly annotations: {
        get(...props: any): any;
        getTags(...props: any): any;
        put(...props: any): any;
        save(...props: any): any;
        merge(...props: any): any;
        split(...props: any): any;
        group(...props: any): any;
        clear(...props: any): any;
        search(...props: any): any;
        searchEmpty(...props: any): any;
        upload(...props: any): any;
        select(...props: any): any;
        import(...props: any): any;
        export(...props: any): any;

        statistics(...props: any): any;
        hasUnsavedChanges(...props: any): any;
        exportDataset(...props: any): any;
        sub_get(...props: any): any;
        sub_put(...props: any): any;
        sub_projection(...props: any): any;
        exportTaskAnnotations(...props: any): any;
    };

    public readonly actions: {
        undo(count?: number): any;
        redo(count?: number): any;
        freeze(frozen: boolean): any;
        clear(): any;
        get(): any;
    };

    public readonly frames: {
        get(frame: number, isPlaying?: boolean, step?: number): any;
        ranges(): any;
        preview(): any;
        contextImage(): any;
    };

    public readonly logger: {
        log(logType: LogType, payload: any, wait: boolean): Promise<any>;
    };

    public readonly predictor: {
        status(): any;
        predict(): any;
    };

    public static async getFrameMeta(jobId: number, order: string[] = []) {
        const frameMeta: any[] = await service.frames.get(jobId);
        return frameMeta.reduce((previous: Record<number, FrameMeta>, current) => {
            previous[current.frameIndex] = FrameMeta.import(current, cacheInstance, order);
            return previous;
        }, {});
    }

    public static async createJob(jobId: number) {
        // 后续
        const jobInfo = await service.job.get(jobId);

        if (jobInfo.projectId) {
            const project = await Project.createProject(jobInfo.projectId);
            jobInfo.project = project;

            if (project?.projectCompanyType === ProjectCompanyType.dazhuo) {
                const jobAutoAttrInfo = await api_queryExportJobAutoAttr({ jobId, annotateType: AnnotateType.box3d });
                if (jobAutoAttrInfo) {
                    // console.log('查到的导出信息：', jobAutoAttrInfo);
                    jobAutoAttrInfo.autolabelStatus_ = getLabelByValue(
                        'autolabelStatus',
                        jobAutoAttrInfo.autolabelStatus,
                    );
                    jobAutoAttrInfo.backfillStatus_ = getLabelByValue('backfillStatus', jobAutoAttrInfo.backfillStatus);
                }
                jobInfo.autoAttrInfo = jobAutoAttrInfo;
            }

            const result: any[] = await service.label.get(jobInfo.projectId);

            const labels = result.map((label) => {
                return new Label(label);
            });
            jobInfo.labels = labels;
        }
        const frameMetas = await Job.getFrameMeta(jobId, jobInfo.project?.direction);

        // 非必要流程，只对特定项目有效
        try {
            if (proPoseProjectIds.includes(jobInfo.projectId)) {
                // 需要下载pose
                const { posees, lidar2imu } = await AmygoPoseModel.dispose(jobId, [...Object.values(frameMetas)][0].id);
                Object.values(frameMetas).forEach((meta) => {
                    const pose = AmygoPoseModel.match(posees, meta.frameTime);
                    meta.pose = new AmygoPoseModel(pose, lidar2imu);
                });
            }
        } catch (error) {
            console.log('出现错误：', error);
        }

        if (jobInfo.taskId) {
            const task = await Task.createTask(jobInfo.taskId);
            jobInfo.task = task;
        }

        const job = new Job(jobInfo, frameMetas);
        return job;
    }

    constructor(jobInfo: any, frameMetas: Record<number, FrameMeta> = {}) {
        // 主要识别符
        this.id = jobInfo.id;
        this.name = jobInfo.name;
        this.jobId = jobInfo.id;

        // 父级id
        this.taskId = jobInfo.taskId;
        this.projectId = jobInfo.projectId;

        // 题包主要内容
        this.labels = jobInfo.labels;
        this.jobStatus = jobInfo.jobStatus;
        this.jobStatus_ = jobInfo.jobStatus_;
        this.startFrame = jobInfo.startFrame;
        this.stopFrame = jobInfo.stopFrame;
        this.totalBlock = jobInfo.totalBlock;
        this.totalFrame = jobInfo.totalFrame;
        this.jobType = jobInfo.jobType;

        // 主要数据来源
        // this.engineBlocks = jobInfo.engineBlocks;
        // this.engineLabeledimages = jobInfo.engineLabeledimages;
        // this.engineLabeledshapes = jobInfo.engineLabeledshapes;
        // this.engineLabeledtracks = jobInfo.engineLabeledtracks;
        // this.engineTask = jobInfo.engineTask;
        // this.engineProject = jobInfo?.engineTask?.engineProject;

        // this.blocks = jobInfo.engineBlocks;
        // this.tags = jobInfo.engineLabeledimages;
        // this.shapes = jobInfo.engineLabeledshapes;
        // this.tracks = jobInfo.engineLabeledtracks;

        // this.task = new Task(jobInfo.engineTask);
        // this.project = new Project(jobInfo.engineTask.engineProject);
        this.task = jobInfo.task;
        this.project = jobInfo.project;

        this.projectType = this.project?.projectType;

        // this.engineTask = jobInfo.engineTask;
        // this.engineProject = jobInfo.engineTask.engineProject;

        // this.innerParam: number[] | number[][]; // 内参
        // this.outerParam: number[] | number[][]; // 外参
        // this.outerReverse: boolean[]; // 外参是否求逆
        // this.cameraTypes: CameraType[]; // 相机类型
        // this.distortionParam: number[] | number[][]; // 相机类型
        // this.labelRange: number[];
        // this.labelEngle: number;
        // this.startRadian: number;

        // this.direction: string[];

        // 很有可能没用到
        this.info = jobInfo.info;
        this.segmentId = jobInfo.segmentId;
        this.status = jobInfo.status;
        this.imgHeight = jobInfo.imgHeight || this.task?.imgHeight || 0;
        this.imgWidth = jobInfo.imgWidth || this.task?.imgWidth || 0;
        this.pcdHeight = jobInfo.pcdHeight;
        this.pcdWidth = jobInfo.pcdWidth;
        this.enableReview = jobInfo.enableReview;

        this.pickRegions = jobInfo.pickRegions;

        this.score = jobInfo.score;
        this.reviewInfo = jobInfo.reviewInfo;
        this.backOneTimes = jobInfo.backOneTimes;
        this.backTwoTimes = jobInfo.backTwoTimes;
        this.backReceiveTimes = jobInfo.backReceiveTimes;
        this.checkerOneId = jobInfo.checkerOneId;
        this.checkerTwoId = jobInfo.checkerTwoId;
        this.assigneeId = jobInfo.assigneeId;
        this.receiverId = jobInfo.receiverId;
        this.shapeNum = jobInfo.shapeNum;

        this.statusUpdateTime = jobInfo.statusUpdateTime;
        this.updatedDate = jobInfo.updatedDate;
        this.createdDate = jobInfo.createdDate;

        // 场景信息，
        this.sceneArea = jobInfo.sceneArea;
        this.sceneName = jobInfo.sceneName;
        this.sceneTime = jobInfo.sceneTime;
        this.sequenceId = jobInfo.sequenceId;

        this.minWidth = this.project?.frameWidthMin || 0;
        this.minHeight = this.project?.frameHeightMin || 0;
        this.checkNo = jobInfo.checkNo;
        this.outClipId = jobInfo.outClipId;
        // this.jobIndex = jobInfo.jobIndex;

        this.jobInstance = jobInfo;
        this.dimension = this.projectType === ProjectType.two ? DimensionType.DIM_2D : DimensionType.DIM_3D;

        this.checkResult = jobInfo.checkResult;

        this.supplement = jobInfo.mendLabelTime ? moment(jobInfo.mendLabelTime).valueOf() : 0;
        this.autoAttrInfo = jobInfo.autoAttrInfo;

        this.annotations = {
            // get: this.jobInstance.annotations.get,
            // get: this.getAnnotationsInfo,
            // getTags: this.jobInstance.annotations.getTags,
            // put: this.jobInstance.annotations.put,
            // save: this.jobInstance.annotations.save,
            // merge: this.jobInstance.annotations.merge,
            // split: this.jobInstance.annotations.split,
            // group: this.jobInstance.annotations.group,
            // clear: this.jobInstance.annotations.clear,
            // search: this.jobInstance.annotations.search,
            // searchEmpty: this.jobInstance.annotations.searchEmpty,
            // upload: this.jobInstance.annotations.upload,
            // select: this.jobInstance.annotations.select,
            // import: this.jobInstance.annotations.import,
            // export: this.jobInstance.annotations.export,

            // statistics: this.statistics,
            // hasUnsavedChanges: this.jobInstance.annotations.hasUnsavedChanges,
            // exportDataset: this.jobInstance.annotations.exportDataset,
            // sub_get: this.jobInstance.annotations.sub_get,
            // sub_put: this.jobInstance.annotations.sub_put,
            // sub_projection: this.jobInstance.annotations.sub_projection,
            // exportTaskAnnotations: this.jobInstance.annotations.exportTaskAnnotations,

            get: this.getAnnotationsInfo,
            getTags: this.getTagsInfo,
            put: this.annotationsPut,
            save: this.annotationsSave,
            merge: () => {},
            split: () => {},
            group: () => {},
            clear: () => {},
            search: () => {},
            searchEmpty: () => {},
            upload: () => {},
            select: this.annotationsSelect,
            import: () => {},
            export: () => {},

            statistics: this.statistics,
            hasUnsavedChanges: () => {
                return hasUnsavedChanges(this);
            },
            exportDataset: () => {},
            sub_get: this.annotationsSubGet,
            sub_put: this.annotationsSubPut,
            sub_projection: this.annotationsSubProjection,
            exportTaskAnnotations: this.exportTaskAnnotations,
        };

        this.actions = {
            // undo: this.jobInstance.actions.undo,
            // redo: this.jobInstance.actions.redo,
            // freeze: this.jobInstance.actions.freeze,
            // clear: this.jobInstance.actions.clear,
            // get: this.jobInstance.actions.get,
            undo: this.actionsUndo,
            redo: this.actionsRedo,
            freeze: this.actionsFreeze,
            clear: this.actionsClear,
            get: () => {
                return getActions(this);
            },
        };

        this.frames = {
            get: this.getFrameData,
            // get: this.jobInstance.frames.get,
            // ranges: this.jobInstance.frames.ranges,
            // preview: this.jobInstance.frames.preview,
            // contextImage: this.jobInstance.frames.contextImage,
            ranges: () => {},
            preview: () => {},
            contextImage: () => {},
        };

        this.logger = {
            // log: this.jobInstance.logger.log,
            log: async (logType: LogType, payload: any, wait: boolean) => {
                const result = await loggerStorage.log(
                    logType,
                    {
                        ...payload,
                        task_id: this.taskId,
                        job_id: this.id,
                    },
                    wait,
                );
                return result;
            },
        };

        this.predictor = {
            // status: this.jobInstance.predictor.status,
            // predict: this.jobInstance.predictor.predict,
            status: () => {},
            predict: () => {},
        };

        this._cache.update({
            startFrame: this.startFrame,
            stopFrame: this.stopFrame,
            frameMeta: this.frameMeta,
        });

        Object.entries(frameMetas).forEach(([frame, meta]) => {
            this.frameMeta.set(+frame, meta);
        });
        // this.getFrameInfos(frameMetas);
        this.init();
    }

    public get engineTask() {
        console.warn('即将删除engineTask：');
        return this.task;
    }

    public get engineProject() {
        console.warn('即将删除engineProject：');
        return this.project;
    }

    // 内参
    public get innerParam(): number[][] {
        try {
            let innerParam = this.task.innerParam || this.project.innerParam;

            if (typeof innerParam === 'string') {
                innerParam.trim();
                innerParam = innerParam.replace(/[\r\n]/g, '');
                return JSON.parse(innerParam);
            } else if (innerParam) {
                return innerParam;
            }
        } catch (error) {
            throw new Error('内参格式出错！');
        }
        return [];
    }
    // 外参
    public get outerParam(): number[][] {
        try {
            let outerParam = this.task.outerParam || this.project.outerParam;
            if (typeof outerParam === 'string') {
                outerParam.trim();
                outerParam = outerParam.replace(/[\r\n]/g, '');
                return JSON.parse(outerParam);
            } else if (outerParam) {
                return outerParam;
            }
        } catch (error) {
            throw new Error('外参格式出错！');
        }
        return [];
    }
    // 外参是否求逆
    public get outerReverse(): boolean[] {
        try {
            let outerReverse = this.task.outerReverse || this.project.outerReverse;
            if (typeof outerReverse === 'string') {
                outerReverse.trim();
                outerReverse = outerReverse.replace(/[\r\n]/g, '');
                return JSON.parse(outerReverse);
            } else if (outerReverse) {
                return outerReverse;
            }
        } catch (error) {
            throw new Error('是否需要逆转外参参数出错！');
        }
        return [];
    }
    // 相机类型
    public get cameraTypes(): CameraType[] {
        try {
            let cameraParam = this.task.cameraParam || this.project.cameraParam;
            cameraParam.trim();
            if (typeof cameraParam === 'string') {
                cameraParam = cameraParam.replace(/[\r\n]/g, '');
                return JSON.parse(cameraParam);
            } else if (cameraParam) {
                return cameraParam;
            }
        } catch (error) {
            throw new Error('相机类型参数出错！');
        }
        return [];
    }
    // 相机类型
    public get distortionParam(): number[][] {
        try {
            let distortionParam = this.task.distortionParam || this.project?.distortionParam;
            if (typeof distortionParam === 'string') {
                distortionParam.trim();
                distortionParam = distortionParam.replace(/[\r\n]/g, '');
                return JSON.parse(distortionParam);
            } else if (distortionParam) {
                return distortionParam;
            }
        } catch (error) {
            throw new Error('畸变参数格式出错！');
        }
        return [];
    }
    public get labelRange(): number[] {
        let labelRange: number[] | string = [];
        try {
            labelRange = this.task.labelRange || this.project.labelRange;
            if (typeof labelRange === 'string') {
                if (labelRange.includes('[')) {
                    labelRange = JSON.parse(labelRange) as number[];
                } else if (labelRange.includes(',')) {
                    labelRange = labelRange.split(',').map((item) => +item);
                } else if (labelRange.includes(';')) {
                    labelRange = labelRange.split(';').map((item) => +item);
                } else {
                    labelRange = [+labelRange];
                }
                return labelRange;
            } else if (typeof labelRange === 'number') {
                labelRange = [labelRange];
            } else {
                labelRange = [];
            }
            return labelRange;
        } catch (error) {
            labelRange = [];
        }
        return labelRange;
        // return this.task.labelRange;
    }

    public get labelEngle(): number {
        if (typeof this.task.labelEngle === 'string') {
            return +this.task.labelEngle;
        } else if (typeof this.task.labelEngle === 'number') {
            return this.task.labelEngle;
        }
        return 0;
    }

    public get startRadian(): number {
        if (typeof this.task.startRadian === 'string') {
            return +this.task.startRadian;
        } else if (typeof this.task.startRadian === 'number') {
            return this.task.startRadian;
        }
        return 0;
        // return +this.task.startRadian;
    }

    public get direction(): string[] {
        const { direction } = this.project;
        if (Array.isArray(direction)) {
            return direction;
        } else if (direction && typeof direction === 'string') {
            if ((direction as string).startsWith('[')) {
                return JSON.parse(direction);
            } else if ((direction as string).includes(',')) {
                return (direction as string).split(',');
            }
            return [direction];
        }
        return [];
    }

    public async submit(data: any) {
        return submitJob(data);
        // return this.jobInstance.submit(...props);
    }

    public async save(...props: any) {
        message.error('标注工具应该没有这个功能！');
        // return this.jobInstance.save(...props);
    }

    public async delete(...props: any) {
        message.error('标注工具应该没有这个功能！');
        // return this.jobInstance.delete(...props);
    }

    public annotationsSelect = (objectStates: ObjectState[], x: number, y: number) => {
        return selectObject(this, objectStates, x, y);
    };

    public annotationsPut = (objcectStates: ObjectState[]) => {
        return putAnnotations(this, objcectStates);
    };

    public export() {
        exportAnnotations(this);
        // return this.jobInstance.export(...props);
    }

    public async import(data: any) {
        return importAnnotations(this, data);
        // return this.jobInstance.import(...props);
    }

    public async saveExportData(...props: any) {
        message.error('标注工具这个功能应该已经不需要！');
        // return this.jobInstance.saveExportData(...props);
    }

    public async reSaveData3D(...props: any) {
        message.error('标注工具这个功能应该已经不需要！');
        // return this.jobInstance.reSaveData3D(...props);
    }

    public async issues(...props: any) {
        // return this.jobInstance.issues(...props);
        return AnnotationHubHelper.getInstance().issue.get(this.id);
    }

    public async openIssue(issue: any, message: string) {
        // return this.jobInstance.openIssue(...props);
        return AnnotationHubHelper.getInstance().issue.createIssue(issue, message);
    }

    public async beforehandIssues(...props: any) {
        return this.jobInstance.beforehandIssues(...props);
    }

    public async getAnnotations(
        frameNumber: number,
        showAllInterpolationTracks: boolean,
        filters: any = {},
        cameraName?: string,
    ) {
        // return this.jobInstance.getAnnotations(...props);
        return await getAnnotations(this, frameNumber, showAllInterpolationTracks, filters, cameraName);
    }

    public init() {
        // 赋予视角值
        const frameMeta = this.frameMeta.get(this.startFrame);
        if (
            this.project.projectType === ProjectType.two &&
            this.project.projectDumpType === ProjectDumpType.lane &&
            frameMeta
        ) {
            this.cams = frameMeta.imageNames ? [...frameMeta.imageNames] : [];
            this.cameraName = this.cams?.[0];
        }

        // 根据方向重新排序图片名称列表
        if (this.direction && this.direction.length) {
            // 图片名称是否包含了方向
            Object.entries(this.frameMeta).forEach(([frame, frameMeta]) => {
                const imageNames = [...frameMeta.imageNames];
                this.direction.forEach((directionName, num) => {
                    const imageName = imageNames.find((name) => name.includes(directionName));

                    frameMeta.imageNames[num] = imageName!;
                });
            });
            // const newFileImgs = this.direction
            //     .map((dir: string) => frameInfo.fileImgs.find((imageStr: string) => imageStr.includes(dir)))
            //     .filter((str) => str);
            // // 新的方向是否有值，有的话则启用
            // if (newFileImgs?.length) {
            //     frameInfo.fileImgs = newFileImgs;
            // }
        }
        // if (!Job.isNotOldTask(this.taskId)) {
        //     return;
        // }
        // 获取帧信息
        // this.getFrameInfos();

        // this._cache = new CacheFiles({
        //     startFrame: this.startFrame,
        //     stopFrame: this.stopFrame,
        //     frameInfo: this.frameInfos,

        //     projectDumpType: this.project?.projectDumpType,
        //     // job: this,
        // });

        // console.log('处理了：', this.frameInfos);
        // this.initFrameData();
    }

    private getFrameInfos = (infos: any[]) => {
        try {
            // const infos = await queryFramesInfo({ jobId: this.id });
            if (Array.isArray(infos) && infos.length) {
                infos.forEach((frameInfo: any) => {
                    // 处理名称
                    // if (frameInfo.fileImg) {
                    frameInfo.fileImgs = frameInfo.fileImg?.split(',') || [];
                    // 2D车道线才需要
                    if (
                        this.project.projectType === ProjectType.two &&
                        this.project.projectDumpType === ProjectDumpType.lane
                    ) {
                        this.cams = [...frameInfo.fileImgs];
                        this.cameraName = this.cameraName || this.cams[0];
                    }
                    // }
                    // if (frameInfo.filePcd) {
                    frameInfo.filePcds = frameInfo.filePcd?.split(',') || [];
                    // }

                    // 反序列化相机参数
                    frameInfo.param = JSON.parse(frameInfo.param || JSON.stringify({}));

                    // 根据方向重新排序图片名称列表
                    if (this.direction && this.direction.length) {
                        // 图片名称是否包含了方向
                        const newFileImgs = this.direction
                            .map((dir: string) => frameInfo.fileImgs.find((imageStr: string) => imageStr.includes(dir)))
                            .filter((str) => str);
                        // 新的方向是否有值，有的话则启用
                        if (newFileImgs?.length) {
                            frameInfo.fileImgs = newFileImgs;
                        }
                    }

                    if (frameInfo.param) {
                        for (const key in frameInfo.param) {
                            if (frameInfo.param[key].distcoeff.length) {
                                frameInfo.param[key].type = CameraType.buttonhole;
                            }
                        }
                    }
                    this.frameInfos[frameInfo.frameIndex] = frameInfo;
                });
                return this.frameInfos;
            }
            return [];
        } catch (error) {
            throw new Error(`获取帧信息失败-${(error as Error).message}`);
        }
    };

    public isNotOldTask = (): boolean => {
        return Job.isNotOldTask(this.taskId);
    };

    /**
     * 已知旧数据使用原有逻辑
     * @returns
     */
    public static isNotOldTask = (taskId: number): boolean => {
        return ![
            1389, 1390, 1391, 1392, 1393, 1394, 1395, 1396, 1397, 1398, 1401, 1404, 1405, 1389, 1390, 1391, 1392, 1377,
            1378, 1379, 1380, 1381, 1382, 1383, 1384, 1385, 1386, 1399, 1402, 1403, 1410, 1414, 1387, 1407, 1408, 195,
            193,
        ].includes(taskId);
    };

    /**
     * 获取FrameData
     * @returns
     */
    public getFrameData = async (frame: number, isPlaying: boolean = false, step: number = 1) => {
        if (!Job.isNotOldTask(this.taskId)) {
            return await this.jobInstance.frames.get(frame, isPlaying, step);
        }

        // const frameInfo = this.frameInfos[frame];

        // const newframeData = new FrameData({
        //     frameInfo,
        //     dimension: this.dimension,
        //     number: frame,
        //     cache: this._cache!,
        //     startFrame: this.startFrame,
        //     stopFrame: this.stopFrame,
        //     // hasRelatedContext,
        //     job: this,
        // });

        return this.frameMeta.get(frame)!;
    };

    // private initFrameData = async () => {
    //     for (let frame = this.startFrame; frame <= this.stopFrame; frame++) {
    //         const frameInfo = this.frameInfos[frame];
    //         const newframeData = new FrameData({
    //             frameInfo,
    //             dimension: this.dimension,
    //             // jid: this.id,
    //             // width: 0,
    //             // height: 0,
    //             number: frame,
    //             cache: this._cache!,
    //             startFrame: this.startFrame,
    //             stopFrame: this.stopFrame,
    //             // hasRelatedContext,
    //             job: this,
    //         });
    //     }
    // };

    public getCameraParams = (frame: number) => {
        const frameInfo = this.frameMeta.get(frame);
        // if (!Job.isNotOldTask(this.taskId) || this.innerParam.length) {
        //     return {
        //         intrinsics: this.innerParam,
        //         extrinsics: this.outerParam,
        //         distcoeffs: (this.distortionParam as number[][]).map((item: number[], index: number) => ({
        //             cameraType: this?.cameraTypes[index],
        //             k1: item?.[0],
        //             k2: item?.[1],
        //             k3: item?.[2],
        //             p1: item?.[3],
        //             p2: item?.[4],
        //             k4: item?.[5],
        //             k5: item?.[6],
        //             k6: item?.[7],
        //             s1: item?.[8],
        //             s2: item?.[9],
        //         })),
        //         needInvert: this.outerReverse,
        //     };
        // }

        const intrinsics: number[][] = [];
        const extrinsics: number[][] = [];
        const distcoeffs: Distcoeff[] = [];
        const needInvert: boolean[] = [];

        if (this.innerParam?.length) {
            return {
                intrinsics: this.innerParam,
                extrinsics: this.outerParam,
                distcoeffs: (this.distortionParam as number[][]).map((item: number[], index: number) => ({
                    cameraType: this?.cameraTypes[index],
                    k1: item?.[0],
                    k2: item?.[1],
                    k3: item?.[2],
                    p1: item?.[3],
                    p2: item?.[4],
                    k4: item?.[5],
                    k5: item?.[6],
                    k6: item?.[7],
                    s1: item?.[8],
                    s2: item?.[9],
                })),
                needInvert: this.outerReverse,
            };
        } else if (frameInfo) {
            if (Object.keys(frameInfo.param).length) {
                this.direction.forEach((cameraName: string) => {
                    intrinsics.push(frameInfo.param[cameraName].intrinsic.flat());
                    extrinsics.push(frameInfo.param[cameraName].extrinsic.flat());

                    const distcoeff = frameInfo.param[cameraName].distcoeff.flat();
                    distcoeffs.push({
                        cameraType:
                            frameInfo.param[cameraName].distortion_model === 'fisheye'
                                ? CameraType.fisheye
                                : CameraType.buttonhole,
                        k1: distcoeff?.[0],
                        k2: distcoeff?.[1],
                        p1: distcoeff?.[2],
                        p2: distcoeff?.[3],
                        k3: distcoeff?.[4],
                        k4: distcoeff?.[5],
                        k5: distcoeff?.[6],
                        k6: distcoeff?.[7],
                        s1: distcoeff?.[8],
                        s2: distcoeff?.[9],
                    });
                    needInvert.push(false);
                });
            } else {
                return {
                    intrinsics: this.innerParam,
                    extrinsics: this.outerParam,
                    distcoeffs: (this.distortionParam as number[][]).map((item: number[], index: number) => ({
                        cameraType: this?.cameraTypes[index],
                        k1: item?.[0],
                        k2: item?.[1],
                        k3: item?.[2],
                        p1: item?.[3],
                        p2: item?.[4],
                        k4: item?.[5],
                        k5: item?.[6],
                        k6: item?.[7],
                        s1: item?.[8],
                        s2: item?.[9],
                    })),
                    needInvert: this.outerReverse,
                };
            }
        }

        return {
            intrinsics,
            extrinsics,
            distcoeffs,
            needInvert,
        };
    };

    // 更改后需要重新手动获取标注数据
    changeCamera = async (cameraName: string, frame: number = this.startFrame) => {
        // this.jobInstance.cameraName = cameraName;
        // if (cache.has(frame) && this.cameraName !== cameraName) {
        this.cameraName = cameraName;
        //     const frameInfo = this.frameInfos[frame];
        //     const frameData = cache.get(frame);
        //     if (frameData) {
        //         // frameData.onServerRequest = onServerRequest.bind(frameData, frameInfo, this.dimension, cameraName);
        //         frameData.clear();
        //         cache.set(frame, frameData);
        //         await frameData.data();
        //     }
        // }
        // this.jobInstance.annotations.changeCamera(cameraName);
        await changeCamera(this, cameraName);
    };

    // 获取标注列表
    getAnnotationsInfo = async (
        frameNumber: number,
        showAllInterpolationTracks = false,
        filters = [],
        cameraName?: string,
    ) => {
        return await AnnotationHubHelper.getInstance().annotations.get(
            frameNumber,
            showAllInterpolationTracks,
            filters,
            cameraName || this.cameraName,
        );
        // console.log('获取相机');
        return await this.jobInstance.annotations.get(
            frameNumber,
            showAllInterpolationTracks,
            filters,
            cameraName || this.cameraName,
        );
    };

    getTagsInfo = async (frameNumber: number, filters = [], cameraName?: string) => {
        return await AnnotationHubHelper.getInstance().annotations.getTags(
            frameNumber,
            filters,
            cameraName || this.cameraName,
        );
    };

    // 前端日志
    saveWebLogs = async (data: WebLogInfo) => {
        await saveWebLogs(data);
        // await this.jobInstance.saveWebLogs(data);
    };

    // 统计
    statistics = async () => {
        if (!Job.isNotOldTask(this.taskId)) {
            console.log('获取标注统计列表：');
        }
        const oldStatistics = await annotationsStatistics(this);
        return oldStatistics;
    };

    getPresetsIssues = async () => {
        return await AnnotationHubHelper.getInstance().issue.getPresetsIssues(this.projectId);
    };

    getIssues = async () => {
        return await AnnotationHubHelper.getInstance().issue.get(this.id);
    };

    public annotationsSave = async (onUpdate: any) => {
        await saveAnnotations(this, onUpdate);
    };

    public annotationsSubGet = async (frame: number, allTracks: boolean, filters: any[]) => {
        if (!Array.isArray(filters)) {
            throw new ArgumentError('筛选条件必须是数组');
        }

        if (!Number.isInteger(frame)) {
            throw new ArgumentError('frame必须是数字类型');
        }

        if (frame < this.startFrame || frame > this.stopFrame) {
            throw new ArgumentError(`帧 ${frame} 在当前题包中不存在`);
        }

        const annotationsData = await getSubAnnotations(this, frame, allTracks, filters);
        return annotationsData;
    };

    public annotationsSubPut = (objectStates: ObjectState[]) => {
        return putSubAnnotations(this, objectStates);
    };

    public annotationsSubProjection = (objectStates: ObjectState[], directions: string[], get2DPoints: Function) => {
        return projectionSubAnnotations(this, objectStates, directions, get2DPoints);
    };

    public annotationsSubAssociated = (objectStates: ObjectState[], clientID: number) => {
        associatedAnnotations(this, objectStates, clientID);
    };

    public actionsUndo = (count?: number) => {
        return undoActions(this, count);
    };

    public actionsRedo = (count?: number) => {
        return redoActions(this, count);
    };

    public actionsFreeze = (frozen: boolean) => {
        return freezeHistory(this, frozen);
    };

    public actionsClear = () => {
        return clearActions(this);
    };

    public actionsGet = () => {
        return getActions(this);
    };

    public exportTaskAnnotations = async (data: any) => {
        return await api_exportTaskAnnotations(data);
    };

    public saveMultAttribute = async (
        data: Record<number, Record<number, string>>,
        frames: { startFrame: number; stopFrame: number },
    ) => {
        await saveMultAttribute(this, data, frames);
    };

    public loggerLog = async (logType: LogType, payload: any, wait: boolean) => {
        const result = await loggerStorage.log(
            logType,
            {
                ...payload,
                task_id: this.taskId,
                job_id: this.id,
            },
            wait,
        );
        return result;
    };

    public async close(...props: any) {
        if (this._cache) {
            this._cache.destory();
        }

        closeSession(this);
        // this.fr = {};
        if (this.jobInstance?.close) {
            this.jobInstance?.close(...props);
        }

        // return this.jobInstance?.close(...props);
    }
}
export default Job;
