import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColorBy, CombinedState, ObjectType } from 'reducers/interfaces';
import { Layout } from 'antd';
import { Canvas } from 'cvat-canvas-wrapper';
import consts from 'consts';
import { activateObject, confirmCanvasReady } from 'actions/annotation-actions';
import 'styles.scss';

const MAX_DISTANCE_TO_OPEN_SHAPE = 50;

const CanvasWorkSpace = () => {

    const dispatch = useDispatch();

    const {
        jobInstance,
        canvasInstance,

        automaticBordering,
        intelligentPolygonCrop,
        showObjectsTextAlways,
        workspace,
        showProjections,
        selectedOpacity,
        smoothImage,
        textFontSize,
        textPosition,
        textContent,


        brightnessLevel,
        contrastLevel,
        saturationLevel,

        activatedStateID,
        annotations,

        opacity,
        colorBy,
        outlined,
        outlineColor,

        frameData,
        isOnlyShowSelectStates,
        curZLayer,
        canvasIsReady,
    } = useSelector((state: CombinedState) => ({
        jobInstance: state.annotation.job.instance,
        canvasInstance: state.annotation.canvas.instance as Canvas,

        automaticBordering: state.settings.workspace.automaticBordering,
        intelligentPolygonCrop: state.settings.workspace.intelligentPolygonCrop,
        showObjectsTextAlways: state.settings.workspace.showObjectsTextAlways,
        workspace: state.annotation.workspace,
        showProjections: state.settings.shapes.showProjections,
        // selectedOpacity: state.settings.shapes.selectedOpacity / 100,
        selectedOpacity: 50 / 100,
        smoothImage: state.settings.player.smoothImage,
        textFontSize: state.settings.workspace.textFontSize,
        textPosition: state.settings.workspace.textPosition,
        textContent: state.settings.workspace.textContent,

        brightnessLevel: state.settings.player.brightnessLevel / 100,
        contrastLevel: state.settings.player.contrastLevel / 100,
        saturationLevel: state.settings.player.saturationLevel / 100,

        activatedStateID: state.annotation.annotations.activatedStateID,
        annotations: state.annotation.annotations.states,

        // opacity: state.settings.shapes.opacity / 100,
        opacity: 10 / 100,
        colorBy: state.settings.shapes.colorBy,
        outlined: state.settings.shapes.outlined,
        outlineColor: state.settings.shapes.outlineColor,

        frameData: state.annotation.player.frame.data,
        isOnlyShowSelectStates: state.annotation.annotations.isOnlyShowSelectStates,
        curZLayer: state.annotation.annotations.zLayer.cur,

        canvasIsReady: state.annotation.canvas.ready,
    }))

    const [mouseMoveClientID, setMouseMoveClientID] = useState<number | null>(null);

    const onSetupCanvas = async () => {
        await dispatch(confirmCanvasReady());
    }

    const onActivateObject = async (selectID: number | null) => {
        await dispatch(activateObject(selectID, null));
    }

    const initialView = () => {
        const [wrapper] = window.document.getElementsByClassName('aatp-canvas-container');
        wrapper.appendChild(canvasInstance.html());

        canvasInstance.configure({
            smoothImage,
            autoborders: automaticBordering,
            undefinedAttrValue: consts.UNDEFINED_ATTRIBUTE_VALUE,
            displayAllText: false,
            forceDisableEditing: true,
            intelligentPolygonCrop,
            showProjections,
            creationOpacity: selectedOpacity,
            textFontSize,
            textPosition,
            textContent,
            isSegmentation: jobInstance?.engineTask?.engineProject?.projectDumpType === 6,
        });
        canvasInstance.isShowAttribute(false);
    }

    const fitCanvas = (): void => {
        if (canvasInstance) {
            canvasInstance.fitCanvas();
        }
    };

    const initialSetup = () => {
        // Size
        fitCanvas();
    }

    const updateShapesView = () => {
        for (const state of annotations) {
            let shapeColor = '';

            if (colorBy === ColorBy.INSTANCE) {
                shapeColor = state.color;
            } else if (colorBy === ColorBy.GROUP) {
                shapeColor = state.group.color;
            } else if (colorBy === ColorBy.LABEL) {
                shapeColor = state.label.color;
            }

            // TODO: In this approach CVAT-UI know details of implementations CVAT-CANVAS (svg.js)
            const shapeView = window.document.getElementById(`cvat_canvas_shape_${state.clientID}`);
            if (shapeView) {
                const handler = (shapeView as any).instance.remember('_selectHandler');
                if (handler && handler.nested) {
                    handler.nested.fill({ color: shapeColor });
                }

                (shapeView as any).instance.fill({ color: shapeColor, opacity });
                (shapeView as any).instance.stroke({ color: outlined ? outlineColor : shapeColor });
            }
        }
    }


    const activateOnCanvas = (selectID: number | null, isSelect: boolean) => {
        if (isSelect && selectID !== null) {
            const [activatedState] = annotations.filter((state: any): boolean => state.clientID === selectID);
            if (activatedState) {
                canvasInstance.activate(selectID);
            }
            const el = window.document.getElementById(`cvat_canvas_shape_${selectID}`);
            if (el) {
                (el as any as SVGElement).setAttribute('fill-opacity', `${selectedOpacity}`);
            }
        } else if (selectID) {
            const [activatedState] = annotations.filter((state: any): boolean => state.clientID === selectID);
            if (activatedState) {
                canvasInstance.activate(selectID);
            }
            const el = window.document.getElementById(`cvat_canvas_shape_${selectID}`);
            if (el) {
                (el as any as SVGElement).setAttribute('fill-opacity', `${opacity}`);
            }
        }
    }


    const checkedSelect = (object: any, selectID: number | null): boolean => {
        return object.clientID === selectID;
    }

    const canvasSetup = () => {
        onSetupCanvas();
        // updateShapesView();
        // activateOnCanvas(activatedStateID, true);
    }

    const updateCanvas = () => {
        if (frameData !== null && canvasInstance) {
            const objects = annotations.filter((e) => {
                if (e.objectType === ObjectType.TAG) {
                    return false;
                }
                if (isOnlyShowSelectStates && !checkedSelect(e, activatedStateID)) {
                    return false;
                }
                return true;
            });

            canvasInstance.setup(frameData, objects, curZLayer);
        }
    }

    const onCanvasCursorMoved = async (event: any) => {
        const { states, x, y } = event.detail;
        const result = await jobInstance.annotations.select(states, x, y);
        if (result && result.state) {
            if (result.state.shapeType === 'polyline' || result.state.shapeType === 'points') {
                if (result.distance > MAX_DISTANCE_TO_OPEN_SHAPE) {
                    return;
                }
            }

            setMouseMoveClientID(oldClientID => {
                if (oldClientID !== result.state.clientID) {
                    return result.state.clientID;
                }
                return oldClientID;
            })
            // if (activatedStateID !== result.state.clientID) {

            //     onActivateObject(result.state.clientID);
            //     // const { annotations, onCollapseStates } = this.props;
            //     // const objectState = annotations.filter((item: any) => item.clientID === result.state.clientID);
            //     // onCollapseStates(objectState || [], false);
            // }
        }
    }

    const onCanvasClicked = async (event: any) => {
        // onUpdateContextMenu(false, 0, 0, ContextMenuType.CANVAS_SHAPE);
        if (!canvasInstance.html().contains(document.activeElement) && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
        if (event?.target?.tagName === 'svg') {
            setMouseMoveClientID(null);
        }
    }

    // 初始化
    useEffect(() => {
        initialView();
        initialSetup();

        const canvasView = canvasInstance.html();
        window.addEventListener('resize', fitCanvas);
        canvasView.addEventListener('canvas.setup', canvasSetup);
        canvasView.addEventListener('canvas.moved', onCanvasCursorMoved);
        canvasView.addEventListener('click', onCanvasClicked);
        // canvasView.addEventListener('canvas.fit', onCanvasImageFitted);
        return () => {
            window.removeEventListener('resize', fitCanvas);
            canvasView.removeEventListener('canvas.setup', canvasSetup);
            // Events
            canvasView.removeEventListener('canvas.moved', onCanvasCursorMoved);
            canvasView.removeEventListener('click', onCanvasClicked);
            // canvasView.removeEventListener('canvas.fit', onCanvasImageFitted);
        }
    }, []);

    useEffect(() => {
        const canvasView = canvasInstance.html();
        canvasView.addEventListener('canvas.setup', () => {
            canvasInstance.fit();
        }, { once: true });
    }, [])

    // 初始化对象
    useEffect(() => {
        updateCanvas();
    }, [frameData, annotations])

    useLayoutEffect(() => {
        if (canvasIsReady) {
            updateShapesView();
        }
    }, [frameData, colorBy, outlined, outlineColor, opacity, canvasIsReady])

    useEffect(() => {
        onActivateObject(mouseMoveClientID);
    }, [mouseMoveClientID]);

    useEffect(() => {
        if (canvasIsReady && activatedStateID) {
            activateOnCanvas(activatedStateID, true);
        }
        return () => {
            activateOnCanvas(activatedStateID, false);
        }
    }, [canvasIsReady, activatedStateID, opacity, selectedOpacity])

    // 亮度，对比度，饱和度
    useEffect(() => {
        // Filters
        const backgroundElement = window.document.getElementById('cvat_canvas_background');
        if (backgroundElement) {
            const filter = `brightness(${brightnessLevel}) contrast(${contrastLevel}) saturate(${saturationLevel})`;
            backgroundElement.style.filter = filter;
        }

    }, [brightnessLevel, contrastLevel, saturationLevel]);

    return <>
        <Layout.Content style={{ position: 'relative', height: '100%' }}>
            <div
                className='aatp-canvas-container'
                style={{
                    overflow: 'hidden',
                    width: '100%',
                    height: '100%',
                }}
            />
        </Layout.Content>
    </>
}

export default CanvasWorkSpace;