/*
 * @Author: swxy
 * @Date: 2023-05-04 15:02:10
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
        //#region 2023-05-04 待校验
        'userNotice': `
        一、概要

        1、必ず本「ユーザー規約」（以下、「規約」とする）に定められた全ての権利と制限を真剣に読み理解してください。

        2、明示的に規定されていない限り、idatalabプラットフォームが提供する新機能、新サービスは、全てこの規約に従って使用する必要があります。

        3、idatalabプラットフォームは、規約の条項をいつでも変更する権利を保有しており、別途通知する必要はありません。変更後は直ちに有効となり、サービスを使用する際には、ユーザーは注意を払って従う必要があります。この変更に同意しない場合は、本プラットフォームの使用またはアクセスを放棄する必要があります。変更後に本プラットフォームを継続して使用することを選択した場合は、ユーザーがすべての追加または変更内容を承認したものとみなされます。



        二、ユーザー登録とプライバシーに関する説明

        1、ユーザーは、登録手続きを行う際に「ログイン」ボタンをクリックすることで、この規約のすべての条項に完全に同意したものとみなされます。

        2、ユーザーは、本プラットフォームのサービスを使用する際に、アップロードするデータが合法かつ規制に適合していることを保証する必要があります。アップロードされたデータによって発生した問題に対して、ユーザー自身が完全に責任を負い、idatalabプラットフォームは一切の責任を負いません。

        3、idatalabプラットフォームを登録および使用する際に、プラットフォームは、ユーザーの個人情報およびデータを提示、収集、保存することがあります。プラットフォームは、ユーザーの情報やデータを公開したり、第三者に提供することはないことを約束します。



        三、サービス内容

        1. idatalabプラットフォームはユーザーに無料でサービスを提供し、ユーザーがデータをアップロードする際に発生するトラフィック料金は、ユーザーが自己負担するものとします。

        2. 本サービス規約に明示されていない限り、当プラットフォームが提供する新製品、新機能、新サービスは、すべて本サービス規約に準拠します。



        四、サービスの変更、中断、または終了

        1. idatalabプラットフォームは、通知の有無にかかわらず、いつでもシステムの点検またはメンテナンスを行う権利を有します。

        2. ネットワークサービスの特性を考慮して（サーバーの安定性の問題、悪意のあるネットワーク攻撃など、idatalabプラットフォームが制御できない状況を含む）、idatalabプラットフォームは、通知することなく、一部またはすべてのサービスを中断または停止する権利を有します。



        五、ユーザーの利用規約

        1. ユーザーは、本プラットフォームのサービス規約の各規定を遵守するものとします。

        2. ユーザーは、自身のアカウントで行われたあらゆる活動やイベントについて法的責任を負うものとします。

        3. ユーザーは、いかなる不正な目的にも利用できず、本プラットフォームのシステムサービスを不正に活用してはなりません。

        4. ユーザーは、本プラットフォームのサービスの一部またはすべてを、複製、コピー、販売、転売、または他の商業目的に使用してはなりません。

        5. idatalabは、個人ユーザーの同意を得ることなく、ユーザーがアップロードしたデータとその自動化結果を期限付きで自動的に削除する権利を有します。



        六、免責事項

        1. 本ソフトウェアはインターネットサービスを使用する特別な性質を有するため、インターネットサービスが必ずしもユーザーの要求を満たすことを保証せず、また、インターネットサービスが中断されないこと、及びインターネットサービスのタイムリー性、安全性、正確性についても保証しないものとします。

        2. 通信システム、インターネットネットワークの障害、コンピューター障害、コンピューターシステムの問題、その他の不可抗力により生じた損失に対して、当社は一切責任を負わないものとし、ただし、ユーザーに生じる損失や影響を最小限に抑えるために最大限の努力を行うものとします。

        七、本規約の最終的な解釈権は、idatalabプラットフォームが保有します。
`
        //#endregion
}