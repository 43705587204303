/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

// import CameraControls from 'camera-controls';

const BASE_GRID_WIDTH = 2;
const MOVEMENT_FACTOR = 200;
const DOLLY_FACTOR = 5;
const MAX_DISTANCE = 500;
const MIN_DISTANCE = 0.3;
const ZOOM_FACTOR = 7;
const Camera_ZOOM_FACTOR = 20;
const ROTATION_HELPER_OFFSET = 0.1;
const CAMERA_REFERENCE = 'camRef';
const CUBOID_EDGE_NAME = 'edges';
const ROTATION_HELPER = 'rotationHelper';
const ROTATION_SPEED = 80;
const ROTATION_FINETUNING_SPEED = 400;
const FOV_DEFAULT = 1;
// const FOV_MAX = 2;
const FOV_MAX = 10;
const FOV_MIN = 0;
const FOV_INC = 0.08;
const BASE_STROKE_WIDTH = 1.25;

export const helper_radius = 0.3;

// 设置相机为不可移动
// export const setNoneControls = (controls: CameraControls): void => {
//     controls.mouseButtons.left = CameraControls.ACTION.NONE;
//     controls.mouseButtons.right = CameraControls.ACTION.NONE;
//     controls.mouseButtons.wheel = CameraControls.ACTION.NONE;
//     controls.touches.one = CameraControls.ACTION.NONE;
//     controls.touches.two = CameraControls.ACTION.NONE;
//     controls.touches.three = CameraControls.ACTION.NONE;
// };

// // 设置相机为可移动
// export const setControls = (controls: CameraControls): void => {
//     controls.mouseButtons.left = CameraControls.ACTION.ROTATE;
//     controls.mouseButtons.right = CameraControls.ACTION.TRUCK;
//     controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
//     controls.touches.one = CameraControls.ACTION.TOUCH_ROTATE;
//     controls.touches.two = CameraControls.ACTION.TOUCH_DOLLY_TRUCK;
//     controls.touches.three = CameraControls.ACTION.TOUCH_TRUCK;
// };

export default {
    BASE_GRID_WIDTH,
    MOVEMENT_FACTOR,
    DOLLY_FACTOR,
    MAX_DISTANCE,
    MIN_DISTANCE,
    ZOOM_FACTOR,
    ROTATION_HELPER_OFFSET,
    CAMERA_REFERENCE,
    CUBOID_EDGE_NAME,
    ROTATION_HELPER,
    ROTATION_SPEED,
    ROTATION_FINETUNING_SPEED,
    FOV_DEFAULT,
    FOV_MAX,
    FOV_MIN,
    FOV_INC,

    Camera_ZOOM_FACTOR,
    BASE_STROKE_WIDTH,

    // setNoneControls,
    // setControls,
};
