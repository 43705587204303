/*
 * @Author: swxy
 * @Date: 2022-08-15 10:13:12
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from 'utils/storage';
import translation_en_US from './en_US';
import translation_zh_CN from './zh_CH';
import translation_ja_JP from './ja_JP';
import translation_de_DE from './de_DE';
// import moment from 'moment';

export const resources = {
    zh_CN: {
        translation: translation_zh_CN,
        name: translation_zh_CN.language,
    },
    en_US: {
        translation: translation_en_US,
        name: translation_en_US.language,
    },
    ja_JP: {
        translation: translation_ja_JP,
        name: translation_ja_JP.language,
    },
    de_DE: {
        translation: translation_de_DE,
        name: translation_de_DE.language,
    },
};

const defaultLanguage = getLanguage();
// if (!defaultLanguage) {
//     const spotList = ['zh_CN', 'en_US', 'ja_JP'];
//     const { language } = navigator;
// }

i18n.use(initReactI18next).init({
    resources,
    lng: defaultLanguage || 'zh_CN',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
