/*
 * @Author: swxy
 * @Date: 2023-03-15 17:51:41
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'jobs.applyJob': '申请题包',
    'jobs.apply': '申请',
    'jobs.cancel': '取消',


    'jobs.getTeamUsersFailedTip': '获取团队列表失败！',
    'jobs.getTeamTasksFailedTip': '获取任务列表失败！',
    'jobs.applyJobFailedTip': '申请题包失败！',
};
