import Job from 'business/objects/job';
import {
    CombinedState,
    ObjectType,
    SubElement,
    SubElementInfo,
    SubElementType,
    SubElementVersion,
} from 'reducers/interfaces';
import {
    DimensionType,
    LabelObjectType,
    ProjectCompanyType,
    ProjectDumpType,
    ProjectType,
    RoleType,
    ShapeObjFrType,
} from 'utils/ConstType';
import { Box3, Euler, Matrix4, Points, Quaternion, Vector3 } from 'three';
import { PCDLoader } from 'utils/PCDLoader';
import { Label } from 'business/objects/label';
import { getAmygoStore } from 'amygo-store';
import { message } from 'antd';
import ObjectState from 'business/objects/objectState';
import FrameMeta from 'business/objects/frameMeta';
import { downFrameFile } from 'service/job/frame';

// 是否跳过点云数
let skipPointCount = true;

const getJobInstance = (): Job => {
    const state: CombinedState = getAmygoStore().getState();
    return state.annotation.job.instance;
};

const getUserInfo = (): { roleType: number } => {
    const state: CombinedState = getAmygoStore().getState();
    return state.auth.user;
};

const getJobInfo = () => {
    const jobInstance = getJobInstance();
    return {
        startFrame: jobInstance.startFrame,
        stopFrame: jobInstance.stopFrame,
    };
};

let errorMsg = '';

const numPointsOfShapeObjFrType: Record<number, number> = {
    [ShapeObjFrType.car]: 4,
    [ShapeObjFrType.truck]: 4,
    [ShapeObjFrType.bus]: 4,
    [ShapeObjFrType.pickTruck]: 4,
    [ShapeObjFrType.engineVehicle]: 4,
    [ShapeObjFrType.tricycle]: 3,
    [ShapeObjFrType.bicycle]: 2,
    [ShapeObjFrType.motorcycle]: 2,
    17: 4,
};

const getNumOfPointsByShapeObjFrType = (label: any) => {
    if (label) {
        const attribute = label.attributes.find((attr: any) => attr.name === 'shapeObjFrType');
        if (attribute) {
            const value = +attribute.defaultValue;
            if (value >= 0) {
                return numPointsOfShapeObjFrType[value];
            }
        }
    }
    return 0;
};

// const isUnkownShapeObjFrType = (label: any) => {
//     if (label) {
//         const attribute = label.attributes.find((attr: any) => attr.name === 'shapeObjFrType');
//         // 是否是未知
//         return attribute.value === 17;
//     }
//     return false;
// };

const translane2DAttrDumpName = {
    single_dash: 'single_dash',
    single_solid: 'single_solid',
    fat_dash: 'dense_wide_dash',
    ' double_solid': 'double_solid',
    double_dash: 'double_dash',
    left_dash_right_solid: 'left_dash_right_solid',
    right_dash_left_solid: 'left_solid_right_dash',
    road_curb: 'road_edge',
    other: 'others',

    yellow: 'yellow',
    white: 'white',
};

const transValue = (type: string, valueName: string) => {
    type NameType = keyof typeof translane2DAttrDumpName;
    if ((type === 'type' || type === 'color') && translane2DAttrDumpName[valueName as NameType]) {
        return translane2DAttrDumpName[valueName as NameType];
    }
    // console.warn(`转化类型：${type} 时，没有找到${valueName}对应的值。已输出原值`);
    return valueName;
};

const getAttrIdByDumpName = (label: any) =>
    label.attributes.reduce((previous: any, current: any) => {
        previous[current.dumpName] = current.id;
        return previous;
    }, {});

const getBoolean = (value: any) => {
    if (typeof value === 'boolean') {
        return value;
    }
    if (typeof value === 'string') {
        return value === 'True' || value === 'true';
    }
    return false;
};

const getDateString = (date?: string) => {
    if (date) {
        return new Date(date).toISOString().split('.')[0];
    }
    return new Date().toISOString().split('.')[0];
};

const getValueByMid = (value: number, max: number, min: number = 0) => {
    const num = Math.min(max, Math.max(min, value));
    return num;
};

const getPointsInLine = (target: number[], points: number[][] = []) => {
    const maxPoint = points[0];
    const minPoint = points[points.length - 1];

    // 超出最小最大边界不需要继续
    if (target[1] < minPoint[1]) {
        target[0] = minPoint[0];
        target[1] = minPoint[1];
        return target;
    } else if (target[1] > maxPoint[1]) {
        target[0] = maxPoint[0];
        target[1] = maxPoint[1];
        return target;
    }

    for (let index = 0; index < points.length - 1; index++) {
        const x = points[index][0];
        const y = points[index][1];

        const x2 = points[index + 1][0];
        const y2 = points[index + 1][1];

        const k = (y - y2) / (x - x2);
        const b = y - x * k;
        const minY = Math.min(y, y2);
        const maxY = Math.max(y, y2);

        if (target[1] >= minY && target[1] < maxY) {
            //y=xk+b ===>  x = (y - b)/k
            const num = target[0];
            target[0] = (target[1] - b) / k;
            // if (num.toFixed(3) !== target[0].toFixed(3)) {
            //     console.log(`更改了-更改前：${num}, 更改后：${target[0]}`)
            // }
        }
    }

    return target;
};

const isDashLine = (job: Job, label: Label, attributes: Record<number, string>) => {
    let isDash = false;
    // 2D车道线
    if (
        job.projectType === ProjectType.two &&
        job.project.projectDumpType === ProjectDumpType.lane &&
        label &&
        label.attributes &&
        attributes
    ) {
        label.attributes.forEach((attr) => {
            if (attr.name === 'type') {
                // 固定类型，判断是否为行人
                if (
                    [
                        'single_dash',
                        'fat_dash',
                        'double_dash',
                        'left_dash_right_solid',
                        'right_dash_left_solid',
                    ].includes(attributes[attr.id])
                ) {
                    isDash = true;
                }
            }
        });
    }
    return isDash;
};

const downloadPcd = async (jobId: number, frameId: number, name: string): Promise<Points> => {
    try {
        const data = await downFrameFile({ frameId, name });
        const loader = new PCDLoader();
        const url = URL.createObjectURL(data);
        const points = await loader.loadAsync(url);
        URL.revokeObjectURL(url);
        return points;
    } catch (error) {
        console.error(`下载题包${jobId}-第${frameId}失败!`);
        throw error;
    }
};

const countCloudPoints = async (
    jobId: number,
    objectInfos: Record<
        number,
        {
            clientID: number;
            box3: Box3;
            cloudPoint: number;
            matrix4: Matrix4;
        }
    >,
    frameMeta: FrameMeta,
    annotations: ObjectState[] = [],
) => {
    // 1、下载文件
    // 2、 解析pcd
    // 3、循环点数
    const cloudPoints = await downloadPcd(jobId, frameMeta.id as number, frameMeta.filePcd as string);

    const positions = cloudPoints.geometry.getAttribute('position');
    const { count } = positions;

    annotations.forEach((ann: any) => {
        const { points } = ann;
        const position = new Vector3(points[0], points[1], points[2]);

        const scale = new Vector3(points[6], points[7], points[8]);

        const rotation = new Euler(points[3], points[4], points[5]);
        const quaternion = new Quaternion().setFromEuler(rotation);

        const box3 = new Box3().setFromCenterAndSize(new Vector3(0, 0, 0), new Vector3(1, 1, 1));

        const invert = new Matrix4().compose(position, quaternion, scale).invert();
        objectInfos[ann.clientID] = {
            clientID: ann.clientID,
            box3,
            cloudPoint: 0,
            matrix4: invert,
        };
    });

    for (let index = 0; index < count; index += 1) {
        const point = new Vector3().fromBufferAttribute(positions, index);

        annotations.forEach((ann: any) => {
            const isInBox = objectInfos[ann.clientID].box3.containsPoint(
                point.clone().applyMatrix4(objectInfos[ann.clientID].matrix4),
            );
            if (isInBox) {
                objectInfos[ann.clientID].cloudPoint++;
            }
        });
    }

    return objectInfos;
};

//#region 导出3D2D融合-3D目标物
const getExport3DFrame = (frame: number, images: any[]) => {
    let exception_flag = false;

    // if (!images.length) {
    //     throw new Error(`获取帧-${frame}图像标注异常`)
    // }
    if (images.length) {
        exception_flag = getBoolean(Object.values(images[0].attributes)[0]);
    }

    const jobInstance = getJobInstance();

    return {
        basic_info: {
            annotation_date: getDateString(),
            // "annotation_provider": "AMYGO",
            annotation_provider: 'Zhimu',
        },
        ext_info: {
            exception_flag: exception_flag,
        },
    };
};

const getExport3DObject = async (annotation: any, objectInfo: any) => {
    try {
        const { points, label, clientID, attributes } = annotation;
        const rotation = new Euler(annotation.points[3], annotation.points[4], annotation.points[5]);
        const quaternion = new Quaternion().setFromEuler(rotation);

        const attrIdByDumpName = getAttrIdByDumpName(label);
        return {
            size: [points[6], points[7], points[8]],
            obj_center_pos: [points[0], points[1], points[2]],
            obj_rotation: quaternion.toArray(),
            category: label.dumpName,
            track_id: clientID,
            // object_id: getObjectId(),
            is_double_image: getBoolean(attributes[attrIdByDumpName.is_double_image]),
            num_lidar_pts: objectInfo?.cloudPoint || 0,
            num_radar_pts: -1,
            group_id: -1,
            is_group: false,
        };
    } catch (error) {
        console.error('获取3D对象错误！', annotation);
        throw error;
    }
};

const getExport3DObjects = (annotations: any[], objectInfos: any) => {
    return annotations.map((annotation: any) => {
        return getExport3DObject(annotation, objectInfos[annotation.clientID]);
    });
};

const export3DInfo = async (frame: number, frameName?: string) => {
    const cloudPointsByClientID = {};

    const jobInstance = getJobInstance();

    const annotations = await jobInstance.annotations.get(frame, false, []);

    const images = annotations.filter((ann: any) => ann.label.labelObjectType === LabelObjectType.image) || [];

    const jobInfo = getExport3DFrame(frame, images);

    const objects =
        annotations.filter(
            (ann: any) =>
                ann.label.labelObjectType !== LabelObjectType.image &&
                [ObjectType.SHAPE, ObjectType.TRACK].includes(ann.objectType),
        ) || [];

    const objectInfos: any = {};
    // let frameId = '';
    if (!skipPointCount) {
        try {
            const frameData = await jobInstance.frames.get(frame);

            const data = await frameData.data();

            const pcdBlob = data.datas[0];

            const url = URL.createObjectURL(pcdBlob.original);

            const loader = new PCDLoader();
            const points = await loader.loadAsync(url);
            URL.revokeObjectURL(url);

            const positions = points.geometry.getAttribute('position');
            const { count } = positions;

            objects.forEach((ann: any) => {
                const { points } = ann;
                const position = new Vector3(points[0], points[1], points[2]);

                const scale = new Vector3(points[6], points[7], points[8]);

                const rotation = new Euler(points[3], points[4], points[5]);
                const quaternion = new Quaternion().setFromEuler(rotation);

                const box3 = new Box3().setFromCenterAndSize(new Vector3(0, 0, 0), new Vector3(1, 1, 1));

                const invert = new Matrix4().compose(position, quaternion, scale).invert();
                objectInfos[ann.clientID] = {
                    clientID: ann.clientID,
                    box3,
                    cloudPoint: 0,
                    matrix4: invert,
                };
            });

            for (let index = 0; index < count; index += 1) {
                const point = new Vector3().fromBufferAttribute(positions, index);

                objects.forEach((ann: any) => {
                    const isInBox = objectInfos[ann.clientID].box3.containsPoint(
                        point.clone().applyMatrix4(objectInfos[ann.clientID].matrix4),
                    );
                    if (isInBox) {
                        objectInfos[ann.clientID].cloudPoint++;
                    }
                });
            }

            // console.log('结果：', objectInfos);
        } catch (error) {
            console.error('读取3D错误：', error);
            throw error;
        }
    }

    const datas = await Promise.all(getExport3DObjects(objects, objectInfos));

    const exportInfo = {
        annotator: `${jobInstance.assigneeId}`,
        annotation_date: getDateString(),
        annotation_qa: `${jobInstance.receiverId}`,
        frame_id: jobInstance.frameMeta.get(frame)?.frameName,
        check_no: jobInstance.id,
        annotated_info: {
            '3d_object_detection_info': {
                ...jobInfo,
                '3d_object_detection_anns_info': datas,
            },
        },
    };

    // console.log(`帧-${frame}结果：`, exportInfo);
    return exportInfo;
};

const export3DInfoByFrame = async () => {
    const exportInfoByFrame: any = {};

    const { startFrame, stopFrame } = getJobInfo();

    const promises = [];
    for (let frame = startFrame; frame <= stopFrame; frame++) {
        promises.push(export3DInfo(frame));
    }
    const results = await Promise.all(promises);

    // results.forEach((result) => {
    //     exportInfoByFrame[result.frame_id] = result;
    // });
    return results;
};
//#endregion

//#region 导出3D2D融合-2D目标物
const getExport2DFrame = (frame: number, images: any[]) => {
    if (!images.length) {
        throw new Error(`获取帧-${frame}图像标注异常`);
    }
    const jobInstance = getJobInstance();
    return {
        basic_info: {
            annotation_date: getDateString(),
            // "annotation_provider": "AMYGO",
            annotation_provider: 'Zhimu',
        },
        ext_info: {
            exception_flag: Object.values(images[0].attributes)[0],
        },
    };
};

const getSubElements = (elements: SubElement[], label: any, annotation: ObjectState, line_key_points?: number[][]) => {
    const jobInstance = getJobInstance();
    if (elements?.length) {
        const [element] = elements;
        if (element.shapeType === SubElementType.wheelPoint) {
            let numOfPoints = getNumOfPointsByShapeObjFrType(label);
            element.points.sort((ele1, ele2) => (ele1.order || 0) - (ele2.order || 0));
            const pointByOrder = element.points.reduce(
                (previous: Record<number, SubElementInfo>, current: SubElementInfo) => {
                    previous[+current.order!] = current;
                    return previous;
                },
                {},
            );
            const [minX, minY, maxX, maxY] = annotation.points;

            for (let index = 0; index < element.points.length; index++) {
                const point = element.points[index];
                if (point.x < minX || point.x > maxX || point.y < minY || point.y > maxY) {
                    // 有超出范围的戴安
                    errorMsg = `${errorMsg}/车轮点超出框外！-：-id:${annotation?.clientID}-${annotation?.frame}帧${annotation.direction}视角`;
                    break;
                }
            }

            if (element.version >= SubElementVersion.version1_0_0) {
                if (element.numOfPoints > numOfPoints) {
                    // 实际点数与记录点数不一致
                    errorMsg = `${errorMsg}/车轮点实际点数与记录点数不一致，请清除车轮点，重新添加！-id:${annotation?.clientID}-${annotation?.frame}帧${annotation.direction}视角`;
                }
                const orders: number[] = new Array(numOfPoints || element.numOfPoints).fill(0);
                return orders.map((value, index: number) => {
                    // order是从1开始。
                    const subElement = pointByOrder[index + 1];
                    if (!subElement || subElement.occluded) {
                        return [-100, -100];
                    }
                    return [subElement.x, subElement.y];
                });
            }
            // if (isUnkownShapeObjFrType(label)) {
            //     numOfPoints = Math.max(
            //         element.numOfPoints,
            //         element.points.length,
            //         ...element.points.map((v) => v.order || 0),
            //     );
            // }
            const orders: number[] = new Array(numOfPoints).fill(0);
            return orders.map((value, index: number) => {
                // const subElement = element.points[index];
                // if (!subElement || subElement.occluded) {
                //     return [-100, -100];
                // }
                // order是从1开始。
                const subElement = pointByOrder[index + 1];
                if (!subElement || subElement.occluded) {
                    return [-100, -100];
                }

                return [subElement.x, subElement.y];
            });
        }
        if (element.shapeType === SubElementType.dashedLine) {
            // element.points.sort((ele1, ele2) => (ele1.order || 0) - (ele2.order || 0));
            // [0, 1, 2, 3].map((index: number) => {

            //     const subElement = element.points[index];
            //     return [subElement.x, subElement.y];
            // })
            // const helper = getHelper();
            // if (helper?.isDashLine && !helper?.isDashLine(label, annotation.attributes)) {
            if (!isDashLine(jobInstance, label, annotation.attributes)) {
                errorMsg = `${errorMsg}/非虚线却有虚线点（导出已矫正）-id:${annotation?.clientID}-${annotation?.frame}帧`;
                return [];
            }
            let height = Number.MAX_SAFE_INTEGER;
            if (
                element.points.some((point) => {
                    if (point.y > height) {
                        return true;
                    }
                    height = point.y;
                })
            ) {
                element.points.sort((a, b) => b.y - a.y);
                errorMsg = `${errorMsg}/虚线点顺序错乱（导出已矫正）-id:${annotation?.clientID}-${annotation?.frame}帧`;
            }
            const result = [];
            for (let index = 0; index < element.points.length; index += 2) {
                const points: any[] = [];

                // // 对超出边界做处理
                // const getStartX = (xx: number) => {
                //     const x = Math.min(jobInstance.imgWidth, Math.max(0, xx));
                //     return x;
                // }
                // const getStartY = (yy: number) => {
                //     const y = Math.min(jobInstance.imgHeight, Math.max(0, yy));
                //     return y;
                // }
                // const endX = Math.min(jobInstance.imgWidth, Math.max(0, subElement.x));
                // const endY = Math.min(jobInstance.imgWidth, Math.max(0, subElement.y));

                const start = [
                    getValueByMid(element.points[index].x, jobInstance.imgWidth),
                    getValueByMid(element.points[index].y, jobInstance.imgHeight),
                ];
                const end = [
                    getValueByMid(element.points[index + 1].x, jobInstance.imgWidth),
                    getValueByMid(element.points[index + 1].y, jobInstance.imgHeight),
                ];

                getPointsInLine(start, line_key_points);
                getPointsInLine(end, line_key_points);

                points.push(start);
                points.push(!element.points[index].occluded);
                points.push(end);
                points.push(!element.points[index + 1].occluded);
                result.push(points);
            }
            return result;
        }
        return [element.shapeType];
    } else if (
        jobInstance.project.projectCompanyType === ProjectCompanyType.dazhuo &&
        jobInstance?.projectType === ProjectType.mix
    ) {
        // 融合时，没有子对象，且应该有子对象的。直接返回全遮挡。

        let numOfPoints = getNumOfPointsByShapeObjFrType(label);
        if (numOfPoints > 0) {
            return new Array(numOfPoints).fill([-100, -100]);
        }
        return [];
    }
    return [];
};

const getExport2DObject = (annotation: any) => {
    const { points, label, clientID, attributes, elements } = annotation;
    const [leftX, leftY, rightX, rightY] = points;
    const attrIdByDumpName = getAttrIdByDumpName(label);

    if (label.dumpName === 'unknown' && attributes[attrIdByDumpName['signal']] === 'NA') {
        // 2D中，类别为未知，属性车辆信号灯为不适用的，则不导出
        return undefined;
    }

    const lane_id = [+attributes[attrIdByDumpName['lane_id']]];

    if (attributes[attrIdByDumpName['lane_id_2']] && attributes[attrIdByDumpName['lane_id_2']] !== 'NA') {
        lane_id.push(+attributes[attrIdByDumpName['lane_id_2']]);
    }

    return {
        track_id: clientID,
        bbox: [(leftX + rightX) / 2, (leftY + rightY) / 2, rightX - leftX, rightY - leftY],
        lane_id: lane_id,
        is_crop: getBoolean(attributes[attrIdByDumpName['is_crop']]),
        tire_line: getSubElements(elements, annotation.label, annotation),
        category: annotation.label.dumpName,
        signal: attributes[attrIdByDumpName['signal']] || '',
    };
};

const getExport2DObjects = (annotations: any[], cameraInfo: any) => {
    annotations.forEach((annotation) => {
        const obj = getExport2DObject(annotation);
        if (obj) {
            cameraInfo[annotation.direction].objects.push(obj);
        }
    });

    return cameraInfo;
};

const export2DInfo = async (frame: number, frameName?: string) => {
    const jobInstance = getJobInstance();
    const annotations = await jobInstance.annotations.sub_get(frame, false, []);

    const objects = annotations.filter((ann: any) => ann.label.labelObjectType !== LabelObjectType.image);

    // const frameData = await jobInstance.frames.get(frame);
    // const data = await frameData.data();

    const cameraInfo: any = {};
    // let frameId = '';
    jobInstance.direction.forEach((direction: string) => {
        // 视角
        cameraInfo[direction] = {
            // 'camera': direction,
            objects: [],
        };

        // const imageInfo = data.imageData.images.find((image: any) => image.name.includes(direction))
        // frameId = imageInfo.name.split('img/')[1].split('.')[0];
    });

    // const datas = await Promise.all(getExport2DObjects(objects));
    const datas = getExport2DObjects(objects, cameraInfo);
    const exportInfo = {
        annotator: `${jobInstance.assigneeId}`,
        annotation_date: getDateString(),
        annotation_qa: `${jobInstance.receiverId}`,
        frame_id: jobInstance.frameMeta.get(frame)?.frameName,
        check_no: jobInstance.id,
        annotated_info: {
            ...datas,
        },
    };

    console.log(`帧-${frame}结果：`, exportInfo);
    return exportInfo;
};

const export2DInfoByFrame = async () => {
    const exportInfoByFrame: any = {};
    const { startFrame, stopFrame } = getJobInfo();

    const promises = [];
    for (let frame = startFrame; frame <= stopFrame; frame++) {
        promises.push(export2DInfo(frame));
    }
    const results = await Promise.all(promises);

    results.forEach((result) => {
        if (result.frame_id) {
            exportInfoByFrame[result.frame_id] = result;
        }
    });
    return results;
};
//#endregion

//#region 导出2D目标物
const getExport2DLaneFrame = (images: any[]) => {
    // if (!images.length) {
    //     throw new Error(`获取帧-${frame}图像标注异常`)
    // }
    const num_lanes_left_right = [0, 0];
    images.forEach((image) => {
        const { attributes } = image;
        const attrIdByDumpName = getAttrIdByDumpName(image.label);
        if (image.label.dumpName === 'num_lanes_left') {
            num_lanes_left_right[0] = +(attributes[attrIdByDumpName['num_lanes_left']] || 0);
        }
        if (image.label.dumpName === 'num_lanes_right') {
            num_lanes_left_right[1] = +(attributes[attrIdByDumpName['num_lanes_right']] || 0);
        }
        return num_lanes_left_right;
    });
    return num_lanes_left_right;
};

const getExport2DLaneObject = (annotation: any, line_key_points: number[][]) => {
    try {
        const { label, attributes, elements } = annotation;
        const attrIdByDumpName = getAttrIdByDumpName(label);

        let type = attributes[attrIdByDumpName['type']];
        let color = attributes[attrIdByDumpName['color']];
        // type和颜色两个值的导出字段有错误
        // 转化一次两个值，转化为符合客户要求的
        type = transValue('type', type);
        color = type === 'road_edge' && transValue('color', color) !== 'yellow' ? 'others' : transValue('color', color);

        if (type !== 'road_edge' && color === 'others') {
            // 除了路沿线以外的类型，颜色为其他时，提示
            errorMsg = `${errorMsg}/第${annotation.frame}帧${annotation.cameraName}-${annotation.clientID}颜色为其他，请检查是否错误！`;
        }

        return {
            // "clientID": annotation.clientID,
            line_key_points: line_key_points,
            dash_line_start_end: getSubElements(elements, annotation.label, annotation, line_key_points),
            type: type,
            color: color,
            has_fishbone: getBoolean(attributes[attrIdByDumpName['has_fishbone']]),
        };
    } catch (error) {
        console.error('导出信息');
        throw new Error('导出对象错误');
    }
};

// 获取2D车道线-导出对象列表
const getExport2DLaneObjects = (annotations: any[]) => {
    const jobInstance = getJobInstance();
    return annotations
        .map((ann) => {
            const points: number[] = ann.points;

            const line_key_points = points.reduce((previous: number[][], current: number, index: number) => {
                if (index % 2 === 0) {
                    previous.push([current]);
                } else {
                    previous[previous.length - 1][1] = current;
                }
                return previous;
            }, []);

            if (line_key_points?.length < 2) {
                // 不足2个点，或者所有点都在图像外 -50 以外，则不导出
                errorMsg = `${errorMsg}/存在点数不足2个的点-id:${ann.clientID}-${ann.frame}帧`;
                return undefined;
            }

            if (points.every((num: number) => num < -50)) {
                // 不足2个点，或者所有点都在图像外 -50 以外，则不导出
                errorMsg = `${errorMsg}/存在点在-50之外的点-id:${ann.clientID}-${ann.frame}帧`;
                return undefined;
            }

            if (Number.isInteger(jobInstance.imgWidth) && Number.isInteger(jobInstance.imgHeight)) {
                // 如果有图像尺寸。
                const overWidth = jobInstance.imgWidth;
                const overHeight = jobInstance.imgHeight;
                if (
                    line_key_points.some(
                        ([num1, num2], index) => num1 < 0 || num2 < 0 || num1 > overWidth || num2 > overHeight,
                    )
                ) {
                    // index % 2 === 0 为 x
                    // index % 2 === 1 为 y
                    // 所有点都超过图像尺寸50，则不导出
                    errorMsg = `${errorMsg}/存在点在图像之外的点(导出已矫正)-id:${ann.clientID}-${ann.frame}帧`;
                    // 强行规定到图像内
                    line_key_points.forEach((point) => {
                        point[0] = Math.min(Math.max(0, point[0]), jobInstance.imgWidth);
                        point[1] = Math.min(Math.max(0, point[1]), jobInstance.imgHeight);
                    });
                    // return undefined;
                }
            }

            let height = Number.MAX_SAFE_INTEGER;
            if (
                line_key_points.some((point) => {
                    if (point[1] - height > 0) {
                        // 第二个点的y值，比第一个点高
                        return true;
                    }
                    height = point[1];
                    return false;
                })
            ) {
                // let isSort = false;
                // let isOffset = false;

                // line_key_points.forEach(point => {
                //     if (point[1] - height > 3) {
                //         // 第二个点的y值，比第一个点高3px以上，则重新排序。
                //         return true;
                //     }
                //     height = point[1];
                //     return false;
                // })

                height = Number.MAX_SAFE_INTEGER;
                if (
                    line_key_points.some((point) => {
                        if (point[1] - height > 3) {
                            // 第二个点的y值，比第一个点高3px以上，则重新排序。
                            return true;
                        }
                        height = point[1];
                        return false;
                    })
                ) {
                    line_key_points.sort((point1, point2) => point2[1] - point1[1]);
                    errorMsg = `${errorMsg}/车道线顺序没有从近到远（导出已重新排序）-id:${ann.clientID}-${ann.frame}帧`;
                } else {
                    // if (line_key_points[0][1] < line_key_points[line_key_points.length - 1][1]) {
                    //     debugger;
                    // }
                    height = Number.MAX_SAFE_INTEGER;
                    line_key_points.forEach((point) => {
                        if (point[1] - height <= 3 && point[1] - height > 0) {
                            // 第二个点的y值，比第一个点高3px以内，则强行矫正。
                            point[1] = height;
                        }
                        height = point[1];
                    });
                    errorMsg = `${errorMsg}/车道线顺序没有从近到远，误差在3px以内（导出已矫正）-id:${ann.clientID}-${ann.frame}帧`;
                }
            }
            const data = getExport2DLaneObject(ann, line_key_points);
            return data;
        })
        .filter((item) => item);
};

const export2DLaneCameraInfo = async (frame: number, cameraName: string) => {
    const jobInstance = getJobInstance();
    const annotations = await jobInstance.annotations.get(frame, false, [], cameraName);

    const image = annotations.filter((ann: any) => ann.label.labelObjectType === LabelObjectType.image);
    const objects = annotations.filter(
        (ann: any) =>
            ann.label.labelObjectType !== LabelObjectType.image &&
            [ObjectType.SHAPE, ObjectType.TRACK].includes(ann.objectType),
    );

    const datas = await Promise.all(getExport2DLaneObjects(objects));

    return {
        lines: [...datas],
        num_lanes_left_right: getExport2DLaneFrame(image),
    };
};

const export2DLaneInfo = async (frame: number) => {
    const jobInstance = getJobInstance();
    const { cams = [] } = jobInstance;

    const promises = [];
    const cameraData: any = {};
    for (let index = 0; index < cams.length; index++) {
        const cameraName = cams[index];
        promises.push(export2DLaneCameraInfo(frame, cameraName));
        // const annotations = await jobInstance.annotations.get(frame, false, [], cameraName);

        // const image = annotations.filter((ann: any) => ann.label.labelObjectType === LabelObjectType.image);
        // const objects = annotations.filter((ann: any) => ann.label.labelObjectType !== LabelObjectType.image && [ObjectType.SHAPE, ObjectType.TRACK].includes(ann.objectType));

        // const datas = await Promise.all(getExport2DLaneObjects(objects));

        // cameraData[cameraName] = {
        //     // "camera": "camera1",
        //     "lines": [
        //         ...datas,
        //     ],
        //     "num_lanes_left_right": getExport2DLaneFrame(image)
        // };
    }

    const results = await Promise.all(promises);

    results.forEach((result, index) => {
        cameraData[cams[index]] = result;
    });

    const exportInfo = {
        annotator: `${jobInstance.assigneeId}`,
        annotation_date: getDateString(),
        annotation_qa: `${jobInstance.receiverId}`,
        frame_id: jobInstance.frameMeta.get(frame)?.frameName,
        check_no: jobInstance.id,
        annotated_info: cameraData,
    };

    console.log(`帧-${frame}结果：`, exportInfo);
    return exportInfo;
};

const export2DLaneInfoByFrame = async () => {
    // const exportInfoByFrame: any = {};

    // const imageData = await frameData.data();
    // const cameraName = imageData.name.includes('camera1') ? 'camera1' : 'camera0';

    // console.log('相机名称：', imageData.name);
    const { startFrame, stopFrame } = getJobInfo();
    const promises = [];
    for (let frame = startFrame; frame <= stopFrame; frame++) {
        promises.push(export2DLaneInfo(frame));
    }
    const results = await Promise.all(promises);

    // results.forEach((result) => {
    //     exportInfoByFrame[result.frame_id] = result;
    // });
    return results;
};
//#endregion

//#region 导出3D车道线

const get3DSubElements = (elements: SubElement[]) => {
    if (elements?.length) {
        const [element] = elements;
        if (element.shapeType === SubElementType.dashedLine) {
            // element.points.sort((ele1, ele2) => (ele1.order || 0) - (ele2.order || 0));
            // [0, 1, 2, 3].map((index: number) => {

            //     const subElement = element.points[index];
            //     return [subElement.x, subElement.y];
            // })
            const result = [];
            for (let index = 0; index < element.points.length; index += 2) {
                const points: any[] = [];
                const start =
                    element.points[index].pointType === 'start'
                        ? [element.points[index].x, element.points[index].y, element.points[index].z]
                        : [element.points[index].x, element.points[index].y, element.points[index].z];
                const end =
                    element.points[index].pointType === 'end'
                        ? [element.points[index + 1].x, element.points[index + 1].y, element.points[index + 1].z]
                        : [element.points[index + 1].x, element.points[index + 1].y, element.points[index + 1].z];

                points.push(start);
                // points.push(!element.points[index * 2].occluded);
                points.push(end);
                // points.push(!element.points[index * 2 + 1].occluded);
                result.push(points);
            }
            return result;
        }
        return [element.shapeType];
    }
    return [];
};

const getExport3DLaneFrame = (images: any[]) => {
    const num_lanes_left_right = [0, 0];
    images.forEach((image) => {
        const { attributes } = image;
        const attrIdByDumpName = getAttrIdByDumpName(image.label);
        if (image.label.dumpName === 'num_lanes_left') {
            num_lanes_left_right[0] = attributes[attrIdByDumpName['num_lanes_left']];
        }
        if (image.label.dumpName === 'num_lanes_right') {
            num_lanes_left_right[1] = attributes[attrIdByDumpName['num_lanes_right']];
        }
        return num_lanes_left_right;
    });
    return num_lanes_left_right;
};

const getExport3DLaneObject = (annotation: any) => {
    try {
        const { points, label, attributes, elements } = annotation;
        const attrIdByDumpName = getAttrIdByDumpName(label);
        const line_key_points = points.reduce((previous: number[][], current: number, index: number) => {
            if (index % 2 === 0) {
                previous.push([current]);
            } else {
                previous[previous.length - 1][1] = current;
            }
            // if (index % 4 === 0) {
            //     previous.push([[current]]);
            // } else if (index % 4 === 1) {
            //     previous[previous.length - 1][1] = current;
            // } else if (index % 4 === 2) {
            //     previous[previous.length - 1][1] = current;
            // } else {
            //     previous[previous.length - 1][1] = current;
            // }
            return previous;
        }, []);
        return {
            // "clientID": annotation.clientID,
            line_key_points: line_key_points,
            dash_line_start_end: get3DSubElements(elements),
            type: attributes[attrIdByDumpName['type']],
            color: attributes[attrIdByDumpName['color']],
            has_fishbone: getBoolean(attributes[attrIdByDumpName['has_fishbone']]),
        };
    } catch (error) {
        console.error('导出信息');
        throw new Error('导出对象错误');
    }
};

const getExport3DLaneObjects = (annotations: any[]) => {
    // const cameraInfo: any = [{
    //     "camera": cameraName,
    //     "lines": [],
    // }
    // ];

    // annotations.forEach((annotation) => {
    //     cameraInfo[cameraName].lines.push((getExport2DLaneObject(annotation)));
    // });

    return annotations.map((ann) => getExport3DLaneObject(ann));
};

const export3DLaneCameraInfo = async (frame: number) => {
    const jobInstance = getJobInstance();
    const annotations = await jobInstance.annotations.get(frame, false, []);

    // const image = annotations.filter((ann: any) => ann.label.labelObjectType === LabelObjectType.image);
    const objects = annotations.filter(
        (ann: any) =>
            ann.label.labelObjectType !== LabelObjectType.image &&
            [ObjectType.SHAPE, ObjectType.TRACK].includes(ann.objectType),
    );

    const datas = await Promise.all(getExport3DLaneObjects(objects));
    // const imageDatas = getExport2DLaneFrame(image);

    //     ...datas,
    // "num_lanes_left_right": getExport2DLaneFrame(image)
    return {
        lines: [...datas],
        // "3d_object_detection_info": {

        //     "basic_info": {
        //         "annotation_date": new Date(jobInstance.statusUpdateTime).toISOString().split('.')[0],
        //     },
        //     "ext_info": {
        //         "picture_attrs": [
        //             "normal_pictures"
        //         ]
        //     },
        //     "3d_object_detection_anns_info": [
        //         ...datas,
        //     ]
        // }
    };
};

const export3DLaneInfo = async (frame: number) => {
    const jobInstance = getJobInstance();
    // const { cams = [] } = jobInstance;

    // const promises = [];
    // const cameraData: any = {

    // }
    // for (let index = 0; index < cams.length; index++) {
    //     const cameraName = cams[index];
    //     promises.push();
    // }

    const result = await export3DLaneCameraInfo(frame);

    // results.forEach((result, index) => {
    //     cameraData[cams[index]] = result;
    // });

    const exportInfo = {
        annotator: `${jobInstance.assigneeId}`,
        annotation_date: new Date(jobInstance.statusUpdateTime).toISOString().split('.')[0],
        annotation_qa: `${jobInstance.receiverId}`,
        clip_id: jobInstance.frameMeta.get(frame)?.frameName,
        check_no: jobInstance.id,
        annotated_info: result,
    };

    console.log(`帧-${frame}结果：`, exportInfo);
    return exportInfo;
};

const export3DLaneInfoByFrame = async () => {
    const { startFrame, stopFrame } = getJobInfo();
    // const exportInfoByFrame: any = {};

    // const imageData = await frameData.data();
    // const cameraName = imageData.name.includes('camera1') ? 'camera1' : 'camera0';

    // console.log('相机名称：', imageData.name);
    const promises = [];
    for (let frame = startFrame; frame <= stopFrame; frame++) {
        promises.push(export3DLaneInfo(frame));
    }
    const results = await Promise.all(promises);

    // results.forEach((result) => {
    //     exportInfoByFrame[result.frame_id] = result;
    // });
    return results;
};
//#endregion

//#region 导出3D2D融合-3D目标物
const getExport3DBoxFrame = (frame: number, images: any[]) => {
    let exception_flag = false;

    // if (!images.length) {
    //     throw new Error(`获取帧-${frame}图像标注异常`)
    // }
    if (images.length) {
        exception_flag = getBoolean(Object.values(images[0].attributes)[0]);
    }
    const jobInstance = getJobInstance();
    return {
        basic_info: {
            annotation_date: getDateString(),
            // "annotation_provider": "AMYGO",
            annotation_provider: 'Zhimu',
        },
        ext_info: {
            exception_flag: exception_flag,
        },
    };
};

const getExport3DBoxObject = async (
    annotation: any,
    objectInfo: any,
    attributes: Record<number, string>,
    group: Record<number, string>,
) => {
    try {
        const { points, label, serverID } = annotation;
        const rotation = new Euler(annotation.points[3], annotation.points[4], annotation.points[5]);
        const quaternion = new Quaternion().setFromEuler(rotation);

        const attrIdByDumpName = getAttrIdByDumpName(label);
        const lane_id = [+attributes[attrIdByDumpName.lane_id]];

        if (attributes[attrIdByDumpName.lane_id_2] && attributes[attrIdByDumpName.lane_id_2] !== 'NA') {
            lane_id.push(+attributes[attrIdByDumpName.lane_id_2]);
        }

        // if (label.dumpName === 'unknown' && attributes[attrIdByDumpName.signal] === 'NA') {
        //     // 2D中，类别为未知，属性车辆信号灯为不适用的，则不导出
        //     return undefind;
        // }

        let signal = attributes[attrIdByDumpName.signal];

        if (signal === 'NA') {
            signal = '';
        }

        const result: any = {
            track_id: serverID,
            ...group,
            obj_center_pos: [points[0], points[1], points[2]],
            category: label.dumpName || '',
            subcategory: attributes[attrIdByDumpName.subcategory] || '',
            size: [points[6], points[7], points[8]],
            obj_rotation: quaternion.toArray(),
            num_lidar_pts: objectInfo?.cloudPoint || 0,
            num_radar_pts: -1,
            is_double_image: getBoolean(attributes[attrIdByDumpName.is_double_image]),
            is_group: getBoolean(attributes[attrIdByDumpName.is_group]),
            // SemVer: 'v20231218',
            is_crop: getBoolean(attributes[attrIdByDumpName.is_crop]),
            lane_id: lane_id,
            signal,
        };

        const special_clothes = getBoolean(attributes[attrIdByDumpName.special_clothes]);
        if (attributes[attrIdByDumpName.special_clothes]) {
            result.special_clothes = special_clothes;
        }
        return result;
    } catch (error) {
        console.error('获取3D目标物检测对象错误！', annotation);
        throw error;
    }
};

const getExport3DBoxObjects = (annotations: any[], objectInfos: any) => {
    try {
        // 所有对象的属性
        const attrs: Record<number, Record<number, string>> = annotations.reduce((previous, annotation) => {
            // ({
            //     [annotation.clientID]: { ...annotation.attributes }
            // })
            previous[annotation.clientID] = { ...annotation.attributes };
            return previous;
        }, {});

        // 更改组ID有值的组id， 为第一个对象的clientID
        annotations.forEach((annotation: any) => {
            // 卡车和工程车，增加组id
            const attrIdByDumpName = getAttrIdByDumpName(annotation.label);
            const attr = attrs[annotation.clientID];
            // 当前对象的属性
            if (attrIdByDumpName.group_id) {
                // 标签中存在groupId
                let groupIdValue = `${attr[attrIdByDumpName.group_id] || '0'}`;
                // 去除空格
                groupIdValue = groupIdValue.trim();
                if (groupIdValue !== '0') {
                    // 不为0时，找出属性中，相同的值，并将groupId的值，更改为当前clientID
                    annotations.forEach((ann) => {
                        if (annotation.label.id === ann.label.id) {
                            // 相同标签。所以属性中一定有组ID
                            const attrTemp = attrs[ann.clientID];
                            // 获取组Id的值
                            let groupIdValueTemp = `${attrTemp[attrIdByDumpName.group_id] || '0'}`;
                            // 去除空格
                            groupIdValueTemp = groupIdValueTemp.trim();
                            if (groupIdValue === groupIdValueTemp) {
                                // 组ID相同；
                                attrTemp[attrIdByDumpName.group_id] = `${annotation.clientID}`;
                            }
                        }
                    });
                }
            }
        });

        return annotations.map((annotation: ObjectState) => {
            let group: {
                group_id: number;
            } = {
                group_id: annotation.clientID,
            };
            const attrIdByDumpName = getAttrIdByDumpName(annotation.label);

            const attr = { ...attrs[annotation.clientID] };

            if (attrIdByDumpName.group_id) {
                let groupIdValue = `${attr[attrIdByDumpName.group_id] || '0'}`;
                // 去除空格
                groupIdValue = groupIdValue.trim();
                if (groupIdValue && groupIdValue !== '0') {
                    // 当groupId为0时，代表它一直只有一个目标物。
                    group = {
                        group_id: +groupIdValue,
                    };
                    // group = {
                    //     'group_id': annotation.clientID,
                    // };
                    // } else {
                    //     group = {
                    //         'group_id': groupIdValue,
                    //     };
                }
            }

            if (!annotation.direction && annotation.parentID) {
                // 有父ID，groupID直接使用父ID的clientID
                const parent = annotations.find((ann) => ann.serverID === annotation.parentID);
                if (parent) {
                    group.group_id = parent.clientID;
                } else {
                    console.error('没有找到父ID：', annotation.parentID);
                }
            }

            return getExport3DBoxObject(annotation, objectInfos[annotation.clientID], attr, group);
        });
    } catch (error) {
        console.error('框列表错误：', error);
        throw error;
    }
};

const export3DBoxInfo = async (frame: number) => {
    // const cloudPointsByClientID = {};
    const jobInstance = getJobInstance();

    const annotations = await jobInstance.annotations.get(frame, false, []);

    const images = annotations.filter((ann: any) => ann.label.labelObjectType === LabelObjectType.image) || [];

    const jobInfo = getExport3DBoxFrame(frame, images);

    const objects =
        annotations.filter(
            (ann: any) =>
                ann.label.labelObjectType !== LabelObjectType.image &&
                [ObjectType.SHAPE, ObjectType.TRACK].includes(ann.objectType),
        ) || [];

    const objectInfos: any = {};
    if (!skipPointCount) {
        try {
            const frameData = await jobInstance.frames.get(frame);

            const data = await frameData.data();

            const pcdBlob = data.datas[0];
            // frameId = data.name.split('pcd/')[1].split('.')[0];

            const url = URL.createObjectURL(pcdBlob.original);

            const loader = new PCDLoader();
            const points = await loader.loadAsync(url);
            URL.revokeObjectURL(url);

            const positions = points.geometry.getAttribute('position');
            const { count } = positions;

            objects.forEach((ann: any) => {
                const { points } = ann;
                const position = new Vector3(points[0], points[1], points[2]);

                const scale = new Vector3(points[6], points[7], points[8]);

                const rotation = new Euler(points[3], points[4], points[5]);
                const quaternion = new Quaternion().setFromEuler(rotation);

                const box3 = new Box3().setFromCenterAndSize(new Vector3(0, 0, 0), new Vector3(1, 1, 1));

                const invert = new Matrix4().compose(position, quaternion, scale).invert();
                objectInfos[ann.clientID] = {
                    clientID: ann.clientID,
                    box3,
                    cloudPoint: 0,
                    matrix4: invert,
                };
            });

            for (let index = 0; index < count; index += 1) {
                const point = new Vector3().fromBufferAttribute(positions, index);

                objects.forEach((ann: any) => {
                    const isInBox = objectInfos[ann.clientID].box3.containsPoint(
                        point.clone().applyMatrix4(objectInfos[ann.clientID].matrix4),
                    );
                    if (isInBox) {
                        objectInfos[ann.clientID].cloudPoint++;
                    }
                });
            }
        } catch (error) {
            console.error('读取3D错误：', error);
            throw error;
        }
    }

    const datas = await Promise.all(getExport3DBoxObjects(objects, objectInfos));

    const exportInfo = {
        annotator: `${jobInstance.assigneeId || ''}`,
        annotation_date: getDateString(),
        annotation_qa: `${jobInstance.receiverId || ''}`,
        frame_id: jobInstance.frameMeta.get(frame)?.frameName,
        check_no: jobInstance.id,
        semantic_version: 'v20231218', // 采用的标注规则版本
        annotated_info: {
            '3d_object_detection_info': {
                ...jobInfo,
                '3d_object_detection_anns_info': [...datas],
            },
        },
    };

    // console.log(`帧-${frame}结果：`, exportInfo);
    return exportInfo;
};

const export3DBoxInfoByFrame = async () => {
    // const exportInfoByFrame: any = {};

    try {
        const { startFrame, stopFrame } = getJobInfo();
        const promises = [];
        for (let frame = startFrame; frame <= stopFrame; frame++) {
            promises.push(export3DBoxInfo(frame));
        }
        const results = await Promise.all(promises);

        // results.forEach((result) => {
        //     exportInfoByFrame[result.frame_id] = result;
        // });
        return results;
    } catch (error) {
        console.error('错误信息');
        throw new Error(`导出3D错误：${(error as Error).stack}`);
    }
};

//#endregion

export const CX_check = async (skip: boolean = true) => {
    skipPointCount = skip;
    const jobInstance = getJobInstance();
    const { startFrame, stopFrame } = getJobInfo();
    // if (jobInstance.project.projectCompanyType === 2) {
    if (jobInstance.project.projectCompanyType === ProjectCompanyType.dazhuo) {
        const result: {
            annotateId: string;
            clipId: string;
            annotateType: number;
            frames: any[];
        } = {
            annotateId: (jobInstance as Job).task.outDataId,
            clipId: (jobInstance as Job).outClipId,
            annotateType: 1,
            frames: [],
        };

        if (
            jobInstance.project.projectDumpType === ProjectDumpType.lidar &&
            jobInstance.project.projectType === ProjectType.mix
        ) {
            result.annotateType = 3;
            const export3D = await export3DInfoByFrame();
            const export2D = await export2DInfoByFrame();

            for (let index = 0; index <= stopFrame - startFrame; index++) {
                const export2DData = export2D[index];
                const export3DData = export3D[index];

                result.frames.push({
                    frameId: export2DData.frame_id,
                    annotatedInfo: {
                        '2d_object_annotated_info': {
                            ...export2DData.annotated_info,
                        },
                        '3d_object_annotated_info': {
                            ...export3DData.annotated_info,
                        },
                    },
                });
            }
        }

        if (
            jobInstance.project.projectType === ProjectType.two &&
            jobInstance.project.projectDumpType === ProjectDumpType.lane
        ) {
            result.annotateType = 1;
            const export2DLane = await export2DLaneInfoByFrame();
            for (let index = 0; index <= stopFrame - startFrame; index++) {
                const exportData = export2DLane[index];

                result.frames.push({
                    frameId: exportData.frame_id,
                    annotatedInfo: {
                        '2d_lane_annotated_info': {
                            ...exportData.annotated_info,
                        },
                    },
                });
            }
        }

        if (
            jobInstance.project.projectType === ProjectType.three &&
            jobInstance.project.projectDumpType === ProjectDumpType.def
        ) {
            result.annotateType = 4;
            const export3DBox = await export3DBoxInfoByFrame();
            for (let index = 0; index <= stopFrame - startFrame; index++) {
                const exportData = export3DBox[index];

                result.frames.push({
                    frameId: exportData.frame_id,
                    annotatedInfo: {
                        '3d_box_annotated_info': {
                            ...exportData.annotated_info,
                        },
                    },
                });
            }
        }

        if (
            jobInstance.project.projectType === ProjectType.three &&
            jobInstance.project.projectDumpType === ProjectDumpType.lane
        ) {
            result.annotateType = 2;
            const export3DLane = await export3DLaneInfoByFrame();
            for (let index = 0; index <= stopFrame - startFrame; index++) {
                const exportData = export3DLane[index];

                result.frames.push({
                    frameId: exportData.clip_id,
                    annotatedInfo: {
                        '3d_lane_annotated_info': {
                            ...exportData.annotated_info,
                        },
                    },
                });
            }
        }

        if (errorMsg) {
            message.error(errorMsg, 5);
            errorMsg = '';
        }

        return result;
    }
};

export const CX_export = async (skip: boolean = false) => {
    skipPointCount = skip;

    const jobInstance = getJobInstance();

    if (jobInstance.project.projectCompanyType === ProjectCompanyType.dazhuo) {
        if (jobInstance.project.projectType === ProjectType.mix) {
            const export3D = await export3DInfoByFrame();
            const export2D = await export2DInfoByFrame();

            // console.log('导出3D目标物结果：', export3D);
            // console.log('导出2D目标物结果：', export2D);

            const result3D = await jobInstance.annotations.exportTaskAnnotations({
                dimensionType: DimensionType.three,
                jobId: jobInstance.id,
                outJson: JSON.stringify(export3D),
            });

            const result2D = await jobInstance.annotations.exportTaskAnnotations({
                dimensionType: DimensionType.two,
                jobId: jobInstance.id,
                outJson: JSON.stringify(export2D),
            });
            if (result2D.success) {
                message.success('2D导出成功！');
            }
            if (result3D.success) {
                message.success('3D导出成功！');
            }
        } else if (
            jobInstance.project.projectType === ProjectType.two &&
            jobInstance.project.projectDumpType === ProjectDumpType.lane
        ) {
            const export2DLane = await export2DLaneInfoByFrame();
            const result = await jobInstance.annotations.exportTaskAnnotations({
                dimensionType: DimensionType.two,
                jobId: jobInstance.id,
                outJson: JSON.stringify(export2DLane),
            });
            if (result.success) {
                message.success('车道线导出成功！');
            }
        } else if (
            jobInstance.project.projectType === ProjectType.three &&
            jobInstance.project.projectDumpType === ProjectDumpType.lane
        ) {
            const export3DBox = await export3DLaneInfoByFrame();
            // console.log('导出2D车道线结果:', export2DLane);
            const result = await jobInstance.annotations.exportTaskAnnotations({
                dimensionType: DimensionType.three,
                jobId: jobInstance.id,
                outJson: JSON.stringify(export3DBox),
            });
            if (result.success) {
                message.success('3D车道线导出成功！');
            }
        } else if (jobInstance.project.projectType === ProjectType.three) {
            const export3DBox = await export3DInfoByFrame();

            const result = await jobInstance.annotations.exportTaskAnnotations({
                dimensionType: DimensionType.three,
                jobId: jobInstance.id,
                outJson: JSON.stringify(export3DBox),
            });
            if (result.success) {
                message.success('3D导出成功！');
            }
        }

        if (errorMsg) {
            message.error(errorMsg);
            errorMsg = '';
        }
    }
};

export const CX_autoAnntationAttr = async (skip: boolean = false) => {
    skipPointCount = skip;

    const jobInstance = getJobInstance();

    if (jobInstance.project.projectCompanyType === ProjectCompanyType.dazhuo) {
        if (jobInstance.project.projectType === ProjectType.mix) {
            const export3D = await export3DInfoByFrame();
            const export2D = await export2DInfoByFrame();

            return {
                '2D': export2D,
                '3D': export3D,
            };
        } else if (
            jobInstance.project.projectType === ProjectType.two &&
            jobInstance.project.projectDumpType === ProjectDumpType.lane
        ) {
            const export2DLane = await export2DLaneInfoByFrame();
            return export2DLane;
        } else if (
            jobInstance.project.projectType === ProjectType.three &&
            jobInstance.project.projectDumpType === ProjectDumpType.lane
        ) {
            const export3DLane = await export3DLaneInfoByFrame();
            return export3DLane;
        } else if (
            [ProjectType.D3City, ProjectType.three, ProjectType.D3Highway, ProjectType.D23OD].includes(
                jobInstance.project.projectType,
            )
        ) {
            const export3DBox = await export3DBoxInfoByFrame();

            return export3DBox;
        }

        if (errorMsg) {
            message.error(errorMsg);
            errorMsg = '';
        }
        return [];
    }
};

export const toAttrs = (json: any): Record<number, Record<number, Record<number, string>>> => {
    try {
        const attr: Record<number, Record<number, Record<string, string>>> = {};
        const jobInstance = getJobInstance();
        const labelByCategory = jobInstance.labels.reduce((previous: Record<string, any>, current) => {
            previous[current.dumpName] = { ...current };

            previous[current.dumpName].attrByDumpName = current.attributes.reduce((prev: Record<string, any>, cur) => {
                prev[cur.dumpName] = { ...cur };
                return prev;
            }, {});

            return previous;
        }, {});

        const { frameMeta: keyValues } = jobInstance;
        const frameMetas = [...keyValues.values()];

        const frameIndexByFrameName = frameMetas.reduce((previous: Record<string, number>, current) => {
            previous[current.frameName] = current.frameIndex;
            return previous;
        }, {});

        const { frames } = json;
        frames.forEach((frame: any) => {
            const { frame_id, annotated_info } = frame;
            const frameIndex = frameIndexByFrameName[frame_id];
            const { only_3d_city_object_detection_annotated_info } = annotated_info;
            const objects =
                only_3d_city_object_detection_annotated_info['3d_object_detection_info'][
                    '3d_object_detection_anns_info'
                ];

            objects.forEach((obj: any) => {
                const frameObjects = attr[frameIndex] || {};
                // 获取到标签
                const label = labelByCategory[obj.category];
                const objAttr: Record<number, string> = {
                    0: label.labelId,
                };

                const { attrByDumpName } = label;
                Object.entries(obj).forEach(([key, value]) => {
                    const attrInfo = attrByDumpName[key];
                    if (attrInfo) {
                        // 当前是属性
                        // 特殊处理一些导出值
                        if (key === 'signal') {
                            // 车辆信号灯，给客户时，将“NA”转换成了''，因此转换回来。
                            objAttr[attrInfo.id] = (value as string) || 'NA';
                        } else if (key === 'lane_id') {
                            // 车道id进行过合并。因此拆分开
                            if ((value as number[])?.length === 2) {
                                const lane2Info = attrByDumpName['lane_id_2'];
                                objAttr[attrInfo.id] = `${(value as number[])?.[0] || ''}`;
                                objAttr[lane2Info.id] = `${(value as number[])?.[1] || ''}`;
                            } else if ((value as number[])?.length === 1) {
                                objAttr[attrInfo.id] = `${(value as number[])?.[0] || ''}`;
                            } else {
                                objAttr[attrInfo.id] = '-100';
                            }
                        } else {
                            objAttr[attrInfo.id as any as number] = value as string;
                        }
                    }
                });

                frameObjects[obj.track_id] = objAttr;

                attr[frameIndex] = frameObjects;
            });
        });
        return attr;
    } catch (error) {
        throw error;
    }
    throw new Error('自动化转化属性失败');
};
