/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

export interface MasterProps {
    subscribe(listener: Listener): void;
    unsubscribe(listener: Listener): void;
    unsubscribeAll(): void;
    notify(reason: string): void;
    notifyAsync(reason: string): void;
}

export interface Listener {
    notify(master: MasterProps, reason: string): void;
    notifyAsync?(master: MasterProps, reason: string): void;
}

export class Master implements MasterProps {
    // 监听多个对象
    private listeners: Listener[];

    public constructor() {
        this.listeners = [];
    }

    // 注册监听对象
    public subscribe(listener: Listener): void {
        this.listeners.push(listener);
    }

    // 取消注册对象
    public unsubscribe(listener: Listener): void {
        for (let i = 0; i < this.listeners.length; i++) {
            if (this.listeners[i] === listener) {
                this.listeners.splice(i, 1);
            }
        }
    }

    // 取消所有注册对象
    public unsubscribeAll(): void {
        this.listeners = [];
    }

    // 多个注册对象理论上可以同时运行
    public notify(reason: string): void {
        for (const listener of this.listeners) {
            listener.notify(this, reason);
        }
    }

    public async notifyAsync(reason: string): Promise<void> {
        const promises = [];
        for (const listener of this.listeners) {
            promises.push(listener.notifyAsync(this, reason));
        }
        await Promise.all(promises);
    }
}
