/*
 * @Author: swxy
 * @Date: 2023-10-09 10:01:41
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { Group, Object3DEventMap, Vector3 } from 'three';
import Helper from './helper';
import Point, { PointType } from '../objects/point';
import Bezier from '../objects/bezier/bezierModel';

class BezierHelper extends Helper {
    private readonly positions: Vector3[] = [];
    // @ts-expect-error
    public object: Bezier;
    public point = new Point(-1, 'addPoint', PointType.MousePoint, new Vector3(), 'yellow', -1);

    public get length(): number {
        return this.positions.length;
    }

    constructor() {
        super();
    }

    public draw(position?: Vector3) {
        // 开始创建
        if (position) {
            if (this.isCreating) {
                this.isCreating = false;
                const length = (this.object as Bezier).length;
                // 创建结束时，删除最后一个点
                (this.object as Bezier).deletePosition(length - 1, 1);
                // (this.object as Bezier).updatePosition(position.clone(), this.positions.length - 1);
            } else {
                // this.object = new Bezier(-1, [...position.toArray(), ...position.toArray()], 'yellow');
                // 清空

                this.object = new Bezier(-1, [], 'yellow');
                this.isCreating = true;
                (this.object as Bezier).init();
                (this.object as Bezier).updatePosition(position.clone());
                // (this.object as Bezier).updatePosition(position.clone());
            }
        } else if (this.isCreating) {
            this.isCreating = false;
            const length = (this.object as Bezier).length;
            // 创建结束时，删除最后一个点
            (this.object as Bezier).deletePosition(length - 1, 1);
        }
    }

    public updatePosition(pos: Vector3, index?: number) {
        (this.object as Bezier).updatePosition(pos, index);
    }

    public deletePosition(index?: number, deleteCount: number = 0) {
        if (this.isCreating && (this.object as Bezier).length > 1) {
            (this.object as Bezier).deletePosition(index, deleteCount);
        }
    }

    // public updateMousePosition(pos: Vector3) {
    //     // 根据鼠标位置，更新元素
    //     if (this.isCreating) {
    //         (this.object as Bezier).updateLastPosition(pos);
    //     }
    // }
    public updateMousePosition(pos: Vector3) {
        this.point.position.set(pos.x, pos.y, pos.z);
        if (this.isCreating || this.isEditing) {
            if (this.object && this.object.parent) {
                this.object.parent.add(this.point);
            }
        } else if (this.point.parent) {
            this.point.removeFromParent();
        }
    }

    public addPoint(pos: Vector3) {
        this.object.addPoint(pos);
    }
}

export default BezierHelper;
