/*
 * @Author: swxy
 * @Date: 2023-03-11 09:32:06
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import { Button, Col, List, Row, Typography, Descriptions, Modal, message } from 'antd';
import React, { FC, useEffect } from 'react';
// import { CombinedState, SubmitStatus } from 'reducers/interfaces';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { recoveryJobAsync } from "actions/jobs-actions";

import './index.scss';
import { useTranslation } from 'react-i18next';
// import Job from 'business/objects/job';
import { JobStatus } from 'utils/ConstType';
import { getTextColorByJobStatus, getbackgroundColorByBackTimes } from './jobHelper';

// enum JobStatus {
//     annotation = 1,
//     review,
//     finish,
// }

interface JobInfo {
    id: number;
    name: string;
    engineTaskName: string;
    engineProjectName: string;
    jobStatus: JobStatus;
    jobStatus_: string;
    projectType_: string;
    taskType_: string;
    taskId: number;
    teamJobType: number;
    shapeNum: number;
    noteNum: number;
    totalFrame: number;
    statusUpdateTime: string;
    noteRate: number;

    username: string;
    authUser1Username: string;
    authUser2Username: string;
    authUser3Username: string;

    backOneTimes: number;
    backTwoTimes: number;
    backReceiveTimes: number;
}

interface Props {
    job: JobInfo;
    recoveryable?: boolean;
    onItemClick?: (jobInfo: any) => void;
    onRecovery?: () => void;
}


const getTeamJobType = (jobStatus: JobStatus): number => {
    if (jobStatus === JobStatus.annotate || jobStatus === JobStatus.backOne) {
        return 1;
    } else if (jobStatus === JobStatus.submitOne || jobStatus === JobStatus.backTwo) {
        return 2;
    } else if (jobStatus === JobStatus.submitTwo || jobStatus === JobStatus.backReceive) {
        return 3;
    } else if (jobStatus === JobStatus.submitReceive) {
        return 4;
    }
    return 0;
}

const JobComponent: FC<Props> = ({ job, recoveryable, onItemClick, onRecovery: recoveryQuery }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log('题包信息：', job);
    }, []);


    const goJob = (event: React.MouseEvent) => {
        if (onItemClick) {
            onItemClick(job);
            return;
        }

        const url = `/tasks/${job.taskId}/jobs/${job.id}`;
        if (event.ctrlKey) {
            // eslint-disable-next-line security/detect-non-literal-fs-filename
            window.open(url, '_blank', 'noopener noreferrer');
        } else {
            history.push(url);
        }

    }

    const giveUp = async () => {
        const param = {
            jobId: job.id,
            isAnnotation: getTeamJobType(job.jobStatus) === 1,
            isReviewFrist: getTeamJobType(job.jobStatus) === 2,
            isReviewSecord: getTeamJobType(job.jobStatus) === 3,
            isAccept: getTeamJobType(job.jobStatus) === 4,
        }
        const success = await dispatch(recoveryJobAsync(param)) as unknown as boolean;
        if (success === true) {
            recoveryQuery?.();
            message.success(t('jobs.giveUpJobSuccess'));
        } else {
            message.error(t('jobs.giveUpJobError'));
        }
    }

    const onRecovery = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();

        Modal.confirm({
            title: t('jobs.giveUpJob'),
            okText: t('jobs.giveUp'),
            okButtonProps: { danger: true },
            cancelText: t('jobs.cancel'),
            onOk: () => {
                giveUp();
            }
        })
    }

    const getJobUserView = (jobInfo: JobInfo) => {
        if (getTeamJobType(jobInfo.jobStatus) === 1) {
            return <>
                <Descriptions.Item label={t('jobs.backOneTimes')}>{job.backOneTimes}</Descriptions.Item>
                <Descriptions.Item label={t('jobs.firstReviewer')}>{job.authUser1Username}</Descriptions.Item>
            </>
        } else if (getTeamJobType(jobInfo.jobStatus) === 2) {
            return <>
                <Descriptions.Item label={t('jobs.backOneTimes')}>{job.backOneTimes}</Descriptions.Item>
                <Descriptions.Item label={t('jobs.backTwoTimes')}>{job.backTwoTimes}</Descriptions.Item>
                <Descriptions.Item label={t('jobs.annotatior')}>{job.username}</Descriptions.Item>
                <Descriptions.Item label={t('jobs.secordReviewer')}>{job.authUser2Username}</Descriptions.Item>
            </>
        } else if (getTeamJobType(jobInfo.jobStatus) === 3) {
            return <>
                <Descriptions.Item label={t('jobs.backTwoTimes')}>{job.backTwoTimes}</Descriptions.Item>
                <Descriptions.Item label={t('jobs.backReceiveTimes')}>{job.backReceiveTimes}</Descriptions.Item>
                <Descriptions.Item label={t('jobs.firstReviewer')}>{job.authUser1Username}</Descriptions.Item>
                <Descriptions.Item label={t('jobs.acceptor')}>{job.authUser3Username}</Descriptions.Item>
            </>
        } else if (getTeamJobType(jobInfo.jobStatus) === 4) {
            return <>
                <Descriptions.Item label={t('jobs.backReceiveTimes')}>{job.backReceiveTimes}</Descriptions.Item>
                <Descriptions.Item label={t('jobs.secordReviewer')}>{job.authUser2Username}</Descriptions.Item>
                {/* <Descriptions.Item label={t('jobs.acceptor')}>{job.authUser3Username}</Descriptions.Item> */}
            </>
        }
        return <>
            <Descriptions.Item label={t('jobs.backOneTimes')}>{job.backOneTimes}</Descriptions.Item>
            <Descriptions.Item label={t('jobs.backTwoTimes')}>{job.backTwoTimes}</Descriptions.Item>
            <Descriptions.Item label={t('jobs.backReceiveTimes')}>{job.backReceiveTimes}</Descriptions.Item>

            <Descriptions.Item label={t('jobs.annotatior')}>{job.username}</Descriptions.Item>
            <Descriptions.Item label={t('jobs.firstReviewer')}>{job.authUser1Username}</Descriptions.Item>
            <Descriptions.Item label={t('jobs.secordReviewer')}>{job.authUser2Username}</Descriptions.Item>
            <Descriptions.Item label={t('jobs.acceptor')}>{job.authUser3Username}</Descriptions.Item>
        </>
    }

    return (
        <List.Item className='listItemInfo' onClick={goJob} style={{ backgroundColor: getbackgroundColorByBackTimes(job.backOneTimes, job.jobStatus) }}>
            <Row className='jobInfo' justify='space-between'>
                <h3 style={{ color: getTextColorByJobStatus(job.jobStatus) }}>{t('jobs.job')}{job.id}</h3>
                {/* <h3>{t('jobs.job')}{job.name} —— {job.id}</h3> */}
                {/* <span>{job.jobStatus_}</span> */}
            </Row>
            <Row className='detailInfo' justify='space-between'>
                <Col span={1}></Col>
                <Col span={22}>
                    <Descriptions className='taskInfo' column={3}>
                        <Descriptions.Item
                            labelStyle={{ color: getTextColorByJobStatus(job.jobStatus) }}
                            contentStyle={{ color: getTextColorByJobStatus(job.jobStatus) }}
                            label={t('jobs.jobStatus')}
                        >{job.jobStatus_}</Descriptions.Item>
                        {/* <Descriptions.Item label={t('jobs.projectType')}>{job.projectType_}</Descriptions.Item> */}
                        {getJobUserView(job)}

                        <Descriptions.Item label={t('jobs.shapeNum')}>{job.shapeNum || 0}</Descriptions.Item>
                        <Descriptions.Item label={t('jobs.noteRate')}>{job.noteRate || 0}%</Descriptions.Item>
                        <Descriptions.Item label={t('jobs.frameNum')}>{job.totalFrame}</Descriptions.Item>
                        <Descriptions.Item label={t('jobs.statusDate')}>{job.statusUpdateTime}</Descriptions.Item>
                    </Descriptions>
                </Col>
                <Col span={1}></Col>
            </Row>
            {/* <Row className='taskInfo' justify='space-between'>
                <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {t('jobs.noteNum')}{job.noteNum}
                </span>
                <span>
                    {job.taskType_}
                </span>
            </Row> */}
            {recoveryable && <Row className='taskInfo' justify='end'>
                <Button type='primary' danger onClick={onRecovery}>{t('jobs.giveUp')}</Button>
            </Row>}
        </List.Item>
    );
};
export default JobComponent;
