/*
 * @Author: swxy
 * @Date: 2022-10-19 18:06:21
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
     //#region 2023-05-04 待校验
     'error.baseError': 'サービスにエラーが発生しました。後で再試行できます！',
     //#endregion

     'annotation.save.title': 'タイトル パッケージの保存中にエラーが発生しました',
     'annotation.save.detail': '最近のいくつかの操作を記録して、管理者に報告できます。 \r\n[OK]をクリックすると、ページが再度更新されます。 ',

     'error.register.username_length': 'ユーザー名は 5 文字より長くする必要があります',
     'error.register.username_char': 'ユーザー名に使用できるのは、数字、英語の大文字と小文字、"-"、"_" のみです',
     'error.register.username_invalid': '名前が無効です',

     'error.register.error': '登録に失敗しました。後でもう一度お試しください! ',

     'error.register.password_length': 'パスワードは 8 文字より長くする必要があります',
     'error.register.password_number': 'パスワードには少なくとも 1 つの数字が含まれている必要があります',
     'error.register.password_uppercaseChar': 'パスワードには少なくとも 1 つの大文字が含まれている必要があります',
     'error.register.password_lowercaseChar': 'パスワードには少なくとも 1 つの小文字を含める必要があります',

     'error.task.uploadImage': '画像情報が異常か、画像の幅と高さが一致しません! ',
     'error.job.taskInfo': '情報取得中にエラーが発生しました! ',
};
