function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useRef, useState } from 'react';
import raf from "../utils/raf";
import { MAX_SCALE, MIN_SCALE, getClientSize } from "./config";
var initialTransform = {
  x: 0,
  y: 0,
  rotate: 0,
  scale: 1,
  flipX: false,
  flipY: false
};
export default function useImageTransform(imgRef
// canvasRef: React.MutableRefObject<HTMLCanvasElement>,
) {
  var frame = useRef(null);
  var queue = useRef([]);
  var _useState = useState(initialTransform),
    _useState2 = _slicedToArray(_useState, 2),
    transform = _useState2[0],
    setTransform = _useState2[1];
  var resetTransform = function resetTransform() {
    setTransform(initialTransform);
  };

  /** Direct update transform */
  var updateTransform = function updateTransform(newTransform) {
    if (frame.current === null) {
      queue.current = [];
      frame.current = raf(function () {
        setTransform(function (preState) {
          var memoState = preState;
          queue.current.forEach(function (queueState) {
            memoState = _objectSpread(_objectSpread({}, memoState), queueState);
          });
          frame.current = null;
          return memoState;
        });
      });
    }
    queue.current.push(_objectSpread(_objectSpread({}, transform), newTransform));
  };

  /** Scale according to the position of clientX and clientY */
  var dispatchZoomChange = function dispatchZoomChange(ratio, clientX, clientY) {
    var _imgRef$current = imgRef.current,
      offsetWidth = _imgRef$current.offsetWidth,
      offsetHeight = _imgRef$current.offsetHeight,
      offsetLeft = _imgRef$current.offsetLeft,
      offsetTop = _imgRef$current.offsetTop;
    // const { width, height } = canvasRef.current;

    var newRatio = ratio;
    var newScale = transform.scale * ratio;
    if (newScale > MAX_SCALE) {
      newRatio = MAX_SCALE / transform.scale;
      newScale = MAX_SCALE;
    } else if (newScale < MIN_SCALE) {
      newRatio = MIN_SCALE / transform.scale;
      newScale = MIN_SCALE;
    }

    /** Default center point scaling */
    var mergedClientX = clientX !== null && clientX !== void 0 ? clientX : innerWidth / 2;
    var mergedClientY = clientY !== null && clientY !== void 0 ? clientY : innerHeight / 2;
    var diffRatio = newRatio - 1;
    /** Deviation calculated from image size */
    var diffImgX = diffRatio * offsetWidth * 0.5;
    var diffImgY = diffRatio * offsetHeight * 0.5;
    /** The difference between the click position and the edge of the document */
    //   const diffOffsetLeft =
    //   diffRatio * (mergedClientX - transform.x - offsetLeft);
    // const diffOffsetTop = diffRatio * (mergedClientY - transform.y - offsetTop);
    var diffOffsetLeft = diffRatio * (mergedClientX - transform.x - offsetLeft);
    var diffOffsetTop = diffRatio * (mergedClientY - transform.y - offsetTop);
    /** Final positioning */
    var newX = transform.x - (diffOffsetLeft - diffImgX);
    var newY = transform.y - (diffOffsetTop - diffImgY);

    /**
     * 计算超出边界的情况，使用了位运算判断正负，和注释是同样的效果
     */
    // const correctOffsetWidth = offsetWidth / 4;
    // const correctOffsetHeight = offsetHeight / 4;
    // if (Math.abs(newX) >= (offsetWidth * newScale) / 2 - 20) {
    //   // newX = newX > 0 ? newX - correctOffsetWidth : newX + correctOffsetWidth
    //   newX = newX - ((newX >> 31) | 1) * correctOffsetWidth;
    // }
    // if (Math.abs(newY) >= (offsetHeight * newScale) / 2 - 20) {
    //   // newY = newY > 0 ? newY - correctOffsetHeight : newY + correctOffsetHeight
    //   newY = newY - ((newY >> 31) | 1) * correctOffsetHeight;
    // }

    /**
     * When zooming the image
     * When the image size is smaller than the width and height of the window, the position is initialized
     */
    if (ratio < 1 && newScale === 1) {
      var mergedWidth = offsetWidth * newScale;
      var mergedHeight = offsetHeight * newScale;
      var _getClientSize = getClientSize(),
        clientWidth = _getClientSize.width,
        clientHeight = _getClientSize.height;
      if (mergedWidth <= clientWidth && mergedHeight <= clientHeight) {
        newX = 0;
        newY = 0;
      }
    }
    updateTransform({
      x: newX,
      y: newY,
      scale: newScale
    });
  };
  return {
    transform: transform,
    resetTransform: resetTransform,
    updateTransform: updateTransform,
    dispatchZoomChange: dispatchZoomChange
  };
}