/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { AnyAction } from 'redux';
import { AnnotationSubActionTypes } from 'actions/annotationSub-actions';
// import { AuthActionTypes } from 'actions/auth-actions';
// import { BoundariesActionTypes } from 'actions/boundaries-actions';
import { Canvas, CanvasMode, RectDrawingMethod } from 'cvat-canvas-wrapper';
// // import { Canvas3d } from 'canvas3d-wrapper';
// import { Canvas3d } from 'canvas3d-wrapper';

import { AnnotationSubState, ContextMenuType, ActiveControl, ShapeType, ObjectType, DimensionType } from './interfaces';
import { AnnotationActionTypes } from 'actions/annotation-actions';
// import { getDiff } from '../utils/math';
// import { JobStatus } from 'utils/ConstType';

// function updateActivatedStateID(newStates: any[], prevActivatedStateID: number | null): number | null {
//     return prevActivatedStateID === null || newStates.some((_state: any) => _state.clientID === prevActivatedStateID)
//         ? prevActivatedStateID
//         : null;
// }

// function updateActivatedStatesID(newStates: any[], prevSelectedStatesID: number[]): number[] {
//     const clientIDs = newStates.map((_state: any) => _state.clientID);

//     const selectedStatesID = clientIDs.filter((clientID: number) => prevSelectedStatesID.includes(clientID));

//     return selectedStatesID;
// }
const defaultState: AnnotationSubState = {
    states: [],
    activatedStateID: null,
    activatedAttributeID: null,
    selectedStatesID: [],
    collapsed: {},
    collapsedAll: true,

    canvas: {
        contextMenu: {
            visible: false,
            left: 0,
            top: 0,
            type: ContextMenuType.CANVAS_SHAPE,
            pointID: null,
            clientID: null,
        },
        instance: undefined,
        ready: false,
        activeControl: ActiveControl.CURSOR,
    },
    drawing: {
        activeShapeType: ShapeType.RECTANGLE,
        activeLabelID: 0,
        activeObjectType: ObjectType.SHAPE,
        activeNumOfPoints: 2,
        activeRectDrawingMethod: RectDrawingMethod.CLASSIC,
    },

    job: {
        labels: [],
        attributes: {},
        serialidItemBylabelId: {},
    },

    perspective: undefined,

    projectioning: false,
};

export default (state = defaultState, action: AnyAction): AnnotationSubState => {
    switch (action.type) {
        case AnnotationSubActionTypes.changePerspectiveView: {
            const { perspective, activatedStateID } = action.payload;
            let newActivatedID = null;

            if (activatedStateID) {
                const clientIDs = state.states.map((obj) => obj.clientID);
                newActivatedID = clientIDs.includes(activatedStateID) ? activatedStateID : null;
            }

            return {
                ...state,
                perspective,
                activatedStateID: newActivatedID,
            };
        }
        case AnnotationActionTypes.GET_JOB_SUCCESS: {
            const { job } = action.payload;

            // console.log('测试：', job)
            if (job.dimension === DimensionType.DIM_2D) {
                return state;
            }

            const labels = (job.labels || []).filter((label: any) => label.isSub);

            // console.log('标签列表：', labels)

            const attributes = labels.reduce((acc: Record<number, any[]>, label: any): Record<number, any[]> => {
                acc[label.id] = label.attributes.sort((attr1: any, attr2: any) => {
                    if (attr1.dumpName && attr2.dumpName) {
                        return attr1.dumpName.localeCompare(attr2.dumpName);
                    }
                    return attr1.name.localeCompare(attr2.name);
                });
                return acc;
            }, {});
            // 获取id

            return {
                ...state,
                states: [],
                job: {
                    ...state.job,
                    labels,
                    attributes,
                },
                canvas: {
                    ...state.canvas,
                    instance: new Canvas(),
                },
                drawing: {
                    ...state.drawing,
                    activeLabelID: labels.length ? labels[0].id : null,
                },
            };
        }
        case AnnotationSubActionTypes.changeFrameSuccess: {
            const { states } = action.payload;
            return {
                ...state,
                states,
            };
        }
        case AnnotationSubActionTypes.createAnnotationsSuccess: {
            const { states } = action.payload;
            return {
                ...state,
                states,
            };
        }
        case AnnotationSubActionTypes.updateAnnotationsSuccess: {
            const { states: updatedStates, minZ, maxZ } = action.payload;
            const { states: prevStates } = state;
            const nextStates = [...prevStates];

            const clientIDs = prevStates.map(
                (prevState: any): string => `${prevState.direction}_${prevState.clientID}`,
            );
            for (const updatedState of updatedStates) {
                const index = clientIDs.indexOf(`${updatedState.direction}_${updatedState.clientID}`);
                if (index !== -1) {
                    nextStates[index] = updatedState;
                }
            }

            // const maxZLayer = Math.max(state.zLayer.max, maxZ);
            // const minZLayer = Math.min(state.zLayer.min, minZ);

            return {
                ...state,
                // zLayer: {
                //     min: minZLayer,
                //     max: maxZLayer,
                //     cur: maxZLayer,
                // },
                states: nextStates,
            };
        }
        case AnnotationSubActionTypes.removeAnnotationsSuccess: {
            const { objectStates } = action.payload;
            const contextMenuClientID = state.canvas.contextMenu.clientID;
            const contextMenuVisible = state.canvas.contextMenu.visible;
            const objectStatesID: number[] = objectStates.map((objectState: any): number => objectState.clientID);
            const clientIDs: string[] = objectStates.map(
                (objectState: any): string => `${objectState.direction}_${objectState.clientID}`,
            );

            return {
                ...state,
                activatedStateID: null,
                selectedStatesID: [],
                states: state.states.filter(
                    (_objectState: any) => !clientIDs.includes(`${_objectState.direction}_${_objectState.clientID}`),
                ),
                canvas: {
                    ...state.canvas,
                    contextMenu: {
                        ...state.canvas.contextMenu,
                        clientID: objectStatesID.includes(contextMenuClientID as number) ? null : contextMenuClientID,
                        visible: objectStatesID.includes(contextMenuClientID as number) ? false : contextMenuVisible,
                    },
                },
            };
        }
        case AnnotationSubActionTypes.activateObject: {
            const { activatedStateID } = action.payload;

            const {
                canvas: { activeControl },
            } = state;

            if (activeControl !== ActiveControl.CURSOR) {
                return state;
            }

            return {
                ...state,
                activatedStateID,
                activatedAttributeID: null,
                selectedStatesID: [],
                collapsed: {
                    [activatedStateID]: false,
                },
                collapsedAll: true,
            };
        }
        case AnnotationSubActionTypes.projectionAnnotation: {
            // const {
            //     activatedStateID,
            // } = action.payload;

            return {
                ...state,
                projectioning: true,
            };
        }
        case AnnotationSubActionTypes.projectionAnnotationSuccess: {
            const { states } = action.payload;

            return {
                ...state,
                states,
                projectioning: false,
            };
        }
        case AnnotationSubActionTypes.projectionAnnotationFailed: {
            // const {
            //     activatedStateID,
            // } = action.payload;

            return {
                ...state,
                projectioning: false,
            };
        }
        case AnnotationActionTypes.drawSubPoints: {
            const { drawSubPoints } = action.payload;
            return {
                ...state,
                activatedStateID: null,
                selectedStatesID: [],
                canvas: {
                    ...state.canvas,
                    activeControl: drawSubPoints?.clientID ? ActiveControl.DRAW_POINTS : ActiveControl.CURSOR,
                },
            };
        }
        case AnnotationSubActionTypes.rememberCreatedObject: {
            const { payload } = action;

            let { activeControl } = state.canvas;
            if (payload.activeShapeType === ShapeType.RECTANGLE) {
                activeControl = ActiveControl.DRAW_RECTANGLE;
            } else if (payload.activeShapeType === ShapeType.splitRectangle) {
                activeControl = ActiveControl.draw_split_rectangle;
            } else if (payload.activeShapeType === ShapeType.POLYGON) {
                activeControl = ActiveControl.DRAW_POLYGON;
            } else if (payload.activeShapeType === ShapeType.POLYLINE) {
                activeControl = ActiveControl.DRAW_POLYLINE;
            } else if (payload.activeShapeType === ShapeType.POINTS) {
                activeControl = ActiveControl.DRAW_POINTS;
            } else if (payload.activeShapeType === ShapeType.ELLIPSE) {
                activeControl = ActiveControl.DRAW_ELLIPSE;
            } else if (payload.activeShapeType === ShapeType.CUBOID && activeControl !== ActiveControl.birdEyeMode) {
                activeControl = ActiveControl.DRAW_CUBOID;
            } else if (payload.activeShapeType === ShapeType.laneline) {
                activeControl = ActiveControl.laneline;
            } else if (payload.activeObjectType === ObjectType.TAG) {
                activeControl = ActiveControl.CURSOR;
            }

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
                drawing: {
                    ...state.drawing,
                    ...payload,
                    activeInteractor: undefined,
                },
            };
        }
        case AnnotationSubActionTypes.repeatDrawShape: {
            const { activeControl } = action.payload;

            return {
                ...state,
                activatedStateID: null,
                selectedStatesID: [],
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationSubActionTypes.repeatDrawShape: {
            const { activeControl } = action.payload;

            return {
                ...state,
                activatedStateID: null,
                selectedStatesID: [],
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationSubActionTypes.pasteShape: {
            const { activeControl } = action.payload;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
                activatedStateID: null,
                selectedStatesID: [],
            };
        }
        case AnnotationSubActionTypes.resetCanvas: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl: ActiveControl.CURSOR,
                },
            };
        }
        case AnnotationSubActionTypes.confirmCanvasReady: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    ready: true,
                },
            };
        }
        case AnnotationSubActionTypes.collapse_object_items: {
            const { clientID, isCollapse } = action.payload;

            return {
                ...state,
                collapsed: {
                    [clientID]: false,
                },
                collapsedAll: true,
            };
        }
        case AnnotationActionTypes.RESET_CANVAS: {
            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl: ActiveControl.CURSOR,
                },
            };
        }
        case AnnotationActionTypes.DRAG_CANVAS: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.DRAG_CANVAS : ActiveControl.CURSOR;

            return {
                ...state,
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationSubActionTypes.select_issue_position: {
            const { enabled } = action.payload;
            const activeControl = enabled ? ActiveControl.OPEN_ISSUE : ActiveControl.CURSOR;

            return {
                ...state,
                activatedStateID: null,
                selectedStatesID: [],
                canvas: {
                    ...state.canvas,
                    activeControl,
                },
            };
        }
        case AnnotationSubActionTypes.associatedAnnotationSuccess: {
            const { states } = action.payload;
            return {
                ...state,
                states,
            };
        }
        // case AnnotationSubActionTypes.associatedAnnotationFailed: {
        //     const { err } = action.payload;
        //     const activeControl = enabled ? ActiveControl.OPEN_ISSUE : ActiveControl.CURSOR;

        //     return {
        //         ...state,
        //         states,
        //     };
        // }
        default: {
            return state;
        }
    }
};
