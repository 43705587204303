// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
// import Popover from 'antd/lib/popover';
import Icon, { LineOutlined } from '@ant-design/icons';

import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'canvas3d-wrapper';
// import { ShapeType } from 'reducers/interfaces';

import { CubeIcon } from 'icons';
import { CombinedState, ObjectType, ShapeType } from 'reducers/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { rememberObject } from 'actions/annotation-actions';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
// import withVisibilityHandling from './handle-popover-visibility';

// import DrawShapePopoverContainer from
// 'containers/annotation-page/standard-workspace/controls-side-bar/draw-shape-popover';

export interface Props {
    canvasInstance: Canvas | Canvas3d;
    isDrawing: boolean;
    disabled?: boolean;
    cursorShortkey?: string;
    onClick?(): void;
    onClickEvent?(callback: (objectType: ObjectType) => void): void;
}

// const CustomPopover = withVisibilityHandling(Popover, 'draw-cuboid');
function LanelineControl(props: Props): JSX.Element {
    const { canvasInstance, isDrawing, disabled, cursorShortkey, onClick, onClickEvent } = props;
    const dispatch = useDispatch();
    const { activeLabelID, activeNumOfPoints, activeRectDrawingMethod, activeCuboidDrawingMethod } = useSelector(
        (state: CombinedState) => state.annotation.drawing,
    );

    // const dynamcPopoverPros = isDrawing ?
    //     {
    //         overlayStyle: {
    //             display: 'none',
    //         },
    //     } :
    //     {};
    const { t } = useTranslation();
    const onDraw = (objectType: ObjectType = ObjectType.SHAPE): void => {
        canvasInstance.cancel();
        canvasInstance.draw({
            enabled: true,
            shapeType: ShapeType.laneline,
        });

        // 暂不支持连续帧
        dispatch(
            rememberObject({
                activeObjectType: ObjectType.SHAPE,
                activeShapeType: ShapeType.laneline,
                activeLabelID,
                activeNumOfPoints,
                activeRectDrawingMethod,
                activeCuboidDrawingMethod,
            }),
        );
    };

    const dynamicIconProps = isDrawing
        ? {
            className: 'cvat-draw-cuboid-control cvat-active-canvas-control',
            onClick: (): void => {
                canvasInstance.draw({
                    enabled: false,
                    shapeType: ShapeType.laneline,
                });
                canvasInstance.cancel();
            },
        }
        : {
            className: 'cvat-draw-cuboid-control',
            onClick: (): void => {
                if (onClick) {
                    onClick();
                } else {
                    onDraw(ObjectType.SHAPE);
                }
            },
        };

    onClickEvent?.(onDraw);

    return disabled ? (
        <LineOutlined className='cvat-draw-cuboid-control cvat-disabled-canvas-control' />
    ) : (
        // <CustomPopover
        //     {...dynamcPopoverPros}
        //     overlayClassName='cvat-draw-shape-popover'
        //     placement='right'
        //     content={<DrawShapePopoverContainer shapeType={ShapeType.CUBOID} />}
        // >
        // <Tooltip title='画3D框'>
        // <Tooltip placement='rightTop' title={onClick ? '按下可进入画长方体模式。重复操作取消' : `${cursorShortkey || ''}画3D框`}>
        <Tooltip
            placement='rightTop'
            title={
                onClick
                    ? t('workspace.side.controls.cuboid_tip')
                    : `${cursorShortkey || t('workspace.side.controls.cuboid_3d_tip')}`
            }
        >
            {/* <Icon {...dynamicIconProps} component={CubeIcon} /> */}
            <LineOutlined {...dynamicIconProps} />
        </Tooltip>
        // </CustomPopover>
    );
}

export default React.memo(LanelineControl);
