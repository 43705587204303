
import React, { useState, memo } from 'react';
import { Row, Col } from 'antd/lib/grid';
import { MoreOutlined, RightOutlined, DownOutlined } from '@ant-design/icons';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';
import { Avatar, Space, Tooltip } from 'antd';

import { ObjectType, ShapeType, ColorBy } from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
import ObjectButtonsContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-buttons';
import ItemMenu from './object-item-menu';

import './styles.scss';
import { useTranslation } from 'react-i18next';

interface Props {
    jobInstance: any;
    readonly: boolean;
    clientID: number;
    serverID: number | undefined;
    // labelID: number;
    // labels: any[];
    shapeType: ShapeType;
    objectType: ObjectType;
    color: string;
    colorBy: ColorBy;
    shapeIndex?: number;
    locked: boolean;
    changeColorShortcut: string;
    copyShortcut: string;
    pasteShortcut: string;
    propagateShortcut: string;
    changeStartFrameShortcut: string;
    toBackgroundShortcut: string;
    toForegroundShortcut: string;
    removeShortcut: string;
    collapsed: boolean;
    changeColor(color: string): void;
    // changeLabel(label: any): void;
    copy(): void;
    remove(): void;
    propagate(): void;
    changeStartFrame(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    resetCuboidPerspective(): void;
    collapse(): void;
    shouContextMenuItem(): void;
}


const SwitchOutlined = memo((superprops: { collapsed: boolean }): JSX.Element => {
    const { collapsed } = superprops;
    const { t } = useTranslation();

    return (
        // <CVATTooltip title='Show detail attributes'>

        <Col span={1}>
            <CVATTooltip title={t('workspace.side.object.show_detail_att')}>
                {collapsed ? <RightOutlined /> : <DownOutlined />}
            </CVATTooltip>
        </Col>
    );
});

const ClientComponent = memo((superprops: { clientID: number; shapeIndex?: number; }): JSX.Element => {
    const { clientID, shapeIndex } = superprops;
    const { t } = useTranslation();

    const title = typeof shapeIndex === 'number' ? t('workspace.side.object.clientAndIndex_detail') : t('workspace.side.object.client_detail');
    return (
        <Col span={3}>
            <Tooltip title={title}>
                <Text className='cvat-objects-sidebar-state-item-object-clientID-text' style={{ fontSize: 12 }}>
                    {clientID}
                    {typeof shapeIndex === 'number' ? `-${shapeIndex}` : ''}
                </Text>
            </Tooltip>
        </Col>
    );
});

const TypeComponent = memo((superprops: { objectType: ObjectType, shapeType: ShapeType }): JSX.Element => {
    const { shapeType, objectType } = superprops;
    const { t } = useTranslation();

    const name = objectType === ObjectType.TAG ? ObjectType.TAG.toUpperCase() : `${t(`shape.${shapeType}`).toUpperCase()}`;
    const fullName = t(`shape.${shapeType}`);

    return (
        <Col span={3}>
            <Space>
                <Tooltip title={fullName}>
                    <Text
                        type='secondary'
                        style={{ fontSize: 10 }}
                        className='cvat-objects-sidebar-state-item-object-type-text'
                    >
                        {name}
                    </Text>
                </Tooltip>
                {objectType === ObjectType.TRACK && (
                    <Tooltip title={t('workspace.track')}>
                        <Avatar
                            style={{
                                backgroundColor: 'green',
                                verticalAlign: 'middle',
                                fontSize: 8,
                                width: '18px',
                                height: '18px',
                                lineHeight: '18px',
                            }}
                            size='small'
                        >
                            {t('workspace.side.object.track_simple')}
                        </Avatar>
                    </Tooltip>
                )}
            </Space>
        </Col>
    );
});


const MoreOutlinedComponent = memo((superprops: Props): JSX.Element => {
    const {
        jobInstance,
        readonly,
        shapeType,
        objectType,
        serverID,

        color,
        colorBy,
        locked,
        changeColorShortcut,
        copyShortcut,
        pasteShortcut,
        propagateShortcut,
        toBackgroundShortcut,
        changeStartFrameShortcut,
        toForegroundShortcut,
        removeShortcut,


        changeColor,
        copy,
        remove,
        propagate,
        changeStartFrame,
        createURL,
        switchOrientation,
        toBackground,
        toForeground,
        resetCuboidPerspective,
        shouContextMenuItem,
    } = superprops;

    const [menuVisible, setMenuVisible] = useState(false);
    const [colorPickerVisible, setColorPickerVisible] = useState(false);

    const changeMenuVisible = (visible: boolean): void => {
        if (!visible && colorPickerVisible) return;
        setMenuVisible(visible);
    };

    const changeColorPickerVisible = (visible: boolean): void => {
        if (!visible) {
            setMenuVisible(false);
        }
        setColorPickerVisible(visible);
    };


    return (
        <Col
            span={2}
            onClick={(e: React.MouseEvent): void => {
                e.stopPropagation();
            }}
            className='cvat-object-item-button-hover'
        >
            <Dropdown
                open={menuVisible}
                trigger={['click']}
                onOpenChange={changeMenuVisible}
                placement='bottomLeft'
                overlay={ItemMenu({
                    jobInstance,
                    readonly,
                    serverID,
                    locked,
                    shapeType,
                    objectType,
                    color,
                    colorBy,
                    colorPickerVisible,
                    changeColorShortcut,
                    copyShortcut,
                    pasteShortcut,
                    propagateShortcut,
                    changeStartFrameShortcut,
                    toBackgroundShortcut,
                    toForegroundShortcut,
                    removeShortcut,
                    changeColor,
                    copy,
                    remove,
                    propagate,
                    changeStartFrame,
                    createURL,
                    switchOrientation,
                    toBackground,
                    toForeground,
                    resetCuboidPerspective,
                    changeColorPickerVisible,
                    changeMenuVisible,
                    shouContextMenuItem,
                })}
            >
                <MoreOutlined />
            </Dropdown>
        </Col>
    );
});



function ItemTopComponent(props: Props): JSX.Element {
    const {
        readonly,
        clientID,
        shapeType,
        objectType,
        collapsed,
        shapeIndex,
        // serverID,

        // color,
        // colorBy,
        // locked,
        // changeColorShortcut,
        // copyShortcut,
        // pasteShortcut,
        // propagateShortcut,
        // toBackgroundShortcut,
        // changeStartFrameShortcut,
        // toForegroundShortcut,
        // removeShortcut,


        // changeColor,
        // // changeLabel,
        // copy,
        // remove,
        // propagate,
        // changeStartFrame,
        // createURL,
        // switchOrientation,
        // toBackground,
        // toForeground,
        // resetCuboidPerspective,
        // jobInstance,
        // shouContextMenuItem,
        collapse,
    } = props;

    // const { t } = useTranslation();

    return (
        // <Row align='middle'>
        //     <Col span={10}>
        //         <Text style={{ fontSize: 12 }}>{clientID}</Text>
        //         <br />
        //         <Text
        //             type='secondary'
        //             style={{ fontSize: 10 }}
        //             className='cvat-objects-sidebar-state-item-object-type-text'
        //         >
        //             {type}
        //         </Text>
        //     </Col>
        //     <Col span={12}>
        //         <CVATTooltip title='Change current label'>
        //             <LabelSelector
        //                 disabled={readonly}
        //                 size='small'
        //                 labels={labels}
        //                 value={labelID}
        //                 onChange={changeLabel}
        //                 className='cvat-objects-sidebar-state-item-label-selector'
        //             />
        //         </CVATTooltip>
        //     </Col>
        <Row align='middle' className='cvat-objects-sidebar-state-item-object-row' onClick={collapse}>
            {/* <Col span={1}> */}
            <SwitchOutlined collapsed={collapsed} />
            {/* </Col> */}
            {/* <Text className='cvat-objects-sidebar-state-item-object-clientID-text' style={{ fontSize: 12 }}>
                    {clientID}
                </Text> */}
            <ClientComponent clientID={clientID} shapeIndex={shapeIndex} />
            <TypeComponent shapeType={shapeType} objectType={objectType} />

            <Col span={14}>
                <ObjectButtonsContainer readonly={readonly} clientID={clientID} />
            </Col>

            <MoreOutlinedComponent {...props} />
        </Row>
    );
}

export default React.memo(ItemTopComponent);
