/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2023-09-11 16:22:08
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { ReactPortal, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import { Row, Col } from 'antd/lib/grid';
import { Typography, FormInstance } from 'antd';
import { getRandomColor } from 'utils/utils';

import { reviewActions, finishIssueAsync } from 'actions/review-actions';
import { Store } from 'antd/lib/form/interface';
import { CombinedState, IssueOS } from 'reducers/interfaces';
import './styles.scss';

const { Text } = Typography;

interface FormProps {
    top: number;
    left: number;
    angle: number;
    scale: number;
    submit(message: string): void;
    cancel(): void;
}

function MessageForm(props: FormProps): JSX.Element {
    const {
        top, left, angle, scale, submit, cancel,
    } = props;
    const [loading, setLoading] = useState(false);

    const formInstance = useRef<FormInstance>(null);

    const beforehandIssues: IssueOS[] = useSelector((state: CombinedState) => state.review.beforehandIssues) || [];

    const handleSubmit = async (values: Store) => {
        setLoading(true);
        try {
            submit(values.issue_description);
        } catch (error) {
            setLoading(false);
            throw error;
        }
        setLoading(false);
    }

    const selectMessage = (message: string): void => {
        formInstance.current?.setFieldsValue({ issue_description: message });
    };

    return (
        <Form
            className='cvat-create-issue-dialog'
            ref={formInstance}
            style={{ top, left, transform: `scale(${scale}) rotate(${angle}deg)` }}
            onFinish={(values: Store) => handleSubmit(values)}
        >
            {/* <Form.Item
                    name='issue_description'
                    rules={[{ required: true, message: 'Please, fill out the field' }]}
                > */}
            <Form.Item name='issue_description' rules={[{ required: true, message: '请填写该字段' }]}>
                {/* <Input autoComplete='off' placeholder='Please, describe the issue' /> */}
                <Input autoComplete='off' placeholder='请描述这里的问题' />
            </Form.Item>
            <Row justify='space-between' className='issue_content_list'>
                {beforehandIssues.map((item: IssueOS) => <Text className='beforehand_issue' style={{ borderColor: getRandomColor() }} onClick={() => selectMessage(item.content)}>{item.content}</Text>)}
            </Row>
            <Row justify='space-between'>
                <Col>
                    <Button onClick={cancel} type='ghost'>
                        {/* Cancel */}
                        取消
                    </Button>
                </Col>
                <Col>
                    <Button type='primary' htmlType='submit' loading={loading}>
                        {/* Submit */}
                        确定
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

interface Props {
    top: number;
    left: number;
    angle: number;
    scale: number;
}

export default function CreateIssueDialog(props: Props): ReactPortal {
    const dispatch = useDispatch();
    const {
        top, left, angle, scale,
    } = props;

    return ReactDOM.createPortal(
        <MessageForm
            top={top}
            left={left}
            angle={angle}
            scale={scale}
            submit={(message: string) => {
                dispatch(finishIssueAsync(message));
            }}
            cancel={() => {
                dispatch(reviewActions.cancelIssue());
            }}
        />,
        window.document.getElementById('root') as HTMLElement,
    );
}
