/*
 * @Author: swxy
 * @Date: 2022-04-14 15:08:04
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/components/annotation-page/standard3D-workspace/standard3D-workspace.tsx
 * Copyright (C) Amygo
 */

import './styles.scss';
import React, { useState, useEffect } from 'react';
import Layout from 'antd/lib/layout';

import CanvasWrapperContainer from 'containers/annotation-page/canvas/canvas-wrapper3D2D';
import ControlsSideBarContainer from 'containers/annotation-page/standard3D2D-workspace/controls-side-bar/controls-side-bar';
// import ObjectSideBarComponent from 'components/annotation-page/standard-workspace/objects-side-bar/objects-side-bar';
import ObjectSideBarComponent from './objects-side-bar/objects-side-bar';
import ObjectsListContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/objects-list';
import PropagateConfirmContainer from 'containers/annotation-page/standard-workspace/propagate-confirm';
import CanvasContextMenuContainer from 'containers/annotation-page/canvas/canvas-context-menu';
import CanvasPointContextMenuComponent from 'components/annotation-page/canvas/canvas-point-context-menu';

import ControlsSideBarSubComponent from './sub/controls-side-bar/controls-side-bar';
import CanvasWrapper2DComponent from './sub/canvas-wrapper';
import ObjectSideBar2DComponent from './sub/objects-side-bar/objects-side-bar';

import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { disableEditing3D } from 'utils/constant';
import IssueAggregator3DComponent from 'components/annotation-page/review3D2D-workspace/issues-aggregator';
import IssueAggregator2DComponent from 'components/annotation-page/review3D2D-workspace/sub/issues-aggregator';
import TrackFrameComponent from 'components/annotation-page/track-frame-confirm';
import { changePerspectiveView } from 'actions/annotationSub-actions';
import { CloseCircleFilled } from '@ant-design/icons';

// import IssueAggregator2DComponent from 'components/annotation-page/review/issues-aggregator';

export default function StandardWorkspace3DComponent(): JSX.Element {
    // const { cameraIntrinsics, cameraExtrinsics } =
    //     useSelector((state: CombinedState) => state.annotation.annotations.annotationsSetting);
    const dispatch = useDispatch();
    const { roleType, jobStatus, perspective, sidebarPositionLeft } = useSelector((state: CombinedState) => ({
        roleType: state.auth.user?.roleType,
        jobStatus: state.annotation.job.instance?.jobStatus,
        perspective: state.subAnnotation.perspective,
        sidebarPositionLeft: state.annotation.sidebarPositionLeft,
    }));
    const [disableEdit, setDisableEdit] = useState(true);

    useEffect(() => {
        setDisableEdit(disableEditing3D(jobStatus, roleType));
    }, [jobStatus, roleType]);

    const clearPerspectiveView = () => {
        dispatch(changePerspectiveView());
    }
    return (
        <Layout hasSider className='cvat-standard-workspace'>
            {
                perspective && <div className='aatp_standard_sub_background' onClick={clearPerspectiveView}>
                    <div className='aatp_standard_sub_mian' onClick={(e) => e.stopPropagation()}>
                        <ControlsSideBarSubComponent />
                        <CanvasWrapper2DComponent />
                        <ObjectSideBar2DComponent />
                        <IssueAggregator2DComponent />
                    </div>
                    <div className='aatp_standard_sub_close'>
                        <CloseCircleFilled />
                    </div>
                </div>
            }

            <ControlsSideBarContainer readonly={disableEdit} />
            <CanvasWrapperContainer disableEdit={disableEdit} />
            <ObjectSideBarComponent objectsList={<ObjectsListContainer readonly={disableEdit} />} />
            <PropagateConfirmContainer />
            <CanvasContextMenuContainer />
            <CanvasPointContextMenuComponent />
            <IssueAggregator3DComponent />
            <TrackFrameComponent />
            {/* <Setting3D /> */}
        </Layout>
    );
}
