
import { Button, Row, Divider, Space, Cascader, CheckboxOptionType, message } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamUserListAsync, getTeamTaskListAsync, applyJobAsync } from "actions/jobs-actions"
import { CombinedState, RoleType } from "reducers/interfaces";
import { useTranslation } from "react-i18next";

interface Option {
    value?: string | number | null;
    label: React.ReactNode;
    children?: Option[];
    isLeaf?: boolean;
    loading?: boolean;
}

interface ApplyDataType {
    taskId?: number;
    isAnnotation: boolean;
    isReviewFrist: boolean;
    isReviewSecord: boolean;
    isAccept: boolean;
}

interface Porps {
    onCancel?: () => void;
    onOk?: () => void;
}

const selectStyle: React.CSSProperties = {
    width: "60%",
    minWidth: "300px"
}

const checkRoleAndTeamJobType = (roleType: RoleType, teamJobType: number): boolean => {
    let result = false
    if (roleType === RoleType.annotator && teamJobType === 1) {
        // 标注员
        result = true;
    } else if (roleType === RoleType.first_reviewer && teamJobType === 2) {
        // 一审
        result = true;
    } else if (roleType === RoleType.second_reviewer && teamJobType === 3) {
        // 二审
        result = true;
    } else if (roleType === RoleType.acceptor && teamJobType === 4) {
        // 验收
        result = true;
    } else if (roleType >= RoleType.team_leader) {
        result = true;
    }
    return result;
}

const data: ApplyDataType = {
    isAnnotation: false,
    isReviewFrist: false,
    isReviewSecord: false,
    isAccept: false,
}

const ApplyJob: FC<Porps> = ({ onOk, onCancel }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const user = useSelector((state: CombinedState) => state.auth.user);
    const [teamUserLoading, setTeamUserLoading] = useState(false);
    const [applyLoading, setApplyLoading] = useState(false);
    const [options, setOptions] = useState<Option[]>([]);

    const queryTeamUserList = async () => {
        setTeamUserLoading(true);
        // const result = await dispatch(getTeamUserListAsync({ userId: user.id })) as unknown as any;
        const result = await dispatch(getTeamUserListAsync({ userId: user.id })) as unknown as any;
        setTeamUserLoading(false);
        setOptions(result.map((team: any): Option => ({ value: team.teamId, label: team.name, isLeaf: false })))
    }

    const queryTeamTaskList = async (query: any) => {
        const result = await dispatch(getTeamTaskListAsync(query)) as unknown as any;
        return result
    }


    useEffect(() => {
        queryTeamUserList();
    }, []);

    const onChange = (value: (string | number)[], selectedOptions: Option[]) => {
        const key = value[value.length - 1];
        if (typeof key === 'string') {
            const [taskIdStr, teamJobTypeStr] = key.split('-');
            const taskId = taskIdStr ? parseInt(taskIdStr) : undefined;
            const teamJobType = teamJobTypeStr ? parseInt(teamJobTypeStr) : undefined;
            data.taskId = taskId;
            data.isAnnotation = teamJobType === 1;
            data.isReviewFrist = teamJobType === 2;
            data.isReviewSecord = teamJobType === 3;
            data.isAccept = teamJobType === 4;
        }
        // console.log(value, selectedOptions);
    };

    const loadData = (selectedOptions: Option[]) => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        // load options lazily
        // setTimeout(() => {
        //     targetOption.loading = false;
        //     targetOption.children = [
        //         {
        //             label: `${targetOption.label} Dynamic 1`,
        //             value: 21,
        //         },
        //         {
        //             label: `${targetOption.label} Dynamic 2`,
        //             value: 23,
        //         },
        //     ];
        //     setOptions([...options]);
        // }, 1000);
        queryTeamTaskList({
            teamId: targetOption.value,
            assignTaskType: [1],
        }).then(result => {
            const tasks = result.filter((item: any) => checkRoleAndTeamJobType(user.roleType, item.teamJobType)).map((data: any): Option => {
                return { value: `${data.taskId}-${data.teamJobType}`, label: `${data.engineTaskName}-${data.teamJobType_}` }
            });
            targetOption.loading = false;
            targetOption.children = tasks;
            setOptions([...options]);
            if (!tasks || tasks.length === 0) {
                message.info(t('jobs.noTask'));
            }
            // console.log('当前团队的任务列表：', result);
        })
    };

    const apply = async () => {
        if (data.taskId) {
            setApplyLoading(true)
            const response = await dispatch(applyJobAsync(data)) as unknown as any;
            setApplyLoading(false)
            if (response?.success && response?.data) {
                message.success(`${t('jobs.applySuccess')}-${response.data.name}`)
                onOk?.();
            } else {
                message.error(response?.message)
            }
        } else {
            message.error(t('jobs.applyFilterError'))
        }
    }

    return (
        <>
            <Cascader
                style={selectStyle}
                options={options}
                loading={teamUserLoading}
                placeholder={t('jobs.applyFilterPlaceholder')}
                loadData={loadData}
                onChange={onChange}
            // changeOnSelect
            />
            <Divider />
            <Row justify='center'>
                <Space>
                    <Button onClick={apply} type='primary' loading={applyLoading}>{t('jobs.apply')}</Button>
                    <Button onClick={onCancel}>{t('jobs.cancel')}</Button>
                </Space>
            </Row>
        </>
    )
}

export default ApplyJob;