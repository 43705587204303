/*
 * @Author: swxy
 * @Date: 2022-08-15 14:21:02
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import userNotice from './other/userNotice';

export default {
     'login.login': 'login',
     'login.register': 'Registrieren',

     'login.username': 'Benutzername',
     'login.passwort': 'passwort',
     'login.empty_username': 'Bitte geben Sie einen Benutzernamen ein',
     'login.empty_password': 'Bitte geben Sie ein Passwort ein',

     'login.confirm_password': 'Passwort bestätigen',
     'login.confirm_empty_password': 'Bitte geben Sie das Passwort erneut ein',
     'login.valid_password': 'Die doppelt eingegebenen Passwörter sind inkonsistent! ',

     'login.title': 'Werkzeuge zur Datenannotierung',
     'login.demoTitle': 'Automatisches Etikett',
     'login.demoSubTitle': '—— Demoversion',
     'login.resetPassword': 'Passwort vergessen',

     'login.nickName': 'Spitzname',
     'login.email': 'E-Mail',
     'login.mobile': 'Mobil',
     'login.affiliation': 'Firma/Schule',
     'login.info': 'Sonstiges',
     'login.verifyCode': 'Bestätigungscode',
     'login.placeholder_nickName': 'Bitte geben Sie einen Spitznamen ein',
     'login.placeholder_email': 'Bitte geben Sie Ihre E-Mail-Adresse ein',
     'login.placeholder_mobile': 'Bitte geben Sie Ihre Handynummer ein',
     'login.placeholder_affiliation': 'Bitte Firma/Schule eingeben',
     'login.placeholder_info': 'Bitte andere eingeben',
     'login.placeholder_verifyCode': 'Bitte geben Sie den Bestätigungscode ein',

     'login.valid_email': 'E-Mail-Formatfehler! ',
     'login.valid_mobile': 'Handy-Formatfehler! ',

     'login.hasAccount': 'Sie haben bereits ein Konto? du kannst',

     'login.registerSuccess': 'Registrierung erfolgreich! ',

     'login.newPassword': 'Neues Passwort',
     'login.empty_newPassword': 'Bitte geben Sie ein neues Passwort ein',
     'login.confirm_empty_newPassword': 'Bitte geben Sie das neue Passwort erneut ein',

     'login.forgetPassword': 'Passwort vergessen',
     'login.email.change': 'Ersetzen',
     'login.validcode.get': 'Erhalten',
     'login.validcode.placeholder': 'Bitte holen Sie sich den Verifizierungscode ein und geben Sie ihn ein',

     'login.imageVerifyCode': 'Bildüberprüfungscode',
     'login.imageVerifyCode_placeholder': 'Bitte holen Sie sich den Bildverifizierungscode ein und geben Sie ihn ein',
     'login.emailVerifyCode': 'E-Mail-Verifizierungscode',
     'login.emailVerifyCode_placeholder': 'Bitte holen Sie sich den E-Mail-Bestätigungscode ein und geben Sie ihn ein',

     'login.imageCodeNeed': 'Bitte geben Sie den Bildverifizierungscode ein',
     'login.sendEmailValidCodeSuccess': 'Der Bestätigungscode wurde an die E-Mail gesendet, bitte überprüfen Sie ihn',
     'login.sendEmailValidCodeError': 'Fehler beim Senden des Überprüfungscodes!',

     //#region 2023-05-04 待校验
     'register.userNotice': '"Benutzervereinbarung" ',
     'register.confirmUserNotice': 'Bestätigen und zustimmen, um zu unterzeichnen',
     'register.userNoticeTip': 'Bitte überprüfen Sie die "Nutzungsbedingungen" und stimmen Sie ihnen zu.',
     'register.userNotice.title': 'Benutzervereinbarung',
     ...userNotice,
     //#endregion
};
