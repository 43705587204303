/*
 * @Author: swxy
 * @Date: 2022-12-13 10:27:25
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import * as THREE from 'three';
// import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';
import { PCDLoader } from './PCDLoader';
// import { PCDLoader } from './PCDMaxLoader';
// import CameraControls from 'camera-controls';
import Controller from './controller';
import {
    UpdateReasons,
    ViewsDOM,
    Views,
    RenderView,
    Mode,
    ViewType,
    ObjectState,
    Action,
    DrawData,
    Planes,
    Issue,
    DrawMode,
    // CameraAction,
    ArrowType,
    ObjectType,
    ExportBaseInfo,
    PositionInfo,
    ShapeType,
    CameraMode,
    Pcd,
    SubElement,
    PointCloudColorBy,
} from './interface';
import { Listener, MasterProps } from './master';
import { PerspectiveView } from './perspectiveView';
import CONST from './consts';
import { ThreeBaseView } from './threeBaseView';
import CuboidModel from './objects/cuboid';
import Object3DBase, {
    // createRotationHelper,
    selectMesh,
    // setTranslationFaceHelper,
    // setTranslationHelper,
} from './objects/object3DManager';
import { Euler, Matrix4, Quaternion, Vector3 } from 'three';
// import { resizeRendererToDisplaySize } from './public';
import BaseView, { PositionDirection } from './baseView';
import SphereModel, { setEdges as SphereSetEdges } from './objects/sphere';
import DrawObject from './drawObject';
// import { ExportProjectType } from './interface';
import { getExportAttributes } from './exportData';
import { transLidarPositionToImagesPoints } from './utils';
// import LanelineMesh from './objects/lanelineMesh';
import { pointName } from './objects/PointMesh';
import BezierHelper from './helpers/bezierHelper';
import LaneHelper from './helpers/laneHelper';
import Bezier from './objects/bezier/bezierModel';
import BezierPoint, { PointType } from './objects/bezier/point';
import Lane from './objects/lane/laneModel';

export interface Canvas3dViewProps {
    html(): ViewsDOM;
    render(): void;
    keyControls(keys: KeyboardEvent): void;
}

// const objectContrastKeys = ['clientID', 'frame', 'points', 'color', 'lock', 'pinned'];

// let defaultZ = 16.356113433;
// let defaultZ = 0;

enum TargetType {
    none = '',
    issue = 'issue',
    cuboid = 'cuboid',
    laneline = 'laneline',
    bezier2 = 'bezier2',
}

export default class Canvas3dView implements Canvas3dViewProps, Listener {
    private controller: Controller;
    private views: Views;
    // private clock: THREE.Clock;
    // private speed: number;
    private action: Action;
    private cube: Object3DBase | Bezier | Lane;
    private timer: NodeJS.Timer;

    private meshCollection: Map<number, THREE.Object3D>;

    public select?: Bezier;

    // 状态控制，取用方便些
    private set mode(value: Mode) {
        this.controller.mode = value;
    }
    private get mode(): Mode {
        return this.controller.mode;
    }

    private get activeID(): string {
        if (this.controller.data.activeElement.clientID) {
            return this.controller.data.activeElement.clientID + '';
        } else if (this.controller.data.activeElement.issueID) {
            return 'issue' + this.controller.data.activeElement.issueID;
        }
        return this.controller.data.activeElement.clientID + '';
    }

    private get activeObjectType(): TargetType {
        const { clientID, issueID } = this.controller.data.activeElement;
        if (clientID) {
            const object = this.controller.data.objects.get(clientID);
            if (object) {
                return object.shapeType as unknown as TargetType;
            }
        } else if (issueID) {
            return TargetType.issue;
        }
        return TargetType.none;
    }

    constructor(controller: Controller) {
        this.controller = controller;

        // CameraControls.install({ THREE });

        // this.clock = new THREE.Clock();
        // this.speed = CONST.MOVEMENT_FACTOR;

        this.meshCollection = new Map();

        this.action = {
            loading: false,
            cameraMove: false,
            frameCoordinates: new Vector3(0, 0, 0),

            actionView: null,
            isAction: false,
            resetCamera: false,
            selectable: true,

            translation: {
                status: false,
                helper: null,
                coordinates: null,
                offset: new THREE.Vector3(),
                inverseMatrix: new THREE.Matrix4(),
            },
            rotation: {
                status: false,
                helper: null,
                recentMouseVector: new THREE.Vector2(0, 0),
                screenInit: {
                    x: 0,
                    y: 0,
                },
                screenMove: {
                    x: 0,
                    y: 0,
                },
            },
            resize: {
                status: false,
                helper: null,
                recentMouseVector: new THREE.Vector2(0, 0),
                initScales: {
                    x: 1,
                    y: 1,
                    z: 1,
                },
                memScales: {
                    x: 1,
                    y: 1,
                    z: 1,
                },
                resizeVector: new THREE.Vector3(0, 0, 0),
                frontBool: false,
            },
            resizeFace: {
                status: false,
                helper: null,
                recentMouseVector: new THREE.Vector2(0, 0),
                initScales: {
                    x: 1,
                    y: 1,
                    z: 1,
                },
                memScales: {
                    x: 1,
                    y: 1,
                    z: 1,
                },
                resizeVector: new THREE.Vector3(0, 0, 0),
                posName: '',
            },

            drawObject: new DrawObject(),

            // selectPointName: `${pointName}0`,
            movePointName: `${pointName}0`,

            attachCamera: this.attachCamera.bind(this),
            detachCamera: this.detachCamera.bind(this),

            helper: undefined,

            look: {
                lookMesh: undefined,
            },
        };

        this.views = {
            perspective: new PerspectiveView(controller, this.action),
            top: new ThreeBaseView(controller, this.action, ViewType.TOP),
            side: new ThreeBaseView(controller, this.action, ViewType.SIDE),
            front: new ThreeBaseView(controller, this.action, ViewType.FRONT),
        };

        this.views.perspective.parent = this;
        this.views.top.parent = this;
        this.views.side.parent = this;
        this.views.front.parent = this;

        const canvasPerspectiveView = this.views.perspective.renderer.domElement;

        this.mode = Mode.IDLE;

        Object.keys(this.views).forEach((view: string): void => {
            this.views[view as keyof Views].scene.background = new THREE.Color(0x000000);
        });

        canvasPerspectiveView.addEventListener('dblclick', (e: MouseEvent): void => {
            e.preventDefault();

            if (this.mode !== Mode.DRAW) {
                // const { x, y, z } = this.action.frameCoordinates;
                // this.positionAllViews(x, y, z, true);
                return;
            }

            if (this.cube) {
                this.cancelDraw();

                const { x, y, z } = (this.cube as Object3DBase).perspective.position;

                const { x: width, y: height, z: depth } = (this.cube as Object3DBase).perspective.scale;
                const { x: rotationX, y: rotationY, z: rotationZ } = (this.cube as Object3DBase).perspective.rotation;
                const points = [
                    x,
                    y,
                    z - depth / 2,
                    rotationX,
                    rotationY,
                    rotationZ,
                    width,
                    height,
                    depth,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                ];

                const initState = this.controller.data.drawData.initialState?.[0];
                const { label } = initState || {};

                if (typeof this.controller.data.drawData.redraw === 'number') {
                    const state = this.controller.data.objects.get(
                        Number(this.controller.data.selected.perspective.name),
                    );
                    this.dispatchEvent(
                        new CustomEvent('canvas.edited', {
                            bubbles: false,
                            cancelable: true,
                            detail: {
                                state,
                                points,
                            },
                        }),
                    );
                } else {
                    const drawnType =
                        this.controller.data.drawData.type === 'object' ? 'canvas.drawn' : 'canvas.drawnissue';
                    this.dispatchEvent(
                        new CustomEvent(drawnType, {
                            bubbles: false,
                            cancelable: true,
                            detail: {
                                state: {
                                    ...initState,
                                    shapeType: 'cuboid',
                                    frame: this.controller.data.imageID,
                                    points,
                                    label,
                                    type: this.controller.data.drawData.type,
                                },
                                continue: this.controller.data.drawData.redraw,
                                duration: 0,
                            },
                        }),
                    );
                }
            }

            this.dispatchEvent(new CustomEvent('canvas.canceled'));
        });

        canvasPerspectiveView.addEventListener('click', (e: MouseEvent): void => {
            e.preventDefault();
            if (e.ctrlKey || this.action.cameraMove || this.action.drawObject.active) {
                // 正在移动相机视角中，或绘图中,无视点击事件
                this.action.cameraMove = false;
                return;
            }

            if (this.mode !== Mode.DRAW) {
                return;
            }

            const { shapeType } = this.controller.data.drawData;

            if (shapeType === ShapeType.bezier2) {
                const { points, rayCaster, camera, updateMousePosition } = this.views.perspective;
                updateMousePosition(e);
                const { mouseVector, renderer } = rayCaster;
                renderer.setFromCamera(mouseVector, camera);

                if (this.action.helper.isCreating) {
                    let pos = new Vector3().copy(renderer.ray.origin);
                    const { length } = this.action.helper.object as Bezier;
                    if (length === 1) {
                        const intersects = renderer.intersectObjects(points, false);
                        pos = intersects[0].point;
                    }
                    (this.action.helper as BezierHelper).addPoint(pos);
                }
                return;
            } else if (shapeType === ShapeType.laneline) {
                const { points, rayCaster, camera, updateMousePosition } = this.views.perspective;
                updateMousePosition(e);
                const { mouseVector, renderer } = rayCaster;
                renderer.setFromCamera(mouseVector, camera);

                if (this.action.helper?.isCreating) {
                    let pos = new Vector3().copy(renderer.ray.origin);
                    const { length } = this.action.helper.object as Bezier;
                    if (length === 0) {
                        // 和贝塞尔曲线不同，最后鼠标点不再计算入内。所以0的时候使用碰撞的坐标
                        const intersects = renderer.intersectObjects(points, false);
                        pos = intersects[0].point;
                    }
                    (this.action.helper as LaneHelper).addPoint(pos);
                } else if (this.action.helper?.isEditing) {
                    let pos = new Vector3().copy(renderer.ray.origin);
                    (this.action.helper as LaneHelper).addDashedDot(pos);
                }
                return;
            }
        });

        canvasPerspectiveView.addEventListener('contextmenu', (e: MouseEvent): void => {
            e.preventDefault();
            if (e.ctrlKey || this.action.cameraMove || this.action.drawObject.active) {
                // 正在移动相机视角中，或绘图中,无视点击事件
                this.action.cameraMove = false;
                return;
            }

            if (this.mode !== Mode.DRAW) {
                return;
            }

            const { shapeType } = this.controller.data.drawData;

            if (this.action.helper && (shapeType === ShapeType.bezier2 || shapeType === ShapeType.laneline)) {
                if (this.action.helper.isCreating || this.action.helper.isEditing) {
                    (this.action.helper as BezierHelper).deletePosition();
                }
            }
        });

        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view: ViewType): void => {
            this.views[view].renderer.domElement.addEventListener(
                'wheel',
                (event: WheelEvent): void => {
                    event.preventDefault();
                    const { camera } = this.views[view];
                    if (event.deltaY < CONST.FOV_MIN && camera.zoom < CONST.FOV_MAX) {
                        camera.zoom += CONST.FOV_INC;
                    } else if (event.deltaY > CONST.FOV_MIN && camera.zoom > CONST.FOV_MIN + 0.1) {
                        camera.zoom -= CONST.FOV_INC;
                    }

                    camera.updateProjectionMatrix();
                    this.setHelperSize(view);
                },
                { passive: false },
            );
        });

        controller.subscribe(this);
    }

    private get hasAnimation() {
        return this.controller.data.configuration.hasAnimation;
    }

    // 是否可编辑
    private isCanEdit(): boolean {
        return true;
    }

    public dispatchEvent(event: CustomEvent): void {
        this.views.perspective.renderer.domElement.dispatchEvent(event);
    }

    private lookAt(x: number, y: number, z: number, animation: boolean = true): void {
        if (this.views.perspective.controls) {
            const { cameraType, control } = this.views.perspective.controls;
            let { x: camX, y: camY, z: camZ } = control.camera.position;
            const { isForce, isFollow } = this.controller.data.configuration;
            // console.log('当前设置：', isForce);
            if (isFollow) {
                const mesh = this.views.perspective.getActiveObject();
                const object = this.controller.data.objects.get(this.controller.data.activeElement.clientID);
                if (mesh && object.shapeType === ShapeType.CUBOID) {
                    const { x: width, y: height, z: depth } = mesh.scale;
                    camX = x + width;
                    camY = y + height;
                    camZ = z + depth;
                }
            }
            if (isForce) {
                if (cameraType === 'perspective') {
                    // control.setLookAt(x - 8, y - 8, z + 3, x, y, z, animation);
                    control.setLookAt(camX, camY, camZ, x, y, z, animation);
                    // control.setOrbitPoint(x, y, z);
                } else {
                    control.setLookAt(x, y, camZ, x, y, z, animation);
                }
            }
        }
    }

    public updateThreeViewsLookAt(look: Vector3, length?: number) {
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewType) => {
            (this.views[viewType] as ThreeBaseView).lookAtBezier(look, length);
        });
    }

    private setDefaultZoom(): void {
        Object.entries(this.views).forEach(([key]: [ViewType, any]) => {
            if (key !== ViewType.PERSPECTIVE) {
                if (!this.activeID) {
                    this.views[key].camera.zoom = CONST.FOV_DEFAULT;
                    this.views[key].camera.updateProjectionMatrix();
                } else {
                    this.views[key].camera.zoom = 1;
                    this.views[key].camera.updateProjectionMatrix();
                    this.views[key].camera.updateMatrix();
                    this.setHelperSize(key);
                }
            }
        });
    }

    private addSceneChildren(shapeObject: CuboidModel): void {
        this.views.perspective.add(shapeObject.perspective);
    }

    private addThreeViewChildren(shapeObject: CuboidModel | SphereModel | Bezier | Lane): void {
        [ViewType.TOP, ViewType.FRONT, ViewType.SIDE].forEach((view) => {
            this.views[view].add(shapeObject[view]);
            this.views[view].object = shapeObject[view];
        });
    }

    public createObject(
        data: { points: number[]; shapeType: ShapeType; controlPoints?: number[][]; elements?: SubElement[] },
        isContinue: boolean = true,
    ) {
        const { shapeType = ShapeType.CUBOID } = data;
        this.dispatchEvent(
            new CustomEvent('canvas.drawn', {
                bubbles: false,
                cancelable: true,
                detail: {
                    state: {
                        // ...initState,
                        ...data,
                        frame: this.controller.data.imageID,
                        type: 'object',
                        shapeType,
                    },
                    continue: isContinue,
                    duration: 0,
                },
            }),
        );
        // }
    }

    // 创建点云对象
    private createPointCloud(
        position: number[],
        color: number[],
        itemSize: number,
    ): THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial> {
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.Float32BufferAttribute(position, itemSize));
        // 自定义颜色
        geometry.setAttribute('color', new THREE.Float32BufferAttribute(color, itemSize));

        geometry.computeBoundingSphere();
        const material = new THREE.PointsMaterial();
        material.size = 0.05;
        material.vertexColors = true;
        material.color.set(new THREE.Color(0xffffff));

        const points = new THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>(geometry, material);
        points.frustumCulled = false;
        points.name = 'points';
        return points;
    }

    // private resetThreeCamera() {
    //     // if (!(this.controller.data.selected instanceof LanelineMesh)) {
    //     //     return;
    //     // }

    //     [ViewType.TOP, ViewType.FRONT, ViewType.SIDE].forEach((viewType: ViewType) => {
    //         const view = this.views[viewType] as ThreeBaseView;
    //         view.resetCamera();
    //     });
    // }

    public onUpdatePosition(
        clientID: number,
        points: number[],
        controlPoints?: number[][],
        elements?: SubElement[],
    ): void {
        if (typeof clientID === 'number' && !Number.isNaN(clientID)) {
            const state = this.controller.data.objects.get(clientID);
            if (state) {
                this.dispatchEvent(
                    new CustomEvent('canvas.edited', {
                        bubbles: false,
                        cancelable: true,
                        detail: {
                            state,
                            points,
                            controlPoints,
                            elements,
                        },
                    }),
                );
            }
        }
    }

    private clearThreeViews(): void {
        // 未渲染时已删除，不需要再渲染
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewName) => {
            const view = this.views[viewName] as ThreeBaseView;
            view.clearObjects();
        });
    }

    private updateThreeViews(): Promise<void> {
        [ViewType.TOP, ViewType.FRONT, ViewType.SIDE].forEach((view) => {
            (this.views[view] as ThreeBaseView).update();
        });

        return;
    }

    private clearScene() {
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewType: ViewType) => {
            (this.views[viewType] as ThreeBaseView).init();
        });
    }

    public onReSelectObject(activeClientID?: number) {
        // 选中已选中的对象的事件
        const clientID = activeClientID || this.controller.data.activeElement.clientID;
        const object = this.controller.data.objects.get(clientID);
        if (clientID && object) {
            if ([ShapeType.bezier2, ShapeType.laneline].includes(object.shapeType)) {
                const bezier = this.views.perspective.getObjectByName(`${clientID}`) as Bezier;

                bezier.active();

                if (bezier.activeMesh) {
                    this.views.perspective.active(bezier.activeMesh, object);
                }

                setTimeout(() => {
                    this.lookAt(bezier.lookPos.x, bezier.lookPos.y, bezier.lookPos.z, this.hasAnimation);
                    [ViewType.TOP, ViewType.FRONT, ViewType.SIDE].forEach((viewType) => {
                        const view = this.views[viewType];
                        // view.add(bezier[viewType as ViewType.TOP | ViewType.FRONT | ViewType.SIDE]);
                        (view as ThreeBaseView).lookAtBezier(bezier.lookPos, 1);
                    });
                });
            } else if (object.shapeType === ShapeType.CUBOID) {
                const position = new Vector3(object.points[0], object.points[1], object.points[2]);
                this.lookAt(position.x, position.y, position.z, this.hasAnimation);
            }
        }
    }

    private setupObject(object: ObjectState, addToScene: boolean) {
        let color = object.color;
        // 对比
        const { clientID } = object;
        const { opacity, outlined, outlineColor, selectedOpacity, colorBy } = this.controller.data.shapeProperties;

        if (colorBy === 'Label') {
            color = object.label.color;
        }

        if (object.shapeType === ShapeType.CUBOID) {
            const cuboid = new CuboidModel(clientID, color);
            const [x = 0, y = 0, z = 0, rotationX = 0, rotationY = 0, rotationZ = 0] = object.points;
            let [, , , , , , length = 0, width = 0, height = 0] = object.points;

            if (
                [null, undefined, 0].includes(length) ||
                [null, undefined, 0].includes(width) ||
                [null, undefined, 0].includes(height)
            ) {
                // 尺寸异常的暴露出来
                length = 50;
                width = 50;
                height = 50;
            }

            const position = new Vector3(x, y, z);
            const rotation = new Quaternion().setFromEuler(new Euler(rotationX, rotationY, rotationZ));
            const scale = new Vector3(length, width, height);

            // 标注对象的位姿信息加载
            cuboid.applyMatrix4(new Matrix4().compose(position, rotation, scale));
            cuboid.setOpacity(opacity);
            cuboid.userData = object;

            if (object.hidden) {
                cuboid.perspective.visible = false;
            }

            if (addToScene) {
                this.addSceneChildren(cuboid);
            }
            this.controller.data.objects3D.set(clientID, cuboid);
            if (clientID === this.controller.data.activeElement.clientID) {
                this.controller.data.selected = cuboid;
                selectMesh(cuboid.perspective, selectedOpacity);

                if (addToScene) {
                    this.addThreeViewChildren(cuboid);
                    this.updateThreeViews();
                    this.views.perspective.active(cuboid.perspective, object);
                }

                this.rotatePlane(null, null);

                this.updateRotationHelperPos();
                this.updateResizeHelperPos();
            }

            return cuboid;
        } else if (object.shapeType === ShapeType.laneline) {
            // const { points, controls } = object;
            const lane = (this.meshCollection.get(clientID) as Lane) || new Lane(clientID, color);

            lane.import(object, {
                color,
                radius: 0.2,
                opacity,
                controlPointColor: 'red',
            });

            if (object.hidden) {
                lane.visible = false;
            }

            if (clientID === this.controller.data.activeElement.clientID) {
                // this.controller.data.selected = bezier;
                lane.active();
                this.addThreeViewChildren(lane);
            } else {
                lane.unActive();
            }

            lane.userData = object;

            if (addToScene) {
                this.views.perspective.add(lane);
            }

            this.meshCollection.set(clientID, lane);
            return lane;
        } else if (object.shapeType === ShapeType.bezier2) {
            const { points, controls } = object;
            const bezier =
                (this.meshCollection.get(clientID) as Bezier) || new Bezier(clientID, points, color, controls);

            bezier.import(object, {
                color,
                radius: 0.2,
                opacity,
                controlPointColor: 'red',
            });

            if (object.hidden) {
                bezier.visible = false;
            }

            if (clientID === this.controller.data.activeElement.clientID) {
                // this.controller.data.selected = bezier;
                bezier.active();
                this.addThreeViewChildren(bezier);
            } else {
                bezier.unActive();
            }

            bezier.userData = object;

            if (addToScene) {
                this.views.perspective.add(bezier);
            }

            this.meshCollection.set(clientID, bezier);
            return bezier;
        }
    }

    public deleteObject(object: ObjectState) {
        const { clientID } = object;
        // console.log('删除前：', this.views.perspective.points.children);

        const deleteEvent = (point: THREE.Object3D): boolean => {
            const box = point?.getObjectByName(`${clientID}`);
            if (box) {
                box?.removeFromParent();
                return true;
            }
            return false;
        };

        Object.entries(this.views).forEach(([viewType, view]: [ViewType, BaseView]) => {
            let isHasObject = true;
            while (isHasObject) {
                isHasObject = deleteEvent(view.scene);
            }
        });

        // console.log('删除后：', this.views.perspective.points.children);

        this.controller.data.objects3D.delete(clientID);
    }

    public updateObject(object: ObjectState, addToScene: boolean) {
        if (object) {
            this.deleteObject(object);
            this.setupObject(object, addToScene);
        }
    }

    public updateObjects(force?: boolean) {
        // if (
        //     this.controller.data.activeElement.clientID !== this.controller.data.activeElement.oldClientID ||
        //     this.controller.data.activeElement.issueID !== this.controller.data.activeElement.oldIssueID
        // ) {
        //     this.clearThreeViews();
        // }
        this.controller.data.selected = undefined;
        this.clearThreeViews();

        // this.views.perspective.deactive();
        this.views.perspective.scene.clearObjects();
        this.updateIusses();

        const objects = [...this.controller.data.objects.values()];
        const { add, update, delete: deletes } = this.controller.data.updateObjects;
        for (let i = 0; i < objects.length; i++) {
            const object = objects[i];
            this.setupObject(object, true);
        }
        add.splice(0);
        update.splice(0);
        deletes.splice(0);

        // this.clearThreeViews();
        // if (this.views.perspective.points) {
        //     if (force) {
        //         this.views.perspective.scene.clearObjects();
        //         this.updateIusses();
        //         const objects = [...this.controller.data.objects.values()];
        //         const { add, update, delete: deletes } = this.controller.data.updateObjects;
        //         for (let i = 0; i < objects.length; i++) {
        //             const object = objects[i];
        //             this.setupObject(object, true);
        //         }
        //         add.splice(0);
        //         update.splice(0);
        //         deletes.splice(0);
        //     } else {
        //         const { add, update, delete: deletes } = this.controller.data.updateObjects;
        //         for (let i = 0; i < deletes.length; i++) {
        //             const object = deletes[i];
        //             this.deleteObject(object);
        //         }
        //         for (let i = 0; i < add.length; i++) {
        //             const object = add[i];
        //             this.setupObject(object, true);
        //         }
        //         for (let i = 0; i < update.length; i++) {
        //             const object = update[i];
        //             this.updateObject(object, true);
        //         }
        //         add.splice(0);
        //         update.splice(0);
        //         deletes.splice(0);
        //     }
        // }
    }

    private deletePoint() {
        const { objectControl } = this.views.perspective;
        if (objectControl?.object && this.controller.data.object.deletePointClientID) {
            const { deletePointClientID } = this.controller.data.object;
            const object = this.controller.data.objects.get(this.controller.data.object.deletePointClientID);
            if (
                object.shapeType === ShapeType.bezier2 &&
                (objectControl?.object as BezierPoint).isPoint &&
                (objectControl?.object as BezierPoint).type === PointType.bezierPoint &&
                (objectControl?.object as BezierPoint).clientID === deletePointClientID
            ) {
                if (((objectControl?.object as BezierPoint).parent as Bezier).length <= 2) {
                    throw new Error('贝塞尔曲线最少有两个点，无法删除！');
                }
                const index = (objectControl?.object as BezierPoint).pointIndex;
                (objectControl?.object.parent as Bezier).deletePoint(index);
                const { points, controlPoints } = (objectControl?.object.parent as Bezier).toJsonData();
                console.log('将要删除的点：', objectControl?.object);
                // const { points, controls } = object;
                // const newPoints = points.splice(index * 3 ,3);
                this.onUpdatePosition(deletePointClientID, points, controlPoints);
                this.views.perspective.deactive();
            }
        }
    }

    // 删除目标物
    private deleteObjects() {}

    private setupIusse(object: Issue, addToScene: boolean) {
        // 对比
        const { id, resolve } = object;
        const { opacity, outlined, outlineColor, selectedOpacity, colorBy } = this.controller.data.shapeProperties;

        const model = new SphereModel('issue' + id, resolve ? 'green' : '#ffe001');

        const position = new Vector3(object.points[0], object.points[1], object.points[2]);

        // 标注对象的位姿信息加载
        model.setPosition(position.x, position.y, position.z);
        // model.setOpacity(1);
        model.userData = object;

        if (object.hidden) {
            model.perspective.visible = false;
        }

        if (this.controller.data.activeElement.issueID === id) {
            SphereSetEdges(model.top);
            SphereSetEdges(model.side);
            SphereSetEdges(model.front);

            this.controller.data.selected = model;

            this.addThreeViewChildren(model);
            this.updateThreeViews();
            // this.updateThreeViewsAsync();

            // this.detachCamera(null);
        }

        if (addToScene) {
            this.addSceneChildren(model);
        }
        // this.controller.data.objects3D.set(clientID, cuboid);
        return model;
    }

    public updateIusses() {
        const deletesMeshs: THREE.Mesh[] = [];
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT, ViewType.PERSPECTIVE].forEach((viewName) => {
            const view = this.views[viewName] as ThreeBaseView;
            view.objects.forEach((item) => {
                if ((item as THREE.Mesh).isMesh && (item as THREE.Mesh).geometry instanceof THREE.SphereGeometry) {
                    // (item as THREE.Mesh).removeFromParent();
                    deletesMeshs.push(item as THREE.Mesh);
                }
            });
        });

        deletesMeshs.forEach((mesh: THREE.Mesh) => {
            mesh.removeFromParent();
        });

        if (this.views.perspective.points) {
            const issues = this.controller.data.issueRegions;
            // const deletes = this.views.perspective.points.children.reduce(
            //     (prevous: THREE.Mesh[], current: THREE.Mesh) => {
            //         if (current.geometry instanceof THREE.SphereGeometry) {
            //             prevous.push(current);
            //         }
            //         return prevous;
            //     },
            //     [],
            // );
            // deletes.forEach((mesh: THREE.Mesh) => {
            //     this.views.perspective.points.remove(mesh);
            // });

            for (let i = 0; i < issues.length; i++) {
                const issue = issues[i];
                this.setupIusse(issue, true);
            }
        }
    }

    public cancelDraw() {
        if (this.mode === Mode.DRAW) {
            this.controller.data.drawData.enabled = false;
            Object.keys(this.views).forEach((view: string): void => {
                this.views[view as keyof Views].scene?.remove((this.cube as Object3DBase)[view as keyof Views]);
            });

            this.mode = Mode.IDLE;
        }
    }

    public resetCameras(): void {
        // const coordinatesTop = this.model.data.selected.getReferenceCoordinates(ViewType.TOP);
        // const sphericalTop = new THREE.Spherical();
        // sphericalTop.setFromVector3(coordinatesTop);
        // this.views.top.camera.position.setFromSpherical(sphericalTop);
        // this.views.top.camera.updateProjectionMatrix();

        // const coordinatesSide = this.model.data.selected.getReferenceCoordinates(ViewType.SIDE);
        // const sphericalSide = new THREE.Spherical();
        // sphericalSide.setFromVector3(coordinatesSide);
        // this.views.side.camera.position.setFromSpherical(sphericalSide);
        // this.views.side.camera.updateProjectionMatrix();

        // const coordinatesFront = this.model.data.selected.getReferenceCoordinates(ViewType.FRONT);
        // const sphericalFront = new THREE.Spherical();
        // sphericalFront.setFromVector3(coordinatesFront);
        // this.views.front.camera.position.setFromSpherical(sphericalFront);
        // this.views.front.camera.updateProjectionMatrix();
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewType: ViewType) => {
            // const object = this.controller.data.selected[viewType];

            // if (object) {
            //     if (viewType === ViewType.TOP) {
            //         this.views[viewType].camera.position.set(0, 0, 2);
            //     }
            //     if (viewType === ViewType.SIDE) {
            //         this.views[viewType].camera.position.set(0, 2, 0);
            //     }
            //     if (viewType === ViewType.FRONT) {
            //         this.views[viewType].camera.position.set(2, 0, 0);
            //     }

            //     this.views[viewType].camera.lookAt(0, 0, 0);

            //     this.views[viewType].camera.applyQuaternion(object.quaternion);
            //     this.views[viewType].camera.position.applyMatrix4(object.matrixWorld);

            //     this.views[viewType].camera.updateProjectionMatrix();
            // }
            (this.views[viewType] as ThreeBaseView).resetCamera();
        });
        // console.log('重置所有相机视角');
    }

    private setSelectedChildScale(x: number, y: number, z: number): void {
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view: ViewType): void => {
            this.controller.data.selected[view].children.forEach((element: any): void => {
                if (element.name !== CONST.CUBOID_EDGE_NAME) {
                    element.scale.set(
                        x == null ? element.scale.x : x,
                        y == null ? element.scale.y : y,
                        z == null ? element.scale.z : z,
                    );
                }
            });
        });
    }

    private moveObject(coordinates: THREE.Vector3): void {
        const { perspective, top, side, front } = this.controller.data.selected;
        let localCoordinates = coordinates;
        if (this.action.translation.status) {
            localCoordinates = coordinates
                .clone()
                .sub(this.action.translation.offset)
                .applyMatrix4(this.action.translation.inverseMatrix);
        }
        perspective.position.copy(localCoordinates.clone());
        top.position.copy(localCoordinates.clone());
        side.position.copy(localCoordinates.clone());
        front.position.copy(localCoordinates.clone());
    }

    private renderResizeAction(view: ViewType, viewType: any): void {
        // if (this.controller.data.selected instanceof LanelineMesh) return;

        const intersects = viewType.rayCaster.renderer.intersectObjects(
            [viewType.scene.getObjectByName(`${view}Plane`)],
            true,
        );
        // Return if no intersection with the reference plane
        if (intersects.length === 0) return;
        const { x: scaleInitX, y: scaleInitY, z: scaleInitZ } = this.action.resize.initScales;
        const { x: scaleMemX, y: scaleMemY, z: scaleMemZ } = this.action.resize.memScales;
        const { x: initPosX, y: initPosY } = this.action.resize.helper;
        const { x: currentPosX, y: currentPosY } = viewType.rayCaster.mouseVector;
        const { resizeVector } = this.action.resize;

        if (this.action.resize.helper.x === currentPosX && this.action.resize.helper.y === currentPosY) {
            return;
        }

        if (
            this.action.resize.recentMouseVector.x === currentPosX &&
            this.action.resize.recentMouseVector.y === currentPosY
        ) {
            return;
        }
        this.action.resize.recentMouseVector = viewType.rayCaster.mouseVector.clone();
        switch (view) {
            case ViewType.TOP: {
                let y = scaleInitX * (currentPosX / initPosX);
                let x = scaleInitY * (currentPosY / initPosY);
                if (x < 0) x = 0.2;
                if (y < 0) y = 0.2;
                this.controller.data.selected.setScale(y, x, this.controller.data.selected.top.scale.z);
                this.setSelectedChildScale(1 / y, 1 / x, null);
                const differenceX = y / 2 - scaleMemX / 2;
                const differenceY = x / 2 - scaleMemY / 2;

                if (currentPosX > 0 && currentPosY < 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX > 0 && currentPosY > 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y += differenceY;
                } else if (currentPosX < 0 && currentPosY < 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX < 0 && currentPosY > 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y += differenceY;
                }

                this.action.resize.memScales.x = y;
                this.action.resize.memScales.y = x;
                break;
            }
            case ViewType.SIDE: {
                let x = scaleInitX * (currentPosX / initPosX);
                let z = scaleInitZ * (currentPosY / initPosY);
                if (x < 0) x = 0.2;
                if (z < 0) z = 0.2;
                this.controller.data.selected.setScale(x, this.controller.data.selected.top.scale.y, z);
                this.setSelectedChildScale(1 / x, null, 1 / z);
                const differenceX = x / 2 - scaleMemX / 2;
                const differenceY = z / 2 - scaleMemZ / 2;

                if (currentPosX > 0 && currentPosY < 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX > 0 && currentPosY > 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y += differenceY;
                } else if (currentPosX < 0 && currentPosY < 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX < 0 && currentPosY > 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y += differenceY;
                }

                this.action.resize.memScales = { ...this.action.resize.memScales, x, z };
                break;
            }
            case ViewType.FRONT: {
                let y = scaleInitY * (currentPosX / initPosX);
                let z = scaleInitZ * (currentPosY / initPosY);
                if (y < 0) y = 0.2;
                if (z < 0) z = 0.2;
                this.controller.data.selected.setScale(this.controller.data.selected.top.scale.x, y, z);
                this.setSelectedChildScale(null, 1 / y, 1 / z);
                let differenceX;
                let differenceY;

                if (!this.action.resize.frontBool) {
                    differenceX = z / 2 - scaleMemZ / 2;
                    differenceY = y / 2 - scaleMemY / 2;
                    this.action.resize.frontBool = true;
                } else {
                    differenceX = z / 2 - scaleMemY / 2;
                    differenceY = y / 2 - scaleMemZ / 2;
                }
                if (currentPosX > 0 && currentPosY < 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y += differenceY;
                } else if (currentPosX > 0 && currentPosY > 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y += differenceY;
                } else if (currentPosX < 0 && currentPosY < 0) {
                    resizeVector.x += differenceX;
                    resizeVector.y -= differenceY;
                } else if (currentPosX < 0 && currentPosY > 0) {
                    resizeVector.x -= differenceX;
                    resizeVector.y -= differenceY;
                }

                this.action.resize.memScales.y = z;
                this.action.resize.memScales.z = y;
                break;
            }
            default:
        }
        const coordinates = resizeVector.clone();
        intersects[0].object.localToWorld(coordinates);
        this.moveObject(coordinates);
        this.resetCameras();

        // console.log('移动：', this.controller.data.selected.perspective.scale);
        this.dispatchEvent(
            new CustomEvent('canvas.position.update', {
                bubbles: false,
                cancelable: true,
                detail: {
                    scale: {
                        x: this.controller.data.selected.perspective.scale.x,
                        y: this.controller.data.selected.perspective.scale.y,
                        z: this.controller.data.selected.perspective.scale.z,
                    },
                },
            }),
        );
    }

    private renderResizeFaceAction(view: ViewType, viewType: any): void {
        // if (this.controller.data.selected instanceof LanelineMesh) return;

        const intersects = viewType.rayCaster.renderer.intersectObjects(
            [viewType.scene.getObjectByName(`${view}Plane`)],
            true,
        );

        // Return if no intersection with the reference plane
        if (intersects.length === 0) return;

        const { x: scaleInitX, y: scaleInitY, z: scaleInitZ } = this.action.resizeFace.initScales;
        const { x: scaleMemX, y: scaleMemY, z: scaleMemZ } = this.action.resizeFace.memScales;
        const { x: initPosX, y: initPosY } = this.action.resizeFace.helper;
        const { x: currentPosX, y: currentPosY } = viewType.rayCaster.mouseVector;
        const { resizeVector, posName } = this.action.resizeFace;

        if (this.action.resizeFace.helper.x === currentPosX && this.action.resizeFace.helper.y === currentPosY) {
            return;
        }

        if (
            this.action.resizeFace.recentMouseVector.x === currentPosX &&
            this.action.resizeFace.recentMouseVector.y === currentPosY
        ) {
            return;
        }
        this.action.resizeFace.recentMouseVector = viewType.rayCaster.mouseVector.clone();
        switch (view) {
            case ViewType.TOP: {
                // 控制缩放
                if (
                    [
                        `globalResizeFaceHelper_${PositionDirection.top}`,
                        `globalResizeFaceHelper_${PositionDirection.bottom}`,
                    ].includes(posName)
                ) {
                    let y = scaleInitY * (currentPosY / initPosY);
                    if (y < 0) y = 0.2;
                    this.controller.data.selected.setScale(
                        this.controller.data.selected.top.scale.x,
                        y,
                        this.controller.data.selected.top.scale.z,
                    );
                    this.setSelectedChildScale(null, 1 / y, null);

                    const differenceY = y / 2 - scaleMemY / 2;
                    if (posName === `globalResizeFaceHelper_${PositionDirection.top}`) {
                        resizeVector.y += differenceY;
                    } else {
                        resizeVector.y -= differenceY;
                    }
                    this.action.resizeFace.memScales.y = y;
                } else if (
                    [
                        `globalResizeFaceHelper_${PositionDirection.left}`,
                        `globalResizeFaceHelper_${PositionDirection.right}`,
                    ].includes(posName)
                ) {
                    let x = scaleInitX * (currentPosX / initPosX);
                    if (x < 0) x = 0.2;
                    this.controller.data.selected.setScale(
                        x,
                        this.controller.data.selected.top.scale.y,
                        this.controller.data.selected.top.scale.z,
                    );
                    this.setSelectedChildScale(1 / x, null, null);
                    const differenceX = x / 2 - scaleMemX / 2;
                    if (posName === `globalResizeFaceHelper_${PositionDirection.left}`) {
                        resizeVector.x -= differenceX;
                    } else {
                        resizeVector.x += differenceX;
                    }
                    this.action.resizeFace.memScales.x = x;
                }

                break;
            }
            case ViewType.SIDE: {
                // 控制缩放
                if (
                    [
                        `globalResizeFaceHelper_${PositionDirection.top}`,
                        `globalResizeFaceHelper_${PositionDirection.bottom}`,
                    ].includes(posName)
                ) {
                    let z = scaleInitZ * (currentPosY / initPosY);
                    if (z < 0) z = 0.2;
                    this.controller.data.selected.setScale(
                        this.controller.data.selected.top.scale.x,
                        this.controller.data.selected.top.scale.y,
                        z,
                    );
                    this.setSelectedChildScale(null, null, 1 / z);

                    const differenceY = z / 2 - scaleMemZ / 2;
                    if (posName === `globalResizeFaceHelper_${PositionDirection.top}`) {
                        resizeVector.y += differenceY;
                    } else {
                        resizeVector.y -= differenceY;
                    }
                    this.action.resizeFace.memScales.z = z;
                } else if (
                    [
                        `globalResizeFaceHelper_${PositionDirection.left}`,
                        `globalResizeFaceHelper_${PositionDirection.right}`,
                    ].includes(posName)
                ) {
                    let x = scaleInitX * (currentPosX / initPosX);
                    if (x < 0) x = 0.2;
                    this.controller.data.selected.setScale(
                        x,
                        this.controller.data.selected.top.scale.y,
                        this.controller.data.selected.top.scale.z,
                    );
                    this.setSelectedChildScale(1 / x, null, null);
                    const differenceX = x / 2 - scaleMemX / 2;
                    if (posName === `globalResizeFaceHelper_${PositionDirection.left}`) {
                        resizeVector.x -= differenceX;
                    } else {
                        resizeVector.x += differenceX;
                    }
                    this.action.resizeFace.memScales.x = x;
                }

                break;
            }
            case ViewType.FRONT: {
                // 控制缩放
                if (
                    [
                        `globalResizeFaceHelper_${PositionDirection.top}`,
                        `globalResizeFaceHelper_${PositionDirection.bottom}`,
                    ].includes(posName)
                ) {
                    let z = scaleInitZ * (currentPosY / initPosY);
                    if (z < 0) z = 0.2;
                    this.controller.data.selected.setScale(
                        this.controller.data.selected.top.scale.x,
                        this.controller.data.selected.top.scale.y,
                        z,
                    );
                    this.setSelectedChildScale(null, null, 1 / z);

                    const differenceX = scaleMemZ / 2 - z / 2;
                    if (posName === `globalResizeFaceHelper_${PositionDirection.top}`) {
                        resizeVector.x += differenceX;
                    } else {
                        resizeVector.x -= differenceX;
                    }
                    this.action.resizeFace.memScales.z = z;
                } else if (
                    [
                        `globalResizeFaceHelper_${PositionDirection.left}`,
                        `globalResizeFaceHelper_${PositionDirection.right}`,
                    ].includes(posName)
                ) {
                    let y = scaleInitY * (currentPosX / initPosX);
                    if (y < 0) y = 0.2;
                    this.controller.data.selected.setScale(
                        this.controller.data.selected.top.scale.x,
                        y,
                        this.controller.data.selected.top.scale.z,
                    );
                    this.setSelectedChildScale(null, 1 / y, null);

                    const differenceY = y / 2 - scaleMemY / 2;
                    if (posName === `globalResizeFaceHelper_${PositionDirection.left}`) {
                        resizeVector.y -= differenceY;
                    } else {
                        resizeVector.y += differenceY;
                    }
                    this.action.resizeFace.memScales.y = y;
                }

                break;
            }
            default:
        }
        const coordinates = resizeVector.clone();
        intersects[0].object.localToWorld(coordinates);
        this.moveObject(coordinates);
        this.resetCameras();

        this.dispatchEvent(
            new CustomEvent('canvas.position.update', {
                bubbles: false,
                cancelable: true,
                detail: {
                    scale: {
                        x: this.controller.data.selected.perspective.scale.x,
                        y: this.controller.data.selected.perspective.scale.y,
                        z: this.controller.data.selected.perspective.scale.z,
                    },
                },
            }),
        );
    }

    private attachCamera(view: ViewType): void {
        switch (view) {
            case ViewType.TOP:
                this.controller.data.selected.side.attach(this.views.side.camera);
                this.controller.data.selected.front.attach(this.views.front.camera);
                break;
            case ViewType.SIDE:
                this.controller.data.selected.front.attach(this.views.front.camera);
                this.controller.data.selected.top.attach(this.views.top.camera);
                break;
            case ViewType.FRONT:
                this.controller.data.selected.side.attach(this.views.side.camera);
                this.controller.data.selected.top.attach(this.views.top.camera);
                break;
            default:
        }
    }

    // private setHelperVisibility(visibility: boolean): void {
    //     [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewType: ViewType): void => {
    //         const globalRotationObject = this.views[viewType].scene.getObjectByName('globalRotationHelper');
    //         if (globalRotationObject) {
    //             globalRotationObject.visible = visibility;
    //         }
    //         for (let i = 0; i < 8; i++) {
    //             const resizeObject = this.views[viewType].scene.getObjectByName(`globalResizeHelper${i}`);
    //             if (resizeObject) {
    //                 resizeObject.visible = visibility;
    //             }
    //         }
    //     });
    // }

    private setHelperSize(viewType: ViewType): void {
        // if (this.controller.data.selected instanceof LanelineMesh) {
        //     return;
        // }

        // if ([ViewType.TOP, ViewType.SIDE, ViewType.FRONT].includes(viewType)) {
        //     const { camera } = this.views[viewType];
        //     if (!camera || camera instanceof THREE.PerspectiveCamera) return;
        //     const factor = (camera.top - camera.bottom) / camera.zoom;
        //     const rotationObject = this.views[viewType].scene.getObjectByName('globalRotationHelper');
        //     if (rotationObject) {
        //         rotationObject.scale.set(1, 1, 1).multiplyScalar(factor / 10);
        //     }
        //     for (let i = 0; i < 8; i++) {
        //         const resizeObject = this.views[viewType].scene.getObjectByName(`globalResizeHelper${i}`);
        //         if (resizeObject) {
        //             resizeObject.scale.set(1, 1, 1).multiplyScalar(factor / 10);
        //         }
        //     }
        // }

        if ([ViewType.TOP, ViewType.SIDE, ViewType.FRONT].includes(viewType)) {
            // const { camera } = this.views[viewType];
            (this.views[viewType] as ThreeBaseView).setHelperSize();
        }
    }

    private updateRotationHelperPos(): void {
        // if (this.controller.data.selected instanceof LanelineMesh) {
        //     return;
        // }
        // [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view: ViewType): void => {
        //     const point = new THREE.Vector3(0, 0, 0);
        //     if (this.controller.data.selected[view].getObjectByName('rotationHelper')) {
        //         this.controller.data.selected[view].getObjectByName('rotationHelper').getWorldPosition(point);
        //         const globalRotationObject = this.views[view].scene.getObjectByName('globalRotationHelper');
        //         if (globalRotationObject) {
        //             globalRotationObject.position.set(point.x, point.y, point.z);
        //         }
        //     }
        // });

        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewName) => {
            const view = this.views[viewName] as ThreeBaseView;
            view.updateRotationHelperPos();
        });
    }

    private updateResizeHelperPos(): void {
        // if (this.controller.data.selected instanceof LanelineMesh) {
        //     return;
        // }
        // [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view: ViewType): void => {
        //     let i = 0;
        //     this.controller.data.selected[view].children.forEach((element: any): void => {
        //         if (element.name === 'resizeHelper') {
        //             const p = new THREE.Vector3(0, 0, 0);
        //             element.getWorldPosition(p);
        //             const name = `globalResizeHelper${i}`;
        //             const object = this.views[view].scene.getObjectByName(name);
        //             if (object) {
        //                 object.position.set(p.x, p.y, p.z);
        //             }
        //             i++;
        //         }
        //     });
        // });
        [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewName) => {
            const view = this.views[viewName] as ThreeBaseView;
            view.updateResizeHelperPos();
            view.updateResizeFaceHelperPos();
        });
    }

    /**
     * 使用控制器更改对象信息
     * @param object 当前的选中对象
     */
    public updateObjectEvent(object: THREE.Object3D) {
        if (![Mode.IDLE, Mode.birdEye].includes(this.mode)) {
            return;
        }
        // 只能修改当前活动的
        if (this.activeObjectType === TargetType.cuboid) {
            const { x, y, z } = object.position;
            const { x: width, y: height, z: depth } = object.scale;
            const { x: rotationX, y: rotationY, z: rotationZ } = object.rotation;
            const points = [x, y, z, rotationX, rotationY, rotationZ, width, height, depth, 0, 0, 0, 0, 0, 0, 0];
            const { clientID } = this.controller.data.activeElement;
            const state = this.controller.data.objects.get(clientID);
            if (state) {
                this.dispatchEvent(
                    new CustomEvent('canvas.edited', {
                        bubbles: false,
                        cancelable: true,
                        detail: {
                            state,
                            points,
                        },
                    }),
                );
            } else {
                console.error('旋转时，没有找到对象！');
            }
        } else if (this.activeObjectType === TargetType.issue) {
            const { issueID } = this.controller.data.activeElement;
            const [issue] = this.controller.data.issueRegions.filter((_issue: any): boolean => _issue.id === issueID);

            const { x, y, z } = object.position;
            const points = [x, y, z];

            this.dispatchEvent(
                new CustomEvent('canvas.issueedited', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        issue,
                        points,
                    },
                }),
            );
        } else if ([TargetType.bezier2, TargetType.laneline].includes(this.activeObjectType)) {
            const group = object.parent as Bezier;
            const data = group.toJsonData();

            const { points, clientID, controlPoints, elements } = data as any;
            const state = this.controller.data.objects.get(clientID);

            this.dispatchEvent(
                new CustomEvent('canvas.edited', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        state,
                        points,
                        controlPoints,
                        elements,
                    },
                }),
            );
        }
    }

    private drawDone(): void {
        if (this.mode !== Mode.DRAW) {
            return;
        }

        const { enabled, shapeType } = this.controller.data.drawData;
        if (enabled) {
            return;
        }

        if (shapeType === ShapeType.laneline) {
            const lane = this.action.helper.object as Lane;

            const saveData = lane.toJsonData();
            if (saveData.clientID) {
                this.onUpdatePosition(saveData.clientID, saveData.points, undefined, saveData.elements);
                return;
            }
            this.createObject({
                points: saveData.points,
                shapeType: saveData.shapeType,
                // controlPoints: saveData.controlPoints,
            });
        } else if (shapeType === ShapeType.bezier2) {
            const bezier = this.action.helper.object as Bezier;

            const saveData = bezier.toJsonData();
            this.createObject({
                points: saveData.points,
                shapeType: saveData.shapeType,
                controlPoints: saveData.controlPoints,
            });
            // this.action.helper.destroy();
            // this.action.helper = undefined;
        }
    }

    private updateLaneline(): void {
        console.log('更新点对象：', this.controller.data.updateLanelineData);
        // if (!(this.controller.data.selected instanceof LanelineMesh) || !this.action.movePointName) {
        //     return;
        // }

        // const lanelineMesh = this.controller.data.selected;
        // // lanelineMesh.perspective

        // // console.log('更新车道线：', this.controller.data.updateLanelineData)
        // // console.log('当前选中的点：', this.action.movePointName)

        // const point = lanelineMesh.get(this.action.movePointName);
        // const index = point.clientID;

        // if (this.controller.data.updateLanelineData.type === 'add') {
        //     if (index > 0 && index < lanelineMesh.children.size) {
        //         const beforePoint = lanelineMesh.get(`${pointName}${index - 1}`);
        //         const newPos = point.position.clone().add(beforePoint.position).divideScalar(2);
        //         lanelineMesh.updatePoints(newPos, index);
        //     } else {
        //         const newPos = point.position.clone().set(point.position.x + 1, point.position.y + 1, point.position.z);
        //         lanelineMesh.updatePoints(newPos, index);
        //     }
        // } else if (this.controller.data.updateLanelineData.type === 'delete') {
        //     if (lanelineMesh.children.size > 2) {
        //         lanelineMesh.updatePoints(undefined, index, 1);
        //     } else {
        //         throw new Error('无法删除单独的点：点数不足2个，请删除对象');
        //     }
        // }

        // const points = lanelineMesh.points;

        // const { clientID } = this.controller.data.activeElement;

        // const state = this.controller.data.objects.get(clientID);
        // if (state) {
        //     this.dispatchEvent(
        //         new CustomEvent('canvas.edited', {
        //             bubbles: false,
        //             cancelable: true,
        //             detail: {
        //                 state,
        //                 points,
        //             },
        //         }),
        //     );
        // } else {
        //     console.error('删除单独的点时，没有找到对象！');
        // }
    }

    public html() {
        return {
            perspective: this.views.perspective.renderer.domElement,
            top: this.views.top.renderer.domElement,
            side: this.views.side.renderer.domElement,
            front: this.views.front.renderer.domElement,
        };
    }

    private renderRayCaster = (viewType: BaseView): void => {
        viewType.rayCaster.renderer.setFromCamera(viewType.rayCaster.mouseVector, viewType.camera);
        if (this.mode === Mode.DRAW && this.cube) {
            const intersects = this.views.perspective.rayCaster.renderer.intersectObjects(
                this.views.perspective.points,
                false,
            );

            if (intersects.length > 0) {
                this.views.perspective.add((this.cube as Object3DBase).perspective);
                const newPoints = intersects[0].point;
                (this.cube as Object3DBase).perspective.position.copy(newPoints);
            }
        } else if (this.mode === Mode.DRAW && this.action.helper) {
            // console.log('测试：', viewType.rayCaster.renderer.ray.origin);
            // if (this.action.helper.object) {
            //     (this.action.helper.object as Bezier).updateLastPosition(viewType.rayCaster.renderer.ray.origin);
            // }
            if (this.action.helper) {
                this.action.helper.updateMousePosition(viewType.rayCaster.renderer.ray.origin);
            }
        }
    };

    public render() {
        Object.entries(this.views).forEach(([view, viewType]: [string, BaseView]): void => {
            // const viewType = this.views[view as keyof Views];
            if (!(viewType.controls && viewType.camera && viewType.rayCaster)) return;

            this.views[view as ViewType].render();

            if (
                view === ViewType.PERSPECTIVE &&
                viewType.points &&
                viewType.scene.children.length &&
                viewType.scene.context.children.length
                // viewType.scene.children[0] === viewType.points
            ) {
                // console.log('当前状态：', this.controller.mode);
                this.renderRayCaster(viewType);
            }

            if (this.action.actionView === view) {
                if (this.action.isAction) {
                    if (this.action.resize.status) {
                        this.renderResizeAction(view as ViewType, viewType);
                    }
                    if (this.action.resizeFace.status) {
                        this.renderResizeFaceAction(view as ViewType, viewType);
                    }
                    this.updateRotationHelperPos();
                    this.updateResizeHelperPos();
                }
            }
            if (this.action.resetCamera) {
                try {
                    // this.detachCamera(null);
                    // eslint-disable-next-line no-empty
                } catch (e) {
                } finally {
                    this.action.resetCamera = false;
                }
            }
        });
    }

    private translateReferencePlane(coordinates: any): void {
        const topPlane = this.views.top.scene.getObjectByName(Planes.TOP);
        if (topPlane) {
            topPlane.position.x = coordinates.x;
            topPlane.position.y = coordinates.y;
            topPlane.position.z = coordinates.z;
        }
        const sidePlane = this.views.side.scene.getObjectByName(Planes.SIDE);
        if (sidePlane) {
            sidePlane.position.x = coordinates.x;
            sidePlane.position.y = coordinates.y;
            sidePlane.position.z = coordinates.z;
        }
        const frontPlane = this.views.front.scene.getObjectByName(Planes.FRONT);
        if (frontPlane) {
            frontPlane.position.x = coordinates.x;
            frontPlane.position.y = coordinates.y;
            frontPlane.position.z = coordinates.z;
        }
    }

    private detachCamera(view: ViewType): void {
        const coordTop = new Vector3(0, 0, 2);
        coordTop.applyMatrix4(this.views.top.object.matrixWorld);
        const sphericaltop = new THREE.Spherical();
        sphericaltop.setFromVector3(coordTop);

        const coordSide = new Vector3(0, 2, 0);
        coordSide.applyMatrix4(this.views.side.object.matrixWorld);
        const sphericalside = new THREE.Spherical();
        sphericalside.setFromVector3(coordSide);

        const coordFront = new Vector3(2, 0, 0);
        coordFront.applyMatrix4(this.views.front.object.matrixWorld);
        const sphericalfront = new THREE.Spherical();
        sphericalfront.setFromVector3(coordFront);

        // const coordTop = this.controller.data.selected.getReferenceCoordinates(ViewType.TOP);
        // const sphericaltop = new THREE.Spherical();
        // sphericaltop.setFromVector3(coordTop);

        // const coordSide = this.controller.data.selected.getReferenceCoordinates(ViewType.SIDE);
        // const sphericalside = new THREE.Spherical();
        // sphericalside.setFromVector3(coordSide);

        // const coordFront = this.controller.data.selected.getReferenceCoordinates(ViewType.FRONT);
        // const sphericalfront = new THREE.Spherical();
        // sphericalfront.setFromVector3(coordFront);

        const { side: objectSideView, front: objectFrontView, top: objectTopView } = this.controller.data.selected;
        const { camera: sideCamera } = this.views.side;
        const { camera: frontCamera } = this.views.front;
        const { camera: topCamera } = this.views.top;

        switch (view) {
            case ViewType.TOP: {
                const camRotationSide = objectSideView
                    .getObjectByName('cameraSide')
                    .getWorldQuaternion(new THREE.Quaternion());
                objectSideView.remove(sideCamera);
                sideCamera.position.setFromSpherical(sphericalside);
                sideCamera.lookAt(objectSideView.position.x, objectSideView.position.y, objectSideView.position.z);
                sideCamera.setRotationFromQuaternion(camRotationSide);
                sideCamera.scale.set(1, 1, 1);

                const camRotationFront = objectFrontView
                    .getObjectByName('cameraFront')
                    .getWorldQuaternion(new THREE.Quaternion());
                objectFrontView.remove(frontCamera);
                frontCamera.position.setFromSpherical(sphericalfront);
                frontCamera.lookAt(objectFrontView.position.x, objectFrontView.position.y, objectFrontView.position.z);
                frontCamera.setRotationFromQuaternion(camRotationFront);
                frontCamera.scale.set(1, 1, 1);
                break;
            }
            case ViewType.SIDE: {
                const camRotationFront = objectFrontView
                    .getObjectByName('cameraFront')
                    .getWorldQuaternion(new THREE.Quaternion());
                objectFrontView.remove(frontCamera);
                frontCamera.position.setFromSpherical(sphericalfront);
                frontCamera.lookAt(objectFrontView.position.x, objectFrontView.position.y, objectFrontView.position.z);
                frontCamera.setRotationFromQuaternion(camRotationFront);
                frontCamera.scale.set(1, 1, 1);

                objectTopView.remove(topCamera);
                topCamera.position.setFromSpherical(sphericaltop);
                topCamera.lookAt(objectTopView.position.x, objectTopView.position.y, objectTopView.position.z);
                topCamera.setRotationFromEuler(objectTopView.rotation);
                topCamera.scale.set(1, 1, 1);
                break;
            }
            case ViewType.FRONT: {
                const camRotationSide = objectSideView
                    .getObjectByName('cameraSide')
                    .getWorldQuaternion(new THREE.Quaternion());
                objectSideView.remove(sideCamera);
                sideCamera.position.setFromSpherical(sphericalside);
                sideCamera.lookAt(objectSideView.position.x, objectSideView.position.y, objectSideView.position.z);
                sideCamera.setRotationFromQuaternion(camRotationSide);
                sideCamera.scale.set(1, 1, 1);

                objectTopView.remove(topCamera);
                topCamera.position.setFromSpherical(sphericaltop);
                topCamera.lookAt(objectTopView.position.x, objectTopView.position.y, objectTopView.position.z);
                topCamera.setRotationFromEuler(objectTopView.rotation);
                topCamera.scale.set(1, 1, 1);
                break;
            }
            default: {
                sideCamera.position.setFromSpherical(sphericalside);
                sideCamera.lookAt(objectSideView.position.x, objectSideView.position.y, objectSideView.position.z);
                sideCamera.rotation.z = this.views.side.scene.getObjectByName(Planes.SIDE).rotation.z;
                sideCamera.scale.set(1, 1, 1);

                topCamera.position.setFromSpherical(sphericaltop);
                topCamera.lookAt(objectTopView.position.x, objectTopView.position.y, objectTopView.position.z);
                topCamera.setRotationFromEuler(objectTopView.rotation);
                topCamera.scale.set(1, 1, 1);

                const camFrontRotate = objectFrontView
                    .getObjectByName('camRefRot')
                    .getWorldQuaternion(new THREE.Quaternion());
                frontCamera.position.setFromSpherical(sphericalfront);
                frontCamera.lookAt(objectFrontView.position.x, objectFrontView.position.y, objectFrontView.position.z);
                frontCamera.setRotationFromQuaternion(camFrontRotate);
                frontCamera.scale.set(1, 1, 1);
            }
        }
    }

    private rotatePlane(direction: number, view: ViewType): void {
        const sceneTopPlane = this.views.top.scene.getObjectByName(Planes.TOP);
        const sceneSidePlane = this.views.side.scene.getObjectByName(Planes.SIDE);
        const sceneFrontPlane = this.views.front.scene.getObjectByName(Planes.FRONT);
        switch (view) {
            case ViewType.TOP:
                sceneTopPlane.rotateZ(direction);
                sceneSidePlane.rotateY(direction);
                sceneFrontPlane.rotateX(-direction);
                break;
            case ViewType.SIDE:
                sceneTopPlane.rotateY(direction);
                sceneSidePlane.rotateZ(direction);
                sceneFrontPlane.rotateY(direction);
                break;
            case ViewType.FRONT:
                sceneTopPlane.rotateX(direction);
                sceneSidePlane.rotateX(-direction);
                sceneFrontPlane.rotateZ(direction);
                break;
            default: {
                const {
                    top: objectTopView,
                    side: objectSideView,
                    front: objectFrontView,
                } = this.controller.data.selected;

                sceneTopPlane?.position.set(0, 0, 0);
                sceneSidePlane?.position.set(0, 0, 0);
                sceneFrontPlane?.position.set(0, 0, 0);

                sceneTopPlane?.rotation.set(0, 0, 0);
                sceneSidePlane?.rotation.set(-Math.PI / 2, Math.PI / 2000, Math.PI);
                sceneFrontPlane?.rotation.set(0, Math.PI / 2, 0);

                sceneTopPlane?.applyQuaternion(objectTopView.quaternion);
                sceneSidePlane?.applyQuaternion(objectSideView.quaternion);
                sceneFrontPlane?.applyQuaternion(objectFrontView.quaternion);

                this.translateReferencePlane(objectTopView.position);
            }
        }
    }

    private rotateCamera(direction: any): void {
        // [ViewType.TOP, ViewType.FRONT, ViewType.SIDE].forEach((type: ViewType) => {
        //     this.views[type].camera.rotateZ(direction);
        // });
    }

    private rotateCube(instance: CuboidModel, direction: number, type: ViewType): void {
        // [ViewType.TOP, ViewType.FRONT, ViewType.SIDE].forEach((type: ViewType) => {
        switch (type) {
            case ViewType.TOP:
                instance.perspective.rotateZ(direction);
                instance.top.rotateZ(direction);
                instance.side.rotateZ(direction);
                instance.front.rotateZ(direction);
                this.rotateCamera(direction);
                break;
            case ViewType.FRONT:
                instance.perspective.rotateX(direction);
                instance.top.rotateX(direction);
                instance.side.rotateX(direction);
                instance.front.rotateX(direction);
                this.rotateCamera(direction);
                break;
            case ViewType.SIDE:
                instance.perspective.rotateY(direction);
                instance.top.rotateY(direction);
                instance.side.rotateY(direction);
                instance.front.rotateY(direction);
                this.rotateCamera(direction);
                break;
            default:
        }
        this.resetCameras();
        // });
    }

    public editable(object: THREE.Mesh): boolean {
        if (object.geometry instanceof THREE.BoxGeometry) {
            const obj = object?.userData as ObjectState;
            return obj && !obj.hidden && !obj.lock && !this.controller.configuration.forceDisableEditing;
        } else if (object.geometry instanceof THREE.SphereGeometry) {
            const obj = object?.userData as Issue;
            return obj && !obj.hidden && !obj.resolve && !obj.readOnly;
        }
        return false;
    }

    private objectUpdateRotate(inverse?: boolean): void {
        // 目标物旋转
        // const object = this;
        // const sceneTopPlane = this.views.top.scene.getObjectByName(Planes.TOP);
        // const sceneSidePlane = this.views.side.scene.getObjectByName(Planes.SIDE);
        // const sceneFrontPlane = this.views.front.scene.getObjectByName(Planes.FRONT);

        // sceneTopPlane.ro

        const { clientID } = this.controller.data.activeElement;
        if (
            clientID &&
            // !(this.controller.data.selected instanceof LanelineMesh) &&
            this.editable(this.controller.data.selected[ViewType.PERSPECTIVE])
        ) {
            // console.log('旋转：', this.model.data.rotate);
            this.rotateCube(this.controller.data.selected, inverse ? -Math.PI / 2 : Math.PI / 2, ViewType.TOP);
            this.rotatePlane(null, null);

            this.updateRotationHelperPos();
            this.updateResizeHelperPos();

            const { x, y, z } = this.controller.data.selected[ViewType.TOP].position;
            const { x: width, y: height, z: depth } = this.controller.data.selected[ViewType.TOP].scale;
            const { x: rotationX, y: rotationY, z: rotationZ } = this.controller.data.selected[ViewType.TOP].rotation;
            const points = [x, y, z, rotationX, rotationY, rotationZ, width, height, depth, 0, 0, 0, 0, 0, 0, 0];

            const state = this.controller.data.objects.get(clientID);
            if (state) {
                this.dispatchEvent(
                    new CustomEvent('canvas.edited', {
                        bubbles: false,
                        cancelable: true,
                        detail: {
                            state,
                            points,
                        },
                    }),
                );
            } else {
                console.error('旋转时，没有找到对象！');
            }
        }
    }

    private objectUpdatePoints(state: ObjectState, points: number[]): void {
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            // const state = this.controller.data.objects.get(clientID);

            this.dispatchEvent(
                new CustomEvent('canvas.edited', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        state,
                        points,
                    },
                }),
            );
        }, 1000);
    }

    private objectFineRotate(viewType: ViewType, rotationDir: 'clockwise' | 'unclockwise' = 'clockwise'): void {
        // 目标物旋转
        const { clientID } = this.controller.data.activeElement;
        const offset = 0.01;

        let inverse = rotationDir === 'clockwise';

        if (
            clientID &&
            // !(this.controller.data.selected instanceof LanelineMesh) &&
            this.editable(this.controller.data.selected[ViewType.PERSPECTIVE])
        ) {
            // console.log('旋转：', this.model.data.rotate);
            this.rotateCube(this.controller.data.selected, inverse ? -offset : offset, viewType);
            this.rotatePlane(null, null);

            this.updateRotationHelperPos();
            this.updateResizeHelperPos();

            const { x, y, z } = this.controller.data.selected[ViewType.TOP].position;
            const { x: width, y: height, z: depth } = this.controller.data.selected[ViewType.TOP].scale;
            const { x: rotationX, y: rotationY, z: rotationZ } = this.controller.data.selected[ViewType.TOP].rotation;
            const points = [x, y, z, rotationX, rotationY, rotationZ, width, height, depth, 0, 0, 0, 0, 0, 0, 0];

            const state = this.controller.data.objects.get(clientID);

            this.objectUpdatePoints(state, points);
        }
    }

    private objectUpdatePosition(
        viewType: ViewType,
        type: 'move' | 'scale',
        cood: 'x' | 'y' | 'z',
        operation: 'add' | 'sub' = 'add',
    ): void {
        // console.log('移动：');

        const { clientID } = this.controller.data.activeElement;
        // if (this.controller.data.selected instanceof LanelineMesh) {
        //     return;
        // }
        // 目标物移动
        if (clientID && this.editable(this.controller.data.selected[viewType])) {
            // const matrixWorld: THREE.Matrix4 = this.controller.data.selected[ViewType.TOP].matrixWorld.clone();
            // 假定位置为0,0,0
            const position: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
            let { x: width, y: height, z: depth } = this.controller.data.selected[viewType].scale;
            const { x: rotationX, y: rotationY, z: rotationZ } = this.controller.data.selected[viewType].rotation;

            const offset = 0.01;

            if (type === 'move') {
                //移动位置
                if (operation === 'add') {
                    position.x += cood === 'x' ? offset : 0;
                    position.y += cood === 'y' ? offset : 0;
                    position.z += cood === 'z' ? offset : 0;
                } else if (operation === 'sub') {
                    position.x -= cood === 'x' ? offset : 0;
                    position.y -= cood === 'y' ? offset : 0;
                    position.z -= cood === 'z' ? offset : 0;
                }
            }

            if (type === 'scale') {
                //移动位置
                if (operation === 'add') {
                    position.x += cood === 'x' ? offset / 2 : 0;
                    position.y += cood === 'y' ? offset / 2 : 0;
                    position.z += cood === 'z' ? offset / 2 : 0;

                    width += cood === 'x' ? offset : 0;
                    height += cood === 'y' ? offset : 0;
                    depth += cood === 'z' ? offset : 0;
                } else if (operation === 'sub') {
                    position.x -= cood === 'x' ? offset / 2 : 0;
                    position.y -= cood === 'y' ? offset / 2 : 0;
                    position.z -= cood === 'z' ? offset / 2 : 0;

                    width -= cood === 'x' ? offset : 0;
                    height -= cood === 'y' ? offset : 0;
                    depth -= cood === 'z' ? offset : 0;
                }
            }

            // 乘以目标的世界矩阵， 转换为当前目标物位置
            // position.applyMatrix4(matrixWorld);
            this.controller.data.selected[ViewType.TOP].localToWorld(position);
            // console.log(`突然变0了11111： `, this.controller.data.selected[ViewType.TOP]);
            // console.log(`突然变0了： ${type}- ${cood}- ${operation}`, position);

            const points = [
                position.x,
                position.y,
                position.z,
                rotationX,
                rotationY,
                rotationZ,
                width,
                height,
                depth,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ];

            this.moveObject(position);
            this.updateRotationHelperPos();
            this.updateResizeHelperPos();

            const state = this.controller.data.objects.get(clientID);
            this.objectUpdatePoints(state, points);
        }
    }

    private viewsUpdateObjectRotation = (key: any) => {
        switch (key.code) {
            case ArrowType.ArrowLeft: {
                let viewType = undefined;
                if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
                    viewType = ViewType.SIDE;
                } else if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
                    viewType = ViewType.FRONT;
                }

                if (viewType) {
                    this.objectFineRotate(
                        [this.views.side.renderer.domElement].includes(key.target.children[0])
                            ? ViewType.SIDE
                            : ViewType.FRONT,
                        'unclockwise',
                    );
                }
                break;
            }
            case ArrowType.ArrowRight: {
                let viewType = undefined;
                if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
                    viewType = ViewType.SIDE;
                } else if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
                    viewType = ViewType.FRONT;
                }

                if (viewType) {
                    this.objectFineRotate(
                        [this.views.side.renderer.domElement].includes(key.target.children[0])
                            ? ViewType.SIDE
                            : ViewType.FRONT,
                        'clockwise',
                    );
                }
                break;
            }
            case ArrowType.ArrowUp:
                if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
                    this.objectFineRotate(ViewType.TOP, 'unclockwise');
                }
                break;
            case ArrowType.ArrowDown:
                if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
                    this.objectFineRotate(ViewType.TOP, 'clockwise');
                }
                break;

            default:
                break;
        }

        // switch (key.code) {
        //     case ArrowType.ArrowLeft:
        //         if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.TOP);
        //         }
        //         if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.SIDE, changeType, 'x');
        //         }
        //         if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.FRONT, changeType, 'y', 'sub');
        //         }
        //         break;
        //     case ArrowType.ArrowRight:
        //         if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.TOP, changeType, 'x');
        //         }
        //         if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.SIDE, changeType, 'x', 'sub');
        //         }
        //         if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.FRONT, changeType, 'y');
        //         }
        //         break;
        //     case ArrowType.ArrowUp:
        //         if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.TOP, changeType, 'y');
        //         }
        //         if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.SIDE, changeType, 'z');
        //         }
        //         if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.FRONT, changeType, 'z');
        //         }
        //         break;
        //     case ArrowType.ArrowDown:
        //         if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.TOP, changeType, 'y', 'sub');
        //         }
        //         if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.SIDE, changeType, 'z', 'sub');
        //         }
        //         if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
        //             this.objectFineRotate(ViewType.FRONT, changeType, 'z', 'sub');
        //         }
        //         break;

        //     default:
        //         break;
        // }
    };

    private viewsUpdateObjectPosition = (key: any, changeType: 'move' | 'scale' = 'move') => {
        switch (key.code) {
            case ArrowType.ArrowLeft:
                if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.TOP, changeType, 'x', 'sub');
                }
                if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.SIDE, changeType, 'x');
                }
                if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.FRONT, changeType, 'y', 'sub');
                }
                break;
            case ArrowType.ArrowRight:
                if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.TOP, changeType, 'x');
                }
                if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.SIDE, changeType, 'x', 'sub');
                }
                if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.FRONT, changeType, 'y');
                }
                break;
            case ArrowType.ArrowUp:
                if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.TOP, changeType, 'y');
                }
                if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.SIDE, changeType, 'z');
                }
                if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.FRONT, changeType, 'z');
                }
                break;
            case ArrowType.ArrowDown:
                if ([this.views.top.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.TOP, changeType, 'y', 'sub');
                }
                if ([this.views.side.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.SIDE, changeType, 'z', 'sub');
                }
                if ([this.views.front.renderer.domElement].includes(key.target.children[0])) {
                    this.objectUpdatePosition(ViewType.FRONT, changeType, 'z', 'sub');
                }
                break;

            default:
                break;
        }
    };

    public keyControls(key: KeyboardEvent) {
        const { controls } = this.views.perspective;
        if (!controls) return;
        if (key.altKey === true) {
            switch (key.code) {
                case 'KeyT':
                    this.objectUpdateRotate(true);
                    break;
                // case 'x':
                //     console.log('进入编辑模式');
                //     break;
                default:
                    break;
            }
        } else if (key.code === 'ControlLeft') {
            this.action.selectable = !key.ctrlKey;
        } else if (
            key.shiftKey &&
            [ArrowType.ArrowLeft, ArrowType.ArrowRight, ArrowType.ArrowUp, ArrowType.ArrowDown].includes(
                key.code as ArrowType,
            )
        ) {
            this.viewsUpdateObjectRotation(key);
        } else if (key.code === 'KeyT') {
            this.objectUpdateRotate();
        } else if (
            [ArrowType.ArrowLeft, ArrowType.ArrowRight, ArrowType.ArrowUp, ArrowType.ArrowDown].includes(
                key.code as ArrowType,
            )
        ) {
            this.viewsUpdateObjectPosition(key);
        }
    }

    // 为点云着色
    private initCloudPoints(readPoints: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>): void {
        this.views.perspective.setSceneChildren(readPoints);
        // 初始化坐标系帮助线

        this.updateObjects(true);
        return;

        const positions = readPoints.geometry.getAttribute('position');
        const hasIntensity = readPoints.geometry.hasAttribute('intensity');
        const intensities = hasIntensity ? readPoints.geometry.getAttribute('intensity') : null;

        const { array, itemSize } = positions;
        const newPoints = [];
        const newColors = [] as number[];
        const hasColor = readPoints.geometry.hasAttribute('color');
        const colors = hasColor ? readPoints.geometry.getAttribute('color') : null;

        const isRenderColor = false; // 是否渲染颜色
        const isRenderColorByintensity = true; // 是否按照强度值渲染颜色
        const isRenderColorByHeight = false; // 是否按照高度值渲染颜色

        const baseIntensity = 60; // 最低强度颜色值

        // const white = new THREE.Color('red');
        // const red = new THREE.Color('lightgray');
        // const fuchsia = new THREE.Color('red');
        // const yellow = new THREE.Color('red');
        // const green = new THREE.Color('red');
        // const aqua = new THREE.Color('red');
        // const blue = new THREE.Color('red');

        const white = new THREE.Color('white');
        const red = new THREE.Color('red');
        const fuchsia = new THREE.Color('fuchsia');
        const yellow = new THREE.Color('yellow');
        const green = new THREE.Color('green');
        const aqua = new THREE.Color('aqua');
        const blue = new THREE.Color('blue');

        const { r: wr, g: wg, b: wb } = white;
        const { r: rr, g: rg, b: rb } = red;
        const { r: fr, g: fg, b: fb } = fuchsia;
        const { r: yr, g: yg, b: yb } = yellow;
        const { r: gr, g: gg, b: gb } = green;
        const { r: ar, g: ag, b: ab } = aqua;
        const { r: br, g: bg, b: bb } = blue;

        let max = 0,
            min = 0;

        // console.log('强度值：', (intensities as THREE.BufferAttribute).array);

        // console.time('z值计算：')

        // // 取出所有z值
        // let zlist = Array.from(positions.array).filter((num, index) => index % 3 === 2);
        // zlist.sort();
        // // 先舍弃后面的三分之二，再舍弃前面的三分之一
        // zlist = zlist.slice(0, zlist.length / 3);
        // zlist = zlist.slice(zlist.length / 3)

        // const z = zlist[Math.floor(zlist.length / 2)];

        // console.timeEnd('z值计算：')
        // console.log('z值列表：', zlist);
        // console.log('z值：', z);

        // for (let index = 0; index < array.length; index += itemSize) {
        for (let index = 0; index < positions.count; index++) {
            // const x = array[index];
            // const y = array[index + 1];
            // const z = array[index + 2];

            const point = new THREE.Vector3().fromBufferAttribute(positions, index);
            const { x, y, z } = point;
            // const x = array[index];
            // const y = array[index + 1];
            // const z = array[index + 2];
            // 跳过NaN数据
            if (!Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(z)) {
                newPoints.push(x, y, z);
                if (isRenderColor) {
                    // 渲染点云颜色
                    if (colors) {
                        // 自身有颜色，按照自身颜色
                        const { r, g, b } = new THREE.Color().fromBufferAttribute(
                            colors as THREE.BufferAttribute,
                            index,
                        );
                        // const r = colors[index];
                        // const g = colors[index + 1];
                        // const b = colors[index + 2];
                        newColors.push(r, g, b);
                    } else {
                        newColors.push(wr, wg, wb);
                    }
                } else if (isRenderColorByintensity && intensities) {
                    const intensity = (intensities as THREE.BufferAttribute).array[index];

                    max = Math.max(intensity, max);
                    min = Math.min(intensity, min);
                    // console.log('强度值：', intensity);
                    // getFloat32
                    if (intensity < 1) {
                        newColors.push(wr, wg, wb);
                    } else if (intensity >= 1 && intensity < 2) {
                        newColors.push(fr, fg, fb);
                    } else if (intensity >= 2 && intensity < 3) {
                        newColors.push(ar, ag, ab);
                    } else if (intensity >= 3 && intensity < 4) {
                        newColors.push(gr, gg, gb);
                    } else if (intensity >= 4 && intensity < 5) {
                        newColors.push(yr, yg, yb);
                    } else if (intensity >= 5) {
                        newColors.push(rr, rg, rb);
                    } else {
                        // newColors.push(wr, wg, wb);
                        newColors.push(br, bg, bb);
                    }

                    // getUint32
                    // if (intensity < 1060000000) {
                    //     newColors.push(wr, wg, wb);
                    // } else if (intensity >= 1060000000 && intensity < 1070000000) {
                    //     newColors.push(fr, fg, fb);
                    // } else if (intensity >= 1070000000 && intensity < 1080000000) {
                    //     newColors.push(ar, ag, ab);
                    // } else if (intensity >= 1080000000 && intensity < 1090000000) {
                    //     newColors.push(gr, gg, gb);
                    // } else if (intensity >= 1090000000 && intensity < 1100000000) {
                    //     newColors.push(yr, yg, yb);
                    // } else if (intensity >= 1110000000) {
                    //     newColors.push(rr, rg, rb);
                    // } else {
                    //     // newColors.push(wr, wg, wb);
                    //     newColors.push(br, bg, bb);
                    // }

                    // const random = 255 / 5;
                    // getInt8

                    // const newIntensity = Math.max(baseIntensity + intensity, 255);

                    // newColors.push(newIntensity / 255, newIntensity / 255, newIntensity / 255);
                    // if (intensity < random) {
                    //     newColors.push(wr, wg, wb);
                    // } else if (intensity >= random && intensity < random * 2) {
                    //     newColors.push(fr, fg, fb);
                    // } else if (intensity >= random * 2 && intensity < random * 3) {
                    //     newColors.push(ar, ag, ab);
                    // } else if (intensity >= random * 3 && intensity < random * 4) {
                    //     newColors.push(gr, gg, gb);
                    // } else if (intensity >= random * 4 && intensity < random * 5) {
                    //     newColors.push(yr, yg, yb);
                    // } else if (intensity >= random * 5) {
                    //     newColors.push(rr, rg, rb);
                    // } else {
                    //     // newColors.push(wr, wg, wb);
                    //     newColors.push(br, bg, bb);
                    // }
                } else if (!hasColor && isRenderColorByHeight) {
                    // 自身没有颜色
                    if (z < 0.5) {
                        newColors.push(fr, fg, fb);
                    } else if (z >= 0.5 && z < 1) {
                        newColors.push(br, bg, bb);
                    } else if (z >= 1 && z < 1.5) {
                        newColors.push(ar, ag, ab);
                    } else if (z >= 1.5 && z < 2) {
                        newColors.push(gr, gg, gb);
                    } else if (z >= 2 && z <= 2.5) {
                        newColors.push(yr, yg, yb);
                    } else if (z > 2.5) {
                        newColors.push(rr, rg, rb);
                    }
                } else {
                    newColors.push(wr, wg, wb);
                }
            }
        }

        console.log('最小值：', min);
        console.log('最大值：', max);

        const points = this.createPointCloud(newPoints, newColors, itemSize);

        // this.views.perspective.setSceneChildren(points);
        this.views.perspective.scene.context.add(points);

        // this.initTreeViews();
        // this.views.top.setSceneChildren(points.clone() as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>);
        // this.views.side.setSceneChildren(points.clone() as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>);
        // this.views.front.setSceneChildren(points.clone() as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>);
        // 初始化坐标系帮助线

        this.updateObjects(true);
    }

    private initCloudMultPoints(readPointses: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>[]) {
        let maxX = Number.MIN_SAFE_INTEGER,
            minX = Number.MAX_SAFE_INTEGER,
            maxY = Number.MIN_SAFE_INTEGER,
            minY = Number.MAX_SAFE_INTEGER,
            maxZ = Number.MIN_SAFE_INTEGER,
            minZ = Number.MAX_SAFE_INTEGER;

        let normalCount = 0;

        const readPointsEvent = (readPoints: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>) => {
            const positions = readPoints.geometry.getAttribute('position');
            const hasIntensity = readPoints.geometry.hasAttribute('intensity');
            const intensities = hasIntensity ? readPoints.geometry.getAttribute('intensity') : null;

            const { array, itemSize } = positions;
            const newPoints = [];
            const newColors = [] as number[];
            const hasColor = readPoints.geometry.hasAttribute('color');
            const colors = hasColor ? readPoints.geometry.getAttribute('color') : null;

            const isRenderColor = false; // 是否渲染颜色
            const isRenderColorByintensity = true; // 是否按照强度值渲染颜色
            const isRenderColorByHeight = false; // 是否按照高度值渲染颜色

            const baseIntensity = 60; // 最低强度颜色值

            const white = new THREE.Color('white');
            const red = new THREE.Color('red');
            const fuchsia = new THREE.Color('fuchsia');
            const yellow = new THREE.Color('yellow');
            const green = new THREE.Color('green');
            const aqua = new THREE.Color('aqua');
            const blue = new THREE.Color('blue');

            console.log('位置：', positions);
            console.log('强度：', intensities);
            console.log('颜色：', colors);

            const { r: wr, g: wg, b: wb } = white;
            const { r: rr, g: rg, b: rb } = red;
            const { r: fr, g: fg, b: fb } = fuchsia;
            const { r: yr, g: yg, b: yb } = yellow;
            const { r: gr, g: gg, b: gb } = green;
            const { r: ar, g: ag, b: ab } = aqua;
            const { r: br, g: bg, b: bb } = blue;

            for (let index = 0; index < positions.count; index++) {
                const point = new THREE.Vector3().fromBufferAttribute(positions, index);
                const { x, y, z } = point;
                // 跳过NaN数据
                if (!Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(z)) {
                    if (Math.abs(x) < 100 && Math.abs(y) < 100 && Math.abs(z) < 100) {
                        normalCount++;
                    }

                    minX = Math.min(minX, x);
                    maxX = Math.max(maxX, x);

                    minY = Math.min(minY, y);
                    maxY = Math.max(maxY, y);

                    minZ = Math.min(minZ, z);
                    maxZ = Math.max(maxZ, z);
                    // 最小值X： -2395.30224609375
                    // 最大值X： -1853.0706787109375
                    // 最小值Y： -883.384765625
                    // 最大值Y： -575.2508544921875
                    // 最小值Z： -29.83209228515625
                    // 最大值Z： 43.28920364379883

                    //  最小值X： -1911.2142333984375
                    //  最大值X： -1502.5189208984375
                    //  最小值Y： -766.890380859375
                    //  最大值Y： -398.9633483886719
                    //  最小值Z： -30.287517547607422
                    //  最大值Z： 25.925769805908203

                    newPoints.push(x, y, z);
                    // newPoints.push(
                    //     x - (-1853.0706787109375 + -2395.30224609375) / 2,
                    //     y - (-883.384765625 + -575.2508544921875) / 2,
                    //     z - (-30.287517547607422 + 25.925769805908203) / 2,
                    // );
                    // newPoints.push(
                    //     x - (-1911.2142333984375 + -1502.5189208984375) / 2,
                    //     y - (-766.890380859375 + -398.9633483886719) / 2,
                    //     z - (-30.287517547607422 + 25.925769805908203) / 2,
                    // );
                    if (isRenderColor) {
                        // 渲染点云颜色
                        if (colors) {
                            // 自身有颜色，按照自身颜色
                            const { r, g, b } = new THREE.Color().fromBufferAttribute(
                                colors as THREE.BufferAttribute,
                                index,
                            );
                            newColors.push(r, g, b);
                        } else {
                            newColors.push(wr, wg, wb);
                        }
                    } else if (isRenderColorByintensity && intensities) {
                        const intensity = (intensities as THREE.BufferAttribute).array[index];

                        if (intensity < 1) {
                            newColors.push(wr, wg, wb);
                        } else if (intensity >= 1 && intensity < 2) {
                            newColors.push(wr, wg, wb);
                        } else if (intensity >= 2 && intensity < 3) {
                            newColors.push(wr, wg, wb);
                        } else if (intensity >= 3 && intensity < 4) {
                            newColors.push(wr, wg, wb);
                        } else if (intensity >= 4 && intensity < 5) {
                            newColors.push(wr, wg, wb);
                        } else if (intensity >= 5) {
                            newColors.push(rr, rg, rb);
                        } else {
                            // newColors.push(wr, wg, wb);
                            newColors.push(br, bg, bb);
                        }

                        // const newIntensity = Math.min(baseIntensity + intensity, 255);

                        // newColors.push(newIntensity / 255, newIntensity / 255, newIntensity / 255);
                    } else if (!hasColor && isRenderColorByHeight) {
                        // 自身没有颜色
                        if (z < 0.5) {
                            newColors.push(fr, fg, fb);
                        } else if (z >= 0.5 && z < 1) {
                            newColors.push(br, bg, bb);
                        } else if (z >= 1 && z < 1.5) {
                            newColors.push(ar, ag, ab);
                        } else if (z >= 1.5 && z < 2) {
                            newColors.push(gr, gg, gb);
                        } else if (z >= 2 && z <= 2.5) {
                            newColors.push(yr, yg, yb);
                        } else if (z > 2.5) {
                            newColors.push(rr, rg, rb);
                        }
                    } else {
                        newColors.push(wr, wg, wb);
                    }
                }
            }

            const points = this.createPointCloud(newPoints, newColors, itemSize);

            return points;
            // this.initTreeViews();
            // this.views.top.setSceneChildren(points.clone() as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>);
            // this.views.side.setSceneChildren(points.clone() as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>);
            // this.views.front.setSceneChildren(points.clone() as THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>);
            // 初始化坐标系帮助线
        };

        const pointses: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>[] = readPointses.map((rp) => {
            // rp.frustumCulled = false;
            // return rp;
            return readPointsEvent(rp);
        });

        console.log('最小值X：', minX);
        console.log('最大值X：', maxX);
        console.log('最小值Y：', minY);
        console.log('最大值Y：', maxY);
        console.log('最小值Z：', minZ);
        console.log('最大值Z：', maxZ);

        console.log('正常点的数量(Math.abs(x) < 100 && Math.abs(y) < 100 && Math.abs(z) < 100)：', normalCount);

        // console.log('读取结果：', pointses);
        // this.views.perspective.points = pointses[0];
        // this.views.perspective.scene.add(...pointses);
        // this.views.perspective.addNewHelper();

        this.updateObjects(true);
    }

    // 读取点云
    private loadPointCloud = async () => {
        // this.controller.mode = Mode.BUSY;
        const loader = new PCDLoader();
        const points = (await loader.loadAsync(this.controller.data.imageUrl)) as THREE.Points<
            THREE.BufferGeometry,
            THREE.PointsMaterial
        >;
        await this.initCloudPoints(points);
        // this.controller.mode = Mode.IDLE;
        this.dispatchEvent(new CustomEvent('canvas.setup'));
    };

    // 读取点云
    // private loadPointCloud = async () => {
    //     // this.controller.mode = Mode.BUSY;
    //     const loader = new PCDLoader();
    //     // loader.multFrame = true;
    //     const points = (await loader.load(this.controller.data.imageUrl)) as Record<
    //         number,
    //         THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>
    //     >;
    //     await this.initCloudMultPoints([...Object.values(points)]);
    //     // this.controller.mode = Mode.IDLE;
    //     this.dispatchEvent(new CustomEvent('canvas.setup'));
    // };

    // private loadPointCloudData = () => {
    //     if (this.controller.data.image) {
    //         const { position, color, intensity } = this.controller.data.image;
    //         this.views.perspective.updatePointsPositions(position, color, intensity);
    //         this.views.top.updatePointsPositions(position, color, intensity);
    //         this.views.side.updatePointsPositions(position, color, intensity);
    //         this.views.front.updatePointsPositions(position, color, intensity);
    //         this.dispatchEvent(new CustomEvent('canvas.setup'));
    //     }
    // };

    private createPointsFromNode(
        node: any,
        material: THREE.PointsMaterial,
    ): {
        perspective: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
        top: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
        side: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
        front: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
    }[] {
        const pointsList: {
            perspective: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
            top: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
            side: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
            front: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
        }[] = [];
        if (node.children?.length) {
            node.children.forEach((item: any) => {
                const points = this.createPointsFromNode(item, material);
                pointsList.push(...points);
            });
        } else if (node.data && node.data.positions?.length) {
            const { positions, colors, intensities } = node.data;

            const geometry = new THREE.BufferGeometry();
            const material = new THREE.PointsMaterial();

            material.size = 0.005;

            geometry.setAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
            if (colors?.length) {
                material.vertexColors = true;
                geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
            }

            geometry.computeBoundingSphere();

            // this.pointsCount += Math.floor(positions.length / 3);

            const perspective = new THREE.Points(geometry, material);
            const top = new THREE.Points(geometry, material);
            const side = new THREE.Points(geometry, material);
            const front = new THREE.Points(geometry, material);
            pointsList.push({
                perspective,
                top,
                side,
                front,
            });
        }
        return pointsList;
    }

    private pointsFromOctree(pcd: Pcd) {
        const material = new THREE.PointsMaterial();
        material.size = 0.005;
        // const pointsList = this.createPointsFromNode(octree.root, material);
        const { positions, colors } = pcd;
        return positions.map((position, index) => {
            const geometry = new THREE.BufferGeometry();
            geometry.setAttribute('position', position);
            // console.log('查看设置：', this.controller.configuration.pointCloudColor);
            if (
                colors?.length &&
                this.controller.configuration.pointCloudColor &&
                this.controller.configuration.pointCloudColor !== PointCloudColorBy.normal
            ) {
                material.vertexColors = true;
                geometry.setAttribute('color', colors[index]);
            }

            geometry.computeBoundingSphere();

            return {
                perspective: new THREE.Points(geometry, material),
                top: new THREE.Points(geometry, material),
                side: new THREE.Points(geometry, material),
                front: new THREE.Points(geometry, material),
            };
        });
    }

    private loadPointCloudData = () => {
        console.time('创建点云时间');
        if (this.controller.data.pointsCloud) {
            try {
                const data = this.controller.data.pointsCloud;
                // console.log('数据：', this.controller.data.pointsCloud);

                const pointsList = this.pointsFromOctree(data);

                Object.entries(this.views).forEach(([viewType, view]: [string, BaseView]) => {
                    view.scene.removeContexts();
                });

                this.views.perspective.scene.addContext(...pointsList.map((view) => view.perspective));
                this.views.top.scene.addContext(...pointsList.map((view) => view.top));
                this.views.side.scene.addContext(...pointsList.map((view) => view.side));
                this.views.front.scene.addContext(...pointsList.map((view) => view.front));
                // console.log('结果点数：', this.views.perspective.scene.pointsCount);

                this.dispatchEvent(new CustomEvent('canvas.setup'));
            } catch (error) {
                console.error('出现错误！', error);
            }
        } else {
            throw new Error('读取点云错误！');
        }
        console.timeEnd('创建点云时间');
    };

    private updateParams = () => {
        const { cameraRadius = [], viewAngle = 0, viewStartMiddleAngle = 0 } = this.controller.data.pcdParameter;
        Object.entries(this.views).forEach(([viewType, view]: [string, BaseView]) => {
            view.scene.updateAnnotationRange({
                cameraRadius,
                viewAngle,
                viewStartMiddleAngle,
            });
        });
    };

    public notify(master: Controller, reason: string): void {
        switch (reason) {
            case UpdateReasons.update_pcd: {
                // 变更点云
                this.loadPointCloudData();
                break;
            }
            case UpdateReasons.update_param: {
                // 变更点云
                // this.loadPointCloud();
                this.updateParams();
                break;
            }
            case UpdateReasons.update_objects: {
                // 变更点云
                // this.loadPointCloud();
                this.updateObjects(true);
                break;
            }
            case UpdateReasons.delete_point: {
                this.deletePoint();
                break;
            }
            case UpdateReasons.delete_objects: {
                // 变更点云
                // this.loadPointCloud();
                this.deleteObjects();
                break;
            }
            case UpdateReasons.update_issues: {
                // 变更点云
                // this.loadPointCloud();
                this.updateIusses();
                break;
            }
            case UpdateReasons.IMAGE_CHANGED: {
                // 变更点云
                this.loadPointCloud();
                break;
            }
            case UpdateReasons.OBJECTS_UPDATED: {
                // 变更点云
                this.updateObjects(true);
                break;
            }
            case UpdateReasons.ISSUE_REGIONS_UPDATED: {
                // 变更点云
                this.updateIusses();
                break;
            }
            case UpdateReasons.SHAPE_ACTIVATED: {
                // 变更点云
                // this.onSelectObject();
                this.updateObjects(true);
                if (this.controller.data.activeElement.clientID) {
                    this.onReSelectObject();
                }
                this.setDefaultZoom();
                break;
            }
            case UpdateReasons.ISSUE_ACTIVATED: {
                // 变更点云
                // this.onSelectObject();
                this.updateObjects(true);
                break;
            }
            case UpdateReasons.CONFIG_UPDATED: {
                // 变更点云
                this.updateObjects(true);
                break;
            }
            case UpdateReasons.DRAW: {
                const data: DrawData = this.controller.data.drawData;
                if (data.type === 'object') {
                    if (data.initialState) {
                        this.cube = this.setupObject(data.initialState[0], false);
                        this.views.perspective.scene.add(this.cube.perspective);
                        break;
                    } else if (data.shapeType === ShapeType.CUBOID) {
                        this.cube = new CuboidModel(-1, '#ffffff');
                        const { x, y, z } = this.controller.data.config_object.defalutScale;
                        this.cube.setScale(x, y, z);
                    } else if (data.shapeType === ShapeType.laneline) {
                        // this.cube = new LanelineMesh();
                        // this.views.perspective.scene.add(this.cube.perspective);
                        // this.views.perspective.changeDrawMode(CameraMode.orthographic);
                        if (data.enabled) {
                            // 开始绘画
                            console.log('-------------------开始绘画车道线-----------------');
                            this.views.perspective.changeDrawMode(CameraMode.orthographic);
                            this.action.helper = new LaneHelper();
                            this.action.helper.draw(new Vector3());
                            this.views.perspective.scene.add(this.action.helper.object);
                        } else {
                            // this.action.helper.draw();
                            this.drawDone();
                            this.action.helper.destroy();
                            this.action.helper = undefined;
                        }
                    } else if (data.shapeType === ShapeType.bezier2) {
                        if (data.enabled) {
                            this.views.perspective.changeDrawMode(CameraMode.orthographic);
                            this.action.helper = new BezierHelper();
                            this.action.helper.draw(new Vector3());
                            this.views.perspective.scene.add(this.action.helper.object);
                            console.log('-------------------开始绘画贝塞尔曲线2-----------------');
                        } else {
                            this.action.helper.draw();
                            this.drawDone();
                            this.action.helper.destroy();
                            this.action.helper = undefined;
                        }
                    } else {
                        throw new Error(`暂不支持该格式-${data.shapeType}`);
                    }
                } else if (data.type === 'issue') {
                    this.cube = new SphereModel('-1', 'yellow');
                } else if (data.type === 'subObject' && this.controller.data.activeElement.clientID) {
                    const lane = this.meshCollection.get(this.controller.data.activeElement.clientID) as Lane;
                    if (lane && lane.isLane) {
                        // 绘画子对象
                        if (data.enabled) {
                            // 开始绘画
                            // console.log('开始绘画子对象：');
                            this.views.perspective.changeDrawMode(CameraMode.orthographic);
                            this.action.helper = new LaneHelper();
                            this.action.helper.object = lane;
                            (this.action.helper as LaneHelper).drawDashedDots();
                            this.views.perspective.scene.add(this.action.helper.object);
                        } else {
                            this.action.helper.draw();
                            this.drawDone();
                            this.action.helper.destroy();
                            this.action.helper = undefined;
                        }
                    }
                }

                // [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((viewType: ViewType): void => {
                //     this.views[viewType as keyof Views].init();
                // });

                if (data.redraw) {
                    const object = this.views.perspective.scene.getObjectByName(String(data.redraw));
                    if (object) {
                        (this.cube as Object3DBase).perspective = object.clone() as THREE.Mesh;
                        object.visible = false;
                    }
                } else if (data.initialState) {
                    this.cube = this.setupObject(data.initialState[0], false);
                }
                break;
            }
            // case UpdateReasons.bezier2: {
            //     this.dispatchEvent(
            //         new CustomEvent(this.mode === Mode.birdEye ? 'canvas.bezier2.start' : 'canvas.bezier2.stop', {
            //             bubbles: false,
            //             cancelable: true,
            //         }),
            //     );
            //     if (this.mode === Mode.bezier2) {
            //         this.views.perspective.changeDrawMode(CameraMode.orthographic);
            //         this.action.helper = new BezierHelper();
            //         console.log('-------------------开始绘画贝塞尔曲线2-----------------');
            //         this.views.perspective.scene.add(this.action.helper.object);
            //     } else {
            //         this.action.helper.draw(this.views.perspective.rayCaster.renderer.ray.origin);
            //         // this.action.helper?.destroy();
            //         // this.action.helper = undefined;
            //         this.views.perspective.changeDrawMode(CameraMode.perspective);
            //     }
            //     break;
            // }
            case UpdateReasons.birdEye: {
                this.dispatchEvent(
                    new CustomEvent(this.mode === Mode.birdEye ? 'canvas.birdEyeModeStart' : 'canvas.birdEyeModeStop', {
                        bubbles: false,
                        cancelable: true,
                    }),
                );
                // this.controller.data.activeElement.clientID = null;
                // this.controller.data.activeElement.issueID = null;
                // this.onSelectObject();

                // this.mode = Mode.birdEye;
                if (this.mode === Mode.birdEye) {
                    // const { controls } = this.views.perspective;
                    // setControls(controls);
                    // controls.mouseButtons.left = CameraControls.ACTION.NONE;

                    this.views.perspective.changeDrawMode(CameraMode.orthographic);
                } else {
                    this.views.perspective.changeDrawMode(CameraMode.perspective);
                }
                break;
            }
            case UpdateReasons.updateLaneline: {
                this.updateLaneline();
                break;
            }
            case UpdateReasons.summarize_data: {
                this.summarize();
                break;
            }
            case UpdateReasons.move_object: {
                if (this.controller.data.moveData.enabled) {
                    this.mode = Mode.move_object;
                    this.updateObjects(true);
                    this.views.perspective.changeDrawMode(CameraMode.orthographic);
                    this.dispatchEvent(new CustomEvent('canvas.moveObjectStart'));
                } else {
                    this.mode = Mode.IDLE;
                    this.views.perspective.changeDrawMode(CameraMode.perspective);
                    this.dispatchEvent(new CustomEvent('canvas.moveObjectEnd'));
                }
                break;
            }
            case UpdateReasons.CANCEL: {
                if (
                    this.mode === Mode.DRAW ||
                    (this.controller.data.drawData && this.controller.data.drawData.enabled && this.cube)
                ) {
                    this.controller.data.drawData.enabled = false;
                    this.controller.data.drawData.redraw = undefined;
                    Object.keys(this.views).forEach((view: string): void => {
                        this.views[view as keyof Views]?.scene.remove(
                            (this.cube as Object3DBase)?.[view as keyof Views],
                        );
                    });
                }

                this.action.helper?.destroy();
                this.action.helper = undefined;

                this.views.perspective.cancelIdle();
                this.mode = Mode.IDLE;

                this.dispatchEvent(new CustomEvent('canvas.canceled'));
                break;
            }
            case UpdateReasons.FITTED_CANVAS: {
                this.dispatchEvent(new CustomEvent('canvas.fit'));
                break;
            }
            default:
                // this.model.mode = Mode.IDLE;
                break;
        }
    }

    public async notifyAsync(master: MasterProps, reason: string): Promise<void> {
        switch (reason) {
            case UpdateReasons.summarize_data: {
                await this.summarize();
                break;
            }
        }
    }

    private getCamPosition(position: THREE.Vector3) {
        const newPosition = position.clone();
        let camWx = 0;
        let camWy = 0;
        let camWz = 0;

        if (this.controller.data.cameraToBumpers && this.controller.data.cameraToBumpers.length >= 1) {
            newPosition.applyMatrix4(this.controller.data.cameraToBumpers[0]);
            camWx = newPosition.x;
            camWy = newPosition.y;
            camWz = newPosition.z;
        }

        return newPosition;
    }

    /**
     *
     * @param rotationInWorld 目标物在世界坐标系的方向向量
     * @param cameraToBumper 外参
     * @param positionInCam 相机坐标系下，目标物位置
     */
    private getAlpha = (
        rotationInWorld: THREE.Vector3,
        cameraToBumper: THREE.Matrix4,
        positionInCam: THREE.Vector3,
    ) => {
        try {
            // 获取世界坐标系下，方向向量。
            // 从外参中获取旋转四元数
            const rotaion = new THREE.Quaternion().setFromRotationMatrix(cameraToBumper);
            // 世界坐标系下的方向向量，在相机坐标系中的方向向量。
            const rotationInCam = rotationInWorld.clone().applyQuaternion(rotaion);

            // XZ平面上的方向向量。
            const rotationInXZ = new THREE.Vector3(rotationInCam.x, 0, rotationInCam.z);
            // XZ平面上的目标物位置向量。
            const positionInXZ = new THREE.Vector3(positionInCam.x, 0, positionInCam.z);
            // 相机坐标系下，方向向量和x轴之间的夹角。 顺时针为正，逆时针为负
            const rotation_y =
                rotationInXZ.z > 0
                    ? -rotationInXZ.angleTo(new THREE.Vector3(1, 0, 0))
                    : rotationInXZ.angleTo(new THREE.Vector3(1, 0, 0));
            // 相机坐标系下，目标物位置与z轴夹角。 顺时针为正，逆时针为负
            const theta =
                positionInXZ.x > 0
                    ? positionInXZ.angleTo(new THREE.Vector3(0, 0, 1))
                    : -positionInXZ.angleTo(new THREE.Vector3(0, 0, 1));

            // 试用新的计算方法
            let alpha = rotation_y - theta;
            // 适配超出值
            if (alpha > Math.PI) {
                alpha = 2 * Math.PI - alpha;
            } else if (alpha < -Math.PI) {
                alpha = 2 * Math.PI + alpha;
            }
            // let alpha = rotation_y;
            // alpha += Math.atan2(positionInCam.z, positionInCam.x) + 1.5 * Math.PI;
            // if (alpha < -Math.PI) {
            //     alpha += 2 * Math.PI;
            // } else if (alpha > Math.PI) {
            //     alpha -= 2 * Math.PI;
            // }

            // console.log('相机坐标系目标物的方向向量：', rotationInCam);
            // console.log('相机坐标系目标物位置：', positionInCam);
            // console.log('rotation_y: ', rotation_y);
            // console.log('theta: ', theta);
            // console.log('alpha: ', alpha);
            return { rotationY: rotation_y, alpha };
        } catch (error) {}
        return { rotationY: 0, alpha: 0 };
    };

    private async summarize(): Promise<void> {
        const objectURL = URL.createObjectURL(this.controller.data.summarize?.framePcd?.imageData?.data.data);
        const points = await new PCDLoader().loadAsync(objectURL);
        // const positions = points.geometry.getAttribute('position');

        // const { array, itemSize } = positions;
        // const newPoints = [];

        // for (let index = 0; index < array.length; index += itemSize) {
        //     const x = array[index];
        //     const y = array[index + 1];
        //     const z = array[index + 2];
        //     // 跳过nan数据
        //     if (!Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(z)) {
        //         // console.log('有nan，跳过');
        //         newPoints.push(x, y, z);
        //     }
        // }

        // points.geometry.setAttribute('position', new THREE.Float32BufferAttribute(newPoints, itemSize));

        // points.updateMatrixWorld();
        // 将对象加入点中
        // console.log('当前数据：', this.controller.data.summarize.frame);

        const { objects } = this.controller.data.summarize;
        const material = new THREE.MeshBasicMaterial();
        const geometry = new THREE.BoxGeometry(1, 1, 1);

        for (const object of objects) {
            // console.log('测试：',);
            const baseInfo: ExportBaseInfo = {
                jobId: 0,

                frame: 0,
                frameType: 0,

                trackShapeId: object.serverID,
                objId: object.serverID,
                normalShapeId: object.serverID,
            };
            // const attributes: any = {};

            const attributes = getExportAttributes(object.attributes, object.label);
            let positionInfo: PositionInfo = {
                pointsNum: 0,
                objLength: 0,
                objWidth: 0,
                objHeight: 0,
                objWx: 0,
                objWy: 0,
                objWz: 0,
                objPitch: 0,
                objRoll: 0,
                objYaw: 0,
            };

            if (object.objectType !== ObjectType.TAG) {
                const mesh = new THREE.Mesh(geometry, material);
                mesh.position.set(object.points[0], object.points[1], object.points[2]);
                mesh.scale.set(object.points[6], object.points[7], object.points[8]);
                mesh.rotation.set(object.points[3], object.points[4], object.points[5]);

                points.add(mesh);

                points.updateMatrixWorld();
                mesh.updateMatrixWorld();

                mesh.geometry.computeBoundingBox();

                const positions = points.geometry.getAttribute('position');
                const { array, itemSize } = positions;

                const bounding = mesh.geometry.boundingBox;

                let pointsNum = 0;

                for (let index = 0; index < array.length; index += itemSize) {
                    const x = array[index];
                    const y = array[index + 1];
                    const z = array[index + 2];

                    const point = new THREE.Vector3(x, y, z);

                    const isInBox = bounding.containsPoint(mesh.worldToLocal(point.clone()));
                    if (!Number.isNaN(x) && !Number.isNaN(y) && !Number.isNaN(z) && isInBox) {
                        pointsNum++;
                    }
                }

                const point2Ds = transLidarPositionToImagesPoints(
                    mesh.matrixWorld,
                    this.controller.data.cameraCalibs?.[0],
                    this.controller.data.cameraToBumpers?.[0],
                    this.controller.data.cameraDistortionParameter?.[0],
                );

                const camPosition = this.getCamPosition(
                    new THREE.Vector3(object.points[0], object.points[1], object.points[2]),
                );

                // 世界坐标系x轴，上的一个点，与目标物的初始方向相同，应用目标物的欧拉旋转
                const normalizeX = new THREE.Vector3(1, 0, 0).applyEuler(
                    new THREE.Euler(object.points[3], object.points[4], object.points[5]),
                );
                const { rotationY, alpha } = this.getAlpha(
                    normalizeX,
                    this.controller.data.cameraToBumpers?.[0],
                    camPosition,
                );

                positionInfo = {
                    rotationY,
                    alpha,

                    pointsNum,

                    objLength: object.points[6],
                    objWidth: object.points[7],
                    objHeight: object.points[8],

                    objWx: object.points[0],
                    objWy: object.points[1],
                    objWz: object.points[2],

                    objRoll: object.points[3],
                    objPitch: object.points[4],
                    objYaw: object.points[5],

                    points: JSON.stringify(point2Ds),

                    camWx: camPosition.x,
                    camWy: camPosition.y,
                    camWz: camPosition.z,
                };
            }

            this.controller.data.summarize.data.push({
                ...baseInfo,
                ...positionInfo,
                ...attributes,
            });
        }
    }
}

export { Canvas3dView };
