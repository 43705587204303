/*
 * @Author: swxy
 * @Date: 2022-08-17 18:35:05
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
    'workspace.track': 'Consecutive frame',
    'workspace.shape': 'Regular frame',
    'workspace.tag': 'Tag',

    'workspace.top.back': 'Back to previous page',
    'workspace.top.save': 'Save',

    'workspace.top.undo': 'Undo', //Revoke
    'workspace.top.redo': 'Redo', //Next

    // 'workspace.top.go_back': 'Go back', //Back
    'workspace.top.go_back': 'Previous Frame', //Back
    'workspace.top.go_back_filter': 'Previous filtered Frame',
    'workspace.top.go_back_empty': 'Last empty frame',
    'workspace.top.go_next': 'Next Frame', //Next
    'workspace.top.go_next_filter': 'Next filtered frame', //Next with a filter
    'workspace.top.go_next_empty': 'Next empty frame', //Next to an empty frame

    'workspace.top.frist_frame': 'Go to the first frame', //First frame
    // 'workspace.top.prve_step': 'Go back with a step', //Previous node
    'workspace.top.prve_step': 'Rewind', //Previous node
    'workspace.top.play': 'RePlay',
    'workspace.top.pause': 'Pause',

    // 'workspace.top.next_step': 'Go next with a step', //Next node
    'workspace.top.next_step': 'Fast Forward', //Next node
    'workspace.top.last_frame': 'Go to the last frame', //Last frame

    'workspace.top.change_frame_input_tip': 'Press {{hot}} to focus here',
    'workspace.top.panle_m': 'Show panel/Hide panel',
    'workspace.top.attribute_m': 'Show the attributes/ Hide the attributes',
    'workspace.top.active_move': 'Move and activate object',
    'workspace.top.active_click': 'Click and activate object',
    'workspace.top.fullscreen_exit': 'Exit Fullscreen',
    'workspace.top.fullscreen': 'Fullscreen',
    'workspace.top.annotation_info': 'Annotation information',
    'workspace.top.annotation_object_filter': 'Select annotation object',

    'workspace.top.drop.unsavedata_tip': 'The label not saved',
    'workspace.top.drop.save_tip': 'Do you need to save?',
    'workspace.top.drop.save': 'Save',
    'workspace.top.drop.unsave': 'Unsave',

    'workspace.top.drop.change_workspace_tip': 'Change the current work states',
    'workspace.top.drop.change_workspace_content_tip':
        'Status will be changed to "completed". Would you like to continue?',
    'workspace.top.drop.change_workspace_continue': 'Continue',
    'workspace.top.drop.change_workspace_cancle': 'Cancel',

    'workspace.top.drop.showAllProjection': 'Full projection display',
    'workspace.top.drop.showAllProjection_tip': 'Show the projection of the annotation object on the image',
    'workspace.top.drop.panel_model': 'Panel display model',
    'workspace.top.drop.change_panel_model': 'Change the display model of panel list',
    'workspace.top.drop.panel_left': 'The left panel',
    'workspace.top.drop.panel_show_on_left': 'The panel list on the left',
    'workspace.top.drop.change_serialid': 'Modify the identity',
    'workspace.top.drop.panel_sort': 'Sort of the list on the panel',
    'workspace.top.drop.change_workspace': 'Change the work mode',
    'workspace.top.drop.submit': 'Submit',
    'workspace.top.drop.submit_title': 'Submit',

    'workspace.top.drop.workspace_standard3d': 'Standard 3D',
    'workspace.top.drop.workspace_standard': 'Standard',
    'workspace.top.drop.workspace_attribute_annotation': 'Attribute',
    'workspace.top.drop.workspace_tag_annotation': 'Tag',
    'workspace.top.drop.workspace_review': 'Review',

    'workspace.top.modal.sort': 'Sort by',
    'workspace.top.modal.sort_ID_descent': 'ID - descent',
    'workspace.top.modal.sort_ID_ascent': 'ID - ascent',
    'workspace.top.modal.sort_update_time': 'Updated time',

    'workspace.top.modal.submit_permission': 'Permission',
    'workspace.top.modal.submit_permission_sub':
        'You do not have permission to perform this operation. If you hava any question，please request to the administrators.！',
    'workspace.top.modal.submit_permission_close': 'Close',

    'workspace.top.modal.submit_review_success': 'Review success！',
    'workspace.top.modal.submit_review_back_reason_w': 'Please enter the review comments',
    'workspace.top.modal.submit_status_submit': 'Submit',
    'workspace.top.modal.submit_status_frist_review': 'The first review passed',
    'workspace.top.modal.submit_status_second_review': 'The second review passed',
    'workspace.top.modal.submit_status_acceptance': 'Accepted',
    'workspace.top.modal.submit_status_review': 'Review',

    'workspace.top.modal.submit_loading_tip': 'Do not close the page during the data generation process',
    'workspace.top.modal.submit_content': ' Is this job？',

    'workspace.top.modal.submit_sorce_very_bad': 'Very low quality',
    'workspace.top.modal.submit_sorce_bad': 'Low quality',
    'workspace.top.modal.submit_sorce_authorized': 'Qualified',
    'workspace.top.modal.submit_sorce_good': 'Good Quality',
    'workspace.top.modal.submit_sorce_very_good': 'Excellent Quality',

    'workspace.top.modal.submit_submit': 'Submit',
    'workspace.top.modal.submit_pass': 'Pass',
    'workspace.top.modal.submit_back_reason': 'Please enter the review comments！',
    'workspace.top.modal.submit_back': 'Return',
    'workspace.top.modal.submit_close': 'Close',

    'workspace.content.view.top': 'Top View',
    'workspace.content.view.side': 'Side View',
    'workspace.content.view.front': 'Front View',

    // 'workspace.content.view.object_info': 'Length:{{length}},Width:{{width}},Height:{{height}}.',

    'workspace.content.view.object_lenght': 'Length: ',
    'workspace.content.view.object_width': 'Width: ',
    'workspace.content.view.object_height': 'Height: ',

    'workspace.content.view.object_cloud_point': 'Points amount of cloud pints: ',

    'workspace.content.image.only_show_projection_tip': 'Projection of selected object',
    'workspace.content.image.only_show_select': 'Selected',

    'workspace.side.controls.cursor': 'Cursor',
    'workspace.side.controls.parent_sub_delete':
        'When selected, the subsidiary elements will also be deleted when the parent element is deleted for the objects with primary and secondary associations.',

    'workspace.side.controls.max_points': 'Maxmum points',
    'workspace.side.controls.draw_cuboid_model_tip': 'Boundingbox generation model',
    'workspace.side.controls.draw_cuboid_model_from_rectangle': 'Rectangle extension',
    'workspace.side.controls.draw_cuboid_model_4_points': 'Four points',

    'workspace.side.controls.draw_continue_tip':
        'Continue for annotation with current work model after one labeling done.',
    'workspace.side.controls.draw_continue': 'Booster - Continuous annotation',

    'workspace.side.controls.cuboid_tip': 'Click into draw cuboid model, cancel with duplicate operations',
    'workspace.side.controls.cuboid_3d_tip': 'Draw 3D Boundingbox',

    'workspace.side.controls.ellipse_tip': 'Click and into the draw circle model',
    'workspace.side.controls.points_tip':
        'Click and into dot model, click again to recofirm if quantity not configured',
    'workspace.side.controls.polygon_tip':
        'Click and into draw polygon model,  click again to recofirm if quantity not configured',
    'workspace.side.controls.polyline_tip':
        'Click and into draw line model, click again to recofirm if quantity not configured',
    'workspace.side.controls.rectangle_tip':
        'Clicked into draw rectangle model，Press "a" shortcuts, cancel with duplicate operations',
    'workspace.side.controls.fit_tip': 'Fit the image size [Double Click]',
    'workspace.side.controls.group_tip': 'Select and press {{key}} to reset a group.',
    'workspace.side.controls.merge_tip': 'Merge normal frames and continuous frames into new continuous frame',
    'workspace.side.controls.split_tip':
        'Select the continuous frames and subsequent ones and split them into a new continuous frame.',
    'workspace.side.controls.add_v_line':
        'With the current default label, add a secondary object-vertical dividing line',
    'workspace.side.controls.add_h_line':
        'With the current default label, add a secondary object-horizontal dividing line',
    'workspace.side.controls.move_tip': 'Move the image',
    'workspace.side.controls.resize_interest_tip': 'Select a region of interest',
    'workspace.side.controls.rotate_anticlockwise_tip': 'Rotate the image anticlockwise',
    'workspace.side.controls.rotate_clockwise_tip': 'Rotate the image clockwise',
    'workspace.side.controls.fine_tuning': 'Fine tuning',

    'workspace.side.controls.create_issue': 'Create Review Comments',

    // 'workspace.side.controls.track_model_tip':
    //     'Switch between creating < normal dimension object > and < continuous frame dimension object >',
    'workspace.side.controls.track_model_tip': 'Changel the annotation model',
    'workspace.side.controls.track_model': 'Track',
    // 'workspace.side.controls.track_model': 'Consecutive frames',
    'workspace.side.controls.autoFit_tip': 'Automatically calculate rotation and frame the point cloud',
    'workspace.side.controls.autoFit': 'Automatic fitting',

    'workspace.side.issue.defualt_label_msg': 'Defualt label change to：',
    'workspace.side.issue.delete_issue_pop': 'Confirm to delete this review comment？',
    'workspace.side.issue.delete_delete': 'Delete',
    'workspace.side.issue.delete_cancel': 'Cancel',
    'workspace.side.issue.find_previous_frame_issues': 'Find the previous frame with review comments',
    'workspace.side.issue.find_next_frame_issues': 'Find the next frame with review comments',
    'workspace.side.issue.show/hide_all_issue': 'Show/Hide all the review comments',
    'workspace.side.issue.hide_all_resolved_issue': 'Hide all review comments with reworked objects ',
    'workspace.side.issue.resolved': 'Reworked',
    'workspace.side.issue.open': 'Open',

    'workspace.side.object.show_detail_att': 'Show attrubutes of current object',
    'workspace.side.object.track_simple': 'Join',
    'workspace.side.object.lock': 'Locked the current object',
    'workspace.side.object.occluded': 'If the current object is occluded',
    'workspace.side.object.pinned': 'Fix the current object position',
    'workspace.side.object.hidden': 'Hide the current object ',
    'workspace.side.object.outside': 'Current object is out of the image',
    'workspace.side.object.keyframe': 'Set/Cancel the key frames of current object ',

    'workspace.side.object.label_type': 'Label type',
    'workspace.side.object.parent_id': 'Parent ID',
    'workspace.side.object.cancel_relation': 'Disassociate',
    'workspace.side.object.cancel_relation_tip': 'The primary and secondary associations cancelled',

    'workspace.side.object.context.create_url': 'Create the hyperlink for current object',
    'workspace.side.object.context.right_context_panel': 'show the right-click property panel',
    'workspace.side.object.context.copy': 'Copy',
    'workspace.side.object.context.propagate': 'Multi-frame copy',
    'workspace.side.object.context.changeStartFrame': 'Change the start frame',
    'workspace.side.object.context.switch': 'Toggle orientation',
    'workspace.side.object.context.reset': 'Reset perspective',
    'workspace.side.object.context.background': 'Background',
    'workspace.side.object.context.foreground': 'Foreground',
    'workspace.side.object.context.changeColor': 'Change {{name}} color',
    'workspace.side.object.context.object_lock_tip': 'Obejct locked',
    'workspace.side.object.context.delete_object_lock_tip': 'Will keep delete？',
    'workspace.side.object.context.delete': 'Delet',

    'workspace.side.top.lock_all': 'Locked all the current objects',
    'workspace.side.top.hide_all_unlock': 'Hide all current unlocked objects',
    'workspace.side.top.expand_collapse_all': 'Expand or collapse the attributes of all the objects',
    'workspace.side.top.force_active': 'Show the attibutes of current active objects',

    'workspace.side.appearance.color': 'Color',

    'workspace.side.appearance.instance': 'Object',
    'workspace.side.appearance.group': 'Group',
    'workspace.side.appearance.label': 'Label',

    'workspace.side.appearance.opacity': 'Opacity',
    'workspace.side.appearance.selected_opacity': 'Selected opacity',
    'workspace.side.appearance.outlined_borders': 'Outlined borders',
    'workspace.side.appearance.show_bitmap': 'Show bitmap',
    'workspace.side.appearance.show_projections': 'show projections',

    'workspace.side.tab.object': 'Objects',
    'workspace.side.tab.labels': 'Labels',
    'workspace.side.tab.issues': 'Approval',
    'workspace.side.tab.appear': 'Appearance',

    'workspace.side.export.dumpOrderType': 'Export file type',

    'workspace.side.object.context.trackFrame.title': 'Consecutive frame',
    'workspace.side.object.context.trackFrame.detail':
        'Change the start frame of current consecutive frames to(Extend the current objects to: The',
    'workspace.side.object.context.trackFrame.track': 'Frame',

    'workspace.canvas.rectangleHelp':
        'The minimum labeling condition is not met: width-{{minWidth}}, height-{{minHeight}}',

    'workspace.canvas.deletePoints': 'Delete point',
    'workspace.canvas.deletePointsTip': 'Delete point{{shortcut}}',
    'workspace.canvas.setCover': 'Set to occlusion',
    'workspace.canvas.cancelCover': 'Set to normal',
    'workspace.canvas.setStartPoint': 'Set start point',

    'workspace.top.modal.export_loading_tip': '数据导出生成中，请勿关闭页面...',

    'workspace.side.controls.moveObject': '目标物移动模式',

    'workspace.top.track.firstKeyFrame': 'first keyframe',
    'workspace.top.track.lastKeyFrame': 'last keyframe',
    'workspace.top.track.previousKeyFrame': 'previous keyframe {{key}}',
    'workspace.top.track.nextKeyFrame': 'next keyframe {{key}}',
};
