/*
 * @Author: swxy
 * @Date: 2022-08-15 14:22:13
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
     language: 'Deutsch',
     setting: 'Einstellung',
     logout: 'Abmelden',
     loginfo_timeout: 'Anmeldeinformationen sind abgelaufen, bitte melden Sie sich erneut an! ',

     ok: 'OK',
     cancel: 'Abbrechen',
     close: 'Schließen',
     collapsed: 'Erweitern',
     uncollapsed: 'zusammengeklappt',

     'base.deleteConfirm': 'Möchtest du dieses Element wirklich löschen',
     'base.delete': 'Löschen',
     'base.close': 'schließen',
     'base.upload': 'Hochladen',
     
     'base.prevStep': 'Vorheriger',
     'base.nextStep': 'Nächster',
     'base.send': 'Senden',
     
}