/*
 * @Author: swxy
 * @Date: 2023-08-19 16:39:31
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { RequestSetting } from 'reducers/interfaces';
import { post } from 'utils/request';
import { getToken, getUserInfo, removeToken } from 'utils/storage';

// const random = Math.floor(Math.random() * 100000000);
let heartTimer: NodeJS.Timer | undefined = undefined;

export const api_heartbeat = async () => {
    try {
        await post(`/api/aatp/website/heartbeat`);
    } catch (errorData) {
        removeToken();
        // throw generateError(errorData);
    }
    return;
    const requestHeart = async () => {
        try {
            await post(`/api/aatp/website/heartbeat`);
        } catch (errorData) {
            removeToken();
            // throw generateError(errorData);
        }
    };

    if (heartTimer) {
        clearInterval(heartTimer);
    }

    // 有token的时候，才会开启
    if (getToken()) {
        requestHeart();
        heartTimer = setInterval(() => {
            requestHeart();
        }, 30 * 1000);
        // }, 10 * 1000); // 测试用
    }
};

export const queryUserInfo = async (...data: any) => {
    const result = await post('/api/engineFrame/select', ...data);
    return result;
};

export const saveWebLogs = async (data: any, config?: RequestSetting) => {
    const result = await post('/api/logWeb/insertAsyn', data, config);
    return result;
};

export async function saveErrorLogs(data: any) {
    const userInfo = getUserInfo();
    const result = {
        jobId: 0,
        // id: 0,
        detail: '',
        frame: 0,
        kind: '',
        logRankType: 3,
        msg: `操作人：${userInfo.username}\r\n
        测试窗口key：${window?.navigation?.currentEntry?.key}\r\n
        浏览器信息：${navigator.userAgent}`,
        paramInput: '',
        paramOutput: '',
        urlAddress: '',
        userId: userInfo.id,
        ...data,
    };

    saveWebLogs([result]);
}
