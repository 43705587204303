// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Text from 'antd/lib/typography/Text';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Select, { SelectValue } from 'antd/lib/select';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Input from 'antd/lib/input';

import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';

import consts from 'consts';
import SerialIdInput from '../../../common/input-serialid';
import { Attribute } from 'business/objects/label';
import { DimensionType } from 'reducers/interfaces';
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';

interface InputElementParameters {
    clientID: number;
    attrID: number;
    inputType: string;
    values: string[];
    ranges: string[];
    currentValue: string;
    onChange(value: string): void;
}
// 快捷标注属性值的起始数
const start = 1;
function renderInputElement(parameters: InputElementParameters): JSX.Element {
    const { t } = useTranslation();
    const { inputType, attrID, clientID, values, currentValue, ranges, onChange } = parameters;

    const renderCheckbox = (): JSX.Element => (
        <>
            <Text strong>{t('workspace.side.attr.checkbox')}:</Text>
            <div className='attribute-annotation-sidebar-attr-elem-wrapper'>
                <Checkbox
                    onChange={(event: CheckboxChangeEvent): void => onChange(event.target.checked ? 'true' : 'false')}
                    checked={currentValue === 'true'}
                />
            </div>
        </>
    );

    const renderSelect = (): JSX.Element => (
        <>
            <Text strong>{t('workspace.side.attr.select')}: </Text>
            <div className='attribute-annotation-sidebar-attr-elem-wrapper'>
                <Select
                    value={currentValue}
                    style={{ width: '80%' }}
                    onChange={(value: SelectValue) => onChange(value as string)}
                >
                    {values.map(
                        (value: string, index: number): JSX.Element => (
                            <Select.Option key={value} value={value}>
                                {value === consts.UNDEFINED_ATTRIBUTE_VALUE ? consts.NO_BREAK_SPACE : ranges[index]}
                            </Select.Option>
                        ),
                    )}
                </Select>
            </div>
        </>
    );

    const renderRadio = (): JSX.Element => (
        < >
            <Text strong>{t('workspace.side.attr.select')}: </Text>
            <div className='attribute-annotation-sidebar-attr-elem-wrapper'>
                <Radio.Group
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                        justifyItems: 'center'
                    }}
                    value={currentValue}
                    onChange={(event: RadioChangeEvent) => onChange(event.target.value)}
                >
                    {values.map(
                        (value: string, index: number): JSX.Element => (
                            <Radio style={{ display: 'block' }} key={value} value={value}>
                                {value === consts.UNDEFINED_ATTRIBUTE_VALUE ? consts.NO_BREAK_SPACE : ranges[index]}
                            </Radio>
                        ),
                    )}
                </Radio.Group>
            </div>
        </>
    );

    const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Tab', 'Shift', 'Control'].includes(event.key)) {
            event.preventDefault();
            const copyEvent = new KeyboardEvent('keydown', event as any);
            window.document.dispatchEvent(copyEvent);
        }
    };

    const renderText = (): JSX.Element => (
        <>
            {inputType === 'number' ? <Text strong>{t('workspace.side.attr.number')}: </Text> : <Text strong>{t('workspace.side.attr.text')}: </Text>}
            <div className='attribute-annotation-sidebar-attr-elem-wrapper'>
                <Input
                    autoFocus
                    key={`${clientID}:${attrID}`}
                    defaultValue={currentValue}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const { value } = event.target;
                        if (inputType === 'number') {
                            if (value !== '') {
                                const numberValue = +value;
                                if (!Number.isNaN(numberValue)) {
                                    onChange(`${numberValue}`);
                                }
                            }
                        } else {
                            onChange(value);
                        }
                    }}
                    onKeyDown={handleKeydown}
                />
            </div>
        </>
    );
    // const renderSerialId = (): JSX.Element => (
    //     <>
    //         {inputType === 'serialid' ? (
    //             <Text strong style={{ color: 'lightblue' }}>
    //                 Number(serialid):{' '}
    //             </Text>
    //         ) : (
    //             <Text strong>Text: </Text>
    //         )}
    //         <div className='attribute-annotation-sidebar-attr-elem-wrapper'>
    //             <SerialIdInput
    //                 autoFocus
    //                 key={`${clientID}:${attrID}`}
    //                 defaultValue={currentValue}
    //                 onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
    //                     const { value } = event.target;
    //                     onChange(value);
    //                 }}
    //             // onKeyDown={handleKeydown}
    //             />
    //         </div>
    //     </>
    // );

    let element = null;
    if (['select', 'radio', 'checkbox'].includes(inputType)) {
        // if (ranges?.length > 5) {
        //     element = renderSelect();
        // } else {
        //     element = renderRadio();
        //     // else if (inputType === 'select') {
        //     //     element = renderSelect();
        //     // } else if (inputType === 'radio') {
        //     //     element = renderRadio();
        //     // } else if (inputType === 'serialid') {
        //     //     element = renderSerialId();
        // }
        // if (ranges?.length > 5) {
        //     element = renderRadio();
        // } else {
        //     element = renderSelect();
        // }
        if (inputType === 'checkbox') {
            element = renderCheckbox();
        } else if (inputType === 'select') {
            element = renderRadio();
        } else if (inputType === 'radio') {
            element = renderRadio();
        }
    } else {
        element = renderText();
    }

    return <div className='cvat-attribute-annotation-sidebar-attr-editor'>{element}</div>;
}

interface ListParameters {
    inputType: string;
    values: string[];
    ranges: string[];
    onChange(value: string): void;
}

function renderList(parameters: ListParameters): JSX.Element | null {
    const { inputType, values, ranges, onChange } = parameters;
    const { t } = useTranslation();

    if (inputType === 'checkbox') {
        const sortedValues = ['true', 'false'];
        if (values[0].toLowerCase() !== 'true') {
            sortedValues.reverse();
            ranges.reverse();
        }

        const keyMap: KeyMap = {};
        const handlers: {
            [key: string]: (keyEvent?: KeyboardEvent) => void;
        } = {};

        sortedValues.forEach((value: string, index: number): void => {
            const key = `SET_${index + start}_VALUE`;
            keyMap[key] = {
                // name: `Set value "${value}"`,
                // description: `Change current value for the attribute to "${value}"`,
                name: t('hotKey.side.setValue', { value: values[index] }),
                description: t('hotKey.side.setValue_detail', { value: values[index] }),
                sequences: [`${index + start}`],
                action: 'keydown',
                applicable: [DimensionType.DIM_2D],
            };

            handlers[key] = (event: KeyboardEvent | undefined) => {
                if (event) {
                    event.preventDefault();
                }

                onChange(value);
            };
        });

        return (
            <div className='attribute-annotation-sidebar-attr-list-wrapper'>
                <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
                <div>
                    <Text strong>1:</Text>
                    <Text>{` ${ranges[0]}`}</Text>
                </div>
                <div>
                    <Text strong>2:</Text>
                    <Text>{` ${ranges[1]}`}</Text>
                </div>
            </div>
        );
    }

    if (inputType === 'radio' || inputType === 'select') {
        const keyMap: KeyMap = {};
        const handlers: {
            [key: string]: (keyEvent?: KeyboardEvent) => void;
        } = {};

        // const filteredValues = values.filter((value: string): boolean => value !== consts.UNDEFINED_ATTRIBUTE_VALUE);
        values.slice(0, 10).forEach((value: string, index: number): void => {
            if (value === consts.UNDEFINED_ATTRIBUTE_VALUE) {
                return;
            }
            const key = `SET_${index + start}_VALUE`;
            keyMap[key] = {
                // name: `Set value "${value}"`,
                // description: `Change current value for the attribute to "${value}"`,
                name: t('hotKey.side.setValue', { value: ranges[index] }),
                description: t('hotKey.side.setValue_detail', { value: ranges[index] }),
                sequences: [`${index + start}`],
                action: 'keydown',
                applicable: [DimensionType.DIM_2D]
            };

            handlers[key] = (event: KeyboardEvent | undefined) => {
                if (event) {
                    event.preventDefault();
                }

                onChange(value);
            };
        });

        return (
            <div className='attribute-annotation-sidebar-attr-list-wrapper'>
                <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
                {values.map(
                    (value: string, index: number): JSX.Element => {
                        if (value === consts.UNDEFINED_ATTRIBUTE_VALUE) {
                            return <></>;
                        }
                        return (
                            <div key={ranges[index]}>
                                <Text strong>{`${index + start}:`}</Text>
                                <Text>{` ${ranges[index]}`}</Text>
                            </div>
                        )
                    },
                )}
            </div>
        );
    }

    if (inputType === 'number') {
        return (
            <div className='attribute-annotation-sidebar-attr-list-wrapper'>
                <div>
                    <Text strong>{t('workspace.side.attr.nofind.number.from')}:</Text>
                    <Text>{` ${values[0]}`}</Text>
                </div>
                <div>
                    <Text strong>{t('workspace.side.attr.nofind.number.to')}:</Text>
                    <Text>{` ${values[1]}`}</Text>
                </div>
                <div>
                    <Text strong>{t('workspace.side.attr.nofind.number.step')}:</Text>
                    <Text>{` ${values[2]}`}</Text>
                </div>
            </div>
        );
    }

    return null;
}

interface Props {
    clientID: number;
    attribute: Attribute;
    currentValue: string;
    onChange(value: string): void;
}

function AttributeEditor(props: Props): JSX.Element {
    const { attribute, currentValue, onChange, clientID } = props;
    const { inputType, values, id: attrID, ranges } = attribute;

    return (
        <div className='attribute-annotations-sidebar-attribute-editor'>
            {renderList({ values, inputType, ranges, onChange })}
            <hr />
            {renderInputElement({
                clientID,
                attrID,
                inputType,
                currentValue,
                values,
                ranges,
                onChange,
            })}
        </div>
    );
}

export default React.memo(AttributeEditor);
