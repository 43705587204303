/*
 * @Author: swxy
 * @Date: 2022-12-12 17:51:45
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import {
    ViewsDOM,
    Issue,
    Mode,
    DrawData,
    ShapeProperties,
    GroupData,
    CameraDistortionParameter,
    Configuration,
    ImagePosition,
    ExportProjectType,
    SummarizeData,
    PcdParameter,
    CameraType,
    ViewType,
    MouseInteraction,
    CameraAction,
    SupportType,
    ObjectState,
    UpdateLanelineType,
    ShapeType,
    Pcd,
} from './src/interface';
import './i18n';
import pjson from '../package.json';
import Controller from './src/controller';
import Canvas3dView from './src/canvas3dView';

const Canvas3dVersion = pjson.version;

interface Canvas3dProps {
    html(): ViewsDOM;
    init(url: string, frame: number, type: SupportType): Promise<void>;
    setup(objectStates: ObjectState[], force: boolean): void;
    setupIssueRegions(issueRegions: Issue[]): void;
    isAbleToChangeFrame(): boolean;
    mode(): Mode;
    render(): void;
    keyControls(keys: KeyboardEvent): void;
    draw(drawData: DrawData): void;
    cancel(): void;
    birdEyeMode(enable: boolean): void;
    // reviewCanvas(enable: boolean): void;
    activate(clientID?: number | null, issueID?: number | null, attributeID?: number | null): void;
    // activateIssue(issueID: number | null): void;
    configureShapes(shapeProperties: ShapeProperties): void;
    fitCanvas(): void;
    fit(): void;
    group(groupData: GroupData): void;
    moveObject(moveData: { enabled: boolean }): void;
    getCameraParams(
        cameraCalibs: number[][],
        cameraToBumpers: number[][],
        cameraDistortionParameter: CameraDistortionParameter[],
    ): void;
    actionModel(isFineTuning: boolean): void;
    configure(configuration: Partial<Configuration>): void;
    postureToPosition(
        points: number[],
        cameraIndex: number,
        shapeType: ShapeType,
        resultType: '4' | '8',
    ): THREE.Vector2[];
    summarize(frameData: any, objectStates: any[]): Promise<SummarizeData[]>;
    setupPointCloudOrObjectsOrIssues(frame: number, pcd: Pcd, objects?: ObjectState[], issues?: Issue[]): void;
    bezier2Mode(enable: boolean): void;
    deletePoint(clientID: number): void;
    destroy(): void;
}

class Canvas3d implements Canvas3dProps {
    private readonly controller: Controller;
    private view: Canvas3dView;

    public constructor() {
        this.controller = new Controller();
        this.view = new Canvas3dView(this.controller);
    }

    public html(): ViewsDOM {
        return this.view.html();
    }

    public keyControls(keys: KeyboardEvent): void {
        this.view.keyControls(keys);
    }

    public render(): void {
        this.view.render();
    }

    public draw(drawData: DrawData): void {
        this.controller.draw(drawData);
    }

    public async init(url: string, frame: number = 0, type: SupportType = SupportType.pcd): Promise<void> {
        this.controller.init(url, frame, type);
    }

    public setup(objectStates: any[], force: boolean): void {
        this.controller.setup(objectStates, force);
    }

    public setupIssueRegions(issueRegions: Issue[]): void {
        this.controller.setupIssueRegions(issueRegions);
    }

    public mode(): Mode {
        return this.controller.mode;
    }

    public group(groupData: GroupData): void {
        this.controller.group(groupData);
    }

    public isAbleToChangeFrame(): boolean {
        return this.controller.isAbleToChangeFrame();
    }

    public cancel(): void {
        this.controller.cancel();
    }

    public birdEyeMode(enable: boolean): void {
        this.controller.birdEyeMode(enable);
    }

    public moveObject(moveData: { enabled: boolean }): void {
        this.controller.moveObject(moveData);
    }

    // public reviewCanvas(enable: boolean): void {
    //     this.controller.reviewCanvas(enable);
    // }

    public configureShapes(shapeProperties: ShapeProperties): void {
        this.controller.configureShapes(shapeProperties);
    }

    public activate(clientID: number | null, issueID?: number | null, attributeID?: number): void {
        this.controller.activate(clientID, issueID, attributeID);
    }

    // public activateIssue(issueID: number | null): void {
    //     this.controller.activateIssue(issueID);
    // }

    public fit(): void {
        this.controller.fit();
    }

    public fitCanvas(): void {
        this.controller.fit();
    }

    public actionModel(isFineTuning: boolean): void {
        this.controller.actionModel(isFineTuning);
    }

    public getCameraParams(
        cameraCalibs: number[][],
        cameraToBumpers: number[][],
        cameraDistortionParameter?: CameraDistortionParameter[],
        pcdParameter?: PcdParameter,
        needInvert?: boolean[],
    ): void {
        this.controller.getCameraParams(
            cameraCalibs,
            cameraToBumpers,
            cameraDistortionParameter,
            pcdParameter,
            needInvert,
        );
    }

    public updateLaneline(data: { type: UpdateLanelineType }) {
        this.controller.updateLaneline(data);
    }

    public configure(configuration: Partial<Configuration> = {}): void {
        this.controller.configure(configuration);
    }

    // 目标物位姿信息转换为2D像素坐标
    public postureToPosition(
        points: number[],
        cameraIndex: number,
        shapeType: ShapeType,
        resultType: '4' | '8' = '8',
    ): THREE.Vector2[] {
        return this.controller.postureToPosition(points, cameraIndex, shapeType, resultType);
    }

    // 总结数据
    public async summarize(frameData: any, objectStates: any[]): Promise<SummarizeData[]> {
        return this.controller.summarize(frameData, objectStates);
    }

    public setupPointCloudOrObjectsOrIssues(frame: number, data: Pcd, objects?: ObjectState[], issues?: Issue[]): void {
        this.controller.setupPointCloudOrObjectsOrIssues(frame, data, objects, issues);
    }

    public bezier2Mode(enable: boolean): void {
        this.controller.bezier2Mode(enable);
    }

    public deletePoint(clientID: number) {
        this.controller.deletePoint(clientID);
    }

    public destroy(): void {
        this.controller.destroy();
    }
}

export {
    Canvas3d,
    Canvas3dVersion,
    ViewType,
    MouseInteraction,
    ViewsDOM,
    CameraType,
    CameraAction,
    CameraDistortionParameter,
    PcdParameter,
};
