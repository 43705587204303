/*
 * @Author: swxy
 * @Date: 2024-07-22 14:04:07
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { JobStatus } from 'utils/ConstType';

/**
 * 根据题包状态重新排序
 * 打回的优先
 * @param jobs
 */
export const sortJobByJobStatus = (jobs: any[] = []) => {
    jobs.sort((a: any, b: any) => {
        if ([JobStatus.backOne, JobStatus.backTwo, JobStatus.backReceive].includes(b.jobStatus)) {
            return 1;
        }
        if ([JobStatus.backOne, JobStatus.backTwo, JobStatus.backReceive].includes(a.jobStatus)) {
            return -1;
        }
        return 0;
    });
};

/**
 * 根据题包状态决定展示的字体颜色
 * @param jobStatus 打回的会展示为红色
 * @returns
 */
export const getTextColorByJobStatus = (jobStatus: JobStatus) => {
    if ([JobStatus.backOne, JobStatus.backTwo, JobStatus.backReceive].includes(jobStatus)) {
        return 'red';
    }
    return undefined;
};

/**
 * 根据题包的打回次数决定行的背景颜色
 * @param times 大于3次回展示黄色
 * @returns
 */
export const getbackgroundColorByBackTimes = (times: number = 0, jobStatus: JobStatus) => {
    if (times > 3 && [JobStatus.backOne, JobStatus.backTwo, JobStatus.backReceive].includes(jobStatus)) {
        return '#7e7e01';
    }
    return undefined;
};
