/*
 * @Author: swxy
 * @Date: 2023-11-14 15:16:14
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { getSetting } from 'utils/storage';
import PCDLoader from './utils/PCDMaxLoader';
import { Box3, Euler, Float32BufferAttribute, Matrix4, Quaternion, Vector3 } from 'three';
import { PointCloudColorBy } from 'reducers/interfaces';
import { getAmygoStore } from 'amygo-store';
import AmygoPoseModel from './AmygoPoseModel';

// 大于100万点的时候会进行分割
const maxLength = 1000000;

// 分割时，单个点云最多30万个点
const singleMaxLength = 300000;

interface PcdProps {
    positions: number[][];
    colors?: number[][];
    name: string;
}

let data = 1;

class AmygoPcdModel {
    public readonly original: Blob;
    public readonly name: string = '';
    public count: number = 0; //总点数
    public positions: Float32BufferAttribute[] = [];
    public colors: Float32BufferAttribute[] = [];
    public pose?: AmygoPoseModel;

    constructor({ data, name = '' }: { data: Blob; name: string }) {
        this.original = data;
        this.name = name;
    }

    public static async blobToPcd(
        blob: Blob,
        name?: string,
        pointCloudColor?: PointCloudColorBy,
    ): Promise<{
        positions: number[][];
        colors: number[][];
        name?: string;
    }> {
        try {
            const url = URL.createObjectURL(blob);
            const { position, color, intensity } = await PCDLoader.load(url, maxLength, singleMaxLength);
            URL.revokeObjectURL(url);
            // console.log('结果：', result);
            // const setting =
            let newColor: number[][] = [];
            // console.log('这里可以获取到吗：', pointCloudColor);
            // debugger;
            // const setting = getSetting();
            if (pointCloudColor) {
                if (pointCloudColor === PointCloudColorBy.color) {
                    newColor = color;
                } else if (intensity.length && pointCloudColor === PointCloudColorBy.intensity) {
                    newColor = PCDLoader.intensityToColor(intensity);
                } else if (pointCloudColor === PointCloudColorBy.axisZ) {
                    newColor = PCDLoader.axisZToColor(position);
                }
            }
            // const positions: Float32BufferAttribute[] = [];
            // const colors: Float32BufferAttribute[] = [];
            // const length = result.position.length;

            // for (let index = 0; index < length; index++) {
            //     const poses = result.position.shift();
            //     if (poses && poses.length) {
            //         positions.push(new Float32BufferAttribute(poses, 3));
            //     }
            //     if (result.color) {
            //         const coles = result.color.shift();
            //         if (coles && coles.length) {
            //             colors.push(new Float32BufferAttribute(coles, 3));
            //         }
            //     }
            // }

            return {
                positions: position,
                colors: newColor,
                name,
            };
        } catch (error) {
            const newError = new Error(`解析pcd${name}错误`);
            newError.stack = (error as Error).stack;
            throw newError;
        }
    }

    public get size() {
        return this.original.size + this.count * 4;
    }

    public get isAnalyze() {
        return !!this.positions.length;
    }

    public import(positions: number[][], colors: number[][] = []) {
        this.positions = positions.map((pos) => new Float32BufferAttribute(pos, 3));
        if (colors.length) {
            this.colors = colors.map((color) => new Float32BufferAttribute(color, 3));
        }
        this.count = this.positions.reduce((previous, current) => previous + current.count, 0);
    }

    public containPointsFromPoints(points: number[]) {
        const position = new Vector3(points[0], points[1], points[2]);
        const quaternion = new Quaternion().setFromEuler(new Euler(points[3], points[4], points[5]));
        const scale = new Vector3(points[6], points[7], points[8]);
        const objectMatrix = new Matrix4().compose(position, quaternion, scale);

        return this.containPoints(objectMatrix);
    }

    public containPoints(matrix: Matrix4): Vector3[] {
        const box: Box3 = new Box3().setFromCenterAndSize(new Vector3(0, 0, 0), new Vector3(1, 1, 1));
        const invert = matrix.clone().invert();
        const length = this.positions.length;

        const result: Vector3[] = [];

        for (let num = 0; num < length; num += 1) {
            const positions = this.positions[num];
            const { count } = positions;

            for (let index = 0; index < count; index += 1) {
                const pos = new Vector3().fromBufferAttribute(positions, index);
                const { x, y, z } = pos;

                if (Number.isNaN(x) || Number.isNaN(y) || Number.isNaN(z)) {
                    // 跳过无效的点
                    continue;
                }
                let isInBox = false;

                if (invert) {
                    isInBox = box.containsPoint(pos.clone().applyMatrix4(invert));
                } else if (box.containsPoint(pos)) {
                    isInBox = true;
                }

                if (isInBox) {
                    result.push(pos);
                }
            }
        }

        return result;
    }

    public containPointsBox(box: Box3, result: Vector3[] = [], matrix?: Matrix4) {
        const count = this.positions.length;
        for (let num = 0; num < count; num++) {
            const pos = this.positions[num];
            const length = pos.count;
            for (let index = 0; index < length; index++) {
                const vector = new Vector3().fromBufferAttribute(pos, index);
                const { x, y, z } = vector;

                if (Number.isNaN(x) || Number.isNaN(y) || Number.isNaN(z)) {
                    // 跳过无效的点
                    continue;
                }

                let isInBox = false;

                if (matrix) {
                    isInBox = box.containsPoint(vector.clone().applyMatrix4(matrix));
                } else if (box.containsPoint(vector)) {
                    isInBox = true;
                }

                if (isInBox) {
                    result.push(vector);
                }
            }
        }

        return result;
    }

    /**
     * 清除缓存的解析结果
     */
    public clear() {
        this.positions.splice(0, this.positions.length);
        this.colors.splice(0, this.colors.length);
    }

    /**
     * 销毁
     */
    public destroy() {
        this.positions.splice(0, this.positions.length);
        this.colors.splice(0, this.colors.length);
    }
}

export default AmygoPcdModel;
