
import { Checkbox, CheckboxOptionType, Radio, Select } from 'antd';
import React, { FC, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState, ObjectType } from 'reducers/interfaces';
// import { ControlType } from 'utils/ConstType';
import './styles.scss';
import { LabelObjectType } from 'utils/ConstType';
import { createAnnotationsAsync, updateAnnotationsAsync } from 'actions/annotation-actions';
import getCore from 'cvat-core-wrapper';
import ObjectState from 'business/objects/objectState';

// const cvat = getCore();

interface ImageAttributeComponentProps {
    type: 'checkbox' | 'radio' | 'select';
    attrValues: string[]; // 属性值列表
    showValues?: string[]; // 属性名称列表- 没有时取值列表
    readonly?: boolean;
    key?: number;
    value?: string | string[];
    labelId: number;
    changeAttribute?(value: string | string[], labelId: number): Promise<void>;
}
const ImageAttributeComponent: FC<ImageAttributeComponentProps> = ({
    type,
    attrValues,
    showValues,
    readonly,
    value,
    labelId,
    changeAttribute,
}) => {
    let showNames = showValues || attrValues;

    // console.log('值：', value);

    if (['radio', 'select', 'checkbox'].includes(type)) {
        const options = showNames.map((name, index): CheckboxOptionType => {
            return {
                label: showNames[index],
                value: attrValues[index],
            }
        })
        return showNames.length < 5
            ? <Radio.Group
                size='small'
                options={options}
                disabled={readonly}
                value={value}
                onChange={(e) => changeAttribute?.(e.target.value, labelId)}
            />
            : <Select
                className='aatp_attribute_Item_select'
                size='small'
                options={options}
                disabled={readonly}
                value={value}
                onChange={(values) => changeAttribute?.(values, labelId)}
            />
    }
    if (type === 'checkbox') {
        const options = showNames.map((name, index): CheckboxOptionType => {
            return {
                label: showNames[index],
                value: attrValues[index],
            }
        })
        return <Checkbox.Group
            options={options}
            disabled={readonly}
            value={value as string[]}
            onChange={(values) => changeAttribute?.(values as string[], labelId)}
        />
    }
    return <div>
        暂不支持该格式
    </div>
}



interface ImageAttributeItemProps {
    label: any;
    attrs?: any;
    object?: any;
}
const ImageAttributeItem: FC<ImageAttributeItemProps> = ({ label, object = { attributes: {} } }) => {
    const [attribute] = label.attributes;
    const attr = object.attributes || {};
    const dispatch = useDispatch();
    // console.log('attribute:', attrs);

    // console.log('更新了：', object);
    const changeAttribute = async (value: string) => {
        object.attributes = { [attribute.id]: value };
        // console.log('变更：', value);
        dispatch(updateAnnotationsAsync([object]));
    }

    return <div className='aatp_attribute_Item'>
        <div>{label.title}:</div>
        <ImageAttributeComponent
            type={attribute.inputType}
            attrValues={attribute.values}
            showValues={attribute.ranges}
            value={attr[attribute.id]}
            labelId={label.id}
            changeAttribute={changeAttribute}
        />
    </div>
}


interface AttributeAnnotationImageProps {
    // tagState: any;
}
const AttributeAnnotationImage: FC<AttributeAnnotationImageProps> = () => {
    const { jobInstance, labels, tags, frame, cameraName } = useSelector((state: CombinedState) => ({
        jobInstance: state.annotation.job.instance,
        labels: state.annotation.job.labels.filter((item: any) => item.labelObjectType === LabelObjectType.image),
        frame: state.annotation.player.frame.number,
        tags: state.annotation.annotations.states.filter((item: any) => item.objectType === ObjectType.TAG),
        cameraName: state.annotation.annotations.cameraName,
    }));
    const dispatch = useDispatch();

    // const [imageLabels, setImageLabels] = useState<any[]>([]);
    // const [tagByLabelId, setTagByLabelId] = useState<any>({});
    const [creating, setCreating] = useState<{ [frame: number]: boolean }>({});

    const createTags = async (frameIndex: number, labels: any[]) => {
        setCreating((create) => ({ ...create, [frameIndex]: true }));
        const objectStates = labels.map(label => {
            const newTag = {
                attributes: label.attributes.reduce((previous: any[], current: any) => {
                    previous[current.id] = current.defaultValue;
                    return previous;
                }, {}),
                objectType: ObjectType.TAG,
                label,
                frame: frameIndex,
            }
            const objectState = new ObjectState(newTag);
            return objectState
        });
        await dispatch(createAnnotationsAsync(jobInstance, frameIndex, objectStates))
        setCreating((create) => ({ ...create, [frameIndex]: false }));
    }

    const getObject = (label: any) => {
        if (tags && tags.length) {
            return tags.find(tag => tag.label.id === label.id);
        }
        return undefined;
    }

    // 每个图像，在每帧中，必有一个图像标注。没有的添加
    useEffect(() => {
        // tags是与frame一起更改，当frame更改时，tags已经是当前frame的初始tags列表。
        // 如果当前tags中，没有图像标注，则创建一个

        // 需要添加的图像标注
        const needAddLabels = labels.filter((label: any) => {
            if (!tags.find((tag: any) => tag.label.id === label.id && tag.cameraName === cameraName)) {
                return true;
            }
            return false;
        })

        // console.log('现有的tags:', tags);
        // console.log('需要新增的labes:', needAddLabels);
        if (!creating[frame] && needAddLabels.length) {
            createTags(frame, needAddLabels)
        }
    }, [frame, cameraName])

    if (labels && labels.length) {
        return <>{
            labels.map((imageLabel => imageLabel && imageLabel.attributes
                ? <ImageAttributeItem label={imageLabel} object={getObject(imageLabel)} />
                : <></>
            ))
        }</>
    }

    return <></>
}

export default memo(AttributeAnnotationImage);