/*
 * @Author: swxy
 * @Date: 2022-08-15 14:32:34
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {

	'project.project': 'Project',
	'project.no_task': 'No task',
	'project.back_project_list': 'Back to project list',
	'project.task': 'Task',

	'project.last_update_time': 'Last update time',
	'project.create_by': 'Created by',
	'project.pending': 'Pending',
	'project.in_progress': 'In progress',
	'project.completed': 'Completed',
	'project.open': 'Open',
	'project.back_project': 'Back to project',
	'project.back_task': 'Back to tasks',

	'project.job': 'Job',
	'project.jobs': 'Job list',
	'project.frames': '# of frames',
	'project.job_status': "Job's status",
	'project.start_date': 'Start date', 'project.duration': 'Duration',
	'project.copy': 'Copy',
	'project.copy_success': 'Copied to clipboard!',

	'project.overlap_size': 'Overlap size',
	'project.segment_size': 'Total number',
	'project.image_quality': 'Image quality',

	'task.this': 'Here',

	'task.lastFrame': 'You recently viewed frame {{parsedFrame}}',
	'task.lastFrameHelp_pre': 'You can click',
	'task.lastFrameHelp_last': 'to continue at frame {{parsedFrame}}.',
	'task.noLabelHelp': 'No label information available.',
	'task.noLabel': 'Please ask the project administrator if labels have been added to the project.',

	'task.nofoundJob': 'Sorry, this job was not found.',
	'task.pleaseClick': 'Please click',
	'task.backHome': 'to go back to home page.',

	'task.uploadImages': 'Upload images',
	'task.noListData': "There is no data currently. You can ",
	'task.add': "add ",
	'task.one': "one ",
	'task.updateTime': 'Update time',

	'task.selectAutoTypeHelp': 'Please select at least one automation type!',
	'task.seletImageHelp': 'Please select an image first!',
	'task.uploading': 'Uploading, please wait…',
	'task.autoType': 'Automation type:',
	'task.uploadTip': 'Drag and drop images or click here.',
	'task.uploadSupTypeHelp': 'Trail version currently only supports .jpg, .jpeg, .png, .bmp. format for pictures',

	'job.desInfo': 'Image resolution: {{width}} X {{height}}, total frames: {{total}}.',
	'job.backPrevPage': 'Back to previous page.',
	'job.backHome': 'Back to homepage.',
	'job.updateTime': 'Update time',

	'project.myProject': 'My project',

	//#region 2023-05-04 待校验
	'task.checkResolutionTip': 'Check Image Resolution...',
	'task.uploadImagesTip': 'Uploading, please be patient and wait...',
	'task.autoAnnotationTip': 'Automated annotation execution in progress, please be patient...',
	'task.sizeOver.title': 'The number of images exceeds the limit',
	'task.sizeOver.content': `Hello, the experience version only supports data calculation up to 10 tons. If you have any data processing needs, please contact us.

Email: sales@idatalab.cc`,
	'task.countOver.title': 'Number of times exceeded',
	'task.countOver.content': `Hello, the number of experiences on that day has exceeded the limit. If you have any additional requirements, please contact us.

Email: sales@idatalab.cc`,
	'job.deleteSuccess': 'You have deleted {{name}} and the project names will be rearranged!',
	//#endregion
};
