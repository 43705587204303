/*
 * @Author: swxy
 * @Date: 2023-11-14 15:16:14
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { Euler, Matrix3, Matrix4, Quaternion, Vector3 } from 'three';
import { api_downFramePose, api_downloadFrameLidarToImu } from 'service/job/frame';

class AmygoPoseModel {
    /**
     * 位置信息
     */
    public position: Vector3;
    /**
     * 旋转四元数
     */
    public orientation: Quaternion;

    public timestamp: number;

    public lidar2imu: Matrix4;

    constructor(poseInfo: any = {}, lidar2imu?: Matrix4) {
        const { pose } = poseInfo;
        this.lidar2imu = lidar2imu instanceof Matrix4 ? lidar2imu : new Matrix4();
        if (poseInfo) {
            this.timestamp = poseInfo.timestamp;
            this.position = new Vector3(pose.position.x, pose.position.y, pose.position.z);
            // this.orientation = new Quaternion()
            //     .setFromEuler(new Euler(0, 0, Math.PI / 2))
            //     .multiply(
            //         new Quaternion(pose.orientation.qx, pose.orientation.qy, pose.orientation.qz, pose.orientation.qw),
            //     );

            // this.orientation = new Quaternion();
            // this.orientation = new Quaternion().setFromEuler(
            //     new Euler(pose.euler_angles.x, pose.euler_angles.y, pose.euler_angles.z),
            // );
            this.orientation = new Quaternion(
                pose.orientation.qx,
                pose.orientation.qy,
                pose.orientation.qz,
                pose.orientation.qw,
            );
            // console.log('旋转信息：', this.position);
            // const { euler_angles } = pose;
            // this.orientation = new Quaternion().setFromEuler(new Euler(euler_angles.x, euler_angles.y, euler_angles.z));
        } else {
            this.timestamp = 0;
            this.position = new Vector3();
            this.orientation = new Quaternion();
        }
    }

    public static async dispose(jobId: number, frameId: number) {
        const result = await AmygoPoseModel.download(jobId);
        const lidar2imu = await AmygoPoseModel.downloadLidarToImu(frameId);

        if (result) {
            return {
                posees: result,
                lidar2imu,
            };
        }
        return {
            posees: [],
            lidar2imu: undefined,
        };
    }

    public static async downloadLidarToImu(frameId: number): Promise<Matrix4 | undefined> {
        try {
            const resultStr = await api_downloadFrameLidarToImu({ frameId, name: 'lidar2imu' });
            /**
             * 使用正则表达式查找出其中的数值。默认只会匹配到7个数值
             * 请注意：客户如果更改格式，在其中插入了别的数值，以下内容就会出错。
             */
            const regex = /-?\d+(\.\d+)?/g;
            const matches = resultStr.match(regex);

            if (matches?.length) {
                const translation = new Vector3(+matches[0], +matches[1], +matches[2]);
                const rotation = new Quaternion(+matches[3], +matches[4], +matches[5], +matches[6]);
                return new Matrix4().compose(translation, rotation, new Vector3(1, 1, 1));
            }
        } catch (error) {
            throw new Error(`下载lidarToImu信息错误！-${(error as Error).stack}`);
        }
    }

    public static async download(jobId: number) {
        try {
            const result = await api_downFramePose({ jobId: jobId });
            return result;
        } catch (error) {
            throw new Error(`下载pose信息错误！-${(error as Error).stack}`);
        }
    }

    public static match(poses: any[], time?: number) {
        try {
            if (time) {
                let timeStr = `${time}`;
                let timestamp = time;
                let pose = poses[0];

                if (!timeStr.includes('.')) {
                    // poses中时间戳和帧文件的时间戳，格式有所不同，强行转换成位数一致的。
                    const index = `${pose.timestamp}`.indexOf('.');
                    timestamp = +`${timeStr.slice(0, index)}.${timeStr.slice(index)}`;
                }
                let difference = Number.MAX_SAFE_INTEGER;

                poses.forEach((pos) => {
                    const dis = Math.abs(timestamp - pos.timestamp);
                    if (dis < difference) {
                        difference = dis;
                        pose = pos;
                    }
                });

                return pose;
            }
        } catch (error) {
            throw new Error(`下载pose信息错误！-${(error as Error).stack}`);
        }
    }

    public getMatrix(newPose: AmygoPoseModel) {
        return new Matrix4().multiplyMatrices(this.matrix().invert(), newPose.matrix());
    }

    public matrix() {
        return new Matrix4().compose(this.position, this.orientation, new Vector3(1, 1, 1)).multiply(this.lidar2imu);
    }

    public invert() {
        return this.matrix().invert();
    }

    public toString() {
        return `timestamp:${this.timestamp}, position: ${JSON.stringify(this.position)}`;
    }

    /**
     * 清除缓存的解析结果
     */
    public clear() {}

    /**
     * 销毁
     */
    public destroy() {}
}

export default AmygoPoseModel;
