/*
 * @Author: swxy
 * @Date: 2023-11-28 10:48:35
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { post } from 'utils/request';

export const api_cancelCheckJob = async (data: { jobId: number }) => {
    try {
        const result = await post('/business/company/dazhuo/cancelCheckJob', data, { responseOriginalData: true });
        return result;
    } catch (error) {
        throw error;
    }
};

export const api_checkFinish = async (data: { jobId: number }) => {
    try {
        const result = await post('/business/company/dazhuo/checkFinish', data, { responseOriginalData: true });
        return result;
    } catch (error) {
        throw error;
    }
};

export const api_sendCheckJob = async (data: { jobId: number; data: string }) => {
    try {
        const result = await post('/business/company/dazhuo/sendCheckJob', data, { responseOriginalData: true });
        return result;
    } catch (error) {
        throw error;
    }
};

// export const api_updateJob = async (data: { jobId: number }) => {
//     try {
//         const result = await post('/api/engineJob/updateEvenNull', data);
//         return result;
//     } catch (error) {
//         throw error;
//     }
// };
