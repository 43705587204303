/*
 * @Author: swxy
 * @Date: 2023-05-04 15:02:10
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
        //#region 2023-05-04 待校验
        'userNotice': `
        I. General Provisions

        1. Please read and understand all rights and restrictions stipulated in this "User Notice" (hereinafter referred to as the "Notice").

        2. Unless otherwise specified, any new functions or services launched by the idatalab platform shall be unconditionally subject to this Notice.

        3. The idatalab platform reserves the right to modify the terms of this Notice at any time and without prior notice. The revised terms shall take effect immediately, and users shall pay attention to and abide by them when using the services. If you do not agree with the modifications to this agreement, you should stop using or accessing this platform. If you choose to continue to use this platform after the modifications to this Notice, you shall be deemed to have accepted all the new or modified content.



        II. User Registration and Privacy Statement

        1. By clicking the "login" button during the registration process, the user fully accepts all the terms and conditions under this notice.

        2. Users should ensure the legality and compliance of the uploaded data during the use of the platform's services. If any problems arise due to the uploaded data, the user shall bear full responsibility for the consequences, and idatalab platform shall not be liable for any responsibility.

        3. When you register and use the idatalab platform, the platform will prompt, collect, and store your personal information and data. The platform promises not to disclose or provide user information and data to third parties.



        III. Service Content

        1. idatalab platform provides free services to users, and the traffic fees generated during the process of uploading data or using this software are borne by users themselves.

        2. Unless otherwise specified in this Service Notice, any new products, features, or services introduced by the platform are subject to the regulations of this Service Notice.



        IV. Service Change, Interruption, or Termination

        1. idatalab platform has the right to conduct system maintenance or repair at any time, with or without notice.

        2. Due to the special nature of network services (including but not limited to the stability of servers, the existence of malicious network attacks, and other situations that idatalab platform cannot control), the platform has the right to interrupt or terminate part or all of the services at any time without further notice.



        V. User Rules

        1. Users should comply with all the provisions of this platform's Service Notice.

        2. Users bear full legal responsibility for all activities and events that occur through their accounts.

        3. Users shall not engage in any illegal activities or use the platform system services for improper activities.

        4. Users shall not copy, reproduce, sell, resell or use any part of the platform service or the content obtained through the platform service for any other commercial purposes.

        5、idatalab has the right to automatically delete the data uploaded by users and their automated results without seeking the consent of individual users.



        VI. Disclaimer

        1. Due to the special nature of using network services, we do not guarantee that network services will necessarily meet user requirements, nor do we guarantee that network services will not be interrupted, timely, secure, or accurate.

        2. The platform shall not be liable for any losses arising from telecommunications system or Internet network failures, computer failures, computer system problems, or any other force majeure reasons, but we will try our best to minimize the losses and impacts on users.



        VII. The final interpretation of this notice belongs to idatalab platform.
`
        //#endregion
}