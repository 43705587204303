/*
 * @Author: swxy
 * @Date: 2022-08-17 18:35:05
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {

     'workspace.track': 'Kontinuierliche Frames',
     'workspace.shape': 'Normaler Rahmen',

     'workspace.top.back': 'Zur vorherigen Seite gehen',
     'workspace.top.save': 'Aktuelle Änderungen speichern',

     'workspace.top.undo': 'Vorherigen Vorgang abbrechen',
     'workspace.top.redo': 'Zum nächsten Vorgang gehen',

     'workspace.top.go_back': 'Zurück',
     'workspace.top.go_back_filter': 'Vorheriger bedingter Frame',
     'workspace.top.go_back_empty': 'Letztes leeres Bild',
     'workspace.top.go_next': 'Weiter',
     'workspace.top.go_next_filter': 'Nächster bedingter Rahmen',
     'workspace.top.go_next_empty': 'Nächster leerer Rahmen',

     'workspace.top.frist_frame': 'Erster Frame',
     'workspace.top.prve_step': 'Vorheriger Abschnitt',
     'workspace.top.play': 'Spielen',
     'workspace.top.pause': 'Pause',

     'workspace.top.next_step': 'Nächster Abschnitt',
     'workspace.top.last_frame': 'Letztes Bild',

     'workspace.top.change_frame_input_tip': 'Zum Fokussieren {{hot}} drücken',
     'workspace.top.panle_m': 'Panel ein-/ausblenden',
     'workspace.top.attribute_m': 'Attribute im Anmerkungsbildschirm anzeigen/ausblenden',
     'workspace.top.active_move': 'Maus aktives Objekt',
     'workspace.top.active_click': 'Mausklick um das Objekt zu aktivieren',
     'workspace.top.fullscreen_exit': 'Vollbild beenden',
     'workspace.top.fullscreen': 'Vollbild',
     'workspace.top.annotation_info': 'Anmerkungsinformationen',
     'workspace.top.annotation_object_filter': 'Annotationsobjekte filtern',

     'workspace.top.drop.unsavedata_tip': 'Aktuelle Arbeit enthält noch nicht gespeicherte Operationen',
     'workspace.top.drop.save_tip': 'Möchten Sie speichern, bevor Sie fortfahren?',
     'workspace.top.drop.save': 'Speichern',
     'workspace.top.drop.unsave': 'Nicht speichern',

     'workspace.top.drop.change_workspace_tip': 'Aktuellen Arbeitsstatus ändern',
     'workspace.top.drop.change_workspace_content_tip': 'Der aktuelle Arbeitsstatus wird auf "Abgeschlossen" geändert, fortfahren?',
     'workspace.top.drop.change_workspace_continue': 'Weiter',
     'workspace.top.drop.change_workspace_cancle': 'Abbrechen',

     'workspace.top.drop.showAllProjection': 'Vollständige Projektionsanzeige',
     'workspace.top.drop.showAllProjection_tip': 'Zeige die Projektion des markierten Objekts auf dem Bild',
     'workspace.top.drop.panel_model': 'Panel-Anzeigemodus',
     'workspace.top.drop.change_panel_model': 'Anzeigemodus der Bedienfeldliste wechseln',
     'workspace.top.drop.panel_left': 'Linker Bereich',
     'workspace.top.drop.panel_show_on_left': 'Die Panelliste wird links angezeigt',
     'workspace.top.drop.change_serialid': 'ID ändern',
     'workspace.top.drop.panel_sort': 'Panelliste sortieren',
     'workspace.top.drop.change_workspace': 'Arbeitsbereich ändern',
     'workspace.top.drop.submit': 'Senden',
     'workspace.top.drop.submit_title': 'Senden',

     'workspace.top.drop.workspace_standard3d': 'Standard (3D)',
     'workspace.top.drop.workspace_standard': 'Standard',
     'workspace.top.drop.workspace_attribute_annotation': 'Attribut',
     'workspace.top.drop.workspace_tag_annotation': 'Tag <Tag>',
     'workspace.top.drop.workspace_review': 'Überprüfung',

     'workspace.top.modal.sort': 'Sortieren',
     'workspace.top.modal.sort_ID_descent': 'Objekt-ID - absteigend',
     'workspace.top.modal.sort_ID_ascent': 'Objekt-ID - Aufstieg',
     'workspace.top.modal.sort_update_time': 'Aktualisierungszeit',

     'workspace.top.modal.submit_permission': 'Berechtigung',
     'workspace.top.modal.submit_permission_sub': 'Möglicherweise haben Sie keine Berechtigung, diesen Vorgang auszuführen, wenn Sie Fragen haben, können Sie den Projektadministrator fragen! ',
     'workspace.top.modal.submit_permission_close': 'Schließen',

     'workspace.top.modal.submit_review_success': 'Die Überprüfung war erfolgreich! ',
     'workspace.top.modal.submit_review_back_reason_w': 'Bitte geben Sie den Grund für die Rücksendung ein',
     'workspace.top.modal.submit_status_submit': 'Senden',
     'workspace.top.modal.submit_status_frist_review': 'Erster Test bestanden',
     'workspace.top.modal.submit_status_second_review': 'Zweite Überprüfung bestanden',
     'workspace.top.modal.submit_status_acceptance': 'Annahme bestanden',
     'workspace.top.modal.submit_status_review': 'Überprüfung',

     'workspace.top.modal.submit_loading_tip': 'Daten werden generiert, bitte schließen Sie die Seite nicht...',
     'workspace.top.modal.submit_content': 'Dieses Fragenpaket? ',

     'workspace.top.modal.submit_sorce_very_bad': 'Sehr schlecht',
     'workspace.top.modal.submit_sorce_bad': 'Schlecht',
     'workspace.top.modal.submit_sorce_authorized': 'Qualifiziert',
     'workspace.top.modal.submit_sorce_good': 'sehr gut',
     'workspace.top.modal.submit_sorce_very_good': 'sehr gut',

     'workspace.top.modal.submit_submit': 'Senden',
     'workspace.top.modal.submit_pass': 'Bestehen',
     'workspace.top.modal.submit_back_reason': 'Bitte geben Sie den Grund für die Rücksendung an! ',
     'workspace.top.modal.submit_back': 'Zurück',
     'workspace.top.modal.submit_close': 'Schließen',

     'workspace.content.view.top': 'Draufsicht',
     'workspace.content.view.side': 'Seitenansicht',
     'workspace.content.view.front': 'Frontperspektive',

     // 'workspace.content.view.object_info': 'Länge: {{length}}, Breite: {{width}}, Höhe: {{height}}',

     'workspace.content.view.object_lenght': 'Länge:',
     'workspace.content.view.object_width': 'Breite:',
     'workspace.content.view.object_height': 'Hoch:',

     'workspace.content.view.object_cloud_point': 'Punktwolkennummer:',

     'workspace.content.image.only_show_projection_tip': 'Nur die Projektion des ausgewählten Objekts anzeigen',
     'workspace.content.image.only_show_select': 'Ausgewählt',

     'workspace.side.controls.cursor': 'Cursor',
     'workspace.side.controls.parent_sub_delete': 'Nach der Auswahl gibt es Objekte, die Eltern und Kindern zugeordnet sind. Wenn das Elternobjekt gelöscht wird, werden auch die Kindelemente zusammen gelöscht. ',

     'workspace.side.controls.max_points': 'Maximale Punktzahl',
     'workspace.side.controls.draw_cuboid_model_tip': 'Quader-Generierungsmodus',
     'workspace.side.controls.draw_cuboid_model_from_rectangle': 'Rechteckerweiterung',
     'workspace.side.controls.draw_cuboid_model_4_points': '4 Punkte',

     'workspace.side.controls.draw_continue_tip': 'Nach Abschluss einer Notiz im aktuellen Modus bleiben und mit der Notiz fortfahren',
     'workspace.side.controls.draw_continue': 'Fortlaufende Anmerkung',



     'workspace.side.controls.cuboid_tip': 'Drücken, um in den Quader-Zeichnungsmodus zu wechseln. Wiederholungsvorgang abbrechen',
     'workspace.side.controls.cuboid_3d_tip': '3D-Box zeichnen',

     'workspace.side.controls.ellipse_tip': 'Drücken, um in den Kreiszeichnungsmodus zu wechseln',
     'workspace.side.controls.points_tip': 'Drücken, um in den Punktzeichnungsmodus zu wechseln. Wenn die Menge nicht eingestellt ist, klicken Sie erneut auf OK. ',
     'workspace.side.controls.polygon_tip': 'Drücken, um in den Polygon-Zeichnungsmodus zu wechseln. Wenn die Menge nicht eingestellt ist, klicken Sie erneut auf OK. ',
     'workspace.side.controls.polyline_tip': 'Drücken, um in den Strichzeichnungsmodus zu wechseln. Wenn die Menge nicht eingestellt ist, klicken Sie erneut auf OK. ',
     'workspace.side.controls.rectangle_tip': 'Drücken Sie , um in den Zeichenmodus für Rechtecke zu wechseln, und drücken Sie die Tastenkombination "a", um direkt in den Zeichenmodus für Rechtecke zu wechseln. Wiederholungsvorgang abbrechen',
     'workspace.side.controls.fit_tip': 'Das Anzeigebild an die Fenstergröße anpassen [Doppelklick mit der Maus]',
     'workspace.side.controls.group_tip': 'Wählen Sie eine Gruppe aus und drücken Sie {{key}}, um die Gruppe abzubrechen',
     'workspace.side.controls.merge_tip': 'Normale Rahmen/kontinuierliche Rahmen zu einem durchgehenden Rahmen zusammenführen',
     'workspace.side.controls.split_tip': 'Ausgewählten fortlaufenden Rahmen und nachfolgende Frames in einen neuen fortlaufenden Rahmen aufteilen',
     'workspace.side.controls.add_v_line': 'Unter Verwendung der aktuellen Standardbeschriftung ein sekundäres Objekt hinzufügen - vertikale Trennlinie',
     'workspace.side.controls.add_h_line': 'Unter Verwendung der aktuellen Standardbeschriftung eine objekthorizontale Trennlinie der zweiten Ebene hinzufügen',
     'workspace.side.controls.move_tip': 'Bild verschieben',
     'workspace.side.controls.birdEye': 'Halten Sie in der Vogelperspektive die Strg-Taste gedrückt, drücken Sie die linke Maustaste, ziehen Sie ein Kästchen, um einen Quader zu zeichnen',
     'workspace.side.controls.resize_interest_tip': 'Wählen Sie einen Bereich zur Größenänderung aus',
     'workspace.side.controls.rotate_anticlock_tip': 'Bild gegen den Uhrzeigersinn drehen',
     'workspace.side.controls.rotate_clock_tip': 'Bild im Uhrzeigersinn drehen',
     'workspace.side.controls.fine_tuning': 'Feinabstimmung',

     'workspace.side.controls.create_issue': 'Problem erstellen',

     // 'workspace.side.controls.track_model_tip': 'Wechsle zwischen den Modi zum Erstellen von <normalen Anmerkungsobjekten> und <kontinuierlichen Rahmenanmerkungsobjekten>',
     'workspace.side.controls.track_model_tip': 'Spitzentyp wechseln',
     'workspace.side.controls.track_model': 'Kontinuierliche Rahmen',
     'workspace.side.controls.autoFit_tip': 'Drehung automatisch berechnen und die Box an die Punktwolke anpassen',
     'workspace.side.controls.autoFit': 'Automatisch anpassen',

     'workspace.side.issue.defualt_label_msg': 'Standardbezeichnung geändert zu:',
     'workspace.side.issue.delete_issue_pop': 'Ob der Kommentar gelöscht werden soll',
     'workspace.side.issue.delete_delete': 'Löschen',
     'workspace.side.issue.delete_cancel': 'Abbrechen',
     'workspace.side.issue.find_ previous_frame_issues': 'Vorherige Frames mit Kommentaren finden',
     'workspace.side.issue.find_next_frame_issues': 'Nächstes Bild mit Kommentaren suchen',
     'workspace.side.issue.show/hide_all_issue': 'Alle Probleme anzeigen/ausblenden',
     'workspace.side.issue.hide_all_resolved_issue': 'Alle gelösten Probleme ausblenden',
     'workspace.side.issue.resolved': 'Gelöst',
     'workspace.side.issue.open': 'Offen',

     'workspace.side.object.show_detail_att': 'Zeige die Attribute des aktuellen Objekts',
     'workspace.side.object.track_simple': 'verbinden',
     'workspace.side.object.lock': 'Aktuelles Objekt sperren',
     'workspace.side.object.occluded': 'Ob das aktuelle Objekt verdeckt ist',
     'workspace.side.object.pinned': 'Pin the position of the current object',
     'workspace.side.object.hidden': 'Aktuelles Objekt ausblenden',
     'workspace.side.object.outside': 'Das aktuelle Objekt befindet sich außerhalb des Bildschirms',
     'workspace.side.object.keyframe': 'Keyframe des aktuellen Objekts setzen/abbrechen',

     'workspace.side.object.label_type': 'Etikettentyp',
     'workspace.side.object.parent_id': 'Übergeordnete Objekt-ID',
     'workspace.side.object.cancel_relation': 'Verknüpfung aufheben',
     'workspace.side.object.cancel_relation_tip': 'Aktuelle Beziehung aufheben',

     'workspace.side.object.context.create_url': 'Aktuellen Objektlink erstellen',
     'workspace.side.object.context.right_context_panel': 'Rechtsklick-Eigenschaftenfenster anzeigen',
     'workspace.side.object.context.copy': 'Kopieren',
     'workspace.side.object.context.propagate': 'Multi-Frame-Replikation',
     'workspace.side.object.context.changeStartFrame': 'Startframe ändern',
     'workspace.side.object.context.switch': 'Richtung wechseln',
     'workspace.side.object.context.reset': 'Perspektive zurücksetzen',
     'workspace.side.object.context.background': 'Hintergrund',
     'workspace.side.object.context.foreground': 'Vordergrund',
     'workspace.side.object.context.changeColor': 'Ändere die Farbe von {{name}}',
     'workspace.side.object.context.object_lock_tip': 'Das Objekt ist bereits gesperrt',
     'workspace.side.object.context.delete_object_lock_tip': 'Mit dem Löschen fortfahren?',
     'workspace.side.object.context.delete': 'Löschen',

     'workspace.side.top.lock_all': 'Alle aktuellen Anmerkungsobjekte sperren',
     'workspace.side.top.hide_all_unlock': 'Alle derzeit entsperrten Anmerkungsobjekte ausblenden',
     'workspace.side.top.expand_collapse_all': 'Die Eigenschaften aller Anmerkungsobjekte in der Liste erweitern/reduzieren',
     'workspace.side.top.force_active': 'Nur aktuelle anzeigen',

     'workspace.side.appearance.color': 'Farbe',

     'workspace.side.appearance.instance': 'Objekt',
     'workspace.side.appearance.group': 'Gruppe',
     'workspace.side.appearance.label': 'Label',

     'workspace.side.appearance.opacity': 'Deckkraft',
     'workspace.side.appearance.s selected_opacity': 'Ausgewählte Deckkraft',
     'workspace.side.appearance.outlined_borders': 'Umrandete Grenzen',
     'workspace.side.appearance.show_bitmap': 'Bitmap anzeigen',
     'workspace.side.appearance.show_projections': 'Projektionen anzeigen',

     'workspace.side.tab.object': 'object',
     'workspace.side.tab.labels': 'Etiketten',
     'workspace.side.tab.issues': 'Genehmigung',
     'workspace.side.tab.appear': 'Aussehen',

     'workspace.side.export.dumpOrderType': 'Dateityp exportieren',

     'workspace.side.object.context.trackFrame.title': 'Kontinuierlicher Rahmen',
     'workspace.side.object.context.trackFrame.detail': 'Startframe des aktuellen fortlaufenden Frames ändern in (aktuelles Objekt nach vorne erweitern): Nein.',
     'workspace.side.object.context.trackFrame.track': 'frame',

     'workspace.canvas.rectangleHelp': 'Die Mindestbedingungen für Etiketten sind nicht erfüllt: Breite-{{minWidth}}, Höhe-{{minHeight}}',

};
