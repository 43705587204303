// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Row, Col } from 'antd/lib/grid';
import {
    SettingOutlined,
    UserOutlined,
    LoadingOutlined,
    LogoutOutlined,
    CaretDownOutlined,
} from '@ant-design/icons';
import Layout from 'antd/lib/layout';
import Button from 'antd/lib/button';
import Menu, { MenuProps } from 'antd/lib/menu';
import Dropdown from 'antd/lib/dropdown';
import Text from 'antd/lib/typography/Text';

import getCore from 'cvat-core-wrapper';
import {
    AmygoLogo,
} from 'icons';
import { switchSettingsDialog as switchSettingsDialogAction } from 'actions/settings-actions';
import { logoutAsync, authActions } from 'actions/auth-actions';
import { CombinedState, RoleType } from 'reducers/interfaces';
import { Translation, useTranslation } from 'react-i18next';
import Language from 'components/language/language';
import ChangePasswordComponent from './changePassword';
// import { MenuInfo } from 'rc-menu';

import './styles.scss';
import { isNormalUser } from 'utils/constant';
import { Modal } from 'antd';

const core = getCore();

interface Tool {
    name: string;
    description: string;
    server: {
        host: string;
        version: string;
    };
    core: {
        version: string;
    };
    canvas: {
        version: string;
    };
    ui: {
        version: string;
    };
}

interface StateToProps {
    user: any;
    tool: Tool;
    switchSettingsShortcut: string;
    changePasswordDialogShown: boolean;
    changePasswordFetching: boolean;
    logoutFetching: boolean;
    renderChangePasswordItem: boolean;
    isAnalyticsPluginActive: boolean;
    isModelsPluginActive: boolean;
    isGitPluginActive: boolean;
    organizationsFetching: boolean;
    organizationsList: any[];
    currentOrganization: any | null;
}

interface DispatchToProps {
    onLogout: () => void;
    switchSettingsDialog: (show: boolean) => void;
    switchChangePasswordDialog: (show: boolean) => void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        auth: {
            user,
            fetching: logoutFetching,
            fetching: changePasswordFetching,
            showChangePasswordDialog: changePasswordDialogShown,
            allowChangePassword: renderChangePasswordItem,
        },
        plugins: { list },
        about: { server, packageVersion },
        shortcuts: { normalizedKeyMap },
        // settings: { showDialog: settingsDialogShown },
        organizations: { fetching: organizationsFetching, current: currentOrganization, list: organizationsList },
    } = state;

    return {
        user,
        tool: {
            name: server.name as string,
            description: server.description as string,
            server: {
                host: core.config.backendAPI.slice(0, -7),
                version: server.version as string,
            },
            canvas: {
                version: packageVersion.canvas,
            },
            core: {
                version: packageVersion.core,
            },
            ui: {
                version: packageVersion.ui,
            },
        },
        switchSettingsShortcut: normalizedKeyMap.SWITCH_SETTINGS,
        changePasswordDialogShown,
        changePasswordFetching,
        logoutFetching,
        renderChangePasswordItem,
        isAnalyticsPluginActive: list.ANALYTICS,
        isModelsPluginActive: list.MODELS,
        isGitPluginActive: list.GIT_INTEGRATION,
        organizationsFetching,
        currentOrganization,
        organizationsList,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onLogout: (): void => dispatch(logoutAsync()),
        switchSettingsDialog: (show: boolean): void => dispatch(switchSettingsDialogAction(show)),
        switchChangePasswordDialog: (show: boolean): void => dispatch(authActions.switchChangePasswordDialog(show)),
    };
}

type Props = StateToProps & DispatchToProps;

function HeaderContainer(props: Props): JSX.Element {
    const {
        user,
        logoutFetching,
        // switchSettingsShortcut,
        onLogout,
        // switchSettingsDialog,
    } = props;

    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();

    const [changePasswordOpen, setChangePasswordOpen] = useState(false);

    // const changePasswordVisible = (visible: boolean) => {
    //     setChangePasswordOpen(visible);
    // }

    // const userMenu = (
    //     <Menu className='cvat-header-menu'>
    //         {!isNormalUser(user.roleType) && <Menu.Item
    //             icon={<SettingOutlined />}
    //             key='settings'
    //             title={t('setting.settingTip', { setting: switchSettingsShortcut })}
    //             onClick={() => switchSettingsDialog(true)}
    //         >
    //             {t('setting')}
    //         </Menu.Item>}

    //         <Menu.Item
    //             icon={<SettingOutlined />}
    //             key='settings'
    //             title={t('setting.settingTip', { setting: switchSettingsShortcut })}
    //             onClick={() => setChangePasswordOpen(true)}
    //         >
    //             {t('setting.changePassword')}
    //         </Menu.Item>

    //         <Menu.Item
    //             key='logout'
    //             icon={logoutFetching ? <LoadingOutlined /> : <LogoutOutlined />}
    //             onClick={onLogout}
    //             disabled={logoutFetching}
    //         >
    //             {/* Logout */}
    //             <Translation>{(t) => t('logout')}</Translation>
    //         </Menu.Item>
    //     </Menu>
    // );

    const menuClick = (menuInfo: any) => {
        if (menuInfo.key === 'settings') {
            setChangePasswordOpen(true);
        } else if (menuInfo.key === 'logout') {
            onLogout();
        }
    }

    const userMenu: MenuProps = {
        className: "cvat-header-menu",
        onClick: menuClick,
        items: [{
            key: 'settings',
            icon: <SettingOutlined />,
            label: t('setting.changePassword'),
        },
        {
            key: 'logout',
            icon: logoutFetching ? <LoadingOutlined /> : <LogoutOutlined />,
            label: t('logout'),
        },]
    }

    const getButtonClassName = (value: string): string => {
        // eslint-disable-next-line security/detect-non-literal-regexp
        const regex = new RegExp(`${value}$`);
        return location.pathname.match(regex) ? 'aatp_header_button aatp_active_header_button' : 'aatp_header_button';
    };

    const name = user.nickName || user.username;
    return (
        <Layout.Header className='aatp_header'>
            <div className='aatp_left_header'>
                <AmygoLogo className='header-top-logo-icon' />
                {!isNormalUser(user.roleType) && <Button
                    className={getButtonClassName('projects')}
                    type='link'
                    value='projects'
                    href='/projects?page=1'
                    onClick={(event: React.MouseEvent): void => {
                        event.preventDefault();
                        history.push('/projects');
                    }}
                >
                    <Translation>{(t) => t('project.projcet')}</Translation>
                </Button>}

            </div>
            <div className='aatp_right_header'>
                <Language />
                <Dropdown
                    placement='bottomRight'
                    menu={userMenu}
                    className='aatp_header_menu_user_dropdown'
                >
                    <span>
                        <UserOutlined className='aatp_header_dropdown_icon' />
                        <Row>
                            <Col span={24}>
                                <Text strong>
                                    {name.length > 14 ? `${name.slice(0, 10)} ...` : name}
                                </Text>
                            </Col>
                        </Row>
                        <CaretDownOutlined className='aatp_header_dropdown_icon' />
                    </span>
                </Dropdown>
            </div>
            <Modal
                destroyOnClose
                open={changePasswordOpen}
                onCancel={() => setChangePasswordOpen(false)}
                footer={null}
                closeIcon={<></>}
                maskClosable={false}
            >
                <ChangePasswordComponent onClose={() => setChangePasswordOpen(false)} />
            </Modal>
        </Layout.Header>
    );
}

function propsAreTheSame(prevProps: Props, nextProps: Props): boolean {
    let equal = true;
    for (const prop in nextProps) {
        if (prop in prevProps && (prevProps as any)[prop] !== (nextProps as any)[prop]) {
            if (prop !== 'tool') {
                equal = false;
            }
        }
    }

    return equal;
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(HeaderContainer, propsAreTheSame));
