/*
 * @Author: swxy
 * @Date: 2023-04-21 10:03:56
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { FC } from 'react';
import { CombinedState, Label } from 'reducers/interfaces';
import { useSelector } from 'react-redux';

import './styles.scss';
import { getValueName } from 'utils/constant';
import { LabelType } from 'utils/ConstType';

interface Props {
    label: Label;
}


const LabelItem: FC<Props> = ({ label }) => {
    const { selectObjectState } = useSelector((state: CombinedState) => ({
        selectObjectState: state.annotation.annotations.states.find((objectState) => objectState.clientID === state.annotation.annotations.activatedStateID),
    }))

    let isSelectLabelType = false;
    if (selectObjectState && selectObjectState.label.id === label.id) {
        isSelectLabelType = true;
    }

    return <div className='aatp_label_item' style={{ border: isSelectLabelType ? `1px solid ${label.color}` : undefined }}>
        <div style={{ backgroundColor: label.color }} />
        <div>{getValueName('labelType', label.labelType)}</div>
    </div>
}

export default function LabelSider(): JSX.Element {



    const { labels } = useSelector((state: CombinedState) => ({
        // job: state.annotation.job.instance,
        // fetching: state.annotation.job.fetching,
        // frameNumber: state.annotation.player.frame.number,
        labels: state.annotation.job.labels,
    }))


    return (
        <div className='aatp_label_info'>
            {labels && labels.map((label) => {
                if (label.labelType >= LabelType.curb) {
                    return null
                }
                return <LabelItem key={label.id} label={label} />
            })}
        </div>
    );
}
