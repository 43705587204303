/*
 * @Author: swxy
 * @Date: 2022-08-15 10:15:08
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import base from './base';
import canvas from './canvas';
import hotKey from './hotKey';
import error from './error';

export default {
    ...base,
    ...canvas,
    ...hotKey,
    ...error,
};
