/*
 * @Author: swxy
 * @Date: 2022-08-19 11:50:48
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
     'shape.rectangle': 'Moment',
     'shape.polygon': 'viele',
     'Form.Polylinie': 'Linie',
     'shape.points': 'Punkte',
     'shape.cuboid': 'Körper',
     'shape.image': 'Abbildung',
     'shape.ellipse': 'Kreis',
     'shape.other': 'er',

     'shape.full.rectangle': 'Rechteck',
     'shape.full.polygon': 'Polygon',
     'shape.full.polyline': 'Liniensegment',
     'shape.full.points': 'Punkte',
     'shape.full.cuboid': 'quader',
     'shape.full.image': 'Bild',
     'shape.full.ellipse': 'rund',
     'shape.full.other': 'Unbekannter Typ',


};
