/*
 * @Author: swxy
 * @Date: 2022-09-14 15:03:21
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
export default {
    'hotKey.rotateUp.name': '相机上转',
    'hotKey.rotateUp.description': '以相机视角中心为圆点，向上旋转相机',
    'hotKey.rotateDown.name': '相机下转',
    'hotKey.rotateDown.description': '以相机视角中心为圆点，向下旋转相机',
    'hotKey.rotateLeft.name': '相机左转',
    'hotKey.rotateLeft.description': '以相机视角中心为圆点，向左旋转相机',
    'hotKey.rotateRight.name': '相机右转',
    'hotKey.rotateRight.description': '以相机视角中心为圆点，向右旋转相机',
    'hotKey.moveDown.name': '相机下移',
    'hotKey.moveDown.description': '向下移动相机',
    'hotKey.moveUp.name': '相机上移',
    'hotKey.moveUp.description': '向上移动相机',
    'hotKey.moveLeft.name': '相机左移',
    'hotKey.moveLeft.description': '向左移动相机',
    'hotKey.moveRight.name': '相机右移',
    'hotKey.moveRight.description': '向右移动相机',
    'hotKey.zoomIn.name': '相机放大',
    'hotKey.zoomIn.description': '开始放大',
    'hotKey.zoomOut.name': '相机缩小',
    'hotKey.zoomOut.description': '开始缩小',
    'hotKey.moveCam.name': '增加3D框',
    'hotKey.moveCam.description': '增加3D框',
    'hotKey.3dShape.name': '增加3D框',
    'hotKey.3dShape.description': '增加3D框',
    'hotKey.selectAll.name': '当前帧所有对象',
    'hotKey.selectAll.description': '选中当前帧的所有对象',
    'hotKey.panelModel.name': '面板列表模式',
    'hotKey.panelModel.description': '切换列表是单个展示还是多个展示',
    'hotKey.lineV.name': '垂直分割线',
    'hotKey.lineV.description': '在选中的矩形对象中，增加一条垂直分割线。',
    'hotKey.lineH.name': '水平分割线',
    'hotKey.lineH.description': '在选中的矩形对象中，增加一条水平分割线。',
    'hotKey.deletePoints/Lines.name': '删除当前点/线',
    'hotKey.deletePoints/Lines.description': '点/线对象被选中时，删除当前对象鼠标正在点击的这一个点，对象不变',
    'hotKey.cancelRelation.name': '取消关系',
    'hotKey.cancelRelation.description': '取消选中的对象的关联关系，选中的是父对象时，会取消和所有子对象的关联关系。',
    'hotKey.nextObject.name': '选中后一个对象',
    'hotKey.nextObject.description': '选中当前帧，当前活动对象的下一个对象',
    'hotKey.prevObject.name': '选中前一个对象',
    'hotKey.prevObject.description': '选中当前帧，当前活动对象的上一个对象',

    'hotKey.rotateClockwise.name': '顺时针旋转90°',
    'hotKey.rotateAntiClockwise.name': '逆时针旋转90°',
    'hotKey.rotateClockwise.description': '当前选中对象，以上方视角为准，顺时针旋转90°',
    'hotKey.rotateAntiClockwise.description': '当前选中对象，以上方视角为准，逆时针旋转90°',
};
