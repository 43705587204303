/*
 * @Author: swxy
 * @Date: 2023-06-23 18:00:06
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { ActionCreator, AnyAction, Dispatch, Store } from 'redux';
import { ThunkAction } from 'utils/redux';
import getCore from 'cvat-core-wrapper';
import { getAmygoStore } from 'amygo-store';
import { CombinedState, ActiveControl, ObjectType, ShapeType, Rotation, Model, OpenCVTool } from 'reducers/interfaces';
import { AnnotationActionTypes, computeZRange, receiveAnnotationsParameters, autoSave } from './annotation-actions';
import logger, { LogType } from 'cvat-logger';
import { ProjectType } from 'utils/ConstType';
import { Canvas3d } from 'canvas3d-wrapper';
import { Canvas, CuboidDrawingMethod, RectDrawingMethod } from 'cvat-canvas-wrapper';
import { ReviewActionTypes } from './review-actions';
import { DrawSubPointData } from 'cvat-canvas/src/typescript/canvasModel';
import ObjectState from 'business/objects/objectState';
import Job from 'business/objects/job';
// import { switchToolsBlockerState } from './settings-actions';

// const cvat = getCore();
let store: null | Store<CombinedState> = null;

function getStore(): Store<CombinedState> {
    if (store === null) {
        store = getAmygoStore();
    }
    return store;
}

export enum AnnotationSubActionTypes {
    changePerspectiveView = 'changePerspectiveView',
    confirmCanvasReady = 'confirm_canvas_ready',
    activateObject = 'activateObject',

    createAnnotationsSuccess = 'createAnnotationsSuccess',
    createAnnotationsFailed = 'createAnnotationsFailed',
    updateAnnotationsSuccess = 'updateAnnotationsSuccess',
    updateAnnotationsFailed = 'updateAnnotationsFailed',
    removeAnnotationsSuccess = 'removeAnnotationsSuccess',
    removeAnnotationsFailed = 'removeAnnotationsFailed',
    changeFrameSuccess = 'changeFrameSuccess',
    changeFrameFailed = 'changeFrameFailed',

    projectionAnnotation = 'projectionAnnotation',
    projectionAnnotationSuccess = 'projectionAnnotationSuccess',
    projectionAnnotationFailed = 'projectionAnnotationFailed',

    rememberCreatedObject = 'remember_created_object',
    repeatDrawShape = 'repeat_draw_shape',
    // rotateFrame = 'rotate_frame',
    // interactWithCanvas = 'interact_with_canvas',
    pasteShape = 'paste_shape',
    resetCanvas = 'reset_canvas',

    collapse_object_items = 'collapse_object_items',

    select_issue_position = 'select_issue_position',

    associatedAnnotationSuccess = 'associatedAnnotationSuccess',
    associatedAnnotationFailed = 'associatedAnnotationFailed',
}

export function changePerspectiveView(perspective?: string, activatedStateID?: number): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            dispatch({
                type: ReviewActionTypes.CANCEL_ISSUE,
                payload: {},
            });
            dispatch({
                type: AnnotationSubActionTypes.changePerspectiveView,
                payload: {
                    perspective,
                    activatedStateID,
                },
            });
        } catch (error) {
            //
        }
    };
}

export function confirmCanvasReady(): AnyAction {
    return {
        type: AnnotationSubActionTypes.confirmCanvasReady,
        payload: {},
    };
}

export function activateObject(
    activatedStateID: number | null | undefined,
    activatedAttributeID?: number | null,
    issueID?: number,
    selectedStatesID: number[] = [],
): AnyAction {
    return {
        type: AnnotationSubActionTypes.activateObject,
        payload: {
            activatedStateID,
            activatedAttributeID,
            issueID,
            selectedStatesID,
        },
    };
}

export function createSubAnnotationsAsync(
    sessionInstance: any,
    frame: number,
    statesToCreate: any[],
    // direction: string,
): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            // const state: CombinedState = getStore().getState();
            // const { subAnnotation: { perspective } } = state;
            const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
            await sessionInstance.annotations.sub_put(statesToCreate);
            const states = await sessionInstance.annotations.sub_get(frame, showAllInterpolationTracks, filters);
            const history = await sessionInstance.actions.get();

            dispatch(autoSave(sessionInstance));
            dispatch({
                type: AnnotationSubActionTypes.createAnnotationsSuccess,
                payload: {
                    states,
                },
            });
            dispatch({
                type: AnnotationActionTypes.updateHistory,
                payload: {
                    history,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationSubActionTypes.createAnnotationsFailed,
                payload: {
                    error,
                },
            });
        }
    };
}

export function updateSubAnnotationsAsync(
    statesToUpdate: any[],
    // , isChangeLabelType?: boolean
): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const { jobInstance, filters, frame, showAllInterpolationTracks } = receiveAnnotationsParameters();

        // console.log('测试更新11111111111:', statesToUpdate);

        try {
            if (statesToUpdate.some((state: any): boolean => state.updateFlags.zOrder)) {
                // deactivate object to visualize changes immediately (UX)
                dispatch(activateObject(null, null));
            }

            const promises = statesToUpdate.map((objectState: any): Promise<any> => objectState.save());
            const states = await Promise.all(promises);
            // const states = await jobInstance.annotations.sub_get(frame, showAllInterpolationTracks, filters);
            const history = await jobInstance.actions.get();
            const [minZ, maxZ] = computeZRange(states);

            dispatch(autoSave(jobInstance));
            dispatch({
                type: AnnotationSubActionTypes.updateAnnotationsSuccess,
                payload: {
                    states,
                    minZ,
                    maxZ,
                },
            });
            dispatch({
                type: AnnotationActionTypes.updateHistory,
                payload: {
                    history,
                },
            });
        } catch (error) {
            const states = await jobInstance.annotations.get(frame, showAllInterpolationTracks, filters);
            dispatch({
                type: AnnotationSubActionTypes.updateAnnotationsFailed,
                payload: {
                    error,
                    states,
                },
            });
        }
    };
}

export function removeSubObjectAsync(sessionInstance: any, states: any[], force: boolean): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        try {
            const objectStates = Array.isArray(states) ? states : [states];
            await sessionInstance.logger.log(LogType.deleteObject, { count: objectStates.length });
            const { frame } = receiveAnnotationsParameters();

            const promises: Promise<boolean>[] = [];
            objectStates.forEach((objectState: any) => {
                promises.push(objectState.delete(frame, force));
            });

            const removeds = await Promise.all(promises);

            const history = await sessionInstance.actions.get();

            if (removeds.every((removed: boolean) => removed)) {
                dispatch({
                    type: AnnotationSubActionTypes.removeAnnotationsSuccess,
                    payload: {
                        objectStates,
                    },
                });
            } else if (removeds.some((removed: boolean) => removed)) {
                dispatch({
                    type: AnnotationSubActionTypes.removeAnnotationsFailed,
                    payload: {
                        objectStates: objectStates.filter((_, index: number) => removeds[index]),
                    },
                });
                throw new Error('Could not remove some locked objects');
            } else {
                throw new Error('Could not remove the locked object');
            }

            dispatch({
                type: AnnotationActionTypes.updateHistory,
                payload: {
                    history,
                },
            });

            dispatch(autoSave(sessionInstance));
        } catch (error) {
            dispatch({
                type: AnnotationSubActionTypes.removeAnnotationsFailed,
                payload: {
                    error,
                },
            });
        }
    };
}

export function associatedAnnotationsAsync(frame: number, objectStates: ObjectState[], clientID: number): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>, getState: () => CombinedState): Promise<void> => {
        try {
            const {
                annotation: {
                    job: { instance: jobInstance },
                },
            } = getState();
            const { filters, showAllInterpolationTracks } = receiveAnnotationsParameters();
            (jobInstance as Job).annotationsSubAssociated(objectStates, clientID);
            // await dispatch(updateSubAnnotationsAsync(objectStates));
            const states = await jobInstance.annotations.sub_get(frame, showAllInterpolationTracks, filters);
            // const history = await jobInstance.actions.get();
            dispatch(autoSave(jobInstance));
            dispatch({
                type: AnnotationSubActionTypes.associatedAnnotationSuccess,
                payload: {
                    states,
                    history,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationSubActionTypes.associatedAnnotationFailed,
                payload: {
                    error,
                },
            });
        }
    };
}

export function changeSubFrame(nextFrame: number): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>, getState: () => CombinedState): Promise<void> => {
        const state: CombinedState = getState();
        const { instance: job } = state.annotation.job;
        const { filters, frame, showAllInterpolationTracks } = receiveAnnotationsParameters();

        try {
            if (job.projectType !== ProjectType.mix) {
                // 不是混合类型，无需执行
                return;
            }

            const states = await job.annotations.sub_get(nextFrame, showAllInterpolationTracks, filters);
            dispatch({
                type: AnnotationSubActionTypes.changeFrameSuccess,
                payload: {
                    states,
                },
            });
        } catch (error) {
            dispatch({
                type: AnnotationSubActionTypes.changeFrameFailed,
                payload: {
                    error,
                },
            });
        }
    };
}

export function projectionAnnotation(
    projectionStates: any[],
    directions: string[],
    force: boolean = false,
): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>, getState: () => CombinedState): Promise<void> => {
        const state: CombinedState = getState();
        const { instance: job } = state.annotation.job;
        const { instance: canvasInstance } = state.annotation.canvas;
        const { filters, frame, showAllInterpolationTracks } = receiveAnnotationsParameters();

        try {
            if (!projectionStates.length) {
                return;
            }

            // 开始投影
            dispatch({
                type: AnnotationSubActionTypes.projectionAnnotation,
                payload: {},
            });

            await job.annotations.sub_projection(
                projectionStates,
                directions,
                (points3D: number[], direction: string) => {
                    const vecs = (canvasInstance as Canvas3d).postureToPosition(
                        points3D,
                        directions.indexOf(direction),
                        ShapeType.CUBOID,
                        '4',
                    );
                    console.log('获得的点：', vecs);
                    return vecs && vecs.length ? vecs.flatMap((vec) => [vec.x, vec.y]) : null;
                },
            );
            const states = await job.annotations.sub_get(frame, showAllInterpolationTracks, filters);

            dispatch({
                type: AnnotationSubActionTypes.projectionAnnotationSuccess,
                payload: {
                    states,
                },
            });

            dispatch(autoSave(job));
        } catch (error) {
            dispatch({
                type: AnnotationSubActionTypes.projectionAnnotationFailed,
                payload: {
                    error,
                },
            });
        }
    };
}

export function rememberObject(createParams: {
    activeObjectType?: ObjectType;
    activeLabelID?: number;
    activeShapeType?: ShapeType;
    activeNumOfPoints?: number;
    activeRectDrawingMethod?: RectDrawingMethod;
    activeCuboidDrawingMethod?: CuboidDrawingMethod;
}): AnyAction {
    return {
        type: AnnotationSubActionTypes.rememberCreatedObject,
        payload: createParams,
    };
}

export function redrawShapeAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const {
            activatedStateID,
            states,
            canvas: { instance: canvasInstance },
        } = getStore().getState().subAnnotation;

        if (activatedStateID !== null) {
            const [state] = states.filter((_state: any): boolean => _state.clientID === activatedStateID);
            if (state && state.objectType !== ObjectType.TAG) {
                let activeControl = ActiveControl.CURSOR;
                if (state.shapeType === ShapeType.RECTANGLE) {
                    activeControl = ActiveControl.DRAW_RECTANGLE;
                } else if (state.shapeType === ShapeType.POINTS) {
                    activeControl = ActiveControl.DRAW_POINTS;
                } else if (state.shapeType === ShapeType.POLYGON) {
                    activeControl = ActiveControl.DRAW_POLYGON;
                } else if (state.shapeType === ShapeType.POLYLINE) {
                    activeControl = ActiveControl.DRAW_POLYLINE;
                } else if (state.shapeType === ShapeType.CUBOID) {
                    activeControl = ActiveControl.DRAW_CUBOID;
                }

                dispatch({
                    type: AnnotationSubActionTypes.repeatDrawShape,
                    payload: {
                        activeControl,
                    },
                });
                if (canvasInstance instanceof Canvas) {
                    canvasInstance.cancel();
                }
                canvasInstance?.draw({
                    enabled: true,
                    redraw: activatedStateID,
                    shapeType: state.shapeType,
                    crosshair: [ShapeType.RECTANGLE, ShapeType.CUBOID, ShapeType.ELLIPSE].includes(state.shapeType),
                });
            }
        }
    };
}

// export function rotateCurrentFrame(rotation: Rotation): AnyAction {
//     const state: CombinedState = getStore().getState();
//     const {
//         annotation: {
//             player: {
//                 frame: { number: frameNumber },
//                 frameAngles,
//             },
//             job: {
//                 instance: job,
//                 instance: { startFrame },
//             },
//         },
//         settings: {
//             player: { rotateAll },
//         },
//     } = state;

//     const frameAngle = (frameAngles[frameNumber - startFrame] + (rotation === Rotation.CLOCKWISE90 ? 90 : 270)) % 360;

//     job.logger.log(LogType.rotateImage, { angle: frameAngle });

//     return {
//         type: AnnotationSubActionTypes.rotateFrame,
//         payload: {
//             offset: frameNumber - state.annotation.job.instance.startFrame,
//             angle: frameAngle,
//             rotateAll,
//         },
//     };
// }

// export function interactWithCanvas(activeInteractor: Model | OpenCVTool, activeLabelID: number): AnyAction {
//     return {
//         type: AnnotationSubActionTypes.interactWithCanvas,
//         payload: {
//             activeInteractor,
//             activeLabelID,
//         },
//     };
// }

export function repeatDrawShapeAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const {
            canvas: { instance: canvasInstance },
            // job: { labels, instance: jobInstance },
            // player: {
            //     frame: { number: frameNumber },
            // },
            drawing: {
                // activeInteractor,
                activeObjectType,
                // activeLabelID,
                activeShapeType,
                activeNumOfPoints,
                activeRectDrawingMethod,
                activeCuboidDrawingMethod,
            },
        } = getStore().getState().subAnnotation;

        let activeControl = ActiveControl.CURSOR;
        // if (activeInteractor && canvasInstance instanceof Canvas) {
        //     if ((activeInteractor as Model).type.includes('tracker')) {
        //         canvasInstance.interact({
        //             enabled: true,
        //             shapeType: 'rectangle',
        //         });
        //         dispatch(interactWithCanvas(activeInteractor, activeLabelID));
        //         dispatch(switchToolsBlockerState({ buttonVisible: false }));
        //     } else {
        //         canvasInstance.interact({
        //             enabled: true,
        //             shapeType: 'points',
        //             ...(activeInteractor as Model).params.canvas,
        //         });
        //         dispatch(interactWithCanvas(activeInteractor, activeLabelID));
        //     }

        //     return;
        // }
        if (activeShapeType === ShapeType.RECTANGLE) {
            activeControl = ActiveControl.DRAW_RECTANGLE;
        } else if (activeShapeType === ShapeType.POINTS) {
            activeControl = ActiveControl.DRAW_POINTS;
        } else if (activeShapeType === ShapeType.POLYGON) {
            activeControl = ActiveControl.DRAW_POLYGON;
        } else if (activeShapeType === ShapeType.POLYLINE) {
            activeControl = ActiveControl.DRAW_POLYLINE;
        } else if (activeShapeType === ShapeType.CUBOID) {
            activeControl = ActiveControl.DRAW_CUBOID;
        } else if (activeShapeType === ShapeType.ELLIPSE) {
            activeControl = ActiveControl.DRAW_ELLIPSE;
        }

        dispatch({
            type: AnnotationSubActionTypes.repeatDrawShape,
            payload: {
                activeControl,
            },
        });

        if (canvasInstance instanceof Canvas) {
            canvasInstance.cancel();
        }
        if (activeObjectType === ObjectType.TAG) {
            // const objectState = new cvat.classes.ObjectState({
            //     objectType: ObjectType.TAG,
            //     label: labels.filter((label: any) => label.id === activeLabelID)[0],
            //     frame: frameNumber,
            // });
            // dispatch(createSubAnnotationsAsync(jobInstance, frameNumber, [objectState]));
        } else if (canvasInstance) {
            canvasInstance.draw({
                enabled: true,
                rectDrawingMethod: activeRectDrawingMethod,
                cuboidDrawingMethod: activeCuboidDrawingMethod,
                numberOfPoints: activeNumOfPoints,
                shapeType: activeShapeType,
                crosshair: [ShapeType.RECTANGLE, ShapeType.CUBOID, ShapeType.ELLIPSE].includes(activeShapeType),
            });
        }
    };
}

export function pasteShapeAsync(): ThunkAction {
    return async (dispatch: ActionCreator<Dispatch>): Promise<void> => {
        const {
            canvas: { instance: canvasInstance },
            // job: { instance: jobInstance },
            // player: {
            //     frame: { number: frameNumber },
            // },
            drawing: { activeInitialState: initialState },
        } = getStore().getState().subAnnotation;

        if (initialState) {
            let activeControl = ActiveControl.CURSOR;
            if (initialState.shapeType === ShapeType.RECTANGLE) {
                activeControl = ActiveControl.DRAW_RECTANGLE;
            } else if (initialState.shapeType === ShapeType.POINTS) {
                activeControl = ActiveControl.DRAW_POINTS;
            } else if (initialState.shapeType === ShapeType.POLYGON) {
                activeControl = ActiveControl.DRAW_POLYGON;
            } else if (initialState.shapeType === ShapeType.POLYLINE) {
                activeControl = ActiveControl.DRAW_POLYLINE;
            } else if (initialState.shapeType === ShapeType.CUBOID) {
                activeControl = ActiveControl.DRAW_CUBOID;
            } else if (Array.isArray(initialState)) {
                activeControl = ActiveControl.MULT_DRAW;
            }

            dispatch({
                type: AnnotationSubActionTypes.pasteShape,
                payload: {
                    activeControl,
                },
            });
            if (canvasInstance instanceof Canvas) {
                canvasInstance.cancel();
            }
            if (initialState.objectType === ObjectType.TAG) {
                // const objectState = new cvat.classes.ObjectState({
                //     objectType: ObjectType.TAG,
                //     label: initialState.label,
                //     attributes: initialState.attributes,
                //     frame: frameNumber,
                // });
                // dispatch(cr(jobInstance, frameNumber, [objectState]));
            } else {
                canvasInstance?.draw({
                    enabled: true,
                    initialState,
                });
            }
        }
    };
}

export function resetCanvas(): AnyAction {
    return {
        type: AnnotationSubActionTypes.resetCanvas,
        payload: {},
    };
}

export function collapseObjectItem(clientID: number, isCollapse: boolean): AnyAction {
    return {
        type: AnnotationSubActionTypes.collapse_object_items,
        payload: {
            clientID,
            isCollapse,
        },
    };
}

export function selectIssuePosition(enabled: boolean): AnyAction {
    return {
        type: AnnotationSubActionTypes.select_issue_position,
        payload: {
            enabled,
        },
    };
}
