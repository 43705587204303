/*
 * @Author: swxy
 * @Date: 2022-09-14 15:03:21
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
'hotKey.rotateUp.name': 'Kamera auf',
     'hotKey.rotateUp.description': 'Nehmen Sie die Mitte des Kamerablickwinkels als Punkt, drehen Sie die Kamera nach oben',
     'hotKey.rotateDown.name': 'Kamera runter',
     'hotKey.rotateDown.description': 'Nehmen Sie die Mitte des Kamerablickwinkels als Punkt, drehen Sie die Kamera nach unten',
     'hotKey.rotateLeft.name': 'Kamera links abbiegen',
     'hotKey.rotateLeft.description': 'Nehmen Sie die Mitte des Kamerablickwinkels als Punkt, drehen Sie die Kamera nach links',
     'hotKey.rotateRight.name': 'Kamera nach rechts drehen',
     'hotKey.rotateRight.description': 'Nehmen Sie die Mitte des Kamerablickwinkels als Punkt, drehen Sie die Kamera nach rechts',
     'hotKey.moveDown.name': 'Kamera runter',
     'hotKey.moveDown.description': 'Kamera nach unten bewegen',
     'hotKey.moveUp.name': 'Kamera nach oben bewegen',
     'hotKey.moveUp.description': 'Kamera nach oben bewegen',
     'hotKey.moveLeft.name': 'Kamera links',
     'hotKey.moveLeft.description': 'Kamera nach links bewegen',
     'hotKey.moveRight.name': 'Kamerabewegung nach rechts',
     'hotKey.moveRight.description': 'Kamera nach rechts bewegen',
     'hotKey.zoomIn.name': 'Kamera hineinzoomen',
     'hotKey.zoomIn.description': 'Zoomen starten',
     'hotKey.zoomOut.name': 'Kamera verkleinern',
     'hotKey.zoomOut.description': 'Verkleinern starten',
     'hotKey.moveCam.name': '3D wechselt in den Modus zum Bewegen der Kamera',
     'hotKey.moveCam.description': 'Aktuelle 3D-Perspektive verschieben',
     'hotKey.3dShape.name': '3D-Box hinzufügen',
     'hotKey.3dShape.description': '3D-Box hinzufügen',
     'hotKey.selectAll.name': 'Alle Objekte im aktuellen Frame',
     'hotKey.selectAll.description': 'Alle Objekte im aktuellen Frame auswählen',
     'hotKey.panelModel.name': 'Panel-Listenmodus',
     'hotKey.panelModel.description': 'Ob die Schalterliste ein einzelnes Display oder mehrere Displays ist',
     'hotKey.lineV.name': 'Vertikale Trennlinie',
     'hotKey.lineV.description': 'Fügen Sie im ausgewählten rechteckigen Objekt eine vertikale Trennlinie hinzu. ',
     'hotKey.lineH.name': 'Horizontale Trennlinie',
     'hotKey.lineH.description': 'Fügen Sie im ausgewählten rechteckigen Objekt eine horizontale Trennlinie hinzu. ',
     'hotKey.deletePoints/Lines.name': 'Aktuellen Punkt/Linie löschen',
     'hotKey.deletePoints/Lines.description': 'Wenn das Punkt-/Linienobjekt ausgewählt ist, lösche den Punkt, auf den die Maus auf dem aktuellen Objekt klickt, und das Objekt bleibt unverändert',
     'hotKey.cancelRelation.name': 'Beziehung aufheben',
     'hotKey.cancelRelation.description': 'Assoziationsbeziehung des ausgewählten Objekts aufheben Wenn das übergeordnete Objekt ausgewählt wird, wird die Assoziationsbeziehung mit allen untergeordneten Objekten aufgehoben. ',
     'hotKey.nextObject.name': 'Nächstes Objekt auswählen',
     'hotKey.nextObject.description': 'Wähle den aktuellen Frame, das nächste Objekt des aktuell aktiven Objekts',
     'hotKey.prevObject.name': 'Vorheriges Objekt auswählen',
     'hotKey.prevObject.description': 'Wähle den aktuellen Frame, das vorherige Objekt des aktuell aktiven Objekts',

     'hotKey.rotateClockwise.name': 'Um 90° im Uhrzeigersinn drehen',
     'hotKey.rotateAntiClockwise.name': 'Um 90° gegen den Uhrzeigersinn drehen',
     'hotKey.rotateClockwise.description': 'Das aktuell ausgewählte Objekt wird basierend auf der oberen Perspektive um 90° im Uhrzeigersinn gedreht',
     'hotKey.rotateAntiClockwise.description': 'Das aktuell ausgewählte Objekt, basierend auf der oberen Perspektive, dreht sich um 90° gegen den Uhrzeigersinn',

     'hotKey.updateObjectPosition.allowLeft': 'Das aktuell ausgewählte Objekt nach links verschieben',
     'hotKey.updateObjectPosition.allowLeftDetail': 'Lassen Sie das Objekt in der aktuell fokussierten Dreieransicht entsprechend der Richtung der aktuellen Ansicht nach links verschieben',
     'hotKey.updateObjectPosition.allowRight': 'Das aktuell ausgewählte Objekt nach rechts verschieben',
     'hotKey.updateObjectPosition.allowRightDetail': 'In der aktuell fokussierten Dreieransicht das Objekt nach rechts in Richtung der aktuellen Ansicht bewegen',
     'hotKey.updateObjectPosition.allowUp': 'Das aktuell ausgewählte Objekt bewegt sich nach oben',
     'hotKey.updateObjectPosition.allowUpDetail': 'In der aktuell fokussierten Dreieransicht das Objekt in Richtung der aktuellen Ansicht nach oben bewegen',
     'hotKey.updateObjectPosition.allowDown': 'Das aktuell ausgewählte Objekt nach unten verschieben',
     'hotKey.updateObjectPosition.allowDownDetail': 'In der aktuell fokussierten Dreieransicht das Objekt in Richtung der aktuellen Ansicht nach unten bewegen',
};
