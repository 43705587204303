// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import '../styles.scss';
import React from 'react';
import { connect } from 'react-redux';
import {
    StepBackwardOutlined,
    StepForwardOutlined,
    CaretRightOutlined,
    CaretLeftOutlined,
} from '@ant-design/icons';
import {
    Tooltip,
    Col,
} from 'antd';

import { CombinedState } from 'reducers/interfaces';
import {
    changeFrameAsync,
} from 'actions/annotation-actions';
import { ThunkDispatch } from 'utils/redux';
import { useTranslation } from 'react-i18next';

interface StateToProps {
    sidebarPositionLeft: boolean;
    isPanelListModel: boolean;
    objectState: any;
    frameNumber: number;
    canvasInstance: any;
    normalizedKeyMap: Record<string, string>;
    activatedStateID: number | null;
}

interface DispatchToProps {
    changeFrameEvent(frame: number): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            sidebarPositionLeft,
            annotations: {
                states,
                isPanelListModel,
                activatedStateID,
            },
            canvas: { instance: canvasInstance },
            player: {
                frame: { number: frameNumber },
            },
        },
        shortcuts: { normalizedKeyMap },
    } = state;

    const [objectState = {}] = states.filter((_objectState): boolean => _objectState.clientID === activatedStateID);
    return {
        sidebarPositionLeft,
        isPanelListModel,
        objectState,
        frameNumber,
        canvasInstance,
        normalizedKeyMap,
        activatedStateID,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchToProps {
    return {
        changeFrameEvent(frame: number): void {
            dispatch(changeFrameAsync(frame));
        },
    };
}

function changeFrame(frame: number, props: any): void {
    const { changeFrameEvent, canvasInstance } = props;
    if (canvasInstance.isAbleToChangeFrame()) {
        changeFrameEvent(frame);
    }
}

function TrackFrameNavigation(props: StateToProps & DispatchToProps): JSX.Element {
    // const { activatedStateID } = props;

    const { t } = useTranslation();
    // if (!activatedStateID) {
    //     return <></>;
    // }

    const {
        objectState,
        frameNumber,
        normalizedKeyMap,
    } = props;

    const stepBackTitle = t('workspace.top.track.firstKeyFrame');
    const stepForwardTitle = t('workspace.top.track.lastKeyFrame');
    const caretLeftTitle = t('workspace.top.track.previousKeyFrame', { key: normalizedKeyMap.PREV_KEY_FRAME });
    const caretRightTitle = t('workspace.top.track.nextKeyFrame', { key: normalizedKeyMap.NEXT_KEY_FRAME });

    const {
        first, prev, next, last,
    } = objectState.keyframes || {
        first: null, // shapes don't have keyframes, so we use null
        prev: null,
        next: null,
        last: null,
    };

    const navigateFirstKeyframe = (): void => {
        if (first !== frameNumber) {
            changeFrame(first, props);
        }
    };

    const navigatePrevKeyframe = (): void => {
        if (prev !== null && prev !== frameNumber) {
            changeFrame(prev, props);
        }
    };

    const navigateNextKeyframe = (): void => {
        if (next !== null && next !== frameNumber) {
            changeFrame(next, props);
        }
    };

    const navigateLastKeyframe = (): void => {
        if (last !== frameNumber) {
            changeFrame(last, props);
        }
    };

    return (
        <Col
            className='cvat-annotation-header-track-group group-hover-color'
            style={{ visibility: objectState.objectType !== 'track' ? 'hidden' : 'initial' }}
        // style={ objectState.objectType !== 'track' ? { display:'none' } : {} }
        >
            <Tooltip title={stepBackTitle}>
                <StepBackwardOutlined
                    style={{ pointerEvents: first || first === 0 ? 'initial' : 'none', opacity: first || first === 0 ? 1 : 0.5 }}
                    onClick={navigateFirstKeyframe}
                />
            </Tooltip>
            <Tooltip title={caretLeftTitle}>
                <CaretLeftOutlined
                    style={{ pointerEvents: prev || prev === 0 ? 'initial' : 'none', opacity: prev || prev === 0 ? 1 : 0.5 }}
                    onClick={navigatePrevKeyframe}
                />
            </Tooltip>
            <Tooltip title={caretRightTitle}>
                <CaretRightOutlined
                    style={{ pointerEvents: next || next === 0 ? 'initial' : 'none', opacity: next || next === 0 ? 1 : 0.5 }}
                    onClick={navigateNextKeyframe}
                />
            </Tooltip>
            <Tooltip title={stepForwardTitle}>
                <StepForwardOutlined
                    style={{ pointerEvents: last || last === 0 ? 'initial' : 'none', opacity: last || last === 0 ? 1 : 0.5 }}
                    onClick={navigateLastKeyframe}
                />
            </Tooltip>
        </Col>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(TrackFrameNavigation));
