/*
 * @Author: swxy
 * @Date: 2023-09-15 17:56:23
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { Camera, OrthographicCamera, PerspectiveCamera, Vector3 } from 'three';
import CONST from './consts';
import CameraControl from './objects/cameraControl';
// import CameraControls from 'camera-controls';

const defaultDistance = 40;

export enum CameraType {
    orthographic = 'orthographic',
    perspective = 'perspective',
}

class CanvasCameraControls {
    private _orthographic: OrthographicCamera;
    private _perspective: PerspectiveCamera;

    private _orthographicControl: CameraControl;
    private _perspectiveControl: CameraControl;
    // private _control: CameraControl;

    private _orthographicEnable: boolean;
    private _perspectiveEnable: boolean;

    private _cameraType: CameraType;
    private _isCameraChange: boolean = false;

    /**
     * 相机是否可控
     */
    // private _enabled: boolean;

    public get control(): CameraControl {
        return this._cameraType === CameraType.perspective ? this._perspectiveControl : this._orthographicControl;
    }

    public get cameraType(): CameraType {
        return this._cameraType;
    }

    public get camera(): OrthographicCamera | PerspectiveCamera {
        if (this._cameraType === CameraType.orthographic) {
            return this._orthographic;
        }
        return this._perspective;
    }

    public get enabled(): boolean {
        return this.control.enabled;
    }

    public set enabled(enabled: boolean) {
        // this._enabled = enabled;
        this.control.enabled = enabled;
    }

    public get isCameraChange() {
        return this._isCameraChange;
    }

    constructor(domElement: HTMLElement, type: CameraType = CameraType.orthographic) {
        const width = domElement?.parentElement?.clientWidth || window.innerWidth;
        const height = domElement?.parentElement?.clientHeight || window.innerHeight;
        const aspectRatio = width / height;
        const viewSize = CONST.Camera_ZOOM_FACTOR;

        this._perspective = new PerspectiveCamera(50, aspectRatio);
        this._orthographic = new OrthographicCamera(
            -aspectRatio * viewSize,
            aspectRatio * viewSize,
            viewSize,
            -viewSize,
            -50,
            50,
        );

        this.initCamera(domElement);

        this._perspectiveControl = new CameraControl(this._perspective, domElement);
        this._orthographicControl = new CameraControl(this._orthographic, domElement);

        // this.initControls(domElement);

        this._perspectiveEnable = true;
        this._orthographicEnable = true;

        this._cameraType = type;

        setTimeout(() => {
            this?.onCameraChange(type === CameraType.perspective ? this._perspective : this._orthographic);
        });
    }

    private initCamera(domElement: HTMLElement) {
        this._perspective.position.set(defaultDistance, defaultDistance, defaultDistance);
        this._perspective.up.set(0, 0, 1);
        this._perspective.lookAt(0, 0, 0);
        this._perspective.name = 'cameraPerspective';
        this._perspective.updateProjectionMatrix();

        this._orthographic.position.set(0, 0, defaultDistance);
        this._orthographic.up.set(0, 0, 1);
        this._orthographic.lookAt(0, 0, 0);
        this._orthographic.name = 'cameraOrthographic';
        this._orthographic.updateProjectionMatrix();
    }

    private initControls(domElement: HTMLElement) {}

    private resizeRendererToDisplaySize = (
        camera: THREE.PerspectiveCamera | THREE.OrthographicCamera,
        renderer: THREE.WebGLRenderer,
    ): void => {
        const canvas = renderer.domElement;
        if (!canvas.parentElement) return;
        const width = canvas.parentElement.clientWidth;
        const height = canvas.parentElement.clientHeight;
        const needResize = canvas.clientWidth !== width || canvas.clientHeight !== height || this.isCameraChange;
        if (needResize && camera) {
            if (camera instanceof PerspectiveCamera) {
                camera.aspect = width / height;
            } else {
                // const topViewFactor = 0;
                // const viewSize = CONST.ZOOM_FACTOR;
                const viewSize = CONST.Camera_ZOOM_FACTOR;
                const aspectRatio = width / height;
                // camera.left = (-aspectRatio * viewSize) / 2 - topViewFactor;
                // camera.right = (aspectRatio * viewSize) / 2 + topViewFactor;
                // camera.top = viewSize / 2 + topViewFactor;
                // camera.bottom = -viewSize / 2 - topViewFactor;
                camera.left = -aspectRatio * viewSize;
                camera.right = aspectRatio * viewSize;
                camera.top = viewSize;
                camera.bottom = -viewSize;
                camera.near = -50;
                camera.far = 50;
            }
            renderer.setSize(width, height);
            camera.updateProjectionMatrix();
            this._isCameraChange = false;
        }
    };

    public async onPerspectiveCamera(hasAnimation: boolean = true) {
        if (this._cameraType === CameraType.perspective) {
            return;
        }

        const lookVec = new Vector3();
        this.control.getTarget(lookVec);
        if (this._orthographic) {
            await this.control.setLookAt(
                lookVec.x,
                lookVec.y,
                lookVec.z + defaultDistance,
                lookVec.x,
                lookVec.y,
                lookVec.z,
                hasAnimation,
            );
        }

        this._cameraType = CameraType.perspective;
        this._isCameraChange = true;

        await this.control.setLookAt(
            lookVec.x,
            lookVec.y,
            lookVec.z + defaultDistance,
            lookVec.x,
            lookVec.y,
            lookVec.z,
            false,
        );
    }

    public async onOrthographicCamera(hasAnimation: boolean = true) {
        if (this._cameraType === CameraType.orthographic) {
            return;
        }

        const lookVec = new Vector3();
        this.control.getTarget(lookVec);
        if (this._perspective) {
            await this.control.setLookAt(
                lookVec.x,
                lookVec.y,
                lookVec.z + defaultDistance,
                lookVec.x,
                lookVec.y,
                lookVec.z,
                hasAnimation,
            );
        }

        this._cameraType = CameraType.orthographic;
        this._isCameraChange = true;

        await this.control.setLookAt(
            lookVec.x,
            lookVec.y,
            lookVec.z + defaultDistance,
            lookVec.x,
            lookVec.y,
            lookVec.z,
            false,
        );
        this.control.minPolarAngle = 0;
        this.control.maxPolarAngle = 0;
    }

    public cancel() {
        if (this._cameraType === CameraType.perspective) {
            return;
        }

        const lookVec = new Vector3();
        this.control.getTarget(lookVec);
        if (this._orthographic) {
            this.control.setLookAt(
                lookVec.x,
                lookVec.y,
                lookVec.z + defaultDistance,
                lookVec.x,
                lookVec.y,
                lookVec.z,
                false,
            );
        }

        this._cameraType = CameraType.perspective;
        this._isCameraChange = true;

        this.control.setLookAt(
            lookVec.x,
            lookVec.y,
            lookVec.z + defaultDistance,
            lookVec.x,
            lookVec.y,
            lookVec.z,
            false,
        );
    }

    public isNeedUpdateRender(renderer: THREE.WebGLRenderer) {
        this.resizeRendererToDisplaySize(this.camera, renderer);
        return this.control.isNeedUpdateRender();
    }

    /**
     * 外界用来替换，本身不做任何逻辑
     * @param camera
     */
    public onCameraChange(camera: OrthographicCamera | PerspectiveCamera) {}
}

export default CanvasCameraControls;
