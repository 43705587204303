/*
 * @Author: swxy
 * @Date: 2022-08-15 14:21:02
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import userNotice from './other/userNotice';

export default {
    'login.login': 'Login',
    'login.register': 'Registration',

    'login.username': 'username',
    'login.password': 'password',
    'login.empty_username': 'Please enter your username',
    'login.empty_password': 'Please enter your password',

    'login.confirm_password': 'Confirm password',
    'login.valid_password': 'The two passwords entered are inconsistent!',

    'login.title': 'Data Annotation Tool',
    'login.demoTitle': 'Pre-labeling Automation',
    'login.demoSubTitle': '- Trial version',
    'login.resetPassword': 'Forgot Password',

    'login.nickName': 'Nickname',
    'login.email': 'Email',
    'login.mobile': 'Mobile Phone',
    'login.affiliation': ' Company/School',
    'login.info': 'Other',
    'login.verifyCode': 'Verification code',
    'login.placeholder_nickName': 'Please enter your nickname',
    'login.placeholder_email': 'Please enter your email',
    'login.placeholder_mobile': 'Please enter your mobile phone number',
    'login.placeholder_affiliation': 'Please enter your company/school/department name',
    'login.placeholder_info': 'Please enter other information',
    'login.placeholder_verifyCode': ' Please enter the verification code',

    'login.valid_email': 'Incorrect email format!',
    'login.valid_mobile': 'Incorrect phone number format!',

    'login.hasAccount': 'Already have an account? You can ',

    'login.registerSuccess': 'Registration successful!',

    'login.newPassword': 'New Password',
    'login.empty_newPassword': 'Please enter a new password',
    'login.confirm_empty_newPassword': 'Please enter the new password again',

    'login.forgetPassword': 'Forgot password',
    'login.email.change': 'Change',
    'login.validcode.get': 'Get',
    'login.validcode.placeholder': 'Please obtain the verification code and enter it',

    'login.imageVerifyCode': 'Image verification code',
    'login.imageVerifyCode_placeholder': 'Please obtain the image verification code and enter it',
    'login.emailVerifyCode': 'Email verification code',
    'login.emailVerifyCode_placeholder': 'Please obtain the email verification code and enter it',

    'login.imageCodeNeed': 'Please enter the image verification code',
    'login.sendEmailValidCodeSuccess': 'The verification code has been sent to the email, please check it',
    'login.sendEmailValidCodeError': 'Failed to send verification code!',

    //#region 2023-05-04 待校验
    'register.userNotice': '"User Agreement"',
    'register.confirmUserNotice': 'Confirm and agree to sign',
    'register.userNoticeTip': 'Please review and agree to the "User Agreement".',
    'register.userNotice.title': 'User Agreement',
    ...userNotice,
    //#endregion
};