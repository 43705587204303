/*
 * @Author: swxy
 * @Date: 2024-03-27 15:50:13
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { useMemo } from 'react';
import ObjectState from "business/objects/objectState";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CombinedState, InputType, ShapeType } from "reducers/interfaces";
import './styles.scss';
import { Col, Divider, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { updateAnnotationsAsync } from 'actions/annotation-actions';

interface Props {
    activatedObjectState: ObjectState;
    readonly: boolean;
}

interface AttributeDisplayed {
    id: number;
    name: string;
    attributes: {
        id: number;
        name: string;
        defaultValue: string;
        kvData: Record<string, string>;
    }[]
}

const AttributeComponent: FC<Props> = ({ activatedObjectState, readonly }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { states } = useSelector((state: CombinedState) => ({
        // activatedStateID: state.annotation.annotations.activatedStateID,
        states: state.annotation.annotations.states,
    }))
    const [selectStates, setSelectStates] = useState<ObjectState[]>([]);
    const [labelInfo, setLabelInfo] = useState<AttributeDisplayed>();

    useEffect(() => {
        // 当前只支持一层归属关系
        setSelectStates(states.filter((obj: ObjectState) => (
            obj.clientID !== activatedObjectState.clientID && !obj.parentID && obj.serverID && obj.objectType === activatedObjectState.objectType
        )))
    }, [states, activatedObjectState]);

    useEffect(() => {
        const id = activatedObjectState.label.id;
        const title = activatedObjectState.label.title;
        const attrs = [...activatedObjectState.label.attributes];
        // attrs.sort((a, b) => a.id - b.id);
        setLabelInfo({
            id,
            name: title,
            attributes: attrs.map(attr => ({
                id: attr.id,
                name: attr.title,
                defaultValue: attr.defaultValue,
                kvData: [InputType.select, InputType.radio, InputType.checkbox].includes(attr.inputType) ? attr.values.reduce((previous: Record<string, string>, current, index) => {
                    previous[current] = attr.ranges[index];
                    return previous;
                }, {}) : {},
            }))
        })
    }, [activatedObjectState.label.id]);

    const onSelectParentID = (value: number) => {
        activatedObjectState.parentID = value;
        dispatch(updateAnnotationsAsync([activatedObjectState]));
    }

    const parentOptions = selectStates.map(obj => ({
        value: obj.clientID,
        label: `${obj.clientID}-${obj.label.title}`,
    }))

    // const label = activatedObjectState.label;

    // const getAttr = () => {

    //     return {

    //     }
    // }

    // const labelAtt = useMemo(showLabel, [label.id]);

    // console.log('当前对象：', activatedObjectState)
    return <div className='aatp_attribute_sidebar_content'>
        <Row className='aatp_attribute_sidebar_content_parent' justify={'center'} align={'middle'}>
            <Col span={6}>{t('workspace.canvas.attribute.parentID')}</Col>
            <Col>
                {readonly ?
                    activatedObjectState.parentID :
                    <Select
                        className='aatp_attribute_sidebar_content_parent_content'
                        value={activatedObjectState.parentID}
                        options={parentOptions}
                        onChange={onSelectParentID}
                        placeholder={t('workspace.canvas.attribute.parentID_placeholder')}
                        allowClear
                    />}
            </Col>
        </Row>
        <Divider className='aatp_attribute_sidebar_content_divider' />

        <Row className='aatp_attribute_sidebar_content_content'>
            <Row className='aatp_attribute_sidebar_content_title' justify={'center'} align={'middle'}>
                {labelInfo?.name}
            </Row>

            {labelInfo ? labelInfo.attributes.map(data => {
                return <Row className='aatp_attribute_sidebar_content_kv' justify={'center'} align={'middle'}>
                    {data.name}—{data.kvData[activatedObjectState.attributes[data.id]]}
                </Row>
            }) : undefined}
        </Row>



    </div>
}


const AttributeInfoComponent: FC<Props> = ({ activatedObjectState, readonly }) => {
    // const { activatedStateID, states } = useSelector((state: CombinedState) => ({
    //     activatedStateID: state.annotation.annotations.activatedStateID,
    //     states: state.annotation.annotations.states,
    // }))

    // const object = states.find(obj => obj.clientID === activatedStateID);
    if (!activatedObjectState || activatedObjectState.shapeType !== ShapeType.CUBOID) {
        return <></>;
    }
    return <div className="aatp_attribute_sidebar">
        <AttributeComponent activatedObjectState={activatedObjectState} readonly={readonly} />
    </div>
}

export default AttributeInfoComponent;