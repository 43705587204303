// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col, Row } from 'antd/lib/grid';

import LeftGroup from './left-group';
import PlayerButtons from './player-buttons';
import PlayerNavigation from './player-navigation';
import TrackFrameNavigation from './track-frame-navigation';

import '../styles.scss';

interface Props {
}


export default function AnnotationTopBarComponent(props: Props): JSX.Element {

    return (
        <Row justify='space-between'>
            <LeftGroup />
            <Col className='cvat-annotation-header-player-group'>
                <Row align='middle'>
                    <PlayerButtons />
                    <PlayerNavigation />
                    <TrackFrameNavigation />
                </Row>
            </Col>
            <Col></Col>
        </Row>
    );
}
