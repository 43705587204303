/*
 * @Author: swxy
 * @Date: 2023-09-11 13:54:56
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { post } from 'utils/request';

export const api_queryTeamUserList = async (data: { userId: number }) => {
    try {
        const result = await post('/api/authTeamUser/selectRelative', data);
        return result.rows || [];
    } catch (error) {
        throw error;
    }
};

export const api_queryTeamTaskList = async (data: { taskId: number; teamId: number }) => {
    try {
        const result = await post('/api/authTeamTask/selectRelative', data);
        return result.rows || [];
    } catch (error) {
        throw error;
    }
};
// export const api_queryTeamTaskByUser = async (data: { frameId: number; name: string }) => {
//     try {
//         const result = await post('/api/aatp/team/selectUserTask', data, {
//             responseOriginalData: true,
//         });
//         return result;
//     } catch (error) {
//         throw error;
//     }
// };

export const api_recoveryJob = async (data: {
    jobId: number;
    isAnnotation?: boolean;
    isReviewFrist?: boolean;
    isReviewSecord?: boolean;
    isAccept?: boolean;
}) => {
    try {
        let response = null;
        let url = null;

        if (data.isAnnotation) {
            url = '/api/aatp/task/revertAnnotation';
        } else if (data.isReviewFrist) {
            url = '/api/aatp/task/revertCheckOne';
        } else if (data.isReviewSecord) {
            url = '/api/aatp/task/revertCheckTwo';
        } else if (data.isAccept) {
            url = '/api/aatp/task/revertReceive';
        }

        if (url) {
            response = await post(
                `${url}`,
                {
                    jobId: data.jobId,
                },
                {
                    responseOriginalData: true,
                },
            );
            return response.success;
        }
        throw new Error('回收题包出错！');
    } catch (error) {
        throw error;
    }
};

export const api_applyJob = async (data: {
    taskId: number;
    isAnnotation?: boolean;
    isReviewFrist?: boolean;
    isReviewSecord?: boolean;
    isAccept?: boolean;
}) => {
    try {
        let response = null;
        let url = null;

        if (data.isAnnotation) {
            url = '/api/aatp/task/applyAnnotation';
        } else if (data.isReviewFrist) {
            url = '/api/aatp/task/applyCheckOne';
        } else if (data.isReviewSecord) {
            url = '/api/aatp/task/applyCheckTwo';
        } else if (data.isAccept) {
            url = '/api/aatp/task/applyReceive';
        }

        if (url) {
            response = await post(
                `${url}`,
                {
                    taskId: data.taskId,
                },
                {
                    responseOriginalData: true,
                },
            );
            return response;
        }

        throw new Error('申请题包出错！');
    } catch (errorData) {
        throw errorData;
    }
};
