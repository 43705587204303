/*
 * @Author: swxy
 * @Date: 2022-07-26 15:53:04
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

// 主视图
import * as THREE from 'three';
import { Controller } from './controller';
import { Action, Issue, Mode, ObjectState, Planes, ShapeType, UpdateReasons, ViewType } from './interface';
import CONST, { helper_radius } from './consts';
import { isLeft, resizeRendererToDisplaySize } from './public';
import { Listener } from './master';
import { Quaternion, Vector3 } from 'three';
import BaseView, { PositionDirection } from './baseView';
import constants from './consts';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import LanelineMesh from './objects/lanelineMesh';
import SphereModel from './objects/sphere';
import CuboidModel from './objects/cuboid';
import Scene from './objects/scene';
import Bezier from './objects/bezier/bezierModel';

export interface GlobalHelper {
    resize: THREE.Mesh<THREE.BufferGeometry, THREE.Material>[];
    rotation: THREE.Mesh<THREE.BufferGeometry, THREE.Material>[];
    resizeFace: THREE.Mesh<THREE.BufferGeometry, THREE.Material>[];
}

export interface ThreeBaseViewProps {
    rayCaster: RayCast;
    // 主视图场景
    scene: Scene;
    // 渲染注释图场景
    renderer: THREE.WebGLRenderer;
    // 主视图scene的children
    // points: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
    // controls: OrbitControls;
    // camera: THREE.OrthographicCamera;
    globalHelper: GlobalHelper;

    // setSceneChildren(points: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>): void;
    getObjectByName(name: string): THREE.Object3D | undefined;
    clearObjects(name?: string[] | string): void;
    html(): HTMLCanvasElement;
    render(): void;
    keyControls(keys: KeyboardEvent): void;
}

export interface ViewsDOM {
    domElement: HTMLCanvasElement;
}

export interface RayCast {
    renderer: THREE.Raycaster;
    mouseVector: THREE.Vector2;
}

const viewSize = CONST.ZOOM_FACTOR;
const height = window.innerHeight;
const width = window.innerWidth;
const aspectRatio = window.innerWidth / window.innerHeight;

export class ThreeBaseView extends BaseView implements ThreeBaseViewProps, Listener {
    // 外部变量 声明初始化
    public camera: THREE.OrthographicCamera = new THREE.OrthographicCamera(
        (-aspectRatio * viewSize) / 2,
        (aspectRatio * viewSize) / 2,
        viewSize / 2,
        -viewSize / 2,
        -50,
        50,
    );
    public scenePlane: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>;
    public globalHelper: GlobalHelper = {
        resize: [],
        rotation: [],
        resizeFace: [],
    };

    private startMode?: Mode;

    private _lookPos: Vector3 = new Vector3();
    private _lookRadius?: number;

    public constructor(controller: Controller, action: Action, type: ViewType) {
        super(controller, action, type);
        const { domElement } = this.renderer;
        // this.controls.onCameraChange = (camera) => {
        //     this.camera = camera as THREE.OrthographicCamera;
        // };

        if (type === ViewType.TOP) {
            this.camera.left = (-aspectRatio * viewSize) / 2 - 2;
            this.camera.right = (aspectRatio * viewSize) / 2 + 2;
            this.camera.top = viewSize / 2 + 2;
            this.camera.bottom = -viewSize / 2 - 2;

            this.camera.position.set(0, 0, 5);
            this.camera.up.set(0, 1, 0);
            this.camera.lookAt(0, 0, 0);
            this.camera.name = 'cameraTop';
        }

        if (type === ViewType.SIDE) {
            this.camera.position.set(0, 5, 0);
            this.camera.up.set(0, 0, 1);
            this.camera.lookAt(0, 0, 0);
            this.camera.name = 'cameraSide';
        }

        if (type === ViewType.FRONT) {
            this.camera.position.set(3, 0, 0);
            this.camera.up.set(0, 0, 1);
            this.camera.lookAt(0, 0, 0);
            this.camera.name = 'cameraFront';
        }

        this.renderer.setSize(width, height);

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        // // this.controls.listenToKeyEvents(window); // optional
        // this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
        // this.controls.dampingFactor = 0.05;
        // this.controls.screenSpacePanning = false;
        // // this.controls.minDistance = CONST.MIN_DISTANCE;
        // // this.controls.maxDistance = CONST.MAX_DISTANCE;
        // this.controls.maxPolarAngle = 0;
        // this.controls.maxAzimuthAngle = 0;
        // // this.controls.maxZoom = 3;
        // this.controls.mouseButtons.LEFT = null;
        // this.controls.mouseButtons.RIGHT = null;
        // this.controls.saveState();

        domElement.addEventListener('mousedown', this.startAction.bind(this));
        domElement.addEventListener('mousemove', this.moveAction.bind(this));
        domElement.addEventListener('mouseup', this.completeAction.bind(this));
        domElement.addEventListener('mouseleave', this.completeAction.bind(this));

        domElement.addEventListener(
            'wheel',
            (event: WheelEvent): void => {
                event.preventDefault();
                this.setHelperSize();
            },
            { passive: false },
        );

        this.initRotationHelper();
        this.setupResizeHelper();
        this.setupResizeFaceHelper();
        this.setDefaultZoom();
        this.initPlane();
    }

    // public add(object: THREE.Object3D) {
    //     this.object = object.clone();
    //     this.points.add(object);
    // }

    private initPlane() {
        // const canvasView = this.renderer.domElement;

        const scenePlane = new THREE.Mesh(
            // new THREE.PlaneGeometry(
            //     canvasView.offsetHeight,
            //     canvasView.offsetWidth,
            //     canvasView.offsetHeight,
            //     canvasView.offsetWidth,
            // ),
            new THREE.PlaneGeometry(1000, 1000, 1, 1),
            new THREE.MeshBasicMaterial({
                // color: 0xffffff,
                color: 'green',
                // alphaTest: 0,
                visible: false,
                transparent: true,
                opacity: 0.5,
            }),
        );
        scenePlane.position.set(0, 0, 0);
        if (this.type === ViewType.TOP) {
            scenePlane.name = Planes.TOP;
        } else if (this.type === ViewType.SIDE) {
            scenePlane.rotation.set(-Math.PI / 2, Math.PI / 2000, Math.PI);
            scenePlane.name = Planes.SIDE;
        } else if (this.type === ViewType.FRONT) {
            scenePlane.rotation.set(0, Math.PI / 2, 0);
            scenePlane.name = Planes.FRONT;
        }
        (scenePlane.material as THREE.MeshBasicMaterial).side = THREE.DoubleSide;
        (scenePlane as any).verticesNeedUpdate = true;

        this.scenePlane = scenePlane;
        this.scene.add(this.scenePlane);
    }

    // 实际展示的。移动的与
    private initRotationHelper(): void {
        if (
            // this.controller.data.selected instanceof LanelineMesh ||
            this.controller.data.selected instanceof SphereModel
        ) {
            return;
        }
        const sphereGeometry = new THREE.SphereGeometry(helper_radius);
        // const sphereMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff', opacity: 1, visible: true });
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 'green', opacity: 1, visible: true });
        const rotationHelper = new THREE.Mesh(sphereGeometry, sphereMaterial);
        rotationHelper.visible = false;
        rotationHelper.frustumCulled = false;
        rotationHelper.name = 'globalRotationHelper';
        this.globalHelper.rotation = [];
        this.globalHelper.rotation.push(rotationHelper);
        this.scene.add(rotationHelper);
    }

    private setupResizeHelper(): void {
        if (
            // this.controller.data.selected instanceof LanelineMesh ||
            this.controller.data.selected instanceof SphereModel
        ) {
            return;
        }
        const sphereGeometry = new THREE.SphereGeometry(helper_radius);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 'yellow', opacity: 1, visible: true });
        // const sphereMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff', opacity: 1, visible: true });
        const helpers: THREE.Mesh<THREE.SphereGeometry, THREE.MeshBasicMaterial>[] = [];
        this.globalHelper.resize = [];
        const dirs = [
            PositionDirection.top_left,
            PositionDirection.top_right,
            PositionDirection.bottom_right,
            PositionDirection.bottom_left,
        ];
        dirs.forEach((dir: PositionDirection, i: number) => {
            helpers[i] = new THREE.Mesh(sphereGeometry.clone(), sphereMaterial.clone());
            helpers[i].name = `globalResizeHelper_${dir}`;
            this.globalHelper.resize.push(helpers[i]);
            helpers[i].visible = false;
            helpers[i].frustumCulled = false;

            this.scene.add(helpers[i]);
        });
        // for (let i = 0; i < 8; i++) {
        // }
    }

    private setupResizeFaceHelper(): void {
        if (
            // this.controller.data.selected instanceof LanelineMesh ||
            this.controller.data.selected instanceof SphereModel
        ) {
            return;
        }
        const sphereGeometry = new THREE.SphereGeometry(helper_radius);
        const sphereMaterial = new THREE.MeshBasicMaterial({ color: 'blue', opacity: 1, visible: true });
        // const sphereMaterial = new THREE.MeshBasicMaterial({ color: '#ffffff', opacity: 1, visible: true });
        const helpers: THREE.Mesh<THREE.SphereGeometry, THREE.MeshBasicMaterial>[] = [];
        this.globalHelper.resizeFace = [];
        const dirs = [PositionDirection.top, PositionDirection.right, PositionDirection.bottom, PositionDirection.left];
        dirs.forEach((dir: PositionDirection, i: number) => {
            helpers[i] = new THREE.Mesh(sphereGeometry.clone(), sphereMaterial.clone());
            helpers[i].name = `globalResizeFaceHelper_${dir}`;
            this.globalHelper.resizeFace.push(helpers[i]);
            helpers[i].visible = false;
            helpers[i].frustumCulled = false;

            this.scene.add(helpers[i]);
        });
    }

    public setHelperVisibility(visibility: boolean): void {
        if (this.globalHelper.rotation.length) {
            this.globalHelper.rotation.forEach((helper) => {
                helper.visible = visibility;
            });
        }
        if (this.globalHelper.resize.length) {
            this.globalHelper.resize.forEach((helper) => {
                helper.visible = visibility;
            });
        }
        if (this.globalHelper.resizeFace.length) {
            this.globalHelper.resizeFace.forEach((helper) => {
                helper.visible = visibility;
            });
        }
    }

    public updateRotationHelperPos(): void {
        const point = new Vector3(0, 0, 0);
        switch (this.type) {
            case ViewType.TOP:
                point.setX(0.5 + constants.ROTATION_HELPER_OFFSET * 2);
                break;

            case ViewType.SIDE:
                point.setZ(0.5 + constants.ROTATION_HELPER_OFFSET * 2);
                break;

            case ViewType.FRONT:
                point.setZ(0.5 + constants.ROTATION_HELPER_OFFSET * 2);
                break;

            default:
                break;
        }
        if (this.object) {
            this.object.localToWorld(point);
            const globalRotationHelper = this.scene.getObjectByName('globalRotationHelper');
            if (globalRotationHelper) {
                globalRotationHelper.position.set(point.x, point.y, point.z);
            }
        }
    }

    public updateResizeHelperPos(): void {
        const point_top_left = new Vector3(0, 0, 0);
        const point_top_right = new Vector3(0, 0, 0);
        const point_bottom_right = new Vector3(0, 0, 0);
        const point_bottom_left = new Vector3(0, 0, 0);
        // switch (this.type) {
        //     case ViewType.TOP:
        //         point_top_left.set(-0.5, 0.5, 0.5);
        //         point_top_right.set(0.5, 0.5, 0.5);
        //         point_bottom_right.set(0.5, -0.5, 0.5);
        //         point_bottom_left.set(-0.5, -0.5, 0.5);
        //         break;

        //     case ViewType.SIDE:
        //         point_top_left.set(0.5, 0.5, 0.5);
        //         point_top_right.set(-0.5, 0.5, 0.5);
        //         point_bottom_right.set(-0.5, 0.5, -0.5);
        //         point_bottom_left.set(0.5, 0.5, -0.5);
        //         break;

        //     case ViewType.FRONT:
        //         point_top_left.set(0.5, -0.5, 0.5);
        //         point_top_right.set(0.5, 0.5, 0.5);
        //         point_bottom_right.set(0.5, 0.5, -0.5);
        //         point_bottom_left.set(0.5, -0.5, -0.5);
        //         break;

        //     default:
        //         break;
        // }
        switch (this.type) {
            case ViewType.TOP:
                point_top_left.set(-0.5, 0.5, 0);
                point_top_right.set(0.5, 0.5, 0);
                point_bottom_right.set(0.5, -0.5, 0);
                point_bottom_left.set(-0.5, -0.5, 0);
                break;

            case ViewType.SIDE:
                point_top_left.set(0.5, 0, 0.5);
                point_top_right.set(-0.5, 0, 0.5);
                point_bottom_right.set(-0.5, 0, -0.5);
                point_bottom_left.set(0.5, 0, -0.5);
                break;

            case ViewType.FRONT:
                point_top_left.set(0, -0.5, 0.5);
                point_top_right.set(0, 0.5, 0.5);
                point_bottom_right.set(0, 0.5, -0.5);
                point_bottom_left.set(0, -0.5, -0.5);
                break;

            default:
                break;
        }
        const dirs = [
            PositionDirection.top_left,
            PositionDirection.top_right,
            PositionDirection.bottom_right,
            PositionDirection.bottom_left,
        ];
        const pos = [point_top_left, point_top_right, point_bottom_right, point_bottom_left];
        if (this.object) {
            dirs.forEach((dir: PositionDirection, i: number) => {
                const point = pos[i];
                this.object.localToWorld(point);
                this.globalHelper.resize[i];
                const globalResizeHelper = this.scene.getObjectByName(`globalResizeHelper_${dir}`);
                if (globalResizeHelper) {
                    if (this.type === ViewType.TOP) {
                        globalResizeHelper.position.set(point.x, point.y, point.z);
                    } else if (this.type === ViewType.SIDE) {
                        globalResizeHelper.position.set(point.x, point.y, point.z);
                    } else if (this.type === ViewType.FRONT) {
                        globalResizeHelper.position.set(point.x, point.y, point.z);
                    }
                }
            });
        }
    }

    public updateResizeFaceHelperPos(): void {
        const point_top = new Vector3(0, 0, 0);
        const point_right = new Vector3(0, 0, 0);
        const point_bottom = new Vector3(0, 0, 0);
        const point_left = new Vector3(0, 0, 0);
        // switch (this.type) {
        //     case ViewType.TOP:
        //         point_top.set(0, 0.5, 0.5);
        //         point_right.set(0.5, 0, 0.5);
        //         point_bottom.set(0, -0.5, 0.5);
        //         point_left.set(-0.5, 0, 0.5);
        //         break;

        //     case ViewType.SIDE:
        //         point_top.set(0, 0.5, 0.5);
        //         point_right.set(-0.5, 0.5, 0);
        //         point_bottom.set(0, 0.5, -0.5);
        //         point_left.set(0.5, 0.5, 0);
        //         break;

        //     case ViewType.FRONT:
        //         point_top.set(0.5, 0, 0.5);
        //         point_right.set(0.5, 0.5, 0);
        //         point_bottom.set(0.5, 0, -0.5);
        //         point_left.set(0.5, -0.5, 0);
        //         break;

        //     default:
        //         break;
        // }
        switch (this.type) {
            case ViewType.TOP:
                point_top.set(0, 0.5, 0);
                point_right.set(0.5, 0, 0);
                point_bottom.set(0, -0.5, 0);
                point_left.set(-0.5, 0, 0);
                break;

            case ViewType.SIDE:
                point_top.set(0, 0, 0.5);
                point_right.set(-0.5, 0, 0);
                point_bottom.set(0, 0, -0.5);
                point_left.set(0.5, 0, 0);
                break;

            case ViewType.FRONT:
                point_top.set(0, 0, 0.5);
                point_right.set(0, 0.5, 0);
                point_bottom.set(0, 0, -0.5);
                point_left.set(0, -0.5, 0);
                break;

            default:
                break;
        }
        const dirs = [PositionDirection.top, PositionDirection.right, PositionDirection.bottom, PositionDirection.left];
        const pos = [point_top, point_right, point_bottom, point_left];
        if (this.object) {
            dirs.forEach((dir: PositionDirection, i: number) => {
                const point = pos[i];
                this.object.localToWorld(point);
                this.globalHelper.resize[i];
                const globalResizeFaceHelper = this.scene.getObjectByName(`globalResizeFaceHelper_${dir}`);
                if (globalResizeFaceHelper) {
                    // globalResizeFaceHelper.position.set(point.x, point.y, point.z);
                    if (this.type === ViewType.TOP) {
                        globalResizeFaceHelper.position.set(point.x, point.y, point.z);
                    } else if (this.type === ViewType.SIDE) {
                        globalResizeFaceHelper.position.set(point.x, point.y, point.z);
                    } else if (this.type === ViewType.FRONT) {
                        globalResizeFaceHelper.position.set(point.x, point.y, point.z);
                    }
                }
            });
        }
    }

    public setHelperSize(): void {
        // if (this.controller.data.selected instanceof LanelineMesh) {
        //     return;
        // }

        const factor = (this.camera.top - this.camera.bottom) / this.camera.zoom;
        const rotationObject = this.scene.getObjectByName('globalRotationHelper');
        if (rotationObject) {
            rotationObject.scale.set(1, 1, 1).multiplyScalar(factor / 10);
        }

        const resizeDirs = [
            PositionDirection.top_left,
            PositionDirection.top_right,
            PositionDirection.bottom_right,
            PositionDirection.bottom_left,
        ];
        const resizeFaceDirs = [
            PositionDirection.top,
            PositionDirection.right,
            PositionDirection.bottom,
            PositionDirection.left,
        ];

        resizeDirs.forEach((dir: PositionDirection) => {
            const resizeObject = this.scene.getObjectByName(`globalResizeHelper_${dir}`);
            if (resizeObject) {
                resizeObject.scale.set(1, 1, 1).multiplyScalar(factor / 10);
            }
        });

        resizeFaceDirs.forEach((dir: PositionDirection) => {
            const resizeObject = this.scene.getObjectByName(`globalResizeFaceHelper_${dir}`);
            if (resizeObject) {
                resizeObject.scale.set(1, 1, 1).multiplyScalar(factor / 10);
            }
        });
    }

    public positionAllViews(x: number, y: number, z: number, animation: boolean): void {
        if (this.type === ViewType.TOP) {
            this.camera.position.set(x, y, z + 8);
            this.camera.lookAt(x, y, z);
            this.camera.zoom = CONST.FOV_DEFAULT;
        }

        if (this.type === ViewType.SIDE) {
            this.camera.position.set(x, y + 8, z);
            this.camera.lookAt(x, y, z);
            this.camera.zoom = CONST.FOV_DEFAULT;
        }

        if (this.type === ViewType.FRONT) {
            this.camera.position.set(x + 8, y, z);
            this.camera.lookAt(x, y, z);
            this.camera.zoom = CONST.FOV_DEFAULT;
        }
    }

    public setDefaultZoom(): void {
        if (!this.activeID) {
            this.camera.zoom = CONST.FOV_DEFAULT;
            this.camera.updateProjectionMatrix();
        } else {
            this.camera.zoom = 1;
            // this.camera.zoom = x2 / 100;
            this.camera.updateProjectionMatrix();
            this.camera.updateMatrix();
            // this.setHelperSize();
            this.setHelperSize();
        }
    }

    private updateMouseCursor() {
        this.rayCaster.renderer.setFromCamera(this.rayCaster.mouseVector, this.camera);
        const { renderer } = this.rayCaster;
        const object = this.getActiveObject();
        if (object && this.editable() && [ViewType.TOP, ViewType.SIDE, ViewType.FRONT].includes(this.type)) {
            // 如果是批注
            if (this.controller.data.activeElement.issueID) {
                const intersects = renderer.intersectObjects([object], false);
                if (intersects.length !== 0) {
                    // 目标物本身
                    this.mouseCursorMove();
                }
                return false;
            }
            // const rotationHelper = object.getObjectByName('rotationHelper');
            const intersectsHelperRotation = this.rayCaster.renderer.intersectObjects(
                this.globalHelper.rotation,
                false,
            );

            if (intersectsHelperRotation?.length) {
                if (this.type === ViewType.TOP) {
                    this.renderer.domElement.style.cursor = 'ns-resize';
                } else {
                    this.renderer.domElement.style.cursor = 'ew-resize';
                }
            } else {
                const intersects = renderer.intersectObjects([object], false);
                if (intersects.length !== 0) {
                    const { object } = intersects[0];
                    if (object instanceof Bezier) {
                        if (object.activeMesh) {
                            // 目标物本身
                            this.mouseCursorMove();
                        }
                    } else {
                        // 目标物本身
                        this.mouseCursorMove();
                    }
                }

                const intersectsHelperResize = this.rayCaster.renderer.intersectObjects(
                    this.globalHelper.resize,
                    false,
                );

                if (intersectsHelperResize.length !== 0) {
                    if ([PositionDirection.top_right, PositionDirection.bottom_left].includes(this.mouseResizePosDir)) {
                        this.renderer.domElement.style.cursor = 'nesw-resize';
                    } else if (
                        [PositionDirection.top_left, PositionDirection.bottom_right].includes(this.mouseResizePosDir)
                    ) {
                        this.renderer.domElement.style.cursor = 'nwse-resize';
                    }
                }

                const intersectsHelperResizeFace = this.rayCaster.renderer.intersectObjects(
                    this.globalHelper.resizeFace,
                    false,
                );

                if (intersectsHelperResizeFace.length !== 0) {
                    const helperName = intersectsHelperResizeFace[0].object.name;
                    if (helperName.includes(PositionDirection.top)) {
                        this.renderer.domElement.style.cursor = 'ns-resize';
                    } else if (helperName.includes(PositionDirection.right)) {
                        this.renderer.domElement.style.cursor = 'ew-resize';
                    } else if (helperName.includes(PositionDirection.bottom)) {
                        this.renderer.domElement.style.cursor = 'ns-resize';
                    } else if (helperName.includes(PositionDirection.left)) {
                        this.renderer.domElement.style.cursor = 'ew-resize';
                    }
                }
            }
        }
        return false;
    }

    private getIntersects(): any[] {
        if (![Mode.GROUP, Mode.IDLE].includes(this.mode)) return [];

        const { mouseVector, renderer } = this.rayCaster;
        // 通过摄像机和鼠标位置更新射线
        // const { children } = this.points;
        renderer.setFromCamera(mouseVector, this.camera);
        const intersects = renderer.intersectObjects(this.objects, false);
        return intersects;
    }

    private startAction(event: MouseEvent): void {
        this.updateMousePosition(event);
        this.startMode = this.mode;

        if (event.detail !== 1 || !this.points) return;
        // if (![Mode.IDLE, Mode.EDIT].includes(this.mode)) return;
        if (!this.isCanActive) return;

        const rect = this.renderer.domElement.getBoundingClientRect();

        const diffX = event.clientX - rect.left;
        const diffY = event.clientY - rect.top;

        this.action.rotation.screenInit = { x: diffX, y: diffY };
        this.action.rotation.screenMove = { x: diffX, y: diffY };

        const { clientID, issueID } = this.controller.data.activeElement;

        // this.a

        if (this.controller.data.selected && this.editable()) {
            this.action.actionView = this.type;
            this.mode = Mode.EDIT;
            this.action.selectable = false;
            // this.action.selectable = false;
            // if (this.controller.data.selected instanceof LanelineMesh) {
            //     this.rayCaster.renderer.setFromCamera(this.rayCaster.mouseVector, this.camera);
            //     // console.log('相机位置：', this.camera.position)
            //     const group = this.controller.data.selected[this.type];
            //     const points = group.children.filter((obj) => {
            //         if (!(obj as THREE.Line).isLine) {
            //             // (obj as THREE.Mesh).geometry.computeBoundingSphere();
            //             return true;
            //         }
            //         return false;
            //     });
            //     // console.log(`获取的旧ID们${this.type}：`, points.map(poi => poi.id));
            //     const intersectsBox = this.rayCaster.renderer.intersectObjects(points, false);
            //     if (intersectsBox.length) {
            //         this.translateReferencePlane(intersectsBox[0].object.position);
            //     }
            // }
        }
    }

    private moveAction(event: MouseEvent): void {
        this.updateMousePosition(event);
        if (!this.activeID) return;

        const canvas = this.renderer.domElement;
        const rect = canvas.getBoundingClientRect();
        const { mouseVector } = this.rayCaster as { mouseVector: THREE.Vector2 };
        const diffX = event.clientX - rect.left;
        const diffY = event.clientY - rect.top;

        mouseVector.x = (diffX / canvas.clientWidth) * 2 - 1;
        mouseVector.y = -(diffY / canvas.clientHeight) * 2 + 1;

        this.action.rotation.screenMove = { x: diffX, y: diffY };
        // this.action.movePosition.x = diffX;
        // this.action.movePosition.y = diffY;
    }

    private translateReferencePlane(coordinates: any): void {
        if (coordinates) {
            this.scenePlane.position.x = coordinates.x;
            this.scenePlane.position.y = coordinates.y;
            this.scenePlane.position.z = coordinates.z;
        }
    }

    private resetAction(): void {
        this.action.actionView = null;
        this.action.isAction = false;

        this.action.translation.status = false;
        this.action.translation.helper = null;

        this.action.rotation.status = false;
        this.action.rotation.helper = null;
        this.action.rotation.recentMouseVector = new THREE.Vector2(0, 0);

        this.action.resize.status = false;
        this.action.resize.helper = null;
        this.action.resize.recentMouseVector = new THREE.Vector2(0, 0);

        this.action.resizeFace.status = false;
        this.action.resizeFace.helper = null;
        this.action.resizeFace.recentMouseVector = new THREE.Vector2(0, 0);

        this.action.selectable = true;

        this.controller.mode = this.startMode || this.controller.mode;
        this.startMode = undefined;
    }

    private completeAction(e: MouseEvent): void {
        this.updateMousePosition(e, true);

        if (this.mode === Mode.DRAW) {
            return;
        }

        const { actionView, isAction } = this.action;

        if (!isAction) {
            this.resetAction();
            return;
        }

        const { x, y, z } = this.controller.data.selected[actionView].position;

        if (this.controller.data.activeElement.clientID) {
            // if (this.controller.data.selected instanceof LanelineMesh) {
            //     const points = this.controller.data.selected.points;

            //     const state = this.controller.data.objects.get(Number(this.controller.data.activeElement.clientID));
            //     this.dispatchEvent(
            //         new CustomEvent('canvas.edited', {
            //             bubbles: false,
            //             cancelable: true,
            //             detail: {
            //                 state,
            //                 points,
            //             },
            //         }),
            //     );

            //     if (this.action.translation.status) {
            //         this.parent.resetThreeCamera();
            //     }
            //     // this.resetCamera();
            //     const opject = this.controller.data.selected[this.type].getObjectByName(this.action.movePointName);
            //     const { position } = opject;
            //     this.translateReferencePlane(position);
            //     this.resetAction();
            //     return;
            // }

            const { x: width, y: height, z: depth } = this.controller.data.selected[actionView].scale;
            const { x: rotationX, y: rotationY, z: rotationZ } = this.controller.data.selected[actionView].rotation;
            const points = [x, y, z, rotationX, rotationY, rotationZ, width, height, depth, 0, 0, 0, 0, 0, 0, 0];

            const state = this.controller.data.objects.get(Number(this.controller.data.activeElement.clientID));
            this.dispatchEvent(
                new CustomEvent('canvas.edited', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        state,
                        points,
                    },
                }),
            );
        }

        if (this.controller.data.activeElement.issueID) {
            const [issue] = this.controller.data.issueRegions.filter(
                (_issue: any): boolean => _issue.id === this.controller.data.activeElement.issueID,
            );
            // const { x, y, z } = this.model.data.selected[scan].position;
            const points = [x, y, z];

            this.dispatchEvent(
                new CustomEvent('canvas.issueedited', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        issue,
                        points,
                    },
                }),
            );
        }

        // if (this.action.rotation.status) {
        //     this.action?.detachCamera(this.type);
        // }
        // this.resetCamera();
        this.translateReferencePlane(this.object?.position);
        this.resetAction();
    }

    // private resetCamera() {
    //     if (this.activeID) {
    //         const object = this.getObjectByName(this.activeID);
    //         if (object) {
    //             if (this.type === ViewType.TOP) {
    //                 this.camera.position.set(0, 0, 2);
    //             }
    //             if (this.type === ViewType.SIDE) {
    //                 this.camera.position.set(0, 2, 0);
    //             }
    //             if (this.type === ViewType.FRONT) {
    //                 this.camera.position.set(2, 0, 0);
    //             }

    //             this.camera.lookAt(0, 0, 0);

    //             this.camera.applyQuaternion(object.quaternion);
    //             this.camera.position.applyMatrix4(object.matrixWorld);

    //             this.camera.updateProjectionMatrix();
    //         }
    //     }
    // }

    // private initCoordinatHelper(): void {
    //     // this.scene.add(axesHelper);
    //     // this.action.loading = false;
    // }

    private dispatchEvent(event: CustomEvent): void {
        this.parent.dispatchEvent(event);
    }

    // private clearScene(): void {
    //     this.scene.clear();
    // }

    // #region 不断更新的事件

    private rotateCube(instance: CuboidModel, direction: number): void {
        switch (this.type) {
            case ViewType.TOP:
                instance.perspective.rotateZ(direction);
                instance.top.rotateZ(direction);
                instance.side.rotateZ(direction);
                instance.front.rotateZ(direction);
                this.rotateCamera(direction);
                break;
            case ViewType.FRONT:
                instance.perspective.rotateX(direction);
                instance.top.rotateX(direction);
                instance.side.rotateX(direction);
                instance.front.rotateX(direction);
                this.rotateCamera(direction);
                break;
            case ViewType.SIDE:
                instance.perspective.rotateY(direction);
                instance.top.rotateY(direction);
                instance.side.rotateY(direction);
                instance.front.rotateY(direction);
                this.rotateCamera(direction);
                break;
            default:
        }
    }

    private rotateCamera(direction: any): void {
        // this.camera.rotateZ(direction);
    }

    private updateLanelinePosition(): void {
        const intersects = this.rayCaster.renderer.intersectObjects(
            [this.scene.getObjectByName(`${this.type}Plane`)],
            true,
        );

        if (intersects.length !== 0 && intersects[0].point) {
            const coordinates = intersects[0].point;
            this.action.translation.coordinates = coordinates;

            // const { children } = this.controller.data.selected as LanelineMesh;

            let localCoordinates = coordinates;

            if (this.action.translation.status) {
                localCoordinates = coordinates
                    .clone()
                    .sub(this.action.translation.offset)
                    .applyMatrix4(this.action.translation.inverseMatrix);
            }

            // (this.controller.data.selected as LanelineMesh).updatePointMeshesByPointName(
            //     this.action.movePointName,
            //     localCoordinates.clone(),
            // );

            // perspective.position.copy(localCoordinates.clone());
            // top.position.copy(localCoordinates.clone());
            // side.position.copy(localCoordinates.clone());
            // front.position.copy(localCoordinates.clone());
        }
    }

    private renderTranslateAction() {
        // if (this.controller.data.selected instanceof LanelineMesh) {
        //     this.updateLanelinePosition();
        //     return;
        // }
        if (
            this.action.translation.helper.x === this.rayCaster.mouseVector.x &&
            this.action.translation.helper.y === this.rayCaster.mouseVector.y
        ) {
            return;
        }
        const intersects = this.rayCaster.renderer.intersectObjects(
            [this.scene.getObjectByName(`${this.type}Plane`)],
            true,
        );

        if (intersects.length !== 0 && intersects[0].point) {
            const coordinates = intersects[0].point;
            this.action.translation.coordinates = coordinates;
            this.moveObject(coordinates);
            // this.parent.resetCameras();
        }
    }

    private rotatePlane(direction: number): void {
        const sceneTopPlane = this.controller.data.selected.top.parent.parent.getObjectByName(Planes.TOP);
        const sceneSidePlane = this.controller.data.selected.side.parent.parent.getObjectByName(Planes.SIDE);
        const sceneFrontPlane = this.controller.data.selected.front.parent.parent.getObjectByName(Planes.FRONT);
        switch (this.type) {
            case ViewType.TOP:
                sceneTopPlane.rotateZ(direction);
                sceneSidePlane.rotateY(direction);
                sceneFrontPlane.rotateX(-direction);
                break;
            case ViewType.SIDE:
                sceneTopPlane.rotateY(direction);
                sceneSidePlane.rotateZ(direction);
                sceneFrontPlane.rotateY(direction);
                break;
            case ViewType.FRONT:
                sceneTopPlane.rotateX(direction);
                sceneSidePlane.rotateX(-direction);
                sceneFrontPlane.rotateZ(direction);
                break;
            default: {
                const {
                    top: objectTopView,
                    side: objectSideView,
                    front: objectFrontView,
                } = this.controller.data.selected;

                sceneTopPlane.position.set(0, 0, 0);
                sceneSidePlane.position.set(0, 0, 0);
                sceneFrontPlane.position.set(0, 0, 0);

                sceneTopPlane.rotation.set(0, 0, 0);
                sceneSidePlane.rotation.set(-Math.PI / 2, Math.PI / 2000, Math.PI);
                sceneFrontPlane.rotation.set(0, Math.PI / 2, 0);

                sceneTopPlane.applyQuaternion(objectTopView.quaternion);
                sceneSidePlane.applyQuaternion(objectSideView.quaternion);
                sceneFrontPlane.applyQuaternion(objectFrontView.quaternion);

                this.translateReferencePlane(objectTopView.position);
            }
        }
    }
    private renderRotateAction(): void {
        // if (this.controller.data.selected instanceof LanelineMesh) return;
        let rotationSpeed = Math.PI / CONST.ROTATION_SPEED;
        if (this.controller.data.isFineTuning) {
            // rotationSpeed /= 10;
            rotationSpeed = Math.PI / CONST.ROTATION_FINETUNING_SPEED;
        }

        const { renderer } = this;
        const canvas = renderer.domElement;
        if (!canvas) return;
        const canvasCentre = {
            x: canvas.offsetLeft + canvas.offsetWidth / 2,
            y: canvas.offsetTop + canvas.offsetHeight / 2,
        };
        if (
            this.action.rotation.screenInit.x === this.action.rotation.screenMove.x &&
            this.action.rotation.screenInit.y === this.action.rotation.screenMove.y
        ) {
            return;
        }

        if (
            this.action.rotation.recentMouseVector.x === this.rayCaster.mouseVector.x &&
            this.action.rotation.recentMouseVector.y === this.rayCaster.mouseVector.y
        ) {
            return;
        }
        this.action.rotation.recentMouseVector = this.rayCaster.mouseVector.clone();
        if (isLeft(canvasCentre, this.action.rotation.screenInit, this.action.rotation.screenMove)) {
            this.rotateCube(this.controller.data.selected as CuboidModel, -rotationSpeed);
            this.rotatePlane(-rotationSpeed);
        } else {
            this.rotateCube(this.controller.data.selected as CuboidModel, rotationSpeed);
            this.rotatePlane(rotationSpeed);
        }
        this.action.rotation.screenInit.x = this.action.rotation.screenMove.x;
        this.action.rotation.screenInit.y = this.action.rotation.screenMove.y;
        this.parent.resetCameras();
    }

    private moveObject(coordinates: THREE.Vector3): void {
        const { perspective, top, side, front } = this.controller.data.selected;
        let localCoordinates = coordinates;

        if (this.action.translation.status) {
            localCoordinates = coordinates
                .clone()
                .sub(this.action.translation.offset)
                .applyMatrix4(this.action.translation.inverseMatrix);
        }

        const { clientID, issueID } = this.controller.data.activeElement;

        const state = this.controller.data.objects.get(clientID);
        const issue = this.controller.data.objects.get(issueID);

        if (![ShapeType.bezier2, ShapeType.laneline].includes(state?.shapeType)) {
            // 不是车道线
            perspective.position.copy(localCoordinates.clone());
            top.position.copy(localCoordinates.clone());
            side.position.copy(localCoordinates.clone());
            front.position.copy(localCoordinates.clone());
        } else {
            console.log('移动中：', perspective);
        }
    }

    private initiateAction(): void {
        this.rayCaster.renderer.setFromCamera(this.rayCaster.mouseVector, this.camera);
        // if (this.controller.data.selected instanceof LanelineMesh) {
        //     // 车道线
        //     const group = this.controller.data.selected[this.type];
        //     const intersectsBox = this.rayCaster.renderer.intersectObjects(
        //         group.children.filter((obj) => !(obj as THREE.Line).isLine),
        //         false,
        //     );
        //     // const intersectsBox = this.rayCaster.renderer.intersectObjects([this.object], false);

        //     const intersectsPointCloud = this.rayCaster.renderer.intersectObjects([this.scenePlane], true);

        //     if (intersectsBox.length && intersectsPointCloud.length) {
        //         this.action.translation.status = true;
        //         this.action.isAction = true;
        //         this.action.translation.inverseMatrix = intersectsBox[0].object.parent.matrixWorld.invert();
        //         this.action.movePointName = intersectsBox[0].object.name;

        //         this.action.translation.offset = intersectsPointCloud[0].point.sub(
        //             new THREE.Vector3().setFromMatrixPosition(intersectsBox[0].object.matrixWorld),
        //         );
        //     }
        //     return;
        // }

        const { clientID, issueID } = this.controller.data.activeElement;
        const state = this.controller.data.objects.get(clientID);
        const issue = this.controller.data.issueRegions.find((region) => region.id === issueID);
        // const state = this.controller.data.selected.perspective.userData as ObjectState | Issue;

        if (issue) {
            // 批注
            if (issue.resolve || issue.readOnly) return;
        } else if (state) {
            if (state.lock || this.controller.configuration.forceDisableEditing) return;
        } else {
            // 没有其他类型了
            return;
        }

        if (state) {
            const intersectsHelperResize = this.rayCaster.renderer.intersectObjects(this.globalHelper.resize, false);
            // console.log('碰撞检测1：', intersectsHelperResize);
            if (intersectsHelperResize.length !== 0) {
                this.action.resize.helper = this.rayCaster.mouseVector.clone();
                this.action.resize.status = true;
                this.action.isAction = true;
                // this.views.top.controls.enabled = false;
                // this.views.side.controls.enabled = false;
                // this.views.front.controls.enabled = false;
                const { x, y, z } = this.object.scale;
                this.action.resize.initScales = { x, y, z };
                this.action.resize.memScales = { x, y, z };
                this.action.resize.frontBool = false;
                this.action.resize.resizeVector = new THREE.Vector3(0, 0, 0);
                return;
            }
            const intersectsHelperRotation = this.rayCaster.renderer.intersectObjects(
                this.globalHelper.rotation,
                false,
            );

            // console.log('碰撞检测2：', intersectsHelperRotation);
            if (intersectsHelperRotation.length !== 0) {
                this.action.rotation.helper = this.rayCaster.mouseVector.clone();
                this.action.rotation.status = true;
                this.action.isAction = true;
                // this.views.top.controls.enabled = false;
                // this.views.side.controls.enabled = false;
                // this.views.front.controls.enabled = false;
                // this.action?.attachCamera(this.type);
                return;
            }

            const intersectsHelperResizeFace = this.rayCaster.renderer.intersectObjects(
                this.globalHelper.resizeFace,
                false,
            );

            // console.log('碰撞检测1：', intersectsHelperResize);
            if (intersectsHelperResizeFace.length !== 0) {
                this.action.resizeFace.helper = this.rayCaster.mouseVector.clone();
                this.action.resizeFace.status = true;
                this.action.isAction = true;
                // this.views.top.controls.enabled = false;
                // this.views.side.controls.enabled = false;
                // this.views.front.controls.enabled = false;
                const { x, y, z } = this.object.scale;
                this.action.resizeFace.initScales = { x, y, z };
                this.action.resizeFace.memScales = { x, y, z };
                this.action.resizeFace.posName = intersectsHelperResizeFace[0].object.name;
                this.action.resizeFace.resizeVector = new THREE.Vector3(0, 0, 0);
                return;
            }
            if (state.pinned) return;
        }

        const intersectsBox = this.rayCaster.renderer.intersectObjects([this.object], false);

        const intersectsPointCloud = this.rayCaster.renderer.intersectObjects([this.scenePlane], true);

        if (intersectsBox.length !== 0 && intersectsPointCloud.length !== 0) {
            // console.log('有交互：', intersectsBox);
            // if (state && state.shapeType === ShapeType.bezier2) {
            //     console.log('准备移动：', intersectsBox);
            // }
            this.action.translation.helper = this.rayCaster.mouseVector.clone();

            this.action.translation.inverseMatrix = intersectsBox[0].object.parent.matrixWorld.invert();

            this.action.translation.offset = intersectsPointCloud[0].point.sub(
                new THREE.Vector3().setFromMatrixPosition(intersectsBox[0].object.matrixWorld),
            );
            this.action.translation.status = true;
            this.action.isAction = true;
            // this.views.top.controls.enabled = false;
            // this.views.side.controls.enabled = false;
            // this.views.front.controls.enabled = false;
        }
    }

    private renderRayCaster = (): void => {
        // if (this.mode === Mode.IDLE) {
        if (this.isCanActive) {
            this.mouseCursorDefault();
            // if (this.isMouseMoveOnObject()) {
            //     this.mouseCursorMove();
            // }

            this.updateMouseCursor();
        }
    };

    public render(): void {
        // 没有加载完成无需执行接下来的操作
        if (!(this.camera && this.rayCaster)) return;

        // 如果视图窗口resize的话，重新计算相机位置和视图大小
        resizeRendererToDisplaySize(this.camera, this.renderer);

        // 实时更新相机与场景

        this.renderer.render(this.scene, this.camera);
        if (!this.points) return;

        // if (this.type === ViewType.TOP) {
        //     console.log('顶部相机：', this.camera);
        // }

        this.rayCaster.renderer.setFromCamera(this.rayCaster.mouseVector, this.camera);

        if (this.scene.children.length !== 0 && this.scene.context.children.length) {
            this.renderRayCaster();
            // this.resizeAction();
        }

        const { clientID, issueID } = this.controller.data.activeElement;

        if ((clientID || issueID) && this.action.actionView === this.type) {
            if (
                !(
                    this.action.translation.status ||
                    this.action.resize.status ||
                    this.action.rotation.status ||
                    this.action.resizeFace.status
                )
            ) {
                this.initiateAction();
            }

            if (this.action.isAction) {
                if (this.action.translation.status) {
                    this.renderTranslateAction();
                }
                if (this.action.rotation.status) {
                    this.renderRotateAction();
                    this.resetCameraLookEara();
                }

                // this.updateRotationHelperPos();
            } else {
                this.resetAction();
            }
        } else if ((clientID || issueID) && this.action.actionView !== this.type) {
            if (this.action.isAction && this.action.translation.status) {
                this.resetCamera();
            }
        }

        // this.initiateAction();
        // if (this.mode === Mode.EDIT) {
        //     if (!(this.action.translation || this.action.resize || this.action.rotation)) {
        //         this.initiateAction();
        //     }
        //     this.mouseCursorGrabbing();
        //     this.renderTranslateAction();
        // }
    }

    // #endregion

    public loadPointsFromPoints(points: THREE.Points[]) {
        this.scene.removeContexts();
        this.scene.addContext(...points.map((point) => point.clone()));
    }

    public loadPointsFromPositions(position: THREE.Float32BufferAttribute, color: THREE.Float32BufferAttribute): void {
        // const geometry = new THREE.BufferGeometry();
        // geometry.setAttribute('position', new THREE.Float32BufferAttribute(newPoints, itemSize));
        // // 自定义颜色
        // geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, itemSize));
        // geometry.computeBoundingSphere();
        // const material = new THREE.PointsMaterial();
        // material.size = 0.05;
        // material.vertexColors = true;
        // material.color.set(new THREE.Color(0xffffff));
        // const points = new THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>(geometry, material);
        // points.name = 'points';
        // this.setSceneChildren(points);
    }

    public clearObjects(name?: string[] | string) {
        // if (!name) {
        //     this.scene.clear();
        // }
        if (!name) {
            this.scene.clearObjects();
        }
        this.object = undefined;
        this.resetCamera();
        this.updateViewByObject();
    }

    private updateView() {
        const object = this.getActiveObject() as THREE.Mesh;
        if (object) {
            this.object = object;

            if (this.editable()) {
                this.initRotationHelper();
                this.setupResizeHelper();
                this.setupResizeFaceHelper();

                this.setHelperSize();
            }

            this.resetCamera();

            this.translateReferencePlane(object.position);
        }
    }

    // public updatePointsPositions(position: number[], color?: number[], intensity?: number[]): void {
    //     this.points.geometry.setAttribute('position', new THREE.Float32BufferAttribute(position, 3));
    //     if (color) {
    //         this.points.geometry.setAttribute('color', new THREE.Float32BufferAttribute(color, 3));
    //     }
    //     if (intensity) {
    //         this.points.geometry.setAttribute('intensity', new THREE.Float32BufferAttribute(intensity, 1));
    //     }

    //     this.points.geometry.computeBoundingSphere();

    //     this.updateRanle();
    //     // this.scene.add(this.scenePlane);
    //     // this.scene.updateMatrixWorld();

    //     // this.updateView();
    //     // 三个视角完全手动控制相机位置
    //     // this.controls.enabled = false;
    // }

    // private setSceneChildren(points: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>): void {
    //     this.clearObjects();
    //     this.scene.add(points);
    //     this.addNewHelper();

    //     this.initPlane();
    //     this.scene.add(this.scenePlane);

    //     this.scene.updateMatrixWorld();

    //     this.points = points;

    //     this.updateView();
    //     // this.setupResizeHelper();

    //     // 三个视角完全手动控制相机位置
    //     // this.controls.enabled = false;
    // }

    private updateViewByObject() {
        // const object = this.getActiveObject() as THREE.Mesh;
        if (this.object) {
            this.object = this.object;

            if (this.editable() && this.controller.data.activeElement.clientID) {
                this.setHelperVisibility(true);
            } else {
                this.setHelperVisibility(false);
            }

            // this.resetCamera();

            this.translateReferencePlane(this.object.position);
        } else {
            this.setHelperVisibility(false);
        }
    }

    public resetCamera(): void {
        // console.log('这里一直在运行吗？', this.object);
        let objectRadio = 0;
        let position = new Vector3(0, 0, 0);
        let quaternion = new Quaternion();

        if (this.activeID) {
            // 有选中对象
            if (this.object && (this.object as THREE.Mesh).isMesh) {
                position = this.object.position;
                quaternion = this.object.quaternion;
                if (this.type === ViewType.TOP) {
                    objectRadio = this.object.scale.z / 2;
                    this.camera.position.set(0, 0, objectRadio + 5);
                } else if (this.type === ViewType.SIDE) {
                    objectRadio = this.object.scale.y / 2;
                    this.camera.position.set(0, objectRadio + 5, 0);
                } else if (this.type === ViewType.FRONT) {
                    objectRadio = this.object.scale.x / 2;
                    this.camera.position.set(objectRadio + 3, 0, 0);
                }
                this.camera.lookAt(new Vector3(0, 0, 0));

                const matrix = new THREE.Matrix4().compose(position, quaternion, new Vector3(1, 1, 1));
                this.camera.applyMatrix4(matrix);

                const distance = this.camera.position.distanceTo(position);
                this.camera.near = objectRadio === 0 ? 0.1 : distance - objectRadio - 0.01;
                this.camera.far = objectRadio === 0 ? 2000 : distance + objectRadio + 0.01;

                this.camera.updateProjectionMatrix();
            }

            return;
        }

        if (this.type === ViewType.TOP) {
            this.camera.position.set(0, 0, objectRadio + 5);
        } else if (this.type === ViewType.SIDE) {
            this.camera.position.set(0, objectRadio + 5, 0);
        } else if (this.type === ViewType.FRONT) {
            this.camera.position.set(objectRadio + 3, 0, 0);
        }
        this.camera.lookAt(new Vector3(0, 0, 0));

        const matrix = new THREE.Matrix4().compose(position, quaternion, new Vector3(1, 1, 1));
        this.camera.applyMatrix4(matrix);

        const distance = this.camera.position.distanceTo(position);
        this.camera.near = objectRadio === 0 ? 0.1 : distance - objectRadio - 0.01;
        this.camera.far = objectRadio === 0 ? 2000 : distance + objectRadio + 0.01;

        this.camera.updateProjectionMatrix();
    }

    private resetCameraLookEara() {
        let objectRadio = 0;
        let position = new Vector3(0, 0, 0);
        if (this.object) {
            position = this.object.position;
            if (this.type === ViewType.TOP) {
                objectRadio = this.object.scale.z / 2;
            } else if (this.type === ViewType.SIDE) {
                objectRadio = this.object.scale.y / 2;
            } else if (this.type === ViewType.FRONT) {
                objectRadio = this.object.scale.x / 2;
            }
        } else {
        }
        const distance = this.camera.position.distanceTo(position);
        this.camera.near = objectRadio === 0 ? 0.1 : distance - objectRadio - 0.01;
        this.camera.far = objectRadio === 0 ? 2000 : distance + objectRadio + 0.01;
        this.camera.updateProjectionMatrix();
    }

    private resetCameraPosition() {
        let objectRadio = 0;
        let position = new Vector3(0, 0, 0);
        let quaternion = new Quaternion();

        if (!(this.object && (this.object as THREE.Mesh).isMesh)) {
            if (this.type === ViewType.TOP) {
                this.camera.position.set(0, 0, objectRadio + 5);
            } else if (this.type === ViewType.SIDE) {
                this.camera.position.set(0, objectRadio + 5, 0);
            } else if (this.type === ViewType.FRONT) {
                this.camera.position.set(objectRadio + 3, 0, 0);
            }
        } else {
            position = this.object.position;
            quaternion = this.object.quaternion;
            if (this.type === ViewType.TOP) {
                objectRadio = this.object.scale.z / 2;
                this.camera.position.set(0, 0, objectRadio + 5);
            } else if (this.type === ViewType.SIDE) {
                objectRadio = this.object.scale.y / 2;
                this.camera.position.set(0, objectRadio + 5, 0);
            } else if (this.type === ViewType.FRONT) {
                objectRadio = this.object.scale.x / 2;
                this.camera.position.set(objectRadio + 3, 0, 0);
            }
        }
        this.camera.lookAt(new Vector3(0, 0, 0));

        const matrix = new THREE.Matrix4().compose(position, quaternion, new Vector3(1, 1, 1));
        this.camera.applyMatrix4(matrix);
        this.camera.updateProjectionMatrix();
    }

    public update(): void {
        this.resetCamera();
        this.updateViewByObject();
    }

    public lookAtBezier(lookPos: Vector3, objectLength?: number): void {
        this._lookPos = lookPos.clone();
        let distance = 5;
        if (this.type === ViewType.TOP) {
            this.camera.position.set(lookPos.x, lookPos.y, lookPos.z + distance);
        } else if (this.type === ViewType.SIDE) {
            this.camera.position.set(lookPos.x, lookPos.y + distance, lookPos.z);
        } else if (this.type === ViewType.FRONT) {
            distance = 3;
            this.camera.position.set(lookPos.x + distance, lookPos.y, lookPos.z);
        }
        if (typeof length === 'number') {
            this._lookRadius = objectLength;
        }

        if (typeof this._lookRadius === 'number') {
            this.camera.near = distance - this._lookRadius;
            this.camera.far = distance + this._lookRadius;
        }

        this.camera.updateProjectionMatrix();
        this.translateReferencePlane(lookPos);
    }

    public resetLook() {
        let distance = 5;

        if (this.type === ViewType.TOP) {
            this.camera.position.set(this._lookPos.x, this._lookPos.y, this._lookPos.z + distance);
        } else if (this.type === ViewType.SIDE) {
            this.camera.position.set(this._lookPos.x, this._lookPos.y + distance, this._lookPos.z);
        } else if (this.type === ViewType.FRONT) {
            distance = 3;
            this.camera.position.set(this._lookPos.x + distance, this._lookPos.y, this._lookPos.z);
        }

        if (typeof this._lookRadius === 'number') {
            this.camera.near = distance - this._lookRadius;
            // this.camera.far = distance + this._lookRadius;
            this.camera.far = 2000;
        } else {
            this.camera.near = 0.1;
            this.camera.far = 2000;
        }

        this.camera.updateProjectionMatrix();
        this.translateReferencePlane(this._lookPos);
    }

    public keyControls(key: any): void {}

    public html(): HTMLCanvasElement {
        return this.renderer.domElement;
    }

    public notify(master: Controller, reason: string): void {
        // if (reason === UpdateReasons.IMAGE_CHANGED) {
        //     // 变更点云
        // }
    }
}
