/*
 * @Author: swxy
 * @Date: 2023-01-09 11:17:30
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import { throwStatement } from '@babel/types';
import { Vector2, Vector3 } from 'three';

export const isRightOrBottom = (a: number, b: number): boolean => {
    return b - a > 0;
};

class DrawObject {
    public active: boolean = false;
    public visible: boolean = false;
    public enable: boolean = false;
    public initPosition: Vector2;
    public init3DPosition: Vector3;
    public element: HTMLElement;

    public top: number;
    public left: number;
    public right: number;
    public bottom: number;

    public directionLeft: boolean;

    private parentElement: HTMLElement;

    constructor() {
        this.init();
    }

    public init() {
        const rectangle = document.createElement('div');
        rectangle.style.border = '1px solid red';
        rectangle.style.width = 'auto';
        rectangle.style.height = 'auto';
        rectangle.style.position = 'absolute';
        rectangle.style.pointerEvents = 'none';
        rectangle.style.margin = '0';
        rectangle.style.padding = '0';

        this.top = Number.MIN_SAFE_INTEGER;
        this.left = Number.MIN_SAFE_INTEGER;
        this.right = Number.MIN_SAFE_INTEGER;
        this.bottom = Number.MIN_SAFE_INTEGER;

        this.element = rectangle;

        this.initPosition = new Vector2(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        this.init3DPosition = new Vector3(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
        this.active = false;
        this.visible = false;
        this.directionLeft = true;
    }

    private updatePosition(offsetX: number, offsetY: number) {
        const { element } = this;
        if (element?.parentElement) {
            const parentWidth = element?.parentElement.clientWidth;
            const parentHeight = element?.parentElement.clientHeight;

            if (isRightOrBottom(this.initPosition.x, offsetX)) {
                this.left = this.initPosition.x;
                this.right = parentWidth - offsetX;
                this.directionLeft = false;
            } else {
                this.directionLeft = true;
                this.left = offsetX;
                this.right = parentWidth - this.initPosition.x;
            }

            if (isRightOrBottom(this.initPosition.y, offsetY)) {
                this.top = this.initPosition.y;
                this.bottom = parentHeight - offsetY;
            } else {
                this.top = offsetY;
                this.bottom = parentHeight - this.initPosition.y;
            }

            element.style.left = this.left + 'px';
            element.style.top = this.top + 'px';
            element.style.right = this.right + 'px';
            element.style.bottom = this.bottom + 'px';
        }
    }

    public start(offsetX: number, offsetY: number) {
        const { element } = this;

        this.initPosition.x = offsetX;
        this.initPosition.y = offsetY;

        this.updatePosition(offsetX, offsetY);

        element.style.zIndex = '100';

        this.active = true;
    }

    public move(offsetX: number, offsetY: number) {
        if (this.active === true) {
            this.updatePosition(offsetX, offsetY);
        }
    }

    public remove() {
        if (this.active) {
            setTimeout(() => {
                this.active = false;
                this.element.remove();

                this.initPosition.x = Number.MAX_SAFE_INTEGER;
                this.initPosition.y = Number.MAX_SAFE_INTEGER;
                this.init3DPosition.x = Number.MAX_SAFE_INTEGER;
                this.init3DPosition.y = Number.MAX_SAFE_INTEGER;
                this.init3DPosition.z = Number.MAX_SAFE_INTEGER;
            });
        }
    }

    public set parent(parent: HTMLElement) {
        parent.append(this.element);
        this.parentElement = parent;
    }

    public get parent() {
        return this.parentElement;
    }
}

export default DrawObject;
