/*
 * @Author: swxy
 * @Date: 2023-08-08 09:52:34
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { DragOutlined } from '@ant-design/icons';
import { Canvas3d } from 'canvas3d-wrapper';

import { ActiveControl, CombinedState, ObjectType, ShapeType } from 'reducers/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { activateObject, changeMoveObjectActiveControl } from 'actions/annotation-actions';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export interface Props {
    canvasInstance: Canvas3d;
    disabled?: boolean;
    cursorShortkey?: string;
    // onClick?(): void;
    activeControl: ActiveControl;
}

function LanelineControl(props: Props): JSX.Element {
    const { canvasInstance, activeControl, cursorShortkey } = props;

    const isMoving = activeControl === ActiveControl.move_object;
    const dispatch = useDispatch();

    const { t } = useTranslation();
    // const onMoveMode = (): void => {
    //     dispatch(changeMoveObjectActiveControl());
    // };

    const dynamicIconProps = isMoving
        ? {
            className: 'aatp-move-object-control cvat-active-canvas-control',
            onClick: (): void => {
                canvasInstance.moveObject({ enabled: false })
            },
        }
        : {
            className: 'aatp-move-object-control',
            onClick: (): void => {
                dispatch(activateObject(null, null));
                canvasInstance.moveObject({ enabled: true })
            },
        };

    return <Tooltip
        placement='rightTop'
        title={`${t('workspace.side.controls.moveObject')} ${cursorShortkey}`}
    >
        <DragOutlined {...dynamicIconProps} />
    </Tooltip>
    // </CustomPopover>
}

export default React.memo(LanelineControl);
