// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT
// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, message } from 'antd';
import { CombinedState } from 'reducers/interfaces';
import { hideShowContextImage } from 'actions/annotation-actions';
import ObjectProjection from './object-projection';
import { ProjectionThumbnatilImages } from 'amygo-component';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { CarouselRef } from 'antd/lib/carousel';
import Resource from 'business/amygo/AmygoResource';
// import { changePerspectiveView } from 'actions/annotationSub-actions';
// import { defaultCameraName } from 'consts';
// import { ProjectionThumbnatilImages } from '../../../../../../../components/src/index';
// import ProjectionThumbnatilImages from '../../../../../../../components/src/projectionThumbnatilImages/index';

export function adjustContextImagePosition(sidebarCollapsed: boolean): void {
    const element = window.document.getElementsByClassName('aatp-context-image-wrapper')[0] as
        | HTMLDivElement
        | undefined;
    if (element) {
        if (sidebarCollapsed) {
            element.style.right = '40px';
        } else {
            element.style.right = '';
        }
    }
}

export interface BitImageData {
    image?: ImageBitmap;
    name: string;
    imageIndex: number;
    url?: string;
}

interface Props {
    // cuboidsByImages: any;
    // images: BitImageData[];
}

function ContextImage({ }: Props): JSX.Element | null {

    const carouselRef: React.MutableRefObject<CarouselRef | undefined> = useRef<CarouselRef | undefined>(undefined);

    const {
        jobInstance,
        contextImageHidden,
        frame,
        hasRelatedContext,
        frameData,
        keyMap,
    } = useSelector((state: CombinedState) => ({
        jobInstance: state.annotation.job.instance,
        contextImageHidden: state.annotation.player.contextImage.hidden,
        frame: state.annotation.player.frame.number,
        hasRelatedContext: state.annotation.player.frame.hasRelatedContext,
        frameData: state.annotation.player.frame.data,
        keyMap: state.shortcuts.keyMap,
    }));

    const dispatch = useDispatch();

    // const [imagesData, setImagesData] = useState<BitImageData[]>([]);
    const [contextImagesData, setContextImagesData] = useState<BitImageData[]>([]);

    const [zoom, setZoom] = useState(false);
    // const [key, setKey] = useState<string>(frameData?.filename || '1');
    // const [activeCamera, setActiveCamera] = useState<{
    //     name: string,
    //     index: number,
    // } | undefined>();

    const hiddenImage = () => {
        dispatch(hideShowContextImage(!contextImageHidden));
    };

    const getImageBitMapData = async (data: BitImageData): Promise<BitImageData> => {
        // const isCan = URL.canParse(data.url);
        if (data.image) {
            if (data.image instanceof Blob) {
                const image = await createImageBitmap(data.image);
                return {
                    ...data,
                    image,
                }
            }
        }
        return data;
    }

    const getImageBitMapDatas = async (datas: BitImageData[]): Promise<void> => {
        const promises = datas.map(data => {
            return getImageBitMapData(data);
        })

        const imageBitDatas = await Promise.all(promises);

        setContextImagesData(imageBitDatas);
        // return imageBitDatas;
    }

    const getImageDatas = async () => {
        const data: Resource = await frameData.data();
        const { images } = data;
        // 不再需要考虑排序

        setContextImagesData(images.map((image, index) => {
            return {
                name: image.name,
                imageIndex: index,
                image: image.data,
            }
        }));
        // 新的加载形式
        // const orders = jobInstance.direction || [];
        // if (orders.length) {
        //     const images: BitImageData[] = [];
        //     orders.forEach((order: string, index: number) => {
        //         const image = imagesData.find((image: any) => image.name.includes(order));
        //         images.push({
        //             image: image?.imageBitmap,
        //             // url: image.url,
        //             name: order,
        //             imageIndex: index,
        //         });
        //     });
        //     // setImagesData(images)
        //     setContextImagesData(images);
        // } else {
        //     const images: BitImageData[] = [...imagesData.map((item: any, index: number) => ({
        //         image: item?.imageBitmap,
        //         name: item.name,
        //         imageIndex: index,
        //         // url: item.url,
        //     }))];
        //     // setImagesData(images)
        //     setContextImagesData(images);
        // }
    }

    useEffect(() => {
        try {
            if (jobInstance?.isNotOldTask && jobInstance?.isNotOldTask()) {
                getImageDatas();
                return;
            } else {
                message.error('不再支持旧题包！');
            }
            // frameData
            //     .data((): void => {
            //         setImagesData([]);
            //         setContextImagesData([]);
            //     })
            //     .then((data: any): void => {
            //         if (jobInstance?.isNotOldTask && jobInstance?.isNotOldTask()) {
            //             const { images: imagesData } = data;
            //             // 新的加载形式
            //             const orders = jobInstance.direction || [];
            //             if (orders.length) {
            //                 const images: BitImageData[] = [];
            //                 orders.forEach((order: string, index: number) => {
            //                     const image = imagesData.find((image: any) => image.name.includes(order));
            //                     images.push({
            //                         image: image ? image.data : undefined,
            //                         // url: image.url,
            //                         name: order,
            //                         imageIndex: index,
            //                     });
            //                 });
            //                 setImagesData(images)
            //                 // setContextImagesData(images);
            //             } else {
            //                 const images: BitImageData[] = [...imagesData.map((item: any, index: number) => ({
            //                     image: item.data,
            //                     name: item.name,
            //                     imageIndex: index,
            //                     // url: item.url,
            //                 }))];
            //                 setImagesData(images)
            //                 // setContextImagesData(images);
            //             }
            //         }

            //         if (data.imageData && data.imageData.images) {
            //             // setContextImagesData(data.imageData.images || []);
            //             // getBitImage(data.imageData.images);
            //             // getKey();
            //             const orders = jobInstance.direction || [];
            //             if (orders.length) {
            //                 const images: BitImageData[] = [];
            //                 orders.forEach((order: string, index: number) => {
            //                     const image = data.imageData.images.find((image: any) => image.fullName.includes(order));
            //                     images.push({
            //                         image: image ? image.data : undefined,
            //                         // url: image.url,
            //                         name: order,
            //                         imageIndex: index,
            //                     });
            //                 });
            //                 setImagesData(images)
            //                 // setContextImagesData(images);
            //             } else {
            //                 const images: BitImageData[] = [...data.imageData.images.map((item: any, index: number) => ({
            //                     image: item.data,
            //                     // name: item.name,
            //                     imageIndex: index,
            //                     url: item.url,
            //                 }))];
            //                 setImagesData(images)
            //                 // setContextImagesData(images);
            //             }
            //         }
            //     });
        } catch (err) {
            message.error(`解析图片错误：${(err as Error).stack}`);
        }
    }, [hasRelatedContext, contextImageHidden, frameData]);

    // useEffect(() => {

    //     return () => {
    //         if (!jobInstance?.isNotOldTask || !jobInstance?.isNotOldTask()) {
    //             contextImagesData.map(data => data?.image?.close())
    //         }
    //     }
    // }, [contextImagesData])



    // useEffect(() => {
    //     if (!jobInstance?.isNotOldTask || !jobInstance?.isNotOldTask()) {
    //         getImageBitMapDatas(imagesData);
    //     }

    //     return () => {
    //         if (!jobInstance?.isNotOldTask || !jobInstance?.isNotOldTask()) {
    //             imagesData.map(data => {
    //                 if (data.image instanceof ImageBitmap) {
    //                     data.image.close();
    //                 }
    //             })
    //         }
    //     }
    // }, [imagesData])

    // useEffect(() => {
    //     if (activeCamera) {
    //         const { index } = activeCamera;
    //         const directions = (jobInstance.direction || []);
    //         // 有视角文件夹
    //         if (directions?.length > 1) {
    //             dispatch(changePerspectiveView(directions[index], (activatedStateID || undefined)));
    //         } else {
    //             dispatch(changePerspectiveView(`${defaultCameraName}${index}`, (activatedStateID || undefined)));
    //         }
    //     }
    // }, [activatedStateID, activeCamera])

    // useEffect(() => {
    //     if (!perspective) {
    //         setActiveCamera(undefined);
    //     }
    // }, [perspective])

    if (!hasRelatedContext) {
        return null;
    }

    const onClick = (e: React.MouseEvent, name: string, index: number) => {
        setZoom(oldZoom => !oldZoom);
    }

    const subKeyMap = {
        switch_show_image: keyMap.switch_show_image,
    };

    const handlers = {
        switch_show_image: (event: KeyboardEvent | undefined) => {
            if (event && !event.ctrlKey) {
                const index = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(event.key);
                if (carouselRef && index > -1) {
                    carouselRef.current?.goTo?.(index)
                }
            }
        },
    };

    return (
        <Row className={zoom ? "aatp_center_canvas_images" : 'aatp_canvas_images'} justify='end'>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <ProjectionThumbnatilImages
                carouselRef={carouselRef}
                images={contextImagesData}
                frame={frame}
                scaleStep={0.5}
                width={zoom ? '1200px' : '480px'}
                height={zoom ? '800px' : '270px'}
                onClick={onClick}
                onChangeVisible={() => hiddenImage()}
                coverElement={(current, width, height) => <ObjectProjection
                    current={current}
                    canvasWidth={width}
                    canvasHeight={height}
                />
                }
            />
        </Row>
    );
}

export default React.memo(ContextImage);
