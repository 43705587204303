import {
    Vector3,
    Mesh,
    CubicBezierCurve3,
    Line,
    BufferAttribute,
    BufferGeometry,
    LineBasicMaterial,
    ColorRepresentation,
    MeshBasicMaterial,
} from 'three';
import BezierPoint, { PointType } from './point';

const ARC_SEGMENTS = 200;

/**
 * 线段
 */
class Segment {
    private _start: Vector3;
    private _stop: Vector3;
    private _locus: Vector3[] = [];
    private _controlPointColor: ColorRepresentation = 'green';
    private _color: ColorRepresentation = 'red';
    private _opacity: number = 20;
    private _radius: number = 0.2;

    public startMesh: Mesh;
    public stopMesh: Mesh;

    public clientID: number;
    public index: number;

    public readonly bezier: CubicBezierCurve3;
    /**
     * 贝塞尔曲线计算对象
     */
    public readonly locusMeshs: Mesh[] = [];

    public line: Line;

    public get locus(): Vector3[] {
        return this.getBezierPoint();
    }

    constructor(
        clientID: number,
        start: Vector3,
        stop: Vector3,
        index: number,
        locus: Vector3[] = [],
        color: ColorRepresentation = 'red',
        controlPointColor: ColorRepresentation = 'yellow',
        opacity: number = 100,
    ) {
        this.clientID = clientID;
        this._start = start;
        this._stop = stop;

        this.index = index;

        this._locus = locus;

        this._color = color;
        this._controlPointColor = controlPointColor;
        this._opacity = opacity;

        this.bezier = new CubicBezierCurve3(
            this._start.clone(),
            ...(this.locus as [Vector3, Vector3]).map((vec) => vec.clone()),
            this._stop.clone(),
        );

        this.locusMeshs = this.createLocusMeshs();

        const curvePoints = this.bezier.getPoints(ARC_SEGMENTS);
        const curveArray = curvePoints.flatMap((vec) => [vec.x, vec.y, vec.z]);
        const geometry = new BufferGeometry();
        geometry.setAttribute('position', new BufferAttribute(new Float32Array(curveArray), 3));
        this.line = new Line(
            geometry,
            new LineBasicMaterial({
                color: this._color,
                opacity: this._opacity / 100,
            }),
        );
    }

    public static getBezierControlPoints(start: Vector3, end: Vector3) {
        return [
            new Vector3().lerpVectors(start, end, 0.33).add(new Vector3(0, 0, 0)),
            new Vector3().lerpVectors(start, end, 0.67).add(new Vector3(0, 0, 0)),
        ];
    }

    private getBezierPoint() {
        // 小于总起止点数
        // 每两个点之间，获取两个控制点
        if (!this._locus.length) {
            // 不存在的情况，则自动创建两个控制点
            return Segment.getBezierControlPoints(this._start, this._stop);
        }
        return this._locus;
    }

    private createLocusMesh(pos: Vector3, index: number) {
        const point = new BezierPoint(
            this.clientID,
            'control',
            PointType.bezierControl,
            pos,
            this._controlPointColor,
            index,
            this._opacity,
            this._radius,
        );
        point.visible = false;
        return point;
    }

    private createLocusMeshs() {
        return this.locus.map((pos, index) => {
            return this.createLocusMesh(pos, index);
        });
    }

    public update(
        data?: { start?: Vector3; stop?: Vector3; locus?: [Vector3, Vector3] },
        color?: {
            color: ColorRepresentation;
            controlPointColor: ColorRepresentation;
        },
    ) {
        if (data) {
            const { start, stop, locus } = data;
            if (start && (!start.equals(this.bezier.v0) || !start.equals(this.startMesh.position))) {
                this.bezier.v0.set(start.x, start.y, start.z);
                this._start = start;
                this.startMesh.position.set(this._start.x, this._start.y, this._start.z);
            } else if (stop && (!stop.equals(this.bezier.v3) || !stop.equals(this.stopMesh.position))) {
                this.bezier.v3.set(stop.x, stop.y, stop.z);
                this._stop = stop;
                this.stopMesh.position.set(this._stop.x, this._stop.y, this._stop.z);
            } else if (locus) {
                this.bezier.v1.set(locus[0].x, locus[0].y, locus[0].z);
                this.bezier.v2.set(locus[1].x, locus[1].y, locus[1].z);
                this._locus = locus;
                if (this.locusMeshs.length) {
                    this.locusMeshs.forEach((mesh, index) => {
                        mesh.position.set(locus[index].x, locus[index].y, locus[index].z);
                    });
                }
            }

            if (!this._locus.length) {
                const newLocus = this.locus;
                this.bezier.v1.set(newLocus[0].x, newLocus[0].y, newLocus[0].z);
                this.bezier.v2.set(newLocus[1].x, newLocus[1].y, newLocus[1].z);
                if (this.locusMeshs.length) {
                    this.locusMeshs.forEach((mesh, index) => {
                        mesh.position.set(newLocus[index].x, newLocus[index].y, newLocus[index].z);
                    });
                }
            }
            const point = new Vector3();
            const position = this.line.geometry.attributes.position;
            const poses = this.bezier.getPoints(ARC_SEGMENTS);
            position.array.set(poses.flatMap((pos) => [pos.x, pos.y, pos.z]));
            // for (let index = 0; index < ARC_SEGMENTS; index++) {
            //     const t = index / (ARC_SEGMENTS - 1);
            //     this.bezier.getPoint(t, point);
            //     position.setXYZ(index, point.x, point.y, point.z);
            // }
            position.needsUpdate = true;
            this.line.geometry.computeBoundingSphere();
            this.line.matrixWorldNeedsUpdate = true;
        }

        if (color) {
            if (color.color && this._color !== color.color) {
                this._color = color.color;
                (this.line.material as LineBasicMaterial).color.set(this._color);
            } else if (color.controlPointColor && this._controlPointColor !== color.controlPointColor) {
                this._controlPointColor = color.controlPointColor;
                this.locusMeshs.forEach((mesh, index) => {
                    (mesh.material as MeshBasicMaterial).color.set(this._controlPointColor);
                });
            }
        }
    }

    public active() {
        // this.segments.forEach((segment) => {
        //     segment.locusMeshs.forEach((mesh) => {
        //         mesh.visible = this.locusVisible;
        //     });
        // });
    }

    public unActive() {}
}

export default Segment;
