/*
 * @Author: swxy
 * @Date: 2023-04-26 11:31:10
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { FC, useEffect, useState } from 'react';
import {
    CodeOutlined,
} from '@ant-design/icons';
import { useTranslation, getI18n } from 'react-i18next';
import { getRegisterToken } from 'actions/auth-actions';
import ValidCodeInput, { ValidCodeProps } from 'components/common/validCode';

interface Props extends ValidCodeProps {
    dispatchEvent: any;
}

const ImageVerifyCode: FC<Props> = ({ dispatchEvent, ...props }: Props): JSX.Element => {
    const [codeImageUrl, setCodeImageUrl] = useState('');

    const { t } = useTranslation();

    const getImageVerifyCode = async () => {
        // setCodeLoading(true);
        // setCountdown(0);
        try {
            const codeVer = await dispatchEvent(getRegisterToken()) as unknown as any;
            if (codeVer) {
                const url = URL.createObjectURL(codeVer);
                setCodeImageUrl(url);
                // return url;
            }
        } catch (error) {
            console.error('错误信息：', error);
            throw new Error('获取图片验证码失败！')
            // setCodeLoading(false);
        }
    }

    // const getVerifyCode = async () => {
    //     // setCodeLoading(true);
    //     // setCountdown(0);
    //     try {

    //         const url = await getImageVerifyCode();
    //         // setCodeLoading(false);
    //         if (url) {
    //             setCodeImageUrl(url);
    //         }
    //     } catch (error) {
    //         console.error('错误信息：', error);
    //         // setCodeLoading(false);
    //     }
    // }

    useEffect(() => {
        return () => {
            if (codeImageUrl) {
                URL.revokeObjectURL(codeImageUrl);
            }
        }
    }, [])

    return (
        <>
            <ValidCodeInput
                autoComplete='verifyCode_'
                prefix={<CodeOutlined />}
                placeholder={t('login.imageVerifyCode')}
                maxLength={10}
                onLoadCode={getImageVerifyCode}
                text={t('login.validcode.get')}
                {...props}
            />
            {codeImageUrl && <img
                src={codeImageUrl}
                onClick={getImageVerifyCode}
            />}
        </>
    );
}

export default ImageVerifyCode;
