import { Float32BufferAttribute, Matrix3, Matrix4 } from 'three';
import {
    Mode,
    ActivationModel,
    GroupData,
    Issue,
    Configuration,
    DrawData,
    CameraDistortionParameter,
    PcdParameter,
    ImagePosition,
    ExportProjectType,
    SummarizeData,
    ShapeProperties,
    ActiveElement,
    Size,
    Image,
    FocusData,
    ReviewData,
    UpdateReasons,
    CameraType,
    ObjectState,
    SupportType,
    UpdateLanelineType,
    ShapeType,
    Pcd,
    ArrowType,
    PointCloudColorBy,
} from './interface';
import { Master } from './master';
import { copyObject, transPostureToPosition } from './utils';
import i18n from 'i18next';
import CuboidModel from './objects/cuboid';
import LanelineMesh from './objects/lanelineMesh';
import SphereModel from './objects/sphere';

interface ConfigObject {
    defalutScale: { x: number; y: number; z: number };
    minScale: { x: number; y: number; z: number };
}

export interface Canvas3dDataModel {
    activeElement: ActiveElement;
    canvasSize: Size;
    imageUrl?: string;
    imageID?: number;
    image?: {
        name: string;
        position: number[];
        color?: number[];
        intensity?: number[];
    };
    pointsCloud?: Pcd;
    name?: string;
    imageOffset: number;
    imageSize: Size;
    drawData: DrawData;
    mode: Mode;
    objectUpdating: boolean;
    exception: Error | null;
    groupedObjects: any[];
    focusData: FocusData;
    selected: CuboidModel | SphereModel;
    shapeProperties: ShapeProperties;
    groupData: GroupData;
    cameraCalibs: THREE.Matrix3[];
    cameraToBumpers: THREE.Matrix4[];
    cameraDistortionParameter: CameraDistortionParameter[];
    pcdParameter: PcdParameter;
    isFineTuning: boolean;
    configuration: Configuration;
    reviewData: ReviewData;
    issueRegions: Issue[];

    objects: Map<number, ObjectState>;
    drawnObjects: Map<number, ObjectState>;
    objects3D: Map<number, CuboidModel | LanelineMesh>;
    objectsDrawn: ObjectState[];

    updateObjects: {
        add: ObjectState[];
        update: ObjectState[];
        delete: ObjectState[];
    };

    summarize: {
        framePcd: any;
        data: SummarizeData[];
        objects: any[];
        frame: number;
        // type: ExportProjectType;
    };
    updateLanelineData: {
        type: UpdateLanelineType;
    };
    moveData: {
        enabled: boolean;
    };
    config_object: ConfigObject;

    object: {
        deletePointClientID?: number;
    };
}
const objectContrastKeys = ['clientID', 'frame', 'points', 'color', 'lock', 'pinned', 'label', 'id'];

export interface Canvas3dModel {
    mode: Mode;
    data: Canvas3dDataModel;
    readonly groupData: GroupData;
    readonly issueRegions: Issue[];
    readonly configuration: Configuration;
    init(url: string, frame: number, type: SupportType): Promise<void>;
    setup(objectStates: any[], force: boolean): void;
    setupIssueRegions(issueRegions: Issue[]): void;
    isAbleToChangeFrame(): boolean;
    draw(drawData: DrawData): void;
    cancel(): void;
    birdEyeMode(enable: boolean): void;
    // reviewCanvas(enable: boolean): void;
    activate(clientID?: number, issueID?: number, attributeID?: number): void;
    // activateIssue(issueID: number): void;
    configureShapes(shapeProperties: any): void;
    getCameraParams(
        cameraCalibs: number[][],
        cameraToBumpers: number[][],
        cameraDistortionParameter?: CameraDistortionParameter[],
        pcdParameter?: PcdParameter,
        needInvert?: boolean[],
    ): void;
    actionModel(isFineTuning: boolean): void;
    fit(): void;
    group(groupData: GroupData): void;
    configure(configuration: Partial<Configuration>): void;
    postureToPosition(
        points: number[],
        cameraIndex: number,
        shapeType: ShapeType,
        resultType: '4' | '8',
    ): THREE.Vector2[];
    summarize(frameData: any, objectStates: any[]): Promise<SummarizeData[]>;
    setupPointCloudOrObjectsOrIssues(frame: number, pcd: Pcd, objects?: ObjectState[], issues?: Issue[]): void;
    moveObject({ enabled }: { enabled: boolean }): void;
    bezier2Mode(enable: boolean): void;
    deletePoint(clientID: number): void;
    destroy(): void;
}

const { t } = i18n;

export default class Controller extends Master implements Canvas3dModel {
    public data: Canvas3dDataModel;

    public constructor() {
        super();
        this.data = {
            pointsCloud: undefined,
            name: undefined,
            activeElement: {
                clientID: null,
                attributeID: null,
                issueID: null,
                oldClientID: null,
                oldIssueID: null,
            },
            canvasSize: {
                height: 0,
                width: 0,
            },
            objectUpdating: false,
            groupedObjects: [],
            imageUrl: undefined,
            imageID: undefined,
            image: undefined,
            imageOffset: 0,
            imageSize: {
                height: 0,
                width: 0,
            },
            drawData: {
                enabled: false,
                initialState: null,
                type: 'object',
            },
            mode: Mode.IDLE,
            exception: null,
            focusData: {
                clientID: null,
                issueID: null,
            },
            groupData: {
                enabled: false,
                grouped: [],
            },
            selected: null,
            shapeProperties: {
                opacity: 20,
                outlined: false,
                outlineColor: '#000000',
                selectedOpacity: 60,
                colorBy: 'Label',
            },
            cameraCalibs: [],
            cameraToBumpers: [],
            cameraDistortionParameter: [],
            pcdParameter: {
                cameraRadius: [], // 有值时（> 0））展示
                // 视场角
                // 起始线标注角度
                viewStartMiddleAngle: 0,
                // 标注角度
                viewAngle: 0,
            },
            isFineTuning: false,
            configuration: {
                forceDisableEditing: false,
                activeModel: ActivationModel.clickActive,
                birdEyeAutoFit: true,

                hasAnimation: true,

                isFollow: true,
                isForce: true,
                pointCloudColor: PointCloudColorBy.normal,
            },

            reviewData: {
                enabled: false,
                redraw: true,
            },
            // issueUpdate: false,
            issueRegions: [],
            summarize: {
                framePcd: undefined,
                data: [],
                objects: [],
                frame: 0,
                // type: 'zj',
            },
            objects: new Map(),
            drawnObjects: new Map(),
            objects3D: new Map(),
            updateObjects: {
                add: [],
                update: [],
                delete: [],
            },
            objectsDrawn: [],
            updateLanelineData: {
                type: 'normal',
            },

            moveData: {
                enabled: true,
            },
            config_object: {
                defalutScale: { x: 4.6, y: 1.8, z: 1.5 },
                minScale: { x: 4.6, y: 1.8, z: 1.5 },
            },

            object: {
                deletePointClientID: undefined,
            },
        };
    }

    public async init(url: string, frame: number = 0, type: SupportType = SupportType.pcd): Promise<void> {
        if (this.data.imageID === frame) {
            // throw Error(t('error.initialized', { frame }));
            return;
        }
        this.data.imageID = frame;

        if (!url) {
            if ([Mode.EDIT, Mode.DRAG, Mode.RESIZE].includes(this.data.mode)) {
                throw Error(t('error.noPointCloud', { frame }));
            }
        }
        this.data.imageUrl = url;
        this.notify(UpdateReasons.IMAGE_CHANGED);
        return;
    }

    public setup(objectStates: ObjectState[], force: boolean): void {
        if ([Mode.EDIT, Mode.BUSY].includes(this.data.mode)) {
            return;
        }

        // if (force) {
        //     this.data.objectUpdating = false;
        //     this.data.drawnObjects.forEach((current) => {
        //         this.data.updateObjects.delete.push(current);
        //     });
        //     this.data.drawnObjects.clear();
        // }

        if (this.data.objectUpdating) {
            // 正在更新中
            return;
        }

        const newObjects = new Map<number, ObjectState>();
        const drawnObjects = new Map<number, ObjectState>();
        const objectKeys: number[] = [];

        objectStates.forEach((object) => {
            // 'clientID', 'frame', 'points', 'color', 'lock', 'pinned'
            const current = copyObject(object);
            newObjects.set(current.clientID, object);
            drawnObjects.set(current.clientID, current);
            objectKeys.push(current.clientID);

            if (this.data.drawnObjects.has(current.clientID)) {
                // 更新
                const object = this.data.drawnObjects.get(current.clientID);
                if (JSON.stringify(object, objectContrastKeys) !== JSON.stringify(current, objectContrastKeys)) {
                    // 更新
                    // this.views.perspective.scene
                    this.data.updateObjects.update.push(current);
                }
            } else {
                // 增加
                this.data.updateObjects.add.push(current);
            }
        });

        // 删除
        this.data.drawnObjects.forEach((current) => {
            if (!objectKeys.includes(current.clientID)) {
                this.data.updateObjects.delete.push(current);
            }
        });
        this.data.objects = newObjects;
        this.data.drawnObjects = drawnObjects;

        this.data.objectUpdating = true;

        this.notify(UpdateReasons.OBJECTS_UPDATED);
        this.data.objectUpdating = false;
        return;
    }

    public setupIssueRegions(issueRegions: Issue[]): void {
        this.data.issueRegions = issueRegions;
        this.notify(UpdateReasons.ISSUE_REGIONS_UPDATED);
    }

    // 为保持目标物和点云和批注球同时渲染，这三个最好同时触发
    public setupPointCloudOrObjectsOrIssues(frame: number, pcd: Pcd, objects?: ObjectState[], issues?: Issue[]): void {
        if ([Mode.EDIT, Mode.DRAG, Mode.RESIZE, Mode.BUSY, Mode.move_object].includes(this.data.mode)) {
            // throw Error(`画布正在工作中。 正在执行动作: ${this.data.mode}`);
            console.error(`画布正在工作中。 正在执行动作: ${this.data.mode}`);
            return;
        }
        // if ([Mode.EDIT, Mode.BUSY].includes(this.data.mode)) {
        //     return;
        // }

        // console.log('-----------------------保存:------------------------', this.data.mode);

        // 更换点云
        // if (frame !== this.data.imageID || pcd.name !== this.data.image.name) {
        //     this.data.imageUrl = pcd.name;
        //     this.data.imageID = frame;
        //     this.data.image = pcd;
        //     this.notify(UpdateReasons.update_pcd);
        // }
        if (frame !== this.data.imageID || pcd.name !== this.data.name) {
            this.data.imageUrl = pcd.name;
            this.data.imageID = frame;
            this.data.pointsCloud = pcd;
            this.data.name = pcd.name;
            this.notify(UpdateReasons.update_pcd);
        }
        if (objects && this.data.objectsDrawn !== objects) {
            this.data.objectsDrawn = objects;
            this.data.objects.clear();
            objects.forEach((obj) => {
                this.data.objects.set(obj.clientID, obj);
            });
            this.notify(UpdateReasons.update_objects);
        }
        // issues是单独的，暂时不用
        if (issues && this.data.issueRegions !== issues) {
            this.data.issueRegions = issues;
            this.notify(UpdateReasons.update_issues);
        }

        // // 取消引用
        // this.data.pointsCloud = undefined;
    }

    public set mode(value: Mode) {
        this.data.mode = value;
    }

    public get mode(): Mode {
        return this.data.mode;
    }

    public isAbleToChangeFrame(): boolean {
        const isUnable =
            [Mode.DRAG, Mode.EDIT, Mode.RESIZE, Mode.INTERACT, Mode.BUSY].includes(this.data.mode) ||
            (this.data.mode === Mode.DRAW && typeof this.data.drawData.redraw === 'number');
        return !isUnable;
    }

    public draw(drawData: DrawData): void {
        if (drawData.enabled && this.data.drawData.enabled && !drawData.initialState) {
            throw new Error(t('error.drawAlready'));
        }
        if ([Mode.EDIT].includes(this.data.mode) && !drawData.initialState) {
            return;
        }
        this.data.drawData.enabled = drawData.enabled;
        this.data.mode = Mode.DRAW;
        if (typeof drawData.redraw === 'number') {
            const clientID = drawData.redraw;
            // const [state] = this.data.objects.filter((_state: any): boolean => _state.clientID === clientID);
            const state = this.data.objects.get(clientID);
            if (state) {
                this.data.drawData = { ...drawData, type: drawData.type || 'object' };
                this.data.drawData.initialState = { ...this.data.drawData.initialState, label: state.label };
                this.data.drawData.shapeType = state.shapeType;
            } else {
                return;
            }
        } else {
            this.data.drawData = { ...drawData, type: drawData.type || 'object' };
            if (this.data.drawData.initialState) {
                this.data.drawData.shapeType = this.data.drawData.initialState.shapeType;
            }
        }
        this.notify(UpdateReasons.DRAW);
    }

    public bezier2Mode(enable: boolean): void {
        // if (enable && this.data.mode !== Mode.IDLE) {
        //     throw Error(t('error.busyError', { mode: this.data.mode }));
        //     // throw Error(`Canvas is busy. Action: ${this.data.mode}`);
        // }
        // if (!enable && this.data.mode !== Mode.bezier2) {
        //     throw Error(`画布当前不在贝塞尔曲线模式，当前为: ${this.data.mode}`);
        // }
        // this.data.mode = enable ? Mode.bezier2 : Mode.IDLE;
        // this.notify(UpdateReasons.bezier2);
    }

    public birdEyeMode(enable: boolean): void {
        if (enable && this.data.mode !== Mode.IDLE) {
            throw Error(t('error.busyError', { mode: this.data.mode }));
            // throw Error(`Canvas is busy. Action: ${this.data.mode}`);
        }

        if (!enable && this.data.mode !== Mode.birdEye) {
            throw Error(`Canvas is not in the drag mode. Action: ${this.data.mode}`);
        }

        this.data.mode = enable ? Mode.birdEye : Mode.IDLE;
        this.notify(UpdateReasons.birdEye);
    }

    public cancel(): void {
        this.configuration.birdEyeAutoFit = true;
        this.notify(UpdateReasons.CANCEL);
    }

    // public reviewCanvas(enable: boolean): void {
    //     if (enable && this.data.mode !== Mode.IDLE) {
    //         throw Error(t('error.busyError', { mode: this.data.mode }));
    //         // throw Error(`Canvas is busy. Action: ${this.data.mode}`);
    //     }

    //     if (!enable && this.data.mode !== Mode.REVIEW_CANVAS) {
    //         throw Error(`Canvas is not in the drag mode. Action: ${this.data.mode}`);
    //     }

    //     this.data.mode = enable ? Mode.REVIEW_CANVAS : Mode.IDLE;
    //     this.notify(UpdateReasons.REVIEW_CANVAS);
    // }

    public activate(clientID: number = null, issueID: number = null, attributeID: number = null): void {
        // console.log('-----------------------保存2:------------------------', this.data.mode);
        if (
            this.data.activeElement.clientID === clientID &&
            this.data.activeElement.attributeID === attributeID &&
            this.data.activeElement.issueID === issueID
        ) {
            this.notify(UpdateReasons.shape_re_activated);
            return;
        }
        if (![Mode.IDLE, Mode.birdEye].includes(this.data.mode)) {
            throw Error(t('error.busyError', { mode: this.data.mode }));
            // throw Error(`Canvas is busy. Action: ${this.data.mode}`);
        }
        if (typeof clientID === 'number') {
            const state = this.data.objects.get(clientID);
            // const [state] = this.data.objects.filter((_state: any): boolean => _state.clientID === clientID);
            if (!state || state.objectType === 'tag') {
                return;
            }
        }

        this.data.activeElement = {
            clientID,
            attributeID,
            issueID,
            oldClientID: this.data.activeElement.clientID,
            oldIssueID: this.data.activeElement.issueID,
        };

        if (this.data.objects.has(this.data.activeElement.oldClientID)) {
            this.data.updateObjects.update.push(this.data.objects.get(this.data.activeElement.oldClientID));
        }
        if (this.data.objects.has(clientID)) {
            this.data.updateObjects.update.push(this.data.objects.get(clientID));
        }

        // if (clientID) {
        // } else if (issueID) {
        // }
        this.notify(UpdateReasons.SHAPE_ACTIVATED);
        // this.notify(UpdateReasons.ISSUE_ACTIVATED);

        this.data.activeElement = {
            clientID,
            attributeID,
            issueID,
            oldClientID: this.data.activeElement.clientID,
            oldIssueID: this.data.activeElement.issueID,
        };
        this.data.updateObjects.update = [];
    }

    // public activateIssue(issueID: number): void {
    //     if (this.data.activeElement.issueID === issueID) {
    //         return;
    //     }
    //     if (this.data.mode !== Mode.IDLE) {
    //         throw Error(t('error.busyError', { mode: this.data.mode }));
    //         // throw Error(`Canvas is busy. Action: ${this.data.mode}`);
    //     }
    //     if (typeof issueID === 'number') {
    //         const [state] = this.data.issueRegions.filter((_state: any): boolean => _state.id === issueID);
    //         if (!state) {
    //             return;
    //         }
    //     }
    //     this.data.activeElement = {
    //         clientID: null,
    //         attributeID: null,
    //         issueID,
    //         oldClientID: this.data.activeElement.clientID,
    //         oldIssueID: this.data.activeElement.issueID,
    //     };
    //     this.notify(UpdateReasons.ISSUE_ACTIVATED);
    //     this.data.activeElement = {
    //         clientID: null,
    //         attributeID: null,
    //         issueID,
    //         oldClientID: this.data.activeElement.clientID,
    //         oldIssueID: this.data.activeElement.issueID,
    //     };
    // }

    public group(groupData: GroupData): void {}

    public configureShapes(shapeProperties: ShapeProperties): void {
        this.data.drawData.enabled = false;
        this.data.mode = Mode.IDLE;
        this.cancel();
        this.data.shapeProperties = {
            ...shapeProperties,
        };
        this.notify(UpdateReasons.CONFIG_UPDATED);
    }

    public configure(configuration: Partial<Configuration> = {}): void {
        // if (typeof configuration.forceDisableEditing === 'boolean') {
        //     this.data.configuration.forceDisableEditing = configuration.forceDisableEditing;
        // }
        // if (typeof configuration.activeModel === 'string') {
        //     this.data.configuration.activeModel = configuration.activeModel;
        // }
        // if (typeof configuration.birdEyeAutoFit === 'boolean') {
        //     this.data.configuration.birdEyeAutoFit = configuration.birdEyeAutoFit;
        // }

        // console.log('-----------------------保存3:------------------------', this.data.mode);
        if ([Mode.EDIT, Mode.DRAG, Mode.RESIZE, Mode.BUSY, Mode.move_object].includes(this.data.mode)) {
            // throw Error(`画布正在工作中。 正在执行动作: ${this.data.mode}`);
            console.error(`画布正在工作中。 正在执行动作: ${this.data.mode}`);
            return;
        }

        try {
            this.data.configuration = {
                ...this.data.configuration,
                ...configuration,
            };

            this.notify(UpdateReasons.CONFIG_UPDATED);
        } catch (error) {
            console.error('3D设置配置错误', error);
        }
    }

    public updateLaneline(data: { type: UpdateLanelineType }): void {
        this.data.updateLanelineData.type = data.type;
        this.notify(UpdateReasons.updateLaneline);
        this.data.updateLanelineData.type = 'normal';
    }

    // 总结数据
    public async summarize(frameData: any, objectStates: any[]): Promise<SummarizeData[]> {
        this.data.summarize.objects = objectStates;
        this.data.summarize.frame = frameData.number;
        this.data.summarize.data = [];
        // this.data.summarize.type = type;
        this.data.summarize.framePcd = await frameData.data().catch((exception: any): void => {
            this.data.exception = exception;
            // this.notify(UpdateReasons.DATA_FAILED);
            throw exception;
        });

        await this.notifyAsync(UpdateReasons.summarize_data);

        // return this.data.summarize.data[this.data.summarize.frame];
        return this.data.summarize.data.map((item) => ({
            semanticObjFrType: 0,
            shapeObjFrType: 0,
            shapeObjType: 0,
            laneFrType: 0,
            ...item,
            jobId: frameData.jid,
            frame: frameData.number,
        }));
    }

    public actionModel(isFineTuning: boolean): void {
        this.data.isFineTuning = isFineTuning;
    }

    public moveObject(moveData: { enabled: boolean }): void {
        this.data.moveData = moveData;
        this.notify(UpdateReasons.move_object);
    }

    public getCameraParams(
        cameraCalibs: number[][],
        cameraToBumpers: number[][],
        cameraDistortionParameter?: CameraDistortionParameter[],
        pcdParameter?: PcdParameter,
        needInvert?: boolean[],
    ): void {
        if (cameraCalibs && cameraCalibs.length) {
            type Calib = [number, number, number, number, number, number, number, number, number];
            this.data.cameraCalibs = cameraCalibs.map(
                (cameraCalib: number[]): THREE.Matrix3 => new Matrix3().set(...(cameraCalib as Calib)),
            );
        }
        if (cameraToBumpers && cameraToBumpers.length) {
            type Bumper = [
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
                number,
            ];
            this.data.cameraToBumpers = cameraToBumpers.map((cameraToBumper: number[], index): Matrix4 => {
                if (needInvert?.[index]) {
                    return new Matrix4().set(...(cameraToBumper as Bumper)).invert();
                }
                return new Matrix4().set(...(cameraToBumper as Bumper));
            });
        }
        if (cameraDistortionParameter && cameraDistortionParameter.length) {
            this.data.cameraDistortionParameter = cameraDistortionParameter.map(
                (item): CameraDistortionParameter => ({
                    cameraType: item.cameraType || CameraType.normal,
                    k1: item.k1 || 0,
                    k2: item.k2 || 0,
                    k3: item.k3 || 0,
                    k4: item.k4 || 0,
                    k5: item.k5 || 0,
                    k6: item.k6 || 0,
                    s1: item.s1 || 0,
                    s2: item.s2 || 0,
                    s3: item.s3 || 0,
                    s4: item.s4 || 0,
                    p1: item.p1 || 0,
                    p2: item.p2 || 0,

                    // ...item,
                }),
            );
        } else {
            this.data.cameraDistortionParameter = cameraToBumpers.map(
                (): CameraDistortionParameter => ({
                    cameraType: CameraType.normal,
                    k1: 0,
                    k2: 0,
                    k3: 0,
                    k4: 0,
                    k5: 0,
                    k6: 0,
                    s1: 0,
                    s2: 0,
                    s3: 0,
                    s4: 0,
                    p1: 0,
                    p2: 0,
                }),
            );
        }

        if (
            pcdParameter.cameraRadius?.toString() !== this.data.pcdParameter.cameraRadius?.toString() ||
            pcdParameter.viewAngle !== this.data.pcdParameter.viewAngle ||
            pcdParameter.viewStartMiddleAngle !== this.data.pcdParameter.viewStartMiddleAngle
        ) {
            this.data.pcdParameter = {
                ...pcdParameter,
            };
            this.notify(UpdateReasons.update_param);
        }
    }

    public postureToPosition(
        points: number[],
        cameraIndex: number,
        shapeType: ShapeType,
        resultType: '4' | '8',
    ): THREE.Vector2[] {
        const intrinsics = this.data.cameraCalibs?.[cameraIndex];
        const extrinsics = this.data.cameraToBumpers?.[cameraIndex];
        const distortion = this.data.cameraDistortionParameter?.[cameraIndex];

        return transPostureToPosition(points, intrinsics, extrinsics, distortion, shapeType, resultType);
    }

    public deletePoint(clientID: number) {
        this.data.object.deletePointClientID = clientID;
        this.notify(UpdateReasons.delete_point);
        this.data.object.deletePointClientID = undefined;
    }

    public get configObject() {
        return this.data.config_object;
    }

    public set configObject(data: Partial<ConfigObject>) {
        this.data.config_object = {
            ...this.data.config_object,
            ...data,
        };
    }

    public fit(): void {
        this.notify(UpdateReasons.FITTED_CANVAS);
    }

    public get issueRegions(): Issue[] {
        return { ...this.data.issueRegions };
    }

    public get groupData(): GroupData {
        return { ...this.data.groupData };
    }

    public get configuration(): Configuration {
        return { ...this.data.configuration };
    }

    public destroy(): void {}
}

export { Controller };
