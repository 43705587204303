/*
 * @Author: swxy
 * @Date: 2023-04-23 17:24:46
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
    'setting.initialize': '初始化',

    'setting.initialize.sure': '是否确认将所有设置初始化',

    'setting.settingTip': '快捷键 {{setting}}',
    'setting.changePassword': '更改密码',

    'setting.changePasswordSuccess': '更改密码成功！',
    'setting.changePasswordConfirm': '更改',

    'setting.workspace.setting': '设置',
    'setting.workspace.3d': '3D',
    'setting.workspace.3dTarget': '3D目标物',
    'setting.workspace.3d.camera': '相机',
    'setting.workspace.3d.cameraTip': '控制相机操作',
    'setting.workspace.3d.cameraHelp': '当选中目标物时，使相机',
    'setting.workspace.3d.camera.force': '注视',
    'setting.workspace.3d.camera.forceTip':
        '当选中目标物的时候，相机位置不变，旋转看向目标物。（使目标物位于视图的中心）',
    'setting.workspace.3d.camera.follow': '跟随',
    'setting.workspace.3d.camera.followTip':
        '当选中目标物的时候，相机保持当前旋转，移动到目标物不远处。（使目标物位于视图的中心）',

    'setting.workspace.canvas.title': '画布',
    'setting.workspace.canvas.header': '画布',
    'setting.workspace.canvas.description': '对背景或点云进行设置',
    'setting.workspace.canvas.detail': '对背景或点云进行设置',
    'setting.workspace.canvas.pointcloudcolor.normal': '不设置',
    'setting.workspace.canvas.pointcloudcolor.color': '自身颜色',
    'setting.workspace.canvas.pointcloudcolor.intensity': '以强度值决定',
    'setting.workspace.canvas.pointcloudcolor.axisZ': '以坐标轴Z轴决定',
    'setting.workspace.canvas.pointCloudColor.title': '点云颜色',
    'setting.workspace.canvas.pointCloudColor.description':
        '设置点云的颜色，默认为不设置为白色。设置后，若没有相应的值，则仍为白色',
};
