/*
 * @Author: swxy
 * @Date: 2023-11-13 16:28:37
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { Float32BufferAttribute } from 'three';
import AmygoPcdModel from './AmygoPcdModel';
import AmygoImageModel from './AmygoImageModel';

interface ResourceProps {
    data: Blob;
    name: string;
}

class AmygoResource {
    public pcds: AmygoPcdModel[];
    public images: AmygoImageModel[] = [];
    public memory: number = 0; // 所占内存大小
    public count: number = 0; //总点数

    constructor(pcds: ResourceProps[] = [], imageDatas: ResourceProps[] = []) {
        this.pcds = pcds.map((pcd) => {
            this.memory += pcd.data.size;
            return new AmygoPcdModel(pcd);
        });
        this.images = imageDatas.map((imageData) => {
            this.memory += imageData.data.size;
            return new AmygoImageModel(imageData);
        });
    }

    public get isAnalyze() {
        if (this.pcds.length) {
            return this.pcds[0].isAnalyze;
        }
        if (this.images.length) {
            return this.images[0].isAnalyze;
        }
        return false;
    }

    public import(
        pcds: {
            positions: number[][];
            colors?: number[][];
        }[],
        images: {
            data: ImageBitmap;
        }[],
    ) {
        try {
            pcds.forEach(({ positions, colors = [] }, index) => {
                this.pcds[index].import(positions, colors);
            });
            images.forEach((image, index) => {
                this.images[index].import(image);
            });
            this.computeMemory();
        } catch (error) {
            throw new Error(`导入信息出错:${(error as Error).stack}`);
        }
    }

    public computeMemory() {
        this.memory = 0;
        this.pcds.forEach((_, index) => {
            this.memory += this.pcds[index].size;
        });
        this.images.forEach((_, index) => {
            this.memory += this.images[index].size;
        });
    }

    public clear() {
        this.pcds.forEach((pcd) => {
            pcd.clear();
        });
        this.images.forEach((image) => {
            image.clear();
        });
        this.computeMemory();
    }

    public destroy() {
        this.pcds.forEach((pcd) => {
            pcd.destroy();
        });
        this.images.forEach((image) => {
            image.destroy();
        });
    }
}

export default AmygoResource;
