/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import './styles.scss';
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';

import { UserAgreement } from 'reducers/interfaces';
import FooterDrawer from 'components/login-page/intel-footer-drawer';
import RegisterForm, { RegisterData, UserConfirmation } from './register-form';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import { registerAsync } from 'actions/auth-actions';

interface RegisterPageComponentProps {
    fetching: boolean;
    userAgreements: UserAgreement[];
    // onRegister: (registerData: RegisterData) => Promise<any>;
}

function RegisterPageComponent(props: RegisterPageComponentProps & RouteComponentProps): JSX.Element {
    const sizes = {
        style: {
            width: 400,
        },
    };
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const { fetching, userAgreements } = props;
    const { Content } = Layout;

    const onSubmit = async (registerData: RegisterData): Promise<void> => {
        const result = await dispatch(registerAsync(registerData)) as any;
        // console.log('注册结果：', result);
        if (result?.success) {
            message.success(t('login.registerSuccess'));
            history.push('/login');
        }
    }

    return (
        <Layout>
            <Content>
                <Row justify='center' align='middle' style={{ height: '100%' }} className='body'>
                    <Col {...sizes}>
                        <Title level={2}> {t('login.register')} </Title>
                        <RegisterForm
                            fetching={fetching}
                            userAgreements={userAgreements}
                            onSubmit={onSubmit}
                        />
                        <Row justify='start' align='top'>
                            <Col>
                                <Text strong>
                                    {t('login.hasAccount')}
                                    <Link to='/login'> {t('login.login')} </Link>
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Content>
            <FooterDrawer />
        </Layout>
    );
}

export default withRouter(RegisterPageComponent);
