// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Layout from 'antd/lib/layout';
import Slider from 'antd/lib/slider';
import Dropdown from 'antd/lib/dropdown';
import { PlusCircleOutlined, UpOutlined } from '@ant-design/icons';

import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import {
    ColorBy,
    GridColor,
    ObjectType,
    ContextMenuType,
    Workspace,
    ShapeType,
    ActivationModel,
    DimensionType,
} from 'reducers/interfaces';
import { LogType } from 'cvat-logger';
import { Canvas } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'canvas3d-wrapper';
import getCore from 'cvat-core-wrapper';
import consts from 'consts';
import CVATTooltip from 'components/common/cvat-tooltip';
import ImageSetupsContent from './image-setups-content';
import ContextImage from '../standard-workspace/context-image/context-image';
import { message } from 'antd';
import { Translation } from 'react-i18next';
import AttributeAnnotationImage from 'components/annotation-page/attribute-annotation-image';
import SuspensionComponent from 'components/common/suspension/suspension';
import ImageCameraManagerComponent from './image-camera-manager';
// import ResourceData from 'business/objects/resourceData';
import Resource from 'business/amygo/AmygoResource';
// import FrameData from 'business/frame';
import { ElementSizeMinStandard } from 'utils/elements';
import { ProjectDumpType } from 'utils/ConstType';
import ObjectState from 'business/objects/objectState';
import ChangeMultAttributeComponent from 'components/popups/changeMultAttributeComponent';
import FrameMeta from 'business/amygo/AmygoFrameMeta';

// const cvat = getCore();

const MAX_DISTANCE_TO_OPEN_SHAPE = 50;

interface Props {
    sidebarCollapsed: boolean;
    canvasInstance: Canvas;
    jobInstance: any;
    activatedStateID: number | null;
    activatedAttributeID: number | null;
    annotations: any[];
    frameData: any;
    frameAngle: number;
    frameFetching: boolean;
    frame: number;
    opacity: number;
    colorBy: ColorBy;
    selectedOpacity: number;
    outlined: boolean;
    outlineColor: string;
    showBitmap: boolean;
    showProjections: boolean;
    grid: boolean;
    gridSize: number;
    gridColor: GridColor;
    gridOpacity: number;
    activeLabelID: number;
    activeObjectType: ObjectType;
    curZLayer: number;
    minZLayer: number;
    maxZLayer: number;
    brightnessLevel: number;
    contrastLevel: number;
    saturationLevel: number;
    resetZoom: boolean;
    smoothImage: boolean;
    aamZoomMargin: number;
    showObjectsTextAlways: boolean;
    textFontSize: number;
    textPosition: 'auto' | 'center';
    textContent: string;
    showAllInterpolationTracks: boolean;
    workspace: Workspace;
    automaticBordering: boolean;
    intelligentPolygonCrop: boolean;
    keyMap: KeyMap;
    canvasBackgroundColor: string;
    switchableAutomaticBordering: boolean;
    activeModel: ActivationModel;
    selectedStatesID: number[];
    isOnlyShowSelectStates: boolean;
    cameraName?: string;

    onSetupCanvas: () => void;
    onDragCanvas: (enabled: boolean) => void;
    onZoomCanvas: (enabled: boolean) => void;
    onMergeObjects: (enabled: boolean) => void;
    onGroupObjects: (enabled: boolean) => void;
    onSplitTrack: (enabled: boolean) => void;
    onEditShape: (enabled: boolean) => void;
    onShapeDrawn: () => void;
    onResetCanvas: () => void;
    onUpdateAnnotations(states: any[]): void;
    onCreateAnnotations(sessionInstance: any, frame: number, states: any[]): void;
    onMergeAnnotations(sessionInstance: any, frame: number, states: any[]): void;
    onGroupAnnotations(sessionInstance: any, frame: number, states: any[]): void;
    onSplitAnnotations(sessionInstance: any, frame: number, state: any): void;
    onActivateObject(activatedStateID: number | null): void;
    onUpdateContextMenu(visible: boolean, left: number, top: number, type: ContextMenuType, pointID?: number): void;
    onAddZLayer(): void;
    onSwitchZLayer(cur: number): void;
    onChangeBrightnessLevel(level: number): void;
    onChangeContrastLevel(level: number): void;
    onChangeSaturationLevel(level: number): void;
    onChangeGridOpacity(opacity: number): void;
    onChangeGridColor(color: GridColor): void;
    onSwitchGrid(enabled: boolean): void;
    onSwitchAutomaticBordering(enabled: boolean): void;
    onFetchAnnotation(): void;
    onGetDataFailed(error: any): void;
    onStartIssue(position: number[]): void;

    onSelectObjects(clientIDs: number[]): void;

    onCollapseStates(states: any[], collapsed: boolean): void;
    onMouseMultSelect(e: MouseEvent): void;
    changeActiveModel(activeModel: ActivationModel): void;
}

export default class CanvasWrapperComponent extends React.PureComponent<Props> {
    moveClientID: number | null = null; // 记录当前鼠标上次移动到的对象id, 只是用来比较，不需要引发更新操作。因此不用state

    public componentDidMount(): void {
        const {
            automaticBordering,
            intelligentPolygonCrop,
            showObjectsTextAlways,
            workspace,
            showProjections,
            selectedOpacity,
            smoothImage,
            textFontSize,
            textPosition,
            textContent,
            jobInstance,
        } = this.props;
        const { canvasInstance } = this.props as { canvasInstance: Canvas };

        // It's awful approach from the point of view React
        // But we do not have another way because cvat-canvas returns regular DOM element
        const [wrapper] = window.document.getElementsByClassName('cvat-canvas-container');
        wrapper.appendChild(canvasInstance.html());

        canvasInstance.configure({
            smoothImage,
            autoborders: automaticBordering,
            undefinedAttrValue: consts.UNDEFINED_ATTRIBUTE_VALUE,
            displayAllText: showObjectsTextAlways,
            forceDisableEditing: workspace === Workspace.REVIEW_WORKSPACE,
            intelligentPolygonCrop,
            showProjections,
            creationOpacity: selectedOpacity,
            textFontSize,
            textPosition,
            textContent,
            isSegmentation: jobInstance?.engineTask?.engineProject?.projectDumpType === 6,
        });

        this.initialSetup();
        this.updateCanvas();
    }

    public componentDidUpdate(prevProps: Props): void {
        const {
            opacity,
            colorBy,
            selectedOpacity,
            outlined,
            outlineColor,
            showBitmap,
            frameData,
            frameAngle,
            annotations,
            sidebarCollapsed,
            activatedStateID,
            curZLayer,
            resetZoom,
            smoothImage,
            grid,
            gridSize,
            gridOpacity,
            gridColor,
            brightnessLevel,
            contrastLevel,
            saturationLevel,
            workspace,
            frameFetching,
            showObjectsTextAlways,
            textFontSize,
            textPosition,
            textContent,
            showAllInterpolationTracks,
            automaticBordering,
            intelligentPolygonCrop,
            showProjections,
            canvasBackgroundColor,
            selectedStatesID,
            isOnlyShowSelectStates,
            onFetchAnnotation,
            cameraName,
        } = this.props;
        const { canvasInstance } = this.props as { canvasInstance: Canvas };
        if (
            prevProps.showObjectsTextAlways !== showObjectsTextAlways ||
            prevProps.automaticBordering !== automaticBordering ||
            prevProps.showProjections !== showProjections ||
            prevProps.intelligentPolygonCrop !== intelligentPolygonCrop ||
            prevProps.selectedOpacity !== selectedOpacity ||
            prevProps.smoothImage !== smoothImage ||
            prevProps.textFontSize !== textFontSize ||
            prevProps.textPosition !== textPosition ||
            prevProps.textContent !== textContent
        ) {
            canvasInstance.configure({
                undefinedAttrValue: consts.UNDEFINED_ATTRIBUTE_VALUE,
                displayAllText: showObjectsTextAlways,
                autoborders: automaticBordering,
                showProjections,
                intelligentPolygonCrop,
                creationOpacity: selectedOpacity,
                smoothImage,
                textFontSize,
                textPosition,
                textContent,
            });
        }

        if (prevProps.showAllInterpolationTracks !== showAllInterpolationTracks) {
            onFetchAnnotation();
        }

        if (prevProps.sidebarCollapsed !== sidebarCollapsed) {
            const [sidebar] = window.document.getElementsByClassName('cvat-objects-sidebar');
            if (sidebar) {
                sidebar.addEventListener(
                    'transitionend',
                    () => {
                        canvasInstance.fitCanvas();
                    },
                    { once: true },
                );
            }
        }

        if (
            prevProps.selectedStatesID &&
            prevProps.selectedStatesID.length &&
            (!selectedStatesID ||
                !selectedStatesID.length ||
                prevProps.selectedStatesID.length !== selectedStatesID.length)
        ) {
            canvasInstance.activates([]);
            prevProps.selectedStatesID.forEach((actID: any) => {
                const el = window.document.getElementById(`cvat_canvas_shape_${actID}`);
                if (el) {
                    (el as any).instance.fill({ opacity: opacity / 100 });
                }
            });
        }

        if (prevProps.activatedStateID !== null && prevProps.activatedStateID !== activatedStateID) {
            canvasInstance.activate(null);
            const el = window.document.getElementById(`cvat_canvas_shape_${prevProps.activatedStateID}`);
            if (el) {
                (el as any).instance.fill({ opacity });
            }
        }

        if (gridSize !== prevProps.gridSize) {
            canvasInstance.grid(gridSize, gridSize);
        }

        if (gridOpacity !== prevProps.gridOpacity || gridColor !== prevProps.gridColor || grid !== prevProps.grid) {
            const gridElement = window.document.getElementById('cvat_canvas_grid');
            const gridPattern = window.document.getElementById('cvat_canvas_grid_pattern');
            if (gridElement) {
                gridElement.style.display = grid ? 'block' : 'none';
            }
            if (gridPattern) {
                gridPattern.style.stroke = gridColor.toLowerCase();
                gridPattern.style.opacity = `${gridOpacity}`;
            }
        }

        if (
            brightnessLevel !== prevProps.brightnessLevel ||
            contrastLevel !== prevProps.contrastLevel ||
            saturationLevel !== prevProps.saturationLevel
        ) {
            const backgroundElement = window.document.getElementById('cvat_canvas_background');
            if (backgroundElement) {
                const filter = `brightness(${brightnessLevel}) contrast(${contrastLevel}) saturate(${saturationLevel})`;
                backgroundElement.style.filter = filter;
            }
        }

        if (
            prevProps.cameraName !== cameraName ||
            prevProps.annotations !== annotations ||
            prevProps.frameData !== frameData ||
            prevProps.curZLayer !== curZLayer ||
            prevProps.isOnlyShowSelectStates !== isOnlyShowSelectStates ||
            (isOnlyShowSelectStates &&
                (prevProps.activatedStateID !== activatedStateID || prevProps.selectedStatesID !== selectedStatesID))
        ) {
            this.updateCanvas();
        }

        if (prevProps.frame !== frameData.number && resetZoom && workspace !== Workspace.ATTRIBUTE_ANNOTATION) {
            canvasInstance.html().addEventListener(
                'canvas.setup',
                () => {
                    canvasInstance.fit();
                },
                { once: true },
            );
        }

        if (
            prevProps.opacity !== opacity ||
            prevProps.outlined !== outlined ||
            prevProps.outlineColor !== outlineColor ||
            prevProps.selectedOpacity !== selectedOpacity ||
            prevProps.colorBy !== colorBy
        ) {
            this.updateShapesView();
        }

        if (prevProps.showBitmap !== showBitmap) {
            canvasInstance.bitmap(showBitmap);
        }

        if (prevProps.frameAngle !== frameAngle) {
            canvasInstance.rotate(frameAngle);
        }

        if (prevProps.workspace !== workspace) {
            if (workspace === Workspace.REVIEW_WORKSPACE) {
                canvasInstance.configure({
                    forceDisableEditing: true,
                });
            } else if (prevProps.workspace === Workspace.REVIEW_WORKSPACE) {
                canvasInstance.configure({
                    forceDisableEditing: false,
                });
            }
        }

        if (frameFetching !== prevProps.frameFetching) {
            const loadingAnimation = window.document.getElementById('cvat_canvas_loading_animation');
            if (loadingAnimation) {
                if (frameFetching) {
                    loadingAnimation.classList.remove('cvat_canvas_hidden');
                } else {
                    loadingAnimation.classList.add('cvat_canvas_hidden');
                }
            }
        }

        if (prevProps.canvasBackgroundColor !== canvasBackgroundColor) {
            const canvasWrapperElement = window.document
                .getElementsByClassName('cvat-canvas-container')
                .item(0) as HTMLElement | null;
            if (canvasWrapperElement) {
                canvasWrapperElement.style.backgroundColor = canvasBackgroundColor;
            }
        }

        this.activateOnCanvas();
    }

    public componentWillUnmount(): void {
        const { canvasInstance } = this.props as { canvasInstance: Canvas };

        canvasInstance.html().removeEventListener('mousedown', this.onCanvasMouseDown);
        canvasInstance.html().removeEventListener('click', this.onCanvasClicked);
        canvasInstance.html().removeEventListener('contextmenu', this.onCanvasContextMenu);
        canvasInstance.html().removeEventListener('canvas.editstart', this.onCanvasEditStart);
        canvasInstance.html().removeEventListener('canvas.edited', this.onCanvasEditDone);
        canvasInstance.html().removeEventListener('canvas.dragstart', this.onCanvasDragStart);
        canvasInstance.html().removeEventListener('canvas.dragstop', this.onCanvasDragDone);
        canvasInstance.html().removeEventListener('canvas.zoomstart', this.onCanvasZoomStart);
        canvasInstance.html().removeEventListener('canvas.zoomstop', this.onCanvasZoomDone);

        canvasInstance.html().removeEventListener('canvas.setup', this.onCanvasSetup);
        canvasInstance.html().removeEventListener('canvas.canceled', this.onCanvasCancel);
        canvasInstance.html().removeEventListener('canvas.find', this.onCanvasFindObject);
        canvasInstance.html().removeEventListener('canvas.deactivated', this.onCanvasShapeDeactivated);
        canvasInstance.html().removeEventListener('canvas.moved', this.onCanvasCursorMoved);

        canvasInstance.html().removeEventListener('canvas.zoom', this.onCanvasZoomChanged);
        canvasInstance.html().removeEventListener('canvas.fit', this.onCanvasImageFitted);
        canvasInstance.html().removeEventListener('canvas.dragshape', this.onCanvasShapeDragged);
        canvasInstance.html().removeEventListener('canvas.resizeshape', this.onCanvasShapeResized);
        canvasInstance.html().removeEventListener('canvas.clicked', this.onCanvasShapeClicked);
        canvasInstance.html().removeEventListener('canvas.drawn', this.onCanvasShapeDrawn);
        canvasInstance.html().removeEventListener('canvas.merged', this.onCanvasObjectsMerged);
        canvasInstance.html().removeEventListener('canvas.groupped', this.onCanvasObjectsGroupped);
        canvasInstance.html().removeEventListener('canvas.regionselected', this.onCanvasPositionSelected);
        canvasInstance.html().removeEventListener('canvas.splitted', this.onCanvasTrackSplitted);

        canvasInstance.html().removeEventListener('canvas.contextmenu', this.onCanvasPointContextMenu);
        canvasInstance.html().removeEventListener('canvas.error', this.onCanvasErrorOccurrence);

        window.removeEventListener('resize', this.fitCanvas);
    }

    private onCanvasErrorOccurrence = (event: any): void => {
        const { exception } = event.detail;
        const { onGetDataFailed } = this.props;
        onGetDataFailed(exception);
    };

    private isSafeArea = (state: any, newPoints?: number[]): boolean => {
        const { jobInstance } = this.props;
        if (state.shapeType === ShapeType.RECTANGLE && jobInstance?.minWidth > 0 && jobInstance?.minHeight > 0) {
            const [minX, minY, maxX, maxY] = (newPoints || state.points);
            if (maxX - minX < jobInstance.minWidth || maxY - minY < jobInstance.minHeight) {
                // console.log('不可： ', state.points);

                // message.info(`没有达到最低标注条件：宽-${jobInstance.minWidth}， 高-${jobInstance.minHeight}`);
                message.info(
                    <Translation>
                        {(t) =>
                            t('workspace.canvas.rectangleHelp', {
                                minWidth: jobInstance.minWidth,
                                minHeight: jobInstance.minHeight,
                            })
                        }
                    </Translation>,
                );
                return false;
                // if (state.clientID) {
                //     return false;
                // }
                // throw new Error('Error: Crossed');
            }
        }
        return true;
    };

    private getObjects = (states: any[], duration: any): any[] => {
        const { jobInstance, activeLabelID, activeObjectType, frame } = this.props;

        const newStates: any[] = [];

        states.forEach((state: any) => {
            const isDrawnFromScratch = !state.label;
            if (isDrawnFromScratch) {
                jobInstance.logger.log(LogType.drawObject, { count: 1, duration });
            } else {
                jobInstance.logger.log(LogType.pasteObject, { count: 1, duration });
            }

            const isSafe = this.isSafeArea(state);
            if (isSafe) {
                const newState = { ...state };
                newState.objectType = state.objectType || activeObjectType;
                // newState.label = newState.label ||
                //     jobInstance.labels.filter((label: any) => label.id === activeLabelID)[0];
                newState.label =
                    newState.label || jobInstance.labels.filter((label: any) => label.id === activeLabelID)[0];
                newState.rotation = newState.rotation || 0;
                newState.pointsLine = newState.pointsLine || 0;

                newState.occluded = state.occluded || false;
                newState.frame = frame;

                if (newState.shapeType === ShapeType.POLYLINE) {
                    newState.pointOccludeds = new Array(state.points.length).fill(false);
                }

                const objectState = new ObjectState(newState);
                newStates.push(objectState);
            }

            // return objectState;
        });

        return newStates;
    };

    private onCanvasShapeDrawn = (event: any): void => {
        const { jobInstance, frame, onShapeDrawn, onCreateAnnotations } = this.props;

        if (!event.detail.continue) {
            onShapeDrawn();
        }

        const { state, states, duration } = event.detail;
        const objects = this.getObjects(state ? [state] : states, duration);

        onCreateAnnotations(jobInstance, frame, objects);
        // const { state, duration } = event.detail;
        // const isDrawnFromScratch = !state.label;
        // if (isDrawnFromScratch) {
        //     jobInstance.logger.log(LogType.drawObject, { count: 1, duration });
        // } else {
        //     jobInstance.logger.log(LogType.pasteObject, { count: 1, duration });
        // }

        // state.objectType = state.objectType || activeObjectType;
        // state.label = state.label || jobInstance.labels.filter((label: any) => label.id === activeLabelID)[0];
        // state.occluded = state.occluded || false;
        // state.frame = frame;
        // state.rotation = state.rotation || 0;
        // const objectState = new cvat.classes.ObjectState(state);
        // onCreateAnnotations(jobInstance, frame, [objectState]);
    };

    private onCanvasObjectsMerged = (event: any): void => {
        const { jobInstance, frame, onMergeAnnotations, onMergeObjects } = this.props;

        onMergeObjects(false);

        const { states, duration } = event.detail;
        jobInstance.logger.log(LogType.mergeObjects, {
            duration,
            count: states.length,
        });
        onMergeAnnotations(jobInstance, frame, states);
    };

    private onCanvasObjectsGroupped = (event: any): void => {
        const { jobInstance, frame, onGroupAnnotations, onGroupObjects } = this.props;

        onGroupObjects(false);

        const { states } = event.detail;
        onGroupAnnotations(jobInstance, frame, states);
    };

    private onCanvasPositionSelected = (event: any): void => {
        const { onResetCanvas, onStartIssue } = this.props;
        const { points } = event.detail;
        onStartIssue(points);
        onResetCanvas();
    };

    private onCanvasTrackSplitted = (event: any): void => {
        const { jobInstance, frame, onSplitAnnotations, onSplitTrack } = this.props;

        onSplitTrack(false);

        const { state } = event.detail;
        onSplitAnnotations(jobInstance, frame, state);
    };

    private fitCanvas = (): void => {
        const { canvasInstance } = this.props;
        if (canvasInstance) {
            canvasInstance.fitCanvas();
        }
    };

    private onCanvasMouseDown = (e: MouseEvent): void => {
        const { workspace, activatedStateID, selectedStatesID, onActivateObject, onMouseMultSelect } = this.props;

        if ((e.target as HTMLElement).tagName === 'svg' && e.button !== 2) {
            if (
                (activatedStateID !== null || (selectedStatesID && selectedStatesID.length)) &&
                workspace !== Workspace.ATTRIBUTE_ANNOTATION
            ) {
                onActivateObject(null);
            }
        }

        // 只有在Standard和Review下，才可以多选
        if (
            (e.ctrlKey || e.metaKey) &&
            (Workspace.STANDARD === workspace || Workspace.REVIEW_WORKSPACE === workspace)
        ) {
            // 开始监听鼠标down和up事件，并将移动范围内的shapes选中
            onMouseMultSelect(e);
        }
    };

    private onCanvasClicked = (): void => {
        const { onUpdateContextMenu } = this.props;
        const { canvasInstance } = this.props as { canvasInstance: Canvas };
        onUpdateContextMenu(false, 0, 0, ContextMenuType.CANVAS_SHAPE);
        if (!canvasInstance.html().contains(document.activeElement) && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    };

    private onCanvasContextMenu = (e: MouseEvent): void => {
        const { activatedStateID, onUpdateContextMenu } = this.props;

        if (e.target && !(e.target as HTMLElement).classList.contains('svg_select_points')) {
            onUpdateContextMenu(activatedStateID !== null, e.clientX, e.clientY, ContextMenuType.CANVAS_SHAPE);
        }
    };

    private onCanvasShapeDragged = (e: any): void => {
        const { jobInstance } = this.props;
        const { id } = e.detail;
        jobInstance.logger.log(LogType.dragObject, { id });
    };

    private onCanvasShapeResized = (e: any): void => {
        const { jobInstance } = this.props;
        const { id } = e.detail;
        jobInstance.logger.log(LogType.resizeObject, { id });
    };

    private onCanvasImageFitted = (): void => {
        const { jobInstance } = this.props;
        jobInstance.logger.log(LogType.fitImage);
    };

    private onCanvasZoomChanged = (): void => {
        const { jobInstance } = this.props;
        jobInstance.logger.log(LogType.zoomImage);
    };

    // private onCanvasShapeClicked = (e: any): void => {
    //     const { clientID } = e.detail.state;
    //     const sidebarItem = window.document.getElementById(`cvat-objects-sidebar-state-item-${clientID}`);
    //     if (sidebarItem) {
    //         sidebarItem.scrollIntoView();
    //     }
    // };

    private onCanvasShapeClicked = async (event: any): Promise<void> => {
        const { jobInstance } = this.props;
        const result = await jobInstance.annotations.select(event.detail.states, event.detail.x, event.detail.y);

        if (result?.state) {

            const { clientID, relation = {} } = result.state;
            const { parent } = relation;
            // const { clientID } = e.detail.state;
            const {
                annotations,
                // activatedStateID,
                workspace,
                activeModel,

                onCollapseStates,
                onSelectObjects,
                onActivateObject,
                // selectedStatesID,
            } = this.props;

            if (parent && parent.clientID) {
                const sidebarItem = window.document.getElementById(`cvat-objects-sidebar-state-item-${parent.clientID}`);
                if (sidebarItem) {
                    sidebarItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    // var target = document.getElementById("target");
                    // target.parentNode.scrollTop = target.offsetTop;
                    // sidebarItem.parentNode.scrollTop = sidebarItem.offsetTop;
                }
            }
            setTimeout(() => {
                const sidebarItem = window.document.getElementById(`cvat-objects-sidebar-state-item-${clientID}`);
                if (sidebarItem) {
                    sidebarItem.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    // var target = document.getElementById("target");
                    // target.parentNode.scrollTop = target.offsetTop;
                    // sidebarItem.parentNode.scrollTop = sidebarItem.offsetTop;
                }
            });

            // 只有Standard 和 才可以多选
            if (workspace !== Workspace.STANDARD && workspace !== Workspace.REVIEW_WORKSPACE) {
                return;
            }
            // 只按下了ctrlKey或者mac上的command、window上的windows键。
            const mouseEvent = event.detail.e;
            if (mouseEvent && (mouseEvent.ctrlKey || mouseEvent.metaKey) && !mouseEvent.altKey && !mouseEvent.shiftKey) {
                // const selecteds = [clientID];
                // if (activatedStateID && !selectedStatesID.includes(activatedStateID)) {
                //     selecteds.splice(0, 0, activatedStateID);
                // }
                onSelectObjects([clientID]);
            } else if (
                activeModel === ActivationModel.clickActive &&
                [Workspace.STANDARD, Workspace.REVIEW_WORKSPACE].includes(workspace) &&
                mouseEvent &&
                (mouseEvent.button === 0 || mouseEvent.button === 2) &&
                mouseEvent.buttons <= 2
            ) {
                // 处于点击模式下，且是standard或者review模式下
                onActivateObject(clientID);
                // const {
                //     annotations, onCollapseStates,
                // } = this.props;
                // const objectState = annotations.filter((item: any) => item.clientID === result.state.clientID);
                // onCollapseStates(objectState || [], false);
            }
            const { activatedStateID } = this.props;
            // 修改 2021-10-12 添加点击左侧框，展示右侧详情
            if (annotations && annotations.length && activatedStateID) {
                const objectState = annotations.filter((item: any) => item.clientID === activatedStateID);
                onCollapseStates(objectState || [], false);
            }
        }
    };

    private onCanvasObjectsSelected = (e: any): void => {
        const { detail } = e;
        const { states } = detail;
        const { onSelectObjects } = this.props;
        if (states && states.length) {
            onSelectObjects(states.map((state: any) => state.clientID));
        }
    };

    private onCanvasShapeDeactivated = (e: any): void => {
        const { onActivateObject, activatedStateID } = this.props;
        const { state } = e.detail;

        // when we activate element, canvas deactivates the previous
        // and triggers this event
        // in this case we do not need to update our state
        if (state.clientID === activatedStateID) {
            onActivateObject(null);
        }
    };

    private onCanvasCursorMoved = async (event: any): Promise<void> => {
        const { activeModel } = this.props;
        if (activeModel !== ActivationModel.moveInActive) {
            // 不是移动触发模式，不需要执行下面的代码
            return;
        }
        const { jobInstance, activatedStateID, workspace, onActivateObject, selectedStatesID } = this.props;

        if (![Workspace.STANDARD, Workspace.REVIEW_WORKSPACE].includes(workspace)) {
            return;
        }

        const result = await jobInstance.annotations.select(event.detail.states, event.detail.x, event.detail.y);

        if (result && result.state) {
            if (result.state.shapeType === 'polyline' || result.state.shapeType === 'points') {
                if (result.distance > MAX_DISTANCE_TO_OPEN_SHAPE) {
                    return;
                }
            }

            if (activatedStateID !== result.state.clientID) {
                // onActivateObject(result.state.clientID);
                if (!selectedStatesID.includes(result.state.clientID)) {
                    onActivateObject(result.state.clientID);
                    const { annotations, onCollapseStates } = this.props;
                    const objectState = annotations.filter((item: any) => item.clientID === result.state.clientID);
                    onCollapseStates(objectState || [], false);
                }
            }
        }
    };

    private onCanvasEditStart = (): void => {
        const { onActivateObject, onEditShape } = this.props;
        onActivateObject(null);
        onEditShape(true);
    };

    private onCanvasEditDone = (event: any): void => {
        const { onEditShape, onUpdateAnnotations } = this.props;

        onEditShape(false);

        const { state, points, rotation, pointsLine, states, changesById, elements } = event.detail;
        if (states && states.length) {
            states.forEach((estate: any) => {
                const { points, pointsLine, elements } = changesById[estate.clientID];
                // eslint-disable-next-line no-param-reassign
                estate.points = points;
                state.rotation = rotation;
                estate.pointsLine = pointsLine;
                if (Array.isArray(elements) && elements.length) {
                    state.elements = elements;
                }
            });
            onUpdateAnnotations(states);
            return;
        }
        const isSafeArea = this.isSafeArea(state, points);
        if (isSafeArea) {
            state.points = points;
            state.rotation = rotation;
            state.pointsLine = pointsLine;
            if (Array.isArray(elements) && elements.length) {
                state.elements = elements;
            }
        } else {
            state.points = [...state.points];
        }
        onUpdateAnnotations([state]);
    };

    private onCanvasDragStart = (): void => {
        const { onDragCanvas } = this.props;
        onDragCanvas(true);
    };

    private onCanvasDragDone = (): void => {
        const { onDragCanvas } = this.props;
        onDragCanvas(false);
    };

    private onCanvasZoomStart = (): void => {
        const { onZoomCanvas } = this.props;
        onZoomCanvas(true);
    };

    private onCanvasZoomDone = (): void => {
        const { onZoomCanvas } = this.props;
        onZoomCanvas(false);
    };

    private onCanvasSetup = (): void => {
        const { onSetupCanvas } = this.props;
        onSetupCanvas();
        this.updateShapesView();
        this.activateOnCanvas();
    };

    private onCanvasCancel = (): void => {
        const { onResetCanvas } = this.props;
        onResetCanvas();
    };

    private onCanvasFindObject = async (e: any): Promise<void> => {
        const { jobInstance } = this.props;
        const { canvasInstance } = this.props as { canvasInstance: Canvas };

        const result = await jobInstance.annotations.select(e.detail.states, e.detail.x, e.detail.y);

        if (result && result.state) {
            if (result.state.shapeType === 'polyline' || result.state.shapeType === 'points') {
                if (result.distance > MAX_DISTANCE_TO_OPEN_SHAPE) {
                    return;
                }
            }

            canvasInstance.select(result.state);
        }
    };

    private onCanvasPointContextMenu = (e: any): void => {
        const { activatedStateID, onUpdateContextMenu, annotations } = this.props;

        const [state] = annotations.filter((el: any) => el.clientID === activatedStateID);
        if (![ShapeType.CUBOID, ShapeType.RECTANGLE].includes(state.shapeType)) {
            onUpdateContextMenu(
                activatedStateID !== null,
                e.detail.mouseEvent.clientX,
                e.detail.mouseEvent.clientY,
                ContextMenuType.CANVAS_SHAPE_POINT,
                e.detail.pointID,
            );
        }
    };

    private activateSetOnCanvas(): void {
        const { activatedStateID, activatedAttributeID, selectedOpacity, aamZoomMargin, workspace, annotations } =
            this.props;
        const { canvasInstance } = this.props as { canvasInstance: Canvas };

        if (activatedStateID !== null) {
            const [activatedState] = annotations.filter((state: any): boolean => state.clientID === activatedStateID);
            if (workspace === Workspace.ATTRIBUTE_ANNOTATION) {
                if (activatedState.objectType !== ObjectType.TAG) {
                    canvasInstance.focus(activatedStateID, aamZoomMargin);
                } else {
                    canvasInstance.fit();
                }
            }
            if (activatedState && activatedState.objectType !== ObjectType.TAG) {
                canvasInstance.activate(activatedStateID, activatedAttributeID);
            }
            const el = window.document.getElementById(`cvat_canvas_shape_${activatedStateID}`);
            if (el) {
                (el as any as SVGElement).setAttribute('fill-opacity', `${selectedOpacity}`);
            }
        }
    }

    private activatesSetOnCanvas(activatedStatesID: any[]): void {
        if (activatedStatesID && activatedStatesID.length) {
            const { workspace } = this.props;
            if (workspace !== Workspace.ATTRIBUTE_ANNOTATION) {
                const { activatedAttributeID, selectedOpacity, annotations } = this.props;
                const { canvasInstance } = this.props as { canvasInstance: Canvas };

                const selectStates = annotations.reduce((acc, state): any[] => {
                    if (activatedStatesID.includes(state.clientID) && state.objectType !== ObjectType.TAG) {
                        acc.push(state.clientID);
                    }
                    return acc;
                }, []);

                if (selectStates && selectStates.length) {
                    canvasInstance.activates(selectStates, activatedAttributeID);
                }

                activatedStatesID.forEach((actID: number): void => {
                    const el = window.document.getElementById(`cvat_canvas_shape_${actID}`);
                    if (el) {
                        (el as any as SVGElement).setAttribute('fill-opacity', `${selectedOpacity / 100}`);
                    }
                });
            }
        }
    }

    // 选中效果
    private activateOnCanvas(): void {
        const {
            activatedStateID,
            // activatedAttributeID,
            // canvasInstance,
            // selectedOpacity,
            // aamZoomMargin,
            // workspace,
            // annotations,
            selectedStatesID,
        } = this.props;

        // 2021-10-14更改为可能多选
        if (selectedStatesID && selectedStatesID.length) {
            this.activatesSetOnCanvas(selectedStatesID);
        } else if (activatedStateID !== null) {
            // this.activateSetOnCanvas(activatedStateID);
            this.activateSetOnCanvas();
        }
    }

    private updateSubShapesView(state: any): void {
        // const { opacity, colorBy, outlined, outlineColor } = this.props;

        //     let shapeColor = '';

        //     if (colorBy === ColorBy.INSTANCE) {
        //         shapeColor = state.color;
        //     } else if (colorBy === ColorBy.GROUP) {
        //         shapeColor = state.group.color;
        //     } else if (colorBy === ColorBy.LABEL) {
        //         shapeColor = state.label.color;
        //     }

        //     // TODO: In this approach CVAT-UI know details of implementations CVAT-CANVAS (svg.js)
        //     const shapeView = window.document.getElementById(`cvat_canvas_shape_${state.clientID}`);
        //     if (shapeView) {
        //         const handler = (shapeView as any).instance.remember('_selectHandler');
        //         if (handler && handler.nested) {
        //             handler.nested.fill({ color: shapeColor });
        //         }

        //         (shapeView as any).instance.fill({ color: shapeColor, opacity });
        //         (shapeView as any).instance.stroke({ color: outlined ? outlineColor : shapeColor });
        //     }
    }

    private getColor = (label: any, attributes: Record<number, string>, state: any) => {
        const { opacity, colorBy, outlined, outlineColor } = this.props;
        let { attributes: labelAttr } = label;
        let shapeColor = '';
        if (colorBy === ColorBy.INSTANCE) {
            shapeColor = state.color;
        } else if (colorBy === ColorBy.GROUP) {
            shapeColor = state.group.color;
        } else if (colorBy === ColorBy.LABEL) {
            shapeColor = state.label.color;
        }

        try {
            labelAttr.forEach((attr: any) => {
                if (attr.colors?.length) {
                    const value = attributes[attr.id];

                    const index = (attr.values as string[]).findIndex((str => value === str));
                    if (index >= 0) {
                        shapeColor = attr.colors[index];
                    }
                }
            })
        } catch (error) {

        }

        return shapeColor;
    };

    private updateShapesView(): void {
        const { annotations, opacity, colorBy, outlined, outlineColor } = this.props;

        for (const state of annotations) {
            let shapeColor = this.getColor(state.label, state.attributes, state);

            // if (colorBy === ColorBy.INSTANCE) {
            //     shapeColor = state.color;
            // } else if (colorBy === ColorBy.GROUP) {
            //     shapeColor = state.group.color;
            // } else if (colorBy === ColorBy.LABEL) {
            //     shapeColor = state.label.color;
            // }

            // TODO: In this approach CVAT-UI know details of implementations CVAT-CANVAS (svg.js)
            const shapeView = window.document.getElementById(`cvat_canvas_shape_${state.clientID}`);
            if (shapeView) {
                const handler = (shapeView as any).instance.remember('_selectHandler');
                if (handler && handler.nested) {
                    handler.nested.fill({ color: shapeColor });
                }

                (shapeView as any).instance.fill({ color: shapeColor, opacity });
                (shapeView as any).instance.stroke({ color: outlined ? outlineColor : shapeColor });
            }

            // this.updateSubShapesView();
        }
    }

    private checkedSelect(object: any, activatedStateID: number | null, selectedStatesID: number[]): boolean {
        return object.clientID === activatedStateID || selectedStatesID.includes(object.clientID);
    }

    private async updateImage(): Promise<void> {
        const {
            curZLayer,
            annotations,
            frameData,
            canvasInstance,
            isOnlyShowSelectStates,
            activatedStateID,
            selectedStatesID,
            cameraName,
            jobInstance,
            frame,
        } = this.props;
        // console.log('相机名称：', cameraName)
        // canvasInstance.changeFrame(frame, cameraName);
        const data = await frameData.data() as Resource;
        if (data) {
            const objects = annotations.filter((e) => {
                if (e.objectType === ObjectType.TAG) {
                    return false;
                }
                if (isOnlyShowSelectStates && !this.checkedSelect(e, activatedStateID, selectedStatesID)) {
                    return false;
                }
                return true;
            });

            let index = 0;
            if (jobInstance.cams?.length) {
                index = jobInstance.cams.findIndex((name: string) => name === cameraName);
            }

            canvasInstance?.setupImageUpdate(frame, {
                image: data.images[index].data!,
                name: data.images[index].name,
            }, objects, curZLayer);
        }
    }

    private updateCanvas(): void {
        const {
            curZLayer,
            annotations,
            frameData,
            canvasInstance,
            isOnlyShowSelectStates,
            activatedStateID,
            selectedStatesID,
            cameraName,
            jobInstance,
        } = this.props;

        if (!jobInstance?.isNotOldTask || !jobInstance?.isNotOldTask()) {
            if (frameData !== null && canvasInstance) {
                const objects = annotations.filter((e) => {
                    if (e.objectType === ObjectType.TAG) {
                        return false;
                    }
                    if (isOnlyShowSelectStates && !this.checkedSelect(e, activatedStateID, selectedStatesID)) {
                        return false;
                    }
                    return true;
                });

                canvasInstance.setup(frameData, objects, curZLayer);
            }
        } else {
            if (frameData !== null && canvasInstance) {
                this.updateImage();
            }
        }
    }

    private initialSetup(): void {
        const {
            grid,
            gridSize,
            gridColor,
            gridOpacity,
            brightnessLevel,
            contrastLevel,
            saturationLevel,
            canvasBackgroundColor,
            workspace,
            changeActiveModel,
        } = this.props;
        const { canvasInstance } = this.props as { canvasInstance: Canvas };

        // Size
        window.addEventListener('resize', this.fitCanvas);
        this.fitCanvas();

        // Grid
        const gridElement = window.document.getElementById('cvat_canvas_grid');
        const gridPattern = window.document.getElementById('cvat_canvas_grid_pattern');
        if (gridElement) {
            gridElement.style.display = grid ? 'block' : 'none';
        }
        if (gridPattern) {
            gridPattern.style.stroke = gridColor.toLowerCase();
            gridPattern.style.opacity = `${gridOpacity}`;
        }
        canvasInstance.grid(gridSize, gridSize);

        // Filters
        const backgroundElement = window.document.getElementById('cvat_canvas_background');
        if (backgroundElement) {
            const filter = `brightness(${brightnessLevel}) contrast(${contrastLevel}) saturate(${saturationLevel})`;
            backgroundElement.style.filter = filter;
        }

        const canvasWrapperElement = window.document
            .getElementsByClassName('cvat-canvas-container')
            .item(0) as HTMLElement | null;
        if (canvasWrapperElement) {
            canvasWrapperElement.style.backgroundColor = canvasBackgroundColor;
        }

        // Events
        canvasInstance.html().addEventListener(
            'canvas.setup',
            () => {
                const { activatedStateID, activatedAttributeID } = this.props;
                canvasInstance.fit();
                canvasInstance.activate(activatedStateID, activatedAttributeID);
            },
            { once: true },
        );

        // standard 默认为
        if (workspace === Workspace.STANDARD || workspace === Workspace.REVIEW_WORKSPACE) {
            changeActiveModel(
                workspace === Workspace.STANDARD ? ActivationModel.clickActive : ActivationModel.moveInActive,
            );
        }

        canvasInstance.html().addEventListener('mousedown', this.onCanvasMouseDown);
        canvasInstance.html().addEventListener('click', this.onCanvasClicked);
        canvasInstance.html().addEventListener('contextmenu', this.onCanvasContextMenu);
        canvasInstance.html().addEventListener('canvas.editstart', this.onCanvasEditStart);
        canvasInstance.html().addEventListener('canvas.edited', this.onCanvasEditDone);
        canvasInstance.html().addEventListener('canvas.dragstart', this.onCanvasDragStart);
        canvasInstance.html().addEventListener('canvas.dragstop', this.onCanvasDragDone);
        canvasInstance.html().addEventListener('canvas.zoomstart', this.onCanvasZoomStart);
        canvasInstance.html().addEventListener('canvas.zoomstop', this.onCanvasZoomDone);

        canvasInstance.html().addEventListener('canvas.setup', this.onCanvasSetup);
        canvasInstance.html().addEventListener('canvas.canceled', this.onCanvasCancel);
        canvasInstance.html().addEventListener('canvas.find', this.onCanvasFindObject);
        canvasInstance.html().addEventListener('canvas.deactivated', this.onCanvasShapeDeactivated);
        canvasInstance.html().addEventListener('canvas.moved', this.onCanvasCursorMoved);

        canvasInstance.html().addEventListener('canvas.zoom', this.onCanvasZoomChanged);
        canvasInstance.html().addEventListener('canvas.fit', this.onCanvasImageFitted);
        canvasInstance.html().addEventListener('canvas.dragshape', this.onCanvasShapeDragged);
        canvasInstance.html().addEventListener('canvas.resizeshape', this.onCanvasShapeResized);
        canvasInstance.html().addEventListener('canvas.clicked', this.onCanvasShapeClicked);
        canvasInstance.html().addEventListener('canvas.drawn', this.onCanvasShapeDrawn);
        canvasInstance.html().addEventListener('canvas.merged', this.onCanvasObjectsMerged);
        canvasInstance.html().addEventListener('canvas.groupped', this.onCanvasObjectsGroupped);
        canvasInstance.html().addEventListener('canvas.regionselected', this.onCanvasPositionSelected);
        canvasInstance.html().addEventListener('canvas.splitted', this.onCanvasTrackSplitted);
        canvasInstance.html().addEventListener('canvas.multSelect', this.onCanvasObjectsSelected);

        canvasInstance.html().addEventListener('canvas.contextmenu', this.onCanvasPointContextMenu);
        canvasInstance.html().addEventListener('canvas.error', this.onCanvasErrorOccurrence);
    }

    public render(): JSX.Element {
        const {
            maxZLayer,
            curZLayer,
            minZLayer,
            keyMap,
            switchableAutomaticBordering,
            automaticBordering,
            onSwitchAutomaticBordering,
            onSwitchZLayer,
            onAddZLayer,

            activatedStateID,
            annotations,
            workspace,
            jobInstance,
        } = this.props;

        const preventDefault = (event: KeyboardEvent | undefined): void => {
            if (event) {
                event.preventDefault();
            }
        };

        const subKeyMap = {
            SWITCH_AUTOMATIC_BORDERING: keyMap.SWITCH_AUTOMATIC_BORDERING,
        };

        const handlers = {
            SWITCH_AUTOMATIC_BORDERING: (event: KeyboardEvent | undefined) => {
                if (switchableAutomaticBordering) {
                    preventDefault(event);
                    onSwitchAutomaticBordering(!automaticBordering);
                }
            },
        };

        let minStandardElement = <></>
        if (jobInstance.minWidth && jobInstance.minHeight) {
            let minWidth = +jobInstance.minWidth;
            let minHeight = +jobInstance.minHeight;
            let strokeWidth = 1;
            if (jobInstance.dimension === DimensionType.DIM_2D && jobInstance.project.projectDumpType === ProjectDumpType.lane) {
                // 2D 车道线
                if (jobInstance.cameraName === 'camera0') {
                    minWidth = minWidth - 1;
                    minHeight = minHeight - 1;
                }
                if (jobInstance.cameraName === 'camera1') {
                    minWidth = minWidth + 1;
                    minHeight = minHeight + 1;
                }
                strokeWidth = 0.5;
            }

            minStandardElement = <ElementSizeMinStandard width={minWidth} height={minHeight} strokeWidth={strokeWidth} />
        }

        return (
            <Layout.Content style={{ position: 'relative' }}>
                <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
                {/*
                    This element doesn't have any props
                    So, React isn't going to rerender it
                    And it's a reason why cvat-canvas appended in mount function works
                */}
                <div
                    className='cvat-canvas-container'
                    style={{
                        overflow: 'hidden',
                        width: '100%',
                        // height: '100%',
                        height: 'calc(100% - 30px)',
                    }}
                />

                <ContextImage />

                <Dropdown trigger={['click']} placement='topCenter' overlay={<ImageSetupsContent />}>
                    <UpOutlined className='cvat-canvas-image-setups-trigger' />
                </Dropdown>

                <div className='cvat-canvas-z-axis-wrapper'>
                    <Slider
                        disabled={minZLayer === maxZLayer}
                        min={minZLayer}
                        max={maxZLayer}
                        value={curZLayer}
                        vertical
                        reverse
                        defaultValue={0}
                        onChange={(value: number): void => onSwitchZLayer(value as number)}
                    />
                    <CVATTooltip title={`Add new layer ${maxZLayer + 1} and switch to it`}>
                        <PlusCircleOutlined onClick={onAddZLayer} />
                    </CVATTooltip>
                </div>
                <div className='aatp_image_annotation'>
                    <AttributeAnnotationImage />
                </div>
                <SuspensionComponent activatedStateID={activatedStateID} states={annotations} />
                <ImageCameraManagerComponent />
                {minStandardElement}
                <ChangeMultAttributeComponent />
            </Layout.Content>
        );
    }
}
