/*
 * @Author: swxy
 * @Date: 2023-05-04 15:02:10
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
        //#region 2023-05-04 待校验
        'userNotice': `
        I. Allgemeines

        1. Bitte lesen und verstehen Sie sorgfältig alle Rechte und Einschränkungen, die in dieser "Nutzungsvereinbarung" (im Folgenden kurz "Vereinbarung" genannt) festgelegt sind.

        2. Sofern nicht anders angegeben, sind alle neuen Funktionen und Dienste, die von der idatalab-Plattform eingeführt werden, bedingungslos gemäß dieser Vereinbarung zu verwenden.

        3. Die idatalab-Plattform behält sich das Recht vor, die Bedingungen dieser Vereinbarung jederzeit ohne weitere Benachrichtigung zu ändern. Die geänderten Bedingungen treten sofort in Kraft, und der Benutzer muss sie bei der Nutzung der Dienste beachten und einhalten. Wenn der Benutzer mit den Änderungen dieser Vereinbarung nicht einverstanden ist, sollte er die Nutzung oder den Zugriff auf die Plattform aufgeben. Wenn der Benutzer nach der Änderung dieser Vereinbarung die Plattform weiterhin nutzt, gilt dies als Annahme aller hinzugefügten oder geänderten Inhalte.



        II. Benutzerregistrierung und Datenschutzbestimmungen

        1. Durch Klicken auf die Schaltfläche "Anmelden" im Registrierungsprozess erklärt der Benutzer, dass er alle Bedingungen dieser Vereinbarung vollständig akzeptiert hat.

        2. Der Benutzer muss sicherstellen, dass die von ihm hochgeladenen Daten rechtmäßig und konform sind. Für Probleme, die durch hochgeladene Daten verursacht werden, und für die Folgen, die aus der Entstehung solcher Probleme resultieren, trägt der Benutzer die alleinige Verantwortung, und die idatalab-Plattform übernimmt keine Haftung.

        3. Wenn Sie sich bei der Nutzung der idatalab-Plattform registrieren und diese nutzen, werden Ihre persönlichen Informationen und Daten erfasst, gespeichert und aufbewahrt. Die idatalab-Plattform verpflichtet sich, die Benutzerdaten nicht an Dritte weiterzugeben oder öffentlich zu machen.



        III. Leistungen

        1. idatalab stellt den Nutzern kostenlos Dienstleistungen zur Verfügung. Die vom Nutzer bei der Verwendung des Netzwerks zum Hochladen von Daten oder bei der Nutzung dieser Software anfallenden Verbindungsgebühren trägt der Nutzer selbst.

        2. Sofern in diesen Nutzungsbedingungen nicht anders angegeben, unterliegen alle neuen Produkte, Funktionen und Dienstleistungen, die von dieser Plattform eingeführt werden, diesen Nutzungsbedingungen.



        IV. Änderung, Unterbrechung oder Beendigung des Dienstes

        1. idatalab hat das Recht, das System jederzeit zu überprüfen oder Wartungsarbeiten durchzuführen, unabhängig davon, ob der Nutzer benachrichtigt wird.

        2. Aufgrund der Besonderheiten des Netzwerkdienstes (einschließlich, aber nicht beschränkt auf Stabilitätsprobleme des Servers, das Vorhandensein von böswilligen Netzwerkangriffen und Umstände, die idatalab nicht kontrollieren kann) hat idatalab das Recht, den Dienst teilweise oder vollständig jederzeit zu unterbrechen oder zu beenden, ohne dass eine separate Benachrichtigung erforderlich ist.



        V. Nutzungsregeln

        1. Der Nutzer muss die Bestimmungen dieser Plattform-Service-Hinweise einhalten.

        2. Der Nutzer trägt die volle rechtliche Verantwortung für alle Aktivitäten und Ereignisse, die über sein Konto stattfinden oder stattgefunden haben.

        3. Der Nutzer darf die Systemdienste dieser Plattform nicht für illegale Zwecke oder unangemessene Aktivitäten nutzen.

        4. Der Nutzer darf keinen Teil dieses Plattformdienstes oder das in diesem Plattformdienst verwendete oder erworbene Material kopieren, vervielfältigen, verkaufen, weiterverkaufen oder für andere kommerzielle Zwecke verwenden.

        5. idatalab hat das Recht, die von den Nutzern hochgeladenen Daten und deren automatisierte Ergebnisse ohne die Zustimmung der einzelnen Nutzer automatisch zu löschen.



        VI. Haftungsausschluss

        1. Angesichts der besonderen Art der Nutzung von Netzwerkdiensten durch diese Software wird keine Gewähr dafür übernommen, dass die Netzwerkdienste die Anforderungen des Benutzers erfüllen oder dass die Netzwerkdienste nicht unterbrochen werden. Es wird auch keine Gewähr für die Aktualität, Sicherheit und Richtigkeit der Netzwerkdienste übernommen.

        2. Für Verluste, die durch Telekommunikationssysteme oder Internet-Netzwerkfehler, Computerfehler, Computerprobleme oder andere Gründe höherer Gewalt verursacht werden, übernimmt das Plattform-System keine Verantwortung, aber es wird sein Bestes tun, um die Verluste und Auswirkungen auf den Benutzer zu minimieren.



        VII. Die endgültige Interpretationsbefugnis dieser Nutzungsbedingungen liegt bei idatalab-Plattform.
`
        //#endregion
}