/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2023-07-21 15:46:50
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useRef } from 'react';
import Layout from 'antd/lib/layout';
// import { Divider } from 'antd';

import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { ActiveControl, Rotation } from 'reducers/interfaces';
// import { Canvas3d as Canvas, Canvas3d } from 'canvas3d-wrapper';
import { Canvas3d } from 'canvas3d-wrapper';

// import RotateControl from 'components/annotation-page/standard-workspace/controls-side-bar/rotate-control';
import CursorControl, {
    Props as CursorControlProps,
} from 'components/annotation-page/standard-workspace/controls-side-bar/cursor-control';
import MoveControl, {
    Props as MoveControlProps,
} from 'components/annotation-page/standard-workspace/controls-side-bar/move-control';
// import FitControl from 'components/annotation-page/standard-workspace/controls-side-bar/fit-control';
// import ResizeControl from 'components/annotation-page/standard-workspace/controls-side-bar/resize-control';
import ControlVisibilityObserver from 'components/annotation-page/standard-workspace/controls-side-bar/control-visibility-observer';
import IssueControl from './issue-control';

const ObservedCursorControl = ControlVisibilityObserver<CursorControlProps>(CursorControl);
const ObservedMoveControl = ControlVisibilityObserver<MoveControlProps>(MoveControl);
interface Props {
    canvasInstance: Canvas3d;
    activeControl: ActiveControl;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;

    rotateFrame(rotation: Rotation): void;
    selectIssuePosition(enabled: boolean): void;
}

export default function ControlsSideBarComponent(props: Props): JSX.Element {
    const {
        canvasInstance,
        activeControl,
        normalizedKeyMap,
        keyMap,
        // rotateFrame,
        selectIssuePosition,
    } = props;
    const changeCameraViewEvent = useRef<any>();

    const preventDefault = (event: KeyboardEvent | undefined): void => {
        if (event) {
            event.preventDefault();
        }
    };

    const subKeyMap = {
        CANCEL: keyMap.CANCEL,
        OPEN_REVIEW_ISSUE: keyMap.OPEN_REVIEW_ISSUE,
    };

    const handlers = {
        CANCEL: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (activeControl !== ActiveControl.CURSOR) {
                canvasInstance.cancel();
            }
        },
        OPEN_REVIEW_ISSUE: (event: KeyboardEvent | undefined) => {
            preventDefault(event);

            canvasInstance.cancel();
            if (activeControl === ActiveControl.OPEN_ISSUE) {
                selectIssuePosition(false);
            } else {
                canvasInstance.draw({
                    enabled: true,
                    type: 'issue',
                });
                // canvasInstance.reviewCanvas(true);
                selectIssuePosition(true);
            }
        },
        ENTER_MOVE: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            changeCameraViewEvent.current?.();
        },
    };

    return (
        // <Layout.Sider className='cvat-canvas-controls-sidebar' theme='light' width={44}>
        <Layout.Sider className='cvat-canvas-controls-sidebar' theme='light' width={90}>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <ObservedCursorControl
                cursorShortkey={normalizedKeyMap.CANCEL}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
            />
            <ObservedMoveControl
                cursorShortkey={normalizedKeyMap.ENTER_MOVE}
                canvasInstance={canvasInstance}
                activeControl={activeControl}
                onClick={(callback: () => void) => {
                    changeCameraViewEvent.current = callback;
                }}
            />
            <IssueControl
                canvasInstance={canvasInstance}
                activeControl={activeControl}
                selectIssuePosition={selectIssuePosition}
                shortcut={normalizedKeyMap.OPEN_REVIEW_ISSUE}
            />
        </Layout.Sider>
    );
}
