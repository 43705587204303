/*
 * @Author: swxy
 * @Date: 2022-12-13 10:57:34
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
const language = 'Language';

export const getLanguage = (): string | null => localStorage.getItem(language);

import { Euler, Line3, Matrix3, Matrix4, Quaternion, Vector2, Vector3 } from 'three';
// import { getRelativelyPoints } from './cuboid';
import { CameraType, CameraDistortionParameter, ImagePosition, ObjectState, ShapeType } from './interface';

// export const points: [
//     THREE.Vector3,
//     THREE.Vector3,
//     THREE.Vector3,
//     THREE.Vector3,
//     THREE.Vector3,
//     THREE.Vector3,
//     THREE.Vector3,
//     THREE.Vector3,
// ] = [
//     new Vector3(0.5, -0.5, 0.5), // 0
//     new Vector3(0.5, -0.5, -0.5), // 1
//     new Vector3(0.5, 0.5, 0.5), // 2
//     new Vector3(0.5, 0.5, -0.5), // 3

//     new Vector3(-0.5, 0.5, 0.5),
//     new Vector3(-0.5, 0.5, -0.5),
//     new Vector3(-0.5, -0.5, 0.5),
//     new Vector3(-0.5, -0.5, -0.5),
// ];

export const getRelativelyPoints = () => {
    return [
        new Vector3(0.5, 0.5, 0.5), // 2
        new Vector3(0.5, 0.5, -0.5), // 3
        new Vector3(0.5, -0.5, 0.5), // 0
        new Vector3(0.5, -0.5, -0.5), // 1

        new Vector3(-0.5, -0.5, 0.5),
        new Vector3(-0.5, -0.5, -0.5),
        new Vector3(-0.5, 0.5, 0.5),
        new Vector3(-0.5, 0.5, -0.5),
    ];
};

type Intrinsics = [number, number, number, number, number, number, number, number, number] | number[];
type Extrinsics =
    | [
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          number,
          number,
      ]
    | number[];

type Points = [
    [number, number],
    [number, number],
    [number, number],
    [number, number],

    [number, number],
    [number, number],
    [number, number],
    [number, number],

    // 最大值和最小值
    [number, number],
    [number, number],
];

const changeDistortion = (position: Vector3, distortion: CameraDistortionParameter): Vector3 => {
    // 投影到图片上的像素点位置（未偏移前）
    const a = position.x / position.z;
    const b = position.y / position.z;

    const { k1, k2, k3, k4, cameraType } = distortion;
    const r = Math.sqrt(a ** 2 + b ** 2);

    // 鱼眼相机的畸变计算
    if (cameraType === CameraType.fisheye) {
        // 投影时的入射角度
        const angle = Math.atan(r);
        const angle2 = angle * (1 + k1 * angle ** 2 + k2 * angle ** 4 + k3 * angle ** 6 + k4 * angle ** 8);
        // 反转回rd
        // const rd = Math.tan(angle2);
        const rd = angle2;
        position = new Vector3((rd / r) * a, (rd / r) * b, 1);
    } else if (cameraType === CameraType.buttonhole) {
        const { p1, p2, k5, k6, s1, s2, s3, s4 } = distortion;
        const k123 = 1 + k1 * r ** 2 + k2 * r ** 4 + k3 * r ** 6;
        const k456 = 1 + k4 * r ** 2 + k5 * r ** 4 + k6 * r ** 6;

        const k = k123 / k456;

        const x = a * k + 2 * p1 * a * b + p2 * (r ** 2 + 2 * a ** 2) + s1 * r ** 2 + s2 * r ** 4;
        const y = b * k + p1 * (r ** 2 + 2 * b ** 2) + 2 * p2 * a * b + s3 * r ** 2 + s4 * r ** 4;
        position = new Vector3(x, y, 1);
    }
    return position;
};

/**
 *
 * @param matrixWorld 物体的世界坐标系转换矩阵
 * @param intrinsics 内参
 * @param extrinsics 外参
 * @param distortion 畸变
 */
export const transLidarPositionToImagesPoints = (
    matrixWorld: Matrix4,
    intrinsics: Matrix3,
    extrinsics: Matrix4,
    distortion: CameraDistortionParameter,
): Points => {
    try {
        // 世界坐标系中8点位置，重要的是顺序
        const initPositions = getRelativelyPoints();
        // 世界坐标系中的8个点的位置
        const positions = initPositions.map((vector: Vector3) => {
            return vector.clone().applyMatrix4(matrixWorld);
        });

        let minX: number = Number.MAX_SAFE_INTEGER,
            minY: number = Number.MAX_SAFE_INTEGER,
            maxX: number = Number.MIN_SAFE_INTEGER,
            maxY: number = Number.MIN_SAFE_INTEGER;
        const imagePoints = positions.map((pos): [number, number] => {
            const newPos = pos.clone();
            // 转化为相机坐标系
            newPos.applyMatrix4(extrinsics);

            if (newPos.z < 0) {
                throw Error('over');
            }

            changeDistortion(newPos, distortion);
            newPos.applyMatrix3(intrinsics);

            const x = newPos.x / newPos.z;
            const y = newPos.y / newPos.z;

            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
            return [x, y];
        });
        return [...imagePoints, [minX, minY], [maxX, maxY]] as Points;
    } catch (error) {
        // 一旦出错，返回8个0点
        return [
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
            [0, 0],
        ];
    }
};

/**
 *
 * @param matrixWorld 物体的世界坐标系转换矩阵
 * @param intrinsics 内参
 * @param extrinsics 外参
 * @param distortion 畸变
 */
export const transLidarToImagesPositions = (
    matrixWorld: Matrix4,
    intrinsics: Matrix3,
    extrinsics: Matrix4,
    distortion: CameraDistortionParameter,
): Vector2[] => {
    try {
        // 世界坐标系中8点位置，重要的是顺序
        const initPositions = getRelativelyPoints();
        // 世界坐标系中的8个点的位置
        const positions = initPositions.map((vector: Vector3) => {
            return vector.clone().applyMatrix4(matrixWorld);
        });

        const imagePoints = positions.map((pos): Vector2 => {
            const newPos = pos.clone();
            // 转化为相机坐标系
            newPos.applyMatrix4(extrinsics);

            if (newPos.z < 0) {
                throw Error('over');
            }

            changeDistortion(newPos, distortion);
            newPos.applyMatrix3(intrinsics);

            const x = newPos.x / newPos.z;
            const y = newPos.y / newPos.z;

            return new Vector2(x, y);
        });
        return [...imagePoints];
    } catch (error) {
        // 一旦出错，返回8个0点
        return null;
    }
};

export const transPostureToMatrixWorld = (points: number[]): Matrix4 => {
    const scale = new Vector3(points[6], points[7], points[8]);
    const objPos = new Vector3(points[0], points[1], points[2]);
    const objectRotation = new Euler(points[3], points[4], points[5]);
    const objQuaternion = new Quaternion().setFromEuler(objectRotation);

    const worldMatrix = new Matrix4();
    // 正常情况
    worldMatrix.compose(objPos, objQuaternion, scale);
    return worldMatrix;
};

export const transPositionsToPosition2D = (
    positions: Vector3[],
    intrinsics: Matrix3,
    extrinsics: Matrix4,
    distortion: CameraDistortionParameter,
) => {
    try {
        const imagePoints = positions
            .map((pos): Vector2 => {
                const newPos = pos.clone();
                // 转化为相机坐标系
                newPos.applyMatrix4(extrinsics);

                if (newPos.z < 0) {
                    return null;
                    throw Error('over');
                }

                changeDistortion(newPos, distortion);
                newPos.applyMatrix3(intrinsics);

                const x = newPos.x / newPos.z;
                const y = newPos.y / newPos.z;

                return new Vector2(x, y);
            })
            .filter((item) => item);
        return [...imagePoints];
    } catch (error) {
        return null;
    }
};

export const transPostureToPosition = (
    points: number[],
    intrinsics: Matrix3,
    extrinsics: Matrix4,
    distortion: CameraDistortionParameter,
    shapeType: ShapeType,
    resultType: '4' | '8' = '8',
): Vector2[] => {
    if (shapeType === ShapeType.CUBOID) {
        const worldMatrix = transPostureToMatrixWorld(points);
        const positions = transLidarToImagesPositions(worldMatrix, intrinsics, extrinsics, distortion);
        if (resultType === '4' && positions?.length) {
            let min = new Vector2(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
            let max = new Vector2(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);

            positions.forEach((pos) => {
                min.set(Math.min(pos.x, min.x), Math.min(pos.y, min.y));
                max.set(Math.max(pos.x, max.x), Math.max(pos.y, max.y));
            });
            return [min, max];
        }
        return positions;
    }
    if (shapeType === ShapeType.laneline) {
        const vector3s = [];
        for (let index = 0; index < points.length; index += 3) {
            const vector = new Vector3().fromArray(points, index);
            vector3s.push(vector);
        }
        const positions = transPositionsToPosition2D(vector3s, intrinsics, extrinsics, distortion);
        return positions;
    }
};

export const copyObject = (object: ObjectState): ObjectState => {
    return {
        clientID: object.clientID,
        frame: object.frame,
        points: object.points,
        attributes: {},

        lock: object.lock,
        color: object.color,
        hidden: object.hidden,
        pinned: object.pinned,
        serverID: object.serverID,

        keyframes: object.keyframes,
        group: object.group,
        updated: object.updated,
        objectType: object.objectType,
        label: object.label,
        shapeType: object.shapeType,
        zOrder: object.zOrder,
        elements: [],
    };
};

/**
 * 将数字数组转换为THREE的Vector3的数组
 * @param points
 * @returns Vector3[]
 */
export const pointToVector3 = (points: number[]): Vector3[] => {
    return points.reduce((previous: Vector3[], current, index) => {
        if (index % 3 === 0) {
            previous.push(new Vector3(current, 0, 0));
        } else {
            const sub = Math.floor(index / 3);
            if (index % 3 === 1) {
                previous[sub].setY(current);
            } else {
                previous[sub].setZ(current);
            }
        }
        return previous;
    }, []);
};

export const pointInLine = (vec: Vector3, lines: Line3[]): Vector3 => {
    let distance = 0;
    let nearIndex = -1;
    let near = vec.clone();

    for (let index = 0; index < lines.length; index++) {
        const line = lines[index];
        const target = line.closestPointToPoint(vec, true, new Vector3());
        const dur: number = vec.distanceTo(target);

        // 允许一定的误差范围内，则直接认为就在线上。以便移动到其他线段
        if (dur < 0.0005) {
            near = target;
            break;
        }

        // console.log('线段：', line.clone());
        // console.log('线段上的点：', target.clone());
        // console.log('当前点：', vec.clone());
        // console.log('测试这里：', dur);
        // console.log('第几根线段：', index + 1);
        if (index === 0) {
            distance = dur;
        }

        near = distance >= dur ? target : near;
    }
    // lines.forEach((line, index) => {
    //     const target = line.closestPointToPoint(vec, true, new Vector3());
    //     const dur: number = vec.distanceTo(target);
    //     if (index === 0) {
    //         // 第一条线段
    //         distance = dur;
    //         near = target;
    //         // console.log('当前距离：', distance);
    //         // console.log('间隔：', dur);
    //         // console.log('线段：', index);
    //     }
    //     if (distance > dur) {
    //         near = target;
    //         distance = dur;
    //     }
    // });

    return vec.set(near.x, near.y, near.z);
};

export const pointsInNearLines = (points: Vector3[] = [], linePoints: Vector3[] = [], isNewObject: boolean = false) => {
    let inLinePoints = [...points];
    try {
        if (linePoints.length < 2) {
            return points;
        }

        // 获取到线段长度
        let old: Vector3 | undefined = undefined;
        const lines = linePoints.reduce((previous: Line3[], current: Vector3) => {
            if (old) {
                previous.push(new Line3(old, current));
            }
            old = current;
            return previous;
        }, []);

        // console.log(
        //     '输入：',
        //     inLinePoints.map((pos) => pos.clone()),
        // );
        inLinePoints = inLinePoints.map((vec) => {
            return pointInLine(isNewObject ? vec.clone() : vec, lines);
        });
        // console.log('结果：', inLinePoints);
    } catch (error) {
        console.error('转化错误：', error);
        throw error;
    }

    return inLinePoints;
};
