// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, {
    useEffect,
    useState,
    // useState
} from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'antd/lib/grid';
import {
    // LoadingOutlined, QuestionCircleOutlined,
    CopyOutlined,
} from '@ant-design/icons';
// import { ColumnFilterItem } from 'antd/lib/table/interface';
import Table from 'antd/lib/table';
import Button from 'antd/lib/button';
// import Select from 'antd/lib/select';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import copy from 'copy-to-clipboard';

import {
    CombinedState,
    // JobStage
} from 'reducers/interfaces';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { clearJobAnnotation, getJobsAsync } from 'actions/jobs-actions';
// import UserSelector, { User } from './user-selector';
import { useTranslation } from 'react-i18next';
import { Popconfirm } from 'antd';
import { getValueName, isAdmin } from 'utils/constant';
import Job from 'business/objects/job';
import { JobStatus } from 'utils/ConstType';

interface Props {
    taskInstance: any;
    onJobUpdate(jobInstance: any): void;
}

// function ReviewSummaryComponent({ jobInstance }: { jobInstance: any }): JSX.Element {
//     const [summary, setSummary] = useState<Record<string, any> | null>(null);
//     const [error, setError] = useState<any>(null);
//     useEffect(() => {
//         setError(null);
//         jobInstance
//             .issues(jobInstance.id)
//             .then((issues: any[]) => {
//                 setSummary({
//                     issues_unsolved: issues.filter((issue) => !issue.resolved_date).length,
//                     issues_resolved: issues.filter((issue) => issue.resolved_date).length,
//                 });
//             })
//             .catch((_error: any) => {
//                 // eslint-disable-next-line
//                 console.log(_error);
//                 setError(_error);
//             });
//     }, []);

//     if (!summary) {
//         if (error) {
//             if (error.toString().includes('403')) {
//                 return <p>You do not have permissions</p>;
//             }

//             return <p>Could not fetch, check console output</p>;
//         }

//         return (
//             <>
//                 <p>Loading.. </p>
//                 <LoadingOutlined />
//             </>
//         );
//     }

//     return (
//         <table className='cvat-review-summary-description'>
//             <tbody>
//                 <tr>
//                     <td>
//                         <Text strong>Unsolved issues</Text>
//                     </td>
//                     <td>{summary.issues_unsolved}</td>
//                 </tr>
//                 <tr>
//                     <td>
//                         <Text strong>Resolved issues</Text>
//                     </td>
//                     <td>{summary.issues_resolved}</td>
//                 </tr>
//             </tbody>
//         </table>
//     );
// }

function JobListComponent(props: Props & RouteComponentProps): JSX.Element {
    const {
        taskInstance,
        // onJobUpdate,
        history: { push },
    } = props;

    const { t } = useTranslation();
    // const [total, setTotal] = useState(0);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const dispatch = useDispatch();

    const jobs: any = useSelector((state: CombinedState) => state.jobs.current);
    // const user: any = useSelector((state: CombinedState) => state.auth.user);
    const total: any = useSelector((state: CombinedState) => state.jobs.count);

    const {
        // jobs,
        id: taskId,
    } = taskInstance;

    const getJobs = async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }): Promise<void> => {
        await dispatch<any>(
            getJobsAsync({
                taskId,
                page: pageIndex,
                pageIndex,
                pageSize,
            }),
        );
        // if (pageIndex === 0 || !pageIndex) {
        //     setTotal(result.count);
        // }
    };

    useEffect(() => {
        // dispatch(getJobsAsync({ taskId: taskInstance.id, page: 1 }));
        getJobs({ pageIndex: 0, pageSize: 10 });
    }, [taskInstance.id]);

    // function sorter(path: string) {
    //     return (obj1: any, obj2: any): number => {
    //         let currentObj1 = obj1;
    //         let currentObj2 = obj2;
    //         let field1: string | null = null;
    //         let field2: string | null = null;
    //         for (const pathSegment of path.split('.')) {
    //             field1 = currentObj1 && pathSegment in currentObj1 ? currentObj1[pathSegment] : null;
    //             field2 = currentObj2 && pathSegment in currentObj2 ? currentObj2[pathSegment] : null;
    //             currentObj1 = currentObj1 && pathSegment in currentObj1 ? currentObj1[pathSegment] : null;
    //             currentObj2 = currentObj2 && pathSegment in currentObj2 ? currentObj2[pathSegment] : null;
    //         }

    //         if (field1 && field2) {
    //             return field1.localeCompare(field2);
    //         }

    //         if (field1 === null) {
    //             return 1;
    //         }

    //         return -1;
    //     };
    // }

    // function collectUsers(path: string): ColumnFilterItem[] {
    //     return Array.from<string | null>(
    //         new Set(
    //             jobs.map((job: any) => {
    //                 if (job[path] === null) {
    //                     return null;
    //                 }

    //                 return job[path].username;
    //             }),
    //         ),
    //     ).map((value: string | null) => ({ text: value || 'Is Empty', value: value || false }));
    // }

    const clearAnnotation = async (jobId: number) => {
        setDeleteLoading(true);
        await dispatch(clearJobAnnotation(jobId)) as any;
        setDeleteLoading(false);
    }

    const columns = [
        {
            // title: 'Job',
            title: t('project.job'),
            dataIndex: 'name',
            key: 'name',
            render: (id: string, record: any): JSX.Element => (
                <div>
                    <Button
                        type='link'
                        onClick={(e: React.MouseEvent): void => {
                            e.preventDefault();
                            push(`/tasks/${taskId}/jobs/${record.key}`);
                        }}
                        href={`/tasks/${taskId}/jobs/${record.key}`}
                    >
                        {t('project.job')}
                        {` #${id}`}
                    </Button>
                </div>
            ),
        },
        {
            // title: 'Frames',
            // title: '帧数',
            title: t('project.frames'),
            dataIndex: 'frames',
            key: 'frames',
            className: 'cvat-text-color cvat-job-item-frames',
        },
        {
            // title: 'Stage',
            // title: '题包状态',
            title: t('project.job_status'),
            dataIndex: 'jobStatus',
            key: 'jobStatus',
            className: 'cvat-job-item-stage',
            render: (status: number) => getValueName('jobStatus', status),
            // render: (jobInstance: any): JSX.Element => {
            //     const { stage } = jobInstance;

            //     return (
            //         <div>
            //             <Select
            //                 value={stage}
            //                 onChange={(newValue: string) => {
            //                     jobInstance.stage = newValue;
            //                     onJobUpdate(jobInstance);
            //                 }}
            //             >
            //                 <Select.Option value={JobStage.ANNOTATION}>{JobStage.ANNOTATION}</Select.Option>
            //                 <Select.Option value={JobStage.REVIEW}>{JobStage.REVIEW}</Select.Option>
            //                 <Select.Option value={JobStage.ACCEPTANCE}>{JobStage.ACCEPTANCE}</Select.Option>
            //             </Select>
            //             <CVATTooltip title={<ReviewSummaryComponent jobInstance={jobInstance} />}>
            //                 <QuestionCircleOutlined />
            //             </CVATTooltip>
            //         </div>
            //     );
            // },
            // sorter: sorter('stage.stage'),
            // filters: [
            //     { text: 'annotation', value: 'annotation' },
            //     { text: 'validation', value: 'validation' },
            //     { text: 'acceptance', value: 'acceptance' },
            // ],
            // onFilter: (value: string | number | boolean, record: any) => record.stage.stage === value,
        },
        // {
        //     // title: 'State',
        //     title: '状态',
        //     dataIndex: 'status',
        //     key: 'status',
        //     className: 'cvat-job-item-state',
        //     // render: (jobInstance: any): JSX.Element => {
        //     //     const { state } = jobInstance;
        //     //     return (
        //     //         <Text type='secondary'>
        //     //             {state}
        //     //         </Text>
        //     //     );
        //     // },
        //     // sorter: sorter('state.state'),
        //     // filters: [
        //     //     { text: 'new', value: 'new' },
        //     //     { text: 'in progress', value: 'in progress' },
        //     //     { text: 'completed', value: 'completed' },
        //     //     { text: 'rejected', value: 'rejected' },
        //     // ],
        //     // onFilter: (value: string | number | boolean, record: any) => record.state.state === value,
        // },
        {
            // title: 'Started on',
            // title: '开始时间',
            title: t('project.start_date'),
            dataIndex: 'started',
            key: 'started',
            className: 'cvat-text-color',
        },
        {
            // title: 'Duration',
            // title: '期间',
            title: t('project.duration'),
            dataIndex: 'duration',
            key: 'duration',
            className: 'cvat-text-color',
        },
        // {
        //     // title: 'Assignee',
        //     title: '分配给',
        //     dataIndex: 'realName',
        //     key: 'realName',
        //     className: 'cvat-job-item-assignee',
        //     // render: (jobInstance: any): JSX.Element => (
        //     //     <UserSelector
        //     //         className='cvat-job-assignee-selector'
        //     //         value={jobInstance.assignee}
        //     //         onSelect={(value: User | null): void => {
        //     //             jobInstance.assignee = value;
        //     //             onJobUpdate(jobInstance);
        //     //         }}
        //     //     />
        //     // ),
        //     // sorter: sorter('assignee.assignee.username'),
        //     // filters: collectUsers('assignee'),
        //     // onFilter: (value: string | number | boolean, record: any) => (
        //     //     record.assignee.assignee?.username || false
        //     // ) === value,
        // },
        // {
        //     // title: 'Duration',
        //     // title: '期间',
        // },
    ];

    // if (isAdmin(user.roleType)) {
    //     columns.push({
    //         title: t('base.delete'),
    //         dataIndex: 'delete',
    //         key: 'delete',
    //         // className: 'cvat-text-color',
    //         render: (id: string, record: any): JSX.Element => (
    //             <Popconfirm
    //                 title='删除该题包中的所有标注（不可撤销）'
    //                 disabled={deleteLoading}
    //                 onConfirm={() => {
    //                     console.log('删除该题包标注：', record);
    //                     clearAnnotation(record.key);
    //                 }}
    //             >
    //                 <Button type='primary' danger loading={deleteLoading}>清空标注</Button>
    //             </Popconfirm >
    //         ),
    //     })
    // }

    let completed = 0;
    const data = jobs.reduce((acc: any[], job: Job) => {
        if (job.jobStatus > JobStatus.pass) {
            completed++;
        }

        const created = moment(job.createdDate);

        const now = moment(moment.now());
        acc.push({
            key: job.id,
            name: job.name,
            frames: `${job.startFrame || 0}-${job.stopFrame || 0}`,
            jobStatus: job.jobStatus,
            status: job.status,
            started: `${created.format('YYYY-MM-DD  HH:MM')}`,
            duration: `${moment.duration(now.diff(created)).humanize()}`,
            // realName: job.realName,
        });

        return acc;
    }, []);

    const onPageChange = (pageIndex: number, pageSize: number): void => {
        dispatch(
            getJobsAsync({
                taskId: taskInstance.id,
                // page: pageIndex - 1,
                pageIndex: pageIndex - 1,
                pageSize,
            }),
        );
    };

    return (
        <div className='cvat-task-job-list'>
            <Row justify='space-between' align='middle'>
                <Col>
                    <Text className='cvat-text-color cvat-jobs-header'> {t('project.jobs')} </Text>
                    {/* <CVATTooltip trigger='click' title='Copied to clipboard!'> */}
                    <CVATTooltip trigger='click' title={t('project.copy_success')}>
                        <Button
                            type='link'
                            onClick={(): void => {
                                let serialized = '';
                                const [latestJob] = [...taskInstance.jobs].reverse();
                                for (const job of taskInstance.jobs) {
                                    const baseURL = window.location.origin;
                                    serialized += `Job #${job.id}`.padEnd(`${latestJob.id}`.length + 6, ' ');
                                    serialized += `: ${baseURL}/tasks/${taskInstance.id}/jobs/${job.id}`.padEnd(
                                        `${latestJob.id}`.length + baseURL.length + 8,
                                        ' ',
                                    );
                                    serialized += `: [${job.startFrame}-${job.stopFrame}]`.padEnd(
                                        `${latestJob.startFrame}${latestJob.stopFrame}`.length + 5,
                                        ' ',
                                    );

                                    // if (job.assignee) {
                                    //     serialized += `\t assigned to "${job.assignee.username}"`;
                                    // }

                                    serialized += '\n';
                                }
                                copy(serialized);
                            }}
                        >
                            <CopyOutlined />
                            {/* Copy */}
                            {t('project.copy')}
                        </Button>
                    </CVATTooltip>
                </Col>
                <Col>
                    <Text className='cvat-text-color'>{`${completed} - ${total}`}</Text>
                </Col>
            </Row>
            <Table
                className='cvat-task-jobs-table'
                rowClassName={() => 'cvat-task-jobs-table-row'}
                columns={columns}
                dataSource={data}
                pagination={{
                    total,
                    hideOnSinglePage: true,
                    showQuickJumper: true,
                    onChange: onPageChange,
                }}
                size='small'
            />
        </div>
    );
}

export default withRouter(JobListComponent);
