/*
 * @Author: swxy
 * @Date: 2022-10-19 18:06:21
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
     //#region 2023-05-04 待校验
     'error.baseError': 'Es gab einige Fehler mit dem Service, Sie können es später erneut versuchen!',
     //#endregion

     'annotation.save.title': 'Fehler beim Speichern des Titelpakets',
     'annotation.save.detail': 'Sie können die letzten Vorgänge aufzeichnen und dem Administrator melden. \r\nNachdem Sie auf [OK] geklickt haben, wird die Seite erneut aktualisiert. ',

     'error.register.username_length': 'Benutzername muss länger als 5 Zeichen sein',
     'error.register.username_char': 'Benutzername darf nur verwenden: Zahlen, englische Groß- und Kleinbuchstaben, "-", "_"',
     'error.register.username_invalid': 'Ungültiger Name',

     'error.register.error': 'Registrierung fehlgeschlagen, bitte versuchen Sie es später erneut! ',

     'error.register.password_length': 'Passwort muss länger als 8 Zeichen sein',
     'error.register.password_number': 'Passwort muss mindestens eine Zahl enthalten',
     'error.register.password_uppercaseChar': 'Passwort muss mindestens einen Großbuchstaben enthalten',
     'error.register.password_lowercaseChar': 'Passwort muss mindestens einen Kleinbuchstaben enthalten',

     'error.task.uploadImage': 'Die Bildinformationen sind anormal oder die Bildbreite und -höhe sind nicht konsistent! ',
     'error.job.taskInfo': 'Fehler beim Abrufen der Informationen! ',
};
