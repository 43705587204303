// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';

import CVATTooltip from 'components/common/cvat-tooltip';

import {
    FastBackwardOutlined,
    FastForwardOutlined,
    ForwardOutlined,
    BackwardOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
    CaretLeftOutlined,
    CaretRightOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import {
    changeFrameAsync,
    switchPlay as switchPlayAction,
} from 'actions/annotation-actions';
import isAbleToChangeFrame from 'utils/is-able-to-change-frame';
import GlobalHotKeys from 'utils/mousetrap-react';

const preventDefault = (event: KeyboardEvent | undefined): void => {
    if (event) {
        event.preventDefault();
    }
};

interface Props {
}

function PlayerButtons(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const {
        canvasIsReady,

        playing,
        playPauseShortcut,
        nextFrameShortcut,
        previousFrameShortcut,
        forwardShortcut,
        backwardShortcut,

        frameNumber,
        jobInstance,
        frameStep,

        keyMap,
    } = useSelector((state: CombinedState) => ({
        canvasIsReady: state.annotation.canvas.ready,

        playing: state.annotation.player.playing,
        playPauseShortcut: state.shortcuts.normalizedKeyMap.PLAY_PAUSE,
        nextFrameShortcut: state.shortcuts.normalizedKeyMap.NEXT_FRAME,
        previousFrameShortcut: state.shortcuts.normalizedKeyMap.PREV_FRAME,
        forwardShortcut: state.shortcuts.normalizedKeyMap.FORWARD_FRAME,
        backwardShortcut: state.shortcuts.normalizedKeyMap.BACKWARD_FRAME,

        frameNumber: state.annotation.player.frame.number,
        jobInstance: state.annotation.job.instance,
        frameStep: state.settings.player.frameStep,

        keyMap: state.shortcuts.keyMap,
    }))

    const changeFrame = (frame: number): void => {
        if (isAbleToChangeFrame()) {
            dispatch(changeFrameAsync(frame));
        }
    }

    const switchPlay = (playing: boolean) => {
        dispatch(switchPlayAction(playing));
    }

    const onSwitchPlay = () => {
        if (playing) {
            switchPlay(false);
        } else if (frameNumber < jobInstance.stopFrame) {
            switchPlay(true);
        }
    }

    const onPrevFrame = () => {
        const newFrame = Math.max(jobInstance.startFrame, frameNumber - 1);
        if (newFrame !== frameNumber) {
            if (playing) {
                switchPlay(false);
            }

            changeFrame(newFrame);
        }
    }

    const onNextFrame = () => {
        const newFrame = Math.min(jobInstance.stopFrame, frameNumber + 1);
        if (newFrame !== frameNumber) {
            if (playing) {
                switchPlay(false);
            }

            changeFrame(newFrame);
        }
    }

    const onForward = () => {
        const newFrame = Math.min(jobInstance.stopFrame, frameNumber + frameStep);
        if (newFrame !== frameNumber) {
            if (playing) {
                switchPlay(false);
            }
            changeFrame(newFrame);
        }
    }

    const onBackward = () => {
        const newFrame = Math.max(jobInstance.startFrame, frameNumber - frameStep);
        if (newFrame !== frameNumber) {
            if (playing) {
                switchPlay(false);
            }
            changeFrame(newFrame);
        }
    }

    const onFirstFrame = () => {
        const newFrame = jobInstance.startFrame;
        if (newFrame !== frameNumber) {
            if (playing) {
                switchPlay(false);
            }
            changeFrame(newFrame);
        }
    }

    const onLastFrame = () => {
        const newFrame = jobInstance.stopFrame;
        if (newFrame !== frameNumber) {
            if (playing) {
                switchPlay(false);
            }
            changeFrame(newFrame);
        }
    }

    const { t } = useTranslation();

    const prevRegularText = t('workspace.top.go_back');
    const nextRegularText = t('workspace.top.go_next');

    let prevButton = <CaretLeftOutlined className='cvat-player-previous-button' onClick={onPrevFrame} />;
    let prevButtonTooltipMessage = prevRegularText;
    let nextButton = <CaretRightOutlined className='cvat-player-next-button' onClick={onNextFrame} />;
    let nextButtonTooltipMessage = nextRegularText;

    const subKeyMap = {
        SAVE_JOB: keyMap.SAVE_JOB,
        UNDO: keyMap.UNDO,
        REDO: keyMap.REDO,
        NEXT_FRAME: keyMap.NEXT_FRAME,
        PREV_FRAME: keyMap.PREV_FRAME,
        FORWARD_FRAME: keyMap.FORWARD_FRAME,
        BACKWARD_FRAME: keyMap.BACKWARD_FRAME,
        PLAY_PAUSE: keyMap.PLAY_PAUSE,
    };

    const handlers = {
        NEXT_FRAME: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (canvasIsReady) {
                onNextFrame();
            }
        },
        PREV_FRAME: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (canvasIsReady) {
                onPrevFrame();
            }
        },
        FORWARD_FRAME: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (canvasIsReady) {
                onForward();
            }
        },
        BACKWARD_FRAME: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            if (canvasIsReady) {
                onBackward();
            }
        },
        PLAY_PAUSE: (event: KeyboardEvent | undefined) => {
            preventDefault(event);
            onSwitchPlay();
        },
    };

    return (
        <Col className='cvat-player-buttons'>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <CVATTooltip title={t('workspace.top.frist_frame')}>
                <FastBackwardOutlined className='cvat-player-first-button' onClick={onFirstFrame} />
            </CVATTooltip>
            <CVATTooltip title={`${t('workspace.top.prve_step')} ${backwardShortcut}`}>
                <BackwardOutlined className='cvat-player-backward-button' onClick={onBackward} />
            </CVATTooltip>
            <CVATTooltip placement='top' title={`${prevButtonTooltipMessage} ${previousFrameShortcut}`}>
                {prevButton}
            </CVATTooltip>

            {!playing ? (
                <CVATTooltip title={`${t('workspace.top.play')} ${playPauseShortcut}`}>
                    <PlayCircleOutlined className='cvat-player-play-button' onClick={onSwitchPlay} />
                </CVATTooltip>
            ) : (
                <CVATTooltip title={`${t('workspace.top.pause')} ${playPauseShortcut}`}>
                    <PauseCircleOutlined className='cvat-player-pause-button' onClick={onSwitchPlay} />
                </CVATTooltip>
            )}
            <CVATTooltip placement='top' title={`${nextButtonTooltipMessage} ${nextFrameShortcut}`}>
                {nextButton}
            </CVATTooltip>
            <CVATTooltip title={`${t('workspace.top.next_step')} ${forwardShortcut}`}>
                <ForwardOutlined className='cvat-player-forward-button' onClick={onForward} />
            </CVATTooltip>
            <CVATTooltip title={t('workspace.top.last_frame')}>
                <FastForwardOutlined className='cvat-player-last-button' onClick={onLastFrame} />
            </CVATTooltip>
        </Col>
    );
}

export default React.memo(PlayerButtons);
