/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2022-12-24 17:18:05
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Icon from '@ant-design/icons';

import { ActiveControl } from 'reducers/interfaces';
import { Canvas3d } from 'canvas3d-wrapper';
import { RectangleIcon } from 'icons';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useTranslation } from 'react-i18next';
// import { message } from 'antd';

interface Props {
    shortcut: string;
    canvasInstance: Canvas3d;
    activeControl: ActiveControl;
    selectIssuePosition(enabled: boolean): void;
}

function CreateIssueControl(props: Props): JSX.Element {
    const { activeControl, canvasInstance, selectIssuePosition, shortcut } = props;

    const { t } = useTranslation();

    return (
        // <CVATTooltip title='Open an issue' placement='right'>
        <CVATTooltip title={`t('workspace.side.controls.create_issue') ${shortcut}`} placement='right'>
            <Icon
                component={RectangleIcon}
                className={
                    activeControl === ActiveControl.OPEN_ISSUE
                        ? 'cvat-issue-control cvat-active-canvas-control'
                        : 'cvat-issue-control'
                }
                onClick={(): void => {
                    // message.info('目前暂不支持批注，敬请期待！');
                    // return;
                    // if (activeControl === ActiveControl.OPEN_ISSUE) {
                    //     canvasInstance.selectRegion(false);
                    //     selectIssuePosition(false);
                    // } else {
                    //     canvasInstance.cancel();
                    //     canvasInstance.selectRegion(true);
                    //     selectIssuePosition(true);
                    // }
                    canvasInstance.cancel();
                    if (activeControl === ActiveControl.OPEN_ISSUE) {
                        // canvasInstance.reviewCanvas(false);
                        selectIssuePosition(false);
                    } else {
                        canvasInstance.draw({
                            enabled: true,
                            type: 'issue',
                        });
                        // canvasInstance.reviewCanvas(true);
                        selectIssuePosition(true);
                    }
                }}
            />
        </CVATTooltip>
    );
}

export default React.memo(CreateIssueControl);
