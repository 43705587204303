/*
 * @Author: swxy
 * @Date: 2022-12-09 14:56:20
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CombinedState, ObjectType, ShapeType } from 'reducers/interfaces';
import { Canvas3d } from 'canvas3d-wrapper';
import { defaultCameraName } from 'consts';
// import { Vector2 } from 'three';

// interface PostureInfo {
//     position: Vector3;
//     scale: Vector3;
//     rotation: Vector3;
// }

interface Vector2 {
    x: number;
    y: number;
}

// type ObjectPoints = [Vector2, Vector2, Vector2, Vector2, Vector2, Vector2, Vector2, Vector2];

interface ProjectionObject {
    clientID: number;
    type: ShapeType;
    points: number[];
    color: string;
}

interface Props {
    current: number; // 当前视角
    canvasWidth: number;
    canvasHeight: number;
    onlyShowActive?: boolean;

    // cameraName?: string;
}

// 边， 连接除车头外的线
// const lineOrder = [
//     [0, 6],
//     [1, 7],
//     [2, 4],
//     [3, 5],

//     [6, 4],
//     [6, 7],
//     [5, 4],
//     [5, 7],
// ];

// // 车头面
// const directionSurfaceOrder = [
//     [0, 2],
//     [0, 1],
//     [3, 1],
//     [3, 2],
// ];

const pointWidth = 8;

const ObjectProjection: FC<Props> = ({ current, canvasWidth, canvasHeight, onlyShowActive }) => {

    const canvasImageRef = useRef<HTMLCanvasElement>(null);
    const canvasImageActRef = useRef<HTMLCanvasElement>(null);
    const jobInstance = useSelector((state: CombinedState) => state.annotation.job.instance);
    const { direction: directions = [] } = jobInstance;
    const direction = directions.length ? directions[current] : `${defaultCameraName}${current}`;
    const states = useSelector((state: CombinedState) => state.subAnnotation.states.filter(item => item.objectType !== ObjectType.TAG && item.direction === direction));
    const activatedStateID = useSelector((state: CombinedState) => state.annotation.annotations.activatedStateID);
    const isOnlyShowSelectStates3D = useSelector(
        (state: CombinedState) => state.annotation.annotations.isOnlyShowSelectStates3D,
    );

    const [objectPositions, setObjectPositions] = useState<Map<number, ProjectionObject>>(new Map());

    useEffect(() => {
        // 标注对象变更
        // 提取所有对象的points
        setObjectPositions((old) => {
            let isUpdate = false;

            const position = states.reduce((previous: Map<number, ProjectionObject>, current) => {
                previous.set(current.clientID, {
                    clientID: current.clientID,
                    type: current.shapeType,
                    points: current.points,
                    color: current.label.color,
                });
                return previous;
            }, new Map<number, ProjectionObject>());

            if (JSON.stringify([...old.values()]) !== JSON.stringify([...position.values()])) {
                isUpdate = true;
            }

            return isUpdate ? position : old;
        });
    }, [states]);

    const defaultColor = '#ff4136';
    const defaultActiveColor = '#61c200';

    const drawnRect = (
        ctx: CanvasRenderingContext2D,
        point1: Vector2,
        point2: Vector2,
        point3: Vector2,
        point4: Vector2,
        // color: string = defaultColor,
    ) => {
        ctx.beginPath();
        ctx.moveTo(point1.x, point1.y);
        ctx.lineTo(point2.x, point2.y);
        ctx.lineTo(point3.x, point3.y);
        ctx.lineTo(point4.x, point4.y);
        ctx.lineTo(point1.x, point1.y);
        // ctx.fillStyle = color;
        ctx.fill();
    };

    const drawnLine = (
        ctx: CanvasRenderingContext2D,
        point1: Vector2,
        point2?: Vector2,
        color: string = defaultColor,
    ) => {
        if (point2) {
            const { x, y } = point2;
            ctx.moveTo(x, y);
        }
        ctx.strokeStyle = color;
        ctx.lineTo(point1.x, point1.y);
    };

    const drawn = (ctx: CanvasRenderingContext2D, points2D: Vector2[], type: ShapeType, color: string, isActive = false) => {

        if (!isActive && type === ShapeType.CUBOID) {
        } else if (isActive && type === ShapeType.CUBOID) {
        } else if (!isActive && type === ShapeType.laneline) {
            ctx.lineWidth = 5;
            ctx.beginPath();
            for (let index = 0; index < points2D.length - 1; index++) {
                const point1 = points2D[index]
                const point2 = points2D[index + 1]
                drawnLine(ctx, point1, point2, color);
            }
            ctx.closePath();
            ctx.stroke();

        } else if (isActive && type === ShapeType.laneline) {
            ctx.lineWidth = 5;
            ctx.fillStyle = color;

            for (const points of points2D) {
                const point1 = { x: points.x - pointWidth, y: points.y - pointWidth };
                const point2 = { x: points.x + pointWidth, y: points.y - pointWidth };
                const point3 = { x: points.x + pointWidth, y: points.y + pointWidth };
                const point4 = { x: points.x - pointWidth, y: points.y + pointWidth };
                drawnRect(ctx, point1, point2, point3, point4);
            }
        }
    }

    const drawnObjects = () => {
        if (canvasImageRef.current && typeof current === 'number') {
            const ctx = canvasImageRef.current.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                if (!objectPositions) {
                    return;
                }

                const iter = objectPositions.entries();
                for (const [clientID, projectionObject] of iter) {
                    // if (clientID === activatedStateID) {
                    //     continue;
                    // }
                    const { points, type, color } = projectionObject;
                    const points2D = points.reduce((previous: Vector2[], num: number, index: number) => {
                        const vec = previous[Math.floor(index / 2)] || { x: 0, y: 0 };
                        if (index % 2 === 0) {
                            vec.x = num;
                        } else {
                            vec.y = num;
                        }
                        previous[Math.floor(index / 2)] = vec;
                        return previous;
                    }, []);
                    if (points2D && points2D.length && type === ShapeType.laneline &&
                        (!isOnlyShowSelectStates3D || activatedStateID === clientID)) {
                        drawn(ctx, points2D, type, color, false);
                        continue;
                    }
                    if (
                        points2D &&
                        points2D.length === 8 &&
                        (!isOnlyShowSelectStates3D || activatedStateID === clientID)
                    ) {
                        // 8点
                        ctx.lineWidth = 5;
                        ctx.beginPath();
                        const array = [
                            [0, 6],
                            [1, 7],
                            [2, 4],
                            [3, 5],

                            [6, 4],
                            [6, 7],
                            [5, 4],
                            [5, 7],
                        ];
                        for (const arr of array) {
                            drawnLine(ctx, points2D[arr[0]], points2D[arr[1]]);
                        }
                        ctx.closePath();
                        ctx.stroke();

                        ctx.beginPath();
                        // 最后画出车头面
                        const paths = [0, 2, 3, 1]; // 车门
                        const acts = [
                            [0, 2],
                            [0, 1],
                            [3, 1],
                            [3, 2],
                        ];
                        ctx.fillStyle = '#2af53a45';
                        drawnRect(ctx, points2D[paths[0]], points2D[paths[1]], points2D[paths[2]], points2D[paths[3]]);
                        for (const arr of acts) {
                            drawnLine(ctx, points2D[arr[0]], points2D[arr[1]], defaultActiveColor);
                        }
                        // drawnLine(ctx, projection.points[0], projection.points[2], defaultActiveColor);
                        // drawnLine(ctx, projection.points[0], projection.points[1], defaultActiveColor);
                        // drawnLine(ctx, projection.points[3], projection.points[1], defaultActiveColor);
                        // drawnLine(ctx, projection.points[3], projection.points[2], defaultActiveColor);
                        ctx.closePath();
                        ctx.stroke();

                    } else if (
                        points2D &&
                        points2D.length === 2 &&
                        (!isOnlyShowSelectStates3D || activatedStateID === clientID)) {

                        const [min, max] = points2D;
                        ctx.fillStyle = color + '32';
                        drawnRect(ctx, {
                            x: min.x,
                            y: min.y,
                        }, {
                            x: max.x,
                            y: min.y,
                        }, {
                            x: max.x,
                            y: max.y,
                        }, {
                            x: min.x,
                            y: max.y,
                        });
                        ctx.closePath();
                        ctx.stroke();
                    }
                }
            }
        }
    };

    const drawnActObject = () => {
        if (canvasImageActRef.current) {
            const ctx = canvasImageActRef.current.getContext('2d');
            if (ctx) {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

                if (!objectPositions || !activatedStateID || !objectPositions.has(activatedStateID)) {
                    return;
                }
                const { points, type, color } = objectPositions.get(activatedStateID) as ProjectionObject;
                const points2D = points.reduce((previous: Vector2[], num: number, index: number) => {
                    const vec = previous[Math.floor(index / 2)] || { x: 0, y: 0 };
                    if (index % 2 === 0) {
                        vec.x = num;
                    } else {
                        vec.y = num;
                    }
                    previous[Math.floor(index / 2)] = vec;
                    return previous;
                }, []);

                if (points2D && points2D.length && type === ShapeType.laneline) {
                    drawn(ctx, points2D, type, color, true);
                    return;
                }

                if (points2D && points2D.length === 8) {
                    // 8点
                    ctx.fillStyle = '#639bf545';
                    ctx.lineWidth = 2;

                    const paths = [
                        [0, 2, 4, 6], // 上
                        [1, 3, 5, 7], // 下
                        [0, 6, 7, 1], // 左
                        [2, 4, 5, 3], // 右
                        [6, 4, 5, 7], // 后
                        [0, 2, 3, 1], // 车门
                    ];

                    for (const path of paths) {
                        if (path === paths[paths.length - 1]) {
                            ctx.fillStyle = '#de4def45';
                        }
                        drawnRect(ctx, points2D[path[0]], points2D[path[1]], points2D[path[2]], points2D[path[3]]);
                    }
                } else if (
                    points2D &&
                    points2D.length === 2) {

                    const [min, max] = points2D;
                    ctx.fillStyle = color + '70';
                    drawnRect(ctx, {
                        x: min.x,
                        y: min.y,
                    }, {
                        x: max.x,
                        y: min.y,
                    }, {
                        x: max.x,
                        y: max.y,
                    }, {
                        x: min.x,
                        y: max.y,
                    });
                    ctx.closePath();
                    ctx.stroke();
                }
            }
        }
    };

    useEffect(() => {
        // 有对象坐标变更或者删减
        drawnObjects();
        drawnActObject();
    }, [current, objectPositions, activatedStateID, isOnlyShowSelectStates3D]);

    return (
        <>
            {!onlyShowActive ? <canvas ref={canvasImageRef} width={canvasWidth} height={canvasHeight}
                style={{
                    position: 'absolute',
                    display: 'flex',
                    top: '0',
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none',
                }}
            /> : <></>}
            <canvas ref={canvasImageActRef} width={canvasWidth} height={canvasHeight}
                style={{
                    position: 'absolute',
                    display: 'flex',
                    top: '0',
                    width: '100%',
                    height: '100%',
                    pointerEvents: 'none',
                }} />
        </>
    );
};

export default ObjectProjection;
