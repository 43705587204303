/*
 * @Author: swxy
 * @Date: 2023-08-16 19:03:45
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { FC, ReactElement, SyntheticEvent, useEffect, useReducer, useRef, useState } from 'react';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import {
    ActiveView,
    CombinedState,
} from 'reducers/interfaces';
import { useTranslation } from 'react-i18next';
import { Canvas3d } from 'canvas3d-wrapper';
import '../styles.scss'
import { defaultRightViewWidth } from 'consts';
import ThreeCanvasComponent from './three_canvas';
import { useSelector } from 'react-redux';


interface Props {
    acitveView?: ActiveView;
    onChangeActiveView: (activeView: ActiveView) => void;
    defaultWdith?: number;
    contextImageHidden: boolean;
    onChangeWidth?: (width: number) => void

    activatedStateID?: number | null;
    canvasInstance?: Canvas3d;
}

const ThreeCanvasWrapper: FC<Props> = ({
    // acitveView,
    defaultWdith = defaultRightViewWidth,
    contextImageHidden = false,
    onChangeWidth,
}) => {

    const [viewSize, setViewSize] = useState({
        fullHeight: 0,
        fullWidth: 0,
        // vertical: 0,
        // horizontal: 0,
        width: 0,
        top: 1 / 3,
        side: 1 / 3,
        front: 1 / 3,
    });
    const {
        // canvasInstance,
        // activatedStateID,
        zoomView,
        acitveView,
    } = useSelector((state: CombinedState) => ({
        activatedStateID: state.annotation.annotations.activatedStateID,
        canvasInstance: state.annotation.canvas.instance as Canvas3d,
        acitveView: state.annotation.annotations.acitveView,
        zoomView: state.annotation.canvas.zoomView,
    }))

    // const onChangeActiveView = (view: ActiveView) => {

    // }

    const onViewResize = (event: SyntheticEvent, { node, size, handle }: ResizeCallbackData) => {
        setViewSize(oldSize => {
            return {
                ...oldSize,
                width: size.width - (event as unknown as MouseEvent).movementX,
            }
        });
        onChangeWidth?.(size.width);
    };

    const onResize = () => {
        const canvas3dContainer = document.getElementById('canvas3d-container');
        const canvas3dPerspectiveContainer = document.getElementById('cvat-canvas3d-perspective');

        if (canvas3dContainer && canvas3dPerspectiveContainer) {
            setViewSize(oldSize => {
                const fullWidth = canvas3dContainer.clientWidth;
                const fullHeight = canvas3dPerspectiveContainer.clientHeight - viewMarginTop;
                const width = oldSize.width || defaultWdith;
                const top = oldSize.top || 1 / 3;
                const side = oldSize.side || 1 / 3;
                const front = oldSize.front || 1 / 3;
                return {
                    fullHeight,
                    fullWidth,
                    // vertical: canvas3dContainer.clientHeight / 1.5,
                    // horizontal: canvas3dContainer.clientHeight / 3,
                    width,
                    top,
                    side,
                    front,
                }
            });
        }
    }

    const onThreeResize = (type: ActiveView, size: { width: number, height: number }, movementY: number): void => {
        setViewSize(oldSize => {
            let top = oldSize.top;
            let side = oldSize.side;
            let front = oldSize.front;

            if (type === ActiveView.Top) {
                top = (size.height + movementY) / oldSize.fullHeight;
                side = 1 - front - top;
            }

            if (type === ActiveView.Side) {
                side = (size.height + movementY) / oldSize.fullHeight;
                front = 1 - side - top;
            }

            return {
                ...oldSize,
                top,
                side,
                front,
            }
        });
        // onChangeWidth?.(size.width);
    };


    // const onFocus = (view: ActiveView): void => {
    //     if (activatedStateID) {
    //         onChangeActiveView(view);
    //     }
    // };

    // const onBlur = (view: ActiveView): void => {
    //     onChangeActiveView(ActiveView.default);
    // };
    const viewMarginTop = contextImageHidden ? 0 : 280;


    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        // const canvas3dContainer = document.getElementById('canvas3d-container');
        // if (canvas3dContainer) {
        //     const fullHeight = canvas3dContainer.clientHeight - viewMarginTop;
        //     setViewSize(oldSize => ({
        //         ...oldSize,
        //         fullHeight,
        //         top: oldSize.top / oldSize.fullHeight * fullHeight,
        //         side: oldSize.side / oldSize.fullHeight * fullHeight,
        //         front: oldSize.front / oldSize.fullHeight * fullHeight,
        //     }));
        // }
        onResize();
        return () => {
        };
    }, [contextImageHidden]);

    if (zoomView) {
        return <ResizableBox
            className='cvat-resizable'
            width={viewSize.width}
            height={Infinity}
            axis='y'
            handle={<div className='aatp_resizable_handle_vertical_side' onDoubleClick={() => {
                setViewSize(oldSize => {
                    return {
                        ...oldSize,
                        width: defaultRightViewWidth,
                    }
                });
                onChangeWidth?.(defaultRightViewWidth);
            }} />}
            onResize={onViewResize}
        >
            <div
                className='aatp_canvas3d_orthographic_views'
                style={{ marginTop: `${viewMarginTop}px`, height: `calc( 100% - ${viewMarginTop}px)` }}
            >
                <ThreeCanvasComponent
                    key={zoomView}
                    viewType={zoomView}
                />
                {/* {[ActiveView.Top, ActiveView.Side, ActiveView.Front].map((type: ActiveView) => {
                    return <ThreeCanvasComponent
                        key={type}
                        viewType={type}
                    />
                })} */}
            </div>
        </ResizableBox>
    }


    const topHeight = viewSize.top * viewSize.fullHeight;
    const sideHeight = viewSize.side * viewSize.fullHeight;
    const frontHeight = viewSize.front * viewSize.fullHeight;

    return (<ResizableBox
        className='aatp-resizable'
        width={viewSize.width}
        height={Infinity}
        axis='y'
        handle={<div className='aatp_resizable_handle_vertical_side' onDoubleClick={() => {
            setViewSize(oldSize => {
                return {
                    ...oldSize,
                    width: defaultRightViewWidth,
                }
            });
            onChangeWidth?.(defaultRightViewWidth);
        }} />}
        onResize={onViewResize}
    >
        <div
            className='aatp_canvas3d_orthographic_views'
        // style={{ marginTop: `${viewMarginTop}px`, height: `calc( 100% - ${viewMarginTop}px)` }}
        >
            {/* {[ActiveView.Top, ActiveView.Side, ActiveView.Front].map((type: ActiveView) => {
                return <ThreeCanvasComponent
                    key={type}
                    viewType={type}
                />
            })} */}
            <ResizableBox
                className='aatp-resizable'
                width={viewSize.width}
                height={topHeight}
                axis='x'
                handle={<div className='aatp_resizable_handle_h_view'
                // onDoubleClick={() => {
                //     setViewSize(oldSize => {
                //         return {
                //             ...oldSize,
                //             width: defaultRightViewWidth,
                //         }
                //     });
                //     onChangeWidth?.(defaultRightViewWidth);
                // }}
                />}
                onResize={(event: SyntheticEvent, { node, size, handle }: ResizeCallbackData) => {
                    onThreeResize(ActiveView.Top, size, (event as unknown as MouseEvent).movementY);
                }}
            >
                <ThreeCanvasComponent
                    key={ActiveView.Top}
                    viewType={ActiveView.Top}
                />
            </ResizableBox>
            <ResizableBox
                className='aatp-resizable'
                width={viewSize.width}
                height={sideHeight}
                axis='x'
                handle={<div className='aatp_resizable_handle_h_view'
                // onDoubleClick={() => {
                //     setViewSize(oldSize => {
                //         return {
                //             ...oldSize,
                //             width: defaultRightViewWidth,
                //         }
                //     });
                //     onChangeWidth?.(defaultRightViewWidth);
                // }}
                />}
                onResize={(event: SyntheticEvent, { node, size, handle }: ResizeCallbackData) => {
                    onThreeResize(ActiveView.Side, size, (event as unknown as MouseEvent).movementY);
                }}
            >
                <ThreeCanvasComponent
                    key={ActiveView.Side}
                    viewType={ActiveView.Side}
                />
            </ResizableBox>
            <ResizableBox
                width={viewSize.width}
                height={frontHeight}
            // axis='y'
            // handle={<div className='aatp_resizable_handle_vertical_side' onDoubleClick={() => {
            //     setViewSize(oldSize => {
            //         return {
            //             ...oldSize,
            //             width: defaultRightViewWidth,
            //         }
            //     });
            //     onChangeWidth?.(defaultRightViewWidth);
            // }} />}
            // onResize={onResize}
            >
                <ThreeCanvasComponent
                    key={ActiveView.Front}
                    viewType={ActiveView.Front}
                />
            </ResizableBox>
        </div>
    </ResizableBox>
    )
}

export default ThreeCanvasWrapper;