/*
 * @Author: swxy
 * @Date: 2023-07-01 10:14:43
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { FC, useEffect } from 'react';
import { Button, Modal, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState, SubElementInfo, ShapeType, SubElement, Workspace, SubElementType, SubElementVersion } from 'reducers/interfaces';
import { drawSubPoint as drawSubPointAction, updateAnnotationsAsync, activateObject } from 'actions/annotation-actions';
// import getCore from 'cvat-core-wrapper';
import { activateObject as activateSubObject, updateSubAnnotationsAsync } from 'actions/annotationSub-actions';
import { Canvas } from 'cvat-canvas-wrapper';
import SuspensionElement from './suspensionElement';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { ProjectCompanyType, ProjectDumpType, ProjectType, ShapeObjFrType } from 'utils/ConstType';
// import getHelper from 'business/objects/customs/helper';

import '../styles.scss';
import { Label } from 'business/objects/label';
import Job from 'business/objects/job';

interface Props {
    activatedStateID?: number | null;
    states: any[];
}
// const cvat = getCore();

const numPointsOfShapeObjFrType: Record<number, number> = {
    [ShapeObjFrType.car]: 4,
    [ShapeObjFrType.truck]: 4,
    [ShapeObjFrType.bus]: 4,
    [ShapeObjFrType.pickTruck]: 4,
    [ShapeObjFrType.engineVehicle]: 4,
    [ShapeObjFrType.tricycle]: 3,
    [ShapeObjFrType.bicycle]: 2,
    [ShapeObjFrType.motorcycle]: 2,
    17: 4,  // 未知按4个车轮点算
}


const getNumOfPointsByShapeObjFrType = (label: any) => {
    if (label) {
        const attribute = label.attributes.find((attr: any) => attr.name === 'shapeObjFrType');
        if (attribute) {
            const value = +attribute.defaultValue
            if (value >= 0) {
                return numPointsOfShapeObjFrType[value];
            }
        }
    }
    return 0;
}

const geSubElementType = (isLane: boolean, label?: any) => {
    if (isLane && label) {
        const attribute = label.attributes.find((attr: any) => attr.name === 'shapeObjFrType');
        if (attribute) {
            const value = +attribute.defaultValue
            if (value >= ShapeObjFrType.lane) {
                return SubElementType.dashedLine;
            }

        }
    }
    return SubElementType.wheelPoint;
}

const isDashLine = (job: Job, label: Label, attributes: Record<number, string>) => {
    let isDash = false;
    // 2D车道线
    if (
        job.projectType === ProjectType.two &&
        job.project.projectDumpType === ProjectDumpType.lane &&
        label &&
        label.attributes &&
        attributes
    ) {
        label.attributes.forEach((attr) => {
            if (attr.name === 'type') {
                // 固定类型，判断是否为行人
                if (
                    [
                        'single_dash',
                        'fat_dash',
                        'double_dash',
                        'left_dash_right_solid',
                        'right_dash_left_solid',
                    ].includes(attributes[attr.id])
                ) {
                    isDash = true;
                }
            }
        });
    }
    return isDash;
}

// const isUnkownShapeObjFrType = (label: any) => {
//     if (label) {
//         const attribute = label.attributes.find((attr: any) => attr.name === 'shapeObjFrType');
//         // 是否是未知
//         return attribute.value === 17;
//     }
//     return false;
// };

const Suspension: FC<Props> = ({ activatedStateID, states = [] }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();

    // console.log('当前结果：', states);

    const { drawSubPoints, workSpace, jobInstance, keyMap } = useSelector((state: CombinedState) => ({
        drawSubPoints: state.annotation.annotations.drawSubPoints,
        workSpace: state.annotation.workspace,
        jobInstance: state.annotation.job.instance,
        keyMap: state.shortcuts.keyMap,
    }));

    const canvasInstance = useSelector((state: CombinedState) =>
        jobInstance.projectType === ProjectType.mix
            ? state.subAnnotation.canvas.instance
            : state.annotation.canvas.instance) as Canvas;

    // const onResize = async (event: Event) => {
    //     const { detail: {
    //         points,
    //         clientID,
    //         // duration,
    //         // shapeType,
    //         subType,
    //     } } = event as CustomEvent;

    //     const activatedState = states.find((state) => state.clientID === clientID);

    //     const subElement: SubElement = JSON.parse(JSON.stringify(activatedState.elements[0]));

    //     if (subType === SubElementType.wheelPoint) {
    //         if (Array.isArray(points)) {
    //             points.forEach((num: number, index: number) => {
    //                 const subscript = Math.floor(index / 2);
    //                 if (index % 2 === 0) {
    //                     subElement.points[subscript].x = num;
    //                 } else {
    //                     subElement.points[subscript].y = num;
    //                     subElement.points[subscript] = {
    //                         ...subElement.points[subscript],
    //                     };
    //                 }
    //             });
    //         }
    //     } else if (subType === SubElementType.dashedLine) {
    //         if (points.length % 4 !== 0) {
    //             message.error('虚线点必须同时拥有起始点和结束点(点的数量是偶数)');
    //             return
    //         }

    //         if (Array.isArray(points)) {
    //             points.forEach((num: number, index: number) => {
    //                 const subscript = Math.floor(index / 2);
    //                 if (index % 2 === 0) {
    //                     subElement.points[subscript].x = num;
    //                 } else {
    //                     subElement.points[subscript].y = num;
    //                     subElement.points[subscript] = {
    //                         ...subElement.points[subscript],
    //                     };
    //                 }
    //             });
    //         }

    //         // 重新计算，点在线上
    //         // console.log('当前信息：', points);
    //         // return
    //     } else {
    //         message.error('没有找到该类型的子元素！')
    //     }

    //     activatedState.elements = [subElement];

    //     // (canvasInstance as Canvas).cancel();
    //     // await dispatch(drawSubPointAction())

    //     if (activatedState.direction) {
    //         // if (clientID) {
    //         //     dispatch(activateSubObject(clientID))
    //         // }
    //         dispatch(updateSubAnnotationsAsync([activatedState]));
    //     } else {
    //         // if (clientID) {
    //         //     dispatch(activateObject(clientID, null))
    //         // }
    //         dispatch(updateAnnotationsAsync([activatedState]));
    //     }

    // }

    const onComplete = (event: Event) => {
        const { detail: {
            points,
            clientID,
            // duration,
            // shapeType,
            subType,
        } } = event as CustomEvent;

        const activatedState = states.find((state) => state.clientID === clientID);

        const subElement: SubElement = Array.isArray(activatedState.elements) && activatedState.elements.length ? activatedState.elements[0] : {
            shapeType: subType,
            numOfPoints: getNumOfPointsByShapeObjFrType(activatedState.label),
            points: [],
            version: SubElementVersion.version1_0_0,
        };

        if (subType === SubElementType.wheelPoint) {
            // const elements = Array.isArray(activatedState.elements) ? [...activatedState.elements] : [subElement];
            if (Array.isArray(points)) {
                const sub: SubElementInfo = {
                    x: -100,
                    y: -100,
                    occluded: false,
                    order: 0,
                }

                const hasOrder = subElement.points.map((sub) => +(sub.order || 1));
                // const newPoints: SubElementInfo[] = [];
                points.forEach((num: number, index: number) => {
                    // const subscript = Math.floor(index / 2);
                    if (index % 2 === 0) {
                        sub.x = num;
                    } else {
                        sub.y = num;
                        // subElement.points[subscript] = {
                        //     ...sub,
                        //     order: subscript + 1,
                        // };
                        let order = 0;
                        for (let index = 1; index <= subElement.numOfPoints; index++) {
                            if (order === 0 && !hasOrder.includes(index)) {
                                order = index;
                            }
                        }
                        // newPoints.push({
                        //     ...sub,
                        //     order,
                        // });
                        subElement.points.push({
                            ...sub,
                            order,
                        });
                        // subElement.numOfPoints = subscript;
                    }
                });
            }

            // if (!isUnkownShapeObjFrType(activatedState.label)) {
            // 去除多余的点，除未知外，最大点数是固定的

            if (subElement.points.length >= subElement.numOfPoints) {
                subElement.points = subElement.points.filter((point, index) => index < subElement.numOfPoints);
            }
            // }

            // subElement.numOfPoints = isUnkownShapeObjFrType(activatedState.label) ? Math.max(subElement.numOfPoints, subElement.points.length) : subElement.numOfPoints;
        } else if (subType === SubElementType.dashedLine) {
            if (points.length % 4 !== 0) {
                message.error('虚线点必须同时拥有起始点和结束点(点的数量是偶数)');
                return
            }

            if (Array.isArray(points)) {
                const sub: SubElementInfo = {
                    x: -100,
                    y: -100,
                    pointType: 'start',
                    occluded: false,
                }
                points.forEach((num: number, index: number) => {
                    const subscript = Math.floor(index / 2);
                    if (index % 2 === 0) {
                        sub.x = num;
                    } else {
                        sub.y = num;
                        subElement.points[subscript] = {
                            ...sub,
                            pointType: subscript % 2 === 0 ? 'start' : 'end',
                        };

                        // subElement.numOfPoints = subscript;
                    }
                });
            }
            subElement.numOfPoints = Math.max(subElement.numOfPoints, subElement.points.length);
            // 重新计算，点在线上
            // console.log('当前信息：', points);
            // return
        } else {
            message.error('没有找到该类型的子元素！')
        }


        activatedState.elements = [subElement];

        (canvasInstance as Canvas).cancel();
        dispatch(drawSubPointAction())
        if (activatedState.direction) {
            // if (clientID) {
            //     dispatch(activateSubObject(clientID))
            // }
            dispatch(updateSubAnnotationsAsync([activatedState]));
        } else {
            // if (clientID) {
            //     dispatch(activateObject(clientID, null))
            // }

            dispatch(updateAnnotationsAsync([activatedState]));
        }
    }

    useEffect(() => {
        const canvasElement = canvasInstance?.html();
        if (canvasElement) {
            canvasElement?.addEventListener('canvas.subDrawn', onComplete);
            // canvasElement?.addEventListener('canvas.sub.edited', onResize);
        }
        return () => {
            if (canvasElement) {
                canvasElement?.removeEventListener('canvas.subDrawn', onComplete);
                // canvasElement?.removeEventListener('canvas.sub.edited', onResize);
            }
        }
    }, [states]);


    if (jobInstance.project.projectCompanyType !== ProjectCompanyType.dazhuo) {
        // 目前只有dazuo的客户需要标车轮点
        return <></>
    }
    if (!activatedStateID && !drawSubPoints) {
        // 没有选中目标物  或者  没有在绘制中时， 不展示当前页面
        return <></>;
    }

    const activatedState = states.find((state) => state.clientID === activatedStateID);

    const isDrawSubPoints = !!drawSubPoints;
    const subElementType = drawSubPoints?.subElementType || geSubElementType(jobInstance.project.projectType !== ProjectType.mix, activatedState?.label);

    const onClick = () => {

        (canvasInstance as Canvas).cancel();
        if (isDrawSubPoints) {
            // const { clientID } = drawSubPoints;
            // const activatedState = states.find((state) => state.clientID === clientID);
            dispatch(drawSubPointAction());
            // if (activatedState.direction) {
            //     dispatch(activateSubObject(clientID))
            // } else {
            //     dispatch(activateObject(clientID, null));
            // }
            return;
        }

        if (subElementType === SubElementType.dashedLine) {
            // 虚线时，判断是否是虚线属性
            // const helper = getHelper();
            if (!isDashLine(jobInstance, activatedState?.label, activatedState?.attributes)) {
                message.error('该对象属性并不是虚线！');
                return;
            }
        }

        (canvasInstance as Canvas).drawSubPoint({
            enabled: true,
            clientID: activatedStateID as number,
            // points: activatedState.subPoints,
            shapeType: ShapeType.POINTS,
            numberOfPoints: subElementType === SubElementType.dashedLine ? 0 : getNumOfPointsByShapeObjFrType(activatedState.label),
            subType: subElementType,
        });
        dispatch(drawSubPointAction({
            clientID: activatedStateID as number,
            subElementType,
        }));
    }

    if (activatedState?.elements?.length) {
        const readonly = workSpace === Workspace.REVIEW_WORKSPACE || activatedState.lock;
        return <SuspensionElement objectState={activatedState} readonly={readonly} addSubPoint={onClick} />;
    } else if (workSpace === Workspace.REVIEW_WORKSPACE) {
        return <></>;
    }


    const onPointsComplex = () => {
        (canvasInstance as Canvas).drawSubPoint({
            enabled: false,
        });
    }

    const subKeyMap = {
        add_sub_element: keyMap.add_sub_element,
    };

    const handlers = {
        add_sub_element: (event: KeyboardEvent | undefined) => {
            if (event) {

                isDrawSubPoints ? onPointsComplex() : onClick();
            }
        },
    };

    return (
        <div className='aatp_suspension'>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <div className='aatp_suspension_row'>
                <div className='aatp_suspension_row_title'>
                    {subElementType === SubElementType.wheelPoint ? '添加目标物车轮点:' : '添加目标物虚线点:'}
                </div>
                <div className='aatp_suspension_row_content'>
                    <Button onClick={onClick}>{isDrawSubPoints ? '取消' : '添加 [J]'}</Button>
                </div>
            </div>

            {isDrawSubPoints && <div className='aatp_suspension_row_center'>
                <Button onClick={onPointsComplex}>完成 [J]</Button>
            </div>}
        </div>
    )
}

export default Suspension;