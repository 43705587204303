// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import { connect } from 'react-redux';
import Result from 'antd/lib/result';
import Text from 'antd/lib/typography/Text';
import Paragraph from 'antd/lib/typography/Paragraph';
import Collapse from 'antd/lib/collapse';
import TextArea from 'antd/lib/input/TextArea';
import copy from 'copy-to-clipboard';
import ErrorStackParser from 'error-stack-parser';

import { ThunkDispatch } from 'utils/redux';
import { resetAfterErrorAsync } from 'actions/boundaries-actions';
import { CombinedState } from 'reducers/interfaces';
import logger, { LogType } from 'cvat-logger';
import CVATTooltip from 'components/common/cvat-tooltip';
import consts from 'consts';
import { saveErrorLogs } from 'service/api/api';

interface OwnProps {
    children: JSX.Element;
}

interface StateToProps {
    job: any | null;
    serverVersion: string;
    coreVersion: string;
    canvasVersion: string;
    uiVersion: string;
}

interface DispatchToProps {
    restore(): void;
}

interface State {
    hasError: boolean;
    error: Error | null;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            job: { instance: job },
        },
        about: { server, packageVersion },
    } = state;

    return {
        job,
        serverVersion: server.version as string,
        coreVersion: packageVersion.core,
        canvasVersion: packageVersion.canvas,
        uiVersion: packageVersion.ui,
    };
}

function mapDispatchToProps(dispatch: ThunkDispatch): DispatchToProps {
    return {
        restore(): void {
            dispatch(resetAfterErrorAsync());
        },
    };
}

type Props = StateToProps & DispatchToProps & OwnProps;
class GlobalErrorBoundary extends React.PureComponent<Props, State> {
    public constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
        };
    }

    static getDerivedStateFromError(error: Error): State {
        return {
            hasError: true,
            error,
        };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        const { job } = this.props;
        const parsed = ErrorStackParser.parse(error);

        const logPayload = {
            filename: parsed[0].fileName,
            line: parsed[0].lineNumber,
            message: error.message,
            column: parsed[0].columnNumber,
            stack: error.stack,
            componentStack: errorInfo.componentStack,
        };
        saveErrorLogs({
            urlAddress: location.pathname,
            detail: JSON.stringify(logPayload),
        })

        if (job) {
            job.logger.log(LogType.sendException, logPayload);
        } else {
            logger.log(LogType.sendException, logPayload);
        }
    }

    public render(): React.ReactNode {
        const { restore, job, serverVersion, coreVersion, canvasVersion, uiVersion } = this.props;

        const { hasError, error } = this.state;

        const restoreGlobalState = (): void => {
            this.setState({
                error: null,
                hasError: false,
            });

            restore();
        };

        if (hasError && error) {
            const message = `${error.name}\n${error.message}\n\n${error.stack}`;
            return (
                <div className='cvat-global-boundary'>
                    <Result
                        status='error'
                        // title='Oops, something went wrong'
                        // subTitle='More likely there are some issues with the tool'
                        title='页面出现了一些错误'
                        subTitle='工具可能存在一些问题'
                    >
                        <div>
                            <Paragraph>
                                {/* <Paragraph strong>What has happened?</Paragraph>
                                <Paragraph>Program error has just occurred</Paragraph> */}
                                <Paragraph strong>出了什么事？</Paragraph>
                                <Paragraph>发生了程序错误</Paragraph>
                                <Paragraph>
                                    {/* <Text strong>What should I do?</Text> */}
                                    <Text strong>我可以怎么做？</Text>
                                </Paragraph>
                                <ul>
                                    <li>描述下出现错误的前几步操作</li>
                                    <li>
                                        <CVATTooltip title='Copied!' trigger='click'>
                                            {/* eslint-disable-next-line */}
                                            <a
                                                onClick={() => {
                                                    copy(message);
                                                }}
                                            >
                                                {' '}
                                                {/* Copy{' '} */}
                                                复制{' '}
                                            </a>
                                        </CVATTooltip>
                                        {/* the error message to clipboard */}
                                        这个错误信息
                                    </li>
                                    <li>截图告知管理员</li>
                                </ul>
                                <Collapse accordion activeKey={'errorMessage'}>
                                    <Collapse.Panel header='错误信息' key='errorMessage'>
                                        <Text type='danger'>
                                            <TextArea
                                                className='cvat-global-boundary-error-field'
                                                autoSize
                                                value={message}
                                            />
                                        </Text>
                                    </Collapse.Panel>
                                </Collapse>
                            </Paragraph>

                        </div>
                    </Result>
                </div>
            );
        }

        const { children } = this.props;
        return children;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalErrorBoundary);
