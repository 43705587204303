// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

// import { Store } from 'redux';
import { ActionUnion, createAction, ThunkAction } from 'utils/redux';
import getCore from 'cvat-core-wrapper';
// import { getAmygoStore } from 'cvat-store';
// import { CombinedState } from 'reducers/interfaces';
import { issueChangeStaus } from 'utils/constant';
import { DimensionType } from 'reducers/interfaces';
import Issue from 'business/objects/issue';
import { sleep } from 'utils/utils';

// const cvat = getCore();
// let store: null | Store<CombinedState> = null;

// function getStore(): Store<CombinedState> {
//     if (store === null) {
//         store = getAmygoStore();
//     }
//     return store;
// }

export enum ReviewActionTypes {
    CREATE_ISSUE = 'CREATE_ISSUE',
    START_ISSUE = 'START_ISSUE',
    FINISH_ISSUE_SUCCESS = 'FINISH_ISSUE_SUCCESS',
    FINISH_ISSUE_FAILED = 'FINISH_ISSUE_FAILED',
    CANCEL_ISSUE = 'CANCEL_ISSUE',
    RESOLVE_ISSUE = 'RESOLVE_ISSUE',
    RESOLVE_ISSUE_SUCCESS = 'RESOLVE_ISSUE_SUCCESS',
    RESOLVE_ISSUE_FAILED = 'RESOLVE_ISSUE_FAILED',
    REOPEN_ISSUE = 'REOPEN_ISSUE',
    REOPEN_ISSUE_SUCCESS = 'REOPEN_ISSUE_SUCCESS',
    REOPEN_ISSUE_FAILED = 'REOPEN_ISSUE_FAILED',
    COMMENT_ISSUE = 'COMMENT_ISSUE',
    COMMENT_ISSUE_SUCCESS = 'COMMENT_ISSUE_SUCCESS',
    COMMENT_ISSUE_FAILED = 'COMMENT_ISSUE_FAILED',
    UPDATE_ISSUE_SUCCESS = 'UPDATE_ISSUE_SUCCESS',
    UPDATE_ISSUE_FAILED = 'UPDATE_ISSUE_FAILED',
    REMOVE_ISSUE_SUCCESS = 'REMOVE_ISSUE_SUCCESS',
    REMOVE_ISSUE_FAILED = 'REMOVE_ISSUE_FAILED',
    SUBMIT_REVIEW = 'SUBMIT_REVIEW',
    SUBMIT_REVIEW_SUCCESS = 'SUBMIT_REVIEW_SUCCESS',
    SUBMIT_REVIEW_FAILED = 'SUBMIT_REVIEW_FAILED',
    SWITCH_ISSUES_HIDDEN_FLAG = 'SWITCH_ISSUES_HIDDEN_FLAG',
    SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG = 'SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG',
    SELECT_ISSUE = 'SELECT_ISSUE',
    // DELETE_ISSUES_SUCCESS = 'DELETE_ISSUES_SUCCESS',
    // DELETE_ISSUES_FAILED = 'DELETE_ISSUES_FAILED',
}

export const reviewActions = {
    createIssue: () => createAction(ReviewActionTypes.CREATE_ISSUE, {}),
    startIssue: (position: number[], type?: DimensionType) =>
        createAction(ReviewActionTypes.START_ISSUE, { position: Issue.hull(position, type) }),
    finishIssueSuccess: (frame: number, issue: any) =>
        createAction(ReviewActionTypes.FINISH_ISSUE_SUCCESS, { frame, issue }),
    finishIssueFailed: (error: any) => createAction(ReviewActionTypes.FINISH_ISSUE_FAILED, { error }),
    cancelIssue: () => createAction(ReviewActionTypes.CANCEL_ISSUE),
    commentIssue: (issueId: number) => createAction(ReviewActionTypes.COMMENT_ISSUE, { issueId }),
    commentIssueSuccess: () => createAction(ReviewActionTypes.COMMENT_ISSUE_SUCCESS),
    commentIssueFailed: (error: any) => createAction(ReviewActionTypes.COMMENT_ISSUE_FAILED, { error }),
    updateIssueSuccess: () => createAction(ReviewActionTypes.UPDATE_ISSUE_SUCCESS),
    updateIssueFailed: (error: any) => createAction(ReviewActionTypes.UPDATE_ISSUE_FAILED, { error }),
    resolveIssue: (issueId: number) => createAction(ReviewActionTypes.RESOLVE_ISSUE, { issueId }),
    resolveIssueSuccess: () => createAction(ReviewActionTypes.RESOLVE_ISSUE_SUCCESS),
    resolveIssueFailed: (error: any) => createAction(ReviewActionTypes.RESOLVE_ISSUE_FAILED, { error }),
    reopenIssue: (issueId: number) => createAction(ReviewActionTypes.REOPEN_ISSUE, { issueId }),
    reopenIssueSuccess: () => createAction(ReviewActionTypes.REOPEN_ISSUE_SUCCESS),
    reopenIssueFailed: (error: any) => createAction(ReviewActionTypes.REOPEN_ISSUE_FAILED, { error }),
    submitReview: (jobId: number) => createAction(ReviewActionTypes.SUBMIT_REVIEW, { jobId }),
    submitReviewSuccess: () => createAction(ReviewActionTypes.SUBMIT_REVIEW_SUCCESS),
    submitReviewFailed: (error: any, jobId: number) =>
        createAction(ReviewActionTypes.SUBMIT_REVIEW_FAILED, { error, jobId }),
    removeIssueSuccess: (issueId: number, frame: number) =>
        createAction(ReviewActionTypes.REMOVE_ISSUE_SUCCESS, { issueId, frame }),
    removeIssueFailed: (error: any) => createAction(ReviewActionTypes.REMOVE_ISSUE_FAILED, { error }),
    switchIssuesHiddenFlag: (hidden: boolean) => createAction(ReviewActionTypes.SWITCH_ISSUES_HIDDEN_FLAG, { hidden }),
    switchIssuesHiddenResolvedFlag: (hidden: boolean) =>
        createAction(ReviewActionTypes.SWITCH_RESOLVED_ISSUES_HIDDEN_FLAG, { hidden }),
    selectIssue: (id: number | null) => createAction(ReviewActionTypes.SELECT_ISSUE, { selectIssueID: id }),
    // deleteIssueSuccess: (activeReview: any, frame: number) =>
    //     createAction(ReviewActionTypes.DELETE_ISSUES_SUCCESS, { activeReview, frame }),
    // deleteIssueFailed: (error: any) => createAction(ReviewActionTypes.DELETE_ISSUES_FAILED, { error }),
};

export type ReviewActions = ActionUnion<typeof reviewActions>;

export const finishIssueAsync =
    (message: string): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            annotation: {
                player: {
                    frame: { number: frameNumber },
                },
                job: { instance: jobInstance },
                annotations: { cameraName },
            },
            review: { newIssuePosition },
            subAnnotation: { perspective },
        } = state;

        try {
            const issue = new Issue({
                job: jobInstance.id,
                jobId: jobInstance.id,
                frame: frameNumber,
                position: newIssuePosition,
                points: newIssuePosition,
                direction: perspective,
                jobStatus: jobInstance.jobStatus,
                cameraName,
            });

            const savedIssue = await jobInstance.openIssue(issue, message);
            dispatch(reviewActions.finishIssueSuccess(frameNumber, savedIssue));
        } catch (error) {
            dispatch(reviewActions.finishIssueFailed(error));
        }
    };

export const commentIssueAsync =
    (id: number, message: string): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            // auth: { user },
            review: { frameIssues },
        } = state;

        try {
            dispatch(reviewActions.commentIssue(id));
            const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
            await issue.comment({
                message,
                // owner: user,
            });

            dispatch(reviewActions.commentIssueSuccess());
        } catch (error) {
            dispatch(reviewActions.commentIssueFailed(error));
        }
    };

export const updateIssuePointsAsync =
    (id: number, points: number[]): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            // auth: { user },
            review: { frameIssues },
        } = state;

        try {
            dispatch(reviewActions.commentIssue(id));
            const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
            await issue.update(points);

            dispatch(reviewActions.updateIssueSuccess());
        } catch (error) {
            dispatch(reviewActions.updateIssueFailed(error));
        }
    };

export const resolveIssueAsync =
    (id: number): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            auth: { user },
            review: { issues },
        } = state;

        try {
            const noteStatus = issueChangeStaus(user?.roleType);
            dispatch(reviewActions.resolveIssue(id));
            const [issue] = issues.filter((_issue: any): boolean => _issue.id === id);
            await issue.resolve(noteStatus);
            dispatch(reviewActions.resolveIssueSuccess());
        } catch (error) {
            dispatch(reviewActions.resolveIssueFailed(error));
        }
    };

export const reopenIssueAsync =
    (id: number): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            // auth: { user },
            review: { frameIssues },
        } = state;

        try {
            // const noteStatus = issueChangeStaus(user?.roleType);
            dispatch(reviewActions.reopenIssue(id));
            const [issue] = frameIssues.filter((_issue: any): boolean => _issue.id === id);
            issue.jobStatus = state.annotation.job.instance.jobStatus;
            await issue.reopen();
            dispatch(reviewActions.reopenIssueSuccess());
        } catch (error) {
            dispatch(reviewActions.reopenIssueFailed(error));
        }
    };

export const deleteIssueAsync =
    (id: number): ThunkAction =>
    async (dispatch, getState) => {
        const state = getState();
        const {
            review: { issues },
            annotation: {
                player: {
                    frame: { number: frameNumber },
                },
            },
        } = state;

        try {
            const [issue] = issues.filter((_issue: any): boolean => _issue.id === id);
            await issue.delete();
            dispatch(reviewActions.removeIssueSuccess(id, frameNumber));
        } catch (error) {
            dispatch(reviewActions.removeIssueFailed(error));
        }
    };

export const selectIssue =
    (id: number | null): ThunkAction =>
    async (dispatch) => {
        dispatch(reviewActions.selectIssue(id));
    };
// export const deleteIssueAsync = (id: number): ThunkAction => async (dispatch, getState) => {
//     const state = getState();
//     const {
//         review: { activeReview },
//     } = state;

//     try {
//         if (activeReview && activeReview.issues.find((issue: any) => issue.id === id)) {
//             await activeReview.deleteIssue(id);
//             await activeReview.toLocalStorage();
//             const annotationState: CombinedState = getStore().getState();
//             const { annotation: { player: { frame: { number } } } } = annotationState;

//             dispatch(reviewActions.deleteIssueSuccess(activeReview, number));
//         }
//     } catch (error) {
//         dispatch(reviewActions.deleteIssueFailed(error));
//     }
// };
