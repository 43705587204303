/*
 * @Author: swxy
 * @Date: 2022-08-15 14:21:02
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import userNotice from './other/userNotice';

export default {
    'login.login': 'ログイン',
    'login.register': '登録',

    'login.username': 'ユーザー名',
    'login.password': 'パスワード',
    'login.empty_username': 'ユーザー名を入力してください',
    'login.empty_password': 'パスワードを入力してください',

    'login.confirm_password': 'パスワードの確認',
    'login.confirm_empty_password': 'パスワードを再入力してください',
    'login.valid_password': '2回の入力パスワードが一致しません！',

    'login.title': 'データ寸法ツール',
    'login.demoTitle': '自動ラベル',
    'login.demoSubTitle': '―― 体験版',
    'login.resetPassword': 'パスワードを忘れる',

    'login.nickName': 'ニックネーム',
    'login.email': 'メールボックス',
    'login.mobile': '携帯電話',
    'login.affiliation': '会社/学校',
    'login.info': 'その他',
    'login.verifyCode': '認証コード',
    'login.placeholder_nickName': 'ニックネームを入力してください',
    'login.placeholder_email': 'メールアドレスを入力してください',
    'login.placeholder_mobile': '携帯電話に入力してください',
    'login.placeholder_affiliation': '会社/学校を入力してください',
    'login.placeholder_info': 'その他を入力してください',
    'login.placeholder_verifyCode': '認証コードを入力してください',

    'login.valid_email': 'メールボックスのフォーマットが間違っています！',
    'login.valid_mobile': '手机格式携帯電話のフォーマットが間違っている！错误！',

    'login.hasAccount': '既存のアカウント？できます。',

    'login.registerSuccess': '登録に成功しました！',


    'login.newPassword': '新しいパスワード',
    'login.empty_newPassword': '新しいパスワード',
    'login.confirm_empty_newPassword': '新しいパスワードを再入力してください',

    'login.forgetPassword': 'パスワードを忘れる',
    'login.email.change': '交換',
    'login.validcode.get': '取得',
    'login.validcode.placeholder': '認証コードを取得し、入力してください',

    'login.imageVerifyCode': 'ピクチャ認証コード',
    'login.imageVerifyCode_placeholder': '画像認証コードを取得し、入力してください',
    'login.emailVerifyCode': 'メール認証コード',
    'login.emailVerifyCode_placeholder': 'メール認証コードを取得し、入力してください',

    'login.imageCodeNeed': '画像認証コードを入力してください',
    'login.sendEmailValidCodeSuccess': '認証コードがメールボックスに送信されました。確認してください',
    'login.sendEmailValidCodeError': '認証コードの送信に失敗しました！',

    //#region 2023-05-04 待校验
    'register.userNotice': '「利用者規約」',
    'register.confirmUserNotice': '確認して同意して署名する',
    'register.userNoticeTip': '「ユーザー規約」を確認して同意してください。',
    'register.userNotice.title': '利用者規約',
    ...userNotice,
    //#endregion
};
