// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Table from 'antd/lib/table';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';

import CVATTooltip from 'components/common/cvat-tooltip';
import { CombinedState, DimensionType } from 'reducers/interfaces';
import { showStatistics } from 'actions/annotation-actions';
import { useTranslation } from 'react-i18next';
import { LabelObjectType, LabelProjectType, ProjectType } from 'utils/ConstType';
import { number } from 'prop-types';
import Job from 'business/objects/job';
import { getValueName } from 'utils/constant';
import { Descriptions } from 'antd';
import { api_countTargetTotal, queryJobInfo } from 'service/job/job';

interface StateToProps {
    visible: boolean;
    collecting: boolean;
    data: any;
    jobStatus: string;
    savingJobStatus: boolean;
    bugTracker: string | null;
    startFrame: number;
    stopFrame: number;
    dimension: DimensionType;
    assignee: any | null;
}

interface DispatchToProps {
    closeStatistics(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            statistics: { visible, collecting, data },
            job: {
                saving: savingJobStatus,
                instance: {
                    bugTracker,
                    startFrame,
                    stopFrame,
                    assignee,
                    dimension,
                    status: jobStatus,
                },
            },
        },
    } = state;

    return {
        visible,
        collecting,
        data,
        jobStatus,
        savingJobStatus,
        bugTracker,
        startFrame,
        stopFrame,
        dimension,
        assignee: assignee?.username || 'Nobody',
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        closeStatistics(): void {
            dispatch(showStatistics(false));
        },
    };
}

function StatisticsModalComponent(props: StateToProps & DispatchToProps): JSX.Element {
    const {
        collecting,
        data,
        visible,
        assignee,
        startFrame,
        stopFrame,
        bugTracker,
        closeStatistics,
        dimension,
    } = props;
    const { t } = useTranslation();

    const { jobInstance, issues } = useSelector((state: CombinedState) => ({
        jobInstance: state.annotation.job.instance,
        issues: state.review.issues,
    }))

    const [issueData, setIssueData] = useState<{
        issue2D: number;
        issue3D: number;
        total: number;
    }>({
        issue2D: 0,
        issue3D: 0,
        total: 0,
    });

    const getIssueData = () => {
        if (issues && issues.length) {
            const statistics = {
                issue2D: 0,
                issue3D: 0,
                total: issues.length,
            }
            issues.forEach((issue) => {
                if ([ProjectType.three].includes(jobInstance.projectType)) {
                    statistics.issue3D++;
                } else if ([ProjectType.two].includes(jobInstance.projectType)) {
                    statistics.issue2D++;
                } else if ([ProjectType.mix].includes(jobInstance.projectType)) {
                    if (issue.direction) {
                        statistics.issue2D++;
                    } else {
                        statistics.issue3D++;
                    }
                } else {
                    statistics.issue2D++;
                }
            })

            setIssueData(statistics);
        }
    }

    const baseProps = {
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { width: 100 } },
        onOk: closeStatistics,
        width: 1024,
        visible,
        closable: false,
        destroyOnClose: true,
        bodyStyle: {
            height: `calc(100vh - 300px)`,
            overflow: 'scroll',
        },

    };

    useEffect(() => {
        getIssueData();
    }, [issues]);



    if (collecting || !data) {
        return (
            <Modal {...baseProps}>
                <Spin style={{ margin: '0 50%' }} />
            </Modal>
        );
    }

    let total = 0;

    const makeShapesTracksTitle = (title: string): JSX.Element => (
        <CVATTooltip title={`${t('workspace.shape')} / ${t('workspace.track')}`}>
            <Text strong style={{ marginRight: 5 }}>
                {title}
            </Text>
            <QuestionCircleOutlined className='cvat-info-circle-icon' />
        </CVATTooltip>
    );

    const tagLabels = (jobInstance.labels as any[] || []).filter(label => label.labelObjectType === LabelObjectType.image) || []
    const columnsTags = [
        {
            title: <Text strong> {t('overview.label')} </Text>,
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: <Text strong> {t('overview.total')} </Text>,
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: <Text strong> {t('overview.frames')} </Text>,
            dataIndex: 'frames',
            key: 'frames',
        },
    ];

    const rowsTags = tagLabels.map((label: any) => {
        const key = label.id;
        return ({
            key,
            label: label.title || key,
            frames: jobInstance.stopFrame - jobInstance.startFrame + 1,
            total: data.label[key].tags,
        })
    })

    rowsTags.push({
        key: '___total',
        label: t('overview.total'),
        frames: jobInstance.stopFrame - jobInstance.startFrame + 1,
        total: rowsTags.reduce((previous, current) => previous + current.total, 0),
    })

    let labels = (jobInstance.labels as any[] || []).filter(label => ![LabelObjectType.image, LabelObjectType.semantic].includes(label.labelObjectType)) || []
    const columns2D = [
        {
            title: <Text strong> {t('overview.label')} </Text>,
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: makeShapesTracksTitle(t('shape.full.rectangle')),
            dataIndex: 'rectangle',
            key: 'rectangle',
        },
        {
            title: makeShapesTracksTitle(t('shape.full.polygon')),
            dataIndex: 'polygon',
            key: 'polygon',
        },
        {
            title: makeShapesTracksTitle(t('shape.full.polyline')),
            dataIndex: 'polyline',
            key: 'polyline',
        },
        {
            title: makeShapesTracksTitle(t('shape.full.points')),
            dataIndex: 'points',
            key: 'points',
        },
        {
            title: makeShapesTracksTitle(t('shape.full.ellipse')),
            dataIndex: 'ellipse',
            key: 'ellipse',
        },
        {
            title: makeShapesTracksTitle(t('shape.full.cuboid')),
            dataIndex: 'cuboid',
            key: 'cuboid',
        },
        {
            title: <Text strong> {t('overview.auto')}  </Text>,
            dataIndex: 'auto',
            key: 'auto',
        },
        {
            title: <Text strong> {t('overview.manually')}  </Text>,
            dataIndex: 'manually',
            key: 'manually',
        },
        {
            title: <Text strong> {t('overview.modify')}  </Text>,
            dataIndex: 'modify',
            key: 'modify',
        },
        {
            title: <Text strong> {t('overview.interpolated')}  </Text>,
            dataIndex: 'interpolated',
            key: 'interpolated',
        },
        {
            title: <Text strong>{t('overview.total')} </Text>,
            dataIndex: 'total',
            key: 'total',
        },
    ];

    if (jobInstance.projectType === ProjectType.mix) {
        labels = (jobInstance.labels as any[] || []).filter(label =>
            ![LabelObjectType.image, LabelObjectType.semantic].includes(label.labelObjectType) &&
            label.mainLabelId &&
            label.labelProjectType === LabelProjectType.two) || []
    }

    const rows2D = labels.map((label: any) => {
        const key = label.id;
        return ({
            key,
            label: label.title || key,
            rectangle: `${data.data.data2D[key].rectangle.shape} / ${data.data.data2D[key].rectangle.track}`,
            polygon: `${data.data.data2D[key].polygon.shape} / ${data.data.data2D[key].polygon.track}`,
            polyline: `${data.data.data2D[key].polyline.shape} / ${data.data.data2D[key].polyline.track}`,
            points: `${data.data.data2D[key].points.shape} / ${data.data.data2D[key].points.track}`,
            ellipse: `${data.data.data2D[key].ellipse.shape} / ${data.data.data2D[key].ellipse.track}`,
            cuboid: `${data.data.data2D[key].cuboid.shape} / ${data.data.data2D[key].cuboid.track}`,
            auto: data.data.data2D[key].auto,
            manually: data.data.data2D[key].manually,
            interpolated: data.data.data2D[key].interpolated,
            total: data.data.data2D[key].total,
        })
    })

    rows2D.push({
        key: '___total',
        label: t('overview.total'),
        rectangle: `${data.data.total2D.rectangle.shape} / ${data.data.total2D.rectangle.track}`,
        polygon: `${data.data.total2D.polygon.shape} / ${data.data.total2D.polygon.track}`,
        polyline: `${data.data.total2D.polyline.shape} / ${data.data.total2D.polyline.track}`,
        points: `${data.data.total2D.points.shape} / ${data.data.total2D.points.track}`,
        ellipse: `${data.data.total2D.ellipse.shape} / ${data.data.total2D.ellipse.track}`,
        cuboid: `${data.data.total2D.cuboid.shape} / ${data.data.total2D.cuboid.track}`,
        auto: rows2D.reduce((previous, current) => previous + current.auto, 0),
        manually: rows2D.reduce((previous, current) => previous + current.manually, 0),
        interpolated: rows2D.reduce((previous, current) => previous + current.interpolated, 0),
        total: rows2D.reduce((previous, current) => previous + current.total, 0),
    })

    // total += rows2D.reduce((previous, current) => previous + current.total, 0);
    total += rows2D[rows2D.length - 1].total;

    const columns3D = [
        {
            title: <Text strong> {t('overview.label')} </Text>,
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: makeShapesTracksTitle(t('shape.full.polyline')),
            dataIndex: 'polyline',
            key: 'polyline',
        },
        {
            title: makeShapesTracksTitle(t('shape.full.cuboid')),
            dataIndex: 'cuboid',
            key: 'cuboid',
        },
        {
            title: <Text strong> {t('overview.auto')}  </Text>,
            dataIndex: 'auto',
            key: 'auto',
        },
        {
            title: <Text strong> {t('overview.manually')}  </Text>,
            dataIndex: 'manually',
            key: 'manually',
        },
        {
            title: <Text strong> {t('overview.interpolated')}  </Text>,
            dataIndex: 'interpolated',
            key: 'interpolated',
        },
        {
            title: <Text strong>{t('overview.total')} </Text>,
            dataIndex: 'total',
            key: 'total',
        },
    ];

    if (jobInstance.projectType === ProjectType.mix) {
        labels = (jobInstance.labels as any[] || []).filter(label =>
            ![LabelObjectType.image, LabelObjectType.semantic].includes(label.labelObjectType) &&
            !label.parentLabelId &&
            label.labelProjectType !== LabelProjectType.two) || []
    }

    const rows3D = labels.map((label: any) => {
        const key = label.id;
        return ({
            key,
            label: label.title || key,
            rectangle: `${data.data.data3D[key].rectangle.shape} / ${data.data.data3D[key].rectangle.track}`,
            polygon: `${data.data.data3D[key].polygon.shape} / ${data.data.data3D[key].polygon.track}`,
            polyline: `${data.data.data3D[key].polyline.shape} / ${data.data.data3D[key].polyline.track}`,
            points: `${data.data.data3D[key].points.shape} / ${data.data.data3D[key].points.track}`,
            ellipse: `${data.data.data3D[key].ellipse.shape} / ${data.data.data3D[key].ellipse.track}`,
            cuboid: `${data.data.data3D[key].cuboid.shape} / ${data.data.data3D[key].cuboid.track}`,
            auto: data.data.data3D[key].auto,
            manually: data.data.data3D[key].manually,
            interpolated: data.data.data3D[key].interpolated,
            total: data.data.data3D[key].total,
        })
    })

    rows3D.push({
        key: '___total',
        label: t('overview.total'),
        rectangle: `${data.data.total3D.rectangle.shape} / ${data.data.total3D.rectangle.track}`,
        polygon: `${data.data.total3D.polygon.shape} / ${data.data.total3D.polygon.track}`,
        polyline: `${data.data.total3D.polyline.shape} / ${data.data.total3D.polyline.track}`,
        points: `${data.data.total3D.points.shape} / ${data.data.total3D.points.track}`,
        ellipse: `${data.data.total3D.ellipse.shape} / ${data.data.total3D.ellipse.track}`,
        cuboid: `${data.data.total3D.cuboid.shape} / ${data.data.total3D.cuboid.track}`,
        auto: rows3D.reduce((previous, current) => previous + current.auto, 0),
        manually: rows3D.reduce((previous, current) => previous + current.manually, 0),
        interpolated: rows3D.reduce((previous, current) => previous + current.interpolated, 0),
        total: rows3D.reduce((previous, current) => previous + current.total, 0),
    })

    // total += rows3D.reduce((previous, current) => previous + current.total, 0);
    total += rows3D[rows3D.length - 1].total;

    const rows = labels.map((label: any) => {
        const key = label.id;
        return ({
            key,
            label: label.title || key,
            rectangle: `${data.data.data[key].rectangle.shape} / ${data.data.data[key].rectangle.track}`,
            polygon: `${data.data.data[key].polygon.shape} / ${data.data.data[key].polygon.track}`,
            polyline: `${data.data.data[key].polyline.shape} / ${data.data.data[key].polyline.track}`,
            points: `${data.data.data[key].points.shape} / ${data.data.data[key].points.track}`,
            ellipse: `${data.data.data[key].ellipse.shape} / ${data.data.data[key].ellipse.track}`,
            cuboid: `${data.data.data[key].cuboid.shape} / ${data.data.data[key].cuboid.track}`,
            auto: data.data.data[key].auto,
            manually: data.data.data[key].manually,
            modify: data.data.data[key].modify,
            interpolated: data.data.data[key].interpolated,
            total: data.data.data[key].total,
        })
    })

    rows.push({
        key: '___total',
        label: t('overview.total'),
        rectangle: `${data.data.total.rectangle.shape} / ${data.data.total.rectangle.track}`,
        polygon: `${data.data.total.polygon.shape} / ${data.data.total.polygon.track}`,
        polyline: `${data.data.total.polyline.shape} / ${data.data.total.polyline.track}`,
        points: `${data.data.total.points.shape} / ${data.data.total.points.track}`,
        ellipse: `${data.data.total.ellipse.shape} / ${data.data.total.ellipse.track}`,
        cuboid: `${data.data.total.cuboid.shape} / ${data.data.total.cuboid.track}`,
        auto: rows.reduce((previous, current) => previous + current.auto, 0),
        manually: rows.reduce((previous, current) => previous + current.manually, 0),
        modify: rows.reduce((previous, current) => previous + current.modify, 0),
        interpolated: rows.reduce((previous, current) => previous + current.interpolated, 0),
        total: rows.reduce((previous, current) => previous + current.total, 0),
    })

    total += rows[rows.length - 1].total;

    const columnsIssue = [
        {
            title: <Text strong> {t('overview.issue.column.2d')} </Text>,
            dataIndex: 'issue2D',
            key: 'issue2D',
        },
        {
            title: <Text strong> {t('overview.issue.column.3d')} </Text>,
            dataIndex: 'issue3D',
            key: 'issue3D',
        },
        {
            title: <Text strong>{t('overview.total')} </Text>,
            dataIndex: 'total',
            key: 'total',
        },
    ];

    const rowsIssue = [{
        issue2D: issueData?.issue2D,
        issue3D: issueData?.issue3D,
        total: issueData?.total,
    }]

    // rowsIssue.push({
    //     key: '___total',
    //     label: t('overview.total'),
    //     rectangle: `${data.total.rectangle.shape} / ${data.total.rectangle.track}`,
    //     polygon: `${data.total.polygon.shape} / ${data.total.polygon.track}`,
    //     polyline: `${data.total.polyline.shape} / ${data.total.polyline.track}`,
    //     points: `${data.total.points.shape} / ${data.total.points.track}`,
    //     ellipse: `${data.total.ellipse.shape} / ${data.total.ellipse.track}`,
    //     cuboid: `${data.total.cuboid.shape} / ${data.total.cuboid.track}`,
    //     auto: data.total.auto,
    //     manually: data.total.manually,
    //     interpolated: data.total.interpolated,
    //     total: data.total.total,
    // })

    let tables = {
        tags: {
            title: t('overview.tag.title'),
            columns: columnsTags,
            rows: rowsTags,
        },
        annotations2D: {
            title: t('overview.annotations2D.title'),
            columns: columns2D,
            rows: rows2D,
        },
        annotations3D: {
            title: t('overview.annotations3D.title'),
            columns: columns3D,
            rows: rows3D,
        },
        annotations: {
            title: t('overview.annotations.title'),
            columns: columns2D,
            rows: rows,
        },
        issue: {
            title: t('overview.issue.title'),
            columns: columnsIssue,
            rows: rowsIssue,
        }
    }

    let elementTag = tables.tags.rows.length ? <>
        <Row justify='space-around' className='cvat-job-info-statistics'>
            <Col span={24}>
                <Text className='cvat-text'>{tables.tags.title}</Text>
                <Table
                    scroll={{ y: 400 }}
                    bordered
                    pagination={false}
                    columns={tables.tags.columns}
                    dataSource={tables.tags.rows}
                />
            </Col>
        </Row>
    </> : <></>

    let element2D = [ProjectType.two, ProjectType.mix].includes(jobInstance.projectType) ? <>

        <br />
        <br />

        <Row justify='space-around' className='cvat-job-info-statistics'>
            <Col span={24}>
                <Text className='cvat-text'>{tables.annotations2D.title}</Text>
                <Table
                    scroll={{ y: 400 }}
                    bordered
                    pagination={false}
                    columns={tables.annotations2D.columns}
                    dataSource={tables.annotations2D.rows}
                />
            </Col>
        </Row>
    </> : undefined


    let element3D = [ProjectType.three, ProjectType.mix].includes(jobInstance.projectType) ? <>

        <br />
        <br />

        <Row justify='space-around' className='cvat-job-info-statistics'>
            <Col span={24}>
                <Text className='cvat-text'>{tables.annotations3D.title}</Text>
                <Table
                    scroll={{ y: 400 }}
                    bordered
                    pagination={false}
                    columns={tables.annotations3D.columns}
                    dataSource={tables.annotations3D.rows}
                />
            </Col>
        </Row>
    </> : undefined

    let element: any = [element2D, element3D];
    if (!element2D && !element3D) {
        element = <>

            <br />
            <br />

            <Row justify='space-around' className='cvat-job-info-statistics'>
                <Col span={24}>
                    <Text className='cvat-text'>{tables.annotations.title}</Text>
                    <Table
                        scroll={{ y: 400 }}
                        bordered
                        pagination={false}
                        columns={tables.annotations.columns}
                        dataSource={tables.annotations.rows}
                    />
                </Col>
            </Row>
        </>
    }


    let elementIssue = <>

        <br />
        <br />

        <Row justify='space-around' className='cvat-job-info-statistics'>
            <Col span={24}>
                <Text className='cvat-text'>{tables.issue.title}</Text>
                <Table
                    scroll={{ y: 400 }}
                    bordered
                    pagination={false}
                    columns={tables.issue.columns}
                    dataSource={tables.issue.rows}
                />
            </Col>
        </Row>
    </>

    return (
        <Modal {...baseProps}>
            <div className='cvat-job-info-modal-window'>
                <Row justify='start'>
                    <Col>
                        <Text className='cvat-text'>{t('overview.title')}</Text>
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            {t('overview.userInfo.assignee')}
                        </Text>
                        <Text className='cvat-text'>{assignee}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            {t('overview.frameInfo.startFrame')}
                        </Text>
                        <Text className='cvat-text'>{startFrame}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            {t('overview.frameInfo.stopFrame')}
                        </Text>
                        <Text className='cvat-text'>{stopFrame}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            {t('overview.frameInfo.frames')}
                        </Text>
                        <Text className='cvat-text'>{stopFrame - startFrame + 1}</Text>
                    </Col>
                </Row>
                {!!bugTracker && (
                    <Row justify='start' className='cvat-job-info-bug-tracker'>
                        <Col>
                            <Text strong className='cvat-text'>
                                Bug tracker
                            </Text>
                            <a href={bugTracker}>{bugTracker}</a>
                        </Col>
                    </Row>
                )}

                <br />
                <br />
                <br />
                <br />

                {
                    elementTag
                }

                {
                    element
                }

                {/* {
                    element2D
                }

                {
                    element3D
                } */}

                {
                    elementIssue
                }
                <Row justify='space-around' className='cvat-job-info-statistics'>
                    <Col span={24}>
                        <Text className='cvat-text'>元素准确率：</Text>
                        {/* <Text className='cvat-text'>{((1 - (issueData.total / (rows2D[rows2D.length - 1].total + rows3D[rows3D.length - 1].total))) * 100).toFixed(2)}%</Text> */}
                        <Text className='cvat-text'>{total > 0 ? ((1 - (issueData.total / total)) * 100).toFixed(2) : 100}%</Text>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsModalComponent);

const StatisticsSimpleModalComponent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { visible, jobInstance, issues } = useSelector((state: CombinedState) => ({
        visible: state.annotation.statistics.visible,
        jobInstance: state.annotation.job.instance,
        issues: state.review.issues,
    }))

    const [loading, setLoading] = useState(true);
    const [total, setTotal] = useState(0);
    const [issueData, setIssueData] = useState<{
        issue2D: number;
        issue3D: number;
        total: number;
    }>({
        issue2D: 0,
        issue3D: 0,
        total: 0,
    });

    const closeStatistics = () => {
        dispatch(showStatistics(false))
    }

    const getTotal = async () => {
        setLoading(true);
        const success = await api_countTargetTotal({ jobId: jobInstance.id });
        if (success) {
            const jobInfo = await queryJobInfo({ id: jobInstance.id });
            if (jobInfo && typeof jobInfo.shapeNum === 'number') {
                setTotal(jobInfo.shapeNum);
            }
        }

        setLoading(false);
        // setLoading(false);
    }

    const getIssueData = () => {
        if (issues && issues.length) {
            const statistics = {
                issue2D: 0,
                issue3D: 0,
                total: issues.length,
            }
            issues.forEach((issue) => {
                if ([ProjectType.three].includes(jobInstance.projectType)) {
                    statistics.issue3D++;
                } else if ([ProjectType.two].includes(jobInstance.projectType)) {
                    statistics.issue2D++;
                } else if ([ProjectType.mix].includes(jobInstance.projectType)) {
                    if (issue.direction) {
                        statistics.issue2D++;
                    } else {
                        statistics.issue3D++;
                    }
                } else {
                    statistics.issue2D++;
                }
            })

            setIssueData(statistics);
        }
    }

    useEffect(() => {
        if (visible) {
            getTotal();
        }
    }, [visible]);

    useEffect(() => {
        if (visible) {
            getIssueData();
        }
    }, [visible, issues]);

    const { startFrame, stopFrame, jobStatus } = jobInstance as Job

    const baseProps = {
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { width: 100 } },
        onOk: closeStatistics,
        width: 1024,
        visible,
        closable: false,
        destroyOnClose: true,
        bodyStyle: {
            height: `calc(100vh - 300px)`,
            overflow: 'scroll',
        },
    };


    const columnsIssue = [
        {
            title: <Text strong> {t('overview.issue.column.2d')} </Text>,
            dataIndex: 'issue2D',
            key: 'issue2D',
        },
        {
            title: <Text strong> {t('overview.issue.column.3d')} </Text>,
            dataIndex: 'issue3D',
            key: 'issue3D',
        },
        {
            title: <Text strong>{t('overview.total')} </Text>,
            dataIndex: 'total',
            key: 'total',
        },
    ];

    const rowsIssue = [{
        issue2D: issueData?.issue2D,
        issue3D: issueData?.issue3D,
        total: issueData?.total,
    }]

    return <Modal {...baseProps}>
        <Spin spinning={loading}>
            <div className='cvat-job-info-modal-window'>
                <Row justify='start'>
                    <Col>
                        <Text className='cvat-text'>{t('overview.title')}</Text>
                    </Col>
                </Row>
                <Row justify='start'>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            {t('overview.jobInfo.jobStatus')}
                        </Text>
                        <Text className='cvat-text'>{getValueName('jobStatus', jobStatus)}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            {t('overview.frameInfo.startFrame')}
                        </Text>
                        <Text className='cvat-text'>{startFrame}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            {t('overview.frameInfo.stopFrame')}
                        </Text>
                        <Text className='cvat-text'>{stopFrame}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            {t('overview.frameInfo.frames')}
                        </Text>
                        <Text className='cvat-text'>{stopFrame - startFrame + 1}</Text>
                    </Col>
                </Row>

                <br />
                <br />
                <br />
                <br />

                <Row justify='space-around' className='cvat-job-info-statistics'>
                    <Col span={24}>
                        <Descriptions>
                            <Descriptions.Item label={t('overview.annotation.target')}>
                                {total}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <br />
                <br />

                <Row justify='space-around' className='cvat-job-info-statistics'>
                    <Col span={24}>
                        <Text className='cvat-text'>{t('overview.issue.title')}</Text>
                        <Table
                            scroll={{ y: 400 }}
                            bordered
                            pagination={false}
                            columns={columnsIssue}
                            dataSource={rowsIssue}
                        />
                    </Col>
                </Row>

                <Row justify='space-around' className='cvat-job-info-statistics'>
                    <Col span={24}>
                        <Text className='cvat-text'>元素准确率：</Text>
                        <Text className='cvat-text'>{total > 0 ? ((1 - (issueData.total / total)) * 100).toFixed(2) : 100}%</Text>
                    </Col>
                </Row>
            </div>
        </Spin>
    </Modal>
}

// export default StatisticsSimpleModalComponent;
