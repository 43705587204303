/*
 * @Author: swxy
 * @Date: 2023-08-26 17:29:10
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { ScriptingError, ServerError } from 'errors/exception';
import Collection from './annotationCollection';
import Job from './job';
import Shape, { ServerShapeData, ToServerShapeData } from './shape';
import Tag, { ServerTagData, ToServerTagData } from './tag';
import Track, { ServerTrackData, ServerTrackShapeData, ToServerTrackData } from './track';
import { saveAnnotationsToServer, saveImageAnnotations } from 'service/job/job';
import { ObjectType } from 'reducers/interfaces';
import { message } from 'antd';

function getDirectionClientIDKey(clientID: number, direction?: string) {
    return direction ? `${clientID}-${direction}` : clientID;
}

class AnnotationsSaver {
    private sessionType: string;
    private id: number;
    private hash: string;

    private deleteTrackShape: Record<number, { trackId: number; frame: number }>;
    private deleteClientIDs: number[];

    private waitPromises: { param: Function }[];
    private saving: boolean;

    public collection: Collection;
    public initialObjects: {
        tags: Record<number, ToServerTagData>;
        shapes: Record<number, ToServerShapeData>;
        tracks: Record<number, ToServerTrackData>;
    };

    public savTime: number;

    constructor(collection: Collection, session: Job) {
        this.sessionType = 'job';
        this.id = session.id;
        // this.version = version;
        this.collection = collection;
        this.initialObjects = {
            tags: {},
            shapes: {},
            tracks: {},
        };
        // this.addClientId = { shapes: [], tracks: [], tags: [] };
        this.hash = this._getHash();

        this.deleteTrackShape = {};
        this.deleteClientIDs = [];

        // 等待保存promise， 保存池
        this.waitPromises = [];
        this.saving = false;

        // We need use data from export instead of initialData
        // Otherwise we have differ keys order and JSON comparison code incorrect
        const exported = this.collection.export();

        this._resetState();
        for (const shape of exported.shapes) {
            if (shape.id) {
                this.initialObjects.shapes[shape.id] = shape;
            }
        }

        for (const track of exported.tracks) {
            if (track.id) {
                this.initialObjects.tracks[track.id] = track;
            }
        }

        for (const tag of exported.tags) {
            if (tag.id) {
                this.initialObjects.tags[tag.id] = tag;
            }
        }

        this.savTime = new Date().valueOf();
    }

    public updateInitial({
        tracks,
        shapes,
        tags,
    }: // trackShapes,
    {
        tracks: Track[];
        shapes: Shape[];
        tags: Tag[];
        // trackShapes: Record<number, any>;
    }) {
        const data = {
            tracks: tracks.filter((track) => !track.removed).map((track) => track.toJSON()),
            shapes: shapes.filter((shape) => !shape.removed).map((shape) => shape.toJSON()),
            tags: tags.filter((tag) => !tag.removed).map((tag) => tag.toJSON()),
        };

        for (const shape of data.shapes) {
            if (shape.id) {
                this.initialObjects.shapes[shape.id] = shape;
            }
        }

        for (const track of data.tracks) {
            if (track.id) {
                this.initialObjects.tracks[track.id] = track;
            }
        }

        for (const tag of data.tags) {
            if (tag.id) {
                this.initialObjects.tags[tag.id] = tag;
            }
        }

        this._updateHash();
    }

    public updateInitialTrackShape(trackShapesByTrackId: Record<number, Record<number, any>>) {
        // const data = {
        //     tracks: tracks.filter((track) => !track.removed).map((track) => track.toJSON()),
        // };

        Object.entries(trackShapesByTrackId).forEach(([key, trackShape]) => {
            this.initialObjects.tracks[+key].mergeTrackShapes = {
                ...this.initialObjects.tracks[+key].mergeTrackShapes,
                ...trackShape,
            };
        });

        this._updateHash();
    }

    _resetState() {
        this.initialObjects = {
            shapes: {},
            tracks: {},
            tags: {},
        };
    }

    _getHash() {
        const exported = this.collection.export();
        return JSON.stringify(exported);
    }

    _updateHash() {
        const exported: {
            tracks: ToServerTrackData[];
            shapes: ToServerShapeData[];
            tags: ToServerTagData[];
        } = {
            tracks: Object.values(this.initialObjects.tracks).sort((a, b) => (a.id || -1) - (b.id || 0)),
            shapes: Object.values(this.initialObjects.shapes).sort((a, b) => (a.id || -1) - (b.id || 0)),
            tags: Object.values(this.initialObjects.tags).sort((a, b) => (a.id || -1) - (b.id || 0)),
        };

        this.hash = JSON.stringify(exported);
    }

    _tParams(exported: { tracks: ToServerTrackData[]; shapes: ToServerShapeData[]; tags: ToServerTagData[] }) {
        const splitted: {
            created: {
                shapes: Record<number | string, ToServerShapeData>;
                tracks: Record<number | string, ToServerTrackData>;
                tags: Record<number | string, ToServerTagData>;
            };
            updated: {
                shapes: Record<number | string, ToServerShapeData>;
                tracks: Record<number | string, ToServerTrackData>;
                tags: Record<number | string, ToServerTagData>;
            };
            deleted: {
                shapes: number[];
                tracks: number[];
                trackShapes: number[];
                tags: number[];
            };
        } = {
            created: {
                shapes: {},
                tracks: {},
                tags: {},
            },
            updated: {
                shapes: {},
                tracks: {},
                tags: {},
            },
            deleted: {
                shapes: [],
                tracks: [],
                trackShapes: [],
                tags: [],
            },
        };

        const keys = [
            // 'idVals',
            'id',
            'shape',
            'track',
            'labelId',
            'group',
            'frame',
            'occluded',
            'zOrder',
            'points',
            'occludedParts',
            'pointsLine',
            'rotation',
            'type',
            'value',
            'source',
            'outside',
            'trackShape',
            'shapeId',
            'trackId',
            'direction',
            'subPoints',
            'pointsControl',
        ];

        // Find created and updated objects
        for (const key of Object.keys(exported)) {
            const type = key as 'tags' | 'shapes' | 'tracks';
            for (const object of exported[type]) {
                if (typeof object.id === 'undefined') {
                    const clientKey = getDirectionClientIDKey(object.clientID, object.direction);
                    splitted.created[type][clientKey] = object;
                } else if (object.id in this.initialObjects[type]) {
                    try {
                        const objectId = object.id;
                        let isUpdateObject = false;
                        // const updateObject: any = {
                        //     id: object.id,
                        //     // jobId: object.jobId,
                        //     clientID: object.clientID,
                        //     shape: {
                        //         id: object.id,
                        //         jobId: object.jobId,
                        //         clientID: object.clientID,
                        //     },
                        // };

                        // updateObject.idVals = {};

                        const updateObject: ToServerTagData | ToServerShapeData | ToServerTrackData = {
                            ...object,
                            idVals: {},
                        };

                        // 先对比是否有更改对象本身。
                        const exportedHash = JSON.stringify(object, keys);
                        const initialHash = JSON.stringify(this.initialObjects[type][object.id], keys);
                        if (exportedHash !== initialHash) {
                            // updateObject.shape = type === 'tracks' ? object.track : object.shape;
                            isUpdateObject = true;
                        }

                        const objectIdVals: Record<number, string> = {};
                        // 比较属性是否更改
                        Object.entries(object.idVals).forEach(([attrId, value]) => {
                            const { idVals } = this.initialObjects[type][objectId];
                            if (idVals[+attrId] !== value) {
                                objectIdVals[+attrId] = value;
                                isUpdateObject = true;
                            }
                        });

                        // 需要更新的属性
                        updateObject.idVals = objectIdVals;

                        if (type === 'tags') {
                            // updateObject.image = updateObject.shape;
                            // delete updateObject.shape;
                        }
                        if (type === 'tracks') {
                            // updateObject.track = updateObject.shape;
                            // delete updateObject.shape;
                            // (updateObject as ToServerTrackData).mergeTrackShapes = {};
                            const updateMergeTrackShapes: Record<number, any> = {};

                            // 连续帧的关键帧
                            const { mergeTrackShapes } = object as ToServerTrackData;
                            // updateTrack.mergeTrackShapes = {};
                            Object.entries(mergeTrackShapes).forEach(([frame, shape]) => {
                                const initialShape = this.initialObjects[type][objectId].mergeTrackShapes[+frame];
                                if (initialShape) {
                                    // 是否更新了关键帧
                                    let isUpdateShape = false;
                                    // 是否更新了关键帧的属性
                                    // let isUpdateAttr = false;
                                    const exportedShapeHash = JSON.stringify(shape, keys);
                                    const initialShapeHash = JSON.stringify(initialShape, keys);
                                    const updateShape = {
                                        ...initialShape,
                                        idVals: {},
                                    };

                                    const newTrackShapeIdVals: Record<number, string> = {};

                                    // 比较关键帧属性是否修改
                                    Object.entries(shape.idVals).forEach(([attrId, value]) => {
                                        const { idVals = {} } = initialShape;
                                        if (idVals[+attrId] !== value) {
                                            newTrackShapeIdVals[+attrId] = value;
                                            isUpdateObject = true;
                                            isUpdateShape = true;
                                        }
                                    });

                                    updateShape.idVals = newTrackShapeIdVals;

                                    // 比较关键帧信息是否更改
                                    if (exportedShapeHash !== initialShapeHash) {
                                        updateShape.trackShape = shape.trackShape;
                                        isUpdateObject = true;
                                        isUpdateShape = true;
                                    }

                                    if (isUpdateShape) {
                                        // updateObject.mergeTrackShapes[shape.frame] = updateShape;
                                        updateMergeTrackShapes[shape.frame] = updateShape;
                                    }
                                } else {
                                    // 新增关键帧
                                    isUpdateObject = true;
                                    // updateObject.mergeTrackShapes[shape.frame] = shape;
                                    updateMergeTrackShapes[shape.frame] = shape;
                                }
                            });
                            (updateObject as ToServerTrackData).mergeTrackShapes = updateMergeTrackShapes;
                        }
                        if (isUpdateObject) {
                            const clientKey = getDirectionClientIDKey(object.clientID, object.direction);
                            splitted.updated[type][clientKey] = updateObject;
                        }
                    } catch (error) {
                        console.error('转换属性状态错误！');
                        throw new ScriptingError(
                            `检查修改内容错误-待保存数据:${JSON.stringify(object)}。 错误详情:-${error}`,
                        );
                    }
                } else {
                    throw new ScriptingError(`对象的 ID “${object.id}”，在初始状态下不存在`);
                }
            }
        }

        // 找到需要删除的id
        const indexes = {
            shapes: exported.shapes.reduce((ids: number[], object) => {
                if (object.id) {
                    ids.push(object.id);
                }
                return ids;
            }, []),
            tracks: exported.tracks.reduce((ids: number[], object) => {
                if (object.id) {
                    ids.push(object.id);
                }
                return ids;
            }, []),
            tags: exported.tags.reduce((ids: number[], object) => {
                if (object.id) {
                    ids.push(object.id);
                }
                return ids;
            }, []),
        };

        // 只需要找出需要删除的，因此无视id不存在的
        const trackObj = exported.tracks.reduce((previous: Record<number, any>, current) => {
            if (current.id) {
                previous[current.id] = current;
            }
            return previous;
        }, {});

        for (const key of Object.keys(this.initialObjects)) {
            const type = key as 'tags' | 'shapes' | 'tracks';
            for (const id of Object.keys(this.initialObjects[type])) {
                const object = this.initialObjects[type][+id];
                if (!indexes[type].includes(+id) && id !== 'null' && id !== 'undefined') {
                    // 找到初始里有，但当前导出的对象里没有的id，当前对象需要删除
                    splitted.deleted[type].push(+id);
                    this.deleteClientIDs.push(object.clientID);
                } else if (type === 'shapes') {
                    //
                } else if (type === 'tracks') {
                    // 当id包含时，找到需要删除和多余的的属性、连续帧的关键帧、关键帧的属性里，是否有多余及需要删除的。
                    if (object.id) {
                        const track = trackObj[object.id];
                        const trackedshapesFrames = Object.keys(track.mergeTrackShapes);
                        Object.entries((object as ToServerTrackData).mergeTrackShapes).forEach(([frame, current]) => {
                            // 以往的shapeid里，如果现有的没有包含，则需要删除该对象
                            if (!trackedshapesFrames.includes(frame)) {
                                splitted.deleted.trackShapes.push(current.id!);
                                this.deleteTrackShape[current.id!] = {
                                    trackId: object.id!,
                                    frame: +frame,
                                };
                            }
                        });
                    }
                }
            }
        }

        return {
            jobId: this.id,
            delShapes: [...splitted.deleted.shapes],
            delTracks: [...splitted.deleted.tracks],
            delTrackShapes: [...splitted.deleted.trackShapes],
            delImages: [...splitted.deleted.tags],
            mergeShapes: { ...splitted.created.shapes, ...splitted.updated.shapes },
            mergeTracks: { ...splitted.created.tracks, ...splitted.updated.tracks },
            mergeImages: { ...splitted.created.tags, ...splitted.updated.tags },
        };
    }

    async saveImages(data: { delImages: number[]; mergeImages: Record<number | string, ToServerTagData> }) {
        try {
            const { mergeImages } = data;
            const { newImages = {} } = await saveImageAnnotations(data);

            // 更改则直接用参数替换
            Object.entries(mergeImages).forEach(([clientID, tag]) => {
                if (!tag.id && newImages[clientID]) {
                    const tagId: number = newImages[clientID];
                    tag.id = tagId;
                    tag.image.id = tag.id;
                    // this.collection.objects[clientID].serverID = tag.id;
                    this.collection.updateServerID({
                        clientID: tag.clientID,
                        serverID: tag.id!,
                        objectType: ObjectType.TAG,
                        direction: tag.image.direction, // 有方向，代表是投影
                    });

                    this.initialObjects.tags[tag.id!] = tag;
                } else if (tag.id) {
                    // 回填属性
                    if (this.initialObjects.tags[tag.id]) {
                        const { idVals } = this.initialObjects.tags[tag.id];
                        // const updateIdVals = shape.idVals;
                        tag.idVals = { ...idVals, ...tag.idVals };
                    }

                    this.initialObjects.tags[tag.id] = tag;
                } else {
                    message.error(`新增图像标注对象可能失败了：${tag.image.frame}`);
                }
            });
        } catch (error) {
            // console.log('保存图像标注时错误：', error);
            throw new ServerError(`保存图像标注时错误：${error}`);
        }
    }

    async saveData(data: {
        delShapes: number[];
        delTrackShapes: number[];
        delTracks: number[];
        mergeShapes: Record<number | string, ToServerShapeData>;
        mergeTracks: Record<number | string, ToServerTrackData>;
        jobId: number;
    }) {
        const { ...shapes } = data;

        const { newShapes = {}, newTracks = {} } = await saveAnnotationsToServer(shapes);
        const { delShapes = [], delTrackShapes = [], delTracks = [], mergeShapes = {}, mergeTracks = {} } = shapes;
        for (const type of Object.keys(this.initialObjects)) {
            if (type === 'shapes') {
                // 删除普通帧
                delShapes.forEach((shapeServerID) => {
                    delete this.initialObjects[type][shapeServerID];
                });

                // 更改则直接用参数替换
                Object.entries(mergeShapes).forEach(([clientID, shape]) => {
                    try {
                        if (!shape.id && newShapes[clientID]) {
                            const shapeId = newShapes[clientID];
                            shape.id = shapeId;
                            shape.shape.id = shape.id;

                            this.initialObjects[type][shapeId] = shape;
                            // this.collection.objects[clientID].serverID = shape.id;
                            this.collection.updateServerID({
                                clientID: shape.clientID,
                                serverID: shapeId,
                                objectType: ObjectType.SHAPE,
                                direction: shape.shape.direction, // 有方向，代表是投影
                            });
                        } else if (shape.id) {
                            // 回填属性
                            if (this.initialObjects[type][shape.id]) {
                                const { idVals } = this.initialObjects[type][shape.id];
                                // const updateIdVals = shape.idVals;
                                shape.idVals = { ...idVals, ...shape.idVals };
                            }

                            this.initialObjects[type][shape.id] = shape;
                        } else {
                            message.error(`新增框可能失败了：${shape.shape.clientID}`);
                        }
                    } catch (error) {
                        throw new ServerError(`回填普通帧报错:${error}`);
                    }
                });
            } else if (type === 'tracks') {
                try {
                    // 删除连续帧
                    delTracks.forEach((shapeServerID) => {
                        delete this.initialObjects[type][shapeServerID];
                        // delete this.collection.objects[clientID];
                    });
                    // 删除连续帧下的关键帧
                    delTrackShapes.forEach((trackShapeId) => {
                        const { trackId, frame } = this.deleteTrackShape[trackShapeId];
                        delete this.initialObjects[type][trackId].mergeTrackShapes[frame];
                    });
                } catch (error) {
                    throw new ServerError(`删除连续帧报错:${error}`);
                }

                // 更改则直接用入参替换
                Object.entries(mergeTracks).forEach(([clientID, track]) => {
                    // const addTrack = newTracks[clientID];

                    const { id, newTrackShapes = {} } = newTracks[clientID] || {};

                    if (!track.id && id) {
                        try {
                            // 新增的关键帧
                            track.id = id;
                            track.track.id = track.id;
                            // this.collection.objects[clientID].serverID = track.id;
                            const trackShapeServerIDByFrame: Record<number, number> = {};
                            // this.initialObjects[type][track.id] = track;
                            if (track.mergeTrackShapes && Object.keys(track.mergeTrackShapes).length) {
                                Object.entries(track.mergeTrackShapes).forEach(([frame, shape]) => {
                                    // 新增的关键帧回填id
                                    if (!shape.id && newTrackShapes[frame]) {
                                        const trackShapeId = newTrackShapes[frame];
                                        shape.id = trackShapeId;
                                        shape.trackShape.id = shape.id;

                                        // if (this.collection.objects[clientID].shapes[frame]) {
                                        //     this.collection.objects[clientID].shapes[frame].serverID = shape.id;
                                        // }
                                        trackShapeServerIDByFrame[+frame] = trackShapeId;
                                    } else if (!shape.id && !newTrackShapes[frame]) {
                                        message.error(`新增连续帧框可能失败了：第${shape.frame}帧的${shape.clientID}`);
                                    }
                                });
                            }

                            this.collection.updateServerID({
                                clientID: track.clientID,
                                serverID: id,
                                objectType: ObjectType.TRACK,
                                direction: track.track.direction, // 有方向，代表是投影
                                trackShapeServerIDByFrame,
                            });
                        } catch (error) {
                            throw new ServerError(`新增连续帧报错:${error}`);
                        }
                        this.initialObjects[type][id] = track;
                    } else if (track.id) {
                        try {
                            // 只修改关键帧时，连续帧信息中只有id。为了兼容更改连续帧信息，需要合并初始化和入参的连续帧信息。
                            const {
                                track: oldTrack = {},
                                idVals: oldIdVals = {},
                                mergeTrackShapes: oldMergeTrackShapes = {},
                            } = this.initialObjects[type][track.id] || {};
                            // 更新连续帧信息
                            track.track = { ...oldTrack, ...track.track };
                            // 更新连续帧属性信息
                            track.idVals = { ...oldIdVals, ...track.idVals };

                            // 更新关键帧信息
                            // 初始的关键帧列表,上面已经进行了删除。
                            // 由于入参只有修改/新增的关键帧，没有无变化的，因此以初始关键帧为准。
                            const trackShapeServerIDByFrame: Record<number, number> = {};
                            // 有更新/新增关键帧。
                            if (track.mergeTrackShapes && Object.keys(track.mergeTrackShapes).length) {
                                Object.entries(track.mergeTrackShapes).forEach(([frame, shape]) => {
                                    // 新增的关键帧回填id
                                    if (!shape.id && newTrackShapes[frame]) {
                                        const trackShapeId = newTrackShapes[frame];
                                        shape.id = trackShapeId;
                                        shape.trackShape.id = shape.id;

                                        trackShapeServerIDByFrame[+frame] = trackShapeId;
                                        // if (track.direction) {
                                        //     if (this.collection.objects[clientID].shapes[frame]) {
                                        //         this.collection.objects[clientID].shapes
                                        // [frame].serverID = shape.id;
                                        //     }
                                        // } else {
                                        //     if (this.collection.objects[clientID].shapes[frame]) {
                                        //         this.collection.objects[clientID].shapes[frame].
                                        // serverID = shape.id;

                                        //     }
                                        // }
                                    } else if (shape.id) {
                                        try {
                                            // 有id时，存在一种特殊情况，在initialObjects中不存在该帧，oldMergeTrackShapes[frame]可能为空
                                            // 取消关键帧保存后，initialObjects已经删除该帧，但撤销取消关键帧后，该帧id仍然存在。
                                            const { idVals: oldTrackShapeIdVals = {}, trackShape: oldTrackShape = {} } =
                                                oldMergeTrackShapes[+frame] || {};
                                            shape.idVals = { ...oldTrackShapeIdVals, ...shape.idVals };
                                            shape.trackShape = { ...oldTrackShape, ...shape.trackShape };
                                        } catch (error) {
                                            throw new ServerError(`修改连续帧框报错:${error},
                                            旧帧： ${JSON.stringify(oldMergeTrackShapes[+frame])}。
                                            新帧：${JSON.stringify(shape)}`);
                                        }
                                    } else {
                                        message.error(`新增连续帧框可能失败了：第${shape.frame}帧的${shape.clientID}`);
                                    }
                                });
                            }
                            this.collection.updateTrackServerID({
                                clientID: track.clientID,
                                objectType: ObjectType.TRACK,
                                direction: track.track.direction, // 有方向，代表是投影
                                trackShapeServerIDByFrame,
                            });
                            track.mergeTrackShapes = { ...oldMergeTrackShapes, ...track.mergeTrackShapes };
                        } catch (error) {
                            throw new ServerError(`修改连续帧报错:${error}`);
                        }
                        this.initialObjects[type][track.id] = track;
                    } else {
                        message.error(`新增连续帧可能失败了：${track.track.clientID}`);
                    }
                });
            }
        }
    }

    // 正式的保存
    async saveAction(onUpdateArg: Function) {
        this.saving = true;
        this.deleteTrackShape = {};
        this.deleteClientIDs = [];
        // // 更新和新增两种id

        const onUpdate =
            typeof onUpdateArg === 'function'
                ? onUpdateArg
                : (message: string) => {
                      console.log(message);
                  };
        onUpdate('正在将修改保存到服务器...');
        const exported = this.collection.export();

        let param;
        try {
            param = this._tParams(exported);
        } catch (error) {
            this.saving = false;
            throw new ScriptingError(`保存时转换对象错误:${error}`);
        }
        try {
            // const { shapes, tracks } = param;
            const savePromises = [];

            // if (shapes && (shapes.deletes.length || param.shapes.merges.length)) {
            //     savePromises.push(this.saveShape(shapes));
            // }

            // if (tracks && (tracks.deletes.length || tracks.merges.length)) {
            //     savePromises.push(this.saveTrack(tracks));
            // }

            const { delShapes, delTracks, delTrackShapes, mergeShapes, mergeTracks, jobId } = param;
            if (
                delShapes.length ||
                delTracks.length ||
                delTrackShapes.length ||
                Object.keys(mergeShapes).length ||
                Object.keys(mergeTracks).length
            ) {
                savePromises.push(
                    this.saveData({
                        delShapes,
                        delTracks,
                        delTrackShapes,
                        mergeShapes,
                        mergeTracks,
                        jobId,
                    }),
                );
            }
            const { delImages, mergeImages } = param;
            if (delImages.length || Object.keys(mergeImages).length) {
                savePromises.push(
                    this.saveImages({
                        delImages,
                        mergeImages,
                    }),
                );
            }

            await Promise.all(savePromises).catch((e) => {
                this.waitPromises = [];
                this.saving = false;
                throw new ServerError(`promise-${e?.message}`, 0);
            });

            this.hash = this._getHash();

            this.saving = false;
            // 执行完保存结束后，查看是否有等待保存的。有的话，按顺序一个个保存。
            // 静默保存没有阻塞操作，防止本身返回有可能的时间慢导致的后发先至以及和保存冲突。
            if (this.waitPromises && this.waitPromises.length) {
                const data = this.waitPromises.shift();
                if (data) {
                    await this.saveAction(data.param);
                }
            }
        } catch (error) {
            this.saving = false;
            console.error('保存时错误：', error);
            throw new ScriptingError(`保存时错误:${error}`);
        }
    }

    async save(onUpdateArg: Function) {
        console.time('保存耗时');
        // 是否正在保存
        if (new Date().valueOf() - this.savTime < 1000) {
            message.warn('两次保存之间间隔至少1秒');
        } else if (this.saving || (this.waitPromises && this.waitPromises.length)) {
            this.waitPromises.push({ param: onUpdateArg });
        } else {
            try {
                await this.saveAction(onUpdateArg);
                this.saving = false;
                this.savTime = new Date().valueOf();
            } catch (error) {
                this.saving = false;
                // this.savTime = new Date().valueOf();
                throw new ScriptingError(`${(error as Error).stack}`);
            }
        }
        console.timeEnd('保存耗时');
    }

    hasUnsavedChanges() {
        // console.log('初始hash：', this._getHash());
        // console.log('当前hash：', this.hash);
        return this._getHash() !== this.hash;
    }
}
export default AnnotationsSaver;
