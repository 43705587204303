// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useRef } from 'react';
import {
    UserOutlined,
    MailOutlined,
    LockOutlined,
    PhoneOutlined,
    BankOutlined,
    CodeOutlined,
} from '@ant-design/icons';
import Form, { RuleRender, RuleObject } from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';

import patterns from 'utils/validation-patterns';

import { UserAgreement } from 'reducers/interfaces';
import { useTranslation, getI18n } from 'react-i18next';
import { Checkbox, InputNumber, Modal, Radio, Steps, message } from 'antd';
import { getRegisterToken, sendVerificationCodeVActionsAsync } from 'actions/auth-actions';
import { useDispatch } from 'react-redux';
import ValidCodeInput from 'components/common/validCode';
import ImageValidCode from './imageValidCode';



export interface UserConfirmation {
    name: string;
    value: boolean;
}

export interface RegisterData {
    username?: string; // 用户名-true
    nickName: string; // 昵称-false
    password: string; // 密码-true
    password_again: string; // 确认密码-true

    verifyCode_: string; // 验证码-true

    email: string;  // 邮箱-true
    mobile: string; // 手机-true

    affiliation: string;  // 公司/学校-true

    info: string; // 其他 - false
    confirmations: UserConfirmation[];
}

interface Props {
    fetching: boolean;
    userAgreements: UserAgreement[];
    onSubmit(registerData: RegisterData): void;
}

const { t: tran } = getI18n();

export const validatePassword: RuleRender = (): RuleObject => ({
    validator(_: RuleObject, value: string): Promise<void> {
        if (!patterns.validatePasswordLength.pattern.test(value)) {
            return Promise.reject(new Error(tran(patterns.validatePasswordLength.interId)));
        }

        if (!patterns.passwordContainsNumericCharacters.pattern.test(value)) {
            return Promise.reject(new Error(tran(patterns.passwordContainsNumericCharacters.interId)));
        }

        if (!patterns.passwordContainsUpperCaseCharacter.pattern.test(value)) {
            return Promise.reject(new Error(tran(patterns.passwordContainsUpperCaseCharacter.interId)));
        }

        if (!patterns.passwordContainsLowerCaseCharacter.pattern.test(value)) {
            return Promise.reject(new Error(tran(patterns.passwordContainsLowerCaseCharacter.interId)));
        }

        return Promise.resolve();
    },
});

export const validateConfirmation: ((firstFieldName: string, t: any) => RuleRender) = (
    firstFieldName: string,
    t: any,
): RuleRender => ({ getFieldValue }): RuleObject => ({
    validator(_: RuleObject, value: string): Promise<void> {
        if (value && value !== getFieldValue(firstFieldName)) {
            return Promise.reject(new Error(t('login.valid_password')));
        }

        return Promise.resolve();
    },
});

const validateAgreement: ((userAgreements: UserAgreement[]) => RuleRender) = (
    userAgreements: UserAgreement[],
): RuleRender => () => ({
    validator(rule: any, value: boolean): Promise<void> {
        const [, name] = rule.field.split(':');
        const [agreement] = userAgreements
            .filter((userAgreement: UserAgreement): boolean => userAgreement.name === name);
        if (agreement.required && !value) {
            return Promise.reject(new Error(`You must accept ${agreement.displayText} to continue!`));
        }

        return Promise.resolve();
    },
});

// let maxCount = 60

function RegisterFormComponent(props: Props): JSX.Element {
    const { fetching, userAgreements, onSubmit } = props;
    // const [codeLoading, setCodeLoading] = useState(false);
    // const [countdown, setCountdown] = useState(0);
    // const timer = useRef<NodeJS.Timer>();
    // const [codeImageUrl, setCodeImageUrl] = useState('');
    const registerRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [formInstance] = Form.useForm();


    const showUesrNeedKnown = () => {
        const content = t('userNotice')
        Modal.info({
            title: t('register.userNotice.title'),
            content: <div style={{
                whiteSpace: 'pre-wrap',
                height: 'calc( 100vh - 400px )',
                minHeight: '400px',
                overflow: 'auto',
            }} >
                {content}
            </div >,
            width: '80%',
        })
    }


    async function validateUsername(_: RuleObject, value: string): Promise<void> {
        // console.log('验证了：', value);

        if (!patterns.validateUsernameLength.pattern.test(value)) {
            return Promise.reject(new Error(t(patterns.validateUsernameLength.interId)));
        }

        if (!patterns.validateUsernameCharacters.pattern.test(value)) {
            return Promise.reject(new Error(t(patterns.validateUsernameCharacters.interId)));
        }

        return Promise.resolve();
    }


    // const getImageVerifyCode = async () => {
    //     // setCodeLoading(true);
    //     // setCountdown(0);
    //     try {
    //         const codeVer = await dispatch(getRegisterToken()) as unknown as any;
    //         if (codeVer) {
    //             const url = URL.createObjectURL(codeVer);
    //             return url;
    //         }
    //     } catch (error) {
    //         console.error('错误信息：', error);
    //         // setCodeLoading(false);
    //     }
    // }

    const getEmailVerifyCode = async () => {
        // 验证邮箱正确输入
        await formInstance.validateFields(['email']);

        // let imageUrl: string = '';

        // console.log('挂载：', typeof registerRef.current);

        let code = '';
        Modal.confirm({
            // getContainer: document.getElementsByClassName('body')[0],
            content: <ImageValidCode
                autoComplete='verifyCode_'
                prefix={<CodeOutlined />}
                placeholder={t('login.imageVerifyCode')}
                maxLength={10}
                // onLoadCode={getVerifyCode}
                text={t('login.validcode.get')}
                onChange={e => {
                    code = e.target.value;
                }}
                dispatchEvent={dispatch}
            />,
            okText: t('base.send'),
            cancelText: t('base.close'),
            onOk: async () => {
                if (!code) {
                    message.info(t('login.imageCodeNeed'))
                }
                // 获取邮箱
                const email = formInstance.getFieldValue('email');
                if (email) {
                    try {
                        await dispatch(sendVerificationCodeVActionsAsync({ email, verifyCode_: code, type: 0 }));
                        message.success(t('login.sendEmailValidCodeSuccess'))
                        // 获取验证码
                        return Promise.resolve();
                        // URL.revokeObjectURL(imageUrl);
                    } catch (error) {
                        message.success(t('login.sendEmailValidCodeError'))
                    }
                    return Promise.reject('验证码验证失败！')
                }
                return Promise.reject('验证码验证失败！')
            },
            onCancel: () => {
                // URL.revokeObjectURL(imageUrl);
            }
        })
    }

    // const getVerifyCode = async () => {
    //     // setCodeLoading(true);
    //     // setCountdown(0);
    //     try {

    //         const url = await getImageVerifyCode();
    //         // setCodeLoading(false);
    //         if (url) {
    //             setCodeImageUrl(url);
    //         }
    //     } catch (error) {
    //         console.error('错误信息：', error);
    //         // setCodeLoading(false);
    //     }
    // }

    // useEffect(() => {
    //     // if (countdown === 0) {
    //     //     setCodeLoading(false);
    //     //     setCountdown(0);
    //     // }
    // }, [countdown])

    // useEffect(() => {
    //     return () => {
    //         if (codeImageUrl) {
    //             URL.revokeObjectURL(codeImageUrl);
    //         }
    //     }
    // }, [])

    return (
        <Form
            form={formInstance}
            onFinish={(values: Record<string, string | boolean>) => {
                const agreements = Object.keys(values)
                    .filter((key: string): boolean => key.startsWith('agreement:'));
                // const confirmations = agreements
                //     .map((key: string): UserConfirmation => ({ name: key.split(':')[1], value: (values[key] as boolean) }));
                const rest = Object.entries(values)
                    .filter((entry: (string | boolean)[]) => !agreements.includes(entry[0] as string));

                onSubmit({
                    ...(Object.fromEntries(rest) as any as RegisterData),
                    // confirmations,
                    username: values.email as string,
                });
            }}
            className='register-form'
        >
            <Form.Item
                hasFeedback
                name='email'
                rules={[
                    {
                        type: 'email',
                        message: t('login.valid_email'),
                    },
                    {
                        required: true,
                        message: t('login.placeholder_email'),
                    },
                ]}
            >
                <Input
                    autoComplete='email'
                    prefix={<MailOutlined />}
                    placeholder={t('login.email')}
                    maxLength={100}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                name='nickName'
            >
                <Input
                    prefix={<UserOutlined />}
                    placeholder={t('login.nickName')}
                    maxLength={30}
                />
            </Form.Item>
            <Form.Item
                hasFeedback
                name='password'
                rules={[
                    {
                        required: true,
                        message: t('login.empty_password'),
                    }, validatePassword,
                ]}
            >
                <Input.Password
                    autoComplete='new-password'
                    prefix={<LockOutlined />}
                    placeholder={t('login.password')}
                    maxLength={30}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                name='password_again'
                dependencies={['password']}
                rules={[
                    {
                        required: true,
                        message: t('login.confirm_empty_password'),
                    }, validateConfirmation('password', t),
                ]}
            >
                <Input.Password
                    autoComplete='new-password'
                    prefix={<LockOutlined />}
                    placeholder={t('login.confirm_password')}
                    maxLength={30}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                name='mobile'
                rules={[
                    {
                        type: 'number',
                        max: 99999999999,
                        min: 10000000000,
                        message: t('login.valid_mobile'),
                    },
                    {
                        required: true,
                        message: t('login.placeholder_mobile'),
                    },
                ]}
            >
                <InputNumber
                    style={{ width: '100%' }}
                    autoComplete='mobile'
                    controls={false}
                    prefix={<PhoneOutlined />}
                    placeholder={t('login.mobile')}
                    maxLength={14}
                // minLength={11}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                name='affiliation'
                rules={[
                    {
                        required: true,
                        message: t('login.placeholder_affiliation'),
                    },
                ]}
            >
                <Input
                    style={{ width: '100%' }}
                    autoComplete='affiliation'
                    prefix={<BankOutlined />}
                    placeholder={t('login.affiliation')}
                    maxLength={100}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                name='info'
            >
                <Input.TextArea
                    autoComplete='info'
                    // prefix={<MailOutlined />}
                    placeholder={t('login.info')}
                    maxLength={300}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                name='verifyCodeEmail_'
                rules={[
                    {
                        required: true,
                        message: t('login.emailVerifyCode_placeholder'),
                    },
                ]}
            >
                <ValidCodeInput
                    autoComplete='verifyCodeEmail_'
                    prefix={<CodeOutlined />}
                    placeholder={t('login.emailVerifyCode')}
                    maxLength={10}
                    onLoadCode={getEmailVerifyCode}
                    text={t('login.validcode.get')}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                name='verifyCode_'
                rules={[
                    {
                        required: true,
                        message: t('login.imageVerifyCode_placeholder'),
                    },
                ]}
            >
                {/* <ValidCodeInput
                    autoComplete='verifyCode_'
                    prefix={<CodeOutlined />}
                    placeholder={t('login.imageVerifyCode')}
                    maxLength={10}
                    onLoadCode={getVerifyCode}
                    text={t('login.validcode.get')}
                /> */}
                <ImageValidCode
                    autoComplete='verifyCode_'
                    prefix={<CodeOutlined />}
                    placeholder={t('login.imageVerifyCode')}
                    maxLength={10}
                    // onLoadCode={getVerifyCode}
                    dispatchEvent={dispatch}
                    text={t('login.validcode.get')}
                />
            </Form.Item>

            {/* {codeImageUrl && <img
                src={codeImageUrl}
                onClick={getVerifyCode}
            />} */}
            <Form.Item
                name='userNeedKnown'
                valuePropName="checked"
                rules={[
                    // {
                    //     required: true,
                    //     message: '请查看并同意《用户需知》',
                    // },
                    {
                        validator(rule, value, callback) {
                            if (value !== true) {
                                return Promise.reject(new Error(t('register.userNoticeTip')))
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <Checkbox>
                    <div onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>{t('register.confirmUserNotice')}
                        <Button type='link' onClick={showUesrNeedKnown}>{t('register.userNotice')}</Button>
                    </div>
                </Checkbox>
            </Form.Item>

            <Form.Item>
                <Button
                    type='primary'
                    htmlType='submit'
                    className='register-form-button'
                    loading={fetching}
                    disabled={fetching}
                >
                    {t('login.register')}
                </Button>
            </Form.Item>

            <div ref={registerRef}></div>
        </Form>
    );
}

export default React.memo(RegisterFormComponent);
