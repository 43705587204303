/*
 * @Author: swxy
 * @Date: 2023-07-01 10:14:43
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { FC, useEffect } from 'react';
import { Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState, SubElementInfo, ShapeType, SubElement, Workspace, SubElementType, SubElementVersion } from 'reducers/interfaces';
import { drawSubPoint as drawSubPointAction, updateAnnotationsAsync } from 'actions/annotation-actions';
import { updateSubAnnotationsAsync } from 'actions/annotationSub-actions';
import SuspensionElement from './suspensionElement';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { ProjectDumpType, ProjectType, ShapeObjFrType } from 'utils/ConstType';
// import getHelper from 'business/objects/customs/helper';

import '../styles.scss';
import { Label } from 'business/objects/label';
import Job from 'business/objects/job';
import { Canvas3d } from 'canvas3d';

interface Props {
    activatedStateID?: number | null;
    states: any[];
}
// const cvat = getCore();

const numPointsOfShapeObjFrType: Record<number, number> = {
    [ShapeObjFrType.car]: 4,
    [ShapeObjFrType.truck]: 4,
    [ShapeObjFrType.bus]: 4,
    [ShapeObjFrType.pickTruck]: 4,
    [ShapeObjFrType.engineVehicle]: 4,
    [ShapeObjFrType.tricycle]: 3,
    [ShapeObjFrType.bicycle]: 2,
    [ShapeObjFrType.motorcycle]: 2,
    17: 4,  // 未知按4个车轮点算
}


const getNumOfPointsByShapeObjFrType = (label: any) => {
    if (label) {
        const attribute = label.attributes.find((attr: any) => attr.name === 'shapeObjFrType');
        if (attribute) {
            const value = +attribute.defaultValue
            if (value >= 0) {
                return numPointsOfShapeObjFrType[value];
            }
        }
    }
    return 0;
}

const geSubElementType = (isLane: boolean, label?: any) => {
    if (isLane && label) {
        const attribute = label.attributes.find((attr: any) => attr.name === 'shapeObjFrType');
        if (attribute) {
            const value = +attribute.defaultValue
            if (value >= ShapeObjFrType.lane) {
                return SubElementType.dashedLine;
            }

        }
    }
    return SubElementType.wheelPoint;
}

const isDashLine = (job: Job, label: Label, attributes: Record<number, string>) => {
    let isDash = false;
    // 2D车道线
    if (
        job.projectType === ProjectType.two &&
        job.project.projectDumpType === ProjectDumpType.lane &&
        label &&
        label.attributes &&
        attributes
    ) {
        label.attributes.forEach((attr) => {
            if (attr.name === 'type') {
                // 固定类型，判断是否为行人
                if (
                    [
                        'single_dash',
                        'fat_dash',
                        'double_dash',
                        'left_dash_right_solid',
                        'right_dash_left_solid',
                    ].includes(attributes[attr.id])
                ) {
                    isDash = true;
                }
            }
        });
    }
    return isDash;
}

const Suspension: FC<Props> = ({ activatedStateID, states = [] }) => {
    const dispatch = useDispatch();

    const { drawSubPoints, workSpace, jobInstance, keyMap } = useSelector((state: CombinedState) => ({
        drawSubPoints: state.annotation.annotations.drawSubPoints,
        workSpace: state.annotation.workspace,
        jobInstance: state.annotation.job.instance,
        keyMap: state.shortcuts.keyMap,
    }));

    const canvasInstance = useSelector((state: CombinedState) =>
        jobInstance.projectType === ProjectType.three
            // && jobInstance.projectDumpType === ProjectDumpType.lane
            ? state.annotation.canvas.instance as Canvas3d : undefined);

    // console.log('这里吗：', canvasInstance)
    if (!canvasInstance) {
        return <></>;
    }

    const onResize = async (event: Event) => {
        const { detail: {
            points,
            clientID,
            // duration,
            // shapeType,
            // subType,
        } } = event as CustomEvent;

        const activatedState = states.find((state) => state.clientID === clientID);

        const subElement: SubElement = JSON.parse(JSON.stringify(activatedState.elements[0]));

        // if (subType === SubElementType.dashedLine) {
        if (points.length % 6 !== 0) {
            message.error('虚线点必须同时拥有起始点和结束点(点的数量是偶数)');
            return
        }

        if (Array.isArray(points)) {
            points.forEach((num: number, index: number) => {
                const subscript = Math.floor(index / 3);
                if (index % 3 === 0) {
                    subElement.points[subscript].x = num;
                } else if (index % 3 === 1) {
                    subElement.points[subscript].y = num;
                } else {
                    subElement.points[subscript].z = num;
                    subElement.points[subscript] = {
                        ...subElement.points[subscript],
                    };
                }
            });
        }

        // 重新计算，点在线上
        // console.log('当前信息：', points);
        // return
        // } else {
        //     message.error('没有找到该类型的子元素！')
        // }

        activatedState.elements = [subElement];

        // (canvasInstance as Canvas).cancel();
        // await dispatch(drawSubPointAction())

        if (activatedState.direction) {
            // if (clientID) {
            //     dispatch(activateSubObject(clientID))
            // }
            dispatch(updateSubAnnotationsAsync([activatedState]));
        } else {
            // if (clientID) {
            //     dispatch(activateObject(clientID, null))
            // }
            dispatch(updateAnnotationsAsync([activatedState]));
        }

    }

    const onComplete = (event: Event) => {
        const { detail: {
            points,
            // clientID,
            // duration,
            // shapeType,
            subType,
        } } = event as CustomEvent;


        const activatedState = states.find((state) => state.clientID === drawSubPoints?.clientID);

        if (activatedState) {
            const subElement: SubElement = {
                shapeType: subType,
                numOfPoints: Math.floor(points.length / 3),
                points: [],
                version: SubElementVersion.version1_0_0,
            };

            if (subType === SubElementType.dashedLine) {
                if (points.length % 6 !== 0) {
                    message.error('虚线点必须同时拥有起始点和结束点(点的数量是偶数)');
                    return
                }

                if (Array.isArray(points)) {
                    const sub: SubElementInfo = {
                        x: -100,
                        y: -100,
                        z: -100,
                        pointType: 'start',
                        occluded: false,
                    }
                    points.forEach((num: number, index: number) => {
                        const subscript = Math.floor(index / 3);
                        if (index % 3 === 0) {
                            sub.x = num;
                        } else if (index % 3 === 1) {
                            sub.y = num;
                        } else {
                            sub.z = num;
                            subElement.points[subscript] = {
                                ...sub,
                                pointType: subscript % 2 === 0 ? 'start' : 'end',
                            };

                            subElement.numOfPoints = subscript;
                        }
                    });
                }
                // 重新计算，点在线上
                // console.log('当前信息：', points);
                // return
            } else {
                message.error('没有找到该类型的子元素！')
            }


            activatedState.elements = [subElement];

            // canvasInstance.cancel();
            dispatch(drawSubPointAction())
            if (activatedState.direction) {
                // if (clientID) {
                //     dispatch(activateSubObject(clientID))
                // }
                dispatch(updateSubAnnotationsAsync([activatedState]));
            } else {
                // if (clientID) {
                //     dispatch(activateObject(clientID, null))
                // }

                dispatch(updateAnnotationsAsync([activatedState]));
            }
        }
        canvasInstance.cancel();
    }

    useEffect(() => {
        const canvasElement = canvasInstance?.html().perspective;
        if (canvasElement) {
            // canvasElement?.addEventListener('canvas.subDrawn', onComplete);
            // canvasElement?.addEventListener('canvas.sub.edited', onResize);
        }
        return () => {
            if (canvasElement) {
                // canvasElement?.removeEventListener('canvas.subDrawn', onComplete);
                // canvasElement?.removeEventListener('canvas.sub.edited', onResize);
            }
        }
    }, [states]);


    if (!activatedStateID && !drawSubPoints) {
        // 没有选中目标物  或者  没有在绘制中时， 不展示当前页面
        return <></>;
    }

    const activatedState = states.find((state) => state.clientID === activatedStateID);

    if (activatedState?.shapeType !== ShapeType.laneline && !drawSubPoints) {
        return <></>;
    }

    if (activatedState?.elements?.length) {
        // console.log('当前子对象：', activatedState?.elements)
        const readonly = workSpace === Workspace.REVIEW_WORKSPACE || activatedState.lock;
        return <SuspensionElement objectState={activatedState} readonly={readonly} />;
    } else if (workSpace === Workspace.REVIEW_WORKSPACE) {
        return <></>;
    }


    const isDrawSubPoints = !!drawSubPoints;
    const subElementType = SubElementType.dashedLine;

    const onClick = () => {

        (canvasInstance as Canvas3d).cancel();
        if (isDrawSubPoints) {
            dispatch(drawSubPointAction());
            return;
        }

        if (subElementType === SubElementType.dashedLine) {
            // 虚线时，判断是否是虚线属性
            // const helper = getHelper();
            // if (!isDashLine(jobInstance, activatedState?.label, activatedState?.attributes)) {
            //     message.error('该对象属性并不是虚线！');
            //     return;
            // }
        }

        canvasInstance.draw({
            enabled: true,
            shapeType: ShapeType.laneline,
            type: 'subObject',
            // initialState: activatedState,
        });
        dispatch(drawSubPointAction({
            clientID: activatedStateID as number,
            subElementType,
        }));
    }

    // if (activatedState?.elements?.length) {
    //     const readonly = workSpace === Workspace.STANDARD3D || activatedState.lock;
    //     return <SuspensionElement objectState={activatedState} readonly={readonly} addSubPoint={onClick} />;
    // } else if (workSpace === Workspace.REVIEW_WORKSPACE) {
    //     return <></>;
    // }


    const onPointsComplex = () => {
        const drawState = states.find((state) => state.clientID === activatedStateID);
        canvasInstance.draw({
            enabled: false,
            type: 'subObject',
            shapeType: ShapeType.laneline,
            initialState: drawState,
        });
        dispatch(drawSubPointAction());
        canvasInstance.cancel();
    }

    const subKeyMap = {
        add_sub_element: keyMap.add_sub_element,
    };

    const handlers = {
        add_sub_element: (event: KeyboardEvent | undefined) => {
            if (event) {

                isDrawSubPoints ? onPointsComplex() : onClick();
            }
        },
    };

    return (
        <div className='aatp_suspension3D'>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <div className='aatp_suspension_row'>
                <div className='aatp_suspension_row_title'>
                    {'添加目标物虚线点:'}
                </div>
                <div className='aatp_suspension_row_content'>
                    <Button onClick={onClick}>{isDrawSubPoints ? '取消' : '添加 [J]'}</Button>
                </div>
            </div>

            {isDrawSubPoints && <div className='aatp_suspension_row_center'>
                <Button onClick={onPointsComplex}>完成 [J]</Button>
            </div>}
        </div>
    )
}

export default Suspension;