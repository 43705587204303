/*
 * @Author: swxy
 * @Date: 2023-08-26 09:46:43
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { post } from 'utils/request';

export const queryTasksInfo = async (...data: any) => {
    const result = await post('/api/engineTask/selectRelative', ...data);
    return result;
};

export const queryTaskInfo = async (...data: any) => {
    const result = await post('/api/engineTask/selectDetailByPrimary', ...data);
    return result;
};

export const api_queryTaskInfo = async (...data: any) => {
    const result = await post('/api/engineTask/selectDetailByPrimary', ...data);
    return result;
};
