/*
 * @Author: swxy
 * @Date: 2023-09-09 11:18:52
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { Tooltip } from "antd";
import FrameData from "business/amygo/AmygoFrameMeta";
import ObjectState from "business/objects/objectState";
import { Canvas3d } from "canvas3d";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CombinedState, ShapeType } from "reducers/interfaces";
import { Box3, Euler, Matrix4, Quaternion, Vector3 } from "three";
import { getMatrixFromObjectState } from "utils/utils";

interface Props {
    frameData: FrameData;
    frame: number;
    canvasInstance: Canvas3d;
    objectState?: ObjectState;
    states?: ObjectState[];
}


const CanvasFooterComponent: FC<Props> = ({ frameData, objectState, frame, canvasInstance, states = [] }) => {
    const [pointCount, setPointCount] = useState(0);
    const [scale, setScale] = useState<{ x: number; y: number; z: number }>();
    const { t } = useTranslation();

    const pointCountFramePcd = async (data: FrameData, object?: ObjectState) => {
        if (!object || object.shapeType !== ShapeType.CUBOID) {
            setPointCount(0);
        } else {
            const data = await frameData.data();
            if (data?.pcds.length) {
                const points = object.points;
                const pcd = data.pcds[0];
                // const position = new Vector3(points[0], points[1], points[2]);
                // const quaternion = new Quaternion().setFromEuler(new Euler(points[3], points[4], points[5]));
                // const scale = new Vector3(points[6], points[7], points[8]);
                // const objectMatrix = new Matrix4().compose(position, quaternion, scale);
                const vecs = pcd.containPointsFromPoints(points);
                setPointCount(vecs.length);
            }
        }
    }

    const updatePosition = (e: Event) => {
        const { scale: newScale } = (e as CustomEvent).detail;
        setScale({
            ...newScale,
        })
    }

    useEffect(() => {
        if (frameData.frameIndex === frame) {
            pointCountFramePcd(frameData, objectState);
        }
        if (objectState) {
            const [, , , , , , length, width, height] = objectState.points;
            setScale({
                x: length,
                y: width,
                z: height,
            })
        } else {
            setScale(undefined)
        }
    }, [objectState]);

    useEffect(() => {
        const perspective = canvasInstance.html().perspective;
        if (perspective) {
            perspective.addEventListener('canvas.position.update', updatePosition);
        }
        return () => {
            perspective.removeEventListener('canvas.position.update', updatePosition);
        }
    }, []);


    if (!objectState) {
        return <></>
    }

    let isParentIntersection = false;

    if (objectState.parentID && states?.length) {
        const parentState = states.find(o => o.clientID === objectState.parentID);
        if (parentState) {
            const targetMatrix = getMatrixFromObjectState(objectState);
            const parentMatrix = getMatrixFromObjectState(objectState);

            const targetBox3 = new Box3().applyMatrix4(targetMatrix);
            const parentBox3 = new Box3().applyMatrix4(parentMatrix);

            // 两个目标物是否交汇
            isParentIntersection = targetBox3.intersectsBox(parentBox3);
        }
    }

    if (objectState.shapeType === ShapeType.CUBOID) {
        return <div>
            {objectState.parentID && <Tooltip title={t('workspace.footer.intersection_tip')}>
                <span>{t('workspace.footer.intersection')}</span>
            </Tooltip>}

            &nbsp;&nbsp;&nbsp;

            {t('workspace.content.view.object_lenght')}
            {scale?.x?.toFixed(2)}
            &nbsp;
            {t('workspace.content.view.object_width')}
            {scale?.y?.toFixed(2)}
            &nbsp;
            {t('workspace.content.view.object_height')}
            {scale?.z?.toFixed(2)}
            &nbsp;&nbsp;
            {t('workspace.content.view.object_cloud_point')}
            {pointCount || '--'}
        </div>;
    }

    if (objectState.shapeType === ShapeType.laneline) {
        const [firstX] = objectState.points;
        const lastX = objectState.points[objectState.points.length - 3];
        return <div>
            {t('workspace.content.view.object_lenght')}
            {Math.abs(firstX - lastX)?.toFixed(2)}
        </div>;
    }

    return <>
    </>
}

export default CanvasFooterComponent;