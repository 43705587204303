/*
 * @Author: swxy
 * @Date: 2023-11-14 15:16:35
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

interface ImageProps {
    data: Blob;
    name: string;
}

class AmygoImageModel {
    public name: string;
    public data?: ImageBitmap;

    public width: number = 0;
    public height: number = 0;

    public original: Blob;

    constructor({ data, name }: ImageProps) {
        // this.width = data.width;
        // this.height = data.height;
        this.name = name;
        this.original = data;
    }

    public get size() {
        let size = 0;
        if (this.data) {
            size = this.data.width * this.data.height * 4;
        }
        return this.original.size + size;
    }

    public get isAnalyze() {
        return !!this.data;
    }

    public import(image: { data: ImageBitmap }) {
        this.data = image.data;
        this.width = image.data.width;
        this.height = image.data.height;
    }

    public static async blobToImageBitmap(
        blob: Blob,
        name?: string,
    ): Promise<{
        data: ImageBitmap;
        name?: string;
    }> {
        const image = await createImageBitmap(blob);
        return {
            data: image,
            name,
        };
    }

    public clear() {
        this.data?.close();
        this.data = undefined;
    }

    public destroy() {
        this.data?.close();
        this.data = undefined;
    }
}

export default AmygoImageModel;
