// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import { Col } from 'antd/lib/grid';
// import Icon from '@ant-design/icons';
import Icon, {
    EyeOutlined,
    EyeInvisibleOutlined,
    FullscreenOutlined,
    FullscreenExitOutlined,
    InfoCircleOutlined,
    FilterOutlined,
    ProfileOutlined,
    ShakeOutlined,
    SendOutlined,
    SettingOutlined
} from '@ant-design/icons';
// import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import Tooltip from 'antd/lib/tooltip';
import Moment from 'react-moment';

import moment from 'moment';
import { useSelector } from 'react-redux';

import {
    // FilterIcon, FullscreenIcon, InfoIcon,
    BrainIcon,
} from 'icons';
import {
    CombinedState,
    // DimensionType,
    // Workspace,
    PredictorState,
    ActivationModel,
} from 'reducers/interfaces';
import MoreDropDown from './more-dropdown';
import { useTranslation } from 'react-i18next';

interface Props {
    // workspace: Workspace;
    predictor: PredictorState;
    isTrainingActive: boolean;
    activeModel: ActivationModel;
    canvasInstance: any;
    sidebarCollapsed: boolean;
    showStatistics(): void;
    switchPredictor(predictorEnabled: boolean): void;
    showFilters(): void;
    // changeWorkspace(workspace: Workspace): void;
    collapseSidebar(): void;
    changeActiveModel(activeModel: ActivationModel): void;
    showSetting(): void;

    jobInstance: any;
}

function RightGroup(props: Props): JSX.Element {
    const {
        // canvasInstance,
        sidebarCollapsed,
        activeModel,
        predictor,
        isTrainingActive,
        // workspace,
        // predictor,
        // jobInstance,
        // isTrainingActive,
        showStatistics,
        // changeWorkspace,
        switchPredictor,
        showFilters,
        collapseSidebar,
        changeActiveModel,
        showSetting,
    } = props;
    // const [showAttribute, setShowAttribute] = useState(canvasInstance ? canvasInstance.showAttribute : true);
    const [isFullscreen, setFullScreen] = useState(false);
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(sidebarCollapsed);

    const { t } = useTranslation();

    const annotationAmount = predictor.annotationAmount || 0;
    const mediaAmount = predictor.mediaAmount || 0;
    const formattedScore = `${(predictor.projectScore * 100).toFixed(0)}%`;
    const predictorTooltip = (
        <div className='cvat-predictor-tooltip'>
            <span>Adaptive auto annotation is</span>
            {predictor.enabled ? (
                <Text type='success' strong>
                    {' active'}
                </Text>
            ) : (
                <Text type='warning' strong>
                    {' inactive'}
                </Text>
            )}
            <br />
            <span>
                Annotations amount:
                {annotationAmount}
            </span>
            <br />
            <span>
                Media amount:
                {mediaAmount}
            </span>
            <br />
            {annotationAmount > 0 ? (
                <span>
                    Model mAP is {formattedScore}
                    <br />
                </span>
            ) : null}
            {predictor.error ? (
                <Text type='danger'>
                    {predictor.error.toString()}
                    <br />
                </Text>
            ) : null}
            {predictor.message ? (
                <span>
                    Status: {predictor.message}
                    <br />
                </span>
            ) : null}
            {predictor.timeRemaining > 0 ? (
                <span>
                    Time Remaining:{' '}
                    <Moment date={moment().add(-predictor.timeRemaining, 's')} format='hh:mm:ss' trim durationFromNow />
                    <br />
                </span>
            ) : null}
            {predictor.progress > 0 ? (
                <span>
                    Progress:
                    {predictor.progress.toFixed(1)} %
                </span>
            ) : null}
        </div>
    );

    let predictorClassName = 'cvat-annotation-header-button cvat-predictor-button';
    if (!!predictor.error || !predictor.projectScore) {
        predictorClassName += ' cvat-predictor-disabled';
    } else if (predictor.enabled) {
        if (predictor.fetching) {
            predictorClassName += ' cvat-predictor-fetching';
        }
        predictorClassName += ' cvat-predictor-inprogress';
    }

    // const filters = useSelector((state: CombinedState) => state.annotation.annotations.filters);

    // const leftPanelHelpMessage = isSidebarCollapsed ? 'show left panle' : 'hide left panle';
    // const showAttributeHelpMessage = showAttribute ? 'hide attribute in canvas' : 'show attribute in canvas';
    // const fullscreen = isFullscreen ? 'exit Fullscreen' : 'Fullscreen';
    // const leftPanelHelpMessage = '展示/隐藏面板';
    // const showAttributeHelpMessage = '在标注画面中展示/隐藏属性';
    // const activeModelHelpMessage =
    //     activeModel === ActivationModel.clickActive ? '鼠标移动激活对象' : '鼠标点击激活对象';
    // const fullscreen = isFullscreen ? '退出全屏' : '全屏';
    const leftPanelHelpMessage = t('workspace.top.panle_m');
    // const showAttributeHelpMessage = t('workspace.top.attribute_m');
    const activeModelHelpMessage =
        activeModel === ActivationModel.clickActive ? t('workspace.top.active_move') : t('workspace.top.active_click');
    const fullscreen = isFullscreen ? t('workspace.top.fullscreen_exit') : t('workspace.top.fullscreen');

    return (
        <Col className='cvat-annotation-header-right-group'>
            {/* {isTrainingActive && (
                <Button
                    type='link'
                    className={predictorClassName}
                    onClick={() => {
                        switchPredictor(!predictor.enabled);
                    }}
                >
                    <Tooltip title={predictorTooltip}>
                        <Icon component={BrainIcon} />
                    </Tooltip>
                    {annotationAmount ? `mAP ${formattedScore}` : 'not trained'}
                </Button>
            )} */}

            <Tooltip title={leftPanelHelpMessage}>
                <Button
                    type='link'
                    className={`cvat-annotation-header-button${!isSidebarCollapsed ? ' panel_sidebar_hidden' : ''}`}
                    onClick={(): void => {
                        if (collapseSidebar) {
                            setIsSidebarCollapsed(!isSidebarCollapsed);
                            collapseSidebar();
                        }
                    }}
                >
                    <ProfileOutlined />
                    {/* ShowAttribute */}
                </Button>
            </Tooltip>
            <Tooltip title={activeModelHelpMessage}>
                <Button
                    type='link'
                    className='cvat-annotation-header-button'
                    onClick={(): void => {
                        changeActiveModel(
                            activeModel === ActivationModel.clickActive
                                ? ActivationModel.moveInActive
                                : ActivationModel.clickActive,
                        );
                        // if (canvasInstance) {
                        //     setShowAttribute(!showAttribute);
                        //     canvasInstance.isShowAttribute(!canvasInstance.showAttribute);
                        // }
                    }}
                >
                    {activeModel === ActivationModel.clickActive ? <ShakeOutlined /> : <SendOutlined />}
                    {/* ShowAttribute */}
                </Button>
            </Tooltip>
            {/* <Tooltip title={showAttributeHelpMessage}>
                <Button
                    type='link'
                    className='cvat-annotation-header-button'
                    onClick={(): void => {
                        if (canvasInstance) {
                            setShowAttribute(!showAttribute);
                            canvasInstance.isShowAttribute(!canvasInstance.showAttribute);
                        }
                    }}
                >
                    {showAttribute ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                </Button>
            </Tooltip> */}
            <Tooltip title={fullscreen}>
                <Button
                    type='link'
                    className='cvat-annotation-header-button'
                    onClick={(): void => {
                        setFullScreen(!isFullscreen);
                        if (window.document.fullscreenEnabled) {
                            if (window.document.fullscreenElement) {
                                window.document.exitFullscreen();
                            } else {
                                window.document.documentElement.requestFullscreen();
                            }
                        }
                    }}
                >
                    {isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
                    {/* <Icon component={FullscreenIcon} />
                Fullscreen */}
                </Button>
            </Tooltip>
            {/* <Tooltip title='标注信息'> */}
            <Tooltip title={t('workspace.top.annotation_info')}>
                <Button type='link' className='cvat-annotation-header-button' onClick={showStatistics}>
                    <InfoCircleOutlined />
                </Button>
            </Tooltip>
            <Tooltip title={t('setting.workspace.setting')}>
                <Button type='link' className='cvat-annotation-header-button' onClick={showSetting}>
                    <SettingOutlined />
                </Button>
            </Tooltip>

            {/* <Tooltip title='筛选标注对象'> */}
            {/* <Tooltip title={t('workspace.top.annotation_object_filter')}>
                <Button
                    type='link'
                    className={`cvat-annotation-header-button ${filters.length ? 'filters-armed' : ''}`}
                    onClick={showFilters}
                >
                    <FilterOutlined />
                </Button>
            </Tooltip> */}
            {/* <div>
                <Select
                    dropdownClassName='cvat-workspace-selector-dropdown'
                    className='cvat-workspace-selector'
                    onChange={changeWorkspace}
                    value={workspace}
                >
                    {Object.values(Workspace).map((ws) => {
                        if (jobInstance.dimension === DimensionType.DIM_3D) {
                            if (ws === Workspace.STANDARD) {
                                return null;
                            }
                            return (
                                <Select.Option disabled={ws !== Workspace.STANDARD3D} key={ws} value={ws}>
                                    {ws}
                                </Select.Option>
                            );
                        }
                        if (ws !== Workspace.STANDARD3D) {
                            return (
                                <Select.Option key={ws} value={ws}>
                                    {ws}
                                </Select.Option>
                            );
                        }
                        return null;
                    })}
                </Select>
            </div> */}
            <MoreDropDown />
        </Col>
    );
}

export default React.memo(RightGroup);
