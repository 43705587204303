/*
 * @Author: swxy
 * @Date: 2022-10-19 18:06:21
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

export default {
    'error.initialized': '当前帧-{{frame}}:点云已初始化',
    'error.noPointCloud': '当前帧-{{frame}}:点云路径不存在',
    'error.drawAlready': '当前在绘画中',
    'error.busyError': '当前画布正忙。 正在执行: {{mode}}',
};
