import {
    Vector3,
    Mesh,
    CubicBezierCurve3,
    Line,
    BufferAttribute,
    BufferGeometry,
    LineBasicMaterial,
    ColorRepresentation,
    MeshBasicMaterial,
} from 'three';
import LanePoint, { PointType } from './point';

const ARC_SEGMENTS = 200;

/**
 * 线段
 */
class Segment extends Line {
    private _start: Vector3;
    private _stop: Vector3;
    // private _locus: Vector3[] = [];
    private _controlPointColor: ColorRepresentation = 'green';
    private _color: ColorRepresentation = 'red';
    private _opacity: number = 20;
    private _radius: number = 0.2;

    public startMesh: Mesh;
    public stopMesh: Mesh;

    public clientID: number;
    public index: number;

    // public readonly bezier: CubicBezierCurve3;
    /**
     * 贝塞尔曲线计算对象
     */
    public readonly locusMeshs: Mesh[] = [];

    // public line: Line;

    // public get locus(): Vector3[] {
    //     return this.getLanePoint();
    // }

    constructor(
        clientID: number,
        start: Vector3,
        stop: Vector3,
        index: number,
        // locus: Vector3[] = [],
        color: ColorRepresentation = 'red',
        controlPointColor: ColorRepresentation = 'yellow',
        opacity: number = 100,
    ) {
        const geometry = new BufferGeometry();
        geometry.setAttribute(
            'position',
            new BufferAttribute(new Float32Array([start.x, start.y, start.z, stop.x, stop.y, stop.z]), 3),
        );
        // this.line = new Line(
        //     geometry,
        //     new LineBasicMaterial({
        //         color: this._color,
        //         opacity: this._opacity / 100,
        //     }),
        // );

        const material = new LineBasicMaterial({
            color: color,
            opacity: opacity / 100,
        });

        super(geometry, material);

        this.clientID = clientID;
        this._start = start;
        this._stop = stop;

        this.index = index;

        this._color = color;
        this._controlPointColor = controlPointColor;
        this._opacity = opacity;
    }

    public update(
        data?: { start?: Vector3; stop?: Vector3 },
        color?: {
            color: ColorRepresentation;
            controlPointColor: ColorRepresentation;
        },
    ) {
        if (data) {
            const { start, stop } = data;
            if (start && !start.equals(this.startMesh.position)) {
                // this.bezier.v0.set(start.x, start.y, start.z);
                this._start = start;
                this.startMesh.position.set(this._start.x, this._start.y, this._start.z);
            } else if (stop && !stop.equals(this.stopMesh.position)) {
                // this.bezier.v3.set(stop.x, stop.y, stop.z);
                this._stop = stop;
                this.stopMesh.position.set(this._stop.x, this._stop.y, this._stop.z);
            }

            const position = this.geometry.attributes.position;
            position.array.set(
                [this.startMesh.position, this.stopMesh.position].flatMap((pos) => [pos.x, pos.y, pos.z]),
            );
            position.needsUpdate = true;
            this.geometry.computeBoundingSphere();
            this.matrixWorldNeedsUpdate = true;
        }

        if (color) {
            if (color.color && this._color !== color.color) {
                this._color = color.color;
                (this.material as LineBasicMaterial).color.set(this._color);
            } else if (color.controlPointColor && this._controlPointColor !== color.controlPointColor) {
                this._controlPointColor = color.controlPointColor;
                this.locusMeshs.forEach((mesh, index) => {
                    (mesh.material as MeshBasicMaterial).color.set(this._controlPointColor);
                });
            }
        }
    }

    public active() {
        // this.segments.forEach((segment) => {
        //     segment.locusMeshs.forEach((mesh) => {
        //         mesh.visible = this.locusVisible;
        //     });
        // });
    }

    public unActive() {}
}

export default Segment;
