/*
 * @Author: swxy
 * @Date: 2023-07-01 10:14:43
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { FC, useState } from 'react';
import { Button, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";
import '../styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState, ObjectType, Workspace } from 'reducers/interfaces';
import { removeSubObjectAsync, createSubAnnotationsAsync, activateObject, associatedAnnotationsAsync } from 'actions/annotationSub-actions';
import getCore from 'cvat-core-wrapper';
import ObjectState from 'business/objects/objectState';


interface Props {
    activatedStateID?: number | null;
    states: any[];
}
// const cvat = getCore();

const Associated: FC<Props> = ({ activatedStateID, states = [] }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();

    const [objectStates, setObjectStates] = useState<any[]>([]);
    // const states3D = useSelector((state: CombinedState) => state.annotation.annotations.states);
    // const jobInstance = useSelector((state: CombinedState) => state.annotation.job.instance);
    const {
        workspace,
        jobInstance,
        perspective,
        frame,
    } = useSelector((state: CombinedState) => ({
        states3D: state.annotation.annotations.states,
        jobInstance: state.annotation.job.instance,
        perspective: state.subAnnotation.perspective,
        frame: state.annotation.player.frame.number,
        workspace: state.annotation.workspace,
    }));

    if (!activatedStateID) {
        return <></>
    }

    const activatedState = states.find((state) => state.clientID === activatedStateID);
    // if (!activatedState || activatedState?.parentID) {
    if (!activatedState) {
        return <></>
    }

    const onShowAssociateEableObjects = async () => {

        setObjectStates([]);
    }

    const onAssociated = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // 所有连续帧
        const objects3D = await jobInstance.annotations.get(frame, true, []);

        // let selectClientID: number | null = null;

        // 所有连续帧
        const objects2D = await jobInstance.annotations.sub_get(frame, true, []);
        const hasParentClientIDs = objects2D.reduce((previous: string[], state: any) => {
            if (state.parentID) {
                previous.push(`${state.clientID}_${state.direction}`);
            }
            return previous;
        }, []);


        const options1: { label: string, value: number }[] = objects3D.reduce((previous: { label: string, value: number }[], item: any) => {
            // 已关联/标签类型不同/在当前视角已存在/标签类型不同
            if (!item.serverID
                || item.objectType !== activatedState.objectType
                || activatedState.label.parentLabelId !== item.label.id
                || hasParentClientIDs.includes(`${item.clientID}_${perspective}`)
            ) {
                return previous;
            }

            let type = t('workspace.shape');
            if (item.objectType === ObjectType.TAG) {
                type = t('workspace.tag');
            }
            if (item.objectType === ObjectType.TRACK) {
                type = t('workspace.track');
            }
            previous.push({
                label: `${type}-${item.clientID}`,
                value: item.clientID,
            })
            return previous;
        }, []);

        const options2: { label: string, value: number }[] = objects2D.reduce((previous: { label: string, value: number }[], item: any) => {
            // 已关联/标签类型不同/在当前视角已存在/标签类型不同/3D待关联中已存在/当前列表已存在
            if (!item.parentID
                || item.objectType !== activatedState.objectType
                || activatedState.label.id !== item.label.id
                || hasParentClientIDs.includes(`${item.clientID}_${perspective}`)
                || !!options1.find(obj => obj.value === item.clientID)
                || !!previous.find(obj => obj.value === item.clientID)
            ) {
                return previous;
            }
            let type = t('workspace.shape');
            if (item.objectType === ObjectType.TAG) {
                type = t('workspace.tag');
            }
            if (item.objectType === ObjectType.TRACK) {
                type = t('workspace.track');
            }
            previous.push({
                label: `${type}-${item.clientID}`,
                value: item.clientID,
            })
            return previous;
        }, []);

        console.log('parentIDs:', options2);
        // console.log('对象列表：', objects2D);

        const options = [...options1, ...options2];

        // const options: { label: string, value: number }[] = objects3D.reduce((previous: { label: string, value: number }[], current: any) => {
        //     if (!current.serverID) {
        //         return previous;
        //     }
        //     if (current.objectType === activatedState.objectType
        //         && activatedState.label.parentLabelId === current.label.id
        //         && !hasParentClientIDs.includes(`${current.clientID}_${perspective}`)) {
        //         let type = t('workspace.shape');
        //         if (current.objectType === ObjectType.TAG) {
        //             type = t('workspace.tag');
        //         }
        //         if (current.objectType === ObjectType.TRACK) {
        //             type = t('workspace.track');
        //         }
        //         previous.push({
        //             label: `${type}-${current.clientID}`,
        //             value: current.clientID,
        //         })
        //     }
        //     return previous;
        // }, []);

        Modal.info({
            title: t('workspace.canvas.canvas3D2D.select_associated'),
            content: (
                <>
                    <Select
                        style={{ width: '100%' }}
                        options={options}
                        defaultValue={activatedState.parentID}
                        // onFocus={onShowAssociateEableObjects}
                        onChange={async (value: number) => {
                            try {
                                // const newObject = {
                                //     ...activatedState.toJSON(),
                                //     clientID: value,
                                //     parentID: value,
                                //     // attributes: {},
                                // }
                                // const objectState = new ObjectState(newObject);
                                // selectClientID = value;
                                // dispatch(removeSubObjectAsync(jobInstance, [activatedState], true));
                                await dispatch(associatedAnnotationsAsync(frame, [activatedState], value));
                                dispatch(activateObject(value));
                            } catch (error: any) {
                                console.error('创建关联对象过程中失败', error);
                                throw new Error(`创建关联对象过程中失败:${(error as Error).message}`)
                            }
                        }}
                    />
                </>
            ),
            okText: t('close'),
        });
    }

    return (
        <>
            <Button className='aapt_standard_associated' type='primary' disabled={Workspace.REVIEW_WORKSPACE === workspace} onClick={onAssociated}>
                {activatedState?.parentID ? t('workspace.canvas.canvas3D2D.changeAssociated') : t('workspace.canvas.canvas3D2D.associated')}
            </Button>
        </>
    )
}

// export default memo(Associated, (preProps, nextProps) => (preProps.activatedStateID === nextProps.activatedStateID));
export default Associated;