/*
 * @Author: swxy
 * @Date: 2023-04-24 16:22:37
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
/*
 * @Author: swxy
 * @Date: 2023-04-24 16:22:37
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { resetPasswordAsync } from 'actions/auth-actions';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form, { RuleRender, RuleObject } from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import { LockOutlined } from '@ant-design/icons';
import patterns from 'utils/validation-patterns';
import { useTranslation, getI18n } from 'react-i18next';
import { CombinedState } from 'reducers/interfaces';
import { Divider, Row, Space, message } from 'antd';


const { t: tran } = getI18n();

const validatePassword: RuleRender = (): RuleObject => ({
    validator(_: RuleObject, value: string): Promise<void> {
        if (!patterns.validatePasswordLength.pattern.test(value)) {
            return Promise.reject(new Error(tran(patterns.validatePasswordLength.interId)));
        }

        if (!patterns.passwordContainsNumericCharacters.pattern.test(value)) {
            return Promise.reject(new Error(tran(patterns.passwordContainsNumericCharacters.interId)));
        }

        if (!patterns.passwordContainsUpperCaseCharacter.pattern.test(value)) {
            return Promise.reject(new Error(tran(patterns.passwordContainsUpperCaseCharacter.interId)));
        }

        if (!patterns.passwordContainsLowerCaseCharacter.pattern.test(value)) {
            return Promise.reject(new Error(tran(patterns.passwordContainsLowerCaseCharacter.interId)));
        }

        return Promise.resolve();
    },
});

const validateConfirmation: ((firstFieldName: string, t: any) => RuleRender) = (
    firstFieldName: string,
    t: any,
): RuleRender => ({ getFieldValue }): RuleObject => ({
    validator(_: RuleObject, value: string): Promise<void> {
        if (value && value !== getFieldValue(firstFieldName)) {
            return Promise.reject(new Error(t('login.valid_password')));
        }

        return Promise.resolve();
    },
});



interface Props {
    onClose?: () => void;
}

const ChangePassword: FC<Props> = ({ onClose }) => {
    const dispatch = useDispatch()

    const { t } = useTranslation();
    const {
        user,
        fetching,
    } = useSelector((state: CombinedState) => ({
        user: state.auth.user,
        fetching: state.auth.fetching,
    }));

    const onSubmit = async ({ password }: { password: string }) => {
        dispatch(resetPasswordAsync(password, user.id))
        message.success(t('setting.changePasswordSuccess'))
        onClose?.();
    }

    return (
        <Form
            onFinish={onSubmit}
            className='cvat-reset-password-confirm-form'
        >
            <Form.Item
                hasFeedback
                name='password'
                rules={[
                    {
                        required: true,
                        message: t('login.empty_password'),
                    }, validatePassword,
                ]}
            >
                <Input.Password
                    autoComplete='new-password'
                    prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    placeholder={t('login.empty_password')}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                name='newPassword'
                dependencies={['password']}
                rules={[
                    {
                        required: true,
                        message: t('login.confirm_empty_newPassword'),
                    }, validateConfirmation('password', t),
                ]}
            >
                <Input.Password
                    autoComplete='new-password'
                    prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    placeholder={t('login.confirm_empty_newPassword')}
                />
            </Form.Item>

            <Divider />
            <Row justify='center' align='middle'>
                <Space>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='cvat-reset-password-confirm-form-button'
                        loading={fetching}
                        disabled={fetching}
                    >
                        {t('setting.changePasswordConfirm')}
                    </Button>
                    <Button
                        className='cvat-reset-password-confirm-form-button'
                        onClick={onClose}
                    >
                        {t('base.close')}
                    </Button>
                </Space>
            </Row>
        </Form>
    )
}

export default ChangePassword;