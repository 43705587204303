/*
 * @Author: swxy
 * @Date: 2023-03-11 09:32:06
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import { Button, CheckboxOptionType, Col, List, Radio, RadioChangeEvent, Row, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { CombinedState, RoleType } from 'reducers/interfaces';
import { JobStatus as SubmitStatus, } from 'utils/ConstType';
import { useDispatch, useSelector } from 'react-redux';
import { getJobsInfoAsync } from 'actions/jobs-actions';

import './index.scss';
import JobComponent from './jobItem';
import { acceptorAndMore, annotatorAndMore, reviewFristAndMore, reviewSecondAndMore } from 'utils/constant';
import ApplyJobComponent from './applyJob';
import { getI18n, useTranslation } from 'react-i18next';

enum JobStatus {
    working = 1,
    review,
    accept,
    finish,
}

interface BaseInfo {
    id: number;
    name: number;
    status: number;
    statusName: string;
}

interface Job extends BaseInfo { }
interface Task extends BaseInfo {
    jobs: Job[];
}

interface Project {
    tasks: Task[];
}

interface Filter {
    jobStatus: JobStatus;
}

let list: Project[] = [];
let jobs: any[] = [];

const { t: tranT } = getI18n();

const allOptions = [
    { label: tranT('jobs.filter.working'), value: JobStatus.working },
    { label: tranT('jobs.filter.review'), value: JobStatus.review },
    { label: tranT('jobs.filter.accept'), value: JobStatus.accept },
    { label: tranT('jobs.filter.finish'), value: JobStatus.finish },
];

const getOption = (roleType: RoleType): CheckboxOptionType[] => {
    if (acceptorAndMore(roleType, false)) {
        return allOptions.filter((item) => [JobStatus.working, JobStatus.finish].includes(item.value));
    } else if (reviewSecondAndMore(roleType, false)) {
        return allOptions.filter((item) =>
            [JobStatus.working, JobStatus.accept, JobStatus.finish].includes(item.value),
        );
    } else if (reviewFristAndMore(roleType, false)) {
        return allOptions.filter((item) =>
            [JobStatus.working, JobStatus.review, JobStatus.finish].includes(item.value),
        );
    } else if (annotatorAndMore(roleType, false)) {
        return allOptions.filter((item) =>
            [JobStatus.working, JobStatus.review, JobStatus.finish].includes(item.value),
        );
    }
    return allOptions;
};

const JobList = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation()

    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<Filter>({
        jobStatus: JobStatus.working,
    });
    const [jobsList, setJobsList] = useState<any[]>([]);
    const [options, setOptions] = useState<CheckboxOptionType[]>(allOptions);
    const [applyVisible, setApplyVisible] = useState<boolean>(false);

    const user = useSelector((state: CombinedState) => state.auth.user);

    const query = async () => {
        setApplyVisible(false)
        setLoading(true);
        const result = await dispatch(
            getJobsInfoAsync({
                taskType: [1],
                pageSize: -1,
            }) as unknown as any[],
        );
        jobs = result;
        // setJobsList(result);
        setLoading(false);
    };

    const filterJobs = (list: any[], filter: Filter) => {
        if (filter.jobStatus) {
        }
    };

    useEffect(() => {
        query();
    }, []);

    useEffect(() => {
        // const projects = new Map();
        // jobsList.forEach((job) => {
        //     const project = projects.get(job.projectId) || {
        //         id: job.projcectId,
        //         name: job.engineProjectName,
        //         status: job.projectStatus,
        //         statusName: job.projectStatus,
        //         tasks: new Map(),
        //     };

        //     const task: Task = project.tasks.get(job.taskId) || {
        //         id: job.taskId,
        //         name: job.taskName,
        //         status: job.taskStatus,
        //         statusName: job.taskStatus,
        //         jobs: [],
        //     };
        //     task.jobs.push(job);
        //     projects.set(job.projectId, project);
        //     project.tasks.set(job.taskId, task);
        // });

        // list = Object.entries(projects.entries()).map(
        //     ([projectId, proj]): Project => ({
        //         ...proj,
        //         tasks: Object.entries(proj.tasks.entries()).map(
        //             ([taskId, task]): Task => ({
        //                 ...task,
        //             }),
        //         ),
        //     }),
        // );

        let result = [];
        if (acceptorAndMore(user.roleType)) {
            if (filter.jobStatus === JobStatus.working) {
                result = jobs.filter((job) => [SubmitStatus.submitReceive, SubmitStatus.pass].includes(job.jobStatus));
            }
            if (filter.jobStatus === JobStatus.finish) {
                result = jobs.filter((job) => job.jobStatus >= SubmitStatus.passed);
            }
        } else if (reviewSecondAndMore(user.roleType, false)) {
            if (filter.jobStatus === JobStatus.working) {
                result = jobs.filter((job) =>
                    [SubmitStatus.submitTwo, SubmitStatus.backReceive].includes(job.jobStatus),
                );
            }
            if (filter.jobStatus === JobStatus.accept) {
                result = jobs.filter((job) => [SubmitStatus.submitReceive, SubmitStatus.pass].includes(job.jobStatus));
            }
            if (filter.jobStatus === JobStatus.finish) {
                result = jobs.filter((job) => job.jobStatus >= SubmitStatus.passed);
            }
        } else if (reviewFristAndMore(user.roleType, false)) {
            if (filter.jobStatus === JobStatus.working) {
                result = jobs.filter((job) =>
                    [SubmitStatus.submitOne, SubmitStatus.backTwo].includes(job.jobStatus),
                );
            }
            if (filter.jobStatus === JobStatus.review) {
                result = jobs.filter((job) => [SubmitStatus.submitTwo, SubmitStatus.backReceive, SubmitStatus.submitReceive, SubmitStatus.pass].includes(job.jobStatus));
            }
            if (filter.jobStatus === JobStatus.finish) {
                result = jobs.filter((job) => job.jobStatus >= SubmitStatus.passed);
            }
        } else if (annotatorAndMore(user.roleType, false)) {
            if (filter.jobStatus === JobStatus.working) {
                result = jobs.filter((job) => [SubmitStatus.normal, SubmitStatus.annotate, SubmitStatus.backOne].includes(job.jobStatus));
            }
            if (filter.jobStatus === JobStatus.review) {
                result = jobs.filter((job) =>
                    [
                        SubmitStatus.submitOne,
                        SubmitStatus.submitTwo,
                        SubmitStatus.backTwo,
                        SubmitStatus.submitReceive,
                        SubmitStatus.backReceive,
                        SubmitStatus.pass,
                    ].includes(
                        job.jobStatus,
                    ),
                );
            }
            if (filter.jobStatus === JobStatus.finish) {
                result = jobs.filter((job) => job.jobStatus >= SubmitStatus.passed);
            }
        }
        setJobsList(result);
    }, [jobs, filter]);

    useEffect(() => {
        if (user.roleType) {
            setOptions(getOption(user.roleType));
        }
    }, [user.roleType]);

    const onChangeFilter = (e: RadioChangeEvent) => {
        setFilter((oldFilter) => ({
            ...oldFilter,
            jobStatus: e.target.value,
        }));
    };

    const applyVisibleChange = (applyVisible: boolean) => {
        setApplyVisible(applyVisible)
    }

    const cancel = () => {
        applyVisibleChange(false)
    }

    return (
        <div className='salesmanPage'>
            <Row justify='space-between' className='filter'>
                <div>筛选条件</div>
                <div>
                    <Button onClick={() => applyVisibleChange(true)}>{t('jobs.applyJob')}</Button>
                </div>
            </Row>
            <Row justify='start' className='filter'>
                <Col offset={2}>
                    <Radio.Group
                        className='textColor'
                        defaultValue={JobStatus.working}
                        options={options}
                        onChange={onChangeFilter}
                    />
                </Col>
            </Row>
            <Row justify='center'>
                {/* <Spin size='large' spinning={fetching} /> */}
                <List
                    className='salesmanPage_list'
                    itemLayout='horizontal'
                    loading={loading}
                    dataSource={jobsList}
                    bordered
                    renderItem={(job) => <JobComponent job={job} recoveryable={filter.jobStatus === JobStatus.working} onRecovery={query} />}
                />
            </Row>
            <Modal
                open={applyVisible}
                footer={null}
                destroyOnClose
                width={"80%"}
                onCancel={cancel}
            >
                <ApplyJobComponent onOk={query} onCancel={cancel} />
            </Modal>
        </div>
    );
};
export default JobList;
