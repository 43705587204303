/*
 * @Author: swxy
 * @Date: 2022-07-30 16:08:55
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import React, { useEffect, useState } from 'react';
import { Button, Row, Space, Rate, Typography, Divider, Spin, Result, Popconfirm, Input, message, Radio } from 'antd';
import { useSelector } from 'react-redux';
import { CombinedState, ExportProjectType, ObjectType } from 'reducers/interfaces';
import {
    acceptorAndMore,
    annotatorAndMore,
    getConst,
    getConstItem,
    reviewFristAndMore,
    reviewSecondAndMore,
} from 'utils/constant';
import { useHistory } from 'react-router';
import { Canvas3d } from 'canvas3d-wrapper';
import { useTranslation } from 'react-i18next';
import { isDev } from 'utils/enviroment';
import { getExportAttributes } from 'utils/export';
import { ProjectCompanyType, JobStatus } from 'utils/ConstType';

// 当前提交/审批的场景类型
enum Mode {
    annotation,
    first_review,
    second_review,
    acception,
    finish,
}

enum DumpOrderType {
    default,
    first,
    secord,
    mix = 9,
}

function getMode(jobStatus: JobStatus): Mode {
    if ([JobStatus.annotate, JobStatus.backOne].includes(jobStatus)) {
        return Mode.annotation;
    }
    if ([JobStatus.submitOne, JobStatus.backTwo].includes(jobStatus)) {
        return Mode.first_review;
    }
    if ([JobStatus.backReceive, JobStatus.submitTwo].includes(jobStatus)) {
        return Mode.second_review;
    }
    if ([JobStatus.submitReceive, JobStatus.pass].includes(jobStatus)) {
        return Mode.acception;
    }
    return Mode.finish;
}

interface Props {
    onCancel?: () => void;
    onSubmit?: () => void;
}

const { Text } = Typography;

export default ({ onCancel }: Props): JSX.Element => {
    const jobInstance = useSelector((state: CombinedState) => state.annotation.job.instance);
    const canvasInstance = useSelector((state: CombinedState) => state.annotation.canvas.instance);
    const user = useSelector((state: CombinedState) => state.auth.user);
    const [score, setScore] = useState<number>(3);
    const [loading, setLoading] = useState<boolean>(false);
    const [backReason, setBackReason] = useState<string>('');
    const [mode] = useState<Mode>(getMode(jobInstance.jobStatus));
    const [exportTimes, setExportTimes] = useState<DumpOrderType>(DumpOrderType.secord);

    const { t } = useTranslation();

    const projectType = jobInstance.project.projectCompanyType;
    const history = useHistory();

    useEffect(() => {
        if (jobInstance.score && jobInstance.score !== score) {
            setScore(jobInstance.score);
        }
    }, [jobInstance.score]);

    // 检查当前用户权限，是否能够审核
    const checkRole = (): boolean => {
        if (mode === Mode.annotation && annotatorAndMore(user.roleType)) {
            return true;
        }
        if (mode === Mode.first_review && reviewFristAndMore(user.roleType)) {
            return true;
        }
        if (mode === Mode.second_review && reviewSecondAndMore(user.roleType)) {
            return true;
        }
        if (mode === Mode.acception && acceptorAndMore(user.roleType)) {
            return true;
        }
        return false;
    };

    if (!checkRole()) {
        return (
            <>
                <Row justify='center'>
                    <Result
                        status='403'
                        title={t('workspace.top.modal.submit_permission')}
                        subTitle={t('workspace.top.modal.submit_permission_sub')}
                    />
                </Row>
                <Divider />
                <Row justify='center'>
                    <Space>
                        <Button type='primary' onClick={onCancel}>
                            {t('workspace.top.modal.submit_permission_close')}
                        </Button>
                    </Space>
                </Row>
            </>
        );
    }

    const getStatesSum = async (type?: 'zj' | 'fr', frameData?: any, states?: any) => {
        // if (type === 'fr' && jobInstance.jobId && jobInstance.engineTask.engineProject.projectType === 1) {
        //     // 2D
        //     // const result = await jobInstance.getParentJobInfo();
        //     // setParentJobInfo(result);
        //     // console.log('结果：', result);
        //     // return [];
        //     // console.log('states:', states);

        //     return [];
        // }
        if (jobInstance.id && jobInstance.engineTask.engineProject.projectType === 1) {
            return states.map((state: any) => {
                const attributes = getExportAttributes(state.attributes, state.label);
                return {
                    jobId: jobInstance.id,
                    frame: frameData.number,
                    frameType: jobInstance.engineTask?.engineProject?.parentFrameType || 0,
                    trackShapeId: state.serverID,
                    objId: state.serverID,
                    normalShapeId: state.serverID,
                    dimensionType: 1,
                    dumpOrderType: 0,
                    semanticObjFrType: 0,
                    shapeObjFrType: 0,
                    shapeObjType: 0,
                    laneFrType: 0,
                    points: JSON.stringify(state.points),
                    occludedParts:
                        state.pointOccludeds && state.pointOccludeds.length
                            ? JSON.stringify(
                                state.points.filter(
                                    (pos: number, index: number) => state.pointOccludeds[index] === true,
                                ) || [],
                            )
                            : undefined,

                    ...attributes,
                };
            });
        }

        return (canvasInstance as Canvas3d).summarize(frameData, states);
    };

    const getFrameStateDatas = async (type?: 'zj' | 'fr', frame?: number, data?: any): Promise<any> => {
        const newData = data || [];
        const frameNumber = frame || jobInstance.startFrame;

        // console.log('查看步骤0：', frameNumber);
        const states = await jobInstance.annotations.get(frameNumber, false, []);
        // console.log('查看步骤1：', states);
        const frameData = await jobInstance.frames.get(frameNumber, false);
        // console.log('查看步骤2：', frameData);
        const frameStateData = await getStatesSum(type, frameData, states);
        // console.log('查看步骤3：', frameStateData);

        if (frameNumber < jobInstance.stopFrame) {
            return [...frameStateData, ...(await getFrameStateDatas(type, frameNumber + 1, newData))];
        }
        return frameStateData;
    };

    const saveExportInfo = async (type?: 'zj' | 'fr'): Promise<void> => {
        // if (jobInstance.id) {
        //     await jobInstance.get;
        // }

        let data = await getFrameStateDatas(type);

        // console.log('数据：', data);

        const times = jobInstance.engineTask?.engineProject?.projectDumpType === 2 ? exportTimes : undefined;

        const shapes = data.map((item: any) => ({
            ...item,
            frameType: jobInstance.engineTask?.engineProject?.parentFrameType || 0,
            dumpOrderType: times,
        }));
        // console.time('测试接口时间');

        const result = await jobInstance.saveExportData({ shapes, job: { dumpOrderType: times } });

        // console.timeEnd('测试接口时间');
        if (!result) {
            throw new Error('重新统计框错误！');
        }
    };

    const onPass = async (pass = true): Promise<void> => {
        // console.time('重新统计所有框数据总耗时');
        setLoading(true);

        try {
            // // 二审且类型是3D
            // if (mode === Mode.second_review && pass && jobInstance.dimension === '3d' && jobInstance.projectId === 8) {
            //     // 3D才需要
            //     await save3DInfo();
            // }

            // console.log('项目信息：', projectType);
            // console.log('项目id1：', jobInstance.projectId === 5);
            if (jobInstance?.engineProject?.projectCompanyType !== ProjectCompanyType.dazhuo) {
                if (mode === Mode.second_review && pass && isDev() && jobInstance.projectId === 5) {
                    // 开发环境，项目为3
                    await saveExportInfo('fr');
                } else if (mode === Mode.second_review && pass && jobInstance.projectId === 18) {
                    // 生产 fr
                    await saveExportInfo('fr');
                    // } else if (mode === Mode.second_review && pass && projectType !== ProjectCompanyType.def) {
                    //     // 生产 zj
                    //     await saveExportInfo('zj');
                } else if (mode === Mode.second_review && pass && projectType !== ProjectCompanyType.def) {
                    // 需要导出
                    await saveExportInfo();
                } else if (mode === Mode.second_review && pass) {
                    // 没有导出类型，二审时，不做导出计算
                    // await saveExportInfo();
                }
            }
            // console.timeEnd('重新统计所有框数据总耗时');
            // return;

            const data: any = {
                jobId: jobInstance.id,
                backReason,
                score: 0,
                isPass: pass,
            };

            // 标注不需要分数
            if (mode === Mode.annotation) {
                delete data.score;
                delete data.backReason;
            }

            // 通过不需要退回原因
            if (pass) {
                delete data.backReason;
            }

            const result = await jobInstance.submit(data);
            setLoading(false);
            // console.timeEnd('重新统计所有框数据总耗时');
            if (result?.success) {
                message.success(t('workspace.top.modal.submit_review_success'));
                history.replace(`/tasks/${jobInstance.taskId}`);
            }
        } catch (error: any) {
            message.error(error.message);
            setLoading(false);
        }
        // return result as unknown as boolean;
    };

    const onChangeBackReason = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setBackReason(e.target.value || '');
    };

    // 退回时，确认
    const onBack = async (): Promise<void> => {
        // if (!backReason) {
        //     message.warn(t('workspace.top.modal.submit_review_back_reason_w'));
        //     return Promise.reject();
        // }

        await onPass(false);

        // if (result) {
        //     return Promise.resolve();
        // }
        return Promise.resolve();
    };

    // 没有权限的已被过滤，因此一下一定会有权限
    const getTip = (): string => {
        if (mode === Mode.annotation) {
            return 'workspace.top.modal.submit_status_submit';
        }
        if (mode === Mode.first_review) {
            return 'workspace.top.modal.submit_status_frist_review';
        }
        if (mode === Mode.second_review) {
            return 'workspace.top.modal.submit_status_second_review';
        }
        if (mode === Mode.acception) {
            return 'workspace.top.modal.submit_status_acceptance';
        }
        return 'workspace.top.modal.submit_status_review';
    };

    const options = getConst('dumpOrderType');

    const render = (
        <Spin spinning={loading} tip={t('workspace.top.modal.submit_loading_tip')}>
            <Row justify='center'>
                <Space align='center' direction='vertical'>
                    {mode !== Mode.annotation && (
                        <Rate
                            allowHalf
                            allowClear={false}
                            onChange={setScore}
                            defaultValue={3}
                            tooltips={[
                                'workspace.top.modal.submit_sorce_very_bad',
                                'workspace.top.modal.submit_sorce_bad',
                                'workspace.top.modal.submit_sorce_authorized',
                                'workspace.top.modal.submit_sorce_good',
                                'workspace.top.modal.submit_sorce_very_good',
                            ]}
                        />
                    )}
                    <Text>
                        {t(getTip())}
                        {t('workspace.top.modal.submit_content')}
                    </Text>
                </Space>
            </Row>
            <Divider />
            {jobInstance.projectType === ExportProjectType.FR &&
                mode === Mode.second_review &&
                jobInstance.engineTask?.engineProject?.projectDumpType === 2 && (
                    <>
                        <Row justify='center'>
                            <Space>
                                <Text>{t('workspace.side.export.dumpOrderType')}：</Text>
                                <Radio.Group
                                    value={exportTimes}
                                    onChange={(e) => {
                                        setExportTimes(e.target.value);
                                    }}
                                    options={options.filter(
                                        (item) =>
                                            item.value !== DumpOrderType.default && item.value !== DumpOrderType.mix,
                                    )}
                                >
                                    {/* <Radio value={1}>第一次</Radio>
                            <Radio value={2}>第二次</Radio> */}
                                </Radio.Group>
                            </Space>
                        </Row>
                        <Divider />
                    </>
                )}
            <Row justify='center'>
                <Space>
                    {mode === Mode.annotation ? (
                        <Button type='primary' onClick={() => onPass()} loading={loading}>
                            {t('workspace.top.modal.submit_submit')}
                        </Button>
                    ) : (
                        <>
                            <Button type='primary' onClick={() => onPass()} loading={loading}>
                                {t('workspace.top.modal.submit_pass')}
                            </Button>
                            <Popconfirm
                                title={
                                    t('workspace.top.modal.submit_back_reason_tip')
                                    // <Input.TextArea
                                    //     autoSize={{ minRows: 3 }}
                                    //     placeholder={t('workspace.top.modal.submit_back_reason')}
                                    //     value={backReason}
                                    //     maxLength={2000}
                                    //     onChange={onChangeBackReason}
                                    // />
                                }
                                // onCancel={() => {
                                //     // console.log('清空了');
                                //     setBackReason('');
                                // }}
                                onConfirm={onBack}
                                destroyTooltipOnHide
                            >
                                <Button danger loading={loading}>
                                    {t('workspace.top.modal.submit_back')}
                                </Button>
                            </Popconfirm>
                        </>
                    )}
                    <Button onClick={onCancel}>{t('workspace.top.modal.submit_close')}</Button>
                </Space>
            </Row>
        </Spin>
    );

    return <>{render}</>;
};
