// Copyright (C) 2021-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import { getAmygoStore } from 'amygo-store';
import { CombinedState } from 'reducers/interfaces';

export default function isAbleToChangeFrame(): boolean {
    const store = getAmygoStore();

    const state: CombinedState = store.getState();
    const { instance } = state.annotation.canvas;
    return !!instance && instance.isAbleToChangeFrame() && !state.annotation.player.navigationBlocked;
}
