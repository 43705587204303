const MAX_HISTORY_LENGTH = 128;

/**
 * Types of actions with annotations
 * @enum {string}
 * @name HistoryActions
 * @memberof module:API.cvat.enums
 * @property {string} CHANGED_LABEL Changed label
 * @property {string} CHANGED_ATTRIBUTES Changed attributes
 * @property {string} CHANGED_POINTS Changed points
 * @property {string} CHANGED_OUTSIDE Changed outside
 * @property {string} CHANGED_OCCLUDED Changed occluded
 * @property {string} CHANGED_ZORDER Changed z-order
 * @property {string} CHANGED_LOCK Changed lock
 * @property {string} CHANGED_COLOR Changed color
 * @property {string} CHANGED_HIDDEN Changed hidden
 * @property {string} CHANGED_SOURCE Changed source
 * @property {string} MERGED_OBJECTS Merged objects
 * @property {string} SPLITTED_TRACK Splitted track
 * @property {string} GROUPED_OBJECTS Grouped objects
 * @property {string} CREATED_OBJECTS Created objects
 * @property {string} REMOVED_OBJECT Removed object
 * @property {string} CHANGED_POINTSLINE Changed pointsLine
 * @property {string} change_elements Changed elements
 * @readonly
 */
export enum HistoryActions {
    CHANGED_LABEL = 'Changed label',
    CHANGED_ATTRIBUTES = 'Changed attributes',
    CHANGED_POINTS = 'Changed points',
    CHANGED_OUTSIDE = 'Changed outside',
    CHANGED_OCCLUDED = 'Changed occluded',
    CHANGED_ZORDER = 'Changed z-order',
    CHANGED_KEYFRAME = 'Changed keyframe',
    CHANGED_LOCK = 'Changed lock',
    CHANGED_PINNED = 'Changed pinned',
    CHANGED_COLOR = 'Changed color',
    CHANGED_HIDDEN = 'Changed hidden',
    CHANGED_SOURCE = 'Changed source',
    MERGED_OBJECTS = 'Merged objects',
    SPLITTED_TRACK = 'Splitted track',
    GROUPED_OBJECTS = 'Grouped objects',
    CREATED_OBJECTS = 'Created objects',
    REMOVED_OBJECT = 'Removed object',
    CHANGED_POINTSLINE = 'Changed pointsLine',
    change_parentID = 'Change ParentID',
    change_subPoints = 'Change SubPoints',
    CHANGED_PointOccludeds = 'Changed point occludeds',
    change_elements = 'Changed elements',
    change_controls = 'Changed bezier controls points',
}

class AnnotationHistory {
    private frozen: boolean;
    private _undo: any[] = [];
    private _redo: any[] = [];
    constructor() {
        this.frozen = false;
        this.clear();
    }

    freeze(frozen: boolean) {
        this.frozen = frozen;
    }

    get() {
        return {
            undo: this._undo.map((undo) => [undo.action, undo.frame]),
            redo: this._redo.map((redo) => [redo.action, redo.frame]),
        };
    }

    do(action: any, undo: any, redo: any, clientIDs: any, frame: any) {
        if (this.frozen) return;
        const actionItem = {
            clientIDs,
            action,
            undo,
            redo,
            frame,
        };

        this._undo = this._undo.slice(-MAX_HISTORY_LENGTH + 1);
        this._undo.push(actionItem);
        this._redo = [];
    }

    undo(count: number = 1) {
        const affectedObjects = [];
        for (let i = 0; i < count; i++) {
            const action = this._undo.pop();
            if (action) {
                action.undo();
                this._redo.push(action);
                affectedObjects.push(...action.clientIDs);
            } else {
                break;
            }
        }

        return affectedObjects;
    }

    redo(count: number = 1) {
        const affectedObjects = [];
        for (let i = 0; i < count; i++) {
            const action = this._redo.pop();
            if (action) {
                action.redo();
                this._undo.push(action);
                affectedObjects.push(...action.clientIDs);
            } else {
                break;
            }
        }

        return affectedObjects;
    }

    clear() {
        this._undo = [];
        this._redo = [];
    }
}

export default AnnotationHistory;
