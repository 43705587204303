/*
 * @Author: swxy
 * @Date: 2023-08-19 15:28:20
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
    'overview.title': '总览',

    'overview.userInfo.assignee': '分配人',

    'overview.frameInfo.startFrame': '起始帧',
    'overview.frameInfo.stopFrame': '截止帧',
    'overview.frameInfo.frames': '总帧数',

    'overview.annotations.statistics.title': '标注统计',

    'overview.label': '标签',
    'overview.frames': '总帧数',
    'overview.total': '总计',

    'overview.auto': '自动化',
    'overview.manually': '手动调整',
    'overview.interpolated': '自动插值',

    'overview.tag.title': '图像标注统计',
    'overview.annotations2D.title': '2D目标物统计',
    'overview.annotations3D.title': '3D目标物统计',
    'overview.issue.title': '批注统计',

    'overview.issue.column.2d': '2D批注',
    'overview.issue.column.3d': '3D批注',

    'overview.jobInfo.jobStatus': '题包状态',
    'overview.annotation.target': '目标物统计',

    'overview.annotations.title': '目标物统计',
    'overview.modify': '手动修改',
};
