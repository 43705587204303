/*
 * @Author: swxy
 * @Date: 2022-05-14 11:06:43
 * @LastEditors: swxy
 * @FilePath: /aatp-ui/cvat-ui/src/utils/utils.ts
 * Copyright (C) Amygo
 */

import ObjectState from "business/objects/objectState";
import { Euler, Matrix4, Quaternion, Vector3 } from "three";

// 生成随机的十六进制颜色
export const getRandomColor = (): string => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

// export const dataBase64ToBlob = (base64Data: string) => {
//     let byteArr = Buffer.from(base64Data, 'base64');
//     if (base64Data.split(',')[0].indexOf('base64') >= 0) byteArr = Buffer.from(base64Data, 'base64'); //base64 解码
//     else {
//         // @ts-ignore
//         byteArr = Buffer.from(base64Data, 'base64url');
//     }
//     const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]; //mime类型 -- image/png
//     const uintArr = new Uint8Array(byteArr.buffer); // 创建视图

//     return new Blob([uintArr], {
//         type: mimeString,
//     });
// };
/**
 * 将以base64的图片url数据转换为Blob
 * @param urlData 用url方式表示的base64图片数据
 */
export const dataBase64ToBlob = (urlData: string) => {
    let arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

export const loadImageSize = async (file: File): Promise<{ width: number; height: number; error?: boolean }> => {
    // document.getElementById('root')?.appendChild(image);

    // console.log('图片：', image);
    // setTimeout(() => {
    // }, 1);

    return new Promise((resolve, reject) => {
        const image = new Image();
        const url = URL.createObjectURL(file);
        image.src = url;
        image.style.display = 'none';
        image.onload = () => {
            resolve({ width: image.width, height: image.height });
            URL.revokeObjectURL(url);
        };
        image.onerror = () => {
            reject();
            URL.revokeObjectURL(url);
        };
    });
};

export const getVersion = () => {
    return '0.5';
};

export const sleep = async (num: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, num);
    });
};


export const getMatrixFromObjectState = (objectState: ObjectState) => {
    try {
        const [
            x,
            y,
            z,
            rotationX,
            rotationY,
            rotationZ,
            width,
            height,
            depth,
        ] = objectState.points;
        return new Matrix4().compose(
            new Vector3(x,y,z),
            new Quaternion().setFromEuler(new Euler(rotationX,rotationY,rotationZ)),
            new Vector3(width, height, depth))
    } catch (error) {
        console.error('从目标物获取矩阵信息出错！');
    }
    return new Matrix4();
}