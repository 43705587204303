/*
 * @Author: swxy
 * @Date: 2024-08-02 10:18:48
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { useState } from 'react';
import { Button, Tooltip } from "antd"
import Job from "business/objects/job";
import { CombinedState, ObjectType } from "reducers/interfaces";
import { proPoseProjectIds } from "utils/constant";
import { useDispatch, useSelector } from 'react-redux';
import ObjectState from 'business/objects/objectState';
import { Euler, Matrix4, Quaternion, Vector3 } from 'three';
import { updateAnnotationsAsync } from 'actions/annotation-actions';

const TrackFitByPose = () => {
    const [loading, setLoading] = useState(false)
    const { frame, jobInstance, states } = useSelector((state: CombinedState) => {
        return {
            frame: state.annotation.player.frame.number,
            jobInstance: state.annotation.job.instance as Job,
            states: state.annotation.annotations.states as ObjectState[],
        }
    })
    const frameMeta = jobInstance.frameMeta.get(frame);

    const dispatch = useDispatch();

    if (!frameMeta?.pose) {
        return <></>
    }

    /**
     *
     * @param worldToA A的世界变换矩阵
     * @param worldToB B的世界变换矩阵
     * @param aToC C -> A的变换矩阵
     * @returns bToC 需要 B -> C 的变换矩阵
     */
    const getBToC = (worldToA: Matrix4, worldToB: Matrix4, aToC: Matrix4): Matrix4 => {

        // C的世界变换矩阵
        const worldToC = worldToA.clone().multiply(aToC);

        // B->C 的变换矩阵
        const bToWorld = worldToB.clone().invert();
        const bToC = bToWorld.clone().multiply(worldToC);
        return bToC;
    }


    /**
     *
     * @param worldToA A的世界变换矩阵
     * @param worldToB B的世界变换矩阵
     * @param aToC C -> A的变换矩阵
     * @returns bToC 需要 B -> C 的变换矩阵
     */
    const getBToC2 = (a: Matrix4, b: Matrix4, aToC: Matrix4): Matrix4 => {
        console.log('初始目标物位置:', aToC.toArray());

        const lidar2imuMatrix = new Matrix4().compose(new Vector3(0.649725189756224, -0.1486571354765761, 1.724163534628167), new Quaternion(
            -0.5792471459462464,
            -0.3524389031129367,
            0.7261488872430156,
            -0.1138743036923089), new Vector3(1, 1, 1));
        const imu2lidar = lidar2imuMatrix.clone().invert();

        const worldToA = a.multiply(imu2lidar);
        const worldToB = b.multiply(imu2lidar);

        // BtoA的变换矩阵
        const bToA = (worldToB.clone().multiply(imu2lidar).invert()).multiply(worldToA.multiply(imu2lidar));
        const pos = new Vector3();
        const qua = new Quaternion();
        const sclae = new Vector3();
        bToA.decompose(pos, qua, sclae);

        console.log('位置：', pos)
        console.log('旋转：', new Euler().setFromQuaternion(qua))
        console.log('尺寸：', sclae)
        // const bToA = new Matrix4().compose(new Vector3(1.3, 0, 0), new Quaternion(), new Vector3(1, 1, 1));
        console.log('偏移量:', bToA.toArray());

        // B->C 的变换矩阵
        const bToC = (bToA.clone()).multiply(aToC);
        console.log('结果:', bToC.toArray());
        return bToC;
    }

    const cailbrationByObject = async (track: ObjectState) => {
        // 对象上一帧的frameMeta。已排除没有上一帧的对象
        const prevFrameMeta = jobInstance.frameMeta.get(track.keyframes!.prev!);
        const prevStates: ObjectState[] = await jobInstance.getAnnotationsInfo(track.keyframes!.prev!, true);
        const prevObj = prevStates.find((pre) => pre.clientID === track.clientID);
        // 从本帧meta，和上帧meta中，计算出变化的矩阵。
        if (frameMeta.pose && prevFrameMeta?.pose && prevObj) {
            // console.log('前一帧pose时间戳：', prevFrameMeta.pose.matrix().toArray())
            // console.log('当前帧时间戳：', frameMeta.pose.matrix().toArray())
            // console.log(`探测车前一帧矩阵`, JSON.stringify(prevFrameMeta.pose));
            // console.log(`探测车本帧矩阵`, JSON.stringify(frameMeta.pose));
            // console.log(`位置偏转前：`, JSON.stringify(prevObj.points));
            // 物体相对于前一帧车辆的矩阵
            // const prevPos = new Vector3(prevObj.points[0], prevObj.points[1], prevObj.points[2]);
            // const prevQuaternion = new Quaternion().setFromEuler(new Euler(prevObj.points[3], prevObj.points[4], prevObj.points[5]));
            const scale = new Vector3(prevObj.points[6], prevObj.points[7], prevObj.points[8]);
            const prevObjMatrix = new Matrix4().compose(
                new Vector3(prevObj.points[0], prevObj.points[1], prevObj.points[2]),
                new Quaternion().setFromEuler(new Euler(prevObj.points[3], prevObj.points[4], prevObj.points[5])),
                new Vector3(prevObj.points[6], prevObj.points[7], prevObj.points[8]),
            )

            const currentObjMatrix = getBToC(prevFrameMeta.pose.matrix(), frameMeta.pose.matrix(), prevObjMatrix)
            // const currentObjMatrix = getBToC2(
            //     new Matrix4().compose(new Vector3(5, 5, 1), new Quaternion().setFromEuler(new Euler()), new Vector3(1, 1, 1)),
            //     new Matrix4().compose(new Vector3(5 + 1.3, 5, 1), new Quaternion().setFromEuler(new Euler()), new Vector3(1, 1, 1)), prevObjMatrix)

            // 物体相对于前一帧车辆的矩阵
            // const prevObjMatrix = new Matrix4()
            // 物体 在世界坐标系下的变换矩阵
            // prevObjMatrix.multiplyMatrices(prevFrameMeta.pose.matrix(), prevObjMatrix);

            // console.log('物体世界坐标系矩阵：', JSON.stringify(prevObjMatrix));
            // 世界坐标系下，物体是静止的


            // console.log('---物体世界坐标系矩阵：', JSON.stringify(prevObjMatrix));

            // const matrix = prevFrameMeta.pose.getMatrix(frameMeta.pose);
            // console.log('处理矩阵：', JSON.stringify(matrix));
            // 物体在当前帧下的变换矩阵
            // const currentObjMatrix = new Matrix4().multiplyMatrices(frameMeta.pose.invert(), prevObjMatrix);
            // const currentObjMatrix = (prevObjMatrix.clone().invert()).multiply(prevFrameMeta.pose.invert())

            // console.log('位置偏转后：', JSON.stringify(currentObjMatrix));

            const pos = new Vector3();
            const quaternion = new Quaternion();
            currentObjMatrix.decompose(pos, quaternion, new Vector3());
            const euler = new Euler().setFromQuaternion(quaternion);
            track.points = [pos.x, pos.y, pos.z, euler.x, euler.y, euler.z, scale.x, scale.y, scale.z, 0, 0, 0, 0, 0, 0, 0];
        }

        return track;
    }

    const onCalibration = async () => {
        setLoading(true)
        try {
            if (frame !== jobInstance.startFrame) {

                const tracks = states.filter(obj => obj.objectType === ObjectType.TRACK) || [];

                const promise = tracks.reduce((previous: Promise<ObjectState>[], track) => {
                    if (track.keyframes?.prev) {
                        previous.push(cailbrationByObject(track));
                    }
                    return previous;
                }, [])

                const result = await Promise.all(promise);

                dispatch(updateAnnotationsAsync(result))
                // console.log('结果列表：', result)
            }
        } catch (error) {
            throw new Error(`校准出错：-${(error as Error).stack}`)
        } finally {

            setLoading(false)
        }
    }

    return <div style={{ position: 'absolute', right: '20px' }}>
        <Tooltip title="连续帧根据上一帧目标物的位置和旋转，推测本帧目标物相应的位置和旋转">
            <Button loading={loading} onClick={onCalibration} disabled={frame === jobInstance.startFrame}>校准</Button>
        </Tooltip>
    </div>
}

export default TrackFitByPose;