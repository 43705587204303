// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import message from 'antd/lib/message';
import { LockFilled, UnlockOutlined, EyeInvisibleFilled, EyeOutlined, GroupOutlined, UngroupOutlined } from '@ant-design/icons';

import CVATTooltip from 'components/common/cvat-tooltip';
import LabelKeySelectorPopover from './label-key-selector-popover';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

interface Props {
    labelName: string;
    labelColor: string;
    labelID: number;
    visible: boolean;
    statesHidden: boolean;
    statesLocked: boolean;
    keyToLabelMapping: Record<string, number>;
    ownObjectStates: any[];
    activeLabelID: number;
    isUniformSize: boolean;

    hideStates(): void;
    showStates(): void;
    lockStates(): void;
    unlockStates(): void;
    updateLabelShortcutKey(updatedKey: string, labelID: number): void;
    activateObject(activatedStateID: number | null, activatedAttributeID: number | null): void;
    selectObjects(selectedStatesID: number[]): void;
    rememberObject(obj: any): void;
    onChangeIsUniformSize(isUniformSize: boolean): void;
}

function LabelItemComponent(props: Props): JSX.Element {
    const {
        labelName,
        labelColor,
        labelID,
        keyToLabelMapping,
        visible,
        statesHidden,
        statesLocked,
        ownObjectStates,
        activeLabelID,
        hideStates,
        showStates,
        lockStates,
        unlockStates,
        updateLabelShortcutKey,
        activateObject,
        selectObjects,
        rememberObject,
        onChangeIsUniformSize,

        isUniformSize: defulatUniformSize = true,
    } = props;

    const { t } = useTranslation();
    const [isUniformSize, setIsUniformSize] = useState(defulatUniformSize);

    // create reversed mapping just to receive key easily
    const labelToKeyMapping: Record<string, string> = Object.fromEntries(
        Object.entries(keyToLabelMapping).map(([key, _labelID]) => [_labelID, key]),
    );
    const labelShortcutKey = labelToKeyMapping[labelID] || '?';
    const classes = {
        lock: {
            enabled: { className: 'cvat-label-item-button-lock cvat-label-item-button-lock-enabled' },
            disabled: { className: 'cvat-label-item-button-lock' },
        },
        hidden: {
            enabled: { className: 'cvat-label-item-button-hidden cvat-label-item-button-hidden-enabled' },
            disabled: { className: 'cvat-label-item-button-hidden' },
        },
    };

    const click = (e: React.MouseEvent): void => {
        if (e) {
            e.stopPropagation();
        }
    };

    const select = (e?: React.MouseEvent): void => {
        e?.preventDefault();
        e?.stopPropagation();

        setIsUniformSize(!isUniformSize);
        onChangeIsUniformSize(!isUniformSize)

        // activateObject(null, null);
        // selectObjects(
        //     ownObjectStates.reduce((previous: number[], current: any): number[] => {
        //         if (current.label.id === labelID) {
        //             previous.push(current.clientID);
        //         }
        //         return previous;
        //     }, []),
        // );
    };

    const changeActiveLabelID = (): void => {
        rememberObject({ activeLabelID: labelID });
        message.destroy();
        // message.success(`Default label was changed to "${labelName}"`);
        message.success(`${t('workspace.side.issue.defualt_label_msg')} "${labelName}"`);
    };

    return (
        <Row
            align='stretch'
            justify='space-around'
            className={[
                'cvat-objects-sidebar-label-item',
                visible ? '' : 'cvat-objects-sidebar-label-item-disabled',
                labelID === activeLabelID ? 'aatp_objects_sidebar_label_item_select' : '',
            ].join(' ')}
            onClick={changeActiveLabelID}
        >
            <Col span={2}>
                <div style={{ background: labelColor }} className='cvat-label-item-color'>
                    {' '}
                </div>
            </Col>
            <Col span={11}>
                <CVATTooltip title={labelName}>
                    <Text strong className='cvat-text'>
                        {labelName}
                    </Text>
                </CVATTooltip>
            </Col>
            <Tooltip title='切换标签快捷键：Ctrl + 数字。选中目标物时，更改该目标物。未选中时，更改默认标签'>
                <Col span={3}>
                    <LabelKeySelectorPopover
                        keyToLabelMapping={keyToLabelMapping}
                        labelID={labelID}
                        updateLabelShortcutKey={updateLabelShortcutKey}
                    >
                        <Button
                            className='cvat-label-item-setup-shortcut-button'
                            size='small'
                            ghost
                            type='dashed'
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {labelShortcutKey}
                        </Button>
                    </LabelKeySelectorPopover>
                </Col>
            </Tooltip>
            <Col span={2} offset={1}>
                <Tooltip title='当前标签的所有框是否都锁定不可更改'>
                    {statesLocked ? (
                        // <LockFilled {...classes.lock.enabled} onClick={unlockStates} />
                        <LockFilled
                            {...classes.lock.enabled}
                            onClick={(e: React.MouseEvent) => {
                                click(e);
                                unlockStates();
                            }}
                        />
                    ) : (
                        // <UnlockOutlined {...classes.lock.disabled} onClick={lockStates} />
                        <UnlockOutlined
                            {...classes.lock.disabled}
                            // onClick={lockStates}
                            onClick={(e: React.MouseEvent) => {
                                click(e);
                                lockStates();
                            }}
                        />
                    )}
                </Tooltip>
            </Col>
            <Col span={2}>
                <Tooltip title='当前标签的框是否可见'>
                    {statesHidden ? (
                        // <EyeInvisibleFilled {...classes.hidden.enabled} onClick={showStates} />

                        <EyeInvisibleFilled
                            {...classes.hidden.enabled}
                            // onClick={showStates}
                            onClick={(e: React.MouseEvent) => {
                                click(e);
                                showStates();
                            }}
                        />
                    ) : (
                        // <EyeOutlined {...classes.hidden.disabled} onClick={hideStates} />
                        <EyeOutlined {...classes.hidden.disabled} onClick={hideStates} />
                    )}
                </Tooltip>
            </Col>
            <Col span={2}>
                <Tooltip title='该标签是否锁定所有帧统一尺寸（请注意，锁定时调整框，前后帧同一目标物的框都会强行统一大小。每次进入都需要重新设置）'>
                    {isUniformSize ? (
                        <GroupOutlined {...classes.hidden.enabled} onClick={select} />
                    ) : (
                        <UngroupOutlined {...classes.hidden.disabled} onClick={select} />
                    )}
                </Tooltip>
            </Col>
        </Row>
    );
}

export default React.memo(LabelItemComponent);
