/*
 * @Author: swxy
 * @Date: 2023-08-27 15:26:46
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
class IssuePreset {
    public readonly id: number;
    public readonly projectId: number;
    public readonly content: string;
    public readonly addTime: string;
    public readonly updateTime: string;

    constructor(serviceIssue: any) {
        this.id = serviceIssue.id;
        this.projectId = serviceIssue.frame;
        this.content = serviceIssue.content;
        this.addTime = serviceIssue.addTime || new Date().toISOString();
        this.updateTime = serviceIssue.updateTime || new Date().toISOString();
    }
}

export default IssuePreset;
