/*
 * @Author: swxy
 * @Date: 2022-12-21 10:04:23
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import * as THREE from 'three';
// import { EllipseCurve } from 'three/src/extras/curves/EllipseCurve';
// import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';
// import CameraControls from 'camera-controls';
import { Controller } from './controller';
import { Issue, Mode, ObjectState, UpdateReasons, ViewType, Action, ShapeType } from './interface';
import Canvas3dView from './canvas3dView';
import CanvasCameraControls, { CameraType } from './canvasCameraControls';
import Scene from './objects/scene';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import BezierPoint from './objects/bezier/point';
import Bezier from './objects/bezier/bezierModel';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

// export interface Action {
//     cameraMove: boolean;
//     loading: boolean;

//     initPosition: THREE.Vector2;
//     movePosition: THREE.Vector2;

//     translation: boolean; // 平移中
//     rotation: boolean; // 旋转中
//     resize: boolean; // 调整大小中

//     selectable: boolean; // 是否可以选择
// }

export enum PositionDirection {
    top = 'top',
    left = 'left',
    right = 'right',
    bottom = 'bottom',

    top_left = 'top_left',
    top_right = 'top_right',
    bottom_left = 'bottom_left',
    bottom_right = 'bottom_right',
}

export interface RayCast {
    renderer: THREE.Raycaster;
    mouseVector: THREE.Vector2;
}

class BaseView {
    public scene: Scene = new Scene();
    public renderer: THREE.WebGLRenderer = new THREE.WebGLRenderer({ antialias: true });
    public rayCaster: RayCast = {
        renderer: new THREE.Raycaster(),
        mouseVector: new THREE.Vector2(-100, -100),
    };
    // public points: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
    public controls: CanvasCameraControls;

    public controller: Controller;
    public type: ViewType;
    public action: Action;
    public axesHelper: THREE.AxesHelper;
    public camera!: THREE.PerspectiveCamera | THREE.OrthographicCamera;
    public object?: THREE.Object3D;
    public parent!: Canvas3dView;

    /**
     * 物体控制器
     */
    public objectControl!: TransformControls;

    public get objects(): THREE.Object3D[] {
        return this.scene.objectGroup.children;
    }

    public get points(): THREE.Points[] {
        return this.scene.context.children as THREE.Points[];
    }
    // public labelRenderer: CSS2DRenderer;

    public get mouseResizePosDir(): undefined | PositionDirection {
        const { x, y } = this.rayCaster.mouseVector;
        if (x < 0 && y > 0) {
            return PositionDirection.top_left;
        }
        if (x > 0 && y > 0) {
            return PositionDirection.top_right;
        }
        if (x < 0 && y < 0) {
            return PositionDirection.bottom_left;
        }
        if (x > 0 && y < 0) {
            return PositionDirection.bottom_right;
        }
    }

    // 使用方便的转接
    public set mode(value: Mode) {
        this.controller.mode = value;
    }

    public get mode(): Mode {
        return this.controller.mode;
    }

    public get activeID(): string {
        if (this.controller.data.activeElement.clientID) {
            return this.controller.data.activeElement.clientID + '';
        } else if (this.controller.data.activeElement.issueID) {
            return 'issue' + this.controller.data.activeElement.issueID;
        }
        return '';
    }

    constructor(controller: Controller, action: Action, type: ViewType = ViewType.PERSPECTIVE) {
        this.controller = controller;
        this.action = action;
        this.type = type;

        this.controls = new CanvasCameraControls(
            this.renderer.domElement,
            [ViewType.FRONT, ViewType.SIDE, ViewType.TOP].includes(type)
                ? CameraType.orthographic
                : CameraType.perspective,
        );

        // 三视图中，仍然使用自己的camera，而不使用组件
        if (!type || ViewType.PERSPECTIVE === type) {
            this.controls.onCameraChange = (camera) => {
                this.updateCamera(camera);
            };
        }

        this.axesHelper = new THREE.AxesHelper(10);

        this.initTransform();
    }

    private onObjectChange = (data: any) => {
        // console.log('更改：', data);
        if (this.object) {
            if ((this.object as any).isPoint) {
                (this.object.parent as Bezier).updateByMeshs();
                this.parent.updateThreeViewsLookAt(this.objectControl.object.position, 0.2);
            }
        }
    };

    private onObjectDraggingChange = (event: { value: unknown }) => {
        // console.log(`拖拽----${event.value === true ? '开始' : '结束'}`, event);
        this.controls.enabled = !event.value;

        if (event.value === true) {
            this.mode = Mode.EDIT;
        }

        if (event.value === false) {
            // 移动停止，更新对象位置
            this.mode = Mode.IDLE;
            this.parent.updateObjectEvent(this.object);
        }
    };

    private initTransform() {
        if (!this.camera) {
            return;
        }
        if (this.objectControl) {
            this.objectControl.removeEventListener('change', this.onObjectChange);
            this.objectControl.removeEventListener('dragging-changed', this.onObjectDraggingChange);
            this.objectControl.dispose();
            this.objectControl.removeFromParent();
        }
        this.objectControl = new TransformControls(this.camera, this.renderer.domElement);
        this.objectControl.showZ = false;
        this.objectControl.addEventListener('objectChange', this.onObjectChange);
        this.objectControl.addEventListener('dragging-changed', this.onObjectDraggingChange);
        this.scene.add(this.objectControl);
    }

    private updateCamera(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera) {
        this.camera = camera;
        this.initTransform();
    }

    public get isCanActive(): boolean {
        return [Mode.IDLE, Mode.EDIT, Mode.birdEye].includes(this.mode);
    }

    public mouseCursorDefault(): void {
        this.renderer.domElement.style.cursor = 'default';
    }
    public mouseCursorCrosshair(): void {
        if (this.action.selectable) {
            this.renderer.domElement.style.cursor = 'crosshair';
        }
    }
    public mouseCursorMove(): void {
        if (this.moveable()) {
            this.renderer.domElement.style.cursor = 'move';
        }
    }
    public mouseCursorGrab(): void {
        if (this.moveable()) {
            this.renderer.domElement.style.cursor = 'grab';
        }
    }
    public mouseCursorGrabbing(): void {
        if (this.moveable()) {
            this.renderer.domElement.style.cursor = 'grabbing';
        }
    }

    public init(): void {
        this.scene.clear();
    }

    public updateMousePosition = (e: MouseEvent, isLeave?: boolean): void => {
        if (isLeave) {
            this.rayCaster.mouseVector.x = -100;
            this.rayCaster.mouseVector.y = -100;
            return;
        }
        // 更新鼠标位置信息
        const canvas = this.renderer.domElement;
        const { mouseVector } = this.rayCaster;
        const rect = canvas.getBoundingClientRect();
        mouseVector.x = ((e.clientX - (canvas.offsetLeft + rect.left)) / canvas.clientWidth) * 2 - 1;
        mouseVector.y = -((e.clientY - (canvas.offsetTop + rect.top)) / canvas.clientHeight) * 2 + 1;
    };

    public getActiveObject(): THREE.Object3D | undefined {
        if (this.activeID) {
            return this.getObjectByName(this.activeID);
        }
    }

    public readable(): boolean {
        const object = this.getActiveObject() as THREE.Mesh;
        if (!object) {
            return false;
        }
        if (object.geometry instanceof THREE.BoxGeometry) {
            const obj = object?.userData as ObjectState;
            return obj && !obj.hidden;
        } else if (object.geometry instanceof THREE.SphereGeometry) {
            const obj = object?.userData as Issue;
            return obj && !obj.hidden;
        }
        return false;
    }

    public moveable(): boolean {
        const object = this.getActiveObject() as THREE.Mesh;
        if (!object) {
            return false;
        }
        if (object.geometry instanceof THREE.BoxGeometry) {
            const obj = object?.userData as ObjectState;
            return obj && !obj.lock && !this.controller.configuration.forceDisableEditing;
        } else if (object.geometry instanceof THREE.SphereGeometry) {
            const obj = object?.userData as Issue;
            return obj && !obj.resolve && !obj.readOnly;
        } else if (object instanceof Bezier) {
            const obj = object?.userData as ObjectState;

            return obj && !obj.lock && !this.controller.configuration.forceDisableEditing;
        }
        return false;
    }

    public editable(): boolean {
        const object = this.getActiveObject() as THREE.Mesh;
        if (!object) {
            return false;
        }
        if (object.geometry instanceof THREE.BoxGeometry) {
            const obj = object?.userData as ObjectState;
            return obj && !obj.hidden && !obj.lock && !this.controller.configuration.forceDisableEditing;
        } else if (object.geometry instanceof THREE.SphereGeometry) {
            const obj = object?.userData as Issue;
            return obj && !obj.hidden && !obj.resolve && !obj.readOnly;
        } else if (object instanceof THREE.Group) {
            const obj = object?.userData as ObjectState;
            return obj && !obj.hidden && !obj.lock && !this.controller.configuration.forceDisableEditing;
        }
        return false;
    }

    public getObjectByName(name: string): THREE.Object3D | undefined {
        return this.scene?.getObjectByName(name);
    }

    public isMouseMoveOnObject(): boolean {
        this.rayCaster.renderer.setFromCamera(this.rayCaster.mouseVector, this.camera);

        const { renderer } = this.rayCaster;
        const object = this.getActiveObject();
        if (object) {
            const intersects = renderer.intersectObjects(this.objects, false);
            if (intersects.length !== 0) {
                return true;
            }
        }
        return false;
    }

    public add(object: THREE.Object3D) {
        this.scene.objectGroup.add(object);
    }

    public remove(object: THREE.Object3D) {
        this.scene.objectGroup.remove(object);
    }

    public clear() {
        this.scene.objectGroup.clear();
    }

    // public updateRanle() {
    //     this.annotaionRangles();
    //     this.annotationAngle();
    // }

    // public annotaionRangle = (cameraRadius: number) => {
    //     // 删除之前的

    //     if (cameraRadius && !isNaN(cameraRadius)) {
    //         // 范围半径
    //         const curve = new EllipseCurve(
    //             0,
    //             0, // ax, aY
    //             cameraRadius,
    //             cameraRadius, // xRadius, yRadius
    //             0,
    //             2 * Math.PI, // aStartAngle, aEndAngle
    //             false, // aClockwise
    //             0, // aRotation
    //         );
    //         const newpoints = curve.getPoints(50);
    //         const geometry = new THREE.BufferGeometry().setFromPoints(newpoints);
    //         const newmaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
    //         const ellipse = new THREE.Line(geometry, newmaterial);
    //         ellipse.name = 'annotaionRangle';

    //         this.scene.add(ellipse);
    //     }
    // };

    // public annotaionRangles = () => {
    //     const annotaionRangleObjects = this.scene.getObjectsByProperty('name', 'annotaionRangle');

    //     if (annotaionRangleObjects?.length) {
    //         this.scene.remove(...annotaionRangleObjects);
    //     }

    //     const { cameraRadius } = this.controller.data.pcdParameter;
    //     if (cameraRadius) {
    //         cameraRadius.forEach((radius) => {
    //             if (radius && !isNaN(radius)) {
    //                 this.annotaionRangle(radius);
    //             }
    //         });
    //     }
    // };

    // public annotationAngle = () => {
    //     const { viewAngle = 0, viewStartMiddleAngle = 0 } = this.controller.data.pcdParameter;

    //     const line1 = this.scene.getObjectByName('annotationAngle1');
    //     const line2 = this.scene.getObjectByName('annotationAngle2');

    //     line1?.removeFromParent();
    //     line2?.removeFromParent();

    //     if (viewAngle) {
    //         const lineMaterial = new THREE.LineBasicMaterial({
    //             color: 'yellow',
    //         });
    //         const angle = viewAngle * (Math.PI / 180);
    //         const middleAngle = viewStartMiddleAngle * (Math.PI / 180);
    //         const lineLength = new THREE.Vector2(viewAngle, 0); // 第一条线沿x轴半径长
    //         const lineLength2 = lineLength.clone(); // 第二条线沿x轴半径长长
    //         // 第一条线沿x轴半径长，围绕圆心旋转半个cameraViewAngle， 获得第一条边
    //         lineLength.rotateAround(new THREE.Vector2(0, 0), middleAngle + angle / 2);
    //         // 第二条线沿x轴半径长，围绕圆心逆旋转半个cameraViewAngle， 获得第二条边
    //         lineLength2.rotateAround(new THREE.Vector2(0, 0), middleAngle - angle / 2);

    //         const linePoints = [];
    //         const center = new THREE.Vector3(0, 0, 0);
    //         const linePoint2 = new THREE.Vector3(lineLength.x, lineLength.y, 0);
    //         const linePoint3 = new THREE.Vector3(lineLength2.x, lineLength2.y, 0);
    //         linePoints.push(center);
    //         linePoints.push(linePoint2);
    //         const geometry = new THREE.BufferGeometry().setFromPoints(linePoints);
    //         const line = new THREE.Line(geometry, lineMaterial);
    //         line.name = 'annotationAngle1';

    //         const geometry2 = new THREE.BufferGeometry().setFromPoints([center, linePoint3]);
    //         const newline = new THREE.Line(geometry2, lineMaterial);
    //         newline.name = 'annotationAngle2';

    //         this.scene.add(line);
    //         this.scene.add(newline);
    //     }
    // };

    // public addNewHelper(): void {
    //     this.scene.add(this.axesHelper);

    //     if ([ViewType.PERSPECTIVE, ViewType.TOP].includes(this.type)) {
    //         this.annotaionRangles();
    //         this.annotationAngle();
    //     }
    // }

    public deactive() {
        if (this.object) {
            this.object = undefined;
        }
        if (this.objectControl && this.objectControl.object) {
            this.objectControl?.detach();
        }
    }

    public active(object: THREE.Object3D, objectState: ObjectState) {
        this.deactive();

        this.object = object;

        if ([ShapeType.laneline, ShapeType.bezier2].includes(objectState.shapeType)) {
            this.objectControl.showZ = true;
        } else {
            this.objectControl.showZ = false;
        }

        if (object !== this.objectControl.object && this.editable()) {
            // console.log('关联的对象：', object);
            this.objectControl.attach(object);
        }
    }

    public updatePointsPositions(octree: any): void {
        this.scene.removeContexts();

        this.scene.updateContexts(octree.root);

        // this.points.geometry.setAttribute('position', new THREE.Float32BufferAttribute(position, 3));
        // if (color) {
        //     this.points.geometry.setAttribute('color', new THREE.Float32BufferAttribute(color, 3));
        // }
        // if (intensity) {
        //     this.points.geometry.setAttribute('intensity', new THREE.Float32BufferAttribute(intensity, 1));
        // }

        // this.points.geometry.computeBoundingSphere();
    }

    public html(): HTMLCanvasElement {
        return this.renderer.domElement;
    }
}

export default BaseView;
