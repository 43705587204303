/*
 * @Author: swxy
 * @Date: 2023-07-11 14:26:57
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
    'jobs.applyJob': '申请题包',
    'jobs.apply': '申请',
    'jobs.cancel': '取消',
    'jobs.giveUp': '放弃',

    'jobs.getTeamUsersFailedTip': '获取团队列表失败！',
    'jobs.getTeamTasksFailedTip': '获取任务列表失败！',
    'jobs.applyJobFailedTip': '申请题包失败！',
    'jobs.recoveryJobFailedTip': '放弃题包失败！',

    'jobs.filter.working': '作业中',
    'jobs.filter.review': '审核中',
    'jobs.filter.accept': '验收中',
    'jobs.filter.finish': '已通过',

    'jobs.job': '题包：',
    'jobs.task': '任务：',
    'jobs.project': '项目：',

    'jobs.noTask': '该团队没有可申请任务！',
    'jobs.applySuccess': '申请成功！',
    'jobs.applyFilterError': '请先选择任务',
    'jobs.applyFilterPlaceholder': '请选择题包所在任务',

    'jobs.giveUpJob': '放弃这个题包',
    'jobs.giveUpJobSuccess': '放弃题包成功！',
    'jobs.giveUpJobError': '放弃题包失败！',

    'jobs.jobStatus': '题包状态',
    'jobs.shapeNum': '总框数',
    'jobs.noteRate': '元素正确率',
    'jobs.frameNum': '总帧数',
    'jobs.statusDate': '上次题包状态更改时间',
    'jobs.annotatior': '标注员',
    'jobs.firstReviewer': '一审人',
    'jobs.secordReviewer': '二审人',
    'jobs.acceptor': '验收员',
};
