/*
 * @Author: swxy
 * @Date: 2023-05-18 12:03:10
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export var getScrollBarHeight = function getScrollBarHeight(dom) {
  if (dom) {
    return dom.offsetHeight - dom.clientHeight;
  }
  return 0;
};
export var getScrollBarWidth = function getScrollBarWidth(dom) {
  if (dom) {
    return dom.offsetWidth - dom.clientWidth;
  }
  return 0;
};