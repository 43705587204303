/*
 * @Author: swxy
 * @Date: 2023-04-23 17:24:46
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
   
    'setting.settingTip': 'ショートカットキー {{setting}',
    'setting.changePassword': 'パスワードの変更',

    'setting.changePasswordSuccess': 'パスワードの変更に成功しました！',
    'setting.changePasswordConfirm': '変更',
    
}