// Copyright (C) 2021-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import Layout from 'antd/lib/layout';
import Result from 'antd/lib/result';
import Spin from 'antd/lib/spin';
import notification from 'antd/lib/notification';

import ReadOnlyWorkspaceComponent from './workSpace';
import ReadOnlyTopBarComponent from './top/top';
import { CombinedState } from 'reducers/interfaces';
import { usePrevious } from 'utils/hooks';
import Button from 'antd/lib/button';

import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeFrameAsync,
    getJobAsync,
    closeJob as closeJobAction,
} from 'actions/annotation-actions';
import LabelSiderComponent from './labelSider';
import { useTranslation } from 'react-i18next';

interface Props {
}

export default function AnnotationPageComponent(props: Props): JSX.Element {

    const { t } = useTranslation();

    const searchParams = new URLSearchParams(location.search);
    const taskId = searchParams.get('taskId');
    const jobId = searchParams.get('jobId');

    const dispatch = useDispatch();
    const history = useHistory();
    const initialFilters: object[] = [];
    let initialFrame = 0;

    if (searchParams.has('frame')) {
        const searchFrame = +(searchParams.get('frame') as string);
        if (!Number.isNaN(searchFrame)) {
            initialFrame = searchFrame;
        }
    }



    if (searchParams.has('frame')) {
        history.replace(`${history.location.pathname}?taskId=${taskId}&jobId=${jobId}`);
    }


    const { job, fetching, frameNumber } = useSelector((state: CombinedState) => ({
        job: +(jobId as string) === state.annotation.job.requestedId ? state.annotation.job.instance : null,
        fetching: state.annotation.job.fetching,
        frameNumber: state.annotation.player.frame.number,
    }))

    const prevJob = usePrevious(job);
    const prevFetching = usePrevious(fetching);


    const getJob = (): void => {
        if (taskId && jobId) {
            dispatch(getJobAsync(+taskId, +jobId, initialFrame, initialFilters));
        }
    }

    const closeJob = (): void => {
        dispatch(closeJobAction());
    }

    const changeFrame = (frame: number): void => {
        dispatch(changeFrameAsync(frame));
    }

    useEffect(() => {
        const root = window.document.getElementById('root');
        if (root) {
            root.style.minHeight = '720px';
        }

        return () => {
            if (root) {
                root.style.minHeight = '';
            }

            if (!history.location.pathname.endsWith('/job')) {
                closeJob();
            }
        };
    }, []);

    useEffect(() => {
        if (job === null && !fetching) {
            getJob();
        }
    }, [job, fetching]);

    useEffect(() => {
        if (prevFetching && !fetching && !prevJob && job) {
            const latestFrame = localStorage.getItem(`Job_${job.id}_frame`);
            if (latestFrame && Number.isInteger(+latestFrame)) {
                const parsedFrame = +latestFrame;
                if (parsedFrame !== frameNumber && parsedFrame >= job.startFrame && parsedFrame <= job.stopFrame) {
                    const notificationKey = `cvat-notification-continue-job-${job.id}`;
                    notification.info({
                        key: notificationKey,
                        // message: `You finished working on frame ${parsedFrame}`,
                        message: t('task.lastFrame', { parsedFrame }),
                        description: (
                            <span>
                                {t('task.lastFrameHelp_pre')}
                                <Button
                                    className='cvat-notification-continue-job-button'
                                    type='link'
                                    onClick={() => {
                                        changeFrame(parsedFrame);
                                        notification.close(notificationKey);
                                    }}
                                >
                                    {t('task.this')}
                                </Button>
                                {t('task.lastFrameHelp_last', { parsedFrame })}
                                {/* if you would like to continue */}
                            </span>
                        ),
                        placement: 'topRight',
                        className: 'cvat-notification-continue-job',
                    });
                }
            }

            if (!job.labels.length) {
                notification.warning({
                    message: t('task.noLabelHelp'),
                    description: (
                        <span>
                            {t('task.noLabel')}。
                        </span>
                    ),
                    placement: 'topRight',
                    className: 'cvat-notification-no-labels',
                });
            }
        }
    }, [job, fetching, prevJob, prevFetching]);

    if (!jobId) {
        history.replace('/tasks');
    }

    if (job === null) {
        return <Spin size='large' className='cvat-spinner' />;
    }

    if (typeof job === 'undefined') {
        return (
            <Result
                className='cvat-not-found'
                status='404'
                title={t('task.nofoundJob')}
                subTitle={
                    <div>
                        {t('task.pleaseClick')}
                        <Button
                            type='link'
                            onClick={() => {
                                history.push('/');
                            }}
                        >
                            {t('task.this')}
                        </Button>
                        {t('task.backHome')}
                    </div>
                }
            />
        );
    }

    return (
        <Layout className='cvat-annotation-page'>
            <Layout.Header className='cvat-annotation-header'>
                {/* <AnnotationTopBarContainer /> */}
                <ReadOnlyTopBarComponent />
            </Layout.Header>
            <Layout.Content className='cvat-annotation-layout-content'>
                <ReadOnlyWorkspaceComponent />
            </Layout.Content>
            <LabelSiderComponent />
        </Layout>
    );
}
