/*
 * @Author: swxy
 * @Date: 2022-12-15 10:10:49
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import {
    BoxGeometry,
    Mesh,
    Vector3,
    MeshBasicMaterial,
    EdgesGeometry,
    LineSegments,
    LineBasicMaterial,
    Matrix4,
    BufferAttribute,
    Color,
    Object3D,
    SphereGeometry,
    // CustomBlending,
    // OneMinusSrcColorFactor,
    // SrcColorFactor,
} from 'three';
import { ViewType } from '../interface';
import constants from '../consts';
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils';
import Object3DManager, { setLineColor } from './object3DManager';

const points: [Vector3, Vector3, Vector3, Vector3, Vector3, Vector3, Vector3, Vector3] = [
    new Vector3(0.5, -0.5, 0.5), // 0
    new Vector3(0.5, -0.5, -0.5), // 1
    new Vector3(0.5, 0.5, 0.5), // 2
    new Vector3(0.5, 0.5, -0.5), // 3

    new Vector3(-0.5, 0.5, 0.5),
    new Vector3(-0.5, 0.5, -0.5),
    new Vector3(-0.5, -0.5, 0.5),
    new Vector3(-0.5, -0.5, -0.5),
];

export interface Indexable {
    [key: string]: any;
}

const directionColorStr = '#FFFF00';

// export function setLineColor(geometry: EdgesGeometry, outlineColor: string): void {
//     // 两个点确定一条线的颜色。
//     // 一共十二条线，只要确定前四条就可以
//     const position = geometry.getAttribute('position');
//     const defaultColor = new Color(outlineColor);
//     const defaultColors = new Float32Array(position.array.length);
//     const userColors = defaultColor.toArray();

//     const directionColor = new Color(directionColorStr);
//     const directionColors = directionColor.toArray();
//     // 一条线两个点， 所以是两个点的读取
//     for (let i = 0; i < position.count; i += 2) {
//         const num = i * position.itemSize;
//         const x1 = position.array[num];
//         const x2 = position.array[num + position.itemSize];

//         // 当一条线的两个点，都在x轴正方向时。
//         // 代表这条线，是x轴正方向里面的，那一面的四条线中一个。标为车头方向标识。
//         if (x1 > 0 && x2 > 0) {
//             defaultColors.set([...directionColors, ...directionColors], num);
//         } else {
//             defaultColors.set([...userColors, ...userColors], num);
//         }
//     }

//     const colors = new Float32Array(defaultColors);
//     const color = new BufferAttribute(colors, 3);
//     geometry.setAttribute('color', color);
// }

class CuboidModel extends Object3DManager {
    // public perspective: Mesh;
    // public top: Mesh;
    // public side: Mesh;
    // public front: Mesh;

    constructor(name: number, color: string) {
        super(name, color);
        const geometry = new BoxGeometry(1, 1, 1);
        const material = new MeshBasicMaterial({
            color: color,
            wireframe: false,
            transparent: true,
            opacity: 0.1,
            // blending: CustomBlending,
            // blendDst: OneMinusSrcColorFactor,
            // blendSrc: SrcColorFactor,
        });
        const materialHeader = new MeshBasicMaterial({
            color: directionColorStr,
            wireframe: false,
            transparent: true,
            opacity: 0.1,
            // blending: CustomBlending,
            // blendDst: OneMinusSrcColorFactor,
            // blendSrc: SrcColorFactor,
        });
        const materials = [materialHeader, material, material, material, material, material];
        this.perspective = new Mesh(geometry, materials);
        const geo = new EdgesGeometry(this.perspective.geometry);
        setLineColor(geo, color);
        const wireframe = new LineSegments(
            geo,
            new LineBasicMaterial({
                linewidth: 0.5, // color: outlineColor,
                vertexColors: true,
                // color: color,
            }),
        );
        wireframe.name = constants.CUBOID_EDGE_NAME;
        wireframe.computeLineDistances();
        wireframe.renderOrder = 1;
        this.perspective.add(wireframe);
        // this.perspective = new Mesh(geometry, material);

        // this.top = new Mesh(geometry, [...materials]);
        // this.side = new Mesh(geometry, [...materials]);
        // this.front = new Mesh(geometry, [...materials]);

        const newMaterial = material.clone();
        newMaterial.opacity = 0;
        const newMaterialHeader = materialHeader.clone();
        newMaterialHeader.opacity = 0;

        this.top = this.perspective.clone();
        this.side = this.perspective.clone();
        this.front = this.perspective.clone();

        this.top.material = [newMaterialHeader, newMaterial, newMaterial, newMaterial, newMaterial, newMaterial];
        this.side.material = [newMaterialHeader, newMaterial, newMaterial, newMaterial, newMaterial, newMaterial];
        this.front.material = [newMaterialHeader, newMaterial, newMaterial, newMaterial, newMaterial, newMaterial];

        this.perspective.name = `${name}`;
        this.top.name = `${name}`;
        this.side.name = `${name}`;
        this.front.name = `${name}`;

        // const camRotateHelper = new Object3D();
        // camRotateHelper.translateX(-2);
        // camRotateHelper.name = 'camRefRot';
        // camRotateHelper.up = new Vector3(0, 0, 1);
        // camRotateHelper.lookAt(new Vector3(0, 0, 0));
        // this.front.add(camRotateHelper.clone());
    }

    // public setPosition(x: number, y: number, z: number): void {
    //     [ViewType.PERSPECTIVE, ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view): void => {
    //         (this as Indexable)[view].position.set(x, y, z);
    //     });
    // }

    // public setScale(x: number, y: number, z: number): void {
    //     [ViewType.PERSPECTIVE, ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view): void => {
    //         (this as Indexable)[view].scale.set(x, y, z);
    //     });
    // }

    // public setRotation(x: number, y: number, z: number): void {
    //     [ViewType.PERSPECTIVE, ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view): void => {
    //         (this as Indexable)[view].rotation.set(x, y, z);
    //     });
    // }

    // // 以矩阵的形式，调整位置
    // public applyMatrix4(matrix: Matrix4) {
    //     [ViewType.PERSPECTIVE, ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view): void => {
    //         ((this as Indexable)[view] as Mesh).applyMatrix4(matrix);
    //     });
    // }

    // public setOpacity(opacity: number): void {
    //     [ViewType.PERSPECTIVE, ViewType.TOP, ViewType.SIDE, ViewType.FRONT].forEach((view): void => {
    //         const material = (this as Indexable)[view].material as MeshBasicMaterial | MeshBasicMaterial[];
    //         if (Array.isArray(material)) {
    //             material.forEach((mat) => {
    //                 mat.opacity = opacity / 100;
    //             });
    //         } else {
    //             material.opacity = opacity / 100;
    //         }
    //     });
    // }

    // public attachCameraReference(): void {
    //     // Attach Cam Reference
    //     const topCameraReference = new Object3D();
    //     topCameraReference.translateZ(2);
    //     topCameraReference.name = constants.CAMERA_REFERENCE;
    //     this.top.add(topCameraReference);
    //     this.top.userData = { ...this.top.userData, camReference: topCameraReference };

    //     const sideCameraReference = new Object3D();
    //     sideCameraReference.translateY(2);
    //     sideCameraReference.name = constants.CAMERA_REFERENCE;
    //     this.side.add(sideCameraReference);
    //     this.side.userData = { ...this.side.userData, camReference: sideCameraReference };

    //     const frontCameraReference = new Object3D();
    //     frontCameraReference.translateX(2);
    //     frontCameraReference.name = constants.CAMERA_REFERENCE;
    //     this.front.add(frontCameraReference);
    //     this.front.userData = { ...this.front.userData, camReference: frontCameraReference };
    // }

    // public getReferenceCoordinates(viewType: string): Vector3 {
    //     const { elements } = (this as Indexable)[viewType].getObjectByName(constants.CAMERA_REFERENCE).matrixWorld;
    //     return new Vector3(elements[12], elements[13], elements[14]);
    // }
}

// export const selectMesh = (mesh: Mesh, opacity: number) => {
//     const material = mesh.material as MeshBasicMaterial | MeshBasicMaterial[];
//     if (Array.isArray(material)) {
//         material.forEach((mat) => {
//             mat.opacity = opacity / 100;
//         });
//     } else {
//         material.opacity = opacity / 100;
//     }

//     const edges = new EdgesGeometry(mesh.geometry);
//     setLineColor(edges, '#ffffff');
//     const line = new LineSegments(
//         edges,
//         new LineBasicMaterial({
//             // color: '#ffffff',
//             linewidth: 3,
//             vertexColors: true,
//         }),
//     );
//     line.name = constants.CUBOID_EDGE_NAME;
//     mesh.add(line);
// };

// export const unSelectMesh = (mesh: Mesh, opacity: number) => {
//     const material = mesh.material as MeshBasicMaterial | MeshBasicMaterial[];
//     if (Array.isArray(material)) {
//         material.forEach((mat) => {
//             mat.opacity = opacity / 100;
//         });
//     } else {
//         material.opacity = opacity / 100;
//     }

//     const edges = mesh.getObjectByName(constants.CUBOID_EDGE_NAME);
//     edges?.removeFromParent();
// };

// export function setTranslationHelper(instance: Mesh): void {
//     const sphereGeometry = new SphereGeometry(0.1);
//     const sphereMaterial = new MeshBasicMaterial({ color: '#ffffff', opacity: 1, visible: false });
//     instance.geometry.deleteAttribute('normal');
//     instance.geometry.deleteAttribute('uv');
//     // eslint-disable-next-line no-param-reassign
//     instance.geometry = mergeVertices(instance.geometry);
//     const vertices = [];
//     const positionAttribute = instance.geometry.getAttribute('position');
//     for (let i = 0; i < positionAttribute.count; i++) {
//         const vertex = new Vector3();
//         vertex.fromBufferAttribute(positionAttribute, i);
//         vertices.push(vertex);
//     }
//     const helpers = [];
//     for (let i = 0; i < vertices.length; i++) {
//         helpers[i] = new Mesh(sphereGeometry.clone(), sphereMaterial.clone());
//         helpers[i].position.set(vertices[i].x, vertices[i].y, vertices[i].z);
//         helpers[i].up.set(0, 0, 1);
//         helpers[i].name = 'resizeHelper';
//         instance.add(helpers[i]);
//         helpers[i].scale.set(1 / instance.scale.x, 1 / instance.scale.y, 1 / instance.scale.z);
//     }
//     // eslint-disable-next-line no-param-reassign
//     instance.userData = { ...instance.userData, resizeHelpers: helpers };
// }

// export function createRotationHelper(instance: Mesh, viewType: ViewType): void {
//     const sphereGeometry = new SphereGeometry(0.1);
//     const sphereMaterial = new MeshBasicMaterial({ color: '#ffffff', visible: false, opacity: 1 });
//     const rotationHelper = new Mesh(sphereGeometry, sphereMaterial);
//     rotationHelper.name = constants.ROTATION_HELPER;
//     switch (viewType) {
//         case ViewType.TOP:
//             rotationHelper.position.set(
//                 (instance.geometry as BoxGeometry).parameters.height / 2 + constants.ROTATION_HELPER_OFFSET,
//                 0,
//                 0,
//             );
//             instance.add(rotationHelper.clone());
//             // eslint-disable-next-line no-param-reassign
//             instance.userData = { ...instance.userData, rotationHelpers: rotationHelper.clone() };
//             break;
//         case ViewType.SIDE:
//         case ViewType.FRONT:
//             rotationHelper.position.set(
//                 0,
//                 0,
//                 (instance.geometry as BoxGeometry).parameters.depth / 2 + constants.ROTATION_HELPER_OFFSET,
//             );
//             instance.add(rotationHelper.clone());
//             // eslint-disable-next-line no-param-reassign
//             instance.userData = { ...instance.userData, rotationHelpers: rotationHelper.clone() };
//             break;
//         default:
//             break;
//     }
// }

export default CuboidModel;
