/*
 * @Author: swxy
 * @Date: 2023-02-20 16:21:59
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
/*
 * @Author: swxy
 * @Date: 2022-07-26 15:53:04
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

// 主视图
import * as THREE from 'three';
import { Controller } from './controller';
import { Action, ActivationModel, CameraMode, Mode, ShapeType } from './interface';
import { Listener } from './master';
import { Quaternion, Vector2, Vector3, Box3 } from 'three';
import BaseView from './baseView';
// import LanelineMesh from './objects/lanelineMesh';
import Bezier from './objects/bezier/bezierModel';
import Lane from './objects/lane/laneModel';

export interface PerspectiveViewProps {
    rayCaster: RayCast;
    // 主视图场景
    scene: THREE.Scene;
    // 渲染注释图场景
    renderer: THREE.WebGLRenderer;
    // 主视图scene的children
    // points: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>;
    camera: THREE.PerspectiveCamera | THREE.OrthographicCamera;
    // drawMode: Mode;

    setSceneChildren(points: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>): void;
    getObjectByName(name: string): THREE.Object3D | undefined;
    html(): HTMLCanvasElement;
    render(): void;
    keyControls(keys: KeyboardEvent): void;
}

export interface ViewsDOM {
    domElement: HTMLCanvasElement;
}

export interface RayCast {
    renderer: THREE.Raycaster;
    mouseVector: THREE.Vector2;
}

export class PerspectiveView extends BaseView implements PerspectiveViewProps, Listener {
    // 外部变量 声明初始化
    private drawMode: CameraMode = CameraMode.perspective;

    private moveName?: string;

    constructor(controller: Controller, action: Action) {
        super(controller, action);

        const canvasPerspectiveView = this.renderer.domElement;

        const height = window.innerHeight;
        const width = window.innerWidth;

        this.renderer.setSize(width, height);

        let mouseMoveOffset = 0;

        const moveOffset = 20;

        canvasPerspectiveView.addEventListener('click', (e: MouseEvent): void => {
            e.preventDefault();
            if (e.ctrlKey || this.action.cameraMove || this.action.drawObject.active) {
                // 正在移动相机视角中，或绘图中,无视点击事件
                this.action.cameraMove = false;
                mouseMoveOffset = 0;
                return;
            }

            if (this.isDrawLaneline) {
                return;
            }

            this.moveObjectPosition(e);

            // 单击选中
            if (!this.isMoveActive && this.isCanActive) {
                this.updateMousePosition(e);
                this.activeEvent();
            }
        });

        canvasPerspectiveView.addEventListener('mousemove', (event: MouseEvent): void => {
            this.updateMousePosition(event);

            if (event.buttons >= 1) {
                mouseMoveOffset += Math.abs(event.movementX) + Math.abs(event.movementY);
                if (mouseMoveOffset > moveOffset) {
                    this.action.cameraMove = true;
                }
            }
        });

        canvasPerspectiveView.addEventListener('mousedown', this.mouseDown);
        canvasPerspectiveView.addEventListener('mouseup', this.mouseUp);
        canvasPerspectiveView.addEventListener('mousemove', this.mouseMove);

        window.addEventListener('keydown', this.ctrlDown);
        window.addEventListener('keyup', this.ctrlup);
    }

    /**
     * 是否是鼠标移动即选中模式
     */
    private get isMoveActive(): boolean {
        return this.controller.data.configuration.activeModel === ActivationModel.moveInActive;
    }

    private get isDrawLaneline(): boolean {
        return this.mode === Mode.DRAW && this.controller.data.drawData.shapeType === ShapeType.laneline;
    }

    // 自动贴合功能
    private autoFit(includePoints: Vector3[], minZ: number, maxZ: number) {
        // const centerPointInMesh =

        const noBottomPoints = includePoints.filter((point) => point.z >= minZ + 0.2);

        if (noBottomPoints.length <= 0) return null;

        noBottomPoints.sort((a, b) => a.y - b.y);
        noBottomPoints.sort((a, b) => a.x - b.x);

        //#region 获取旋转方向
        const direction = new Vector3(0, 0, 0);
        const tailMidCenterPoint = new Vector3(0, 0, 0);

        const midCount = noBottomPoints.length / 6;
        let rightCount = 0;
        let leftCount = 0;

        noBottomPoints.forEach((pointInMesh, index) => {
            if (index < midCount) {
                direction.x += pointInMesh.x;
                direction.y += pointInMesh.y;
                leftCount++;
            } else if (index >= noBottomPoints.length - midCount) {
                tailMidCenterPoint.x += pointInMesh.x;
                tailMidCenterPoint.y += pointInMesh.y;
                rightCount++;
            }
        });

        direction.x = direction.x / leftCount;
        direction.y = direction.y / leftCount;

        tailMidCenterPoint.x = tailMidCenterPoint.x / rightCount;
        tailMidCenterPoint.y = tailMidCenterPoint.y / rightCount;

        direction.sub(tailMidCenterPoint);
        direction.normalize();

        if (!this.action.drawObject.directionLeft) {
            direction.applyEuler(new THREE.Euler(0, 0, Math.PI));
        }
        //#endregion

        //#region 创建包含所有点的方框
        // 1、将所有包含点反向旋转
        // 2、创建box，包含反向点
        // 3、算出当前中心点，并更改中心点z值
        // 4、使中心点应用旋转，并将结果作为目标物的中心点。
        // 5、box中的xy作为物体缩放的长宽。

        const quaternion = new Quaternion().setFromUnitVectors(new Vector3(1, 0, 0), direction);

        const regNoBottomPoints = noBottomPoints.map((point) => {
            return point.clone().applyQuaternion(quaternion.clone().invert());
        });

        const boxInMesh = new THREE.Box3();
        boxInMesh.setFromPoints(regNoBottomPoints);
        const copyCenter = boxInMesh.getCenter(new Vector3());
        copyCenter.z = (maxZ + minZ) / 2;
        copyCenter.applyQuaternion(quaternion);

        const { x: rotationX, y: rotationY, z: rotationZ } = new THREE.Euler().setFromQuaternion(quaternion);
        const { max, min } = boxInMesh;

        const disparityX = max.x - min.x;
        const disparityY = max.y - min.y;
        let length = Math.max(disparityX, disparityY) + 0.1;
        let width = Math.min(disparityX, disparityY) + 0.1;
        const height = maxZ - minZ + 0.1;

        const points = [
            copyCenter.x,
            copyCenter.y,
            copyCenter.z,
            rotationX,
            rotationY,
            rotationZ,
            length,
            width,
            height,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ];

        return points;
    }

    private getPoints(
        includePoints: Vector3[],
        minZ: number,
        maxZ: number,
        data: {
            position: Vector3;
            scale: Vector3;
            rotation: Vector3;
        },
    ) {
        if (this.controller.data.configuration.birdEyeAutoFit) {
            return this.autoFit(includePoints, minZ, maxZ);
        }

        if (includePoints.length <= 0) return null;

        data.position.setZ((maxZ + minZ) / 2);
        data.scale.setZ(maxZ - minZ + 0.1);

        const copyCenter = data.position.clone();
        const { x: rotationX, y: rotationY, z: rotationZ } = data.rotation;
        const { x: length, y: width, z: height } = data.scale;

        return [
            copyCenter.x,
            copyCenter.y,
            copyCenter.z,
            rotationX,
            rotationY,
            rotationZ,
            length,
            width,
            height,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ];
    }

    private createObjectEvent(event: MouseEvent) {
        if (this.action.drawObject.active) {
            this.camera.updateProjectionMatrix();

            const canvas = this.renderer.domElement;
            const rect = canvas.getBoundingClientRect();
            const x = ((event.clientX - (canvas.offsetLeft + rect.left)) / canvas.clientWidth) * 2 - 1;
            const y = -((event.clientY - (canvas.offsetTop + rect.top)) / canvas.clientHeight) * 2 + 1;

            const initX = (this.action.drawObject.initPosition.x / canvas.clientWidth) * 2 - 1;
            const initY = -(this.action.drawObject.initPosition.y / canvas.clientHeight) * 2 + 1;

            const { renderer } = this.rayCaster;
            renderer.setFromCamera(new THREE.Vector2(x, y), this.camera);

            // const { geometry } = this.points;
            // const positions = geometry.getAttribute('position');

            // const minZ = Number.MIN_SAFE_INTEGER;
            const maxMeshTop = (this.camera.near + this.camera.far) / (this.camera.near - this.camera.far);
            const z = (this.camera.near + this.camera.far) / (this.camera.near - this.camera.far);

            // 绘画出的矩形，中心点坐标
            const centerPos = new Vector2((initX + x) / 2, (initY + y) / 2);
            const center = new Vector3(centerPos.x, centerPos.y, z).unproject(this.camera);

            // const geo = new THREE.BoxGeometry(1, 1, 1);

            // const material = new MeshBasicMaterial({
            //     color: 'green',
            //     wireframe: false,
            //     transparent: true,
            //     opacity: 0.4,
            // });
            // const materialHeader = new MeshBasicMaterial({
            //     color: 'yellow',
            //     wireframe: false,
            //     transparent: true,
            //     opacity: 0.4,
            // });
            // const materials = [materialHeader, material, material, material, material, material];

            // const mesh = new THREE.Mesh(geo, materials);
            // mesh.position.set(center.x, center.y, 0);

            // const scale = new Vector3(1, 1, 1);

            // 绘画出的矩形4个点， 转换为空间坐标
            const position1 = new Vector3().set(initX, initY, maxMeshTop).unproject(this.camera);
            const position2 = new Vector3().set(x, initY, maxMeshTop).unproject(this.camera);
            const position3 = new Vector3().set(x, y, maxMeshTop).unproject(this.camera);
            const position4 = new Vector3().set(initX, y, maxMeshTop).unproject(this.camera);

            // scale.x = position1.distanceTo(position2);
            // scale.y = position1.distanceTo(position4);
            // scale.z = 20;
            // mesh.scale.set(scale.x, scale.y, scale.z);

            // if (this.action.drawObject.directionLeft) {
            //     mesh.rotateZ(Math.PI);
            // }
            // mesh.applyQuaternion(this.camera.quaternion);

            // mesh.updateMatrixWorld();
            // mesh.geometry.computeBoundingBox();
            // let box = mesh.geometry.boundingBox;
            const includePoints: Vector3[] = [];

            let minZ = Number.MAX_SAFE_INTEGER;
            let maxZ = Number.MIN_SAFE_INTEGER;

            const position = new Vector3(center.x, center.y, 0);
            const scale = new Vector3(position1.distanceTo(position2), position1.distanceTo(position4), 20);
            const quaternion = new Quaternion().copy(this.camera.quaternion);

            const matrix = new THREE.Matrix4().compose(position, quaternion, scale);
            const invert = matrix.invert();

            const box: Box3 = new Box3().setFromCenterAndSize(new Vector3(0, 0, 0), new Vector3(1, 1, 1));

            this.controller.data.pointsCloud.containPointsBox(box, includePoints, invert);

            for (let index = 0; index < includePoints.length; index++) {
                const point = includePoints[index];
                minZ = Math.min(minZ, point.z);
                maxZ = Math.max(maxZ, point.z);
            }

            const points = this.getPoints(includePoints, minZ, maxZ, {
                position,
                scale,
                rotation: new Vector3().setFromEuler(new THREE.Euler().setFromQuaternion(quaternion)),
            });

            if (!points) return;
            // mesh.setRotationFromEuler(new THREE.Euler(rotationX, rotationY, rotationZ));
            // mesh.position.set(copyCenter.x, copyCenter.y, copyCenter.z);
            // mesh.scale.set(length, width, height);

            this.parent?.createObject({
                points,
                shapeType: ShapeType.CUBOID,
            });

            // for (let index = 0; index < positions.count; index++) {
            //     const point = new THREE.Vector3().fromBufferAttribute(positions, index);
            //     const { x, y, z } = point;
            //     if (box.containsPoint(mesh.worldToLocal(point))) {
            //         includePoints.push(point);
            //         colors.push(rr, rg, rb);
            //     } else {
            //         colors.push(r, g, b);
            //     }
            // }

            // this.points.geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));
        }
    }

    // private controlsStart = () => {
    //     // if (this.controls.azimuthRotateSpeed > this.controls.restThreshold) {
    //     this.action.cameraMove = true;
    //     // }
    // };

    // private controlsStop = () => {
    //     this.action.cameraMove = false;
    // };

    // private addListenerControls() {
    // this.controls.removeEventListener('controlstart', this.controlsStart);
    // // // 相机是否在调整中监听
    // this.controls.addEventListener('controlstart', this.controlsStart);
    // this.controls.addEventListener(
    //     'controlend',
    //     this.controls.mouseButtons.left !== CameraControls.ACTION.NONE ? this.controlsStart : this.controlsStop,
    // );
    // this.controls.addEventListener('sleep', this.controlsStop);
    // this.controls.addEventListener('rest', this.controlsStop);
    // }

    // private perspectiveCamera() {
    //     // const aspectRatio = window.innerWidth / window.innerHeight;
    //     const width = this.renderer.domElement?.parentElement?.clientWidth || window.innerWidth;
    //     const height = this.renderer.domElement?.parentElement?.clientHeight || window.innerHeight;
    //     const aspectRatio = width / height;
    //     this.camera = new THREE.PerspectiveCamera(50, aspectRatio, 1, 500);
    //     this.camera.position.set(40, 40, 40);
    //     this.camera.up.set(0, 0, 1);
    //     this.camera.lookAt(0, 0, 0);
    //     this.camera.name = 'cameraPerspective';

    //     // this.controls = new CameraControls(this.camera, this.renderer.domElement);
    //     // setControls(this.controls);

    //     // this.controls.minDistance = CONST.MIN_DISTANCE;
    //     // this.controls.maxDistance = CONST.MAX_DISTANCE;

    //     this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    //     // this.controls.listenToKeyEvents(window); // optional
    //     // this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    //     this.controls.dampingFactor = 0.05;
    //     this.controls.screenSpacePanning = false;
    //     this.controls.mouseButtons.RIGHT = THREE.MOUSE.RIGHT;
    //     // this.controls.minDistance = CONST.MIN_DISTANCE;
    //     // this.controls.maxDistance = CONST.MAX_DISTANCE;
    //     // this.controls.maxZoom = 3;
    //     // this.controls.saveState();

    //     // this.renderer.domElement.removeEventListener('wheel', this.wheelChange);
    //     // this.addListenerControls();

    //     this.cancel();
    // }

    private ctrlDown = (event: KeyboardEvent) => {
        if (this.mode === Mode.birdEye) {
            // this.controls.control.azimuthAngle = this.controls.control.azimuthAngle;
            if (event.ctrlKey && this.controls.enabled && this.mode === Mode.birdEye) {
                this.controls.enabled = false;
            }
        }
    };

    private ctrlup = (event: KeyboardEvent) => {
        if (this.mode === Mode.birdEye) {
            if (event.ctrlKey === false && this.controls.enabled === false) {
                this.controls.enabled = true;
                this.action.drawObject.remove();
            }
        }
    };

    // private orthographicCamera() {
    //     const viewSize = CONST.Camera_ZOOM_FACTOR;
    //     const width = this.renderer.domElement?.parentElement?.clientWidth || window.innerWidth;
    //     const height = this.renderer.domElement?.parentElement?.clientHeight || window.innerHeight;
    //     const aspectRatio = width / height;
    //     this.camera = new THREE.OrthographicCamera(
    //         -aspectRatio * viewSize,
    //         aspectRatio * viewSize,
    //         viewSize,
    //         -viewSize,
    //         -50,
    //         50,
    //     );

    //     this.camera.position.set(0, 0, 40);
    //     this.camera.up.set(0, 0, 1);
    //     this.camera.lookAt(0, 0, 0);
    //     this.camera.name = 'cameraPerspective';
    //     this.camera.updateProjectionMatrix();

    //     // this.controls = new CameraControls(this.camera, this.renderer.domElement);
    //     // setNoneControls(this.controls);
    //     // this.controls.mouseButtons.right = CameraControls.ACTION.TRUCK;
    //     // // this.controls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
    //     // this.controls.mouseButtons.wheel = CameraControls.ACTION.ROTATE;
    //     // this.controls.minZoom = 0.5;
    //     // this.controls.minDistance = CONST.MIN_DISTANCE;
    //     // this.controls.maxDistance = CONST.MAX_DISTANCE;

    //     this.controls?.dispose();

    //     this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    //     // this.controls.listenToKeyEvents(window); // optional
    //     // this.controls.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
    //     this.controls.dampingFactor = 0.05;
    //     this.controls.screenSpacePanning = false;
    //     // this.controls.minDistance = CONST.MIN_DISTANCE;
    //     // this.controls.maxDistance = CONST.MAX_DISTANCE;
    //     this.controls.maxPolarAngle = 0;
    //     // this.controls.maxAzimuthAngle = 0;
    //     // this.controls.maxZoom = 3;
    //     this.controls.saveState();

    //     // let isChange = false;
    //     // this.controls.addEventListener('change', (data) => {
    //     //     if (isChange === false && this.action.cameraMove === false) {
    //     //         isChange = true;
    //     //         this.action.cameraMove = true;
    //     //     }
    //     // });

    //     // this.controls.addEventListener('start', () => {
    //     //     // this.action.cameraMove = true;
    //     //     isChange = false;
    //     // });

    //     // this.controls.addEventListener('end', () => {
    //     //     setTimeout(() => {
    //     //         this.action.cameraMove = false;
    //     //     });
    //     // });
    //     // this.addListenerControls();

    //     // const plane = new THREE.Plane();

    //     this.cancel();
    //     if (this.isDrawLaneline) {
    //         this.controls.mouseButtons.LEFT = undefined;
    //     } else if (this.mode === Mode.birdEye) {
    //         window.addEventListener('keydown', this.ctrlDown);
    //         window.addEventListener('keyup', this.ctrlup);

    //         this.renderer.domElement.addEventListener('mousedown', this.mouseDown);
    //         this.renderer.domElement.addEventListener('mouseup', this.mouseUp);
    //         this.renderer.domElement.addEventListener('mousemove', this.mouseMove);
    //     } else if (this.mode === Mode.move_object) {
    //         // this.renderer.domElement.addEventListener('mousedown', this.moveObjectStart);
    //         // this.renderer.domElement.addEventListener('mouseup', this.moveObjectStop);
    //         this.renderer.domElement.addEventListener('click', this.moveObjectPosition);
    //     }
    // }

    private moveObjectPosition = (event: MouseEvent) => {
        if ([Mode.move_object].includes(this.mode) && this.moveName) {
            this.updateMousePosition(event);
            const { mouseVector, renderer } = this.rayCaster;
            // const intersectPoints = renderer.intersectObjects([this.points], false);
            const moveObject = this.scene.getObjectByName(this.moveName)!;
            // if (intersectPoints?.length) {
            const newPos = renderer.ray.origin.clone();
            moveObject.position.copy(newPos.setZ(moveObject.position.z));
            // moveObject.position.copy(intersectPoints[0].point.clone().setZ(moveObject.position.z));
            // }

            const clientID = Number(this.moveName);
            const { x, y, z } = moveObject.position;
            const { x: width, y: height, z: depth } = moveObject.scale;
            const { x: rotationX, y: rotationY, z: rotationZ } = moveObject.rotation;
            const points = [x, y, z, rotationX, rotationY, rotationZ, width, height, depth, 0, 0, 0, 0, 0, 0, 0];

            this.parent?.onUpdatePosition(clientID, points);

            this.moveName = undefined;
        } else if ([Mode.move_object].includes(this.mode) && !this.moveName) {
            this.updateMousePosition(event);
            const { mouseVector, renderer } = this.rayCaster;
            renderer.setFromCamera(mouseVector, this.camera);
            const intersects = renderer.intersectObjects(this.objects, false);
            if (
                [Mode.move_object].includes(this.mode) &&
                intersects?.length &&
                event.button === 0 &&
                intersects[0].object &&
                (intersects[0].object as THREE.Mesh).geometry instanceof THREE.BoxGeometry
            ) {
                // const moveObject = this.scene.getObjectByName(this.moveName);
                this.moveName = intersects[0].object.name;
            }
        }
    };

    private mouseDown = (event: MouseEvent) => {
        this.updateMousePosition(event);

        const { mouseVector, renderer } = this.rayCaster;
        this.camera.updateProjectionMatrix();
        renderer.setFromCamera(mouseVector, this.camera);
        const { clientX, clientY } = event;

        if (event.buttons === 1 && event.ctrlKey && !this.controls.enabled) {
            const canvas = this.renderer.domElement;
            const rect = canvas.getBoundingClientRect();
            const offsetX = clientX - (canvas.offsetLeft + rect.left);
            const offsetY = clientY - (canvas.offsetTop + rect.top);
            // const offsetX = clientX - rect.x;
            // const offsetY = clientY - rect.y;

            this.action.drawObject.parent = this.renderer.domElement.parentElement!;
            this.action.drawObject.start(offsetX, offsetY);
            // this.action.drawObject.start(clientX, clientY);

            this.action.drawObject.init3DPosition = renderer.ray.origin.clone();
        }
    };

    private mouseUp = (event: MouseEvent) => {
        this.updateMousePosition(event);

        const { mouseVector, renderer } = this.rayCaster;
        renderer.setFromCamera(mouseVector, this.camera);

        this.createObjectEvent(event);
        this.action.drawObject.remove();
    };

    private mouseMove = (event: MouseEvent) => {
        this.updateMousePosition(event);

        const { clientX, clientY } = event;
        const canvas = this.renderer.domElement;
        const rect = canvas.getBoundingClientRect();
        const offsetX = clientX - (canvas.offsetLeft + rect.left);
        const offsetY = clientY - (canvas.offsetTop + rect.top);
        // const offsetX = clientX - rect.x;
        // const offsetY = clientY - rect.y;
        this.action.drawObject.move(offsetX, offsetY);
        // this.action.drawObject.move(clientX, clientY);
    };

    public async changeDrawMode(mode: CameraMode) {
        if (this.drawMode !== mode) {
            this.drawMode = mode;
            if (mode === CameraMode.orthographic) {
                await this.controls.onOrthographicCamera();
            } else {
                await this.controls.onPerspectiveCamera();
            }
            this.camera = this.controls.camera;
        }
    }

    public cancelIdle() {
        this.controls.cancel();

        this.drawMode = CameraMode.perspective;
        this.camera = this.controls.camera;
    }

    private activeEvent(): void {
        // const { children } = this.points;
        const { mouseVector, renderer } = this.rayCaster;
        renderer.setFromCamera(mouseVector, this.camera);
        let clientID = null;
        const intersects = renderer.intersectObjects(this.objects, false);

        // const groups = this.objects.filter((obj) => (obj as THREE.Group).isGroup);
        // if (groups?.length) {
        //     const intersects = renderer.intersectObjects([...groups.flatMap((group) => group.children)], true);
        //     if (intersects.length) {
        //         clientID = Number(intersects[0].object.parent!.name);
        //         this.action.movePointName = intersects[0].object.name;

        //         const laneline = this.controller.data.objects3D.get(clientID) as LanelineMesh;

        //         laneline.updatePointMeshes();

        //         if (this.action.movePointName) {
        //             const meshPoint = laneline.get(this.action.movePointName);
        //             meshPoint.setColor(new THREE.Color('white'));
        //             this.parent.resetThreeCamera();
        //         }

        //         this.dispatchEvent(
        //             new CustomEvent('canvas.selected', {
        //                 bubbles: false,
        //                 cancelable: true,
        //                 detail: {
        //                     clientID,
        //                 },
        //             }),
        //         );
        //         return;
        //     }
        // }

        let issueID = null;
        if (intersects.length !== 0) {
            // if ((intersects[0].object as Bezier).isBezier) {
            //     clientID = Number(intersects[0].object.name);
            // } else if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.BoxGeometry) {
            //     // 点击的是方体
            //     clientID = Number(intersects[0].object.name);
            // } else
            if ((intersects[0].object as THREE.Mesh).geometry instanceof THREE.SphereGeometry) {
                // 点击的是方体
                issueID = Number(intersects[0].object.userData.id);
            } else {
                clientID = Number(intersects[0].object.name);
            }

            if ((intersects[0].object as Bezier).isBezier || (intersects[0].object as Lane).isLane) {
                // const bezier = intersects[0].object as Bezier;
                this.parent?.onReSelectObject();
            }
            // } else {
            //     this.deactive();
        }

        if (this.controller.data.activeElement.clientID !== clientID) {
            this.dispatchEvent(
                new CustomEvent('canvas.selected', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        clientID,
                        issueID,
                    },
                }),
            );
        }

        if (this.controller.data.activeElement.issueID !== issueID) {
            this.dispatchEvent(
                new CustomEvent('canvas.selected', {
                    bubbles: false,
                    cancelable: true,
                    detail: {
                        clientID,
                        issueID,
                    },
                }),
            );
        }
    }

    private getIntersects(): any[] {
        if (![Mode.GROUP, Mode.IDLE].includes(this.mode)) return [];

        const { mouseVector, renderer } = this.rayCaster;
        // 通过摄像机和鼠标位置更新射线
        const { children } = this.scene.children[0];
        renderer.setFromCamera(mouseVector, this.camera);
        const intersects = renderer.intersectObjects(children, false);
        return intersects;
    }

    private dispatchEvent(event: CustomEvent): void {
        this.renderer.domElement.dispatchEvent(event);
    }

    private clearScene(): void {
        this.scene.children = [];
    }

    // #region 不断更新的事件

    private renderRayCaster = (): void => {
        this.rayCaster.renderer.setFromCamera(this.rayCaster.mouseVector, this.camera);
        // if (this.mode === Mode.IDLE) {
        if (this.isCanActive) {
            const { renderer } = this.rayCaster;
            const intersects = renderer.intersectObjects(this.objects, false);

            if (intersects.length !== 0) {
                this.renderer.domElement.style.cursor = 'crosshair';
                // setNoneControls(this.controls);
            } else {
                this.renderer.domElement.style.cursor = 'default';
                // setControls(this.controls);
            }
        }

        if ([Mode.move_object].includes(this.mode)) {
            const { renderer } = this.rayCaster;
            const intersects = renderer.intersectObjects(this.objects, false);
            if (intersects.length !== 0) {
                this.renderer.domElement.style.cursor = 'move';
            } else {
                this.renderer.domElement.style.cursor = 'default';
            }

            if (this.moveName) {
                // const intersectPoints = renderer.intersectObjects([this.points], false);
                // 移动中。。。
                // if (intersectPoints?.length) {
                const moveObject = this.scene.getObjectByName(this.moveName);
                const newPos = renderer.ray.origin.clone();
                moveObject!.position.copy(newPos.setZ(moveObject!.position.z));
                // }
            }
        }
    };

    public render(): void {
        // 没有加载完成无需执行接下来的操作
        if (!(this.controls && this.camera && this.rayCaster)) return;
        // this.views[view].controls.update();
        const hasControlsUpdated = this.controls.isNeedUpdateRender(this.renderer);

        // 如果视图窗口resize的话，重新计算相机位置和视图大小
        // this.resizeRendererToDisplaySize(this.camera, this.renderer);

        if (hasControlsUpdated) {
            // 相机是否更新，由于外部可能更新场景。因此场景不止由相机引起
        }

        if (!this.controller.data.activeElement.clientID) {
            this.deactive();
        }

        // 实时更新相机与场景
        this.renderer.render(this.scene, this.camera);
        // this.labelRenderer?.render(this.scene, this.camera);

        if (this.scene.children.length !== 0 && this.scene.context.children.length) {
            this.renderRayCaster();
        }
    }

    // #endregion

    public setSceneChildren(points: THREE.Points<THREE.BufferGeometry, THREE.PointsMaterial>): void {
        this.scene.children[0] = points;
        // this.points = points;
        // this.addNewHelper();
    }

    public getObjectByName(name: string): THREE.Object3D | undefined {
        return this.scene.getObjectByName(name);
    }

    public keyControls(key: any): void {}

    public notify(master: Controller, reason: string): void {
        // if (reason === UpdateReasons.IMAGE_CHANGED) {
        //     // 变更点云
        // }
    }

    public cancel() {
        this.moveName = undefined;

        window.removeEventListener('keydown', this.ctrlDown);
        window.removeEventListener('keyup', this.ctrlup);

        this.renderer.domElement.removeEventListener('mousedown', this.mouseDown);
        this.renderer.domElement.removeEventListener('mouseup', this.mouseUp);
        this.renderer.domElement.removeEventListener('mousemove', this.mouseMove);
    }
}
