/*
 * @Author: swxy
 * @Date: 2023-04-21 12:00:32
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';

import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Timeline from 'antd/lib/timeline';
import {
    Tooltip,
} from 'antd';
import { useHistory } from 'react-router';

import {
    AmygoLogoWhite,
} from 'icons';
import {
    CombinedState,
} from 'reducers/interfaces';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

interface Props {
}

function LeftGroup(props: Props): JSX.Element {
    const {
        saving,
        savingStatuses,
        // jobInstance,
    } = useSelector((state: CombinedState) => ({
        saving: state.annotation.annotations.saving.uploading,
        savingStatuses: state.annotation.annotations.saving.statuses,
        // jobInstance: state.annotation.job.instance,
    }))

    const history = useHistory();
    const { t } = useTranslation();
    const goBack = (): void => {
        const { location } = history;
        history.push(`/projects`, location.state);
    };

    return (
        <>
            <Modal title='Saving changes on the server' open={saving} footer={[]} closable={false}>
                <Timeline pending={savingStatuses[savingStatuses.length - 1] || 'Pending..'}>
                    {savingStatuses.slice(0, -1).map((status: string, id: number) => (
                        <Timeline.Item key={id}>{status}</Timeline.Item>
                    ))}
                </Timeline>
            </Modal>
            <Col className='cvat-annotation-header-left-group'>
                <Tooltip title={t('workspace.top.back')}>
                    <Button type='link' className='cvat-annotation-header-button' onClick={goBack}>
                        <AmygoLogoWhite className='aatp_annotation_header_left_logo' />
                    </Button>
                </Tooltip>
            </Col>
        </>
    );
}

export default React.memo(LeftGroup);
