/*
 * @Author: swxy
 * @Date: 2022-09-14 15:03:21
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export default {
    'hotKey.rotateUp.name': 'Camera up',
    'hotKey.rotateUp.description': 'Rotate the camera upwards around the center of the camera view',
    'hotKey.rotateDown.name': 'Camera down',
    'hotKey.rotateDown.description': 'Rotate the camera downwards around the center of the camera view',
    'hotKey.rotateLeft.name': 'Camera left',
    'hotKey.rotateLeft.description': 'Rotate the camera to the left around the center of the camera view',
    'hotKey.rotateRight.name': 'Camera right',
    'hotKey.rotateRight.description': 'Rotate the camera to the right around the center of the camera view',
    'hotKey.moveDown.name': 'Camera down',
    'hotKey.moveDown.description': 'Move the camera downwards',
    'hotKey.moveUp.name': 'Camera up',
    'hotKey.moveUp.description': 'Move the camera upwards',
    'hotKey.moveLeft.name': 'Camera left',
    'hotKey.moveLeft.description': 'Move the camera to the left',
    'hotKey.moveRight.name': 'Camera right',
    'hotKey.moveRight.description': 'Move the camera to the right',
    'hotKey.zoomIn.name': 'Camera zoom in',
    'hotKey.zoomIn.description': 'Begin zooming in with the camera',
    'hotKey.zoomOut.name': 'Camera zoom out',
    'hotKey.zoomOut.description': 'Begin zooming out with the camera',
    'hotKey.moveCam.name': '3D mode for moving perspective view',
    'hotKey.moveCam.description': 'Move current 3D perspective view',
    'hotKey.3dShape.name': 'Add 3D frame',
    'hotKey.3dShape.description': 'Add 3D frame',
    'hotKey.selectAll.name': 'All objects in current frame',
    'hotKey.selectAll.description': 'Select all objects in the current frame',
    'hotKey.panelModel.name': 'Panel list mode',
    'hotKey.panelModel.description': 'Switch between single and multiple display modes for the list',
    'hotKey.lineV.name': 'Vertical dividing line',
    'hotKey.lineV.description': 'Add a vertical dividing line to the selected rectangular object.',
    'hotKey.lineH.name': 'Horizontal dividing line',
    'hotKey.lineH.description': 'Add a horizontal dividing line to the selected rectangular object.',
    'hotKey.deletePoints/Lines.name': 'Delete current point/line',
    'hotKey.deletePoints/Lines.description': 'When a point/line object is selected, delete the point that the mouse is currently clicking on. The object remains unchanged.',
    'hotKey.cancelRelation.name': 'Cancel relationship',
    'hotKey.cancelRelation.description': 'Cancel the association of the selected object. When the parent object is selected, it will cancel the association with all child objects.',
    'hotKey.nextObject.name': 'Select next object',
    'hotKey.nextObject.description': 'Select the next object of the current active object in the current frame',
    'hotKey.prevObject.name': 'Select previous object',
    'hotKey.prevObject.description': 'Select the previous object of the current active object in the current frame',

    'hotKey.rotateClockwise.name': 'Rotate 90° clockwise',
    'hotKey.rotateAntiClockwise.name': 'Rotate 90° counterclockwise',
    'hotKey.rotateClockwise.description': 'Rotate 90° clockwise with respect to the top view of the currently selected object',
    'hotKey.rotateAntiClockwise.description': 'Rotate 90° counterclockwise with respect to the top view of the currently selected object',

    'hotKey.updateObjectPosition.allowLeft': 'Move selected object left',
    'hotKey.updateObjectPosition.allowLeftDetail': 'In the current focused three views, move the object to the left according to the direction of the current view',
    'hotKey.updateObjectPosition.allowRight': 'Move selected object right',
    'hotKey.updateObjectPosition.allowRightDetail': 'In the current focused three views, move the object to the right according to the direction of the current view',
    'hotKey.updateObjectPosition.allowUp': 'Move selected object up',
    'hotKey.updateObjectPosition.allowUpDetail': 'In the current focused three views, move the object up according to the direction of the current view',
    'hotKey.updateObjectPosition.allowDown': 'Move selected object down',
    'hotKey.updateObjectPosition.allowDownDetail': 'In the current focused three views, move the object down according to the direction of the current view'

};
