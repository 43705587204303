/*
 * @Author: swxy
 * @Date: 2023-08-25 21:12:05
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
export class Exception extends Error {
    constructor(message: string) {
        super(message);
    }
}

/**
 * 参数错误
 */
export class ArgumentError extends Exception {
    constructor(message: string) {
        super(message);
    }
}

/**
 * 接口错误
 */
export class ServerError extends Exception {
    constructor(message: string, code?: number | string) {
        super(message);

        Object.defineProperties(
            this,
            Object.freeze({
                /**
                 * @name code
                 * @type {(string|integer)}
                 * @memberof
                 * @readonly
                 * @instance
                 */
                code: {
                    get: () => code,
                },
            }),
        );
    }
}

/**
 * 数据错误
 */
export class DataError extends Exception {
    constructor(message: string) {
        super(message);
    }
}

export class ScriptingError extends Exception {
    constructor(message: string) {
        super(message);
    }
}

export default Exception;
