/*
 * @Author: swxy
 * @Date: 2023-06-07 15:54:56
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { Vector3, ColorRepresentation, Color, SphereGeometry, MeshBasicMaterial, Mesh } from 'three';
// import BaseObject from '../baseObject';
import Bezier from './bezierModel';

export enum PointType {
    bezierPoint = 'bezierPoint',
    bezierControl = 'bezierControl',
}

class AmyPoint extends Mesh {
    public clientID: number;
    public pointIndex: number;
    public type: PointType = PointType.bezierPoint;
    public radius: number;

    public readonly isPoint = true;

    // public position: Vector3;
    // public color: Color;

    // public object: THREE.Mesh;

    /**
     *
     * @param name 名称
     * @param position 位置
     * @param color 颜色
     * @param pointIndex 第几个点
     * @param opacity 透明度（无效）
     * @param radius 半径
     */
    constructor(
        clientID: number,
        name: string,
        type: PointType = PointType.bezierPoint,
        position: Vector3 = new Vector3(0, 0, 0),
        color: ColorRepresentation = 0xffff00,
        pointIndex: number,
        opacity: number = 20,
        radius: number = 0.2,
    ) {
        const geometry = new SphereGeometry(radius);
        const material = new MeshBasicMaterial({
            color: color,
            transparent: false,
            opacity: opacity / 100,
        });
        super(geometry, material);
        this.clientID = clientID;
        this.type = type;
        this.position.set(position.x, position.y, position.z);
        this.renderOrder = 1;

        this.pointIndex = pointIndex;

        this.name = name;
    }

    public updatePosition(pos: THREE.Vector3) {
        // this.perspective.position.set(pos.x, pos.y, pos.z);
        // this.top.position.set(pos.x, pos.y, pos.z);
        // this.side.position.set(pos.x, pos.y, pos.z);
        // this.front.position.set(pos.x, pos.y, pos.z);
    }

    // public setColor(color: THREE.Color) {
    //     ((this.perspective as THREE.Mesh).material as THREE.MeshBasicMaterial).color.set(color);
    // }

    public move() {
        (this.parent as Bezier).updateByMeshs();
    }
}

export default AmyPoint;
