/*
 * @Author: swxy
 * @Date: 2022-08-24 15:34:57
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

interface AttributeValue {
    id: number;
    specId: number;
    value: string;
}

interface Attribute {
    id: number;
    defaultValue: string;
    inputType: string;
    mutable: boolean;
    name: string;
    titles: string;
    values: string[];
    ranges: string[];
}

interface Label {
    attributes: Attribute[];
}

export enum InputType {
    default = 'normal',
    serialid = 'serialid',
    select = 'select',
    radio = 'radio',
    checkbox = 'checkbox',
    number = 'number',
    text = 'text',
}

/**
 *  导出{属性key: 属性value}值
 * @param atts  Record<number, AttributeValue> 目标物中的属性值键值对
 * @param label  目标的标签属性
 * @returns {属性key: 属性value}
 */
export const getExportAttributes = (atts: Record<number, AttributeValue>, label: Label) => {
    const { attributes = [] } = label;
    // 遍历当前标签属性列表
    return attributes.reduce((previous: Record<string, string | number>, current: Attribute) => {
        // 获取该对象中，这条属性的属性值对象
        const valueObj = atts?.[current.id]; // 没有值，则取默认值
        previous[current.name] = valueObj?.value || current.defaultValue;
        return previous;
    }, {});
};
