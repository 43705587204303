/*
 * @Author: swxy
 * @Date: 2023-07-01 10:14:43
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Radio, RadioChangeEvent, Space, message, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import '../styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState, SubElement, SubElementInfo, SubElementType, SubElementVersion } from 'reducers/interfaces';
import { updateSubAnnotationsAsync } from 'actions/annotationSub-actions';
// import { Canvas } from 'cvat-canvas-wrapper';
import { ProjectType, ShapeObjFrType } from 'utils/ConstType';
import { updateAnnotationsAsync } from 'actions/annotation-actions';
import { Canvas3d } from 'canvas3d';


interface Props {
    objectState: any;
    readonly: boolean;
    addSubPoint?: () => void;
}

// const numPointsOfShapeObjFrType: Record<number, number> = {
//     [ShapeObjFrType.car]: 4,
//     [ShapeObjFrType.truck]: 4,
//     [ShapeObjFrType.bus]: 4,
//     [ShapeObjFrType.pickTruck]: 4,
//     [ShapeObjFrType.engineVehicle]: 4,
//     [ShapeObjFrType.tricycle]: 3,
//     [ShapeObjFrType.bicycle]: 2,
//     [ShapeObjFrType.motorcycle]: 2,
// }

const occludedOptions = [{ label: '是', value: true }, { label: '否', value: false }];

const pointTypeOptions = [{ label: '起始点', value: 'start' }, { label: '终止点', value: 'end' }];

const colors = ['orange', 'aqua', 'greenyellow', 'mediumpurple', 'lightslategray'];


// const getNumOfPointsByShapeObjFrType = (label: any) => {
//     if (label) {
//         const attribute = label.attributes.find((attr: any) => attr.name === 'shapeObjFrType');
//         if (attribute) {
//             const value = +attribute.defaultValue
//             if (value >= 0) {
//                 return numPointsOfShapeObjFrType[value];
//             }

//         }
//     }
//     return 4;
// }

// const isUnknowShapeObjType = (label: any) => {
//     if (label) {
//         const attribute = label.attributes.find((attr: any) => attr.name === 'shapeObjFrType');
//         if (attribute) {
//             const value = +attribute.defaultValue
//             if (value === 17) {
//                 return true;
//             }
//         }
//     }
//     return false;
// }

const SuspensionElement: FC<Props> = (props) => {

    const { objectState, readonly, addSubPoint } = props;

    // const isUnknow = isUnknowShapeObjType(objectState.label);
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [selectPointIndex, setSelectPointIndex] = useState<number>(-1);

    const { jobInstance, states } = useSelector((state: CombinedState) => ({
        jobInstance: state.annotation.job.instance,
        states: state.annotation.annotations.states,
    }));

    // const canvasInstance = useSelector((state: CombinedState) =>
    //     jobInstance.projectType === ProjectType.mix
    //         ? state.subAnnotation.canvas.instance
    //         : state.annotation.canvas.instance) as Canvas3d;

    const element = objectState.elements[0] as SubElement;
    const subPoint = element.points[selectPointIndex] as SubElementInfo;

    const updateSelectPoint = () => {
        if (selectPointIndex !== -1) {
            const shapeView = window.document.getElementById(`aatp_canvas_sub_shape_${objectState.clientID}`);
            if (shapeView) {
                const handler = (shapeView as any).instance;
                if (handler) {
                    const childrens = handler.children();
                    if (childrens) {
                        childrens.forEach((children: any, index: number) => {
                            if (index === selectPointIndex) {
                                children.addClass('aatp_canvas_sub_select_point');
                            }
                        });
                    }
                }
            }
        }
    }

    // const onPointClick = (e: any) => {
    //     const { pointID, objectState: selectState, e: mouseE } = (e as CustomEvent).detail;

    //     if (mouseE) {
    //         (mouseE as MouseEvent)?.preventDefault();
    //         (mouseE as MouseEvent)?.stopPropagation();
    //     }
    //     const { elements } = objectState;
    //     // const subPoint = elements[0].points[pointID];
    //     // console.log('选中的点：', objectState);
    //     setSelectPointIndex(pointID);
    // }

    const onAddSubPoint = () => {
        const { elements } = objectState;
        const [element] = elements;
        if ((element as SubElement).points.length >= (element as SubElement).numOfPoints) {
            message.error('车轮点已达类型的上限！');
            return;
        }
        addSubPoint?.();
    }

    const onDeleteElement = () => {
        // console.log('删除子元素：');
        objectState.elements = [];
        if (objectState.direction) {
            dispatch(updateSubAnnotationsAsync([objectState]));
        } else {
            dispatch(updateAnnotationsAsync([objectState]));
        }
    }

    const onChangeSelectPointIndex = (type: 'add' | 'sub') => {
        if (type === 'add') {
            const { elements } = objectState;
            setSelectPointIndex(Math.min(selectPointIndex + 1, elements[0].points.length - 1));
        } else if (type === 'sub') {
            setSelectPointIndex(Math.max(selectPointIndex - 1, 0));
        }
    }

    const onChangeElementOccluded = (e: RadioChangeEvent) => {
        try {
            const { elements } = objectState;
            elements[0].points[selectPointIndex].occluded = e.target.value;
            objectState.elements = [...objectState.elements];
            dispatch(updateSubAnnotationsAsync([objectState]));
        } catch (error) {
            console.error('变更目标物的子目标物属性-遮挡失败', error);
            throw new Error('变更目标物的子目标物属性-遮挡失败');
        }
    }

    const onChangeElementOrder = (e: RadioChangeEvent, index: number) => {
        try {
            const { elements } = objectState;
            elements[0].points[index].order = e.target.value;
            objectState.elements = [...objectState.elements];
            dispatch(updateSubAnnotationsAsync([objectState]));
        } catch (error) {
            console.error('变更目标物的子目标物属性-顺序失败', error);
            throw new Error('变更目标物的子目标物属性-顺序失败');
        }
    }

    const onChangeElementPointType = (e: RadioChangeEvent) => {
        try {
            const { elements } = objectState;
            elements[0].points[selectPointIndex].pointType = e.target.value;
            objectState.elements = [...objectState.elements];
            dispatch(updateSubAnnotationsAsync([objectState]));
        } catch (error) {
            console.error('变更目标物的子目标物属性-点类型失败', error);
            throw new Error('变更目标物的子目标物属性-点类型失败');
        }
    }

    const onChangeElementPointNumber = (value: number | null) => {
        try {
            if (typeof value === 'number') {
                const { elements } = objectState;
                (elements[0] as SubElement).numOfPoints = value;
                objectState.elements = [...objectState.elements];
            }
            dispatch(updateSubAnnotationsAsync([objectState]));
        } catch (error) {
            console.error('变更目标物的子目标物属性-点类型失败', error);
            throw new Error('变更目标物的子目标物属性-点类型失败');
        }
    }

    const onDeleteSubPoint = (index: number) => {
        try {
            if (typeof index === 'number') {
                const { elements } = objectState;
                (elements[0] as SubElement).points = (elements[0] as SubElement).points.filter((point, num) => index !== num);
                objectState.elements = [...objectState.elements];
            }
            dispatch(updateSubAnnotationsAsync([objectState]));
        } catch (error) {
            console.error('变更目标物的子目标物属性-点类型失败', error);
            throw new Error('变更目标物的子目标物属性-点类型失败');
        }
    }

    // useEffect(() => {
    //     const canvasElement = canvasInstance?.html().perspective;
    //     if (canvasElement) {
    //         // canvasInstance.html().addEventListener('canvas.resizeshape', updateSelectPoint);
    //         canvasElement.addEventListener('canvas.point.click', onPointClick);
    //     }
    //     return () => {
    //         if (canvasElement) {
    //             // canvasInstance.html().removeEventListener('canvas.resizeshape', updateSelectPoint);
    //             canvasElement.removeEventListener('canvas.point.click', onPointClick);
    //         }
    //     }
    // }, []);

    // useLayoutEffect(() => {
    //     updateSelectPoint();
    //     return () => {
    //         const shapeView = window.document.getElementById(`aatp_canvas_sub_shape_${objectState.clientID}`);
    //         if (shapeView) {
    //             const handler = (shapeView as any).instance;
    //             if (handler) {
    //                 const childrens = handler.children();
    //                 if (childrens) {
    //                     childrens.forEach((children: any) => {
    //                         children.removeClass('aatp_canvas_sub_select_point');
    //                     });
    //                 }
    //             }
    //         }
    //     }
    // }, [objectState, states, selectPointIndex]);


    // if (element.shapeType === SubElementType.wheelPoint) {
    //     const numOfPoints = element.numOfPoints || 0
    //     const orders = new Array(element.version >= SubElementVersion.version1_0_0 ? numOfPoints : numOfPoints + 1).fill(0).map((_: any, index: number) => ({
    //         label: index + 1,
    //         value: index + 1,
    //     }));


    //     if (subPoint) {
    //         return (
    //             <div className='aatp_suspension'>
    //                 <div className='aatp_suspension_row_center'>
    //                     子目标物点信息
    //                 </div>
    //                 <div className='aatp_suspension_row'>

    //                     <div className='aatp_suspension_row_center'>
    //                         <Space>
    //                             <Button onClick={() => onChangeSelectPointIndex('sub')}>上一个</Button>

    //                             <Button onClick={() => onChangeSelectPointIndex('add')}>下一个</Button>
    //                         </Space>

    //                     </div>
    //                 </div>
    //                 <div className='aatp_suspension_row'>
    //                     <div className='aatp_suspension_row_title'>
    //                         顺序：
    //                     </div>
    //                     <div className='aatp_suspension_row_content'>
    //                         <Radio.Group
    //                             options={orders}
    //                             value={subPoint.order}
    //                             onChange={(e) => {
    //                                 onChangeElementOrder(e, selectPointIndex);
    //                             }}
    //                             defaultValue={false}
    //                             disabled={readonly}
    //                         />
    //                     </div>
    //                 </div>
    //             </div>
    //         )
    //     }

    //     return (
    //         <div className='aatp_suspension'>
    //             {/* {isUnknow && <div className='aatp_suspension_row_center'>
    //                 <span>
    //                     有几个车轮：
    //                 </span>
    //                 <InputNumber
    //                     // defaultValue={getNumOfPointsByShapeObjFrType(objectState.label)}\
    //                     value={element.numOfPoints}
    //                     onChange={onChangeElementPointNumber}
    //                 />
    //             </div>
    //             } */}
    //             <Space>
    //                 <div>顺序：</div>
    //                 {colors.map((color, index) => {
    //                     return <div className={'aatp_color_box'} style={{ backgroundColor: color }}> {index === colors.length - 1 ? '其他' : index + 1}</div>
    //                 })}
    //             </Space>

    //             <div>
    //                 {element.points.map((point, index) => {

    //                     const order = Number.isInteger(+point.order!) ? +point.order! - 1 : colors.length - 1;
    //                     const color = colors[order];

    //                     // const color = colors[typeof point.order === 'number' ? point.order - 1 : (colors.length - 1)] || colors[colors.length - 1];
    //                     return <div className='aatp_suspension_row'>
    //                         <Space>
    //                             <div className={'aatp_color_box'} style={{ backgroundColor: color }} />
    //                             <div className='aatp_suspension_row_content'>
    //                                 <Radio.Group
    //                                     options={orders}
    //                                     value={point.order}
    //                                     onChange={(e) => {
    //                                         onChangeElementOrder(e, index)
    //                                     }}
    //                                     defaultValue={false}
    //                                     disabled={readonly}
    //                                 />
    //                             </div>
    //                             <div><Button
    //                                 type='dashed'
    //                                 danger
    //                                 onClick={() => {
    //                                     onDeleteSubPoint(index);
    //                                 }}
    //                                 disabled={readonly}
    //                             >删除</Button></div>
    //                         </Space>
    //                     </div>
    //                 })}
    //             </div>

    //             <div className='aatp_suspension_row_center'>
    //                 <Button
    //                     onClick={onAddSubPoint}
    //                     disabled={readonly}
    //                 >增加车轮点</Button>
    //                 <Button
    //                     danger
    //                     onClick={onDeleteElement}
    //                     disabled={readonly}
    //                 >删除车轮点</Button>
    //             </div>
    //         </div>
    //     )
    // }

    // if (subPoint) {
    //     return (
    //         <div className='aatp_suspension'>
    //             <div className='aatp_suspension_row_center'>
    //                 子目标物点信息
    //             </div>
    //             <div className='aatp_suspension_row'>

    //                 <div className='aatp_suspension_row_center'>
    //                     虚线点数：{element.points.length}
    //                     {/* <Space>
    //                         <Button onClick={() => onChangeSelectPointIndex('sub')}>上一个</Button>

    //                         <Button onClick={() => onChangeSelectPointIndex('add')}>下一个</Button>
    //                     </Space> */}

    //                 </div>
    //             </div>
    //             {/* {element.shapeType === SubElementType.dashedLine && <div className='aatp_suspension_row'>
    //                 <div className='aatp_suspension_row_title'>
    //                     是否遮挡：
    //                 </div>
    //                 <div className='aatp_suspension_row_content'>
    //                     <Radio.Group
    //                         options={occludedOptions}
    //                         onChange={onChangeElementOccluded}
    //                         value={subPoint.occluded}
    //                         defaultValue={false}
    //                         disabled={readonly}
    //                     />
    //                 </div>
    //             </div>} */}
    //             {/* {element.shapeType === SubElementType.wheelPoint && <div className='aatp_suspension_row'>
    //                 <div className='aatp_suspension_row_title'>
    //                     顺序：
    //                 </div>
    //                 <div className='aatp_suspension_row_content'>
    //                     <Radio.Group
    //                         options={orders}
    //                         value={subPoint.order}
    //                         onChange={(e) => {
    //                             onChangeElementOrder(e, selectPointIndex);
    //                         }}
    //                         defaultValue={false}
    //                         disabled={readonly}
    //                     />
    //                 </div>
    //             </div>} */}


    //             {/* {element.shapeType === SubElementType.dashedLine && <div className='aatp_suspension_row'>
    //                 <div className='aatp_suspension_row_title'>
    //                     点类型：
    //                 </div>
    //                 <div className='aatp_suspension_row_content'>
    //                     <Radio.Group
    //                         options={pointTypeOptions}
    //                         value={subPoint.pointType}
    //                         onChange={onChangeElementPointType}
    //                         defaultValue={'start'}
    //                         disabled={readonly}
    //                     />
    //                     {subPoint.pointType === 'start' ? '起始点' : '终止点'}
    //                 </div>
    //             </div>} */}


    //             {/* <div className='aatp_suspension_row_center'>
    //                 <Button danger onClick={onDeleteElement}>删除子目标物</Button>
    //             </div> */}
    //         </div>
    //     )
    // }

    return (
        <div className='aatp_suspension3D'>
            <div className='aatp_suspension_row_center'>
                子目标物信息
            </div>
            <div className='aatp_suspension_row_center'>
                虚线点数：{element.points.length}
            </div>
            <div className='aatp_suspension_row_center'>
                <Button
                    danger
                    onClick={onDeleteElement}
                    disabled={readonly}
                >删除子目标物</Button>
            </div>
        </div>
    )
}

export default SuspensionElement;