/*
 * @Author: swxy
 * @Date: 2023-01-10 15:57:40
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
import {
    Scene as BaseScene,
    AxesHelper,
    Float32BufferAttribute,
    Points,
    BufferGeometry,
    PointsMaterial,
    Group,
    EllipseCurve,
    LineBasicMaterial,
    Line,
    Vector2,
    Vector3,
    Object3D,
    SphereGeometry,
    Mesh,
    MeshBasicMaterial,
    BoxHelper,
} from 'three';

class Scene extends BaseScene {
    public context: Group; // 上下文--点云组
    public objectGroup: Group; // 对象组
    public pointsCount = 0;

    constructor() {
        super();

        this.context = new Group();
        this.objectGroup = new Group();
        this.context.name = 'point clouds';
        this.objectGroup.name = 'objects';
        // const material = new SpriteMaterial({ visible: false });

        // const sprite = new Sprite(material);
        // this.add(sprite);
        this.add(this.context);
        this.add(new AxesHelper(10));
        this.add(this.objectGroup);
    }

    public addObject(...objects: Object3D[]) {
        this.objectGroup.add(...objects);
    }

    public removeContexts() {
        this.pointsCount = 0;
        this.context.clear();
    }

    public addContext(...object: Object3D[]) {
        this.context.add(...object);
    }

    public updateContexts(node: any) {
        if (node.children?.length) {
            node.children.forEach((item: any) => {
                this.updateContexts(item);
            });
        } else if (node.data && node.data.positions?.length) {
            const { positions, colors, intensities } = node.data;

            const geometry = new BufferGeometry();
            const material = new PointsMaterial();

            material.size = 0.005;

            const points = new Points(geometry, material);
            // points.frustumCulled = true;

            points.geometry.setAttribute('position', new Float32BufferAttribute(positions, 3));
            if (colors?.length) {
                material.vertexColors = true;
                points.geometry.setAttribute('color', new Float32BufferAttribute(colors, 3));
            }
            // if (intensities?.length) {
            //     points.geometry.setAttribute('intensity', new Float32BufferAttribute(intensities, 1));
            // }

            points.geometry.computeBoundingSphere();

            this.pointsCount += Math.floor(positions.length / 3);

            this.context.add(points);
        }
    }

    public removeObjects(...objects: Object3D[]) {
        this.objectGroup.remove(...objects);
    }

    public clearObjects() {
        this.objectGroup.clear();
    }

    //
    public updateAnnotationRange({
        cameraRadius = [],
        viewAngle = 0,
        viewStartMiddleAngle = 0,
    }: {
        cameraRadius: number[] | number[];
        viewAngle: number;
        viewStartMiddleAngle: number;
    }) {
        this.annotaionRangles(cameraRadius);
        this.annotationAngle(viewAngle, viewStartMiddleAngle);
    }

    public circleRangle = (cameraRadius: number) => {
        // 删除之前的

        if (cameraRadius && !isNaN(cameraRadius)) {
            // 范围半径
            const curve = new EllipseCurve(
                0,
                0, // ax, aY
                cameraRadius,
                cameraRadius, // xRadius, yRadius
                0,
                2 * Math.PI, // aStartAngle, aEndAngle
                false, // aClockwise
                0, // aRotation
            );
            const newpoints = curve.getPoints(50);
            const geometry = new BufferGeometry().setFromPoints(newpoints);
            const newmaterial = new LineBasicMaterial({ color: 0xff0000 });
            const ellipse = new Line(geometry, newmaterial);
            ellipse.name = 'circleRangle';

            this.add(ellipse);
        }
    };

    public rectangleRangle = (cameraRadius: number[] = []) => {
        if (cameraRadius) {
            const [length = 0, width = 0, depth = 0] = cameraRadius;
            const sphere = new SphereGeometry();
            const object = new Mesh(sphere, new MeshBasicMaterial({ color: 0xff0000 }));
            object.scale.set(length, width, depth);
            const box = new BoxHelper(object, 0xffff00);
            box.updateMatrixWorld();
            this.add(box);
        }
    };

    public annotaionRangles = (cameraRadius: number[] | number[][] = []) => {
        const annotaionRangleObjects = this.getObjectsByProperty('name', 'annotaionRangle');

        if (annotaionRangleObjects?.length) {
            this.remove(...annotaionRangleObjects);
        }

        if (cameraRadius) {
            cameraRadius.forEach((radius) => {
                if (Array.isArray(radius)) {
                    this.rectangleRangle(radius);
                } else if (radius && !isNaN(radius)) {
                    this.circleRangle(radius);
                }
            });
        }
    };

    public annotationAngle = (viewAngle: number = 0, viewStartMiddleAngle: number = 0) => {
        const line1 = this.getObjectByName('annotationAngle1');
        const line2 = this.getObjectByName('annotationAngle2');

        line1?.removeFromParent();
        line2?.removeFromParent();

        if (viewAngle) {
            const lineMaterial = new LineBasicMaterial({
                color: 'yellow',
            });
            const angle = viewAngle * (Math.PI / 180);
            const middleAngle = viewStartMiddleAngle * (Math.PI / 180);
            const lineLength = new Vector2(viewAngle, 0); // 第一条线沿x轴半径长
            const lineLength2 = lineLength.clone(); // 第二条线沿x轴半径长长
            // 第一条线沿x轴半径长，围绕圆心旋转半个cameraViewAngle， 获得第一条边
            lineLength.rotateAround(new Vector2(0, 0), middleAngle + angle / 2);
            // 第二条线沿x轴半径长，围绕圆心逆旋转半个cameraViewAngle， 获得第二条边
            lineLength2.rotateAround(new Vector2(0, 0), middleAngle - angle / 2);

            const linePoints = [];
            const center = new Vector3(0, 0, 0);
            const linePoint2 = new Vector3(lineLength.x, lineLength.y, 0);
            const linePoint3 = new Vector3(lineLength2.x, lineLength2.y, 0);
            linePoints.push(center);
            linePoints.push(linePoint2);
            const geometry = new BufferGeometry().setFromPoints(linePoints);
            const line = new Line(geometry, lineMaterial);
            line.name = 'annotationAngle1';

            const geometry2 = new BufferGeometry().setFromPoints([center, linePoint3]);
            const newline = new Line(geometry2, lineMaterial);
            newline.name = 'annotationAngle2';

            this.add(line);
            this.add(newline);
        }
    };

    public getObjectByName(name: string) {
        const object = this.objectGroup.getObjectByName(name);

        if (object) {
            // 优先对象
            return object;
        }
        return super.getObjectByName(name);
    }

    // public getObjectByName(name: string) {
    //     return this.objectGroup.getObjectByName(name);
    // }

    public getSenceByName(name: string) {
        return this.context.getObjectByName(name);
    }
}

export default Scene;
