// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

const quickhull = require('quickhull');

const PluginRegistry = require('./plugins');
const Comment = require('./comment');
const User = require('./user');
const { ArgumentError } = require('./exceptions');
const serverProxy = require('./server-proxy');

/**
 * Class representing a single issue
 * @memberof module:API.cvat.classes
 * @hideconstructor
 */
class Issue {
    constructor(initialData) {
        const contentSplitSymbol = '\r\n########\r\n';
        const data = {
            id: undefined,
            job: undefined,
            frame: undefined,
            // position: undefined,
            // comments: [],
            // created_date: undefined,
            // owner: undefined,
            // resolved: undefined,

            jobId: undefined,
            points: undefined,
            noteUserId: undefined,
            noteStatus: undefined, // 0-正常, 1-已通过， 2-已打回， 9-已结束
            noteType: undefined, // 0-缺省, 1-一般 2-严重 3-最高
            content: undefined,
            title: undefined,
            addTime: undefined,
            updateTime: undefined,
            direction: undefined, // 视角
            cameraName: undefined,
        };

        for (const property in data) {
            if (Object.prototype.hasOwnProperty.call(data, property) && property in initialData) {
                if (property === 'points') {
                    data[property] = typeof initialData[property] === 'string' ? initialData[property] : JSON.stringify(initialData[property]);
                } else {
                    data[property] = initialData[property];
                }
            }
        }

        if (data.owner && !(data.owner instanceof User)) data.owner = new User(data.owner);

        if (data.comments) {
            data.comments = data.comments.map((comment) => new Comment(comment));
        }

        if (typeof data.created_date === 'undefined') {
            data.created_date = new Date().toISOString();
        }

        Object.defineProperties(
            this,
            Object.freeze({
                /**
                 * @name contentSplitSymbol
                 * @type {integer}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                contentSplitSymbol: {
                    get: () => contentSplitSymbol,
                },
                /**
                 * @name id
                 * @type {integer}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                id: {
                    get: () => data.id,
                },
                /**
                 * Region of interests of the issue
                 * @name position
                 * @type {number[]}
                 * @memberof module:API.cvat.classes.Issue
                 * @instance
                 * @readonly
                 * @throws {module:API.cvat.exceptions.ArgumentError}
                 */
                position: {
                    // get: () => data.position,
                    get: () => {
                        try {
                            return JSON.parse(data.points);
                        } catch (error) {
                            return [];
                        }
                    },
                    // set: (value) => {
                    //     if (Array.isArray(value) || value.some((coord) => typeof coord !== 'number')) {
                    //         throw new ArgumentError(`Array of numbers is expected. Got ${value}`);
                    //     }
                    //     data.position = value;
                    // },
                    set: (value) => {
                        if (Array.isArray(value) || value.some((coord) => typeof coord !== 'number')) {
                            throw new ArgumentError(`Array of numbers is expected. Got ${value}`);
                        }
                        data.points = JSON.stringify(value);
                    },
                },
                /**
                 * Region of interests of the issue
                 * @name points
                 * @type {number[]}
                 * @memberof module:API.cvat.classes.Issue
                 * @instance
                 * @readonly
                 * @throws {module:API.cvat.exceptions.ArgumentError}
                 */
                points: {
                    get: () => {
                        try {
                            return JSON.parse(data.points);
                        } catch (error) {
                            throw new ArgumentError(`批注坐标转换错误！批注${data.content}出错，请在右侧批注列表，删除重新添加尝试。`);
                        }
                    },
                    set: (value) => {
                        if (Array.isArray(value) || value.some((coord) => typeof coord !== 'number')) {
                            throw new ArgumentError(`Array of numbers is expected. Got ${value}`);
                        }
                        data.points = JSON.stringify(value);
                    },
                },
                /**
                 * ID of a job, the issue is linked with
                 * @name job
                 * @type {number}
                 * @memberof module:API.cvat.classes.Issue
                 * @instance
                 * @readonly
                 * @throws {module:API.cvat.exceptions.ArgumentError}
                 */
                job: {
                    get: () => data.jobId,
                },
                /**
                 * ID of a jobId, the issue is linked with
                 * @name jobId
                 * @type {number}
                 * @memberof module:API.cvat.classes.Issue
                 * @instance
                 * @readonly
                 * @throws {module:API.cvat.exceptions.ArgumentError}
                 */
                jobId: {
                    get: () => data.jobId,
                },
                // /**
                //  * List of comments attached to the issue
                //  * @name comments
                //  * @type {module:API.cvat.classes.Comment[]}
                //  * @memberof module:API.cvat.classes.Issue
                //  * @instance
                //  * @readonly
                //  * @throws {module:API.cvat.exceptions.ArgumentError}
                //  */
                // comments: {
                //     get: () => [...data.comments],
                // },
                /**
                 * @name frame
                 * @type {integer}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                frame: {
                    get: () => data.frame,
                },
                // /**
                //  * @name createdDate
                //  * @type {string}
                //  * @memberof module:API.cvat.classes.Issue
                //  * @readonly
                //  * @instance
                //  */
                // createdDate: {
                //     get: () => data.created_date,
                // },
                // /**
                //  * An instance of a user who has raised the issue
                //  * @name owner
                //  * @type {module:API.cvat.classes.User}
                //  * @memberof module:API.cvat.classes.Issue
                //  * @readonly
                //  * @instance
                //  */
                // owner: {
                //     get: () => data.owner,
                // },
                // /**
                //  * The flag defines issue status
                //  * @name resolved
                //  * @type {module:API.cvat.classes.User}
                //  * @memberof module:API.cvat.classes.Issue
                //  * @readonly
                //  * @instance
                //  */
                // resolved: {
                //     get: () => data.resolved,
                // },
                /**
                 * The flag defines issue status
                 * @name noteUserId
                 * @type {module:API.cvat.classes.User}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                noteUserId: {
                    get: () => data.noteUserId,
                },
                /**
                 * The flag defines issue status
                 * @name noteStatus
                 * @type {module:API.cvat.classes.User}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                noteStatus: {
                    get: () => data.noteStatus,
                },
                /**
                 * The flag defines issue status
                 * @name noteStatus
                 * @type {module:API.cvat.classes.User}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                noteType: {
                    get: () => data.noteType,
                },
                /**
                 * The flag defines issue status
                 * @name content
                 * @type {module:API.cvat.classes.User}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                content: {
                    get: () => data.content,
                },
                /**
                 * The flag defines issue status
                 * @name content
                 * @type {module:API.cvat.classes.User}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                comments: {
                    get: () => {
                        try {
                            const comments = data.content.split(contentSplitSymbol) || [];
                            return comments.map((comment, index) => new Comment({
                                id: index + 1,
                                message: comment,
                                created_date: data.addTime,
                                updated_date: data.updateTime,
                            }));
                        } catch (error) {
                            throw new ArgumentError(`批注内容解析错误:${error}`);
                        }
                    },
                },
                /**
                 * The flag defines issue status
                 * @name title
                 * @type {module:API.cvat.classes.User}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                title: {
                    get: () => data.title,
                },
                direction: {
                    get: () => data.direction,
                },
                cameraName: {
                    get: () => data.cameraName,
                },
                /**
                 * The flag defines issue status
                 * @name addTime
                 * @type {module:API.cvat.classes.User}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                addTime: {
                    get: () => data.addTime,
                },
                /**
                 * The flag defines issue status
                 * @name updateTime
                 * @type {module:API.cvat.classes.User}
                 * @memberof module:API.cvat.classes.Issue
                 * @readonly
                 * @instance
                 */
                updateTime: {
                    get: () => data.updateTime,
                },
                __internal: {
                    get: () => data,
                },
            }),
        );
    }

    static hull(coordinates, type = '2d') {
        if (type === '3d') {
            return coordinates;
        }

        if (coordinates.length > 4) {
            const points = coordinates.reduce((acc, coord, index, arr) => {
                if (index % 2) acc.push({ x: arr[index - 1], y: coord });
                return acc;
            }, []);

            return quickhull(points)
                .map((point) => [point.x, point.y])
                .flat();
        }

        return coordinates;
    }

    /**
     * @typedef {Object} CommentData
     * @property {string} message a comment message
     * @global
     */
    /**
     * Method appends a comment to the issue
     * For a new issue it saves comment locally, for a saved issue it saves comment on the server
     * @method comment
     * @memberof module:API.cvat.classes.Issue
     * @param {CommentData} data
     * @readonly
     * @instance
     * @async
     * @throws {module:API.cvat.exceptions.ServerError}
     * @throws {module:API.cvat.exceptions.PluginError}
     * @throws {module:API.cvat.exceptions.ArgumentError}
     */
    async comment(data) {
        const result = await PluginRegistry.apiWrapper.call(this, Issue.prototype.comment, data);
        return result;
    }

    /**
     * @typedef {Object} CommentData
     * @property {number} id a comment message
     * @property {number[]} points a comment message
     * @global
     */
    /**
     * Method appends a comment to the issue
     * For a new issue it saves comment locally, for a saved issue it saves comment on the server
     * @method update
     * @memberof module:API.cvat.classes.Issue
     * @param {CommentData} data
     * @readonly
     * @instance
     * @async
     * @throws {module:API.cvat.exceptions.ServerError}
     * @throws {module:API.cvat.exceptions.PluginError}
     * @throws {module:API.cvat.exceptions.ArgumentError}
     */
    async update(points) {
        const result = await PluginRegistry.apiWrapper.call(this, Issue.prototype.update, points);
        return result;
    }

    /**
     * The method resolves the issue
     * New issues are resolved locally, server-saved issues are resolved on the server
     * @method resolve
     * @memberof module:API.cvat.classes.Issue
     * @param {module:API.cvat.classes.User} user
     * @readonly
     * @instance
     * @async
     * @throws {module:API.cvat.exceptions.ServerError}
     * @throws {module:API.cvat.exceptions.PluginError}
     * @throws {module:API.cvat.exceptions.ArgumentError}
     */
    async resolve(noteStatus) {
        const result = await PluginRegistry.apiWrapper.call(this, Issue.prototype.resolve, noteStatus);
        return result;
    }

    /**
     * The method resolves the issue
     * New issues are reopened locally, server-saved issues are reopened on the server
     * @method reopen
     * @memberof module:API.cvat.classes.Issue
     * @readonly
     * @instance
     * @async
     * @throws {module:API.cvat.exceptions.ServerError}
     * @throws {module:API.cvat.exceptions.PluginError}
     * @throws {module:API.cvat.exceptions.ArgumentError}
     */
    async reopen(noteStatus) {
        const result = await PluginRegistry.apiWrapper.call(this, Issue.prototype.reopen, noteStatus);
        return result;
    }

    /**
     * The method deletes the issue
     * Deletes local or server-saved issues
     * @method delete
     * @memberof module:API.cvat.classes.Issue
     * @readonly
     * @instance
     * @async
     * @throws {module:API.cvat.exceptions.ServerError}
     * @throws {module:API.cvat.exceptions.PluginError}
     */
    async delete() {
        await PluginRegistry.apiWrapper.call(this, Issue.prototype.delete);
    }

    // serialize() {
    //     const { comments } = this;
    //     const data = {
    //         position: this.position,
    //         frame: this.frame,
    //         comments: comments.map((comment) => comment.serialize()),
    //     };

    //     if (typeof this.id === 'number') {
    //         data.id = this.id;
    //     }
    //     if (typeof this.job === 'number') {
    //         data.job = this.job;
    //     }
    //     if (typeof this.createdDate === 'string') {
    //         data.created_date = this.createdDate;
    //     }
    //     if (typeof this.resolved === 'boolean') {
    //         data.resolved = this.resolved;
    //     }
    //     if (this.owner instanceof User) {
    //         data.owner = this.owner.serialize().id;
    //     }

    //     return data;
    // }
    serialize() {
        // const { comments } = this;
        const data = {
            points: this.position,
            frame: this.frame,
            content: this.content,
            // comments: comments.map((comment) => comment.serialize()),
        };

        if (typeof this.id === 'number') {
            data.id = this.id;
        }

        if (typeof this.jobId === 'number') {
            data.jobId = this.jobId;
        }

        if (typeof this.frame === 'number') {
            data.frame = this.frame;
        }

        if (typeof this.noteStatus === 'number') {
            data.noteStatus = this.noteStatus;
        } else {
            data.noteStatus = 0;
        }

        if (typeof this.noteType === 'number') {
            data.noteType = this.noteType;
        } else {
            data.noteType = 1;
        }

        if (typeof this.noteUserId === 'number') {
            data.noteUserId = this.noteUserId;
        }

        if (typeof this.points === 'string') {
            data.points = this.points;
        } else if (Array.isArray(this.points)) {
            data.points = JSON.stringify(this.points);
        }

        if (typeof this.title === 'string') {
            data.title = this.title;
        }
        if (typeof this.direction === 'string') {
            data.direction = this.direction;
        }
        if (typeof this.cameraName === 'string') {
            data.cameraName = this.cameraName;
        }

        return data;
    }
}

Issue.prototype.update.implementation = async function (points) {
    // if (typeof data !== 'object' || data === null) {
    //     throw new ArgumentError(`The argument "data" must be an object. Got "${data}"`);
    // }
    // if (typeof data.message !== 'string' || data.message.length < 1) {
    //     throw new ArgumentError(`Comment message must be a not empty string. Got "${data.message}"`);
    // }
    if (!Array.isArray(points)) {
        throw new ArgumentError(`"points"必须是数字数组，现在是"${points}"`);
    }

    const newPoints = JSON.stringify(points);
    // const message = `${this.content}${this.contentSplitSymbol}${data.message}`;
    const response = await serverProxy.issues.update(
        {
            id: this.id,
            points: newPoints,
            // noteStatus: 1,
            // content: message,
        },
    );
    if (response && response.success && response.data === 1) {
        this.__internal.points = newPoints;
        // this.__internal.noteStatus = 1;
    }
    // const comment = new Comment(data);
    // if (typeof this.id === 'number') {
    //     const serialized = comment.serialize();
    //     serialized.issue = this.id;
    //     const response = await serverProxy.comments.create(serialized);
    //     const savedComment = new Comment(response);
    //     this.__internal.comments.push(savedComment);
    // } else {
    //     this.__internal.comments.push(comment);
    // }
};

Issue.prototype.comment.implementation = async function (data) {
    if (typeof data !== 'object' || data === null) {
        throw new ArgumentError(`The argument "data" must be an object. Got "${data}"`);
    }
    if (typeof data.message !== 'string' || data.message.length < 1) {
        throw new ArgumentError(`Comment message must be a not empty string. Got "${data.message}"`);
    }

    const message = `${this.content}${this.contentSplitSymbol}${data.message}`;
    const response = await serverProxy.issues.update(
        {
            id: this.id,
            noteStatus: 1,
            content: message,
        },
    );
    if (response && response.success && response.data === 1) {
        this.__internal.content = message;
        this.__internal.noteStatus = 1;
    }
    // const comment = new Comment(data);
    // if (typeof this.id === 'number') {
    //     const serialized = comment.serialize();
    //     serialized.issue = this.id;
    //     const response = await serverProxy.comments.create(serialized);
    //     const savedComment = new Comment(response);
    //     this.__internal.comments.push(savedComment);
    // } else {
    //     this.__internal.comments.push(comment);
    // }
};

Issue.prototype.resolve.implementation = async function (noteStatus) {
    if (!(noteStatus >= 0 && noteStatus < 3)) {
        throw new ArgumentError(`The argument "user" must be an instance of a User class. Got "${typeof user}"`);
    }

    if (typeof this.id === 'number') {
        const response = await serverProxy.issues.update({
            id: this.id,
            noteStatus,
        });
        // this.__internal.noteStatus = response.noteStatus;
        if (response && response.success && response.data === 1) {
            this.__internal.noteStatus = noteStatus;
        }
    }
    //  else {
    //     this.__internal.resolved = true;
    // }
};

Issue.prototype.reopen.implementation = async function () {
    // if (typeof this.id === 'number') {
    //     const response = await serverProxy.issues.update(this.id, { resolved: false });
    //     this.__internal.resolved = response.resolved;
    // } else {
    //     this.__internal.resolved = false;
    // }

    // if (noteStatus >= 0 && noteStatus < 3) {
    //     throw new ArgumentError(`The argument "user" must be an instance of a User class. Got "${typeof user}"`);
    // }

    if (typeof this.id === 'number') {
        const response = await serverProxy.issues.update({
            id: this.id,
            noteStatus: 0,
        });
        if (response && response.success && response.data === 1) {
            this.__internal.noteStatus = 0;
        }
    }
};

Issue.prototype.delete.implementation = async function () {
    const { id } = this;
    if (id >= 0) {
        await serverProxy.issues.delete(id);
    }
};

module.exports = Issue;
