/*
 * @Author: swxy
 * @Date: 2023-08-26 17:59:06
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { DimensionType, ObjectType, ShapeType, Source, SubElement } from 'reducers/interfaces';
import { Label } from './label';
import { ArgumentError } from 'errors/exception';

export interface UpdateFlags {
    frame: boolean;
    label: boolean;
    attributes: boolean;
    description: boolean;
    points: boolean;
    rotation: boolean;
    outside: boolean;
    occluded: boolean;
    keyframe: boolean;
    zOrder: boolean;
    pinned: boolean;
    lock: boolean;
    color: boolean;
    hidden: boolean;
    relation: boolean;
    pointsLine: boolean;
    descriptions: boolean;
    parentID: boolean;
    cameraName: boolean;
    direction: boolean;
    elements: boolean;
    pointOccludeds: boolean;
    controls: boolean;

    reset: () => void;
    // [k: string]: boolean;
}

export interface SerializedData {
    objectType: ObjectType;
    label: Label;
    frame: number;
    clientID?: number;
    shapeType?: ShapeType;

    serverID?: number;
    jobID?: number;
    parentID?: number;
    lock?: boolean;
    hidden?: boolean;
    pinned?: boolean;
    attributes?: Record<number, string>;
    group?: { color: string; id: number };
    color?: string;
    updated?: number;
    source?: Source;
    zOrder?: number;
    points?: number[];
    occluded?: boolean;
    outside?: boolean;
    keyframe?: boolean;
    rotation?: number;
    descriptions?: string[];
    isGroundTruth?: boolean;
    keyframes?: {
        prev?: number;
        next?: number;
        first?: number;
        last?: number;
    };
    pointsLine?: number;
    cameraName?: string;
    direction?: string;
    elements?: SubElement[];
    pointOccludeds?: boolean[];
    relation?: {};
    shapeIndex?: number; // 序号

    controls?: number[][];

    readonly?: boolean;
    // __internal: {
    //     save: (objectState: ObjectState) => ObjectState;
    //     delete: (frame: number, force: boolean) => boolean;
    // };
}

class ObjectState {
    // public clientID: number;
    // public frame: number;
    // public label: Label;
    // public attributes: Record<number, string>;
    // public points: number[];
    // public objectType: ObjectType;
    // public shapeType: ShapeType;

    // public rotation: number;
    // public outside: boolean;
    // public occluded: boolean;
    // public lock: boolean;
    // public color: string;
    // public hidden: boolean;
    // public pinned: boolean;
    // public source: Source;
    // public zOrder: number;
    // public pointsLine: number;

    // public serverID?: number;
    // public keyframes?: {
    //     first?: number;
    //     prev?: number;
    //     next?: number;
    //     last?: number;
    // };
    // public group?: { color: string; id: number };

    // public relation: any;
    // public parentID?: number;
    // public cameraName?: string;
    // public direction?: string;
    // public keyframe?: boolean;

    // public pointOccludeds?: boolean[];
    // public descriptions: string[];
    // public elements: SubElement[];

    // public updated: number;
    private data: {
        clientID: number;
        frame: number;
        label: Label;
        attributes: Record<number, string>;
        points: number[];
        objectType: ObjectType;
        shapeType: ShapeType;

        rotation: number;
        outside: boolean;
        occluded: boolean;
        lock: boolean;
        color: string;
        hidden: boolean;
        pinned: boolean;
        source: Source;
        zOrder: number;
        pointsLine: number;

        serverID?: number;
        keyframes?: {
            first?: number;
            prev?: number;
            next?: number;
            last?: number;
        };
        group?: { color: string; id: number };

        relation: any;
        parentID?: number;
        cameraName?: string;
        direction?: string;
        keyframe?: boolean;

        pointOccludeds?: boolean[];
        descriptions: string[];
        elements: SubElement[];
        shapeIndex?: number;
        controls?: number[][];

        updated: number;
    };

    public readonly readonly: boolean = false;
    public readonly updateFlags: UpdateFlags;

    constructor(serialized: SerializedData) {
        // this.clientID = serialized.clientID || 0;
        // this.frame = serialized.frame;
        // this.label = serialized.label;
        // this.attributes = serialized.attributes || {};
        // this.points = serialized.points || [];
        // this.shapeType = serialized.shapeType || ShapeType.RECTANGLE;
        // this.objectType = serialized.objectType;

        // this.rotation = serialized.rotation || 0;
        // this.outside = serialized.outside || false;
        // this.occluded = serialized.occluded || false;
        // this.lock = serialized.lock || false;
        // this.color = serialized.color || '';
        // this.hidden = serialized.hidden || false;
        // this.pinned = serialized.pinned || false;
        // this.source = serialized.source || Source.MANUAL;
        // this.zOrder = serialized.zOrder || 0;
        // this.pointsLine = serialized.pointsLine || 0;
        // // direction = '';

        // this.serverID = serialized.serverID;
        // this.keyframes = undefined;
        // this.group = undefined;
        // this.relation = serialized.relation || {};
        // this.parentID = serialized.parentID;
        // this.cameraName = serialized.cameraName;
        // this.keyframe = serialized.keyframe;
        // this.pointOccludeds = serialized.pointOccludeds;
        // this.descriptions = serialized.descriptions || [];
        // this.elements = serialized.elements || [];
        // this.direction = serialized.direction;
        // this.updated = serialized.updated || new Date().valueOf();
        this.data = {
            // @ts-expect-error
            clientID: serialized.clientID || undefined,
            frame: serialized.frame,
            label: serialized.label,
            attributes: serialized.attributes || {},
            points: serialized.points || [],
            shapeType: serialized.shapeType || ShapeType.RECTANGLE,
            objectType: serialized.objectType,

            rotation: serialized.rotation || 0,
            outside: serialized.outside || false,
            occluded: serialized.occluded || false,
            lock: serialized.lock || false,
            color: serialized.color || '',
            hidden: serialized.hidden || false,
            pinned: serialized.pinned || false,
            source: serialized.source || Source.MANUAL,
            zOrder: serialized.zOrder || 0,
            pointsLine: serialized.pointsLine || 0,
            // direction: '',

            serverID: serialized.serverID,
            keyframes: serialized.keyframes,
            group: serialized.group,
            relation: serialized.relation || {},
            parentID: serialized.parentID,
            cameraName: serialized.cameraName,
            keyframe: serialized.keyframe,
            pointOccludeds: serialized.pointOccludeds,
            descriptions: serialized.descriptions || [],
            elements: serialized.elements || [],
            direction: serialized.direction,
            shapeIndex: serialized.shapeIndex,
            controls: serialized.controls,

            updated: serialized.updated || new Date().valueOf(),
        };

        this.readonly = !!serialized.readonly;

        // @ts-expect-error
        this.updateFlags = {
            frame: false,
            label: false,
            attributes: false,
            descriptions: false,

            points: false,
            rotation: false,
            outside: false,
            occluded: false,
            keyframe: false,

            zOrder: false,
            pinned: false,
            lock: false,
            color: false,
            hidden: false,
            pointsLine: false,
            relation: false,
            cameraName: false,
            direction: false,
            parentID: false,
            description: false,
            elements: false,
            pointOccludeds: false,
            controls: false,
        };
        const reset = () => {
            Object.keys(this.updateFlags).forEach((key: string) => {
                if (key !== 'reset') {
                    (this.updateFlags[key as keyof typeof this.updateFlags] as boolean) = false;
                }
            });
        };
        Object.defineProperty(this.updateFlags, 'reset', {
            value: reset,
            writable: false,
            enumerable: false,
        });
        // this.updateFlags = {
        //     reset: () => {
        //         Object.keys(this.updateFlags).forEach((key: string) => {
        //             if (key !== 'reset') {
        //                 (this.updateFlags[key as keyof typeof this.updateFlags] as boolean) = false;
        //             }
        //         });
        //     },
        // };
    }

    public get clientID(): number {
        return this.data.clientID;
    }

    public get frame(): number {
        return this.data.frame;
    }

    public set frame(frame: number) {
        this.data.frame = frame;
        if (this.data.objectType === ObjectType.TRACK) {
            // 连续帧可以更改起始帧
            this.updateFlags.frame = true;
            this.data.frame = frame;
        }
    }

    public get label(): Label {
        return this.data.label;
    }

    public set label(label: Label) {
        this.updateFlags.label = true;
        this.data.label = label;
    }

    public get attributes(): Record<number, string> {
        return this.data.attributes;
    }

    public set attributes(attributes: Record<number, string>) {
        if (typeof attributes !== 'object') {
            throw new ArgumentError(
                `标注对象attributes字段应该是一个键值对，但目前为：${
                    typeof attributes === 'object' ? (attributes as object).constructor.name : typeof attributes
                }`,
            );
        } else {
            for (const attrID of Object.keys(attributes)) {
                this.updateFlags.attributes = true;
                this.data.attributes[+attrID] = attributes[+attrID];
            }
        }
    }

    public get points(): number[] {
        return this.data.points;
    }

    public set points(points: number[]) {
        if (Array.isArray(points)) {
            this.updateFlags.points = true;
            this.data.points = [...points];
        } else {
            throw new ArgumentError(
                `标注对象points字段应该是一个数字数组，但目前为：${
                    typeof points === 'object' ? (points as object).constructor.name : typeof points
                }`,
            );
        }
    }

    public get objectType(): ObjectType {
        return this.data.objectType;
    }

    public get shapeType(): ShapeType {
        return this.data.shapeType;
    }

    public get rotation(): number {
        return this.data.rotation;
    }

    public set rotation(rotation: number) {
        this.data.rotation = rotation;
        this.updateFlags.rotation = true;
    }

    public get outside(): boolean {
        return this.data.outside;
    }

    public set outside(outside: boolean) {
        this.data.outside = outside;
        this.updateFlags.outside = true;
    }

    public get occluded(): boolean {
        return this.data.occluded;
    }

    public set occluded(occluded: boolean) {
        this.data.occluded = occluded;
        this.updateFlags.occluded = true;
    }

    public get lock(): boolean {
        return this.data.lock;
    }

    public set lock(lock: boolean) {
        this.data.lock = lock;
        this.updateFlags.lock = true;
    }

    public get color(): string {
        return this.data.color;
    }

    public set color(color: string) {
        this.data.color = color;
        this.updateFlags.color = true;
    }

    public get hidden(): boolean {
        return this.data.hidden;
    }

    public set hidden(hidden: boolean) {
        this.data.hidden = hidden;
        this.updateFlags.hidden = true;
    }

    public get pinned(): boolean {
        return this.data.pinned;
    }

    public set pinned(pinned: boolean) {
        this.data.pinned = pinned;
        this.updateFlags.pinned = true;
    }

    public get source(): Source {
        return this.data.source;
    }

    public get zOrder(): number {
        return this.data.zOrder;
    }

    public set zOrder(zOrder: number) {
        this.data.zOrder = zOrder;
        this.updateFlags.zOrder = true;
    }

    public get pointsLine(): number {
        return this.data.pointsLine;
    }

    public set pointsLine(pointsLine: number) {
        this.data.pointsLine = pointsLine;
        this.updateFlags.pointsLine = true;
    }

    public get serverID(): number | undefined {
        return this.data.serverID;
    }

    public get keyframes():
        | {
              first?: number;
              prev?: number;
              next?: number;
              last?: number;
          }
        | undefined {
        return this.data.keyframes;
    }

    public get group(): { color: string; id: number } | undefined {
        return this.data.group;
    }

    public get relation(): any {
        return this.data.relation;
    }

    public set relation(relation: any) {
        if (typeof relation !== 'object') {
            throw new ArgumentError(
                `标注对象attributes字段应该是一个键值对，但目前为：${
                    typeof relation === 'object' ? (relation as object).constructor.name : typeof relation
                }`,
            );
        } else {
            this.updateFlags.relation = true;
            this.data.relation = { ...relation };
        }
    }

    public get parentID(): number | undefined {
        return this.data.parentID;
    }

    public set parentID(parentID: number) {
        this.data.parentID = parentID;
        this.updateFlags.parentID = true;
    }

    public get cameraName(): string | undefined {
        return this.data.cameraName;
    }

    public set cameraName(cameraName: string) {
        this.data.cameraName = cameraName;
        this.updateFlags.cameraName = true;
    }

    public get direction(): string | undefined {
        return this.data.direction;
    }

    public set direction(direction: string) {
        this.data.direction = direction;
        this.updateFlags.direction = true;
    }

    public get keyframe(): boolean | undefined {
        return this.data.keyframe;
    }

    public set keyframe(keyframe: boolean) {
        this.updateFlags.keyframe = true;
        this.data.keyframe = keyframe;
    }

    public get pointOccludeds(): boolean[] | undefined {
        return this.data.pointOccludeds;
    }

    public set pointOccludeds(pointOccludeds: boolean[]) {
        this.updateFlags.pointOccludeds = true;
        this.data.pointOccludeds = pointOccludeds;
    }

    public get descriptions(): string[] {
        return this.data.descriptions;
    }

    public set descriptions(descriptions: string[]) {
        if (Array.isArray(descriptions)) {
            this.updateFlags.descriptions = true;
            this.data.descriptions = [...descriptions];
        }
    }

    public get elements(): SubElement[] {
        return this.data.elements;
    }

    public set elements(elements: SubElement[]) {
        if (!Array.isArray(elements)) {
            throw new ArgumentError(
                `标注对象elements字段应该是一个对象数组，但目前为：${
                    typeof elements === 'object' ? (elements as object).constructor.name : typeof elements
                }`,
            );
        } else {
            this.updateFlags.elements = true;
            this.data.elements = [...elements];
        }
    }

    public get shapeIndex(): number | undefined {
        return this.data.shapeIndex;
    }

    public get updated(): number {
        return this.data.updated;
    }

    public get controls(): number[][] | undefined {
        return this.data.controls;
    }

    public set controls(controls: number[][] | undefined) {
        try {
            if (Array.isArray(controls)) {
                this.data.controls = controls.map((points) =>
                    points.map((pos) => {
                        if (typeof pos === 'number' && !Number.isNaN(pos)) {
                            return pos;
                        }
                        throw new Error('贝塞尔曲线控制点值错误：');
                    }),
                );
                this.updateFlags.controls = true;
                return;
            }
            throw new Error('保存贝塞尔曲线控制点错误！');
        } catch (error) {
            throw new ArgumentError(
                `标注对象controls字段应该是一个二维数字数组，但目前为：${
                    typeof controls === 'object' ? (controls as object).constructor.name : typeof controls
                }`,
            );
        }
    }

    public toJSON() {
        return {
            clientID: this.clientID,

            label: this.label,
            // attributes: Object.entries(this.attributes).map(([key, value]) => ({ specId: +key, value })),
            // attributes: this.attributes,
            // elements: this.elements,
            // points: this.points,
            frame: this.frame,
            pointsLine: this.pointsLine,
            objectType: this.objectType,
            shapeType: this.shapeType,

            source: this.source,
            zOrder: this.zOrder,
            lock: this.lock,
            occluded: this.occluded,
            outside: this.outside,
            keyframe: this.keyframe,
            pinned: this.pinned,
            hidden: this.hidden,
            color: this.color,
            rotation: this.rotation,
            descriptions: this.descriptions,
            relation: this.relation,
            direction: this.direction,
            parentID: this.parentID,
            cameraName: this.cameraName,
            elements: JSON.parse(JSON.stringify(this.elements || [])),
            attributes: JSON.parse(JSON.stringify(this.attributes || {})),
            points: this.points ? [...this.points] : undefined,
            shapeIndex: this.shapeIndex,
        };
    }

    public async save(dimension?: DimensionType) {
        return this;
    }
    public async delete(frame: number, force: boolean = false) {
        return false;
    }

    /**
     * 删除当前帧及之后所有帧
     * @param frame
     * @param reverse 是否逆转-变为删除当前帧与之前的所有帧
     * @returns 是否成功
     */
    public async delete_outside(frame: number, reverse: boolean = false) {
        // 外部方法会替换当前方法
        return false;
    }
}

export default ObjectState;
