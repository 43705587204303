// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Button from 'antd/lib/button';
import { DeleteOutlined, EnvironmentOutlined, BlockOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

import { CombinedState, ContextMenuType } from 'reducers/interfaces';
import { updateAnnotationsAsync, updateCanvasContextMenu } from 'actions/annotation-actions';
import CVATTooltip from 'components/common/cvat-tooltip';
import { useTranslation } from 'react-i18next';
import { isDev } from 'utils/enviroment';

interface StateToProps {
    activatedState: any | null;
    selectedPoint: number | null;
    visible: boolean;
    top: number;
    left: number;
    type: ContextMenuType;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            annotations: { states, activatedStateID },
            canvas: {
                contextMenu: { visible, top, left, type, pointID: selectedPoint },
            },
        },
    } = state;

    return {
        activatedState:
            activatedStateID === null
                ? null
                : states.filter((_state) => _state.clientID === activatedStateID)[0] || null,
        selectedPoint,
        visible,
        left,
        top,
        type,
    };
}

interface DispatchToProps {
    onUpdateAnnotations(states: any[]): void;
    onCloseContextMenu(): void;
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onUpdateAnnotations(states: any[]): void {
            dispatch(updateAnnotationsAsync(states));
        },
        onCloseContextMenu(): void {
            dispatch(updateCanvasContextMenu(false, 0, 0));
        },
    };
}

type Props = StateToProps & DispatchToProps;

const supportLabelIds = isDev() ? [54, 132, 133, 134] : [81, 82, 83, 132, 133, 134];

function CanvasPointContextMenu(props: Props): React.ReactPortal | null {
    const { t } = useTranslation();
    const {
        onCloseContextMenu,
        onUpdateAnnotations,
        activatedState,
        visible,
        type,
        top,
        left,
        selectedPoint: pointNum,
    } = props;

    const [contextMenuFor, setContextMenuFor] = useState(activatedState);
    const [isOccluded, setIsOccluded] = useState(false);
    const labelId = activatedState?.label?.id;
    if (activatedState !== contextMenuFor) {
        setContextMenuFor(activatedState);
        if (visible && type === ContextMenuType.CANVAS_SHAPE_POINT) {
            onCloseContextMenu();
        }
    }

    const onPointDelete = (): void => {
        const { selectedPoint } = props;
        if (contextMenuFor && selectedPoint !== null) {
            contextMenuFor.points = contextMenuFor.points
                .slice(0, selectedPoint * 2)
                .concat(contextMenuFor.points.slice(selectedPoint * 2 + 2));
            if (contextMenuFor.pointOccludeds) {
                contextMenuFor.pointOccludeds = contextMenuFor.pointOccludeds
                    .slice(0, selectedPoint * 2)
                    .concat(contextMenuFor.pointOccludeds.slice(selectedPoint * 2 + 2));
            }
            onUpdateAnnotations([contextMenuFor]);
            onCloseContextMenu();
        }
    };

    const onSetStartPoint = (): void => {
        const { selectedPoint } = props;
        if (contextMenuFor && selectedPoint !== null && contextMenuFor.shapeType === 'polygon') {
            contextMenuFor.points = contextMenuFor.points
                .slice(selectedPoint * 2)
                .concat(contextMenuFor.points.slice(0, selectedPoint * 2));
            onUpdateAnnotations([contextMenuFor]);
            onCloseContextMenu();
        }
    };

    const onPointOcclusion = (): void => {
        const { selectedPoint } = props;
        if (contextMenuFor && selectedPoint !== null && contextMenuFor.pointOccludeds) {
            // contextMenuFor.pointOccludeds = contextMenuFor.pointOccludeds
            //     .slice(0, selectedPoint * 2)
            //     .concat(isOccluded ? [false, false] : [true, true])
            //     .concat(contextMenuFor.pointOccludeds.slice(selectedPoint * 2 + 2));
            contextMenuFor.pointOccludeds[selectedPoint * 2] = !isOccluded;
            contextMenuFor.pointOccludeds[selectedPoint * 2 + 1] = !isOccluded;
            contextMenuFor.pointOccludeds = [...contextMenuFor.pointOccludeds];
            onUpdateAnnotations([contextMenuFor]);
            onCloseContextMenu();
        }
    };

    useEffect(() => {
        if (contextMenuFor?.pointOccludeds && typeof pointNum === 'number') {
            setIsOccluded(contextMenuFor?.pointOccludeds[pointNum * 2]);
        }
    }, [contextMenuFor?.pointOccludeds, pointNum]);

    return visible && contextMenuFor && type === ContextMenuType.CANVAS_SHAPE_POINT
        ? ReactDOM.createPortal(
            <div className='cvat-canvas-point-context-menu' style={{ top, left }}>
                {/* <CVATTooltip title='Delete point [Alt + dblclick]'> */}
                <CVATTooltip title={t(`workspace.canvas.deletePointsTip`, { shortcut: ' [Alt + dblclick]' })}>
                    <Button type='link' icon={<DeleteOutlined />} onClick={onPointDelete}>
                        {/* Delete point */}
                        {t('workspace.canvas.deletePoints')}
                    </Button>
                </CVATTooltip>
                {supportLabelIds.includes(labelId) && (
                    <Button type='link' icon={<BlockOutlined />} onClick={onPointOcclusion}>
                        {/* Delete point */}
                        {isOccluded ? t('workspace.canvas.cancelCover') : t('workspace.canvas.setCover')}
                    </Button>
                )}

                {contextMenuFor && contextMenuFor.shapeType === 'polygon' && (
                    <Button type='link' icon={<EnvironmentOutlined />} onClick={onSetStartPoint}>
                        {/* Set start point */}
                        {t('workspace.canvas.setStartPoint')}
                    </Button>
                )}
            </div>,
            window.document.body,
        )
        : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(CanvasPointContextMenu);
