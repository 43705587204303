/*
 * @Author: swxy
 * @Date: 2023-07-23 15:32:42
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, RadioChangeEvent, message } from 'antd';

import {
    changeCamera
} from 'actions/annotation-actions';
import { clamp } from 'utils/math';
import { GridColor, CombinedState, PlayerSettingsState } from 'reducers/interfaces';
import Job from 'business/objects/job';

function ImageSetupsContent(): JSX.Element {
    const dispatch = useDispatch();
    const {
        jobInstance,
        cameraName,
    } = useSelector((state: CombinedState) => ({
        jobInstance: state.annotation.job.instance,
        cameraName: state.annotation.annotations.cameraName,
    }));

    if (!(jobInstance instanceof Job && jobInstance.cams.length >= 2)) {
        // 有同一帧有多个图像文件。

        return <></>
    }

    // console.log('当前视角：', cameraName);

    const options = jobInstance.cams.map((camName: string) => {
        return {
            value: camName,
            label: camName,
        }
    })

    const onChange = (e: RadioChangeEvent) => {
        if (jobInstance.annotations.hasUnsavedChanges()) {
            message.info('请先保存数据 或 稍等一会儿~');
        } else {
            dispatch(changeCamera(jobInstance, e.target.value));
        }
    }

    return (
        <div className='aatp_canvas_camera_controller'>
            <Radio.Group value={cameraName} buttonStyle="solid" options={options} onChange={onChange} />
        </div>
    );
}


export default ImageSetupsContent;