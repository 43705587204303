/*
 * @Author: swxy
 * @Date: 2023-10-25 14:40:46
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { useState, FC, useEffect } from 'react';

import { Spin, message, Descriptions, Row, Divider, Button, Space, Result } from 'antd';
import { api_checkJobAnnotationResult } from 'service/job/job';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { CX_check } from 'business/customs/CX';
import Job from 'business/objects/job';
import { api_cancelCheckJob, api_checkFinish, api_sendCheckJob } from 'service/custom';
import { ProjectType } from 'utils/ConstType';

interface Props {
    onCancel?: () => void;
}

const frameIdToFrame: Record<string, number> = {}

const checkAnnotationResultComponent: FC<Props> = ({ onCancel }) => {
    const [loading, setLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [list, setList] = useState<{
        frame: number;
        message: string;
        "_id"?: string;
        type?: string;
        position?: { x: number; y: number; z: number; };
    }[]>([]);

    const { jobInstance }: { jobInstance: Job } = useSelector((state: CombinedState) => ({
        jobInstance: state.annotation.job.instance,
    }))

    const checkIsSubmit = async () => {
        if (!jobInstance.checkResult) {
            const result = await api_checkFinish({ jobId: jobInstance.id });
            if (result.data === false) {
                setIsSubmit(true);
            }
            // } else if (jobInstance.project.projectType !== ProjectType.two) {
            //     setIsSubmit(false);
        } else if (jobInstance.checkResult) {
            try {
                const { startFrame, stopFrame, frameMeta } = jobInstance;
                for (let index = startFrame; index <= stopFrame; index++) {
                    const frameInfo = frameMeta.get(index)!;
                    frameIdToFrame[frameInfo.frameName] = index;
                }
                const data = JSON.parse(jobInstance.checkResult);
                if (data.code === 0 && data.data) {
                    const { hasError, errors = [] } = data.data;
                    if (!hasError) {
                        setList([]);
                        return;
                    } else {
                        setList(errors.map((error: { frameId: string; message: string }) => {
                            return {
                                message: error.message,
                                frame: frameIdToFrame[error.frameId],
                            }
                        }).sort((a: any, b: any) => a.frame - b.frame));
                    }
                } else {
                    message.error(`检测过程失败！-${data.message}`)
                }
            } catch (error) {
                message.error('检测过程失败！')
            }
        }
    }

    const checkJobAnnotationResult = async () => {
        try {
            setLoading(true);
            const data = await CX_check(true);
            let result = { success: false, data: undefined, message: '未知错误' };
            // if (jobInstance.project.projectType === ProjectType.two) {
            result = await api_sendCheckJob({ jobId: jobInstance.id, data: JSON.stringify(data) })
            // } else {
            //     result = await api_checkJobAnnotationResult({ jobId: jobInstance.id, data: JSON.stringify(data) })
            // }
            if (result?.success && result?.data) {
                message.success('检测链接成功！请稍后查看模型检测结果')
                const data = JSON.parse(result.data)
                if (data.code === 0 && data.data) {
                    message.success('检测成功！');
                    const { hasError, errors = [] } = data.data;
                    if (!hasError) {
                        setList([]);
                        return;
                    } else {
                        setList(errors.map((error: { frameId: string; message: string }) => {
                            return {
                                message: error.message,
                                frame: frameIdToFrame[error.frameId],
                            }
                        }))
                    }
                } else {
                    message.error(`检测过程失败！-${data.message}`)
                }
            } else if (result?.success) {
                message.success('检测链接成功！请稍后查看模型检测结果')
            } else {
                message.error(`检测失败！${result.message ? '-' + result.message : ''}`,);
            }
        } catch (error) {
            message.error('检测过程失败！')
        } finally {
            setLoading(false);
        }
    }

    const onSendCheckJobAnnotation = () => {
        const { startFrame, stopFrame, frameMeta } = jobInstance;
        for (let index = startFrame; index <= stopFrame; index++) {
            const frameInfo = frameMeta.get(index)!;
            frameIdToFrame[frameInfo.frameName] = index;
        }
        checkJobAnnotationResult();
    }

    const onAbort = async () => {
        const result = await api_cancelCheckJob({ jobId: jobInstance.id });
        if (result.success) {
            message.success("已成功取消！");
            setIsSubmit(false);
        }
    }


    useEffect(() => {
        checkIsSubmit();
    }, []);

    return <Spin spinning={loading} tip='结果检测中'>

        {isSubmit && <Result title='模型正在检测中...，请稍后再查看' />}

        {!isSubmit && <Descriptions bordered column={1} style={{ height: '500px', overflow: 'auto' }}>
            {
                list.map(mes => {
                    return <Descriptions.Item
                        key={mes.frame}
                        label={`第${mes.frame}帧`}
                    >{mes.message}</Descriptions.Item>;
                })
            }
        </Descriptions>}


        <Divider />
        <Row justify={'center'}>
            <Space>
                {isSubmit && <Button onClick={onAbort} type='primary' danger>终止检测</Button>}
                <Button onClick={onSendCheckJobAnnotation} type='primary' disabled={isSubmit}>提交检测</Button>
                <Button onClick={onCancel} type='primary' danger>关闭</Button>
            </Space>
        </Row>
    </Spin>
}

export default checkAnnotationResultComponent;