// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';
import { Canvas, CanvasMode } from 'cvat-canvas-wrapper';
import { Canvas3d } from 'canvas3d-wrapper';

import ObjectsListComponent from 'components/annotation-page/standard-workspace/objects-side-bar/objects-list';
import {
    updateAnnotationsAsync,
    removeObjectAsync,
    changeFrameAsync,
    collapseObjectItems,
    changeGroupColorAsync,
    copyShape as copyShapeAction,
    propagateObject as propagateObjectAction,
    onlyShowSelectStates as onlyShowSelectStatesAction,
    selectObjects as selectObjectsAction,
    changePanelModel as changePanelModelAction,
    updateObjectRelation as updateObjectRelationAction,
    activateObject as activateObjectAction,
    trackChangeStartFrame as trackChangeStartFrameAction,
    changeShowMultChangeAttr as changeShowMultChangeAttrAction,
    outsideObjectAsync,
} from 'actions/annotation-actions';
import isAbleToChangeFrame from 'utils/is-able-to-change-frame';
import { CombinedState, StatesOrdering, ObjectType, ColorBy, ActiveControl, ShapeType } from 'reducers/interfaces';
import { message } from 'antd';
import ObjectState from 'business/objects/objectState';
import Job from 'business/objects/job';

interface OwnProps {
    readonly: boolean;
}

interface StateToProps {
    jobInstance: any;
    frameNumber: any;
    statesHidden: boolean;
    statesLocked: boolean;
    statesCollapsedAll: boolean;
    collapsedStates: Record<number, boolean>;
    objectStates: any[];
    annotationsFilters: any[];
    colors: string[];
    colorBy: ColorBy;
    activatedStateID: number | null;
    minZLayer: number;
    maxZLayer: number;
    keyMap: KeyMap;
    normalizedKeyMap: Record<string, string>;
    selectedStatesID: number[];
    isOnlyShowSelectStates: boolean;
    isPanelListModel: boolean;
    statesOrdering: StatesOrdering;
    relationOperate: boolean;
    activeControl: ActiveControl;
    canvasInstance: Canvas | Canvas3d | null;

    perspective?: string;
    showMultChangeAttr: boolean;
}

interface DispatchToProps {
    updateAnnotations(states: any[]): void;
    collapseStates(states: any[], value: boolean): void;
    removeObject: (sessionInstance: any, objectState: any[], force: boolean) => void;
    copyShape: (objectState: any) => void;
    propagateObject: (objectState: any) => void;
    changeFrame(frame: number): void;
    changeGroupColor(group: number, color: string): void;
    onlyShowSelectStates(): void;
    selectObjects(statesID: number[]): void;
    changePanelModel(): void;
    updateObjectRelation(parentObject?: any, subObject?: any, deleteRelation?: any, updateStates?: any[]): void;
    collapseOrExpand(objectStates: any[], collapsed: boolean): void;
    activateObject(activatedStateID: number | null, activatedAttributeID: number | null): void;
    trackChangeStartFrame(clientID?: number | null): void;
    changeShowMultChangeAttr(showMultChangeAttr: boolean): void;

    outsideObjects(sessionInstance: Job, objectState: ObjectState[], reverse?: boolean): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            annotations: {
                states: objectStates,
                filters: annotationsFilters,
                collapsed,
                collapsedAll,
                selectedStatesID,
                activatedStateID,
                zLayer: { min: minZLayer, max: maxZLayer },
                isOnlyShowSelectStates,
                isPanelListModel,
                statesOrdering,
                showMultChangeAttr,
            },
            job: { instance: jobInstance },
            player: {
                frame: { number: frameNumber },
            },
            colors,
            relationOperate,
            canvas: { activeControl, instance },

        },
        subAnnotation: { perspective },
        settings: {
            shapes: { colorBy },
        },
        shortcuts: { keyMap, normalizedKeyMap },
    } = state;

    let statesHidden = true;
    let statesLocked = true;
    const showObjects = objectStates.filter((objectState) => objectState.objectType !== ObjectType.TAG)

    showObjects.forEach((objectState: any) => {
        const { lock } = objectState;
        if (!lock) {
            if (objectState.objectType !== ObjectType.TAG) {
                statesHidden = statesHidden && objectState.hidden;
            }
            statesLocked = statesLocked && objectState.lock;
        }
    });

    return {
        statesHidden,
        statesLocked,
        statesCollapsedAll: collapsedAll,
        collapsedStates: collapsed,
        objectStates: showObjects,
        frameNumber,
        jobInstance,
        annotationsFilters,
        colors,
        colorBy,
        activatedStateID,
        minZLayer,
        maxZLayer,
        keyMap,
        normalizedKeyMap,
        selectedStatesID,
        isOnlyShowSelectStates,
        isPanelListModel,
        statesOrdering,
        relationOperate,
        activeControl,
        canvasInstance: instance,

        perspective,
        showMultChangeAttr,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        updateAnnotations(states: any[]): void {
            dispatch(updateAnnotationsAsync(states));
        },
        collapseStates(states: any[], collapsed: boolean): void {
            dispatch(collapseObjectItems(states, collapsed));
        },
        removeObject(sessionInstance: any, objectState: any[], force: boolean): void {
            dispatch(removeObjectAsync(sessionInstance, objectState, force));
        },
        copyShape(objectState: any): void {
            dispatch(copyShapeAction(objectState));
        },
        propagateObject(objectState: any): void {
            dispatch(propagateObjectAction(objectState));
        },
        changeFrame(frame: number): void {
            dispatch(changeFrameAsync(frame));
        },
        changeGroupColor(group: number, color: string): void {
            dispatch(changeGroupColorAsync(group, color));
        },
        onlyShowSelectStates(): void {
            dispatch(onlyShowSelectStatesAction());
        },
        selectObjects(statesID: number[]): void {
            dispatch(selectObjectsAction(statesID));
        },
        changePanelModel(): void {
            dispatch(changePanelModelAction());
        },
        updateObjectRelation(parentObject?: any, subObject?: any, deleteRelation?: any, updateStates?: any[]): void {
            dispatch(updateObjectRelationAction(parentObject, subObject, deleteRelation, updateStates));
        },
        collapseOrExpand(objectStates: any[], collapsed: boolean): void {
            dispatch(collapseObjectItems(objectStates, collapsed));
        },
        activateObject(activatedStateID: number | null, activatedAttributeID: number | null): void {
            dispatch(activateObjectAction(activatedStateID, activatedAttributeID));
        },
        trackChangeStartFrame(clientID?: number): void {
            dispatch(trackChangeStartFrameAction(clientID));
        },
        changeShowMultChangeAttr(showMultChangeAttr: boolean): void {
            dispatch(changeShowMultChangeAttrAction(showMultChangeAttr));
        },
        outsideObjects(sessionInstance, objectState, reverse) {
            dispatch(outsideObjectAsync(sessionInstance, objectState, reverse));
        },
    };
}

function sortAndMap(objectStates: any[], ordering: StatesOrdering): number[] {
    let sorted = [];
    if (ordering === StatesOrdering.ID_ASCENT) {
        sorted = [...objectStates].sort((a: any, b: any): number => a.clientID - b.clientID);
    } else if (ordering === StatesOrdering.ID_DESCENT) {
        sorted = [...objectStates].sort((a: any, b: any): number => b.clientID - a.clientID);
    } else {
        sorted = [...objectStates].sort((a: any, b: any): number => b.updated - a.updated);
    }

    return sorted.map((state: any) => state.clientID);
}

function checkedSelect(object: any, activatedStateID: number | null, selectedStatesID: number[]): boolean {
    const { relation = {} } = object;
    const { children = [] } = relation;
    if (
        [object, ...children]
            .filter((item) => item)
            .some((obj) => obj.clientID === activatedStateID || selectedStatesID.includes(obj.clientID))
    ) {
        return true;
    }
    return false;
}

type Props = StateToProps & DispatchToProps & OwnProps;

interface State {
    statesOrdering: StatesOrdering;
    objectStates: any[];
    sortedStatesID: number[];
    isPanelListModel: boolean;
    isOnlyShowSelectStates: boolean;
    showStates: any[];
    statesID: number[];
    activatedStateID: number | null;
}

class ObjectsListContainer extends React.PureComponent<Props, State> {
    static defaultProps = {
        readonly: false,
    };

    public constructor(props: Props) {
        super(props);
        this.state = {
            statesOrdering: StatesOrdering.ID_ASCENT,
            objectStates: [],
            sortedStatesID: [],
            isPanelListModel: false,
            isOnlyShowSelectStates: false,
            showStates: [],
            statesID: [],
            activatedStateID: null,
        };
    }

    static getDerivedStateFromProps(props: Props, state: State): State | null {
        if (
            props.objectStates === state.objectStates &&
            props.isPanelListModel === state.isPanelListModel &&
            props.activatedStateID === state.activatedStateID &&
            props.isOnlyShowSelectStates === state.isOnlyShowSelectStates &&
            props.statesOrdering === state.statesOrdering
        ) {
            return null;
        }

        const sortedStatesID = sortAndMap(props.objectStates, props.statesOrdering);
        // 列表模式，展示所有
        if (props.isPanelListModel) {
            const states = props.objectStates;
            const { activatedStateID, selectedStatesID } = props;
            let showStates =
                states.filter((item) => {
                    const { relation = {} } = item;
                    const { parent } = relation;
                    return !parent;
                }) || [];
            if (props.isOnlyShowSelectStates) {
                // 只展示选中的
                showStates = showStates.filter((item: any): boolean =>
                    checkedSelect(item, activatedStateID, selectedStatesID),
                );
            }
            return {
                ...state,
                objectStates: showStates,
                sortedStatesID: sortAndMap(showStates, props.statesOrdering),
                isPanelListModel: props.isPanelListModel,
                isOnlyShowSelectStates: props.isOnlyShowSelectStates,
                statesOrdering: props.statesOrdering,
                showStates,
                statesID: sortedStatesID,
                activatedStateID: props.activatedStateID,
            };
        }

        // 非列表模式，只展示选中的。多选时，只展示第一个。
        // 只展示选中的将会无效。
        const { activatedStateID, selectedStatesID } = props;
        const states = [];
        let objectStateID = activatedStateID;
        if (selectedStatesID && selectedStatesID.length) {
            [objectStateID] = selectedStatesID;
        }
        const objectState = props.objectStates.find((item: any): any => item.clientID === objectStateID);
        if (objectState) {
            states.push(objectState);
        }

        return {
            ...state,
            objectStates: states,
            sortedStatesID: sortAndMap(states, props.statesOrdering),
            isPanelListModel: props.isPanelListModel,
            isOnlyShowSelectStates: props.isOnlyShowSelectStates,
            statesOrdering: props.statesOrdering,
            showStates: states,
            statesID: sortedStatesID,
            activatedStateID: props.activatedStateID,
        };
        // return {
        //     ...state,
        //     objectStates: props.objectStates,
        //     sortedStatesID: sortAndMap(props.objectStates, state.statesOrdering),
        // };
    }

    private onChangeStatesOrdering = (statesOrdering: StatesOrdering): void => {
        const { showStates } = this.state;
        this.setState({
            statesOrdering,
            sortedStatesID: sortAndMap(showStates, statesOrdering),
        });
    };

    private onLockAllStates = (): void => {
        this.lockAllStates(true);
    };

    private onUnlockAllStates = (): void => {
        this.lockAllStates(false);
    };

    private onCollapseAllStates = (): void => {
        this.collapseAllStates(true);
    };

    private onExpandAllStates = (): void => {
        this.collapseAllStates(false);
    };

    private onHideAllStates = (): void => {
        this.hideAllStates(true);
    };

    private onShowAllStates = (): void => {
        this.hideAllStates(false);
    };

    // 只展示当前选中的一个或数个或不显示
    private onlyShowSelectStates = (): void => {
        const {
            onlyShowSelectStates,
        } = this.props;

        onlyShowSelectStates();
    };

    // 添加可以选中多个的功能
    private selectedStates = (): any | null => {
        const { objectStates, selectedStatesID, activatedStateID } = this.props;
        // 多选复制保存，ctrl+v,
        if (selectedStatesID && selectedStatesID.length) {
            const [...states] = objectStates.filter((objectState: any): boolean =>
                selectedStatesID.includes(objectState.clientID),
            );
            return states || null;
        }
        if (activatedStateID !== null) {
            const [state] = objectStates.filter(
                (objectState: any): boolean => objectState.clientID === activatedStateID,
            );

            return [state] || null;
        }

        return null;
    };

    private withSubStates = (states: any[]): any[] => {
        const { relationOperate } = this.props;
        return states.reduce((previous, current) => {
            const { relation = {} } = current;
            previous.push(current);
            if (relationOperate && relation.children) {
                previous.push(...relation.children);
            } else if (relation.children && relation.children.length) {
                relation.children.forEach((item: any) => {
                    if (item.relation && item.relation.relationType) {
                        previous.push(item);
                    }
                });
            }
            return previous;
        }, []);
    };

    private changeRelegation = (selectParentID?: number | undefined): void => {
        const {
            activatedStateID,
            objectStates: states,
            jobInstance,
            updateObjectRelation,
            collapseOrExpand,
        } = this.props;
        if (activatedStateID) {
            const objectState = states.find((object: any) => object.clientID === activatedStateID);
            const parentID = selectParentID || objectState.parentID;
            const parentObject = states.find((item) => item.clientID === parentID);

            const { relation = {} } = objectState;

            // 新增
            if (relation.id && !selectParentID) {
                // 删除
                updateObjectRelation(null, null, [relation.id], [objectState]);
            } else if (selectParentID) {
                updateObjectRelation(parentObject, objectState);
            }

            // 归属完成后，再次展开一次当前选中的对象
            setTimeout(() => {
                // this.collapse(true);
                collapseOrExpand([objectState], false);
            });
        }
    };

    private activityObject = (type: 'next' | 'prev'): void => {
        const { activatedStateID, activeControl, activateObject, collapseOrExpand, objectStates, canvasInstance } =
            this.props;
        const { statesID } = this.state;
        if (
            activatedStateID !== null &&
            activeControl === ActiveControl.CURSOR &&
            canvasInstance?.mode() === CanvasMode.IDLE
        ) {
            const selectIndex = statesID.findIndex((id: number) => id === activatedStateID);
            let currentActivatedID = activatedStateID;
            if (type === 'next') {
                if (selectIndex === statesID.length - 1) {
                    // 已经是最后一个了
                    message.info('已经是最后一个了');
                    return;
                }
                currentActivatedID = statesID[selectIndex + 1];
            }
            if (type === 'prev') {
                if (selectIndex === 0) {
                    // 已经是最后一个了
                    message.info('已经是第一个了');
                    return;
                }
                currentActivatedID = statesID[selectIndex - 1];
            }

            activateObject(currentActivatedID, null);
            const objectState = objectStates.find((obj) => obj.clientID === currentActivatedID);
            // console.log('正常：', objectStates);

            if (objectState) {
                collapseOrExpand([objectState], false);
            }
        }
    };

    private lockAllStates(locked: boolean): void {
        const { objectStates, updateAnnotations, readonly } = this.props;

        if (!readonly) {
            for (const objectState of objectStates) {
                objectState.lock = locked;
            }

            updateAnnotations(objectStates);
        }
    }

    private hideAllStates(hidden: boolean): void {
        const { objectStates, updateAnnotations, readonly } = this.props;

        if (!readonly) {
            for (const objectState of objectStates) {
                objectState.hidden = hidden;
            }

            updateAnnotations(objectStates);
        }
    }

    private collapseAllStates(collapsed: boolean): void {
        const { objectStates, collapseStates } = this.props;

        collapseStates(objectStates, collapsed);
    }

    private onChangeShowMultChangeAttr = (): void => {
        const { showMultChangeAttr, changeShowMultChangeAttr } = this.props;

        changeShowMultChangeAttr(!showMultChangeAttr);
    }

    private getDeletesStates(): ObjectState[] {
        const { jobInstance, readonly } = this.props;
        const states: ObjectState[] = this.selectedStates();

        if (states && !readonly) {
            // 当前要删除的对象中 统计要删除的父子关联关系
            // 3d没有关系
            let deleteStates = states;
            if (!(jobInstance.dimension === 'three' || jobInstance.dimension === '3d')) {
                const { updateObjectRelation } = this.props;
                const updateStates: any[] = [];
                const deleteRelationIds = states.reduce((previous: number[], current) => {
                    const { relation = {} } = current;

                    if (relation.parent) {
                        // 有父元素，代表是二级对象。删除自身的关系
                        previous.push(relation.id);
                        updateStates.push(current);
                    } else if (relation.children) {
                        // 有子元素，代表是一级对象。根据父元素去删除所有子元素。
                        relation.children.forEach((item: any) => {
                            previous.push(item.relation.id);
                        });
                        updateStates.push(current, ...relation.children);
                    }
                    return previous;
                }, []);
                updateObjectRelation(null, null, deleteRelationIds, states);

                // 删除时，是否要将子对象删除。
                deleteStates = this.withSubStates(states);
            }
            return deleteStates;
        }
        return [];
    }

    public render(): JSX.Element {
        const {
            statesHidden,
            statesLocked,
            activatedStateID,
            jobInstance,
            maxZLayer,
            minZLayer,
            keyMap,
            normalizedKeyMap,
            colors,
            colorBy,
            readonly,
            statesCollapsedAll,
            isOnlyShowSelectStates,

            perspective,

            updateAnnotations,
            changeGroupColor,
            removeObject,
            copyShape,
            propagateObject,
            changeFrame,
            selectObjects,
            changePanelModel,
            trackChangeStartFrame,

            outsideObjects,
        } = this.props;
        const {
            objectStates,
            sortedStatesID,
            // statesOrdering,
            showStates,
        } = this.state;

        const subKeyMap = {
            SWITCH_ALL_LOCK: keyMap.SWITCH_ALL_LOCK,
            SWITCH_LOCK: keyMap.SWITCH_LOCK,
            SWITCH_ALL_HIDDEN: keyMap.SWITCH_ALL_HIDDEN,
            SWITCH_HIDDEN: keyMap.SWITCH_HIDDEN,
            SWITCH_OCCLUDED: keyMap.SWITCH_OCCLUDED,
            SWITCH_KEYFRAME: keyMap.SWITCH_KEYFRAME,
            SWITCH_OUTSIDE: keyMap.SWITCH_OUTSIDE,
            // DELETE_OBJECT: keyMap.DELETE_OBJECT,
            TO_BACKGROUND: keyMap.TO_BACKGROUND,
            TO_FOREGROUND: keyMap.TO_FOREGROUND,
            COPY_SHAPE: keyMap.COPY_SHAPE,
            PROPAGATE_OBJECT: keyMap.PROPAGATE_OBJECT,
            track_change_start_frame: keyMap.track_change_start_frame,
            NEXT_KEY_FRAME: keyMap.NEXT_KEY_FRAME,
            PREV_KEY_FRAME: keyMap.PREV_KEY_FRAME,
            CHANGE_OBJECT_COLOR: keyMap.CHANGE_OBJECT_COLOR,
            // TILT_UP: keyMap.TILT_UP,
            // TILT_DOWN: keyMap.TILT_DOWN,
            // ROTATE_LEFT: keyMap.ROTATE_LEFT,
            // ROTATE_RIGHT: keyMap.ROTATE_RIGHT,
            // MOVE_UP: keyMap.MOVE_UP,
            // MOVE_DOWN: keyMap.MOVE_DOWN,
            // MOVE_LEFT: keyMap.MOVE_LEFT,
            // MOVE_RIGHT: keyMap.MOVE_RIGHT,
            // ZOOM_IN: keyMap.ZOOM_IN,
            // ZOOM_OUT: keyMap.ZOOM_OUT,
            PANEL_SHOW_MODEL: keyMap.PANEL_SHOW_MODEL,
            SELECT_FRAME_STATES: keyMap.SELECT_FRAME_STATES,
            CANCLE_RELATION_ON_ACTIVITY: keyMap.CANCLE_RELATION_ON_ACTIVITY,
            NEXT_ACTIVITY_OBJECT: keyMap.NEXT_ACTIVITY_OBJECT,
            PREV_ACTIVITY_OBJECT: keyMap.PREV_ACTIVITY_OBJECT,
            delete_object: keyMap.delete_object,
            delete_all_object: keyMap.delete_all_object,
        };

        const preventDefault = (event: KeyboardEvent | undefined): void => {
            if (event) {
                event.preventDefault();
            }
        };

        const activatedStated = (): any | null => {
            if (activatedStateID !== null) {
                const [state] = objectStates.filter(
                    (objectState: any): boolean => objectState.clientID === activatedStateID,
                );

                return state || null;
            }

            return null;
        };

        const handlers = {
            // TILT_UP: () => { }, // Handled by CVAT 3D Independently
            // TILT_DOWN: () => { },
            // ROTATE_LEFT: () => { },
            // ROTATE_RIGHT: () => { },
            // MOVE_UP: () => { },
            // MOVE_DOWN: () => { },
            // MOVE_LEFT: () => { },
            // MOVE_RIGHT: () => { },
            // ZOOM_IN: () => { },
            // ZOOM_OUT: () => { },
            SWITCH_ALL_LOCK: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                this.lockAllStates(!statesLocked);
            },
            SWITCH_LOCK: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state && !readonly) {
                    state.lock = !state.lock;
                    updateAnnotations([state]);
                }
            },
            SWITCH_ALL_HIDDEN: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                if (!readonly) {
                    this.hideAllStates(!statesHidden);
                }
            },
            SWITCH_HIDDEN: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state && !readonly) {
                    state.hidden = !state.hidden;
                    updateAnnotations([state]);
                }
            },
            SWITCH_OCCLUDED: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                // const state = activatedStated();
                // if (state && !readonly && state.objectType !== ObjectType.TAG) {
                //     state.occluded = !state.occluded;
                //     updateAnnotations([state]);
                // }
            },
            SWITCH_KEYFRAME: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state && !readonly && state.objectType === ObjectType.TRACK) {
                    state.keyframe = !state.keyframe;
                    updateAnnotations([state]);
                }
            },
            SWITCH_OUTSIDE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state && !readonly && state.objectType === ObjectType.TRACK) {
                    state.outside = !state.outside;
                    updateAnnotations([state]);
                }
            },
            DELETE_OBJECT: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                // const state = activatedStated();
                // if (state && !readonly) {
                //     removeObject(jobInstance, state, event ? event.shiftKey : false);
                // }

                const deleteStates = this.getDeletesStates();
                // const states: any[] = this.selectedStates();

                if (deleteStates.length) {
                    // 当前要删除的对象中 统计要删除的父子关联关系
                    // 3d没有关系
                    // let deleteStates = states;
                    // if (!(jobInstance.dimension === 'three' || jobInstance.dimension === '3d')) {
                    //     const { updateObjectRelation } = this.props;
                    //     const updateStates: any[] = [];
                    //     const deleteRelationIds = states.reduce((previous: number[], current) => {
                    //         const { relation = {} } = current;

                    //         if (relation.parent) {
                    //             // 有父元素，代表是二级对象。删除自身的关系
                    //             previous.push(relation.id);
                    //             updateStates.push(current);
                    //         } else if (relation.children) {
                    //             // 有子元素，代表是一级对象。根据父元素去删除所有子元素。
                    //             relation.children.forEach((item: any) => {
                    //                 previous.push(item.relation.id);
                    //             });
                    //             updateStates.push(current, ...relation.children);
                    //         }
                    //         return previous;
                    //     }, []);
                    //     updateObjectRelation(null, null, deleteRelationIds, states);

                    //     // 删除时，是否要将子对象删除。
                    //     deleteStates = this.withSubStates(states);
                    // }
                    if (!event?.ctrlKey && deleteStates.length === 1 && deleteStates[0].shapeType === ShapeType.bezier2) {
                        // 贝塞尔曲线删除单个点
                        const { canvasInstance } = this.props;
                        try {
                            (canvasInstance as Canvas3d).deletePoint(deleteStates[0].clientID);
                        } catch (error) {
                            message.error((error as Error).message);
                        }
                        return;
                    }
                }
                if (deleteStates.length) {
                    removeObject(jobInstance, deleteStates, event ? event.shiftKey : false);
                }
            },
            CHANGE_OBJECT_COLOR: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state) {
                    if (colorBy === ColorBy.GROUP) {
                        const colorID = (colors.indexOf(state.group.color) + 1) % colors.length;
                        changeGroupColor(state.group.id, colors[colorID]);
                        return;
                    }

                    if (colorBy === ColorBy.INSTANCE) {
                        const colorID = (colors.indexOf(state.color) + 1) % colors.length;
                        state.color = colors[colorID];
                        updateAnnotations([state]);
                    }
                }
            },
            TO_BACKGROUND: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state && !readonly && state.objectType !== ObjectType.TAG) {
                    state.zOrder = minZLayer - 1;
                    updateAnnotations([state]);
                }
            },
            TO_FOREGROUND: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state && !readonly && state.objectType !== ObjectType.TAG) {
                    state.zOrder = maxZLayer + 1;
                    updateAnnotations([state]);
                }
            },
            COPY_SHAPE: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                // const state = activatedStated();
                const state = this.selectedStates();
                if (state && !readonly) {
                    copyShape(state);
                }
            },
            PROPAGATE_OBJECT: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state && !readonly) {
                    propagateObject(state);
                }
            },
            track_change_start_frame: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state.objectType === ObjectType.TRACK && !readonly) {
                    trackChangeStartFrame?.(activatedStateID || undefined);
                }
            },
            NEXT_KEY_FRAME: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state && state.objectType === ObjectType.TRACK) {
                    const frame = typeof state.keyframes.next === 'number' ? state.keyframes.next : null;
                    if (frame !== null && isAbleToChangeFrame()) {
                        changeFrame(frame);
                    }
                }
            },
            PREV_KEY_FRAME: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const state = activatedStated();
                if (state && state.objectType === ObjectType.TRACK) {
                    const frame = typeof state.keyframes.prev === 'number' ? state.keyframes.prev : null;
                    if (frame !== null && isAbleToChangeFrame()) {
                        changeFrame(frame);
                    }
                }
            },
            PANEL_SHOW_MODEL: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                changePanelModel();
            },
            NEXT_ACTIVITY_OBJECT: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                this.activityObject('next');
            },
            PREV_ACTIVITY_OBJECT: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                this.activityObject('prev');
            },
            SELECT_FRAME_STATES: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                selectObjects([]);
            },
            CANCLE_RELATION_ON_ACTIVITY: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                this.changeRelegation();
            },
            delete_object: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const deleteStates: ObjectState[] = this.getDeletesStates();
                if (!event?.ctrlKey && deleteStates.length === 1 && deleteStates[0].shapeType === ShapeType.bezier2) {
                    // 贝塞尔曲线删除单个点
                    const { canvasInstance } = this.props;
                    try {
                        (canvasInstance as Canvas3d).deletePoint(deleteStates[0].clientID);
                    } catch (error) {
                        message.error((error as Error).message);
                    }
                    return;
                }
                if (deleteStates.length) {
                    outsideObjects(jobInstance, deleteStates);
                }
            },
            delete_all_object: (event: KeyboardEvent | undefined) => {
                preventDefault(event);
                const deleteStates: ObjectState[] = this.getDeletesStates();
                if (deleteStates.length) {
                    removeObject(jobInstance, deleteStates, event ? event.shiftKey : false);
                }
            },
        };

        return (
            <>
                {!perspective && <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />}
                <ObjectsListComponent
                    jobInstance={jobInstance}
                    statesHidden={statesHidden}
                    statesLocked={statesLocked}
                    statesCollapsedAll={statesCollapsedAll}
                    readonly={readonly || false}
                    // statesOrdering={statesOrdering}
                    sortedStatesID={sortedStatesID}
                    objectStates={showStates}
                    switchHiddenAllShortcut={normalizedKeyMap.SWITCH_ALL_HIDDEN}
                    switchLockAllShortcut={normalizedKeyMap.SWITCH_ALL_LOCK}
                    // changeStatesOrdering={this.onChangeStatesOrdering}
                    isOnlyShowSelectStates={isOnlyShowSelectStates}
                    lockAllStates={this.onLockAllStates}
                    unlockAllStates={this.onUnlockAllStates}
                    collapseAllStates={this.onCollapseAllStates}
                    expandAllStates={this.onExpandAllStates}
                    hideAllStates={this.onHideAllStates}
                    showAllStates={this.onShowAllStates}
                    onlyShowSelectStates={this.onlyShowSelectStates}
                    onChangeShowMultChangeAttr={this.onChangeShowMultChangeAttr}
                />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectsListContainer);
