/*
 * @Author: swxy
 * @Date: 2023-10-07 10:22:03
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { FC } from 'react';
import { Canvas3d } from 'canvas3d-wrapper';
import Icon, { Loading3QuartersOutlined } from '@ant-design/icons';
import { CombinedState, ObjectType, ShapeType } from 'reducers/interfaces';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

export interface Props {
    canvasInstance: Canvas3d;
    isDrawing: boolean;
    disabled?: boolean;
    cursorShortkey?: string;
    onClick?(): void;
}

const BezierLineComponent: FC<Props> = ({ canvasInstance, disabled, onClick, isDrawing }) => {

    const { t } = useTranslation();

    if (disabled) {
        return <Loading3QuartersOutlined className='aatp-bezier-control cvat-disabled-canvas-control' />
    }

    const dynamicIconProps = isDrawing ? {
        className: 'aatp-bezier-control cvat-active-canvas-control',
        onClick: () => {
            canvasInstance.draw({
                enabled: false,
                shapeType: ShapeType.bezier2,
            });
            canvasInstance.cancel();
        }
    } : {
        className: 'aatp-bezier-control',
        onClick: () => {
            onClick?.();
        }
    }

    return <Tooltip
        placement='rightTop'
        title={t('workspace.side.controls.bezier')}
    >
        <Loading3QuartersOutlined {...dynamicIconProps} />
    </Tooltip>
}


export default React.memo(BezierLineComponent);
