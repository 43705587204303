/*
 * @Author: swxy
 * @Date: 2024-03-14 14:22:33
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { Group, Vector3 } from 'three';
import Helper from './helper';
import Lane from '../objects/lane/laneModel';
import Point, { PointType } from '../objects/point';

class LaneHelper extends Helper {
    private readonly positions: Vector3[] = [];
    // @ts-expect-error
    public object: Lane = new Lane(-1, 'yellow');
    public point = new Point(-1, 'addPoint', PointType.MousePoint, new Vector3(), 'yellow', -1);

    public get length(): number {
        return this.positions.length;
    }

    constructor() {
        super();
    }

    public draw(position?: Vector3) {
        // 开始创建
        if (position) {
            if (this.isCreating) {
                this.isCreating = false;
                // const length = this.object.length;
                // 创建结束时，删除最后一个点
                // this.object.deletePosition(length - 1, 1);
                // this.object.updatePosition(position.clone(), this.positions.length - 1);
            } else {
                // this.object = new Bezier(-1, [...position.toArray(), ...position.toArray()], 'yellow');
                // 清空
                this.isCreating = true;

                // this.object = new Lane(-1, 'yellow');
                this.object.init();
                // this.object.updatePosition(position.clone());
                // this.object.updatePosition(position.clone());
            }
        } else if (this.isCreating) {
            this.isCreating = false;
            // const length = this.object.length;
            // // 创建结束时，删除最后一个点
            // this.object.deletePosition(length - 1, 1);
        }
    }

    // 绘制虚线点
    public drawDashedDots(pos?: Vector3) {
        // console.log('虚线点：');
        if (this.isEditing) {
            this.isEditing = false;
        } else {
            this.isEditing = true;
        }
    }

    public updatePosition(pos: Vector3, index?: number) {
        this.object.updatePosition(pos, index);
    }

    public deletePosition(index?: number, deleteCount: number = 0) {
        if (this.isCreating && this.object.length > 1) {
            this.object.deletePosition(index, deleteCount);
        }
    }

    public updateMousePosition(pos: Vector3) {
        this.point.position.set(pos.x, pos.y, pos.z);
        if (this.isCreating || this.isEditing) {
            if (this.object && this.object.parent) {
                this.object.parent.add(this.point);
            }
        } else if (this.point.parent) {
            this.point.removeFromParent();
        }
    }

    public addPoint(pos: Vector3) {
        this.object.addPoint(pos);
    }

    public addDashedDot(pos: Vector3) {
        //
        this.object.updateDashedDot(pos);
    }

    public drawnDashedDot() {}
}

export default LaneHelper;
