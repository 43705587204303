/*
 * @Author: swxy
 * @Date: 2023-09-01 14:10:40
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
function adjustName(name: string) {
    return name.replace(/\./g, '\u2219');
}

class AnnotationsFilter {
    // _convertObjects(statesData: any[]) {
    //     const objects = statesData.map((state) => {
    //         const labelAttributes = state.label.attributes.reduce((acc, attr) => {
    //             acc[attr.id] = attr;
    //             return acc;
    //         }, {});

    //         let xtl = Number.MAX_SAFE_INTEGER;
    //         let xbr = Number.MIN_SAFE_INTEGER;
    //         let ytl = Number.MAX_SAFE_INTEGER;
    //         let ybr = Number.MIN_SAFE_INTEGER;
    //         let [width, height] = [null, null];

    //         if (state.objectType !== ObjectType.TAG) {
    //             state.points.forEach((coord, idx) => {
    //                 if (idx % 2) {
    //                     // y
    //                     ytl = Math.min(ytl, coord);
    //                     ybr = Math.max(ybr, coord);
    //                 } else {
    //                     // x
    //                     xtl = Math.min(xtl, coord);
    //                     xbr = Math.max(xbr, coord);
    //                 }
    //             });
    //             [width, height] = [xbr - xtl, ybr - ytl];
    //         }

    //         const attributes = {};
    //         Object.keys(state.attributes).reduce((acc, key) => {
    //             const attr = labelAttributes[key];
    //             let value = state.attributes[key];
    //             if (attr.inputType === AttributeType.NUMBER) {
    //                 value = +value;
    //             } else if (attr.inputType === AttributeType.CHECKBOX) {
    //                 value = value === 'true';
    //             }
    //             acc[adjustName(attr.name)] = value;
    //             return acc;
    //         }, attributes);

    //         return {
    //             width,
    //             height,
    //             attr: Object.fromEntries([[adjustName(state.label.name), attributes]]),
    //             label: state.label.name,
    //             serverID: state.serverID,
    //             objectID: state.clientID,
    //             type: state.objectType,
    //             shape: state.shapeType,
    //             occluded: state.occluded,
    //         };
    //     });

    //     return objects;
    // }

    filter(statesData: any[], filters: any[]) {
        return statesData;
        // if (!filters.length) return statesData;
        // const converted = this._convertObjects(statesData);
        // return converted
        //     .map((state: any) => state.objectID)
        //     .filter((_: any, index: number) => jsonLogic.apply(filters[0], converted[index]));
    }
}

export default AnnotationsFilter;
