/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import {
    withRouter,
} from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { Row, Col } from 'antd/lib/grid';
import Layout from 'antd/lib/layout';
import { AmygoLogo } from 'icons';
import FooterDrawer from 'components/login-page/intel-footer-drawer';
import Language from 'components/language/language';
import LoginForm, { LoginData } from './login-form';
import { useTranslation } from 'react-i18next';
import { isCatchEyeVersion, isDemo } from 'consts';
import { useDispatch } from 'react-redux';
// import EmailLoginForm from './email-login-form';
// import { loginByCodeAsync } from 'actions/auth-actions';

const bodyBackGroundColor = isCatchEyeVersion ?
    `linear-gradient(45deg, rgba(78, 255, 34,.6), rgba(255, 255, 255, 0) 50%),
    linear-gradient(135deg, rgba(34, 122, 255,.6), rgba(255, 255, 255,0) 50%),
    linear-gradient(225deg, rgba(255, 34, 226,.6), rgba(255, 255, 255,0) 50%),
    linear-gradient(315deg, rgba(255, 240, 34,.6), rgba(255, 255, 255,0) 50%)` :
    'black';

const loginStyle: React.CSSProperties = isCatchEyeVersion ? {
    background: 'linear-gradient(0deg, #9f23fd2e, #9e23fd80)'
} : {
    backgroundColor: '#555555',
}

interface LoginPageComponentProps {
    fetching: boolean;
    renderResetPassword: boolean;
    onLogin: (username: string, password: string, verifyCode_: string) => void;
}

function LoginPageComponent(props: LoginPageComponentProps & RouteComponentProps): JSX.Element {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isForget, setIsForget] = useState(false);

    const { Content } = Layout;

    const {
        fetching,
        onLogin: onPasswordLogin,
        // renderResetPassword
    } = props;


    // const onLoginByCode = async (values: any) => {
    //     dispatch(loginByCodeAsync({ ...values }));
    // }

    let body = (
        <>
            <Row>
                <Title className='login_title' style={{ color: 'white' }} level={2}>
                    {' '}
                    {t('login.title')}
                    {' '}
                </Title>
            </Row>
            <Row>
                <LoginForm
                    fetching={fetching}
                    onSubmit={(loginData: LoginData): void => {
                        onPasswordLogin(loginData.username, loginData.password, loginData.verifyCode_);
                    }}
                />
            </Row>
        </>
    )
    if (isDemo) {
        body = (
            <>
                <Row className='login_title_demo' justify='center' align='middle'>
                    <Title style={{ color: 'white' }} level={2}>
                        {' '}
                        {t('login.demoTitle')}
                        {' '}
                    </Title>
                </Row>
                <Row className='login_title_demo' justify='end' align='middle'>
                    <Title style={{ color: 'white' }} level={4}>
                        {' '}
                        {t('login.demoSubTitle')}
                        {' '}
                    </Title>
                </Row>
                <Row>
                    {/* {isForget ? <EmailLoginForm
                        fetching={fetching}
                        onSubmit={(data: { email: string, verifyCode_: string }): void => {
                            onLoginByCode(data);

                        }}
                        onCancel={() => {
                            setIsForget(false);
                        }}
                    /> :  */}
                    <LoginForm
                        fetching={fetching}
                        onSubmit={(loginData: LoginData): void => {
                            onPasswordLogin(loginData.username, loginData.password, loginData.verifyCode_);
                        }}
                        onForget={() => {
                            setIsForget(true);
                        }}
                    />
                    {/* } */}
                </Row>
            </>
        );
    }

    return (
        <Layout>
            <Content>
                <Row className='language' justify='end'>
                    <Language />
                </Row>
                <Row justify='center' align='middle' className='body' style={{ background: bodyBackGroundColor }}>
                    <Row justify='center' align='middle' className='login_logo_row'>
                        <AmygoLogo className='login_logo' />
                    </Row>
                    <Row className='login_body' style={loginStyle}>
                        {body}
                    </Row>
                </Row>
            </Content>
            <FooterDrawer />
        </Layout>
    );
}

export default withRouter(LoginPageComponent);
