/*
 * @Author: swxy
 * @Date: 2023-08-17 16:16:09
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import React, { FC, useRef, useEffect } from 'react';
import { Canvas3d } from "canvas3d-wrapper";
import { ActiveView, CombinedState, Workspace } from "reducers/interfaces";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveView, changeZoomView } from 'actions/annotation-actions';
import "../styles.scss";
import { Row, Space } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined, ArrowUpOutlined, ArrowDownOutlined, ArrowLeftOutlined, ArrowRightOutlined, DragOutlined, RedoOutlined } from '@ant-design/icons';

interface Props {
    viewType: ActiveView;
}

const titleByView: Record<ActiveView, string> = {
    [ActiveView.Top]: 'workspace.content.view.top',
    [ActiveView.Side]: 'workspace.content.view.side',
    [ActiveView.Front]: 'workspace.content.view.front',
    [ActiveView.default]: '未知视角',
}

const ThreeCanvasComponent: FC<Props> = ({
    viewType,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const viewRef = useRef<HTMLDivElement | null>(null);
    const {
        canvasInstance,
        activatedStateID,
        acitveView,
        zoomView,
        showThreeViewShortcuts,
        workspace,
    } = useSelector((state: CombinedState) => ({
        activatedStateID: state.annotation.annotations.activatedStateID,
        canvasInstance: state.annotation.canvas.instance as Canvas3d,
        acitveView: state.annotation.annotations.acitveView,
        zoomView: state.annotation.canvas.zoomView,
        showThreeViewShortcuts: state.settings.annotationSetting.showThreeViewShortcuts,
        workspace: state.annotation.workspace,
    }))

    const onFocus = (): void => {
        if (activatedStateID) {
            dispatch(changeActiveView(viewType));
        }
    };

    const onBlur = (): void => {
        // onChangeActiveView(ActiveView.default);
        dispatch(changeActiveView(ActiveView.default));
    };

    const onZoom = (): void => {
        dispatch(changeZoomView(viewType));
    }

    const onCloseZoom = () => {
        dispatch(changeZoomView(ActiveView.default));
    }

    useEffect(() => {
        const canvasInstanceDOM = canvasInstance.html();

        if (
            viewRef &&
            viewRef.current
        ) {
            switch (viewType) {
                case ActiveView.Top: {
                    viewRef.current.appendChild(canvasInstanceDOM.top);
                    break;
                }
                case ActiveView.Side: {
                    viewRef.current.appendChild(canvasInstanceDOM.side);
                    break;
                }
                case ActiveView.Front: {
                    viewRef.current.appendChild(canvasInstanceDOM.front);
                    break;
                }
            }
        }

        return () => { };
    }, []);

    const shortcutElement = <div className='aatp_canvas3d_view_help'>
        <div>
            <DragOutlined /> ：
            <ArrowUpOutlined />
            <ArrowRightOutlined />
            <ArrowDownOutlined />
            <ArrowLeftOutlined />
        </div>

        <div>
            <RedoOutlined /> ：
            {
                viewType === ActiveView.Top && <>
                    Shift +
                    <ArrowUpOutlined />
                    <ArrowDownOutlined />
                </>
            }
            {
                viewType === ActiveView.Side && <>
                    Shift +
                    <ArrowRightOutlined />
                    <ArrowLeftOutlined />
                </>
            }
            {
                viewType === ActiveView.Front && <>
                    Shift +
                    <ArrowRightOutlined />
                    <ArrowLeftOutlined />
                </>
            }
        </div>
    </div>


    const element = <div
        className='aatp_canvas3d_orthographic_view'
        // style={{ height: zoomView === viewType ? '100%' : 'calc(100% / 3)' }}
        onContextMenu={(e) => {
            // 取消右键事件
            e.stopPropagation();
            e.preventDefault();
        }}>
        <div
            ref={viewRef}
            tabIndex={1}
            className={`aatp_canvas3d_fullsize`}
            onFocus={() => onFocus()}
            onBlur={() => onBlur()}
        />
        <Row className='aatp_canvas_view' justify={'space-between'}>
            <div
                className={`aatp_canvas_view_title${acitveView === viewType ? ' aatp_canvas3d-active' : ''}`}
            >
                {t(titleByView[viewType])}
            </div>
            <div
                className={`aatp_canvas_view_content`}
            >
                <Space>
                    {viewType === zoomView ? <ZoomOutOutlined onClick={onCloseZoom} /> : <ZoomInOutlined onClick={onZoom} />}
                </Space>
            </div>
        </Row>
        {workspace === Workspace.STANDARD3D && showThreeViewShortcuts && shortcutElement}
    </div>

    return element;
}

export default ThreeCanvasComponent;