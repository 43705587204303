/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2023-07-21 14:09:36
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useState, useEffect } from 'react';
import Layout from 'antd/lib/layout';

import CanvasWrapperContainer from 'containers/annotation-page/canvas/canvas-wrapper3D2D';
import ControlsSideBarContainer from 'containers/annotation-page/review3D-workspace/controls-side-bar/controls-side-bar';
import ControlsSubSideBarContainer from './sub/controlsSideBarContatiner';
import ObjectSideBarComponent from 'components/annotation-page/standard3D2D-workspace/objects-side-bar/objects-side-bar';
import ObjectsListContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/objects-list';
// import CanvasContextMenuContainer from 'containers/annotation-page/canvas/canvas-context-menu';
import IssueAggregatorComponent from './issues-aggregator';
import { useSelector, useDispatch } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import { disableEditing3D } from 'utils/constant';
import { changePerspectiveView } from 'actions/annotationSub-actions';
import { CloseCircleFilled } from '@ant-design/icons';
import ObjectSideBar2DComponent from 'components/annotation-page/standard3D2D-workspace/sub/objects-side-bar/objects-side-bar';
import CanvasWrapper2DComponent from 'components/annotation-page/standard3D2D-workspace/sub/canvas-wrapper';
import IssueAggregator2DComponent from './sub/issues-aggregator';

export default function ReviewWorkspaceComponent(props: any): JSX.Element {
    const dispatch = useDispatch();
    const { roleType, jobStatus, perspective } = useSelector((state: CombinedState) => ({
        roleType: state.auth.user?.roleType,
        jobStatus: state.annotation.job.instance?.jobStatus,
        perspective: state.subAnnotation.perspective,
    }));
    const [disableEdit, setDisableEdit] = useState(true);

    useEffect(() => {
        setDisableEdit(disableEditing3D(jobStatus, roleType));
    }, [jobStatus, roleType]);
    const clearPerspectiveView = () => {
        dispatch(changePerspectiveView());
    }
    return (
        <Layout hasSider className='cvat-review-workspace'>
            {
                perspective && <div className='aatp_standard_sub_background' onClick={clearPerspectiveView}>
                    <div className='aatp_standard_sub_mian' onClick={(e) => e.stopPropagation()}>
                        <ControlsSubSideBarContainer />
                        <CanvasWrapper2DComponent />
                        <ObjectSideBar2DComponent readonly />
                        <IssueAggregator2DComponent />
                    </div>
                    <div className='aatp_standard_sub_close'>
                        <CloseCircleFilled />
                    </div>
                </div>
            }

            <ControlsSideBarContainer />
            <CanvasWrapperContainer disableEdit={disableEdit} />
            <ObjectSideBarComponent objectsList={<ObjectsListContainer readonly={true} />} />
            {/* <PropagateConfirmContainer /> */}
            {/* <CanvasContextMenuContainer /> */}
            {/* <CanvasPointContextMenuComponent /> */}
            <IssueAggregatorComponent />
            {/* <TrackFrameComponent /> */}
        </Layout>
    );
}
