/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditTime: 2023-09-05 10:34:19
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import Form from 'antd/lib/form';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { isCanRegister } from 'consts';
import { Space, Typography, Row, Col } from 'antd';
import {
    Link,
} from 'react-router-dom';
import { ImageVerifyCode } from 'amygo-component';

const { Text } = Typography;


export interface LoginData {
    username: string;
    password: string;
    verifyCode_: string;
}

interface Props {
    fetching: boolean;
    isEmailLogin?: boolean; // 邮箱验证码登录
    onForget?(): void;
    onSubmit(loginData: LoginData): void;
}

let verifyCode: string = '';

function LoginFormComponent(props: Props): JSX.Element {
    const {
        // isEmailLogin,
        fetching, onSubmit, onForget } = props;

    const [form] = Form.useForm();
    const { t } = useTranslation();

    // const [visible, setVisible] = useState(false);

    return (
        <Form
            form={form}
            onFinish={(values) => onSubmit({
                ...values,
                verifyCode_: verifyCode,
            })}
            className='login-form'
        >
            <Form.Item
                hasFeedback
                name='username'
                rules={[
                    {
                        required: true,
                        message: t('login.empty_username'),
                    },
                ]}
            >
                <Input
                    autoComplete='username'
                    prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    placeholder={t('login.username')}
                />
            </Form.Item>

            <Form.Item
                hasFeedback
                name='password'
                rules={[
                    {
                        required: true,
                        message: t('login.empty_password'),
                    },
                ]}
            >
                <Input
                    autoComplete='current-password'
                    prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
                    placeholder={t('login.password')}
                    type='password'
                />
            </Form.Item>

            {/* <Form.Item> */}
            <Form.Item className='login-form-login-button-row'>
                <Button
                    type='primary'
                    loading={fetching}
                    disabled={fetching}
                    htmlType='submit'
                    // onClick={() => setVisible(true)}
                    className='login-form-button'
                >
                    {/* Sign in */}
                    {t('login.login')}
                    {/* 登录 */}
                </Button>
            </Form.Item>
            {/* <ImageVerifyCode
                open={visible}
                onSubmit={(code) => {
                    verifyCode = code || '';
                    form.submit();
                }}
                onCancel={() => setVisible(false)}
                okText={t('ok')}
                cancelText={t('cancel')}
            /> */}
            {
                isCanRegister && <Row justify='end' align='top'>
                    <Space>
                        <Button type='link' onClick={onForget}>{t('login.forgetPassword')}</Button>
                        <Link to='/register'>{t('login.register')}</Link>
                        {/* <Col>
                        <Text>
                            <Link to='/auth/password/reset'>{t('login.resetPassword')}</Link>
                        </Text>
                    </Col> */}
                    </Space>
                </Row>
            }

        </Form>
    );
}

export default React.memo(LoginFormComponent);
