/*
 * @Author: swxy
 * @Date: 2023-09-21 14:30:49
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { Row, Select, Radio, Col, Card, Typography, RadioChangeEvent } from 'antd';
import React, { useState, FC, memo } from 'react';
import { InputType } from 'reducers/interfaces';
import { Attribute, Label } from 'business/objects/label';

import './styles.scss';

const { Text } = Typography;

const renderSelect = ({ title, value, options, onChange }: {
    title: string;
    value: string;
    options: { label: string, value: string }[];
    onChange?: (value: string) => void
}): JSX.Element => (
    <Row style={rowStyle}>
        <Text style={titleStyle} strong>{title}：</Text>
        <Select
            style={contentStyle}
            value={value}
            onChange={onChange}
            options={options}
        />
    </Row>
);

const renderRadio = ({ title, value, options, onChange }: {
    title: string;
    value: string;
    options: { label: string, value: string }[];
    onChange?: (e: RadioChangeEvent) => void
}): JSX.Element => (
    <Row style={rowStyle}>
        <Text style={titleStyle} strong>{title}：</Text>
        <Radio.Group
            style={contentStyle}
            value={value}
            onChange={onChange}
            options={options}
        />
    </Row>
);

interface AttrProps {
    attribute: Attribute;
    onChange?: (attr: Record<number, string>) => void;
}

const tableStyle: React.CSSProperties = {
    display: 'table',
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
}
const rowStyle: React.CSSProperties = {
    display: 'table-row',
}
const titleStyle: React.CSSProperties = {
    display: 'table-cell',
    textAlign: 'right',
}
const contentStyle: React.CSSProperties = {
    display: 'table-cell',
}

const AttributeComponent: FC<AttrProps> = ({ attribute, onChange }) => {

    const { values, title, ranges, inputType, defaultValue, id } = attribute;
    const [value, setValue] = useState<string>(defaultValue)

    const onAttrChange = (data: string) => {
        setValue(data);
        onChange?.({ [id]: data });
    };

    if ([InputType.select].includes(inputType) && values.length > 5) {
        const options = values.map((value, index) => {
            return {
                label: ranges[index],
                value,
            }
        })
        return renderSelect({ options, title, value, onChange: onAttrChange });
    }

    if ([InputType.checkbox, InputType.select, InputType.radio].includes(inputType) && values.length) {
        const options = values.map((value, index) => {
            return {
                label: ranges[index],
                value,
            }
        })
        return renderRadio({
            options,
            title,
            value,
            onChange: (e: RadioChangeEvent) => {
                onAttrChange(e.target.value);
            }
        });
    }

    return <Row style={rowStyle}>
        <Text style={titleStyle} strong>{title}：</Text>
        <Text style={contentStyle} strong type='danger'>
            请联系管理员，暂不支持该格式属性的批量修改
        </Text>
    </Row>
}

interface Props {
    shapeIndex: number;
    label: Label;
    onChange?: (data: Record<number, string>, shapeIndex: number) => void;
}


const ChangeMultAttributeComponent: FC<Props> = ({ shapeIndex, label, onChange }) => {
    const { attributes = [] } = label;

    const onChangeAttribute = (data: Record<number, string>) => {

        onChange?.(data, shapeIndex);
    }

    return <Col span={8}>
        <Card title={`序号：${shapeIndex}`} bodyStyle={tableStyle}>
            {
                attributes.map((attr) => {
                    return <AttributeComponent key={attr.id} attribute={attr} onChange={onChangeAttribute} />
                })
            }
        </Card>
    </Col>
}

export default memo(ChangeMultAttributeComponent);