/*
 * @Author: swxy
 * @Date: 2023-08-21 18:15:05
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import FrameData from 'business/frame';
import { Attribute, Label } from './label';
import AnnotationHistory, { HistoryActions } from './annotationsHistory';
import { ServerTagData } from './tag';
import { ServerShapeData } from './shape';
import Track, { ServerTrackData } from './track';
import { InputType, ObjectType, ShapeType, Source, SubElement } from 'reducers/interfaces';
import { ArgumentError, DataError } from 'errors/exception';
import { checkObjectType } from 'common/common';
import { colors } from 'utils/enums';
import ObjectState, { SerializedData, UpdateFlags } from './objectState';
import { InjectionProps } from './annotationCollection';
import moment from 'moment';

type Frame = number;

// const AttributeType = {
//     CHECKBOX: 'checkbox',
//     RADIO: 'radio',
//     SELECT: 'select',
//     NUMBER: 'number',
//     TEXT: 'text',
//     SERIALID: 'serialid',
// });

const defaultGroupColor = '#E0E0E0';

export class Annotation {
    public jobId: number;

    public labels: Record<number, Label>;
    public history: AnnotationHistory;
    public groupColors: any;
    public serverIDByClientID: Record<number, number>;

    public shapeType: ShapeType = ShapeType.RECTANGLE;

    public clientID: number;
    public serverID?: number;
    public group?: number;
    public frame: number;
    public parentID?: number;
    public color?: string;
    public lock: boolean;
    public cameraName?: string;
    public direction?: string;
    public source: Source;
    public removed: boolean;
    public labelId: number;

    public label: Label;

    public updated: number;
    public attributes: Record<number, string>;
    public frameMeta: Record<Frame, FrameData>;
    public groupObject: {
        id: number;
        color: string;
    };

    public readonly startFrame: number;
    public readonly stopFrame: number;

    public readonly updateTime: number;
    public readonly createTime: number;

    public readonly readonly: boolean = false;

    public readonly isTag: boolean = false;
    public readonly isShape: boolean = false;
    public readonly isTrack: boolean = false;

    constructor(
        data: ServerTagData | ServerShapeData | ServerTrackData,
        clientID: number,
        color: string,
        injection: InjectionProps,
    ) {
        this.labels = injection.labels.reduce((previous: Record<number, Label>, current: Label) => {
            previous[current.labelId] = current;
            return previous;
        }, {});
        this.history = injection.history;
        this.groupColors = injection.groupColors;
        this.serverIDByClientID = injection.serverIDByClientID;
        this.frameMeta = injection.frameMeta;

        this.startFrame = injection.startFrame;
        this.stopFrame = injection.stopFrame;

        this.jobId = data.jobId;
        this.clientID = clientID;
        this.serverID = data.id;
        this.group = data.group || 0;
        this.frame = data.frame;
        this.removed = false;
        this.lock = false;
        this.color = color;
        this.source = data.source || Source.MANUAL;
        this.parentID = data.parentId || (data as ServerShapeData).shapeId || (data as ServerTrackData).trackId;
        (this.labelId = data.labelId),
            // this.direction = data.direction;
            // this.parentID = data.parentId;

            // this.shapeType = data.shapeType;

            (this.cameraName = data.cameraName);

        this.createTime = data.addTime ? moment(data.addTime).valueOf() : 0;
        this.updateTime = data.updateTime ? moment(data.updateTime).valueOf() : 0;

        if (injection.supplement && this.createTime) {
            // 补录时间 > 当前框的创建时间
            this.readonly = this.createTime < injection.supplement;
        }

        if (this.labels[data.labelId]) {
            this.label = this.labels[data.labelId];
        } else {
            // 删除没有的框
            this.removed = true;
            this.label = { id: data.labelId, attributes: [] } as any;
        }
        this.updated = Date.now();
        // this.attributesID = data.attributes.reduce((attributeAccumulator, attr) => {
        //     attributeAccumulator[attr.specId] = attr;
        //     return attributeAccumulator;
        // }, {});
        // this.attributesID.shapes = {};
        // this.attributes = data.attributes.reduce((attributeAccumulator, attr) => {
        //     attributeAccumulator[attr.specId] = attr.value;
        //     return attributeAccumulator;
        // }, {});
        this.attributes = { ...(data.attributes || {}) };
        // this.elements = (data).subPoints
        this.groupObject = Object.defineProperties(
            { id: 0, color: '' },
            {
                color: {
                    get: () => {
                        if (this.group) {
                            return this.groupColors[this.group] || colors[this.group % colors.length];
                        }
                        return defaultGroupColor;
                    },
                    set: (newColor) => {
                        if (this.group && typeof newColor === 'string' && /^#[0-9A-F]{6}$/i.test(newColor)) {
                            this.groupColors[this.group] = newColor;
                        }
                    },
                },
                id: {
                    get: () => this.group,
                },
            },
        );
        this.appendDefaultAttributes(this.label);

        injection.groups.max = Math.max(injection.groups.max, this.group);
    }

    public static objectStateFactory(frame: number, data: SerializedData) {
        const objectState = new ObjectState(data);

        objectState.save = (this as unknown as Annotation).save.bind(this, frame, objectState);
        objectState.delete = (this as unknown as Annotation).delete.bind(this);
        if (data.objectType === ObjectType.TRACK) {
            objectState.delete_outside = (this as unknown as Track).delete_outside.bind(this);
        } else {
            objectState.delete_outside = (this as unknown as Annotation).delete.bind(this);
        }

        // objectState.associated = this.associated.bind(this);
        // eslint-disable-next-line no-underscore-dangle
        // objectState.__internal = {
        //     save: this.save.bind(this, frame, objectState),
        //     delete: this.delete.bind(this),
        //     // export: this.export.bind(this),
        // };

        return objectState;
    }

    protected appendDefaultAttributes(label: Label) {
        const labelAttributes = label.attributes;
        for (const attribute of labelAttributes) {
            if (!(attribute.id in this.attributes)) {
                // this.attributes[attribute.id] = attribute.defaultValue;
                this.attributes[attribute.id] = attribute.defaultValue;
            }
        }
    }

    protected getParentServerID() {
        return this.parentID ? this.serverIDByClientID[this.parentID] : undefined;
    }

    protected static rotatePoint(x: number, y: number, angle: number, cx = 0, cy = 0) {
        const sin = Math.sin((angle * Math.PI) / 180);
        const cos = Math.cos((angle * Math.PI) / 180);
        const rotX = (x - cx) * cos - (y - cy) * sin + cx;
        const rotY = (y - cy) * cos + (x - cx) * sin + cy;
        return [rotX, rotY];
    }

    protected static checkNumberOfPoints(shapeType: ShapeType, points: number[]) {
        if ([ShapeType.RECTANGLE, ShapeType.splitRectangle].includes(shapeType)) {
            if (points.length / 2 !== 2) {
                throw new DataError(`矩形最少需要两个点，当前为： ${points.length / 2}`);
            }
        } else if (shapeType === ShapeType.POLYGON) {
            if (points.length / 2 < 3) {
                throw new DataError(`多边形最少需要3个点，当前为： ${points.length / 2}`);
            }
        } else if (shapeType === ShapeType.POLYLINE) {
            if (points.length / 2 < 2) {
                throw new DataError(`线最少需要两个点，当前为： ${points.length / 2}`);
            }
        } else if (shapeType === ShapeType.POINTS) {
            if (points.length / 2 < 1) {
                throw new DataError(`点集合最少需要一个点，当前数量： ${points.length / 2}`);
            }
        } else if (shapeType === ShapeType.CUBOID) {
            if (points.length / 2 !== 8) {
                throw new DataError(`长方体需要8个点，当前为： ${points.length / 2}`);
            }
        } else if (shapeType === ShapeType.ELLIPSE) {
            if (points.length / 2 !== 2) {
                throw new DataError(`圆需要一个点和x半径、y半径。当前为： ${points.toString()}`);
            }
        } else if (shapeType === ShapeType.laneline) {
            if (points.length / 3 < 2) {
                throw new DataError(`3D车道线至少需要2个点，当前只有： ${points.toString()}`);
            }
        } else if (shapeType === ShapeType.bezier2) {
            if (points.length / 3 < 2) {
                throw new DataError(`3D车道线至少需要2个点，当前只有： ${points.toString()}`);
            }
            // if (points.length / 3 < 2) {
            //     throw new DataError(`3D车道线至少需要2个点，当前只有： ${points.toString()}`);
            // }
        } else {
            throw new ArgumentError(`未知的框类型： ${shapeType}`);
        }
    }

    protected static validateAttributeValue(value: string, attr: Attribute) {
        // const { value } = att;
        const { values } = attr;
        const type = attr.inputType;

        if (typeof value !== 'string') {
            throw new ArgumentError(`属性值必须为字符串，但当前为： ${typeof value}`);
        }

        if (type === InputType.number) {
            return +value >= +values[0] && +value <= +values[1];
        }

        if (type === InputType.checkbox) {
            return ['true', 'false'].includes(value.toLowerCase());
        }

        if (type === InputType.text) {
            return true;
        }

        if (type === InputType.serialid) {
            // 判断是否是正整数
            return /^[a-zA-Z0-9]*$/.test(value);
        }

        return values.includes(value);
    }

    protected static fitPoints(shapeType: ShapeType, points: number[], rotation: number, maxX: number, maxY: number) {
        checkObjectType('rotation', rotation, 'number', null);
        points.forEach((coordinate) => checkObjectType('coordinate', coordinate, 'number', null));

        if (
            shapeType === ShapeType.CUBOID ||
            shapeType === ShapeType.ELLIPSE ||
            !!rotation ||
            shapeType === ShapeType.laneline ||
            shapeType === ShapeType.POINTS ||
            shapeType === ShapeType.bezier2
        ) {
            // cuboids and rotated bounding boxes cannot be fitted
            return points;
        }

        const fittedPoints = [];

        for (let i = 0; i < points.length - 1; i += 2) {
            const x = points[i];
            const y = points[i + 1];
            // Math.clamp(x, 0, maxX)
            const clampedX = Math.min(maxX, Math.max(x, 0));
            // const clampedY = Math.clamp(y, 0, maxY);
            const clampedY = Math.min(maxY, Math.max(y, 0));
            fittedPoints.push(clampedX, clampedY);
        }

        return fittedPoints;
    }

    protected static checkShapeArea(shapeType: ShapeType, points: number[]) {
        const MIN_SHAPE_LENGTH = 3;
        const MIN_SHAPE_AREA = 9;

        if (shapeType === ShapeType.POINTS) {
            return true;
        }

        if (shapeType === ShapeType.ELLIPSE) {
            const [cx, cy, rightX, topY] = points;
            const [rx, ry] = [rightX - cx, cy - topY];
            return rx * ry * Math.PI > MIN_SHAPE_AREA;
        }

        let xmin = Number.MAX_SAFE_INTEGER;
        let xmax = Number.MIN_SAFE_INTEGER;
        let ymin = Number.MAX_SAFE_INTEGER;
        let ymax = Number.MIN_SAFE_INTEGER;

        for (let i = 0; i < points.length - 1; i += 2) {
            xmin = Math.min(xmin, points[i]);
            xmax = Math.max(xmax, points[i]);
            ymin = Math.min(ymin, points[i + 1]);
            ymax = Math.max(ymax, points[i + 1]);
        }

        if (shapeType === ShapeType.POLYLINE) {
            const length = Math.max(xmax - xmin, ymax - ymin);
            return length >= MIN_SHAPE_LENGTH;
        }

        const area = (xmax - xmin) * (ymax - ymin);
        return area >= MIN_SHAPE_AREA;
    }

    protected static findAngleDiff(rightAngle: number, leftAngle: number) {
        let angleDiff = rightAngle - leftAngle;
        angleDiff = ((angleDiff + 180) % 360) - 180;
        if (Math.abs(angleDiff) >= 180) {
            // if the main arc is bigger than 180, go another arc
            // to find it, just substract absolute value from 360 and inverse sign
            angleDiff = 360 - Math.abs(angleDiff) * Math.sign(angleDiff) * -1;
        }
        return angleDiff;
    }

    protected _saveLock(lock: boolean, frame: number) {
        const undoLock = this.lock;
        const redoLock = lock;

        this.history.do(
            HistoryActions.CHANGED_LOCK,
            () => {
                this.lock = undoLock;
                this.updated = Date.now();
            },
            () => {
                this.lock = redoLock;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.lock = lock;
    }

    protected _saveColor(color: string, frame: number) {
        const undoColor = this.color;
        const redoColor = color;

        this.history.do(
            HistoryActions.CHANGED_COLOR,
            () => {
                this.color = undoColor;
                this.updated = Date.now();
            },
            () => {
                this.color = redoColor;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.color = color;
    }

    protected _saveHidden(hidden: boolean, frame: number) {
        throw new DataError('保存隐藏状态错误-没有调用子类方法');
    }

    protected _saveLabel(label: Label, frame: number) {
        const undoLabel = this.label;
        const redoLabel = label;
        const undoAttributes = { ...this.attributes };
        this.label = label;
        this.attributes = {};
        this.appendDefaultAttributes(label);

        // Try to keep old attributes if name matches and old value is still valid
        for (const attribute of redoLabel.attributes) {
            for (const oldAttribute of undoLabel.attributes) {
                if (
                    attribute.name === oldAttribute.name &&
                    Annotation.validateAttributeValue(undoAttributes[oldAttribute.id], attribute)
                ) {
                    this.attributes[attribute.id] = undoAttributes[oldAttribute.id];
                }
            }
        }
        const redoAttributes = { ...this.attributes };

        this.history.do(
            HistoryActions.CHANGED_LABEL,
            () => {
                this.label = undoLabel;
                this.attributes = undoAttributes;
                this.updated = Date.now();
            },
            () => {
                this.label = redoLabel;
                this.attributes = redoAttributes;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );
    }

    protected _saveAttributes(attributes: Record<number, string>, frame: number) {
        const undoAttributes = { ...this.attributes };

        for (const attrID of Object.keys(attributes)) {
            this.attributes[+attrID] = attributes[+attrID];
        }

        const redoAttributes = { ...this.attributes };

        this.history.do(
            HistoryActions.CHANGED_ATTRIBUTES,
            () => {
                this.attributes = undoAttributes;
                this.updated = Date.now();
            },
            () => {
                this.attributes = redoAttributes;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );
    }

    protected _saveParentID(parentID: number) {
        // const undoParentID = this.parentID;
        // const redoParentID = parentID;

        // const undoClientID = this.clientID;
        // const redoClientID = parentID;

        // this.history.do(
        //     HistoryActions.change_parentID,
        //     () => {
        //         this.parentID = undoParentID;
        //         this.clientID = undoClientID;
        //         this.updated = Date.now();
        //     },
        //     () => {
        //         this.parentID = redoParentID;
        //         this.clientID = redoClientID;
        //         this.updated = Date.now();
        //     },
        //     [this.clientID],
        //     frame,
        // );

        if (parentID) {
            this.parentID = parentID;
            // this.clientID = parentID;
        }
        if (this.direction) {
            // 2D视角的clientID与父ID相同
            this.clientID = parentID;
        }
    }

    protected _validateStateBeforeSave(frame: number, data: any, updated: Record<string, boolean>) {
        let fittedPoints: number[] = [];

        if (updated.label) {
            checkObjectType('label', data.label, undefined, Label);
        }

        const labelAttributes = data.label.attributes.reduce(
            (accumulator: Record<number, Attribute>, value: Attribute) => {
                accumulator[value.id] = value;
                return accumulator;
            },
            {},
        );

        if (updated.attributes) {
            for (const attrID of Object.keys(data.attributes)) {
                const value = data.attributes[attrID];
                if (attrID in labelAttributes) {
                    if (!Annotation.validateAttributeValue(value, labelAttributes[attrID])) {
                        throw new ArgumentError(
                            // `Trying to save an attribute attribute with id ${attrID} and invalid value ${value}`,
                            `保存的属性id ${attrID} 和值 ${value} 无效`,
                        );
                    }
                }
            }
        }

        // if (updated.descriptions) {
        //     if (!Array.isArray(data.descriptions) || data.descriptions.some((desc) => typeof desc !== 'string')) {
        //         throw new ArgumentError(
        //             `Descriptions are expected to be an array of strings but got ${data.descriptions}`,
        //         );
        //     }
        // }

        if (updated.points) {
            checkObjectType('points', data.points, undefined, Array);
            Annotation.checkNumberOfPoints(this.shapeType, data.points);
            if (data.direction) {
                fittedPoints = data.points;
            } else {
                // cut points
                const width = this.frameMeta[frame].getWidth();
                const height = this.frameMeta[frame].getHeight();
                // if (width && height) {
                fittedPoints = Annotation.fitPoints(this.shapeType, data.points, data.rotation, width, height);
                // }
                let check = true;
                // if (filename && filename.slice(filename.length - 3) === 'pcd') {
                //     check = false;
                // }
                if (this.shapeType === ShapeType.CUBOID || this.shapeType === ShapeType.laneline) {
                    check = false;
                }
                if (check) {
                    if (!Annotation.checkShapeArea(this.shapeType, fittedPoints)) {
                        fittedPoints = [];
                    }
                }
            }
        }

        if (updated.occluded) {
            checkObjectType('occluded', data.occluded, 'boolean', null);
        }

        if (updated.outside) {
            checkObjectType('outside', data.outside, 'boolean', null);
        }

        if (updated.zOrder) {
            checkObjectType('zOrder', data.zOrder, 'integer', null);
        }

        if (updated.lock) {
            checkObjectType('lock', data.lock, 'boolean', null);
        }

        if (updated.pinned) {
            checkObjectType('pinned', data.pinned, 'boolean', null);
        }

        if (updated.color) {
            checkObjectType('color', data.color, 'string', null);
            if (!/^#[0-9A-F]{6}$/i.test(data.color)) {
                // throw new ArgumentError(`Got invalid color value: "${data.color}"`);
                throw new ArgumentError(`无效的颜色值: "${data.color}"`);
            }
        }

        if (updated.hidden) {
            checkObjectType('hidden', data.hidden, 'boolean', null);
        }

        // if (updated.keyframe) {
        //     checkObjectType('keyframe', data.keyframe, 'boolean', null);
        //     if (!this.shapes || (Object.keys(this.shapes).length === 1 && !data.keyframe)) {
        //         updated.keyframe = false;
        //         throw new ArgumentError(
        //             // 'Can not remove the latest keyframe of an object. Consider removing the object instead',
        //             '连续帧至少需要拥有一个关键帧',
        //         );
        //     }
        // }

        return fittedPoints;
    }

    updateTimestamp(updated: Record<string, boolean>) {
        const anyChanges = Object.keys(updated).some((key) => !!updated[key]);
        if (anyChanges) {
            this.updated = Date.now();
        }
    }

    public updateParentID(parentID?: number) {
        if (this.parentID) {
            this.parentID = parentID;
        }
    }

    public get(frame: number): SerializedData {
        throw new DataError(`应该调用子类的获取方法：`);
    }

    public async save(frame: number, data: ObjectState): Promise<ObjectState> {
        throw new DataError(`应该调用子类的保存方法：`);
    }

    // public associated(clientID: number) {
    //     throw new DataError(`应该调用子类的关联方法：`);
    // }

    public async delete(frame: number, force: boolean) {
        if ((!this.lock || force) && !this.readonly) {
            this.removed = true;
            // this.relation = {};

            this.history.do(
                HistoryActions.REMOVED_OBJECT,
                () => {
                    // this.relation = {};
                    this.serverID = undefined;
                    this.removed = false;
                    this.updated = Date.now();
                    this.attributes = {};
                    // Object.values(this.attributes).forEach((attr) => {
                    //     delete attr.id;
                    //     delete attr.shapeId;
                    // });
                    // if (this.shapes) {
                    //     Object.values(this.shapes).forEach((shape) => {
                    //         delete shape.serverID;
                    //         shape.relation = {};
                    //         shape.attributes = {};
                    //         // if (shape.attributes) {
                    //         //     Object.values(shape.attributes).forEach((attr) => {
                    //         //         delete attr.id;
                    //         //         delete attr.shapeId;
                    //         //     });
                    //         // }
                    //     });
                    // }
                },
                () => {
                    this.removed = true;
                    this.updated = Date.now();
                },
                [this.clientID],
                frame,
            );
        }

        return this.removed;
    }
}

export class DrawnAnnotation extends Annotation {
    public hidden: boolean;
    public pinned: boolean;
    public descriptions: string[];
    // protected shapeType: ShapeType;

    public relation: any;

    constructor(data: ServerShapeData | ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);

        this.descriptions = data.descriptions || [];
        this.shapeType = ShapeType.RECTANGLE;

        this.hidden = false;
        this.pinned = true;
        this.direction = data.direction;
        this.relation = {};
    }

    _saveDescriptions(descriptions: string[]) {
        this.descriptions = [...descriptions];
    }
    _savePinned(pinned: boolean, frame: number) {
        const undoPinned = this.pinned;
        const redoPinned = pinned;

        this.history.do(
            HistoryActions.CHANGED_PINNED,
            () => {
                this.pinned = undoPinned;
                this.updated = Date.now();
            },
            () => {
                this.pinned = redoPinned;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.pinned = pinned;
    }

    _saveRelation(relation: any) {
        // const undoRelation = this.relation;
        // const redoRelation = relation;

        // this.history.do(
        //     HistoryActions.CHANGED_PINNED,
        //     () => {
        //         this.relation = undoRelation;
        //         this.updated = Date.now();
        //     },
        //     () => {
        //         this.relation = redoRelation;
        //         this.updated = Date.now();
        //     },
        //     [this.clientID],
        //     frame,
        // );

        this.relation = relation;
    }

    protected _saveHidden(hidden: boolean, frame: number) {
        const undoHidden = this.hidden;
        const redoHidden = hidden;

        this.history.do(
            HistoryActions.CHANGED_HIDDEN,
            () => {
                this.hidden = undoHidden;
                this.updated = Date.now();
            },
            () => {
                this.hidden = redoHidden;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );

        this.hidden = hidden;
    }

    public associated(clientID: number) {
        this.clientID = clientID;
        this.parentID = clientID;
    }

    public async delete(frame: number, force: boolean) {
        if ((!this.lock || force) && !this.readonly) {
            this.removed = true;
            this.relation = {};

            this.history.do(
                HistoryActions.REMOVED_OBJECT,
                () => {
                    this.relation = {};
                    this.serverID = undefined;
                    this.removed = false;
                    this.updated = Date.now();
                    this.attributes = {};
                    // Object.values(this.attributes).forEach((attr) => {
                    //     delete attr.id;
                    //     delete attr.shapeId;
                    // });
                    // if (this.shapes) {
                    //     Object.values(this.shapes).forEach((shape) => {
                    //         delete shape.serverID;
                    //         shape.relation = {};
                    //         shape.attributes = {};
                    //         // if (shape.attributes) {
                    //         //     Object.values(shape.attributes).forEach((attr) => {
                    //         //         delete attr.id;
                    //         //         delete attr.shapeId;
                    //         //     });
                    //         // }
                    //     });
                    // }
                },
                () => {
                    this.removed = true;
                    this.updated = Date.now();
                },
                [this.clientID],
                frame,
            );
        }

        return this.removed;
    }
}

export default DrawnAnnotation;
