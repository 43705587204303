/*
 * @Author: swxy
 * @Date: 2023-04-18 16:23:02
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import { getJobsAsync } from "actions/jobs-actions";
import { Spin, List, Result, Button, Typography, Empty } from "antd";
import React, { FC, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { CombinedState } from "reducers/interfaces";
import TaskItemComponent from './taskItem';
// import { SmileOutlined } from '@ant-design/icons';

import './../styles.scss';
import { useTranslation } from "react-i18next";


const { Text } = Typography;

interface Props {
    onClickAdd?: () => void;
}

const TaskList: FC<Props> = ({ onClickAdd }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // const [datas, setDatas] = useState<Record<string, any>[]>([]);

    const {
        jobs,
        loading,
        // userId,
        updating,
    } = useSelector((state: CombinedState) => ({
        jobs: state.jobs.current,
        loading: state.jobs.fetching,
        userId: state.auth.user.id,
        updating: state.tasks.updating,
    }));

    const getJobs = async () => {
        // dispatch(getTasksAsync({}));
        dispatch(getJobsAsync({ pageSize: -1 }));
    }

    // useEffect(() => {
    //     getTasks();
    // }, []);

    useEffect(() => {
        if (!updating) {
            getJobs();
        }
    }, [updating])

    // useEffect(() => {
    //     setDatas(tasks.map((task) => ({
    //         // href: 'https://ant.design',
    //         id: task.instance.id,
    //         title: `${task.instance.name}`,
    //         // avatar: 'https://joeschmoe.io/api/v1/random',
    //         description:
    //             `创建于 ${task.instance.createdDate}，更新于 ${task.instance.updatedDate}`,
    //         content:
    //             'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
    //     })));
    // }, [tasks]);


    return <Spin spinning={loading}>
        <div className='aatp_task_list'>
            {!jobs.length && <Result
                icon={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                // title="没有任务"
                subTitle={<>{t('task.noListData')}<Button type="link" onClick={onClickAdd}>{t('task.add')}</Button>{t('task.one')}</>}
            // extra={<Button type="primary">Next</Button>}
            />}
            {!!jobs.length && <List
                dataSource={jobs}
                renderItem={(item: any, index) => (
                    <div key={item.id}>
                        <TaskItemComponent jobInstance={item} name={`${t('project.myProject')}-${jobs.length - index}`} reload={getJobs} />
                    </div>
                )} />}
        </div>
    </Spin>
}

export default TaskList