/*
 * @Author: swxy
 * @Date: 2023-10-07 15:25:19
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { Group, Vector3 } from 'three';
import Point, { PointType } from '../objects/point';

class Helper {
    // private _object?: Group;
    public object?: Group;
    public isCreating: boolean = false; // 是否创建中
    public isEditing: boolean = false; // 是否编辑中
    // public posistion: Vector3 = new Vector3();
    // public get object() {
    //     return this._object!;
    // }

    // public set object(obj: Group) {
    //     this._object = obj;
    // }

    /**
     * 是否工作中-是否处于创建中、编辑中的状态
     */
    public get isWorking() {
        return this.isCreating || this.isEditing;
    }

    constructor() {}

    public updateMousePosition(pos: Vector3) {
        // 待子类实现
        // this.point.position.set(pos.x, pos.y, pos.z);
        // if (this.isCreating || this.isEditing) {
        //     if (this.object && this.object.parent) {
        //         this.object.parent.add(this.point);
        //     }
        // } else if (this.point.parent) {
        //     this.point.removeFromParent();
        // }
    }

    public draw(position?: Vector3) {
        // 逻辑待子类实现
    }

    public destroy(): void {
        this.object?.removeFromParent();
        this.object = undefined;
    }
}

export default Helper;
