/*
 * @Author: swxy
 * @Date: 2023-08-27 14:27:27
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { ArgumentError, ServerError } from 'errors/exception';
import { JobStatus, NoteStatus, NoteType } from 'utils/ConstType';
import quickhull from 'common/quickHull';
// import AnnotationHubHelper from 'business/annotationHubHelper';
import { api_updateIssue, deleteIssue } from 'service/job/job';
import moment from 'moment';

// const getHelper = () => {
//     // 由于相互引用问题，放入方法中
//     return AnnotationHubHelper.getInstance();
// };
// const helper = getHelper();

class Comment {
    constructor(initialData: any) {
        const data: any = {
            id: undefined,
            message: undefined,
            created_date: undefined,
            updated_date: undefined,
            owner: undefined,
        };

        for (const property in data) {
            if (Object.prototype.hasOwnProperty.call(data, property) && property in initialData) {
                data[property] = initialData[property];
            }
        }

        // if (data.owner && !(data.owner instanceof User)) data.owner = new User(data.owner);
        if (typeof data.created_date === 'undefined') {
            data.created_date = new Date().toISOString();
        }

        Object.defineProperties(
            this,
            Object.freeze({
                id: {
                    get: () => data.id,
                },
                message: {
                    get: () => data.message,
                    set: (value) => {
                        if (!value.trim().length) {
                            throw new ArgumentError('Value must not be empty');
                        }
                        data.message = value;
                    },
                },
                createdDate: {
                    get: () => data.created_date,
                },
                updatedDate: {
                    get: () => data.updated_date,
                },
                owner: {
                    get: () => data.owner,
                },
                __internal: {
                    get: () => data,
                },
            }),
        );
    }

    serialize() {
        const data = {
            // @ts-expect-error
            message: this.message,
        };

        // @ts-expect-error
        if (typeof this.id === 'number') {
            // @ts-expect-error
            data.id = this.id;
        }
        // @ts-expect-error
        if (this.createdDate) {
            // @ts-expect-error
            data.created_date = this.createdDate;
        }
        // @ts-expect-error
        if (this.updatedDate) {
            // @ts-expect-error
            data.updated_date = this.updatedDate;
        }

        return data;
    }
}

const contentSplitSymbol = '\r\n########\r\n';
class Issue {
    public contentSplitSymbol: string = contentSplitSymbol;
    public id: number;
    public points: number[];
    public jobId: number;
    public frame: number;
    public noteUserId: number;
    public noteStatus: NoteStatus;
    public noteType: NoteType;
    public content: string;
    // public comments: string;
    public title: string;
    public direction: string;
    public cameraName: string;
    public addTime: string;
    public updateTime: string;

    public jobStatus: JobStatus;

    public readonly: boolean = false;

    constructor(serviceIssue: any, supplement: number = 0) {
        this.id = serviceIssue.id;
        this.jobId = serviceIssue.jobId;
        this.frame = serviceIssue.frame;
        this.noteUserId = serviceIssue.noteUserId;
        this.noteStatus = serviceIssue.noteStatus;
        this.noteType = serviceIssue.noteType;
        this.content = serviceIssue.content;
        // this.comments = serviceIssue.comments;
        this.title = serviceIssue.title;
        this.direction = serviceIssue.direction;
        this.cameraName = serviceIssue.cameraName;
        this.addTime = serviceIssue.addTime;
        this.updateTime = serviceIssue.updateTime;

        this.jobStatus = serviceIssue.jobStatus || JobStatus.def;

        if (this.addTime && supplement) {
            this.readonly = moment(this.addTime).valueOf() < supplement;
        }

        if (typeof this.addTime === 'undefined') {
            this.addTime = new Date().toISOString();
        }
        if (typeof this.updateTime === 'undefined') {
            this.updateTime = this.addTime;
        }

        this.points =
            serviceIssue.points && Array.isArray(serviceIssue.points)
                ? serviceIssue.points
                : JSON.parse(serviceIssue.points || '[]');

        // if (this.comments) {
        //     data.comments = data.comments.map((comment) => new Comment(comment));
        // }
    }

    public get position() {
        return this.points;
    }

    public set position(value: number[]) {
        if (!Array.isArray(value) || value.some((cood) => typeof cood !== 'number')) {
            throw new ArgumentError(`批注位置预期为数字数组，当前值为:${value}`);
        }
        this.points = [...value];
    }

    public get job() {
        return this.jobId;
    }

    public get comments() {
        try {
            const comments = this.content.split(contentSplitSymbol) || [];
            return comments.map(
                (comment, index) =>
                    new Comment({
                        id: index + 1,
                        message: comment,
                        created_date: this.addTime,
                        updated_date: this.updateTime,
                    }),
            );
        } catch (error) {
            throw new ArgumentError(`批注内容解析错误:${error}`);
        }
    }

    static hull(coordinates: number[], type = '2d') {
        if (type === '3d') {
            return coordinates;
        }

        if (coordinates.length > 4 && coordinates.every((coord) => Number.isInteger(coord))) {
            // return [...coordinates];
            const points = coordinates.reduce((acc: { x: number; y: number }[], coord, index, arr) => {
                if (index % 2) acc.push({ x: arr[index - 1], y: coord });
                return acc;
            }, []);

            return quickhull(points)
                .map((point) => [point.x, point.y])
                .flat();
        }

        return coordinates;
    }

    serialize() {
        // const { comments } = this;
        const data: any = {
            points: this.position,
            frame: this.frame,
            content: this.content,
            jobStatus: this.jobStatus,
            // comments: comments.map((comment) => comment.serialize()),
        };

        if (typeof this.id === 'number') {
            data.id = this.id;
        }

        if (typeof this.jobId === 'number') {
            data.jobId = this.jobId;
        }

        if (typeof this.frame === 'number') {
            data.frame = this.frame;
        }

        if (typeof this.noteStatus === 'number') {
            data.noteStatus = this.noteStatus;
        } else {
            data.noteStatus = 0;
        }

        if (typeof this.noteType === 'number') {
            data.noteType = this.noteType;
        } else {
            data.noteType = 1;
        }

        if (typeof this.noteUserId === 'number') {
            data.noteUserId = this.noteUserId;
        }

        if (typeof this.points === 'string') {
            data.points = this.points;
        } else if (Array.isArray(this.points)) {
            data.points = JSON.stringify(this.points);
        }

        if (typeof this.title === 'string') {
            data.title = this.title;
        }
        if (typeof this.direction === 'string') {
            data.direction = this.direction;
        }
        if (typeof this.cameraName === 'string') {
            data.cameraName = this.cameraName;
        }

        return data;
    }

    public update = async (points: number[]) => {
        if (!Array.isArray(points) || points.some((coord) => typeof coord !== 'number')) {
            throw new ArgumentError(`"points"必须是数字数组，现在是"${points}"`);
        }
        try {
            const newPoints = JSON.stringify(points);
            await api_updateIssue({
                id: this.id,
                points: newPoints,
            });

            this.points = [...points];
        } catch (error) {
            throw new ServerError(`更新位置失败-${(error as Error).message}`);
        }
    };

    public comment = async (data: any) => {
        if (typeof data !== 'object' || data === null) {
            throw new ArgumentError(`The argument "data" must be an object. Got "${data}"`);
        }
        if (typeof data.message !== 'string' || data.message.length < 1) {
            throw new ArgumentError(`Comment message must be a not empty string. Got "${data.message}"`);
        }

        try {
            const message = `${this.content}${this.contentSplitSymbol}${data.message}`;
            await api_updateIssue({
                id: this.id,
                noteStatus: NoteStatus.submit,
                content: message,
            });
            this.content = message;
            this.noteStatus = NoteStatus.submit;
        } catch (error) {
            throw new ServerError(`修改批注状态失败-${(error as Error).message}`);
        }
    };

    public resolve = async (noteStatus: NoteStatus) => {
        if (!(noteStatus >= NoteStatus.def && noteStatus <= NoteStatus.finish)) {
            throw new ArgumentError(`批注状态不在规范中，当前:${noteStatus}`);
        }

        try {
            if (typeof this.id === 'number') {
                await api_updateIssue({
                    id: this.id,
                    noteStatus,
                });
                this.noteStatus = noteStatus;
            }
        } catch (error) {
            throw new ServerError(`修改批注状态失败-${(error as Error).message}`);
        }
    };

    public reopen = async () => {
        try {
            if (typeof this.id === 'number') {
                await api_updateIssue({
                    id: this.id,
                    noteStatus: NoteStatus.def,
                    jobStatus: this.jobStatus,
                });
                // if (response && response.success && response.data === 1) {
                this.noteStatus = NoteStatus.def;
                // }
            }
        } catch (error) {
            throw new ServerError(`修改批注状态失败-${(error as Error).message}`);
        }
    };

    public delete = async () => {
        const { id } = this;
        if (id >= 0) {
            await deleteIssue({ id: id });
        }
    };
}

export default Issue;
