import { Spin } from 'antd';
import React, { FC } from 'react';

const style: React.CSSProperties = {
    // width: '100%',
    // height: '100%',
    position: 'absolute',
    inset: 0,
    paddingTop: '300px'
}

interface Props {
    annotationUpdating: boolean;
}

const LoadingComponent: FC<Props> = ({ annotationUpdating }) => {

    return <>
        <Spin style={style} tip={"保存中，请稍后..."} spinning={annotationUpdating} />
    </>
}

export default LoadingComponent;