/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';
import { registerAsync } from 'actions/auth-actions';
import RegisterPageComponent from 'components/register-page/register-page';
import { RegisterData, UserConfirmation } from 'components/register-page/register-form';
import { CombinedState, UserAgreement } from 'reducers/interfaces';

interface StateToProps {
    fetching: boolean;
    userAgreements: UserAgreement[];
}

interface DispatchToProps {
    onRegister: (registerData: RegisterData) => Promise<any>;
}

function mapStateToProps(state: CombinedState): StateToProps {
    return {
        fetching: state.auth.fetching || state.userAgreements.fetching,
        userAgreements: state.userAgreements.list,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        onRegister: async (...args): Promise<any> => dispatch(registerAsync(...args)),
    };
}

function RegisterPageContainer(props: StateToProps & DispatchToProps): JSX.Element {
    return <RegisterPageComponent {...props} />;
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPageContainer);
