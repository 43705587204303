/*
 * @Author: swxy
 * @Date: 2023-07-17 10:40:05
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { Vector3, Line3, Vector2 } from 'three';
import { SubElement, SubElementType } from './interface';

// const getRecentPoint = (lines: Line3, point: Vector3) => {};

export const getPointsInLine = (targetPoints: number[], linePoints: number[]): number[] => {
    if (!(linePoints.length && targetPoints.length && targetPoints.length % 2 === 0 && linePoints.length % 2 === 0)) {
        return [];
    }
    let points: Vector2[] = targetPoints.reduce((previous: Vector2[], current: number, index: number) => {
        const subscipt = Math.floor(index / 2);
        if (index % 2 === 0) {
            previous[subscipt] = new Vector2(current, 0);
        } else {
            previous[subscipt].setY(current);
        }
        return previous;
    }, []);

    const lineSize = 2;
    const maxPoint = new Vector2(linePoints[0], linePoints[1]);
    const minPoint = new Vector2(linePoints[linePoints.length - 2], linePoints[linePoints.length - 1]);
    for (let index = 0; index < linePoints.length - lineSize; index += lineSize) {
        const point1 = new Vector2(linePoints[index], linePoints[index + 1]);
        const point2 = new Vector2(linePoints[index + 2], linePoints[index + 3]);

        const k = (point1.y - point2.y) / (point1.x - point2.x);
        const b = point1.y - point1.x * k;

        const minY = Math.min(point1.y, point2.y);
        const maxY = Math.max(point1.y, point2.y);

        points.forEach((point) => {
            if (point.y < minPoint.y) {
                point.copy(minPoint);
            } else if (point.y > maxPoint.y) {
                point.copy(maxPoint);
            } else if (point.y >= minY && point.y < maxY) {
                //y=xk+b ===>  x = (y - b)/k
                point.setX((point.y - b) / k);
            }
        });
    }

    // console.log('最小点距离：', pointMinInLine);

    // return pointMinInLine.flatMap((point) => [point.x, point.y]);
    return points.flatMap((point) => [point.x, point.y]);

    // return points.map((point: Vector3) => getRecentPoint(lines, point));

    // const lin

    // const lines = linePoints.reduce((previous: Line3[], current: number, index: number) => {
    //     if (index % 2 === 0) {
    //         point = {
    //             x: current,
    //             y: 0,
    //         };
    //     } else {
    //         point.y = current;
    //         previous.push(new Line3());
    //     }
    //     return previous;
    // }, []);
};

/**
 * 子元素的范围
 * @param elements  子元素列表
 * @param rangePoints 范围点
 * @returns
 */
export const subElementRange = (elements: SubElement[], rangePoints: number[] = []) => {
    if (elements?.length && rangePoints.length) {
        elements.forEach((element) => {
            if (element.shapeType === SubElementType.wheelPoint) {
                const [minX, minY, maxX, maxY] = rangePoints;
                // 车轮点限制在范围内
                element.points.forEach((point) => {
                    point.x = Math.min(maxX, Math.max(minX, point.x));
                    point.y = Math.min(maxY, Math.max(minY, point.y));
                });
            }
        });
    }
};

export const subPointsRange = (type: SubElementType, subPoints: number[], rangePoints: number[] = []) => {
    if (rangePoints.length && type === SubElementType.wheelPoint) {
        const [minX, minY, maxX, maxY] = rangePoints;
        subPoints.forEach((num, index) => {
            subPoints[index] =
                index % 2 === 0 ? Math.min(maxX, Math.max(minX, num)) : Math.min(maxY, Math.max(minY, num));
        });
    }
};
