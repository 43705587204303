/*
 * @Author: swxy
 * @Date: 2023-08-26 15:15:47
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
/*
 * @Author: swxy
 * @Date: 2023-08-26 15:15:47
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { checkObjectType } from 'common/common';
import Collection from './objects/annotationCollection';
import Job from './objects/job';
import { ArgumentError, DataError, ServerError } from 'errors/exception';
import FrameData from './frame';
import AnnotationHistory from './objects/annotationsHistory';
import AnnotationsSaver from './objects/annotationSaver';
import {
    api_getShapes,
    // api_getShapesTotal,
    api_getTags,
    // api_getTagsTotal,
    api_getTrackFull,
    // api_getTrackFullTotal,
    api_getTrackKeyFrames,
    api_multUpdateAttrByShapeIndex,
    // getTrackShape, getTracks
} from 'service/job/job';
import ObjectState from './objects/objectState';
import { ServerTagData } from './objects/tag';
import { ServerShapeData } from './objects/shape';
import { ServerTrackData } from './objects/track';
import { ProjectType } from 'utils/ConstType';

// import getHelper from 'business/objects/customs/helper';

const cache = new WeakMap();

export function transSubAnnotations(tags: any[] = [], shapes: any[] = [], tracks: any[] = []) {
    const raw2DAnnotations: Record<
        string,
        {
            tags: ServerTagData[];
            shapes: ServerShapeData[];
            tracks: ServerTrackData[];
        }
    > = {
        // tags: [],
        // shapes: [],
        // tracks: [],
    };
    const raw3DAnnotations: {
        tags: ServerTagData[];
        shapes: ServerShapeData[];
        tracks: ServerTrackData[];
    } = {
        tags: [],
        shapes: [],
        tracks: [],
    };

    const unknown: any[] = [];

    for (const tag of tags) {
        if (tag.direction) {
            raw2DAnnotations[tag.direction] = raw2DAnnotations[tag.direction] || {
                tags: [],
                shapes: [],
                tracks: [],
            };
            raw2DAnnotations[tag.direction].tags.push(tag);
        } else if (!tag.direction) {
            raw3DAnnotations.tags.push(tag);
        } else {
            unknown.push(tag);
        }
    }

    for (const shape of shapes) {
        if (shape.direction) {
            raw2DAnnotations[shape.direction] = raw2DAnnotations[shape.direction] || {
                tags: [],
                shapes: [],
                tracks: [],
            };
            raw2DAnnotations[shape.direction].shapes.push(shape);
        } else if (!shape.direction) {
            raw3DAnnotations.shapes.push(shape);
        } else {
            unknown.push(shape);
        }
    }

    for (const track of tracks) {
        if (track.direction) {
            raw2DAnnotations[track.direction] = raw2DAnnotations[track.direction] || {
                tags: [],
                shapes: [],
                tracks: [],
            };
            raw2DAnnotations[track.direction].tracks.push(track);
        } else if (!track.direction) {
            raw3DAnnotations.tracks.push(track);
        } else {
            unknown.push(track);
        }
    }

    if (unknown.length) {
        console.error('2D3D联合标注，存在错误数据：', unknown);
    }

    return {
        raw2DAnnotations,
        raw3DAnnotations,
    };
}

// export

export async function getTagsFromServer(session: Job, frame?: number[]) {
    try {
        const tags: any[] = await api_getTags({ jobId: session.id, frame });
        return tags.map((tag: any) => {
            tag.attributes = tag.engineLabeledimageattributevals || [];
            delete tag.engineLabeledimageattributevals;
            tag.attributes = (tag.attributes as any[]).reduce((previous: Record<number, string>, current: any) => {
                previous[current.specId] = current.value;
                return previous;
            }, {});
            return tag;
        });
    } catch (error) {
        throw new ServerError(`获取图像标注信息出错-${error as string}`);
    }

    // const tagsFull: any[] = [];

    // // const total = await api_getTagsTotal({ jobId, frame });
    // // const size = 20; // 每次查询10条

    // const getTags = async (pageframe: number) => {
    //     const tags: any[] = await api_getTags({ jobId: session.id, frame: pageframe });
    //     return tags.map((tag: any) => {
    //         tag.attributes = tag.engineLabeledimageattributevals || [];
    //         delete tag.engineLabeledimageattributevals;
    //         tag.attributes = (tag.attributes as any[]).reduce((previous: Record<number, string>, current: any) => {
    //             previous[current.specId] = current.value;
    //             return previous;
    //         }, {});
    //         return tag;
    //     });
    // };

    // try {
    //     const promises: Promise<any[]>[] = [];

    //     for (let index = session.startFrame; index <= session.stopFrame; index++) {
    //         // const element = array[index];
    //         promises.push(getTags(index));
    //     }

    //     const result = await Promise.all(promises);
    //     tagsFull.push(...result.flatMap((item) => item));
    // } catch (error) {
    //     throw new ServerError(`获取图像标注信息出错-${error as string}`);
    // }
    // // console.log('结果：', tagsFull);
    // return tagsFull;
}

export async function getShapesFromServer(session: Job, frame?: number[]) {
    try {
        const shapes: any[] = await api_getShapes({ jobId: session.id, frame });
        return shapes.map((shape: any) => {
            shape.attributes = shape.engineLabeledshapeattributevals || [];
            delete shape.engineLabeledshapeattributevals;
            shape.attributes = (shape.attributes as any[]).reduce((previous: Record<number, string>, current: any) => {
                previous[current.specId] = current.value;
                return previous;
            }, {});
            shape.points = Array.isArray(shape.points) ? shape.points : JSON.parse(shape.points);
            shape.elements = Array.isArray(shape.subPoints) ? shape.subPoints : JSON.parse(shape.subPoints || '[]');
            shape.zOrder = shape.zorder;
            shape.parentId = shape.shapeId;
            shape.pointsControl = shape.pointsControl ? JSON.parse(shape.pointsControl) : shape.pointsControl;

            delete shape.subPoints;
            delete shape.zorder;

            if (shape.occludedParts) {
                shape.occludedParts = Array.isArray(shape.occludedParts)
                    ? shape.occludedParts
                    : JSON.parse(shape.occludedParts || '[]');
            }

            shape.pointsLine = shape.pointsLine ? +shape.pointsLine : 0;

            return shape;
        });
    } catch (error) {
        throw new ServerError(`获取标注框信息出错-${error as string}`);
    }
    // const shapesFull: any[] = [];

    // const getShapes = async (pageframe: number) => {
    //     const shapes: any[] = await api_getShapes({ jobId: session.id, frame: pageframe });
    //     return shapes.map((shape: any) => {
    //         shape.attributes = shape.engineLabeledshapeattributevals || [];
    //         delete shape.engineLabeledshapeattributevals;
    //         shape.attributes = (shape.attributes as any[]).reduce((previous: Record<number, string>, current: any) => {
    //             previous[current.specId] = current.value;
    //             return previous;
    //         }, {});
    //         shape.points = Array.isArray(shape.points) ? shape.points : JSON.parse(shape.points);
    //         shape.elements = Array.isArray(shape.subPoints) ? shape.subPoints : JSON.parse(shape.subPoints || '[]');
    //         shape.zOrder = shape.zorder;
    //         shape.parentId = shape.shapeId;

    //         delete shape.subPoints;
    //         delete shape.zorder;

    //         if (shape.occludedParts) {
    //             shape.occludedParts = Array.isArray(shape.occludedParts)
    //                 ? shape.occludedParts
    //                 : JSON.parse(shape.occludedParts || '[]');
    //         }

    //         shape.pointsLine = shape.pointsLine ? +shape.pointsLine : 0;

    //         return shape;
    //     });
    // };

    // try {
    //     const promises: Promise<any[]>[] = [];

    //     for (let index = session.startFrame; index <= session.stopFrame; index++) {
    //         promises.push(getShapes(index));
    //     }

    //     const result = await Promise.all(promises);
    //     shapesFull.push(...result.flatMap((item) => item));
    // } catch (error) {
    //     throw new ServerError(`获取标注框信息出错-${error as string}`);
    // }
    // return shapesFull;
}

async function getTracksFromServer(session: Job, frame?: number[]) {
    // if (frame) {
    //     const tracks: any[] = await getTracks({ jobId });

    //     return tracks.map((track: any) => {
    //         track.attributes = track.engineLabeledtrackattributevals || [];
    //         delete track.engineLabeledshapeattributevals;
    //         track.attributes = (track.attributes as any[]).reduce((previous: Record<number, string>, current: any) => {
    //             previous[current.specId] = current.value;
    //             return previous;
    //         }, {});
    //         track.shapes = [];
    //         return track;
    //     });
    // }

    try {
        const tracks: any[] = await api_getTrackFull({ jobId: session.id, frame });

        return tracks.map((track: any) => {
            track.attributes = track.engineLabeledtrackattributevals || [];
            delete track.engineLabeledtrackattributevals;
            track.attributes = (track.attributes as any[]).reduce((previous: Record<number, string>, current: any) => {
                previous[current.specId] = current.value;
                return previous;
            }, {});

            track.shapes = track.engineTrackedshapes || [];
            track.parentId = track.trackId;
            delete track.engineTrackedshapes;
            // delete track.trackId;

            track.shapes = track.shapes.map((shape: any) => {
                shape.attributes = shape.engineTrackedshapeattributevals || [];
                delete shape.engineTrackedshapeattributevals;
                shape.attributes = (shape.attributes as any[]).reduce(
                    (previous: Record<number, string>, current: any) => {
                        previous[current.specId] = current.value;
                        return previous;
                    },
                    {},
                );

                shape.points = Array.isArray(shape.points) ? shape.points : JSON.parse(shape.points);
                shape.elements = Array.isArray(shape.subPoints) ? shape.subPoints : JSON.parse(shape.subPoints || '[]');
                shape.zOrder = shape.zorder;
                shape.pointsControl = shape.pointsControl ? JSON.parse(shape.pointsControl) : shape.pointsControl;

                delete shape.subPoints;
                delete shape.zorder;

                if (shape.occludedParts) {
                    shape.occludedParts = Array.isArray(shape.occludedParts)
                        ? shape.occludedParts
                        : JSON.parse(shape.occludedParts || '[]');
                }

                shape.pointsLine = shape.pointsLine ? +shape.pointsLine : 0;

                return shape;
            });

            return track;
        });
    } catch (error) {
        throw new ServerError(`获取连续帧标注信息出错-${error as string}`);
    }
    // const tracksFull: any[] = [];

    // // const total = await api_getTrackFullTotal({ jobId, frame });
    // // const size = 40; // 每次查询50条, 有bug，后台查出的数量是

    // const getTracks = async (pageframe: number) => {
    //     const tracks: any[] = await api_getTrackFull({ jobId: session.id, frame: pageframe });

    //     return tracks.map((track: any) => {
    //         track.attributes = track.engineLabeledshapeattributevals || [];
    //         delete track.engineLabeledshapeattributevals;
    //         track.attributes = (track.attributes as any[]).reduce((previous: Record<number, string>, current: any) => {
    //             previous[current.specId] = current.value;
    //             return previous;
    //         }, {});

    //         track.shapes = track.engineTrackedshapes || [];
    //         track.parentId = track.trackId;
    //         delete track.engineTrackedshapes;
    //         // delete track.trackId;

    //         track.shapes = track.shapes.map((shape: any) => {
    //             shape.attributes = shape.engineTrackedshapeattributevals || [];
    //             delete shape.engineTrackedshapeattributevals;
    //             shape.attributes = (shape.attributes as any[]).reduce(
    //                 (previous: Record<number, string>, current: any) => {
    //                     previous[current.specId] = current.value;
    //                     return previous;
    //                 },
    //                 {},
    //             );

    //             shape.points = Array.isArray(shape.points) ? shape.points : JSON.parse(shape.points);
    //             shape.elements = Array.isArray(shape.subPoints) ? shape.subPoints : JSON.parse(shape.subPoints || '[]');
    //             shape.zOrder = shape.zorder;

    //             delete shape.subPoints;
    //             delete shape.zorder;

    //             if (shape.occludedParts) {
    //                 shape.occludedParts = Array.isArray(shape.occludedParts)
    //                     ? shape.occludedParts
    //                     : JSON.parse(shape.occludedParts || '[]');
    //             }

    //             shape.pointsLine = shape.pointsLine ? +shape.pointsLine : 0;

    //             return shape;
    //         });

    //         return track;
    //     });
    // };
    // try {
    //     const promises: Promise<any[]>[] = [];

    //     for (let index = session.startFrame; index <= session.stopFrame; index++) {
    //         // const element = array[index];
    //         promises.push(getTracks(index));
    //     }

    //     const result = await Promise.all(promises);
    //     tracksFull.push(...result.flatMap((item) => item));
    // } catch (error) {
    //     throw new ServerError(`获取连续帧标注信息出错-${error as string}`);
    // }
    // return tracksFull;
}

// async function getTrackShapesFromServer(jobId: number, frame?: number) {
//     if (frame) {
//         const trackShapes: any[] = await getTrackShape({ jobId, frame });

//         return trackShapes.map((trackShape: any) => {
//             trackShape.attributes = trackShape.engineTrackedshapeattributevals || [];
//             delete trackShape.engineTrackedshapeattributevals;
//             trackShape.attributes = (trackShape.attributes as any[]).reduce(
//                 (previous: Record<number, string>, current: any) => {
//                     previous[current.specId] = current.value;
//                     return previous;
//                 },
//                 {},
//             );
//             trackShape.points = Array.isArray(trackShape.points) ? trackShape.points : JSON.parse(trackShape.points);
//             trackShape.elements = Array.isArray(trackShape.subPoints)
//                 ? trackShape.subPoints
//                 : JSON.parse(trackShape.subPoints || '[]');
//             delete trackShape.subPoints;

//             if (trackShape.occludedParts) {
//                 trackShape.occludedParts = Array.isArray(trackShape.occludedParts)
//                     ? trackShape.occludedParts
//                     : JSON.parse(trackShape.occludedParts || '[]');
//             }

//             trackShape.pointsLine = trackShape.pointsLine ? +trackShape.pointsLine : 0;

//             return trackShape;
//         });
//     }

//     return [];
// }

// async function initCache(session: Job) {
//     if (!cache.has(session)) {
//         const helper = getHelper(session);
//         if (helper) {
//             await helper?.init(session);
//         }
//         const startFrame = session.startFrame;
//         const stopFrame = session.stopFrame;
//         // 获取帧信息
//         const frameMeta: Record<number, FrameData> = {};
//         for (let i = startFrame; i <= stopFrame; i++) {
//             frameMeta[i] = await session.frames.get(i);
//         }

//         const history = new AnnotationHistory();
//         const collection = new Collection({
//             id: session.id,
//             history,
//             job: session,
//             frameMeta,
//         });

//         const saver = new AnnotationsSaver(collection, session);

//         cache.set(session, {
//             collection,
//             saver,
//             history,
//         });
//     }
// }

/**
 * 按照固定帧数，去按步去下载数据
 * @param session 题包
 * @param multNum 同时查询的接口数量
 * @returns
 */
export async function getAnnotationsFromServerByFrame(session: Job, multNum: number = 5) {
    const tagsFull: any[] = [];
    const shapesFull: any[] = [];
    const tracksFull: any[] = [];

    let frame = session.startFrame;
    const getAnnotationFromServerByMult = async () => {
        const tagsPrmoses: Promise<any>[] = [];
        const shapesPrmoses: Promise<any>[] = [];
        const tracksPrmoses: Promise<any>[] = [];
        const start = frame;
        const stop = Math.min(session.stopFrame, frame + multNum);

        tagsPrmoses.push(getTagsFromServer(session, [start, stop]));
        shapesPrmoses.push(getShapesFromServer(session, [start, stop]));
        tracksPrmoses.push(getTracksFromServer(session, [start, stop]));
        // for (let index = 0; index <= multNum; index++) {}
        tagsFull.push(...(await Promise.all(tagsPrmoses)));

        // for (let index = 0; index <= multNum; index++) {}
        shapesFull.push(...(await Promise.all(shapesPrmoses)));

        // for (let index = 0; index <= multNum; index++) {}
        tracksFull.push(...(await Promise.all(tracksPrmoses)));
        frame = stop + 1;
    };

    while (frame <= session.stopFrame) {
        await getAnnotationFromServerByMult();
    }

    return [tagsFull, shapesFull, tracksFull];
}

export async function getAnnotationsFromServer(session: Job, frame?: number) {
    if (!cache.has(session)) {
        const startFrame = session.startFrame;
        const stopFrame = session.stopFrame;

        let tagsResults = [];
        let shapesResults = [];
        let tracksResults = [];
        if (typeof frame === 'number') {
            tagsResults = await getTagsFromServer(session, [frame, frame]);
            shapesResults = await getShapesFromServer(session, [frame, frame]);
            tracksResults = await getTracksFromServer(session, [frame, frame]);
        } else {
            [tagsResults, shapesResults, tracksResults] = await getAnnotationsFromServerByFrame(session);
        }
        const tags = tagsResults.flatMap((item) => item);
        const shapes = shapesResults.flatMap((item) => item);
        const tracks = tracksResults.flatMap((item) => item);

        // console.log('tags:', tags);
        // console.log('shapes:', shapes);
        // console.log('tracks:', tracks);
        // console.log('trackShapes:', trackShapes);

        // 获取帧信息
        const frameMeta: Record<number, FrameData> = {};
        for (let i = startFrame; i <= stopFrame; i++) {
            frameMeta[i] = await session.frames.get(i);
        }

        const history = new AnnotationHistory();
        const collection = new Collection({
            id: session.id,
            history,
            job: session,
            frameMeta,
        });

        // 混合项目导入数据
        if (session.projectType === ProjectType.mix) {
            const { raw2DAnnotations, raw3DAnnotations } = transSubAnnotations(tags, shapes, tracks);
            collection.import({
                ...raw3DAnnotations,
                // trackShapes: trackShapes,
            });
            collection.importSub({
                ...raw2DAnnotations,
            });
        } else {
            // eslint-disable-next-line no-unsanitized/method
            collection.import({
                tags,
                shapes,
                tracks,
                // trackShapes,
            });
        }

        const saver = new AnnotationsSaver(collection, session);

        cache.set(session, {
            collection,
            saver,
            history,
        });
    }
}

export async function closeSession(session: Job) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        cache.delete(session);
    }
    // const helper = getHelper(session);
    // if (helper) {
    //     helper.close();
    // }
}

export async function getAnnotations(
    session: Job,
    frame: number,
    allTracks: boolean,
    filters: any,
    cameraName?: string,
) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);
    // const helper = getHelper(session);

    // if (helper) {
    //     await initCache(session);

    //     return helper.getAnnotations(cache.get(session), frame, allTracks, filters, cameraName);
    // }

    if (cache.has(session)) {
        return cache.get(session).collection.get(frame, allTracks, filters, cameraName);
    }

    await getAnnotationsFromServer(session);
    return cache.get(session).collection.get(frame, allTracks, filters, cameraName);
}

export async function getAnnotationTags(session: Job, frame: number, filters: any) {
    // const helper = getHelper(session);

    // if (helper) {
    //     await initCache(session);

    //     return helper.getAnnotationTags(cache.get(session), frame, filters);
    // }

    if (cache.has(session)) {
        return cache.get(session).collection.getTags(frame, filters);
    }

    await getAnnotationsFromServer(session);
    return cache.get(session).collection.getTags(frame, filters);
}

export async function saveAnnotations(session: Job, onUpdate: any) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        await cache.get(session).saver.save(onUpdate);
    }

    // If a collection wasn't uploaded, than it wasn't changed, finally we shouldn't save it
}

export async function getSubAnnotations(session: Job, frame: number, allTracks: boolean, filters: any) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.getSub(frame, allTracks, filters);
    }

    await getAnnotationsFromServer(session);
    return cache.get(session).collection.getSub(frame, allTracks, filters);
}

// export async function saveSubAnnotations(session, onUpdate) {
//     const sessionType = session instanceof Task ? 'task' : 'job';
//     const cache = getCache(sessionType);

//     if (cache.has(session)) {
//         await cache.get(session).saver.saveSub(onUpdate);
//     }

//     // If a collection wasn't uploaded, than it wasn't changed, finally we shouldn't save it
// }

// export function searchAnnotations(session, filters, frameFrom, frameTo) {
//     // const sessionType = session instanceof Task ? 'task' : 'job';
//     // const cache = getCache(sessionType);

//     if (cache.has(session)) {
//         return cache.get(session).collection.search(filters, frameFrom, frameTo);
//     }

//     throw new DataErr(
//         'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
//     );
// }

// export function searchEmptyFrame(session, frameFrom, frameTo) {
//     const sessionType = session instanceof Task ? 'task' : 'job';
//     const cache = getCache(sessionType);

//     if (cache.has(session)) {
//         return cache.get(session).collection.searchEmpty(frameFrom, frameTo);
//     }

//     throw new DataError(
//         'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
//     );
// }

// export function mergeAnnotations(session, objectStates) {
//     const sessionType = session instanceof Task ? 'task' : 'job';
//     const cache = getCache(sessionType);

//     if (cache.has(session)) {
//         return cache.get(session).collection.merge(objectStates);
//     }

//     throw new DataError(
//         'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
//     );
// }

// export function splitAnnotations(session, objectState, frame) {
//     const sessionType = session instanceof Task ? 'task' : 'job';
//     const cache = getCache(sessionType);

//     if (cache.has(session)) {
//         return cache.get(session).collection.split(objectState, frame);
//     }

//     throw new DataError(
//         'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
//     );
// }

// export function groupAnnotations(session, objectStates, reset) {
//     const sessionType = session instanceof Task ? 'task' : 'job';
//     const cache = getCache(sessionType);

//     if (cache.has(session)) {
//         return cache.get(session).collection.group(objectStates, reset);
//     }

//     throw new DataError(
//         'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
//     );
// }

export function hasUnsavedChanges(session: Job) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    // console.log('测试：时间');
    if (cache.has(session)) {
        return cache.get(session).saver.hasUnsavedChanges();
    }

    return false;
}

export async function clearAnnotations(
    session: Job,
    reload: boolean,
    startframe: number,
    endframe: number,
    delTrackKeyframesOnly: number,
) {
    checkObjectType('reload', reload, 'boolean', null);
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        cache.get(session).collection.clear(startframe, endframe, delTrackKeyframesOnly);
    }

    if (reload) {
        cache.delete(session);
        await getAnnotationsFromServer(session);
    }
}

export function annotationsStatistics(session: Job) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.statistics();
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function putAnnotations(session: Job, objectStates: ObjectState[]) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.put(objectStates, session.id);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function projectionSubAnnotations(
    session: Job,
    objectStates: ObjectState[],
    directions: string[],
    get2DPoints: any,
) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.projection(objectStates, directions, session.id, get2DPoints);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function putSubAnnotations(session: Job, objectStates: ObjectState[]) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.putSub(objectStates, session.id);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function selectObject(session: Job, objectStates: ObjectState[], x: number, y: number) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.select(objectStates, x, y);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

// export async function uploadAnnotations(session: Job, file, loader) {
//     const sessionType = session instanceof Task ? 'task' : 'job';
//     if (!(loader instanceof Loader)) {
//         throw new ArgumentError('A loader must be instance of Loader class');
//     }
//     await serverProxy.annotations.uploadAnnotations(sessionType, session.id, file, loader.name);
// }

export function importAnnotations(session: Job, data: any) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        // eslint-disable-next-line no-unsanitized/method
        return cache.get(session).collection.import(data);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function exportAnnotations(session: Job) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).collection.export();
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

// export async function exportDataset(instance, format, name, saveImages = false) {
//     if (!(format instanceof String || typeof format === 'string')) {
//         throw new ArgumentError('Format must be a string');
//     }
//     if (!(instance instanceof Task || instance instanceof Project || instance instanceof Job)) {
//         throw new ArgumentError('A dataset can only be created from a job, task or project');
//     }
//     if (typeof saveImages !== 'boolean') {
//         throw new ArgumentError('Save images parameter must be a boolean');
//     }

//     let result = null;
//     if (instance instanceof Task) {
//         result = await serverProxy.tasks.exportDataset(instance.id, format, name, saveImages);
//     } else if (instance instanceof Job) {
//         result = await serverProxy.tasks.exportDataset(instance.taskId, format, name, saveImages);
//     } else {
//         result = await serverProxy.projects.exportDataset(instance.id, format, name, saveImages);
//     }

//     return result;
// }

// export function importDataset(instance, format, file, updateStatusCallback = () => {}) {
//     if (!(typeof format === 'string')) {
//         throw new ArgumentError('Format must be a string');
//     }
//     if (!(instance instanceof Project)) {
//         throw new ArgumentError('Instance should be a Project instance');
//     }
//     if (!(typeof updateStatusCallback === 'function')) {
//         throw new ArgumentError('Callback should be a function');
//     }
//     if (!['application/zip', 'application/x-zip-compressed'].includes(file.type)) {
//         throw new ArgumentError('File should be file instance with ZIP extension');
//     }
//     return serverProxy.projects.importDataset(instance.id, format, file, updateStatusCallback);
// }

export function undoActions(session: Job, count?: number) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.undo(count);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function redoActions(session: Job, count?: number) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.redo(count);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function freezeHistory(session: Job, frozen: boolean) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.freeze(frozen);
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function clearActions(session: Job) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.clear();
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function getActions(session: Job) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.get();
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function exportTaskAnnotations(session: Job) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        return cache.get(session).history.get();
    }

    throw new DataError(
        'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    );
}

export function changeCamera(session: Job, cameraName: string) {
    // const sessionType = session instanceof Task ? 'task' : 'job';
    // const cache = getCache(sessionType);

    if (cache.has(session)) {
        const { collection, history } = cache.get(session);
        collection.changeCamera(cameraName);
        const saver = new AnnotationsSaver(collection, session);

        cache.set(session, {
            collection,
            saver,
            history,
        });
    }

    // throw new DataError(
    //     'Collection has not been initialized yet. Call annotations.get() or annotations.clear(true) before',
    // );
}

export function associatedAnnotations(session: Job, objectStates: ObjectState[], clientID: number) {
    if (cache.has(session)) {
        const { collection } = cache.get(session);
        collection.associated(objectStates, clientID);
        // const saver = new AnnotationsSaver(collection, session);
        // cache.set(session, {
        //     collection,
        //     saver,
        //     history,
        // });
    }
}

export async function saveMultAttribute(
    session: Job,
    data: Record<number, Record<number, string>>,
    frames: { startFrame: number; stopFrame: number },
) {
    if (cache.has(session)) {
        // const promises = Object.entries(data).map(([key, idVals]: [string, Record<number, string>]) => {
        //     return api_multUpdateAttrByShapeIndex({
        //         jobId: session.id,
        //         laneIndex: +key,
        //         startFrame: frames.startFrame,
        //         endFrame: frames.stopFrame,
        //         idVals,
        //         // mergeShapes: {
        //         //     [key]: {
        //         //     },
        //         // },
        //     });
        // });
        // const successes = await Promise.all(promises);
        // if (successes.every((success) => success)) {
        //     // 保存成功
        //     const { collection } = cache.get(session);
        //     collection.saveMultAttr(data, frames);
        // }
        const { collection } = cache.get(session);
        collection.saveMultAttr(data, frames);

        return;
        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         reject('错误');
        //     }, 5000);
        // });
    }
    throw new DataError('在数据集还没有创建的情况下，使用了数据集调用：saveMultAttribute！');
}
