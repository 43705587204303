/*
 * @Author: swxy
 * @Date: 2024-01-25 10:48:54
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { AnnotationSettingState, PointCloudColorBy } from 'reducers/interfaces';

// 一般审核员设置
export const defaultReviewSetting: AnnotationSettingState = {
    camera: {
        isFollow: false,
        isForce: false,
    },
    showThreeViewShortcuts: true,
    canvas3D: {
        pointCloudColor: PointCloudColorBy.normal,
    },
};

export const defaultAnnotationSetting: AnnotationSettingState = {
    camera: {
        isForce: false,
        isFollow: false,
    },
    showThreeViewShortcuts: true,
    canvas3D: {
        pointCloudColor: PointCloudColorBy.normal,
    },
};

// 一般审核员设置
export const defaultSetting: AnnotationSettingState = defaultReviewSetting;
