/*
 * @Author: swxy
 * @Date: 2022-08-15 14:32:34
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

export default {
     'project.projcet': 'Projekt',
     'project.no_task': 'Keine Aufgabe',
     'project.back_project_list': 'Zurück-Projektliste',
     'project.task': 'Aufgabe',
     'project.last_update_time': 'Letzte Aktualisierungszeit',
     'project.create_by': 'Erstellt am',
     'project.pending': 'zu erledigen',
     'project.in_progress': 'In Bearbeitung',
     'project.completed': 'Abgeschlossen',
     'project.open': 'Öffnen',
     'project.back_project': 'Zurück zum Projekt',
     'project.back_task': 'Aufgabe zurückgeben',

     'project.job': 'Fragenpaket',
     'project.jobs': 'Liste der Fragenpakete',
     'project.frames': 'Rahmen',
     'project.job_status': 'Status des Fragepakets',
     'project.start_date': 'Startzeit',
     'project.duration': 'Dauer',
     'project.copy': 'Kopieren',
     'project.copy_success': 'In die Zwischenablage kopiert! ',

     'project.overlap_size': 'Überlappungsgröße',
     'project.segment_size': 'Gesamtmenge',
     'project.image_quality': 'Bildqualität',

     'task.this': 'hier',

     'task.lastFrame': 'Der letzte Frame, den Sie angesehen haben, ist {{parsedFrame}}',
     'task.lastFrameHelp_pre': 'Sie können klicken',
     'task.lastFrameHelp_last': 'Zum {{parsedFrame}} Frame springen, um fortzufahren',
     'task.noLabelHelp': 'Keine Etiketteninformationen',
     'task.noLabel': 'Bitte fragen Sie den Projektadministrator, ob dem Projekt ein Label hinzugefügt wurde. ',

     'task.nofoundJob': 'Leider wurde dieses Aufgabenpaket nicht gefunden',
     'task.pleaseClick': 'Bitte klicken',
     'task.backHome': 'Nach Hause zurückkehren',

     'task.uploadImages': 'Bilder hochladen',
     'task.noListData': 'Derzeit sind keine Daten vorhanden, Sie können',
     'task.add': 'Hinzufügen',
     'task.one': 'eins',
     'task.updateTime': 'Aktualisierungszeit',

     'task.selectAutoTypeHelp': 'Bitte wählen Sie mindestens einen Automatisierungstyp aus!',
     'task.seletImageHelp': 'Bitte wählen Sie zuerst ein Bild aus!',
     'task.uploading': 'Wird hochgeladen, bitte haben Sie etwas Geduld...',
     'task.autoType': 'Automatisierungstyp:',
     'task.uploadTip': 'Bild ziehen oder hier klicken',
     'task.uploadSupTypeHelp': 'Die Testversion unterstützt derzeit nur jpg, jpeg, png, bmp',


     'job.desInfo': 'Bildauflösung: {{width}} X {{height}}, Gesamtzahl der Frames: {{total}}',
     'job.backPrevPage': 'Zurück zur vorherigen Ebene',
     'job.backHome': 'Nach Hause zurückkehren',
     'job.updateTime': 'Aktualisierungszeit',

     'project.myProject': 'Mein Projekt',

     //#region 2023-05-04 待校验
     'task.checkResolutionTip': 'Bildauflösung wird überprüft...',
     'task.uploadImagesTip': 'Hochladen, bitte haben Sie Geduld und warten Sie...',
     'task.autoAnnotationTip': 'Automatisierte Annotationsausführung im Gange, bitte haben Sie Geduld...',
     'task.sizeOver.title': 'Die Anzahl der Bilder überschreitet die Grenze',
     'task.sizeOver.content': `Hallo, die Experience Version unterstützt nur Datenberechnungen bis zu 10 Tonnen. Wenn Sie Datenverarbeitungsbedarf haben, kontaktieren Sie uns bitte.

Email: sales@idatalab.cc`,
     'task.countOver.title': 'Anzahl der Überschreitungen',
     'task.countOver.content': `Hallo, die Anzahl der Erlebnisse an diesem Tag hat die Grenze überschritten. Wenn Sie zusätzliche Anforderungen haben, kontaktieren Sie uns bitte.

Email: sales@idatalab.cc`,
     'job.deleteSuccess': 'Sie haben {{name}} gelöscht und die Projektnamen werden neu angeordnet!',
     //#endregion
};
