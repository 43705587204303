/*
 * @Author: swxy
 * @Date: 2023-04-18 16:23:02
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */

import { List, Avatar, Button, Popconfirm, message } from "antd";
import React, { FC, forwardRef, useState } from "react"
import { ScheduleOutlined } from '@ant-design/icons';
import './../styles.scss';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteTaskAsync } from "actions/tasks-actions";
import { useTranslation } from "react-i18next";
import { deleteTaskByIdAsync } from "actions/jobs-actions";


interface Props {
    jobInstance: any;
    name: string;
    reload?: () => Promise<void>;
}

const TaskItem: FC<Props> = forwardRef(({ jobInstance, name, reload }, ref) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const deleteTask = async () => {
        setLoading(true);
        await dispatch(deleteTaskByIdAsync({ jobId: jobInstance.id, taskId: jobInstance.taskId }));
        await reload?.();
        setLoading(false);
        message.success(t('job.deleteSuccess', { name: `"${name}"` }));
    }

    return <List.Item className="aatp_task_Item" key={jobInstance.id}>
        <List.Item.Meta
            avatar={<Avatar shape="square" size={64} icon={<ScheduleOutlined />} />}
            // title={<Link to={`/jobs?taskId=${taskInstance.id}`}>{new Date(taskInstance.updatedDate).valueOf()}</Link>}
            title={<Link to={`/project?taskId=${jobInstance.taskId}&jobId=${jobInstance.id}`}>{name}</Link>}
        // description={`${t('task.updateTime')} ${jobInstance.updatedDate}`}
        />
        <Popconfirm title={t('base.deleteConfirm')} onConfirm={deleteTask} disabled={loading}>
            <Button danger type='primary' loading={loading}>{t('base.delete')}</Button>
        </Popconfirm>
    </List.Item>
})

export default TaskItem