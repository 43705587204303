// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CombinedState, Issue, IssueStaus } from 'reducers/interfaces';

// import { Canvas } from 'cvat-canvas/src/typescript/canvas';
import { Canvas3d } from 'canvas3d-wrapper';

import { commentIssueAsync, resolveIssueAsync, reopenIssueAsync, selectIssue } from 'actions/review-actions';

import { reviewerAndMore } from 'utils/constant';
import CreateIssueDialog from 'components/annotation-page/review3D/create-issue-dialog';
// import HiddenIssueLabel from './hidden-issue-label';
import IssueDialog from 'components/annotation-page/review3D/issue-dialog';
import Job from 'business/objects/job';

export default function IssueAggregatorComponent(): JSX.Element | null {
    const dispatch = useDispatch();
    // const [expandedIssue, setExpandedIssue] = useState<number | null>(null);
    const frameIssues = useSelector((state: CombinedState): Issue[] => state.review.frameIssues.filter(iss => !iss.direction));
    const user = useSelector((state: CombinedState): any => state.auth.user);
    const issuesHidden = useSelector((state: CombinedState): boolean => state.review.issuesHidden);
    const issuesResolvedHidden = useSelector((state: CombinedState): boolean => state.review.issuesResolvedHidden);
    const canvasInstance = useSelector((state: CombinedState) => state.annotation.canvas.instance) as Canvas3d;
    const canvasIsReady = useSelector((state: CombinedState): boolean => state.annotation.canvas.ready);
    const newIssuePosition = useSelector((state: CombinedState): number[] | null => state.review.newIssuePosition);
    const selectIssueID = useSelector((state: CombinedState): number | null => state.review.selectIssueID);
    const perspective = useSelector((state: CombinedState): string | undefined => state.subAnnotation.perspective);
    // const [expandedIssue, setExpandedIssue] = useState<number | null>(selectIssueID);
    // if (expandedIssue !== selectIssueID)
    const issueFetching = useSelector((state: CombinedState): number | null => state.review.fetching.issueId);

    const jobInstance = useSelector((state: CombinedState): Job => state.annotation.job.instance);
    // // const [geometry, setGeometry] = useState<Canvas3d['geometry'] | null>(null);
    // const issueLabels: JSX.Element[] = [];
    const issueDialogs: JSX.Element[] = [];
    // console.log('查看调用时机');

    // 标注员，不是待解决状态以外的都是已解决的。
    // 审核员及以上，只有结束的才是已解决的。

    const isReviewAndMore = reviewerAndMore(user.roleType);
    const getResolved = (_issue: Issue): boolean =>
        !isReviewAndMore ? _issue.noteStatus !== IssueStaus.await : _issue.noteStatus === IssueStaus.finish;

    const getReadAuthority = (_issue: Issue) => {
        return false;
    };

    useEffect(() => {
        type IssueRegionSet = {
            id: number;
            points: number[];
            message: string;
            resolve: boolean;
            hidden?: boolean;
            readOnly?: boolean;
        };
        if (canvasIsReady) {
            const regions = !issuesHidden
                ? frameIssues
                    // .filter((_issue: Issue) => !issuesResolvedHidden || !_issue.resolved)
                    .filter((_issue: Issue) => !issuesResolvedHidden || !getResolved(_issue))
                    .reduce((acc: IssueRegionSet[], issue: Issue): IssueRegionSet[] => {
                        acc.push({
                            id: issue.id,
                            points: issue.points as number[],
                            message: issue.content,
                            // hidden: issuesResolvedHidden && !getResolved(issue),
                            resolve: getResolved(issue),
                            readOnly: !isReviewAndMore || issue.readonly, // 审核以上的权限才可以移动它
                        });
                        return acc;
                    }, [])
                : [];

            // if (newIssuePosition) {
            //     // regions[0] is always empty because key is an id of an issue (<0, >0 are possible)
            //     regions[0] = {
            //         points: newIssuePosition,
            //         hidden: false,
            //     };
            // }

            canvasInstance?.setupIssueRegions(regions);
        }
    }, [frameIssues, issuesHidden, issuesResolvedHidden, canvasIsReady]);

    // const isReviewAndMore = reviewerAndMore(user.roleType);
    // const getResolved = (_issue: Issue): boolean => (!isReviewAndMore ?
    //     _issue.noteStatus !== IssueStaus.await :
    //     _issue.noteStatus === IssueStaus.finish);

    // if (canvasIsReady) {
    //     console.log('准备就绪');
    // }

    if (!(canvasInstance instanceof Canvas3d) || !canvasIsReady || perspective) {
        return null;
    }

    // if (newIssuePosition) {
    //     console.log('创建新的', newIssuePosition);
    // }

    const top =
        ((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 8 * 30) / 2;
    const left =
        ((window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 8 * 30) / 2;

    if (selectIssueID) {
        const issue = frameIssues.find((_issue) => _issue.id === selectIssueID) as Issue;
        if (!issue) {
            return <></>;
        }
        const issueResolved = getResolved(issue);
        const highlight = (): void => {
            const element = window.document.getElementById(`cvat_canvas_issue_region_${selectIssueID}`);
            if (element) {
                element.style.display = 'block';
            }
        };
        const blur = (): void => {
            if (issueResolved) {
                const element = window.document.getElementById(`cvat_canvas_issue_region_${selectIssueID}`);
                if (element) {
                    element.style.display = 'none';
                }
            }
        };
        issueDialogs.push(
            <IssueDialog
                key={issue.id}
                id={issue.id}
                readonly={issue.readonly}
                top={top}
                left={left}
                angle={0}
                scale={1}
                isFetching={issueFetching !== null}
                comments={issue.comments}
                resolved={issueResolved}
                noteStatus={issue.noteStatus}
                highlight={highlight}
                blur={blur}
                collapse={() => {
                    // setExpandedIssue(null);
                    dispatch(selectIssue(null));
                }}
                resolve={() => {
                    dispatch(resolveIssueAsync(issue.id));
                    dispatch(selectIssue(null));
                    // setExpandedIssue(null);
                }}
                reopen={() => {
                    dispatch(reopenIssueAsync(issue.id));
                }}
                comment={(message: string) => {
                    dispatch(commentIssueAsync(issue.id, message));
                }}
                roleType={user.roleType}
                jobStatus={jobInstance.jobStatus}
            />,
        );
    }
    // useEffect(() => {
    //     if (canvasInstance instanceof Canvas3d) {
    //         const { geometry: updatedGeometry } = canvasInstance;
    //         setGeometry(updatedGeometry);

    //         const geometryListener = (): void => {
    //             setGeometry(canvasInstance.geometry);
    //         };

    //         canvasInstance.html().addEventListener('canvas.zoom', geometryListener);
    //         canvasInstance.html().addEventListener('canvas.fit', geometryListener);
    //         canvasInstance.html().addEventListener('canvas.reshape', geometryListener);

    //         return () => {
    //             canvasInstance.html().removeEventListener('canvas.zoom', geometryListener);
    //             canvasInstance.html().removeEventListener('canvas.fit', geometryListener);
    //             canvasInstance.html().addEventListener('canvas.reshape', geometryListener);
    //         };
    //     }

    //     return () => {};
    // }, [canvasInstance]);

    // // 标注员，不是待解决状态以外的都是已解决的。
    // // 审核员及以上，只有结束的才是已解决的。
    // const getResolved = (_issue: Issue): boolean => (!isReviewAndMore ?
    //     _issue.noteStatus !== IssueStaus.await :
    //     _issue.noteStatus === IssueStaus.finish);

    // useEffect(() => {
    //     if (canvasInstance instanceof Canvas3d) {
    //         type IssueRegionSet = Record<number, { hidden: boolean; points: number[] }>;
    //         const regions = !issuesHidden ? frameIssues
    //         // .filter((_issue: Issue) => !issuesResolvedHidden || !_issue.resolved)
    //             .filter((_issue: Issue) => !issuesResolvedHidden || getResolved(_issue))
    //             .reduce((acc: IssueRegionSet, issue: any): IssueRegionSet => {
    //                 acc[issue.id] = {
    //                     points: issue.position,
    //                     hidden: issue.resolved,
    //                 };
    //                 return acc;
    //             }, {}) : {};

    //         if (newIssuePosition) {
    //             // regions[0] is always empty because key is an id of an issue (<0, >0 are possible)
    //             regions[0] = {
    //                 points: newIssuePosition,
    //                 hidden: false,
    //             };
    //         }

    //         canvasInstance.setupIssueRegions(regions);

    //         if (newIssuePosition) {
    //             setExpandedIssue(null);
    //             const element = window.document.getElementById('cvat_canvas_issue_region_0');
    //             if (element) {
    //                 element.style.display = 'block';
    //             }
    //         }
    //     }
    // }, [newIssuePosition, frameIssues, issuesResolvedHidden, issuesHidden, canvasInstance]);

    // // console.log('是否：', (canvasInstance instanceof Canvas) || (canvasInstance instanceof Canvas3d));

    // // if (!(canvasInstance instanceof Canvas3d) || !canvasIsReady || !geometry) {
    // //     return null;
    // // }

    // if (!(canvasInstance instanceof Canvas3d) || !canvasIsReady || !geometry) {
    //     return null;
    // }

    // for (const issue of frameIssues) {
    //     if (issuesHidden) break;
    //     const issueResolved = getResolved(issue);
    //     if (issuesResolvedHidden && issueResolved) continue;
    //     const offset = 15;
    //     const translated = (issue?.points as number[]).map((coord: number): number => coord + geometry.offset);
    //     const minX = Math.min(...translated.filter((_: number, idx: number): boolean => idx % 2 === 0)) + offset;
    //     const minY = Math.min(...translated.filter((_: number, idx: number): boolean => idx % 2 !== 0)) + offset;
    //     const { id } = issue;
    //     const highlight = (): void => {
    //         const element = window.document.getElementById(`cvat_canvas_issue_region_${id}`);
    //         if (element) {
    //             element.style.display = 'block';
    //         }
    //     };

    //     const blur = (): void => {
    //         if (issueResolved) {
    //             const element = window.document.getElementById(`cvat_canvas_issue_region_${id}`);
    //             if (element) {
    //                 element.style.display = 'none';
    //             }
    //         }
    //     };

    //     if (expandedIssue === id) {
    //         issueDialogs.push(
    //             <IssueDialog
    //                 key={issue.id}
    //                 id={issue.id}
    //                 top={minY}
    //                 left={minX}
    //                 angle={-geometry.angle}
    //                 scale={1 / geometry.scale}
    //                 isFetching={issueFetching !== null}
    //                 comments={issue.comments}
    //                 resolved={issueResolved}
    //                 noteStatus={issue.noteStatus}
    //                 highlight={highlight}
    //                 blur={blur}
    //                 collapse={() => {
    //                     setExpandedIssue(null);
    //                 }}
    //                 resolve={() => {
    //                     dispatch(resolveIssueAsync(issue.id));
    //                     setExpandedIssue(null);
    //                 }}
    //                 reopen={() => {
    //                     dispatch(reopenIssueAsync(issue.id));
    //                 }}
    //                 comment={(message: string) => {
    //                     dispatch(commentIssueAsync(issue.id, message));
    //                 }}
    //             />,
    //         );
    //     } else if (issue.comments?.length) {
    //         issueLabels.push(
    //             <HiddenIssueLabel
    //                 key={issue.id}
    //                 id={issue.id}
    //                 top={minY}
    //                 left={minX}
    //                 angle={-geometry.angle}
    //                 scale={1 / geometry.scale}
    //                 resolved={issueResolved}
    //                 // noteStatus={issue.noteStatus}
    //                 message={issue.comments[issue.comments.length - 1].message}
    //                 highlight={highlight}
    //                 blur={blur}
    //                 onClick={() => {
    //                     setExpandedIssue(id);
    //                 }}
    //             />,
    //         );
    //     }
    // }

    const translated = newIssuePosition ? newIssuePosition.map((coord: number): number => coord) : [];

    const [createLeft = null, createTop = null] = translated;
    // const createLeft = translated.length ?
    //     Math.max(...translated.filter((_: number, idx: number): boolean => idx % 2 === 0)) :
    //     null;
    // const createTop = translated.length ?
    //     Math.min(...translated.filter((_: number, idx: number): boolean => idx % 2 !== 0)) :
    //     null;

    return (
        <>
            {/* <div>测试中卫</div> */}
            {createLeft !== null && createTop !== null ? (
                <CreateIssueDialog top={top} left={left} angle={0} scale={1} />
            ) : null}
            {issueDialogs}
            {/* {issueLabels} */}
        </>
    );
}
