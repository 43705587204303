// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import { Row, Col } from 'antd/lib/grid';
import Button from 'antd/lib/button';
// import { MoreOutlined } from '@ant-design/icons';
// import Dropdown from 'antd/lib/dropdown';
import Progress from 'antd/lib/progress';
import moment from 'moment';

// import ActionsMenuContainer from 'containers/actions-menu/actions-menu';
import { ActiveInference } from 'reducers/interfaces';
import AutomaticAnnotationProgress from './automatic-annotation-progress';
import './styles.scss';
import { Translation } from 'react-i18next';

export interface TaskItemProps {
    taskInstance: any;
    // previewImage?: string;
    deleted: boolean;
    hidden: boolean;
    activeInference: ActiveInference | null;
    user: any;
    cancelAutoAnnotation(): void;
}

class TaskItemComponent extends React.PureComponent<TaskItemProps & RouteComponentProps> {
    // private renderPreview(): JSX.Element {
    //     const { previewImage, history, taskInstance } = this.props;
    //     const { id } = taskInstance;
    //     return (
    //         <Col
    //             span={4}
    //             onClick={(e: React.MouseEvent): void => {
    //                 e.preventDefault();
    //                 history.push(`/tasks/${id}`);
    //             }}
    //         >
    //             <div className='cvat-task-item-preview-wrapper'>
    //                 <img alt='Preview' className='cvat-task-item-preview' src={previewImage} />
    //             </div>
    //         </Col>
    //     );
    // }

    private renderDescription(): JSX.Element {
        // Task info
        const { taskInstance } = this.props;
        const { id } = taskInstance;
        const owner = taskInstance.owner ? taskInstance.owner.username : null;
        const updated = moment(taskInstance.updatedDate).fromNow();
        const created = moment(taskInstance.createdDate).format('MMMM Do YYYY');

        // Get and truncate a task name
        const name = `${taskInstance.name.substring(0, 70)}${taskInstance.name.length > 70 ? '...' : ''}`;

        return (
            <Col span={10} className='cvat-task-item-description'>
                <Text strong type='secondary' className='cvat-item-task-id'>{`#${id}: `}</Text>
                <Text strong className='cvat-item-task-name'>
                    {name}
                </Text>
                <br />
                {owner && (
                    <>
                        {/* <Text type='secondary'>{`Created ${owner ? `by ${owner}` : ''} on ${created}`}</Text> */}
                        <Text type='secondary'>
                            <Translation>{(t) => t('project.create_by')}</Translation>
                            {created}
                        </Text>
                        <br />
                    </>
                )}
                {/* <Text type='secondary'>{`Last updated ${updated}`}</Text> */}
                <Text type='secondary'>
                    <Translation>{(t) => t('project.last_update_time')}</Translation>
                    {` ${updated}`}
                </Text>
            </Col>
        );
    }

    private renderProgress(): JSX.Element {
        const { taskInstance, activeInference, cancelAutoAnnotation } = this.props;
        // Count number of jobs and performed jobs
        const numOfJobs = taskInstance.totalJob;
        const numOfCompleted = 0;

        // Progress appearance depends on number of jobs
        let progressColor = null;
        let progressText = null;
        if (numOfCompleted && numOfCompleted === numOfJobs) {
            progressColor = 'cvat-task-completed-progress';
            progressText = (
                <Text strong className={progressColor}>
                    {/* Completed */}
                    <Translation>{(t) => t('project.completed')}</Translation>
                </Text>
            );
        } else if (numOfCompleted) {
            progressColor = 'cvat-task-progress-progress';
            progressText = (
                <Text strong className={progressColor}>
                    {/* In Progress */}
                    {/* 进行中 */}
                    <Translation>{(t) => t('project.in_progress')}</Translation>
                </Text>
            );
        } else {
            progressColor = 'cvat-task-pending-progress';
            progressText = (
                <Text strong className={progressColor}>
                    {/* Pending */}
                    <Translation>{(t) => t('project.pending')}</Translation>
                </Text>
            );
        }

        const jobsProgress = numOfCompleted / numOfJobs;

        return (
            <Col span={6}>
                <Row justify='space-between' align='top'>
                    <Col>
                        <svg height='8' width='8' className={progressColor}>
                            <circle cx='4' cy='4' r='4' strokeWidth='0' />
                        </svg>
                        {progressText}
                    </Col>
                    <Col>
                        {/* <Text type='secondary'>{`${numOfCompleted} of ${numOfJobs} jobs`}</Text> */}
                        <Text type='secondary'>{`${numOfCompleted} - ${numOfJobs}`}</Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Progress
                            className={`${progressColor} cvat-task-progress`}
                            percent={jobsProgress * 100}
                            strokeColor='#1890FF'
                            showInfo={false}
                            strokeWidth={5}
                            size='small'
                        />
                    </Col>
                </Row>
                <AutomaticAnnotationProgress
                    activeInference={activeInference}
                    cancelAutoAnnotation={cancelAutoAnnotation}
                />
            </Col>
        );
    }

    private renderNavigation(): JSX.Element {
        const { taskInstance, history } = this.props;
        const { id } = taskInstance;

        // if (!user || !user.groups || !user.groups.includes('admin')) {
        //     return (
        //         <Col span={4}>
        //             <Row justify='end'>
        //                 <Col>
        //                     <Button
        //                         className='cvat-item-open-task-button'
        //                         type='primary'
        //                         size='large'
        //                         ghost
        //                         href={`/tasks/${id}`}
        //                         onClick={(e: React.MouseEvent): void => {
        //                             e.preventDefault();
        //                             history.push(`/tasks/${id}`);
        //                         }}
        //                     >
        //                         {/* Open */}
        //                         打开
        //                     </Button>
        //                 </Col>
        //             </Row>
        //         </Col>
        //     );
        // }

        return (
            <Col span={4}>
                <Row justify='end'>
                    <Col>
                        <Button
                            className='cvat-item-open-task-button'
                            type='primary'
                            size='large'
                            ghost
                            href={`/tasks/${id}`}
                            onClick={(e: React.MouseEvent): void => {
                                e.preventDefault();
                                history.push(`/tasks/${id}`);
                            }}
                        >
                            {/* Open */}
                            {/* 打开 */}
                            <Translation>{(t) => t('project.open')}</Translation>
                        </Button>
                    </Col>
                </Row>
                {/* <Row justify='end'>
                    <Dropdown overlay={<ActionsMenuContainer taskInstance={taskInstance} />}>
                        <Col className='cvat-item-open-task-actions'>
                            <Text className='cvat-text-color'>更多功能</Text>
                            <MoreOutlined className='cvat-menu-icon' />
                        </Col>
                    </Dropdown>
                </Row> */}
            </Col>
        );
    }

    public render(): JSX.Element {
        const { deleted, hidden } = this.props;
        const style = {};
        if (deleted) {
            (style as any).pointerEvents = 'none';
            (style as any).opacity = 0.5;
        }

        if (hidden) {
            (style as any).display = 'none';
        }

        return (
            <Row className='cvat-tasks-list-item' justify='center' align='top' style={{ ...style }}>
                {/* {this.renderPreview()} */}
                {this.renderDescription()}
                {this.renderProgress()}
                {this.renderNavigation()}
            </Row>
        );
    }
}

export default withRouter(TaskItemComponent);
