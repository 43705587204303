// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'antd/lib/modal';
import { Row, Col } from 'antd/lib/grid';
import { CloseOutlined } from '@ant-design/icons';
import Comment from 'antd/lib/comment';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
// import moment from 'moment';
import CVATTooltip from 'components/common/cvat-tooltip';
import { deleteIssueAsync } from 'actions/review-actions';
import { CombinedState, Comment as IssueComment, IssueStaus } from 'reducers/interfaces';
import { reviewerAndMore } from 'utils/constant';
import { JobStatus, RoleType } from 'utils/ConstType';

interface Props {
    id: number;
    comments?: IssueComment[];
    left: number;
    top: number;
    resolved: boolean;
    isFetching: boolean;
    angle: number;
    scale: number;
    noteStatus: IssueStaus;
    jobStatus: JobStatus;
    roleType: RoleType;
    readonly: boolean;
    collapse: () => void;
    resolve: () => void;
    reopen: () => void;
    comment: (message: string) => void;
    highlight: () => void;
    blur: () => void;
}

const deleteIssueRole = (jobStatus: JobStatus, roleType: RoleType) => {
    let isCanDelete = true;
    // 默认全部为无效批注，可以删除

    // 以下为流程中的批注。
    if (roleType < RoleType.juniorChecker) {
        // 审核员以下
        isCanDelete = false;
    } else if (jobStatus === JobStatus.submitOne && roleType < RoleType.juniorChecker) {
        // 提交一审时，打的批注，不能让一审以下的人删除
        isCanDelete = false;
    } else if (jobStatus === JobStatus.submitTwo && roleType < RoleType.seniorChecker) {
        // 提交二审时，打的批注，不能让二审以下的人删除
        isCanDelete = false;
    } else if ([JobStatus.submitReceive, JobStatus.pass].includes(jobStatus) && roleType < RoleType.receiver) {
        // 提交初验、初验通过时，打的批注，不能让验收以下的人删除
        isCanDelete = false;
    }

    return isCanDelete;
}

export default function IssueDialog(props: Props): JSX.Element {
    const ref = useRef<HTMLDivElement>(null);
    // const user = useSelector((state: CombinedState) => state.auth.user);
    const [currentText, setCurrentText] = useState<string>('');
    const dispatch = useDispatch();
    const {
        comments,
        id,
        left,
        top,
        scale,
        angle,
        resolved,
        isFetching,
        noteStatus,
        collapse,
        resolve,
        reopen,
        comment,
        highlight,
        blur,
        jobStatus,
        roleType,
        readonly,
    } = props;

    // 是否是审核者及之上
    const isReviewAndMore = reviewerAndMore(roleType);

    useEffect(() => {
        if (!resolved) {
            setTimeout(highlight);
        } else {
            setTimeout(blur);
        }
    }, [resolved]);

    const onDeleteIssue = useCallback((): void => {
        Modal.confirm({
            // title: `The issue${id >= 0 ? ` #${id}` : ''} will be deleted.`,
            title: `删除这条评论${id >= 0 ? ` #${id}` : ''}`,
            className: 'cvat-modal-confirm-remove-issue',
            onOk: () => {
                collapse();
                dispatch(deleteIssueAsync(id));
            },
            okButtonProps: {
                type: 'primary',
                danger: true,
            },
            // okText: 'Delete',
            okText: '删除',
        });
    }, []);

    // const created = _comment.createdDate ? moment(_comment.createdDate) : moment(moment.now());
    // const diff = created.fromNow();
    const lines = comments?.map(
        (_comment: IssueComment, index: number): JSX.Element => (
            <Comment
                avatar={null}
                key={_comment.id}
                author={comments.length > 1 ? <Text strong>{`#########第${index + 1}次########`}</Text> : null}
                content={<p>{_comment.message}</p>}
            />
        )
        ,
    );

    // 默认没有按钮
    let resolveButton = <></>;

    const deleteEnable = deleteIssueRole(jobStatus, roleType);

    // 待解决的问题， 会有解决按钮
    if (noteStatus === IssueStaus.await) {
        resolveButton = (
            <Button loading={isFetching} type='primary' onClick={resolve}>
                {/* Resolve */}
                解决
            </Button>
        );
    }

    // 已解决的问题， 审核人员之上会有会有确认解决解决按钮
    if (noteStatus === IssueStaus.resolved && isReviewAndMore) {
        resolveButton = (
            <Button loading={isFetching} type='primary' onClick={resolve}>
                {/* Resolve */}
                确认解决
            </Button>
        );
    }

    // 已结束的问题， 审核人员之上会有重新打开的按钮
    if (noteStatus === IssueStaus.finish && isReviewAndMore) {
        resolveButton = (
            <Button loading={isFetching} type='primary' onClick={reopen}>
                {/* Reopen */}
                重新打开
            </Button>
        );
    }
    // noteStatus === IssueStaus.finish ? (
    //     <Button loading={isFetching} type='primary' onClick={reopen}>
    //         {/* Reopen */}
    //         重新打开
    //     </Button>
    // ) : (
    //     <Button loading={isFetching} type='primary' onClick={resolve}>
    //         {/* Resolve */}
    //         解决
    //     </Button>
    // );

    return ReactDOM.createPortal(
        <div style={{ top, left, transform: `scale(${scale}) rotate(${angle}deg)` }} ref={ref} className='cvat-issue-dialog'>
            <Row className='cvat-issue-dialog-header' justify='space-between'>
                <Col>
                    {/* <Title level={4}>{id >= 0 ? `Issue #${id}` : 'Issue'}</Title> */}
                    <Title level={4}>{id >= 0 ? `问题 #${id}` : '问题'}</Title>
                </Col>
                <Col>
                    {/* <CVATTooltip title='Collapse the chat'> */}
                    <CVATTooltip title='关闭窗口'>
                        <CloseOutlined onClick={collapse} />
                    </CVATTooltip>
                </Col>
            </Row>
            <Row className='cvat-issue-dialog-chat' justify='start'>
                <Col style={{ display: 'block' }}>{lines}</Col>
            </Row>
            {/* {
                noteStatus !== IssueStaus.finish && (
                    <> */}
            <Row className='cvat-issue-dialog-input' justify='start'>
                <Col span={24}>
                    {isReviewAndMore && noteStatus === IssueStaus.await && (
                        <Input
                            // placeholder='Print a comment here..'
                            placeholder='在这里输入评论..'
                            value={currentText}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setCurrentText(event.target.value);
                            }}
                            onPressEnter={() => {
                                if (currentText) {
                                    comment(currentText);
                                    setCurrentText('');
                                }
                            }}
                        />
                    )}

                </Col>
            </Row>

            {
                !readonly &&
                <Row className='cvat-issue-dialog-footer' justify='space-around'>
                    {deleteEnable && (
                        <Col>
                            <Button type='primary' danger onClick={onDeleteIssue}>
                                {/* Remove */}
                                删除
                            </Button>
                        </Col>
                    )}
                    <Col>
                        {currentText.length ? (
                            <Button
                                loading={isFetching}
                                type='primary'
                                disabled={!currentText.length}
                                onClick={() => {
                                    comment(currentText);
                                    setCurrentText('');
                                }}
                            >
                                {/* Comment */}
                                评论
                            </Button>
                        ) : (
                            resolveButton
                        )}
                    </Col>
                </Row>
            }

            {/* </>
                )
            } */}
        </div>,
        window.document.getElementById('cvat_canvas_attachment_board') as HTMLElement,
    );
}
