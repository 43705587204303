/*
 * @Author: swxy
 * @Date: 2022-03-16 14:15:45
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
// Copyright (C) 2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import Layout from 'antd/lib/layout';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const { Footer } = Layout;

function FooterDrawer(): JSX.Element | null {
    const { t } = useTranslation()

    return (
        <Footer className='login_footer'>
            {t('layouts.copyright')} © {t('layouts.copyright_number')}
        </Footer>
    );
}

export default React.memo(FooterDrawer);
