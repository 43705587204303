/*
 * @Author: swxy
 * @Date: 2022-12-13 09:51:29
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// import CameraControls from 'camera-controls';
import { Box3, Matrix4, Vector2, Vector3 } from 'three';
import { PerspectiveView } from './perspectiveView';
import { ThreeBaseView } from './threeBaseView';
import DrawObject from './drawObject';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Helper from './helpers/helper';

export interface Canvas3dView {
    html(): ViewsDOM;
    render(): void;
    keyControls(keys: KeyboardEvent): void;
}

export enum SupportType {
    pcd = 'pcd',
    bin = 'bin',
    txt = 'txt',
}

export enum CameraAction {
    ZOOM_IN = 'KeyI',
    MOVE_UP = 'KeyU',
    MOVE_DOWN = 'KeyO',
    MOVE_LEFT = 'KeyJ',
    ZOOM_OUT = 'KeyK',
    MOVE_RIGHT = 'KeyL',
    TILT_UP = 'ArrowUp',
    TILT_DOWN = 'ArrowDown',
    ROTATE_RIGHT = 'ArrowRight',
    ROTATE_LEFT = 'ArrowLeft',
}

export interface RayCast {
    renderer: THREE.Raycaster;
    mouseVector: THREE.Vector2;
}

export interface Views {
    perspective: PerspectiveView;
    top: ThreeBaseView;
    side: ThreeBaseView;
    front: ThreeBaseView;
}

export interface CubeObject {
    perspective: THREE.Mesh;
    top: THREE.Mesh;
    side: THREE.Mesh;
    front: THREE.Mesh;
}

export interface RenderView {
    renderer: THREE.WebGLRenderer;
    scene: THREE.Scene;
    camera?: THREE.PerspectiveCamera | THREE.OrthographicCamera;
    controls?: OrbitControls;
    rayCaster?: RayCast;
}

export interface ViewsDOM {
    perspective: HTMLCanvasElement;
    top: HTMLCanvasElement;
    side: HTMLCanvasElement;
    front: HTMLCanvasElement;
}

type Points = [
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
    THREE.Vector2,
];

export interface PointsByType {
    normal: Points;
    point4Line: [THREE.Vector2, THREE.Vector2];
}

export type ImagePosition = [Vector2, Vector2, Vector2, Vector2, Vector2, Vector2, Vector2, Vector2] | null;

export interface Size {
    width: number;
    height: number;
}

export interface ActiveElement {
    clientID: number | null;
    attributeID: number | null;
    issueID: number | null;
    oldClientID: number | null;
    oldIssueID: number | null;
}

export interface GroupData {
    enabled: boolean;
    grouped?: [];
}

export interface Image {
    renderWidth: number;
    renderHeight: number;
    imageData: ImageData | CanvasImageSource;
}

export interface DrawData {
    enabled: boolean;
    initialState?: any;
    redraw?: number;
    shapeType?: string;
    type?: 'object' | 'issue' | 'subObject';
}

export interface ReviewData {
    enabled: boolean;
    // initialIssue?: any;
    redraw?: boolean;
}

export enum FrameZoom {
    MIN = 0.1,
    MAX = 10,
}

export enum ObjectType {
    SHAPE = 'shape',
    TRACK = 'track',
    TAG = 'tag',
}

export enum Planes {
    TOP = 'topPlane',
    SIDE = 'sidePlane',
    FRONT = 'frontPlane',
    PERSPECTIVE = 'perspectivePlane',
}

export enum ViewType {
    PERSPECTIVE = 'perspective',
    TOP = 'top',
    SIDE = 'side',
    FRONT = 'front',
}

export enum MouseInteraction {
    CLICK = 'click',
    DOUBLE_CLICK = 'dblclick',
    HOVER = 'hover',
}

export interface FocusData {
    clientID: string | null;
    issueID: string | null;
}

export interface ShapeProperties {
    opacity: number;
    outlined: boolean;
    outlineColor: string;
    selectedOpacity: number;
    colorBy: string;
}

export enum UpdateReasons {
    IMAGE_CHANGED = 'image_changed',
    OBJECTS_UPDATED = 'objects_updated',
    ISSUE_REGIONS_UPDATED = 'issue_regions_update',
    REVIEW_UPDATED = 'revew_updated',
    DRAW = 'draw',
    SELECT = 'select',
    CANCEL = 'cancel',
    DATA_FAILED = 'data_failed',
    // DRAG_CANVAS = 'drag_canvas',
    birdEye = 'birdEye',
    REVIEW_CANVAS = 'review_canvas',
    SHAPE_ACTIVATED = 'shape_activated',
    ISSUE_ACTIVATED = 'issue_activated',
    GROUP = 'group',
    FITTED_CANVAS = 'fitted_canvas',
    CONFIG_UPDATED = 'config_updated',
    summarize_data = 'summarize_data', // 后面慢慢换成小写
    updateLaneline = 'updateLaneline',
    // update = 'update',

    update_pcd = 'update_pcd',
    update_param = 'update_param',
    update_objects = 'update_objects',
    update_issues = 'update_issues',

    move_object = 'move_object',
    shape_re_activated = 'shape_re_activated',

    delete_point = 'delete_point',
    delete_objects = 'delete_objects',
    // bezier2 = 'bezier2',
}

export enum Mode {
    IDLE = 'idle',
    DRAG = 'drag',
    RESIZE = 'resize',
    DRAW = 'draw',
    EDIT = 'edit',
    INTERACT = 'interact',
    // DRAG_CANVAS = 'drag_canvas',
    birdEye = 'birdEye',
    REVIEW_CANVAS = 'review_canvas',
    GROUP = 'group',
    BUSY = 'busy',

    move_object = 'move_object',
    // bezier2 = 'bezier2',
}

export enum CameraMode {
    orthographic = 'orthographic',
    perspective = 'perspective',
}

export enum DrawMode {
    default = 'double click',
    DRAG = 'draw rectangle',
}

export enum CameraType {
    normal = 'normal',
    buttonhole = 'buttonhole',
    fisheye = 'fisheye',
}

export interface CameraDistortionParameter {
    cameraType: CameraType;
    k1?: number;
    k2?: number;
    k3?: number;
    k4?: number;
    k5?: number;
    k6?: number;
    s1?: number;
    s2?: number;
    s3?: number;
    s4?: number;
    p1?: number;
    p2?: number;
    // cameraViewAngle?: number;
    // viewAngleAble?: boolean;// 是否展示视场角参考线
    // viewRadius?: number;
    // viewStartMiddleAngle?: number; // 开始位置的中间角度
}

export interface PcdParameter {
    // 标注范围半径
    cameraRadius?: number[]; // 有值时（> 0））展示

    // 视场角
    // 起始线标注角度
    viewStartMiddleAngle?: number;
    // 标注角度
    viewAngle?: number;
}

// 激活模式， standard模式下，默认是点击激活。review模式下默认是移入激活
export enum ActivationModel {
    clickActive = 'CLICK_ACTIVE',
    moveInActive = 'MOVE_IN_ACTIVE',
}

export enum PointCloudColorBy {
    normal = 'normal', // 无颜色
    color = 'color', // 自身颜色
    intensity = 'intensity', // 强度值
    axisZ = 'axisZ', // 坐标系z轴
}

export interface Configuration {
    forceDisableEditing: boolean;
    activeModel: ActivationModel;
    birdEyeAutoFit: boolean; // 是否自动贴合

    hasAnimation: boolean;

    isForce: boolean; // 是否聚焦
    isFollow: boolean; // 是否跟随
    pointCloudColor: PointCloudColorBy;
}

// export interface BaseObject {
//     points: number[];
//     lock: boolean;
//     color: string;
//     hidden: boolean;
//     pinned: boolean | null;
//     clientID: number;
//     serverID: number;

//     pointsLine: number;
//     keyframes: any | null;
//     group: any;
//     updated: number;
//     objectType: ObjectType;
//     frame: number;
//     // shapeType: ShapeType;
// }
// enum Source {
//     MANUAL = 'manual',
//     AUTO = 'auto',
// }

export type ExportProjectType = 'zj' | 'fr' | undefined;

export enum ShapeType {
    RECTANGLE = 'rectangle',
    POLYGON = 'polygon',
    POLYLINE = 'polyline',
    POINTS = 'points',
    ELLIPSE = 'ellipse',
    CUBOID = 'cuboid',
    IMAGE = 'image',
    splitRectangle = 'splitRectangle',
    laneline = 'laneline',
    bezier2 = 'bezier2', // 有两个控制点的贝塞尔曲线
}

export interface Issue {
    id: number;
    points: number[];
    message: string;
    resolve: boolean;
    hidden?: boolean;
    readOnly?: boolean;
}

// export interface ObjectState {
//     label: any;
//     attributes: any;

//     points: number[];
//     outside: boolean;
//     occluded: boolean;
//     keyframe: boolean;

//     zOrder: number | null;
//     lock: boolean;
//     color: string;
//     hidden: boolean;
//     pinned: boolean | null;
//     pointsLine: boolean;
//     source: Source;
//     keyframes: any | null;
//     group: any;
//     updated: number;

//     clientID: any;
//     serverID: number;

//     frame: number;
//     objectType: ObjectType;
//     shapeType: ShapeType;
//     updateFlags: {
//         label: boolean;
//         attributes: boolean;

//         points: boolean;
//         outside: boolean;
//         occluded: boolean;
//         keyframe: boolean;

//         zOrder: boolean;
//         pinned: boolean;
//         lock: boolean;
//         color: boolean;
//         hidden: boolean;
//         pointsLine: boolean;
//         relation: boolean;
//     };
//     relation: {
//         parent: ObjectState;
//         children: ObjectState[];
//     };
// }

export enum ControlType {
    default,
    serialid,
    select,
    radio,
    checkbox,
    number,
    text,
}

export interface Attribute {
    id: number; //	编号	integer(int32)	integer(int32)
    name: string; //	名字	string
    titles: string; //	显示名称	string
    labelId: number; //	标签编号	integer(int32)	integer(int32)
    controlType: ControlType; //	控件类型	integer(int32)	integer(int32)
    colors?: string[];
    mutable?: boolean; //   连续帧单帧可改	boolean
    defaultValue?: string; //	默认值	string
    values: string[]; //	值	string
    ranges: string[]; // 显示名称
    addTime?: string; //	输入时间	string(date-time)	string(date-time)
    updateTime?: string; //	更新时间	string(date-time)	string(date-time)
}

export enum InputType {
    default = 'normal',
    serialid = 'serialid',
    select = 'select',
    radio = 'radio',
    checkbox = 'checkbox',
    number = 'number',
    text = 'text',
}

export interface LabelAttribute {
    id: number;
    name: string;
    inputType: InputType;
    values: string[];
    defaultValue: string;
    mutable: boolean;
    labelSpecType: ControlType;
}

export interface Label {
    id: number; // 全局labelId
    name: string;
    title?: string;
    color?: string;
    labelType?: number; // 标签类型, 普通，缺省
    updateTime?: string;
    addTime?: string;
    attributes: LabelAttribute[]; // children属于固定字段，不能使用
}

export enum SubElementType {
    wheelPoint = 'wheelPoint', // 车轮点
    dashedLine = 'dashedLine', // 车道线虚线点
}

export enum SubElementVersion {
    version1_0_0 = 1.0,
}

export interface SubElementInfo {
    x: number;
    y: number;
    z?: number;
    occluded?: boolean;
    order?: number;
    pointType?: 'start' | 'end';
}

export interface SubElement {
    shapeType: SubElementType;
    numOfPoints: number;
    points: SubElementInfo[];
    version: SubElementVersion;
}

export interface ObjectState {
    clientID: number;
    attributes: Record<number, string>;
    frame: number;
    points: number[];
    color: string;
    label: Label;
    shapeType: ShapeType;
    objectType?: ObjectType;
    serverID?: number;

    lock?: boolean;
    hidden?: boolean;
    pinned?: boolean | null;

    pointsLine?: number;
    keyframes?: any | null;
    group?: any;
    updated?: number;
    zOrder?: number;

    controls?: number[][];
    elements?: SubElement[];
}

export interface ZJSummarizeData {
    shapeObjType?: number; //  框目标物类型
    objCertainty?: number; //     确定性   false integer(int32)
    crowd?: number; //     是否密集   false integer(int32)
    motion?: number; //     运动状态   false integer(int32)
    kind?: string; //    类型   false string
}

export interface FRSummarizeData {
    shapeObjFrType?: number; //  框目标物类型
    shapeObjType?: number;
    motion?: number; //     运动状态   false integer(int32)  //["Moving","Parked","Stopped"] 转换为 0,1,2 ["Moving","Stationary"]转换为 0 1
    truncation?: string; // 截断
    specialType?: string; // 特殊类型["Towing Vehicle","Trailer"]
    occlusion?: string; // 堵塞
    ifchild?: string; // 是否小孩Type	下拉框	["Adult","Child"] ， 转换为0,1
    position?: string; //  位置
    size?: string; //    Size	下拉框	["Small","Big"] 转换为小写
    drivability?: string; //    可驾驶
    [k: string]: any;
}

export interface ExportBaseInfo {
    // id: number; // 编号   true integer(int64)
    jobId: number; //    题包编号   true integer(int32)
    frame: number; //    帧号   true integer(int32)
    frameType: number; //    帧类型   true integer(int32)
    trackShapeId: number; //    连续框编号   false integer(int64)
    objId: number; //    目标编号   false integer(int32)
    normalShapeId: number; //    普通框编号   false integer(int64)
}

export interface AttributesInfo extends ZJSummarizeData, FRSummarizeData {}

export interface PositionInfo {
    pointsNum: number; //    点云数   false integer(int32)

    objLength: number; //    目标物长   false number(double)
    objWidth: number; //    目标物宽   false number(double)
    objHeight: number; // 目标物高   false number(double)

    objWx: number; //    中心点x   false number(double)
    objWy: number; //    中心点y   false number(double)
    objWz: number; //    中心点z   false number(double)

    objPitch: number; //    旋转角度pitch   false number(double)
    objRoll: number; //    旋转角度roll   false number(double)
    objYaw: number; //    旋转角度yaw   false number(double)

    rotationY?: number;
    alpha?: number;
    points?: string;

    camWx?: number;
    camWy?: number;
    camWz?: number;
}

export interface SummarizeData extends ExportBaseInfo, PositionInfo, AttributesInfo {
    [k: string]: string | number;
}

export interface Action {
    cameraMove: boolean;
    loading: boolean;
    frameCoordinates: Vector3;

    resetCamera: boolean;

    actionView: ViewType | null;
    isAction: boolean;
    selectable: boolean;

    translation: {
        status: boolean;
        helper: Vector2 | null;
        coordinates: Vector3 | null;
        offset: Vector3;
        inverseMatrix: Matrix4;
    };
    rotation: {
        status: boolean;
        helper: Vector2 | null;
        recentMouseVector: Vector2;
        screenInit: {
            x: number;
            y: number;
        };
        screenMove: {
            x: number;
            y: number;
        };
    };
    resize: {
        status: boolean;
        helper: Vector2 | null;
        recentMouseVector: Vector2;
        initScales: {
            x: number;
            y: number;
            z: number;
        };
        memScales: {
            x: number;
            y: number;
            z: number;
        };
        resizeVector: Vector3;
        frontBool: boolean;
    };
    resizeFace: {
        status: boolean;
        helper: Vector2 | null;
        recentMouseVector: Vector2;
        initScales: {
            x: number;
            y: number;
            z: number;
        };
        memScales: {
            x: number;
            y: number;
            z: number;
        };
        resizeVector: Vector3;
        posName: string;
    };
    drawObject: DrawObject;

    // selectPointName: string;
    movePointName: string;

    helper?: Helper;

    look: {
        lookMesh: THREE.Object3D;
    };

    attachCamera(viewType: ViewType): void;
    detachCamera(viewType: ViewType): void;
}

export enum ArrowType {
    ArrowLeft = 'ArrowLeft',
    ArrowRight = 'ArrowRight',
    ArrowUp = 'ArrowUp',
    ArrowDown = 'ArrowDown',
}

// export enum UpdateLanelineType {
//     normal = 'normal',
//     add = 'add',
//     select = 'select',
//     delete = 'delete',
// }
export type UpdateLanelineType = 'normal' | 'add' | 'select' | 'delete';

export interface Pcd {
    positions: THREE.Float32BufferAttribute[];
    colors: THREE.Float32BufferAttribute[];
    count: number;
    name: string;
    containPointsBox(box: Box3, result?: Vector3[], matrix?: Matrix4): Vector3[];
}
