/*
 * @Author: swxy
 * @Date: 2023-07-21 20:45:23
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */

import { post } from 'utils/request';
import poseData from '../../resources/pose.json';
import { getToken } from 'utils/storage';

export const queryFramesInfo = async (...data: any) => {
    const result = await post('/api/engineFrame/select', ...data);
    return result;
};

export const api_queryFramesInfo = async (...data: any) => {
    const result = await post('/api/engineFrame/select', ...data);
    return result;
};

export const queryFrameFilesInfo = async (...data: any) => {
    const result = await post('/api/engineFrameFile/select', ...data);
    return result;
};

export const downFrameFile = async (data: { frameId: number; name: string }) => {
    const result = await post('/api/aatp/frame/downFrameFile', data, {
        responseOriginalData: true,
    });
    return result;
};

export const api_downFramePose = async (data: { jobId: number }) => {
    let token = getToken() ?? '';
    const response = await fetch('/api/aatp/frame/downPoseFile', {
        method: 'POST',
        body: JSON.stringify(data),
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
    return response.json();
};

export const api_downloadFrameLidarToImu = async (data: { frameId: number; name: string }) => {
    let token = getToken() ?? '';
    const response = await fetch('/api/aatp/frame/downFrameFile', {
        method: 'POST',
        body: JSON.stringify(data),
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
    return response.text();
};
