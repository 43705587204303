/*
 * @Author: swxy
 * @Date: 2023-04-21 12:00:41
 * @LastEditors: swxy
 * Copyright (C) Amygo
 */
// Copyright (C) 2020-2021 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect, useRef } from 'react';

import { Row, Col } from 'antd/lib/grid';
// import { LinkOutlined } from '@ant-design/icons';
import Slider from 'antd/lib/slider';
import InputNumber from 'antd/lib/input-number';
// import Input from 'antd/lib/input';
import Text from 'antd/lib/typography/Text';

import CVATTooltip from 'components/common/cvat-tooltip';
import { clamp } from 'utils/math';
import { useTranslation } from 'react-i18next';
import GlobalHotKeys from 'utils/mousetrap-react';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers/interfaces';
import Input from 'antd/lib/input';
import isAbleToChangeFrame from 'utils/is-able-to-change-frame';
import {
    changeFrameAsync,
    switchPlay as switchPlayAction,
} from 'actions/annotation-actions';


interface Props {
    // startFrame: number;
    // stopFrame: number;
    // frameNumber: number;
    // frameFilename: string;
    // focusFrameInputShortcut: string;
    // inputFrameRef: React.RefObject<HTMLInputElement>;
    // onSliderChange(value: number): void;
    // onInputChange(value: number): void;
    // onURLIconClick(): void;
}

function PlayerNavigation(props: Props): JSX.Element {
    const dispatch = useDispatch();

    const {
        playing,

        startFrame,
        stopFrame,
        focusFrameInputShortcut,

        frameNumber,


        keyMap,
    } = useSelector((state: CombinedState) => ({
        playing: state.annotation.player.playing,
        startFrame: state.annotation.job.instance.startFrame,
        stopFrame: state.annotation.job.instance.stopFrame,
        focusFrameInputShortcut: state.shortcuts.normalizedKeyMap.FOCUS_INPUT_FRAME,

        frameNumber: state.annotation.player.frame.number,

        keyMap: state.shortcuts.keyMap,
    }))

    const { t } = useTranslation();
    const [frameInputValue, setFrameInputValue] = useState<number>(frameNumber);
    const inputFrameRef = useRef<HTMLInputElement>(null);


    const changeFrame = (frame: number): void => {
        if (isAbleToChangeFrame()) {
            dispatch(changeFrameAsync(frame));
        }
    }

    const onSwitchPlay = (playing: boolean) => {
        dispatch(switchPlayAction(playing));
    }

    const onSliderChange = (value: number) => {
        if (playing) {
            onSwitchPlay(false);
        }
        changeFrame(value as number);
    }

    const onInputChange = (value: number) => {
        if (value !== frameNumber) {
            if (playing) {
                onSwitchPlay(false);
            }
            changeFrame(value);
        }
    }

    useEffect(() => {
        if (frameNumber !== frameInputValue) {
            setFrameInputValue(frameNumber);
        }
    }, [frameNumber]);

    const subKeyMap = {
        FOCUS_INPUT_FRAME: keyMap.FOCUS_INPUT_FRAME,
    };

    const handlers = {
        FOCUS_INPUT_FRAME: (event: KeyboardEvent | undefined) => {
            event?.preventDefault();
            if (inputFrameRef.current) {
                inputFrameRef.current.focus();
            }
        },
    };

    return (
        <>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <Col className='cvat-player-controls'>
                <Row align='middle'>
                    <Col>
                        <Slider
                            className='cvat-player-slider'
                            min={startFrame}
                            max={stopFrame}
                            value={frameNumber || 0}
                            onChange={onSliderChange}
                        />
                    </Col>
                </Row>
            </Col>
            <Col>
                <CVATTooltip title={t(`workspace.top.change_frame_input_tip`, { hot: focusFrameInputShortcut })}>
                    <InputNumber
                        ref={inputFrameRef}
                        className='cvat-player-frame-selector'
                        type='number'
                        size='small'
                        value={frameInputValue}
                        onChange={(value: number | undefined | string | null) => {
                            if (typeof value !== 'undefined' && value !== null) {
                                setFrameInputValue(Math.floor(clamp(+value, startFrame, stopFrame)));
                            }
                        }}
                        onBlur={() => {
                            onInputChange(frameInputValue);
                        }}
                        onPressEnter={() => {
                            onInputChange(frameInputValue);
                            inputFrameRef.current?.blur();
                        }}
                    />
                </CVATTooltip>
                <Text className='aatp-player-controls-allframe'> - {stopFrame}</Text>
            </Col>
        </>
    );
}

export default React.memo(PlayerNavigation);
