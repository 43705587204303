// Copyright (C) 2019-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import consts from './consts';
import { MasterImpl } from './master';

export interface Size {
    width: number;
    height: number;
}

export interface PCDImageData {
    data: { data: Blob; name: string };
    frame: number;
    images: {
        data: ImageBitmap;
        fullName: string;
        name: string;
    }[];
}

export interface Image {
    renderWidth: number;
    renderHeight: number;
    imageData: ImageData | ImageBitmap;
    name?: string;
}

interface ImageBitmapData {
    image?: ImageBitmap;
    name: string;
    renderWidth: number;
    renderHeight: number;
}

export interface Position {
    x: number;
    y: number;
}

export interface Geometry {
    image: Size;
    canvas: Size;
    grid: Size;
    top: number;
    left: number;
    scale: number;
    offset: number;
    angle: number;
    frame: number;
}

export interface FocusData {
    clientID: number;
    padding: number;
}

export interface ActiveElement {
    clientID: number | null;
    attributeID: number | null;
    clientsID?: number[];
}

export enum RectDrawingMethod {
    CLASSIC = 'By 2 points',
    EXTREME_POINTS = 'By 4 points',
    CLICK_UPANDDOWN = '鼠标点击与松开',
}

export enum CuboidDrawingMethod {
    CLASSIC = 'From rectangle',
    CORNER_POINTS = 'By 4 points',
}

export interface Configuration {
    smoothImage?: boolean;
    autoborders?: boolean;
    displayAllText?: boolean;
    textFontSize?: number;
    textPosition?: 'auto' | 'center';
    textContent?: string;
    undefinedAttrValue?: string;
    showProjections?: boolean;
    forceDisableEditing?: boolean;
    intelligentPolygonCrop?: boolean;
    forceFrameUpdate?: boolean;
    creationOpacity?: number;
    isSegmentation?: boolean;
}

export interface DrawData {
    enabled: boolean;
    shapeType?: string;
    rectDrawingMethod?: RectDrawingMethod;
    cuboidDrawingMethod?: CuboidDrawingMethod;
    numberOfPoints?: number;
    initialState?: any;
    crosshair?: boolean;
    redraw?: number;
    continueLabel?: boolean;
    subType?: string;
}

export interface DrawSubPointData {
    enabled: boolean;
    clientID?: number;
    points?: number[];
    shapeType?: string;
    numberOfPoints?: number;
    subType?: string;
}

export interface InteractionData {
    enabled: boolean;
    shapeType?: string;
    crosshair?: boolean;
    minPosVertices?: number;
    minNegVertices?: number;
    startWithBox?: boolean;
    enableThreshold?: boolean;
    enableSliding?: boolean;
    allowRemoveOnlyLast?: boolean;
    intermediateShape?: {
        shapeType: string;
        points: number[];
    };
    onChangeToolsBlockerState?: (event: string) => void;
}

export interface InteractionResult {
    points: number[];
    shapeType: string;
    button: number;
}
export interface MultSelectData {
    enabled: boolean;
    e: MouseEvent;
}

export interface EditData {
    enabled: boolean;
    state: any;
    pointID: number;
}

export interface GroupData {
    enabled: boolean;
}

export interface MergeData {
    enabled: boolean;
}

export interface SplitData {
    enabled: boolean;
}

export enum FrameZoom {
    MIN = 0.1,
    MAX = 10,
}

export enum UpdateReasons {
    IMAGE_CHANGED = 'image_changed',
    IMAGE_ZOOMED = 'image_zoomed',
    IMAGE_FITTED = 'image_fitted',
    IMAGE_MOVED = 'image_moved',
    GRID_UPDATED = 'grid_updated',

    ISSUE_REGIONS_UPDATED = 'issue_regions_updated',
    OBJECTS_UPDATED = 'objects_updated',
    SHAPE_ACTIVATED = 'shape_activated',
    SHAPE_FOCUSED = 'shape_focused',

    FITTED_CANVAS = 'fitted_canvas',

    INTERACT = 'interact',
    DRAW = 'draw',
    MERGE = 'merge',
    SPLIT = 'split',
    GROUP = 'group',
    SELECT = 'select',
    CANCEL = 'cancel',
    BITMAP = 'bitmap',
    SELECT_REGION = 'select_region',
    DRAG_CANVAS = 'drag_canvas',
    ZOOM_CANVAS = 'zoom_canvas',
    CONFIG_UPDATED = 'config_updated',
    DATA_FAILED = 'data_failed',
    DESTROY = 'destroy',

    SHAPES_ACTIVATED = 'shapes_activated',
    MOUSEMULTSELECT = 'mouse_mult_select',
    CHANGESHOWATTRIBUTE = 'change_show_attribute',

    drawSubPoint = 'draw_sub_point',
    image_changed_update = 'image_changed_update',
}

export enum Mode {
    IDLE = 'idle',
    DRAG = 'drag',
    RESIZE = 'resize',
    DRAW = 'draw',
    EDIT = 'edit',
    MERGE = 'merge',
    SPLIT = 'split',
    GROUP = 'group',
    INTERACT = 'interact',
    SELECT_REGION = 'select_region',
    DRAG_CANVAS = 'drag_canvas',
    ZOOM_CANVAS = 'zoom_canvas',

    MOUSEMULTSELECT = 'mouse_mult_select',
    CHANGESHOWATTRIBUTE = 'change_show_attribute',

    birdEye = 'birdEye',
}

export interface CanvasModel {
    readonly imageData?: ImageBitmapData;
    readonly imageBitmap: boolean;
    readonly image: Image | null;
    readonly issueRegions: Record<number, { hidden: boolean; points: number[] }>;
    readonly objects: any[];
    readonly zLayer: number | null;
    readonly gridSize: Size;
    readonly focusData: FocusData;
    readonly activeElement: ActiveElement;
    readonly drawData: DrawData;
    readonly interactionData: InteractionData;
    readonly mergeData: MergeData;
    readonly splitData: SplitData;
    readonly groupData: GroupData;
    readonly configuration: Configuration;
    readonly selected: any;
    readonly multSelectData: MultSelectData;
    readonly relationOperate: boolean;
    readonly drawSubPointData: DrawSubPointData;
    isShowAttribute: boolean;
    geometry: Geometry;
    mode: Mode;
    exception: Error | null;

    changeFrame(frame: number, imageName?: string): void;

    activates(clientsID: number[], attributeID: number | null): void;
    multSelect(e: MouseEvent): void;
    changeShowAttribute(isShow: boolean): void;
    changeRelationOperate(relationOperate: boolean): void;

    zoom(x: number, y: number, direction: number): void;
    move(topOffset: number, leftOffset: number): void;

    setup(frameData: any, objectStates: any[], zLayer: number): void;
    setupImageUpdate(
        frame: number,
        image: { name: string; image: ImageBitmap },
        objectStates: any[],
        zLayer: number,
    ): void;
    setupImage(
        {
            image,
            frameNumber,
        }: {
            image?: Image;
            frameNumber: number;
        },
        objectStates: any[],
        zLayer: number,
    ): void;
    setupIssueRegions(issueRegions: Record<number, { hidden: boolean; points: number[] }>): void;
    activate(clientID: number | null, attributeID: number | null): void;
    rotate(rotationAngle: number): void;
    focus(clientID: number, padding: number): void;
    fit(): void;
    grid(stepX: number, stepY: number): void;

    draw(drawData: DrawData): void;
    group(groupData: GroupData): void;
    split(splitData: SplitData): void;
    merge(mergeData: MergeData): void;
    select(objectState: any): void;
    interact(interactionData: InteractionData): void;

    drawSubPoint(drawData?: DrawSubPointData): void;

    fitCanvas(width: number, height: number): void;
    bitmap(enabled: boolean): void;
    selectRegion(enabled: boolean): void;
    dragCanvas(enable: boolean): void;
    zoomCanvas(enable: boolean): void;

    isAbleToChangeFrame(): boolean;
    configure(configuration: Configuration): void;
    cancel(): void;
    destroy(): void;
}

export class CanvasModelImpl extends MasterImpl implements CanvasModel {
    private data: {
        activeElement: ActiveElement;
        angle: number;
        canvasSize: Size;
        configuration: Configuration;
        imageBitmap: boolean;
        image: Image | null;
        imageID: number | null;
        frame?: number;

        imageData?: ImageBitmapData;
        imageOffset: number;
        imageSize: Size;
        focusData: FocusData;
        gridSize: Size;
        left: number;
        objects: any[];
        issueRegions: Record<number, { hidden: boolean; points: number[] }>;
        scale: number;
        top: number;
        zLayer: number | null;
        drawData: DrawData;
        interactionData: InteractionData;
        mergeData: MergeData;
        groupData: GroupData;
        splitData: SplitData;
        selected: any;
        mode: Mode;
        exception: Error | null;

        multSelectData: MultSelectData;
        showAttribute: boolean;
        relationOperate: boolean;

        drawSubPointData?: DrawSubPointData;
    };

    public constructor() {
        super();

        this.data = {
            activeElement: {
                clientID: null,
                attributeID: null,
                clientsID: [],
            },
            angle: 0,
            canvasSize: {
                height: 0,
                width: 0,
            },
            configuration: {
                displayAllText: false,
                autoborders: false,
                undefinedAttrValue: '',
                textContent: 'id,label,attributes,source,descriptions',
                textPosition: 'auto',
                textFontSize: consts.DEFAULT_SHAPE_TEXT_SIZE,
                isSegmentation: false,
            },
            imageBitmap: false,
            image: null,
            imageID: null,
            frame: undefined,
            imageData: undefined,
            // imageName: undefined,
            // imageBitmapData: undefined,
            imageOffset: 0,
            imageSize: {
                height: 0,
                width: 0,
            },
            focusData: {
                clientID: 0,
                padding: 0,
            },
            gridSize: {
                height: 100,
                width: 100,
            },
            left: 0,
            objects: [],
            issueRegions: {},
            scale: 1,
            top: 0,
            zLayer: null,
            drawData: {
                enabled: false,
                initialState: null,
            },
            interactionData: {
                enabled: false,
            },
            mergeData: {
                enabled: false,
            },
            groupData: {
                enabled: false,
            },
            splitData: {
                enabled: false,
            },
            selected: null,
            mode: Mode.IDLE,
            exception: null,

            multSelectData: {
                enabled: false,
                e: new MouseEvent('1'),
            },
            showAttribute: true,
            relationOperate: false,

            drawSubPointData: undefined,
        };
    }

    public zoom(x: number, y: number, direction: number): void {
        const oldScale: number = this.data.scale;
        const newScale: number = direction > 0 ? (oldScale * 6) / 5 : (oldScale * 5) / 6;
        this.data.scale = Math.min(Math.max(newScale, FrameZoom.MIN), FrameZoom.MAX);

        const { angle } = this.data;

        const mutiplier = Math.sin((angle * Math.PI) / 180) + Math.cos((angle * Math.PI) / 180);
        if ((angle / 90) % 2) {
            // 90, 270, ..
            const topMultiplier = (x - this.data.imageSize.width / 2) * (oldScale / this.data.scale - 1);
            const leftMultiplier = (y - this.data.imageSize.height / 2) * (oldScale / this.data.scale - 1);
            this.data.top += mutiplier * topMultiplier * this.data.scale;
            this.data.left -= mutiplier * leftMultiplier * this.data.scale;
        } else {
            const leftMultiplier = (x - this.data.imageSize.width / 2) * (oldScale / this.data.scale - 1);
            const topMultiplier = (y - this.data.imageSize.height / 2) * (oldScale / this.data.scale - 1);
            this.data.left += mutiplier * leftMultiplier * this.data.scale;
            this.data.top += mutiplier * topMultiplier * this.data.scale;
        }

        this.notify(UpdateReasons.IMAGE_ZOOMED);
    }

    public move(topOffset: number, leftOffset: number): void {
        this.data.top += topOffset;
        this.data.left += leftOffset;
        this.notify(UpdateReasons.IMAGE_MOVED);
    }

    public fitCanvas(width: number, height: number): void {
        this.data.canvasSize.height = height;
        this.data.canvasSize.width = width;

        this.data.imageOffset = Math.floor(
            Math.max(this.data.canvasSize.height / FrameZoom.MIN, this.data.canvasSize.width / FrameZoom.MIN),
        );

        this.notify(UpdateReasons.FITTED_CANVAS);
        this.notify(UpdateReasons.OBJECTS_UPDATED);
        this.notify(UpdateReasons.ISSUE_REGIONS_UPDATED);
    }

    public bitmap(enabled: boolean): void {
        this.data.imageBitmap = enabled;
        this.notify(UpdateReasons.BITMAP);
    }

    public selectRegion(enable: boolean): void {
        if (enable && this.data.mode !== Mode.IDLE) {
            throw Error(`Canvas is busy-selectRegion. Action: ${this.data.mode}`);
        }

        if (!enable && this.data.mode !== Mode.SELECT_REGION) {
            throw Error(`Canvas is not in the region selecting mode. Action: ${this.data.mode}`);
        }

        this.data.mode = enable ? Mode.SELECT_REGION : Mode.IDLE;
        this.notify(UpdateReasons.SELECT_REGION);
    }

    public dragCanvas(enable: boolean): void {
        if (enable && this.data.mode !== Mode.IDLE) {
            throw Error(`Canvas is busy-dragCanvas. Action: ${this.data.mode}`);
        }

        if (!enable && this.data.mode !== Mode.DRAG_CANVAS) {
            throw Error(`Canvas is not in the drag mode. Action: ${this.data.mode}`);
        }

        this.data.mode = enable ? Mode.DRAG_CANVAS : Mode.IDLE;
        this.notify(UpdateReasons.DRAG_CANVAS);
    }

    public zoomCanvas(enable: boolean): void {
        if (enable && this.data.mode !== Mode.IDLE) {
            throw Error(`Canvas is busy-zoomCanvas. Action: ${this.data.mode}`);
        }

        if (!enable && this.data.mode !== Mode.ZOOM_CANVAS) {
            throw Error(`Canvas is not in the zoom mode. Action: ${this.data.mode}`);
        }

        this.data.mode = enable ? Mode.ZOOM_CANVAS : Mode.IDLE;
        this.notify(UpdateReasons.ZOOM_CANVAS);
    }

    /**
     * @deprecated 该方法应该删除
     * @param frame
     * @param imageName
     */
    public changeFrame(frame: number, imageName?: string) {
        this.data.frame = frame;

        if (this.data.imageData) {
            this.data.imageData.name = imageName;
        } else {
            this.data.imageData = {
                name: imageName,
                renderHeight: 0,
                renderWidth: 0,
            };
        }
    }

    public setupImageUpdate(
        frame: number,
        image: { name: string; image: ImageBitmap },
        objectStates: any[],
        zLayer: number,
    ) {
        try {
            // console.log('只更新对象+++++++++++++', this.data.frame === frame);
            if (
                this.data.frame === frame &&
                image.name === this.data.imageData.name &&
                image.image === this.data.imageData.image
            ) {
                // 图像不需要更新
                this.data.zLayer = zLayer;
                this.data.objects = objectStates;
                this.notify(UpdateReasons.OBJECTS_UPDATED);
                return;
            }

            // console.log('对比：前：', image);
            // console.log('对比：后：', this.data.imageData);

            // if (frame !== this.data.frame) {
            //     // 已过期的数据，无需处置
            //     return;
            // }

            // console.log('==2', frame);
            // console.log('==', this.data.frame);
            // console.log('这里更新了几次图片：', image.name);

            this.data.imageData = {
                image: image.image,
                name: image.name,
                renderWidth: image.image.width,
                renderHeight: image.image.height,
            };
            if (this.data.imageID === frame) {
                setTimeout(() => {
                    this.fit();
                }, 0);
            }
            this.data.frame = frame;
            this.data.imageID = frame;
            this.data.imageSize = {
                height: this.data.imageData.image.height as number,
                width: this.data.imageData.image.width as number,
            };

            this.notify(UpdateReasons.image_changed_update);
            this.data.zLayer = zLayer;
            this.data.objects = objectStates;
            this.notify(UpdateReasons.OBJECTS_UPDATED);
        } catch (exception) {
            this.data.exception = exception;
            if (typeof exception !== 'number' || exception === this.data.imageID) {
                this.notify(UpdateReasons.DATA_FAILED);
            }
            throw exception;
        }
    }

    public setupImage(
        {
            image,
            frameNumber,
        }: {
            image?: Image;
            frameNumber: number;
        },
        objectStates: any[],
        zLayer: number,
    ): void {
        try {
            if (image && typeof frameNumber === 'number') {
                if (frameNumber === this.data.imageID && image.name && this.data.image?.name === image.name) {
                    this.data.zLayer = zLayer;
                    this.data.objects = objectStates;
                    this.notify(UpdateReasons.OBJECTS_UPDATED);
                    // this.data.image = null;
                    // this.notify(UpdateReasons.IMAGE_CHANGED);
                    return;
                }

                this.data.imageID = frameNumber;
                this.data.imageSize = {
                    height: image.imageData.height as number,
                    width: image.imageData.width as number,
                };

                this.data.image = null;
                this.notify(UpdateReasons.IMAGE_CHANGED);

                this.data.image = image;
                this.notify(UpdateReasons.IMAGE_CHANGED);
            }

            this.data.zLayer = zLayer;
            this.data.objects = objectStates;
            this.notify(UpdateReasons.OBJECTS_UPDATED);

            this.fit();
        } catch (exception) {
            this.data.exception = exception;
            if (typeof exception !== 'number' || exception === this.data.imageID) {
                this.notify(UpdateReasons.DATA_FAILED);
            }
            throw exception;
        }
    }

    public setup(frameData: any, objectStates: any[], zLayer: number): void {
        if (this.data.imageID !== frameData.number) {
            if ([Mode.EDIT, Mode.DRAG, Mode.RESIZE].includes(this.data.mode)) {
                throw Error(`Canvas is busy-setup. Action: ${this.data.mode}`);
            }
        }
        if (
            frameData.number === this.data.imageID &&
            this.data?.image?.name === frameData?.fileName &&
            !this.data.configuration.forceFrameUpdate
        ) {
            this.data.zLayer = zLayer;
            this.data.objects = objectStates;
            this.notify(UpdateReasons.OBJECTS_UPDATED);
            return;
        }

        this.data.imageID = frameData.number;
        this.data.imageSize = {
            height: frameData.height as number,
            width: frameData.width as number,
        };

        frameData
            .data((): void => {
                this.data.image = null;
                this.notify(UpdateReasons.IMAGE_CHANGED);
            })
            .then(async (data: Image | any): Promise<void> => {
                if (frameData.number !== this.data.imageID) {
                    // already another image
                    return;
                }
                if (Array.isArray(data.images)) {
                    if (frameData.job.cams?.length) {
                        const index = frameData.job.cams.findIndex((name: string) => name === frameData.job.cameraName);
                        const imageData = data.images[index] as {
                            name: string;
                            data: Blob;
                            size: number;
                        };

                        const image = await self.createImageBitmap(imageData.data);

                        this.data.image = {
                            renderWidth: image.width,
                            renderHeight: image.height,
                            imageData: image,
                            name: imageData.name,
                        };
                        this.data.imageSize = {
                            width: image.width,
                            height: image.height,
                        };
                    }
                } else {
                    this.data.image = data;
                    // this.data.imageSize = {
                    //     height: frameData.height as number,
                    //     width: frameData.width as number,
                    // };
                    this.data.imageSize = {
                        height: data.imageData.height as number,
                        width: data.imageData.width as number,
                    };
                }

                this.notify(UpdateReasons.IMAGE_CHANGED);
                this.data.zLayer = zLayer;
                this.data.objects = objectStates;
                this.notify(UpdateReasons.OBJECTS_UPDATED);

                this.fit();
            })
            .catch((exception: any): void => {
                this.data.exception = exception;
                // don't notify when the frame is no longer needed
                if (typeof exception !== 'number' || exception === this.data.imageID) {
                    this.notify(UpdateReasons.DATA_FAILED);
                }
                throw exception;
            });
    }

    public setupIssueRegions(issueRegions: Record<number, { hidden: boolean; points: number[] }>): void {
        this.data.issueRegions = issueRegions;
        this.notify(UpdateReasons.ISSUE_REGIONS_UPDATED);
    }

    public activate(clientID: number | null, attributeID: number | null): void {
        // console.log('这里会执行几次：', clientID);
        if (this.data.activeElement.clientID === clientID && this.data.activeElement.attributeID === attributeID) {
            return;
        }

        if (this.data.mode !== Mode.IDLE && clientID !== null) {
            // console.error(`Canvas is busy-activate. Action: ${this.data.mode}`);
            // return;
            throw Error(`Canvas is busy-activate. Action: ${this.data.mode}`);
        }

        if (typeof clientID === 'number') {
            const [state] = this.objects.filter((_state: any): boolean => _state.clientID === clientID);
            if (!state || state.objectType === 'tag') {
                return;
            }
        }

        this.data.activeElement = {
            clientID,
            attributeID,
        };

        this.notify(UpdateReasons.SHAPE_ACTIVATED);
    }

    public rotate(rotationAngle: number): void {
        if (this.data.angle !== rotationAngle) {
            this.data.angle = (360 + Math.floor(rotationAngle / 90) * 90) % 360;
            this.fit();
        }
    }

    public focus(clientID: number, padding: number): void {
        this.data.focusData = {
            clientID,
            padding,
        };

        this.notify(UpdateReasons.SHAPE_FOCUSED);
    }

    public fit(): void {
        const { angle } = this.data;

        if ((angle / 90) % 2) {
            // 90, 270, ..
            this.data.scale = Math.min(
                this.data.canvasSize.width / this.data.imageSize.height,
                this.data.canvasSize.height / this.data.imageSize.width,
            );
        } else {
            this.data.scale = Math.min(
                this.data.canvasSize.width / this.data.imageSize.width,
                this.data.canvasSize.height / this.data.imageSize.height,
            );
        }

        this.data.scale = Math.min(Math.max(this.data.scale, FrameZoom.MIN), FrameZoom.MAX);

        this.data.top = this.data.canvasSize.height / 2 - this.data.imageSize.height / 2;
        this.data.left = this.data.canvasSize.width / 2 - this.data.imageSize.width / 2;

        this.notify(UpdateReasons.IMAGE_FITTED);
    }

    public grid(stepX: number, stepY: number): void {
        this.data.gridSize = {
            height: stepY,
            width: stepX,
        };

        this.notify(UpdateReasons.GRID_UPDATED);
    }

    public draw(drawData: DrawData): void {
        if (![Mode.IDLE, Mode.DRAW].includes(this.data.mode)) {
            throw Error(`Canvas is busy-draw. Action: ${this.data.mode}`);
        }

        if (drawData.enabled) {
            if (this.data.drawData.enabled) {
                throw new Error('Drawing has been already started');
            } else if (!drawData.shapeType && !drawData.initialState) {
                throw new Error('A shape type is not specified');
            } else if (typeof drawData.numberOfPoints !== 'undefined') {
                if (drawData.shapeType === 'polygon' && drawData.numberOfPoints < 3) {
                    throw new Error('A polygon consists of at least 3 points');
                } else if (drawData.shapeType === 'polyline' && drawData.numberOfPoints < 2) {
                    throw new Error('A polyline consists of at least 2 points');
                }
            }
        }

        if (typeof drawData.redraw === 'number') {
            const clientID = drawData.redraw;
            const [state] = this.data.objects.filter((_state: any): boolean => _state.clientID === clientID);

            if (state) {
                this.data.drawData = { ...drawData };
                this.data.drawData.shapeType = state.shapeType;
            } else {
                return;
            }
        } else {
            this.data.drawData = { ...drawData };
            if (this.data.drawData.initialState) {
                this.data.drawData.shapeType = this.data.drawData.initialState.shapeType;
            }
        }

        // install default values for drawing method
        if (drawData.enabled) {
            if (drawData.shapeType === 'rectangle' || drawData.shapeType === 'splitRectangle') {
                this.data.drawData.rectDrawingMethod = drawData.rectDrawingMethod || RectDrawingMethod.CLASSIC;
            }
            if (drawData.shapeType === 'cuboid') {
                this.data.drawData.cuboidDrawingMethod = drawData.cuboidDrawingMethod || CuboidDrawingMethod.CLASSIC;
            }
        }

        this.notify(UpdateReasons.DRAW);
    }

    public interact(interactionData: InteractionData): void {
        if (![Mode.IDLE, Mode.INTERACT].includes(this.data.mode)) {
            throw Error(`Canvas is busy-interact. Action: ${this.data.mode}`);
        }
        const thresholdChanged = this.data.interactionData.enableThreshold !== interactionData.enableThreshold;
        if (interactionData.enabled && !interactionData.intermediateShape && !thresholdChanged) {
            if (this.data.interactionData.enabled) {
                throw new Error('Interaction has been already started');
            } else if (!interactionData.shapeType) {
                throw new Error('A shape type was not specified');
            }
        }
        this.data.interactionData = interactionData;
        if (typeof this.data.interactionData.crosshair !== 'boolean') {
            this.data.interactionData.crosshair = true;
        }

        this.notify(UpdateReasons.INTERACT);
    }

    public drawSubPoint(drawData?: DrawSubPointData): void {
        this.data.drawSubPointData = drawData;
        this.notify(UpdateReasons.drawSubPoint);
        this.data.drawSubPointData = undefined;
    }

    public split(splitData: SplitData): void {
        if (![Mode.IDLE, Mode.SPLIT].includes(this.data.mode)) {
            throw Error(`Canvas is busy-split. Action: ${this.data.mode}`);
        }

        if (this.data.splitData.enabled && splitData.enabled) {
            return;
        }

        if (!this.data.splitData.enabled && !splitData.enabled) {
            return;
        }

        this.data.splitData = { ...splitData };
        this.notify(UpdateReasons.SPLIT);
    }

    public group(groupData: GroupData): void {
        if (![Mode.IDLE, Mode.GROUP].includes(this.data.mode)) {
            throw Error(`Canvas is busy-group. Action: ${this.data.mode}`);
        }

        if (this.data.groupData.enabled && groupData.enabled) {
            return;
        }

        if (!this.data.groupData.enabled && !groupData.enabled) {
            return;
        }

        this.data.groupData = { ...groupData };
        this.notify(UpdateReasons.GROUP);
    }

    public merge(mergeData: MergeData): void {
        if (![Mode.IDLE, Mode.MERGE].includes(this.data.mode)) {
            throw Error(`Canvas is busy. Action: ${this.data.mode}`);
        }

        if (this.data.mergeData.enabled && mergeData.enabled) {
            return;
        }

        if (!this.data.mergeData.enabled && !mergeData.enabled) {
            return;
        }

        this.data.mergeData = { ...mergeData };
        this.notify(UpdateReasons.MERGE);
    }

    public select(objectState: any): void {
        this.data.selected = objectState;
        this.notify(UpdateReasons.SELECT);
        this.data.selected = null;
    }

    public configure(configuration: Configuration): void {
        if (typeof configuration.displayAllText === 'boolean') {
            this.data.configuration.displayAllText = configuration.displayAllText;
        }

        if (
            typeof configuration.textFontSize === 'number' &&
            configuration.textFontSize >= consts.MINIMUM_TEXT_FONT_SIZE
        ) {
            this.data.configuration.textFontSize = configuration.textFontSize;
        }

        if (['auto', 'center'].includes(configuration.textPosition)) {
            this.data.configuration.textPosition = configuration.textPosition;
        }

        if (typeof configuration.textContent === 'string') {
            const splitted = configuration.textContent.split(',').filter((entry: string) => !!entry);
            if (
                splitted.every((entry: string) =>
                    ['id', 'label', 'attributes', 'source', 'descriptions'].includes(entry),
                )
            ) {
                this.data.configuration.textContent = configuration.textContent;
            }
        }

        if (typeof configuration.showProjections === 'boolean') {
            this.data.configuration.showProjections = configuration.showProjections;
        }
        if (typeof configuration.autoborders === 'boolean') {
            this.data.configuration.autoborders = configuration.autoborders;
        }
        if (typeof configuration.smoothImage === 'boolean') {
            this.data.configuration.smoothImage = configuration.smoothImage;
        }
        if (typeof configuration.undefinedAttrValue === 'string') {
            this.data.configuration.undefinedAttrValue = configuration.undefinedAttrValue;
        }
        if (typeof configuration.forceDisableEditing === 'boolean') {
            this.data.configuration.forceDisableEditing = configuration.forceDisableEditing;
        }
        if (typeof configuration.intelligentPolygonCrop === 'boolean') {
            this.data.configuration.intelligentPolygonCrop = configuration.intelligentPolygonCrop;
        }
        if (typeof configuration.forceFrameUpdate === 'boolean') {
            this.data.configuration.forceFrameUpdate = configuration.forceFrameUpdate;
        }
        if (typeof configuration.creationOpacity === 'number') {
            this.data.configuration.creationOpacity = configuration.creationOpacity;
        }
        if (typeof configuration.isSegmentation === 'boolean') {
            this.data.configuration.isSegmentation = configuration.isSegmentation;
        }

        this.notify(UpdateReasons.CONFIG_UPDATED);
    }

    public isAbleToChangeFrame(): boolean {
        const isUnable =
            [Mode.DRAG, Mode.EDIT, Mode.RESIZE, Mode.INTERACT].includes(this.data.mode) ||
            (this.data.mode === Mode.DRAW && typeof this.data.drawData.redraw === 'number');

        return !isUnable;
    }

    public activates(clientsID: number[], attributeID: number | null): void {
        this.data.activeElement = {
            clientID: null,
            attributeID,
            clientsID,
        };
        this.notify(UpdateReasons.SHAPES_ACTIVATED);
    }

    public multSelect(e: MouseEvent): void {
        this.data.multSelectData = {
            enabled: true,
            e,
        };
        this.notify(UpdateReasons.MOUSEMULTSELECT);
        this.data.multSelectData = null;
    }

    public changeShowAttribute(isShow: boolean): void {
        this.isShowAttribute = isShow;
        this.notify(UpdateReasons.CHANGESHOWATTRIBUTE);
    }

    public get isShowAttribute(): boolean {
        return this.data.showAttribute;
    }

    public set isShowAttribute(isShow: boolean) {
        this.data.showAttribute = isShow;
    }

    public changeRelationOperate(relationOperate: boolean): void {
        this.data.relationOperate = relationOperate;
    }

    public get relationOperate(): boolean {
        return this.data.relationOperate;
    }
    public get drawSubPointData(): DrawSubPointData | undefined {
        return this.data.drawSubPointData;
    }

    public cancel(): void {
        this.notify(UpdateReasons.CANCEL);
    }

    public destroy(): void {
        this.notify(UpdateReasons.DESTROY);
    }

    public get configuration(): Configuration {
        return { ...this.data.configuration };
    }

    public get geometry(): Geometry {
        return {
            angle: this.data.angle,
            canvas: { ...this.data.canvasSize },
            image: { ...this.data.imageSize },
            grid: { ...this.data.gridSize },
            left: this.data.left,
            offset: this.data.imageOffset,
            scale: this.data.scale,
            top: this.data.top,
            frame: this.data.imageID,
        };
    }

    public set geometry(geometry: Geometry) {
        this.data.angle = geometry.angle;
        this.data.canvasSize = { ...geometry.canvas };
        this.data.imageSize = { ...geometry.image };
        this.data.gridSize = { ...geometry.grid };
        this.data.left = geometry.left;
        this.data.top = geometry.top;
        this.data.imageOffset = geometry.offset;
        this.data.scale = geometry.scale;

        this.data.imageOffset = Math.floor(
            Math.max(this.data.canvasSize.height / FrameZoom.MIN, this.data.canvasSize.width / FrameZoom.MIN),
        );
    }

    public get imageData(): ImageBitmapData | undefined {
        return this.data.imageData;
    }

    public get zLayer(): number | null {
        return this.data.zLayer;
    }

    public get imageBitmap(): boolean {
        return this.data.imageBitmap;
    }

    public get image(): Image | null {
        return this.data.image;
    }

    public get issueRegions(): Record<number, { hidden: boolean; points: number[] }> {
        return { ...this.data.issueRegions };
    }

    public get objects(): any[] {
        if (this.data.zLayer !== null) {
            return this.data.objects.filter((object: any): boolean => object.zOrder <= this.data.zLayer);
        }

        return this.data.objects;
    }

    public get gridSize(): Size {
        return { ...this.data.gridSize };
    }

    public get focusData(): FocusData {
        return { ...this.data.focusData };
    }

    public get activeElement(): ActiveElement {
        return { ...this.data.activeElement };
    }

    public get drawData(): DrawData {
        return { ...this.data.drawData };
    }

    public get interactionData(): InteractionData {
        return { ...this.data.interactionData };
    }

    public get mergeData(): MergeData {
        return { ...this.data.mergeData };
    }

    public get splitData(): SplitData {
        return { ...this.data.splitData };
    }

    public get groupData(): GroupData {
        return { ...this.data.groupData };
    }

    public get multSelectData(): MultSelectData {
        return { ...this.data.multSelectData };
    }

    public get selected(): any {
        return this.data.selected;
    }

    public set mode(value: Mode) {
        this.data.mode = value;
    }

    public get mode(): Mode {
        return this.data.mode;
    }
    public get exception(): Error {
        return this.data.exception;
    }
}
