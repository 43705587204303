/*
 * @Author: swxy
 * @Date: 2023-08-24 19:58:51
 * @LastEditors: swxy
 * Copyright (C) AMYGO AI
 */
import { DimensionType, ObjectType, ShapeType, Source, SubElement } from 'reducers/interfaces';
import { ShapeResourceType, ShapeStatus } from 'utils/ConstType';
import DrawnAnnotation, { Annotation } from './annotation';
// import FrameData from 'business/frame';
import AnnotationHistory, { HistoryActions } from './annotationsHistory';
import { Attribute, Label } from './label';
import { checkObjectType } from 'common/common';
import { ArgumentError, DataError, ScriptingError } from 'errors/exception';
import { InjectionProps } from './annotationCollection';
import {
    Bezier2Shape,
    CuboidShape,
    EllipseShape,
    PointsShape,
    PolygonShape,
    PolylineShape,
    RectangleShape,
} from './shape';
import ObjectState, { SerializedData } from './objectState';
import { Euler, Matrix4, Quaternion, Vector3 } from 'three';
// import getHelper from './customs/helper';

interface Point {
    x: number;
    y: number;
}

export interface ServerTrackShapeData {
    trackId?: number; //	连续帧编号
    id?: number; //	编号	integer(int64)	integer(int64)
    jobId: number; //	题包编号	integer(int32)	integer(int32)
    // labelId: number; //	标签编号	integer(int32)	integer(int32)
    type: ShapeType; //	类型	string
    // cameraName: string; //	相机名称	string
    // direction: string; //	方向	string
    attributes: Record<number, string>; //	标注框属性(框编号)	array	标注框属性(框编号)
    frame: number; //	帧号	integer(int32)	integer(int32)
    occluded: boolean; //	是否遮挡	boolean
    occludedParts?: boolean[]; //	遮挡部分	string
    outside: boolean;
    points: number[]; //	点	string
    pointsLine?: number; //	线坐标	string
    reviewInfo?: string; //	审核信息	string
    rotation?: number; //	旋转	number(double)	number(double)
    shapeIndex?: number; //	框序号	integer(int32)	integer(int32)
    shapeStatus?: ShapeStatus; //	标注框状态	integer(int32)	integer(int32)
    elements: SubElement[]; //	子点	string
    zOrder?: number; //		integer(int32)	integer(int32)

    backTimes?: number; //	打回次数	integer(int32)	integer(int32)
    addTime?: string; //	输入时间	string(date-time)	string(date-time)
    updateTime?: string; //	更新时间	string(date-time)	string(date-time)

    pointsControl?: number[][]; // 控制点
}

export interface ServerTrackData {
    clientID?: number;
    id?: number; //	编号	integer(int64)	integer(int64)
    jobId: number; //	题包编号	integer(int32)	integer(int32)
    labelId: number; //	标签编号	integer(int32)	integer(int32)
    amount?: string; //	数量	integer(int32)
    cameraName?: string; //	相机名称	string
    direction?: string; //	方向	string
    attributes: Record<number, string>; //	标注框属性(框编号)	array	标注框属性(框编号)
    frame: number; //	帧号	integer(int32)	integer(int32)
    group: number; //	组	integer(int32)	integer(int32)
    reviewInfo?: string; //	审核信息	string
    shapeResourceType?: ShapeResourceType; //	标注框来源类型	integer(int32)	integer(int32)
    source?: Source; //	源	string
    shapes: ServerTrackShapeData[];
    trackId?: number; //	主帧编号	integer(int64)	integer(int64)
    parentId?: number;
    addTime?: string; //	输入时间	string(date-time)	string(date-time)
    updateTime?: string; //	更新时间	string(date-time)	string(date-time)
    relation?: any;
    descriptions?: string[];
}

type Frame = number;

export interface TrackShape {
    serverID?: number;
    attributes: Record<number, string>;
    // occluded: value.occluded,
    occluded: boolean;
    zOrder: number;
    points: number[];
    elements: SubElement[];
    pointOccludeds?: boolean[];
    outside: boolean;
    rotation: number;
    pointsLine: number;
    frame: number;
    controls?: number[][];
}

interface TrackShapePosition extends Omit<TrackShape, 'serverID' | 'attributes' | 'frame'> {
    // points: number[];
    // elements: SubElement[];
    // pointOccludeds?: boolean[];
    // rotation: number;
    // occluded: boolean;
    // outside: boolean;
    // zOrder: number;
    keyframe: boolean;
}

export interface ToServerTrackData {
    id?: number;
    clientID: number;
    idVals: Record<number, string>;
    direction?: string;
    track: {
        jobId: number;
        id?: number;
        clientID: number;
        labelId: number;
        frame: number;
        group?: number;
        source: Source;
        trackId?: number;
        direction?: string;
        cameraName?: string;
    };
    mergeTrackShapes: Record<
        number,
        {
            id?: number;
            clientID: number;
            frame: number;
            idVals: Record<number, string>;
            trackShape: {
                id?: number;
                jobId: number;
                type: ShapeType;
                // occluded: this.shapes[frame].occluded,
                occluded: false;
                zOrder: number;

                occludedParts?: string;
                points: string;
                pointsLine?: number;
                rotation: number;
                outside: boolean;
                frame: number;
                trackId: number;
                subPoints: string;

                pointsControl?: string;
            };
        }
    >;
}
class Track extends DrawnAnnotation {
    public shapes: Record<Frame, TrackShape>;
    public descriptions: string[];
    public readonly isTrack: boolean = true;

    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);

        this.shapes = data.shapes.reduce((shapeAccumulator: Record<Frame, TrackShape>, value) => {
            shapeAccumulator[value.frame] = {
                serverID: value.id,
                // occluded: value.occluded,
                pointsLine: value.pointsLine || 0,
                occluded: false,
                zOrder: value.zOrder || 0,
                points: value.points,
                elements: value.elements || [],
                pointOccludeds: value.occludedParts,
                outside: value.outside,
                rotation: value.rotation || 0,
                attributes: { ...value.attributes },
                frame: value.frame,
                controls: value.pointsControl,
            };

            return shapeAccumulator;
        }, {});
        this.descriptions = [];

        if (!Object.keys(this.shapes).length) {
            this.removed = true;
        }
    }

    // 转换成服务器使用的字段
    public toJSON(): ToServerTrackData {
        const labelAttributes = this.label.attributes.reduce((accumulator: Record<number, Attribute>, attribute) => {
            accumulator[attribute.id] = attribute;
            return accumulator;
        }, {});

        return {
            id: this.serverID,
            clientID: this.clientID,
            idVals: { ...this.attributes },
            direction: this.direction,
            track: {
                jobId: this.jobId,
                id: this.serverID,
                clientID: this.clientID,
                labelId: this.label.id,
                frame: this.frame,
                group: this.group,
                source: this.source,
                trackId:
                    this.relation && this.relation.parentSeventID
                        ? this.relation.parentSeventID
                        : this.getParentServerID(),
                direction: this.direction,
                cameraName: this.cameraName,
            },
            mergeTrackShapes: Object.keys(this.shapes).reduce((shapesAccumulator: Record<Frame, any>, current) => {
                const frame = +current;
                shapesAccumulator[frame] = {
                    id: this.shapes[frame].serverID,
                    clientID: +frame,
                    frame: +frame,
                    idVals: Object.keys(this.shapes[frame].attributes).reduce(
                        (attributeAccumulator: Record<number, string>, current) => {
                            const attrId = +current;
                            if (labelAttributes[attrId] && labelAttributes[attrId].mutable) {
                                // attributeAccumulator.push({
                                //     specId: attrId,
                                //     value: this.shapes[frame].attributes[attrId],
                                // });
                                attributeAccumulator[attrId] = this.shapes[frame].attributes[attrId];
                            }

                            return attributeAccumulator;
                        },
                        {},
                    ),
                    trackShape: {
                        id: this.shapes[frame].serverID,
                        jobId: this.jobId,
                        type: this.shapeType,
                        // occluded: this.shapes[frame].occluded,
                        occluded: false,
                        zOrder: this.shapes[frame].zOrder || 0,

                        occludedParts: Array.isArray(this.shapes[frame].pointOccludeds)
                            ? JSON.stringify(this.shapes[frame].pointOccludeds)
                            : undefined,
                        points:
                            typeof this.shapes[frame].points === 'string'
                                ? [...this.shapes[frame].points]
                                : JSON.stringify([...this.shapes[frame].points]),
                        pointsLine: this.shapes[frame].pointsLine || 0,
                        rotation: this.shapes[frame].rotation || 0,
                        outside: this.shapes[frame].outside,
                        frame: +frame,
                        trackId: this.serverID,
                        // trackId: this.serverID,
                        subPoints:
                            this.shapes[frame].elements && this.shapes[frame].elements.length
                                ? JSON.stringify(this.shapes[frame].elements)
                                : JSON.stringify([]),
                        pointsControl: this.shapes[frame].controls ? JSON.stringify(this.shapes[frame].controls) : '',
                    },
                };

                return shapesAccumulator;
            }, {}),
        };
    }

    /**
     * 获取满足创建ObjectState的值
     * @param frame
     * @returns
     */
    public get(frame: number) {
        const { prev, next, first, last } = this.boundedKeyframes(frame);

        return {
            ...this.getPosition(frame, prev, next),
            attributes: this.getAttributes(frame),
            descriptions: [...this.descriptions],
            group: this.groupObject,
            objectType: ObjectType.TRACK,
            shapeType: this.shapeType,
            clientID: this.clientID,
            serverID: this.serverID,
            lock: this.lock || this.readonly,
            color: this.color,
            hidden: this.hidden,
            updated: this.updated,
            label: this.label,
            pinned: this.pinned,
            keyframes: {
                prev,
                next,
                first,
                last,
            },
            frame,
            source: this.source,
            relation: this.relation,
            direction: this.direction,
            parentID: this.parentID,
            cameraName: this.cameraName,

            readonly: this.readonly,
        };
    }

    public updateShapes = (shapes: ServerTrackShapeData[]) => {
        const addShapes = shapes.reduce((previous: Record<number, TrackShape>, current) => {
            previous[current.frame] = {
                serverID: current.id,
                // occluded: value.occluded,
                pointsLine: current.pointsLine || 0,
                occluded: false,
                zOrder: current.zOrder || 0,
                points: current.points,
                elements: current.elements || [],
                pointOccludeds: current.occludedParts,
                outside: current.outside,
                rotation: current.rotation || 0,
                attributes: { ...current.attributes },
                frame: current.frame,
                controls: current.pointsControl,
            };
            return previous;
        }, {});
        this.shapes = {
            ...addShapes,
            ...this.shapes,
        };

        return addShapes;
    };

    public toShapeJson = (shapes: Record<number, TrackShape>) => {
        const labelAttributes = this.label.attributes.reduce((accumulator: Record<number, Attribute>, attribute) => {
            accumulator[attribute.id] = attribute;
            return accumulator;
        }, {});

        return Object.keys(shapes).reduce((shapesAccumulator: Record<Frame, any>, current) => {
            const frame = +current;
            shapesAccumulator[frame] = {
                id: shapes[frame].serverID,
                clientID: +frame,
                frame: +frame,
                idVals: Object.keys(shapes[frame].attributes).reduce(
                    (attributeAccumulator: Record<number, string>, current) => {
                        const attrId = +current;
                        if (labelAttributes[attrId] && labelAttributes[attrId].mutable) {
                            // attributeAccumulator.push({
                            //     specId: attrId,
                            //     value: shapes[frame].attributes[attrId],
                            // });
                            attributeAccumulator[attrId] = shapes[frame].attributes[attrId];
                        }

                        return attributeAccumulator;
                    },
                    {},
                ),
                trackShape: {
                    id: shapes[frame].serverID,
                    jobId: this.jobId,
                    type: this.shapeType,
                    // occluded: shapes[frame].occluded,
                    occluded: false,
                    zOrder: shapes[frame].zOrder || 0,

                    occludedParts: Array.isArray(shapes[frame].pointOccludeds)
                        ? JSON.stringify(shapes[frame].pointOccludeds)
                        : undefined,
                    points:
                        typeof shapes[frame].points === 'string'
                            ? [...shapes[frame].points]
                            : JSON.stringify([...shapes[frame].points]),
                    pointsLine: shapes[frame].pointsLine || 0,
                    rotation: shapes[frame].rotation || 0,
                    outside: shapes[frame].outside,
                    frame: +frame,
                    trackId: this.serverID,
                    // trackId: this.serverID,
                    subPoints:
                        shapes[frame].elements && shapes[frame].elements.length
                            ? JSON.stringify(shapes[frame].elements)
                            : JSON.stringify([]),
                    pointsControl: shapes[frame].controls ? JSON.stringify(shapes[frame].controls) : '',
                },
            };

            return shapesAccumulator;
        }, {});
    };

    protected _validateStateBeforeSave(frame: number, data: any, updated: Record<string, boolean>) {
        const fittedPoints = super._validateStateBeforeSave(frame, data, updated);

        if (updated.keyframe) {
            checkObjectType('keyframe', data.keyframe, 'boolean', null);
            if (!this.shapes || (Object.keys(this.shapes).length === 1 && !data.keyframe)) {
                updated.keyframe = false;
                throw new ArgumentError(
                    // 'Can not remove the latest keyframe of an object. Consider removing the object instead',
                    '连续帧至少需要拥有一个关键帧',
                );
            }
        }

        return fittedPoints;
    }

    copyAttributes(attributes: Record<number, string> = {}) {
        return JSON.parse(JSON.stringify(attributes));
    }

    copyElements(elements: SubElement[] = []) {
        if (elements.length) {
            return JSON.parse(JSON.stringify(elements));
        }
        return elements;
    }

    copyControls(controls?: number[][]) {
        if (controls) {
            return JSON.parse(JSON.stringify(controls));
        }
        return controls;
    }

    protected copyShape(frame: number): TrackShape {
        const current = this.get(frame);
        return {
            frame,
            // elements,
            elements: this.copyElements(current.elements),
            points: [...current.points],
            rotation: current.rotation,
            pointOccludeds: current.pointOccludeds ? [...current.pointOccludeds] : undefined,
            zOrder: current.zOrder,
            outside: current.outside,
            occluded: current.occluded,
            // attributes: {},
            attributes: this.copyAttributes(current.attributes),
            pointsLine: current.pointsLine,
            controls: this.copyControls(current.controls),
        };
    }

    getAttributes(targetFrame: number) {
        const result: Record<number, string> = {};

        const wasKeyframe = targetFrame in this.shapes;

        // First of all copy all unmutable attributes
        for (const attrID in this.attributes) {
            if (Object.prototype.hasOwnProperty.call(this.attributes, attrID)) {
                result[attrID] = this.attributes[attrID];
            }
        }

        if (wasKeyframe) {
            const { attributes } = this.shapes[targetFrame];
            for (const attrID in attributes) {
                if (Object.prototype.hasOwnProperty.call(attributes, attrID)) {
                    result[attrID] = attributes[attrID];
                }
            }
            return result;
        }

        // Secondly get latest mutable attributes up to target frame
        const frames = Object.keys(this.shapes).sort((a, b) => +a - +b);
        for (const frame of frames) {
            if (+frame <= targetFrame) {
                const { attributes } = this.shapes[+frame];

                for (const attrID in attributes) {
                    if (Object.prototype.hasOwnProperty.call(attributes, attrID)) {
                        // result[attrID] = {
                        //     ...attributes[attrID],
                        // };
                        // result[attrID] = attributes[attrID].value || attributes[attrID].defaultValue;
                        result[attrID] = attributes[attrID];

                        // if (+frame !== targetFrame) {
                        //     delete result[attrID].id;
                        //     delete result[attrID].shapeId;
                        // }
                    }
                }
            }
        }

        return result;
    }

    protected interpolatePosition(
        leftPosition: TrackShape,
        rightPosition: TrackShape,
        offset: number,
    ): Omit<TrackShapePosition, 'keyframe'> {
        return {
            points: leftPosition.points,
            rotation: leftPosition.rotation,
            occluded: leftPosition.occluded,
            outside: leftPosition.outside,
            zOrder: leftPosition.zOrder,
            elements: leftPosition.elements || [],
            pointOccludeds: leftPosition.pointOccludeds ? [...leftPosition.pointOccludeds] : undefined,
            pointsLine: leftPosition.pointsLine | 0,
            controls: leftPosition.controls,
        };
    }

    getPosition(targetFrame: number, leftKeyframe?: number, rightFrame?: number): TrackShapePosition {
        const leftFrame = targetFrame in this.shapes ? targetFrame : leftKeyframe;
        const rightPosition =
            typeof rightFrame === 'number' && Number.isInteger(rightFrame) ? this.shapes[rightFrame] : undefined;
        const leftPosition =
            typeof leftFrame === 'number' && Number.isInteger(leftFrame) ? this.shapes[leftFrame] : undefined;

        if (typeof rightFrame === 'number' && typeof leftFrame === 'number' && leftPosition && rightPosition) {
            return {
                ...this.interpolatePosition(
                    leftPosition,
                    rightPosition,
                    (targetFrame - leftFrame) / (rightFrame - leftFrame),
                ),
                keyframe: targetFrame in this.shapes,
            };
        }

        if (leftPosition) {
            return {
                points: [...leftPosition.points],
                elements: this.copyElements(leftPosition.elements),
                // elements: [...leftPosition.elements],
                pointOccludeds: leftPosition.pointOccludeds ? [...leftPosition.pointOccludeds] : undefined,
                rotation: leftPosition.rotation,
                occluded: leftPosition.occluded,
                outside: leftPosition.outside,
                zOrder: leftPosition.zOrder,
                pointsLine: leftPosition.pointsLine,
                keyframe: targetFrame in this.shapes,
                controls: this.copyControls(leftPosition.controls),
            };
        }

        if (rightPosition) {
            return {
                points: [...rightPosition.points],
                // elements: [...rightPosition.elements],
                elements: this.copyElements(rightPosition.elements),
                pointOccludeds: rightPosition.pointOccludeds ? [...rightPosition.pointOccludeds] : undefined,
                rotation: rightPosition.rotation,
                occluded: rightPosition.occluded,
                zOrder: rightPosition.zOrder,
                pointsLine: rightPosition.pointsLine,
                keyframe: targetFrame in this.shapes,
                outside: true,
                // controls: leftPosition.controls?.map((values) => [...values]),
                controls: this.copyControls(rightPosition.controls),
            };
        }

        throw new DataError('左边的关键帧和右边的关键帧都没有找到。' + `无法进行插值。 对象后台 ID: ${this.serverID}`);
    }

    boundedKeyframes(targetFrame: number) {
        const frames = Object.keys(this.shapes).map((frame) => +frame);
        let lDiff = Number.MAX_SAFE_INTEGER;
        let rDiff = Number.MAX_SAFE_INTEGER;
        let first = Number.MAX_SAFE_INTEGER;
        let last = Number.MIN_SAFE_INTEGER;

        for (const frame of frames) {
            if (frame < first) {
                first = frame;
            }
            if (frame > last) {
                last = frame;
            }

            const diff = Math.abs(targetFrame - frame);

            if (frame < targetFrame && diff < lDiff) {
                lDiff = diff;
            } else if (frame > targetFrame && diff < rDiff) {
                rDiff = diff;
            }
        }

        const prev = lDiff === Number.MAX_SAFE_INTEGER ? undefined : targetFrame - lDiff;
        const next = rDiff === Number.MAX_SAFE_INTEGER ? undefined : targetFrame + rDiff;

        return {
            prev,
            next,
            first,
            last,
        };
    }

    _saveStartFrame(changeFrame: number, frame: number) {
        // const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;

        if (changeFrame < 0 || typeof changeFrame !== 'number' || !wasKeyframe) {
            return;
        }
        this.shapes[changeFrame] = this.copyShape(frame);
        // this.shapes[changeFrame] = {
        //     frame: changeFrame,
        //     occluded: current.occluded,
        //     rotation: current.rotation,
        //     zOrder: current.zOrder,
        //     points: current.points,
        //     pointOccludeds: current.pointOccludeds,
        //     outside: current.outside,
        //     pointsLine: current.pointsLine,
        //     attributes: this.copyAttributes(current.attributes),
        //     elements: this.copyElements(current.elements),
        // };
        this.frame = changeFrame;
        // console.log('现在：', this.shapes);
    }

    _saveLabel(label: Label, frame: number) {
        const undoLabel = this.label;
        const redoLabel = label;

        /**
         * attributes
         */

        const undoAttributes = {
            unmutable: { ...this.attributes },
            mutable: Object.keys(this.shapes).map((key) => ({
                frame: +key,
                attributes: { ...this.shapes[+key].attributes },
            })),
        };

        /**
         * elements
         */
        const undoElements = Object.keys(this.shapes).map((key) => ({
            frame: +key,
            elements: [...this.shapes[+key].elements],
        }));

        this.label = label;
        this.attributes = {};
        for (const shape of Object.values(this.shapes)) {
            shape.attributes = {};
            shape.elements = [];
        }
        this.appendDefaultAttributes(label);

        const redoAttributes = {
            unmutable: { ...this.attributes },
            mutable: Object.keys(this.shapes).map((key) => ({
                frame: +key,
                attributes: { ...this.shapes[+key].attributes },
            })),
        };

        const redoElements = Object.keys(this.shapes).map((key) => ({
            frame: +key,
            elements: [...this.shapes[+key].elements],
        }));

        this.history.do(
            HistoryActions.CHANGED_LABEL,
            () => {
                this.label = undoLabel;
                this.attributes = undoAttributes.unmutable;
                for (const mutable of undoAttributes.mutable) {
                    this.shapes[mutable.frame].attributes = mutable.attributes;
                }
                for (const elementByFrame of undoElements) {
                    this.shapes[elementByFrame.frame].elements = elementByFrame.elements;
                }
                this.updated = Date.now();
            },
            () => {
                this.label = redoLabel;
                this.attributes = redoAttributes.unmutable;
                for (const mutable of redoAttributes.mutable) {
                    this.shapes[mutable.frame].attributes = mutable.attributes;
                }
                for (const elementByFrame of redoElements) {
                    this.shapes[elementByFrame.frame].elements = elementByFrame.elements;
                }
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );
    }

    _saveAttributes(attributes: Record<number, string>, frame: number) {
        const current = this.get(frame);
        const labelAttributes = this.label.attributes.reduce((accumulator: Record<number, Attribute>, value) => {
            accumulator[value.id] = value;
            return accumulator;
        }, {});

        const wasKeyframe = frame in this.shapes;
        const undoAttributes = this.attributes;
        const undoShape = wasKeyframe ? this.shapes[frame] : undefined;

        let mutableAttributesUpdated = false;
        const redoAttributes = { ...this.attributes };
        for (const key of Object.keys(attributes)) {
            const attrID = +key;
            if (labelAttributes[attrID] && !labelAttributes[attrID].mutable) {
                redoAttributes[attrID] = attributes[attrID];
            } else if (attributes[attrID] !== current.attributes[attrID]) {
                mutableAttributesUpdated =
                    mutableAttributesUpdated ||
                    // not keyframe yet
                    !(frame in this.shapes) ||
                    // keyframe, but without this attrID
                    !(attrID in this.shapes[frame].attributes) ||
                    // keyframe with attrID, but with another value
                    this.shapes[frame].attributes[attrID] !== attributes[attrID];
            }
        }
        let redoShape: TrackShape | undefined;
        if (mutableAttributesUpdated) {
            if (wasKeyframe) {
                redoShape = {
                    ...this.shapes[frame],
                    attributes: {
                        ...this.shapes[frame].attributes,
                    },
                };
            } else {
                redoShape = {
                    ...this.copyShape(frame),
                    // frame,
                    // zOrder: current.zOrder,
                    // points: current.points,
                    // pointOccludeds: current.pointOccludeds,
                    // outside: current.outside,
                    // occluded: current.occluded,
                    // rotation: current.rotation,
                    // pointsLine: current.pointsLine,
                    // // attributes: {},
                    // // elements: current.elements,
                    // attributes: this.copyAttributes(current.attributes),
                    // elements: this.copyElements(current.elements),
                };
            }
        }

        for (const key of Object.keys(attributes)) {
            const attrID = +key;
            if (
                redoShape &&
                labelAttributes[attrID] &&
                labelAttributes[attrID].mutable &&
                attributes[attrID] !== current.attributes[attrID]
            ) {
                redoShape.attributes[attrID] = attributes[attrID];
            }
        }

        this.attributes = redoAttributes;
        if (redoShape) {
            this.shapes[frame] = redoShape;
        }

        this.history.do(
            HistoryActions.CHANGED_ATTRIBUTES,
            () => {
                this.attributes = undoAttributes;
                if (undoShape) {
                    this.shapes[frame] = undoShape;
                } else if (redoShape) {
                    delete this.shapes[frame];
                }
                this.updated = Date.now();
            },
            () => {
                this.attributes = redoAttributes;
                if (redoShape) {
                    this.shapes[frame] = redoShape;
                }
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );
    }

    _savePointsLine(pointsLine: number, frame: number) {
        const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;
        const undoShape = wasKeyframe ? this.shapes[frame] : undefined;
        const redoShape = wasKeyframe
            ? { ...this.shapes[frame], pointsLine }
            : {
                  ...this.copyShape(frame),
                  pointsLine,
                  //   frame,
                  //   rotation: current.rotation,
                  //   pointOccludeds: current.pointOccludeds,
                  //   zOrder: current.zOrder,
                  //   outside: current.outside,
                  //   occluded: current.occluded,
                  //   // attributes: {},
                  //   // elements: current.elements,
                  //   attributes: this.copyAttributes(current.attributes),
                  //   elements: this.copyElements(current.elements),
                  //   points: [...current.points],
              };

        this.shapes[frame] = redoShape;
        this.source = Source.MANUAL;
        this._appendShapeActionToHistory(
            HistoryActions.CHANGED_POINTS,
            frame,
            undoSource,
            redoSource,
            undoShape,
            redoShape,
        );
    }

    _savePoints(points: number[], rotation: number, frame: number, dimension?: DimensionType) {
        // const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;
        const undoShape = wasKeyframe ? this.shapes[frame] : undefined;
        const redoShape = wasKeyframe
            ? { ...this.shapes[frame], points, rotation }
            : {
                  ...this.copyShape(frame),
                  points,
                  rotation,
                  //   frame,
                  //   pointOccludeds: current.pointOccludeds,
                  //   zOrder: current.zOrder,
                  //   outside: current.outside,
                  //   occluded: current.occluded,
                  //   // attributes: {},
                  //   // elements: current.elements,
                  //   attributes: this.copyAttributes(current.attributes),
                  //   elements: this.copyElements(current.elements),
                  //   pointsLine: current.pointsLine,
              };

        this.shapes[frame] = redoShape;
        this.source = Source.MANUAL;
        this._appendShapeActionToHistory(
            HistoryActions.CHANGED_POINTS,
            frame,
            undoSource,
            redoSource,
            undoShape,
            redoShape,
        );
    }

    _saveElements(elements: SubElement[], frame: number) {
        const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;
        const undoShape = wasKeyframe ? this.shapes[frame] : undefined;
        const redoShape = wasKeyframe
            ? { ...this.shapes[frame], elements }
            : {
                  ...this.copyShape(frame),
                  elements: this.copyElements(elements),
                  //   frame,
                  //   // elements,
                  //   points: current.points,
                  //   rotation: current.rotation,
                  //   pointOccludeds: current.pointOccludeds,
                  //   zOrder: current.zOrder,
                  //   outside: current.outside,
                  //   occluded: current.occluded,
                  //   // attributes: {},
                  //   attributes: this.copyAttributes(current.attributes),
                  //   pointsLine: current.pointsLine,
              };

        this.shapes[frame] = redoShape;
        this.source = Source.MANUAL;
        this._appendShapeActionToHistory(
            HistoryActions.change_elements,
            frame,
            undoSource,
            redoSource,
            undoShape,
            redoShape,
        );
    }

    _savePointOccludeds(pointOccludeds: boolean[] | undefined, frame: number) {
        // const undoPointOccludeds = this.pointOccludeds;
        // const redoPointOccludeds = pointOccludeds;

        // this.history.do(
        //     HistoryActions.CHANGED_PINNED,
        //     () => {
        //         this.pointOccludeds = undoPointOccludeds;
        //         this.updated = Date.now();
        //     },
        //     () => {
        //         this.pointOccludeds = redoPointOccludeds;
        //         this.updated = Date.now();
        //     },
        //     [this.clientID],
        //     frame,
        // );

        // this.pointOccludeds = pointOccludeds;

        const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;
        const undoShape = wasKeyframe ? this.shapes[frame] : undefined;
        const redoShape = wasKeyframe
            ? { ...this.shapes[frame], pointOccludeds }
            : {
                  ...this.copyShape(frame),
                  pointOccludeds,
                  //   frame,
                  //   points: current.points,
                  //   zOrder: current.zOrder,
                  //   outside: current.outside,
                  //   occluded: current.occluded,
                  //   rotation: current.rotation,
                  //   pointsLine: current.pointsLine,
                  //   // attributes: {},
                  //   // elements: current.elements,
                  //   elements: this.copyElements(current.elements),
                  //   attributes: this.copyAttributes(current.attributes),
              };

        this.shapes[frame] = redoShape;
        this.source = Source.MANUAL;
        this._appendShapeActionToHistory(
            // HistoryActions.CHANGED_POINTS,
            HistoryActions.CHANGED_PointOccludeds,
            frame,
            undoSource,
            redoSource,
            undoShape,
            redoShape,
        );
    }

    _saveOutside(frame: number, outside: boolean) {
        const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;
        const undoShape = wasKeyframe ? this.shapes[frame] : undefined;
        const redoShape = wasKeyframe
            ? { ...this.shapes[frame], outside }
            : {
                  ...this.copyShape(frame),
                  outside,
                  //   frame,
                  //   rotation: current.rotation,
                  //   zOrder: current.zOrder,
                  //   points: current.points,
                  //   pointOccludeds: current.pointOccludeds,
                  //   occluded: current.occluded,
                  //   //   rotation: current.rotation,
                  //   pointsLine: current.pointsLine,
                  //   // attributes: {},
                  //   // elements: current.elements,
                  //   attributes: this.copyAttributes(current.attributes),
                  //   elements: this.copyElements(current.elements),
              };

        this.shapes[frame] = redoShape;
        this.source = Source.MANUAL;
        this._appendShapeActionToHistory(
            HistoryActions.CHANGED_OUTSIDE,
            frame,
            undoSource,
            redoSource,
            undoShape,
            redoShape,
        );
    }

    _saveOccluded(occluded: boolean, frame: number) {
        const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;
        const undoShape = wasKeyframe ? this.shapes[frame] : undefined;
        const redoShape = wasKeyframe
            ? { ...this.shapes[frame], occluded }
            : {
                  ...this.copyShape(frame),
                  occluded,
                  //   frame,
                  //   rotation: current.rotation,
                  //   zOrder: current.zOrder,
                  //   points: current.points,
                  //   pointOccludeds: current.pointOccludeds,
                  //   outside: current.outside,
                  //   pointsLine: current.pointsLine,
                  //   // attributes: {},
                  //   // elements: current.elements,
                  //   attributes: this.copyAttributes(current.attributes),
                  //   elements: this.copyElements(current.elements),
              };

        this.shapes[frame] = redoShape;
        this.source = Source.MANUAL;
        this._appendShapeActionToHistory(
            HistoryActions.CHANGED_OCCLUDED,
            frame,
            undoSource,
            redoSource,
            undoShape,
            redoShape,
        );
    }

    _saveZOrder(zOrder: number, frame: number) {
        const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;
        const undoShape = wasKeyframe ? this.shapes[frame] : undefined;
        const redoShape = wasKeyframe
            ? { ...this.shapes[frame], zOrder }
            : {
                  ...this.copyShape(frame),
                  zOrder,
                  //   frame,
                  //   rotation: current.rotation,
                  //   occluded: current.occluded,
                  //   points: current.points,
                  //   pointOccludeds: current.pointOccludeds,
                  //   outside: current.outside,
                  //   pointsLine: current.pointsLine,
                  //   // attributes: {},
                  //   // elements: current.elements,
                  //   attributes: this.copyAttributes(current.attributes),
                  //   elements: this.copyElements(current.elements),
              };

        this.shapes[frame] = redoShape;
        this.source = Source.MANUAL;
        this._appendShapeActionToHistory(
            HistoryActions.CHANGED_ZORDER,
            frame,
            undoSource,
            redoSource,
            undoShape,
            redoShape,
        );
    }

    _saveKeyframe(frame: number, keyframe?: boolean) {
        const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;

        if ((keyframe && wasKeyframe) || (!keyframe && !wasKeyframe)) {
            return;
        }

        const undoSource = this.source;
        const redoSource = Source.MANUAL;
        const undoShape = wasKeyframe
            ? {
                  ...this.shapes[frame],
                  id: undefined,
                  serverID: undefined,
              }
            : undefined;
        const redoShape = keyframe
            ? {
                  ...this.copyShape(frame),
                  //   frame,
                  //   rotation: current.rotation,
                  //   zOrder: current.zOrder,
                  //   points: current.points,
                  //   pointOccludeds: current.pointOccludeds,
                  //   outside: current.outside,
                  //   occluded: current.occluded,
                  //   source: current.source,
                  //   pointsLine: current.pointsLine,
                  //   // attributes: {},
                  //   // elements: current.elements,
                  //   attributes: this.copyAttributes(current.attributes),
                  //   elements: this.copyElements(current.elements),
              }
            : undefined;

        this.source = Source.MANUAL;
        if (redoShape) {
            this.shapes[frame] = redoShape;
        } else {
            delete this.shapes[frame];
        }

        this._appendShapeActionToHistory(
            HistoryActions.CHANGED_KEYFRAME,
            frame,
            undoSource,
            redoSource,
            undoShape,
            redoShape,
        );
    }

    async save(frame: number, data: ObjectState, dimension?: DimensionType): Promise<ObjectState> {
        const updated = data.updateFlags;

        // 即使lock也可以更新
        if (updated.parentID && data.parentID) {
            this._saveParentID(data.parentID);
            this.updateTimestamp(updated as unknown as Record<string, boolean>);
        }

        // 关系的更新与锁定无关
        if (updated.relation) {
            this._saveRelation(data.relation);
            this.updateTimestamp(updated as unknown as Record<string, boolean>);
        }

        if (this.lock && data.lock) {
            updated.reset();
            return Annotation.objectStateFactory.call(this, frame, this.get(frame));
        }

        // const updated = data.updateFlags;
        const fittedPoints = this._validateStateBeforeSave(frame, data, updated as unknown as Record<string, boolean>);
        const { rotation } = data;

        if (updated.frame) {
            this._saveStartFrame(data.frame, frame);
            this.updateTimestamp(updated as unknown as Record<string, boolean>);
            updated.reset();

            return Annotation.objectStateFactory.call(this, data.frame, this.get(data.frame));
        }

        // // 关系的更新与锁定无关
        // if (updated.relation) {
        //     this._saveRelation(data.relation);
        // }

        if (updated.pointsLine) {
            this._savePointsLine(data.pointsLine, frame);
        }

        if (updated.label) {
            this._saveLabel(data.label, frame);
        }

        if (updated.lock) {
            this._saveLock(data.lock, frame);
        }

        if (updated.pinned) {
            this._savePinned(data.pinned, frame);
        }

        if (updated.color) {
            this._saveColor(data.color, frame);
        }

        if (updated.hidden) {
            this._saveHidden(data.hidden, frame);
        }

        if (updated.points && fittedPoints.length) {
            this._savePoints(fittedPoints, rotation, frame, dimension);
        }

        if (updated.outside) {
            this._saveOutside(frame, data.outside);
        }

        if (updated.occluded) {
            this._saveOccluded(data.occluded, frame);
        }

        if (updated.zOrder) {
            this._saveZOrder(data.zOrder, frame);
        }

        if (updated.attributes) {
            this._saveAttributes(data.attributes, frame);
        }

        // if (updated.descriptions) {
        //     this._saveDescriptions(data.descriptions);
        // }

        if (updated.keyframe) {
            this._saveKeyframe(frame, data.keyframe);
        }

        if (updated.parentID && data.parentID) {
            this._saveParentID(data.parentID);
        }

        if (updated.elements) {
            this._saveElements(data.elements, frame);
        }

        if (updated.pointOccludeds) {
            this._savePointOccludeds(data.pointOccludeds, frame);
        }

        this.updateTimestamp(updated as unknown as Record<string, boolean>);
        updated.reset();

        return Track.objectStateFactory.call(this, frame, this.get(frame));
    }

    public _appendShapeActionToHistory(
        actionType: HistoryActions,
        frame: number,
        undoSource: Source,
        redoSource: Source,
        undoShape?: TrackShape,
        redoShape?: TrackShape,
    ) {
        this.history.do(
            actionType,
            () => {
                if (!undoShape) {
                    delete this.shapes[frame];
                } else {
                    this.shapes[frame] = undoShape;
                }
                this.source = undoSource;
                this.updated = Date.now();
            },
            () => {
                if (!redoShape) {
                    delete this.shapes[frame];
                } else {
                    this.shapes[frame] = redoShape;
                }
                this.source = redoSource;
                this.updated = Date.now();
            },
            [this.clientID],
            frame,
        );
    }

    public async delete_outside(frame: Frame, reverse: boolean = false) {
        // 检查当前是否是关键帧
        // const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;

        if (!this.lock) {
            if (Object.keys(this.shapes).length === 1 && wasKeyframe) {
                return await this.delete(frame);
            }

            const keyFrame = reverse ? Math.min(this.stopFrame, frame + 1) : Math.max(this.startFrame, frame - 1);

            const keyFrameWasKeyFrame = keyFrame in this.shapes;

            const undoSource = this.source;
            const redoSource = Source.MANUAL;
            const undoShapes = this.shapes;
            const redoShapes = Object.entries(this.shapes).reduce((previous, [currentFrame, shape]) => {
                if ((reverse === true && +currentFrame > frame) || (reverse === false && +currentFrame < frame)) {
                    // 逆转时，是只保存当前帧之后（不包含当前帧）的数据
                    // 其他是保存当前帧之前（不包含当前帧）的数据
                    previous[+currentFrame] = shape;
                } else {
                    shape.serverID = undefined;
                }
                return previous;
            }, {} as Record<number, TrackShape>);

            if (!keyFrameWasKeyFrame) {
                // 让第一个或者最后一个，变成关键帧
                redoShapes[keyFrame] = {
                    ...this.copyShape(keyFrame),
                    outside: true,
                };
            } else {
                this.shapes[keyFrame].outside = true;
            }

            this.source = Source.MANUAL;
            this.shapes = redoShapes;

            this.relation = {};

            this.history.do(
                HistoryActions.REMOVED_OBJECT,
                () => {
                    this.shapes = undoShapes;
                    this.source = undoSource;
                    this.updated = Date.now();
                },
                () => {
                    this.shapes = redoShapes;
                    this.source = redoSource;
                    this.updated = Date.now();
                },
                [this.clientID],
                frame,
            );

            return true;
        }
        return false;
    }

    public async delete(frame: number, force: boolean = false) {
        if ((!this.lock || force) && !this.readonly) {
            this.removed = true;
            this.relation = {};

            this.history.do(
                HistoryActions.REMOVED_OBJECT,
                () => {
                    this.relation = {};
                    this.serverID = undefined;
                    this.removed = false;
                    this.updated = Date.now();
                    if (this.shapes) {
                        Object.values(this.shapes).forEach((shape) => {
                            delete shape.serverID;
                        });
                    }
                },
                () => {
                    this.removed = true;
                    this.updated = Date.now();
                },
                [this.clientID],
                frame,
            );
        }

        return this.removed;
    }

    static distance(points: number[], x: number, y: number, angle: number) {}
}

export class RectangleTrack extends Track {
    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.RECTANGLE;
        this.pinned = false;
        for (const shape of Object.values(this.shapes)) {
            Annotation.checkNumberOfPoints(this.shapeType, shape.points);
        }
    }

    protected interpolateElements(leftElements: SubElement[], rightElements: SubElement[], offset: number) {
        // 左右子元素数量不一定对等
        if (leftElements.length && rightElements.length) {
            const [leftElement] = leftElements;
            const [rightElement] = rightElements;
            const positionOffset = leftElement.points.map((point, index) => ({
                ...point,
                x: rightElement.points[index] ? rightElement.points[index].x - point.x : 0,
                y: rightElement.points[index] ? rightElement.points[index].y - point.y : 0,
            }));
            return [
                {
                    ...leftElement,
                    points: leftElement.points.map((point, index) => ({
                        ...point,
                        x: point.x + positionOffset[index].x * offset,
                        y: point.y + positionOffset[index].y * offset,
                    })),
                },
            ];
        }
        return leftElements;
    }

    protected interpolatePosition(leftPosition: TrackShape, rightPosition: TrackShape, offset: number) {
        const positionOffset = leftPosition.points.map((point, index) => rightPosition.points[index] - point);
        return {
            points: leftPosition.points.map((point, index) => point + positionOffset[index] * offset),
            rotation:
                (leftPosition.rotation +
                    Annotation.findAngleDiff(rightPosition.rotation, leftPosition.rotation) * offset +
                    360) %
                360,
            occluded: leftPosition.occluded,
            outside: leftPosition.outside,
            zOrder: leftPosition.zOrder,
            pointsLine: leftPosition.pointsLine,
            elements: this.interpolateElements(leftPosition.elements, rightPosition.elements, offset),
        };
    }
}

export class SplitRectangleTrack extends Track {
    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.RECTANGLE;
        this.pinned = false;
        for (const shape of Object.values(this.shapes)) {
            Annotation.checkNumberOfPoints(this.shapeType, shape.points);
        }
    }

    protected interpolatePosition(leftPosition: TrackShape, rightPosition: TrackShape, offset: number) {
        const positionOffset = leftPosition.points.map((point, index) => rightPosition.points[index] - point);
        return {
            points: leftPosition.points.map((point, index) => point + positionOffset[index] * offset),
            rotation:
                (leftPosition.rotation +
                    Annotation.findAngleDiff(rightPosition.rotation, leftPosition.rotation) * offset +
                    360) %
                360,
            occluded: leftPosition.occluded,
            outside: leftPosition.outside,
            zOrder: leftPosition.zOrder,
            pointsLine: leftPosition.pointsLine,
            elements: leftPosition.elements || [],
        };
    }
}

export class EllipseTrack extends Track {
    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.ELLIPSE;
        this.pinned = false;
        for (const shape of Object.values(this.shapes)) {
            Annotation.checkNumberOfPoints(this.shapeType, shape.points);
        }
    }

    protected interpolatePosition(leftPosition: TrackShape, rightPosition: TrackShape, offset: number) {
        const positionOffset = leftPosition.points.map((point, index) => rightPosition.points[index] - point);

        return {
            points: leftPosition.points.map((point, index) => point + positionOffset[index] * offset),
            rotation:
                (leftPosition.rotation +
                    Annotation.findAngleDiff(rightPosition.rotation, leftPosition.rotation) * offset +
                    360) %
                360,
            occluded: leftPosition.occluded,
            outside: leftPosition.outside,
            zOrder: leftPosition.zOrder,
            pointsLine: leftPosition.pointsLine,
            elements: leftPosition.elements || [],
        };
    }
}

export class PolyTrack extends Track {
    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        for (const shape of Object.values(this.shapes)) {
            shape.rotation = 0; // is not supported
        }
    }

    protected interpolatePosition(leftPosition: TrackShape, rightPosition: TrackShape, offset: number) {
        if (offset === 0) {
            return {
                points: [...leftPosition.points],
                rotation: leftPosition.rotation,
                pointOccludeds: leftPosition.pointOccludeds,
                occluded: leftPosition.occluded,
                outside: leftPosition.outside,
                zOrder: leftPosition.zOrder,
                pointsLine: leftPosition.pointsLine,
                elements: leftPosition.elements || [],
            };
        }

        function toArray(points: Point[]) {
            return points.reduce((acc: number[], val) => {
                acc.push(val.x, val.y);
                return acc;
            }, []);
        }

        function toPoints(array: number[]) {
            return array.reduce((acc: Point[], _, index) => {
                if (index % 2) {
                    acc.push({
                        x: array[index - 1],
                        y: array[index],
                    });
                }

                return acc;
            }, []);
        }

        function curveLength(points: Point[]) {
            return points.slice(1).reduce((acc, _, index) => {
                const dx = points[index + 1].x - points[index].x;
                const dy = points[index + 1].y - points[index].y;
                return acc + Math.sqrt(dx ** 2 + dy ** 2);
            }, 0);
        }

        function curveToOffsetVec(points: Point[], length: number) {
            const offsetVector = [0]; // with initial value
            let accumulatedLength = 0;

            points.slice(1).forEach((_, index) => {
                const dx = points[index + 1].x - points[index].x;
                const dy = points[index + 1].y - points[index].y;
                accumulatedLength += Math.sqrt(dx ** 2 + dy ** 2);
                offsetVector.push(accumulatedLength / length);
            });

            return offsetVector;
        }

        function findNearestPair(value: number, curve: number[]) {
            let minimum = [0, Math.abs(value - curve[0])];
            for (let i = 1; i < curve.length; i++) {
                const distance = Math.abs(value - curve[i]);
                if (distance < minimum[1]) {
                    minimum = [i, distance];
                }
            }

            return minimum[0];
        }

        function matchLeftRight(leftCurve: number[], rightCurve: number[]) {
            const matching: Record<number, number[]> = {};
            for (let i = 0; i < leftCurve.length; i++) {
                matching[i] = [findNearestPair(leftCurve[i], rightCurve)];
            }

            return matching;
        }

        function matchRightLeft(
            leftCurve: number[],
            rightCurve: number[],
            leftRightMatching: Record<number, number[]>,
        ) {
            const matchedRightPoints = Object.values(leftRightMatching).flat();
            const unmatchedRightPoints = rightCurve
                .map((_, index) => index)
                .filter((index) => !matchedRightPoints.includes(index));
            const updatedMatching = { ...leftRightMatching };

            for (const rightPoint of unmatchedRightPoints) {
                const leftPoint = findNearestPair(rightCurve[rightPoint], leftCurve);
                updatedMatching[leftPoint].push(rightPoint);
            }

            for (const key of Object.keys(updatedMatching)) {
                const sortedRightIndexes = updatedMatching[+key].sort((a, b) => a - b);
                updatedMatching[+key] = sortedRightIndexes;
            }

            return updatedMatching;
        }

        function reduceInterpolation(
            interpolatedPoints: Point[],
            matching: Record<number, number[]>,
            leftPoints: Point[],
            rightPoints: Point[],
        ) {
            function averagePoint(points: Point[]) {
                let sumX = 0;
                let sumY = 0;
                for (const point of points) {
                    sumX += point.x;
                    sumY += point.y;
                }

                return {
                    x: sumX / points.length,
                    y: sumY / points.length,
                };
            }

            function computeDistance(point1: Point, point2: Point) {
                return Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2);
            }

            function minimizeSegment(
                baseLength: number,
                N: number,
                startInterpolated: number,
                stopInterpolated: number,
            ) {
                const threshold = baseLength / (2 * N);
                const minimized = [interpolatedPoints[startInterpolated]];
                let latestPushed = startInterpolated;
                for (let i = startInterpolated + 1; i < stopInterpolated; i++) {
                    const distance = computeDistance(interpolatedPoints[latestPushed], interpolatedPoints[i]);

                    if (distance >= threshold) {
                        minimized.push(interpolatedPoints[i]);
                        latestPushed = i;
                    }
                }

                minimized.push(interpolatedPoints[stopInterpolated]);

                if (minimized.length === 2) {
                    const distance = computeDistance(
                        interpolatedPoints[startInterpolated],
                        interpolatedPoints[stopInterpolated],
                    );

                    if (distance < threshold) {
                        return [averagePoint(minimized)];
                    }
                }

                return minimized;
            }

            const reduced: Point[] = [];
            const interpolatedIndexes: Record<number, number[]> = {};
            let accumulated = 0;
            for (let i = 0; i < leftPoints.length; i++) {
                // eslint-disable-next-line
                interpolatedIndexes[i] = matching[i].map(() => accumulated++);
            }

            function leftSegment(start: number, stop: number) {
                const startInterpolated = interpolatedIndexes[start][0];
                const stopInterpolated = interpolatedIndexes[stop][0];

                if (startInterpolated === stopInterpolated) {
                    reduced.push(interpolatedPoints[startInterpolated]);
                    return;
                }

                const baseLength = curveLength(leftPoints.slice(start, stop + 1));
                const N = stop - start + 1;

                reduced.push(...minimizeSegment(baseLength, N, startInterpolated, stopInterpolated));
            }

            function rightSegment(leftPoint: number) {
                const start = matching[leftPoint][0];
                const [stop] = matching[leftPoint].slice(-1);
                const startInterpolated = interpolatedIndexes[leftPoint][0];
                const [stopInterpolated] = interpolatedIndexes[leftPoint].slice(-1);
                const baseLength = curveLength(rightPoints.slice(start, stop + 1));
                const N = stop - start + 1;

                reduced.push(...minimizeSegment(baseLength, N, startInterpolated, stopInterpolated));
            }

            let previousOpened = null;
            for (let i = 0; i < leftPoints.length; i++) {
                if (matching[i].length === 1) {
                    // check if left segment is opened
                    if (previousOpened !== null) {
                        // check if we should continue the left segment
                        if (matching[i][0] === matching[previousOpened][0]) {
                            continue;
                        } else {
                            // left segment found
                            const start = previousOpened;
                            const stop = i - 1;
                            leftSegment(start, stop);

                            // start next left segment
                            previousOpened = i;
                        }
                    } else {
                        // start next left segment
                        previousOpened = i;
                    }
                } else {
                    // check if left segment is opened
                    if (previousOpened !== null) {
                        // left segment found
                        const start = previousOpened;
                        const stop = i - 1;
                        leftSegment(start, stop);

                        previousOpened = null;
                    }

                    // right segment found
                    rightSegment(i);
                }
            }

            // check if there is an opened segment
            if (previousOpened !== null) {
                leftSegment(previousOpened, leftPoints.length - 1);
            }

            return reduced;
        }

        // the algorithm below is based on fact that both left and right
        // polyshapes have the same start point and the same draw direction
        const leftPoints = toPoints(leftPosition.points);
        const rightPoints = toPoints(rightPosition.points);
        const leftOffsetVec = curveToOffsetVec(leftPoints, curveLength(leftPoints));
        const rightOffsetVec = curveToOffsetVec(rightPoints, curveLength(rightPoints));

        const matching = matchLeftRight(leftOffsetVec, rightOffsetVec);
        const completedMatching = matchRightLeft(leftOffsetVec, rightOffsetVec, matching);

        const interpolatedPoints = Object.keys(completedMatching)
            .map((leftPointIdx) => +leftPointIdx)
            .sort((a, b) => a - b)
            .reduce((acc: Point[], leftPointIdx) => {
                const leftPoint = leftPoints[leftPointIdx];
                for (const rightPointIdx of completedMatching[leftPointIdx]) {
                    const rightPoint = rightPoints[rightPointIdx];
                    acc.push({
                        x: leftPoint.x + (rightPoint.x - leftPoint.x) * offset,
                        y: leftPoint.y + (rightPoint.y - leftPoint.y) * offset,
                    });
                }

                return acc;
            }, []);

        const reducedPoints = reduceInterpolation(interpolatedPoints, completedMatching, leftPoints, rightPoints);

        return {
            points: toArray(reducedPoints),
            elements: leftPosition.elements || [],
            pointOccludeds: leftPosition.pointOccludeds,
            rotation: leftPosition.rotation,
            occluded: leftPosition.occluded,
            outside: leftPosition.outside,
            zOrder: leftPosition.zOrder,
            pointsLine: leftPosition.pointsLine,
        };
    }
}

export class PolygonTrack extends PolyTrack {
    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.POLYGON;
        for (const shape of Object.values(this.shapes)) {
            Annotation.checkNumberOfPoints(this.shapeType, shape.points);
        }
    }

    protected interpolatePosition(leftPosition: TrackShape, rightPosition: TrackShape, offset: number) {
        const copyLeft = {
            ...leftPosition,
            points: [...leftPosition.points, leftPosition.points[0], leftPosition.points[1]],
        };

        const copyRight = {
            ...rightPosition,
            points: [...rightPosition.points, rightPosition.points[0], rightPosition.points[1]],
        };

        const result = super.interpolatePosition.call(this, copyLeft, copyRight, offset);

        return {
            ...result,
            points: result.points.slice(0, -2),
        };
    }
}

export class PolylineTrack extends PolyTrack {
    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.POLYLINE;
        for (const shape of Object.values(this.shapes)) {
            Annotation.checkNumberOfPoints(this.shapeType, shape.points);
        }
    }
}

export class PointsTrack extends PolyTrack {
    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.POINTS;
        for (const shape of Object.values(this.shapes)) {
            Annotation.checkNumberOfPoints(this.shapeType, shape.points);
        }
    }

    protected interpolatePosition(leftPosition: TrackShape, rightPosition: TrackShape, offset: number) {
        // interpolate only when one point in both left and right positions
        if (leftPosition.points.length === 2 && rightPosition.points.length === 2) {
            return {
                points: leftPosition.points.map(
                    (value, index) => value + (rightPosition.points[index] - value) * offset,
                ),
                pointOccludeds: leftPosition.pointOccludeds,
                rotation: leftPosition.rotation,
                occluded: leftPosition.occluded,
                outside: leftPosition.outside,
                zOrder: leftPosition.zOrder,
                pointsLine: leftPosition.pointsLine,
                elements: leftPosition.elements || [],
            };
        }

        return {
            points: [...leftPosition.points],
            pointOccludeds: leftPosition.pointOccludeds,
            rotation: leftPosition.rotation,
            occluded: leftPosition.occluded,
            outside: leftPosition.outside,
            zOrder: leftPosition.zOrder,
            pointsLine: leftPosition.pointsLine,
            elements: leftPosition.elements || [],
        };
    }
}

export class CuboidTrack extends Track {
    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.CUBOID;
        this.pinned = false;
        for (const shape of Object.values(this.shapes)) {
            Annotation.checkNumberOfPoints(this.shapeType, shape.points);
            shape.rotation = 0; // is not supported
        }
    }

    protected interpolatePosition(leftPosition: TrackShape, rightPosition: TrackShape, offset: number) {
        const positionOffset = leftPosition.points.map((point, index) => rightPosition.points[index] - point);

        return {
            points: leftPosition.points.map((point, index) => point + positionOffset[index] * offset),
            rotation: leftPosition.rotation,
            occluded: leftPosition.occluded,
            outside: leftPosition.outside,
            zOrder: leftPosition.zOrder,
            pointsLine: leftPosition.pointsLine,
            elements: leftPosition.elements || [],
        };
    }

    isSizeChange(oldPoint: number[], points: number[]) {
        const width = Math.floor(points[6] * 100);
        const height = Math.floor(points[7] * 100);
        const depth = Math.floor(points[8] * 100);

        const currentWidth = Math.floor(oldPoint[6] * 100);
        const currentHeight = Math.floor(oldPoint[7] * 100);
        const currentDepth = Math.floor(oldPoint[8] * 100);
        if (width !== currentWidth || height !== currentHeight || depth !== currentDepth) {
            return true;
        }
        return false;
    }

    isSizeNotFixed() {
        // let isNotFixed = false;
        // if (this.label && this.label.attributes) {
        //     this.label.attributes.forEach((attr) => {
        //         if (attr.name === 'shapeObjFrType') {
        //             // 固定类型，判断是否为行人
        //             if (['2'].includes(attr.defaultValue)) {
        //                 isNotFixed = true;
        //             }
        //         }
        //         if (attr.name === 'group_id' && this.attributes[attr.id] && `${this.attributes[attr.id]}` !== '0') {
        //             // 有组id，且当前组id值不为'0'时。
        //             isNotFixed = true;
        //         }
        //     });
        // }
        // return isNotFixed;
        return this.label.isUniformSize === false;
    }

    async _savePoints(points: number[], rotation: number, frame: number, dimension: DimensionType) {
        const current = this.get(frame);
        // const helper = getHelper();
        if (
            dimension === '3d' &&
            this.isSizeChange(current.points, points) &&
            !this.isSizeNotFixed()
            //  && !helper?.isSizeNotFixed(this.label, this.attributes)
        ) {
            // 3D 保持目标物大小前后保持一致。
            const wasKeyframe = frame in this.shapes;
            const undoSource = this.source;
            const redoSource = Source.MANUAL;
            const undoShapes = { ...this.shapes };

            // 当前帧
            const currentFrame = wasKeyframe
                ? { ...this.shapes[frame], points, rotation }
                : {
                      frame,
                      points,
                      rotation,
                      pointOccludeds: current.pointOccludeds,
                      zOrder: current.zOrder,
                      outside: current.outside,
                      occluded: current.occluded,
                      // attributes: {},
                      // elements: current.elements,
                      pointsLine: current.pointsLine,
                      attributes: this.copyAttributes(current.attributes),
                      elements: this.copyElements(current.elements),
                  };

            delete this.shapes[frame]; // 去除目标物的当前帧。 当前帧不参与下述的计算

            const oldWidth = current.points[6];
            const oldHeight = current.points[7];
            const oldDepth = current.points[8];

            const width = points[6];
            const height = points[7];
            const depth = points[8];
            // // const [x, y, z,,,, width, height, depth] = points;
            const offsetWidth = width - oldWidth;
            const offsetHeight = height - oldHeight;
            const offsetDepth = depth - oldDepth;
            for (const [, shape] of Object.entries(this.shapes)) {
                const [shapeX, shapeY, shapeZ, shapeRX, shapeRY, shapeRZ, shapeWidth, shapeHeight, shapeDepth] =
                    shape.points;

                const quaternion = new Quaternion().setFromEuler(new Euler(shapeRX, shapeRY, shapeRZ));
                const pos = new Vector3(shapeX, shapeY, shapeZ);
                const scale = new Vector3(shapeWidth, shapeHeight, shapeDepth);

                const mat = new Matrix4().compose(pos, quaternion, scale);

                const newPos = new Vector3(
                    -(offsetWidth / shapeWidth) * 0.5,
                    (offsetHeight / shapeHeight) * 0.5,
                    (offsetDepth / shapeDepth) * 0.5,
                );

                newPos.applyMatrix4(mat);

                shape.points = [
                    newPos.x,
                    newPos.y,
                    newPos.z,
                    shapeRX,
                    shapeRY,
                    shapeRZ,
                    width,
                    height,
                    depth,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                ];
            }

            const redoShapes = { ...this.shapes };
            redoShapes[frame] = currentFrame;

            // if (!wasKeyframe) {
            //     // 不是关键帧，则增加一个关键帧
            //     this.shapes[frame] = {
            //         frame,
            //         points,
            //         rotation,
            //         pointOccludeds: current.pointOccludeds,
            //         zOrder: current.zOrder,
            //         outside: current.outside,
            //         occluded: current.occluded,
            //         // attributes: {},
            //         // elements: current.elements,
            //         attributes: this.copyAttributes(current.attributes),
            //         elements: this.copyElements(current.elements),
            //     };
            // }

            this.shapes = redoShapes;
            this.source = Source.MANUAL;

            this.history.do(
                HistoryActions.CHANGED_LABEL,
                () => {
                    this.shapes = undoShapes;
                    this.source = undoSource;
                    this.updated = Date.now();
                },
                () => {
                    this.shapes = redoShapes;
                    this.source = redoSource;
                    this.updated = Date.now();
                },
                [this.clientID],
                frame,
            );
            return;
        }
        // const current = this.get(frame);
        const wasKeyframe = frame in this.shapes;
        const undoSource = this.source;
        const redoSource = Source.MANUAL;
        const undoShape = wasKeyframe ? this.shapes[frame] : undefined;
        const redoShape = wasKeyframe
            ? { ...this.shapes[frame], points, rotation }
            : {
                  frame,
                  points,
                  rotation,
                  pointOccludeds: current.pointOccludeds,
                  zOrder: current.zOrder,
                  outside: current.outside,
                  occluded: current.occluded,
                  // attributes: {},
                  // elements: current.elements,
                  pointsLine: current.pointsLine,
                  attributes: this.copyAttributes(current.attributes),
                  elements: this.copyElements(current.elements),
              };

        this.shapes[frame] = redoShape;
        this.source = Source.MANUAL;
        this._appendShapeActionToHistory(
            HistoryActions.CHANGED_POINTS,
            frame,
            undoSource,
            redoSource,
            undoShape,
            redoShape,
        );
    }

    async save(frame: number, data: ObjectState, dimension?: DimensionType): Promise<ObjectState> {
        const updated = data.updateFlags;

        // const helper = getHelper();
        // if (this.serverID) {
        //     await helper?.getAnnotationByTrack(this.serverID, this.direction);
        // }

        if (this.readonly) {
            throw new ScriptingError('该对象目前为只读，不可修改！');
        }

        // 即使lock也可以更新
        if (updated.parentID && data.parentID) {
            this._saveParentID(data.parentID);
            this.updateTimestamp(updated as unknown as Record<string, boolean>);
        }

        // 关系的更新与锁定无关
        if (updated.relation) {
            this._saveRelation(data.relation);
            this.updateTimestamp(updated as unknown as Record<string, boolean>);
        }

        if (this.lock && data.lock) {
            updated.reset();
            return Annotation.objectStateFactory.call(this, frame, this.get(frame));
        }

        // const updated = data.updateFlags;
        const fittedPoints = this._validateStateBeforeSave(frame, data, updated as unknown as Record<string, boolean>);
        const { rotation } = data;

        if (updated.frame) {
            this._saveStartFrame(data.frame, frame);
            this.updateTimestamp(updated as unknown as Record<string, boolean>);
            updated.reset();

            return Annotation.objectStateFactory.call(this, data.frame, this.get(data.frame));
        }

        // // 关系的更新与锁定无关
        // if (updated.relation) {
        //     this._saveRelation(data.relation);
        // }

        if (updated.pointsLine) {
            this._savePointsLine(data.pointsLine, frame);
        }

        if (updated.label) {
            this._saveLabel(data.label, frame);
        }

        if (updated.lock) {
            this._saveLock(data.lock, frame);
        }

        if (updated.pinned) {
            this._savePinned(data.pinned, frame);
        }

        if (updated.color) {
            this._saveColor(data.color, frame);
        }

        if (updated.hidden) {
            this._saveHidden(data.hidden, frame);
        }

        if (updated.points && fittedPoints.length) {
            await this._savePoints(fittedPoints, rotation, frame, dimension!);
        }

        if (updated.outside) {
            this._saveOutside(frame, data.outside);
        }

        if (updated.occluded) {
            this._saveOccluded(data.occluded, frame);
        }

        if (updated.zOrder) {
            this._saveZOrder(data.zOrder, frame);
        }

        if (updated.attributes) {
            this._saveAttributes(data.attributes, frame);
        }

        // if (updated.descriptions) {
        //     this._saveDescriptions(data.descriptions);
        // }

        if (updated.keyframe) {
            this._saveKeyframe(frame, data.keyframe);
        }

        if (updated.parentID && data.parentID) {
            this._saveParentID(data.parentID);
        }

        if (updated.elements) {
            this._saveElements(data.elements, frame);
        }

        if (updated.pointOccludeds) {
            this._savePointOccludeds(data.pointOccludeds, frame);
        }

        this.updateTimestamp(updated as unknown as Record<string, boolean>);
        updated.reset();

        return Track.objectStateFactory.call(this, frame, this.get(frame));
    }
}

export class Bezier2Track extends Track {
    constructor(data: ServerTrackData, clientID: number, color: string, injection: InjectionProps) {
        super(data, clientID, color, injection);
        this.shapeType = ShapeType.bezier2;
        for (const shape of Object.values(this.shapes)) {
            Annotation.checkNumberOfPoints(this.shapeType, shape.points);
        }
    }

    protected interpolatePosition(leftPosition: TrackShape, rightPosition: TrackShape, offset: number) {
        // interpolate only when one point in both left and right positions
        // if (leftPosition.points.length === 2 && rightPosition.points.length === 2) {
        //     return {
        //         points: leftPosition.points.map(
        //             (value, index) => value + (rightPosition.points[index] - value) * offset,
        //         ),
        //         pointOccludeds: leftPosition.pointOccludeds,
        //         rotation: leftPosition.rotation,
        //         occluded: leftPosition.occluded,
        //         outside: leftPosition.outside,
        //         zOrder: leftPosition.zOrder,
        //         pointsLine: leftPosition.pointsLine,
        //         elements: leftPosition.elements || [],
        //         controls: leftPosition.controls?.map((values, index) =>
        //             values.map((value, number) => value + (rightPosition!.controls![index][number] - value) * offset),
        //         ),
        //     };
        // }

        return {
            points: [...leftPosition.points],
            pointOccludeds: leftPosition.pointOccludeds,
            rotation: leftPosition.rotation,
            occluded: leftPosition.occluded,
            outside: leftPosition.outside,
            zOrder: leftPosition.zOrder,
            pointsLine: leftPosition.pointsLine,
            elements: leftPosition.elements || [],
            controls: leftPosition.controls?.map((values) => [...values]),
        };
    }
}

RectangleTrack.distance = RectangleShape.distance;
PolygonTrack.distance = PolygonShape.distance;
PolylineTrack.distance = PolylineShape.distance;
PointsTrack.distance = PointsShape.distance;
EllipseTrack.distance = EllipseShape.distance;
CuboidTrack.distance = CuboidShape.distance;
Bezier2Track.distance = Bezier2Shape.distance;

export default Track;
